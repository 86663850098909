import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPromocaoRegras() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPromocaoRegras = useCallback(
    (promocaoId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/promocao/${promocaoId}/regra`,
        method: 'GET',
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPromocaoRegras,
  };
}
