import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { PromocaoListSearchProps } from "views/pages/private/cadastros/promocao/components/promocao-list/promocao-list-search-props";
import { PromocaoPesquisaModal } from "views/pages/private/cadastros/promocao/components/promocao-pesquisa-modal/promocao-pesquisa-modal";

export const PromocoesFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            ativo: 'true',
            termo: '',
        } as PromocaoListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PromocaoFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PromocaoFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <PromocaoPesquisaModal
            filtros={modalState.filtros}
            openned={modalState.openned}
        />
    )
}