import { Box, Divider, Typography } from "views/design-system"
import { useStyles } from "./carrinho-dados-endereco-styles"
import { CarrinhoDadosEnderecoProps } from "./carrinho-dados-endereco-props"
import { toDecimalString } from "utils/to-decimal"
import { useThemeQueries } from "views/theme"

export const CarrinhoDadosEndereco = ({ pedido, ...props }: CarrinhoDadosEnderecoProps) => {

    const classes = useStyles()
    const { theme } = useThemeQueries();
    return (
        <Box className={classes.container}>
            <Divider className={classes.divider} />
            <Box style={{ paddingTop: theme.spacing(1) }}>
                <Box style={{ paddingBottom: theme.spacing(.5), width: "100%" }} flex justifyContent="space-between">
                    <Typography variant="body2" className={classes.negrito}>
                        Dados de Entrega
                    </Typography>
                </Box>
                <Box style={{ paddingBottom: theme.spacing(.5), width: "100%" }} flex justifyContent="space-between">
                    <Typography variant="body2">
                        <span className={classes.negrito}>Informação Adicional:</span> {pedido.informacaoAdicional || "-"}
                    </Typography>
                </Box>
                <Box style={{ paddingBottom: theme.spacing(.5), width: "100%" }} flex justifyContent="space-between">
                    <Typography variant="body2">
                        <span className={classes.negrito}>Pagamento{pedido.pagamentos.length > 1 ? 's' : ''}:</span> {pedido.pagamentos.map((pag, i, pags) => {
                            if (pags.length === 1) {
                                return `${pag.pagamentoDescricao}`
                            }
                            return `${pag.pagamentoDescricao} R$ ${toDecimalString(pag.valorPago)}${pags.length - 1 !== i ? ' - ' : ''}`
                        })}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}