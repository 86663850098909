
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";

export function usePutCartaoConvenioStatus() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putCartaoConvenioStatus = useCallback(
        (id: string, convenioId: string, status: EnumConvenioStatus, empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/${convenioId}/cartao/${id}/status/${status}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putCartaoConvenioStatus,
    };
}
