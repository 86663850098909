import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toCurrency, toDecimal } from 'utils/to-decimal';
import { useCadastros, useToastSaurus } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { FormAdicionarProdutoDetalhadoModel, FormInfoAdicionalProduto } from 'views/components/form/produto/form-info-adicional-produto/form-info-adicional-produto';
import { DefaultFormRefs } from 'views/components/form/utils';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { MenosSimplesIcon } from 'views/components/icons/menos-simples-icon';
import { BarcodeIcon, CategoriaIcon, MaisIcon, VoltarIcon } from 'views/components/icons';
import { useKeyControls } from 'services/app/hooks/key-controls';
import { ModalAdicionarProdutosProps } from 'views/components/modals/adicionar-produto/modal-adicionar-produto';
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { CircularLoading } from 'views/components/utils';
import { useStyles } from '../modal-adicionar-produto-styles'
import classNames from 'classnames';
import { useDescontoAcrescimo } from 'services/app/hooks/desconto-acrescimo';
import { EnumAcrescimoDesconto } from 'model/enums/enum-acrescimo-desconto';
import { AdicionarDescontoAcrescimoFormModel } from 'model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model';
import { EnumTpDescontoAcrescimo } from 'model/enums/enum-tp-desconto-acrescimo';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { LaboratorioIcon } from 'views/components/icons/laboratorio-icon';
import { TarjaMedicamentoMock } from 'data/mocks/tarja-medicamento-mock';
import { TipoMedicamentoMock } from 'data/mocks/tipo-medicamento-mock';
import { isEmpty } from 'lodash';

export const AdicionarProdutoDetalhado = ({
    produto: prod,
    codigo,
    ...props
}: ModalAdicionarProdutosProps) => {
    const [qtde, setQtde] = useState<number>(1);
    const classes = useStyles();
    const { fecharAdicionarProduto } = useCadastros()
    const { carregando, adicionarProduto } = useMovProd()
    const { alterarProduto } = useMovAtual();
    const { adicionarAcrescimoDescontoItem } = useDescontoAcrescimo();
    const { showToast } = useToastSaurus();

    const porcentagem = (codigo?.percTabela ?? 0) / 100;
    const porcCalc = prod.vUnCom * porcentagem;

    const produto = useMemo(() => ({
        ...prod,
        vUnCom: codigo ? codigo.precoFixo ? codigo.precoFixo / 1 : codigo.percTabela ? prod.vUnCom + porcCalc : prod.vUnCom : prod.vUnCom
    }), [codigo, porcCalc, prod])

    const refFormInfo = useRef<DefaultFormRefs<FormAdicionarProdutoDetalhadoModel>>(null)

    const onCloseClick = useCallback(() => {
        fecharAdicionarProduto();
    }, [fecharAdicionarProduto]);

    const { callEvent } = useEventTools()

    useEffect(() => {
        if (props.aberto && produto) {
            callEvent(AppEventEnum.PermiteEntradaDigitada, false)
        }

        return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
    }, [callEvent, produto, props.aberto])

    const handleClickSum = () => {
        setQtde(qtde + 1);
    };

    const handleClickASubtraction = () => {
        if (qtde > 0)
            setQtde(qtde - 1);
    };

    const handleClickSave = async (model: FormAdicionarProdutoDetalhadoModel) => {
        try {
            let addProduto = { ...produto }

            addProduto.qCom = qtde;
            addProduto.infAdic = model.infAdic;
            
            if(model.percDesconto && toDecimal(model.percDesconto) >= 100){
                throw new Error('O valor do desconto não pode ser maior ou igual ao valor do produto.')
            }

            if(model.valorDesconto && toDecimal(model.valorDesconto) >= (addProduto.vUnCom * addProduto.qCom)){
                throw new Error('O valor do desconto não pode ser maior ou igual ao valor do produto.')
            }

            const ret = await adicionarProduto(addProduto);

            if (model.percDesconto || model.valorDesconto) {
                const descModel = new AdicionarDescontoAcrescimoFormModel();
                if (model.percDesconto) {
                    descModel.vPorcentagem = toDecimal(model.percDesconto);
                    descModel.tpCampo = EnumTpDescontoAcrescimo.Porcentagem;
                } else {
                    descModel.vValorFixo = toDecimal(model.valorDesconto);
                    descModel.tpCampo = EnumTpDescontoAcrescimo.ValorFixo;
                }
                try {
                    const prodComDesc = await adicionarAcrescimoDescontoItem(EnumAcrescimoDesconto.DESCONTO, ret!, descModel)
                    await alterarProduto(prodComDesc);
                } catch (e: any) {
                    //TODO: Definir um jeito melhor de remover o item caso dê erro no desconto (q não fique inativo)
                    await alterarProduto({ ...ret!, ativo: false });
                    throw e;
                }
            }
            onCloseClick()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }

    const valorProduto = produto.vUnComOrig ?? 0;
    const valorPMC = produto.med?.vPMC ?? 0;
    const valorPromocao = (produto.vUnCom) ?? 0;

    const valorDe = valorProduto > valorPMC ? valorProduto : valorPMC;
    const valorPor = valorPromocao;

    const valorTotal = produto.vUnCom * qtde;
    const possuiDe = valorDe > 0 && valorDe !== valorPor;

    useKeyControls(
        {
            onForward: handleClickSum,
            onBackward: handleClickASubtraction,
            onCancel: onCloseClick,
            onEnter: () => refFormInfo.current?.submitForm(),
        }
    )

    return (
        <>
            <Box className={classes.container}>
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <ModalHeader
                    title={
                        `${produto.xProd} - ${qtde} ${produto.uCom}(s)`
                    }
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={onCloseClick}
                        />
                    }
                />
                <Box className={classes.containerScroll}>
                    <Box flex flexDirection='column' style={{ width: '100%' }}>
                        <Box>
                            <Box className={classes.infoContainer}>
                                <Box className={classes.imageContainer}>
                                    {produto.imgUrl.length > 0 && (
                                        <img
                                            src={produto.imgUrl}
                                            className={classes.cardImg}
                                            alt={produto.xProd}
                                        />
                                    )}
                                    {produto.imgUrl.length === 0 && (
                                        <img
                                            src={SemImagem}
                                            className={classes.cardImg}
                                            alt={produto.xProd}
                                        />
                                    )}
                                </Box>
                                <Box className={classes.infoTitleContainer}>
                                    <Typography variant="h5">
                                        {produto.xProd}
                                    </Typography>
                                    <Divider />
                                    <Box className={classes.valorUnitario}>
                                        <Typography color='textSecondary' variant='caption'>
                                            Valor Unitário
                                        </Typography>
                                        <Typography color='primary' weight={600}>
                                            {toCurrency(produto.vUnCom)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.cardInfo}>
                            {(codigo?.codigo || produto.cEan) && <Box className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                                <BarcodeIcon tipo="GERAL" />
                                <Typography variant="caption">
                                    {codigo?.codigo || produto.cEan}
                                </Typography>
                            </Box>}
                            {(produto.cProd !== produto.cEan && produto.cProd) && (
                                <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                                    <BarcodeIcon tipo="GERAL" />
                                    <Typography variant="caption">
                                        {produto.cProd}
                                    </Typography>
                                </Grid>
                            )}
                            {produto.categoria && <Box className={classNames(classes.tagProdutos, classes.tagCategoria)}>
                                <CategoriaIcon tipo="GERAL" />
                                <Typography variant="caption">
                                    {produto.categoria}
                                </Typography>
                            </Box>}
                            {produto.med?.medicamento?.tarja && (
                                <Grid className={classNames(classes.tagProdutos, classes.tagTarja)}>
                                    <MedicamentoIcon tipo="GERAL" />
                                    <Typography variant="caption">
                                        {TarjaMedicamentoMock.find((tarja) => tarja.Key === produto.med?.medicamento?.tarja)?.Value ?? ''}
                                    </Typography>
                                </Grid>
                            )}
                            {produto.med?.medicamento?.tipoMedicamento !== undefined && (
                                <Grid className={classNames(classes.tagProdutos, classes.tagTipoMedicamento)}>
                                    <MedicamentoIcon tipo="GERAL" />
                                    <Typography variant="caption">
                                        {TipoMedicamentoMock.find((tipo) => tipo.Key === produto.med?.medicamento?.tipoMedicamento)?.Value ?? ''}
                                    </Typography>
                                </Grid>
                            )}
                            {!isEmpty(produto.med?.medicamento?.laboratorioNome) && (
                                <Grid className={classNames(classes.tagProdutos, classes.tagLaboratorio, (produto.med?.medicamento?.laboratorioNome ?? '').toUpperCase().indexOf("CIMED") > -1 ? classes.tagLaboratorioDestaque : undefined)}>
                                    <LaboratorioIcon tipo="GERAL" />
                                    <Typography variant="caption">
                                        {produto.med?.medicamento?.laboratorioNome}
                                    </Typography>
                                </Grid>
                            )}
                            {produto.med?.medicamento?.classeTerapeutica !== undefined && (
                                <Grid className={classNames(classes.tagProdutos, classes.tagClasse)}>
                                    <CategoriaIcon tipo="GERAL" />
                                    <Typography variant="caption">
                                        {produto.med?.medicamento?.classeTerapeutica}
                                    </Typography>
                                </Grid>
                            )}
                            {produto.med?.medicamento?.composicao !== undefined && (
                                <Grid className={classNames(classes.tagProdutos, classes.tagComposicao)}>
                                    <MedicamentoIcon tipo="GERAL" />
                                    <Typography variant="caption">
                                        {produto.med?.medicamento?.composicao}
                                    </Typography>
                                </Grid>
                            )}
                        </Box>
                        <Box className={classes.qtdContainer}>
                            <Box>
                                {possuiDe && (
                                    <Typography variant="h6" align='center' style={{ fontWeight: 600, textDecoration: 'line-through' }} color="textSecondary">
                                        {`De: ${toCurrency(valorDe)} UN`}{valorDe === valorPMC ? '*' : ''}
                                    </Typography>
                                )}
                                <Typography variant="h5" align='center' style={{ fontWeight: 600 }} color="primary">
                                    {possuiDe ? 'Por: ' : ''}{`${toCurrency(valorTotal)}`}
                                </Typography>
                            </Box>
                            <Grid container className={classes.buttonQuantidade}>
                                <Button
                                    disabled={qtde <= 1 ? true : false}
                                    className={classes.buttonChanged}
                                    variant="contained"
                                    color="primary"
                                    tabIndex={-1}
                                    onClick={handleClickASubtraction}
                                >
                                    <MenosSimplesIcon class={classes.buttonMaisMenos} />
                                </Button>
                                <TextFieldSaurus
                                    className={classes.textPerson}
                                    tipo="NUMERO"
                                    value={qtde}
                                    onChange={(e) => {
                                        const novoQtde = Number(e.target.value)
                                        if (!isNaN(novoQtde))
                                            setQtde(novoQtde)
                                    }}
                                />
                                <Button
                                    disabled={qtde >= 990}
                                    className={classes.buttonChanged}
                                    color="primary"
                                    variant="contained"
                                    tabIndex={-1}
                                    onClick={handleClickSum}
                                >
                                    <MaisIcon class={classes.buttonMaisMenos} />
                                </Button>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box mt={3}>
                            <FormInfoAdicionalProduto
                                ref={refFormInfo}
                                loading={false}
                                showLoading
                                onSubmit={handleClickSave}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.buttonAdicionar}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => refFormInfo.current?.submitForm()}
                        disabled={qtde <= 0}
                        rounded
                    >
                        {`Adicionar ${toCurrency(valorTotal)}`}
                    </Button>
                </Box>
            </Box>
        </>
    );
};