import { RetaguardaRotasMock } from "data/mocks/retaguarda-rotas-mock";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DocumentoFiscalModal } from "views/components/modals/documento-fiscal/documento-fiscal-modal";

export const CadastroDocumentoFiscalModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: ''
    });

    const retiradaRoute = RetaguardaRotasMock.documentosRota.path + `/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroDocumentoFiscalModal')

    useEffect(() => {
        if (location.pathname.indexOf(retiradaRoute) === 0) {
            try {
                const rota = location.pathname.split('/');
                const id = rota[2];

                if (id.length > 0) {
                    setModalState(prev => ({
                        id: id === novoCadastroRoute ? '' : id,
                        aberto: true,
                        ultimaUrl: retiradaRoute,
                        search: prev.search
                    }));
                    return;
                }
            } catch { }
        }
    }, [location.pathname, retiradaRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? retiradaRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;

        if (!openned) {
            push({
                pathname: retiradaRoute,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(retiradaRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        });
    }, [retiradaRoute, modalState.aberto, modalState.search, modalState.ultimaUrl, push])

    useEffect(() => {
        addHandler(AppEventEnum.DocumentoFiscalModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DocumentoFiscalModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DocumentoFiscalModal openned={modalState.aberto} key="mdlPessoa" id={modalState.id} />
    )
}