import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';
import { ModulosPDVMock } from './menu-pdv-mock';

export const ModulosFavoritosPadraoMock: MenuModel[] =
    ModulosPDVMock.filter((item) => {
        return item.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA ||
            item.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO ||
            item.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO
    });

