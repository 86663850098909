import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { CardNaoEncontrado } from "views/components/cards";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { ModalHeader } from "views/components/modals/components";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { Paginacao } from "views/components/paginacao";
import { Box, Button, Grid, Typography } from "views/design-system";
import { FormPesquisaHeader } from "views/components/form/form-pesquisa-header/form-pesquisa-header";
import { MenosIcon } from "views/components/icons/menos-icon";
import { DesfazerIcon, FiltroIcon, InformacaoIcon, NovoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";
import CardPromocaoRegraDePor from "views/components/cards/card-promocao-detalhe/card-promocao-regra-de-por/card-promocao-regra-de-por";
import CardPromocaoRegraAtacadoPerc from "views/components/cards/card-promocao-detalhe/card-promocao-regra-atacado-perc/card-promocao-regra-atacado-perc";
import CardPromocaoRegraAtacadoValor from "views/components/cards/card-promocao-detalhe/card-promocao-regra-atacado-valor/card-promocao-regra-atacado-valor";
import { PromocaoRegraDetalheItemModel } from "model/api/gestao/promocao/promocao-regra-detalhes-item-model";
import { PromocaoRegraDetalheModel } from "model/api/gestao/promocao/promocao-regra-detalhes-model";
import { PromocaoRegraEdicaoListagemProps } from "./promocao-regra-listagem-props";
import { RadioSelecionadoTodosIcon } from "views/components/icons/radio-selecionado-todos-icon";
import { useStyles } from "./promocao-regra-listagem-styles";
import { CheckboxDefault } from "views/components/controles/checkboxes";

export const PromocaoRegraEdicaoListagem = (props: PromocaoRegraEdicaoListagemProps) => {

    const modalClasses = useModalStyles();
    const classes = useStyles();
    const [selecionando, setSelecionando] = useState(false);

    const [filtros, setFiltros] = useState({ termo: '' });
    const [produtosSelecionados, setProdutosSelecionados] = useState<PromocaoRegraDetalheModel[]>([]);

    const cardSelecionado = useCallback(async (item: PromocaoRegraDetalheItemModel, detalhe: PromocaoRegraDetalheModel) => {
        if (selecionando) {
            setProdutosSelecionados((prev) => {
                // ATENÇÂO: ESTOU FAZENDO PELO DETALHE ID POIS HOJE NÃO EXISTE ITENS[] DENTRO DE REGRAS
                //QUANDO FOR IMPLEMENTADA UMA REGRA QUE TEM VARIOS ITEMS DENTRO DE UM DETALHE TERÁ QUE CORRIGIR AQUI
                if (prev.findIndex(x => x.id === detalhe.id) > -1) {
                    return prev.filter(x => x.id !== detalhe.id);
                } else {
                    return [...prev, detalhe];
                }
            });
            return;
        }
        await props.editarProduto(item, detalhe);
    }, [props, selecionando]);

    const deletarProdutosSelecionados = useCallback(() => {
        try {
            props.deletarDetalhe(produtosSelecionados.map(x => x.id));
        } catch (e) { }
        setProdutosSelecionados([]);
        setSelecionando(false);
        props.salvarRegra();
    }, [produtosSelecionados, props])

    const renderSearchTextfield = useMemo(() => (
        <FormPesquisaHeader
            text={filtros.termo}
            forceMobile
            onSubmit={(model) => {
                setFiltros((prev) => {
                    if (prev.termo === model.generico) return prev;
                    return {
                        ...prev,
                        termo: model.generico
                    }
                });
            }}
            placeholder={`${props.nomeRegra}: Filtrar produtos`}
        />
    ), [filtros.termo, props.nomeRegra]);

    const renderProdutos = useMemo(() => {
        return props.model.map((detalhe, indexDetalhe) => {
            return detalhe.itens.filter(x => {
                if (filtros.termo.length > 0) {
                    return x.produto.toLowerCase().includes(filtros.termo.toLowerCase()) ||
                        x.variacao.toLowerCase().includes(filtros.termo.toLowerCase()) ||
                        (x.codigo ?? '').toLowerCase().includes(filtros.termo.toLowerCase());
                }
                return true;
            }).map((item, indexItem) => {
                switch (props.regra) {
                    case EnumPromocaoTipoRegra.DePor:
                        return <CardPromocaoRegraDePor selected={produtosSelecionados.findIndex(x => x.id === detalhe.id) > -1} onCheck={selecionando ? () => cardSelecionado(item, detalhe) : undefined} key={indexItem} item={item} detalhe={detalhe} onClick={selecionando ? undefined : () => cardSelecionado(item, detalhe)} />
                    case EnumPromocaoTipoRegra.AtacadoPerc:
                        return <CardPromocaoRegraAtacadoPerc selected={produtosSelecionados.findIndex(x => x.id === detalhe.id) > -1} onCheck={selecionando ? () => cardSelecionado(item, detalhe) : undefined} key={indexItem} item={item} detalhe={detalhe} onClick={selecionando ? undefined : () => cardSelecionado(item, detalhe)} />
                    case EnumPromocaoTipoRegra.AtacadoValor:
                        return <CardPromocaoRegraAtacadoValor selected={produtosSelecionados.findIndex(x => x.id === detalhe.id) > -1} onCheck={selecionando ? () => cardSelecionado(item, detalhe) : undefined} key={indexItem} item={item} detalhe={detalhe} onClick={selecionando ? undefined : () => cardSelecionado(item, detalhe)} />
                    default:
                        return undefined;
                }
            });
        }).filter(x => x.length > 0);
    }, [cardSelecionado, filtros.termo, produtosSelecionados, props.model, props.regra, selecionando]);

    const renderTopContent = useMemo(() => {
        const todosSelecionados = props.model.findIndex(x => produtosSelecionados.findIndex(y => y.id === x.id) === -1) === -1;
        return <Grid flex justifyContent="space-between" flexDirection="row">
            {props.model.length > 0 && (
                <Grid
                    onClick={() => {
                        setProdutosSelecionados([]);
                        setSelecionando(!selecionando)
                    }}
                    className={classes.botaoSelecionar}>
                    {selecionando ? (
                        <>
                            <DesfazerIcon tipo="BUTTON_PRIMARY" />
                            Desfazer
                        </>
                    ) : (
                        <>
                            <RadioSelecionadoTodosIcon tipo="BUTTON_PRIMARY" />
                            Selecionar Produtos
                        </>
                    )}
                </Grid>
            )}
            <Paginacao
                className={classes.paginacao}
                pageChanged={() => { }}
                totalPages={1}
                totalRegisters={props.model.length}
                currentPage={1}
            />
            {selecionando && (
                <Grid
                    onClick={() => {
                        if (todosSelecionados)
                            setProdutosSelecionados([]);
                        else
                            setProdutosSelecionados(props.model);
                    }}
                    className={classNames(classes.botaoSelecionar, classes.check)} style={{ marginLeft: 8, marginRight: 0 }}>
                    Selecionar Todos
                    <CheckboxDefault style={{ margin: -8 }} checked={todosSelecionados} />
                </Grid>
            )}
        </Grid>
    }, [classes.botaoSelecionar, classes.check, classes.paginacao, produtosSelecionados, props.model, selecionando]);

    const renderButtons = useMemo(() => {
        return <Box className={modalClasses.acoes}>
            <Grid container spacing={2}>
                {!selecionando ? (
                    <>
                        <Grid item xs={12} sm={12}>
                            <Button
                                disabled={Boolean(props.carregando)}
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                onClick={props.adicionarProdutos}
                            >
                                <NovoIcon tipo="BUTTON" />
                                Adicionar Produtos
                            </Button>
                        </Grid>
                        {props.model.filter(x => x.adicionado || x.alterado).length > 0 && (
                            <Grid item xs={12} sm={12}>
                                <Button
                                    disabled={props.model.filter(x => !x.validado).length > 0}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={props.salvarRegra}
                                >
                                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={deletarProdutosSelecionados}
                        >
                            <MenosIcon tipo="BUTTON_PRIMARY" />
                            Deletar Produtos
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    }, [deletarProdutosSelecionados, modalClasses.acoes, props.adicionarProdutos, props.carregando, props.model, props.salvarRegra, selecionando])

    return (
        <>
            <ModalHeader
                title={renderSearchTextfield}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={props.voltar}
                    />
                }
            />
            <Box className={modalClasses.content}>
                {!props.carregando && (
                    <>
                        <Grid className={classNames(modalClasses.contentForms)} >
                            {renderTopContent}
                            <Grid>
                                {(renderProdutos.length === 0 && filtros.termo.length === 0) ? (
                                    <CardNaoEncontrado
                                        iconSize="small"
                                        mensagem={
                                            <>
                                                <Typography noWrap={false} style={{ whiteSpace: 'normal' }} align="center" variant="subtitle2">
                                                    Nenhum produto foi adicionado na regra '{props.nomeRegra}'.
                                                </Typography>
                                                <Button style={{ height: 30, marginTop: 16 }} onClick={props.adicionarProdutos} variant='outlined' color="primary">Adicionar Produto</Button>
                                            </>
                                        }
                                        icon={<InformacaoIcon tipo="GERAL" />}
                                    />
                                )
                                    : (renderProdutos.length === 0 && filtros.termo.length > 0) ? (
                                        <CardNaoEncontrado
                                            iconSize="small"
                                            mensagem={`Nenhum produto encontrado com o filtro '${filtros.termo}'.`}
                                            icon={<FiltroIcon tipo="GERAL" />}
                                        />
                                    ) : (renderProdutos)
                                }
                            </Grid>
                        </Grid>
                        {renderButtons}
                    </>
                )}
            </Box>
        </>
    );
}