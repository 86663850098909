import { Tooltip, Typography } from "@material-ui/core"
import { useStyles } from "./tooltip-saurus-styles"
import { TooltipImpressorasProps } from "./tooltip-impressoras-props"
import { InformacaoIcon } from "views/components/icons"


export const ToolTipImpressoras = ({ descricao }: TooltipImpressorasProps) => {
    const classes = useStyles()
    return (
            <Tooltip
                title={
                    <Typography className={classes.tooltipText} align='center'>{descricao}</Typography>
                }
            >
                <div  className={classes.iconDiv}>
                    <InformacaoIcon tipo='GERAL' class={classes.infoIconExt} />
                </div>
            </Tooltip>
    )
}