import { useEffect, useRef, useState } from 'react';
import { useStyles } from './form-contrato-documento-styles';
import { useFormContratoDocumentoValidation } from './form-contrato-documento-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { ContratoDocumentoFormModel } from '../../../../../model/app/forms/master/contrato-documento-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Grid } from 'views/design-system';

export const FormContratoDocumento = React.forwardRef<
  DefaultFormRefs<ContratoDocumentoFormModel>,
  DefaultFormProps<ContratoDocumentoFormModel>
>((props: DefaultFormProps<ContratoDocumentoFormModel>, refs) => {
  const txtDocumento = useRef<HTMLInputElement>();
  const { FormContratoDocumentoValidationYup } =
    useFormContratoDocumentoValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new ContratoDocumentoFormModel(''),
  });
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<ContratoDocumentoFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormContratoDocumentoValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (form: ContratoDocumentoFormModel) => {
    props.onSubmit(form);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      if (txtDocumento.current) {
        txtDocumento.current.focus();
      }
    });
  }, []);

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new ContratoDocumentoFormModel('') });
      reset();
      if (!isMobile) {
        txtDocumento?.current?.focus();
      }
    },
    fillForm: (model: ContratoDocumentoFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) {
        txtDocumento?.current?.focus();
      }
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="documento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="CNPJ"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtDocumento}
                    allowSubmit={true}
                    autoComplete={'off'}
                    label="CNPJ"
                    placeholder=""
                    error={Boolean(
                      errors.documento && errors.documento.message,
                    )}
                    helperText={
                      touchedFields.documento || errors.documento
                        ? errors.documento?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
