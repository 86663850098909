import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormTranEnderecoProps } from "./form-tran-endereco-props"
import { Controller } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus"
import { UFMock } from "data/mocks"
import { Grid } from "views/design-system"

export const FormTranEndereco = ({
    control,
    formState,
    setValue,
    openAccordion,
    setOpenAccordion,
    ...props
}: FormTranEnderecoProps) => {

    const { errors, touchedFields } = formState
    return (
        <AccordionSaurus
            labelPrimary="Endereço"
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="xEnder"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Endereço"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.xEnder && errors.xEnder.message)}
                                helperText={
                                    touchedFields.xEnder || errors.xEnder
                                        ? errors.xEnder?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Controller
                        name="xMun"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Município"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.xMun && errors.xMun.message)}
                                helperText={
                                    touchedFields.xMun || errors.xMun
                                        ? errors.xMun?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="uf"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={UFMock}
                                disabled={props.loading}
                                fullWidth
                                variant="outlined"
                                label="UF"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.uf && errors.uf.message)}
                                helperText={
                                    touchedFields.uf || errors.uf
                                        ? errors.uf?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    if (event) {
                                        const newStatus = UFMock.filter(
                                            (item) => item.Key === event.target.value,
                                        )[0]?.Key;
                                        setValue('uf', newStatus);
                                    }
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}