import { Grid, Tooltip, Typography } from 'views/design-system';
import React, { useCallback, useMemo } from 'react';
import {
  BoletoIcon,
  CartaoIcon,
  ChequeIcon,
  CreditoLojaIcon,
  DepositoBancarioIcon,
  DinheiroIcon,
  DuplicataMercantilIcon,
  QrCodeIcon,
  CartaoFidelidadeIcon,
  SemDinheiroIcon,
  TransferenciaBancariaIcon,
  ValeAlimentacaoIcon,
  ValeCombustivelIcon,
  ValePresenteIcon,
} from 'views/components/icons';
import { useStyles } from './card-finalizadora-mov-styles';
import { useThemeQueries } from 'views';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { CardFinalizadoraMovProps } from './card-finalizadora-mov-props';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { isPlanoPagIntegrado } from 'utils/plano-utils';
import classNames from 'classnames';
import { POSIcon } from 'views/components/icons/pos-icon';
import { DefaultCard } from '../components';

export const CardFinalizadoraMov = ({
  model,
  onClick,
  onCheck,
  selected
}: CardFinalizadoraMovProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { plano } = useSessaoAtual()
  const { showToast } = useToastSaurus()

  const isPagIntegrado = isPlanoPagIntegrado(plano?.plano)
  const noModelPagIntegrado = !isPagIntegrado && model.tpTransacao === EnumPagTpTransacao.S2_PAY

  const handleClick = useCallback(async () => {
    if (noModelPagIntegrado) {
      showToast('error', 'Seu plano não tem Pagamento Integrado.')
      return
    }
    onClick(model)
  }, [model, noModelPagIntegrado, onClick, showToast])

  const colorDisableIcon = noModelPagIntegrado ? theme.palette.action.disabledBackground : theme.palette.text.primary

  const cardIcon = useMemo(() => {
    switch (model.tpMod) {
      case EnumPagTpMod.BOLETO_BANCARIO:
        return <BoletoIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.CARTAO_CREDITO:
      case EnumPagTpMod.CARTAO_DEBITO:
        return <CartaoIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.CHEQUE:
        return <ChequeIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.CREDITO_LOJA:
        return (
          <CreditoLojaIcon tipo="GERAL" fill={colorDisableIcon} />
        );
      case EnumPagTpMod.DEPOSITO_BANCARIO:
        return (
          <DepositoBancarioIcon
            tipo="GERAL"
            fill={colorDisableIcon}
          />
        );
      case EnumPagTpMod.DINHEIRO:
        return <DinheiroIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.DUPLICATA_MERCANTIL:
        return (
          <DuplicataMercantilIcon
            tipo="GERAL"
            fill={colorDisableIcon}
          />
        );
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return <QrCodeIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.PROGRAMA_FIDELIDADE:
        return (
          <CartaoFidelidadeIcon
            tipo="GERAL"
            fill={colorDisableIcon}
          />
        );
      case EnumPagTpMod.SEM_PAGAMENTO:
        return (
          <SemDinheiroIcon tipo="GERAL" fill={colorDisableIcon} />
        );
      case EnumPagTpMod.TRANSFERENCIA_BANCARIA:
        return (
          <TransferenciaBancariaIcon
            tipo="GERAL"
            fill={colorDisableIcon}
          />
        );
      case EnumPagTpMod.VALE_ALIMENTACAO:
      case EnumPagTpMod.VALE_REFEICAO:
        return (
          <ValeAlimentacaoIcon tipo="GERAL" fill={colorDisableIcon} />
        );
      case EnumPagTpMod.VALE_COMBUSTIVEL:
        return (
          <ValeCombustivelIcon tipo="GERAL" fill={colorDisableIcon} />
        );
      case EnumPagTpMod.VALE_PRESENTE:
        return (
          <ValePresenteIcon tipo="GERAL" fill={colorDisableIcon} />
        );

      default:
        return <POSIcon tipo="GERAL" fill={colorDisableIcon} />;
    }
  }, [model.tpMod, colorDisableIcon]);

  return (
    <DefaultCard hasTagStatus={false} onClick={handleClick} className={classes.cardContainer}>
      <Grid className={classes.cardContent}>
        <Grid
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Tooltip arrow title={`${model.descricao}`}>
            <Grid className={classes.cardIcon}>{cardIcon}</Grid>
          </Tooltip>
        </Grid>
        <Grid className={classes.celulaGrid}>
          <Typography
            className={
              classNames(
                classes.textPayment,
                noModelPagIntegrado
                  ? classes.textPaymentDisabled
                  : classes.textPaymentMain
              )
            }
            variant="caption"
          >
            {model.descricao}
          </Typography>
        </Grid>
      </Grid>
    </DefaultCard>
  );
};

export default React.memo(CardFinalizadoraMov);
