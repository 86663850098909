import {
  Comissionado,
  useComissionadosStore
} from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { useStyles } from './preview-vendedores-styles';
import { useConfirm } from 'material-ui-confirm';
import { AddIcon } from 'views/components/icons/add-icon';
import { Button, Grid } from 'views/design-system';
import CardPessoaPreview from 'views/components/cards/card-pessoa-preview/card-pessoa-preview';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { CardNaoEncontrado } from 'views/components/cards';
import { DesfazerIcon, InformacaoIcon, LixoIcon } from 'views/components/icons';
import { useCallback, useMemo, useState } from 'react';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import classNames from 'classnames';
import { Paginacao } from 'views/components/paginacao';
import { RadioSelecionadoTodosIcon } from 'views/components/icons/radio-selecionado-todos-icon';

interface PreviewVendedoresProps {
  setDel: (value: boolean) => void;
  setAddVendedores: () => void;
  handleRemove: (value: Comissionado[]) => Promise<boolean>;
}

export const PreviewVendedores = ({
  setDel,
  setAddVendedores,
  handleRemove
}: PreviewVendedoresProps) => {
  const confirm = useConfirm();
  const [, setAtt] = useState<boolean>(false);
  const [isModeSelect, setIsModeSelect] = useState<boolean>(false);

  const comissionadoVendedores = useComissionadosStore(
    (store) => store.state.comissionadoVendedores
  );

  const [selectedList, setSelectedList] = useState<Comissionado[]>([]);

  const classes = useStyles();

  const handleRemoveVendedores = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: 'Excluir vendedores',
      description: 'Deseja excluir os vendedores selecionados?',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(async () => {
      setIsModeSelect(false);
      await handleRemove(selectedList);
      setAtt((prev) => !prev);
    });
  }, [confirm, handleRemove, selectedList]);

  const onCardSelected = useCallback((comissionado: Comissionado) => {
    setSelectedList((prev) => {
      if (prev.some((x) => x.id === comissionado.id)) {
        return prev.filter((x) => x.id !== comissionado.id);
      }

      return [...prev, comissionado];
    });
  }, []);

  const renderTopContent = useMemo(() => {
    const todosSelecionados =
      comissionadoVendedores.findIndex(
        (x) => selectedList.findIndex((y) => y.id === x.id) === -1
      ) === -1;
    return (
      <Grid flex justifyContent="space-between" flexDirection="row">
        {comissionadoVendedores.length > 0 && (
          <Grid
            onClick={() => {
              setSelectedList([]);
              setIsModeSelect((prev) => !prev);
            }}
            className={classes.botaoSelecionar}
          >
            {isModeSelect ? (
              <>
                <DesfazerIcon tipo="BUTTON_PRIMARY" />
                Desfazer
              </>
            ) : (
              <>
                <RadioSelecionadoTodosIcon tipo="BUTTON_PRIMARY" />
                Selecionar Vendedores
              </>
            )}
          </Grid>
        )}
        <Paginacao
          className={classes.paginacao}
          pageChanged={() => {}}
          totalPages={1}
          totalRegisters={comissionadoVendedores.length}
          currentPage={1}
        />
        {isModeSelect && (
          <Grid
            onClick={() => {
              if (todosSelecionados) setSelectedList([]);
              else setSelectedList(comissionadoVendedores);
            }}
            className={classNames(classes.botaoSelecionar, classes.check)}
            style={{ marginLeft: 8, marginRight: 0 }}
          >
            Selecionar Todos
            <CheckboxDefault
              style={{ margin: -8 }}
              checked={todosSelecionados}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [
    classes.botaoSelecionar,
    classes.check,
    classes.paginacao,
    comissionadoVendedores,
    isModeSelect,
    selectedList
  ]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {renderTopContent}

        <div className={classes.list}>
          <div className={classes.listContainer}>
            {comissionadoVendedores.length === 0 && (
              <CardNaoEncontrado
                icon={<InformacaoIcon tipo="GERAL" />}
                mensagem="Vendedores não encontrado"
              />
            )}
            {comissionadoVendedores.length > 0 &&
              comissionadoVendedores.map((comissionado) => {
                return (
                  <CardPessoaPreview
                    tipo={EnumTpPessoaComissionamento.Vendedor}
                    onAltera={() => {}}
                    model={comissionado}
                    key={comissionado.internalId}
                    onSelect={onCardSelected}
                    selecionar={isModeSelect}
                    selecionado={selectedList.some(
                      (x) => x.id === comissionado.id
                    )}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className={classes.acoes}>
        {!isModeSelect ? (
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={() => {
              setDel(false);
              setAddVendedores();
            }}
          >
            <AddIcon tipo="BUTTON" />
            Adicionar vendedor
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            color="error"
            disabled={selectedList.length <= 0}
            onClick={() => {
              handleRemoveVendedores();
            }}
          >
            <LixoIcon tipo="BUTTON_PRIMARY" />
            Remover Vendedores
          </Button>
        )}
      </div>
    </div>
  );
};
