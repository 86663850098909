import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';

import { ProdutoNovoSubItemFormModel } from 'model/app/forms/produto/produto-subitem/produto-novo-subitem-form-model';
import { AutocompleteProdutos } from '../../../../controles/autocompletes/autocomplete-produtos/autocomplete-produtos';
import { TextFieldSaurus } from 'views/components/controles/inputs/text-field-saurus/text-field-saurus';
import { ProdutoResumidoModel } from '../../../../../../model/api/gestao/produto/produto/produto-resumido-model';
import { Controller, useForm } from 'react-hook-form';
import { useFormNovoProdutoSubItemValidation } from './form-novo-produto-subitem-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCadastros, useToastSaurus } from 'services/app';
import { useGetProdutoById } from 'data/api/gestao/produto/produto/get-produto-by-id';
import { ProdutoNovoModel } from 'model/api/gestao/produto/produto/produto-novo-model';
import { ProdutoCompletoModel } from 'model/api/gestao/produto/produto/produto-completo-model';
import { usePostProduto } from 'data/api/gestao/produto/produto/post-produto';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { ProdutoCompletoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model';
import { AutocompleteProdModificador } from 'views/components/controles/autocompletes/autocomplete-prod-modificador/autocomplete-prod-modificador';
import { guidEmpty } from 'utils/guid-empty';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { produtoPageNome } from 'views/pages/private/cadastros/produto/produto-page';
import { Box, Grid } from 'views/design-system';
import { Card } from '@material-ui/core';

export interface FormNovoSubItemProps
  extends DefaultFormProps<ProdutoNovoSubItemFormModel> {
  empresaId: string;
  onSubmit(
    model: ProdutoNovoSubItemFormModel,
    beforeModel?: ProdutoNovoSubItemFormModel | null
  ): Promise<boolean>;
  produtoGradeId: string;
  tipo?: EnumTipoProduto;
  tipoProdutoPai?: EnumTipoProduto;
  produtoId?: string;
}

export const FormNovoSubItem = forwardRef<
  DefaultFormRefs<ProdutoNovoSubItemFormModel>,
  FormNovoSubItemProps
>(({ loading, ...props }: FormNovoSubItemProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInput = useRef<HTMLInputElement>(null);
  const { isMobile } = useThemeQueries();
  const [modelForm, setModelForm] = useState<ProdutoNovoSubItemFormModel>(
    props.model ?? new ProdutoNovoSubItemFormModel()
  );
  const [, setUpdate] = useState<boolean>(false);
  const { showToast } = useToastSaurus();

  const { getProdutoById, carregando: carregandoGet } = useGetProdutoById();
  const { postProduto, carregando: carregandoPost } = usePostProduto();

  const { abrirCadastroProdutoFacilitado } = useCadastros();
  const { getEmpresaAtual } = useEmpresaAtual();

  const { FormNovoProdutoSubItemYupValidation } =
    useFormNovoProdutoSubItemValidation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    setError
  } = useForm<ProdutoNovoSubItemFormModel>({
    resolver: yupResolver(FormNovoProdutoSubItemYupValidation),
    defaultValues: { ...modelForm },
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const mesmoProdutoGrade = useCallback(
    (produtoGradeId: string) => {
      if (produtoGradeId === props.produtoGradeId) {
        setError('produtoNome', {
          message: `${produtoPageNome(
            props.tipo ?? EnumTipoProduto.Produto,
            false
          )} é o mesmo que o ${produtoPageNome(
            props.tipo ?? EnumTipoProduto.Produto,
            false
          ).toLowerCase()} principal.`
        });
        return true;
      }
      return false;
    },
    [props.produtoGradeId, props.tipo, setError]
  );

  const onSubmit = useCallback(
    async (values: ProdutoNovoSubItemFormModel) => {
      const model = picker<ProdutoNovoSubItemFormModel>(
        values,
        new ProdutoNovoSubItemFormModel()
      );
      const beforeModel = picker<ProdutoNovoSubItemFormModel>(
        modelForm,
        new ProdutoNovoSubItemFormModel()
      );

      if (mesmoProdutoGrade(values.produtoSubGradeId ?? '')) {
        return;
      }
      props.onSubmit(model, beforeModel);
    },
    [mesmoProdutoGrade, modelForm, props]
  );

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInput.current?.focus();
    },
    fillForm: (model: ProdutoNovoSubItemFormModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInput.current?.focus();
      }, 500);
    }
  }));

  const getAdicionalById = useCallback(
    async (id: string) => {
      const res = await getProdutoById(props.empresaId, id);

      if (res.erro) throw res.erro;

      const ret = res.resultado?.data as ProdutoCompletoModel;

      return ret;
    },
    [getProdutoById, props.empresaId]
  );

  const handleCadastroProdutoFacilitado = useCallback(
    (produto: ProdutoCompletoModel, adicional: ProdutoNovoModel) => {
      try {
        setValue('produtoNome', produto.nome);
        setValue('produtoSubGradeId', produto.grades[0].id);

        // if (autoCadastrar) {
        //   handleSubmit(onSubmit)()
        //   return
        // }

        setUpdate((prev) => !prev);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [setValue, showToast]
  );

  const cadastrarProdutoFacilitado = useCallback(
    async (name: string, editar: boolean) => {
      try {
        const produto = new ProdutoNovoModel();
        produto.nome = name;
        produto.empresaId = getEmpresaAtual()!.id;
        produto.tipo = EnumTipoProduto.Insumo;
        const ret = await postProduto(produto, getEmpresaAtual()!.id);
        if (ret.erro) throw ret.erro;

        produto.id = ret.resultado?.data.id;

        const produtoCompleto = await getAdicionalById(produto.id);

        showToast(
          'success',
          `${props.tipo === EnumTipoProduto.Produto
            ? 'Adicional'
            : produtoPageNome(props.tipo ?? EnumTipoProduto.Produto, false)
          } Cadastrado!`
        );

        if (editar) {
          abrirCadastroProdutoFacilitado(
            async (produtoRetornado: ProdutoCompletoPreCadastroFormModel) => {
              produtoCompleto.nome = produtoRetornado.nome;
              handleCadastroProdutoFacilitado(produtoCompleto, produto);
            },
            produto,
            produtoCompleto.grades[0].id
          );
          return;
        }

        handleCadastroProdutoFacilitado(produtoCompleto, produto);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      abrirCadastroProdutoFacilitado,
      getAdicionalById,
      getEmpresaAtual,
      handleCadastroProdutoFacilitado,
      postProduto,
      props.tipo,
      showToast
    ]
  );

  const calcularMinMaxSubitem = useCallback(
    (e: any) => {
      setValue(e.target.name, parseInt(e.target.value));

      const vMax = getValues('qMax');
      const vPadrao = getValues('qPadrao');

      if (vPadrao > vMax) return setValue('qPadrao', vMax);
    },
    [getValues, setValue]
  );

  const carregando = loading || carregandoGet || carregandoPost;

  const tpProdutoPesquisar = useCallback(() => {
    switch (props.tipoProdutoPai) {
      case EnumTipoProduto.Produto:
        return [
          EnumTipoProduto.Insumo,
          EnumTipoProduto.Produto,
          EnumTipoProduto.Servico,
          EnumTipoProduto.Combo
        ];
      case EnumTipoProduto.Combo:
        return [EnumTipoProduto.Combo, EnumTipoProduto.Produto];
      case EnumTipoProduto.Insumo:
        return [EnumTipoProduto.Insumo];
      default:
        return undefined;
    }
  }, [props.tipoProdutoPai])


  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {carregando && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="FULLSIZED" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Card
              style={{
                padding: 16,
                position: 'relative'
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="produtoNome"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdutos
                        inputRef={refInput}
                        loadingExterno={loading}
                        label={
                          props.tipo === EnumTipoProduto.Combo
                            ? 'Produto'
                            : produtoPageNome(props.tipo ?? EnumTipoProduto.Produto, false)}
                        allowSubmit
                        error={Boolean(
                          errors.produtoNome && errors.produtoNome.message
                        )}
                        helperText={
                          errors.produtoNome
                            ? errors.produtoNome?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('produtoNome')}
                        tipo={props.tipo}
                        optSemProduto
                        onChange={async (retorno) => {
                          if (retorno.isNewVal) {
                            let editar = false;
                            if (typeof retorno.isNewVal === 'object') {
                              editar =
                                retorno.isNewVal.identificador === 'editar';
                            }

                            await cadastrarProdutoFacilitado(retorno.value, editar);
                            return;
                          }
                          if (!retorno.isString) {
                            const produto = picker<ProdutoResumidoModel>(
                              retorno.value,
                              new ProdutoResumidoModel()
                            );

                            if (
                              getValues('produtoSubGradeId') !==
                              produto.produtoGradeId
                            ) {
                              setValue('modificadorId', '');
                              setValue('modificadorNome', '');
                            }
                            setValue('produtoNome', produto.nome);
                            setValue(
                              'produtoSubGradeId',
                              produto.produtoGradeId
                            );
                            setValue('produtoSubId', produto.produtoId);
                            setUpdate((prev) => !prev);
                            mesmoProdutoGrade(produto.produtoGradeId);
                          }
                        }}
                        permiteAdicionar
                        textNovoItem={[
                          {
                            texto: 'Adicionar:',
                            identificador: 'adicionar'
                          },
                          {
                            texto: 'Adicionar e Editar:',
                            identificador: 'editar'
                          }
                        ]}
                        tpProduto={tpProdutoPesquisar()}
                        exibirTipo
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="modificadorNome"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdModificador
                        inputRef={refInput}
                        empresaId={props.empresaId}
                        loadingExterno={loading}
                        label={`Modificador`}
                        allowSubmit
                        error={Boolean(
                          errors.modificadorNome &&
                          errors.modificadorNome.message
                        )}
                        helperText={
                          errors.modificadorNome
                            ? errors.modificadorNome?.message
                            : undefined
                        }
                        {...field}
                        onChange={(retorno) => {
                          if (!retorno.isString) {
                            const modificador = retorno.value;
                            setValue('modificadorId', modificador.id);
                            setValue('modificadorNome', modificador.nome);
                          }
                        }}
                        produtoId={getValues('produtoSubId') ?? ''}
                        produtoGradeId={
                          getValues('produtoSubGradeId') === guidEmpty() &&
                            !getValues('produtoSubGradeId')
                            ? ''
                            : getValues('produtoSubGradeId') ?? ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="qMin"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        fullWidth
                        autoComplete="new-password"
                        disabled={loading}
                        label="Mínimo"
                        allowSubmit
                        variant="outlined"
                        error={Boolean(errors.qMin && errors.qMin.message)}
                        helperText={
                          errors.qMin ? errors.qMin?.message : undefined
                        }
                        {...field}
                        onChange={calcularMinMaxSubitem}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="qMax"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        fullWidth
                        autoComplete="new-password"
                        disabled={loading}
                        allowSubmit
                        label="Máximo"
                        variant="outlined"
                        error={Boolean(errors.qMax && errors.qMax.message)}
                        helperText={
                          errors.qMax ? errors.qMax?.message : undefined
                        }
                        {...field}
                        onChange={calcularMinMaxSubitem}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="qPadrao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        fullWidth
                        autoComplete="new-password"
                        disabled={loading}
                        allowSubmit
                        label="Quantidade Padrão"
                        variant="outlined"
                        error={Boolean(
                          errors.qPadrao && errors.qPadrao.message
                        )}
                        helperText={
                          errors.qPadrao ? errors.qPadrao?.message : undefined
                        }
                        {...field}
                        onChange={calcularMinMaxSubitem}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Card>
          </form>
        </div>
      </Box>
    </>
  );
});
