import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { Route, RouteProps } from 'react-router-dom';
import { useMenuStorage } from 'services/app/hooks/menu-storage';
import { MensagemSemConexao } from 'routers/components/sem-conexao-route/components/mensagem-sem-conexao/mensagem-sem-conexao';
import { SincronizacaoGeralToast } from 'routers/components/sem-conexao-route/components/sincronizacao-geral-toast/sincronizacao-geral-toast';
import { SincronizacaoGeralChamada } from 'routers/components/sem-conexao-route/components/sincronizacao-geral/sincronizacao-geral';
import { useStyles } from './sem-conexao-route-styles';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { isEqual } from 'lodash';
import classNames from 'classnames';

export const SemConexaoRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {

  const { get } = useMenuStorage()
  const [menuAtual, setMenuAtual] = useState(get())
  const { addHandler, removeHandler } = useEventTools()

  const retMenuAlterado = useCallback((menu: MenuPrincipalModel) => {
    setMenuAtual((prev) => {
      const menuEqual = isEqual(prev, menu);
      if (!menuEqual) return menu;
      return prev;
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
    return () => removeHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
  }, [addHandler, removeHandler, retMenuAlterado]);

  const modoPDV = menuAtual.modo === EnumMenuPrincipalModo.PDV

  const classes = useStyles()

  return (
    <Route {...otherProps}>
      <SincronizacaoGeralChamada />
      <div className={classNames(classes.container, modoPDV ? classes.containerPDV : null)}>
        {children}
        <MensagemSemConexao />
        <SincronizacaoGeralToast />
      </div>
    </Route>
  );
};
