import { Grid, Typography, Button, Box } from 'views/design-system';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './venda-leitor-fragment-styles';
import Scanner from './components/scanner/scanner';
import { useToastSaurus } from 'services/app';
import { useThemeQueries } from 'views';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { ButtonKeyboardTopMultiply } from 'views/components/keyboard/components/button-keyboard-top-multiply/button-keyboard-top-multiply';
import { KeypadIcon } from 'views/components/icons/keypad-icon';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import classNames from 'classnames';
import { useDigitarCodigo } from 'services/app/hooks/digitar-codigo';
import { CameraIcon } from 'views/components/icons/camera-icon';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { CarrinhoContainer } from '../mov-carrinho/components/cart-wrapper/carrinho-container';
import { useDesktopMode } from 'services/app/hooks/desktop-mode';
import { PesquisaProduto } from '../components/pesquisa/produtos/pesquisa-produtos/pesquisa-produto';
import { FinalizarVendaButton } from '../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';

interface ProdLeitorProps {
  handleAddByCodigo: () => void;
  textChanged: (text: string, formattedText: string) => Promise<boolean>;
  submit: () => Promise<boolean>;
  add: () => Promise<boolean>;
}

const ProdLeitor = ({ handleAddByCodigo, textChanged, submit, add }: ProdLeitorProps) => {
  //STATES E REFS
  const { get, set } = useDigitarCodigo();
  const [digitarCodigo, setDigitarCodigo] = useState(get());

  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')
  const valueRef = useRef<HTMLParagraphElement | null>(null);
  const valueCodRef = useRef<HTMLParagraphElement | null>(null)
  const btnAddRef = useRef<HTMLDivElement | null>(null)
  const btnFinRef = useRef<HTMLDivElement | null>(null)

  //PROVIDERS
  const { inserirProdutoByCodigo } = useMovProd();
  const { callEvent } = useEventTools()
  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey,
    addMultiply,
  } = useKeyboard({
    maxLength: 20,
    floatCases: 0,
    isNumeric: false,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual
  });

  //AUX E Outros
  const classes = useDefaultCadastroStyles();
  const classesLeitor = useStyles();

  const { addHandler, removeHandler } = useEventTools()

  const attValueDisplay = useCallback(() => {

    const v = getFormattedText();
    const vReplace = v.replace(/\*/g, 'x')

    if (valueCodRef.current) {
      valueCodRef.current.textContent = vReplace
    }
    if (valueRef.current) {
      if (v.length === 0) {
        valueRef.current.textContent = "Aguardando Digitação"
        valueRef.current.className = classesLeitor.textVisorAguardando
        if (btnFinRef.current) btnFinRef.current.style.display = 'flex'
        if (btnAddRef.current) btnAddRef.current.style.display = 'none'
        return
      }
      valueRef.current.textContent = vReplace
      valueRef.current.className = classesLeitor.textVisor
      if (btnFinRef.current) btnFinRef.current.style.display = 'none'
      if (btnAddRef.current) btnAddRef.current.style.display = 'flex'
    }
  }, [classesLeitor.textVisor, classesLeitor.textVisorAguardando, getFormattedText])

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: {
        mobile: 'Leitura de Código',
        desktop: 'Faça a Leitura de Código'
      },
      showMenuPdv: true,
      showDefinicoes: true,
      pesquisa: {
        allowLetras: true,
        allowNumbers: true,
      },
    })
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
  }, [addHandler, attValueDisplay, removeHandler, setHeaderProps])

  const { showToast } = useToastSaurus();
  const { theme } = useThemeQueries();

  const { getDesktopMode } = useDesktopMode();
  const isDesktopMode = getDesktopMode();

  const digitos = getFormattedText();

  const possuiScanner = true;

  const exibirDigitarCodigo = digitarCodigo || !possuiScanner;

  useEffect(() => {
    if (!exibirDigitarCodigo) callEvent(AppEventEnum.PermiteEntradaDigitada, true)

    return () => {
      callEvent(AppEventEnum.PermiteEntradaDigitada, true)
    }
  }, [callEvent, exibirDigitarCodigo])


  const handleClickDigitarCodigo = useCallback(() => {
    setDigitarCodigo(!digitarCodigo);
    set(!digitarCodigo)
  }, [digitarCodigo, set]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    }, [addValue]);

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleTextMultiply = useCallback(
    (value: any) => {
      if (value === '*') {
        if (handleText) handleText(value);
      } else {
        addMultiply(value);
      }
    },
    [addMultiply, handleText],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    handleAddByCodigo();
    resetText('');
    submitKey();
  }, [handleAddByCodigo, resetText, submitKey]);

  const inserirProduto = useCallback(
    async (codProduto: string) => {
      try {
        await inserirProdutoByCodigo(getFormattedText() + codProduto);
      } catch (err: any) {
        showToast('error', err.message);
      } finally {
        resetText('');
      }
    },
    [getFormattedText, inserirProdutoByCodigo, resetText, showToast],
  );

  const RenderFluxoInicialLeitor = memo(() => {
    return (
      <>
        <Grid className={classesLeitor.content}>
          <Grid style={{ flex: 1, display: 'block' }}>
            <ButtonKeyboardTopMultiply
              handleButtonMultiplyValue={handleTextMultiply}
            />
          </Grid>
        </Grid>

        <Button
          className={classNames(classesLeitor.button, 'round')}
          onClick={handleClickDigitarCodigo}
          variant="outlined"
          color="primary"
          style={{ height: '60px' }}
        >
          <KeypadIcon tipo="BUTTON" fill={theme.palette.primary.main} />

          <Typography className={classesLeitor.textButton}>
            Digitar Código
          </Typography>
        </Button>
      </>
    );
  });

  const teclado = useMemo(() => {
    return (
      <>
        <Grid
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto'
          }}
        >
          <Keyboard
            isButtonMultiplyTopKeyboard
            handleMultiplyValue={handleTextMultiply}
            handleAdd={handleAdd}
            isButtonBackspace
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric={false}
            isButtonComma
            tecladoBackground
          />
        </Grid>
      </>
    );
  }, [
    handleAdd,
    handleAddValue,
    handleBackSpace,
    handleSubmit,
    handleText,
    handleTextMultiply,
  ]);

  useEffect(() => {

  }, [digitos])

  const renderInserirCodigoManual = useCallback(() => {
    return (
      <>
        <Grid className={classesLeitor.visorCodigo}>
          <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid flex flexDirection='row' alignItems='center'>
              {possuiScanner && (
                <Grid className={classesLeitor.visorButton}></Grid>
              )}
              <Grid className={classesLeitor.contentVisor}>
                <Typography className={classesLeitor.label}>
                  Código do Produto
                </Typography>
                <Typography
                  className={classesLeitor.textVisorAguardando}
                  variant="h4"
                  color="primary"
                  ref={valueRef}
                >
                  {getFormattedText().replace(/\*/g, 'x') || "Aguardando Digitação"}
                </Typography>
              </Grid>
              {possuiScanner && (
                <Button
                  onClick={handleClickDigitarCodigo}
                  variant="outlined"
                  color="primary"
                  className={classNames(classesLeitor.visorButton, classesLeitor.visorLeitor)}
                >
                  {digitarCodigo && (
                    <CameraIcon tipo="GERAL"
                      fill={theme.palette.primary.main} />
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {teclado}
      </>
    );
  }, [
    classesLeitor.textVisorAguardando,
    classesLeitor.visorButton,
    classesLeitor.contentVisor,
    classesLeitor.label,
    classesLeitor.visorCodigo,
    getFormattedText,
    handleClickDigitarCodigo,
    possuiScanner,
    teclado,
    classesLeitor.visorLeitor,
    digitarCodigo,
    theme.palette.primary.main
  ]);

  const leituraRealizada = async (novoCod: string) => {
    await inserirProduto(novoCod);
  };

  // const butonsCarrinho = useMemo(() => {
  //   return <CarrinhoButton />;
  // }, []);

  const adicionarButton = useMemo(() => {
    return (
      <Grid item xs={12} className={classesLeitor.containerItemFooter}>
        <Button
          onClick={() => {
            handleAddByCodigo();
            resetText('');
          }}
          rounded
          variant="contained"
          color="primary"
          fullWidth
          className={classesLeitor.textButton}
          style={{ height: '56px', color: '#fff.', marginBottom: theme.spacing(2) }}
        >
          Adicionar Código
        </Button>
      </Grid>
    );
  }, [
    classesLeitor.containerItemFooter,
    classesLeitor.textButton,
    theme,
    handleAddByCodigo,
    resetText,
  ]);

  const finalizarButton = useMemo(() => {
    return <FinalizarVendaButton />;
  }, []);

  return (
    <Grid className={classes.root}>
      <Box className={classes.pagesContainer}>
        <Box className={classNames(classes.halfPage, isDesktopMode ? '' : classes.fullWidth)}>
          <div style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            position: 'relative'
          }}>
            {exibirDigitarCodigo ? (
              <></>
            ) : (
              <Grid className={classesLeitor.containerPreviewInfo}>
                <Typography className={classesLeitor.textPreview}>
                  Passe o código do produto no scanner
                </Typography>
                <Typography
                  ref={valueCodRef}
                  className={classesLeitor.textPreview}
                  style={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {digitos}
                </Typography>
              </Grid>
            )}

            {possuiScanner && (
              !exibirDigitarCodigo ? (
                <>
                  <Scanner
                    beep
                    onScan={leituraRealizada}
                    inicialOrSecundario={false}
                  />
                </>
              ) : (
                renderInserirCodigoManual()
              )
            )}

            {/* {exibirDigitarCodigo ? (
        renderInserirCodigoManual()
      ) : (
        <RenderFluxoInicialLeitor />
      )} */}
            <Box flex flexDirection="column" >
              {possuiScanner && !exibirDigitarCodigo && <RenderFluxoInicialLeitor />}
              <Grid className={classesLeitor.containerFooter}>
                {/* {butonsCarrinho} */}
                <Grid ref={btnAddRef} container justifyContent='center' alignItems='center' style={{ display: 'none' }}>
                  {adicionarButton}
                </Grid>
              </Grid>
            </Box>
          </div>
          <PesquisaProduto />
        </Box>
        {isDesktopMode && <Box className={classNames(classes.divisoria, classes.cartPage)}>
          <CarrinhoContainer />
        </Box>}
      </Box>
      {!isDesktopMode && <Grid ref={btnFinRef}>
        {finalizarButton}
      </Grid>}
    </Grid>
  );
};

export default ProdLeitor;
