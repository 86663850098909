import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from 'views/design-system';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { ConfigNfceFormModel } from 'model/app/forms/master/configuracoes-nfce-form-model';
import { useThemeQueries } from '../../../../../theme/util-styles';
import classNames from 'classnames';
import { CancelarIcon, SalvarIcon } from 'views/components/icons';
import { FormConfigNfce } from 'views/components/form/master/configuracoes-nfce/form-configuracoes-nfce';
import { useGetConfigEmissor } from 'data/api/gestao/empresa/get-config-emissor';
import { PatchConfigEmissorProps, usePatchConfigEmissor } from 'data/api/gestao/empresa/patch-config-emissor';
import { isEqual } from 'lodash';
import { CardPropagandaUrlNfce } from 'views/components/cards/card-propaganda-url-nfce/card-propaganda-url-nfce';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { VariaveisAmbiente } from 'config';
import { useDeleteConfigNfce } from 'data/api/gestao/empresa/delete-config-emissor';
import { useConfirm } from 'material-ui-confirm';
import { useStyles } from './accordion-configuraoces-nfce-styles';
import { usePDV } from 'services/app/hooks/pdv';
import { picker } from 'utils/picker';
import { NfceIcon } from 'views/components/icons/nfce-icon';
import { ConfigNfceModel } from 'model/api/gestao/nfce/configuracoes-nfce-model';

interface Props {
  documento?: string;
  hasId?: boolean;
}

export const AccordionConfiguracoesNfce = ({ documento, hasId = false }: Props) => {
  // STATES E REFS
  const configNfceFormRef = useRef<DefaultFormRefs<ConfigNfceFormModel>>(null);
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [emissorFormState, setEmissorFormState] = useState<ConfigNfceFormModel>(
    new ConfigNfceFormModel(),
  );
  const isPOS = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS
  const configNfceForm = useRef<ConfigNfceFormModel>(new ConfigNfceFormModel())

  // PROVIDERS
  const { getEmpresaSelecionada, refreshEmpresa, refreshEmpresaFiscal } = useSessaoAtual();

  // CHAMADAS API
  const { getConfigEmissor, carregando: carregandoGetConfigNfce } =
    useGetConfigEmissor();
  const { patchConfigEmissor, carregando: carregandoPatchConfigEmissor } =
    usePatchConfigEmissor();
  const { deleteConfigNfce, carregando: carregandoDeleteConfigNfce } =
    useDeleteConfigNfce();
  const { invalidarConfiguracoes, carregando: carregandoPDV } = usePDV();

  // AUX
  const { showToast } = useToastSaurus();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();
  const confirm = useConfirm();
  const classes = useStyles();

  const carregando =
    carregandoGetConfigNfce ||
    carregandoPatchConfigEmissor ||
    preenchendoTela ||
    carregandoDeleteConfigNfce ||
    carregandoPDV;

  const getConfigNfce = useCallback(async () => {
    const res = await getConfigEmissor(hasId && documento ? documento : getEmpresaSelecionada()?.Documento ?? '');
    if (res.erro) {
      throw res.erro;
    }
    const resultado = res.resultado?.data as ConfigNfceModel

    let formModel: ConfigNfceFormModel = picker<ConfigNfceFormModel>(resultado, new ConfigNfceFormModel());
    formModel.pfxBase64 = resultado?.pfxLocalUrl ?? ''
    configNfceForm.current = formModel
    return formModel;
  }, [documento, getConfigEmissor, getEmpresaSelecionada, hasId]);

  const preencherTela = useCallback(async () => {
    try {
      const configNfce = await getConfigNfce();
      setEmissorFormState(configNfce);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getConfigNfce, showToast]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        try {
          setPreenchendoTela(true);
          preencherTela();
        } catch (e: any) {
          showToast('error', e.message);
        } finally {
          setPreenchendoTela(false);
        }
      })();
      return () => {
        //funcao de limpeza
        setEmissorFormState(new ConfigNfceFormModel());
      };
    }, 300);
  }, [getConfigNfce, preencherTela, showToast]);

  const recarregarForm = useCallback((model: ConfigNfceFormModel) => {
    configNfceFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(emissorFormState);
  }, [recarregarForm, emissorFormState]);

  const saveChangesConfigNfce = useCallback(
    async (model: ConfigNfceFormModel) => {
      const index = model.pfxBase64.indexOf('https://');
      if (index === 0) {
        throw new Error('Precisa adicionar novamente o Certificado Digital para poder atualizar.')
      }

      const env = picker<PatchConfigEmissorProps>(model, new PatchConfigEmissorProps());
      const ret = await patchConfigEmissor(env, hasId && documento ? documento : getEmpresaSelecionada()?.Documento ?? '');
      if (ret.erro) {
        throw ret.erro;
      }

      invalidarConfiguracoes();

      setEmissorFormState(model);
      showToast('success', 'NFC-e atualizado com sucesso!');
      recarregarForm(model)
      preencherTela();
    },
    [documento, getEmpresaSelecionada, hasId, invalidarConfiguracoes, patchConfigEmissor, preencherTela, recarregarForm, showToast],
  );

  const handleDeleteConfigNfce = useCallback(async () => {
    try {
      const ret = await deleteConfigNfce(
        hasId ? documento || '' : getEmpresaSelecionada()?.Documento || '',
      );

      if (ret.erro) throw ret.erro;

      await refreshEmpresaFiscal();
      await preencherTela();

      showToast('success', 'Fiscal Deletado');
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [deleteConfigNfce, hasId, documento, getEmpresaSelecionada, refreshEmpresaFiscal, preencherTela, showToast]);

  const handleSubmit = useCallback(
    async (model: ConfigNfceFormModel, beforeModel: ConfigNfceFormModel) => {
      const configToEqual = isEqual(model, beforeModel);

      try {
        if (configToEqual) {
          showToast('info', 'Nenhuma informação atualizada.')
          return
        }

        await saveChangesConfigNfce(model);
        await refreshEmpresa();
        await refreshEmpresaFiscal();

      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [refreshEmpresa, refreshEmpresaFiscal, saveChangesConfigNfce, showToast],
  );

  const form = useMemo(() => {
    return (
      <div
        className={classNames(classesModal.contentForms)}
        style={{ height: '100%' }}
      >
        <FormConfigNfce
          ref={configNfceFormRef}
          onSubmit={handleSubmit}
          showLoading={false}
          loading={preenchendoTela || isPOS}
        />
        <div style={{ marginTop: 8 }}>
          <CardPropagandaUrlNfce />
        </div>
      </div>
    );
  }, [
    isPOS,
    classesModal.contentForms,
    handleSubmit,
    preenchendoTela,
  ]);

  const buttonSalvar = useMemo(() => {
    return (
      <div className={classesModal.acoes}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Button
              disabled={
                carregando ||
                emissorFormState.pfxBase64.length <= 1 ||
                isPOS
              }
              onClick={() =>
                confirm({
                  title: 'Desvincular NFC-e?',
                  description:
                    'Deseja realmente desvincular os dados de NFC-e? Obs: Para reconfigurá-lo, será necessário preencher todas as configurações novamente.',
                  cancellationText: 'Voltar',

                  cancellationButtonProps: {
                    color: 'default',
                  },
                  confirmationText: 'Desvincular',
                }).then(() => {
                  handleDeleteConfigNfce();
                })
              }
              variant="contained"
              color="secondary"
              className={classes.buttonCancelar}
              size="large"
              fullWidth
            >
              <CancelarIcon tipo="BUTTON_PRIMARY" />
              Desvincular
            </Button>
          </Grid>
          <Grid item xs={6} md={8}>
            <Button
              disabled={carregando || isPOS}
              onClick={() => configNfceFormRef.current?.submitForm()}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              <SalvarIcon tipo="BUTTON_PRIMARY" />
              Salvar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }, [
    isPOS,
    carregando,
    classes.buttonCancelar,
    classesModal.acoes,
    confirm,
    emissorFormState.pfxBase64.length,
    handleDeleteConfigNfce,
  ]);

  return (
    <AccordionSaurus
      labelPrimary={'Configurações de NFC-e'}
      tituloChildren={
        <NfceIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : (
            form
          )}
          {buttonSalvar}
        </div>
      </div>
    </AccordionSaurus>
  );
};
