import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { UploadCargaHeader } from './components/upload-carga-header/upload-carga-header';
import { UploadCargaList } from './components/upload-carga-list/upload-carga-list';
import { ButtonFab } from 'views/components/controles';
import { Grid } from '@material-ui/core';
import { useCadastros } from 'services/app';
import { UploadCargaIcon } from 'views/components/icons/upload-carga-icon';
import { useCallback } from 'react';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';

export const UploadCargaPage = () => {
  const classes = useDefaultCadastroStyles();
  const { abrirCadastroUploadCarga } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroUploadCarga('', window.location.pathname, true, EnumUploadCargaTipo.PESSOA);
  }, [abrirCadastroUploadCarga]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <UploadCargaHeader />
      </Grid>
      <Grid className={classes.list}>
        <UploadCargaList />
      </Grid>
      <ButtonFab
        tooltip="Adicionar Conversão de dados"
        icon={<UploadCargaIcon tipo="BUTTON_FAB" fill={'#fff'} />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default UploadCargaPage;
