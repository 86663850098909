import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { NovoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { Button, Grid } from "@material-ui/core";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { DefaultFormRefs } from "views/components/form/utils";
import { useToastSaurus, useCadastros, useSessaoAtual } from "services/app";
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";

import { picker } from "utils/picker";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";

import { guidEmpty } from "../../../../../../utils/guid-empty";
import classNames from "classnames";
import { PerfilEditFormModel } from "model/app/forms/perfil/perfil-edit-form-model";
import { FormPerfilEdit } from "views/components/form/perfil/form-perfil-edit/form-perfil-edit";
import { isEqual } from "lodash";
import { PerfilModel } from "model/api/gestao/perfil/perfil-model";
import { useGetPerfilById } from "data/api/gestao/perfil";
import { usePutPerfil } from "data/api/gestao/perfil/put-perfil";
import { PerfilEditPermissaoFormModel } from "model/app/forms/perfil/perfil-edit-permissao-form-model";
import { PutPerfilPermissaoProps, usePutPerfilPermissao } from "data/api/gestao/perfil/put-perfil-permissao";
// import { useGetCaixaPerfilById } from "data/api/gestao/caixa-perfil/get-caixa-perfil-id";
// import { CaixaPerfilPermissaoModel } from "model/api/gestao/caixa-perfil/caixa-perfil-permissao-model";
import { useGetPerfilHorario } from "data/api/gestao/perfil-horario";
import { PerfilHorarioModel } from "model/api/gestao/perfil-horario/perfil-horario-model";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import { isPlanoFarmaceutico } from "utils/plano-utils";
import { EnumGrupoPermissao } from "model/enums/enum-grupo-permissao";
import { PerfilPermissaoModel } from "model";

export const PerfilEdit = (props: { id: string }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const classes = useModalStyles();

  const { fecharCadastroPerfil, abrirCadastroPerfil } = useCadastros();
  const { getPerfilById, carregando: carregandoGet } = useGetPerfilById();
  // const { getCaixaPerfilById, carregando: carregandoGetCaixaPerfil } = useGetCaixaPerfilById();
  const { putPerfil, carregando: carregandoPut } = usePutPerfil();
  const { putPerfilPermissao, carregando: carregandoPutPermissao } = usePutPerfilPermissao();
  // const { putCaixaPerfilPermissao, carregando: carregandoPutCaixaPermissao } = usePutCaixaPerfilPermissao();
  const { getPerfilHorario, carregando: carregandoGetHorario } = useGetPerfilHorario()
  const { plano } = useSessaoAtual()

  const carregando =
    carregandoPut ||
    carregandoGet ||
    preenchendoTela ||
    carregandoPutPermissao ||
    // carregandoGetCaixaPerfil ||
    carregandoGetHorario;
  const [perfilFormState, setPerfilForm] = useState<PerfilEditFormModel>(
    new PerfilEditFormModel()
  );

  const refEditForm = useRef<DefaultFormRefs<PerfilEditFormModel>>(null);
  const refPerfilModel = useRef<PerfilModel>(new PerfilModel());
  const recarregarForm = useCallback((model: PerfilEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(perfilFormState);
  }, [perfilFormState, recarregarForm]);
  const isFarma = useMemo(()=>{
    return isPlanoFarmaceutico(plano?.plano)
  },[plano?.plano]) 
  const formatPerfil = useCallback((perfil: PerfilEditFormModel) => {
    //doideiras malucas abaixo
    //aqui eu trato no primeiro get lá para preencher o input
    perfil.contratoId =
      perfil.contratoId === null ? guidEmpty() : perfil.contratoId;

    return perfil;
  }, []);

  const retornaPermissoes = useCallback((listaPermissoes: PerfilPermissaoModel[]) => {
    if (isFarma) {
      let permissoesFarma = listaPermissoes.filter(permissao => permissao.grupoPermisao !== EnumGrupoPermissao.FOOD)
      let comandas = listaPermissoes.find(x => x.codigo === EnumCodigosPermissoes.MESAS_E_COMANDAS)
      let vitrine = listaPermissoes.find(x => x.codigo === EnumCodigosPermissoes.CARDAPIO)
      if (comandas) {
        comandas.titulo = 'Comandas'
        comandas.grupoPermisao = 'Gestão de Pedidos'
        comandas.descricao = 'Permissão Para Acessar O Módulo De Controle De Comandas.'
        permissoesFarma.push(comandas)
      }
      if(vitrine){
        vitrine.titulo = 'Vitrine'
        vitrine.grupoPermisao = 'Gestão de Pedidos'
        vitrine.descricao = 'Permissão Para Acessar O Módulo De Controle Da Vitrine.'
        permissoesFarma.push(vitrine)
      }
      return permissoesFarma
    }
    listaPermissoes.filter(modulo => modulo.grupoPermisao === EnumGrupoPermissao.FOOD ).map(x => x.grupoPermisao = 'Salão')
    return listaPermissoes.filter(permissao => permissao.grupoPermisao !== EnumGrupoPermissao.FARMACIAS)
  },[isFarma])

  const getPerfilByIdWapper = useCallback(async () => {
    const res = await getPerfilById(props.id);
    if (res.erro) {
      throw res.erro;
    }

    // const resCaixa = await getCaixaPerfilById(props.id);

    const ret = res.resultado?.data as PerfilModel;
    refPerfilModel.current = ret;

    let perfil = picker<PerfilEditFormModel>(ret, new PerfilEditFormModel());
    if (ret.permissoes) {
      perfil.permissoes = retornaPermissoes(ret.permissoes).reduce<Record<string, PerfilEditPermissaoFormModel[]>>((prev, curr) => {
        const obj = picker<PerfilEditPermissaoFormModel>(curr, new PerfilEditPermissaoFormModel());
        const prevKey = Object.keys(prev).find(item => item === curr.grupoPermisao)

        if (prevKey) {
          prev[prevKey].push(obj)
          return prev
        }

        return {
          ...prev,
          [curr.grupoPermisao]: [obj]
        }
      }, {})
    }

    // if (resCaixa.resultado?.data.opcoes) {
    //   perfil.permissao.pdv = (resCaixa.resultado?.data.opcoes as Array<CaixaPerfilPermissaoModel>).filter(x => x.descModulo === "Saurus PDV").map(cxperm => {
    //     return new PerfilEditPermissaoFormModel(cxperm.id, cxperm.codOpcao.toString(), cxperm.infOpcao, cxperm.descOpcao, cxperm.tpOpcao, cxperm.vPermissao, false);
    //   });
    // }

    return formatPerfil(perfil);
  }, [getPerfilById, props.id, formatPerfil, retornaPermissoes]);

  const preencherTela = useCallback(async () => {
    try {
      const perfil = await getPerfilByIdWapper();
      setPerfilForm({ ...perfil });
    } catch (e: any) {
      if (e.message)
        showToast("error", e.message);
    }
  }, [getPerfilByIdWapper, showToast]);

  useEffect(() => {
    (async () => {
      setPreenchendoTela(true);
      preencherTela();
      setPreenchendoTela(false);
    })();
    return () => {
      //funcao de limpeza
      setPerfilForm(new PerfilEditFormModel());
    };
  }, [preencherTela]);

  const getPerfilHorarioWrapper = useCallback(async (id: string) => {
    const res = await getPerfilHorario(id)

    if (res.erro) throw res.erro

    return res.resultado?.data as PerfilHorarioModel[]
  }, [getPerfilHorario])

  const saveChangesPerfil = useCallback(
    async (perfilModelForm: PerfilEditFormModel) => {
      let perfil = picker<PerfilModel>(
        perfilModelForm,
        refPerfilModel.current,
      );
      perfil.horarios = refPerfilModel.current.horarios
      const ret = await putPerfil(perfil);
      if (ret.erro) {
        throw ret.erro;
      }

    },
    [putPerfil]
  );

  const saveChangesPerfilPermissao = useCallback(
    async (permissoes: Array<PerfilEditPermissaoFormModel>, perfilId: string) => {
      const ret = await putPerfilPermissao(permissoes.map(p => new PutPerfilPermissaoProps(perfilId, p.codigo, p.valor)), perfilId);
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [putPerfilPermissao]
  );

  // const saveChangesCaixaPerfilPermissao = useCallback(
  //   async (permissoes: Array<PerfilEditPermissaoFormModel>, perfilId: string) => {
  //     const ret = await putCaixaPerfilPermissao(permissoes.map(p => new PutCaixaPerfilPermissaoProps(p.id, p.codigo, p.valor)), perfilId);
  //     if (ret.erro) {
  //       throw ret.erro;
  //     }
  //   },
  //   [putCaixaPerfilPermissao]
  // );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroPerfil("", "", true);
  }, [abrirCadastroPerfil]);

  const handleSubmit = useCallback(
    async (model: PerfilEditFormModel, beforeModel: PerfilEditFormModel) => {
      try {
        const perfilEqual = isEqual(model, beforeModel);
        if (!perfilEqual) {
          const horarios = await getPerfilHorarioWrapper(model.id)
          model.horarios = [...horarios] || []
          await saveChangesPerfil(model);
        }
        let pendentes_gestao = new Array<PerfilEditPermissaoFormModel>();
        let pendentes_pdv = new Array<PerfilEditPermissaoFormModel>();
        //CRIO UM OBJ APENAS COM OS ARRAYS
        Object.entries(model.permissoes).forEach((permissao) => {
          const result = Object.keys(permissao[1]).map((key) => permissao[1][key as any]);
          if (permissao[0].toString() !== "pdv") {
            pendentes_gestao = pendentes_gestao.concat(result.filter(x => x.alterado));
          } else {
            pendentes_pdv = pendentes_pdv.concat(result.filter(x => x.alterado));
          }
        });

        if (pendentes_gestao.length > 0) {
          await saveChangesPerfilPermissao(pendentes_gestao, model.id);
        }

        // if (pendentes_pdv.length > 0) {
        //   await saveChangesCaixaPerfilPermissao(pendentes_pdv, model.id);
        // }

        if (perfilEqual && pendentes_gestao.length === 0 && pendentes_pdv.length === 0) {
          showToast("info", "Nenhuma informação foi alterada");
        } else {
          showToast("success", "Perfil atualizado com Sucesso!", );
          preencherTela();
        }
      } catch (e: any) {
        showToast("error", e.message);
        refEditForm.current?.resetForm();
      }
    },
    [
      getPerfilHorarioWrapper,
      saveChangesPerfil,
      saveChangesPerfilPermissao,
      showToast,
      preencherTela
    ]
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroPerfil();
  }, [fecharCadastroPerfil]);


  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Edição Perfil de Acesso"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined
          )}
        >
          <FormPerfilEdit
            ref={refEditForm}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Novo Perfil
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar Perfil
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default PerfilEdit;
