import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import CardProdutoBusca from 'views/components/cards/card-produto-busca/card-produto-busca';

export interface ProdutoGridProps {
  list: Array<ProdutoResumidoModel>;
  carregando: boolean;
  onCardClicked: (produto: ProdutoResumidoModel) => void;
  onCardHolded: (produto: ProdutoResumidoModel) => void;
}

export const PesquisarProdutoListData = (props: ProdutoGridProps) => {
  const onCardClicked = (produto: ProdutoResumidoModel) => {
    props.onCardClicked(produto);
  };

  const onCardHolded = (produto: ProdutoResumidoModel) => {
    props.onCardHolded(produto);
  };

  return (
    <>
      {(props.list.length === 0 || props.carregando) && (
        <CardNaoEncontrado
          mensagem={props.carregando ? `Procurando produtos` : "Nenhum Produto encontrado."}
          icon={props.carregando
            ? <div
              style={{
                marginRight: 30,
                marginTop: 0,
                position: 'relative',
              }}
            > <CircularLoading tipo="ONAUTOCOMPLETE" />
            </div>
            : <InformacaoIcon tipo="GERAL" />}
        />
      )}
      {!props.carregando && props.list.length > 0 &&
        props.list.map((produto, i) => {
          return (
            <CardProdutoBusca
              onClick={onCardClicked}
              qtdeCarrinho={undefined}
              onHold={onCardHolded}
              model={produto}
              key={produto.id}
              index={i}
            />
          );
        })}
    </>
  );
};
