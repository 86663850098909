import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerCarrinhoVazio: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  cardCarrinhoVazio: {
    padding: 50,
    borderRadius: 10,
    maxWidth: 700,
    minWidth: 100,
    width: '100%',
    whiteSpace: 'normal',
    flex: 1,
    '& svg': {
      width: '180px',
      height: '180px',
      margin: theme.spacing(4),
      fill: theme.palette.primary.dark,
      [theme.breakpoints.down('sm')]: {
        width: '140px',
        height: '140px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '120px',
        height: '120px',
      },
    }
  },
  marginCard: {
    margin: theme.spacing(4)
  },
  textCenter: {
    textAlign: 'center'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '56px',
    width: '100%',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      lineHeight: '32px',
    },
  },
  textInfo: {
    fontSize: '36px',
    lineHeight: '36px',
    fontWeight: 'normal',
    color: theme.palette.primary.dark,
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    },
  }
}));
