import { Badge } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CardItemProps } from './card-item-tab-props';
import { useStyles } from './card-item-tab-styles';
import { useThemeQueries } from 'views/theme';
import { formatDecimalInteger } from 'utils/to-decimal';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Box, Button, Grid, Tooltip, Typography } from 'views/design-system';

export const CardItemTab = (props: CardItemProps) => {
  // STATES E REFS
  const [qtd, setQtd] = useState(0);

  // HOOKS
  const { addHandler, removeHandler } = useEventTools();

  const { isMobile } = useThemeQueries();
  const { model } = useMemo(() => props, [props]);
  const classes = useStyles({
    isMobile,
    location: props.path === model.path
  });

  const auxEvent = useCallback(
    () => ({
      '/produtos': {
        subscribe: () => {
          addHandler(AppEventEnum.QtdProdutos, setQtd);
        },
        unsubscribe: () => {
          removeHandler(AppEventEnum.QtdProdutos, setQtd);
        }
      },
      '/medicamentos': {
        subscribe: () => {
          addHandler(AppEventEnum.QtdMedicamentos, setQtd);
        },
        unsubscribe: () => {
          removeHandler(AppEventEnum.QtdMedicamentos, setQtd);
        }
      },
      '/principioAtivo': {
        subscribe: () => {
          addHandler(AppEventEnum.QtdPrincipioAtivo, setQtd);
        },
        unsubscribe: () => {
          removeHandler(AppEventEnum.QtdPrincipioAtivo, setQtd);
        }
      },
      '/varejo': {
        subscribe: () => {
          addHandler(AppEventEnum.QtdVarejo, setQtd);
        },
        unsubscribe: () => {
          removeHandler(AppEventEnum.QtdVarejo, setQtd);
        }
      },
      '/revista': {
        subscribe: () => {
          addHandler(AppEventEnum.QtdRevista, setQtd);
        },
        unsubscribe: () => {
          removeHandler(AppEventEnum.QtdRevista, setQtd);
        }
      }
    }),
    [addHandler, removeHandler]
  );

  useEffect(() => {
    auxEvent()[model.path].subscribe();

    return () => {
      auxEvent()[model.path].unsubscribe();
    };
  }, [addHandler, auxEvent, model.path, removeHandler]);

  return (
    <Grid item xs={props.isApenasUm ? 12 : 3} className={classes.container}>
      <Button
        className={classes.btnContent}
        variant="text"
        color="primary"
        classes={{
          label: classes.btnLabel,
          text: classes.btnText,
          root: classes.btnRoot
        }}
        onClick={() => {
          props.setPath(model.path);
        }}
      >
        <Box className={classes.fullWidth}>
          <Box flex justifyContent="center" className={classes.fullWidth}>
            <Tooltip arrow title={model.nome} placement="left">
              <Badge
                className={classes.badgeQtde}
                badgeContent={qtd && formatDecimalInteger(qtd)}
                color={
                  'error'
                }
              >
                {model.icon}
              </Badge>
            </Tooltip>
          </Box>
          <Typography align="center" color="textPrimary" className={classes.textPrimary}>
            {model.nome}
          </Typography>
        </Box>
      </Button>
    </Grid>
  );
};
