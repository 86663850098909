import { Grid } from 'views/design-system';
import { useStyles } from './splash-screen-styles'
import { PublicPageHeader } from '../headers';
import { consoleDev } from 'utils/console-dev';

interface SplashScreenProps {
    imagem: string
    alt: string
}

export const SplashScreen = ({ imagem, alt }: SplashScreenProps) => {
    const classes = useStyles();

    return (
        <Grid className={classes.splashscreen}>
            <PublicPageHeader topOpacity='0.8' />
            <img className={classes.imagem} src={imagem} alt={alt} onError={(e) => consoleDev(e)} />
        </Grid>
    )
}