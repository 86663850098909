import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useRef } from 'react';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { Box, Button } from 'views/design-system';
import { FormPagerIdentificador } from 'views/components/form/pager-identificador/form-pager-identificador';
import { FormIdentificadorModel } from 'model/api/gestao/pedido/pedido-dados-model';
import { useToastSaurus } from 'services/app';
import { useStyles } from './gerar-pager-identificador-styles';
import { CloseIconCircle } from 'views/components/icons/close-icon-circle';
import { useThemeQueries } from 'views/theme';
import { usePDV } from 'services/app/hooks/pdv';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { OkIcon } from 'views/components/icons';
import { useMovRota } from 'services/app/hooks/mov-rota';


export const GerarPagerIdentificador = () => {
    const classes = useStyles();

    const { getMov, setIdentificadorPager, setSalaoId } = useMovAtual();
    const { getPDV, getConfigByCod } = usePDV();
    const { showToast } = useToastSaurus()
    const { theme } = useThemeQueries()
    const formRef = useRef<DefaultFormRefs<FormIdentificadorModel>>(null);
    const salaoIdref = useRef<string>('');
    const clientePadrao = useCadastroPadrao()
    const { avancarFluxoMov } = useMovRota();

    const handleSubmitPedidoComItem = useCallback(async (model: FormIdentificadorModel) => {
        try {
            await setIdentificadorPager(model.identificador)
            await setSalaoId(model?.salaoId ?? "")
            await avancarFluxoMov();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [avancarFluxoMov, setIdentificadorPager, setSalaoId, showToast])

    const handleSubmitPedidoSemItem = useCallback(async () => {
        try {
            await setIdentificadorPager('')
            await setSalaoId(salaoIdref.current)
            await avancarFluxoMov();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [avancarFluxoMov, setIdentificadorPager, setSalaoId, showToast])

    useEffect(() => {
        const mov = getMov()
        if (mov) {
            formRef.current?.fillForm({
                identificador: (mov.clienteIdentificado && !clientePadrao)
                    || mov.cliente?.nome.toUpperCase() !== "CONSUMIDOR"
                    ? mov.cliente?.nome ?? ''
                    : "",
                salaoId: ''
            })
        }
    }, [clientePadrao, getConfigByCod, getMov, getPDV])

    return (
        <Box className={classes.container} flex alignItems='center' justifyContent='center'>
            <Box className={classes.cardContainer}>
                <Box className={classes.cardContent}>
                    <FormPagerIdentificador
                        loading={false}
                        onSubmit={handleSubmitPedidoComItem}
                        showLoading={false}
                        ref={formRef}
                        salaoIdref={salaoIdref}
                    />

                    <Box className={classes.buttonContainer}>
                        <Box>
                            <Button
                                fullWidth
                                color='primary'
                                variant='outlined'
                                onClick={() => {
                                    handleSubmitPedidoSemItem()
                                }}
                                className={classes.submitButton1}
                            >
                                <CloseIconCircle tipo='BUTTON' fill={theme.palette.primary.main} />
                                Não Identificar
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                fullWidth
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                    formRef.current?.submitForm()
                                }}
                                className={classes.submitButton2}
                            >
                                <OkIcon tipo='BUTTON_PRIMARY' fill={theme.palette.common.white} />
                                Confirmar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
};