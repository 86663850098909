import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { DadosImpressaoAPI } from "model/api/gestao/impressora/impressao-local-model";

export function usePostEquipamentoImpressao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postEquipamentoImpressao = useCallback(
    (impressao: DadosImpressaoAPI, empresaId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/impressao`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(impressao),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postEquipamentoImpressao,
  };
}
