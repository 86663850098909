import { Container } from "@material-ui/core"
import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { useStyles } from "./dados-entrada-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useRef, useState } from "react"
import { AvancarIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { InfMovModel, VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useToastSaurus } from "services/app"
import classNames from "classnames"
import { FormDadosEntrada } from "views/components/form/entrada/dados-entrada/form-dados-entrada"
import { DefaultFormRefs } from "views/components/form/utils"
import { picker } from "utils/picker"
import { DadosEntradaFormModel } from "model/app/forms/entrada/dados-entrada-form-model"
import { isEqual } from "lodash"
import { toDateString } from "utils/to-date"
import { stringNumeros } from "utils/string-numeros"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"
import { Button, Grid } from "views/design-system"

const DadosEntradaPage = () => {
    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const formRef = useRef<DefaultFormRefs<DadosEntradaFormModel>>(null)


    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const [modelForm, setModelForm] = useState<DadosEntradaFormModel>(new DadosEntradaFormModel())
    const [preenchendoTela, setPreenchendoTela] = useState<boolean>(true)
    const { getVendaCompleta, saveChangesVendaCompleta, carregando: carregandoEntrada } = useEntrada(mov)

    const carregando = [carregandoEntrada, preenchendoTela].includes(true)

    const recarregarForm = useCallback((model: DadosEntradaFormModel) => {
        formRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(modelForm)
    }, [modelForm, recarregarForm])

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        let movAtual = new VendaCompletaModel()
        if (location.state?.mov && !att) {
            movAtual = location.state.mov
        } else {
            movAtual = await getVendaCompleta(movId)
        }
        const infMov = { ...movAtual.infMov }
        const infMovForm = picker<DadosEntradaFormModel>(infMov, new DadosEntradaFormModel())
        infMovForm.origemEmissao = movAtual.origemEmissao
        infMovForm.depositoId = movAtual.depositoId
        infMovForm.idNFe = movAtual.idNFe ?? ''
        infMovForm.dhEmi = toDateString(infMovForm.dhEmi, 'yyyy-MM-DDTHH:mm') || ''
        infMovForm.dhSaiEnt = infMovForm.dhSaiEnt ? toDateString(infMovForm.dhSaiEnt, 'yyyy-MM-DDTHH:mm') || '' : ''
        setMov(movAtual)

        return infMovForm;
    }, [getVendaCompleta, location.state?.mov, movId])

    const preencherTela = useCallback(async (att: boolean = false) => {
        try {
            setPreenchendoTela(true);
            const entrada = await getInfoVenda(att);
            setModelForm(entrada);
        } catch (e: any) {
            showToast('error', e.message);
        }
        finally {
            setPreenchendoTela(false);
        }
    }, [getInfoVenda, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                search: location.search,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto

                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.search, location.state, mov, movId]
    );

    useEffect(() => {
        preencherTela()
    }, [preencherTela])

    const proximaTela = useCallback((att: boolean = false) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/destinatario`,
        search: location.search,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto
        }
    }), [history, location.search, location.state, mov, movId])

    const handleSubmit = useCallback(async (model: DadosEntradaFormModel, beforeModel: DadosEntradaFormModel) => {
        try {
            const movAtual = structuredClone(mov)
            const modelEqual = isEqual(model, beforeModel)

            if (modelEqual) {
                showToast('info', "Nenhuma informação alterada")
                proximaTela()
                return;
            }
            const modelMov = picker<InfMovModel>(model, mov.infMov)
            movAtual.idNFe = model.idNFe
            movAtual.origemEmissao = model.origemEmissao
            movAtual.infMov = modelMov
            movAtual.infMov.pag = mov.infMov.pag
            movAtual.infMov.prod = mov.infMov.prod
            movAtual.infMov.nFref = mov.infMov.nFref || []
            movAtual.infMov.receitasMedicas = mov.infMov.receitasMedicas
            movAtual.infMov.transp.vol = mov.infMov.transp.vol
            movAtual.infMov.transp.reboque = mov.infMov.transp.reboque
            if (mov.infMov.cobr) {
                movAtual.infMov.cobr = mov.infMov.cobr
            }
            movAtual.depositoId = model.depositoId
            movAtual.infMov.infIntermediador = {
                cnpj: model.infIntermediador.cnpj ? stringNumeros(model.infIntermediador.cnpj) : null,
                idCadIntTran: model.infIntermediador.idCadIntTran || null
            }

            await saveChangesVendaCompleta(movAtual)
            showToast('success', 'Dados da Entrada atualizados com sucesso!')
            proximaTela(true)
        } catch (error: any) {
            showToast('error', error.message)
        }
    }, [mov, proximaTela, saveChangesVendaCompleta, showToast])

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Dados da Entrada"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <FormDadosEntrada
                            loading={carregando}
                            onSubmit={handleSubmit}
                            showLoading={false}
                            ref={formRef}
                            mov={mov}
                        />
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => formRef.current?.submitForm()}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default DadosEntradaPage