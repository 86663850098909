import { Box } from "views/design-system";
import classNames from "classnames";
import { useCallback, useRef } from "react";
import { useCadastros, useToastSaurus } from "services/app";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { VoltarIcon } from "views/components/icons";
import { CircularLoading } from "views/components/utils";
import { ModalHeader } from "../../components";
import { useModalStyles } from "../../utils/modal-styles";
import { useStyles } from "../promocao-edicao/promocao-edicao-styles";
import { PromocaoDados } from "../components/promocao-dados/promocao-dados";
import { usePromocaoDadosStore } from "../components/promocao-dados/hooks/use-promocao-dados-store";
import { usePostPromocao } from "data/api/gestao/promocao/post-promocao";
import { useHistory } from "react-router-dom";

export const PromocaoCadastro = () => {
    const modalClasses = useModalStyles();
    const classes = useStyles();
    const { fecharCadastroPromocao, abrirCadastroPromocao } = useCadastros()
    const {
        resetStorePromocao
    } = usePromocaoDadosStore((store) => store);

    const { showToast } = useToastSaurus();
    const { carregando: carregandoPost, postPromocao } = usePostPromocao();

    const redirect = useRef<boolean>(true);
    const history = useHistory();
    const pathName = history.location.pathname;

    const cancelarCadastro = useCallback(async () => {
        resetStorePromocao();
        fecharCadastroPromocao();
        return true;
    }, [fecharCadastroPromocao, resetStorePromocao]);

    const confirmarCadastro = useCallback(async () => {
        try {
            const promocao = usePromocaoDadosStore.getState().state.promocao;
            const res = await postPromocao(promocao);
            if (res.erro) throw res.erro;

            resetStorePromocao();

            if (redirect.current) {
                abrirCadastroPromocao(res.resultado?.data.id, pathName, true);
            } else {
                resetStorePromocao();
            }
            if (!redirect.current) {
                showToast("success", "Campanha adicionada com sucesso!", );
            }
            return true;
        } catch (e: Error | any) {
            showToast('error', e.message);
            return false;
        }
    }, [abrirCadastroPromocao, pathName, postPromocao, resetStorePromocao, showToast]);

    const carregando = carregandoPost;

    return (
        <>
            <Box className={classNames(modalClasses.root, classes.root)}>
                <ModalHeader
                    title='Cadastro de Promoção'
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={() => {
                                cancelarCadastro();
                            }}
                        />
                    }
                />
                <Box className={classNames(modalClasses.content, classes.relative)}>
                    {carregando && (
                        <Box className={classes.loadingContainer}>
                            <CircularLoading tipo="FULLSIZED" />
                        </Box>
                    )}
                    <PromocaoDados
                        cadastroDeNovaPromocao
                        cancelarCadastro={cancelarCadastro}
                        confirmarCadastro={confirmarCadastro}
                    ></PromocaoDados>
                </Box>
            </Box>
        </>
    );
};


