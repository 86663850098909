import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';

import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { FormNomePreCadastroProps } from './nome-identificacao-props';
import { IdentificacaoNomePreCadastroFormModel } from 'model/app/forms/mov-identificacao/identificacao-nome-pre-cadastro-form-model';
import { useStyles } from './nome-identificacao-styles';

export const FormNomePreCadastro = forwardRef<
    DefaultFormRefs<IdentificacaoNomePreCadastroFormModel>,
    FormNomePreCadastroProps
>(({ loading, ...props }: FormNomePreCadastroProps, ref) => {


    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const classes = useStyles()
    const [modelState, setModelState] = useState<IdentificacaoNomePreCadastroFormModel>()

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
    } = useForm<IdentificacaoNomePreCadastroFormModel>({
        defaultValues: modelState,
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: IdentificacaoNomePreCadastroFormModel) => {
        const model = picker<IdentificacaoNomePreCadastroFormModel>(
            values,
            new IdentificacaoNomePreCadastroFormModel(),
        );

        props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            setTimeout(() => {
                if (!isMobile) refInputNome.current?.focus();
            }, 500)
            reset();
        },
        fillForm: (model: IdentificacaoNomePreCadastroFormModel) => {
            reset({ ...model })
            setModelState(model)
        },
    }));

    useEffect(() => {
        if (!isMobile) refInputNome.current?.focus();
    }, [isMobile])

    return (
        <>
            <Grid className={utilClasses.formContainer} flex alignItems='center' justifyContent='center'>
                {loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Box p={3}>
                        <Grid container >
                            <Grid item xs={12}>
                                <Typography className={classes.title} variant="h5" align='center'>
                                    Informe o Nome
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.contentInput}>
                                <Controller
                                    name="nome"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            allowSubmit
                                            inputRef={refInputNome}
                                            removeBackground
                                            className={classes.input}
                                            disabled={loading}
                                            variant="standard"
                                            placeholder={"Aguardando Digitação"}
                                            classes={{
                                                root: classes.root
                                            }}
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                            error={Boolean(errors.nome && errors.nome.message)}
                                            helperText={
                                                touchedFields.nome || errors.nome
                                                    ? errors.nome?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => {
                                                props.nomeRef.current = event.target.value;
                                                field.onChange(event)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </Grid>
        </>
    );
},
);
