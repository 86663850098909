import { Grid, Typography } from 'views/design-system';
import { CardHistoricoVendaProps } from './card-historico-venda-props';
import { useCallback } from 'react';

import { DefaultCard } from 'views/components/cards/components';
import { Styles } from './card-historico-venda-styles';
import { useThemeQueries } from 'views';
import { EnumHistoricoVendaStatus } from 'model/enums/enum-historico-venda-status';
import { StatusHistoricoVendaMock } from 'data/mocks/status-historico-venda-mock';
import { TpHistoricoVendaMock } from 'data/mocks/tp-historico-venda-mock';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { toDateString } from 'utils/to-date';

export const CardHistoricoVenda = ({
  model,
  onClick,
  onCheck,
  selected,
  consumidorPadrao
}: CardHistoricoVendaProps) => {
  const classes = Styles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumHistoricoVendaStatus.Pendente:
        return theme.palette.warning.main;
      case EnumHistoricoVendaStatus.Finalizado:
        return theme.palette.success.main;
      case EnumHistoricoVendaStatus.Cancelado:
      case EnumHistoricoVendaStatus.Abortado:
      case EnumHistoricoVendaStatus.Rejeitado:
        return theme.palette.error.main;
      case EnumHistoricoVendaStatus.EmDigitacao:
        return theme.palette.warning.dark;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.primary.main,
    model.status,
    theme.palette.error.main,
    theme.palette.warning.dark,
  ]);

  const descricao = StatusHistoricoVendaMock.filter(
    (x) => x.Key === model.status,
  )[0]?.Value;

  const tpStatus = TpHistoricoVendaMock.filter((x) => x.Key === model.mod)[0]
    ?.Value;

  const isConsumidorPadrao = model.destDoc === consumidorPadrao.cpfcnpj

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () =>
          onClick(model.id)
        }
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid} style={{ width: 200 }}>
            <Typography color="textPrimary" variant="caption">
              Dados do cliente
            </Typography>

            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{isConsumidorPadrao ? consumidorPadrao.nome : model.destNome}</strong>
            </Typography>
          </div>

          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Itens:
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {formatDecimalInteger(model.totalQtCom, 3)}
            </Typography>
          </div>

          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Data:
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {
                toDateString(model.dhEmi, 'DD/MM/yyyy HH:mm')
              }
            </Typography>
          </div>

          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Número:
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.nnf === 0 || !model.nnf ? `- /${model.serie}` : `${model.nnf}/${model.serie}`}
            </Typography>
          </div>

          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Valor total:
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{toDecimalString(model.totalVNf, 2)}</strong>
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Grid className={classes.containerStatus}>
              <div
                style={{
                  display: 'flex',
                  padding: theme.spacing(0.5),
                  marginRight: theme.spacing(0.5),
                  borderRadius: 4,
                  justifyContent: 'center',
                  background: theme.palette.primary.main,
                }}
              >
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                  style={{ color: '#fff' }}
                >
                  {tpStatus}
                </Typography>
              </div>

              <div
                style={{
                  display: 'flex',
                  padding: theme.spacing(0.5),
                  borderRadius: 4,
                  justifyContent: 'center',
                  background: retornarCorStatus(),
                }}
              >
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                  style={{ color: '#fff' }}
                >
                  {descricao}
                </Typography>
              </div>
            </Grid>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
