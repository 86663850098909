import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: "auto",
    width: '100%',
    background: "#fff",
    boxShadow: theme.shadows[2],
    padding: 4,
    paddingBottom: 0,
  },
  listCatalogo: {
    flex: "1 1 100%",
    overflowX: "hidden",
    overflow: "auto"
  },
  categoriasList: {
    overflowX: 'auto',
  },
  categoriasListLinha: {
    display: 'grid',
    paddingBottom: 4,
  },
  categoriaFavoritos: {
    cursor: "pointer",
    margin: '2px',
    width: 55,
    height: 55,
    minHeight: 55,
    maxHeight: 55,
    borderTop: "1px solid  rgba(0,0,0,0.0)",
    border: "1px solid  rgba(0,0,0,0.0)",
    borderBottom: "3px solid rgba(0,0,0,0.0)",

    "& svg": {
      width: 34,
      height: 34,
      marginTop: 10,
      marginLeft: 10
    }
  },
  categoriaOutros: {
    cursor: "pointer",
    margin: '2px',
    width: 55,
    height: 55,
    minHeight: 55,
    maxHeight: 55,
    borderTop: "1px solid  rgba(0,0,0,0.0)",
    border: "1px solid  rgba(0,0,0,0.0)",
    borderBottom: "3px solid rgba(0,0,0,0.0)",

    "& svg": {
      width: 34,
      height: 34,
      marginTop: 8,
      marginLeft: 9
    }
  },

  categoria: {
    cursor: "pointer",
    position: 'relative',
    width: "auto",
    margin: '2px',
    color: "#000",
    fontWeight: 600,
    padding: 0,
    minHeight: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    fontSize: 10,
    textAlign: 'center',
    borderTop: "1px solid  rgba(0,0,0,0.0)",
    border: "1px solid  rgba(0,0,0,0.0)",
    borderBottom: "3px solid rgba(0,0,0,0.0)",
  },
  paragraph: {
    zIndex: 1
  },
  categoriaSelecionada: {
    borderColor: "rgba(0,0,0,0.4)",
    backgroundColor: `${theme.palette.primary.main}30`,
    fontWeight: 'bold',
  },
  image: {
    opacity: 0.3,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'cover',
    zIndex: 0,
  }
}));