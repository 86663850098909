import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { SaloesModal } from "views/components/modals/saloes/saloes-modal";

export const CadastroSaloesModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: ''
    });

    const saloesRoute = `/saloes/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroSaloesModal')

    useEffect(() => {
        if (location.pathname.indexOf(saloesRoute) === 0) {
            const posrota = location.pathname.replace(saloesRoute, '');
            if (posrota.length > 0) {
                setModalState({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: saloesRoute
                });
                return;
            }
        }
    }, [location.pathname, saloesRoute]);

    const modalAlterado = useCallback(async ({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? saloesRoute : callbackUrl;
        if (!openned) {
            push(url)
        }
        if (trocarUrl) {
            push(saloesRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }

        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url
        });

    }, [saloesRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.SaloesModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.SaloesModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return <SaloesModal openned={modalState.aberto} key="mdlSaloes" id={modalState.id} />
}