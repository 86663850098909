import classNames from 'classnames';
import { useStyles } from './card-modelo-apuracao-styles'
import { Button, Typography } from 'views/design-system';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';

interface CardModeloApuracaoProps {
    icone: JSX.Element
    label: string
    type: number
    onClick: (type: number, event: any) => void
    value: number | null
    description: string
}

export const CardModeloApuracao = ({ icone, label, onClick, type, value, description }: CardModeloApuracaoProps) => {
    const classes = useStyles();

    const handleClickApuracao = (e: any) => {
        onClick(type, e);
    }

    return (
        <Button fullWidth id="submitButton" className={classNames(classes.container, value === type && classes.border)} onClick={handleClickApuracao}>
            <div className={classes.radio}>
                <SaurusRadio checked={value === type} />
            </div>
            <div>
                {icone}
                <Typography className={classes.label}>{label}</Typography>
                <Typography className={classes.description}>{description}</Typography>
            </div>
        </Button>
    )
}