
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { ConvenioSolicitarCreditoPostModel } from "model/api/gestao/convenio";

export function usePostConvenioSolicitarCredito() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postConvenioSolicitarCredito = useCallback(
        (solicitacao: ConvenioSolicitarCreditoPostModel, empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/crediario/solicitar-credito`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(solicitacao),
                enviarTokenUsuario: true,
                timeout: 90 * 1000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postConvenioSolicitarCredito,
    };
}
