
import { guidEmpty } from "utils/guid-empty";

export class DepositoModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public nome: string = '',
        public nfReferenciadaNaVenda: string = '',
    ) { }
}
