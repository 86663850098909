import { EnumTpAutenticacaoConvenio } from "model/enums/enum-tp-autenticacao-convenio";
import { DefaultModal } from "../components"
import { ModalProps } from "../utils";
import { ConvenioResumoFaturas } from "./components/convenio-resumo-faturas/convenio-resumo-faturas";

interface ConvenioResumoFaturasModalProps extends ModalProps {
    openned: boolean;
    pessoaId: string;
    tipo?: EnumTpAutenticacaoConvenio
    anoMesSelecionado?: number
}

export const ConvenioResumoFaturasModal = (props: ConvenioResumoFaturasModalProps) => {

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && <ConvenioResumoFaturas anoMesSelecionado={props.anoMesSelecionado} tipo={props.tipo} pessoaId={props.pessoaId} />}
        </DefaultModal>
    )
}