import {
  Comissionado,
  useComissionadosStore
} from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { useStyles } from './preview-gerentes-gerais-styles';
import { useConfirm } from 'material-ui-confirm';
import { AddIcon } from 'views/components/icons/add-icon';
import { Button, Grid } from 'views/design-system';
import CardPessoaPreview from 'views/components/cards/card-pessoa-preview/card-pessoa-preview';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { CardNaoEncontrado } from 'views/components/cards';
import { DesfazerIcon, InformacaoIcon, LixoIcon } from 'views/components/icons';
import { useCallback, useMemo, useState } from 'react';
import { RadioSelecionadoTodosIcon } from 'views/components/icons/radio-selecionado-todos-icon';
import { Paginacao } from 'views/components/paginacao';
import classNames from 'classnames';
import { CheckboxDefault } from 'views/components/controles/checkboxes';

interface PreviewGerentesGeraisProps {
  setDel: (value: boolean) => void;
  setAddGerenteGeral: () => void;
  handleRemove: (value: Comissionado[]) => Promise<boolean>;
}

export const PreviewGerentesGerais = ({
  setDel,
  setAddGerenteGeral,
  handleRemove
}: PreviewGerentesGeraisProps) => {
  const confirm = useConfirm();
  const [, setAtt] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<Comissionado[]>([]);
  const [isModeSelect, setIsModeSelect] = useState<boolean>(false);

  const comissionadoGerenteGeral = useComissionadosStore(
    (store) => store.state.comissionadoGerenteGeral
  );

  const classes = useStyles();

  const handleRemoveGerentesGerais = () => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: 'Excluir Gerentes Gerais',
      description: 'Deseja excluir os gerentes gerais?',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(async () => {
      const res = await handleRemove(selectedList);
      if (res) {
        setIsModeSelect(false);
        setAtt((prev) => !prev);
        if (comissionadoGerenteGeral.length - 1 <= 0) {
          setAddGerenteGeral();
        }
      }
    });
  };

  const onCardSelected = useCallback((comissionado: Comissionado) => {
    setSelectedList((prev) => {
      if (prev.some((x) => x.id === comissionado.id)) {
        return prev.filter((x) => x.id !== comissionado.id);
      }

      return [...prev, comissionado];
    });
  }, []);

  const renderTopContent = useMemo(() => {
    const todosSelecionados =
      comissionadoGerenteGeral.findIndex(
        (x) => selectedList.findIndex((y) => y.id === x.id) === -1
      ) === -1;
    return (
      <Grid flex justifyContent="space-between" flexDirection="row">
        {comissionadoGerenteGeral.length > 0 && (
          <Grid
            onClick={() => {
              setSelectedList([]);
              setIsModeSelect((prev) => !prev);
            }}
            className={classes.botaoSelecionar}
          >
            {isModeSelect ? (
              <>
                <DesfazerIcon tipo="BUTTON_PRIMARY" />
                Desfazer
              </>
            ) : (
              <>
                <RadioSelecionadoTodosIcon tipo="BUTTON_PRIMARY" />
                Selecionar Gerentes
              </>
            )}
          </Grid>
        )}
        <Paginacao
          className={classes.paginacao}
          pageChanged={() => {}}
          totalPages={1}
          totalRegisters={comissionadoGerenteGeral.length}
          currentPage={1}
        />
        {isModeSelect && (
          <Grid
            onClick={() => {
              if (todosSelecionados) setSelectedList([]);
              else setSelectedList(comissionadoGerenteGeral);
            }}
            className={classNames(classes.botaoSelecionar, classes.check)}
            style={{ marginLeft: 8, marginRight: 0 }}
          >
            Selecionar Todos
            <CheckboxDefault
              style={{ margin: -8 }}
              checked={todosSelecionados}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [
    classes.botaoSelecionar,
    classes.check,
    classes.paginacao,
    comissionadoGerenteGeral,
    isModeSelect,
    selectedList
  ]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {renderTopContent}

        <div className={classes.list}>
          <div className={classes.listContainer}>
            {comissionadoGerenteGeral.length === 0 && (
              <CardNaoEncontrado
                icon={<InformacaoIcon tipo="GERAL" />}
                mensagem="Gerentes Gerais não encontrado"
              />
            )}
            {comissionadoGerenteGeral.length > 0 &&
              comissionadoGerenteGeral
                .filter((gg) => !gg.removido)
                .map((comissionado) => {
                  return (
                    <CardPessoaPreview
                      tipo={EnumTpPessoaComissionamento.GerenteGeral}
                      onAltera={() => {}}
                      onSelect={onCardSelected}
                      model={comissionado}
                      selecionar={isModeSelect}
                      selecionado={selectedList.some(
                        (x) => x.id === comissionado.id
                      )}
                    />
                  );
                })}
          </div>
        </div>
      </div>
      <div className={classes.acoes}>
        {isModeSelect ? (
          <Button
            variant="contained"
            fullWidth
            color="error"
            disabled={selectedList.length <= 0}
            onClick={() => {
              handleRemoveGerentesGerais();
            }}
          >
            <LixoIcon tipo="BUTTON_PRIMARY" />
            Remover gerentes gerais
          </Button>
        ) : (
          <Button
            variant="outlined"
            fullWidth
            style={{ marginTop: 16 }}
            color="primary"
            onClick={() => {
              setDel(false);
              setAddGerenteGeral();
            }}
          >
            <AddIcon tipo="BUTTON" />
            Adicionar gerente geral
          </Button>
        )}
      </div>
    </div>
  );
};
