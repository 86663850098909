import { Grid, Typography } from 'views/design-system';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useStyles } from './teclado-mesa-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

interface TecladoMesaFragmentProps {
  textChanged: (text: string, formattedText: string) => Promise<boolean>
}

export const TecladoMesaFragment = (props: TecladoMesaFragmentProps) => {
  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')
  const valueRef = useRef<HTMLParagraphElement | null>(null);

  const { addHandler, removeHandler } = useEventTools()
  const {
    getFormattedText,
    sendText,
    resetText,
    backSpaceKey
  } = useKeyboard({
    maxLength: 9,
    floatCases: 0,
    handleTextChanged: props.textChanged,
    isNumeric: false,
    digitado: digitado,
    textoAtual: textoAtual
  });

  const classes = useStyles();

  const attValueDisplay = useCallback((any: any) => {
    const v = getFormattedText();
    if (valueRef.current) valueRef.current.textContent = v;
  }, [getFormattedText])

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
  }, [addHandler, attValueDisplay, removeHandler])

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleEscape = useCallback(() => {
    resetText('');
  }, [resetText]);

  const teclado = useMemo(() => {
    return (
      <Grid container style={{ flex: 1 }}>
        <Keyboard
          isButtonBackspace
          handleBackSpace={handleBackSpace}
          handleText={handleText}
          handleEscape={handleEscape}
          isNumeric
        />
      </Grid>
    );
  }, [handleBackSpace, handleEscape, handleText]);

  return (
    <>
      <Grid container flexDirection="column" className={classes.container}>
        <Grid className={classes.middleContainer}>
          <Grid container className={classes.middleContent}>
            <Grid item>
              <div className={classes.valorContainer}>
                <div className="valorMonetario">
                  <Typography className="valor" ref={valueRef} variant="h4">
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {teclado}
      </Grid>
    </>
  );
};
