import { useCallback, useEffect, useMemo } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { ProdutoHeader } from './components/produto-header/produto-header';
import { ProdutoList } from './components/produto-list/produto-list';
import { ButtonFab } from 'views/components/controles';
import {
  useCadastros,
  useSessaoAtual,
} from 'services/app';
import { ProdutoAddIcon } from 'views/components/icons/produto-add-icon';
// import { CardControleCotaProdutos } from 'views/components/cards/card-controle-cota-produtos';
// import { isPlanoFiscal } from 'utils/plano-utils';
import { useToastSaurus } from 'services/app';
import { useHistory } from 'react-router-dom';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { Grid } from 'views/design-system';
import React from 'react';

export type ProdutoPageProps = {
  tipo: EnumTipoProduto
}

export const produtoPageNome = (tipo: EnumTipoProduto, plural: boolean = true, isFarmaceutico: boolean = false) => {
  switch (tipo) {
    case EnumTipoProduto.Combo:
      return isFarmaceutico ? `Kit${plural ? 's' : ''} e Combo${plural ? 's' : ''}` : `Combo${plural ? 's' : ''}`
    case EnumTipoProduto.Insumo:
      return `Insumo${plural ? 's' : ''}`
    case EnumTipoProduto.Produto:
      return `Produto${plural ? 's' : ''}`
    case EnumTipoProduto.Medicamento:
      return `Medicamento${plural ? 's' : ''}`
    default:
      return `Produto${plural ? 's' : ''}`
  }
}

export const produtoPageUrl = () => {
  const pathname = window.location.pathname;

  if (pathname.indexOf('/insumos') > -1) return '/insumos'
  if (pathname.indexOf('/combos') > -1) return '/combos'

  return '/produtos'
}

const ProdutoPage = ({ tipo }: ProdutoPageProps) => {
  const classes = useDefaultCadastroStyles();
  const { plano } = useSessaoAtual();
  const isFarmaceutico = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano]);
  const { showToast } = useToastSaurus();
  // const isFiscal = isPlanoFiscal(plano?.plano);

  const { abrirCadastroProduto } = useCadastros();
  const { location } = useHistory();
  const { callTour } = useTourSaurus()

  useEffect(() => {
    callTour(EnumTour.CADASTROPRODUTO)
  }, [callTour])

  const onClickAdd = useCallback(() => {
    try {
      let url = location.pathname;
      if (url[url.length - 1] === '/') {
        //removendo "/" final da URL caso tenha para não bugar as rotas
        url = url.substring(0, url.length - 1);
      }
      if (url.split('').filter(x => x === '/').length > 1) {
        url = '/' + url.split('/')[1]
      }
      abrirCadastroProduto('', tipo, url, true);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [abrirCadastroProduto, location, showToast, tipo]);

  const component = useMemo(() => <>
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <ProdutoHeader
          tipo={tipo}
          isFarmaceutico={isFarmaceutico}
        />
      </Grid>
      <Grid className={classes.list}>
        <ProdutoList tipo={tipo} />
      </Grid>
      <ButtonFab
        id='tour-cadProduto'
        tooltip={`Adicionar ${produtoPageNome(tipo, false, isFarmaceutico)}`}
        icon={<ProdutoAddIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
      {/* {!isFiscal && (
      <Grid style={{ zIndex: 1 }}>
        <CardControleCotaProdutos />
      </Grid>
    )} */}
    </Grid>
  </>, [classes.header, classes.list, classes.root, isFarmaceutico, onClickAdd, tipo]);

  return component;
};

export default React.memo(ProdutoPage);
