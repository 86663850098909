import { useCallback } from "react";

export function useKeyDownHandler() {

    const handleKeyDown = useCallback((
        e: KeyboardEvent,
        actualValue: string,
        allowNumbers: boolean = true,
        allowChars: boolean = true,
        allowSpecialChars: boolean = true,
        floatCase: number = 0,
        maxLenght: number = 7
    ) => {
        const char = e.key;
        const bkpValue = actualValue;
        if (!allowSpecialChars) {
            actualValue = actualValue.replace(',', '').replace('.', '');
        }

        let newValue = actualValue;

        if (char === 'Space') {
            newValue = actualValue + ' ';
        } else if (char === 'Backspace') {
            if (actualValue?.length > 0)
                newValue = actualValue.substring(0, actualValue?.length - 1);
        }
        else if (char?.length === 1) {
            let concatena = false;
            if (allowNumbers && !isNaN(parseInt(char))) {
                concatena = true;
            }
            if (allowChars && char.search(/[a-zA-Z]+/) > -1) {
                concatena = true
            }
            if (allowSpecialChars && char.search(/[a-zA-Z]+/) === -1 && isNaN(parseInt(char))) {
                concatena = true;
            }

            if (concatena) {
                newValue = actualValue + char;
            }
        }

        if (allowNumbers && !allowSpecialChars && !allowChars) {
            if (newValue?.length > floatCase) {
                let valor = (parseFloat(newValue) / Math.pow(10, floatCase));
                newValue = valor.toFixed(floatCase).replace('.', ',');

                if (isNaN(valor) || !isFinite(valor)) {
                    newValue = bkpValue;
                }
            }
        }

        if (newValue?.length > maxLenght) {
            newValue = bkpValue;
        }

        return newValue;


    }, []);



    return {
        handleKeyDown
    };
}