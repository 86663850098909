import { useCallback } from 'react';
import { VariaveisAmbiente } from 'config';
import { useApiBase } from 'data/api/base';
import { PromocaoRegraModel } from 'model/api/gestao/promocao/promocao-regra-model';

export function usePutPromocaoRegra() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPromocaoRegra = useCallback(
        (regra: PromocaoRegraModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/promocao/${regra.promocaoId}/regra/${regra.id}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(regra),
                enviarTokenUsuario: true
            }),
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putPromocaoRegra
    };
}
