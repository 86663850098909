import { InformacoesConvenioProps } from "./informacoes-convenio-props"
import { toDecimalString } from "utils/to-decimal"
import { useStyles } from "./informacoes-convenio-styles"
import { EditarIcon } from "views/components/icons"
import { Button, Grid, Typography } from "views/design-system"

export const InformacoesConvenio = ({ model, editarConvenio }: InformacoesConvenioProps) => {
    const { cartoes } = model

    const classes = useStyles()

    const saldoTotal = cartoes?.reduce((prev, curr) => prev + curr.saldo.saldo, 0) ?? 0
    const limiteTotal = cartoes?.reduce((prev, curr) => prev + curr.limite, 0) ?? 0
    const qtdeCartoes = cartoes?.length ?? 0

    return (
        <div className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="h6" className={classes.body}>
                        R$ {toDecimalString(limiteTotal + saldoTotal)}
                    </Typography>
                    <Typography variant="body2" className={classes.title}>
                        Limite R$ {toDecimalString(limiteTotal)}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" className={classes.title}>
                        Cartões
                    </Typography>
                    <Typography variant="h6" className={classes.body}>
                        {qtdeCartoes}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" className={classes.title}>
                        Fechamento
                    </Typography>
                    <Typography variant="h6" className={classes.body}>
                        Dia {`${model.diaFechamento}`.padStart(2, '0')}
                    </Typography>
                </Grid>
                {cartoes.length > 0 && (
                    <Grid item xs='auto'>
                        <div className={classes.containerBtnEditar}>
                            <Button className={classes.btnEditar} variant="outlined" color="primary" onClick={editarConvenio}>
                                <EditarIcon tipo="BUTTON" />
                            </Button>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}