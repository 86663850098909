import { ApagarIcon } from 'views/components/icons/apagar-icon';
import { useStyles } from './button-keyboard-backspace-styles';
import { ButtonKeyboardBackspaceProps } from './button-keyboard-backspace-props';
import { Button } from 'views/design-system';

export function ButtonKeyboardBackspace(props: ButtonKeyboardBackspaceProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.buttonKeyboardBackspaceContainer}
      onClick={() => {
        if (props.clickFunc != null) props.clickFunc();
      }}
    >
      <Button rounded variant='contained' style={{ height: 48, width: 48, display: 'grid', padding: 0 }} color="primary">
        <ApagarIcon tipo="BUTTON_PRIMARY" class={classes.backspaceIcon} />
      </Button>
    </div>
  );
}
