import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetComissaoApuracaoList() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissaoApuracaoList = useCallback(
        (data: {
            empresaId: string;
            comissaoId: string;
            page: number,
            dataInicial: Date,
            dataFinal: Date,
        }) => {
            // const dataInicial = data.dataInicial ? toDateString(data.dataInicial, 'yyyy-MM-DD') : undefined;
            // const dataFinal = data.dataFinal ? toDateString(data.dataFinal, 'yyyy-MM-DD') : undefined;
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${data.empresaId}/comissao/${data.comissaoId}/apuracao`,
                method: 'GET',
                params: {
                    Page: data.page,
                    PageSize: 10,
                    // ...(data.dataInicial ? {
                    //     datInicial: dataInicial,
                    // } : null),
                    // ...(data.dataFinal ? {
                    //     dataFinal: dataFinal,
                    // } : null)
                },
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            })
        }, [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getComissaoApuracaoList,
    };
}