import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    paginacao: {
        padding: 0,
        margin: 0,
    },
    botaoSelecionar: {
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        marginRight: theme.spacing(1),
        minHeight: '30px',
        boxShadow: theme.shadows[1],
        borderRadius: 5,
        padding: 3,
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        background: theme.palette.common.white,
        "& strong": {
            paddingLeft: theme.spacing(1)
        },
        "& svg": {
            fill: theme.palette.text.primary,
            width: 22,
            height: 22,
        }
    },
    check: {
        "& svg": {
            fill: theme.palette.primary.main,
            marginLeft: 8,
        }
    },
    cardCheckBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: -8,
        marginLeft: 8,
    },
}));