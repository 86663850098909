import { CloseIconCircle } from "views/components/icons/close-icon-circle"
import { useThemeQueries } from "views/theme"
import { useStyles } from "./questionario-etapa-styles"
import { OkIcon, VoltarIcon } from "views/components/icons"
import { Button, Grid, Typography } from "views/design-system"

export interface QuestionarioEtapaProps {
    pergunta: string
    funcaoBotao1: () => void
    funcaoBotao2: () => void
    textoBotao1: string
    textoBotao2: string
    btnVoltar?: () => void
}


export const QuestionarioEtapa = ({ pergunta, funcaoBotao1, funcaoBotao2, textoBotao1, textoBotao2, btnVoltar }: QuestionarioEtapaProps,) => {

    const { isMobile, theme } = useThemeQueries()
    const classes = useStyles()
    return (
        <Grid container flex flexDirection='column' justifyContent='space-between' >
            <Typography align={isMobile ? 'left' : 'center'} variant='body1' className={classes.question}>
                {pergunta}
            </Typography>
            <Grid  container spacing={isMobile ? 1 : 2}>
                {btnVoltar && (
                    <Grid item xs={isMobile ? 12: 4}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            size="large"
                            fullWidth
                            onClick={btnVoltar}>
                            <VoltarIcon tipo='BUTTON' fill={theme.palette.primary.main} />
                            Passo Anterior
                        </Button>
                    </Grid>
                )}
                <Grid item xs={isMobile ? 6 : btnVoltar ? 4 : 6}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={funcaoBotao1}>
                        <CloseIconCircle tipo='BUTTON' fill={theme.palette.common.white} />
                        {textoBotao1}
                    </Button>
                </Grid>
                <Grid item xs={isMobile ? 6 : btnVoltar ? 4 : 6}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={funcaoBotao2}>
                        <OkIcon tipo='BUTTON' fill={theme.palette.common.white} />
                        {textoBotao2}
                    </Button>
                </Grid>

            </Grid>
        </Grid>
    )
}