import { guidEmpty } from "utils/guid-empty";

export class CartaoConvenioPostModel {
    constructor(
        public id?: string,
        public contaPessoaId?: string,
        public nomeCartao: string = '',
        public numeroCartao: string = '',
        public senha: string = '',
        public limite: number = 0,
        public pessoaId: string = guidEmpty(),
        public dataValidade: string | Date = '',
    ) { }
}
