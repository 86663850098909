import { useCallback, FocusEvent, useState, useEffect, useRef } from "react";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
  NovoItemModel,
} from "../autocomplete-saurus/autocomplete-saurus";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { useGetDepositoEmpresa } from "data/api/gestao/deposito";
import { DepositoEmpresaModel } from "model/api/gestao/deposito/deposito-empresa-model";
import { guidEmpty } from "utils/guid-empty";
import { validaGuid } from "utils/valida-guid";

export interface AutocompleteDepositosProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string | null;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<any>;
  permiteAdicionar?: boolean;
  textNovoItem?: string | NovoItemModel[];
  tpProduto?: EnumTipoProduto[];
  exibirTipo?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  optSemProduto?: boolean;
  tipo?: EnumTipoProduto;
  isCodigoNome?: boolean
  fetchOnLoad?: boolean
}

export const AutocompleteDepositos = ({
  onChange,
  loadingExterno,
  ...props

}: AutocompleteDepositosProps) => {
  const [depositos, setDepositos] = useState<Array<DepositoEmpresaModel>>(
    []
  );
  const { getDepositoEmpresa, carregando } = useGetDepositoEmpresa();

  const getDepositosWrapper = useCallback(async (termo?: string, atribuirValor: boolean = false) => {
    try {
      const ret = await getDepositoEmpresa(termo)
      if (ret.erro) throw ret.erro

      const data = ret.resultado!.data.list as DepositoEmpresaModel[];

      //esse somente vai entrar no cadastro (caso tenha apenas 1 depósito)
      if (atribuirValor && data.length === 1 && !validaGuid(props.value || '')) {
        if (onChange) {
          onChange({
            isString: false,
            isNewVal: true,
            value: data[0]
          })
        }
      }
      setDepositos(ret.resultado?.data.list)

    } catch (e: any) {

    }
  }, [getDepositoEmpresa, onChange, props.value])

  const getById = useCallback(async (id: string) => {
    const ret = await getDepositoEmpresa();
    if (ret.erro) throw ret.erro

    const data = ret.resultado!.data.list.find((deposito: DepositoEmpresaModel) => deposito.depositoId === id) as DepositoEmpresaModel;
    //esse somente vai entrar na edição (caso já tenha um id)
    if (data) {
      if (onChange) {
        onChange({
          isString: false,
          isNewVal: true,
          value: data
        })
      }

    }
  }, [getDepositoEmpresa, onChange])

  const allowFetch = useRef<boolean>(true);
  const allowFetchById = useRef<boolean>(true);

  useEffect(() => {
    if (props.fetchOnLoad && allowFetch.current) {
      allowFetch.current = false
      getDepositosWrapper('', true)
    }
  }, [getDepositosWrapper, props.fetchOnLoad])

  useEffect(() => {
    if (allowFetchById.current && validaGuid(props.value || '')) {
      allowFetchById.current = false;
      getById(props.value!);
    }
  }, [getById, props.value])

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );
  const onPesquisa = useCallback(
    (termo: string) => {
      getDepositosWrapper(termo);
    },
    [getDepositosWrapper],
  );

  return (
    <AutocompleteSaurus
      value={props.value === guidEmpty() ? '' : props.value}
      optionLabelKey="depositoNome"
      optionValueKey="depositoNome"
      onChange={onChangeWrapper}
      onPesquisa={onPesquisa}
      opcoes={depositos}
      loading={carregando}
      label={props.label}
      placeholder={props.placeholder}
      error={props.error}
      helperText={props.helperText}
    />
  );
};
