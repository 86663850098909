import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './menu-tab-categoria-styles';
import { useToastSaurus } from 'services/app';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { CircularLoading, useThemeQueries } from 'views';
import { CatalogoList } from '../catalogo-list/catalogo-list';
import { guidEmptyOne } from 'utils/guid-empty';
import { TabelaCategorias } from 'database/interfaces/interface-tabela-categorias';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { isEmpty } from 'lodash';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { EnumTipoExibicao } from 'model/enums/enum-tipo-exibicao';
import classNames from 'classnames';
import { ArrowDownIcon } from 'views/components/icons/arrow-down-icon';
import { ArrowTopIcon } from 'views/components/icons/arrow-top-icon';
import { EstrelaFavoritoIcon } from 'views/components/icons/estrela-favorito-icon';
import { Grid } from 'views/design-system';
import { DefaultCard } from 'views/components/cards/components';

interface MenuTabCategoriaProps {
  openPesquisa: boolean;
}

export const MenuTabCategoria = ({ openPesquisa }: MenuTabCategoriaProps) => {
  const classes = useStyles();
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<ProdutoCategoriaModel | TabelaCategorias | undefined>(undefined);
  var tinycolor = require('tinycolor2');
  const { xs, sm, md, lg, xl } = useThemeQueries();
  const [carregandoInterno, setCarregandoIntero] = useState<boolean>(true);
  const { showToast } = useToastSaurus();
  const [exibirTodasCategorias, setExibirTodasCategorias] = useState<boolean>(false);
  const [linhaSelecionada, setLinhaSelecionada] = useState<number>(0);
  const [categorias, setCategorias] = useState<
    Array<ProdutoCategoriaModel | TabelaCategorias>
  >([]);
  const { theme } = useThemeQueries();
  const carregando = carregandoInterno;

  useEffect(() => {
    if (categorias && categorias.length > 0) {
      const cats = categorias.filter(x => x.id === guidEmptyOne());
      const cat = (cats.length > 0 ? cats[0] : categorias[0]);
      setCategoriaSelecionada(cat);
    }
  }, [categorias]);

  const search = useCallback(async () => {
    try {
      const categoriasIndexedDB = await TouchoneDBPrimary.categorias
        .filter(
          (x) =>
            x?.temProdutos === true &&
            x?.ativo &&
            [EnumTipoExibicao.Catalogo, EnumTipoExibicao.Todos].includes(
              x.tipoExibicao
            )
        )
        .toArray();

      const categorias: Array<ProdutoCategoriaModel | TabelaCategorias> = [
        ...categoriasIndexedDB
      ];



      const favorito = new ProdutoCategoriaModel(
        guidEmptyOne(),
        undefined,
        'Favoritos',
        'Favoritos',
        'Favoritos',
        'rgba(239, 142, 71)',
        true
      );

      categorias.unshift(favorito);


      setCategorias(categorias);
      setCarregandoIntero(false);
    } catch (e: any) {
      showToast('error', 'Erro ao identificar as categorias. Detalhe: ' + e.message);
    }
  }, [showToast]);

  useEffect(() => {
    search();
  }, [search]);

  const selecionarCategoria = useCallback((id: string, index: number) => {
    var cat = categorias.filter(x => x.id === id);
    if (cat.length > 0) {
      setLinhaSelecionada(index);
      setCategoriaSelecionada(cat[0]);
      setExibirTodasCategorias(false);
    }
  }, [setCategoriaSelecionada, categorias]);

  const retNovoModelo = useCallback((pageSize: number, page: number) => {
    return categorias.filter(x => x.id !== guidEmptyOne())
      .sort(function (a, b) {
        return a.nome!.localeCompare(b.nome!);
      })
      .sort(function (a, b) {
        return a.id === guidEmptyOne() ? -1 : 0;
      })
      .sort((a, b) => a.ordem - b.ordem)
      .map((categoria, index) => {
        if (index + 1 > pageSize * page || index + 1 <= pageSize * (page - 1))
          return <></>;

        const color = tinycolor(
          `${isEmpty(categoria?.cor) ? '#FFFFFF' : categoria.cor}`
        );

        const background = categoria?.foto ? (
          <img
            alt="Imagem ou cor da categoria."
            src={atob(categoria?.foto)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
              currentTarget.style.opacity = '1';
              currentTarget.style.filter =
                'brightness(50%)';
            }}
            className={classes.image}
            style={{
              top: 'none',
              left: 'none'
            }}
          />) : <></>

        const isSelecionada = categoria?.id === categoriaSelecionada?.id;

        return (
          <DefaultCard
            hasTagStatus={false}
            className={classNames(classes.categoria, (isSelecionada ? classes.categoriaSelecionada : undefined))}
            onClick={async () => selecionarCategoria(categoria?.id!, page)}
            style={{
              backgroundColor: categoria?.foto ? (categoria?.cor ? categoria?.cor : `#666`) : `${categoria?.cor}`,
              color: categoria?.foto
                ? '#FFF'
                : color.isLight()
                  ? theme.palette.text.primary
                  : '#FFF'
            }}
          >
            {background}
            <p className={classNames(!openPesquisa && classes.paragraph)}>{categoria?.nome}</p>
          </DefaultCard>
        );
      })
  }, [categorias, tinycolor, classes.image, classes.categoria, classes.categoriaSelecionada, classes.paragraph, categoriaSelecionada?.id, theme.palette.text.primary, openPesquisa, selecionarCategoria]);

  const categoriaFavorita = useMemo(() => {
    return <DefaultCard hasTagStatus={false} className={classNames(classes.categoriaFavoritos, (categoriaSelecionada?.id === guidEmptyOne() ? classes.categoriaSelecionada : undefined))} onClick={async () => selecionarCategoria(guidEmptyOne(), 1)}>
      <EstrelaFavoritoIcon tipo="BUTTON_PRIMARY" fill="#FFBF00" />
    </DefaultCard>
  }, [categoriaSelecionada, classes.categoriaFavoritos, classes.categoriaSelecionada, selecionarCategoria]);

  const categoriaOutros = useMemo(() => {
    return <DefaultCard hasTagStatus={false} className={classes.categoriaOutros} onClick={async () => setExibirTodasCategorias((prev) => { return !prev })} >
      {exibirTodasCategorias && (
        <ArrowTopIcon tipo="BUTTON" fill={"#555"} />
      )}
      {!exibirTodasCategorias && (
        <ArrowDownIcon tipo="BUTTON" fill={"#555"} />
      )}
    </DefaultCard>
  }, [exibirTodasCategorias, classes.categoriaOutros])

  const qtdCategorias = useMemo(() => categorias.length - 1, [categorias.length]);

  const qtdColunas = useMemo(() => {
    let qtdMaxima = xs ? 3 : sm ? 4 : md ? 5 : lg ? 6 : xl ? 8 : 6
    if (qtdCategorias < qtdMaxima) {
      qtdMaxima = qtdCategorias
    } else if (qtdCategorias < qtdMaxima * 2 && qtdCategorias > qtdMaxima) {
      qtdMaxima = Math.floor(qtdCategorias / 2) + ((qtdCategorias % 2) > 0 ? 1 : 0)
    }
    return qtdMaxima;
  }, [qtdCategorias, xs, sm, md, lg, xl]);

  const linhas = useMemo(() => {
    const rets = Math.floor(qtdCategorias / qtdColunas) + ((qtdCategorias % qtdColunas) > 0 ? 1 : 0);
    return rets > 2 ? 2 : rets;
  }, [qtdCategorias, qtdColunas]);

  const exibirMaisCategorias = qtdCategorias > qtdColunas * 2 && !exibirTodasCategorias;

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      {linhas > 0 && (
        <div className={classes.root}>
          {linhas > 0 && (
            <div className={classes.categoriasListLinha} style={{
              gridTemplateColumns: '60px repeat(' + (linhas > 1 ? qtdColunas : qtdCategorias) + ', minmax(0, 1fr))',
            }}>
              {categoriaFavorita}
              {retNovoModelo(qtdColunas, 1)}
            </div>
          )}
          {linhas > 1 && (
            <>
              {
                [2, 3, 4, 5, 6, 7, 8].map(x => {

                  const colunas = (qtdCategorias >= x * qtdColunas ? qtdColunas : qtdCategorias % qtdColunas);

                  //VALIDA PARA NAO RETORNAR NADA NAS OUTRAS COLUNAS QUE NAO ROLA
                  if (qtdCategorias < ((qtdColunas * (x - 1))) + colunas || colunas === 0) {
                    return <></>
                  }


                  //SE NAO FOR PRA EXIBIR, ELE PULA O PASSO
                  if (exibirMaisCategorias) {
                    if (linhaSelecionada > 1 && x !== linhaSelecionada)
                      return <></>
                    if (linhaSelecionada <= 1 && x > 2)
                      return <></>
                  }

                  let ultima = false;
                  if (exibirMaisCategorias) {
                    if (qtdCategorias > x * qtdColunas)
                      ultima = true;
                    if (linhaSelecionada > 1 && x === linhaSelecionada)
                      ultima = true;
                  } else {
                    if (Math.floor(qtdCategorias / qtdColunas) < x && x > 2)
                      ultima = true;
                  }


                  return (
                    <div className={classes.categoriasListLinha} style={{
                      gridTemplateColumns: 'repeat(' + colunas + ', minmax(0, 1fr)) ' + (ultima ? '60px' : '0px'),
                    }}>
                      {retNovoModelo(qtdColunas, exibirMaisCategorias ? linhaSelecionada < 2 ? x : linhaSelecionada : x)}
                      {ultima && categoriaOutros}
                    </div>
                  )
                })
              }
            </>
          )}
        </div>
      )}
      <Grid className={classes.listCatalogo}>
        {categoriaSelecionada?.id && (
          <CatalogoList
            todasCategorias={categorias}
            idCategoria={categoriaSelecionada?.id}
            cor={categoriaSelecionada?.cor || '#fff'}
          />
        )}
      </Grid>
    </>
  );
};
