import { Button, Grid } from "views/design-system";
import classNames from "classnames";
import { useGetMeuUsuario } from 'data/api/gestao/meu-usuario';
import { usePutAcessoApi } from "data/api/gestao/usuario/put-acesso-api";
import { isEmpty } from "lodash";
import { UsuarioModel } from "model/api/gestao/usuarios/usuario-model";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormCodigoApi } from "views/components/form/master/codigo-api/form-codigo-api";
import { ChaveIcon } from "views/components/icons/chave-icon";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useThemeQueries } from "views/theme"

export const AccordionChaveApi = () => {
    //AUX
    const { theme } = useThemeQueries();
    const classesModal = useModalStyles();
    const { showToast } = useToastSaurus();

    //STATES
    const [openAccordion, setOpenAccordion] = useState<boolean>(false);
    const [model, setModel] = useState<UsuarioModel>(new UsuarioModel());

    //CHAMADAS DA API
    const { getMeuUsuario, carregando: carregandoGet } = useGetMeuUsuario();
    const { putAcessoApi, carregando: carregandoPutAcessoSuporte } = usePutAcessoApi();

    const carregando = carregandoPutAcessoSuporte || carregandoGet;

    const getUsuarioWrapper = useCallback(async () => {
        try {
            const res = await getMeuUsuario();

            if (res.erro) throw res.erro

            if (isEmpty(res.resultado?.data.userKey)) {
                return
            }

            return setModel(res.resultado?.data)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getMeuUsuario, showToast])

    useEffect(() => {
        if (openAccordion) {
            getUsuarioWrapper();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAccordion])

    const putAcessoApiWrapper = useCallback(async () => {
        const res = await putAcessoApi();

        if (res.erro) throw res.erro

        return res.resultado?.data;
    }, [putAcessoApi])

    const gerarChave = useCallback(async () => {
        try {
            const data = await putAcessoApiWrapper()

            setModel(data)

            showToast('success', 'Código gerado com sucesso.');
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [putAcessoApiWrapper, showToast])

    //BUTTON SALVAR
    const buttonGerarCodigo = useMemo(() => {
        return (
            <div className={classesModal.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            color="primary"
                            fullWidth
                            onClick={gerarChave}
                        >
                            <ChaveIcon tipo="BUTTON_PRIMARY" />
                            {isEmpty(model.userKey) ? 'Gerar Código' : 'Gerar Outro Código'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }, [classesModal.acoes, gerarChave, model.userKey]);

    //FORMS 
    const forms = useMemo(() => {
        return (
            <>
                <div className={classNames(classesModal.contentForms)} style={{
                    height: '100%'
                }}>
                    <FormCodigoApi
                        model={model}
                    />
                </div>
            </>
        )
    }, [classesModal.contentForms, model])

    return (
        <>
            <AccordionSaurus
                labelPrimary="Chave de Integração"
                tipoExpand="bold"
                tituloChildren={
                    <ChaveIcon tipo="BUTTON" fill={theme.palette.text.primary} />
                }
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
            >
                <div className={classesModal.root}>
                    {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                    <div className={classesModal.content}>
                        {forms}
                        {buttonGerarCodigo}
                    </div>
                </div>
            </AccordionSaurus>
        </>
    )
}