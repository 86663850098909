
export enum EnumPlanoOpcoes {
    Fiscal = 600100,
    Modificador = 600900,
    CadastroProdutos = 100200,
    Vendas = 200100,
    Convenio = 200200,
    Comissao = 200300,
    IntegraoSaurusPDV = 600400,
    ControleDeVendedor = 500100,
    ControleDeAcesso = 500200,
    DiasExpiracao = 400200,
    Consumacao = 600300,
    ControleEstoque = 500300,
    Promocoes = 500400,
    SuporteViaWhatsApp = 400100,
    PagamentoIntegrado = 300200,
    RelatoriosBasicos = 300400,
    MesaComQrCode = 300500,
    ControleProducao = 300600,
    VitrineDigital = 300700,
    OperacaoPedido = 300800,
    Farmaceutico = 600500,
    DeliveryProprio = 300900,
    EntregaDelivery = 300610,
    EntradaMercadoria = 600600,
    Usuarios = 100400,
}