import { Box, Button } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from '../../../../utils';
import classNames from 'classnames';
import { usePostRetiradaDocumento } from 'data/api/gestao/relatorios/retirada-documento/post-retirada-documento';
import { RetiradaDocumentoPostModel } from 'model/api/gestao/retirada-documento/retirada-documento-post-model';
import { FormDocumentoFiscalCadastro } from 'views/components/form/documento-fiscal/form-documento-fiscal-cadastro';
import { DocumentoFiscalCadastroFormModel } from 'model/app/forms/documento-fiscal/documento-fiscal-cadastro-form-model';
import { DocumentoAdicionarIcon } from 'views/components/icons';
import { useStyles } from './documento-fiscal-cadastro-styles';
import { EnumMovModelo } from 'model';

interface Props {
  header?: boolean;
}

export const DocumentoFiscalCadastro = (props: Props) => {

  const { header = true } = props
  const { postRetiradaDocumento, carregando } = usePostRetiradaDocumento()
  const { abrirCadastroRetirada, fecharCadastroRetirada } =
    useCadastros();

  const classes = useModalStyles();
  const cadastroClasses = useStyles();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const cadDocumentoFiscalFormRef =
    useRef<DefaultFormRefs<DocumentoFiscalCadastroFormModel>>(null);
  const { showToast } = useToastSaurus();

  const saveNewRetirada = useCallback(
    async (model: RetiradaDocumentoPostModel) => {
      try {

        const ret = await postRetiradaDocumento(model, getEmpresaSelecionada()!.Documento);
        if (ret.erro) {
          throw ret.erro;
        }
        abrirCadastroRetirada("", "", false)
        fecharCadastroRetirada()
        showToast('success', 'Documento adicionado com sucesso!', );
      }
      catch (e: any) {

        showToast('error', e.message);
        cadDocumentoFiscalFormRef.current?.fillForm(model);
      }
    },
    [postRetiradaDocumento, getEmpresaSelecionada, abrirCadastroRetirada, fecharCadastroRetirada, showToast],
  );

  const handleSubmit = useCallback(
    async (modelP: RetiradaDocumentoPostModel) => {
      const retiradaToCreate = picker<RetiradaDocumentoPostModel>(
        modelP,
        new RetiradaDocumentoPostModel(),
      );
      retiradaToCreate.mod = [EnumMovModelo.NFCE, EnumMovModelo.NFE, EnumMovModelo.SAT];
      retiradaToCreate.status = [2]
      retiradaToCreate.dInicial = `${retiradaToCreate.dInicial}T00:00:00`
      retiradaToCreate.dFinal = `${retiradaToCreate.dFinal}T23:59:59`


      return saveNewRetirada(retiradaToCreate);
    },
    [saveNewRetirada],
  );

  useEffect(() => {
    cadDocumentoFiscalFormRef.current?.resetForm();
  }, []);

  const handleClick = useCallback(() => {
    cadDocumentoFiscalFormRef.current?.submitForm()
  }, [])


  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      {header && (
        <ModalHeader
          title={'Novo Documento XML'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={() => fecharCadastroRetirada()}
            />
          }
        />
      )}
      <div className={classes.content}>
        <Box
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
            cadastroClasses.contentForm
          )}
        >
          <FormDocumentoFiscalCadastro
            ref={cadDocumentoFiscalFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </Box>
        <div className={classNames(
          classes.acoes,
          cadastroClasses.button
        )}>
          <Button
            disabled={carregando}
            onClick={handleClick}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            <DocumentoAdicionarIcon tipo='BUTTON_PRIMARY' />
            Gerar Documento
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DocumentoFiscalCadastro;
