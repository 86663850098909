import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
} from 'react';
import { Box, Grid, Button, Typography, Divider, Tooltip } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { useForm, useFieldArray } from 'react-hook-form';
import { picker } from 'utils/picker';
import { useStyles } from './form-plano-styles';
import { PlanoFormModel } from 'model/api/gestao/plano/plano-form-model';
import { ModuloPlanoModel } from 'model/api/gestao/plano/plano-modulo-model';
import { DefaultCard, useDefaultCardStyles } from 'views/components/cards/components';
import { BotoesAddESub } from './components/button-add-sub';
import { useFormPlanoValidation } from './form-plano-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { calcPercent } from 'utils/calc-percent';
import { EnumTipoCalculo } from 'model/enums/enum-tipo-calculo';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { InformacaoIcon } from 'views/components/icons';
import { roundTo } from 'utils/round-to';
import { useSessaoAtual, useToastSaurus } from 'services/app';


export const FormPlano = forwardRef<
    DefaultFormRefs<PlanoFormModel>,
    DefaultFormProps<PlanoFormModel>
>(({ loading, onSubmit, ...props }: DefaultFormProps<PlanoFormModel>, ref) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPlanoValidationYup } = useFormPlanoValidation();

    const classes = useStyles()
    const cardClasses = useDefaultCardStyles();
    const { theme } = useThemeQueries()
    const { usuario, getEmpresaSelecionada } = useSessaoAtual();
    const { showToast } = useToastSaurus();


    const {
        handleSubmit,
        reset,
        setValue,
        getValues,
        trigger,
        control,
        formState: { errors },
    } = useForm<PlanoFormModel>({
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
        resolver: yupResolver(FormPlanoValidationYup)

    });

    const { fields } = useFieldArray({
        control,
        name: 'modulos'
    });

    const fieldsSerializados = fields.map((fields, index) => {
        return {
            ...fields,
            indexOriginal: index,
        };
    });

    const submitAlterarDados = (values: PlanoFormModel) => {
        //values.modulos = Object.values(values.modulos).map(item => item)
        values.modulos = values.modulos.map((item) =>
            picker<ModuloPlanoModel>(item, new ModuloPlanoModel()),
        );
        onSubmit(values);
    };

    //FUNÇÃO DO LICENCIAMENTO PRA CALCULAR VALOR DO MODULO DE ACORDO COM MODULOS DEPENDENTES OU FILIAIS
    const calcularValorModulos = useCallback((modulo: ModuloPlanoModel, modulos: ModuloPlanoModel[], valorFinal: number, index: number) => {
        if (modulo.tipoCalculo === EnumTipoCalculo.Modulo && modulo.tipoControle === EnumTipoControleModulo.Filial) {
            modulo.codModuloBaseCalculo = modulo.codModuloBaseCalculo.toString()
            const codsBase = modulo.codModuloBaseCalculo.includes(',') ? modulo.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : modulo.codModuloBaseCalculo.split('-').map(cod => Number(cod))
            const moduloBase = modulos.filter(mod => codsBase.includes(mod.codigo))

            const valorBase = toDecimal(moduloBase.reduce((prev, curr) => {
                if (curr.quantidadeContratada === 0) return prev
                if (curr.quantidadeContratada === 1) {
                    return curr.valorFinal + prev
                }
                return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorFinal, curr.percAdicional) + curr.valorFinal + prev, 6)
            }, 0))


            valorFinal = moduloBase.length > 0 && modulo.quantidadeContratada > 0 ? toDecimal((modulo.quantidadeContratada) * calcPercent(valorBase, modulo.percAdicional), 6) : valorFinal

        } else if (modulo.tipoCalculo === EnumTipoCalculo.Modulo) {
            const codBase = Number(modulo.codModuloBaseCalculo)
            const moduloBase = modulos.find(mod => mod.codigo === codBase)

            valorFinal = moduloBase ? valorFinal * (moduloBase?.quantidadeContratada || 1) : valorFinal
        }

        if (modulo.quantidadeContratada === -1) {
            setValue(`modulos.${index}.valorFinal`, 0)
            return 0
        }

        setValue(`modulos.${index}.valorFinal`, valorFinal)

        return valorFinal
    }, [setValue])

    const calcularValorTotal = useCallback((modulos: ModuloPlanoModel[]) => {
        const valorTotalModulos = modulos.reduce((prev, curr, index) => {

            if (curr.quantidadeContratada === 0) return prev

            const quantMult = toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorOriginal, curr.percAdicional), 6)
            let valorFinal = curr.valorOriginal + quantMult

            valorFinal = calcularValorModulos(curr, modulos, valorFinal, index)

            return prev + valorFinal
        }, 0)

        setValue('valor', roundTo(valorTotalModulos))
        setValue('valorCalculado', roundTo(valorTotalModulos))
    }, [calcularValorModulos, setValue])

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            if(errors && errors.modulos){
                let message = '';
                for(let [key, value] of Object.entries<any>(errors.modulos)){
                    message += (message.length === 0 ? '' : ', ') + `${getValues(`modulos.${parseInt(key)}.nome`)}: ${value.quantidadeContratada.message}`
                }

                showToast('error', `Erro nos seguintes módulos:\n${message}`)
                return
            }
            handleSubmit(submitAlterarDados)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputNome.current?.focus();
        },
        fillForm: (model: PlanoFormModel) => {
            reset({ ...model });
            model.modulos.forEach((modulo, i) => {
                setValue(`modulos.${i}.quantidadeContratada`, modulo.quantidadeContratada)
                const percAdicional = modulo.percAdicional
                const valor = modulo.valorFinal
                
                const quantMult = toDecimal((modulo.quantidadeContratada - 1) * calcPercent(valor, percAdicional), 6);

                let valorSomado = modulo.quantidadeContratada > 0 ? valor + quantMult : 0
                calcularValorModulos(modulo, model.modulos, valorSomado, i)
            })
            calcularValorTotal(model.modulos)
            if (!isMobile) refInputNome.current?.focus();
        },
    }));

    const alterarQtd = useCallback((index: number, qtd: number) => {
        const modulo = getValues(`modulos.${index}`)
        const modulos = getValues('modulos')

        setValue(`modulos.${index}.quantidadeContratada`, qtd)
        const percAdicional = modulo.percAdicional
        const valor = modulo.valorFinal

        const quantMult = toDecimal((qtd - 1) * calcPercent(valor, percAdicional), 6);

        let valorSomado = qtd > 0 ? valor + quantMult : 0

        valorSomado = calcularValorModulos(modulo, modulos, valorSomado, index)

        setValue(`modulos.${index}.valorFinal`, valorSomado)

        //refazendo calculo de todos os módulos
        calcularValorTotal(modulos)

        trigger(`modulos.${index}.quantidadeContratada`)
    }, [calcularValorModulos, calcularValorTotal, getValues, setValue, trigger])

    //Isso é uma validação para exibir ou não 4 casas decimais no card
    const retornaNumeroDeCasas = useCallback((value: number) => {
        const casaDecimal = toDecimalString(value, 4).split(',')[1][2]

        return Number(casaDecimal) > 0 ? 4 : 2
    }, [])

    const retornarDescricaoTooltip = useCallback((model: ModuloPlanoModel) => {
        if (model.tipoControle === EnumTipoControleModulo.Filial) {
            return (
                <div>
                    <Typography>Este módulo é cobrado de acordo com o valor dos seguintes módulos considerando o percentual adicional.</Typography>
                    <br />
                    {getValues('modulos').filter(m => {
                        const modulos = model.codModuloBaseCalculo.includes(',') ? model.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : model.codModuloBaseCalculo.split('-').map(cod => Number(cod))
                        return modulos.includes(m.codigo)
                    }).map(m => (
                        <Typography>- {m.nome};</Typography>
                    ))}
                </div>
            )
        }
        if (model.tipoCalculo === EnumTipoCalculo.Modulo) {
            const codBase = Number(model.codModuloBaseCalculo)
            const moduloBase = getValues('modulos').find(mod => mod.codigo === codBase)
            return (
                <div>
                    <Typography variant='body2'>Percentual Adicional: {model.percAdicional}%</Typography>
                    <br />
                    <Typography>Este módulo é cobrado de acordo com o valor do seguinte módulo considerando o percentual adicional.</Typography>
                    <br />
                    <Typography>- {moduloBase?.nome}.</Typography>
                </div>
            )
        }
        return <Typography variant='body2'>Percentual Adicional: {model.percAdicional}%</Typography>
    }, [getValues])

    return (
        <>
            <Box mb={2}>
                <div className={utilClasses.formContainer}>
                    {loading && props.showLoading ? (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo="FULLSIZED" />
                        </div>
                    ) : null}

                    <form
                        onSubmit={handleSubmit(submitAlterarDados)}
                        className={loading ? utilClasses.controlLoading : ''}
                    >
                        <Grid container spacing={2} justifyContent="center" style={{ height: '100%' }}>
                            <Grid item xs={12}>
                                <Box className={classes.containerFlex}>
                                    <Box className={classes.moduloContainer}>
                                        <Typography variant="h6">Módulos</Typography>
                                        <Divider style={{ background: theme.palette.primary.main, height: 2 }} />
                                        <Grid container spacing={1} style={{ marginTop: '.5rem' }}>
                                            {fieldsSerializados.length > 0 &&
                                                fieldsSerializados.map((item, i) => {
                                                    return (
                                                        <Grid item xs={12}>
                                                            <DefaultCard>
                                                                <Grid container spacing={2} className={classNames(cardClasses.cardContent, classes.card, errors && errors.modulos?.[i] ? classes.cardError : undefined)}>
                                                                    <Grid item xs={12} sm={6} md={3}>
                                                                        <Typography variant="caption" color='primary'>
                                                                            Nome
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textPrimary"
                                                                            variant="body1"
                                                                            style={{ fontWeight: 600 }}
                                                                        >
                                                                            {item.nome}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} className={classes.valorCard}>
                                                                        <Box>
                                                                            <Box display='flex' flexDirection='column'>
                                                                                {item.tipoControle !== EnumTipoControleModulo.Filial ?
                                                                                    <>
                                                                                        <Typography variant="caption">Valor</Typography>
                                                                                        <Typography
                                                                                            color="textPrimary"
                                                                                            variant="body1"
                                                                                            style={{ fontWeight: 600 }}
                                                                                        >
                                                                                            R$ {toDecimalString(item.valorOriginal, retornaNumeroDeCasas(item.valorOriginal))}
                                                                                        </Typography>
                                                                                    </> : <>
                                                                                        <Typography variant="caption">Perc. Adicional</Typography>
                                                                                        <Typography
                                                                                            color="textPrimary"
                                                                                            variant="body1"
                                                                                            style={{ fontWeight: 600 }}
                                                                                        >
                                                                                            {item.percAdicional}%
                                                                                        </Typography>
                                                                                    </>}
                                                                            </Box>
                                                                        </Box>
                                                                        <Tooltip title={retornarDescricaoTooltip(item)}>
                                                                            <div>
                                                                                <InformacaoIcon
                                                                                    tipo="BUTTON"
                                                                                    style={{
                                                                                        width: 18,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3}>
                                                                        <Typography variant="caption">Contratado</Typography>
                                                                        <Typography
                                                                            color="textPrimary"
                                                                            variant="body1"
                                                                            style={{ fontWeight: 600 }}
                                                                        >
                                                                            R$ {toDecimalString(getValues(`modulos.${item.indexOriginal}.valorFinal`), retornaNumeroDeCasas(getValues(`modulos.${item.indexOriginal}.valorFinal`)))}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={2} className={classes.buttonAddSubContainer}>
                                                                        <BotoesAddESub
                                                                            alterarQtd={alterarQtd}
                                                                            error={Boolean(errors.modulos && errors.modulos[item.indexOriginal]?.quantidadeContratada)}
                                                                            model={item}
                                                                            control={control}
                                                                            value={getValues(`modulos.${item.indexOriginal}.quantidadeContratada`)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </DefaultCard>
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography
                                            align="center"
                                            variant="h6"
                                            color="primary"
                                            className={classes.cardPricingR$}
                                        >
                                            Valor Total
                                        </Typography>
                                        <Divider
                                            style={{ backgroundColor: theme.palette.primary.main }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flex: 1,
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                                color="primary"
                                                className={classes.cardPricingR$}
                                            >
                                                R$
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="primary"
                                                className={classes.cardPricing}
                                            >
                                                {(
                                                    /*
                                                        SEGUNDO O GUI TEM UM ACORDO QUE REVENDAS Q USAM O SISTEMA PRA USO PRÓPRIO TEM
                                                        UM VALOR ACORDADO FIXO DE 50 REAIS, POR ISSO ESSA VALIDAÇÃO AQUI
                                                    */
                                                    getEmpresaSelecionada()?.Documento === usuario?.licenca.RepresentanteDocumento ?
                                                        50 :
                                                        getValues('valor') || 0
                                                ).toFixed(2).replace('.', ',')}
                                            </Typography>
                                            <Box className={classes.cardTextPerMonth}>
                                                <Typography
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.textPerMonth}
                                                >
                                                    /mês
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </Box>
        </>
    );
});  