import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useFormReceitaPreCadastroValidation } from './form-receita-validations';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';

import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReceitaPreCadastroFormModel } from 'model/app/forms/receita/receita-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TipoReceitaMock } from 'data/mocks/tipo-receita-mock';
import { UFMock } from 'data/mocks/uf-mock';
import { KeyValueModel } from 'model';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { toDateString } from 'utils/to-date';

export interface FormReceitaProps
  extends DefaultFormProps<ReceitaPreCadastroFormModel> {}

export const FormReceitaPreCadastro = forwardRef<
  DefaultFormRefs<ReceitaPreCadastroFormModel>,
  FormReceitaProps
>(({ loading, ...props }: FormReceitaProps, ref) => {
  const { getEmpresaAtual } = useEmpresaAtual();
  const utilClasses = makeUtilClasses();
  const [focused, setFocused] = useState<boolean>(true);
  const [initialValues, setInitialValues] =
    useState<ReceitaPreCadastroFormModel>(new ReceitaPreCadastroFormModel());

  const { FormYupValidationPreReceita } = useFormReceitaPreCadastroValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
  } = useForm<ReceitaPreCadastroFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormYupValidationPreReceita),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (values: ReceitaPreCadastroFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ReceitaPreCadastroFormModel());
      reset();
    },
    fillForm: (model: ReceitaPreCadastroFormModel) => {
      setInitialValues(model);
      reset({ ...model });

      const dataHora = new Date();
      const diferenca = dataHora.getTimezoneOffset();
      dataHora.setMinutes(dataHora.getMinutes() - diferenca);
      const dataFormatada =  dataHora.toISOString().slice(0, 16);

      setValue('dataHoraLancamento', dataFormatada)
      setValue('dataReceita', toDateString(new Date(), 'yyyy-MM-DD') ?? '')
      setValue('uf', getEmpresaAtual()?.uf ?? '')
    }
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="receitaTipo"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    {...field}
                    label="Tipo de Receita"
                    variant="outlined"
                    fullWidth
                    allowSubmit
                    disabled={loading}
                    conteudo={TipoReceitaMock}
                    error={Boolean(
                      errors.receitaTipo && errors.receitaTipo.message
                    )}
                    helperText={
                      touchedFields.receitaTipo || errors.receitaTipo
                        ? errors.receitaTipo?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = TipoReceitaMock.filter(
                        (item) => item.Key === event.target.value
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('receitaTipo', item);
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="dataHoraLancamento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    autoFocus={focused}
                    disabled={loading}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      field.onBlur();
                      setTimeout(() => setFocused(false), 100);
                    }}
                    fullWidth
                    variant="outlined"
                    label={'Data e hora do lançamento'}
                    tipo={'DATA_HORA'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.dataHoraLancamento &&
                        errors.dataHoraLancamento.message
                    )}
                    helperText={
                      touchedFields.dataHoraLancamento ||
                      errors.dataHoraLancamento
                        ? errors.dataHoraLancamento?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="dataReceita"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    autoFocus={focused}
                    disabled={loading}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      field.onBlur();
                      setTimeout(() => setFocused(false), 100);
                    }}
                    fullWidth
                    variant="outlined"
                    label={'Data Receita'}
                    tipo={'DATA'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.dataReceita && errors.dataReceita.message
                    )}
                    helperText={
                      touchedFields.dataReceita || errors.dataReceita
                        ? errors.dataReceita?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="uf"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    {...field}
                    conteudo={UFMock.map(
                      (x) => new KeyValueModel(x.Value, x.Value)
                    )}
                    fullWidth
                    autoComplete="new-password"
                    variant="outlined"
                    label="UF"
                    allowSubmit
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(errors.uf && errors.uf.message)}
                    helperText={
                      touchedFields.uf || errors.uf
                        ? errors.uf?.message
                        : undefined
                    }
                    onChange={(event) => {
                      const value = UFMock.filter(
                        (x) => x.Value === event.target.value
                      )[0]?.Value;
                      setValue('uf', value);
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="numeroReceita"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    autoFocus={focused}
                    disabled={loading}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      field.onBlur();
                      setTimeout(() => setFocused(false), 100);
                    }}
                    fullWidth
                    variant="outlined"
                    label={'Nº Receita'}
                    tipo={'TEXTO'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.numeroReceita && errors.numeroReceita.message
                    )}
                    helperText={
                      touchedFields.numeroReceita || errors.numeroReceita
                        ? errors.numeroReceita?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="cid"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    autoFocus={focused}
                    disabled={loading}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      field.onBlur();
                      setTimeout(() => setFocused(false), 100);
                    }}
                    fullWidth
                    variant="outlined"
                    label={'CID'}
                    tipo={'TEXTO'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(errors.cid && errors.cid.message)}
                    helperText={
                      touchedFields.cid || errors.cid
                        ? errors.cid?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
