import useEventListener from '@use-it/event-listener';
import { EnumKey } from "model/enums/enum-keycode";
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEffect } from 'react';

const defaultForwardKeys = [
    EnumKey.ARROW_DOWN,
    EnumKey.ARROW_RIGHT,
    EnumKey.TAB
]

const defaultBackwardKeys = [
    EnumKey.ARROW_UP,
    EnumKey.ARROW_LEFT
]

const submitKeys = [
    EnumKey.ENTER,
]

//ESSE HOOK SERVE PRA NAVEGAR EM LISTA POR TECLADOS (EXEMPLO PESQUISA DE PRODUTO / CLIENTE NO PDV)
//USO DE REF SERVE PRA MANTER PERFORMANCE EM LISTAGENS
export const useKeyNavigation = (
    tamanhoLista: number,
    value: React.MutableRefObject<number>,
    onEnter?: (index: number) => any,
    overrideKeys?: {
        forwardKeys?: EnumKey[];
        backwardKeys?: EnumKey[];
    }
) => {
    const { callEvent } = useEventTools();

    const forwardKeys = overrideKeys?.forwardKeys || defaultForwardKeys;
    const backwardKeys = overrideKeys?.backwardKeys || defaultBackwardKeys;

    useEventListener('keydown', (event: any) => {
        const key = event.key;

        if (tamanhoLista - 1 < value.current) {
            value.current = -1;
        }
        if (
            (
                [...forwardKeys, ...backwardKeys, ...submitKeys].includes(key)
            ) &&
            tamanhoLista > 0
        ) {

            if (submitKeys.includes(key) && onEnter) {
                onEnter(value.current);
                return
            }

            event.preventDefault();

            if (backwardKeys.includes(key)) {
                if (value.current === 0) {
                    callEvent(AppEventEnum.KeyNavigationIndex, -1);
                    value.current = -1;
                    return
                }
                if (value.current < 0) {
                    callEvent(AppEventEnum.KeyNavigationIndex, tamanhoLista - 1);
                    value.current = tamanhoLista - 1;
                    return
                }
                callEvent(AppEventEnum.KeyNavigationIndex, value.current - 1)
                value.current = value.current - 1;
                return
            }

            if (forwardKeys.includes(key)) {
                if (value.current < 0) {
                    callEvent(AppEventEnum.KeyNavigationIndex, 0)
                    value.current = 0;
                    return
                }
                if (value.current === tamanhoLista - 1) {
                    callEvent(AppEventEnum.KeyNavigationIndex, -1)
                    value.current = -1;
                    return
                }
                callEvent(AppEventEnum.KeyNavigationIndex, value.current + 1)
                value.current = value.current + 1;
                return
            }
        } else {
            value.current = -1;
            callEvent(AppEventEnum.KeyNavigationIndex, -1)
        }
    })

    useEventListener('click', () => {
        if (value.current !== -1) {
            value.current = -1;
            callEvent(AppEventEnum.KeyNavigationIndex, -1)
        }
    })

    useEffect(() => {
        return () => {
            callEvent(AppEventEnum.KeyNavigationIndex, -1)
        }
    }, [callEvent])
}