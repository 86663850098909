import { Grid } from 'views/design-system';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { SelecaoVendedorList } from './components/selecao-vendedor-list/selecao-vendedor-list';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useEffect } from 'react';

export const MovSelecaoVendedorPage = () => {
  const classes = useDefaultCadastroStyles();
  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Selecione Vendedores',
      showDefinicoes: true,
      showMenuPdv: true,
      pesquisa: {
        allowLetras: true,
        capturarDigitacao: true
      }
    })
  }, [setHeaderProps])

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.root}>
          <Grid className={classes.list}>
            <SelecaoVendedorList />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
