import { Drawer, Grid } from "@material-ui/core";
import { useMemo } from "react";

import { DefaultModalProps } from "./default-modal-props";
import { useStyles } from "./default-modal-styles";
import { useThemeQueries } from "views/theme";

export const DefaultModal = ({ minWidth, ...props }: DefaultModalProps) => {
  const { isMobile } = useThemeQueries();
  const { ...drawerProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);

  const classes = useStyles();

  return (
    <>
      <Drawer  {...drawerProps} >
        <Grid container style={{ minWidth: isMobile ? 0 : minWidth }} className={classes.drawerContainer}>
          {props.children}
        </Grid>
      </Drawer>
    </>
  );
};
