import { CardCategoriaProps } from './card-codigo-props';
import { EditarIcon } from '../../icons/editar-icon';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from 'utils/to-decimal';
import { memo } from 'react';
import { useStyles } from './card-codigo-styles'
import classNames from 'classnames';
import { Grid, Typography } from 'views/design-system';
import { Tooltip } from '@material-ui/core';

const CardCodigo = ({
  model,
  onClick,
}: CardCategoriaProps) => {

  const defaultCardClasses = useDefaultCardStyles();
  const classes = useStyles();

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model)
        }}
        className={classes.card}
      >
        <Grid container className={defaultCardClasses.cardContent} justifyContent='space-between'>
          <Grid item md={5} xs={4}>
            <Typography color="textSecondary">
              Código
            </Typography>
            <Typography
              className={classNames('celula-grid-value', classes.overflowText, classes.weight)}
              color="textPrimary"
              variant="body1">
              {model.codigo ?? 'Sem cód'}
            </Typography>
          </Grid>
          {(model.fator && model.fator !== 0) ? (
            <Grid item xs={3}>
              <Typography color="textSecondary" >
                Fator
              </Typography>
              <Typography
                className={classNames('celula-grid-value', classes.weight)}
                color="textPrimary"
                variant="body1">
                {model.fator ?? 0}
              </Typography>
            </Grid>
          ) : null}
          {(model.precoFixo || model.percTabela) ? (
            <Grid item md={3} xs={4}>
              <Typography color="textSecondary" >
                {
                  model.precoFixo
                    ? "Preço Fixo"
                    : model.percTabela ? (model.percTabela < 0 ? "% Desconto" : "Preço %") : 'Preço'
                }
              </Typography>
              <Typography
                className={classNames('celula-grid-value', classes.weight)}
                color="textPrimary"
                variant="body1"
              >
                {
                  !model.precoFixo
                    ? !model.percTabela
                      ? 0
                      : `${Math.abs(model.percTabela)}%`
                    : 'R$' + toDecimalString(String(model.precoFixo))
                }
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Tooltip arrow title="Editar Código">
          <div style={{
            display: 'flex',
            alignItems: "center"
          }}>
            <EditarIcon tipo="BUTTON" style={{ width: 25, height: 25, marginRight: 0 }} />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default memo(CardCodigo);
