import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useFormProdutoValorCompraVendaPreCadastroValidation } from './form-produto-valor-compra-venda-pre-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { ProdutoValorVendaCompraPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-valor-compra-venda-form-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { toDecimal } from '../../../../../../utils/to-decimal';
import { toDecimalString } from 'utils/to-decimal';
import { useConfirm } from 'material-ui-confirm';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from 'views/design-system';
export interface FormProdutoValorVendaCompraPreCadastroProps
  extends DefaultFormProps<ProdutoValorVendaCompraPreCadastroFormModel> {}

export const FormProdutoValorVendaCompraPreCadastro = forwardRef<
  DefaultFormRefs<ProdutoValorVendaCompraPreCadastroFormModel>,
  FormProdutoValorVendaCompraPreCadastroProps
>(({ loading, ...props }: FormProdutoValorVendaCompraPreCadastroProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputValorCompra = useRef<HTMLInputElement>(null);
  const refInputValorVenda = useRef<HTMLInputElement>(null);
  const [initialValues, setInitialValues] =
    useState<ProdutoValorVendaCompraPreCadastroFormModel>(
      new ProdutoValorVendaCompraPreCadastroFormModel(),
    );
  const { isMobile } = useThemeQueries();
  const confirm = useConfirm();
  const { FormProdutoValorCompraVendaPreCadastroYupValidation } =
    useFormProdutoValorCompraVendaPreCadastroValidation();

  const showConfirm = useCallback(
    (campo: string) => {
      return confirm({
        title: `Campo "${campo}" sem valor!`,
        description: `Deseja manter o campo "${campo}" sem valor?`,
        cancellationText: 'Não',
        confirmationText: 'Sim',
      });
    },
    [confirm],
  );
  const calcularPLucro = useCallback((vPreco: number, vCompra: number) => {
    const pLucro = ((vPreco - vCompra) / vCompra) * 100;
    return toDecimalString(isNaN(pLucro) ? 0 : pLucro, 2);
  }, []);

  const calcularValorFinal = useCallback((vCompra: number, pLucro: number) => {
    return toDecimalString((vCompra * pLucro) / 100 + vCompra);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<ProdutoValorVendaCompraPreCadastroFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormProdutoValorCompraVendaPreCadastroYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = async (
    values: ProdutoValorVendaCompraPreCadastroFormModel,
  ) => {
    const model = picker<ProdutoValorVendaCompraPreCadastroFormModel>(
      values,
      new ProdutoValorVendaCompraPreCadastroFormModel(),
    );

    let confirmVPreco = true;

    if (model.vPreco.length === 0 || isNaN(toDecimal(model.vPreco))) {
      model.vPreco = '0';
    }
    if (model.vCompra.length === 0 || isNaN(toDecimal(model.vCompra))) {
      model.vCompra = '0';
    }

    if (toDecimal(model.vPreco) === 0) {
      await showConfirm('Valor Venda').catch(() => {
        confirmVPreco = false;
        if (!isMobile) refInputValorVenda.current?.focus();
        return;
      });
      if (!confirmVPreco) {
        return;
      }
    }
    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ProdutoValorVendaCompraPreCadastroFormModel());
      if (!isMobile) refInputValorCompra.current?.focus();
      reset();
    },
    fillForm: (model: ProdutoValorVendaCompraPreCadastroFormModel) => {
      model.vCompra = toDecimalString(model.vCompra);
      model.vPreco = toDecimalString(model.vPreco);
      model.pLucro = calcularPLucro(
        toDecimal(model.vPreco),
        toDecimal(model.vCompra),
      );

      setInitialValues(model);
      reset({ ...model });
      if (!isMobile) refInputValorCompra.current?.focus();
    },
  }));

  const calcularValores = useCallback(
    (event: any) => {
      setValue(event.target.name, event.target.value);
      const vCompra = toDecimal(
        event.target.name === 'vCompra'
          ? event.target.value
          : getValues('vCompra'),
      );
      const vPreco = toDecimal(
        event.target.name === 'vPreco'
          ? event.target.value
          : getValues('vPreco'),
      );
      const pLucro = toDecimal(
        event.target.name === 'pLucro'
          ? event.target.value
          : getValues('pLucro'),
      );

      if (event.target.name === 'pLucro') {
        setValue('vPreco', calcularValorFinal(vCompra, pLucro));
      } else {
        setValue('pLucro', calcularPLucro(vPreco, vCompra));
      }
    },
    [setValue, getValues, calcularValorFinal, calcularPLucro],
  );

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="vCompra"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    inputRef={refInputValorCompra}
                    tipo="DECIMAL"
                    manterMascara
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label="Valor de Compra"
                    showStartAdornment
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 12 }}
                    placeholder="(Opcional)"
                    error={Boolean(errors.vCompra && errors.vCompra.message)}
                    helperText={
                      touchedFields.vCompra || errors.vCompra
                        ? errors.vCompra?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => calcularValores(event)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="pLucro"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    manterMascara
                    disabled={loading}
                    tipo="DECIMAL"
                    fullWidth
                    variant="outlined"
                    label="Margem de Lucro (%)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Percentual"
                    error={Boolean(errors.pLucro && errors.pLucro.message)}
                    helperText={
                      touchedFields.pLucro || errors.pLucro
                        ? errors.pLucro?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => calcularValores(event)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="vPreco"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    inputRef={refInputValorVenda}
                    tipo="DECIMAL"
                    allowSubmit
                    manterMascara
                    disabled={loading}
                    showStartAdornment
                    fullWidth
                    variant="outlined"
                    label="Valor de Venda"
                    inputProps={{ maxLength: 12 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="(Opcional)"
                    error={Boolean(errors.vPreco && errors.vPreco.message)}
                    helperText={
                      touchedFields.vPreco || errors.vPreco
                        ? errors.vPreco?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => calcularValores(event)}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
