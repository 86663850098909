import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1)
    },
    cardVermelho: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.error.main,
    },
    cardVerde: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.success.main,
    },
    badge: {
        width: 80,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(1),
        fontSize: 11,
        padding: '2px 4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 30,
    },
    badgeLaranja: {
        background: theme.palette.warning.dark,
        color: theme.palette.primary.contrastText,
    },
    badgeVermelho: {
        background: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontWeight: 500
        },
        [theme.breakpoints.down('xs')]: {
            '& p, span': {
                fontSize: '13px'
            },
        }
    },
    iconRight: {

        [theme.breakpoints.down('sm')]: {
            display: 'flex !important',
            border: 'none'
        },
        "& svg": {
            marginTop: -8,
            marginBottom: -8,
        }
    }
}));