import { guidEmpty } from "utils/guid-empty";
import { ConvenioCartaoAutenticarModel } from "./convenio-cartao-autenticar-model";
import { ConvenioDadosAdicionaisModel } from "./convenio-dados-adicionais-model";

export class ConvenioSolicitarCreditoPostModel {
    constructor(
        public cartao: ConvenioCartaoAutenticarModel = new ConvenioCartaoAutenticarModel(),
        public tefId: string = guidEmpty(),
        public caixaId: string = guidEmpty(),
        public dhEmi: string | Date = '',
        public quantidadeParcelas: number = 0,
        public valor: number = 0,
        public dadosAdicionais: ConvenioDadosAdicionaisModel = new ConvenioDadosAdicionaisModel(),
        public infAdic: string = '',
        public colImpViaCliente: number = 0,
    ) { }
}