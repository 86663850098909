import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: 'center'
    },
    header: {
        flex: "0 1 auto"
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    containerCancelamento: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        overflow: 'auto',
        display: 'flex'
    },
    content: {
        maxWidth: '650px',
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: theme.spacing(1.5)
    },
    contentPC: {
        width: '650px',
        padding: theme.spacing(1.5),
        alignItems: 'center'
    },
    infoContainer: {
        display: "flex",
        flexDirection: 'column',
        flex: 1
    },
    infoContainerPC: {
        display: "flex",
        flexDirection: 'column',
        marginBottom: 4
    },
    infoContent: {
        backgroundColor: '#FFF',
        borderRadius: 15,
        padding: 16,
        margin: '4px 0',
        boxShadow: "1px 1px 2px rgb(0 0 0 / 10%)"
    },
    textTitle: {
        fontWeight: 700,
        lineHeight: "42px",
        fontSize: "2rem",
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    infoItemValor: {
        fontSize: '1.8rem',
        fontWeight: 700,
        lineHeight: '30px',
        maxWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    textSubtitle: {
        fontWeight: 500,
        lineHeight: "23px",
        fontSize: "1rem",
        color: theme.palette.grey[500],
        marginBottom: theme.spacing(2),
    },
    cardInfo: {
        width: "100%",
        backgroundColor: "#fff",
        "box-shadow": "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
        borderRadius: theme.spacing(1),
        padding: '12px'
    },
    label: {
        fontSize: '0.750rem',
        color: '#BCBCBC',
        lineHeight: '14,1px'
    },
    infoNomeEQuantidade: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '23px',
        maxWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    divider: {
        marginTop: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
    },
    infoTotal: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '21px',
        color: '#8D8D8D',
        marginTop: theme.spacing(2)
    },
    containerButton: {
        boxSizing: 'border-box',
        width: '100%',
        borderTop: '1px solid #ECEDED',
        padding: '0 16px',
        maxWidth: 650,
        display: 'flex',
        justifyContent: 'center'
    },
    labelVermelho: {
        fontSize: '0.9rem',
        color: '#F44336',
        lineHeight: '14,1px',
        fontWeight: 700,
    },
    labelVerde: {
        fontSize: '0.9rem',
        fontWeight: 700,
        color: '#10C822',
        lineHeight: '14,1px'
    }
}));