import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { CompartilhamentoDadosWebHookModel } from "model/api/gestao/compartilhamento-dados-webhook/compartilhamento-dados-webhook-model";
import { useSessaoAtual } from "services/app";
import { VariaveisAmbiente } from "config";

export function usePutCompartilhamentoDadosWebHook() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getPessoa } = useSessaoAtual()

  const putCompartilhamentoDadosWebHook = useCallback(
    (dados: CompartilhamentoDadosWebHookModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/contrato/${getPessoa().pessoa.contratoId}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(dados),
        enviarTokenUsuario: true
      }),
    [getPessoa, invocarApi]
  );
  return {
    ...outrasPropriedades,
    putCompartilhamentoDadosWebHook,
  };
}
