import { useCallback, useEffect, useMemo } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { PessoaHeader } from './components/pessoa-header/pessoa-header';
import { PessoaList } from './components/pessoa-list/pessoa-list';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import { useCadastros } from 'services/app';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { Grid } from 'views/design-system';

export const PessoaPage = () => {
  const classes = useDefaultCadastroStyles();
  const { location } = useHistory();
  const { abrirCadastroPessoa } = useCadastros();
  const { callTour } = useTourSaurus()

  useEffect(() => {
    callTour(EnumTour.CADASTROPESSOAS)
  }, [callTour])

  const onClickAdd = useCallback(() => {
    abrirCadastroPessoa('', location.pathname, true)
  }, [abrirCadastroPessoa, location.pathname]);

  const component = useMemo(() => <>
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <PessoaHeader />
      </Grid>
      <Grid className={classes.list}>
        <PessoaList />
      </Grid>
      <ButtonFab
        id='tour-cadPessoas'
        tooltip="Adicionar Pessoa"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  </>, [classes.header, classes.list, classes.root, onClickAdd]);

  return component;
};

export default PessoaPage;
