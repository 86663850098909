import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { DefaultModal } from '../components/default-modal/default-modal';
import { PromocaoRegraEdicao } from './promocao-regra-edicao/promocao-regra-edicao';

export interface PromocaoRegraModalProps {
    id: string;
    tipoRegra: EnumPromocaoTipoRegra;
    promocaoId: string;
    openned?: boolean;
    aux?: any;
}
export const PromocaoRegraModal = (props: PromocaoRegraModalProps) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            <PromocaoRegraEdicao id={props.id} tipoRegra={props.tipoRegra} promocaoId={props.promocaoId} />
        </DefaultModal>
    );
};
