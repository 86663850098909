import { Button, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import {
  SalvarEditarIcon,
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useHistory } from 'react-router-dom';
import { usePostProdutoFiscal } from '../../../../../../data/api/gestao/produto-fiscal/post-produto-fiscal';
import { ProdutoFiscalModel } from '../../../../../../model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { ProdutoFiscalCadastroFormModel } from '../../../../../../model/app/forms/produto-fiscal/produto-fiscal-cadastro-form-model';
import { FormProdutoFiscalCadastro } from '../../../../form/produto-fiscal/form-produto-fiscal-cadastro';
import classNames from 'classnames';
import { guidEmpty } from 'utils/guid-empty';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { ModBcIcmsMock } from 'data/mocks/modbc-icms-mock';

export const ProdutoFiscalCadastro = (props: {
  empresaId: string;
  contratoId: string;
}) => {
  const { postProdutoFiscal, carregando: carregandoPost } =
    usePostProdutoFiscal();
  const { getConsumidor } = useCadastroPadrao();
  const { fecharCadastroProdutoFiscal, abrirCadastroProdutoFiscal } =
    useCadastros();
  const cadProdutoFiscalFormRef =
    useRef<DefaultFormRefs<ProdutoFiscalCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);
  const classes = useModalStyles();
  const carregando = carregandoPost;

  useEffect(() => {
    cadProdutoFiscalFormRef.current?.fillForm(new ProdutoFiscalModel());
  }, []);

  const history = useHistory();
  const pathName = history.location.pathname;

  const { showToast } = useToastSaurus();
  const saveNewProdutoFiscal = useCallback(
    async (model: ProdutoFiscalModel) => {
      try {
        if (model.regimeTributarioId === guidEmpty()) {
          const consumidorPadrao = await getConsumidor();
          model.regimeTributarioId =
            consumidorPadrao?.regimeTributarioId || guidEmpty();
        }
        const ret = await postProdutoFiscal(model);

        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          abrirCadastroProdutoFiscal(ret.resultado?.data.id, pathName, true);
        } else {
          cadProdutoFiscalFormRef.current?.resetForm();
        }
        if (!redirect.current) {
          showToast('success', 'Grupo de Impostos adicionado com sucesso!', );
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadProdutoFiscalFormRef.current?.resetForm();
      }
    },
    [
      postProdutoFiscal,
      showToast,
      getConsumidor,
      abrirCadastroProdutoFiscal,
      pathName,
    ],
  );
  const handleSubmit = useCallback(
    async (modelN: ProdutoFiscalCadastroFormModel) => {
      let produtoFiscalToCreate = picker<ProdutoFiscalModel>(
        modelN,
        new ProdutoFiscalModel(),
      );
      produtoFiscalToCreate.empresaId = props.empresaId;
      produtoFiscalToCreate.contratoId = props.contratoId;

      produtoFiscalToCreate.icmsModBc = ModBcIcmsMock.length > 1 ? 0 : 3

      produtoFiscalToCreate.icmsModBcSt = 4;
      produtoFiscalToCreate.infComplementar = '';
      return saveNewProdutoFiscal(produtoFiscalToCreate);
    },
    [saveNewProdutoFiscal, props.contratoId, props.empresaId],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadProdutoFiscalFormRef.current?.submitForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroProdutoFiscal();
  }, [fecharCadastroProdutoFiscal]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Cadastro de Grupo de Impostos'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormProdutoFiscalCadastro
            ref={cadProdutoFiscalFormRef}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default ProdutoFiscalCadastro;
