import { makeStyles } from "@material-ui/core";

export const useDefaultMovimentacaoStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        minHeight: '100vh',
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        flex: "0 1 auto",
        width: '100%'
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto"
    }, defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
}));
