import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model"
import { AcrescimoItemModel, AcrescimoPagamentoModel, DescontoItemModel, DescontoPagamentoModel, VendaAcrescimoModel, VendaDescontoModel } from "model/api/gestao/venda/venda-desconto-model"
import { AdicionarDescontoAcrescimoFormModel } from "model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model"
import { useCallback } from "react"
import { useSessaoAtual } from "../providers";
import { useToastSaurus } from "./toast-saurus";
import { useMovAtual } from "./mov-atual";
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo";
import { toDecimal } from "utils/to-decimal";
import { useContratoAtual } from "./contrato-atual";
import { EnumContratoConfig } from "model/enums/enum-contrato-config";
import { EnumTipoDesconto } from "model/enums/enum-tipo-desconto";
import { isEmpty } from "lodash";
import { roundTo } from "utils/round-to";
import { useConfirmSaurus } from "./confirm-saurus";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { EnumAcrescimoDesconto } from "model/enums/enum-acrescimo-desconto";
import { useCadastros } from "./cadastros";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";

interface RetPermissao {
    usuarioId: string;
    usuarioNome: string;
}

type CodigosPermissaoDesconto = (
    EnumCodigosPermissoes.ACRESCIMO_VENDA |
    EnumCodigosPermissoes.DESCONTO_VENDA |
    EnumCodigosPermissoes.ACRESCIMO_ITEM |
    EnumCodigosPermissoes.DESCONTO_ITEM
)


export const useDescontoAcrescimo = () => {

    const { usuario: usuarioLogado, getPermissaoDesconto, getPermissaoBoolean } = useSessaoAtual();
    const { showToast } = useToastSaurus();
    const { getMov, alterarProdutos } = useMovAtual();
    const { getConfigByCod } = useContratoAtual();
    const { showConfirm } = useConfirmSaurus();
    const { abrirSolicitarPermissao } = useCadastros();

    const retornaTipoDesconto = useCallback((desconto: VendaDescontoModel) => {
        let tipo = EnumTipoDesconto.Pagamento;

        if (desconto?.item)
            if (desconto.item.valorDesconto > 0) tipo = EnumTipoDesconto.Item;

        if (desconto.promocao)
            if (desconto.promocao.valorDesconto > 0) tipo = EnumTipoDesconto.Promocao;

        return tipo;
    }, [])

    const retornaTipoAcrescimo = useCallback((acrescimo: VendaAcrescimoModel) => {
        let tipo = EnumTipoDesconto.Pagamento;

        if (acrescimo.item)
            if (acrescimo.item.valorAcrescimo > 0) tipo = EnumTipoDesconto.Item;

        return tipo;
    }, [])


    const recalcularDescontoVenda = useCallback(async (
        mov: MovSimplesModel | undefined = undefined,
        desconto: DescontoPagamentoModel,
        tpDesc: EnumTpDescontoAcrescimo,
    ) => {
        const venda = mov || getMov()!;
        if (!desconto.percentualDesconto && !desconto.valorDesconto) {
            const prods = venda.produtos.map(prod => {
                prod.descontos = prod.descontos.filter(desc => retornaTipoDesconto(desc) !== EnumTipoDesconto.Pagamento);

                return prod;
            })

            await alterarProdutos(prods);
            return;
        }

        const servico = getConfigByCod(EnumContratoConfig.ProdutoServico);
        const ret = venda!.produtos.filter((x) => x.produtoId === servico);

        const prods = venda.produtos.map(item => {
            let vAtualVenda = venda.vNF;
            const produtoServico = ret.find((x) => x.id === item.id);
            if (!isEmpty(produtoServico) || !item.ativo || !item.indFin) {
                item.descontos = item.descontos.filter(desc => retornaTipoDesconto(desc) !== EnumTipoDesconto.Pagamento);
                return item;
            }

            const outrosDescontos = item.descontos.filter(desc => retornaTipoDesconto(desc) !== EnumTipoDesconto.Pagamento) || [];

            const outrosDescontosValor = outrosDescontos.reduce((prev, curr) => {
                switch (retornaTipoDesconto(curr)) {
                    case EnumTipoDesconto.Item:
                        return curr.item!.valorDesconto + prev;
                    default:
                        return curr.promocao!.valorDesconto + prev;
                }
            }, 0)

            let possuiDesconto = outrosDescontos.length > 0;
            let vItem = possuiDesconto ? item.vProd - outrosDescontosValor : item.vProd;
            item.descontos = item.descontos.map((desc) => {
                if (retornaTipoDesconto(desc) !== EnumTipoDesconto.Pagamento || !desc.pagamento) {
                    return desc;
                }
                vItem = vItem - desc.pagamento.valorDesconto;

                return desc;
            })


            const vDesconto = tpDesc === EnumTpDescontoAcrescimo.ValorFixo ? desconto.valorDesconto : desconto.percentualDesconto;

            let descontoCalculado = 0;
            let percCalculado = 0;
            if (tpDesc === EnumTpDescontoAcrescimo.Porcentagem) {
                //nesse cenario desconto é percentual, então vDesconto é a porcentagem final
                descontoCalculado = roundTo(vItem * (vDesconto / 100));
                const descontoVenda = roundTo(vAtualVenda * (vDesconto / 100));
                percCalculado = vDesconto;

                vAtualVenda -= descontoVenda;
            } else {
                //aqui o desconto é um valor fixo, então preciso recalcular o percentual caso valor da venda mude
                percCalculado = (vDesconto * 100) / vAtualVenda;
                descontoCalculado = roundTo(vItem * (percCalculado / 100));

                vAtualVenda -= vDesconto;
            }


            const novoDesconto = new VendaDescontoModel();

            novoDesconto.pagamento = new DescontoPagamentoModel();

            novoDesconto.pagamento.valorDesconto = descontoCalculado;
            if (tpDesc === EnumTpDescontoAcrescimo.Porcentagem) novoDesconto.pagamento.percentualDesconto = vDesconto;
            novoDesconto.pagamento.usuarioId = desconto.usuarioId;
            novoDesconto.pagamento.usuarioNome = desconto.usuarioNome;
            novoDesconto.pagamento.finalizadoraId = desconto.finalizadoraId;
            novoDesconto.pagamento.finalizadoraNome = desconto.finalizadoraNome;

            vItem -= descontoCalculado;

            item.descontos.push(novoDesconto)

            return item;
        })
        // Arredondar diferenças
        const valorVendaComDescontos = venda.vNF - venda.vDesc - venda.vDescEmbutido - desconto.valorDesconto;

        const valorTotalDescontosProdutos = prods.reduce((soma, prod) => {
            const produtoServico = ret.find((x) => x.id === prod.id);
            if (!isEmpty(produtoServico) || !prod.ativo || !prod.indFin) {
                return soma;
            }
            const descs = prod.descontos.filter(desc => retornaTipoDesconto(desc) === EnumTipoDesconto.Pagamento)
            const v = descs[descs.length - 1]?.pagamento?.valorDesconto || 0;
            if (v) return v + soma;
            return soma;
        }, 0)
        const valorVendaComDescontodProd = venda.vNF - venda.vDesc - venda.vDescEmbutido - valorTotalDescontosProdutos;

        if (valorVendaComDescontodProd !== valorVendaComDescontos) {
            const diferenca = valorVendaComDescontos - valorVendaComDescontodProd;

            const prodsAtivos = prods.filter(item => {
                const produtoServico = ret.find((x) => x.id === item.id);
                if (isEmpty(produtoServico) && item.ativo && item.indFin) {
                    return true;
                }
                return false;
            })

            const ultimoProduto = prodsAtivos[prodsAtivos.length - 1];

            const indexProduto = prods.indexOf(ultimoProduto);

            let ultimoDesconto: VendaDescontoModel | VendaDescontoModel[] =
                ultimoProduto.descontos.filter(desc => retornaTipoDesconto(desc) === EnumTipoDesconto.Pagamento);

            ultimoDesconto = ultimoDesconto[ultimoDesconto.length - 1];

            if (ultimoDesconto.pagamento) {
                const index = ultimoProduto.descontos.indexOf(ultimoDesconto);

                ultimoDesconto.pagamento.valorDesconto = roundTo(ultimoDesconto.pagamento.valorDesconto - diferenca);

                ultimoProduto.descontos[index] = ultimoDesconto;


                prods[indexProduto] = ultimoProduto;
            }
        }
        await alterarProdutos(prods);

    }, [alterarProdutos, getConfigByCod, getMov, retornaTipoDesconto])



    const recalcularAcrescimoVenda = useCallback(async (
        mov: MovSimplesModel | undefined = undefined,
        acrescimo: AcrescimoPagamentoModel,
        tpAcresc: EnumTpDescontoAcrescimo,
    ) => {
        const venda = mov || getMov()!;
        if (!acrescimo.valorAcrescimo && !acrescimo.percentualAcrescimo) {
            const prods = venda.produtos.map(prod => {
                prod.acrescimos = prod.acrescimos.filter(acresc => retornaTipoAcrescimo(acresc) !== EnumTipoDesconto.Pagamento);
                return prod;
            })

            await alterarProdutos(prods);
            return;
        }
        const servico = getConfigByCod(EnumContratoConfig.ProdutoServico);
        const ret = venda!.produtos.filter((x) => x.produtoId === servico);


        const prods = venda!.produtos.map(item => {
            let vAtualVenda = venda.vNF;
            const produtoServico = ret.find((x) => x.id === item.id);
            if (!isEmpty(produtoServico) || !item.ativo || !item.indFin) {
                item.acrescimos = item.acrescimos.filter(acresc => retornaTipoAcrescimo(acresc) !== EnumTipoDesconto.Pagamento);
                return item;
            }

            const outrosAcrescimos = item.acrescimos.filter(acresc => retornaTipoAcrescimo(acresc) !== EnumTipoDesconto.Pagamento) || [];

            const outrosAcrescimossValor = outrosAcrescimos.reduce((prev, curr) => {
                if (retornaTipoAcrescimo(curr) === EnumTipoDesconto.Item) {
                    return curr.item!.valorAcrescimo + prev;
                }
                return prev;
            }, 0)

            let possuiAcrescimo = outrosAcrescimos.length > 0;
            let vItem = possuiAcrescimo ? item.vProd + outrosAcrescimossValor : item.vProd;
            item.acrescimos = item.acrescimos.map((acresc, i) => {
                if (retornaTipoAcrescimo(acresc) !== EnumTipoDesconto.Pagamento || !acresc.pagamento) {
                    return acresc;
                }

                vItem = vItem + acresc.pagamento.valorAcrescimo;

                return acresc;

            })

            const vAcrescimo = tpAcresc === EnumTpDescontoAcrescimo.ValorFixo ? acrescimo.valorAcrescimo : acrescimo.percentualAcrescimo;

            let acrescimoCalculado = 0;
            let percCalculado = 0;
            if (tpAcresc === EnumTpDescontoAcrescimo.Porcentagem) {
                //nesse cenario desconto é percentual, então vDesconto é a porcentagem final
                acrescimoCalculado = roundTo(vItem * (vAcrescimo / 100));
                const acrescimoVenda = roundTo(vAtualVenda * (vAcrescimo / 100));
                percCalculado = vAcrescimo;

                vAtualVenda += acrescimoVenda;
            } else {
                //aqui o desconto é um valor fixo, então preciso recalcular o percentual caso valor da venda mude
                percCalculado = (vAcrescimo * 100) / vAtualVenda;
                acrescimoCalculado = roundTo(vItem * (percCalculado / 100));

                vAtualVenda += vAcrescimo;
            }

            const novoAcrescimo = new VendaAcrescimoModel();

            novoAcrescimo.pagamento = new AcrescimoPagamentoModel();

            novoAcrescimo.pagamento.valorAcrescimo = acrescimoCalculado;
            if (tpAcresc === EnumTpDescontoAcrescimo.Porcentagem) novoAcrescimo.pagamento.percentualAcrescimo = vAcrescimo;
            novoAcrescimo.pagamento.usuarioId = acrescimo.usuarioId;
            novoAcrescimo.pagamento.usuarioNome = acrescimo.usuarioNome;
            novoAcrescimo.pagamento.finalizadoraId = acrescimo.finalizadoraId;
            novoAcrescimo.pagamento.finalizadoraNome = acrescimo.finalizadoraNome;
            novoAcrescimo.pagamento.embutido = false;

            vItem = vItem + acrescimoCalculado;

            item.acrescimos.push(novoAcrescimo)

            return item;
        })

        // Arredondar diferenças
        const valorTotalAcrescimos = venda.vNF + venda.vOutro + venda.vOutroEmbutido + acrescimo.valorAcrescimo;

        const valorAcrescimosProdutos = prods.reduce((soma, prod) => {
            const produtoServico = ret.find((x) => x.id === prod.id);
            if (produtoServico || !prod.ativo || !prod.indFin) {
                return soma;
            }
            const acrescs = prod.acrescimos.filter(acresc => retornaTipoAcrescimo(acresc) === EnumTipoDesconto.Pagamento)
            const v = acrescs[acrescs.length - 1].pagamento?.valorAcrescimo;
            if (v) return v + soma;
            return soma;
        }, 0)

        const valorTotalComAcrescimosProdutos = venda.vNF + venda.vOutro + venda.vOutroEmbutido + valorAcrescimosProdutos
        if (valorTotalAcrescimos !== valorTotalComAcrescimosProdutos) {
            const diferenca = valorTotalComAcrescimosProdutos - valorTotalAcrescimos;

            const prodsAtivos = prods.filter(item => {
                const produtoServico = ret.find((x) => x.id === item.id);
                if (isEmpty(produtoServico) && item.ativo && item.indFin) {
                    return true;
                }
                return false;
            })

            const ultimoProduto = prodsAtivos[prodsAtivos.length - 1];

            const indexProduto = prods.indexOf(ultimoProduto);

            let ultimoAcrescimo: VendaAcrescimoModel | VendaAcrescimoModel[] =
                ultimoProduto.acrescimos.filter(desc => retornaTipoAcrescimo(desc) === EnumTipoDesconto.Pagamento);

            ultimoAcrescimo = ultimoAcrescimo[ultimoAcrescimo.length - 1];

            if (ultimoAcrescimo.pagamento) {
                const index = ultimoProduto.acrescimos.indexOf(ultimoAcrescimo);

                ultimoAcrescimo.pagamento.valorAcrescimo = roundTo(ultimoAcrescimo.pagamento.valorAcrescimo - diferenca);

                ultimoProduto.acrescimos[index] = ultimoAcrescimo;

                prods[indexProduto] = ultimoProduto;
            }
        }
        venda.produtos = prods;

        await alterarProdutos(prods);

    }, [alterarProdutos, getConfigByCod, getMov, retornaTipoAcrescimo])

    const permissaoAcrescimoDesconto = useCallback((
        vTotal: number,
        valor: number,
        tipoOperacao: EnumAcrescimoDesconto,
        tpDesconto: EnumTpDescontoAcrescimo,
        codigo: CodigosPermissaoDesconto
    ) => new Promise<RetPermissao>((resolve, reject) => {
        if (tipoOperacao === EnumAcrescimoDesconto.ACRESCIMO) {
            if (getPermissaoBoolean(codigo)) {
                resolve({ usuarioNome: usuarioLogado!.saudacao, usuarioId: usuarioLogado!.usuarioId });
                return
            }
            const msg = codigo === EnumCodigosPermissoes.ACRESCIMO_ITEM ? 'aplicar acréscimo no item' : 'aplicar acréscimo na venda total';
            abrirSolicitarPermissao(async (_, user) => {
                resolve({ usuarioNome: user.saudacao, usuarioId: user.usuarioId });
            }, codigo,
                msg,
                undefined,
                vTotal,
                () => {
                    reject('Você não possuí permissão para ' + msg + '.');
                }
            )
        } else {
            if (getPermissaoDesconto(codigo, valor, tpDesconto, vTotal)) {
                resolve({ usuarioNome: usuarioLogado!.saudacao, usuarioId: usuarioLogado!.usuarioId });
                return
            }
            const msg = codigo === EnumCodigosPermissoes.DESCONTO_ITEM ? 'aplicar desconto no item' : 'aplicar desconto na venda total';
            abrirSolicitarPermissao(async (_, user) => {
                resolve({ usuarioNome: user.saudacao, usuarioId: user.usuarioId });
            }, codigo,
                msg,
                undefined,
                vTotal,
                () => {
                    reject('Você não possuí permissão para ' + msg + '.');
                }
            )
        }
    }), [abrirSolicitarPermissao, getPermissaoBoolean, getPermissaoDesconto, usuarioLogado])


    const adicionarAcrescimoDesconto = useCallback(
        async (
            tipoOperacao: EnumAcrescimoDesconto,
            model: AdicionarDescontoAcrescimoFormModel,
        ) => {
            const venda = getMov();

            const user: RetPermissao = { usuarioNome: '', usuarioId: '' }

            if (!venda) {
                throw new Error('Não há uma venda em andamento');
            }

            if (tipoOperacao === EnumAcrescimoDesconto.DESCONTO) {
                let porcentagemDesconto = 0;
                let totalProdutosComDesc = venda.vNF;

                //Valida se está trabalhando com valor fixo ou porcentagem
                if (model.tpCampo === EnumTpDescontoAcrescimo.ValorFixo) {
                    let valorTotalVenda = totalProdutosComDesc ?? 0;
                    porcentagemDesconto =
                        ((model.vValorFixo ?? 0) / valorTotalVenda) * 100;
                } else if (model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem) {
                    porcentagemDesconto = model.vPorcentagem ?? 0;
                } else {
                    porcentagemDesconto = 0;
                }
                const valorDesconto = roundTo((venda.vNF * porcentagemDesconto) / 100)
                try {
                    const ret = await permissaoAcrescimoDesconto(
                        venda.vProd,
                        model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem ? porcentagemDesconto : valorDesconto,
                        tipoOperacao,
                        model.tpCampo,
                        EnumCodigosPermissoes.DESCONTO_VENDA
                    )
                    user.usuarioNome = ret.usuarioNome;
                    user.usuarioId = ret.usuarioId;
                } catch (e: any) {
                    throw e;
                }

                if (valorDesconto >= (venda.vNF - venda.vPago)) {
                    throw new Error('Desconto é igual ou maior que o valor total a ser pago!')
                }
                const modeloDesconto: DescontoPagamentoModel = {
                    ...new DescontoPagamentoModel(),
                    usuarioId: user.usuarioId,
                    usuarioNome: user.usuarioNome,
                    percentualDesconto: porcentagemDesconto,
                    valorDesconto: valorDesconto
                }
                await recalcularDescontoVenda(venda, modeloDesconto, model.tpCampo);
                return;
            }

            let porcentagemAcrescimo = 0;
            let totalProdutosComAcresc = venda.vNF;

            //Valida se está trabalhando com valor fixo ou porcentagem
            if (model.tpCampo === EnumTpDescontoAcrescimo.ValorFixo) {
                let valorTotalVenda = totalProdutosComAcresc ?? 0;
                porcentagemAcrescimo =
                    ((model.vValorFixo ?? 0) / valorTotalVenda) * 100;
            } else if (model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem) {
                porcentagemAcrescimo = model.vPorcentagem ?? 0;
            } else {
                porcentagemAcrescimo = 0;
            }

            const valorAcrescimo = roundTo((venda.vNF * porcentagemAcrescimo) / 100)

            try {
                const ret = await permissaoAcrescimoDesconto(
                    venda.vProd,
                    model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem ? porcentagemAcrescimo : valorAcrescimo,
                    tipoOperacao,
                    model.tpCampo,
                    EnumCodigosPermissoes.ACRESCIMO_VENDA
                )

                user.usuarioNome = ret.usuarioNome;
                user.usuarioId = ret.usuarioId;
            } catch (e: any) {
                throw e
            }

            const modeloAcrescimo: AcrescimoPagamentoModel = {
                ...new AcrescimoPagamentoModel(),
                usuarioId: user.usuarioId,
                usuarioNome: user.usuarioNome,
                percentualAcrescimo: porcentagemAcrescimo,
                valorAcrescimo: valorAcrescimo,
            }
            await recalcularAcrescimoVenda(venda, modeloAcrescimo, model.tpCampo);
        },
        [getMov, permissaoAcrescimoDesconto, recalcularAcrescimoVenda, recalcularDescontoVenda]
    );
    const adicionarAcrescimoDescontoVenda = useCallback(async (
        tipoOperacao: EnumAcrescimoDesconto,
        model: AdicionarDescontoAcrescimoFormModel,
        fecharDialog: () => void,
    ) => {
        const mov = getMov();
        try {
            if (!mov) throw new Error('Venda não encontrada');
            if (tipoOperacao === EnumAcrescimoDesconto.DESCONTO) {

                const hasAcrescimo = mov.produtos.some(x => x.acrescimos.some(y => y.pagamento && y.pagamento?.valorAcrescimo));

                if (hasAcrescimo) {
                    try {
                        await showConfirm({
                            title: 'Acrescimo na venda',
                            description: 'Aplicar um desconto na venda irá remover o acréscimo aplicado anteriormente. Deseja aplicar o desconto?',
                            primaryButtonText: 'Aplicar',
                            secondaryButtonText: 'Cancelar',
                        })
                        //CHAMO ABAIXO PARA LIMPAR OS DESCONTOS
                        await adicionarAcrescimoDesconto(EnumAcrescimoDesconto.ACRESCIMO, {
                            tpCampo: EnumTpDescontoAcrescimo.ValorFixo,
                            vPorcentagem: 0,
                            vValorFixo: 0,
                        })

                        await adicionarAcrescimoDesconto(tipoOperacao, model);

                    } catch (e: any) {
                        fecharDialog();
                        return
                    }
                } else {
                    await adicionarAcrescimoDesconto(tipoOperacao, model);
                }

                showToast(
                    "success",
                    `Desconto de ${model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem
                        ? `${model.vPorcentagem}%`
                        : `R$ ${toDecimal(model.vValorFixo)}`
                    }
                foi atribuído na venda total.`
                );
            } else {
                const hasDescontos = mov.produtos.some(x => x.descontos.some(y => y.pagamento && y.pagamento?.valorDesconto));

                if (hasDescontos) {
                    try {
                        await showConfirm({
                            title: 'Desconto na venda',
                            description: 'Aplicar um acréscimo na venda irá remover o desconto aplicado anteriormente. Deseja aplicar o acréscimo?',
                            primaryButtonText: 'Aplicar',
                            secondaryButtonText: 'Cancelar',
                        })

                        //CHAMO ABAIXO PARA LIMPAR OS ACRESCIMOS
                        await adicionarAcrescimoDesconto(EnumAcrescimoDesconto.DESCONTO, {
                            tpCampo: EnumTpDescontoAcrescimo.ValorFixo,
                            vPorcentagem: 0,
                            vValorFixo: 0,
                        })

                        await adicionarAcrescimoDesconto(tipoOperacao, model);

                    } catch (e: any) {
                        fecharDialog();
                        return
                    }
                } else {
                    await adicionarAcrescimoDesconto(tipoOperacao, model);
                }

                showToast(
                    "success",
                    `Acréscimo de ${model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem
                        ? `${model.vPorcentagem}%`
                        : `R$ ${toDecimal(model.vValorFixo)}`
                    }
                  foi atribuído na venda total.`
                );
            }

            fecharDialog();
        } catch (e: any) {
            showToast('error', e.message)
        }

    }, [adicionarAcrescimoDesconto, getMov, showConfirm, showToast])


    //DESCONTOS E ACRESCIMOS NO ITEM
    const adicionarAcrescimoDescontoItem = useCallback(async (
        tipoOperacao: EnumAcrescimoDesconto,
        produto: MovSimplesProdutoModel,
        model: AdicionarDescontoAcrescimoFormModel,
    ) => {
        let user = {
            usuarioId: usuarioLogado!.usuarioId,
            usuarioNome: usuarioLogado!.saudacao
        }
        if (tipoOperacao === EnumAcrescimoDesconto.ACRESCIMO) {
            const adicionarInfoAcrescimo = (item: MovSimplesProdutoModel, vAcresc: number) => {
                let acresc: VendaAcrescimoModel | undefined;

                if (vAcresc === 0) {
                    item.acrescimos = item.acrescimos.filter(x => retornaTipoAcrescimo(x) !== EnumTipoDesconto.Item);
                    return item
                }

                item.descontos = item.descontos.filter(desc => retornaTipoDesconto(desc) !== EnumTipoDesconto.Item);

                if (item.acrescimos.length > 0) {
                    acresc = item.acrescimos.find(x => retornaTipoAcrescimo(x) === EnumTipoDesconto.Item);
                    if (acresc && acresc.item) {
                        const index = item.acrescimos.indexOf(acresc);

                        acresc.item.usuarioId = user.usuarioId;
                        acresc.item.usuarioNome = user.usuarioNome;
                        acresc.item.valorAcrescimo = vAcresc;
                        acresc.item.percentualAcrescimo = model.vPorcentagem || roundTo((item.vProd / vAcresc) * 100);
                        acresc.item.embutido = true;

                        item.acrescimos[index] = acresc;

                        return item;
                    }
                }

                acresc = new VendaAcrescimoModel();

                acresc.item = new AcrescimoItemModel();

                acresc.item.usuarioId = user.usuarioId;
                acresc.item.usuarioNome = user.usuarioNome;
                acresc.item.valorAcrescimo = vAcresc;
                acresc.item.percentualAcrescimo = roundTo(model.vPorcentagem || (item.vProd / vAcresc) * 100);

                item.acrescimos.push(acresc);

                return item;
            }

            let newState = Object.assign({}, produto);
            let vAcresc = 0;
            if (model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem) {
                const valorDesconto = roundTo((newState.vUnCom * newState.qCom) * (model.vPorcentagem! / 100), 2);

                vAcresc = roundTo(valorDesconto);

            } else {
                vAcresc = roundTo((model.vValorFixo ?? 0), 2);

            }

            try {
                const ret = await permissaoAcrescimoDesconto(
                    produto.vProd,
                    model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem ? (model.vPorcentagem || 0) : (model.vValorFixo || 0),
                    EnumAcrescimoDesconto.ACRESCIMO,
                    model.tpCampo,
                    EnumCodigosPermissoes.ACRESCIMO_ITEM
                )

                user.usuarioId = ret.usuarioId;
                user.usuarioNome = ret.usuarioNome
            } catch (e: any) {
                throw e;
            }


            newState = adicionarInfoAcrescimo(newState, vAcresc);

            return newState;
        }

        const adicionarInfoDesconto = (item: MovSimplesProdutoModel, vDesc: number) => {
            let desc: VendaDescontoModel | undefined;

            if (vDesc === 0) {
                item.descontos = item.descontos.filter(x => retornaTipoDesconto(x) !== EnumTipoDesconto.Item);
                return item
            }

            item.acrescimos = item.acrescimos.filter(acresc => retornaTipoAcrescimo(acresc) !== EnumTipoDesconto.Item);

            if (item.descontos.length > 0) {
                desc = item.descontos.find(x => retornaTipoDesconto(x) === EnumTipoDesconto.Item);
                if (desc && desc.item) {
                    const index = item.descontos.indexOf(desc);

                    desc.item.usuarioId = user?.usuarioId || usuarioLogado!.usuarioId;
                    desc.item.usuarioNome = user?.usuarioNome || usuarioLogado!.saudacao;
                    desc.item.valorDesconto = vDesc;
                    desc.item.percentualDesconto = model.vPorcentagem || roundTo((item.vProd / vDesc) * 100);

                    item.descontos[index] = desc;

                    return item;
                }
            }

            desc = new VendaDescontoModel();

            desc.item = new DescontoItemModel();


            desc.item.usuarioId = user?.usuarioId || usuarioLogado!.usuarioId;
            desc.item.usuarioNome = user?.usuarioNome || usuarioLogado!.saudacao;
            desc.item.valorDesconto = vDesc;
            desc.item.percentualDesconto = roundTo(model.vPorcentagem || (item.vProd / vDesc) * 100);

            item.descontos.push(desc);

            return item;
        }

        let newState = Object.assign({}, produto);
        let vDesc = 0;
        if (model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem) {
            const valorDesconto = roundTo((newState.vUnCom * newState.qCom) * (model.vPorcentagem! / 100), 2);

            vDesc = roundTo(valorDesconto);

        } else {
            vDesc = roundTo((model.vValorFixo ?? 0), 2);

        }

        if ((vDesc ?? 0) >= (produto.vProd ?? 0)) {
            throw new Error('O valor do desconto não pode ser maior ou igual ao valor do produto.')
        }

        try {
            const ret = await permissaoAcrescimoDesconto(
                produto.vProd,
                model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem ? (model.vPorcentagem || 0) : (model.vValorFixo || 0),
                EnumAcrescimoDesconto.DESCONTO,
                model.tpCampo,
                EnumCodigosPermissoes.DESCONTO_ITEM
            )
            user.usuarioId = ret.usuarioId;
            user.usuarioNome = ret.usuarioNome || ''
        } catch (e: any) {
            throw e;
        }

        newState = adicionarInfoDesconto(newState, vDesc);


        return newState;

    }, [permissaoAcrescimoDesconto, retornaTipoAcrescimo, retornaTipoDesconto, usuarioLogado])

    //


    //retorna descontos feitos apenas nos produtos
    const retornaDescontoDosProdutos = useCallback((produtos: MovSimplesProdutoModel[]) => {
        const produtosAtivos = produtos.filter(produto => produto.ativo === true)
        const listaDeDescontos = produtosAtivos
            .flatMap(produto => produto.descontos)
            .filter(desconto => (desconto.item?.valorDesconto ?? 0) > 0)
            .map(valor => (valor.item?.valorDesconto ?? 0))
            .reduce((acc, current) => acc + current, 0)

        return listaDeDescontos
    }, [])


    //Confirmar se essa função pode ser substituida pela prop vDescEmbutido da movSimples
    const retornaDescontoDasPromocoes = useCallback((produtos: MovSimplesProdutoModel[]) => {
        const produtosAtivos = produtos.filter(produto => produto.ativo === true)
        const listaDeDescontos = produtosAtivos
            .flatMap(produto => produto.descontos)
            .filter(desconto => (desconto.promocao?.valorDesconto ?? 0) > 0)
            .map(valor => (valor.promocao?.valorDesconto ?? 0))
            .reduce((acc, current) => acc + current, 0)

        return listaDeDescontos
    }, [])


    const retornaDescontoNoItem = useCallback((produto: MovSimplesProdutoModel) => {
        return produto.descontos.reduce((acc, current) => acc + (current.item?.valorDesconto ?? 0), 0)
    }, [])

    //Confirmar se essa função pode ser substituida pela prop vDescEmbutido da movSimples
    const retornaDescontoDePromocaoNoItem = useCallback((produto: MovSimplesProdutoModel) => {
        return produto.descontos.reduce((acc, current) => acc + (current.promocao?.valorDesconto ?? 0), 0) / produto.qCom
    }, [])

    //retorna descontos feitos apenas na venda        
    const retornaDescontoNaVenda = useCallback((produtos: MovSimplesProdutoModel[], mov: MovSimplesModel) => {
        const descNosProdutos = retornaDescontoDosProdutos(produtos)
        const descTotal = mov.vDesc
        const descNaVenda = descTotal - descNosProdutos
        return descNaVenda
    }, [retornaDescontoDosProdutos])

    const retornaAcrescimoNoItem = useCallback((produto: MovSimplesProdutoModel) => {
        return produto.acrescimos.reduce((acc, current) => acc + (current.item?.valorAcrescimo ?? 0), 0) / produto.qCom
    }, [])

    //Esse retorno pega o valor de vProd que considera valor dos itens subtraindo o vdescEmbutido e somando o vOutro no item, sendo assim criei o retorno para exibir o valor total da venda com valores cheios para exibir ao cliente final quando essa venda deveria ser e quanto ele pagou depois dos descontos e acrescimos se houver. 
    const retornaValorTotalDaVendaComDescontosEacrescimos = useCallback((mov: MovSimplesModel) => {
        const descontos = mov.vDescEmbutido
        const totalDaVenda = (mov.vProd + descontos) - (mov.vOutro + mov.vOutroEmbutido)
        return totalDaVenda
    }, [])

    return {
        adicionarAcrescimoDescontoVenda,
        adicionarAcrescimoDescontoItem,
        retornaDescontoDosProdutos,
        retornaDescontoNaVenda,
        retornaDescontoDasPromocoes,
        retornaDescontoNoItem,
        retornaDescontoDePromocaoNoItem,
        retornaAcrescimoNoItem,
        retornaValorTotalDaVendaComDescontosEacrescimos
    }
}