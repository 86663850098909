import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { PerfilModal } from "views/components/modals/perfil/perfil-modal";

export const CadastroPerfilModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: ''
    });

    const perfilRoute = `/permissoes/perfil/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroPerfilModal')

    useEffect(() => {
        if (location.pathname.indexOf(perfilRoute) === 0) {
            const posrota = location.pathname.replace(perfilRoute, '');
            if (posrota.length > 0) {
                setModalState({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: perfilRoute
                });
                return;
            }
        }
    }, [location.pathname, perfilRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? perfilRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        
        if (!openned) {
            push(perfilRoute)
        }
        if (trocarUrl) {
            push(perfilRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url
        });
    }, [modalState.aberto, modalState.ultimaUrl, perfilRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.PerfilModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PerfilModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <PerfilModal openned={modalState.aberto} key="mdlPessoa" id={modalState.id} />
    )
}