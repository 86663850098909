import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden",
        flexGrow: 1
    },
    header: {
        flex: "0 1 auto"
    },
    content: {
        flex: '1 1 100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
    },
}));
