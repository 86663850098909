import { Box, Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-alterar-quantidade-clientes-styles';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useState, useEffect } from 'react';
import { useCadastros } from 'services/app';
import { useThemeQueries } from 'views/theme';

interface DialogAlterarQuantidadeClientesProps {
  openned: boolean;
  alterarQuantidade: (quantidadeClientes: number) => void;
  qtdeInicial: number;
  comanda?: boolean;
}

export const DialogAlterarQuantidadeClientes = ({
  openned,
  alterarQuantidade,
  qtdeInicial,
  comanda,
}: DialogAlterarQuantidadeClientesProps) => {
  const [qtdPessoas, setQtdPessoas] = useState<number>(qtdeInicial);
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { fecharDialogAlterarQuantidadeClientes } = useCadastros();

  useEffect(() => {
    return () => {
      setQtdPessoas(0);
    };
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault()
    alterarQuantidade(qtdPessoas);
    fecharDialogAlterarQuantidadeClientes();
  }

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo={`Clientes na ${comanda ? 'comanda' : 'mesa'}`}
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <form onSubmit={handleSubmit}>
            <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Box style={{ flex: 1 }}>
                <Typography className={classes.label}>
                  Altere a quantidade de clientes no pedido.
                </Typography>
                <TextFieldSaurus
                  fullWidth
                  size="small"
                  allowSubmit
                  value={qtdPessoas}
                  maxTexto={3}
                  placeholder="Número de Pessoas"
                  onChange={(e) => setQtdPessoas(Number(e.target.value))}
                  adornmentColor={theme.palette.text.disabled}
                  tipo="NUMERO"
                />
              </Box>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                <Grid item xs={4}>
                  {fecharDialogAlterarQuantidadeClientes && (
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        fecharDialogAlterarQuantidadeClientes();
                      }}
                      rounded
                    >
                      <VoltarIcon
                        tipo="BUTTON_PRIMARY"
                        fill={theme.palette.primary.main}
                      />
                      Voltar
                    </Button>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={
                      qtdPessoas <= 0 || qtdPessoas === qtdeInicial ? true : false
                    }
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    rounded
                  >
                    <OkIcon tipo="BUTTON_PRIMARY" fill={'#fff'} />
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
