import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';

import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { useStyles } from './accordion-recarregar-dados-styles';
import { useState } from 'react';
import { DialogRecarregarDados } from 'views/components/dialog/dialog-recarregar-dados/dialog-recarregar-dados';
import { RecarregarIcon } from 'views/components/icons';
import { PdvIcon } from 'views/components/icons/pdv-icon';

export const AccordionRecarregarDados = () => {

  // STATES E REFS
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  // AUX
  const { theme } = useThemeQueries();
  const classesModal = useModalStyles();
  const classes = useStyles();

  const handleOpenCloseDialog = () => setOpenDialog(prev => !prev)

  return (
    <AccordionSaurus
      labelPrimary={`Manutenção dos PDVs`}
      tituloChildren={
        <PdvIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      heightDivider={2}
      showDivider
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
    >
      <div className={classNames(classesModal.root, classes.root)}>
        <div className={classesModal.content}>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
          <div
            className={classNames(classesModal.contentForms)}
            style={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                <Typography
                  variant="body2"
                  style={{ textAlign: 'center', fontWeight: '500' }}
                >
                  Clique no botão abaixo caso esteja com problemas de sincronização com um ou mais PDVs. Este processo poderá demorar alguns minutos pois iremos gerar uma nova base de dados para os PDVs.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  onClick={handleOpenCloseDialog}
                  variant="contained"
                  color="primary"
                >
                  <RecarregarIcon tipo="BUTTON_PRIMARY" />
                  Gerar nova Base PDV
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {openDialog && (
        <DialogRecarregarDados
          closeModal={handleOpenCloseDialog}
          openned={openDialog}
        />
      )}
    </AccordionSaurus>
  );
};
