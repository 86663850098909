import { useCallback } from "react";
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPlanoAtual() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPlanoAtual = useCallback(
    () =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/planos/plano-atual`,
        method: "GET",
        enviarTokenUsuario: true,
      })
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPlanoAtual,
  };
}
