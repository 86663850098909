import { TabelaClientes } from "database/interfaces/interface-tabela-clientes";
import { PessoaModel } from "model/api/gestao/pessoa";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogInformacaoOperador } from "views/components/dialog/dialog-informacao-operador/dialog-informacao-operador";
import { ModalProps } from "views/components/modals/utils";

export interface InformacaoOperadorDialogRenderProps extends ModalProps {
    cliente: TabelaClientes | PessoaModel
    confirmar: () => void
    cancelar: () => void
}

export const InformacaoOperadorDialogRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<InformacaoOperadorDialogRenderProps>({
        cancelar: () => { },
        confirmar: () => { },
        cliente: new PessoaModel(),
        id: '',
        openned: false
    });

    consoleDev('AdicionarProdutoDialog')

    const modalAlterado = useCallback(({ ...props }: InformacaoOperadorDialogRenderProps) => {
        setModalState(prev => ({
            ...prev,
            ...props
        }));
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.InformacaoOperador, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.InformacaoOperador, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.openned && (
                <DialogInformacaoOperador {...modalState} />
            )}
        </>
    )
}