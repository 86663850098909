import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { Box, Grid, Typography } from 'views/design-system';
import { DashboardHeader } from './components/dashboard-header';
import { DashboardContent } from './components/dashboard-content/dashboard-content';
import { useSessaoAtual } from "services/app";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import { useStyles } from './dashboard-styles'
import { useImagemWhitelabel } from "services/app/hooks/imagem-whitelabel";
import { useMemo } from "react";


const DashboardPage = () => {
  const classes = useDefaultCadastroStyles();
  const dashboardClasses = useStyles()
  const { getPermissaoBoolean } = useSessaoAtual();
  const { imagemWhitelabel } = useImagemWhitelabel();
  
  const content = useMemo(() =>
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <DashboardHeader />
      </Grid>
      <Grid className={classes.list}>
        {getPermissaoBoolean(EnumCodigosPermissoes.RELATORIOS) ? (
          <DashboardContent />
        ) : (
          <Box style={{ width: "100%", height: "100%" }} flex alignItems='center' justifyContent='center'
            flexDirection='column' gap={2}>
            {imagemWhitelabel && <img src={imagemWhitelabel} alt='Logo do Touchone' className={dashboardClasses.logo} />}
            <Typography align='center' variant="h6">Bem-vindo ao TouchOne, comece a explorar o sistema!</Typography>
          </Box>
        )}
      </Grid>
    </Grid >, [classes.header, classes.list, classes.root, dashboardClasses.logo, getPermissaoBoolean, imagemWhitelabel]);

  return content;
};

export default DashboardPage;
