import { Box, Typography } from "views/design-system"
import { useStyles } from '../opcoes-entrada-styles'
import { Controller, useFormContext } from "react-hook-form";
import { VendaCompletaXMLModel } from "model/api/gestao/venda/venda-completa-xml-model";
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov";
import classNames from "classnames";
import { SaurusRadio } from "views/components/controles/radio/saurus-radio";
import { FormControlLabel } from "@material-ui/core";


const OpcoesEntradaConfirmarStatus = ({ removePadding }: { removePadding?: boolean }) => {
    const classes = useStyles();

    const { control, setValue } = useFormContext<VendaCompletaXMLModel>();

    return <Box className={classNames(classes.checkboxCard, removePadding ? classes.noPadding : '')}>
        <Typography align='center'>
            Selecione a opção para confirmar se você já recebeu a entrada de mercadoria.
        </Typography>
        <Box flex justifyContent='center' gap={4}>
            <Controller
                name='status'
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        label="Aguardando Recebimento"
                        checked={field.value === EnumTpStatusMov.Pendente}
                        onClick={() => {
                            setValue('status', EnumTpStatusMov.Pendente)
                        }}
                        control={<SaurusRadio />}
                    />
                )}
            />
            <Controller
                name='status'
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        label="Finalizado"
                        checked={field.value === EnumTpStatusMov.Finalizado}
                        onClick={() => {
                            setValue('status', EnumTpStatusMov.Finalizado)
                        }}
                        control={<SaurusRadio />}
                    />
                )}
            />
        </Box>
    </Box>
}

export default OpcoesEntradaConfirmarStatus