import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-importar-pedidos-styles';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useThemeQueries } from 'views/theme';
import { useLocation } from 'react-router-dom';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { useSessaoAtual } from 'services/app';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useCallback, useEffect, useState } from 'react';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { CancelarIcon } from 'views/components/icons';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

interface LocationProps {
  pedidos: PedidoModelComanda[];
  mesa?: boolean
}

const MovImportarPedidoPage = () => {
  const { state } = useLocation<LocationProps>();
  const { pedidos, mesa } = state;
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const [number, setNumber] = useState<number>(0);
  const [erro, setErro] = useState<boolean>(false);
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { putPedidoStatus } = usePutPedidoStatus();
  const { transformarPedidosEmVendaSimples } = useMovAtual();
  const { redirectCarrinho } = useMovRota();
  const importarPedido = useCallback(
    async (id: string) => {
      const respostaPutStatus = await putPedidoStatus(
        getEmpresaSelecionada()?.Id ?? '',
        id,
        EnumStatusPedido.IMPORTADO,
      );

      if (respostaPutStatus.erro) {
        throw respostaPutStatus.erro;
      }
    },
    [getEmpresaSelecionada, putPedidoStatus],
  );

  const importandoPedido = useCallback(async () => {
    try {
      if (number < state.pedidos.length) {
        await importarPedido(state.pedidos[number].id);
      }
      if (number === state.pedidos.length - 1) {
        await transformarPedidosEmVendaSimples(pedidos);
        await redirectCarrinho();
        return;
      }

      setNumber((prev) => prev + 1);
    } catch {
      setErro(true);
    }
  }, [number, state.pedidos, importarPedido, transformarPedidosEmVendaSimples, pedidos, redirectCarrinho]);

  useEffect(() => {
    (async () => {
      await importandoPedido();
    })();
  }, [importandoPedido]);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Importando Pedidos'
    })
  }, [setHeaderProps])

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.content}>
          <Grid
            className={
              isMobile ? classes.infoContainer : classes.infoContainerPC
            }
          >
            <Typography className={classes.textTitle}>
              {!erro ? 'Importando Pedidos' : 'Ocorreu um erro'}
            </Typography>
            {!erro ? (
              <Typography
                className={classes.infoTotal}
                style={{ textAlign: 'center' }}
              >
                {number + 1}/{pedidos.length}
              </Typography>
            ) : (
              <></>
            )}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 32,
              }}
            >
              {!erro ? (
                <LoadingFinalizacao />
              ) : (
                <Grid className={classes.imageError}>
                  <CancelarIcon tipo="GERAL" fill="#D22" />
                </Grid>
              )}
            </Grid>
            <Grid
              className={!isMobile ? classes.infoCardContent : classes.cardInfo}
            >
              <Grid
                container
                justifyContent="center"
                style={{ flexDirection: 'column', display: 'flex' }}
              >
                <Grid
                  item
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {!erro ? (
                    <Typography className={classes.infoTotal}>
                      {mesa ? 'Mesa nº ' : 'Comanda nº '}{mesa ? pedidos[number]?.mesaCodigo : pedidos[number]?.codigoComanda}
                    </Typography>
                  ) : (
                    <Typography className={classes.infoNomeEQuantidade}>
                      Ocorreu um erro ao importar o pedido N°{' '}
                      {mesa ? pedidos[number]?.mesaCodigo : pedidos[number]?.codigoComanda}
                    </Typography>
                  )}
                </Grid>
                {erro && (
                  <Grid
                    item
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      variant="contained"
                      onClick={async () => {
                        setErro(false);
                        await importandoPedido();
                      }}
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      Tentar Novamente
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MovImportarPedidoPage;
