import { KeyValueModel } from "model";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";

export const PromocaoStatusMock: KeyValueModel[] = [
    new KeyValueModel('true', "Ativo"),
    new KeyValueModel('false', "Inativo"),
    new KeyValueModel('todos', "Todos"),
];

export const PromocaoTipoRegraMock: KeyValueModel[] = [
    new KeyValueModel(EnumPromocaoTipoRegra.DePor, "De/Por", { info: "Substituirá o valor do produto.", status: 'Ativo' }),
    new KeyValueModel(EnumPromocaoTipoRegra.AtacadoValor, "Atacado R$", { info: "Substituirá o valor do produto a partir de uma determinada quantidade.", status: 'Ativo' }),
    new KeyValueModel(EnumPromocaoTipoRegra.AtacadoPerc, "Atacado %", { info: "Desconto no produto a partir de uma determinada quantidade.", status: 'Ativo' }),
    new KeyValueModel(EnumPromocaoTipoRegra.LeveMaisPagueMenosQtde, "Leve Mais, Pague Menos", { info: "Rateia o desconto de uma quantidade de produtos para levar uma quantidade maior.", status: 'Em Breve (2025)' }),
    new KeyValueModel(EnumPromocaoTipoRegra.ProgressivoPerc, "Progressivo em %", { info: "A cada quantidade acrescida será aplicado um % de desconto.", status: 'Em Breve (2025)' }),
];


export const PromocaoTipoRegraRotaMock: KeyValueModel[] = [
    new KeyValueModel(EnumPromocaoTipoRegra.DePor, "de-por"),
    new KeyValueModel(EnumPromocaoTipoRegra.AtacadoPerc, "atacado-perc"),
    new KeyValueModel(EnumPromocaoTipoRegra.AtacadoValor, "atacado-valor"),
];
