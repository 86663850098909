import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Fade,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { useStyles } from './accordion-saurus-styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { useCallback } from 'react';
import classNames from 'classnames';
import { ExpandBoldRoundIcon, ExpandIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { SwitchSaurus } from 'views/components/controles';

interface AccordionSaurusProps extends AccordionProps {
  tituloChildren?: React.ReactNode;
  expandChildren?: React.ReactNode;
  labelPrimary?: string;
  labelSecondary?: React.ReactNode;
  colorDivider?: string;
  heightDivider?: number;
  noIconRotation?: boolean;
  noPaperRoot?: boolean;
  noPaddingContent?: boolean;
  tipoExpand?: 'padrao' | 'bold';
  colorExpand?: string;
  expandSize?: number;
  showDivider?: boolean;
  sombreado?: boolean;
  betweenTitles?: boolean;
  labelColor?: string;
  titleId?: string;
  labelSecondaryClasses?: string
  funcaoDoSwitch?: (value: boolean)=> void,
  checkedSwitch?: boolean
}

export const AccordionSaurus = ({
  tituloChildren,
  expandChildren,
  labelPrimary,
  labelSecondary,
  colorDivider,
  showDivider,
  heightDivider,
  noPaperRoot,
  noPaddingContent,
  tipoExpand,
  colorExpand,
  expandSize,
  sombreado,
  labelColor,
  labelSecondaryClasses,
  funcaoDoSwitch,
  checkedSwitch,
  ...props
}: AccordionSaurusProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const selectExpand = useCallback(() => {
    switch (tipoExpand) {
      case 'bold':
        return (
          <ExpandBoldRoundIcon
            style={{ width: 25, height: 25, fill: colorExpand ?? undefined }}
          />
        );
      default:
        return (
          <ExpandIcon
            style={{ width: 25, height: 25, fill: colorExpand ?? undefined }}
          />
        );
    }
  }, [tipoExpand, colorExpand]);

  return (
    <>
      <Accordion
        {...props}
        classes={{
          root: classNames(
            noPaperRoot ? classes.rootAccordionNoPaper : classes.rootAccordion,
            classes.iconWithoutRotation,
            sombreado ? classes.sombreado : undefined
          )
        }}

      >
        <AccordionSummary
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent,
            content: noPaddingContent
              ? classes.accordionSumaryContentNoPadding
              : classes.accordionSumaryContent
          }}
          expandIcon={
            expandChildren ? <>{expandChildren}</> : <>{selectExpand()}</>
          }
        >
          <Grid container >
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: props.betweenTitles ? 'space-between' : 'normal'
              }}
            >
              {tituloChildren && <>{tituloChildren}</>}
              {labelPrimary && (
                <Typography
                  variant="h6"
                  display="inline"
                  className={classes.title}
                  style={{
                    color: labelColor || theme.palette.grey[600]
                  }}
                >
                  {labelPrimary}
                </Typography>
              )}
              {labelSecondary && (
                <Box
                  ml={1}
                  className={classNames(labelSecondaryClasses)}
                >
                  {labelSecondary}
                </Box>
              )}
            </Grid>
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              label=""
              control={<></>}
            />
          </Grid>
          {funcaoDoSwitch &&
            <SwitchSaurus variant='LIGHT' 
            checked={checkedSwitch}
            onClick={(e) => {e.stopPropagation()}} 
            onChange={(e) => {funcaoDoSwitch && funcaoDoSwitch(e.target.checked)}} />
          }
        </AccordionSummary>
        <Fade in={showDivider} mountOnEnter unmountOnExit>
          <Grid xs={12} style={{ padding: theme.spacing(1, noPaperRoot ? 0 : 2, .5, noPaperRoot ? 0 : 2), marginTop: '-43px' }}>
            <div className={classes.divider} style={{ background: colorDivider }} />
          </Grid>
        </Fade>
        <AccordionDetails
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent
          }}
        >
          {props.children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
