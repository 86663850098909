import { create } from 'zustand';

interface IMensagem {
    mensagem: string;
    isInput: boolean;
}

interface PagamentoProps {
    processando: boolean;
    cancelavel: boolean;
    mensagem: string;
}

interface IStateStore {
    processando: boolean;
    cancelavel: boolean;
    mensagem: IMensagem[];

}

interface IAguardandoPagamentoStore {
    props: IStateStore;
    setAguardandoPagamentoProps: (props: PagamentoProps) => void;
}

export const useAguardandoPagamentoStore = create<IAguardandoPagamentoStore>((set) => ({
    props: {
        mensagem: [],
        processando: false,
        cancelavel: false,
    },
    setAguardandoPagamentoProps(props: PagamentoProps) {
        let mensagens: IMensagem[] = [{
            mensagem: props.mensagem,
            isInput: false
        }];

        if (props.mensagem.toLowerCase().includes('senha:')) {
            mensagens = [];
            mensagens.push({ mensagem: props.mensagem.toLowerCase().split('senha:')[0].toUpperCase(), isInput: false });
            mensagens.push({ mensagem: 'SENHA:', isInput: false });
            mensagens.push({ mensagem: props.mensagem.toLowerCase().split('senha:')[1].toUpperCase() || ' ', isInput: true });
        }

        set((state) => ({
            ...state,
            props: {
                ...props,
                mensagem: mensagens
            }
        }))
    },
}))