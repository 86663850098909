import { Typography } from 'views/design-system';
import { useStyles } from './button-keyboard-number-styles';
import { ButtonKeyboardProps } from './button-keyboard-number-props';

export function ButtonKeyboardNumber(props: ButtonKeyboardProps) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.ButtonKeyboardContainer}`}
      onClick={() => {
        if (props.value !== null && props.value !== undefined)
          props.clickFunc(props.value as number);
        else if (props.label !== null) props.clickFunc(props.label);
      }}
    >
      <Typography
        className={classes.TypographyButtonKeyboard}
        variant={props.textVariant !== undefined ? props.textVariant : 'h5'}
      >
        {`${props.prefix !== undefined ? props.prefix : ''} ${props.label !== undefined ? props.label : props.value
          }`}
      </Typography>
    </div>
  );
}
