import { useCallback } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';

import { useHistory } from 'react-router-dom';
import { useCadastros } from 'services/app';
import { useConfirm } from 'material-ui-confirm';
import { PromocaoHeader } from './components/promocao-header/promocao-header';
import { PromocaoList } from './components/promocao-list/promocao-list';
import { usePromocaoDadosStore } from 'views/components/modals/promocao/components/promocao-dados/hooks/use-promocao-dados-store';

export const PromocaoPage = () => {
    const classes = useDefaultCadastroStyles();
    const { location } = useHistory();
    const { abrirCadastroPromocao } = useCadastros();
    const confirm = useConfirm();

    const {
        state: { promocao },
        resetStorePromocao
    } = usePromocaoDadosStore((store) => store);

    const onClickAdd = useCallback(() => {
        if (promocao.nome.length > 0) {
            confirm({
                title: `Cadastro em andamento`,
                description: `Observamos que você já iniciou o cadastro de uma campanha de promoção. Gostaria de continuar com o processo de cadastro?`,
                cancellationText: 'Novo cadastro',
                cancellationButtonProps: {
                    color: 'default'
                },
                confirmationText: 'Continuar cadastro'
            })
                .then(() => {
                    abrirCadastroPromocao('', location.pathname, true);
                })
                .catch(() => {
                    resetStorePromocao();
                    abrirCadastroPromocao('', location.pathname, true);
                });
        } else {
            resetStorePromocao();
            abrirCadastroPromocao('', location.pathname, true);
        }
    }, [abrirCadastroPromocao, location.pathname, confirm, promocao.nome.length, resetStorePromocao]);

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <PromocaoHeader />
                </div>
                <div className={classes.list}>
                    <PromocaoList />
                </div>
                <ButtonFab
                    id="tour-cadPromocao"
                    tooltip="Adicionar campanha de promoções"
                    icon={<NovoIcon tipo="BUTTON_FAB" />}
                    onClick={onClickAdd}
                />
            </div>
        </>
    );
};
