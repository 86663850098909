import { CardNcmProps } from './card-ncm-props';
import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from 'utils/to-decimal';
import { useThemeQueries } from '../../..';
import { Grid, Tooltip, Typography } from 'views/design-system';
export const CardNcm = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardNcmProps) => {
  const classes = useDefaultCardStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [model.ativo, theme.palette.error.main, theme.palette.success.main]);
  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <Tooltip
          arrow
          title={model.ativo ? 'Habilitado' : 'Desabilitado'}
          placement="right"
        >
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <Grid container className={classes.cardContent} spacing={1} >
          <Grid item className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              NCM
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              weight={700}
            >
              {model.codigo}
            </Typography>
          </Grid>
          <Grid item
            className={classes.celulaGridFullDescriptionFull}
            style={{ whiteSpace: 'break-spaces' }}
          >
            <Typography color="textPrimary" variant="caption">
              Descricao
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.descricao}
            </Typography>
          </Grid>
          {model.cest !== null && model.cest.length > 0 && (
            <Grid item className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Cest
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.cest}
              </Typography>
            </Grid>
          )}
          {model.pTribManual !== null && (
            <Grid item className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                % Trib. Manual
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {toDecimalString(model.pTribManual, 2)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Tooltip arrow title="Editar NCM">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
