import { Grid } from "views/design-system";
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { TaxaEntregaContent } from "./taxa-entrega-content/taxa-entrega-content";
import { useVendaHeaderStore } from "views/components/headers/venda-header/stores/venda-header-store";
import { useEffect } from "react";

const MovTaxaEntregaPage = () => {
  const classes = useDefaultCadastroStyles();

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Taxa de Entrega',
      showMenuPdv: true,
    })
  }, [setHeaderProps])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.list}>
        <TaxaEntregaContent />
      </Grid>
    </Grid>
  );
};

export default MovTaxaEntregaPage;
