import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fullHeight: {
        height: '100%',
        "& > form": {
            height: '100%',
            "& > *" : {
                height: '100%'
            }
        }
    },
}))
