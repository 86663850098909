import classNames from 'classnames';
import { useGetProcessos } from 'data/api/gestao/processos/get-processos';
import { useGetSetorById } from 'data/api/gestao/setores/get-setor-by-id';
import { usePutSetor } from 'data/api/gestao/setores/put-setores';
import { isEmpty, isEqual } from 'lodash';
import {
  ProcessosModel,
  ProcessosSetorModel,
} from 'model/api/gestao/processos/processos-model';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { SetorEProcessoEditFormModel } from 'model/app/forms/setor/setor-edit-form-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { guidEmpty } from 'utils/guid-empty';
import { picker } from 'utils/picker';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { DialogAdicionarProcessos } from 'views/components/dialog/dialog-adicionar-processos/dialog-adicionar-processos';
import { DialogEditarProcessos } from 'views/components/dialog/dialog-editar-processos/dialog-editar-processos';
import { FormSetorEdit } from 'views/components/form/setor/form-setor-edit/form-setor-edit';
import { DefaultFormRefs } from 'views/components/form/utils';
import { SalvarIcon, VoltarIcon } from 'views/components/icons';
import { CircularLoading } from 'views/components/utils';
import { ModalHeader } from '../../components';
import { useModalStyles } from '../../utils/modal-styles';
import { useSessaoAtual } from 'services/app';
import { useGetSaloes } from 'data/api/gestao/saloes/get-saloes';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { useStyles } from './setores-edit-styles';
import { CardSaloesProcessos } from 'views/components/cards/card-saloes-processos';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { DialogProcessos } from 'views/components/dialog/dialog-processos/dialog-processos';
import { AccordionProcessos } from './components/accordion-processos/accordion-processos';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';

export type SetoresEditProps = {
  id: string
}

export const SetoresEdit = (props: SetoresEditProps) => {
  const processoAdicionado = useRef<boolean>(false);

  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const processoSetorRef = useRef<ProcessosSetorModel>(
    new ProcessosSetorModel()
  );
  const salaoRef = useRef<SaloesModel>(new SaloesModel())
  const setorRef = useRef<SetoresModel>(new SetoresModel());
  const classes = useModalStyles();
  const myClasses = useStyles()
  const { fecharCadastroSetores } = useCadastros();
  const { getSetorById, carregando: carregandoSetor } = useGetSetorById();
  const { putSetor, carregando: carregandoPut } = usePutSetor();
  const { getProcessos, carregando: carregandoProcessos } = useGetProcessos();
  const { getSaloes, carregando: carregandoSaloes } = useGetSaloes()
  const { getEmpresaAtual } = useEmpresaAtual()

  const { getEmpresaSelecionada } = useSessaoAtual();

  const [listProcessos, setListProcessos] = useState<ProcessosModel[]>([]);
  const [listSaloes, setListSaloes] = useState<SaloesModel[]>([])
  const [processoSetor, setProcessoSetor] = useState<ProcessosSetorModel>(
    new ProcessosSetorModel()
  );
  const [processo, setProcesso] = useState<ProcessosModel>(
    new ProcessosModel()
  );

  // STATES DE CONTROLE DIALOG
  const [showDialogEditarProcessos, setShowDialogEditarProcessos] =
    useState<boolean>(false);
  const [showDialogAdicionarProcessos, setShowDialogAdicionarProcessos] =
    useState<boolean>(false);
  const [showDialogProcessos, setShowDialogProcessos] =
    useState<boolean>(false);

  const carregando = [
    carregandoPut,
    carregandoSetor,
    preenchendoTela,
    carregandoProcessos,
    carregandoSaloes
  ].includes(true);

  const [setorEProcessoFormState, setSetorEProcessoForm] =
    useState<SetorEProcessoEditFormModel>(new SetorEProcessoEditFormModel());
  const [onlyImpressor, setOnlyImpressor] = useState<boolean>(false);

  const refEditForm =
    useRef<DefaultFormRefs<SetorEProcessoEditFormModel>>(null);

  const recarregarForm = useCallback((model: SetorEProcessoEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(setorEProcessoFormState);
  }, [setorEProcessoFormState, recarregarForm]);

  const getSetorByIdWapper = useCallback(async () => {
    const res = await getSetorById(props.id, getEmpresaSelecionada()?.Id ?? '');
    if (res.erro) {
      throw res.erro;
    }
    const setor = res.resultado?.data as SetoresModel;
    if (!setor.uriImage) {
      setor.uriImage = '';
    }
    setorRef.current = res.resultado?.data;

    return setor;
  }, [getEmpresaSelecionada, getSetorById, props.id]);

  const getSaloesWrapper = useCallback(async () => {
    const query = 'pageSize=0';
    const res = await getSaloes(query, getEmpresaAtual()?.id ?? '')

    if (res.erro) throw res.erro

    const salaoSemId: SaloesModel = {
      ...new SaloesModel(),
      id: null,
      descricao: 'Processo de Todos os Salões'
    }
    const ret = res.resultado?.data.list as SaloesModel[]
    const saloes = ret.filter(salao => salao.status.codigo === EnumStatusSalao.ATIVO)
    if (!isEmpty(saloes) && saloes.length > 1) {
      return [
        salaoSemId,
        ...saloes
      ]
    }

    return saloes
  }, [getEmpresaAtual, getSaloes])

  const getProcesso = useCallback(
    async (setorId: string) => {
      if (setorId === guidEmpty()) {
        return;
      }

      const processos = await getProcessos(
        getEmpresaSelecionada()!.Id,
        setorId
      );
      if (processos.erro) {
        showToast('error', 'Não foi possível carregar os processos do setor.');
      }

      let ret: Array<ProcessosSetorModel> = processos.resultado?.data.list;

      const processo =
        ret?.filter((item) => item.setor.id === setorId)[0] ??
        new ProcessosSetorModel();

      processoSetorRef.current = processo;

      setListProcessos(processo?.processos || []);

      setProcessoSetor(processo);

      return processo;
    },
    [getEmpresaSelecionada, getProcessos, showToast]
  );

  const preencherTela = useCallback(async () => {
    try {
      setPreenchendoTela(true);
      const setor = await getSetorByIdWapper();
      const processo = await getProcesso(setor.id);
      const saloes = await getSaloesWrapper()
      const preencherForm = new SetorEProcessoEditFormModel(
        setor.id,
        setor.descricao,
        setor.uriImage,
        processo?.minutosDePreparo || 0
      );
      setSetorEProcessoForm({ ...preencherForm });
      setListSaloes(saloes)
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setPreenchendoTela(false);
    }
  }, [getProcesso, getSaloesWrapper, getSetorByIdWapper, showToast]);

  const saveChangesSetor = useCallback(
    async (setoresModelForm: SetoresModel) => {
      const ret = await putSetor(
        setoresModelForm,
        props.id,
        getEmpresaSelecionada()?.Id ?? ''
      );

      if (ret.erro) {
        showToast(
          'error',
          'Não foi possível atualizar o setor, tente novamente mais tarde'
        );
      }
    },
    [getEmpresaSelecionada, props.id, putSetor, showToast]
  );

  const handleSubmit = useCallback(
    async (
      model: SetorEProcessoEditFormModel,
      beforeModel: SetorEProcessoEditFormModel
    ) => {
      try {

        if (isEqual(model, beforeModel)) {
          showToast('info', 'Nenhuma informação foi alterada');
          return
        }

        const setorPicker = picker<SetoresModel>(model, setorRef.current);
        if (!setorPicker.uriImage) {
          setorPicker.uriImage = '';
        }

        await saveChangesSetor(setorPicker);

        showToast('success', 'Setor atualizado com sucesso!');
        preencherTela();

      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [saveChangesSetor, showToast, preencherTela]
  );

  const handleClickCard = useCallback(
    (model: ProcessosModel, isOnlyImpressor = false) => {
      setProcesso(model);
      setOnlyImpressor(isOnlyImpressor);
      setShowDialogEditarProcessos(true);
    }, []);

  const handleOpenNovoProcesso = () => {
    setShowDialogAdicionarProcessos(true);
  };

  const onCloseClick = useCallback(() => {
    fecharCadastroSetores();
  }, [fecharCadastroSetores]);

  useEffect(() => {
    preencherTela();
  }, [preencherTela]);

  useEffect(() => {
    if (
      showDialogAdicionarProcessos === false &&
      showDialogEditarProcessos === false &&
      processoAdicionado.current === true
    ) {
      preencherTela();
      processoAdicionado.current = false;
      return;
    }
  }, [
    showDialogEditarProcessos,
    preencherTela,
    props.id,
    showDialogAdicionarProcessos
  ]);

  return (
    <>
      <div className={classes.root}>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
        <ModalHeader
          title={'Edição do Setor'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />
        <div className={classes.content}>
          <div
            className={classNames(
              myClasses.contentForms,
              preenchendoTela ? classes.contentFormsLoading : undefined
            )}
          >
            <FormSetorEdit
              ref={refEditForm}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
            />
            <Grid container spacing={2} style={{ marginBottom: '8px', justifyContent: 'flex-end' }}>
              <Grid item xs={6}>
                <Button
                  disabled={carregando}
                  onClick={() => refEditForm.current?.submitForm()}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <SalvarIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
          {!carregando && listSaloes.length <= 1 && (
            <>
              <Box py={1} px={2} className={classNames(myClasses.titleTop)}>
                <Typography color="primary" variant="h6">
                  Lista de Processos
                </Typography>
              </Box>
              <Divider variant='fullWidth' className={myClasses.divider} />
              <Box className={classNames(myClasses.containerSaloes, classes.contentForms)}>
                <Box px={2}>
                  <Grid container spacing={1}>
                    <AccordionProcessos
                      models={listProcessos}
                      handleClickCard={handleClickCard}
                      handleOpenNovoProcesso={handleOpenNovoProcesso}
                    />
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          {!carregando && listSaloes.length > 1 && (
            <>
              <Box py={1} px={2} className={classNames(myClasses.titleTop)}>
                <Typography color="primary" variant="h6">
                  Salões e Processos
                </Typography>
              </Box>
              <Divider variant='fullWidth' className={myClasses.divider} />
              <Box className={classNames(myClasses.containerSaloes, classes.contentForms)}>
                <Box mx={2}>
                  {listSaloes.filter(salao => salao.status.codigo === EnumStatusSalao.ATIVO).map(salao => (
                    <Grid item xs={12}>
                      <CardSaloesProcessos
                        model={salao}
                        onClick={(model) => {
                          salaoRef.current = model
                          setShowDialogProcessos(true)
                        }}
                        onCheck={() => { }}
                        selected={false} />
                      {!salao.id && (
                        <Divider variant='fullWidth' className={myClasses.dividerCard} />
                      )}
                    </Grid>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </div>
      </div >

      {showDialogAdicionarProcessos && (
        <DialogAdicionarProcessos
          aberto={showDialogAdicionarProcessos}
          close={() => {
            setShowDialogAdicionarProcessos(false);
          }}
          processoSetor={processoSetor}
          processoAdicionado={processoAdicionado}
        />
      )
      }

      {
        showDialogEditarProcessos && (
          <DialogEditarProcessos
            onlyImpressor={onlyImpressor}
            aberto={showDialogEditarProcessos}
            processoAdicionado={processoAdicionado}
            close={() => {
              setShowDialogEditarProcessos(false);
            }}
            setor={setorRef.current}
            processoSetor={processoSetor}
            processo={processo}
          />
        )
      }

      {
        showDialogProcessos && (
          <DialogProcessos
            aberto={showDialogProcessos}
            salao={salaoRef.current}
            setorId={props.id}
            close={() => setShowDialogProcessos(false)}
          />
        )
      }
    </>
  );
};
