import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from '../../../../../views/theme';
import { CircularLoading } from '../../../../../views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from '../../../../../views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs
} from '../../../../../views/components/form/utils/form-default-props';
import { useFormPerfilEditValidation } from './form-perfil-edit-validation';
import { PerfilEditFormModel } from '../../../../../model/app/forms/perfil/perfil-edit-form-model';
import AccordionPerfil from './components/accordion-perfil/accordion-perfil';
import { PerfilGrupoPermissaoMock } from 'data/mocks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { HorariosAcesso } from './components/horarios-acesso/horarios-acesso';
import { picker } from 'utils/picker';
import { EnumTour } from 'model/enums/enum-tour';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { Box } from 'views/design-system';

export const FormPerfilEdit = forwardRef<
  DefaultFormRefs<PerfilEditFormModel>,
  DefaultFormProps<PerfilEditFormModel>
>(({ loading, ...props }: DefaultFormProps<PerfilEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<PerfilEditFormModel>(
    new PerfilEditFormModel()
  );
  const { FormPerfilEditYupValidation } = useFormPerfilEditValidation();
  const { isMobile } = useThemeQueries();
  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues
  } = useForm<PerfilEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormPerfilEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange'
  });
  const onSubmit = (values: PerfilEditFormModel) => {
    const perfil = picker<PerfilEditFormModel>(
      values,
      new PerfilEditFormModel()
    );
    perfil.permissoes = values.permissoes;
    props.onSubmit(perfil, modelForm);
  };
  const onPermissaoChanced = useCallback(
    (cod: number, novoValor: string, titulo: string) => {
      const copyModelForm = { ...modelForm };

      const perm = copyModelForm.permissoes[titulo].find(
        (x: any) => x.codigo === cod.toString()
      );
      if (!perm) return;
      const adm = copyModelForm.permissoes[titulo];
      const indexPerm = adm.indexOf(perm);
      perm.valor = novoValor;
      perm.alterado = true;
      adm[indexPerm] = perm;
      copyModelForm.permissoes[titulo] = adm;
      setModelForm({ ...copyModelForm });
      reset({ ...copyModelForm });
    },
    [modelForm, reset]
  );

  
  const retornaAcordions = useCallback(() => {
    //AQUI, TRANFORMAMOS A CLASSE EM ARRAY
    return Object.entries(modelForm.permissoes)
      .map((permissao) => {
        //AQUI, TRANSFORMO O permissao[1] (AO QUAL BUGA NO JS QUANDO FACO O ENTRIES) EM ARRAY NOVAMENTE
        const result = Object.keys(permissao[1]).map(
          (key) => permissao[1][key as any]
        );
        //PEGO O NOME DO OBJ E USO DE REFERENCIA PARA O ACORDION
        let titulo = PerfilGrupoPermissaoMock.find(
          (x) => x.Key === permissao[0]
        )?.Value;
        if (!titulo) titulo = permissao[0];
        return { titulo: titulo, permissoes: result, chave: permissao[0] };
      })
      .sort(function (a, b) {
        if (a.titulo < b.titulo) {
          return -1;
        }
        if (a.titulo > b.titulo) {
          return 1;
        }
        return 0;
      })
      .map((permissao, index) => {
        if (permissao.permissoes.length === 0) return <></>;
        const key = `permissoes-accordeon-${permissao.chave}__${index}`;

        return (
          <AccordionPerfil
            key={key}
            onPermissaoChanced={onPermissaoChanced}
            chave={permissao.chave}
            titulo={permissao.titulo}
            permissoes={permissao.permissoes}

          ></AccordionPerfil>
        );
      });
  }, [modelForm.permissoes, onPermissaoChanced]);

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInputNome.current?.focus();
    },
    fillForm: (model: PerfilEditFormModel) => {
      setModelForm(model);
      reset({
        ...model
      });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInputNome.current?.focus();
      }, 500);
    }
  }));

  const { callTour } = useTourSaurus()
  useEffect(() => {
    if (!loading)
      setTimeout(() => { callTour(EnumTour.CONFIGPERFILDEACESSO) }, 1000)
  }, [callTour, loading])

  return (
    <Box my={2}>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    autoComplete="new-password"
                    fullWidth
                    disabled={loading}
                    label="Nome"
                    variant="outlined"
                    error={Boolean(errors?.nome?.message)}
                    helperText={
                      touchedFields.nome || errors.nome
                        ? errors.nome?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    autoComplete="new-password"
                    fullWidth
                    disabled={loading}
                    label="Descrição / Informativo"
                    variant="outlined"
                    error={Boolean(errors?.descricao?.message)}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} id='tour-perfilDeAcessoHorarios'>
              <div>
                  <HorariosAcesso perfilId={getValues('id')} />
              </div>
              <div>
                <Typography color="primary" variant="h5">
                  <b>Permissões de Acesso</b>
                </Typography>
              </div>
              <div style={{ padding: 0 }}>
                <Box mx={2}>{retornaAcordions()}</Box>
              </div>
            </Grid>

          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </Box>
  );
});
