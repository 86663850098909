import { Grid, Typography } from '@material-ui/core';
import { CardProdMedicamentosProps } from './card-prod-medicamentos-props';
import { DefaultCard } from '../components';
import { useStyles } from './card-prod-medicamento-styles';
import { CheckboxDefault } from 'views/components/controles/checkboxes';

const CardProdMedicamento = ({
  model,
  onClick,
  onSelect,
  selecionado,
  isMobile
}: CardProdMedicamentosProps) => {
  const cardClasses = useStyles();

  return (
    <>
      <DefaultCard
        isSelected={selecionado}
        onClick={async () => {
          onClick(model);
        }}
      >
        <Grid container spacing={1} style={{ width: '100%', padding: 8 }}>
          <Grid xs={1}>
            <div className={cardClasses.cardLeftButton}>
              <CheckboxDefault
                onClick={(e: any) => {
                  onSelect(model);
                  e?.preventDefault();
                  e?.stopPropagation()
                }}
                checked={selecionado}
              />
            </div>
          </Grid>
          <Grid xs={10} container>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 8
              }}
            >
              <div
                className={cardClasses.celulaGrid}
                style={{
                  maxWidth: isMobile ? 220 : 370,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <Typography color="textPrimary" variant="caption" className={cardClasses.label}>
                  Medicamento
                </Typography>
                <Typography
                  color="textPrimary"
                  className={cardClasses.textConteudo}
                >
                  {model.xProd}
                </Typography>
              </div>
              <Grid style={{ display: 'flex' }}>
                {model.cEan && (
                  <div
                    className={cardClasses.celulaGrid}
                    style={{ marginRight: 8 }}
                  >
                    <Typography color="textPrimary" variant="caption" className={cardClasses.label}>
                      Código
                    </Typography>
                    <Typography
                      className={cardClasses.textConteudo}
                      color="textPrimary"
                    >
                      {model.cEan}
                    </Typography>
                  </div>
                )}
                <div className={cardClasses.celulaGrid}>
                  <Typography color="textPrimary" variant="caption" className={cardClasses.label}>
                    Itens
                  </Typography>
                  <Typography
                    className={cardClasses.textConteudo}
                    color="textPrimary"
                  >
                    {model.qCom}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};

export default CardProdMedicamento;
