import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetComissaoApuracaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissaoApuracaoBy = useCallback(
        (data: {
            empresaId: string;
            comissaoId: string;
            apuracaoId: string;
        }) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${data.empresaId}/comissao/${data.comissaoId}/apuracao/${data.apuracaoId}`,
                method: 'GET',
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            })
        }, [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getComissaoApuracaoBy,
    };
}