import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPePisProps } from "./form-pe-pis-props"
import { Controller } from "react-hook-form"
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { CstPisCofinsMock } from "data/mocks/cst-pis-mock"
import { useState } from "react"
import { ProdutoEntradaFormModel } from "model/app/forms/entrada/produto-entrada-form-model"
import { Grid } from "views/design-system"

export const FormPePis = ({ control, formState, getValues, setValue, ...props }: FormPePisProps) => {

    const { errors } = formState
    const [, setAtt] = useState<boolean>(false)
    const todosCampos = ["pis.vBc", "pis.pPis", "pis.qBcProd", "pis.vAliqProd", "pis.vPis"]
    const produtoPadrao = new ProdutoEntradaFormModel()

    const camposHabilitados = (value?: number) => ({
        1: ["pis.vBc", "pis.pPis", "pis.vPis"],
        2: ["pis.vBc", "pis.pPis", "pis.vPis"],
        3: ["pis.qBcProd", "pis.vAliqProd", "pis.vPis"],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        49: todosCampos,
        50: todosCampos,
        51: todosCampos,
        52: todosCampos,
        53: todosCampos,
        54: todosCampos,
        55: todosCampos,
        56: todosCampos,
        60: todosCampos,
        61: todosCampos,
        62: todosCampos,
        63: todosCampos,
        64: todosCampos,
        65: todosCampos,
        66: todosCampos,
        67: todosCampos,
        70: todosCampos,
        71: todosCampos,
        72: todosCampos,
        73: todosCampos,
        74: todosCampos,
        75: todosCampos,
        98: todosCampos,
        99: todosCampos,
    })[value || getValues('pis.cst')] || []

    const resetaCampos = (value: number) => {
        const camposString = camposHabilitados(value).map(y => y.split('.')[1])
        const objIcms = Object.entries(produtoPadrao.pis).filter(x => !camposString.includes(x[0]))
        const naoRemover = ['cst']
        objIcms.forEach(x => {
            if (!naoRemover.includes(x[0])) {
                if (x?.[1] !== undefined) {
                    setValue((`pis.${x[0]}`) as any, x[1])
                }
            }
        })
    }

    const manterHabilitado = (name: string) => camposHabilitados().filter(x => x === name).length === 0

    return (
        <AccordionSaurus
            labelPrimary="PIS"
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="pis.cst"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={CstPisCofinsMock}
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="CST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.pis?.cst && errors.pis.cst.message)}
                                helperText={
                                    errors.pis?.cst
                                        ? errors.pis?.cst?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = CstPisCofinsMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    resetaCampos(value)
                                    setValue("pis.cst", value)
                                    setAtt(prev => !prev)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="pis.vBc"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.pis?.vBc && errors.pis.vBc.message)}
                                helperText={
                                    errors.pis?.vBc
                                        ? errors.pis?.vBc?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="pis.pPis"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Aliquota PIS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.pis?.pPis && errors.pis.pPis.message)}
                                helperText={
                                    errors.pis?.pPis
                                        ? errors.pis?.pPis?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="pis.qBcProd"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Quantidade de B.C."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.pis?.qBcProd && errors.pis.qBcProd.message)}
                                helperText={
                                    errors.pis?.qBcProd
                                        ? errors.pis?.qBcProd?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="pis.vAliqProd"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor Unitário"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.pis?.vAliqProd && errors.pis.vAliqProd.message)}
                                helperText={
                                    errors.pis?.vAliqProd
                                        ? errors.pis?.vAliqProd?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="pis.vPis"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor PIS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.pis?.vPis && errors.pis.vPis.message)}
                                helperText={
                                    errors.pis?.vPis
                                        ? errors.pis?.vPis?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}