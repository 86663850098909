import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormTaxaEntregaValidation = () => {


    const formTaxaEntregaValidationYup = useMemo(() => {
        return (
            Yup.object({
                valor: Yup.string()
            })
        )
    }, [])

    return {
        formTaxaEntregaValidationYup,
    }
}

