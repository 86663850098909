import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const CimaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 24.984375 14 C 24.722656 14.003906 24.476563 14.109375 24.292969 14.292969 L 4.292969 34.292969 C 4.03125 34.542969 3.925781 34.917969 4.019531 35.265625 C 4.109375 35.617188 4.382813 35.890625 4.734375 35.980469 C 5.082031 36.074219 5.457031 35.96875 5.707031 35.707031 L 25 16.414063 L 44.292969 35.707031 C 44.542969 35.96875 44.917969 36.074219 45.265625 35.980469 C 45.617188 35.890625 45.890625 35.617188 45.980469 35.265625 C 46.074219 34.917969 45.96875 34.542969 45.707031 34.292969 L 25.707031 14.292969 C 25.515625 14.101563 25.253906 13.996094 24.984375 14 Z"></path>
      </DefaultIcon>
    </>
  );
};
