import { guidEmpty } from "utils/guid-empty";

export class MovimentacaoEstoqueModel {
    constructor(
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public produtoId: string = guidEmpty(),
        public produtoVariacaoId: string = guidEmpty(),
        public depositoId: string = guidEmpty(),
        public dhEmi: string | Date = '',
        public dhCompetencia: string | Date = '',
        public pessoaDocumento: string = '',
        public pessoaNome: string = '',
        public nfId: string = ' ',
        public nNf: number = 0,
        public serie: number = 0,
        public mod: number = 0,
        public cProd: string = '',
        public ean: string = '',
        public tpOp: number = 0,
        public uComReal: string = '',
        public uComOriginal: string = '',
        public qComReal: number = 0,
        public qComOriginal: number = 0,
        public fator: number = 0,
        public vProd: number = 0,
        public vFinal: number = 0,
        public status: number = 0,
        public xProd: string = ''
    ) { }
}

export class SomatoriosMovimentacoesEstoqueModel {
    constructor(
        public TotalQComOriginal: number = 0,
        public TotalQComReal: number = 0
    ) { }
}