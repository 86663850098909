import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetCampanhaApuracaoVendasExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getCampanhaApuracaoVendasExcel = useCallback(
        async (data: {
            page: number,
            empresaId: string,
            comissaoId: string,
            apuracaoId: string
        }) => {

            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${data.empresaId}/comissao/${data.comissaoId}/apuracao/${data.apuracaoId}/excel`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                timeout: 80000,
                params: {
                    page: data.page
                },
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=Comissoes.xls; filename*=UTF-8''Comissoes.xls"
                },
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getCampanhaApuracaoVendasExcel,
    };
}