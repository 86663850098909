import {
  Box,
  Typography,
  Button,
  Divider,
  Tooltip,
  Grid,
} from '@material-ui/core';
import { useMemo } from 'react';
import { UpgradeIcon } from 'views/components/icons/upgrade-icon';
import { useDefaultCardStyles } from '../components';
import classNames from 'classnames';
import { CardPlanoConfigInicialProps } from './card-plano-config-inicial-props';
import { useStyles } from './card-plano-config-inicial-styles';
import { CloseIconCircle } from 'views/components/icons/close-icon-circle';
import { useThemeQueries } from 'views/theme';
import { OkIcon } from 'views/components/icons';

const CardPlanoConfigInicial = ({
  model,
  modulos,
  planoAtual,
  planoUpgrade,
  escolherPlano,
  texto
}: CardPlanoConfigInicialProps) => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();
  const { theme } = useThemeQueries()
  // const limiteVendas = getPlanoLimite(model, EnumPlanoOpcoes.Vendas);
  const planoFragment = useMemo(() => {
    return (
      <>
        <div className={classes.cardContent}>
          <Grid container >
            <Grid item>
              <Box className={classes.cardContainerDescricao}>
                <Typography
                  variant="h5"
                  className={classes.cardTitle}
                >
                  {model?.nome}
                </Typography>

              </Box>
              <Box className={classes.cardContainerDescricao}>
                <Tooltip title={model?.descricao ?? ""} placement="top" >
                  <Typography
                    variant="caption"
                    color="secondary"
                    className={classNames(classes.cardSubTitle, classes.cardDescricao)}
                    paragraph
                  >
                    {model?.descricao}
                  </Typography>
                </Tooltip>
              </Box>

            </Grid>

            <Grid container wrap='wrap'>
              <Grid item className={classNames(classes.containerItem, classes.cardOpcoes)}>

                {!!model?.opcoes &&
                  model?.opcoes.length > 0 &&
                  model?.opcoes
                    .filter((x) => modulos.includes(x.codigo))
                    .sort(function (a, b) {
                      if (a.nome < b.nome) {
                        return -1;
                      }
                      if (a.nome > b.nome) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((itemOpcoes, index) => {
                      return (
                        <Grid className={classes.list} key={index}>
                          {model?.id !== 'custom' && <>
                            {parseInt(itemOpcoes.valor) > 0 ? (
                              <Box>
                                <OkIcon tipo='BUTTON' fill={theme.palette.success.main} />
                              </Box>
                            ) : (
                              <Box>
                                <CloseIconCircle tipo='BUTTON' fill={theme.palette.error.main} />
                              </Box>
                            )}
                          </>}
                          <Tooltip title={itemOpcoes.descricao} placement="top">
                            <Typography
                              variant="body2"
                              className={classes.cardOptionItem}
                              noWrap
                            >
                              {itemOpcoes?.nome}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      );
                    })}

              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.dividerGrid}>
              <Divider
                className={classes.divider}
              />
            </Grid>
            <Grid xs={12} item className={classes.cardDescricaoValor}>

              {model?.valor === 0 && model?.valorPromo === 0 && (
                <Typography
                  variant="h4"
                  color="primary"
                  className={classes.cardPricingFree}
                >
                  {model?.id === 'custom' ? '' : 'Grátis'}
                </Typography>
              )}

              {model?.valor !== 0 && model?.valorPromo === model?.valor && (
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.cardPricingR$}
                  >
                    R$
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary"
                    className={classes.cardPricing}
                  >
                    {model?.valor.toFixed(2).replace('.', ',')}
                  </Typography>
                  <Box className={classes.cardTextPerMonth}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.textPerMonth}
                    >
                      /mês
                    </Typography>
                  </Box>
                </Box>
              )}

              {model?.valorPromo > 0 && model?.valorPromo !== model?.valor && (
                <>
                  <Grid xs={12} className={classes.cardContainerDesconto}>
                    <Grid className={classes.cardItemDesconto}>


                      <Grid item xs={6} className={model.valorPromo < model.valor ? classes.textRiscado : ""}>
                        {model?.valorPromo < model.valor &&
                          <Typography
                            variant="caption"
                            className={classes.cardPricingPromoR$}
                          >
                            De: R$
                          </Typography>
                        }

                        <Typography
                          variant="caption"
                          className={classes.cardPricingPromo}
                        >
                          {model?.valorPromo < model.valor ? model?.valor.toFixed(2).replace('.', ',') : "Sem Desconto"}
                        </Typography>
                      </Grid>

                      <Grid xs={12} className={classNames(classesCard.celulaGrid, classes.cardPrincingDesconto)}>
                        <Typography
                          variant="body1"
                          color="primary"
                          className={classes.cardPricingR$}
                        >
                          R$
                        </Typography>
                        <Typography
                          variant="h4"
                          color="primary"
                          className={classes.cardPricing}
                        >
                          {model?.valorPromo.toFixed(2).replace('.', ',')}
                        </Typography>
                        <Box className={classes.cardTextPerMonth}>
                          <Typography
                            variant="body1"
                            color="primary"
                            className={classes.textPerMonth}
                          >
                            /mês
                          </Typography>
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} >
              <Box >
                {/* <Box className={classes.cardLimiteVendas}>
                  <Typography
                    variant="caption"
                  >
                    <Box height={20}>
                      {model.id !== 'custom' && (limiteVendas === -1
                        ? 'Vendas Ilimitadas'
                        : 'Limite de ' + limiteVendas + ' vendas mês')}
                    </Box>
                  </Typography>
                </Box> */}
                <Box >
                  <Button
                    variant={model.valorPromo < model.valor ? "contained" : "outlined"}
                    color="primary"
                    fullWidth
                    className={classes.buttonViewMore}
                    size='small'
                    onClick={escolherPlano}
                  >

                    <UpgradeIcon tipo="BUTTON_PRIMARY" fill={model.valorPromo > model.valor && theme.palette.primary.main} />

                    {texto}
                  </Button>
                </Box>

              </Box>
            </Grid>

          </Grid>
        </div >
      </>
    );
  }, [classes.buttonViewMore, classes.cardContainerDesconto, classes.cardContainerDescricao, classes.cardContent, classes.cardDescricao, classes.cardDescricaoValor, classes.cardItemDesconto, classes.cardOpcoes, classes.cardOptionItem, classes.cardPricing, classes.cardPricingFree, classes.cardPricingPromo, classes.cardPricingPromoR$, classes.cardPricingR$, classes.cardPrincingDesconto, classes.cardSubTitle, classes.cardTextPerMonth, classes.cardTitle, classes.containerItem, classes.divider, classes.dividerGrid, classes.list, classes.textPerMonth, classes.textRiscado, classesCard.celulaGrid, escolherPlano, model?.descricao, model?.id, model?.nome, model?.opcoes, model?.valor, model?.valorPromo, modulos, texto, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main]);

  return (
    <>
      <Box className={classes.cardPlano} >
        <Box >
          <Box
            className={
              planoUpgrade
                ? classes.backGroundTitlePlanSelect
                : planoAtual
                  ? classes.backGroundTitleMostPopularPlan
                  : classes.backGroundTitle
            }
          >
            <Typography
              variant="body1"
              className={classes.textTopTitle}
            >
              {planoUpgrade
                ? 'Melhor opção para você!'
                : planoAtual
                  ? 'Plano Atual.'
                  : ''}
            </Typography>
          </Box>
        </Box>
        <Box >
          <Box
            key={model.id}
            className={
              classes.cardContainer
            }
          >
            {planoFragment}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { CardPlanoConfigInicial };