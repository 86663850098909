import { useCallback, useMemo } from "react";
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { ButtonFab } from "views/components/controles";
import { useCadastros } from "services/app";
import { ImpostoAddIcon } from '../../../../components/icons/imposto-add-icon';
import { ImpostoList } from './components/imposto-list/imposto-list';
import { ImpostoHeader } from './components/imposto-header/imposto-header';
import { Box } from "views/design-system";

export const ImpostosPage = () => {
    const classes = useDefaultCadastroStyles();
    const { abrirCadastroProdutoFiscal } = useCadastros();

    const onClickAdd = useCallback(() => {
        abrirCadastroProdutoFiscal("", "", true);
    }, [abrirCadastroProdutoFiscal]);

    const component = useMemo(() => <Box className={classes.root}>
        <Box className={classes.header}>
            <ImpostoHeader />
        </Box>
        <Box className={classes.list}>
            <ImpostoList />
        </Box>
        <ButtonFab
            tooltip="Adicionar Grupo de Impostos"
            icon={<ImpostoAddIcon tipo="BUTTON_FAB" />}
            onClick={onClickAdd}
        />
    </Box>, [classes.header, classes.list, classes.root, onClickAdd]);

    return component;
};

export default ImpostosPage;
