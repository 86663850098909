import { Grid, Typography } from 'views/design-system';
import classNames from 'classnames';
import { HeaderProp, IConvertedStateProps, useVendaHeaderStore } from '../stores/venda-header-store';
import { useStyles } from './venda-header-desktop-styles';
import { DesfazerIcon, FiltroIcon, ProcurarIcon, VoltarIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { useCadastros } from 'services/app';
import { MenuHorizontalIcon } from 'views/components/icons/menu-horizontal-icon';
import { MenuCirculadoFilledIcon } from 'views/components/icons/menu-circulo-filled-icon';
import { ButtonHeaderDesktop } from 'views/components/controles/buttons/button-header-desktop/button-header-desktop';
import { PesquisaValor } from 'views/pages/private/gerenciar-sessao/components/sessao-valor-header/components/pesquisa-valor/pesquisa-valor';
import { useAutoInput } from 'services/app/hooks/auto-input';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

const retProp = (prop: HeaderProp<any>) => {
    if (typeof prop === 'object' && Object.keys(prop).includes('mobile') && Object.keys(prop).includes('desktop')) {
        return prop.desktop;
    }
    return prop;
}

export const VendaHeaderDesktop = () => {
    //STORAGE
    const props = useVendaHeaderStore((state) => state.props);


    const {
        id,
        title,
        showDefinicoes,
        showMenuPdv,
        pesquisa,
        showFiltro,
        openFiltro,
        voltar,
        customButton,
        hide,
        search
    } = Object.entries(props).reduce<IConvertedStateProps>((prev, curr) => ({ ...prev, [curr[0]]: retProp(curr[1]) }), {});

    const setSearch = useVendaHeaderStore((state) => state.setSearch);
    const switchFiltro = useVendaHeaderStore((state) => state.switchFiltro);

    // HOOKS
    const { callEvent } = useEventTools();
    const { abrirMenu, isMenuAberto, fecharMenu } = useMenuPrincipal();
    const { abrirDialogDefinicoes } = useCadastros();
    const { digitando } = useAutoInput({
        value: search || '',
        setValue(val) {
            setSearch(val)
        },
        ativo: Boolean(pesquisa),
        capturarDigitacao: props.pesquisa?.capturarDigitacao,
        aceitarLetras: Boolean(pesquisa?.allowLetras),
        aceitarNumeros: Boolean(pesquisa?.allowNumbers)
    });

    // AUX
    const classes = useStyles();

    const pesquisaValor = showFiltro && (
        <PesquisaValor
            isOpened={Boolean(openFiltro)}
            onCloseSearch={() => switchFiltro()}
            onPesquisar={(props) => {
                setSearch(props.termo);
            }}
        />
    );

    const bottomArea = () => {
        if (showFiltro && openFiltro) {
            return pesquisaValor;
        }
        return null
    }

    const retTitulo = () => {
        if (digitando) {
            if (search && search.length > 0) {
                return search;
            }
            return 'Digite para pesquisar...'
        }
        return title || 'Venda'
    }

    const showRightArea = [showDefinicoes, Boolean(pesquisa), showFiltro].includes(true) || customButton;

    return (
        <>
            <Grid className={classNames(classes.mainHeaderContainer, hide ? classes.displayNone : '')} id='vendaHeader'>
                <Grid container className={classes.topContainer}>
                    {(showMenuPdv && !digitando) && (
                        <ButtonHeaderDesktop
                            destaque
                            icon={<MenuCirculadoFilledIcon tipo='PRIVATE_HEADER' />}
                            onClick={() => {
                                if (isMenuAberto()) {
                                    fecharMenu();
                                } else {
                                    abrirMenu();
                                }
                            }}
                        />
                    )}
                    {(voltar || digitando) && (
                        <ButtonHeaderDesktop
                            destaque
                            icon={<VoltarIcon tipo='PRIVATE_HEADER' />}
                            onClick={() => {
                                if (digitando) {
                                    setSearch('');
                                    callEvent(AppEventEnum.TelaPesquisa, false);
                                    return
                                }
                                if (voltar) {
                                    voltar.onClick()
                                    return
                                }
                            }}
                        />
                    )}

                    <Grid item className={classNames(classes.flexContent)}>
                        <Typography variant="body1" className={classes.title}>
                            {
                                retTitulo()
                            }
                        </Typography>
                    </Grid>

                    {showRightArea && (
                        <Grid item className={classes.flexFixedRight}>
                            {customButton && customButton.map(btn => (
                                (
                                    <ButtonHeaderDesktop
                                        icon={btn.icon}
                                        onClick={() => {
                                            btn.onClick()
                                        }}
                                        tooltip={btn.tooltip}
                                    />
                                )
                            ))}
                            {(pesquisa && !digitando) && (
                                <ButtonHeaderDesktop
                                    icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
                                    onClick={() => {
                                        setSearch('');
                                        callEvent(AppEventEnum.TelaPesquisa, true);
                                    }}
                                />
                            )}
                            {showFiltro && (
                                <ButtonHeaderDesktop
                                    icon={openFiltro ? <DesfazerIcon tipo='PRIVATE_HEADER' /> : <FiltroIcon tipo='PRIVATE_HEADER' />}
                                    onClick={() => {
                                        switchFiltro();
                                    }}
                                />
                            )}
                            {showDefinicoes && (
                                <ButtonHeaderDesktop
                                    divisoriaLateral
                                    icon={<MenuHorizontalIcon tipo="PRIVATE_HEADER" />}
                                    onClick={() => {
                                        abrirDialogDefinicoes();
                                    }}
                                    id={id}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
                {bottomArea() && (
                    <Grid className={classes.bottomContainer} item xs={12}>
                        {bottomArea()}
                    </Grid>
                )}
            </Grid>
        </>
    );
};