import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { CalculadoraModal } from 'views/components/modals/calculadora/calculadora-modal';

export const Calculadora = () => {
  const { push, location } = useHistory();
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
    setValue: (index: number, value: string) => { },
    value: ''
  });

  const fechamentoRoute = `/venda-simples/gerenciar-sessao-fechamento-caixa/`;
  const calculadoraRoute = 'calcular';

  useEffect(() => {
    if (location.pathname.indexOf(fechamentoRoute) === 0) {
      const posrota = location.pathname.replace(fechamentoRoute, '');
      if (posrota.length > 0) {
        setModalState({
          id: posrota.replace(calculadoraRoute, ''),
          aberto: true,
          ultimaUrl: fechamentoRoute,
          setValue: modalState.setValue,
          value: modalState.value
        });
        return;
      }
    }
  }, [
    location.pathname,
    fechamentoRoute,
    modalState.setValue,
    modalState.value,
  ]);

  const modalAlterado = useCallback(
    ({ id, openned, callbackUrl, trocarUrl, value, setValue }: any) => {
      let url = callbackUrl.length === 0 ? fechamentoRoute : callbackUrl;
      if (modalState.aberto) {
        url = modalState.ultimaUrl;
      }

      if (!openned) {
        push(fechamentoRoute);
      }
      if (trocarUrl) {
        push(fechamentoRoute + (id?.length === 0 ? calculadoraRoute : id));
      }
      
      setModalState({
        id: id,
        aberto: openned,
        ultimaUrl: url,
        setValue: setValue,
        value: value
      });

    },
    [modalState.aberto, modalState.ultimaUrl, fechamentoRoute, push],
  );

  useEffect(() => {
    addHandler(AppEventEnum.CalculadoraModal, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.CalculadoraModal, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <CalculadoraModal
      openned={modalState.aberto}
      key="calculado-modal"
      id={modalState.id}
      value={modalState.value}
      setValue={modalState.setValue}
    />
  );
};
