import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ComissaoApuracaoModal } from "views/components/modals/comissao-apuracao/comissao-apuracao-modal";

export const CadastroComissaoApuracaoModalRender = () => {
    // const { push } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        comissaoId: '',
        openned: false,
        ultimaUrl: '',
    });

    // const comissaoRoute = `/comissoes/`;
    // const apuracaoRoute = `/apuracao`;

    consoleDev('CadastroComissaoApuracaoModal')

    useEffect(() => {
    }, []);

    const modalAlterado = useCallback(({ comissaoId, openned, callbackUrl, trocarUrl }: any) => {
        // let url = comissaoRoute + comissaoId + apuracaoRoute
        // if (callbackUrl.length > 0) {
        //     url = callbackUrl
        // }

        // if (modalState.openned) {
        //     url = modalState.ultimaUrl;
        // }
        setModalState({
            id: '',
            comissaoId,
            openned: openned,
            ultimaUrl: ''
            // url,
        });
        // if (!openned && !trocarUrl) {
        //     push({
        //         pathname: url,
        //     })
        // }
        // if (trocarUrl) {
        //     push(url);
        // }
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ComissaoApuracaoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ComissaoApuracaoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            <ComissaoApuracaoModal openned={modalState.openned} key="mdlComissaoApuracao" comissaoId={modalState.comissaoId} />
        </>
    )
}