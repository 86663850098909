import { useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react';
import { Grid } from 'views/design-system';
import { useStyles } from './catalogo-list-styles';
import { CatalogoListData } from './catalogo-list-data';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { guidEmpty, guidEmptyOne } from 'utils/guid-empty';
import { TabelaProdutos } from 'database/interfaces/interface-tabela-produtos';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { TabelaCategorias } from 'database/interfaces/interface-tabela-categorias';
interface CategoriaProps {
  idCategoria: string;
  cor: string;
  todasCategorias: Array<ProdutoCategoriaModel | TabelaCategorias>
}

export const CatalogoList = ({ idCategoria, todasCategorias, cor }: CategoriaProps) => {
  const classes = useStyles();
  const {
    inserirProdutoDefault,
    inserirProdutoFromSearchDetailed,
    validaInsercaoDetalhado,
    carregando: carregandoMovProd,
  } = useMovProd();
  const { showToast } = useToastSaurus();
  const carregando = carregandoMovProd;
  const { abrirSelecaoProdutosCodigoDialog } = useCadastros();
  let defaultContainer = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | null>(null)
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 100,
    list: Array<ProdutoResumidoModel | TabelaProdutos>()
  });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => setHeight((defaultContainer.current?.offsetHeight ?? 0)));

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ProdutoResumidoModel | TabelaProdutos>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number, idCategoria: string = '') => {
      try {
        let prods: TabelaProdutos[] = [];

        if (guidEmptyOne() === idCategoria) {
          prods = await TouchoneDBPrimary.produtos
            .filter((produto) => produto.favorito === true)
            .toArray();
        } else if (guidEmpty() === idCategoria) {
          prods = await TouchoneDBPrimary.produtos
            .filter((produto) => produto.categoriaId === null)
            .toArray();
        } else {
          prods = await TouchoneDBPrimary.produtos
            .filter((produto) => produto.categoriaId === idCategoria)
            .toArray();
        }

        fillResult(
          1,
          1,
          prods.length,
          prods.filter(
            (model: TabelaProdutos) =>
              model.tipo === EnumTipoProduto.Produto ||
              model.tipo === EnumTipoProduto.Medicamento ||
              model.tipo === EnumTipoProduto.Combo
          )
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fillResult, showToast]
  );

  useEffect(() => {
    (async () => {
      await search(1, idCategoria);
    })();
  }, [idCategoria, search]);

  const refreshList = async () => {
    await search(1, idCategoria);
  };

  const onCardHolded = async (produto: ProdutoResumidoModel) => {
    const isOpenDetalhado = validaInsercaoDetalhado()
    if (isOpenDetalhado) return
    await inserirProdutoFromSearchDetailed(produto);
  };

  const onCardClicked = async (produto: ProdutoResumidoModel) => {
    const isOpenDetalhado = validaInsercaoDetalhado()
    if (isOpenDetalhado) {
      await inserirProdutoFromSearchDetailed(produto);
      return
    }
    if (produto.codigos.length > 1) {
      abrirSelecaoProdutosCodigoDialog(produto);
      return
    }

    await inserirProdutoDefault(produto);
  };

  return (
    <>
      <div id="defaultContainer" ref={defaultContainer} className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container className={classes.background}>
          <CatalogoListData
            cor={cor}
            height={height}
            todasCategorias={todasCategorias}
            categoriaId={idCategoria}
            carregando={carregando}
            list={(queryStatus.list as ProdutoResumidoModel[]) ?? []}
            refreshList={refreshList}
            onCardHolded={onCardHolded}
            onCardClicked={onCardClicked}
          />
        </Grid>
      </div>
    </>
  );
};
