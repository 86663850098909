import { useCallback, useState } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, ProcurarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { FormPesquisaHeader } from "views/components/form/form-pesquisa-header/form-pesquisa-header";
import { Box } from "views/design-system";

export const ImpostoHeader = () => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const { abrirImpostosFiltroModal } = useFiltrosModais();

    const history = useHistory();

    const [openTextfield, setOpenTextfield] = useState<boolean>(true);

    const urlParams = new URLSearchParams(history.location.search)
    const filtros = {
        status: urlParams.get('status'),
        termo: urlParams.get('termo')
    }

    const leftArea = useCallback(
        () => (
            isMobile ?
                <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
                null
        ),
        [isMobile, abrirMenu]
    );

    const rightArea = useCallback(
        () => (
            <Box flex gap={2}>
                {!openTextfield && <ButtonPrivateHeader
                    icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
                    tooltip={'Abrir Pesquisa'}
                    onClick={() => setOpenTextfield(true)}
                ></ButtonPrivateHeader>}
                <ButtonPrivateHeader
                    icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
                    tooltip={'Filtros'}
                    onClick={() => {
                        abrirImpostosFiltroModal({
                            status: !isEmpty(filtros.status) ? Number(filtros.status) : 1,
                            termo: filtros.termo || ''
                        })
                    }}
                ></ButtonPrivateHeader>
            </Box>
        ),
        [abrirImpostosFiltroModal, filtros.status, filtros.termo, openTextfield]
    );

    const searchTextfield = (
        <FormPesquisaHeader
          text={filtros.termo || ''}
          closeSearch={() => setOpenTextfield(false)}
          onSubmit={(model) => {
            const searchs: Array<string | null> = [
              `status=${filtros.status}`,
              model.generico.length > 0 ? `generico=${model.generico}` : null,
            ]
    
            let query = ''
    
            searchs
              .filter(x => x)
              .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)
    
            history.push({
              pathname: '/impostos',
              search: query
            })
          }}
          placeholder="Buscar grupo pelo nome..."
        />
      )

    return (
        <>
            <PrivatePageHeader
                title="Grupo de Impostos"
                leftArea={leftArea()}
                middleArea={openTextfield && searchTextfield}
                rightArea={rightArea()}
            />
        </>
    );
};
