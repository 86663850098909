import { EnumTpSituacaoApuracao } from "data/api/gestao/comissao/patch-campanha-apuracao-descondierar-comissionado";
import { IVendaComissao } from "./IVendaComissao";
import { EnumTpComissionado } from "model/enums/enum-tp-comissionado";

export enum EnumTpStatusProcessamento {
    Processando = 0,
    Concluido = 1
}

export interface IComissionado {
    id: string
    baseCalculo: number;
    dtComp: string
    nome: string;
    qtdItens: number;
    valorComissao: number;
    vendas: IVendaComissao[];
    tipo: EnumTpComissionado;
    valorCondicao: string;
    situacao: EnumTpSituacaoApuracao;
    statusProcessamento: EnumTpStatusProcessamento;
}   