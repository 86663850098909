import { FiltroIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { useStyles } from './relatorios-avancados-filtro-styles'
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { SituacaoGeralMockTodos } from "data/mocks/situacao-geral-mock";
import { NumberIcon } from 'views/components/icons/number-icon';
import { FormPesquisaGenerico } from 'views/components/form/pesquisa-generica/form-pesquisa-generico';
import { RelatoriosAvancadosListSearchProps } from 'views/pages/private/relatorios/relatorios-avancados/components/relatorios-avancados-list/relatorios-avancados-list-search-props';
import { Box, Button } from 'views/design-system';

interface ITelaState {
    status: boolean;
    codigo: boolean
}

const retornaLabel = (valor: number) => {
    switch(valor){
        case 1:
            return 'Habilitado'
        case 0:
            return 'Desabilitado'
        default:
            return 'Todos'
    }
}

interface RelatoriosAvancadosPesquisaModalProps {
    openned: boolean;
    filtros: RelatoriosAvancadosListSearchProps;
}
export const RelatoriosAvancadosFiltroModal = ({
    openned,
    filtros
}: RelatoriosAvancadosPesquisaModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { fecharRelatoriosAvancadosFiltroModal } = useFiltrosModais();
    const history = useHistory();

    const [telas, setTelas] = useState<ITelaState>({
        status: false,
        codigo: false
    })
    const [localProps, setLocalProps] = useState<RelatoriosAvancadosListSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (Object.values(telas).some(x => x)) {
            setTelas(() => ({
                status: false,
                codigo: false
            }))
            return
        }
        fecharRelatoriosAvancadosFiltroModal(false)
    }, [fecharRelatoriosAvancadosFiltroModal, telas])

    const onSubmit = useCallback((localProps: RelatoriosAvancadosListSearchProps) => {
        const searchs: Array<string | null> = [
            `status=${localProps.status}`,
            `codigo=${localProps.codigo}`,
            localProps.termo.length > 0 ? `termo=${localProps.termo}` : null,
        ]

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        history.push({
            pathname: '/relatorios-avancados',
            search: query
        })
        fecharRelatoriosAvancadosFiltroModal(true)
    }, [fecharRelatoriosAvancadosFiltroModal, history])

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    <Box className={classNames(classes.container)}>
                        <CardFiltro<RelatoriosAvancadosListSearchProps, ITelaState>
                            icon={<FiltroIcon />}
                            nome="Situação do relatórios"
                            propName="status"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="status"
                            value={retornaLabel(localProps.status)}
                            valorPadrao={1}
                            valorAtual={localProps.status}
                        />
                         <CardFiltro<RelatoriosAvancadosListSearchProps, ITelaState>
                            icon={<NumberIcon />}
                            nome="Código do relatório"
                            propName="codigo"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="codigo"
                            value={localProps.codigo}
                            valorPadrao={''}
                        />
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => onSubmit({
                                status: 1,
                                termo: '',
                                codigo: ''
                            })}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>
                        {telas.status && (
                           <RadioList
                           handleSubmit={async (status) => {
                               setLocalProps(prev => ({
                                   ...prev,
                                   status: status
                               }))
                               setTelas(prev => ({
                                   ...prev,
                                   status: false
                               }))
                           }}
                           selecionado={localProps.status}
                           mock={SituacaoGeralMockTodos}
                       />
                        )}
                          {telas.codigo && (
                           <FormPesquisaGenerico
                                onSubmit={(model) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        codigo: model.generico
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        codigo: false,
                                        descricao: false,
                                        categoria: false,
                                        marca: false
                                    }))
                                }}
                                text={(localProps as any)?.codigo ?? ''}
                                label={'Código'}
                                placeholder={'Pesquise pelo código do relatório'}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal >
    )
}