
export class ProdutoFiscalEditFormModel {
    constructor(
        public id: string = '',
        public contratoId = '',
        public empresaId = '',
        public descricao: string = '',
        public cfop: string = '',
        public cfopDesc: string = '',
        public cBenef: string = '',
        public icmsCst: number = 0,
        public icmsModBc: number = 0,
        public icmsPRedBc: number = 0,
        public icmsPIcms: number = 0,
        public icmsModBcSt: number = 0,
        public icmsPMvaVUnidSt: number = 0,
        public icmsPredBcSt: number = 0,
        public icmsPIcmsSt: number = 0,
        public icmsMotDesIcms: number = 1,
        public icmsPBcOp: number = 0,
        public icmsUfSt: string = '',
        public icmsPCredSn: number = 0,
        public icmsPredSn: number = 0,
        public icmsPFcp: number = 0,
        public icmsPFcpSt: number = 0,
        public pisCst: number = 1,
        public pisPercVUnidPis: number = 0,
        public cofinsCst: number = 1,
        public cofinsPercVUnidCofins: number = 0,
        public ecfAliq: number = 0,
        public ativo: boolean = false,
        public infComplementar: string = '',
    ) { }
}