import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid } from 'views/design-system';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { CardTransferirItens } from 'views/components/cards/card-tranferir-itens/card-transferir-itens';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';

export interface TransferirItensListDataProps {
  itens: Array<PedidoProdutoModel>;
  carregando: boolean;
  onCardSelected: (produto: PedidoProdutoModel) => any;
  itensSelecionados: Array<string>;
}

export const TransferirItensListData = (
  props: TransferirItensListDataProps,
) => {
  const onCardSelected = (produto: PedidoProdutoModel) => {
    props.onCardSelected(produto);
  };

  const produtosAdicionais =
    props.itens ?? [];

  const agruparProdutosSubItens = () => {
    const produtosAgrupados: PedidoProdutoModel[] =
      props.itens.filter((p) => ((!p.indFin && p.valorTotal === 0) ? (p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem) : p.indFin) &&
        (p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem || p.tpProduto.codigo === EnumTpProduto.Produto || p.tpProduto.codigo === EnumTpProduto.Medicamento));

    return produtosAgrupados.map((prodAgrupado) => {
      const prodAdicional = produtosAdicionais.filter(
        (adicional) => adicional.groupId === prodAgrupado.codigoReferencia && adicional.tpProduto.codigo !== EnumTpProduto.ProdutoComSubItem
      );

      if (prodAdicional.length > 0) {
        return {
          ...prodAgrupado,
          adicionais: prodAdicional
        };
      }

      return {
        ...prodAgrupado,
        adicionais: []
      };
    });
  };

  const produtos = agruparProdutosSubItens();
  return (
    <>
      {props.itens.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum item encontrado."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}

      {produtos.length > 0 &&
        produtos.map((produto) => {
          return (
            <CardTransferirItens
              model={produto}
              key={produto.id}
              onClick={onCardSelected}
              selecionado={
                props.itensSelecionados.filter(
                  (prodId) => prodId === produto.id,
                ).length === 1
                  ? true
                  : false
              }
            />
          );
        })}
    </>
  );
};
