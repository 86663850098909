import { usePostPedidoDados } from 'data/api/gestao/pedido-dados/post-pedido-dados';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  GestaoStorageKeys,
  useCadastros,
  useGestaoStorage,
  useToastSaurus
} from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { AtualizarIcon, CancelarIcon, VoltarIcon } from 'views/components/icons';
import { useStyles } from './mov-enviar-pedido-page-styles';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { usePostPedidoProduto } from 'data/api/gestao/pedido-produto/post-pedido-dados-produtos';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { guidEmpty } from 'utils/guid-empty';
import { useSessaoAtual } from 'services/app';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { isEmpty } from 'lodash';
import { usePDV } from 'services/app/hooks/pdv';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { usePostPedidoReceita } from 'data/api/gestao/pedido-receita/post-pedido-receita';
import { useGetHealth } from 'data/api/gestao/healthy';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { VendaIcon } from 'views/components/icons/venda-icon';
import { Button, Grid, Typography } from 'views/design-system';
import { useGetPedidoSalaoById } from 'data/api/gestao/pedido/get-pedido-salao-by-id';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useMovValidators } from 'services/app/hooks/mov-validators';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { EnumMovModelo } from 'model';

enum EnumSituacao {
  ProcurandoPedido = 0,
  IniciandoNovoPedido = 1,
  EnviandoReceitas = 2,
  EnviandoProdutos = 3,
  FinalizandoEnvioPedido = 4,
}

export const MovEnviarPedidoPage = () => {
  // PROVIDERS
  const {
    getMov,
    setPedidoExistente,
    carregando: carregandoProvider,
    setSalaoId,
    setMesaId,
    setComandaId,
    setTipoMovimentacao,
    limparInformacoesPedidos,
    retornarModDaVenda
  } = useMovAtual();
  const {
    pedidoDeliveryDadosWrapper,
    pedidoProdutosDeliveryWrapper,
    deletePedidoDelivery,
  } = usePedidoDelivery();
  const { delRegistro } = useGestaoStorage();
  const {
    pedidoDadosWrapper,
    pedidoProdutosWrapper,
    getConfiguracoesMesaEComanda,
    getSaloesCadastrados
  } = usePedidoLocal();
  const { getEmpresaSelecionada, getPermissaoBoolean } = useSessaoAtual();
  const { getConfigByCod: getContratoConfig } = useContratoAtual();
  const { getConfigByCod: getEmpresaConfig } = useEmpresaAtual();
  const { getConfigByCod: getConfigPDV } = usePDV();
  const { abrirSolicitarPermissao } = useCadastros();
  const {
    redirectLanding,
    redirectSelecionarCliente,
    redirectCatalogo,
    redirectCarrinho,
    redirectPagamentoAvancado,
    validarInicioDoPedido,
    redirectPedidoVincularComanda,
    redirectPedidoVincularMesa,
    redirectVendaFrete,
    redirectLancadorPadrao
  } = useMovRota();
  const {
    dadosIniciaisPendente,
    dadosProdutosPendente,
    dadosPedidoComandaPendente
  } = useMovValidators();

  // CHAMADAS API
  const { getPedidoSalao, carregando: carregandoGetPedidos } =
    useGetPedidoSalao();
  const { getPedidoSalaoById, carregando: carregandoGetPedidoSalaoById } =
    useGetPedidoSalaoById();
  const { postPedidoDados, carregando: carregandoPostPedidoDados } =
    usePostPedidoDados();
  const { postPedidoProdutos, carregando: carregandoPostProdutos } =
    usePostPedidoProduto();
  const { postPedidoReceita, carregando: carregandoPostReceitas } =
    usePostPedidoReceita();
  const { getHealth, carregando: carregandoHealth } = useGetHealth();

  // AUX
  const carregando = [
    carregandoProvider,
    carregandoGetPedidos,
    carregandoPostPedidoDados,
    carregandoPostReceitas,
    carregandoPostProdutos,
    carregandoHealth,
    carregandoGetPedidoSalaoById
  ].includes(true);

  const { push: historyPush } = useHistory();
  const classes = useStyles();
  const [error, setError] = useState<Error>();
  const { showToast } = useToastSaurus();

  const finalizarComoVenda = useRef<boolean>(false);

  const [situacao, setSituacao] = useState<EnumSituacao>(EnumSituacao.ProcurandoPedido);

  // MARK: transformarEmVenda
  const transformarEmVenda = async () => {
    try {
      await setComandaId('');
      await setMesaId('');
      await setSalaoId('');
      await setTipoMovimentacao(retornarModDaVenda().mod);
      return await redirectPagamentoAvancado();
    } catch (e: Error | any) {
      showToast('error', e.message);
    }
  };

  // MARK: verificarConexao
  const verificarConexao = useCallback(async () => {
    try {
      const rethealth = await getHealth();

      if (rethealth.erro) throw new Error();
    } catch (e: any) {
      const modeloTrabalho = getConfigPDV(101);
      const temFechamento = [
        EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS
      ].includes(modeloTrabalho as EnumModeloDeTrabalho);
      if (
        temFechamento &&
        (getMov()!.produtos.filter((x) => x.ativo)?.length ?? 0) > 0
      ) {
        finalizarComoVenda.current = true;
      }
      throw new Error('Sem conexão com a internet.');
    }
  }, [getConfigPDV, getHealth, getMov]);

  // MARK: pedidosQueNaoPodemReceberProdutos
  const pedidosQueNaoPodemReceberProdutos = [
    EnumStatusPedido.CANCELADO,
    EnumStatusPedido.FECHADO,
    EnumStatusPedido.FINALIZADOOUENTREGUE,
    EnumStatusPedido.IMPORTADO
  ];

  let limiteReq = 1;

  // MARK: iniciarVerificacaoDoPedido
  const iniciarVerificacaoDoPedido = useCallback(async () => {
    finalizarComoVenda.current = false;
    switch (situacao) {
      case EnumSituacao.ProcurandoPedido:
        try {
          const mov = getMov();

          if (await dadosIniciaisPendente(mov)) {
            showToast('info', 'nenhuma venda em andamento');
            await redirectLanding();
            return;
          }

          if (mov?.mod === EnumMovModelo.PEDIDO) {
            if (!(await validarInicioDoPedido())) {
              await redirectLanding();
            }

            const mesaOuComanda = getConfiguracoesMesaEComanda();
            const balcoes = await getSaloesCadastrados();

            const qtdBalcoes =
              balcoes.filter(
                (salao) => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA
              )?.length ?? 0;

            if (
              mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
              (await dadosPedidoComandaPendente(mov!))
            ) {
              await redirectPedidoVincularComanda();
              return;
            }

            if (
              mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA &&
              mov!.informacoesGeraisPedido.mesaId === '' &&
              !mov!.informacoesGeraisPedido.balcaoIdentificado &&
              (mesaOuComanda.qtdeMesa > 0 || qtdBalcoes > 1)
            ) {
              redirectPedidoVincularMesa();
              return;
            }

            // query de busca se for mesa ou comanda
            let query = '';

            if (
              mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
              mov?.informacoesGeraisPedido.comandaId
            ) {
              query = `comandaId=${mov?.informacoesGeraisPedido.comandaId}`;
            }

            if (
              mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA &&
              mov?.informacoesGeraisPedido.mesaId
            ) {
              query = `mesaId=${mov?.informacoesGeraisPedido.mesaId}`;
            }

            await verificarConexao();

            // Aqui eu verifico se a venda não tem id o que significa que não identificamos o pedido anteriormente
            if (
              (mov?.id === '' || mov?.id === guidEmpty()) &&
              (!isEmpty(mov?.informacoesGeraisPedido.comandaId) ||
                !isEmpty(mov?.informacoesGeraisPedido.mesaId))
            ) {
              const responsePedidos = await getPedidoSalao(
                getEmpresaSelecionada()!.Id,
                query
              );

              if (responsePedidos.isTimeout) {
                if (limiteReq <= 2) {
                  limiteReq++;
                  await iniciarVerificacaoDoPedido();
                } else {
                  throw new Error(
                    `Não foi possível identificar o pedido, tente novamente. Detalhe: ${responsePedidos.erro}`
                  );
                }
                return;
              }

              if (responsePedidos.erro) {
                throw new Error(
                  `Não foi possível identificar o pedido, tente novamente. Detalhe: ${responsePedidos.erro}`
                );
              }

              const pedidos =
                (responsePedidos?.resultado?.data?.list as PedidoModel[]) ?? [];

              // aqui verifico o status do pedido para saber se posso atrelar produtos a ele.
              if (
                pedidos?.length > 0 &&
                pedidosQueNaoPodemReceberProdutos.includes(
                  pedidos[0].statusPedido.codigo
                )
              ) {
                if (
                  mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
                  mov?.informacoesGeraisPedido.comandaId
                ) {
                  const comanda = await TouchoneDBPrimary.comandas.get({
                    id: pedidos[0].comandaId
                  });

                  showToast(
                    'error',
                    `A comanda ${comanda?.codigoComanda} está no status de '${pedidos[0].statusPedido.descricao}' e não permite lançamentos.`
                  );
                  redirectPedidoVincularComanda();
                  return;
                }

                if (
                  mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA &&
                  mov?.informacoesGeraisPedido.mesaId
                ) {
                  const mesa = await TouchoneDBPrimary.mesas.get({
                    id: pedidos[0].mesaId
                  });

                  showToast(
                    'error',
                    `A mesa ${mesa?.codigo} está no status de '${pedidos[0].statusPedido.descricao}' e não permite lançamentos.`
                  );
                  redirectPedidoVincularMesa();
                  return;
                }
              }

              let codigoMesa: string = '';

              if (pedidos?.length > 0 && pedidos[0].mesaId) {
                const mesa = await TouchoneDBPrimary.mesas.get({
                  id: pedidos[0].mesaId
                });

                if (mesa) {
                  codigoMesa = mesa.codigo ?? '';
                }
              }

              let codigoComanda: string = '';

              if (pedidos?.length > 0 && pedidos[0].comandaId) {
                const comanda = await TouchoneDBPrimary.comandas.get({
                  id: pedidos[0].comandaId
                });

                if (comanda) {
                  codigoComanda = comanda.codigoComanda ?? '';
                }
              }

              if (pedidos?.length > 0) {
                await setPedidoExistente(
                  pedidos[0].id,
                  pedidos[0].comandaId,
                  pedidos[0].mesaId,
                  pedidos[0].salaoId,
                  codigoMesa,
                  codigoComanda,
                  pedidos[0].codigoPedido || '',
                  pedidos[0].identificador || ''
                );
                setSituacao(EnumSituacao.EnviandoReceitas);
                return;
              }
              setSituacao(EnumSituacao.IniciandoNovoPedido);
            } else if (mov?.id !== '' && mov?.id !== guidEmpty()) {
              setSituacao(EnumSituacao.EnviandoReceitas);
            } else {
              setSituacao(EnumSituacao.IniciandoNovoPedido);
            }
          }

          if (mov?.mod === EnumMovModelo.DELIVERY) {
            const produtoEntrega = getContratoConfig(EnumContratoConfig.ProdutoEntrega)

            if (!mov.cliente?.endereco) {
              await redirectSelecionarCliente()
              return;
            }
            //aqui verifico se tenho produtos.
            if (mov.produtos.length === 0) {
              showToast('info', 'Nenhum produto foi identificado');
              redirectCatalogo();
              return;
            }
            if (mov.produtos.filter(p => p.produtoId === produtoEntrega).length === 0) {
              showToast('info', 'Taxa de Entrega não foi registrada.');
              await redirectVendaFrete();
              return;
            }

            if (mov.pags.length <= 0) {
              await redirectPagamentoAvancado();
              return
            }

            const pedido = await pedidoDeliveryDadosWrapper(mov);

            const postPedido = await postPedidoDados(
              getEmpresaSelecionada()!.Id,
              pedido,
            );

            if (postPedido.erro) {
              throw new Error(
                'Não foi possível criar um pedido, tente novamente.',
              );
            }

            const pedidoCriado = postPedido.resultado?.data as PedidoModel;

            await setPedidoExistente(
              pedidoCriado.id,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              true
            );

            setSituacao(EnumSituacao.IniciandoNovoPedido);
          }

          if (mov?.mod !== EnumMovModelo.DELIVERY && mov?.mod !== EnumMovModelo.PEDIDO) {
            await redirectLanding();
            return
          }
        } catch (e: any) {
          setError(e);
          return;
        }
        break;
      case EnumSituacao.IniciandoNovoPedido:
        try {
          const mov = getMov();

          if (await dadosIniciaisPendente(mov)) {
            showToast('info', 'nenhuma venda em andamento');
            await redirectLanding();
            return;
          }

          if (mov?.mod === EnumMovModelo.PEDIDO) {
            const mesaOuComanda = getConfiguracoesMesaEComanda();
            const saloes = await getSaloesCadastrados();
            // aqui eu verifico se meu tipo de trabalho é comanda e eu posso identificar a mesa

            const mesas = mesaOuComanda?.qtdeMesa ?? 0;

            const balcoes = saloes.filter(
              (salao) => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA
            );
            const semMesasEBalcao = mesas === 0 && (balcoes?.length ?? 0) === 0;

            if (mesas > 0 || (balcoes?.length ?? 0) > 0) {
              if (
                mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
                !mov?.informacoesGeraisPedido.mesaId &&
                mov?.informacoesGeraisPedido.balcaoIdentificado === false &&
                !semMesasEBalcao &&
                (mesas > 0 || (balcoes?.length ?? 0) > 1)
              ) {
                redirectPedidoVincularMesa();
                return;
              } else if (
                (balcoes?.length ?? 0) === 1 &&
                isEmpty(mov?.informacoesGeraisPedido.salaoId)
              ) {
                await setSalaoId(balcoes[0].id || '');
              }
            }

            //aqui verifico se tenho produtos.
            if (await dadosProdutosPendente(mov!)) {
              redirectCatalogo();
              return;
            }

            if (
              !mov?.clienteIdentificado &&
              getEmpresaConfig(EnumEmpresaConfig.SelecaoClienteVenda) === '2'
            ) {
              await redirectSelecionarCliente();
              return;
            }

            const pedido = pedidoDadosWrapper(getMov() || mov!); //getMov caso tenha atualizado salaoId para o balcao

            await verificarConexao();

            const postPedido = await postPedidoDados(
              getEmpresaSelecionada()!.Id,
              pedido
            );

            if (postPedido.erro) {
              throw new Error(
                `Não foi possível criar um pedido, tente novamente. Detalhe: ${postPedido.erro}`
              );
            }

            const pedidoCriado = postPedido.resultado?.data as PedidoModel;

            let codigoMesa: string = '';

            if (pedidoCriado.mesaId) {
              const mesa = await TouchoneDBPrimary.mesas.get({
                id: pedidoCriado.mesaId
              });

              if (mesa) {
                codigoMesa = mesa.codigo ?? '';
              }
            }

            let codigoComanda: string = '';

            if (pedidoCriado.comandaId) {
              const comanda = await TouchoneDBPrimary.comandas.get({
                id: pedidoCriado.comandaId
              });

              if (comanda) {
                codigoComanda = comanda.codigoComanda ?? '';
              }
            }

            await setPedidoExistente(
              pedidoCriado.id,
              pedidoCriado.comandaId,
              pedidoCriado.mesaId,
              pedidoCriado.salaoId,
              codigoMesa,
              codigoComanda,
              pedidoCriado.codigoPedido || '',
              pedidoCriado.identificador || ''
            );

            setSituacao(EnumSituacao.EnviandoReceitas);
          }

          if (mov?.mod === EnumMovModelo.DELIVERY) {
            const produtos = pedidoProdutosDeliveryWrapper(
              mov.produtos.filter(x => x.produtoId !== getContratoConfig(EnumContratoConfig.ProdutoServico)),
              mov.informacoesGeraisPedido.salaoId,
            );

            const resultPostProdutos = await postPedidoProdutos(
              getEmpresaSelecionada()!.Id,
              mov.id,
              produtos,
            );

            if (resultPostProdutos.erro) {
              throw new Error(
                'Não foi possível enviar os produtos do pedido, tente novamente.',
              );
            }

            setSituacao(EnumSituacao.EnviandoReceitas);
          }

        } catch (e: any) {
          setError(e);
          return;
        }
        break;
      case EnumSituacao.EnviandoReceitas:
        try {
          const mov = getMov();

          if (mov?.mod === EnumMovModelo.PEDIDO) {
            if (await dadosIniciaisPendente(mov)) {
              showToast('info', 'nenhuma venda em andamento');
              await redirectLanding();
              return;
            }

            if (await dadosProdutosPendente(mov!)) {
              redirectCatalogo();
              return;
            }

            if (
              !mov?.clienteIdentificado &&
              getEmpresaConfig(EnumEmpresaConfig.SelecaoClienteVenda) === '2'
            ) {
              await redirectSelecionarCliente();
              return;
            }

            const receitas = mov?.receitasMedicas;

            if ((receitas?.length ?? 0) > 0) {
              const resultPostReceitas = await postPedidoReceita(
                getEmpresaSelecionada()!.Id,
                mov!.id,
                receitas!
              );

              if (resultPostReceitas.erro) {
                throw new Error(
                  'Não foi possível enviar as receitas para o pedido, tente novamente.'
                );
              }
            }

            setSituacao(EnumSituacao.EnviandoProdutos);
          }

          if (mov?.mod === EnumMovModelo.DELIVERY) {
            setSituacao(EnumSituacao.FinalizandoEnvioPedido);
          }

        } catch (e: any) {
          setError(e);
          return;
        }
        break;
      case EnumSituacao.EnviandoProdutos:
        try {
          const mov = getMov();

          if (await dadosIniciaisPendente(mov)) {
            showToast('info', 'nenhuma venda em andamento');
            await redirectLanding();
            return;
          }

          if (await dadosProdutosPendente(mov!)) {
            redirectCatalogo();
            return;
          }

          if (
            !mov?.clienteIdentificado &&
            getEmpresaConfig(EnumEmpresaConfig.SelecaoClienteVenda) === '2'
          ) {
            await redirectSelecionarCliente();
            return;
          }

          const products = mov?.produtos.filter(
            (x) =>
              x.produtoId !==
              getContratoConfig(EnumContratoConfig.ProdutoServico)
          );

          const produtos = await pedidoProdutosWrapper(
            products!,
            mov?.informacoesGeraisPedido.salaoId
          );

          await verificarConexao();

          const resultPostProdutos = await postPedidoProdutos(
            getEmpresaSelecionada()!.Id,
            mov!.id,
            produtos
          );

          if (resultPostProdutos.erro) {
            const resPedido = await getPedidoSalaoById(
              getEmpresaSelecionada()?.Id ?? '',
              mov!.id
            );

            if (
              resPedido.erro ||
              (resPedido.resultado?.data &&
                (resPedido.resultado.data.statusPedido.codigo !==
                  EnumStatusPedido.CONFIRMADO ||
                  resPedido.resultado.data.statusPedido.codigo !==
                  EnumStatusPedido.FECHADO))
            ) {
              await limparInformacoesPedidos();
              showToast(
                'info',
                'Identificamos uma inconsistência nos dados fornecidos. Por favor, realize a identificação do pedido novamente.'
              );
              await redirectCarrinho();
              return;
            }

            throw new Error(
              `Não foi possível enviar os produtos do pedido, tente novamente. ${resultPostProdutos.erro}`
            );
          }

          if (resultPostProdutos.isTimeout) {
            if (limiteReq <= 2) {
              limiteReq++;
              await iniciarVerificacaoDoPedido();
            } else {
              throw new Error(
                `Não foi possível identificar o pedido, tente novamente. ${resultPostProdutos.erro}`
              );
            }
            return;
          }

          setSituacao(EnumSituacao.FinalizandoEnvioPedido);
        } catch (e: any) {
          setError(e);
          return;
        }
        break;
      case EnumSituacao.FinalizandoEnvioPedido:
        delRegistro(GestaoStorageKeys.MovSimplesAtual, false);
        deletePedidoDelivery();
        showToast('success', 'Pedido enviado com sucesso!');
        localStorage.removeItem('pedido');
        await redirectLanding(false, '?pedidos=true');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situacao]);

  const checkPermissao = useCallback(async () => {
    if (
      !getPermissaoBoolean(EnumCodigosPermissoes.REALIZAR_PEDIDOS) &&
      (!localStorage.getItem('pedido') ||
        localStorage.getItem('pedido') === 'false')
    ) {
      abrirSolicitarPermissao(
        async () => {
          await iniciarVerificacaoDoPedido();
          localStorage.setItem('pedido', 'true');
        },
        EnumCodigosPermissoes.REALIZAR_PEDIDOS,
        'Realizar Venda',
        undefined,
        undefined,
        async () => {
          await redirectCarrinho();
        }
      );
      return;
    }

    await iniciarVerificacaoDoPedido();
  }, [
    abrirSolicitarPermissao,
    getPermissaoBoolean,
    iniciarVerificacaoDoPedido,
    redirectCarrinho
  ]);

  const handleVoltar = async () => {
    try {
      await redirectLancadorPadrao()
    } catch (err: any) {
      showToast('error', err.message)
    }
  }

  useEffect(() => {
    (async () => {
      await checkPermissao();
    })();
  }, [
    abrirSolicitarPermissao,
    checkPermissao,
    getPermissaoBoolean,
    historyPush,
    iniciarVerificacaoDoPedido
  ]);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Enviar Pedido',
    })
  }, [setHeaderProps])

  const mov = getMov();

  return (
    <>
      <Grid className={classes.root}>
        {carregando && (
          <Grid
            className={classes.containerLoading}
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              style={{ display: 'flex', justifyContent: 'center', padding: 32 }}
            >
              <LoadingFinalizacao />
            </Grid>
            <Typography variant="h5" color="primary">
              {situacao === EnumSituacao.ProcurandoPedido && mov?.mod === EnumMovModelo.PEDIDO && <>Procurando Pedido</>}
              {situacao === EnumSituacao.ProcurandoPedido && mov?.mod === EnumMovModelo.DELIVERY && <>Iniciando um novo Pedido</>}

              {situacao === EnumSituacao.IniciandoNovoPedido && mov?.mod === EnumMovModelo.PEDIDO && <>Iniciando um novo Pedido</>}
              {situacao === EnumSituacao.IniciandoNovoPedido && mov?.mod === EnumMovModelo.DELIVERY && <>Enviando Produtos</>}


              {situacao === EnumSituacao.EnviandoReceitas && mov?.mod === EnumMovModelo.PEDIDO && <>Enviando Receitas</>}
              {situacao === EnumSituacao.EnviandoProdutos && <>Enviando Produtos</>}
              {situacao === EnumSituacao.FinalizandoEnvioPedido && <>Finalizando Envio do Pedido</>}
            </Typography>
          </Grid>
        )}
        {!carregando && error && (
          <Grid
            container
            className={classes.containerError}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={8} md={6} className={classes.container}>
              <Grid className={classes.content} style={{ justifyContent: (error?.message ?? 0).length > 500 ? 'normal' : 'center' }}>
                <Grid className={classes.imageError}>
                  <CancelarIcon tipo="GERAL" fill="#D22" />
                </Grid>
                <Typography variant="h5" align="center">
                  {error.message}
                </Typography>
              </Grid>
              <Grid p={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  disabled={carregando}
                  className={classes.btnTentarNovamente}
                  onClick={checkPermissao}
                >
                  <AtualizarIcon tipo="BUTTON_PRIMARY" />
                  Tentar Novamente
                </Button>
                {finalizarComoVenda.current && (
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    fullWidth={true}
                    disabled={carregando}
                    className={classes.btnTentarNovamente}
                    onClick={() => {
                      transformarEmVenda();
                    }}
                  >
                    <VendaIcon tipo="BUTTON" />
                    Finalizar como Venda
                  </Button>
                )}
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  fullWidth={true}
                  disabled={carregando}
                  className={classes.btnTentarNovamente}
                  onClick={handleVoltar}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid >
    </>
  );
};

export default MovEnviarPedidoPage;
