import { useThemeQueries } from "views"
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { useCallback } from "react";

/*
 criei esse hook pra ter um controle a mais do modo desktop e quem sabe
 se um dia calhar tornar ele uma configuração ou algo personalizável, em vez de
 usar somente o isMobile
 */
export const useDesktopMode = () => {
    const { isMobile } = useThemeQueries();
    const { setRegistro, getRegistro } = useGestaoStorage();

    const setDesktopMode = useCallback((status: boolean) => {
        setRegistro(GestaoStorageKeys.DesktopMode, status, false);
    }, [setRegistro])

    const getDesktopMode = useCallback((): boolean => {
        const mode = getRegistro(GestaoStorageKeys.DesktopMode, false);
        if (mode === undefined) {
            return !isMobile;
        }
        return mode && !isMobile;
    }, [getRegistro, isMobile])

    return {
        setDesktopMode,
        getDesktopMode
    }
}