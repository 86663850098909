import { useCallback, useEffect, useState, useRef } from "react";
import { NovoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { isEqual } from "utils/is-equal";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { DefaultFormRefs } from "views/components/form/utils";
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { picker } from "utils/picker";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import classNames from "classnames";
import { FormImpressoraEdit } from "views/components/form/impressora/form-impressora-edit/form-impressora-edit";
import { ImpressoraModel } from "model/api/gestao/impressora/impressora-model";
import { useGetEquipamentoById, usePutEquipamento } from "data/api/gestao/equipamento";
import { usePDV } from 'services/app/hooks/pdv';
import { Button, Grid } from "views/design-system";

export const ImpressoraEdit = (props: { id: string }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const classes = useModalStyles();
  const { abrirCadastroEquipamentos, fecharCadastroEquipamentos } = useCadastros();
  const { getEquipamentoById, carregando: carregandoGet } = useGetEquipamentoById();
  const { putEquipamento, carregando: carregandoPut } = usePutEquipamento();
  const [formInSubmit, setFormInSubmit] = useState(false);
  const carregando = carregandoPut || carregandoGet || preenchendoTela || formInSubmit;
  const [impressoraFormState, setImpressoraForm] = useState<ImpressoraModel>(new ImpressoraModel());
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { invalidarConfiguracoes } = usePDV();


  const refEditForm = useRef<DefaultFormRefs<ImpressoraModel>>(null);
  const refImpressoraModel = useRef<ImpressoraModel>(new ImpressoraModel());

  const recarregarForm = useCallback((model: ImpressoraModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(impressoraFormState);
  }, [impressoraFormState, recarregarForm]);

  const getImpressoraByIdWrapper = useCallback(async () => {
    const res = await getEquipamentoById(getEmpresaSelecionada()?.Id ?? '', props.id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as ImpressoraModel;
    refImpressoraModel.current = ret;
    const ncm = picker<ImpressoraModel>(ret, new ImpressoraModel());

    return ncm;
  }, [getEquipamentoById, getEmpresaSelecionada, props.id]);

  useEffect(() => {
    (async () => {
      try {
        setPreenchendoTela(true);
        let ncm = await getImpressoraByIdWrapper();
        setImpressoraForm(ncm);
        setPreenchendoTela(false);
      } catch (e: any) {
        showToast("error", e.message);
        setPreenchendoTela(false);
      }
    })();
    return () => {
      //funcao de limpeza
      setImpressoraForm(new ImpressoraModel());
    };
  }, [showToast, getImpressoraByIdWrapper, setImpressoraForm]);

  const saveChangesImpressora = useCallback(
    async (model: ImpressoraModel) => {
      const equipamento = picker<ImpressoraModel>(model, refImpressoraModel.current);
      try {
        const ret = await putEquipamento(equipamento, getEmpresaSelecionada()?.Id ?? '', equipamento.id);

        if (ret.erro) {
          throw ret.erro;
        }
        showToast("success", "Impressora atualizado com sucesso!", );
        setImpressoraForm(model);
        invalidarConfiguracoes();
      } catch (e: any) {
        showToast("error", e.message);
        refEditForm.current?.resetForm();
      }
    },
    [putEquipamento, getEmpresaSelecionada, showToast, invalidarConfiguracoes]
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroEquipamentos("", "", true);
  }, [abrirCadastroEquipamentos]);

  const handleSubmit = useCallback(
    async (model: ImpressoraModel, beforeModel: ImpressoraModel) => {
      const impressoraEqual = isEqual(model, beforeModel);

      if (impressoraEqual) {
        showToast("info", "Nenhuma informação foi alterada");
        return
      }

      await saveChangesImpressora(model);

    },
    [saveChangesImpressora, showToast]
  );
  const onCloseClick = useCallback(() => {
    fecharCadastroEquipamentos();
  }, [fecharCadastroEquipamentos]);


  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Edição de Impressora"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined
          )}
        >
          <FormImpressoraEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            setCarregandoExterno={setFormInSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Nova Impressora
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
