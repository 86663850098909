import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTheme } from '@material-ui/core';
import { useStyles } from './abrir-caixa-page-styles';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { CircularLoading } from 'views/components';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { toDecimal } from 'utils/to-decimal';
import { Button, Grid, Typography } from 'views/design-system';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useAbrirCaixa } from './abrir-caixa-page-hook';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';

export const AbrirCaixa = () => {

  // STATES E REFS
  const valorInicial = useRef<string>('');
  const digitado = useRef<boolean>(false);
  const textoAtual = useRef<string>('');
  const valueRef = useRef<HTMLParagraphElement | null>(null);

  // AUX
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  // HOOKS
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { getSessaoAtivaOperador, handleAbrirCaixa, carregando } = useAbrirCaixa()

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      valorInicial.current = String(toDecimal(formattedText, 2));
      return true;
    },
    []
  );

  useEffect(() => {
    callEvent(AppEventEnum.AlterarMenuPDV, false);

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true);
    };
  }, [callEvent]);

  useEffect(() => {
    getSessaoAtivaOperador();
  }, [getSessaoAtivaOperador]);

  const submit = useCallback(async () => {
    handleAbrirCaixa(valorInicial.current);
    return true;
  }, [handleAbrirCaixa]);

  const {
    getFormattedText,
    addValue,
    sendText,
    addKey,
    submitKey,
    backSpaceKey
  } = useKeyboard({
    handleTextChanged: textChanged,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual,
    maxLength: 7,
    floatCases: 2,
    isNumeric: true
  });

  const attValueDisplay = useCallback(
    (any: any) => {
      const v = getFormattedText();
      if (valueRef.current) {
        if (v.length === 0) {
          valueRef.current.className = classes.placeholder;
          valueRef.current.textContent = 'Aguardando Digitação';
          return;
        }
        valueRef.current.className = classes.inputText;
        valueRef.current.textContent = v;
      }
    },
    [classes.inputText, classes.placeholder, getFormattedText]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay);

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay);
  }, [addHandler, attValueDisplay, removeHandler]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue]
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText]
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    submitKey();
  }, [submitKey]);

  const teclado = useMemo(() => {
    return (
      <>
        <Grid container style={{ flex: 1 }}>
          <Keyboard
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric
            isButtonBackspace
            isButtonKeyboard00
          />
        </Grid>
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  const buttonsAction = useMemo(() => {
    return (
      <Grid
        container
        flexDirection="column"
        flexWrap='nowrap'
        pb={1}
      >
        <Grid
          item
          xs={12}
          style={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
          }}
        >
          <Grid
            container
            xs={12}
            style={{
              marginTop: theme.spacing(1)
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => history.push(RetaguardaRotasMock.dashboardRota.path)}
                style={{ marginRight: theme.spacing(1) }}
                className="round"
              >
                <CancelarIcon tipo="BUTTON"></CancelarIcon>
                Voltar
              </Button>
            </Grid>

            <Grid container xs={6}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={submit}
                style={{ marginLeft: theme.spacing(1) }}
                className="round"
              >
                <OkIcon tipo="BUTTON_PRIMARY"></OkIcon>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [history, submit, theme]);

  const renderKeyboard = () => {
    return (
      <>
        <Grid className={classes.content}>
          <Grid className={classes.inputArea}>
            <Typography className={classes.placeholder} ref={valueRef}>
              (opcional)
            </Typography>
          </Grid>
        </Grid>
        {teclado}
        {buttonsAction}
      </>
    );
  };

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({ title: 'Abertura de Caixa' })
  }, [setHeaderProps])

  return (
    <>
      {carregando && (
        <CircularLoading tipo="FULLSIZED" />
      )}
      <Grid className={classes.root}>
        <Grid className={classes.container}>
          <Grid className={classes.containerTitle}>
            <Typography className={classes.title}>
              Informe o valor em caixa para iniciar
            </Typography>
          </Grid>
          {renderKeyboard()}
        </Grid>
      </Grid>
    </>
  );
};
