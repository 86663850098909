import { Paper } from "@material-ui/core"
import { CardDashboardChartHeatMapProps } from "../card-dashboard-chart-props"
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts'
import { useCallback } from "react";
import { CircularLoading } from "views/components/utils";
import { toDecimalString } from "utils/to-decimal";
import { Box, Divider, Grid, Typography } from "views/design-system";


export const CardDashboardChartHeatMap = ({ model, carregando }: CardDashboardChartHeatMapProps) => {
    const utilClasses = useStyles()

    const pegarDia = (item: String) => {
        let ano = item.slice(0, 4)
        let mes = item.slice(4, 6)
        let dia = item.slice(6, 8)
        return `${ano}-${mes}-${Number(dia) + 1}`
    }

    interface ArrayHoraProps {
        x: string;
        y: number;
        valorVenda: number
    }

    const getSeries = useCallback(() => {

        let dia = []

        if (!model) {
            return;
        }

        let horas = new Array<number>();

        for (let i = 0; i <= 23; i++) {
            horas[i] = 0;
        }

        let horaArray = Array.from(new Set<number>(
            model.map(item => item.hora)
        ))

        horaArray.forEach((item) => {
            horas[item]++;
        });

        let horasIniciais: number = 23;
        let horasFinais: number = 0;
        model.forEach(item => {
            if (item.hora < horasIniciais) {
                horasIniciais = item.hora
            }
            if (item.hora > horasFinais)
                horasFinais = item.hora;
        });

        let horarioLimite = 0

        for (let i = horasIniciais; i < horasFinais; i++) {
            horarioLimite++
        }

        for (let i = 0; i < 7; i++) {

            const arrayHora = new Array<ArrayHoraProps>();

            for (
                let hora = (horarioLimite > 10 ? 0 : horasIniciais);
                hora <= (horarioLimite > 10 ? horas.length : horasFinais);
                hora++
            ) {
                let registros = model.filter(item => item.hora === hora && new Date(pegarDia(String(item.data))).getDay() === i)

                let valorHora: number = 0;
                let quantidadeVendasHora: number = 0
                registros.forEach(item => {
                    quantidadeVendasHora += item.qVendas
                    valorHora += item.vVenda
                });
                
                
                if (horarioLimite > 10) {
                    if (horas[hora] > 0) {
                        arrayHora.push({
                            x: `${hora}h`,
                            y: quantidadeVendasHora,
                            valorVenda: valorHora,
                        });
                    }
                } else {
                    arrayHora.push({
                        x: `${hora}h`,
                        y: quantidadeVendasHora,
                        valorVenda: valorHora,
                    });
                }
            }

            dia[i] = arrayHora;
        }

        const serie: any[] = [
            {
                name: "Dom",
                data: dia[0],

            },
            {
                name: "Seg",
                data: dia[1]
            },
            {
                name: "Ter",
                data: dia[2]
            },
            {
                name: "Qua",
                data: dia[3]
            },
            {
                name: "Qui",
                data: dia[4]
            },
            {
                name: "Sex",
                data: dia[5]
            },
            {
                name: "Sáb",
                data: dia[6]
            },
        ]
        return serie.reverse();
    }, [model])
 

    const options = {
        plotOptions: {
            bar: {
                horizontal: true,
            },
            heatmap: {
                radius: 5,
                width:  15,
            },
        },
        tooltip: {
            style: {
                fontSize: '24px'
            },
            y: {
                formatter: (val: any, { w, dataPointIndex, seriesIndex }: any) => {
                    const data = toDecimalString(w.globals.initialSeries[seriesIndex].data[dataPointIndex].valorVenda, 2)
                    return (
                        `${val}${val > 1 ? ' vendas' : ' venda'}  <br>  
                        R$ ${data}`)
                }
            },
            x: {
                formatter: (val: any) => `${val}`
            },
            valorVenda: {
                formatter: (val: any) => ` R$: ${val}`
            }
        },
        dataLabels: {
            enabled: true,
            fontSize: 11,
            style: {
                colors: ['#666']
            },

        },
        colors: ["#1F9CE4"],
    }


    return (
        <>
            <Paper className={utilClasses.cardContainer}>
                <Grid container >
                    <Grid item xs={12} p={1}>
                        <Typography color='primary' weight={500}>
                            Volume semanal de vendas
                        </Typography>
                        <Divider variant="fullWidth"/>
                    </Grid>
                   
                    <Grid item xs={12} >
                        {carregando ?
                            <CircularLoading tipo="FULLSIZED" />
                            :
                            <Box m={2}>
                                {model.length === 0 ?
                                    <Typography variant="body1" align="center">Não há dados para exibir</Typography>
                                    :
                                    <Chart options={options} series={getSeries()} type="heatmap" className={utilClasses.chart} />
                                }
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
