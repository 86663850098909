import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { GestaoStorageKeys, useCadastros, useChatSuporteSaurus, useGestaoStorage, useGestaoToken, useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography, Divider } from 'views/design-system';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useSessaoAtual } from 'services/app';

import { useThemeQueries } from '../../../../../theme/util-styles';

import classNames from 'classnames';
import {
  BoletoIcon,
  CancelarIcon,
  EditarIcon,
  MedalhaFillIcon,
} from 'views/components/icons';

import { useStyles } from './accordion-dados-plano-styles';
import { CardInfoPlano } from './components/card-info-plano/card-info-plano';
import { PropOpcoes } from 'model/api/gestao/plano/plano-model';
import { useHistory } from 'react-router';
import { PlanoCorMock } from 'data/mocks/plano-cor-mock';
import { CotaModel } from 'model/api/gestao/cota/cota-model';
import { guidEmpty } from 'utils/guid-empty';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { stringNumeros } from 'utils/string-numeros';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { EnumTipoCobranca } from 'model/enums/enum-tipo-combranca';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useGetCotasAnoMes } from 'data/api/gestao/cota/get-cota-ano-mes';
import { dateParaCompetencia } from 'utils/competencia';

export const AccordionDadosPlano = () => {
  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(false);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<CotaModel>(),
  });

  // PROVIDERS
  const { plano, getPermissaoBoolean } = useSessaoAtual();
  const { abrirDialogQrCode } = useCadastros();
  const { abrirChat } = useChatSuporteSaurus()
  const { getEmpresaAtual } = useEmpresaAtual()

  // CHAMADAS API
  const { getCotasAnoMes, carregando: carregandoCotas } = useGetCotasAnoMes();

  // AUX
  const carregando = carregandoCotas;
  const { showToast } = useToastSaurus();
  const classes = useStyles();
  const history = useHistory();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();
  const { getRegistro } = useGestaoStorage();
  const { convertToken } = useGestaoToken();

  const handleChangePlan = useCallback(() => {
    history.push('/alterar-plano');
  }, [history]);

  const getToken = getRegistro(GestaoStorageKeys.Token, false)

  const tokenConvertido = convertToken(getToken)

  const planoAvulso = plano?.plano.nome.toLowerCase() === 'avulso'

  const handleChangeInvoices = useCallback(() => {
    const link = `https://licenciamento.touchone.com.br/cobranca?codCliente=${plano?.contratado.id
      }&doc=${stringNumeros(tokenConvertido?.empresa[0].Documento || '')}`;

    if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA) {
      abrirDialogQrCode(link);
      return;
    }
    window.open(link, '_blank');
  }, [abrirDialogQrCode, plano?.contratado.id, tokenConvertido?.empresa]);

  const anoMes = dateParaCompetencia(new Date());

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<CotaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const compareLimitData = (
    listLimitPlan: CotaModel[],
    planoAtuaOpcoes: PropOpcoes[],
  ) => {
    const ar = planoAtuaOpcoes.map((opcao) => {
      const cota = listLimitPlan.find((cota) => cota.codigo === opcao.codigo);

      if (cota) {
        return {
          id: cota.id,
          codigo: opcao.codigo,
          quant: cota.quant,
          nome: opcao.nome,
          valor: opcao.valor,
        };
      }

      return {
        id: guidEmpty(),
        codigo: opcao.codigo,
        quant: 0,
        nome: opcao.nome,
        valor: opcao.valor,
      };
    });
    //removendo o que for null do array
    return ar.filter(function (el) {
      return el != null;
    });
  };

  const search = useCallback(
    async (newPage: number) => {
      const query = 'anoMes=' + anoMes;
      try {
        const res = await getCotasAnoMes(query);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [anoMes, getCotasAnoMes, fillResult, showToast],
  );

  useEffect(() => {
    setTimeout(() => {
      search(1);
    }, 200);
  }, [search]);

  interface propsColor {
    id: string;
    cor: string;
  }

  const filterColor = useCallback(
    (item: propsColor) => {
      if (item.id === plano?.plano?.id) return item.cor;
    },
    [plano?.plano?.id],
  );

  const medalhaIcon = useMemo(() => {
    return (
      <MedalhaFillIcon
        tipo="BUTTON"
        fill={PlanoCorMock.find(filterColor)?.cor || theme.palette.text.primary}
      />
    );
  }, [filterColor, theme.palette.text.primary]);

  const content = useMemo(() => {
    return (
      <div
        className={classNames(classesModal.contentForms)}
        style={{ height: '100%' }}
      >
        <Grid container spacing={4} className={classes.containerLimitPlan}>
          <Typography variant="subtitle1" className={classes.subTitle}>
            Recursos Contratados
          </Typography>
          <Divider className={classes.divider} />
          <Grid className={classes.containerInfo}>
            <Typography variant="body2">
              Verifique abaixo os recursos contratados e alguns casos, o quanto
              já foi consumido.
            </Typography>
          </Grid>
          <Grid className={classes.containerCardItemsLimit}>
            {plano &&
              queryStatus.list &&
              queryStatus.list.length > 0 &&
              compareLimitData(queryStatus.list, plano?.plano.opcoes).map(
                (item, index) =>
                  item!.codigo === EnumPlanoOpcoes.DiasExpiracao &&
                    plano.plano.valor !== 0 ? (
                    <></>
                  ) : (
                    <CardInfoPlano key={item?.id + '_' + index} data={item!} />
                  ),
              )}
            {!plano ||
              (queryStatus.list.length === 0 &&
                plano?.plano.opcoes.length > 0 &&
                plano?.plano.opcoes.map((item, index) => (
                  <CardInfoPlano
                    key={item.id + '_' + index}
                    data={{
                      id: '',
                      codigo: 0,
                      nome: item.nome,
                      quant: 0,
                      valor: item.valor,
                    }}
                  />
                )))}
          </Grid>
        </Grid>
      </div>
    );
  }, [
    classes.containerCardItemsLimit,
    classes.containerInfo,
    classes.containerLimitPlan,
    classes.divider,
    classes.subTitle,
    classesModal.contentForms,
    plano,
    queryStatus.list,
  ]);




  const buttons = useMemo(() => {
    return (
      <Grid>
        <Grid
          spacing={2}
          container
          style={{
            marginTop: theme.spacing(4),
            marginBottom: 0,
          }}
        >
          <Grid item xs={12} sm={5} md={5} lg={4}>
            <Button
              disabled={carregando}
              onClick={() => handleChangePlan()}
              variant="outlined"
              fullWidth
              color="primary"
            >
              <EditarIcon tipo="BUTTON" />
              Mudar meu Plano
            </Button>
          </Grid>
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && getPermissaoBoolean(EnumCodigosPermissoes.FATURAS) &&
            tokenConvertido?.licenca.RepresentanteTipoCobranca === EnumTipoCobranca.ClienteFinal && (
              <Grid item xs={12} sm={7} md={6} lg={4}>
                <Button
                  disabled={carregando}
                  onClick={() => handleChangeInvoices()}
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  <BoletoIcon tipo="BUTTON_PRIMARY" />
                  Visualizar minhas Faturas
                </Button>
              </Grid>
            )}
          <Grid item xs={12} sm={5} md={5} lg={4}>
            <Button
              disabled={carregando}
              onClick={() => abrirChat(tokenConvertido!.email, formatarCPFCNPJ(getEmpresaAtual()!.cpfcnpj),
                'Ola, gostaria de solicitar o cancelamento do meu contrato.')}
              variant="outlined"
              fullWidth
              className={classes.buttonCancelar}
            >
              <CancelarIcon tipo="BUTTON" />
              Cancelar Contrato
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [abrirChat, carregando, classes.buttonCancelar, getEmpresaAtual, getPermissaoBoolean, handleChangeInvoices, handleChangePlan, theme, tokenConvertido]);

  return (
    <AccordionSaurus
      labelPrimary={`Plano atual: ${plano?.plano?.nome || '-'}`}
      tituloChildren={planoAvulso ? null : medalhaIcon}
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : (
            content
          )}
          {buttons}
        </div>
      </div>
    </AccordionSaurus>
  );
};
