import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostPessoasByIds() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postPessoasByIds = useCallback(
    (ids: string[]) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/buscar-ids`,
        method: 'POST',
        data: JSON.stringify(ids),
        enviarTokenUsuario: true,
        headers: {
          'content-type': 'application/json'
        }
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postPessoasByIds
  };
}
