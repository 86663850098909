import { Grid } from 'views/design-system';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { memo } from 'react';
import { CardNaoEncontrado } from 'views/components/cards';
import CardFinalizadoraMov from '../../../../../../components/cards/card-finalizadora-mov/card-finalizadora-mov';
import { useStyles } from './novo-pagamento-list-styles';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components';

export interface NovoPagamentoGridProps {
  list: Array<FinalizadoraModel> | null;
  carregando: boolean;
  selectedList: Array<string>;
  onCardClicked: (model: FinalizadoraModel) => any;
  onCardChecked: (model: FinalizadoraModel) => any;
  paymentScreen?: boolean;
  entrada?: boolean
  isPagFatura?: boolean
}

export const NovoPagamentoListData = ({
  carregando: loading,
  list,
  selectedList,
  onCardChecked: onCheck,
  onCardClicked: onClick,
  paymentScreen = false,
  ...props
}: NovoPagamentoGridProps) => {
  const classes = useStyles({ paymentScreen });
  const { showToast } = useToastSaurus()

  const onCardClicked = (model: FinalizadoraModel) => {
    try {
      onClick(model);
    } catch (error: any) {
      showToast('error', error.message)
    }
  };

  const onCardChecked = (model: FinalizadoraModel) => {
    onCheck(model);
  };

  const payments = (list ?? [])
    .filter((item) => {
      if (!item.ativo) return false
      return item;
    })
    .sort((a, b) => a.ordem - b.ordem);

  const divider = Math.ceil(payments.length / 2);
  const lineOne = payments.length <= 8 ? payments : payments.slice(0, divider);
  const lineTwo = payments.length <= 8 ? [] : payments.slice(divider);


  const fillBreakpoint = (value: number) => {
    let resultado;
    switch (value) {
      case 1:
        resultado = 12;
        break;
      case 2:
        resultado = 6;
        break;
      case 3:
        resultado = 4;
        break;
      default:
        resultado = 3;
    }
    return resultado as any
  }

  const exibirLoading = list === null || loading;
  const exibirSemRegistro = payments.length === 0;
  return (
    <>
      <Grid item xs={12} className={classes.container}>
        <Grid item xs={12}>
          {exibirLoading ? (
            <Grid flex className={classes.containerLoading} alignItems='center' justifyContent='center'>
              <CircularLoading tipo="NORMAL" />
            </Grid>
          ) : exibirSemRegistro ? (
            <Grid pr={1} pb={1}>
              <CardNaoEncontrado
                mensagem="Nenhuma forma de pagamento encontrada."
                icon={<></>}
              />
            </Grid>
          ) : <>
            <Grid className={classes.row}>
              {lineOne.map((finalizadora, index, arr) => {
                return (
                  <Grid xs={fillBreakpoint(arr.length)}>
                    <CardFinalizadoraMov
                      selected={
                        selectedList.filter((item) => item === finalizadora.id)
                          .length > 0
                          ? true
                          : false
                      }
                      onCheck={onCardChecked}
                      onClick={onCardClicked}
                      model={finalizadora}
                      key={index}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {payments.length >= 8 && (
              <Grid className={classes.row}>
                {lineTwo.map((finalizadora, index) => {
                  return (
                    <CardFinalizadoraMov
                      selected={
                        selectedList.filter((item) => item === finalizadora.id)
                          .length > 0
                          ? true
                          : false
                      }
                      onCheck={onCardChecked}
                      onClick={onCardClicked}
                      model={finalizadora}
                      key={index}
                    />
                  );
                })}
              </Grid>
            )}</>
          }
        </Grid>

      </Grid>
    </>
  );
};

export default memo(NovoPagamentoListData);
