import { forwardRef, useImperativeHandle } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../../../views/components/controles/inputs';
import { makeUtilClasses } from '../../../../../views/theme';
import { CircularLoading } from '../../../../../views/components/utils/circular-loading/circular-loading';
import { PerfilCadastroFormModel } from '../../../../../model/app/forms/perfil/perfil-cadastro-form-model';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../../../../views/components/form/utils/form-default-props';
import { useFormPerfilValidation } from './form-perfil-cadastro-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const FormPerfilCadastro = forwardRef<
  DefaultFormRefs<PerfilCadastroFormModel>,
  DefaultFormProps<PerfilCadastroFormModel>
>((props: DefaultFormProps<PerfilCadastroFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormPerfilValidationYup } = useFormPerfilValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<PerfilCadastroFormModel>({
    resolver: yupResolver(FormPerfilValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: PerfilCadastroFormModel) => {
    const modelP = new PerfilCadastroFormModel();
    modelP.descricao = values.descricao;
    modelP.nome = values.nome;
    props.onSubmit(modelP);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: () => { },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box id='tour-perfilDeAcessoDados' my={2}>
            <Grid item xs={12}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    autoComplete='new-password'
                    variant="outlined"
                    label="Nome do Perfil"
                    allowSubmit
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Ex: Vendedor"
                    error={Boolean(errors.nome && errors.nome.message)}
                    helperText={
                      touchedFields.nome || errors.nome
                        ? errors.nome?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    autoComplete='new-password'
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Descrição do Perfil (Informativo)"
                    allowSubmit
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Ex: Perfil para vendedores de Rua"
                    error={Boolean(
                      errors.descricao && errors.descricao.message,
                    )}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
