import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useCadastros, useToastSaurus } from 'services/app';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { AvancarIcon, VoltarIcon } from 'views/components/icons';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { ModalHeader } from 'views/components/modals/components';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useStyles } from './selecionar-rastros-styles';
import { SelecionarRastrosProps } from './selecionar-rastros-props';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  LoteModel,
  RastroLoteModel
} from 'model/api/gestao/rastros/rastro-lote-model';
import { useGetRastroLoteCodigoAnvisa } from 'data/api/gestao/rastros/get-rastro-lote-codigo-anvisa';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { SelecionarRastrosListData } from './selecionar-rastros-list-data/selecionar-rastros-list-data';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useConfirm } from 'material-ui-confirm';
import { CircularLoading } from 'views/components/utils';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useThemeQueries } from 'views/theme';
import { EnumMovModelo } from 'model';

export const SelecionarRastros = ({
  medicamentos,
  fluxoFinalizado,
  receitaMed
}: SelecionarRastrosProps) => {
  // HOOKS & UTILS
  const modalClasses = useModalStyles();
  const { getRastroLoteCodigoAnvisa, carregando: carregandoRastro } =
    useGetRastroLoteCodigoAnvisa();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { showToast } = useToastSaurus();
  const { theme } = useThemeQueries();
  const { fecharRastroMedicamentoModal } = useCadastros();
  const { adicionarReceitaNaVenda, getMov } = useMovAtual();
  const carregando = [carregandoRastro].includes(true);
  const confirm = useConfirm();

  const [query, setQuery] = useState({
    totalIndex: medicamentos.length - 1,
    index: 0,
    medicamentos: medicamentos
  });

  //STATES & VALIDATES
  const [rastro, setRastro] = useState<RastroLoteModel>(new RastroLoteModel());
  const medicamentoAtual = query.medicamentos[query.index];
  const qComMedicamentos = query.medicamentos.reduce((a, b) => a + b.qCom, 0);
  const qtdRastros = query.medicamentos
    .map((m) => m.rastro ?? [])
    .reduce((acc, current) => acc.concat(current ?? []), [])
    .reduce((acc, current) => acc + (current?.qLote ?? 0), 0);
  const quantidadeRastroSelecionados = (medicamentoAtual.rastro ?? []).reduce(
    (a, b) => a + (b.qLote ?? 0),
    0
  );
  const todosRastrosMedicamentosSelecionados = qComMedicamentos === qtdRastros;
  const todosSelecionados =
    medicamentoAtual.qCom - quantidadeRastroSelecionados <= 0;

  const classes = useStyles({
    todosSelecionados,
    todosRastrosMedicamentosSelecionados
  });

  const handleClose = () => {
    fecharRastroMedicamentoModal();
  };

  const searchRastros = useCallback(async () => {
    try {
      const res = await getRastroLoteCodigoAnvisa(
        getEmpresaAtual()?.id ?? '',
        medicamentoAtual.cProdANVISA ?? ''
      );
      
      if (res.erro) throw res.erro;

      const resultado = res.resultado?.data as RastroLoteModel;

      setRastro(resultado);
    } catch (error: any) {
      showToast('error', error.message);
    }
  }, [
    getEmpresaAtual,
    getRastroLoteCodigoAnvisa,
    medicamentoAtual.cProdANVISA,
    showToast
  ]);

  useEffect(() => {
    searchRastros();
  }, [searchRastros]);

  const alterarQuantidadeRastro = useCallback(
    (model: LoteModel, qtd: number) => {
      const medAtual = { ...medicamentoAtual } as MovSimplesProdutoModel;

      const encontrarRastro = (medAtual.rastro ?? []).filter(
        (lote) => lote.nLote === model.lote
      );
      if (medAtual.rastro === null) {
        medAtual.rastro = [];
      }
      if (encontrarRastro.length === 0) {
        medAtual.rastro.push({
          cAgreg: model.codigoAgregador,
          dFab: model.dataFabricacao,
          dVal: model.dataValidade,
          nLote: model.lote,
          qLote: qtd
        });
      } else {
        if (qtd <= 0) {
          medAtual.rastro = (medAtual.rastro ?? []).filter(
            (x) => x.nLote !== model.lote
          );
        } else {
          const rastro = medAtual.rastro.find((x) => x.nLote === model.lote);
          const index = medAtual.rastro.indexOf(rastro!);
          medAtual.rastro[index] = { ...rastro!, qLote: qtd };
        }
      }

      const medicamentos = query.medicamentos.map((x) => {
        if (x.id === medAtual.id) {
          return medAtual;
        }

        return x;
      });

      setQuery((prev) => ({
        ...prev,
        medicamentos: medicamentos
      }));
    },
    [medicamentoAtual, query.medicamentos]
  );

  const proximoProduto = async () => {
    if (!todosSelecionados && quantidadeRastroSelecionados > 0) {
      showToast(
        'error',
        'Após ter selecionado um dos lotes deste produto, terá que informar de todos eles.'
      );
      return;
    }

    setQuery((prev) => ({
      ...prev,
      index: prev.index + 1
    }));
    await searchRastros();
  };

  const anteriorProduto = async () => {
    if (!todosSelecionados && quantidadeRastroSelecionados > 0) {
      showToast(
        'error',
        'Após ter selecionado um dos lotes deste produto, terá que informar de todos eles.'
      );
      return;
    }

    setQuery((prev) => ({
      ...prev,
      index: prev.index - 1
    }));
    await searchRastros();
  };

  const confirmarLotes = async () => {
    if (!todosSelecionados && quantidadeRastroSelecionados > 0) {
      showToast(
        'error',
        'Após ter selecionado um dos lotes deste produto, terá que informar de todos eles.'
      );
      return;
    }
    if (!todosRastrosMedicamentosSelecionados) {
      confirm({
        title: 'Deseja continuar?',
        description:
          'Você não selecionou todos os lotes da venda, deseja continuar mesmo assim?',
        cancellationText: 'Voltar',
        confirmationText: 'Confirmar'
      }).then(async () => {
        try {
          await adicionarReceitaNaVenda(receitaMed, query.medicamentos);
          fecharRastroMedicamentoModal();
          await fluxoFinalizado();
        } catch (e: any) {
          showToast('error', e.message);
        }
      });
      return;
    }
    try {
      await adicionarReceitaNaVenda(receitaMed, query.medicamentos);
      fecharRastroMedicamentoModal();
      await fluxoFinalizado();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  return (
    <div className={modalClasses.root}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <ModalHeader
        title={'Selecionar Lotes'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={handleClose}
          />
        }
      />
      <div className={modalClasses.content}>
        <div className={modalClasses.contentForms}>
          <Box className={classes.contentInfoMed}>
            <Box width="100%" height="100%" display="flex">
              <Box width="100%" p={0.5} px={2}>
                <Typography className={classes.codEan}>
                  Cód: {medicamentoAtual.cEan || medicamentoAtual.cProdANVISA}
                </Typography>
                <Typography className={classes.titleMedicamento}>
                  {medicamentoAtual.xProd}
                </Typography>
              </Box>
              <Box className={classes.titleQtd}>
                <Box className={classes.titleQtdContent}>
                  <Typography>Quantidade Total</Typography>
                  <Typography variant="h4" className={classes.titleQtdNumber}>
                    {medicamentoAtual.qCom}
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classNames(
                  classes.titleQtd,
                  todosSelecionados ? classes.titleQtdRestanteTodos : undefined,
                  classes.titleQtdRestante
                )}
              >
                <Box className={classes.titleQtdContent}>
                  <Typography>Quantidade Restante</Typography>
                  <Typography
                    variant="h4"
                    className={classNames(classes.titleQtdNumber)}
                  >
                    {medicamentoAtual.qCom - quantidadeRastroSelecionados}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Box width="100%" my={2}>
                <Typography variant="h6" color="primary">
                  Lotes e Validade
                </Typography>
                <Divider
                  className={classes.divider}
                  color={theme.palette.secondary.main}
                />
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <SelecionarRastrosListData
                list={(rastro?.lotes ?? []).filter(lotes => lotes.lote && lotes.dataValidade && (getMov()?.mod !== EnumMovModelo.NFE ? true : lotes.dataFabricacao) && lotes.quantidade)}
                onCardClick={alterarQuantidadeRastro}
                disabled={todosSelecionados}
                produtoMed={medicamentoAtual}
              />
            </Grid>
          </Grid>
        </div>
        <div className={modalClasses.acoes}>
          <Grid container spacing={2}>
            {query.index > 0 && (
              <Grid item xs={query.index === query.totalIndex ? 12 : 6}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={anteriorProduto}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Anterior
                </Button>
              </Grid>
            )}
            {query.index < query.totalIndex && (
              <Grid item xs={query.index === 0 ? 12 : 6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={proximoProduto}
                >
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Próximo
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                classes={{
                  containedPrimary: classNames(
                    classes.btnConfirmar,
                    !todosRastrosMedicamentosSelecionados
                      ? classes.btnConfirmarTodosMedicamentos
                      : undefined
                  )
                }}
                onClick={confirmarLotes}
              >
                <ConfirmarIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
