import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './sessao-sincronizar-styles';
import { SessaoSincronizarHeader } from './components/sessao-sincronizar-header';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useThemeQueries } from 'views/theme';
import { useToastSaurus } from 'services/app';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CancelarIcon, VoltarIcon } from 'views/components/icons';
import { usePostSincronizarValores } from 'data/api/gestao/pontos-venda/post-sincronizar-valores';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import {
  SessaoSincronizar,
  SessaoValorModel,
} from 'model/api/gestao/sessao/sessao-valor-model';
import { RetornarIcon } from 'views/components/icons/retornar-icon';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { EnumSincronizacaoGeral } from 'model/enums/enum-sincronizacao-geral';
import { useSessaoAtual } from 'services/app';

interface LocationProps {
  itens: SessaoValorModel[][];
}

const SincronizarSessaoPage = () => {
  // STATES E REFS
  const [isSinc, setIsSinc] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(0);
  const [erro, setErro] = useState<boolean>(false);

  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const {
    getSessao,
    verificarItensParaSincronizacao,
    converterDados,
    verificarItensParaFechamento,
    atualizarValores,
  } = useSessaoPDV();
  const { callEvent } = useEventTools();
  const { setStatusSincronizacaoAtual } = useSincronizacaoGeral();

  // CHAMADAS API
  const { postSincronizarValores } = usePostSincronizarValores();

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const history = useHistory();
  const search = useLocation().search;
  const redirect = new URLSearchParams(search).get('redirect');
  const { state } = useLocation<LocationProps>();
  const { itens } = state;

  const { showToast } = useToastSaurus();

  const sincronizarValores = useCallback(
    async (caixaId: string, valores: SessaoSincronizar[]) => {
      const respostaPost = await postSincronizarValores(
        getEmpresaSelecionada()!.Id,
        caixaId,
        valores,
      );

      if (respostaPost.erro) {
        throw respostaPost.erro;
      }
    },
    [getEmpresaSelecionada, postSincronizarValores],
  );

  const sincronizar = useCallback(async () => {
    try {
      const sessao = await getSessao();

      if (!sessao) {
        throw new Error('Não foi possível identificar a sessao.');
      }

      if (number < itens.length) {
        const valoresConvertidos = converterDados(itens[number]);
        await sincronizarValores(sessao.caixaId, valoresConvertidos);
        await atualizarValores(itens[number]);
      }
      if (number >= itens.length - 1) {
        if (redirect && redirect === 'fechamento') {
          const valoresNaoSincronizados = await verificarItensParaFechamento();

          if (!valoresNaoSincronizados) {
            const itens = await verificarItensParaSincronizacao();
            if (!itens || itens.length === 0) {
              return history.push('/venda-simples/gerenciar-sessao');
            }
          }

          return history.push({
            pathname: '/venda-simples/gerenciar-sessao-sincronizar-fechamento',
            state: { itens: valoresNaoSincronizados },
          });
        }

        setStatusSincronizacaoAtual({
          ativo: false,
          sincronizandoAtual: 0,
          status: EnumSincronizacaoGeralStatus.Finalizado,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0,
        });
        history.push(isMobile ? '/venda-simples/gerenciar-sessao-leitura-caixa' : '/venda-simples/gerenciar-sessao')
        return;
      }

      setNumber((prev) => prev + 1);
    } catch (e: any) {
      setErro(true);
      showToast('error', e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, number, itens]);

  useEffect(() => {
    if (isSinc) {
      (async () => {
        await sincronizar();
      })();
      return;
    }

    if (itens.length === 0) {
      history.push({
        pathname: isMobile ? '/venda-simples/gerenciar-sessao-leitura-caixa' : '/venda-simples/gerenciar-sessao'
      });
    }

    (async () => {
      const valoresNaoSincronizados = await verificarItensParaSincronizacao();

      if (!valoresNaoSincronizados || valoresNaoSincronizados.length === 0) {
        history.push(isMobile ? '/venda-simples/gerenciar-sessao-leitura-caixa' : '/venda-simples/gerenciar-sessao');
        return;
      }

      setIsSinc(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isSinc, sincronizar]);

  const fecharESincronizarMaisTarde = useCallback(async () => {
    const sessao = await getSessao();

    if (sessao?.aberta) {
      await TouchoneDBPrimary.sessao.update(sessao.idIndexed!, {
        aberta: false,
      });
    }

    callEvent(AppEventEnum.SessaoPDV, 1);
    return history.push('/');
  }, [callEvent, getSessao, history]);

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.header}>
          <SessaoSincronizarHeader />
        </Grid>
        <Grid className={classes.content}>
          <Grid
            className={
              isMobile ? classes.infoContainer : classes.infoContainerPC
            }
          >
            <Typography className={classes.textTitle}>
              {!erro ? 'Sincronizando' : 'Ocorreu um erro'}
            </Typography>
            {!erro ? (
              <Typography
                className={classes.infoTotal}
                style={{ textAlign: 'center' }}
              >
                {number + 1}/{itens.length ?? 'Carregando...'}
              </Typography>
            ) : (
              <></>
            )}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 32,
              }}
            >
              {!erro ? (
                <LoadingFinalizacao />
              ) : (
                <Grid className={classes.imageError}>
                  <CancelarIcon tipo="GERAL" fill="#D22" />
                </Grid>
              )}
            </Grid>
            <Grid
              className={!isMobile ? classes.infoCardContent : classes.cardInfo}
            >
              <Grid
                container
                justifyContent="center"
                style={{ flexDirection: 'column', display: 'flex' }}
              >
                <Grid
                  item
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {!erro ? (
                    <Typography className={classes.infoTotal}>
                      Conjunto nº {number + 1}
                    </Typography>
                  ) : (
                    <Typography className={classes.infoNomeEQuantidade}>
                      Ocorreu um erro ao sincronizar os dados
                    </Typography>
                  )}
                </Grid>
                {erro && (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={async () => {
                        setErro(false);
                        await sincronizar();
                      }}
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      <RetornarIcon tipo="BUTTON_PRIMARY" />
                      Tentar Novamente
                    </Button>

                    <Button
                      variant="contained"
                      onClick={
                        redirect && redirect === 'fechamento'
                          ? () => fecharESincronizarMaisTarde()
                          : () => {
                            history.push('/venda-simples/gerenciar-sessao');
                          }
                      }
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      <VoltarIcon tipo="BUTTON_PRIMARY" />
                      {redirect && redirect === 'fechamento'
                        ? 'Sincronizar mais tarde'
                        : 'tente mais tarde'}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SincronizarSessaoPage;
