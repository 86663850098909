import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    '& form': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  containerDesktop: {
    padding: theme.spacing(0, 2),
    paddingRight: 0,
    maxWidth: 400,
    float: 'right',
    width: '100%'
  },

  textfield: {
    '& .Mui-focused': {
      background: 'rgba(20,20,20, 0.1)'
    },
    borderRadius: theme.shape.borderRadius,
    '& svg': {
      fill: theme.palette.grey[700]
    },
    '& input': {
      fontWeight: 400,
      zIndex: 1,
      border: 'none !important',
    },
    '& input::placeholder': {
      color: theme.palette.grey[400]
    },
    color: theme.palette.grey[700],
  },
  textFieldMobile: {
    '& .Mui-endAdornment': {
      zIndex: 1,
    },
    '& fieldset': {
      zIndex: 0,
      background: 'rgba(255,255,255, 0.1)',
      border: '1px solid rgba(255,255,255, 0.2)',
    },
    '& .Mui-focused > fieldset': {
      background: 'rgba(255,255,255, 0.2) !important',
      border: '1px solid rgba(255,255,255, 0.3) !important',
    },
    '& svg': {
      fill: theme.palette.common.white
    },
    '& input': {
      fontWeight: 400,
      zIndex: 1,
      border: 'none !important',
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
      },
    },
    '& input::placeholder': {
      color: theme.palette.common.white
    },
    color: theme.palette.common.white
  },
  containerAcoes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& div': {
      height: theme.spacing(3),
      margin: 0,
      cursor: 'pointer',
    },
    '& svg': {
      fill: theme.palette.primary.contrastText,
      widht: theme.spacing(3),
      height: theme.spacing(3),
      margin: 0,
      marginLeft: theme.spacing(2),
    }
  },
  containerTitulo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& div': {
      cursor: 'text',
    },
    '& svg': {
      fill: theme.palette.primary.contrastText,
      opacity: 0.5,
      widht: 20,
      height: 20,
      marginBottom: -4,
      margin: 0,
      cursor: 'pointer',
      marginLeft: theme.spacing(1),
    }
  },
  title: {
    fontWeight: 400,
    textShadow: "2px 2px 1px rgba(0,0,0,0.05)",
    fontSize: "1.5rem",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      fontWeight: 400,
    },
  },
}));
