import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { DefaultModal } from "../components"
import { ProdutoCodigoModel } from "model/api/gestao/produto/produto-codigo/produto-codigo-model";
import { AdicionarProdutoDetalhado } from "./components/adicionar-produto-detalhado";

export interface ModalAdicionarProdutosProps {
    aberto: boolean;
    produto: MovSimplesProdutoModel;
    codigo?: ProdutoCodigoModel | null
}

export const ModalAdicionarProduto = (props: ModalAdicionarProdutosProps) => {

    return (
        <DefaultModal
            minWidth="400px"
            open={props.aberto || false}
            variant={"temporary"}
            anchor="right"
        >
            <AdicionarProdutoDetalhado
                {...props}
            />
        </DefaultModal>
    )
}