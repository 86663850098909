import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { Redirect, RouteProps } from 'react-router-dom';
import { useMovAtualStorage } from 'services/app/hooks/mov-atual-storage';
import { consoleDev } from 'utils/console-dev';

export const MovAtualValidationRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { get } = useMovAtualStorage();
  consoleDev('MovAtualValidationRoute');
  const route = () => {
    return get() ? children : <Redirect to={MovRotasMock.landingRota} />;
  };
  return <>{route()}</>;
};
