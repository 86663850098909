import { Button, Grid } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-produto-balanca-styles';
import { OkIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { TextFieldSaurus } from '../../controles/inputs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCadastros } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Controller, useForm } from 'react-hook-form';
import { useDialogProdutoBalancaValidation } from './dialog-produto-balanca-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProdutoBalancaFormModel } from 'model/app/forms/movimentacao/produto-balanca-form-model';
import { isEmpty } from 'lodash';
import { PesandoProduto } from './components/pesando-produto/pesando-produto';
import { useUSB } from 'services/app/hooks/usb';

export interface DialogAdicionarProdutoBalancaProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel | null;
}

export const DialogAdicionarProdutoBalanca = ({
  aberto,
  produto = new MovSimplesProdutoModel(),
}: DialogAdicionarProdutoBalancaProps) => {

  const classes = useStyles();
  const refInputQCom = useRef<HTMLInputElement>(null);
  const refInputVFinal = useRef<HTMLInputElement>(null);
  const { isMobile } = useThemeQueries();
  const { carregando, adicionarProdutoBalanca } = useMovProd()
  const { fecharAdicionarProdutoBalanca } = useCadastros()
  const { callEvent } = useEventTools()
  const { DialogProdutoBalancaValidation } = useDialogProdutoBalancaValidation()
  const valorEscolhido = useRef<'qtd' | 'valor'>()
  const [pesandoProduto, setPesandoProduto] = useState<boolean>(false)
  const [disableQtd, setDisableQtd] = useState<boolean>(false)
  const { verificarUSB, temUSB } = useUSB();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    setError
  } = useForm<ProdutoBalancaFormModel>({
    defaultValues: { ...new ProdutoBalancaFormModel() },
    resolver: yupResolver(DialogProdutoBalancaValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    if (aberto && produto) {
      verificarUSB();
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [aberto, callEvent, produto, verificarUSB])

  useEffect(() => {
    if (produto) {
      if (produto.qCom !== 1) {
        setValue('qCom', toDecimalString(produto.qCom, 4));
        setValue('vFinal', toDecimalString(produto.vFinal));
      }

      if (aberto) {
        setTimeout(() => {
          refInputQCom.current?.focus();
        }, 50);
      }
    }
  }, [aberto, isMobile, produto, setValue]);

  const onSubmit = useCallback(async (value: ProdutoBalancaFormModel) => {
    if (isEmpty(value.qCom) || toDecimal(value.qCom) === 0) {
      setError('qCom', {
        message: 'Informe a quantidade do produto'
      })
      return;
    }

    if (valorEscolhido.current === 'qtd') //VERIFICAR SE FOI FEITO VALOR OU QTDE
    {
      produto!.qCom = toDecimal(value.qCom, 10);
    } else {
      produto!.qCom = 0;
    }
    produto!.vProd = toDecimal(value.vFinal, 10)

    await adicionarProdutoBalanca(produto!);
    fecharAdicionarProdutoBalanca()
  }, [adicionarProdutoBalanca, fecharAdicionarProdutoBalanca, produto, setError])

  const handleChangeQCom = useCallback(
    (qCom: string | undefined, vFinal: string | undefined, disableQCom?: boolean) => {

      if (disableQCom) {
        setDisableQtd(true)
      }

      if (qCom !== undefined) {

        valorEscolhido.current = 'qtd'
        if (qCom.startsWith(',')) {
          setValue('qCom', `0${qCom}`)
        } else { setValue('qCom', qCom); }

        setValue('vFinal', toDecimalString(toDecimal(qCom, 4) * produto!.vUnCom, 2));
        return
      }

      if (vFinal !== undefined) {
        valorEscolhido.current = 'valor'
        setValue('vFinal', vFinal);
        setValue('qCom', toDecimalString(toDecimal(vFinal) / produto!.vUnCom, 3));
      }
    },
    [produto, setValue],
  );

  const submitBalanca = useCallback(async (peso: string) => {
    handleChangeQCom(peso, undefined, true)
    await handleSubmit(onSubmit)()
    setPesandoProduto(false);
  }, [handleChangeQCom, handleSubmit, onSubmit])

  const closePesando = useCallback(() => {
    setPesandoProduto(false)
  }, [])

  useEffect(() => {
    if (temUSB) {
      setPesandoProduto(true)
    }
  }, [temUSB])

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Produto Pesável"
      isButtonTitleClose={fecharAdicionarProdutoBalanca !== undefined}
      handleClickClose={fecharAdicionarProdutoBalanca}
      tamanho="sm"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.cardContainer}>
        {!pesandoProduto ? <Grid className={classes.cardContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={4}>
                <TextFieldSaurus
                  tipo="DECIMAL"
                  manterMascara
                  max={8}
                  inputProps={{ maxLength: 12 }}
                  readOnly={true}
                  fullWidth
                  variant="outlined"
                  label="Valor de Venda"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={produto!.vUnCom}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <Controller
                  control={control}
                  name="qCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputQCom}
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      readOnly={disableQtd}
                      fullWidth
                      variant="outlined"
                      label="Quantidade"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      casasDecimais={3}
                      allowSubmit
                      maxTexto={12}
                      error={Boolean(
                        errors.qCom && errors.qCom.message,
                      )}
                      helperText={
                        touchedFields.qCom || errors.qCom
                          ? errors.qCom?.message
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => {

                        handleChangeQCom(e.target.value, undefined)
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={4}>

                <Controller
                  control={control}
                  name="vFinal"
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputVFinal}
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Valor Total"
                      maxTexto={12}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      allowSubmit
                      {...field}
                      error={Boolean(
                        errors.vFinal && errors.vFinal.message,
                      )}
                      helperText={
                        touchedFields.vFinal || errors.vFinal
                          ? errors.vFinal?.message
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => handleChangeQCom(undefined, e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item mt={2} xs={12}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  rounded
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  {'Confirmar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid> : <PesandoProduto closeBalanca={closePesando} submitPeso={submitBalanca} />}
      </Grid>
    </DialogSaurus>
  );
};