import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const PromocaoDePorIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 25 3 C 12.85529 3 3 12.85529 3 25 C 3 30.033081 4.6735359 34.64018 7.4726562 38.34375 L 2.2929688 36.759766 A 1.0008691 1.0008691 0 1 0 1.7070312 38.673828 L 10.644531 41.404297 L 11.886719 32.208984 A 1.0001 1.0001 0 0 0 10.917969 31.060547 A 1.0001 1.0001 0 0 0 9.9042969 31.941406 L 9.1835938 37.285156 C 6.5655686 33.890133 5 29.659806 5 25 C 5 13.93471 13.93471 5 25 5 L 25 3 z M 27.111328 3.0761719 L 26.828125 5.0527344 L 27.822266 5.1992188 C 28.815266 5.3352188 27.821453 5.1989375 28.814453 5.3359375 L 29.097656 3.359375 L 28.103516 3.2128906 L 27.111328 3.0761719 z M 31.230469 3.875 L 30.576172 5.7636719 L 31.519531 6.0859375 C 32.463531 6.4079375 31.520844 6.0852031 32.464844 6.4082031 L 33.119141 4.5195312 L 31.230469 3.875 z M 35.109375 5.4355469 L 34.113281 7.1621094 L 34.976562 7.6640625 C 35.850562 8.1670625 34.976562 7.6649688 35.851562 8.1679688 L 36.847656 6.4296875 L 35.109375 5.4355469 z M 39.355469 8.5957031 L 38.113281 17.791016 A 1.0001994 1.0001994 0 1 0 40.095703 18.058594 L 40.816406 12.714844 C 43.434431 16.109867 45 20.340194 45 25 C 45 36.06529 36.06529 45 25 45 L 25 47 C 37.14471 47 47 37.14471 47 25 C 47 19.966919 45.326464 15.35982 42.527344 11.65625 L 47.707031 13.240234 A 1.0008692 1.0008692 0 1 0 48.292969 11.326172 L 39.355469 8.5957031 z M 14.080078 41.789062 L 13.078125 43.521484 L 14.8125 44.523438 L 15.816406 42.800781 L 14.080078 41.789062 z M 17.460938 43.5625 L 16.798828 45.447266 L 18.683594 46.099609 L 19.345703 44.214844 L 18.404297 43.888672 L 17.460938 43.5625 z M 21.105469 44.648438 L 20.816406 46.625 L 21.808594 46.775391 L 22.800781 46.914062 L 23.091797 44.939453 L 22.097656 44.789062 L 21.105469 44.648438 z"></path>
            </DefaultIcon>
        </>
    );
};
