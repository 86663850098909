import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        fontFamily: "'Montserrat', sans-serif",
    },
    noWrap: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    primary: {
        color: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    commomWhite: {
        color: theme.palette.common.white,
    },
    alignLeft: {
        textAlign: 'left',
    },
    alignCenter: {
        textAlign: 'center',
    },
    alignRight: {
        textAlign: 'right',
    },
    alignJustify: {
        textAlign: 'justify',
    },
    inherit: {
        color: "inherit",
    },
    initial: {
    },
    textPrimary: {
        color: theme.palette.text.primary,
    },
    textSecondary: {
        color: theme.palette.text.secondary,
    },
    weightNormal: {
        fontWeight: 'normal',
    },
    weight500: {
        fontWeight: '500 !important' as any,
    },
    weight600: {
        fontWeight: '600 !important' as any,
    },
    weight700: {
        fontWeight: '700 !important' as any,
    },
    weight800: {
        fontWeight: '800 !important' as any,
    },
    weight900: {
        fontWeight: '900 !important' as any,
    },
    h1: {
        fontSize: '6rem',
        fontWeight: 300,
        lineHeight: 1.167,
    },
    h2: {
        fontSize: '3.75rem',
        fontWeight: 300,
        lineHeight: 1.2,
    },
    h3: {
        fontSize: '3rem',
        fontWeight: 400,
        lineHeight: 1.167,
    },
    h4: {
        fontSize: '2.125rem',
        fontWeight: 400,
        lineHeight: 1.235,
    },
    h5: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.334,
    },
    h6: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: 1.6,
    },
    subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.75,
    },
    subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
    },
    overline: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 2.66,
        textTransform: 'uppercase',
    },
    srOnly: {
        width: '1px',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
    },
    body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
    },
    body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.43,
    },
    caption: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
    },
    button: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1.75,
        textTransform: 'none',
    }
}));
