import { useEffect, useMemo, useRef } from 'react';
import { Grid, Typography } from 'views/design-system';
import { useStyles } from './sincronizacao-page-styles';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { TpSincronizacaoMock } from 'data/mocks/tp-sincronizacao';
import { useSessaoAtual } from 'services/app';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { EnumSincronizacao } from 'model/enums/enum-sincronizacao';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { toDecimalString } from 'utils/to-decimal';

const SincronizacaoPage = () => {
  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const { callEvent } = useEventTools();
  const setHideHeader = useVendaHeaderStore((state) => state.setHideHeader);
  const {
    iniciarSincronizacao,
    sincAtual,
  } = useSincronizacaoCadastros();
  const classes = useStyles();
  const soUmaVez = useRef<boolean>(false);

  useEffect(() => {
    setHideHeader(true);
    callEvent(AppEventEnum.AlterarMenuPDV, false);

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true);
    }
  }, [callEvent, setHideHeader]);

  useEffect(() => {
    if (
      !soUmaVez.current
    ) {
      (async () => {
        const params = new URLSearchParams(window.location.search);
        const forcar = params.get('forcar')?.toLowerCase() === 'true';

        await iniciarSincronizacao(getEmpresaSelecionada()!.Id, forcar);
      })();
      soUmaVez.current = true;
      return;
    }
  }, [iniciarSincronizacao, getEmpresaSelecionada]);

  const tpSincronizacao = TpSincronizacaoMock.filter(
    (x) => x.Key === sincAtual?.sincAtual,
  );

  const middleContainerLoadEInfo = useMemo(() => {
    return (
      <>
        <Grid className={classes.containerLoader}>
          <LoadingFinalizacao />
        </Grid>
        <Typography className={classes.textTitle}>Sincronizando...</Typography>

        <Typography variant="subtitle1" className={classes.textSubtitle}>
          Aguarde enquanto estamos realizando a sincronização dos seus
          cadastros.
        </Typography>
      </>
    );
  }, [classes.containerLoader, classes.textSubtitle, classes.textTitle]);

  const calcularSincronizacao = (
    totalResults: number,
    pageSize: number,
    pageAtual: number,
    totalPage: number,
  ) => {
    return totalResults < pageSize
      ? pageAtual
      : pageAtual === totalPage
        ? totalResults
        : pageAtual * pageSize;
  };

  const registros = calcularSincronizacao(
    sincAtual?.totalResults || 0,
    sincAtual?.pageSize || 100,
    sincAtual?.pageAtual || 1,
    sincAtual?.totalPage || 1,
  );

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.content}>
          <Grid className={classes.infoContainer}>
            {middleContainerLoadEInfo}
            {sincAtual && (
              <>
                <Grid className={classes.containerSincAtual}>
                  <Grid className={classes.textInfo}>

                    {tpSincronizacao ?
                      [EnumSincronizacao.SALOES, EnumSincronizacao.COMANDAS, EnumSincronizacao.MESAS].includes(tpSincronizacao[0].Key) && isFarmaceutico ? 'Configurações' : tpSincronizacao[0].Value : '...'}
                    {sincAtual?.totalResults &&
                      sincAtual?.totalPage &&
                      sincAtual?.pageAtual && (
                        <>
                          {': '}{toDecimalString(registros * 100 / sincAtual?.totalResults, 0)}%
                        </>
                      )}
                  </Grid>
                </Grid>

                {sincAtual?.totalResults &&
                  sincAtual?.totalPage &&
                  sincAtual?.pageAtual && (
                    <Typography className={classes.textASincronizar}>
                      Progresso:
                      ({registros}/{sincAtual?.totalResults})
                    </Typography>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SincronizacaoPage;
