import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1)
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        '& p': {
            fontWeight: 500,
            wordBreak: 'break-word'
        },
        [theme.breakpoints.down('xs')]: {
            '& p, span': {
                fontSize: '13px'
            },
        }
    },
    iconRight: {
        // borderLeftWidth: 1,
        // borderLeftStyle: "solid",
        // borderLeftColor: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            display: 'flex !important',
        },
        '& svg': {
            fill: theme.palette.primary.main
        }
    },
    negrito: {
        'font-weight': '600!important'
    },
}));