export enum EnumKey {
    ESC = 'Escape',
    BACKSPACE = 'Backspace',
    TAB = 'Tab',
    ENTER = 'Enter',
    SHIFT = 'Shift',
    CTRL = 'Control',
    ALT = 'Alt',
    PAUSE = 'Pause',
    CAPS_LOCK = 'CapsLock',
    SPACE = ' ',
    PAGE_UP = 'PageUp',
    PAGE_DOWN = 'PageDown',
    END = 'End',
    HOME = 'Home',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_UP = 'ArrowUp',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_DOWN = 'ArrowDown',
    PRINT_SCREEN = 'PrintScreen',
    INSERT = 'Insert',
    DELETE = 'Delete',
    DIGIT_0 = '0',
    DIGIT_1 = '1',
    DIGIT_2 = '2',
    DIGIT_3 = '3',
    DIGIT_4 = '4',
    DIGIT_5 = '5',
    DIGIT_6 = '6',
    DIGIT_7 = '7',
    DIGIT_8 = '8',
    DIGIT_9 = '9',
    KEY_A = 'a',
    KEY_B = 'b',
    KEY_C = 'c',
    KEY_D = 'd',
    KEY_E = 'e',
    KEY_F = 'f',
    KEY_G = 'g',
    KEY_H = 'h',
    KEY_I = 'i',
    KEY_J = 'j',
    KEY_K = 'k',
    KEY_L = 'l',
    KEY_M = 'm',
    KEY_N = 'n',
    KEY_O = 'o',
    KEY_P = 'p',
    KEY_Q = 'q',
    KEY_R = 'r',
    KEY_S = 's',
    KEY_T = 't',
    KEY_U = 'u',
    KEY_V = 'v',
    KEY_W = 'w',
    KEY_X = 'x',
    KEY_Y = 'y',
    KEY_Z = 'z',
    META_LEFT = 'Meta',
    META_RIGHT = 'Meta',
    CONTEXT_MENU = 'ContextMenu',
    NUMPAD_0 = '0',
    NUMPAD_1 = '1',
    NUMPAD_2 = '2',
    NUMPAD_3 = '3',
    NUMPAD_4 = '4',
    NUMPAD_5 = '5',
    NUMPAD_6 = '6',
    NUMPAD_7 = '7',
    NUMPAD_8 = '8',
    NUMPAD_9 = '9',
    MULTIPLY = '*',
    ADD = '+',
    SUBTRACT = '-',
    DECIMAL = '.',
    DIVIDE = '/',
    F1 = 'F1',
    F2 = 'F2',
    F3 = 'F3',
    F4 = 'F4',
    F5 = 'F5',
    F6 = 'F6',
    F7 = 'F7',
    F8 = 'F8',
    F9 = 'F9',
    F10 = 'F10',
    F11 = 'F11',
    F12 = 'F12',
    NUM_LOCK = 'NumLock',
    SCROLL_LOCK = 'ScrollLock',
    SEMICOLON = ';',
    EQUALS = '=',
    COMMA = ',',
    DASH = '-',
    PERIOD = '.',
    SLASH = '/',
    BACKQUOTE = '`',
    BRACKET_LEFT = '[',
    BACKSLASH = '\\',
    BRACKET_RIGHT = ']',
    QUOTE = '\''
}
