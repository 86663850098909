import { useStyles } from './button-keyboard-add-styles';
import { ButtonKeyboardAddProps } from './button-keyboard-add-props';
import { MaisIcon } from 'views/components/icons';
import { Button } from 'views/design-system';

export function ButtonKeyboardAdd(props: ButtonKeyboardAddProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.buttonKeyboardAddContainer}
      onClick={() => {
        if (props.clickFunc != null) props.clickFunc();
      }}
    >
      <Button rounded variant='contained' style={{ height: 48, width: 48, display: 'grid', padding: 0 }} color="primary">
        <MaisIcon tipo="BUTTON_PRIMARY" class={classes.addIcon} />
      </Button>
    </div>
  );
}
