import { useEffect, useState, useCallback, useRef } from "react";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { useHistory } from "react-router-dom";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useStyles } from "./movimentacoes-list-style";
import { MovimentacoesEstoqueListData } from "./movimentacoes.list-data";
import { useGetMovimentacoesProdutoEstoque } from "data/api/gestao/movimentacao-de-produto-estoque/get-movimentacao-produto-estoque";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { InformacaoIcon } from "views/components/icons";
import { Box, Grid } from "views/design-system";
import { DepositoListModel } from "../../produto-estoque";
import { IRangeData } from "views/components/controles/inputs/calendario/calendario";
import { dataAtual, toDate, toDateStringApi } from "utils/to-date";
import { Paper, Typography } from "@material-ui/core";
import { useThemeQueries } from "views/theme";
import { MovimentacaoEstoqueModel, SomatoriosMovimentacoesEstoqueModel } from "model/api/gestao/movimentacao-estoque/movimentacao-estoque-model";
import { FormMovimentacoesEstoqueFiltros, FormMovimentacoesEstoqueModel } from "views/components/form/movimentacoes-estoque-filtros/form-movimentacoes-estoque-filtros";
import { DefaultFormRefs } from "views/components/form/utils";
import { isEmpty } from "lodash";
import { guidEmpty } from "utils/guid-empty";

interface MovimentacaoEstoqueListProps {
  variacaoProdutoId: string,
  depositoId: string,
  depositosList: DepositoListModel[],
  carregando: boolean
}

export const MovimentacoesEstoqueList = (props: MovimentacaoEstoqueListProps) => {

  const classes = useStyles();
  const { abrirCadastroDepositos } = useCadastros();
  const { getMovimentacoesProdutoEstoque, carregando: carregandoMovimentacoes } = useGetMovimentacoesProdutoEstoque();
  const { addHandler, removeHandler } = useEventTools()
  const { showToast } = useToastSaurus();
  const { getEmpresaAtual } = useEmpresaAtual()
  const history = useHistory();
  const { theme } = useThemeQueries()
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: new Array<MovimentacaoEstoqueModel>(),
    somatorios: new SomatoriosMovimentacoesEstoqueModel()
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const formFiltroRef = useRef<DefaultFormRefs<FormMovimentacoesEstoqueModel>>(null)

  const loading = carregandoMovimentacoes || props.carregando
  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: MovimentacaoEstoqueModel[],
      somatorios: SomatoriosMovimentacoesEstoqueModel
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
        somatorios: somatorios
      });
    },
    []
  );

  const empresaId = getEmpresaAtual()?.id ?? ''
  
  const preencherDataPadrao = useCallback((model:FormMovimentacoesEstoqueModel)=>{
   formFiltroRef.current?.fillForm(model)
  },[])
  
  const search = useCallback(
    async (newPage: number, rangeData?: IRangeData, depositos?: DepositoListModel) => { 
      let listaDepositoId = ''
      let dataInicialTratada = ''
      let dataFinalTratada = ''
      if (depositos ) {
        if(isEmpty(depositos.id) || depositos.id === guidEmpty()){
          listaDepositoId = ''
        } else{listaDepositoId = `&DepositoId=${depositos.id}`}
      }

      if (rangeData) {
        if (rangeData.inicio !== undefined) {
          let dataInicial = toDateStringApi(rangeData.inicio).split('T')
          dataInicial[1] = '00:00:00'
          dataInicialTratada = dataInicial.join("T")
        }

        if (rangeData.fim !== undefined) {
          let dataFinal = toDateStringApi(rangeData.fim).split('T')
          dataFinal[1] = '23:59:00'
          dataFinalTratada = dataFinal.join("T")
        }
      } else {
        let dataInicial = dataAtual().split('T')
        dataInicial[1] = '00:00:00'
        dataInicialTratada = dataInicial.join("T")
        let dataFinal = dataAtual().split('T')
        dataFinal[1] = '23:59:00'
        dataFinalTratada = dataFinal.join("T")
      }
      const valorFiltroPadrao = new FormMovimentacoesEstoqueModel()
      valorFiltroPadrao.periodo.inicio = toDate(dataInicialTratada)
      valorFiltroPadrao.periodo.fim = toDate(dataFinalTratada)

      preencherDataPadrao(valorFiltroPadrao)

      const query = `ProdutoVariacaoId=${props.variacaoProdutoId}${listaDepositoId}&DataInicial=${dataInicialTratada}&DataFinal=${dataFinalTratada}&PageSize=30`
      try {
        const res = await getMovimentacoesProdutoEstoque(query, empresaId, newPage);
        if (res.erro) throw res.erro;
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }
        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
          res.resultado?.data?.somatorios
        );

      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [empresaId, fillResult, getMovimentacoesProdutoEstoque, preencherDataPadrao, props.variacaoProdutoId, showToast]
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    addHandler(AppEventEnum.DepositoModal, modalEdit)

    return () => removeHandler(AppEventEnum.DepositoModal, modalEdit)
  }, [addHandler, modalEdit, removeHandler])

  useEffect(() => {
    if (!modalEditAberto) {
      search(queryStatus.page);
    }
  }, [modalEditAberto, queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    abrirCadastroDepositos(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  const handleSubmit = useCallback(async (model: FormMovimentacoesEstoqueModel) => {
    await search(queryStatus.page, model.periodo, model.depositos);
  }, [queryStatus.page, search])


  return (
    <Box className={classes.defaultContainer}>
      {loading && <CircularLoading tipo="FULLSIZED" />}

      <FormMovimentacoesEstoqueFiltros
        depositoList={props.depositosList}
        loading={loading}
        showLoading={false}
        onSubmit={handleSubmit}
        ref={formFiltroRef}
      />
      <Box flex alignItems="center" className={classes.paginacao}>
        <Paginacao
          pageChanged={pageChanged}
          totalPages={queryStatus.totalPages}
          totalRegisters={queryStatus.totalResults}
          currentPage={queryStatus.page}
        />
      </Box>
      <Box className={classes.listContainer}>
        <MovimentacoesEstoqueListData
          carregando={loading}
          list={queryStatus.list}
          onCardSelected={onCardSelected}
          onCardChecked={onCardChecked}
        />
      </Box>
      <Paper className={classes.paperInfo}>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between" flex>
          <Grid item xs={9} flex alignItems="center" >
            <InformacaoIcon tipo="BUTTON_FAB" fill={theme.palette.primary.main} />
            <Typography variant="body2" className={classes.info}>
              O valor total exibido ao lado corresponderá exclusivamente à data selecionada no filtro.
            </Typography>
          </Grid>
          <Grid item xs={2} flex justifyContent="flex-end">
            <Typography variant="h6">Total: <strong>{queryStatus.somatorios?.TotalQComOriginal ?? 0}</strong></Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

