import { Button, Tooltip } from "views/design-system"
import { useStyles } from "./button-modal-header-styles";
import { isEmpty } from 'lodash';
import classNames from "classnames";

interface ButtonIconProps {
    icon: JSX.Element;
    tooltip: string;
    onClick: () => any;
    disabled?: boolean;
    className?: string;
}

export const ButtonModalHeader = (props: ButtonIconProps) => {
    const classes = useStyles();
    return (
        <>
            {!isEmpty(props.tooltip) &&
                (
                    <Tooltip arrow title={props.tooltip}>
                        <Button className={classNames(classes.button, props.className)} disabled={props.disabled} onClick={props.onClick}>
                            {props.icon}
                        </Button>
                    </Tooltip>
                )}
            {isEmpty(props.tooltip) &&
                (
                    <Button className={classNames(classes.button, props.className)} disabled={props.disabled} onClick={props.onClick}>
                        {props.icon}
                    </Button>
                )}
        </>
    )

}

