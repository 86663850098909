import { Controller, useForm } from "react-hook-form";
import { makeUtilClasses, useThemeQueries } from "views/theme";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { UsuarioModel } from "model/api/gestao/usuarios/usuario-model";
import { toDateString } from "utils/to-date";
import { pegaHora } from "utils/pegar-data";
import { InformacaoIcon } from "views/components/icons";
import { useFormCodigoSuporteRemotoValidationYup } from "./form-codigo-suporte-remoto-validation";
import { useStyles } from "./form-codigo-suporte-remoto-styles";
import { Box, Grid, Typography } from "views/design-system";

interface Props {
    model: UsuarioModel;
    revogar: boolean;
    expirado: boolean;
}

export const FormCodigoSuporteRemoto = ({
    model,
    revogar,
    expirado,
}: Props) => {

    //AUX
    const utilClasses = makeUtilClasses();
    const { FormCodigoSporteRemotoValidationYup } = useFormCodigoSuporteRemotoValidationYup();
    const { theme, isMobile } = useThemeQueries();
    const classes = useStyles();

    const {
        control,
        formState: { errors, touchedFields },
    } = useForm<UsuarioModel>({
        resolver: yupResolver(FormCodigoSporteRemotoValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    return (
        <>
            <Box my={2}>
                <div className={utilClasses.formContainer}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <Controller
                                name="codAcessoSuporte"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label="Código"
                                        tipo="TEXTO"
                                        fullWidth
                                        readOnly
                                        error={Boolean(
                                            errors.codAcessoSuporte && errors.codAcessoSuporte.message,
                                        )}
                                        helperText={
                                            touchedFields.codAcessoSuporte || errors.codAcessoSuporte
                                                ? errors.codAcessoSuporte?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={!revogar ? model.codAcessoSuporte : ''}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.containerTextMessage}>
                            <Typography
                                variant="body1"
                                className={classes.textMessage}
                                style={{
                                    color: expirado ? theme.palette.error.main : theme.palette.primary.main,
                                }}
                            >
                                <InformacaoIcon
                                    tipo="BUTTON"
                                    fill={expirado ? theme.palette.error.main : theme.palette.primary.main}
                                    style={{
                                        height: isMobile ? '40px' : '28px',
                                        width: isMobile ? '40px' : '28px'
                                    }}
                                />
                                <Box>
                                    <Typography weight={600}>
                                        {!revogar ?
                                            expirado ?
                                                `O código está expirado. Gere novamente para poder acessar.` :
                                                `O código irá expirar em ${toDateString(model.expiracaoAcessoSuporte)} as ${pegaHora(model.expiracaoAcessoSuporte)}`
                                            : `Para gerar o código basta clicar no botão abaixo "Gerar Código".`}
                                    </Typography>
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </>
    )
}