import { useGetPoliticaValidar } from "data/api/gestao/politica";
import { PoliticaModel } from "model";
import parse from 'html-react-parser';
import { useCallback, useEffect, useState } from "react";
import { Grid, Button, Typography, Box } from "views/design-system"
import { useStyles } from "./termos-de-uso-styles";
import { CircularLoading } from "views/components/utils";
import { AvancarIcon, CancelarIcon, TermosIcon } from "views/components/icons";
import { isEmpty } from "lodash";
import { useThemeQueries } from "views/theme";
import { useCadastros } from "services/app/hooks/cadastros";
import { useTermosDeUsoStore } from "views/states/termos-de-uso-store";
import { useToastSaurus } from "services/app";
import { ModalExibirTermosDeUsoProps } from "../termos-de-uso-modal";


export const TermosDeUso = ({ finalizarCadastro }: ModalExibirTermosDeUsoProps) => {
    const { fecharTermosDeUso } = useCadastros()
    const [politica, setPolitica] = useState<PoliticaModel>();
    const { getPoliticaValidar, carregando } = useGetPoliticaValidar()
    const classes = useStyles()
    const { theme } = useThemeQueries()
    const loading = carregando
    const { setTermosAceitos } = useTermosDeUsoStore()
    const { showToast } = useToastSaurus()

    const getTermos = useCallback(async () => {
        try {
            const res = await getPoliticaValidar()
            if (res.erro) throw res.erro
            setPolitica(res.resultado?.data[0])
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getPoliticaValidar, showToast])

    useEffect(() => {
        getTermos()
    }, [getTermos])

    return (
        <div className={classes.contentCenter}>
            <Grid container spacing={2} justifyContent="center" className={classes.overflowHidden}>
                {(loading || isEmpty(politica)) ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                <Grid xs={12} item className={classes.titleSpace} >
                    <Typography align="center" variant="h4" color="primary">
                        <TermosIcon tipo="GERAL" class={classes.imgTitulo} />
                        Termos de Uso
                    </Typography>
                    <Box mt={3} >
                        <Typography align="center" variant="body1">
                            Leia os termos de uso e política de privacidade para continuar o acesso ao sistema.
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={12} item className={classes.termosDeUso}>
                    {parse(politica?.termo || '')}
                </Grid>
                <Grid container spacing={2} xs={12} className={classes.buttonSpace} >
                    <Grid item xs={6} >
                        <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            fullWidth={true}
                            disabled={loading}
                            onClick={() => {
                                fecharTermosDeUso()
                            }}
                        >
                            <CancelarIcon
                                fill={theme.palette.primary.main}
                                tipo="BUTTON"
                            />
                            Fechar
                        </Button>
                    </Grid>
                    <Grid item xs={6} >
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            disabled={loading}
                            onClick={() => {
                                setTermosAceitos(true)
                                finalizarCadastro()
                                fecharTermosDeUso()
                            }}
                        >
                            <AvancarIcon tipo="BUTTON_PRIMARY" />
                            Aceitar e Continuar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}