import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { ProdutoSelecionarModal } from "views/components/modals/produto-selecionar-modal/produto-selecionar-modal";
import { ProdutoSelecionarModalProps } from "views/components/modals/produto-selecionar-modal/produto-selecionar-modal-props";

export const ProdutoSelecionarModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<ProdutoSelecionarModalProps>();

    const modalAlterado = useCallback((props: ProdutoSelecionarModalProps) => {
        setModalState(props);
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ProdutoSelecionarModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ProdutoSelecionarModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <ProdutoSelecionarModal adicionar={modalState?.adicionar} idsBloqueados={modalState?.idsBloqueados ?? []} openned={modalState?.openned || false} voltar={modalState?.voltar} />
    )
}