import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';
import { StatusCadastroMock } from '../../../../data/mocks';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import React from 'react';
import { useStyles } from './card-convenio-styles'
import classNames from 'classnames';
import { CardConvenioProps } from './card-convenio-props';
import { EnumConvenioStatus } from 'model/enums/enum-convenio-status';
import { toDecimalString } from 'utils/to-decimal';
import { Grid, Tooltip, Typography } from 'views/design-system';

const CardConvenio = ({ model, onClick, onCheck, selected }: CardConvenioProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const { cartoes } = model

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumConvenioStatus.Ativo:
        return theme.palette.success.main;
      case EnumConvenioStatus.Bloqueado:
        return theme.palette.warning.main;
      case EnumConvenioStatus.Inativo:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status,
  ]);

  const descricao = StatusCadastroMock.filter((x) => x.Key === model.status)?.[0]
    ?.Value;

  const cartao = cartoes.find(cartao => cartao.pessoaId === model.pessoaId)
  const nome = cartao?.nomeCartao ?? ''
  const numeroCartao = cartao?.numeroCartao ?? ''
  const qtdeCartao = cartoes.length
  const limiteTotal = cartoes.reduce((cc, curr) => cc + curr.limite, 0)

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model?.id ?? '');
        }}
      >
        <Tooltip arrow title={descricao ?? 'Não encontrado'} placement="right">
          <div
            className={defaultCardClasses.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(defaultCardClasses.cardContent, classes.card)}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={3} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Pessoa
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                {nome}
              </Typography>
            </Grid>
            <Grid item sm={6} md={3} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Nº do Cartão do Titular
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                {numeroCartao}
              </Typography>
            </Grid>
            <Grid item sm={4} md={2} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Qtd Cartões
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                 {`${qtdeCartao}`.padStart(2, '0')}
              </Typography>
            </Grid>
            <Grid item sm={6} md={2} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Fechamento
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                Dia {`${model.diaFechamento}`.padStart(2, '0')}
              </Typography>
            </Grid>
            <Grid item sm={6} md={2} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Limite
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                R$ {toDecimalString(limiteTotal)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Editar Convênio">
          <div className={classNames(defaultCardClasses.cardRightButton, classes.iconRight)}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardConvenio);
