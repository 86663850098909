import * as Yup from "yup";
import { useMemo } from "react";

export const useFormPromocaoNomeValidation = () => {
  const FormYupValidationNomePromocao = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Informe o nome da campanha da promoção.').min(3, 'O nome da campanha deve ter no mínimo 3 caracteres.').max(30, 'O nome da campanha deve ter no máximo 30 caracteres.'),
    });
  }, []);

  return {
    FormYupValidationNomePromocao,
  };
};
