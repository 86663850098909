import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { ComissaoModal } from 'views/components/modals/comissao/comissao-modal/comissao-modal';

export const CadastroComissaoModal = () => {
  const { push, location } = useHistory();
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
    search: '',
    veioDoCadastro: false
  });

  const comissaoRoute = `/comissoes/`;
  const novoCadastroRoute = 'adicionar';

  consoleDev('CadastroComissoesModal');

  useEffect(() => {
    if (location.pathname.indexOf(comissaoRoute) === 0) {
      const rota = location.pathname.split('/');
      const id = rota[2];
      const posrota = location.pathname.replace(comissaoRoute, '');
      if (posrota.length > 0) {
        setModalState((prev) => ({
          ...prev,
          id: id === novoCadastroRoute ? '' : id,
          aberto: true,
          ultimaUrl: comissaoRoute
        }));
        return;
      }
    }
  }, [location.pathname, comissaoRoute]);

  const modalAlterado = useCallback(
    ({ id, openned, callbackUrl, trocarUrl, veioDoCadastro }: any) => {
      let url = callbackUrl.length === 0 ? comissaoRoute : callbackUrl;
      if (modalState.aberto) {
        url = modalState.ultimaUrl;
      }
      const prevSearch = modalState.search;
     
      if (!openned && !trocarUrl) {
        push({
          pathname: comissaoRoute,
          search: prevSearch
        });
      }
      if (trocarUrl) {
        push(comissaoRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
      setModalState({
        id: id,
        aberto: openned,
        ultimaUrl: url,
        search: window.location.search,
        veioDoCadastro
      });
    },
    [
      modalState.aberto,
      modalState.search,
      modalState.ultimaUrl,
      comissaoRoute,
      push
    ]
  );

  useEffect(() => {
    addHandler(AppEventEnum.ComissaoModal, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.ComissaoModal, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <ComissaoModal
          openned={modalState.aberto}
          key="mdlComissao"
          id={modalState.id}
          veioDoCadastro={modalState.veioDoCadastro}
        />
      )}
    </>
  );
};
