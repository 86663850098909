import { useCallback } from 'react';
import { VariaveisAmbiente } from 'config';
import { useApiBase } from 'data/api/base';
import { PromocaoModel } from 'model/api/gestao/promocao/promocao-model';

export function usePutPromocao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPromocao = useCallback(
        (promocao: PromocaoModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/promocao/${promocao.id}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(promocao),
                enviarTokenUsuario: true
            }),
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putPromocao
    };
}
