import { Button, Grid } from '@material-ui/core';
import { useRef, useState } from 'react';
import { useStyles } from './form-apelido-styles';
import { useFormApelidoValidation } from './form-apelido-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ApelidoFormModel } from 'model/app/forms/master/apelido-form';

export const FormApelido = React.forwardRef<
  DefaultFormRefs<ApelidoFormModel>,
  DefaultFormProps<ApelidoFormModel>
>((props: DefaultFormProps<ApelidoFormModel>, refs) => {
  const txtDocumento = useRef<HTMLInputElement>();
  const { FormApelidoValidationYup } =
    useFormApelidoValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new ApelidoFormModel(''),
  });
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ApelidoFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormApelidoValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (form: ApelidoFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new ApelidoFormModel('') });
      reset();
      if (!isMobile) {
        txtDocumento?.current?.focus();
      }
    },
    fillForm: (model: ApelidoFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) {
        txtDocumento?.current?.focus();
      }
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="apelido"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtDocumento}
                    allowSubmit={true}
                    autoComplete={'off'}
                    label="Apelido"
                    placeholder=""
                    error={Boolean(
                      errors.apelido && errors.apelido.message,
                    )}
                    helperText={
                      errors.apelido
                        ? errors.apelido?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
