import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { PerfisHeader } from './components/perfis-header/perfis-header';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetPerfis } from 'data/api/gestao/perfil/get-perfis';

import { useGetEmpresasUsuario } from 'data/api/gestao/perfil/get-perfil-empresa-usuarios';
import { ButtonFab } from 'views/components/controles';
import { useHistory } from 'react-router';
import { useCadastros, useToastSaurus } from 'services/app';
import { NovaPermissaoIcon } from 'views/components/icons/nova-permissao-icon';
import { NovoUsuarioIcon } from 'views/components/icons';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus
} from 'views/components/tabs/tabs-saurus';
import { EmpresaUsuarioModel, PerfilModel } from 'model';
import { PerfilListData } from './components/perfis-tabs/components/perfil-list-data';
import { PerfiUsuariolListData } from './components/perfis-tabs/components/perfil-usuario-list-data';
import { useStyles } from './perfis-page-styles';
import { Paginacao } from 'views/components/paginacao';
import { isPlanoComControleDeAcesso } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { CardControleCotaUsuarios } from 'views/components/cards/card-controle-cota-usuarios/card-controle-cota-usuarios';
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { EnumTour } from "model/enums/enum-tour";
import { Grid } from 'views/design-system';

export const PerfisPage = () => {
  const defClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { plano } = useSessaoAtual();

  const rotaPerfil = '/permissoes/perfil';
  const rotaUsuario = '/permissoes/usuarios';
  const PlanoComControleDeAcesso = isPlanoComControleDeAcesso(plano?.plano);

  const retornaAba = (path: string) => {
    return path.indexOf(rotaPerfil) > -1 ? 0 : 1;
  };

  const { getPerfis, carregando: carregandoPerfis } = useGetPerfis();
  const [perfis, setPerfis] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PerfilModel>()
  });

  const { getEmpresasUsuario, carregando: carregandoUsuarios } =
    useGetEmpresasUsuario();
  const [empresaUsuario, setEmpresaUsuario] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<EmpresaUsuarioModel>()
  });

  const { replace, location } = useHistory();
  const { showToast } = useToastSaurus();
  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname)
  );
  const [carregandoInicial, setCarregandoInicial] = useState(false);
  const { addHandler, removeHandler } = useEventTools();

  const [selectedPerfilList, setSelectedPerfilList] = useState<Array<string>>(
    []
  );
  const [selectedUsuarioList, setSelectedUsuariosList] = useState<
    Array<string>
  >([]);

  const [modalEditPerfilAberto, setModalEditPerfilAberto] = useState(false);
  const [modalEditEmpresaUsuarioAberto, setModalEditEmpresaUsuarioAberto] =
    useState(false);

  const { abrirCadastroPerfil, abrirCadastroUsuario } = useCadastros();
  const { callTour } = useTourSaurus()
  const carregando =
    carregandoPerfis || carregandoUsuarios || carregandoInicial;

  if (!PlanoComControleDeAcesso) {
    setAbaSelecionada(1)
  }

  const fillResultPerfil = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PerfilModel>
    ) => {
      setPerfis({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const fillResultEmpresaUsuario = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<EmpresaUsuarioModel>
    ) => {
      setEmpresaUsuario({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  // Lista os perfis
  const handlePerfis = useCallback(
    async (newPage: number) => {
      try {
        const res = await getPerfis('', newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          handlePerfis(res.resultado?.data?.totalPages);
          return;
        }

        fillResultPerfil(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getPerfis, fillResultPerfil, showToast]
  );

  // Lista os usuarios e suas permissões
  const handlePerfisEmpresasUsuario = useCallback(
    async (newPage: number) => {
      try {
        const res = await getEmpresasUsuario('', newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          handlePerfis(res.resultado?.data?.totalPages);
          return;
        }

        fillResultEmpresaUsuario(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fillResultEmpresaUsuario, getEmpresasUsuario, handlePerfis, showToast]
  );

  useEffect(() => {
    handlePerfisEmpresasUsuario(1);
  }, [handlePerfisEmpresasUsuario]);

  const onCardPerfilSelected = useCallback((id: string) => {
    abrirCadastroPerfil(id, location.pathname, true);
  }, [abrirCadastroPerfil, location.pathname]);

  const onCardPerfilChecked = useCallback((id: string) => {
    const aux = [...selectedPerfilList];
    aux.push(id);
    setSelectedPerfilList(aux);
  }, [selectedPerfilList]);

  const onCardUsuarioSelected = useCallback((model: EmpresaUsuarioModel) => {
    abrirCadastroUsuario(model);
  }, [abrirCadastroUsuario]);

  const onCardUsuarioChecked = useCallback((id: string) => {
    const aux = [...selectedPerfilList];
    aux.push(id);
    setSelectedUsuariosList(aux);
  }, [selectedPerfilList]);

  const onClickAdd = useCallback(() => {
    abrirCadastroPerfil('', '', true);
  }, [abrirCadastroPerfil]);

  const onClickAddUsuario = useCallback(() => {
    abrirCadastroUsuario();
  }, [abrirCadastroUsuario]);

  const tabChange = useCallback((index: number) => {
    if (index === 0) {
      replace(rotaPerfil);
    } else {
      replace(rotaUsuario);
    }
  }, [replace]);;

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditPerfilAberto(openned);
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.PerfilModal, modalEdit);

    return () => removeHandler(AppEventEnum.PerfilModal, modalEdit);
  }, [addHandler, modalEdit, removeHandler]);

  const modalUsuarioEdit = useCallback(({ openned }: any) => {
    setModalEditEmpresaUsuarioAberto(openned);
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.DialogUsuario, modalUsuarioEdit);

    return () => removeHandler(AppEventEnum.DialogUsuario, modalUsuarioEdit);
  }, [addHandler, modalUsuarioEdit, removeHandler]);

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname]);

  useEffect(() => {
    if (abaSelecionada === 0 && !modalEditPerfilAberto) {
      handlePerfis(perfis.page);
    } else if (abaSelecionada === 1 && !modalEditEmpresaUsuarioAberto) {
      handlePerfisEmpresasUsuario(empresaUsuario.page);
    }
    setCarregandoInicial(false);
  }, [
    abaSelecionada,
    empresaUsuario.page,
    handlePerfis,
    handlePerfisEmpresasUsuario,
    modalEditEmpresaUsuarioAberto,
    modalEditPerfilAberto,
    perfis.page
  ]);

  useEffect(() => {
    if (location.pathname === "/permissoes/perfil") {
      callTour(EnumTour.ADDPERFILDEACESSO)
    }
    if (location.pathname === "/permissoes/usuarios") {
      callTour(EnumTour.ADDUSUARIOS)
    }
  }, [callTour, location.pathname])

  const retButton = useCallback(() => {
    if (abaSelecionada === 0 && PlanoComControleDeAcesso) {
      return (
        <ButtonFab
          id="tour-perfilDeAcesso"
          tooltip="Adicionar Perfil"
          icon={<NovaPermissaoIcon tipo="BUTTON_FAB" />}
          onClick={onClickAdd}
        />
      );
    } else {
      return (
        <ButtonFab
          id="tour-CadUsuarios"
          tooltip="Adicionar Novo Usuário"
          icon={<NovoUsuarioIcon tipo="BUTTON_FAB" />}
          onClick={onClickAddUsuario}
        />
      );
    }
  }, [PlanoComControleDeAcesso, abaSelecionada, onClickAdd, onClickAddUsuario]);

  const pageChangedPerfis = useCallback(
    async (newPage: number) => {
      if (newPage <= perfis.totalPages || newPage > 0) {
        handlePerfis(newPage);
      }
    },
    [perfis.totalPages, handlePerfis]
  );

  const pageChangedEmpresaUsuario = useCallback(
    async (newPage: number) => {
      if (newPage <= perfis.totalPages || newPage > 0) {
        handlePerfisEmpresasUsuario(newPage);
      }
    },
    [perfis.totalPages, handlePerfisEmpresasUsuario]
  );

  const component = useMemo(() => <Grid className={defClasses.root}>
    <Grid className={defClasses.header}>
      <PerfisHeader />
    </Grid>
    <Grid className={defClasses.list}>
      {PlanoComControleDeAcesso ? (
        <TabsSaurus
          selectedTabIndex={abaSelecionada}
          onChange={tabChange}
          tabsLabel={[
            new TabSaurusLabel('Perfil de acesso', 0),
            new TabSaurusLabel('Usuários', 1)
          ]}
          tabsContent={[
            new TabSaurusContent(
              0,
              (
                <Grid className={classes.contentTab}>
                  <Paginacao
                    pageChanged={pageChangedPerfis}
                    totalPages={perfis.totalPages}
                    totalRegisters={perfis.totalResults}
                    currentPage={perfis.page}
                  />
                  <PerfilListData
                    carregando={carregando}
                    list={perfis.list}
                    onCardChecked={onCardPerfilChecked}
                    onCardSelected={onCardPerfilSelected}
                    selectedList={selectedPerfilList}
                  ></PerfilListData>
                </Grid>
              )
            ),
            new TabSaurusContent(
              1,
              (
                <>
                  <Grid className={classes.contentTab}>
                    <Paginacao
                      pageChanged={pageChangedEmpresaUsuario}
                      totalPages={empresaUsuario.totalPages}
                      totalRegisters={empresaUsuario.totalResults}
                      currentPage={empresaUsuario.page}
                    />
                    <PerfiUsuariolListData
                      carregando={carregando}
                      usuario={empresaUsuario.list}
                      onCardChecked={onCardUsuarioChecked}
                      onCardSelected={onCardUsuarioSelected}
                      selectedList={selectedUsuarioList}
                    ></PerfiUsuariolListData>
                  </Grid>
                </>
              )
            )
          ]}
        />
      ) : (
        <>
          <Grid className={classes.contentTab}>
            <Paginacao
              pageChanged={pageChangedEmpresaUsuario}
              totalPages={empresaUsuario.totalPages}
              totalRegisters={empresaUsuario.totalResults}
              currentPage={empresaUsuario.page}
            />
            <PerfiUsuariolListData
              carregando={carregando}
              usuario={empresaUsuario.list}
              onCardChecked={onCardUsuarioChecked}
              onCardSelected={onCardUsuarioSelected}
              selectedList={selectedUsuarioList}
            ></PerfiUsuariolListData>
          </Grid>
        </>
      )}
      {retButton()}
    </Grid>
    <Grid xs={12} className={classes.limitPopUp}>
      <CardControleCotaUsuarios />
    </Grid>
  </Grid>, [PlanoComControleDeAcesso, abaSelecionada, carregando, classes.contentTab, classes.limitPopUp, defClasses.header, defClasses.list, defClasses.root, empresaUsuario.list, empresaUsuario.page, empresaUsuario.totalPages, empresaUsuario.totalResults, onCardPerfilChecked, onCardPerfilSelected, onCardUsuarioChecked, onCardUsuarioSelected, pageChangedEmpresaUsuario, pageChangedPerfis, perfis.list, perfis.page, perfis.totalPages, perfis.totalResults, retButton, selectedPerfilList, selectedUsuarioList, tabChange]);
  return component;
};

export default PerfisPage;
