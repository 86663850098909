import { Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-config-resumo-sessao-styles';
import { GestaoStorageKeys, useCadastros, useGestaoStorage } from 'services/app';
import { SwitchSaurus } from 'views/components/controles';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

export interface DialogConfigResumoSessaoProps {
  aberto: boolean;
  sessaoId: string;
}

export interface ModuloResumoSessaoProps {
  nome: string,
  selecionado: boolean,
  query: string,
  resumo: string
}

const opcoesPadrao: ModuloResumoSessaoProps[] = [{
  nome: 'Detalhado',
  query: 'detalhado',
  selecionado: true,
  resumo: 'Gerar a soma dos valores movimentados durante o período'
},
{
  nome: 'Detalhar Produtos',
  query: 'detalharProdutos',
  selecionado: true,
  resumo: 'Gerar resumo de todos os produtos vendidos durante o período'
},
{
  nome: 'Detalhar Vendedores',
  query: 'detalharVendedores',
  selecionado: true,
  resumo: 'Gerar resumo e detalhamento dos vendedores durante o período'
},
{
  nome: 'Detalhar Cancelamentos',
  query: 'detalharCancelamento',
  selecionado: true,
  resumo: 'Gerar detalhamento dos cancelamentos durante o período'
}
]

export const DialogConfigResumoSessao = ({
  aberto,
  sessaoId,
}: DialogConfigResumoSessaoProps) => {
  const { getRegistro, setRegistro } = useGestaoStorage()
  const classes = useStyles();
  const { fecharConfigResumoSessao } = useCadastros()

  const [opcoes, setOpcoes] = useState<ModuloResumoSessaoProps[]>(opcoesPadrao)

  useEffect(() => {
    const config = getRegistro(GestaoStorageKeys.ResumoSessao, false)
    if (isEmpty(config)) {
      setRegistro(GestaoStorageKeys.ResumoSessao, opcoesPadrao, false)
      setOpcoes(opcoesPadrao)
    } else {
      setOpcoes(config)
    }
  }, [getRegistro, setRegistro])


  const toogleHabilitado = (query: string) => {
    setOpcoes(opcoes => opcoes.map(opcao => {
      if (opcao.query === query) {
        return {
          ...opcao,
          selecionado: !opcao.selecionado
        }
      } return opcao
    }))
  }

  return (
    <DialogSaurus
      aberto={aberto}
      titulo="Configurar Resumo Sessão"
      tamanho="xs"
    >
      <Grid className={classes.cardContainer}>
        <Grid className={classes.cardContent}>
          <Typography variant='button' >Personalize o que deseja Imprimir no resumo da Sessão:</Typography>
          <Grid
            className={classes.opcoes}>
            {opcoes.map(opcao =>
              <Grid xs={12}
                className={classes.cardOpcao}
                key={opcao.query}
                container>
                <Grid xs={10}

                >
                  <Typography variant='h6'>{opcao.nome}</Typography>
                  <Typography variant='caption'>{opcao.resumo}</Typography>
                </Grid>
                <Grid xs={2}  >
                  <SwitchSaurus
                    variant="LIGHT"
                    onClick={() => {
                      toogleHabilitado(opcao.query)
                    }}
                    checked={opcao.selecionado}
                  />

                </Grid>

              </Grid>
            )}
          </Grid>
          <Grid className={classes.botoesGrid} xs={12}>
            <Grid xs={5}>
              <Button
                fullWidth
                size='medium'
                className={classNames('round', classes.botaoFechar)}
                rounded
                variant='outlined'
                color='primary'
                onClick={() => {
                  setRegistro(GestaoStorageKeys.PrimeiraImpressaoResumo, false, false)
                  fecharConfigResumoSessao(sessaoId)
                }}>
                Fechar
              </Button>
            </Grid>
            <Grid xs={5}>
              <Button
                fullWidth
                size='medium'
                rounded
                variant='contained'
                color='primary'
                onClick={() => {
                  setRegistro(GestaoStorageKeys.PrimeiraImpressaoResumo, false, false)
                  setRegistro(GestaoStorageKeys.ResumoSessao, opcoes, false)
                  fecharConfigResumoSessao(sessaoId)
                }}>
                Salvar
              </Button>
            </Grid>
          </Grid>


        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
