import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostProdutosByIds() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postProdutosByIds = useCallback(
    (empresaId: string, ids: string[]) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Produto/buscar-ids`,
        method: 'POST',
        data: JSON.stringify(ids),
        enviarTokenUsuario: true,
        headers: {
          'Content-type': 'application/json'
        }
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutosByIds
  };
}
