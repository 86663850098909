import { useCallback, useEffect, useRef } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useDefaultCardStyles } from '../default-card-styles'
import { AppEventEnum } from "model/enums/enum-app-event";

export const useCaptureCardNavigation = (index: number) => {

    const cardRef = useRef<HTMLDivElement>(null);
    const { addHandler, removeHandler } = useEventTools();
    const classes = useDefaultCardStyles();

    const highlightCard = useCallback((selected: number) => {
        if (!cardRef.current) return;
        if (selected === index) {
            cardRef.current.classList.add(classes.selected);
            cardRef.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        } else {
            cardRef.current.classList.remove(classes.selected);
        }
    }, [classes.selected, index])

    useEffect(() => {
        addHandler(AppEventEnum.KeyNavigationIndex, highlightCard)
        return () => {
            removeHandler(AppEventEnum.KeyNavigationIndex, highlightCard)
        }
    }, [addHandler, highlightCard, removeHandler])

    return {
        cardRef
    }
}