export enum TipoValorComissao {
  ValorFixoProduto,
  Porcentagem,
}

export interface KeyboardFragmentProps {
  valor: string | undefined,
  index: number;
  submit: () => Promise<boolean>;
  textChanged: (text: string, formattedText: string) => Promise<boolean>;
  tipo: TipoValorComissao;
}