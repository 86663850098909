import { CardModeloApuracao } from "views/components/cards/card-modelo-apuracao/card-modelo-apuracao"
import { Grid } from "views/design-system"
import { ValoresApuracaoProps } from "./valores-apuracao-props"
import { DinheiroIcon } from "views/components/icons";
import { SacoDinheiroIcon } from "views/components/icons/saco-dinheiro-icon";
import { useStyles } from "./valores-apuracao-styles";
import { EnumTpModeloApuracao } from "model/enums/enum-tp-modelo-apuracao";


export const ValoresApuracao = ({ modeloApuracao, onClick }: ValoresApuracaoProps) => {
    const classes = useStyles()

    const buttonsValorApura = [
        // {
        //   icon: (
        //     <ValorLucroIcon
        //       tipo="BUTTON"
        //       style={{
        //         width: '96px',
        //         height: '52px',
        //         margin: 0
        //       }}
        //     />
        //   ),
        //   label: 'Valor bruto',
        //   description: 'Recompensas baseadas no valor bruto das vendas realizadas.',
        //   type: EnumTpModeloApuracao.ValorBruto
        // },
        {
            icon: (
                <DinheiroIcon
                    tipo="BUTTON"
                    class={classes.icon}
                />
            ),
            label: 'Valor Vendido',
            description: 'Recompensas baseadas no valor total das vendas realizadas.',
            type: EnumTpModeloApuracao.ValorVendido
        },
        {
            icon: (
                <SacoDinheiroIcon
                    tipo="BUTTON"
                    class={classes.icon}
                />
            ),
            label: 'Lucro',
            description: 'Recompensas baseadas no lucro líquido gerado.',
            type: EnumTpModeloApuracao.Lucro
        }
    ];

    return (
        <Grid container spacing={1}>
            {buttonsValorApura.map((modelo) => (
                <Grid item xs={4}>
                    <CardModeloApuracao
                        key={modelo.label}
                        icone={modelo.icon}
                        label={modelo.label}
                        type={modelo.type}
                        description={modelo.description}
                        value={modeloApuracao}
                        onClick={onClick}
                    />
                </Grid>
            ))}
        </Grid>
    )
}