import useStyles from './card-produto-importacao-xml-styles';
import { CardProdutoEntradaXMLProps } from './card-produto-importacao-xml-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from '../../../../utils/to-decimal';
import { useThemeQueries } from '../../..';
import { guidEmpty } from 'utils/guid-empty';
import { Box, Grid, Typography, Tooltip } from 'views/design-system';
import { EditarIcon } from 'views/components/icons';

export const CardProdutoImportacaoXML = ({
  model,
  onClick: onClickProps,
  selected
}: CardProdutoEntradaXMLProps) => {
  const classes = useDefaultCardStyles();
  const cardProdutoImporcataoXMLClasses = useStyles();
  const { theme } = useThemeQueries();

  const title = model.produtoId === guidEmpty() ? 'Não Cadastrado' : 'Cadastrado';
  const vFinal =
    model.vProd + model.vFrete + model.vSeg + model.vOutro - model.vDesc;

  const background = model.produtoId === guidEmpty()
    ? theme.palette.info.main
    : theme.palette.success.main;

  const onClick = () => {
    if (onClickProps) {
      onClickProps(model.id);
    }
  };

  return (
    <DefaultCard
      className={cardProdutoImporcataoXMLClasses.content}
      isSelected={selected}
      onClick={async () => {
        onClick();
      }}
    >
      <Tooltip arrow title={title} placement="right">
        <div
          className={classes.tagStatus}
          style={{
            background
          }}
        />
      </Tooltip>

      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
        >
          <Typography color="textPrimary" variant="caption">
            Código
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>{model.cProd}</strong>
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
        >
          <Typography color="textPrimary" variant="caption">
            Qtd.
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>{toDecimalString(model.qCom, 3)}</strong>
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
        >
          <Typography color="textPrimary" variant="caption">
            Valor Total
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>R$ {toDecimalString(vFinal)}</strong>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          className={cardProdutoImporcataoXMLClasses.gridProdutoName}
        >
          <Typography color="textPrimary" variant="caption">
            Produto
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>{model.xProd}</strong>
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.cardRightButton}>
        <EditarIcon tipo='BUTTON' />
      </Box>
    </DefaultCard>
  );
};
