import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
    },
    containerBody: {
        display: "flex",
        position: "relative",
        width: "100%",
        padding: "0px",
        margin: "0px",
        flexGrow: 1,
        overflowY: 'auto',
    },
    flexContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        }
    },
    // containerBodyPDVHeight: {
    //     height: window.innerHeight - 70,
    //     [theme.breakpoints.down('sm')]: {
    //         height: window.innerHeight - 65,
    //     }
    // },
}));