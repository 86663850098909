import { useDefaultCadastroStyles } from "../cadastros/components/default-cadastro-styles";
import { ConvenioFaturaList } from "./components/convenio-fatura-list/convenio-fatura-list";
import { ConvenioFaturaHeader } from "./components/convenio-fatura-header/convenio-fatura-header";
import { useStyles } from "./convenio-fatura-styles";
import classNames from "classnames";
import { Box } from "views/design-system";

const ConvenioFaturaPage = () => {
  const defaultCadastrosClasses = useDefaultCadastroStyles();
  const classes = useStyles()

  return (
    <Box className={defaultCadastrosClasses.root}>
      <Box className={defaultCadastrosClasses.header}>
        <ConvenioFaturaHeader
        />
      </Box>
      <Box className={classNames(defaultCadastrosClasses.list, classes.list)}>
        <ConvenioFaturaList />
      </Box>
    </Box>
  );
};

export default ConvenioFaturaPage