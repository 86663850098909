
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { CartaoConvenioPostModel } from "model/api/gestao/convenio/cartao-convenio-post-model";

export function usePostCartaoConvenio() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postCartaoConvenio = useCallback(
    (cartaoConvenio: CartaoConvenioPostModel, empresaId: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/${cartaoConvenio.contaPessoaId}/cartao`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(cartaoConvenio),
        enviarTokenUsuario: true,
      })
    },
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    postCartaoConvenio,
  };
}
