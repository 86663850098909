import { Grid } from 'views/design-system';
import { useState, useEffect } from 'react';
import { RelatorioComponents, useRelatorioPerformance } from 'services/app/providers/relatorio-performance-provider/relatorio-performance-provider';
import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { CardComponentRelatorio } from '../card-component-relatorio/card-component-relatorio';

interface DialogPerformanceRelatorioProps {
    openned: boolean;
    closeModal: () => void;
    name: string;
}

export const DialogPerformanceRelatorio = ({
    openned,
    closeModal,
    name
}: DialogPerformanceRelatorioProps) => {

    const { rendersPorComponente } = useRelatorioPerformance();
    const classes = useDefaultCadastroStyles();


    const [list, setList] = useState<RelatorioComponents[]>([])

    useEffect(() => {
        const components = rendersPorComponente(name);
        setList(components)
    }, [name, rendersPorComponente])

    return (
        <>
            <DialogSaurus
                aberto={openned || false}
                titulo={name}
                handleClickClose={closeModal}
                isButtonTitleClose
                tamanho="lg"
            >
                <Grid className={classes.list} style={{ padding: '0 15px 20px 15px' }}>
                    {list.map(render => (
                        <Grid item xs={12}>
                            <CardComponentRelatorio model={render} />
                        </Grid>
                    ))}
                </Grid>
            </DialogSaurus>
        </>
    );
};