import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flowGrow: 1
    },
    header: {
        flex: "0 1 auto"
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    content: {
        display: "flex",
        justifyContent: 'center',
        overflow: 'auto',
        alignItems: 'center',
        height: '100%',
        padding: theme.spacing(1)
    },
    infoContainer: {
        display: "flex",
        flexDirection: 'column',
        maxWidth: 650,
        flex: 1
    },
    infoContainerPC: {
        display: "flex",
        flexDirection: 'column',
        maxWidth: 650,
        flex: 1,
    },
    infoCardContent: {
        background: '#FFFFFF',
        "box-shadow": "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 8px rgba(96, 97, 112, 0.16)",
        borderRadius: "8px",
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    textTitle: {
        fontWeight: 700,
        lineHeight: "42px",
        fontSize: "2rem",
        textAlign: "center",
        color: theme.palette.primary.main,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    textSubtitle: {
        textAlign: 'center',
        padding: 16,
        color: '#8D8D8D',
        lineHeight: '23px'
    },
    cardInfo: {
        width: "100%",
        padding: '16px'
    },
    label: {
        fontSize: '1rem',
        color: '#8D8D8D',
        lineHeight: '14,1px'
    },
    infoNomeEQuantidade: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '30px',
        maxWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    divider: {
        marginTop: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
    },
    infoTotal: {
        fontSize: '1.8rem',
        fontWeight: 700,
        marginTop: 8,
        lineHeight: '21px',
        color: '#4F4F4F',
    },
    buttonFinalizar: {
        marginBottom: theme.spacing(1),
        color: '#fff',
        backgroundColor: theme.palette.primary.main
    },
}));