import { EnumMovModelo } from "model";
import { VendaOffModel } from "model/api/gestao/venda/venda-off-model";

export const filterVendaOffLine = (vendas: VendaOffModel[], termo: string, status: number, dInicial: string, dFinal: string, nnf: string, tpMov: Array<EnumMovModelo | -1>) => {
    let vendasFiltradas: VendaOffModel[] = vendas;
    
    if (termo) {
        vendasFiltradas = vendasFiltradas.filter(venda => {
            return venda.serie.toString() === termo
        });
    }

    if (status !== -1) {
        vendasFiltradas = vendasFiltradas.filter(venda => {
            return venda.status === status
        });
    }

    if (dInicial) {
        vendasFiltradas = vendasFiltradas.filter(venda => {
            return new Date(venda.dEmi).getTime() > new Date(dInicial).getTime()
        });
    }

    if (dFinal) {
        vendasFiltradas = vendasFiltradas.filter(venda => {
            return new Date(venda.dEmi).getTime() < new Date(dFinal).getTime()
        });
    }

    if (nnf) {
        vendasFiltradas = vendasFiltradas.filter(venda => {
            return venda.nnf.toString() === termo
        });
    }

    if (tpMov) {
        vendasFiltradas = vendasFiltradas.filter(venda => {
            if (tpMov.some(x => x === -1)) {
                return venda.mod
            }
            return tpMov.includes(venda.mod)
        })
    }

    return vendasFiltradas;
}
