import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContent: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    icon: {
        width: 56,
        height: 56,
        margin: 0,
    },
    divider: {
        marginTop: theme.spacing(2)
    },
    title: {
        fontWeight: 700
    },
    contentQrCode: {
        width: '100%',
        '@media print': {
            height: '100vh',
            width: '100vw'
        }
    },
    ajustarTela: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        '@media print': {
            width: '100vw'
        },
    },
    qrCode: {
        height: '200px!important',
        width: '200px!important',
    }
}));
