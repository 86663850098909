import { memo } from 'react';
import { Typography } from '@material-ui/core';
import { CardDupPagamentoProps } from './card-dup-pagamento-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import classNames from 'classnames';
import { toDateString } from 'utils/to-date';
import { toDecimalString } from 'utils/to-decimal';

const CardDupPagamentoPotion = ({ model, onClick, index }: CardDupPagamentoProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick({ ...model, index });
        }}
      >
        <div className={classNames(classes.cardContent)} >
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Parcela
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {index + 1}
            </Typography>
          </div>
          <div className={classes.celulaGrid} style={{ minWidth: 105 }}>
            <Typography color="textPrimary" variant="caption">
              Data de Vencimento
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {!model?.dVenc ? 'Sem data' : toDateString(new Date(model?.dVenc) ?? '')}
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Valor
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              R$ {toDecimalString(model?.vDup ?? 0)}
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};

export const CardDupPagamento = memo(CardDupPagamentoPotion);
