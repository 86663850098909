import { Paper, useTheme } from '@material-ui/core';
import { CardDashboardChartVendedoresProps } from '../card-dashboard-chart-props';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { Box, Divider, Grid, Typography } from 'views/design-system';

export const CardDashboardChartVendedores = ({
  model,
  carregando
}: CardDashboardChartVendedoresProps) => {
  const utilClasses = useStyles();
  const theme = useTheme();

  let modUnicos = new Map(
    model.map((obj) => [obj.nome, obj]),
  );

  const modsUnicosList = Array.from(modUnicos.values());

  let vendedoresSerelizados = modsUnicosList.map((vendedores) => {
    return {
      ...vendedores,
      vTotal: 0,
      qVendas: 0
    };
  });

  vendedoresSerelizados = vendedoresSerelizados.map((item) => {
    model.map((i) => {
      if (i.nome === item.nome) {
        let novaQuantidade = (item.vTotal += i.vTotal);
        let novaQTDE = (item.qVendas += i.qVendas)
        return {
          ...item,
          vTotal: novaQuantidade,
          qVendas: novaQTDE
        };
      }
      return i;
    });
    return item;
  });

  const totalVendas = model.reduce((a, b) => a + b.vTotal, 0);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <Grid container >
          <Grid item xs={12} p={1}>
            <Typography
              color="primary"
              weight={500}
            >
              Top 10 Vendedores
            </Typography>
            <Divider variant='fullWidth' />
          </Grid>

          {model.length === 0 ? (
            <Grid item xs={12} flex flexDirection='column' my={2}>
              <Typography variant="body1" align='center' >
                Não há dados para exibir
              </Typography>
            </Grid>
          ) : (
            <>
              {vendedoresSerelizados
                .sort((a, b) => b.vTotal - a.vTotal)
                .filter((item, index) => index < 10)
                .map((item, index) => {
                  let dinheiro = item.vTotal
                  let vendas = item.qVendas
                  const venda = parseInt(dinheiro.toString());
                  const porcentagem = (
                    (venda * 100) /
                    parseInt(totalVendas.toString())
                  ).toFixed(0);

                  const options = {
                    chart: {
                      sparkline: {
                        enabled: true,
                        offsetY: -20,
                      },
                    },
                    plotOptions: {
                      radialBar: {
                        hollow: {
                          size: '65%',
                        },
                        dataLabels: {
                          name: {
                            show: true,
                            offsetY: 5,
                            fontSize: '14px',
                            fontWeight: 700,
                            color: '#666',
                            formatter: function (val: any) {
                              return `R$ ${val}`;
                            },
                          },
                          value: {
                            show: false,

                            formatter: function (val: any) {
                              return val;
                            },
                          },
                        },
                      },
                    },
                    labels: [String(toDecimalString(dinheiro, 2))],
                    colors: [
                      '#' +
                      ((Math.random() * 0xffffff) << 0)
                        .toString(16)
                        .padStart(6, '0'),
                    ],
                  };

                  return (
                    <Grid item xs={12}>
                      <Paper
                        key={index}
                        style={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                          position: 'relative',
                          overflow: 'hidden'
                        }}
                      >
                        <Box px={2} flex justifyContent='space-between' alignItems='center'>
                          <Box >
                            <Typography
                              weight={600}
                            >
                              {index + 1}°{' '}
                              {item.nome !== null ? item.nome : 'Sem nome'}
                            </Typography>
                            <Typography>
                              {vendas < 2 ? `${vendas} Venda` : `${vendas} Vendas`}
                            </Typography>
                          </Box>
                          {VariaveisAmbiente.paymentDevice === undefined ? (
                            <Box flex flexDirection='column' py={1} ml={1} >
                              <Typography
                                align='right'
                              >
                                R$ {String(toDecimalString(dinheiro, 2))}
                              </Typography>
                              <Typography
                                weight={500}
                                variant='caption'
                                align='right'
                              >
                                {porcentagem}%
                              </Typography>
                            </Box>
                          ) : (
                            <Chart
                              options={options}
                              series={[Number(porcentagem)]}
                              type="radialBar"
                              height={130}
                              width={130}
                            />
                          )}
                        </Box>
                        {VariaveisAmbiente.paymentDevice === undefined ? (
                          <div style={{
                            width: `${porcentagem}%`,
                            backgroundColor: `#${((Math.random() * 0xffffff) << 0)
                              .toString(16)
                              .padStart(6, '0')}`,
                            height: 5,
                            zIndex: '-1000'
                          }} />
                        ) : ""}
                      </Paper>
                    </Grid>
                  );
                })}
            </>
          )}
        </Grid>

      </Paper >
    </>
  );
};
