import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    icone: {
        marginLeft: theme.spacing(1),
        width: 32,
        height: 32,
    },
    iconeAcao: {
        width: 32,
        height: 32,
    },
    texto: {
        flex: 1,
        
    }
}));