import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { Pagamento } from './pagamento-modal/pagamento/pagamento';
import { PagCbPagamentoProps } from './pagamento-modal/pagamento/pagamento-props';

export interface DadosDoPagamentoModalProps extends ModalProps {
  pagCb?: PagCbPagamentoProps
  isPagFatura?: boolean
}

export const DadosDoPagamentoModal = (props: DadosDoPagamentoModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && <Pagamento pagCb={props.pagCb} isPagFatura={props.isPagFatura} />}
    </DefaultModal>
  );
};
