import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dividerVerticalRightArea: {
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  buttonMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  iconOptions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 45,
    height: 45,

    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
        fill: theme.palette.common.white,
    }
  },
  destaqueButton: {
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(1),
    justifyContent: 'center',
  }
}));