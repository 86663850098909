import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .Mui-focused': {
      background: 'rgba(0,0,0, 0.05)'
    },
    '& fieldset': {
      zIndex: 0,
      background: 'rgba(255,255,255, 0.1)',
    },

    '& .Mui-focused > fieldset': {
      background: 'rgba(255,255,255, 0.2) !important',
    },
    '& svg': {
      fill: theme.palette.grey[700]
    },
    '& input': {
      color: theme.palette.text.primary,
      fontWeight: 400,
      zIndex: 1,
      border: 'none !important',
    },
    '& input::placeholder': {
      color: theme.palette.grey[500]
    },
    color: theme.palette.grey[700],
  },
  textFieldMobile: {
    '& .Mui-endAdornment': {
      zIndex: 1,
    },
    '& fieldset': {
      zIndex: 0,
      background: 'rgba(255,255,255, 0.1)',
      border: '1px solid rgba(255,255,255, 0.2)',
    },
    '& .Mui-focused > fieldset': {
      background: 'rgba(255,255,255, 0.2) !important',
      border: `1px solid rgba(255,255,255, 0.3) !important`,
    },
    '& svg': {
      fill: theme.palette.common.white
    },
    '& input': {
      fontWeight: 400,
      color: theme.palette.primary.contrastText,
      zIndex: 1,
      border: 'none !important',
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
      },
    },
    '& input::placeholder': {
      color: theme.palette.grey[200]
    },
    color: theme.palette.common.white
  }
}));