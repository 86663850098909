import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { CardRelatoriosAvancados } from 'views/components/cards/card-relatorios-avancados';
import { RelatoriosAvancadosModel } from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';

export interface RelatoriosAvancadosGridProps {
    list: Array<RelatoriosAvancadosModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (relatorio: RelatoriosAvancadosModel) => any;
    onCardChecked: (id: string) => any;
}

export const RelatoriosAvancadosListData = ((props: RelatoriosAvancadosGridProps) => {

    const onCardSelected = (relatorio: RelatoriosAvancadosModel) => {
        props.onCardSelected(relatorio);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhum relatório encontrado." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((categoria, index) => {
                return (
                    <CardRelatoriosAvancados
                        selected={
                            props.selectedList.filter((item) => item === categoria.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={categoria}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
