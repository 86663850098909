
import { EditarIcon } from "views/components/icons";
import { useDefaultCardStyles } from "../components";
import { CardVolumeTransportadoProps } from "./card-volume-transportado-props";
import { DefaultCard } from "../components/default-card"
import { toDecimalString } from "utils/to-decimal";
import { Grid, Tooltip, Typography } from "views/design-system";
import { useThemeQueries } from "views/theme";


export const CardVolumeTransportado = ({ model, onClick, onCheck, selected }: CardVolumeTransportadoProps) => {
  const {isMobile} = useThemeQueries()
  const defaultCardClasses = useDefaultCardStyles();


  return (
    <>
      <DefaultCard isSelected={selected}
        onClick={async () => {
          await onClick(model);
        }}>
        <Grid container className={defaultCardClasses.cardContent}>
          <Grid item xs={4} md={2} pl={1}
            
          >
            <Typography color="textPrimary" variant="caption">
              Qtd.
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.qVol}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} pl={1}
            
          >
            <Typography color="textPrimary" variant="caption">
              Espécie
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.esp}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} pl={1}
            
          >
            <Typography color="textPrimary" variant="caption">
              Número
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.nVol}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} pl={1}
            
          >
            <Typography color="textPrimary" variant="caption">
              Marca
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.marca}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} pl={1}
          >
            <Typography color="textPrimary" variant="caption">
              Peso L
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model.pesoL, 4)}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} pl={1}
            
          >
            <Typography color="textPrimary" variant="caption">
              Peso B
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model.pesoB, 4)}
            </Typography>
          </Grid>
        </Grid>
        {!isMobile &&
        <Tooltip arrow title="Editar volume">
          <div className={defaultCardClasses.cardRightButtonProcesso}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
        
        }
      </DefaultCard>
    </>
  );
};

