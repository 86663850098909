import { Button, Grid, Typography } from 'views/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGestaoToken, useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components';
import { PublicPageHeader } from 'views/components/headers';
import { AtualizarIcon, AvancarIcon, SucessoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { usePostCompartilhamentoDadosAceitar } from './../../../../data/api/gestao/compartilhamento-dados/post-compartilhamento-dados-aceitar';
import { useStyles } from './aceitar-compartilhamento-styles'
import { useSessaoAtual } from 'services/app';

const AceitarCompartilhamento = () => {

    const { postCompartilhamentoDadosAceitar, carregando } = usePostCompartilhamentoDadosAceitar()
    const { showToast } = useToastSaurus()
    const classes = useStyles()
    const { theme } = useThemeQueries()
    const [erro, setErro] = useState<boolean>(false)
    const [textErro, setTextErro] = useState<string>('')


    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)

    const params = urlParams.get('chave')
    const { usuario } = useSessaoAtual()
    const { isTokenExpired } = useGestaoToken()
    const history = useHistory()
    const fullUrl = window.location.href

    const aceitarCompartilhamento = useCallback(async () => {
        try {
            setErro(false)
            const res = await postCompartilhamentoDadosAceitar(params ?? '')

            if (res.erro) throw res.erro
        } catch (e: any) {
            setErro(true)
            setTextErro(e.message)
            showToast('error', e.message)
        }
    }, [params, postCompartilhamentoDadosAceitar, showToast])

    useEffect(() => {
        if (isTokenExpired(usuario)) {
            return history.push(`/login?callbackUrl=${btoa(fullUrl)}`)
        }
        aceitarCompartilhamento()
    }, [aceitarCompartilhamento, fullUrl, history, isTokenExpired, usuario])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <Grid className={classes.container}>
                <Grid className={classes.content}>
                    {carregando ? (
                        <CircularLoading tipo='FULLSIZED' />
                    ) : (
                        <Grid className={classes.infoContainer}>
                            {!erro ? (
                                <Grid>
                                    <Typography className={classes.textTitle}>
                                        Compartilhamento Aceito
                                    </Typography>
                                    <Grid className={classes.svgSuccess}>
                                        <SucessoIcon style={{
                                            width: '170px'
                                        }} fill={theme.palette.primary.main} />
                                    </Grid>
                                    <div style={{ display: 'flex', marginTop: 16, justifyContent: 'center', width: '100%' }}>
                                        <Button
                                            onClick={() => history.push('/')}
                                            variant='outlined'
                                            color="primary"
                                            fullWidth
                                            style={{ maxWidth: 300 }}
                                            size="large"
                                        >
                                            <AvancarIcon tipo="BUTTON" /> Continuar
                                        </Button>
                                    </div>
                                </Grid>
                            ) : (
                                <>
                                    <Typography className={classes.textTitle}>
                                        Aceitar Compartilhamento
                                    </Typography>
                                    <Typography className={classes.textSubtitle}>
                                        Houve um erro ao aceitar o compartilhamento. <br /> Detalhe: {textErro}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        <Button variant='contained' color="primary" size="large" onClick={aceitarCompartilhamento}>
                                            <AtualizarIcon tipo='BUTTON_PRIMARY' /> Tentar novamente
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default AceitarCompartilhamento