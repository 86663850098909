import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    '& form': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  containerDesktop: {
    padding: theme.spacing(0, 2),
    paddingRight: 0,
    maxWidth: 400,
    float: 'right',
    width: '100%'
  }
}));
