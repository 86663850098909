import { SelecaoTipoPessoa } from './components/selecao-tipo-pessoa.tsx';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { useCadastros } from 'services/app';

interface StepComissionadosProps {
  comissaoId: string
}

export const ComissaoComissionados = ({ ...props
}: StepComissionadosProps) => {

  // HOOKS
  const { abrirComissaoComissionados } = useCadastros()

  const handleClickTipoPessoa = (tipo: EnumTpPessoaComissionamento) => {
    abrirComissaoComissionados(props.comissaoId, tipo, '', true)
  };

  return (
    <SelecaoTipoPessoa onClick={handleClickTipoPessoa} />
  );
};
