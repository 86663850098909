
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { ConvenioCartaoAutenticarModel } from "model/api/gestao/convenio";

export function usePostConvenioAutenticar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postConvenioAutenticar = useCallback(
        (auth: ConvenioCartaoAutenticarModel, empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/autenticar`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(auth),
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postConvenioAutenticar,
    };
}
