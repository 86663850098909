import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  const heightLinha = "25px";
  const heightFavorita = "40px";
  return ({
    root: {
      position: "relative",
      width: "100%",
      paddingLeft: 20,
      height: "50px",
      cursor: "pointer",
      userSelect: "none",
      '@media (hover: hover)': {
        "&:hover": {
          backgroundColor: theme.palette.grey[200]
        },
      },
      "&:active": {
        backgroundColor: theme.palette.grey[300]
      }
    },
    iconContainer: {
      minWidth: theme.spacing(7),
      flexShrink: 0,
      display: "inline-flex"
    },
    favoriteRoot: {
      position: "relative",
      width: "100%",
      paddingLeft: 25,
      height: "60px",
      cursor: "pointer",
      userSelect: "none",
      '@media (hover: hover)': {
        "&:hover": {
          backgroundColor: theme.palette.grey[200]
        },
      },
      "&:active": {
        backgroundColor: theme.palette.grey[300]
      }
    },
    text: {
      marginLeft: "-10px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: 450,
      fontSize: "15px",
      color: theme.palette.text.primary,
    },
    icon: {
      fill: theme.palette.primary.main,
      width: heightLinha,
      height: heightLinha,
    },
    favoriteIcon: {
      width: heightFavorita,
      height: heightFavorita,
      fill: theme.palette.primary.main,
      marginLeft: -10
    }
    ,
    favoriteText: {
      color: theme.palette.primary.main,
      fontSize: "17px",
      fontWeight: 650,
    },
    containerNomeDescricao: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'left',
      flexDirection: 'column',
    },
    containerDecricaoModulo: {
      marginTop: '0px'
    },
    descricaoText: {
      fontSize: 10,
      marginLeft: -10,
      marginTop: -5,
      fontWeight: 500,
      color: theme.palette.text.secondary
    }
  })
});
