import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";

export const ConvenioFaturaHeader = () => {

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  // const history = useHistory();

  // const [openTextfield, setOpenTextfield] = useState<boolean>(true);

  // const urlParams = new URLSearchParams(history.location.search)
  // const filtros = {
  //   status: urlParams.get('status'),
  //   termo: urlParams.get('termo')
  // }

  const leftArea = useCallback(
    () => (
      isMobile ?
        <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
        null
    ),
    [isMobile, abrirMenu]
  );

  // const rightArea = useCallback(
  //   () => (
  //     <ButtonPrivateHeader
  //       icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
  //       tooltip='Pesquisar'
  //       onClick={() => setOpenTextfield(true)}
  //     ></ButtonPrivateHeader>
  //   ),
  //   []
  // );

  // const searchTextfield = (
  //   <FormPesquisaHeader
  //     text={filtros.termo || ''}
  //     closeSearch={() => setOpenTextfield(false)}
  //     onSubmit={(model) => {
  //       const searchs: Array<string | null> = [
  //         model.generico.length > 0 ? `termo=${model.generico}` : null,
  //       ]

  //       let query = ''

  //       searchs
  //         .filter(x => x)
  //         .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

  //       history.push({
  //         pathname: '/depositos',
  //         search: query
  //       })
  //     }}
  //     placeholder="Buscar depósito pelo nome..."
  //   />
  // )

  return (
    <>
      <PrivatePageHeader
        title="Gestão de Faturas"
        leftArea={leftArea()}
      // middleArea={openTextfield && searchTextfield}
      // rightArea={!openTextfield && rightArea()}
      />
    </>
  );
};
