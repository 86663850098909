import { CircularLoading } from "views/components/utils";
import classNames from "classnames";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";
import { Box } from "views/design-system";
import { PromocaoRegraEdicaoListagem } from "./components/promocao-regra-listagem/promocao-regra-listagem";
import { useStyles } from "./promocao-regra-edicao-styles";
import { useModalStyles } from "../../utils/modal-styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCadastros, useToastSaurus } from "services/app";
import { usePutPromocaoRegra } from "data/api/gestao/promocao/put-promocao-regra";
import { useGetPromocaoRegrasById } from "data/api/gestao/promocao/get-promocao-regras-by-id";
import { usePromocaoRegraStore } from "../components/promocao-regra-store";
import { isEmpty } from "lodash";
import { PromocaoRegraModel } from "model/api/gestao/promocao/promocao-regra-model";
import { PromocaoRegraDetalheModel } from "model/api/gestao/promocao/promocao-regra-detalhes-model";
import { PromocaoRegraDetalheItemModel } from "model/api/gestao/promocao/promocao-regra-detalhes-item-model";
import { PromocaoRegraEdicaoItem } from "./components/promocao-regra-item/promocao-regra-edicao-item";
import { useGetProdutoVariacao } from "data/api/gestao/produto/produto-variacao/get-produto-variacao";
import { ProdutoVariacaoModel } from "model/api/gestao/produto/produto-variacao/produto-variacao";
import { usePostPromocaoRegra } from "data/api/gestao/promocao/post-promocao-regra";
import { guidEmpty } from "utils/guid-empty";
import { PromocaoTipoRegraMock } from "data/mocks/promocao-mock";
import { useConfirm } from "material-ui-confirm";
import { toDecimal, toDecimalString } from "utils/to-decimal";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { ProdutoResumidoModel } from "model/api/gestao/produto/produto/produto-resumido-model";
import { newGuid } from "utils/new-guid";

export interface PromocaoRegraEdicaoProps {
    id: string;
    tipoRegra: EnumPromocaoTipoRegra;
    promocaoId: string;
}

enum EnumTelaEdicaoProduto {
    Inicial,
    Listagem,
    EdicaoItem
}

export const PromocaoRegraEdicao = (props: PromocaoRegraEdicaoProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const confirm = useConfirm();

    const { showToast } = useToastSaurus();
    const { carregando: carregandoPutPromocaoRegra, putPromocaoRegra } = usePutPromocaoRegra();
    const { carregando: carregandoPostPromocaoRegra, postPromocaoRegra } = usePostPromocaoRegra();
    const { carregando: carregandoGetById, getPromocaoRegraById } = useGetPromocaoRegrasById()
    const { carregando: carregandoGetVariacao, getProdutoVariacao } = useGetProdutoVariacao();
    const { abrirProdutoSelecionarModal, fecharProdutoSelecionarModal } = useFiltrosModais();
    const { fecharCadastroPromocaoRegra } = useCadastros()
    const { setRegra, resetRegra, deleteRegraDetalhes, setRegraDetalhe, regraState } = usePromocaoRegraStore();

    //STATES
    const filtrarProdutosNovosNaEdicao = useRef<Boolean>(false);
    const [carregandoManual, setCarregandoManual] = useState<Boolean>(true);
    const [produtos, setProdutos] = useState<ProdutoVariacaoModel[]>([]);
    const [passo, setPasso] = useState<EnumTelaEdicaoProduto | undefined>(undefined)
    const [edicaoItem, setEdicaoItem] = useState({
        item: new PromocaoRegraDetalheItemModel(),
        detalhe: new PromocaoRegraDetalheModel(),
        index: -1,
        total: -1,
        produtoVariacao: new ProdutoVariacaoModel(),
    });
    //CONSTANTES
    const carregando = carregandoManual || carregandoGetById || carregandoPutPromocaoRegra || carregandoGetVariacao || carregandoPostPromocaoRegra;
    const nomeRegra = PromocaoTipoRegraMock.find(x => x.Key === props.tipoRegra)?.Value ?? 'Nova Regra';

    const salvarRegra = useCallback(async () => {
        try {
            const regra = usePromocaoRegraStore.getState().regraState;
            if (regra.id === guidEmpty()) {
                const res = await postPromocaoRegra(regra);
                if (res.erro) throw res.erro;
            } else {
                const res = await putPromocaoRegra(regra);
                if (res.erro) throw res.erro;
            }

            showToast("success", "Campanha atualizada com sucesso!",);
            fecharCadastroPromocaoRegra();
            return true;
        } catch (e: Error | any) {
            showToast('error', e.message);
            return false;
        }
    }, [fecharCadastroPromocaoRegra, postPromocaoRegra, putPromocaoRegra, showToast]);

    const definirPasso = useCallback((passo: EnumTelaEdicaoProduto) => {
        setPasso(passo);
    }, []);

    const retornaTodosItens = useCallback(() => {
        let detalhes = usePromocaoRegraStore.getState().regraState.detalhes;
        if (filtrarProdutosNovosNaEdicao.current) {
            detalhes = detalhes.filter(x => x.adicionado);
        }
        return detalhes.flatMap(x => x.itens);
    }, []);

    const retornaIndexETotal = useCallback((item: PromocaoRegraDetalheItemModel) => {
        const totalItens = retornaTodosItens();
        return {
            total: totalItens.length,
            index: totalItens.findIndex(x => x.id === item.id)
        }

    }, [retornaTodosItens]);

    const retornaProduto = useCallback(async (produtos: ProdutoVariacaoModel[], item: PromocaoRegraDetalheItemModel) => {
        let produto = produtos.find(x => x.produtoId === item.produtoId && x.id === item.variacaoId);
        if (produto === undefined) {
            const resProduto = await getProdutoVariacao(item.produtoId, item.variacaoId);
            if (resProduto.erro) throw new Error('Não foi possível identificar o Produto. Detalhe: ' + resProduto.erro.message);
            produto = resProduto.resultado?.data;

            if (produto)
                setProdutos([...produtos, produto]);
        }
        return produto;
    }, [getProdutoVariacao]);

    const editarProduto = useCallback(async (item: PromocaoRegraDetalheItemModel, detalhe: PromocaoRegraDetalheModel) => {
        try {
            const { index, total } = retornaIndexETotal(item);
            let produto = await retornaProduto(produtos, item);
            setEdicaoItem({ item, detalhe, index, total, produtoVariacao: produto ?? new ProdutoVariacaoModel() });
            definirPasso(EnumTelaEdicaoProduto.EdicaoItem);

        } catch (e: Error | any) {
            showToast('error', e.message);
        }
    }, [definirPasso, produtos, retornaIndexETotal, retornaProduto, showToast]);

    const adicionarProdutos = useCallback(() => {
        const ids = regraState.detalhes.flatMap(x => x.itens).map(x => x.produtoId);
        ids.push(...regraState.detalhes.flatMap(x => x.itens).map(x => x.variacaoId));

        abrirProdutoSelecionarModal({
            adicionar: (produtos: ProdutoResumidoModel[]) => {
                //ADICIONAR OS PRODUTOS PARA FAZERMOS OS NOVOS DETALHES E ITENS

                const regra = usePromocaoRegraStore.getState().regraState;
                produtos.forEach(prod => {
                    const detalheId = newGuid();
                    regra.detalhes.push({
                        ativo: true,
                        id: detalheId,
                        itens: [{
                            id: newGuid(),
                            produtoId: prod.produtoId,
                            variacaoId: prod.produtoGradeId,
                            vCadastro: prod.vPreco,
                            ativo: true,
                            codigo: null,
                            detalheId: detalheId,
                            produto: prod.nome,
                            variacao: ''
                        }],
                        limiteOcorrencia: 0,
                        quantidade: 0,
                        regraId: regra.id,
                        //QUANDO FOR PROMOCOES % VAI ENTRAR COM VALOR ZERO, SENAO ENTRA COM O VALOR DE CADASTRO DO PRODUTO
                        valor: regra.tipoRegra === EnumPromocaoTipoRegra.AtacadoPerc ? 0 : prod.vPreco,
                        adicionado: true,
                        alterado: true,
                        validado: false
                    });
                });

                fecharProdutoSelecionarModal();

                filtrarProdutosNovosNaEdicao.current = true;
                const firstEdit = regra.detalhes.filter(x => x.adicionado)[0]
                setRegra(regra);

                editarProduto(firstEdit.itens[0], firstEdit);
            },
            voltar: () => {
                fecharProdutoSelecionarModal();
                if (regraState.detalhes.length === 0) {
                    fecharCadastroPromocaoRegra();
                }
                definirPasso(EnumTelaEdicaoProduto.Listagem);
            },
            idsBloqueados: ids
        });
    }, [abrirProdutoSelecionarModal, definirPasso, editarProduto, fecharCadastroPromocaoRegra, fecharProdutoSelecionarModal, regraState.detalhes, setRegra]);

    const salvarItem = useCallback((detalhe: PromocaoRegraDetalheModel, item: PromocaoRegraDetalheItemModel) => {
        detalhe.alterado = true;
        detalhe.validado = true;
        const indexItem = detalhe.itens.findIndex(x => x.id === item.id);
        if (indexItem > -1) {
            detalhe.itens[indexItem] = item;
        } else {
            detalhe.itens.push(item);
        }

        setRegraDetalhe(detalhe);
    }, [setRegraDetalhe]);

    const confirmarTodosItens = useCallback(async (
        detalheEntrada: PromocaoRegraDetalheModel,
        itemEntrada: PromocaoRegraDetalheItemModel,
        percDescAplicado: number,
        itens: PromocaoRegraDetalheItemModel[],
    ) => {
        try {
            const regra = usePromocaoRegraStore.getState().regraState;
            setCarregandoManual(true);

            for (let i of itens) {
                const detalhe = regra.detalhes.find(x => x.id === i.detalheId);
                if (!detalhe)
                    continue;

                let produto = await retornaProduto(produtos, i);
                if (produto?.precos?.length === 0)
                    continue;

                const vPreco = produto?.precos[0].vPreco ?? 0;
                if (vPreco <= 0)
                    continue;

                if (regra.tipoRegra === EnumPromocaoTipoRegra.AtacadoPerc ||
                    regra.tipoRegra === EnumPromocaoTipoRegra.AtacadoValor) {
                    detalhe.quantidade = detalheEntrada.quantidade;
                }

                if (regra.tipoRegra === EnumPromocaoTipoRegra.AtacadoPerc)
                    detalhe.valor = percDescAplicado;

                if (regra.tipoRegra === EnumPromocaoTipoRegra.AtacadoValor ||
                    regra.tipoRegra === EnumPromocaoTipoRegra.DePor)
                    detalhe.valor = vPreco - toDecimal(vPreco * (percDescAplicado / 100), 2);
            }

            setRegra(regra);
            if (!await salvarRegra()) {
                definirPasso(EnumTelaEdicaoProduto.Listagem);
                return;
            }
        } catch (e: Error | any) {
            showToast('error', 'Erro ao salvar os produtos. Detalhe: ' + e.message);
        } finally {
            setCarregandoManual(false);
        }

    }, [definirPasso, produtos, retornaProduto, salvarRegra, setRegra, showToast]);

    const proximoItem = useCallback(async (
        detalhe: PromocaoRegraDetalheModel,
        item: PromocaoRegraDetalheItemModel,
        percDescAplicado: number) => {
        salvarItem(detalhe, item);

        const { index, total } = retornaIndexETotal(item);
        const itens = retornaTodosItens();

        const confirmarProduto = async () => {
            if (index < total - 1) {
                const proximoItem = itens[index + 1];
                const proximoDetalhe = usePromocaoRegraStore.getState().regraState.detalhes.filter(x => x.itens.findIndex(f => f.id === proximoItem.id) > -1);
                await editarProduto(proximoItem, proximoDetalhe[0]);
            } else {
                //SE ACABOU O PASSO A PASSO, VOLTO PARA A LISTAGEM
                if (filtrarProdutosNovosNaEdicao.current) {
                    if (!await salvarRegra()) {
                        definirPasso(EnumTelaEdicaoProduto.Listagem);
                        return;
                    }
                    filtrarProdutosNovosNaEdicao.current = false;
                } else {
                    definirPasso(EnumTelaEdicaoProduto.Listagem);
                }
            }
        };
        //SE TIVER FILTRANDO SOMENTE PRODUTOS NOVOS E INDICE FOR == 0, VOU PERGUNTAR SE QUER APLICAR O MESMO % DE DESCONTO
        if (filtrarProdutosNovosNaEdicao.current && index === 0 && total > 1) {
            const texto =
                `de ${toDecimalString(percDescAplicado, 2)}% de desconto` +
                ((regraState.tipoRegra === EnumPromocaoTipoRegra.AtacadoPerc || regraState.tipoRegra === EnumPromocaoTipoRegra.AtacadoValor) ?
                    ` com Mínimo de ${toDecimalString(detalhe.quantidade, 0)} Produtos` :
                    '');

            await confirm({
                title: 'Sim, Replicar Condição',
                description:
                    `Você selecionou outros ${total - 1} produtos para a regra ${nomeRegra}. Deseja replicar a condição ${texto} para eles?`,
                cancellationText: 'Não'
            }).then(async () => {
                await confirmarTodosItens(detalhe, item, percDescAplicado, itens);
            }
            ).catch(async () => {
                await confirmarProduto();

            });
            return;
        }
        await confirmarProduto();
    }, [confirm, confirmarTodosItens, definirPasso, editarProduto, nomeRegra, regraState.tipoRegra, retornaIndexETotal, retornaTodosItens, salvarItem, salvarRegra]);

    const voltarItem = useCallback(async (detalhe: PromocaoRegraDetalheModel, item: PromocaoRegraDetalheItemModel) => {
        salvarItem(detalhe, item);

        const { index } = retornaIndexETotal(item);
        const itens = retornaTodosItens();

        if (index > 0) {
            const proximoItem = itens[index - 1];
            const proximoDetalhe = regraState.detalhes.filter(x => x.itens.findIndex(f => f.id === proximoItem.id) > -1);
            await editarProduto(proximoItem, proximoDetalhe[0]);
        }
    }, [editarProduto, regraState.detalhes, retornaIndexETotal, retornaTodosItens, salvarItem]);

    const preencherRegra = useCallback(async () => {
        try {
            if (isEmpty(props.id)) {
                resetRegra(props.promocaoId, props.tipoRegra);
                setCarregandoManual(false);
                definirPasso(EnumTelaEdicaoProduto.Inicial);
                return;
            }

            const res = await getPromocaoRegraById(props.promocaoId, props.id);
            if (res.erro) throw res.erro;
            const regra = (res.resultado?.data || new PromocaoRegraModel()) as PromocaoRegraModel;
            regra.detalhes.forEach(x => { x.adicionado = false; x.alterado = false; x.validado = true; });
            setRegra(regra);
            setCarregandoManual(false);

            definirPasso(EnumTelaEdicaoProduto.Inicial);
        }
        catch (e: Error | any) {
            showToast('error', e.message);
        }
        finally {
            setCarregandoManual(false);
        }
    }, [definirPasso, getPromocaoRegraById, props.id, props.promocaoId, props.tipoRegra, resetRegra, setRegra, showToast]);

    useEffect(() => {
        preencherRegra();
    }, [preencherRegra]);

    useEffect(() => {
        const regra = usePromocaoRegraStore.getState().regraState;
        if (regra && passo === EnumTelaEdicaoProduto.Inicial) {
            definirPasso(EnumTelaEdicaoProduto.Listagem);
            if (regra.detalhes.length === 0) {
                adicionarProdutos();
            }
        }
    }, [adicionarProdutos, definirPasso, passo]);

    return (
        <>
            {carregando && (
                <Box id="carregandoEdicao" className={classes.loadingContainer}>
                    <CircularLoading tipo="FULLSIZED" />
                </Box>
            )}
            <Box className={classNames(modalClasses.root, classes.root)}>
                {(passo === EnumTelaEdicaoProduto.Listagem) && (
                    <PromocaoRegraEdicaoListagem
                        adicionarProdutos={adicionarProdutos}
                        carregando={carregando}
                        deletarDetalhe={deleteRegraDetalhes}
                        editarProduto={editarProduto}
                        id={props.id}
                        regra={props.tipoRegra}
                        model={regraState.detalhes}
                        nomeRegra={nomeRegra}
                        salvarRegra={salvarRegra}
                        voltar={() => fecharCadastroPromocaoRegra()}
                    />
                )}
                {passo === EnumTelaEdicaoProduto.EdicaoItem && (
                    <PromocaoRegraEdicaoItem
                        carregando={carregando}
                        id={props.id}
                        regra={props.tipoRegra}
                        nomeRegra={nomeRegra}
                        voltar={() => definirPasso(EnumTelaEdicaoProduto.Listagem)}
                        detalhe={edicaoItem.detalhe}
                        item={edicaoItem.item}
                        index={edicaoItem.index}
                        total={edicaoItem.total}
                        variacaoProduto={edicaoItem.produtoVariacao}
                        proximoItem={proximoItem}
                        voltarItem={voltarItem}
                    />
                )}
            </Box>
        </>
    );
};

