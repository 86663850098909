import { Grid } from 'views/design-system';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { useStyles } from './comandas-mesa-list-data-styles';
import { CardComandaNaMesaAtendimento } from 'views/components/cards/card-comanda-na-mesa-atendimento/card-comanda-na-mesa-atendimento';
import { CardVincularComanda } from 'views/components/cards/card-vincular-comanda/card-vincular-comanda';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';

export interface ComandasMesaListDataProps {
  comandas: Array<PedidoModelComanda>;
  carregando: boolean;
  onCardSelected: (comanda: PedidoModelComanda) => any;
  onCardHolded: (pedido: PedidoModel) => void;
  codigoMesa: string;
  onClickVincularComanda: () => void;
}

export const ComandasMesaListData = (props: ComandasMesaListDataProps) => {
  // HOOKS
  const { getPDV, getConfigByCod } = usePDV();
  const modeloTrabalho = getConfigByCod(101) as EnumModeloDeTrabalho;

  // AUX
  const classes = useStyles();
  const isApenasCaixa =
    getPDV()?.configuracoes.find((p) => p.cod === 101)?.vConfig ===
    EnumModeloDeTrabalho.APENAS_CAIXA;

  const onCardSelected = (comanda: PedidoModelComanda) => {
    props.onCardSelected(comanda);
  };

  const onCardHolded = async (pedido: PedidoModel) => {
    props.onCardHolded(pedido);
  };

  return (
    <>
      <Grid item xs={12} spacing={2} className={classes.containerListCard}>
        <>
          {props.comandas.length > 0 &&
            props.comandas.map((comanda) => {
              return (
                <CardComandaNaMesaAtendimento
                  model={comanda}
                  key={comanda.id}
                  onClick={onCardSelected}
                  codigoMesa={props.codigoMesa}
                  onHold={onCardHolded}
                  modeloTrabalho={modeloTrabalho}
                />
              );
            })}
          {!isApenasCaixa && <CardVincularComanda onClick={props.onClickVincularComanda} />}
        </>
      </Grid>
    </>
  );
};
