import { Box, Typography } from 'views/design-system';
import { ConfirmadoIcon } from 'views/components/icons/confirmado-icon';
import { useThemeQueries } from 'views/theme';

interface GerarComandaProps {
    fase: number;
    label: string;
}

export const GerarComandaConfirmacao = ({ fase, label }: GerarComandaProps) => {

    const { theme, xs } = useThemeQueries();

    return (
        <Box flexDirection='column' flex justifyContent='center' alignItems='center' style={{ flexGrow: 1, marginBottom: xs ? '100px' : undefined, gap: 16 }} >
            <ConfirmadoIcon tipo='GERAL' viewBox='0 0 138 138' style={{ width: 130, height: 138, fill: theme.palette.primary.main }} />
            <Typography variant='h4' align='center' color='primary'>{label}</Typography>
        </Box>
    );
};