import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses, useThemeQueries } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormProcessosNovaValidation } from './form-processos-novo-validation';
import { picker } from 'utils/picker';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { StatusProcessoTipoSelect } from 'data/mocks/status-processo-tipo-mock';
import { ProcessosFormModel } from 'model/api/gestao/processos/processos-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useGetEquipamentos } from 'data/api/gestao/equipamento';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import { KeyValueModel } from 'model';
import { FecharIcon } from 'views/components/icons';
import { useStyles } from './form-processos-novo-styles';
import { EnumStatusProcessoTipo } from 'model/enums/enum-processo-tipo';
import { Link } from 'react-router-dom';

export const FormProcessosNovo = forwardRef<
  DefaultFormRefs<ProcessosFormModel>,
  DefaultFormProps<ProcessosFormModel>
>((props: DefaultFormProps<ProcessosFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormProcessosNovaValidationYup } = useFormProcessosNovaValidation();
  const [model, setModel] = useState<ProcessosFormModel>(
    new ProcessosFormModel()
  );
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { getEquipamentos, carregando: carregandoGet } = useGetEquipamentos();
  const { fecharCadastroSetores, abrirCadastroEquipamentos } = useCadastros()
  const [equipamentosSelecionados, setEquipamentosSelecionados] = useState<
    Array<string>
  >(props.model?.equipamentosId ?? []);
  const [equipamentosMock, setEquipamentosMock] = useState<
    Array<KeyValueModel>
  >([]);
  const { theme } = useThemeQueries();
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
  } = useForm<ProcessosFormModel>({
    defaultValues: { ...model, status: EnumStatusProcessoTipo.EmProducao },
    resolver: yupResolver(FormProcessosNovaValidationYup),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const mock: Array<KeyValueModel> = equipamentosMock?.filter(
    (x) => !equipamentosSelecionados.includes(x.Key)
  );

  const onSubmit = (values: ProcessosFormModel) => {
    const processo = picker<ProcessosFormModel>(values, model);
    processo.equipamentosId = equipamentosSelecionados;

    props.onSubmit(processo);
  };

  const getEquipamentosWrapper = useCallback(async () => {
    const res = await getEquipamentos(getEmpresaSelecionada()?.Id ?? '');

    if (res.erro) throw res.erro;

    if (!res.resultado) {
      return [];
    }

    return res.resultado.data?.list as Array<ImpressoraModel>;
  }, [getEmpresaSelecionada, getEquipamentos]);

  useEffect(() => {
    (async () => {
      try {
        const equipamentosArray = await getEquipamentosWrapper();
        setEquipamentosMock(
          equipamentosArray.map((x) => new KeyValueModel(x.id, x.descricao))
        );
      } catch (err: any) {
        showToast('error', err.message);
      }
    })();
  }, [getEquipamentosWrapper, showToast]);

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset(new ProcessosFormModel());
    },
    fillForm: (value: ProcessosFormModel) => {
      setModel({ ...value });
      reset({ ...value });
    }
  }));

  const removeEquipamento = useCallback(
    (value: string) => {
      const modulo = equipamentosSelecionados.filter((item) => item !== value);
      setEquipamentosSelecionados(modulo);
    },
    [equipamentosSelecionados]
  );

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete='new-password'
                    variant="outlined"
                    label="Descrição do Processo"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 30 }}
                    allowSubmit
                    placeholder={'EX: Em Produção'}
                    error={Boolean(
                      errors.descricao && errors.descricao.message
                    )}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="processoProducao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete='new-password'
                    variant="outlined"
                    label="Ordem"
                    InputLabelProps={{
                      shrink: true
                    }}
                    allowSubmit
                    placeholder={'01'}
                    error={Boolean(
                      errors.processoProducao && errors.processoProducao.message
                    )}
                    {...field}
                  />
                )}
              />
            </Grid>
            {errors.processoProducao?.message && (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  marginTop: '-10px',
                  height: 36
                }}
              >
                {(touchedFields.processoProducao ||
                  errors.processoProducao) && (
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.error.main
                      }}
                    >
                      {errors.processoProducao?.message}
                    </Typography>
                  )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Tipo"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={StatusProcessoTipoSelect}
                    error={Boolean(errors.status && errors.status.message)}
                    helperText={
                      touchedFields.status || errors.status
                        ? errors.status?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = StatusProcessoTipoSelect.filter(
                        (item) => item.Key === event.target.value
                      )[0]?.Key;

                      setValue('status', item);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box position="relative">
                {carregandoGet && <CircularLoading tipo="FULLSIZED" />}
                <SelectSaurus
                  fullWidth
                  disabled={mock.length <= 0}
                  label="Adicionar Equipamentos"
                  variant="outlined"
                  conteudo={mock}
                  value=""
                  helperText={mock.length <= 0 ?
                    <Typography variant='caption'>
                      Você não tem nenhum equipamento para selecionar. Para cadastrar um, <Link onClick={() => {
                        fecharCadastroSetores()
                        abrirCadastroEquipamentos('', '', true)
                      }} to='/impressoras'>clique aqui</Link>
                    </Typography> : ''}
                  onChange={(e) => {
                    const item = mock.filter(i => i.Key === e.target.value)
                    setEquipamentosSelecionados(prev => [...prev, item[0].Key])
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ padding: '0 8px 16px' }}>
                <Box paddingBottom="8px">
                  <Typography variant="caption" color="textSecondary">
                    Equipamentos Selecionados
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={2}
                  style={{
                    minHeight: 52,
                    borderRadius: 8,
                    border: `1px solid ${theme.palette.action.hover}`
                  }}
                >
                  {equipamentosSelecionados.map((equipamento) => {
                    return (
                      <Grid item>
                        <Card className={classes.card}>
                          <div className={classes.cardContent}>
                            <Typography
                              className={classes.moduloNome}
                              variant="body2"
                            >
                              {
                                equipamentosMock?.filter(
                                  (item) => item.Key === equipamento
                                )[0]?.Value
                              }
                            </Typography>
                            <FecharIcon
                              tipo="BUTTON"
                              style={{ width: 16, cursor: 'pointer' }}
                              onClick={() => removeEquipamento(equipamento)}
                            />
                          </div>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
