import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerQtde: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    textQCom: {
        color: theme.palette.grey[700],
        minWidth: 50,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'baseline',
        padding: '0px 5px',
        "& h6": {
            fontWeight: 'bold',
        },
        "& span": {
            fontWeight: 'bold',
        }
    },
    btnAlterarQuantidade: {
        width: '32px',
        height: '32px',
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
        borderRadius: '50%',
        padding: '0!important'
    },
}));
