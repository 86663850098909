import { Grid, Button } from 'views/design-system';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { SelecionarMesaList } from './components/selecionar-mesa-list/selecionar-mesa-list';
import TecladoMesa from './components/teclado-mesa/teclado-mesa';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

export const MovVincularMesaPage = () => {
  const [digitarCodigo, setDigitarCodigo] = useState<boolean>(false);
  const [codigoMesa, setCodigoMesa] = useState('');
  const classes = useDefaultCadastroStyles();
  const { goBack } = useHistory();

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Seleção de Mesa',
      voltar: {
        onClick() {
          goBack();
        },
      },
    })
  }, [goBack, setHeaderProps])

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.list}>
          <SelecionarMesaList codigoMesa={codigoMesa} />
        </Grid>

        {digitarCodigo ? (
          <>
            <TecladoMesa setCodigoMesa={setCodigoMesa} />
            <Grid style={{ padding: '0 8px', marginBottom: 8 }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setDigitarCodigo(false)}
                style={{
                  borderRadius: '20px',
                  height: '56px',
                  color: '#fff',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '33px',
                  textAlign: 'center',
                }}
              >
                Ver Mesas
              </Button>
            </Grid>
          </>
        ) : (
          <Grid style={{ padding: '0 8px', marginBottom: 8 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setDigitarCodigo(true)}
              style={{
                height: '56px',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '33px',
                textAlign: 'center',
              }}
            >
              Digitar Nº Mesa
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
