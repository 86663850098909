import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ConvenioResumoFaturasModal } from "views/components/modals/convenio-resumo-faturas/convenio-resumo-faturas-modal";

export const ConvenioResumoFaturasModalRender = () => {
    // const { push } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
        pessoaId: '',
        tipo: undefined,
        anoMesSelecionado: undefined
    });

    // const pessoaRoute = `/convenios/`;
    // const novoCadastroRoute = 'adicionar';

    consoleDev('ConvenioResumoFaturasModal')

    // useEffect(() => {
    //     if (location.pathname.indexOf(pessoaRoute) === 0) {
    //         const posrota = location.pathname.replace(pessoaRoute, '');
    //         if (posrota.length > 0) {
    //             setModalState(prev => ({
    //                 ...prev,
    //                 id: posrota.replace(novoCadastroRoute, ''),
    //                 aberto: true,
    //                 ultimaUrl: pessoaRoute,
    //             }));
    //             return;
    //         }
    //     }
    // }, [location.pathname, pessoaRoute]);

    const modalAlterado = useCallback(({ pessoaId, tipo, openned, callbackUrl, trocarUrl, anoMesSelecionado }: any) => {
        // let url = callbackUrl.length === 0 ? pessoaRoute : callbackUrl;
        if (modalState.aberto) {
            // url = modalState.ultimaUrl;
        }
        setModalState({
            aberto: openned,
            // ultimaUrl: url,
            pessoaId,
            tipo,
            anoMesSelecionado
        });
        // if (!openned && !trocarUrl) {
        //     push({
        //         pathname: pessoaRoute,
        //     })
        // }
        // if (trocarUrl) {
        //     push(pessoaRoute + (id?.length === 0 ? novoCadastroRoute : id));
        // }
    }, [modalState.aberto])

    useEffect(() => {
        addHandler(AppEventEnum.ConvenioResumoFaturasModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ConvenioResumoFaturasModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <ConvenioResumoFaturasModal {...modalState} openned={modalState.aberto} key="mdlConvenio" id={""} />
    )
}