import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { ComissaoModel } from 'model/api/gestao/comissao/comissao-model';

export function usePostComissaoComissionados() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postComissaoComissionados = useCallback(
        (empresaId: string, comissaoId: string, comissionados: ComissaoModel[]) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/comissao/${comissaoId}/comissionados`,
                method: 'POST',
                data: JSON.stringify(comissionados),
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postComissaoComissionados,
    };
}