import { guidEmpty } from "utils/guid-empty";
import { CartaoConvenioPostModel } from "./cartao-convenio-post-model";

export class ConvenioPostModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public dataExpiracao: string | Date = '',
        public diaFechamento: number = 0,
        public diasParaPagamento: number = 0,
        public cartoes: CartaoConvenioPostModel[] = []
    ) { }
}