import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CardRastroProps } from './card-rastro-props';
import { EditarIcon } from '../../icons/editar-icon';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { memo } from 'react';
import { useStyles } from './card-rastro-styles'
import classNames from 'classnames';
import { toDateString } from 'utils/to-date';

const CardRastro = ({
  model,
  onClick,
}: CardRastroProps) => {

  const defaultCardClasses = useDefaultCardStyles();
  const classes = useStyles();

  return (
    <>
      <DefaultCard
        onClick={async () => onClick()}
        className={classes.card}
      >
        <Grid container className={defaultCardClasses.cardContent} justifyContent='space-between'>
          <Grid item xs={6}>
            <Typography color="textSecondary">
              Lote
            </Typography>
            <Typography
              className={classNames('celula-grid-value', classes.overflowText, classes.weight)}
              color="textPrimary"
              variant="body1">
              {model.nLote ?? 'Sem número'}
            </Typography>
          </Grid>
          {(model.qLote && model.qLote !== 0) ? (
            <Grid item xs={6}>
              <Typography color="textSecondary" >
                Qtde
              </Typography>
              <Typography
                className={classNames('celula-grid-value', classes.weight)}
                color="textPrimary"
                variant="body1">
                {model.qLote ?? 0}
              </Typography>
            </Grid>
          ) : null}
          {(model.dFab) ? (
            <Grid item xs={6}>
              <Typography color="textSecondary" >
                Data de Fabricação
              </Typography>
              <Typography
                className={classNames('celula-grid-value', classes.weight)}
                color="textPrimary"
                variant="body1"
              >
                {toDateString(model.dFab)}
              </Typography>
            </Grid>
          ) : null}
          {(model.dVal) ? (
            <Grid item xs={6}>
              <Typography color="textSecondary" >
                Data de Validade
              </Typography>
              <Typography
                className={classNames('celula-grid-value', classes.weight)}
                color="textPrimary"
                variant="body1"
              >
                {toDateString(model.dVal)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Tooltip arrow title="Editar Código">
          <div style={{
            display: 'flex',
            alignItems: "center"
          }}>
            <EditarIcon tipo="BUTTON" style={{ width: 25, height: 25, marginRight: 0 }} />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default memo(CardRastro);
