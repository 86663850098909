import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import { FocusEvent } from "react";
import { TextFieldSaurus } from "../../inputs"
import { useStyles } from "./autocomplete-multiple-styles";


export interface AutocompleteEmailsProps {
  opcoes: Array<any>;
  inputRef?: React.Ref<any>;
  name?: string;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode | undefined;
  error?: boolean;
  value?: any;
  onKeyDown?: (evento: any) => void
  onChange?: (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => any
}

export const AutocompleteMultiple = ({
  opcoes,
  inputRef,
  name,
  disabled,
  onBlur,
  label,
  placeholder,
  helperText,
  error,
  onChange,
  onKeyDown,
  value
}: AutocompleteEmailsProps) => {
  const classes = useStyles()

  return (
    <Autocomplete
      className={classes.deleteIconx}
      limitTags={2}
      options={opcoes}
      freeSolo
      multiple
      defaultValue={opcoes}
      onKeyDown={onKeyDown}
      value={value}
      onChange={onChange}
      autoSelect
      renderInput={(params) => (
        <TextFieldSaurus
          className={classes.textArea}
          inputRef={inputRef}
          {...params}
          name={name}
          fullWidth
          disabled={disabled}
          variant="outlined"
          onBlur={onBlur}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}

        />


      )}
    />
  )
}
