import { useCallback, useState } from "react";
import { useMovAtual } from "./mov-atual";
import { useToastSaurus } from "./toast-saurus";
import { useConfirm } from "material-ui-confirm";

export const useFaturamento = () => {

    const { getMov, faturarMov, ignorarFaturamentoMov } = useMovAtual();
    const [carregandoFaturamento, setCarregando] = useState<boolean>(false);
    const { showToast } = useToastSaurus();
    const confirm = useConfirm();

    const faturarVenda = useCallback(async () => {
        try {
            setCarregando(true);
            const mov = getMov();

            if (mov?.isFaturada === false) {
                await faturarMov();
            }

            setCarregando(false);
        } catch (error: any) {
            showToast('error', error.message);

            //SE O ERRO OCASIONAR POR INTERNET OU API QUEBRADA
            if (error.cause === "retorno-api") {
                confirm({
                    title: 'Promoções não Disponíveis',
                    description:
                        'Não foi possível carregar as promoções por problemas de conexão com a ' +
                        'internet ou alguma indisponibilidade dos serviços. Deseja seguir a venda sem calcular as promoções ou tentar novamente?',
                    cancellationText: 'Seguir sem Promoções',
                    confirmationText: 'Tentar novamente',
                    cancellationButtonProps: {
                        variant: 'outlined',
                        color: 'primary'
                    },
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'primary'
                    }
                }).then(async () => {
                    await faturarVenda();
                }).catch(async () => {
                    await ignorarFaturamentoMov();
                    setCarregando(false);
                });
            }


        }
    }, [confirm, faturarMov, getMov, ignorarFaturamentoMov, showToast]);

    return {
        faturarVenda,
        carregandoFaturamento,
    }
}