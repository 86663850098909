import { DefaultModal } from "views/components/modals/components/default-modal";
import { PessoaSelecionarBusca } from "./components/pessoa-selecionar-busca";
import { PessoaSelecionarModalProps } from "./pessoa-selecionar-modal-props";

export const PessoaSelecionarModal = (props: PessoaSelecionarModalProps) => {

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && (
                <PessoaSelecionarBusca {...props} />
            )}
        </DefaultModal >
    );
};
