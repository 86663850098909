
import { CardNaoEncontrado } from 'views/components/cards';
import CardProdutoRevisao from 'views/components/cards/card-produto-revisao/card-produto-revisao';
import { InformacaoIcon } from 'views/components/icons';
// import { CardStepProduto } from '../card-step-produto';

export interface ProdutoGridProps {
    list: Array<{
        nome: string;
        categoria: string;
        id: string;
        imgUrl: string;
        removido?: boolean;
        sincronizado?: boolean;
        marca: string
    }>;
    carregando: boolean;
    selectedList: Array<{
        nome: string;
        categoria: string;
        id: string;
        imgUrl: string;
        removido?: boolean;
        sincronizado?: boolean;
        marca: string
    }>;
    onCardSelected: (id: string) => any;
    onCardChecked: (produto: {
        nome: string;
        categoria: string;
        id: string;
        imgUrl: string;
        removido?: boolean;
        sincronizado?: boolean;
        marca: string
    }) => any;
    select: boolean
}

export const ListProdutoDataRevisao = (props: ProdutoGridProps) => {
    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (produto: {
        nome: string;
        categoria: string;
        id: string;
        imgUrl: string;
        removido?: boolean;
        marca: string;
        sincronizado?: boolean;
    }) => {
        props.onCardChecked(produto);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem={`Nenhum produto encontrado.`}
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.list.length > 0 &&
                props.list.map((produto) => {
                    return (
                        <>
                            <CardProdutoRevisao
                                selected={
                                    props.selectedList.find(
                                        (item) => item.id === produto.id && (item.removido === false || item.removido === undefined),
                                    ) ? true
                                        : false
                                }
                                onCheck={onCardChecked}
                                onClick={onCardSelected}
                                model={produto}
                                key={produto.id}
                                select={props.select}
                            />
                        </>
                    );
                })}
        </>
    );
};
