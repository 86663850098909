import { useCallback, useEffect, useMemo, useState } from 'react';
import { VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useToastSaurus, useCadastros, useSessaoAtual, } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { validaGuid } from 'utils/valida-guid';
import { SwitchSaurus } from 'views/components/controles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { ConvenioModel } from 'model/api/gestao/convenio/convenio-model';
import { InformacoesIniciais } from './components/informacoes-iniciais/informacoes-iniciais';
import { EdicaoConvenio } from './components/edicao-convenio/edicao-convenio';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { useGetConvenioById } from 'data/api/gestao/conta-pessoa/get-conta-pessoa-by-id';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { CircularLoading } from 'views/components/utils';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { useStyles } from './convenio-gerenciar-styles';
import { EnumConvenioStatus } from 'model/enums/enum-convenio-status';
import { usePutConvenioStatus } from 'data/api/gestao/conta-pessoa/put-conta-pessoa-status';
import { Box, Typography } from 'views/design-system';
import { CartaoConvenioModel } from 'model/api/gestao/convenio';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { guidEmpty } from 'utils/guid-empty';
import { toDecimal } from 'utils/to-decimal';

export enum EnumFaseGerenciarConvenio {
  FormularioCartao,
  InformacoesConvenio,
}

export interface FiltroPessoaProps {
  termo: string,
  tpCadastro: number[]
}

export const ConvenioGerenciar = (props: { id: string, pessoa?: PessoaModel }) => {
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools()
  const { getEmpresaAtual } = useEmpresaAtual()
  const { getPermissaoBoolean} = useSessaoAtual()
  const [convenioId, setConvenioId] = useState<string>(props.id)
  const { abrirPessoaSelecionarModal, fecharPessoaSelecionarModal } = useFiltrosModais()

  const hasId = useMemo(() => validaGuid(convenioId), [convenioId])

  const [faseGerenciarConvenio, setFaseGerenciarConvenio] = useState<EnumFaseGerenciarConvenio>(
    hasId
      ? EnumFaseGerenciarConvenio.InformacoesConvenio
      : EnumFaseGerenciarConvenio.FormularioCartao
  )
  const [convenio, setConvenio] = useState<ConvenioModel>(new ConvenioModel())

  const modalClasses = useModalStyles();
  const classes = useStyles()
  const {
    fecharCadastroConvenio,
    abrirConvenioResumoFaturas
  } = useCadastros();
  const { getConvenioById, carregando: carregandoGet } = useGetConvenioById()
  const { putConvenioStatus, carregando: carregandoPutStatus } = usePutConvenioStatus()
  const { getConfigByCod } = useEmpresaAtual()

  const limiteCartao = getConfigByCod(EnumEmpresaConfig.LimiteCreditoCartao)
  const senhaPadrao = getConfigByCod(EnumEmpresaConfig.SenhaPadrao)
  const [preenchendoTela, setPreenchendoTela] = useState<boolean>(false)

  const carregando = [carregandoGet, carregandoPutStatus, preenchendoTela].includes(true)

  const getConvenioByIdWrapper = useCallback(async () => {
    try {
      setPreenchendoTela(true)
      if (!hasId) return
      const res = await getConvenioById(convenioId, getEmpresaAtual()?.id ?? '')
      if (res.erro) throw res.erro

      const convenio = res.resultado?.data as ConvenioModel
      setConvenio(convenio)

    } catch (err: any) {
      showToast('error', 'Ocorreu um problema ao carregar as informações do Convênio. Detalhe: ' + err.message)
    } finally {
      setPreenchendoTela(false)
    }
  }, [convenioId, getConvenioById, getEmpresaAtual, hasId, showToast])

  const changeStatus = useCallback(async () => {
    try {
      setPreenchendoTela(true)
      const novoStatus = convenio.status === EnumConvenioStatus.Ativo ? EnumConvenioStatus.Inativo : EnumConvenioStatus.Ativo
      const res = await putConvenioStatus(convenioId, novoStatus, getEmpresaAtual()?.id ?? '')
      if (res.erro) throw res.erro

      showToast('success', 'Cartão ' + (novoStatus === EnumConvenioStatus.Ativo ? 'Ativado' : 'Inativado'))
      await getConvenioByIdWrapper()
    } catch (err: any) {
      showToast('error', 'Ocorreu um problema ao alterar o status. Detalhe: ' + err.message)
    } finally {
      setPreenchendoTela(false)
    }
  }, [convenio.status, convenioId, getConvenioByIdWrapper, getEmpresaAtual, putConvenioStatus, showToast]);

  const atualizarConvenio = useCallback((model: {
    openned: boolean
    att: boolean
  }) => {
    const { openned, att } = model
    if (!openned && att) {
      getConvenioByIdWrapper()
    }
  }, [getConvenioByIdWrapper])

  useEffect(() => {
    addHandler(AppEventEnum.CartaoConvenioModal, atualizarConvenio)

    return () => removeHandler(AppEventEnum.CartaoConvenioModal, atualizarConvenio)
  }, [addHandler, atualizarConvenio, removeHandler])

  const handleVoltarSelecao = useCallback(() => {
    fecharPessoaSelecionarModal()
    fecharCadastroConvenio()
  }, [fecharCadastroConvenio, fecharPessoaSelecionarModal])

  const handleSelecionarPessoa = useCallback((pessoas: PessoaModel[]) => {
    const [pessoa] = pessoas
    setConvenio(prev => ({
      ...prev,
      pessoaId: pessoa.id,
      cartoes: [
        {
          ...new CartaoConvenioModel(),
          pessoaId: pessoa.id,
          nomeCartao: pessoa.nome,
          numeroCartao: pessoa.cpfcnpj,
          senha: senhaPadrao ?? '',
          limite: toDecimal(limiteCartao ?? '0')
        }
      ]
    }))
    fecharPessoaSelecionarModal()
  }, [fecharPessoaSelecionarModal, limiteCartao, senhaPadrao])

  const abrirSelecaoPessoa = useCallback(() => {
    abrirPessoaSelecionarModal({
      voltar: handleVoltarSelecao,
      idsBloqueados: [],
      adicionar: handleSelecionarPessoa,
      typeSelect: 'radio',
      label: 'Selecione a Pessoa',
    })
  }, [abrirPessoaSelecionarModal, handleSelecionarPessoa, handleVoltarSelecao])

  const onCloseClick = useCallback(() => {
    fecharCadastroConvenio();
  }, [fecharCadastroConvenio]);

  useEffect(() => {
    if (convenio.pessoaId === guidEmpty() && !props.pessoa && !hasId) {
      abrirSelecaoPessoa()
    }
  }, [abrirSelecaoPessoa, convenio.pessoaId, hasId, props.pessoa])

  useEffect(() => {
    getConvenioByIdWrapper()
  }, [getConvenioByIdWrapper])

  const alterarId = (id: string) => {
    setConvenioId(id)
  }

  const onClickFatura = useCallback(() => {
    fecharCadastroConvenio()
    setTimeout(() => {
      abrirConvenioResumoFaturas({ pessoaId: convenio.pessoaId })
    }, 50)
  },[abrirConvenioResumoFaturas, convenio.pessoaId, fecharCadastroConvenio])

  const permissaoEditarCartao = useMemo(() => getPermissaoBoolean(EnumCodigosPermissoes.CONVENIOCARTAO) , [getPermissaoBoolean])

  const headerMenuOptions = useCallback(()=>{
    if(permissaoEditarCartao){
    return (
      [
        new MenuOptionsModel(
          (
            <Box className={classes.menuItem}>
              <Typography>Ativo</Typography>
              <SwitchSaurus
                size="small"
                variant="DEFAULT"
                checked={convenio.status === EnumConvenioStatus.Ativo}
              />
            </Box>
          ),
          <></>,
          changeStatus,
        ),
        new MenuOptionsModel(
          (
            <Box className={classes.menuItem}>
              <Typography>Faturas</Typography>
            </Box>
          ),
          <></>,
          onClickFatura,
        ),
      ]
    )}else{
      return ([
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>Faturas</Typography>
          </Box>
        ),
        <></>,
        onClickFatura,
      )
    ])
  
    }
  },[changeStatus, classes.menuItem, convenio.status, onClickFatura, permissaoEditarCartao])

  useEffect(() => {
    if (props.pessoa) {
      const pessoa = props.pessoa
      setConvenio(prev => ({
        ...prev,
        pessoaId: pessoa.id,
        cartoes: [
          {
            ...new CartaoConvenioModel(),
            nomeCartao: pessoa.nome,
            numeroCartao: pessoa.cpfcnpj,
            limite: limiteCartao ? Number(limiteCartao) : 0,
            senha: senhaPadrao ?? '',
            pessoaId: pessoa.id,
          }
        ],
      }))
      setFaseGerenciarConvenio(EnumFaseGerenciarConvenio.FormularioCartao)
    }
  }, [limiteCartao, props.pessoa, senhaPadrao])

  return (
    <div className={modalClasses.root}>
      <ModalHeader
        title={
          hasId ? 'Edição do Convênio' : 'Cadastro do Convênio'
        }
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          <>
            {hasId && (
              <MenuOptions
                options={headerMenuOptions()}
              />
            )}
          </>
        }
      />
      <div className={modalClasses.content}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        {!carregando && !hasId && (
          <>
            {faseGerenciarConvenio === EnumFaseGerenciarConvenio.FormularioCartao && (
              <InformacoesIniciais
                {...{
                  convenio,
                  setConvenio,
                  setFaseGerenciarConvenio,
                  alterarId,
                  abrirSelecaoPessoa,
                }}
              />
            )}
          </>
        )}
        {!carregando && hasId && (
          <>
            {faseGerenciarConvenio === EnumFaseGerenciarConvenio.InformacoesConvenio && (
              <EdicaoConvenio
                {...{
                  convenio,
                  getConvenioByIdWrapper,
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConvenioGerenciar;
