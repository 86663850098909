import { Grid } from 'views/design-system';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { MesasComandasHeader } from './components/mesas-comandas-header/mesas-comandas-header';
import { MesasComandasTabs } from './components/mesas-comandas-tabs/mesas-comandas-tabs';
import { useMemo } from 'react';

export const MesasComandasPage = () => {
  const classes = useDefaultCadastroStyles();
  const component = useMemo(() => <Grid className={classes.root}>
    <Grid className={classes.header}>
      <MesasComandasHeader />
    </Grid>
    <Grid className={classes.list}>
      <MesasComandasTabs />
    </Grid>
  </Grid>, [classes.header, classes.list, classes.root]);
  return component;
};

export default MesasComandasPage;
