export const impressaoParcelasGuilhotina = (pags: string[]) => {
    let impressao = ''

    pags.forEach((pag, index, array) => {
        impressao += `
        ${pag}
        ${(array.length - 1 === (index) ? '' : '<guilhotina>')}
        `
    })

    return impressao
}