export const validaCaracter = (value: string | undefined, validarCaracteresIsolados: boolean = false): string => {
    const mapaCaracteresEspeciais: { [key: string]: string } = {
        'á': 'a', 'ã': 'a', 'â': 'a', 'à': 'a', 'ä': 'a',
        'é': 'e', 'ê': 'e', 'è': 'e', 'ë': 'e',
        'í': 'i', 'î': 'i', 'ì': 'i', 'ï': 'i',
        'ó': 'o', 'õ': 'o', 'ô': 'o', 'ò': 'o', 'ö': 'o',
        'ú': 'u', 'û': 'u', 'ù': 'u', 'ü': 'u',
        'ç': 'c',
        'Á': 'A', 'Ã': 'A', 'Â': 'A', 'À': 'A', 'Ä': 'A',
        'É': 'E', 'Ê': 'E', 'È': 'E', 'Ë': 'E',
        'Í': 'I', 'Î': 'I', 'Ì': 'I', 'Ï': 'I',
        'Ó': 'O', 'Õ': 'O', 'Ô': 'O', 'Ò': 'O', 'Ö': 'O',
        'Ú': 'U', 'Û': 'U', 'Ù': 'U', 'Ü': 'U',
        'Ç': 'C'
        // Adicione mais substituições conforme necessário
    };

    const mapaApenasCaracteresEspeciais: {[key: string]: string} = {
        '`': '', "'": '', "´": "", ',': '',
        '<': '', '>': '', '~': '', '[': '', ']': '',
        '{': '', '}': '', '=': '', '+': '', '-': '', '*': '',
        '/': '', '"': '', "!": '', "#": '', "$": '', "%": '',
        "¨": '', "&": '', '(': '', ")": '', '_': '', ';': '',
        ':': '', '|': '', '.': '', 'º': '', 'ª': '', '§': '',
    }

    // eslint-disable-next-line no-useless-escape
    // const caracteresEspeciaisRegex = /[$^&\[\]:;<>~\/\\|]/;

    let resultado = '';

    for (const char of value ?? '') {
        if (mapaCaracteresEspeciais[char]) {
            resultado += mapaCaracteresEspeciais[char];
            if(validarCaracteresIsolados && mapaApenasCaracteresEspeciais[char]){
                resultado += mapaApenasCaracteresEspeciais[char];
            }
            // eslint-disable-next-line no-useless-escape
        } else if (/[a-zA-Z0-9 ]/.test(char) && !/[$^&\[\]:;<>~\/\\|]/.test(char)) {
            resultado += char;
        }
    }


    return resultado;
}

export const isTextUTF8 = (value: string): boolean => {
    const regex = /[ƒ‡¡©“]/i;
    return !regex.test(value);
}