import { useCallback, useMemo, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import LeitorComanda from './leitor-comanda-fragment';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { isEmpty } from 'lodash';
import { isOnlyHasNumber } from 'utils/to-decimal';
import { useMovRota } from 'services/app/hooks/mov-rota';

const MovLeitorComandaPage = () => {
  //STATES E REFS
  const codigo = useRef<string>('');

  //PROVIDERS
  const { showToast } = useToastSaurus();
  const { searchComandas } = usePedidoLocal();
  const { setComandaId, setCodigoComanda } = useMovAtual();
  const { getConfigByCod } = useEmpresaAtual();
  const { avancarFluxoMov } = useMovRota();
  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      codigo.current = text;
      return true;
    },
    [],
  );

  const submit = useCallback(async () => {
    return false;
  }, []);

  const add = useCallback(async () => {
    return true;
  }, []);

  const handleConfime = useCallback(async () => {
    try {
      const codigoComanda = codigo.current;
      const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
      const prefixo = buscaPrefixo?.toUpperCase();

      let codComanda: any = codigoComanda;

      if (codComanda?.startsWith(prefixo)) {
        codComanda = codigoComanda.slice(prefixo?.length);
      }

      if (!isEmpty(prefixo) && codigoComanda.includes(prefixo!)) {
        if (isOnlyHasNumber(codComanda)) {
          codComanda = Number(codComanda);
        }
      }

      const comandas = await searchComandas(codComanda);

      if (comandas.length === 0) {
        throw new Error(
          `Nenhuma comanda foi identificada com o código informado ${codigoComanda}`,
        );
      }

      await setComandaId(comandas[0].id);
      await setCodigoComanda(codigoComanda);
      await avancarFluxoMov();

    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [avancarFluxoMov, getConfigByCod, searchComandas, setCodigoComanda, setComandaId, showToast]);

  const fragmento = useMemo(() => {
    return <LeitorComanda add={add} submit={submit} textChanged={textChanged} handleConfime={handleConfime} />;
  }, [add, handleConfime, submit, textChanged]);

  return (
    <>
      {fragmento}
    </>
  );
};

export default MovLeitorComandaPage;
