export const PdvRotasMock = {
    configuracoesPDV: '/venda-simples/configuracoes',
    sincronizarDadosRota: '/venda-simples/sincronizar-dados',
    sincronizarResumoRota: '/venda-simples/sincronizar-resumo',
    sincronizarResumoTodosRota: '/venda-simples/sincronizar-resumo/todos',
    sincronizarResumoSucessoRota: '/venda-simples/sincronizar-resumo/sucesso',
    sincronizarResumoFalhaRota: '/venda-simples/sincronizar-resumo/falha',

    gerenciarSessao: "/venda-simples/gerenciar-sessao",
    gerenciarSessaoSangria: "/venda-simples/gerenciar-sessao-sangria",
    gerenciarSessaoDespesa: "/venda-simples/gerenciar-sessao-despesa",
    gerenciarSessaoReforco: "/venda-simples/gerenciar-sessao-reforco",
    gerenciarSessaoReceita: "/venda-simples/gerenciar-sessao-receita",
    gerenciarSessaoLeituraCaixa: "/venda-simples/gerenciar-sessao-leitura-caixa",
    gerenciarSessaoFechamentoCaixa: "/venda-simples/gerenciar-sessao-fechamento-caixa",
    gerenciarSessaoSincronizar: "/venda-simples/gerenciar-sessao-sincronizar",
    gerenciarSessaoSincronizarFechamento: "/venda-simples/gerenciar-sessao-sincronizar-fechamento",

}