import * as Yup from 'yup';

export const useFormProdutoMotivoCancelamentoValidation = () => {

  const formProdutoMotivoCancelamentoYupValidation = Yup.object().shape({
    infAdicional: Yup.string().notRequired().min(0).max(250, 'Máximo de 250 caracteres'),
  });

  return {
    formProdutoMotivoCancelamentoYupValidation
  }
}

