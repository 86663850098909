import { Box, Grid, Typography } from 'views/design-system';
import { PublicPageHeader } from 'views/components/headers';
import { Stepper } from 'views/components/stepper';
import { CircularLoading, useThemeQueries } from 'views';
import { useStyles } from './novo-contrato-styles';
import { useNovoContratoPageLogic } from './novo-contrato-logic';
import { useEffect, useMemo } from 'react';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { GestaoStorageKeys, useGestaoStorage } from 'services/app';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { VariaveisAmbiente } from 'config';

const NovoContratoPage = () => {
  const { formStepper, formArray, carregando } = useNovoContratoPageLogic();
  const { isMobile } = useThemeQueries();
  const classes = useStyles();
  const stepClasses = useStepperStyles();
  const location = useLocation()
  const search = location.search;
  const chaveAtivacao = VariaveisAmbiente.chaveAtivacaoRevenda || new URLSearchParams(search).get('chaveAtivacao');
  const { setRegistro, exists, getRegistro } = useGestaoStorage()
  const history = useHistory()

  useEffect(() => {
    if (!isEmpty(chaveAtivacao)) {
      if (exists(GestaoStorageKeys.ChaveAtivacao)) {
        if (getRegistro(GestaoStorageKeys.ChaveAtivacao) !== chaveAtivacao) {
          setRegistro(GestaoStorageKeys.ChaveAtivacao, chaveAtivacao)
          return
        }
      }
      setRegistro(GestaoStorageKeys.ChaveAtivacao, chaveAtivacao)
      return
    }

    history.push('/chave-contrato')
  }, [chaveAtivacao, exists, getRegistro, history, setRegistro])

  const getButtons = useMemo(() => {
    return (
      <div className={stepClasses.stepButtons}>
        <Box mt={3}>
          <Grid spacing={2} container>
            {formArray[formStepper.currentStep].previousButton && (
              <Grid item xs={4}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}

            {formArray[formStepper.currentStep].nextButton && (
              <Grid item xs={8}>
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepClasses.stepButtons, formArray, formStepper.currentStep]);

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <Grid className={classes.root} container justifyContent="center">
        <Grid item xs={11} md={10} lg={6}>
          <div className={classes.stepperOuterContainer}>
            <div className={stepClasses.stepperContent}>
              <Stepper steps={formArray} stepAtual={formStepper.currentStep} />
            </div>
            <div className={classes.divForm}>
              <div className={stepClasses.stepFix}>
                <div className={stepClasses.step}>
                  <div className={stepClasses.stepTitleContent}>
                    <Typography
                      align="center"
                      className={stepClasses.stepTitle}
                      variant="h3"
                      color="primary"
                    >
                      {formArray[formStepper.currentStep].formTitleIcon}
                      {formArray[formStepper.currentStep].formTitle}
                    </Typography>
                    <Box mt={3}>
                      <Typography align="center" variant="body1">
                        {formArray[formStepper.currentStep].formDesc}
                      </Typography>
                    </Box>
                  </div>
                  <div className={stepClasses.stepForm}>
                    {formArray[formStepper.currentStep].form}
                  </div>
                  {!isMobile && getButtons}
                </div>
              </div>
              {isMobile && getButtons}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NovoContratoPage;
