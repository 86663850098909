import classNames from "classnames";
import { BadgeProps } from "./badge-props";
import { useStyles } from "./badge-styles";
import { forwardRef } from "react";
import { isEmpty } from "lodash";

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>((props, ref) => {
    const styles = useStyles();
    const color = props.color === 'primary' ? styles.primary :
        props.color === 'error' ? styles.error :
            props.color === 'secondary' ? styles.secondary :
                props.color === 'default' ? styles.default : undefined;

    return isEmpty(props.badgeContent) ? <>{props.children}</> :
        <span className={classNames(
            props.classes?.root,
            props.className,
            styles.root
        )} style={props.style} id={props.id} onClick={props.onClick} >
            {props.children}
            <span className={classNames(props.classes?.badge, styles.topRight, styles.badge, color)}>
                {props.badgeContent}
            </span>
        </span>
});