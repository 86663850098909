
import { ApuracoesList } from "./components/lists/apuracoes-list";
import { ApuracaoVendaComissao } from "./components/lists/apuracao-vendas-list";
import { useApuracaoStore } from "./stores/apuracao-store";

export const ApurarComissao = (props: {
    comissaoId: string
}) => {
    const acaoApurar = useApuracaoStore(state => state.stateComissaoApuracao.ui.acaoApurar)

    return <>
        {
            acaoApurar === 'comissionado' && <ApuracoesList />
        }

        {
            acaoApurar === 'vendas' && <ApuracaoVendaComissao />
        }
    </>
}