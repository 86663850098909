import { useCallback, useEffect, useState } from 'react';
import { CancelarIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import { BarcodeScanIcon } from 'views/components/icons/barcode-scan-icon';
import { DialogSelecionarSalao } from 'views/components/dialog/dialog-selecionar-salao/dialog-selecionar-salao';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { DialogScannAtendimento } from './components/dialog-scann-atendimento/dialog-scann-atendimento';
import { useConfirm } from 'material-ui-confirm';
import { DialogScannRFID } from '../../../../../../components/dialog/dialog-scann-rfid/dialog-scann-rfid';
import { ScannerIcon } from 'views/components/icons/scanner-icon';
import { CodigoBarrasIcon } from 'views/components/icons/codigo-barras-icon';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { useGetPedidoSalaoProcessado } from 'data/api/gestao/pedido/get-pedido-salao-processado';
import { useSessaoAtual } from 'services/app';
import { isEmpty } from 'lodash';
import { useToastSaurus } from 'services/app';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

export const AtendimentoHeader = () => {
  const history = useHistory();
  const { showToast } = useToastSaurus();
  const [dialogSalao, setDialogSalao] = useState<boolean>(false)
  const [dialogScanner, setDialogScanner] = useState<boolean>(false)
  const [dialogRFID, setDialogRFID] = useState<boolean>(false)
  const { getConfiguracoesMesaEComanda, searchComandas } = usePedidoLocal()
  const { getPedidoSalaoProcessado } = useGetPedidoSalaoProcessado();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { push } = useHistory();
  const confirm = useConfirm();
  const { redirectCancelamento } = useMovRota()
  const [erro, setErro] = useState<string>('')

  const closeDialogScanner = useCallback(() => {
    setDialogScanner(false);
  }, []);

  const abrirScanner = useCallback((tipo: 'codigo' | 'nfc') => {
    switch (tipo) {
      case 'codigo':
        setDialogScanner(true)
        break;
      case 'nfc':
        setDialogRFID(true)
        break;
    }
  }, [])

  const onScannerClick = useCallback(async () => {
    const tipoTrabalhoComanda = getConfiguracoesMesaEComanda()?.tipoTrabalhoComanda;
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.HIBRIDO) {
      confirm({
        title: 'Escanear Comanda',
        description: 'Como deseja escanear a comanda?',
        confirmationText: <>
          <ScannerIcon tipo='BUTTON_PRIMARY' />
          Comanda RFID
        </>,
        cancellationText: <>
          <CodigoBarrasIcon tipo='BUTTON_PRIMARY' />
          Código de Barras
        </>,
        cancellationButtonProps: {
          variant: 'contained',
          color: 'primary'
        }
      }).then(() => abrirScanner('nfc'))
        .catch(() => abrirScanner('codigo'))

      return
    }
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID) {
      abrirScanner('nfc')
    }
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_CODIGO) {
      abrirScanner('codigo')
    }
  }, [abrirScanner, confirm, getConfiguracoesMesaEComanda])

  const handleCancel = useCallback(async () => {
    try {
      await redirectCancelamento();
    } catch (e: any) {
      showToast('info', e.message);
      return;
    }
  }, [redirectCancelamento, showToast]);


  const isAbaDelivery = useCallback(
    () => {
      const rotaAtual = '/venda-simples/atendimento/delivery'.indexOf(history.location.pathname);
      return rotaAtual > -1;
    },
    [history.location.pathname]
  );

  const confirmarComanda = useCallback(
    async (rfid: string) => {
      setErro('')
      try {
        const comandas = await searchComandas(rfid, 'rfId');

        if (comandas.length === 0) {
          throw new Error(
            `Nenhuma comanda foi identificada com o RFID: ${rfid}`,
          );
        }

        const pedidos = await getPedidoSalaoProcessado(getEmpresaSelecionada()?.Id!, `comandaId=${comandas[0]?.id ?? ''}`);

        let comandaComPedido: string = "";
        pedidos.resultado?.data?.list?.forEach((item: any) => {
          item.pedidos.forEach((valor: any) => {
            comandaComPedido = valor.comandaId;
          })
        })

        if (isEmpty(comandaComPedido) || comandaComPedido !== comandas[0].id) {
          throw new Error(`A Comanda ${rfid} não possui um pedido.`)
        }

        else {
          push(`/venda-simples/visualizar-pedido/${comandas[0].id}`)
        }

      } catch (err: any) {
        setErro(err.message)
      }
    },
    [getEmpresaSelecionada, getPedidoSalaoProcessado, push, searchComandas],
  );

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {

    const arrBtns = [];
    if (!isAbaDelivery()) {
      arrBtns.push({
        icon: <BarcodeScanIcon tipo="PRIVATE_HEADER" />,
        tooltip: 'Abrir Leitor',
        onClick: () => {
          if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA) {
            setDialogSalao(true)
            return;
          }
          onScannerClick()
        }
      })
    }
    arrBtns.push({
      icon: <CancelarIcon tipo="PRIVATE_HEADER" />,
      tooltip: "Cancelar venda",
      onClick: () => handleCancel()
    })

    setHeaderProps({
      showMenuPdv: true,
      title: 'Atendimento',
      customButton: arrBtns
    })
  }, [getConfiguracoesMesaEComanda, handleCancel, isAbaDelivery, onScannerClick, setHeaderProps])

  return (
    <>
      {dialogSalao && (
        <DialogSelecionarSalao
          aberto={dialogSalao}
          close={() => setDialogSalao(false)}
        />
      )}

      {dialogScanner && (
        <DialogScannAtendimento
          openned={dialogScanner}
          closeModal={closeDialogScanner}
        />
      )}

      {dialogRFID && (
        <DialogScannRFID
          closeDialog={() => setDialogRFID(false)}
          openned={dialogRFID}
          confirmarComandaRFID={confirmarComanda}
          erro={erro}
        />
      )}
    </>
  );
};
