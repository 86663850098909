import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from 'views/design-system';

import { useGetPlanos } from 'data/api/gestao/plano/get-planos';
import { useCadastros, useToastSaurus } from 'services/app/hooks';
import { useSessaoAtual } from 'services/app';

import { CircularLoading, useThemeQueries } from 'views';

import { PlanoModel, PropOpcoes } from 'model/api/gestao/plano/plano-model';

import { useStyles } from './carousel-planos-saurus-styles';
import { CarouselSaurus } from 'views/components/carousel-saurus';
import { usePostPlano } from 'data/api/gestao/plano/post-plano';
import { useHistory } from 'react-router-dom';
import { CardPlano } from 'views/components/cards/card-plano/card-plano';
import { DialogPlano } from 'views/components/dialog/dialog-plano/dialog-plano';
import { useConfirm } from 'material-ui-confirm';
import { guidEmpty } from 'utils/guid-empty';
import { isEmpty } from 'lodash';
import { PlanoAtualModel } from 'model/api/gestao/plano/plano-atual-model';
import { useGetPlanoAtual } from 'data/api/gestao/plano/get-plano-atual';

interface PropsCarousel {
  pathToRedirect: string;
  setIsPlanoAvulso?: (value: boolean) => void;
}

export const CarouselPlanosSaurus = ({ pathToRedirect, setIsPlanoAvulso }: PropsCarousel) => {
  const { plano: planoAtual } = useSessaoAtual();
  const [detailedModel, setDetailedModel] = useState<PlanoModel | undefined>(
    undefined,
  );
  const confirm = useConfirm();
  const { postPlano, carregando: carregandoPostPlano } = usePostPlano();
  const history = useHistory();
  const [planos, setPlanos] = useState<PlanoModel[]>([]);
  const [plano, setPlano] = useState<PlanoAtualModel>(planoAtual || new PlanoAtualModel())
  const planosDoGet = useRef<PlanoModel[]>([])
  const { getPlanos, carregando: carregandoGetPlanos } = useGetPlanos();
  const { getPlanoAtual, carregando: carregandoGetPlanoAtual } = useGetPlanoAtual();
  const { abrirDialogPlanoCustomizado } = useCadastros();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();
  const carregando = carregandoGetPlanos || carregandoPostPlano || carregandoGetPlanoAtual;
  const classes = useStyles();

  const handleOpen = useCallback((plano: PlanoModel) => {
    if (plano.id === 'custom' || planosDoGet.current.filter(plan => plan.id === plano.id).length === 0) {
      abrirDialogPlanoCustomizado(pathToRedirect)
      return
    }
    setDetailedModel(plano);
  }, [abrirDialogPlanoCustomizado, pathToRedirect])

  const handleClose = () => {
    setDetailedModel(undefined);
  };

  const handlePlans = useCallback(async () => {
    try {
      const res = await getPlanos();
      if (res.erro) throw res.erro;
      const ret = res.resultado?.data as PlanoModel[]
      planosDoGet.current = ret
      if (isEmpty(ret)) {
        setIsPlanoAvulso && setIsPlanoAvulso(isEmpty(ret))
        return
      }
      const resAtual = await getPlanoAtual();
      if (resAtual.erro) throw resAtual.erro
      setPlano(resAtual.resultado?.data)

      if (ret.filter(x => x.id === plano?.plano.id).length === 0) {
        let plan = { ...resAtual.resultado?.data } as PlanoAtualModel | undefined
        if (plan) {
          plan.plano.opcoes = plan.plano.opcoes.map<PropOpcoes>(opc => ({
            ...opc,
            exibir: opc.exibir === 2 ? 1 : opc.exibir
          }))
        }
        setPlanos([
          plan?.plano ?? new PlanoModel(),
          ...ret
        ])
        return
      }
      const planoCustomizado = new PlanoModel(
        'custom',
        'Plano Personalizado',
        'Personalize seu plano de acordo com as necessidades de seu negócio. Módulos e quantidades tudo sob seu controle!',
        '',
        ret[0].opcoes.map(x => ({ ...x, valor: '0' })),
      )
      setPlanos([planoCustomizado, ...ret]);
    } catch (e: any) {
      showToast('error', 'Erro ao Carregar os Planos. Detalhe: ' + e.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlanos, setIsPlanoAvulso, showToast]);

  useEffect(() => {
    handlePlans();
  }, [handlePlans])

  const { refreshPlanoUsuario } = useSessaoAtual();

  const changePlano = async (planoId: string, melhorDia: number) => {
    try {
      var ret = await postPlano({
        planoId: planoId,
        melhorDia: melhorDia,
        tpFatura: 1,
      });

      if (ret.erro) throw ret.erro;

      await refreshPlanoUsuario();
      showToast('success', 'Alteração de Plano realizada com Sucesso!');
      history.push(`${pathToRedirect}`);
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const carousel = useMemo(() => {
    return (
      <CarouselSaurus
        isEscuro={false}
        fullScreen={false}
        breakPoints={[
          { width: 1, itemsToShow: 1 },
          { width: 768, itemsToShow: 3 },
        ]}
        isRTL={false}
        showArrows={isMobile ? false : true}
      >
        {planos.map((item, index) => {
          return (
            <CardPlano
              key={index}
              model={item}
              showDetailButton={true}
              isMostPopular={item.valor.toFixed(2) === '0.00'}
              onDetail={(p) => handleOpen(p)}
              isSelected={plano?.plano?.id === item.id ? true : false}
              isAvulso={planosDoGet.current.filter(plan => plan.id === item.id).length === 0}
            />
          )
        })}
      </CarouselSaurus>
    );
  }, [handleOpen, isMobile, plano?.plano?.id, planos]);

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.planosContainer}>{!isEmpty(planos) && carousel}</Grid>

      {detailedModel && (
        <DialogPlano
          loading={carregando}
          openned={detailedModel !== undefined}
          closeModal={handleClose}
          model={detailedModel!}
          changePlanoAtual={(p) => {
            if (plano?.plano?.id !== guidEmpty()) {
              confirm({
                title: `Alteração de Plano`,
                description: `Deseja confirmar a alteração para o Plano ${p?.nome}?\n\nAtenção, todos as limitações e cobranças serão aplicadas instantâneamente.`,
                cancellationText: 'Cancelar Alteração',
                cancellationButtonProps: {
                  color: 'default',
                },
                confirmationText: 'Confirmar Contratação',
              }).then(() => {
                changePlano(p?.id || '', 5);
              });
            } else {
              changePlano(p?.id || '', 5);
            }
          }}
        />
      )}
    </>
  );
};
