import { useCallback } from "react";
import { CardVendaDadosProps } from "./card-venda-dados-props";
import { StatusHistoricoVendaMock } from "data/mocks/status-historico-venda-mock";
import { TpHistoricoVendaMock } from "data/mocks/tp-historico-venda-mock";
import { toDecimalString } from "utils/to-decimal";
import { useThemeQueries } from "views/theme";
import { AccordionPagamentos } from "views/pages/private/detalhes-venda/componets/accordion-pagamentos/accordion-pagamentos";
import { AccordionProdutos } from "views/pages/private/detalhes-venda/componets/accordion-produtos/accordion-produtos";
import { CardDadosClienteDetalheVenda } from "../card-dados-cliente-detalhe-venda/card-dados-cliente-detalhe-venda";
import { CardDadosEmpresaDetalheVenda } from "../card-dados-empresa-detalhe-venda/card-dados-empresa-detalhe-venda";
import { useDefaultCardStyles } from "../components";
import { useStyle } from './card-venda-dados-styles';
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov";
import { toDateString } from "utils/to-date";
import { Card } from "@material-ui/core";
import { Box, Divider, Grid, Typography } from "views/design-system";
import classNames from "classnames";

export const CardVendaDados = (props: CardVendaDadosProps) => {

    const { theme } = useThemeQueries();
    const classes = useDefaultCardStyles();
    const vendaDadosClasses = useStyle();

    const retornarCorStatus = useCallback((): string => {
        switch (props.model.status) {
            case EnumTpStatusMov.Pendente:
                return theme.palette.warning.main;
            case EnumTpStatusMov.Finalizado:
                return theme.palette.success.main;
            case EnumTpStatusMov.Cancelado:
            case EnumTpStatusMov.Abortado:
            case EnumTpStatusMov.Rejeitado:
                return theme.palette.error.main;
            case EnumTpStatusMov.EmDigitacao:
                return theme.palette.warning.dark;
            default:
                return theme.palette.primary.main;
        }
    }, [
        theme.palette.success.main,
        theme.palette.warning.main,
        theme.palette.primary.main,
        props.model.status,
        theme.palette.error.main,
        theme.palette.warning.dark,
    ]);

    const descricao = StatusHistoricoVendaMock.filter(
        (x) => x.Key === props.model.status,
    )[0]?.Value;

    const tpstatus = TpHistoricoVendaMock.filter((x) => x.Key === props.model.infMov.mod)[0]
        ?.Value;

    return (
        <>
            <Card style={{ boxShadow: theme.shadows[1] }}>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} flex alignItems='center' >
                                <Typography
                                    variant="h5"
                                    color="textPrimary"
                                    weight={600}
                                >
                                    Dados da Venda
                                </Typography>
                            </Grid>
                            <Grid item xs={6} flex justifyContent='flex-end' >
                                <div className={classes.celulaGrid}>
                                    <Grid className={vendaDadosClasses.containerStatus} my={1} >
                                        <Box
                                        className={classNames(classes.cardStatus, classes.cardStatusTag)}
                                        >
                                            <Typography
                                                color="commomWhite"
                                                variant="body1"
                                            >
                                                {tpstatus}
                                            </Typography>
                                        </Box>

                                        <Box flex pl={1} pr={1} justifyContent="center" className={classes.cardStatus}
                                            style={{
                                                background: retornarCorStatus(),
                                            }}
                                        >
                                            <Typography
                                                color="commomWhite"
                                                variant="body1"
                                            >
                                                {descricao}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" style={{ background: theme.palette.primary.main }} />
                    </Grid>
                    <Grid item xs={12} flex justifyContent="space-between" mx={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3}>
                                <Typography
                                    variant="caption"
                                    color='textPrimary'
                                >
                                    Data da Emissão
                                </Typography>
                                {props.model.infMov.dhEmi && props.model.infMov.dhEmi !== '0001-01-01T00:00:00+00:00' &&
                                    <Typography
                                        variant="body2"
                                        color="textPrimary"
                                        weight={500}
                                    >
                                        {toDateString(props.model.infMov.dhEmi, 'DD/MM/yyyy HH:mm')}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Itens
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color='textPrimary'
                                >
                                    {props.model.infMov.prod.length}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography
                                    variant="caption"
                                    color='textPrimary'
                                >
                                    Número
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {props.model.infMov.nnf === 0 || !props.model.infMov.nnf ? `- /${props.model.infMov.serie}` : `${props.model.infMov.nnf}/${props.model.infMov.serie}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Valor Total
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {`R$ ${toDecimalString(props.model.infMov.total.vnf)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <CardDadosEmpresaDetalheVenda
                            model={props.model}
                        />
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <CardDadosClienteDetalheVenda
                            model={props.model}
                        />
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <AccordionPagamentos
                            model={props.model}
                        />
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <AccordionProdutos
                            model={props.model}
                        />
                    </Grid>
                </Grid >
            </Card >
        </>
    )
}