import { Typography } from 'views/design-system';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useStyles } from './sincronizacao-geral-toast-styles';
import { LoadingSicronizacaoGeral } from '../../../../../views/components/utils/loading-sincronizacao-geral/loading-sincronizacao-geral';
import { SincronizarIcon } from '../../../../../views/components/icons/sincronizar-icon';
import { ISincronizacaoAtual } from 'services/app/hooks/sincronizar-dados';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { EnumSincronizacaoGeral } from 'model/enums/enum-sincronizacao-geral';
import { TpSincronizacaoGeralMock } from 'data/mocks/tp-sincronizacao-geral';

export const SincronizacaoGeralToast = () => {
  const [ativar, setAtivar] = useState<ISincronizacaoAtual>({
    ativo: false,
    status: EnumSincronizacaoGeralStatus.Finalizado,
    sincronizandoAtual: 0,
    tipo: EnumSincronizacaoGeral.SessaoValores,
    totalAsincronizar: 0,
  });
  const classes = useStyles();

  const { addHandler, removeHandler } = useEventTools();

  const getSincronizacaoAtual = useCallback(
    (sincAtual: ISincronizacaoAtual) => {
      setAtivar(sincAtual);
    },
    [],
  );

  useEffect(() => {
    addHandler(AppEventEnum.SincronizacaoGeral, getSincronizacaoAtual);

    return () =>
      removeHandler(AppEventEnum.SincronizacaoGeral, getSincronizacaoAtual);
  }, [addHandler, getSincronizacaoAtual, removeHandler]);

  const tpSincAtual = TpSincronizacaoGeralMock.filter(
    (x) => x.Key === ativar.tipo,
  )[0].Value;

  return (
    <>
      {ativar.ativo && (
        <div className={classes.background}>
          <Typography variant="body2" className={classes.mensagem}>
            <SincronizarIcon style={{ marginRight: 8 }} tipo="BUTTON_PRIMARY" />
            Sincronizando {tpSincAtual}... {ativar.sincronizandoAtual}/
            {ativar.totalAsincronizar}
            <LoadingSicronizacaoGeral />
          </Typography>
        </div>
      )}
    </>
  );
};
