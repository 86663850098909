import { create } from "zustand";
import { IComissionado } from "../../comissao/comissao-modal/stores/types/IComissionado";
import { persist } from "zustand/middleware";

type ApuracaoActionsKeys = 'criar' | 'apurar';

export interface IComissaoApuracaoStateProps {
    comissionados: Omit<IComissionado, 'vendas'>[];
    selectedComissionado?: IComissionado;
    blackListVendas: string[];
    apuracaoId?: string;
    ui: {
        filtroData?: string;

        acao: ApuracaoActionsKeys;
        acaoApurar?: 'comissionado' | 'vendas';
        acaoApurarComissionado?: 'deletando' | 'adicionando';
        carregando?: boolean;
        dialogTitle?: string;
        datasApuracao?: string[]
        apuracoesPagination: {
            page: number;
            totalRegisters: number;
            currentPage: number;
            totalPages?: number;
        };
    }
}

export interface IComissaoApuracaoStoreProps {
    stateComissaoApuracao: IComissaoApuracaoStateProps;
    setDatasApuracao: (datas: string[]) => void;
    setApuracoesPagination(data: { page: number, totalRegisters: number, totalPages: number }): void;
    resetStoreComissaoApuracao: () => void;
    resetBlackListVendas: () => void;
    setBlackListVendas: (data: string[]) => void;
    setComissaoApuracaoApurar: (acaoApurar: 'comissionado' | 'vendas') => void;
    setComissaoApuracaoAcao: (acao: 'criar' | 'apurar') => void;
    setComissaoApuracaoId: (id: string) => void;
    setComissaoApuracaoUICarregando: (carregando: boolean) => void;
    setComissaoApuracaoUIDialogTitle: (dialogTitle: string) => void;
    setComissaoApuracaoApurarComissionado: (acao: 'deletando' | 'adicionando') => void;
    setComissionados: (data: Omit<IComissionado, 'vendas'>[]) => void;
    setSelectedComissionado: (data: IComissionado) => void;
    resetSelectedComissionado: () => void;
    setFilroData: (data: string) => void;
}

export const useApuracaoStore = create<IComissaoApuracaoStoreProps>()(
    persist(
        (set) => ({
            // MARK: resetStoreComissaoApuracao
            resetStoreComissaoApuracao: () => {
                set(() => ({
                    stateComissaoApuracao: {
                        comissionados: [],
                        ui: {
                            acao: 'criar',
                            acaoApurar: 'comissionado',
                            acaoApurarComissionado: 'adicionando',
                            apuracoesPagination: {
                                page: 0,
                                totalRegisters: 0,
                                currentPage: 0

                            }
                        },
                        blackListVendas: []
                    }
                }));
            },
            // MARK: resetBlackListVendas
            resetBlackListVendas: () => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        blackListVendas: []
                    }
                }));
            },
            // MARK: stateComissaoApuracao
            stateComissaoApuracao: {
                comissionados: [],
                ui: {
                    acao: 'criar',
                    acaoApurar: 'comissionado',
                    acaoApurarComissionado: 'adicionando',
                    apuracoesPagination: {
                        page: 0,
                        totalRegisters: 0,
                        currentPage: 0,
                        totalPages: 0
                    }
                },
                blackListVendas: []
            },
            // MARK: setDatasApuracao
            setDatasApuracao: (datas) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            datasApuracao: datas
                        }
                    }
                }));
            },
            // MARK: setBlackListVendas
            setBlackListVendas: (data: string[]) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        blackListVendas: data
                    }
                }));
            },
            // MARK: setComissaoApuracaoId
            setComissaoApuracaoId: (apuracaoId: string) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        apuracaoId
                    }
                }));
            },
            // MARK: setComissaoApuracaoApurar
            setComissaoApuracaoApurar: (acaoApurar: 'comissionado' | 'vendas') => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            acaoApurar
                        }
                    }
                }));
            },
            // MARK: setComissaoApuracaoUICarregando
            setComissaoApuracaoUICarregando: (carregando: boolean) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            carregando
                        }
                    }
                }));
            },
            // MARK: setComissaoApuracaoUIDialogTitle
            setComissaoApuracaoUIDialogTitle: (dialogTitle: string) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            dialogTitle
                        }
                    }
                }));
            },
            // MARK: setComissaoApuracaoApurarComissionado
            setComissaoApuracaoApurarComissionado: (acao: 'deletando' | 'adicionando') => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            acaoApurarComissionado: acao
                        }
                    }
                }));
            },
            // MARK: setComissaoApuracaoAcao
            setComissaoApuracaoAcao: (acao: 'criar' | 'apurar') => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            acao
                        },

                    }
                }));
            },
            // MARK: setApuracoesPagination
            setApuracoesPagination: (data) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            apuracoesPagination: {
                                ...partial.stateComissaoApuracao.ui.apuracoesPagination,
                                ...data
                            }
                        }
                    }
                }));
            },
            // MARK: setComissionados
            setComissionados: (data: Omit<IComissionado, 'vendas'>[]) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        comissionados: data
                    }
                }));
            },
            // MARK: setSelectedComissionado
            setSelectedComissionado: (data: IComissionado) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        selectedComissionado: data
                    }
                }));
            },
            // MARK: resetSelectedComissionado
            resetSelectedComissionado: () => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        selectedComissionado: undefined
                    }
                }));
            },
            // MARK: setFilroData
            setFilroData: (data: string) => {
                set((partial) => ({
                    stateComissaoApuracao: {
                        ...partial.stateComissaoApuracao,
                        ui: {
                            ...partial.stateComissaoApuracao.ui,
                            filtroData: data
                        }
                    }
                }));
            },
        }),
        {
            name: 'comissao-apuracao-store',
        }
    )
)