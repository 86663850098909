import { useTheme } from '@material-ui/core';
import { CardComandaProps } from './card-comanda-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useCallback } from 'react';
import { ComandasStatusMock } from 'data/mocks/comandas-status-mock';
import { EnumComandas } from 'model/enums/enum-comandas';
import { Tooltip, Typography } from 'views/design-system';
export const CardComanda = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardComandaProps) => {
  const classes = useDefaultCardStyles();
  const theme = useTheme();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status.codigo) {
      case EnumComandas.ATIVO:
        return theme.palette.success.main;
      case EnumComandas.INATIVO:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status,
  ]);

  const descricao = ComandasStatusMock.filter(
    (x) => x.Key === model.status.codigo,
  )[0].Value;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Código comanda
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.codigoComanda}
            </Typography>
          </div>
          {model.rfId && (
            <div className={classes.celulaGridFull}>
              <Typography color="textPrimary" variant="caption">
                RFID
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.rfId}</strong>
              </Typography>
            </div>
          )}
        </div>
        <Tooltip arrow title="Editar Comanda">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
