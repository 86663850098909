import { Redirect, Route, Switch } from 'react-router-dom';
import MovFinalizarPage from 'views/pages/private/movimentacao/mov-finalizar/mov-finalizar-page';
import MovCancelarPage from 'views/pages/private/movimentacao/mov-cancelar/mov-cancelar-page';
import MovProdAvulsoPage from 'views/pages/private/movimentacao/mov-prod-avulso/mov-prod-avulso-page';
import MovProdCatalogoPage from 'views/pages/private/movimentacao/mov-prod-catalogo/mov-prod-catalogo-page';
import MovProdLeitorPage from 'views/pages/private/movimentacao/mov-prod-leitor/venda-leitor-page';
import { MovSelecionarPDVPage } from 'views/pages/private/movimentacao/mov-selecionar-pdv/mov-selecionar-pdv-page';
import MovSimplesLandingPage from 'views/pages/private/movimentacao/mov-simples-landing/mov-simples-landing-page';
import { MovClienteIdentificacaoPage } from 'views/pages/private/movimentacao/mov-cliente-identificacao/mov-cliente-identificacao-page';
import MovErroFinalizarPage from 'views/pages/private/movimentacao/mov-erro-finalizar/mov-erro-finalizar-page';
import { MovAtualValidationRoute } from '../mov-atual-validation-route/mov-atual-validation-route';
import { MovSelecaoVendedorPage } from 'views/pages/private/movimentacao/mov-selecao-vendedor/mov-selecao-vendedor-page';
import { MovPagamentoNovoPage } from 'views/pages/private/movimentacao/mov-pagamento-novo/mov-pagamento-novo-page';
import { MovCarrinhoPage } from 'views/pages/private/movimentacao/mov-carrinho/mov-carrinho-page';
import SincronizacaoPage from 'views/pages/private/sincronizacao-dados/sincronizar/sincronizacao-page';
import SincronizacaoSucessoErroPage from 'views/pages/private/sincronizacao-dados/sincronizacao-sucesso-erro-listagem/sincronizacao-sucesso-erro-page';
import React from 'react';
import { MovComprovantePage } from 'views/pages/private/movimentacao/mov-comprovante/mov-comprovante-page';
import { MovComprovanteDetalhePage } from 'views/pages/private/movimentacao/mov-comprovante-detalhe/mov-comprovante-detalhe-page';
import MovHistoricoPagamentoPage from 'views/pages/private/movimentacao/mov-historico-pagamento/mov-historico-pagamento-page';
import MovLeitorComandaPage from 'views/pages/private/movimentacao/mov-leitor-comanda/venda-leitor-comanda-page';
import { MovVincularMesaPage } from 'views/pages/private/movimentacao/mov-vincular-mesa/mov-vincular-mesa-page';
import MovEnviarPedidoPage from 'views/pages/private/movimentacao/mov-enviar-pedido/mov-enviar-pedido-page';
import AtendimentoPage from 'views/pages/private/movimentacao/mov-atendimento-mesas-comandas/atendimento-page';
import { MovVisualizarMesaEComandaPage } from 'views/pages/private/movimentacao/mov-visualizar-mesa-e-comanda/mov-visualizar-mesa-e-comanda-page';
import { MovSelecionarComandaFechamentoPage } from 'views/pages/private/movimentacao/mov-selecionar-comanda-fechamento/mov-selecionar-comanda-fechamento-page';
import { MovVisualizarPedidoPage } from 'views/pages/private/movimentacao/mov-visualizar-pedido/mov-visualizar-pedido';
import MovFecharComandaPage from 'views/pages/private/movimentacao/mov-fechar-pedido/mov-fechar-pedido-page';
import MovImportarComandaPage from 'views/pages/private/movimentacao/mov-importar-pedidos/mov-importar-pedidos-page';
import MovFinalizarPedidoPage from 'views/pages/private/movimentacao/mov-finalizar-pedidos/mov-finalizar-pedidos-page';
import MovCancelarImportacaoPage from 'views/pages/private/movimentacao/mov-cancelar-importacao/mov-cancelar-importacao-page';
import { MovTransferirItensPedidoPage } from 'views/pages/private/movimentacao/mov-transferir-itens-pedido/mov-transferir-itens-pedido-page';
import { MovAlterarVendedorProdutoPedidoPage } from 'views/pages/private/movimentacao/mov-selecionar-vendedor-produto/mov-selecionar-vendedor-produto-page';
import { AbrirCaixa } from 'views/pages/private/movimentacao/mov-abrir-caixa/abrir-caixa-page';
import { consoleDev } from 'utils/console-dev';
import MovGerarComandaPage from 'views/pages/private/movimentacao/mov-controle-salao/mov-gerar-comanda/mov-gerar-comanda-page';
import { MovFinalizacaoParcialComandaPage } from 'views/pages/private/movimentacao/mov-finalizacao-parcial-comanda/mov-finalizacao-parcial-comanda-page';
import { HistoricoVendaPage } from 'views/pages/private/historico-vendas/historico-vendas-page';
import MovProcessandoPagamentoPage from 'views/pages/private/movimentacao/mov-processando-pagamento/mov-processando-pagamento-page';
import MovGerarPagerIdentificadorPage from 'views/pages/private/movimentacao/mov-identificacao-pager/mov-gerar-pager-identificador-page';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import GerenciarSessaoPage from 'views/pages/private/gerenciar-sessao/sessao-seletor/geranciar-sessao-page';
import SessaoSangriaPage from 'views/pages/private/gerenciar-sessao/sessao-sangria/sessao-sangria-page';
import SessaoDespesaPage from 'views/pages/private/gerenciar-sessao/sessao-despesa/sessao-despesa-page';
import SessaoReforcoPage from 'views/pages/private/gerenciar-sessao/sessao-reforco/sessao-reforco-page';
import SessaoReceitaPage from 'views/pages/private/gerenciar-sessao/sessao-receita/sessao-receita-page';
import { SessaoLeituraCaixaPage } from 'views/pages/private/gerenciar-sessao/sessao-leitura-caixa/sessao-leitura-caixa-page';
import { SessaoFechamentoCaixaPage } from 'views/pages/private/gerenciar-sessao/sessao-fechamento-caixa/sessao-fechamento-caixa-page';
import SincronizarSessaoPage from 'views/pages/private/gerenciar-sessao/sessao-sincronizacao-dados/sessao-sincronizar/sessao-sincronizar-page';
import SincronizarSessaoFechamentoPage from 'views/pages/private/gerenciar-sessao/sessao-sincronizacao-dados/sessao-sincronizar-fechamento/sessao-sincronizar-fechamento-page';
import MovClienteDeliveryPage from 'views/pages/private/movimentacao/mov-cliente-delivery/mov-cliente-delivery-page';
import MovTaxaEntregaPage from 'views/pages/private/movimentacao/mov-taxa-entrega/mov-taxa-entrega-page';
import MovAtendimentoDeliveryPage from 'views/pages/private/movimentacao/mov-atendimento-delivery/mov-atendimento-delivery-page';

export const RouterVenda = () => {
  const movLeitorComandaPage = React.useMemo(() => {
    return <MovLeitorComandaPage></MovLeitorComandaPage>;
  }, []);

  const movSelecionarPDVPage = React.useMemo(() => {
    return <MovSelecionarPDVPage></MovSelecionarPDVPage>;
  }, []);

  const movAbrirCaixaPage = React.useMemo(() => {
    return <AbrirCaixa></AbrirCaixa>;
  }, []);
  const movComprovantePage = React.useMemo(() => {
    return <MovComprovantePage></MovComprovantePage>;
  }, []);

  const movComprovanteDetalhePage = React.useMemo(() => {
    return <MovComprovanteDetalhePage></MovComprovanteDetalhePage>;
  }, []);

  const movFinalizarPage = React.useMemo(() => {
    return <MovFinalizarPage></MovFinalizarPage>;
  }, []);

  const movSimplesLandingPage = React.useMemo(() => {
    return <MovSimplesLandingPage></MovSimplesLandingPage>;
  }, []);

  const movProdAvulsoPage = React.useMemo(() => {
    return <MovProdAvulsoPage></MovProdAvulsoPage>;
  }, []);

  const movProdLeitorPage = React.useMemo(() => {
    return <MovProdLeitorPage></MovProdLeitorPage>;
  }, []);

  const movProdCatalogoPage = React.useMemo(() => {
    return <MovProdCatalogoPage></MovProdCatalogoPage>;
  }, []);

  const movCarrinhoPage = React.useMemo(() => {
    return <MovCarrinhoPage></MovCarrinhoPage>;
  }, []);

  const movCancelarPage = React.useMemo(() => {
    return <MovCancelarPage></MovCancelarPage>;
  }, []);

  const movErroFinalizarPage = React.useMemo(() => {
    return <MovErroFinalizarPage></MovErroFinalizarPage>;
  }, []);

  const movClienteIdentificacaoPage = React.useMemo(() => {
    return <MovClienteIdentificacaoPage></MovClienteIdentificacaoPage>;
  }, []);

  const movSelecaoVendedorPage = React.useMemo(() => {
    return <MovSelecaoVendedorPage></MovSelecaoVendedorPage>;
  }, []);

  const movPagamentoNovoPage = React.useMemo(() => {
    return <MovPagamentoNovoPage></MovPagamentoNovoPage>;
  }, []);

  const movVincularMesaPage = React.useMemo(() => {
    return <MovVincularMesaPage></MovVincularMesaPage>;
  }, []);

  const movEnviarPedidoPage = React.useMemo(() => {
    return <MovEnviarPedidoPage></MovEnviarPedidoPage>;
  }, []);

  const movVisualizarMesaEComandaPage = React.useMemo(() => {
    return <MovVisualizarMesaEComandaPage></MovVisualizarMesaEComandaPage>;
  }, []);

  const movSelecionarComandaFechamentoPage = React.useMemo(() => {
    return (
      <MovSelecionarComandaFechamentoPage></MovSelecionarComandaFechamentoPage>
    );
  }, []);

  const movVisualizarPedidoPage = React.useMemo(() => {
    return <MovVisualizarPedidoPage></MovVisualizarPedidoPage>;
  }, []);

  const movHistoricoPagamento = React.useMemo(() => {
    return <MovHistoricoPagamentoPage></MovHistoricoPagamentoPage>;
  }, []);

  const movFecharComanda = React.useMemo(() => {
    return <MovFecharComandaPage></MovFecharComandaPage>;
  }, []);

  const movImportarPedidos = React.useMemo(() => {
    return <MovImportarComandaPage />;
  }, []);

  const movFinalizarPedidos = React.useMemo(() => {
    return <MovFinalizarPedidoPage />;
  }, []);

  const movCancelarImportacao = React.useMemo(() => {
    return <MovCancelarImportacaoPage />;
  }, []);

  const movTransferirItensPedido = React.useMemo(() => {
    return <MovTransferirItensPedidoPage />;
  }, []);

  const movAlterarVendedorProduto = React.useMemo(() => {
    return <MovAlterarVendedorProdutoPedidoPage />;
  }, []);

  const movGerarMesaComanda = React.useMemo(() => {
    return <MovGerarComandaPage />;
  }, []);

  const movFinalizacaoParcialComanda = React.useMemo(() => {
    return <MovFinalizacaoParcialComandaPage />;
  }, []);

  const movProcessandoPagamento = React.useMemo(() => {
    return <MovProcessandoPagamentoPage />;
  }, []);

  const movGerarPagerIdentificador = React.useMemo(() => {
    return <MovGerarPagerIdentificadorPage />;
  }, []);

  const movIdentificarClienteDelivery = React.useMemo(() => {
    return <MovClienteDeliveryPage />;
  }, []);


  const movTaxaEntregaPage = React.useMemo(() => {
    return <MovTaxaEntregaPage />
  }, [])

  const movAtendimentoDeliveryPage = React.useMemo(() => {
    return <MovAtendimentoDeliveryPage />
  }, [])

  const switchVendaComMov = React.useMemo(() => {
    consoleDev('routerVenda-switchVendaInterno');

    return (
      <>
        <Route path={MovRotasMock.pedidosRota} component={AtendimentoPage} />
        <Route path={MovRotasMock.historicoVendas} component={HistoricoVendaPage} />
        <Route exact path={MovRotasMock.finalizacaoParcialRota}>
          {movFinalizacaoParcialComanda}
        </Route>
        <Route exact path={MovRotasMock.pedidoMesaVincularRota}>
          {movVincularMesaPage}
        </Route>
        <Route exact path={MovRotasMock.visualizarPedidoRota + "/:tipoTrabalhoId"}>
          {movVisualizarPedidoPage}
        </Route>
        <Route exact path={MovRotasMock.mesaEComandaRota + "/:mesaId"}>
          {movVisualizarMesaEComandaPage}
        </Route>
        <Route exact path={MovRotasMock.transferirItensRota}>
          {movTransferirItensPedido}
        </Route>
        <Route exact path={MovRotasMock.fechamentoComandaRota}>
          {movSelecionarComandaFechamentoPage}
        </Route>
        <Route exact path={MovRotasMock.fecharComandaRota}>
          {movFecharComanda}
        </Route>
        <Route exact path={MovRotasMock.pedidoAlterarVendedor}>
          {movAlterarVendedorProduto}
        </Route>
        <Route exact path={MovRotasMock.importarPedidosRota}>
          {movImportarPedidos}
        </Route>
        <Route exact path={MovRotasMock.lancamentoAvulsoRota}>
          {movProdAvulsoPage}
        </Route>
        <Route exact path={MovRotasMock.produtoLeitorRota}>
          {movProdLeitorPage}
        </Route>
        <Route exact path={MovRotasMock.enviarPedidoRota}>
          {movEnviarPedidoPage}
        </Route>
        <Route exact path={MovRotasMock.catalogoRota}>
          {movProdCatalogoPage}
        </Route>
        <Route exact path={MovRotasMock.carrinhoRota}>
          {movCarrinhoPage}
        </Route>
        <Route exact path={MovRotasMock.cancelarRota + '/:id?'}>
          {movCancelarPage}
        </Route>
        <Route exact path={MovRotasMock.pedidoComandaVincularRota}>
          {movLeitorComandaPage}
        </Route>
        <Route path={MovRotasMock.finalizarVendaRejeicaoRota} exact>
          {movErroFinalizarPage}
        </Route>
        <Route path={MovRotasMock.identificarClienteRota} exact>
          {movClienteIdentificacaoPage}
        </Route>
        <Route path={MovRotasMock.identificarVendedorRota} exact>
          {movSelecaoVendedorPage}
        </Route>
        <Route path={MovRotasMock.novoPagamentoRota}>
          {movPagamentoNovoPage}
        </Route>
        <Route path={MovRotasMock.pagamentoHistoricoRota}>
          {movHistoricoPagamento}
        </Route>
        <Route path={MovRotasMock.pedidoIdentificadorVincularRota}>
          {movGerarPagerIdentificador}
        </Route>
        <Route path={MovRotasMock.identificarClienteDelivery}>
          {movIdentificarClienteDelivery}
        </Route>
        <Route path={MovRotasMock.entregaFreteDelivery}>
          {movTaxaEntregaPage}
        </Route>
        <Route path={MovRotasMock.atendimentoDeliveryRota}>
          {movAtendimentoDeliveryPage}
        </Route>
      </>
    );
  }, [movAlterarVendedorProduto, movAtendimentoDeliveryPage, movCancelarPage, movCarrinhoPage, movClienteIdentificacaoPage, movEnviarPedidoPage, movErroFinalizarPage, movFecharComanda, movFinalizacaoParcialComanda, movGerarPagerIdentificador, movHistoricoPagamento, movIdentificarClienteDelivery, movImportarPedidos, movLeitorComandaPage, movPagamentoNovoPage, movProdAvulsoPage, movProdCatalogoPage, movProdLeitorPage, movSelecaoVendedorPage, movSelecionarComandaFechamentoPage, movTaxaEntregaPage, movTransferirItensPedido, movVincularMesaPage, movVisualizarMesaEComandaPage, movVisualizarPedidoPage]);

  const switchVenda = React.useMemo(() => {
    consoleDev('routerVenda-switchVenda');
    return (
      <Switch>
        <Route exact path={MovRotasMock.cancelarImportacaoRota}>
          {movCancelarImportacao}
        </Route>
        <Route exact path={MovRotasMock.finalizarPedidosRota}>
          {movFinalizarPedidos}
        </Route>
        <Route exact path={MovRotasMock.selecionarPDVRota}>
          {movSelecionarPDVPage}
        </Route>
        <Route exact path={MovRotasMock.abrirCaixaRota}>
          {movAbrirCaixaPage}
        </Route>
        <Route path={MovRotasMock.finalizarVendaComprovanteRota} exact>
          {movComprovantePage}
        </Route>
        <Route path={MovRotasMock.finalizarVendaDetalheRota} exact>
          {movComprovanteDetalhePage}
        </Route>
        <Route path={MovRotasMock.pagamentoProcessarRota}>
          {movProcessandoPagamento}
        </Route>
        <Route path={MovRotasMock.finalizarVendaRota} exact>
          {movFinalizarPage}
        </Route>
        <Route exact path={MovRotasMock.landingRota}>
          {movSimplesLandingPage}
        </Route>
        <Route exact path={MovRotasMock.pedidosEntrada}>
          {movGerarMesaComanda}
        </Route>
        <Route
          path={PdvRotasMock.sincronizarDadosRota}
          component={SincronizacaoPage}
        />
        <Route
          path={PdvRotasMock.sincronizarResumoRota}
          component={SincronizacaoSucessoErroPage}
        />
        <Route path={PdvRotasMock.gerenciarSessao} component={GerenciarSessaoPage} />
        <Route path={PdvRotasMock.gerenciarSessaoSangria} component={SessaoSangriaPage} />
        <Route path={PdvRotasMock.gerenciarSessaoDespesa} component={SessaoDespesaPage} />
        <Route path={PdvRotasMock.gerenciarSessaoReforco} component={SessaoReforcoPage} />
        <Route path={PdvRotasMock.gerenciarSessaoReceita} component={SessaoReceitaPage} />
        <Route path={PdvRotasMock.gerenciarSessaoLeituraCaixa} component={SessaoLeituraCaixaPage} />
        <Route path={PdvRotasMock.gerenciarSessaoFechamentoCaixa} component={SessaoFechamentoCaixaPage} />
        <Route path={PdvRotasMock.gerenciarSessaoSincronizar} component={SincronizarSessaoPage} />
        <Route path={PdvRotasMock.gerenciarSessaoSincronizarFechamento} component={SincronizarSessaoFechamentoPage} />

        <MovAtualValidationRoute>{switchVendaComMov}</MovAtualValidationRoute>
        <Route>
          <Redirect to={MovRotasMock.landingRota} />
        </Route>
      </Switch>
    );
  }, [movCancelarImportacao, movFinalizarPedidos, movSelecionarPDVPage, movAbrirCaixaPage, movComprovantePage, movComprovanteDetalhePage, movProcessandoPagamento, movFinalizarPage, movSimplesLandingPage, movGerarMesaComanda, switchVendaComMov]);
  return switchVenda;
};
