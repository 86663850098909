import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  containerRegistros: {
    background: '#fff',
    padding: 8,
    boxShadow: '0px 2.5px 4px 0px #28293D26',
    height: '41px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: 18
  },
  listContainer: {
    "& .card": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
  content: {
    flex: '1 1 auto',
    height: 0,
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  list: {
  },
  acoes: {
    flex: 0,
    padding: theme.spacing(2),
  },
  botaoSelecionar: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    minHeight: '30px',
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    padding: 3,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: theme.palette.common.white,
    "& strong": {
      paddingLeft: theme.spacing(1)
    },
    "& svg": {
      fill: theme.palette.text.primary,
      width: 22,
      height: 22,
    }
  },
  check: {
    "& svg": {
      fill: theme.palette.primary.main,
      marginLeft: 8,
    }
  },
  paginacao: {
    padding: 0,
    margin: 0,
  },
}));
