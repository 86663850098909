import { Box, Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { OkIcon } from 'views/components/icons';
import { useCadastros, useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormProdutoMotivoCancelamentoValidation } from './dialog-produto-motivo-cancelamento-validations';
import { isEmpty } from 'lodash';
import { DialogProdutoMotivoCancelamentoProps } from './dialog-produto-motivo-cancelamento-props';
import { useThemeQueries } from 'views/theme';
import { useCallback, useMemo, useState } from 'react';
import { useStyles } from './dialog-produto-motivo-cancelamento-styles';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { FormControlLabel, RadioGroup } from '@material-ui/core';

class ProdutoMotivoCancelamentoForm {
  constructor(
    public motivoCancelamento: string = ''
  ) { }
}

export const DialogProdutoMotivoCancelamento = ({
  ...props
}: DialogProdutoMotivoCancelamentoProps) => {

  const { theme } = useThemeQueries()
  const { fecharProdutoMotivoCancelamento } = useCadastros()
  const { showToast } = useToastSaurus()
  const classes = useStyles()
  const { formProdutoMotivoCancelamentoYupValidation } = useFormProdutoMotivoCancelamentoValidation()
  const [motivo, setMotivo] = useState<string>('')

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ProdutoMotivoCancelamentoForm>({
    defaultValues: {
      motivoCancelamento: ''
    },
    resolver: yupResolver(formProdutoMotivoCancelamentoYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const motivos = useMemo(() => [
    'Tempo de Espera Prolongado',
    'Erro no Pedido',
    'Mudança de Opinião',
    'Qualidade Insatisfatória',
    'Itens Alergicos',
    'Atendimento Ruim',
    'Preço Incompatível',
    'Pedido Duplicado',
    'Pedido Realizado por Engano',
    'Problemas no Ambiente',
    'Outro, especifique:'
  ], [])

  const submit = useCallback(async (value: ProdutoMotivoCancelamentoForm) => {
    try {
      const isNotMotivoCampo = motivos[motivos.length - 1] !== motivo
      props.callback(isNotMotivoCampo ? motivo : value.motivoCancelamento)

      fecharProdutoMotivoCancelamento()
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [fecharProdutoMotivoCancelamento, motivo, motivos, props, showToast])

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setMotivo(ev.target.value)
  }

  return (
    <DialogSaurus
      aberto={props.aberto}
      titulo="Motivo do Cancelamento"
      tamanho="sm"
      isButtonTitleClose={true}
      colorTitulo={theme.palette.primary.main}
      handleClickClose={fecharProdutoMotivoCancelamento}
      bottomArea={
        <Grid px={2} className={classes.containerButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            rounded
            type="submit"
            onClick={() => handleSubmit(submit)()}
          >
            <OkIcon tipo='BUTTON_PRIMARY' />
            Confirmar
          </Button>
        </Grid>
      }
    >
      <form onSubmit={handleSubmit(submit)}>
        <Typography>
          <Box mb={2}>
            Informe o Motivo do Cancelamento(opcional)
          </Box>
        </Typography>
        <RadioGroup aria-label="motivoCancelamento" value={motivo} onChange={handleChange}>
          {motivos.map(x => (
            <div>
              <FormControlLabel
                key={x}
                value={x}
                label={x}
                control={
                  <SaurusRadio
                    checked={x === motivo}
                  />
                } />

            </div>
          ))}
        </RadioGroup>
        <div>
          <Controller
            name='motivoCancelamento'
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                {...field}
                placeholder={'Digitar o motivo'}
                disabled={motivos[motivos.length - 1] !== motivo}
                error={!isEmpty(errors.motivoCancelamento)}
                multiline
                minRows={2}
                allowSubmit
                helperText={errors.motivoCancelamento
                  ? errors.motivoCancelamento?.message
                  : undefined}
              />
            )}
          />
        </div>
      </form>
    </DialogSaurus>
  )
};
