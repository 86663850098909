import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { guidEmpty } from 'utils/guid-empty';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';

export interface Comissionado {
  id: string;
  internalId: string;
  tipo: EnumTpPessoaComissionamento;
  nome: string;
  pessoaId: string;
  documento: string;
  condicao: number;
  valor: number;
  vendedores?: Comissionado[];
  novo?: boolean;
  removido?: boolean;
}

interface IStateStore {
  stepComissionados: number;
  comissionadoGerenteGeral: Comissionado[];
  comissionadoGerentes: Comissionado[];
  comissionadoVendedores: Comissionado[];
  gerenteGeralAtual: Comissionado | undefined;
  gerenteAtual: Comissionado | undefined;
  vendedoresAtual: Comissionado[];
}

interface IComissaoComissionadosStore {
  state: IStateStore;
  setComissionadoGerenteGeral: (data: Comissionado) => void;
  removerComissionadoGerenteGeral: (data: Comissionado) => void;
  setComissionadoGerentes: (data: Comissionado) => void;
  removerComissionadoGerentes: (data: Comissionado) => void;
  setGerenteGeralAtual: (data: Comissionado | undefined) => void;
  setGerenteAtual: (data: Comissionado | undefined) => void;
  setComissionadoVendedores: (data: Comissionado[]) => void;
  setVendedoresAtual: (data: Comissionado[]) => void;
  removerComissionadoVendedorGerente: (
    idGerente: string,
    data: Comissionado
  ) => void;
  editComissionadoGerente: (data: Comissionado) => void;
  removerComissionadoVendedor: (data: Comissionado) => void;
  resetStoreComissionados: () => void;
  setStatePessoasComissionados: (
    gerentesGerais: Comissionado[],
    gerentes: Comissionado[],
    vendedores: Comissionado[]
  ) => void;
}

export const useComissionadosStore = create<IComissaoComissionadosStore>()(
  persist(
    (set, get) => ({
      state: {
        stepComissionados: 0,
        comissionadoGerenteGeral: [],
        comissionadoGerentes: [],
        comissionadoVendedores: [],
        vendedoresAtual: [],
        gerenteGeralAtual: undefined,
        gerenteAtual: undefined
      },
      setComissionadoGerenteGeral(data: Comissionado) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoGerenteGeral: [
              ...state.state.comissionadoGerenteGeral,
              data
            ]
          }
        }));
      },
      removerComissionadoGerenteGeral(data: Comissionado) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoGerenteGeral:
              data.id !== guidEmpty()
                ? [
                  ...state.state.comissionadoGerenteGeral.map((gg) => {
                    if (gg.internalId === data.internalId) {
                      return {
                        ...gg,
                        removido: true
                      };
                    }
                    return gg;
                  })
                ]
                : [
                  ...state.state.comissionadoGerenteGeral.filter(
                    (gg) => gg.id !== data.id
                  )
                ]
          }
        }));
      },
      setGerenteGeralAtual(data: Comissionado | undefined) {
        set((state) => ({
          state: {
            ...state.state,
            gerenteGeralAtual: data
          }
        }));
      },
      setComissionadoGerentes(data: Comissionado) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoGerentes: [...state.state.comissionadoGerentes, data]
          }
        }));
      },
      removerComissionadoGerentes(data: Comissionado) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoGerentes:
              data.id !== guidEmpty()
                ? [
                  ...state.state.comissionadoGerentes.map((g) => {
                    if (g.internalId === data.internalId) {
                      let gerente = g;

                      if ((gerente.vendedores?.length ?? 0) > 0) {
                        gerente.vendedores = gerente.vendedores
                          ?.filter((v) => v.id !== guidEmpty())
                          ?.map((v) => {
                            return { ...v, removido: true };
                          });
                      }

                      return {
                        ...gerente,
                        removido: true
                      };
                    }
                    return g;
                  })
                ]
                : [
                  ...state.state.comissionadoGerentes.filter(
                    (g) => g.internalId !== data.internalId
                  )
                ]
          }
        }));
      },
      setGerenteAtual(data: Comissionado | undefined) {
        set((state) => ({
          state: {
            ...state.state,
            gerenteAtual: data
          }
        }));
      },
      setComissionadoVendedores(data: Comissionado[]) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoVendedores: [
              ...state.state.comissionadoVendedores,
              ...data
            ]
          }
        }));
      },
      setVendedoresAtual(data: Comissionado[]) {
        set((state) => ({
          state: {
            ...state.state,
            vendedoresAtual: data
          }
        }));
      },
      removerComissionadoVendedorGerente(
        idGerente: string,
        data: Comissionado
      ) {
        const gerente = get().state.comissionadoGerentes.find(
          (g) => g.internalId === idGerente
        );

        if (!gerente) {
          return;
        }

        set((state) => ({
          state: {
            ...state.state,
            comissionadoGerentes: state.state.comissionadoGerentes.map((g) => {
              if (g.internalId === gerente.internalId) {
                return {
                  ...g,
                  vendedores:
                    data.id !== guidEmpty()
                      ? g.vendedores?.map((v) => {
                        if (v.internalId === data.internalId) {
                          return {
                            ...v,
                            removido: true
                          };
                        }
                        return v;
                      })
                      : g.vendedores?.filter(
                        (v) => v.internalId !== data.internalId
                      )
                };
              }

              return g;
            })
          }
        }));
      },
      editComissionadoGerente(data: Comissionado) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoGerentes: state.state.comissionadoGerentes.map((g) => {
              if (g.internalId === data.internalId) {
                return data;
              }
              return g;
            })
          }
        }));
      },
      removerComissionadoVendedor(data: Comissionado) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoVendedores:
              data.id !== guidEmpty()
                ? state.state.comissionadoVendedores.map((v) => {
                  if (v.internalId === data.internalId) {
                    return {
                      ...v,
                      removido: true
                    };
                  }
                  return v;
                })
                : [
                  ...state.state.comissionadoVendedores.filter(
                    (v) => v.internalId !== data.internalId
                  )
                ]
          }
        }));
      },
      resetStoreComissionados() {
        set(() => ({
          state: {
            stepComissionados: 0,
            comissionadoGerenteGeral: [],
            comissionadoGerentes: [],
            comissionadoVendedores: [],
            vendedoresAtual: [],
            gerenteGeralAtual: undefined,
            gerenteAtual: undefined
          }
        }));
      },
      setStatePessoasComissionados(gerentesGerais, gerentes, vendedores) {
        set((state) => ({
          state: {
            ...state.state,
            comissionadoVendedores: vendedores,
            comissionadoGerenteGeral: gerentesGerais,
            comissionadoGerentes: gerentes
          }
        }));
      }
    }),
    {
      name: 'comissoes-comissionados-store'
    }
  )
);
