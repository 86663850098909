import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback } from 'react';
import { CarrinhoListPedido } from 'views/pages/private/movimentacao/mov-carrinho/components/cart-wrapper/components/carrinho-list/carrinho-list-data';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { useContratoAtual } from './contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

export const useAgruparProdutos = (list: Array<MovSimplesProdutoModel>) => {
  // HOOKS
  const { getConfigByCod } = useContratoAtual();

  const produtosVendaAdicionais =
    list?.filter(
      (produto) =>
        produto.pedidoId === '' &&
        (produto.indFin || (produto.ativo && (produto.tpProduto === EnumTpProduto.ProdutoComSubItem || produto.tpProduto === EnumTpProduto.Produto || produto.tpProduto === EnumTpProduto.Combo || produto.tpProduto === EnumTpProduto.Medicamento))) &&
        produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoServico) &&
        produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoEntrega)
    ) ?? [];

  const produtosPedidosAdicionais = useCallback(
    () =>
      list?.filter(
        (produto) =>
          produto.indFin &&
          produto.pedidoId !== '' &&
          produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoServico) &&
          produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoEntrega)
      ) ?? [],
    [getConfigByCod, list]
  );

  const produtosPedidos = useCallback(
    () => {
      const adicionaisList = produtosPedidosAdicionais()
      return list
        ?.filter(
          (produto) =>
            produto.pedidoId !== '' && (produto.tpProduto === EnumTpProduto.ProdutoComSubItem || produto.tpProduto === EnumTpProduto.Produto || produto.tpProduto === EnumTpProduto.Combo || produto.tpProduto === EnumTpProduto.Medicamento)
        ).filter(p => {
          if (p.tpProduto === EnumTpProduto.Combo || p.indFin || (p.tpProduto === EnumTpProduto.ProdutoComSubItem && !p.produtoPaiId && p.subItens.length > 0)) {
            return p
          }

          return null
        })
        .map((p) => {
          const adicionais = adicionaisList.filter(
            (adicional) => adicional.idGroup === p.id
          );
          if (adicionais.length > 0) {
            return {
              ...p,
              adicionais: adicionais
            };
          }
          return p;
        }) ?? []
    }
    ,
    [list, produtosPedidosAdicionais]
  );

  const agruparProdutosPorPedido = useCallback(() => {
    const produtosAgrupados: CarrinhoListPedido[] = [];
    const produtosPedido = produtosPedidos()
    for (var i = 0; i < produtosPedido.length; i++) {
      let produtoPedido = produtosPedido[i]
      var pedidoIgual = false;
      for (var j = 0; j < i; j++) {
        if (
          produtosAgrupados[j] &&
          produtoPedido.pedidoId === produtosAgrupados[j].pedidoId
        ) {
          produtosAgrupados[j].produtos.push(produtoPedido);
          pedidoIgual = true;
          break;
        }
      }

      if (!pedidoIgual) {
        produtosAgrupados.push({
          codigoPedido: produtoPedido.codigoPedido,
          pedidoId: produtoPedido.pedidoId,
          comandaId: produtoPedido.comandaId,
          mesaId: produtoPedido.mesaId,
          produtos: [produtoPedido],
          identificador: produtoPedido.identificador ?? ""
        });
      }
    }

    return produtosAgrupados;
  }, [produtosPedidos]);

  const agruparProdutosPorAdicionais = () => {
    const produtosAgrupados: MovSimplesProdutoModel[] =
      produtosVendaAdicionais.filter((p) => !p.produtoPaiId && (p.tpProduto === EnumTpProduto.ProdutoComSubItem || p.tpProduto === EnumTpProduto.Produto || p.tpProduto === EnumTpProduto.Combo || p.tpProduto === EnumTpProduto.Medicamento));
    return produtosAgrupados.map((prodAgrupado) => {
      const prodAdicional = produtosVendaAdicionais.filter(
        (adicional) => adicional.idGroup === prodAgrupado.id && adicional.tpProduto !== EnumTpProduto.Combo && adicional.tpProduto !== EnumTpProduto.ProdutoComSubItem && adicional.tpProduto !== EnumTpProduto.Produto && adicional.tpProduto !== EnumTpProduto.Medicamento
      );

      if (prodAdicional.length > 0) {
        return {
          ...prodAgrupado,
          adicionais: prodAdicional
        };
      }

      return prodAgrupado;
    });
  };

  return {
    agruparProdutosPorPedido,
    agruparProdutosPorAdicionais
  };
};
