import { PontosVendaModel } from 'model/api/gestao/pontos-venda';
import CardPontosVendaSimples from 'views/components/cards/card-pontos-venda-simples/card-pontos-venda-simples';
import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { useCallback } from 'react';

export interface PDVGridProps {
  list: Array<PontosVendaModel>;
  carregando: boolean;
  onCardSelected: (pdv: PontosVendaModel) => any;
}

export const PDVListData = ({ list, onCardSelected, carregando }: PDVGridProps) => {
  const onCardSelectedWrapper = useCallback((pdv: PontosVendaModel) => {
    onCardSelected(pdv);
  }, [onCardSelected]);

  return (
    <>
      {list.length === 0 && !carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum caixa encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {list.length > 0 &&
        list.map((pdv) => {
          return (
            <CardPontosVendaSimples onClick={onCardSelectedWrapper} model={pdv} key={pdv.id} />
          );
        })}
    </>
  );
};
