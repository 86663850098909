import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { ConvenioModel } from 'model/api/gestao/convenio/convenio-model';
import CardConvenio from 'views/components/cards/card-convenio/card-convenio';

export interface ConvenioGridProps {
  list: Array<ConvenioModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const ConvenioListData = (props: ConvenioGridProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Convênio encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((pessoa, index) => {
          return (
            <CardConvenio
              selected={
                props.selectedList.filter((item) => item === pessoa.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={pessoa}
              key={index}
            />
          );
        })}
    </>
  );
};
