import { CardImpressoraProps } from './card-impressora-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { Tooltip, Typography } from 'views/design-system';

export const CardImpressora = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardImpressoraProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <div className={classes.cardContent}>
          <div
            className={classes.celulaGridFull}
            style={{ whiteSpace: 'break-spaces' }}
          >
            <Typography color="primary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>
                {model.descricao}
              </strong>
            </Typography>
          </div>
          <div
            className={classes.celulaGrid}
            style={{ whiteSpace: 'break-spaces', minWidth: 200 }}
          >
            <Typography color="primary" variant="caption">
              Modelo
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.modelo}
            </Typography>
          </div>
          <div
            className={classes.celulaGrid}
            style={{ whiteSpace: 'break-spaces' }}
          >
            <Typography color="primary" variant="caption">
              Caminho
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.caminho}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Impressora">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
