import { EnumSincronizacao } from './../../model/enums/enum-sincronizacao';
import { KeyValueModel } from "model";

export const TpSincronizacaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumSincronizacao.PRODUTOS, "Produtos"),
    new KeyValueModel(EnumSincronizacao.PROMOCOES_DEPOR, "Promoções"),
    new KeyValueModel(EnumSincronizacao.MEDICAMENTOS, "Medicamentos"),
    new KeyValueModel(EnumSincronizacao.CLIENTES, "Clientes"),
    new KeyValueModel(EnumSincronizacao.CONFIGURACOES, "Configurações"),
    new KeyValueModel(EnumSincronizacao.FINALIZADORAS, "Finalizadoras"),
    new KeyValueModel(EnumSincronizacao.NCMS, "Ncms"),
    new KeyValueModel(EnumSincronizacao.IMPOSTOS, "Impostos"),
    new KeyValueModel(EnumSincronizacao.CATEGORIAS, "Categorias"),
    new KeyValueModel(EnumSincronizacao.MESAS, "Mesas"),
    new KeyValueModel(EnumSincronizacao.COMANDAS, "Comandas"),
    new KeyValueModel(EnumSincronizacao.SALOES, "Salões"),
    new KeyValueModel(EnumSincronizacao.MODIFICADORES, "Modificadores"),
];
