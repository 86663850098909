import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useFormMarcaEditValidation } from './form-marca-edit-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { IndEscalaMock } from 'data/mocks/ind-escala-mock';
import { Box, Button, Grid } from 'views/design-system';

export interface FormMarcaEditProps extends DefaultFormProps<MarcaModel> {
  setCarregandoExterno(carregando: boolean): void;
}
export const FormMarcaEdit = forwardRef<
  DefaultFormRefs<MarcaModel>,
  FormMarcaEditProps
>(({ loading, ...props }: FormMarcaEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<MarcaModel>(
    new MarcaModel(),
  );
  const { FormMarcaEditYupValidation } = useFormMarcaEditValidation();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<MarcaModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormMarcaEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: MarcaModel) => {
    const ncm = picker<MarcaModel>(values, new MarcaModel());

    props.onSubmit(ncm, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInputCodigo.current?.focus();
    },
    fillForm: (model: MarcaModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInputCodigo.current?.focus();
      }, 500);
    },
  }));
  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      label="Nome"
                      variant="outlined"
                      error={Boolean(
                        errors.nome && errors.nome.message,
                      )}
                      helperText={
                        touchedFields.nome || errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cnpjFab"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      tipo="CNPJ"
                      allowSubmit
                      label="CNPJ"
                      variant="outlined"
                      error={Boolean(
                        errors.cnpjFab && errors.cnpjFab.message,
                      )}
                      helperText={
                        touchedFields.cnpjFab || errors.cnpjFab
                          ? errors.cnpjFab?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="indEscala"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      variant="outlined"
                      fullWidth
                      disabled={loading}
                      conteudo={IndEscalaMock}
                      label={'Indicador de Escala Relevante'}
                      {...field}
                      error={Boolean(
                        errors.indEscala && errors.indEscala.message,
                      )}
                      helperText={
                        touchedFields.indEscala || errors.indEscala
                          ? errors.indEscala?.message
                          : undefined
                      }
                      onChange={(event) => {
                        if (event) {
                          const valor = IndEscalaMock.filter(
                            (item) => item.Key === event.target.value
                          )[0]?.Key;
                          setValue('indEscala', valor);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
