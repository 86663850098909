import {
  CampanhaComissaoModel,
} from 'model/api/gestao/comissao/comissao-model';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { EnumTpModeloApuracao } from 'model/enums/enum-tp-modelo-apuracao';

interface IStateStore {
  stepCampanha: number;
  comissao: CampanhaComissaoModel;
}

interface ISetCampanhaStep1 {
  nome: string;
  modeloApuracao: EnumTpModeloApuracao;
  empresaId: string
}

interface ISetCampanhaStep2 {
  dataInicial: string;
  dataFinal: string;
  horarioInicio: number;
  horarioFinal: number;
  diasSemana: number;
  modeloApuracao: EnumTpModeloApuracao;
}



interface IComissaoStore {
  state: IStateStore;
  setComissaoStep1: (data: ISetCampanhaStep1) => void;
  setComissaoStep2: (data: ISetCampanhaStep2) => void;
  resetStoreComissao: () => void;
  setComissao: (data: CampanhaComissaoModel) => void;
  setStatus: () => void;
}

export const useComissaoStore = create<IComissaoStore>()(
  persist(
    (set) => ({
      state: {
        stepCampanha: 0,
        comissao: new CampanhaComissaoModel()
      },
      // MARK: setComissaoStep1
      setComissaoStep1: (data: ISetCampanhaStep1) => {
        set((state) => ({
          state: {
            ...state.state,
            comissao: {
              ...state.state.comissao,
              ...data
            }
          }
        }));
      },
      // MARK: setComissaoStep2
      setComissaoStep2: (data: ISetCampanhaStep2) => {
        set((state) => ({
          state: {
            ...state.state,
            comissao: {
              ...state.state.comissao,
              ...data
            }
          }
        }));
      },
      // MARK: resetStoreComissao
      resetStoreComissao() {
        set(() => ({
          state: {
            stepCampanha: 0,
            comissao: new CampanhaComissaoModel()
          }
        }));
      },
      // MARK: setComissao
      setComissao(data: CampanhaComissaoModel) {
        set(() => ({
          state: {
            stepCampanha: 0,
            comissao: {
              ...data,
              dataInicial: data.dataInicial.length > 0 ? data.dataInicial.split('T')?.[0] ?? '' : '',
              dataFinal: data.dataFinal.length > 0 ? data.dataFinal.split('T')?.[0] ?? '' : ''
            }
          }
        }));
      },
      // MARK: setStatus
      setStatus() {
        set((state) => ({
          state: {
            stepCampanha: 0,
            comissao: {
              ...state.state.comissao,
              status: state.state.comissao.status === 1 ? 0 : 1
            }
          }
        }));
      }
    }),
    {
      name: 'comissoes-comissao-store',
    }
  )
);
