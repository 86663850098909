import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormGerenciarInfoConvenioValidation } from './form-gerenciar-info-convenio-validation';
import { GerenciarInfoConvenioFormModel } from 'model/app/forms/convenio/gerenciar-info-convenio-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { Box, Button, Grid } from 'views/design-system';

export const FormGerenciarInfoConvenio = forwardRef<
    DefaultFormRefs<GerenciarInfoConvenioFormModel>,
    DefaultFormProps<GerenciarInfoConvenioFormModel>
>((props: DefaultFormProps<GerenciarInfoConvenioFormModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const refInputCnpjCpf = useRef<HTMLInputElement>(null);
    const { isMobile } = useThemeQueries();
    const { FormGerenciarInfoConvenioValidation } = useFormGerenciarInfoConvenioValidation();
    const [modelForm, setModelForm] = useState<GerenciarInfoConvenioFormModel>(new GerenciarInfoConvenioFormModel())
    const { getConfigByCod } = useEmpresaAtual()

    // O valor sempre vai ser 0 ou 1, mas vem como string, então já entendeu né
    const permiteConfigDiaFechamentoPagamento = Boolean(Number(getConfigByCod(EnumEmpresaConfig.PermiteConfigurarDiaFechamentoPagamento)))

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
    } = useForm<GerenciarInfoConvenioFormModel>({
        resolver: yupResolver(FormGerenciarInfoConvenioValidation),
        criteriaMode: 'all',
        mode: 'onChange',
        defaultValues: modelForm
    });

    const onSubmit = (values: GerenciarInfoConvenioFormModel) => {
        props.onSubmit(values, modelForm);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputCnpjCpf.current?.focus();
        },
        fillForm: (model: GerenciarInfoConvenioFormModel) => {
            if (!isMobile) refInputCnpjCpf.current?.focus();
            setModelForm(model)
            reset(model)
        },
    }));

    const diasDoMesMock = useCallback(() => {
        const diasMesMock: KeyValueModel[] = []
        for (let i = 1; i <= 30; i++) {
            diasMesMock.push(new KeyValueModel(i, i.toString()))
        }
        return diasMesMock
    }, [])

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Box my={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <Controller
                                    name="diaFechamento"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            conteudo={diasDoMesMock()}
                                            disabled={props.loading || !permiteConfigDiaFechamentoPagamento}
                                            allowSubmit
                                            fullWidth
                                            variant="outlined"
                                            label="Dia de Fechamento"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.diaFechamento && errors.diaFechamento.message,
                                            )}
                                            helperText={
                                                touchedFields.diaFechamento || errors.diaFechamento
                                                    ? errors.diaFechamento?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Controller
                                    name="diasParaPagamento"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            conteudo={diasDoMesMock()}
                                            disabled={props.loading || !permiteConfigDiaFechamentoPagamento}
                                            allowSubmit
                                            fullWidth
                                            variant="outlined"
                                            label="Dias para Pagamento"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.diasParaPagamento && errors.diasParaPagamento.message,
                                            )}
                                            helperText={
                                                touchedFields.diasParaPagamento || errors.diasParaPagamento
                                                    ? errors.diasParaPagamento?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
