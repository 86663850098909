import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({

    containerListCard: {
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: theme.spacing(3),
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            margin: 0,
        },
    },
    containerGrupo: {
        // flexGrow: 1,
        // flexShrink: 0,
        // flexBasis: '50%',

    },

    containertitle: {
        margin: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
        },
    },

}));
