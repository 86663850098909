import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        border: 'none',
        height: '1px',
        margin: '0',
        flexShrink: 0,
        backgroundColor: 'rgba(85, 85, 85, 0.5)',
    },
    inset: {
        marginLeft: theme.spacing(9),
    },
    middle: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));
