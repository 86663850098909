import { useStyles } from './finalizar-venda-button-styles';
import { useToastSaurus } from 'services/app';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useCallback, useEffect, useRef } from 'react';
import { AppEventEnum } from 'model/enums/enum-app-event';
import classNames from 'classnames';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { CircularLoading } from 'views/components';
import { Button, Grid } from 'views/design-system';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';

interface Props {
  disabled?: boolean;
  paddingLeft?: boolean;
  label?: string
  noCarrinho?: boolean;
}

export const FinalizarVendaButton = ({
  disabled,
  paddingLeft = false,
  label,
  noCarrinho
}: Props) => {
  const refFinalizar = useRef<HTMLDivElement>(null);

  const { getMov, carregando } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();

  const retTxtBotao = useCallback(() => {
    if (label) {
      return label
    }

    const mov = getMov();
    const prodServico = getContratoConfigByCod(EnumContratoConfig.ProdutoServico);
    const prodEntrega = getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega);

    const qtdProdutos = mov?.produtos.filter(produtoPai => [EnumTpProduto.Produto, EnumTpProduto.ProdutoComSubItem, EnumTpProduto.Combo, EnumTpProduto.Medicamento].includes(produtoPai.tpProduto) && produtoPai.ativo)

    const qtdSomada = qtdProdutos?.reduce((acc, current) => acc + current.qCom, 0) ?? 0;

    let quantidade = qtdSomada;
    //Davi 30/09/2024 - Ajuste para não considerar o valor de serviços na finalização da venda pois está mostrando errado para finalizar.
    let vNF = (mov?.vNF ?? 0);// - (mov?.mod === EnumMovModelo.PEDIDO ? mov?.vServicos : 0); 

    const prodTaxaNaVenda = mov?.produtos.filter(
      (prod) => prod.produtoId === prodServico || prod.produtoId === prodEntrega
    );

    if (prodTaxaNaVenda) {
      quantidade =
        quantidade <= 0
          ? quantidade
          : quantidade - (prodTaxaNaVenda.length ?? 0);
    }

    if (mov?.isFaturada)
      return `Finalizar ${quantidade > 1 ? formatDecimalInteger(quantidade) + ' itens ' : ''} R$ ${toDecimalString(vNF ?? 0, 2)}`;
    else
      return `Faturar ${quantidade > 1 ? formatDecimalInteger(quantidade) + ' itens ' : ''} R$ ${toDecimalString(vNF ?? 0, 2)}`;


  }, [getContratoConfigByCod, getMov, label]);

  const movProdAlterado = useCallback(
    (any: any) => {
      if (refFinalizar.current) {
        refFinalizar.current.textContent = retTxtBotao();
      }
    },
    [retTxtBotao]
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler]);

  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { avancarFluxoMov } = useMovRota();

  const handleClickFinalizar = async () => {
    try {
      await avancarFluxoMov();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  return (
    <>
      <Grid
        item
        className={classNames(
          classes.containerItemFooter,
          paddingLeft ? classes.containerItemFooterLeft : undefined
        )}
      >
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Button
          fullWidth

          variant="contained"
          color="primary"
          disabled={disabled}
          rounded
          className={classNames(classes.textPreview)}
          onClick={async () => {
            if (!carregando) {
              await handleClickFinalizar();
            }
          }}
        >
          <CarrinhoIcon tipo='BUTTON_PRIMARY' />
          <div ref={refFinalizar}>
            {retTxtBotao()}
          </div>
        </Button>
      </Grid>
    </>
  );
};
