import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1)
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
        '& p': {
            fontWeight: 500
        },
        [theme.breakpoints.down('xs')]: {
            '& p, span': {
                fontSize: '13px'
            },
        }
    },
    iconRight: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex !important',
            border: 'none'
        },
    },
    negrito: {
        'font-weight': '700!important'
    },
    verticalCenter: {
        flexDirection: 'inherit',
        alignItems: 'center',
    },
    removeMargin: {
        marginLeft: theme.spacing(0),
    },
    cardStatus: {
        padding: '2px 8px',
        borderRadius: '20px',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 80,
        "& span": {
            fontSize: 10,
            fontWeight: 600,
        },
    },
    contentCardStatus: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: theme.spacing(1),
    }
}));