import { useCallback } from 'react';
import { useApiBase } from 'data/api/base';
import { VariaveisAmbiente } from 'config';

export function useGetMedicamentoCarga() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMedicamentoCarga = useCallback(
        (codigos: string[] | null, uf: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/MedicamentoPreco/carga?uf=${uf}`,
                method: 'POST',
                enviarTokenUsuario: true,
                data: codigos || []
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getMedicamentoCarga
    };
}
