import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetRastroLoteCodigoAnvisa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getRastroLoteCodigoAnvisa = useCallback(
    (empresaId: string, codigoAnvisa: string) => {

      return invocarApi({
        url: `${VariaveisAmbiente.apiUrlSNGPC}/api/v1/empresa/${empresaId}/Estoque/buscar-lote-codigo-anvisa?codigoAnvisa=${codigoAnvisa}`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getRastroLoteCodigoAnvisa
  };
}
