import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useGetProdutoSubItens } from "data/api/gestao/produto/produto-subitem/get-produto-subitens";
import { useCallback, useState } from "react";
import { useToastSaurus } from "services/app/hooks/toast-saurus";
import { ProdutoNovoSubItemModel, ProdutoSubItemModel, } from "model/api/gestao/produto/produto-subitem/produto-subitem-model";
import { NovoIcon } from "views/components/icons";
import { DefaultFormRefs } from "../../../../../../form/utils";
import { ProdutoNovoSubItemFormModel } from "model/app/forms/produto/produto-subitem/produto-novo-subitem-form-model";
import { isEqual } from "utils/is-equal";
import { usePostProdutoSubItem } from "data/api/gestao/produto/produto-subitem/post-produto-subitem";
import { picker } from "utils/picker";
import CardProdutoSubItem from "../../../../../../cards/card-subitem/card-produto-subitem";
import { usePutProdutoSubItem } from "data/api/gestao/produto/produto-subitem/put-produto-subItem";
import { isEmpty } from "lodash";
import { guidEmpty } from "utils/guid-empty";
import { FormNovoSubItem } from "views/components/form/produto/produto-subitem/novo-subitem/form-novo-produto-subitem";
import { useDeleteProdutoSubItem } from "data/api/gestao/produto/produto-subitem/delete-produto-subitem";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { produtoPageNome } from "views/pages/private/cadastros/produto/produto-page";
import { GenericForm } from "views/components/form/components/generic-form";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { Button, Grid } from "views/design-system";

export interface ProdutoSubItensRefs {
  goBack: () => void;
  isOpenned: () => boolean;
}

interface FormComponentSubitemProps {
  model: ProdutoNovoSubItemFormModel;
}
export interface ProdutoSubItensProps {
  empresaId: string;
  produtoId: string;
  variacaoId: string;
  carregandoExterno: boolean;
  setCarregandoExterno: (carregando: boolean) => any;
  produtoGradeId: string
  tipo: EnumTipoProduto;
  tpProduto: EnumTipoProduto;
}
export const ProdutoSubItens = forwardRef<ProdutoSubItensRefs, ProdutoSubItensProps>(({
  carregandoExterno,
  setCarregandoExterno,
  ...props
}: ProdutoSubItensProps, ref) => {
  const classes = useModalStyles();

  const { getProdutoSubItens, carregando: carregandoGetSubItens } = useGetProdutoSubItens();
  const { postProdutoSubItem, carregando: carregandoPostSubItens } = usePostProdutoSubItem();
  const { putProdutoSubItem, carregando: carregandoPutSubItens } = usePutProdutoSubItem();
  const { deleteProdutoSubItem, carregando: carregandoDelSubItens } = useDeleteProdutoSubItem()
  const { callEvent } = useEventTools();
  const [subItensList, setSubItensList] = useState<ProdutoSubItemModel[]>([]);
  const [produtoSubItem, setProdutoSubItem] = useState<ProdutoNovoSubItemFormModel>(new ProdutoNovoSubItemFormModel())
  const [formComponent, setFormComponent] = useState<FormComponentSubitemProps | null>(null)

  const refProdutoAddSubItem = useRef<DefaultFormRefs<ProdutoNovoSubItemFormModel>>(null);
  const refProdutoEditSubItem = useRef<DefaultFormRefs<ProdutoNovoSubItemFormModel>>(null);

  const produtoEditSubItemModel = useRef<ProdutoSubItemModel>(
    new ProdutoSubItemModel()
  );

  const { showToast } = useToastSaurus();
  const carregando =
    carregandoGetSubItens || carregandoPostSubItens || carregandoPutSubItens || carregandoExterno || carregandoDelSubItens;

  const recarregarSubItens = useCallback(async () => {
    if (props.variacaoId?.length > 0 && props.variacaoId !== guidEmpty()) {
      try {
        const ret = await getProdutoSubItens(props.produtoId, props.variacaoId);
        if (ret.erro) throw ret.erro
        setSubItensList(ret.resultado?.data as Array<ProdutoSubItemModel>);
      } catch (e: any) {
        setSubItensList([])
        showToast(
          "error",
          `Não foi possível carregar os sub-itens do produto. Detalhe: ${e.message}`
        );
      }
    }
  }, [getProdutoSubItens, props.produtoId, props.variacaoId, showToast]);

  const recarregarForm = useCallback(() => {
    refProdutoEditSubItem.current?.fillForm(produtoSubItem)
  }, [produtoSubItem])

  useEffect(() => {
    (async () => {
      await recarregarSubItens();
      recarregarForm()
    })();
    return () => {
      //funcao de limpeza
    };
  }, [recarregarForm, recarregarSubItens]);

  const openNovoSubItem = useCallback(() => {
    callEvent(AppEventEnum.AttTituloEdicaoProduto, {
      titulo: 'Novo Adicional'
    })
    setFormComponent({
      model: new ProdutoNovoSubItemFormModel()
    })
  }, [callEvent]);
  const closeNovoSubItem = useCallback(() => {
    callEvent(AppEventEnum.AttTituloEdicaoProduto, {
      titulo: ''
    })
    setFormComponent(null)
  }, [callEvent]);

  const editarSubItem = (model: ProdutoNovoSubItemFormModel) => {
    callEvent(AppEventEnum.AttTituloEdicaoProduto, {
      titulo: 'Editar Adicional'
    })
    setFormComponent({
      model
    })
  }

  const addSubItem = useCallback(
    async (produtoSubItemModelForm: ProdutoNovoSubItemFormModel) => {
      let produto = picker<ProdutoNovoSubItemModel>(
        produtoSubItemModelForm,
        new ProdutoNovoSubItemModel()
      );
      produto.produtoGradeId = props.produtoGradeId;

      if (produto.produtoSubGradeId === guidEmpty()) {
        produto.produtoSubGradeId = null
      }

      const ret = await postProdutoSubItem(
        props.produtoId,
        props.variacaoId,
        produto
      );

      if (ret.erro) {
        throw ret.erro;
      }

    },
    [postProdutoSubItem, props.produtoGradeId, props.produtoId, props.variacaoId]
  );
  const handleSubmitNovo = useCallback(
    async (model: ProdutoNovoSubItemFormModel, beforeModel: ProdutoNovoSubItemFormModel) => {
      try {
        const produtoSubItemEqual = isEqual(model, beforeModel);
        if (produtoSubItemEqual) {
          showToast("info", "Nenhuma informação foi alterada");
          return false
        }

        await addSubItem(model);

        showToast("success", "Adicional inserido com sucesso!");
        recarregarSubItens();
        closeNovoSubItem();
        return true
      } catch (e: any) {
        showToast("error", e.message);
        return false
      }
    },
    [addSubItem, closeNovoSubItem, recarregarSubItens, showToast]
  );

  const saveChangesSubItem = useCallback(
    async (produtoSubItemModelForm: ProdutoNovoSubItemFormModel) => {
      let produto = picker<ProdutoNovoSubItemModel>(
        produtoSubItemModelForm,
        produtoEditSubItemModel.current
      );
      produto.produtoGradeId = props.produtoGradeId;

      if (produto.produtoSubGradeId === guidEmpty()) {
        produto.produtoSubGradeId = null
      }
      if (produto.modificadorId === guidEmpty()) {
        produto.modificadorId = null
      }

      const ret = await putProdutoSubItem(
        props.produtoId,
        props.variacaoId,
        produto
      );
      if (ret.erro) {
        throw ret.erro;
      }

    },
    [props.produtoGradeId, props.produtoId, props.variacaoId, putProdutoSubItem]
  );
  const handleSubmitEdit = useCallback(
    async (model: ProdutoNovoSubItemFormModel, beforeModel: ProdutoNovoSubItemFormModel) => {
      try {

        const produtoSubItemEqual = isEqual(model, beforeModel);
        if (produtoSubItemEqual) {
          showToast("info", "Nenhuma informação foi alterada");
          return false
        }

        if (model.produtoSubGradeId === guidEmpty()) {
          model.produtoSubGradeId = null
        }

        await saveChangesSubItem(model);
        showToast("success", "Adicional atualizado com sucesso!");
        recarregarSubItens();
        closeNovoSubItem();
        return true
      }
      catch (err: any) {
        showToast("error", err.message);
        return false
      }
    },
    [closeNovoSubItem, recarregarSubItens, saveChangesSubItem, showToast]
  );
  const onCardClick = useCallback(
    (produto: ProdutoSubItemModel) => {
      let produtoForm = picker<ProdutoNovoSubItemFormModel>(
        produto,
        new ProdutoNovoSubItemFormModel()
      );
      produtoForm.produtoNome = produto.produtoSubGrade?.descricao;
      produtoEditSubItemModel.current = produto;
      setProdutoSubItem(produtoForm)
    },
    []
  );

  const handleDelete = useCallback(async (subItemId: string) => {
    try {
      const res = await deleteProdutoSubItem(props.produtoId, props.variacaoId, subItemId)

      if (res.erro) throw res.erro

      showToast('success', "Adicional deletado!")
      recarregarSubItens()
      closeNovoSubItem();
      return true
    } catch (err: any) {
      showToast('error', err.message)
      return false
    }
  }, [closeNovoSubItem, deleteProdutoSubItem, props.produtoId, props.variacaoId, recarregarSubItens, showToast])

  const onCardChecked = useCallback((id: string) => { }, []);

  useImperativeHandle(ref, () => ({
    goBack: () => {
      callEvent(AppEventEnum.AttTituloEdicaoProduto, {
        titulo: ''
      })
      setFormComponent(null)
    },
    isOpenned: () => Boolean(formComponent)
  }))

  const isEditSubitem = formComponent && !isEmpty(formComponent.model.id) && formComponent.model.id !== guidEmpty()

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <div
            className={classNames(
              carregando && classes.contentFormsLoading
            )}
            style={{ height: "100%" }}
          >
            {isEmpty(subItensList) ? (
              <></>
            ) : (
              subItensList.map((item) => {
                return (
                  <CardProdutoSubItem
                    tipo={props.tipo}
                    produtoGradeId={props.produtoGradeId}
                    key={item.id}
                    carregando={carregando}
                    model={item}
                    onClick={onCardClick}
                    onCheck={onCardChecked}
                    selected={false}
                    empresaId={props.empresaId}
                    tipoProdutoPai={props.tpProduto}
                    handleSubmit={handleSubmitEdit}
                    produtoId={props.produtoId}
                    abrirEdicao={editarSubItem}
                  />
                )
              })
            )}
          </div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                fullWidth
                size='large'
                onClick={() => {
                  openNovoSubItem();
                  refProdutoAddSubItem.current?.fillForm(new ProdutoNovoSubItemFormModel());
                }}
              >
                <NovoIcon tipo="BUTTON" />
                {props.tipo === EnumTipoProduto.Produto ? "Novo Adicional" : `Novo ${produtoPageNome(props.tipo, false)}`}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {formComponent && (
        <GenericForm<ProdutoNovoSubItemFormModel>
          buttonLabel="Confirmar"
          model={formComponent.model}
          deleteItem={isEditSubitem ? ({
            buttonLabel: 'Excluir',
            handleDelete: async (id) => {
              await handleDelete(id)
            },
            id: formComponent.model.id || ''
          }) : undefined}
        >
          <FormNovoSubItem
            produtoGradeId={props.produtoGradeId}
            ref={refProdutoEditSubItem}
            empresaId={props.empresaId}
            loading={carregando}
            onSubmit={isEditSubitem ? handleSubmitEdit : handleSubmitNovo}
            showLoading
            model={formComponent.model}
            tipo={props.tipo}
            tipoProdutoPai={props.tpProduto}
            produtoId={props.produtoId}
          />
        </GenericForm>
      )}
    </>
  );
})