import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    content: {
        background: "#fff",
        height: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    contentPequeno: {
        height: 120,
    },
    title: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
    },
    inputArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 260,
        maxWidth: 395,
        borderBottom: "1px solid #D0D0D0",
    },
    inputText: {
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '40px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing(1),
    },
    placeholder: {
        fontSize: '1rem',
        fontWeight: "normal",
        lineHeight: '40px',
        textAlign: 'center',
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        minWidth: '220px',
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    buscaCadastrados: {
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '42px',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    }
}));