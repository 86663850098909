import { guidEmpty } from "utils/guid-empty";

export class PromocaoRegraDetalheItemModel {
    constructor(
        public id: string = guidEmpty(),
        public detalheId: string = guidEmpty(),
        public produtoId: string = '',
        public variacaoId: string = '',
        public codigo: string | null = '',
        public produto: string = '',
        public variacao: string = '',
        public vCadastro: number = 0,
        public ativo: boolean = true
    ) { }
}
