import { DefaultFormRefs } from 'views/components/form/utils';
import {
  CampanhaDataFormModel,
  FormCampanhaData
} from './components/form-data-campanha';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import { useComissaoStore } from 'views/components/modals/comissao/comissao-modal/stores/comissao-store';
import { convertNumberToTimeString, convertTimeStringToNumber } from 'utils/corverter-horario';
import { guidEmpty } from 'utils/guid-empty';

interface FormCadastroDataCampanhaProps {
  cadastroNovaComissao?: boolean
  submit: () => void;
}

export interface RefPropFormDataCampanha {
  submit: () => Promise<void>;
}

export const CadastroDataCampanha = forwardRef<
  RefPropFormDataCampanha,
  FormCadastroDataCampanhaProps
>(({ cadastroNovaComissao, ...props }: FormCadastroDataCampanhaProps, ref) => {
  const refCadastroDataCampanhaRef =
    useRef<DefaultFormRefs<CampanhaDataFormModel>>(null);

  const comissao = useComissaoStore((state) => state.state.comissao);
  const setComissaoStep2 = useComissaoStore((state) => state.setComissaoStep2);

  const recarregarForm = useCallback((model: CampanhaDataFormModel) => {
    refCadastroDataCampanhaRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm({
      dataFinal: comissao.dataFinal,
      dataInicial: comissao.dataInicial,
      diasSemana: comissao.diasSemana,
      horarioFinal: comissao.id === guidEmpty() && comissao.horarioFinal === 0 ? '23:59' : convertNumberToTimeString(comissao.horarioFinal),
      horarioInicio: comissao.horarioInicio === 0 ? '00:00' : convertNumberToTimeString(comissao.horarioInicio),
      modeloApuracao: comissao.modeloApuracao,
    });
  }, [comissao.dataFinal, comissao.dataInicial, comissao.diasSemana, comissao.horarioFinal, comissao.horarioInicio, comissao.id, comissao.modeloApuracao, recarregarForm]);

  function submit(model: CampanhaDataFormModel) {
    if (model.diasSemana !== null) {
      setComissaoStep2({
        dataFinal: model.dataFinal,
        dataInicial: model.dataInicial,
        diasSemana: model.diasSemana,
        horarioFinal: convertTimeStringToNumber(model.horarioFinal) || 2359,
        horarioInicio: convertTimeStringToNumber(model.horarioInicio) || 0,
        modeloApuracao:
          cadastroNovaComissao
            ? comissao.modeloApuracao
            : model.modeloApuracao ?? comissao.modeloApuracao
      });
    }

    props.submit()
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await refCadastroDataCampanhaRef.current?.submitForm();
    }
  }));

  return (
    <FormCampanhaData
      loading={false}
      ref={refCadastroDataCampanhaRef}
      onSubmit={submit}
      showLoading={false}
      cadastroNovaComissao={cadastroNovaComissao}
    />
  );
});
