import * as Yup from "yup";
import { useMemo } from "react";

export const useFormPromocaoDataValidation = () => {
  const FormYupValidationDataPromocao = useMemo(() => {
    return Yup.object().shape({
      horarioFinal: Yup.string().required('Informe o horário final.'),
      horarioInicial: Yup.string().required('Informe o horário inicial.'),
      dataFinal: Yup.string().required('Informe a data final.'),
      dataInicial: Yup.string().required('Informe a data inicial.'),
      diasSemana: Yup.number().required('A promoção deve estar ativa ao menos em um dia.').typeError('A promoção deve estar ativa ao menos em um dia.')
    });
  }, []);

  return {
    FormYupValidationDataPromocao,
  };
};
