import { PromocaoRegraDetalheItemModel } from './../../../../../model/api/gestao/promocao/promocao-regra-detalhes-item-model';
import { PromocaoRegraDetalheModel } from 'model/api/gestao/promocao/promocao-regra-detalhes-model';
import { PromocaoRegraModel } from 'model/api/gestao/promocao/promocao-regra-model';
import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { guidEmpty } from 'utils/guid-empty';
import { newGuid } from 'utils/new-guid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IPromocaoRegraStore {
    regraState: PromocaoRegraModel;
    resetRegra: (promocaoId: string, tipoRegra: EnumPromocaoTipoRegra) => void;
    setRegra: (data: PromocaoRegraModel) => void;
    setRegraDetalhe: (data: PromocaoRegraDetalheModel) => void;
    deleteRegraDetalhes: (ids: string[]) => void;
    setRegraItem: (data: PromocaoRegraDetalheItemModel) => void;
    deleteRegraItem: (detalheId: string, ids: string[]) => void;
}

export const usePromocaoRegraStore = create<IPromocaoRegraStore>()(
    persist(
        (set) => ({

            regraState: new PromocaoRegraModel(),
            resetRegra(promocaoId: string, tipoRegra: EnumPromocaoTipoRegra) {
                set(() => ({
                    regraState: new PromocaoRegraModel(guidEmpty(), promocaoId, tipoRegra, 0, 0, true, [])
                }));
            },
            setRegra(data: PromocaoRegraModel) {
                set(() => ({
                    regraState: {
                        ...data
                    }
                }));
            },
            setRegraDetalhe(data: PromocaoRegraDetalheModel) {
                return set((regraState) => {
                    const index = regraState.regraState.detalhes.findIndex(x => x.id === data.id);
                    if (index === -1) {
                        if (data.id === guidEmpty()) {
                            data.id = newGuid();
                        }
                        regraState.regraState.detalhes.push(data);
                    }
                    else {
                        regraState.regraState.detalhes[index] = data;
                    }
                    return {
                        regraState: {
                            ...regraState.regraState
                        }
                    };
                });
            },
            deleteRegraDetalhes(ids: string[]) {
                return set((regraState) => {
                    regraState.regraState.detalhes = regraState.regraState.detalhes.filter(x => !ids.includes(x.id))
                    return {
                        regraState: {
                            ...regraState.regraState
                        }
                    };
                });
            },
            setRegraItem(data: PromocaoRegraDetalheItemModel) {
                return set((regraState) => {
                    const indexRegra = regraState.regraState.detalhes.findIndex(x => x.id === data.detalheId);
                    if (indexRegra === -1) {
                        throw new Error('O Detalhamento da regra não foi identificado');
                    }
                    const regra = regraState.regraState.detalhes[indexRegra];
                    const index = regra.itens.findIndex(x => x.id === data.id);
                    if (index === -1) {
                        if (data.id === guidEmpty()) {
                            data.id = newGuid();
                        }
                        regra.itens.push(data);
                    }
                    else {
                        regra.itens[index] = data;
                    }
                    return {
                        regraState: {
                            ...regraState.regraState
                        }
                    };
                });
            },
            deleteRegraItem(detalheId: string, ids: string[]) {
                return set((regraState) => {
                    const indexRegra = regraState.regraState.detalhes.findIndex(x => x.id === detalheId);
                    if (indexRegra === -1) {
                        throw new Error('O Detalhamento da regra não foi identificado');
                    }
                    regraState.regraState.detalhes[indexRegra].itens = regraState.regraState.detalhes[indexRegra].itens.filter(x => !ids.includes(x.id))
                    return {
                        regraState: {
                            ...regraState.regraState
                        }
                    };
                });
            }
        }),
        {

            name: 'promocao-regra-store',
        }
    )
);
