import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { makeUtilClasses, useThemeQueries } from 'views';

import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { useConsultaCEP } from 'data/api/wsmaster';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useToastSaurus } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { IndIEDestMock, UFMock } from 'data/mocks';
import { isEmpty, isEqual } from 'lodash';
import { stringNumeros } from 'utils/string-numeros';
import {
  AutocompletePessoas,
  EnumNomeCnpj
} from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { guidEmpty } from 'utils/guid-empty';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumIndIEDest } from 'model';
import { useFormPessoaEnderecoValidation } from './form-pessoa-endereco-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { PessoaEditEnderecoFormModel } from 'model/app/forms/pessoa/pessoa-edit-form-model';

export interface FormPessoaEnderecoRefs
  extends DefaultFormRefs<PessoaEditEnderecoFormModel> {
  getFields: () => Promise<PessoaEditEnderecoFormModel | false>;
  isEqual: () => boolean;
}

export const FormPessoaEndereco = forwardRef<
  FormPessoaEnderecoRefs,
  DefaultFormProps<PessoaEditEnderecoFormModel>
>((props: DefaultFormProps<PessoaEditEnderecoFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { showToast } = useToastSaurus();

  const originalModel = useRef<PessoaEditEnderecoFormModel>(new PessoaEditEnderecoFormModel());
  const [, setAtt] = useState<boolean>(false)

  const { isMobile } = useThemeQueries();
  const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
  const { FormPessoaEnderecoValidationYup } = useFormPessoaEnderecoValidation()

  const carregando = carregandoCEP;

  //REFS
  const refInputCnpjCpf = useRef<HTMLInputElement>(null);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    clearErrors
  } = useForm<PessoaEditEnderecoFormModel>({
    defaultValues: props.model || new PessoaEditEnderecoFormModel(),
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(FormPessoaEnderecoValidationYup)
  });

  const { getEmpresaAtual } = useEmpresaAtual();

  const onSubmit = (values: PessoaEditEnderecoFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
    fillForm: (model: PessoaEditEnderecoFormModel) => {
      reset({ ...model, uf: model.uf || getEmpresaAtual()?.uf || '' });
      originalModel.current = {
        ...model
      };
      setTimeout(() => {
        if (!isMobile) refInputCnpjCpf.current?.focus();
      }, 500);
    },
    getFields: async () => {
      const isValid = await trigger();
      if (!isValid || !isEmpty(errors)) {
        return false;
      }

      return new PessoaEditEnderecoFormModel(
        getValues('id'),
        getValues('pessoaId'),
        getValues('cep'),
        getValues('logradouro'),
        getValues('numero'),
        getValues('complemento'),
        getValues('referencia'),
        getValues('bairro'),
        getValues('cMun'),
        getValues('xMun'),
        getValues('uf'),
        getValues('cuf'),
        getValues('ierg'),
        getValues('im'),
        getValues('indIEDest'),
        getValues('pessoaTransportadoraPadraoId')
      );
    },
    isEqual: () => {
      const currModel = new PessoaEditEnderecoFormModel(
        getValues('id'),
        getValues('pessoaId'),
        getValues('cep'),
        getValues('logradouro'),
        getValues('numero'),
        getValues('complemento'),
        getValues('referencia'),
        getValues('bairro'),
        getValues('cMun'),
        getValues('xMun'),
        getValues('uf'),
        getValues('cuf'),
        getValues('ierg'),
        getValues('im'),
        getValues('indIEDest'),
        getValues('pessoaTransportadoraPadraoId')
      );
      return isEqual(currModel, originalModel.current);
    }
  }));

  const loading = carregando || props.loading;

  useEffect(() => {
    if (props.model) {
      reset({ ...props.model });
    }
  }, [props.model, reset]);

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo={'NORMAL'} />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            loading && props.showLoading ? utilClasses.controlLoading : ''
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="cep"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="CEP"
                    disabled={props.loading}
                    fullWidth
                    searchable
                    autoComplete={'off'}
                    label="CEP"
                    placeholder=""
                    onSearch={async (value: string) => {
                      try {
                        let res = await consultaCEP(value);

                        setValue('cep', stringNumeros(res.CEP));
                        setValue('bairro', res.Bairro);
                        setValue('logradouro', res.Logradouro);
                        setValue('uf', res.UF);
                        setValue('cMun', res.CMun);
                        setValue('xMun', res.Municipio);
                      } catch (e: any) {
                        showToast('error', e.message);
                      }
                    }}
                    error={Boolean(errors.cep && errors.cep.message)}
                    helperText={errors.cep ? errors.cep?.message : undefined}
                    {...field}
                    value={getValues('cep')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="logradouro"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Logradouro"
                    placeholder="Ex: Av. Paulista"
                    error={Boolean(
                      errors.logradouro && errors.logradouro.message
                    )}
                    helperText={
                      errors.logradouro ? errors.logradouro?.message : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="numero"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Número"
                    placeholder="Ex: 112"
                    error={Boolean(errors.numero && errors.numero.message)}
                    helperText={
                      errors.numero ? errors.numero?.message : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="complemento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Complemento"
                    placeholder="Ex: Apartamento 11"
                    error={Boolean(
                      errors.complemento && errors.complemento.message
                    )}
                    helperText={
                      errors.complemento
                        ? errors.complemento?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="bairro"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Bairro"
                    placeholder="Ex: Bela Vista"
                    error={Boolean(errors.bairro && errors.bairro.message)}
                    helperText={
                      errors.bairro ? errors.bairro?.message : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="xMun"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Município"
                    placeholder="Ex: São Paulo"
                    error={Boolean(errors.xMun && errors.xMun.message)}
                    helperText={errors.xMun ? errors.xMun?.message : undefined}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="cMun"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Código do Município"
                    placeholder="Ex: 3550308"
                    error={Boolean(errors.cMun && errors.cMun.message)}
                    helperText={errors.cMun ? errors.cMun?.message : undefined}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="uf"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    disabled={props.loading}
                    conteudo={UFMock}
                    fullWidth
                    variant="outlined"
                    label={'UF'}
                    allowSubmit
                    select
                    {...field}
                    value={
                      UFMock.find((uf) => uf.Value === getValues('uf'))?.Key
                    }
                    onChange={(event) => {
                      if (event) {
                        const item = UFMock.filter(
                          (item) => item.Key === event.target.value
                        )[0];
                        if (item) {
                          setValue('uf', item.Value);
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="im"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Inscrição Municipal"
                    placeholder=""
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="ierg"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    readOnly={getValues('indIEDest') !== EnumIndIEDest.CONTRIBUINTE_ICMS}
                    fullWidth
                    autoComplete={'off'}
                    label="Inscrição Estadual"
                    placeholder=""
                    error={Boolean(errors.ierg && errors.ierg.message)}
                    helperText={errors.ierg ? errors.ierg?.message : undefined}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="indIEDest"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={IndIEDestMock}
                    label={'Indicador IE'}
                    {...field}
                    onChange={(event) => {
                      if (event) {
                        const valor = IndIEDestMock.filter(
                          (item) => item.Key === event.target.value
                        )[0]?.Key;
                        setValue('indIEDest', valor);
                        if (valor !== EnumIndIEDest.CONTRIBUINTE_ICMS) {
                          setValue('ierg', '')
                          clearErrors('ierg')
                        }
                        setAtt(prev => !prev)
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="pessoaTransportadoraPadraoId"
                control={control}
                render={({ field }) => (
                  <AutocompletePessoas
                    disabled={props.loading}
                    label="Transportadora Padrão"
                    allowSubmit
                    error={Boolean(
                      errors.pessoaTransportadoraPadraoId &&
                      errors.pessoaTransportadoraPadraoId.message
                    )}
                    helperText={
                      errors.pessoaTransportadoraPadraoId
                        ? errors.pessoaTransportadoraPadraoId?.message
                        : undefined
                    }
                    {...field}
                    exibirTipo
                    onChange={async (retorno) => {
                      if (retorno.isString) {
                        if (retorno.value === guidEmpty()) {
                          setValue('pessoaTransportadoraPadraoId', '');
                          return;
                        }
                        setValue('pessoaTransportadoraPadraoId', retorno.value);
                      } else {
                        setValue(
                          'pessoaTransportadoraPadraoId',
                          retorno.value.id
                        );
                      }
                    }}
                    fetchOnLoad={!isEmpty(field.value)}
                    nomeCnpj={EnumNomeCnpj.Nome}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
