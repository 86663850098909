import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import { picker } from 'utils/picker';
import { useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from 'views/design-system';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useSessaoAtual } from 'services/app';
import { FormAlterarSenha } from 'views/components/form/master/alterar-senha/form-alterar-senha';
import { AlterarSenhaFormModel } from 'model/app/forms/master/alterar-senha-form-model';
import { usePutAlterarSenha } from 'data/api/gestao/usuario/put-alterar-senha';
import { AlterarSenhaModel } from 'model/api/gestao/master/alterar-senha';
import { useThemeQueries } from '../../../../../theme/util-styles';
import { AtualizarIcon } from '../../../../../components/icons/atualizar-icon';
import classNames from 'classnames';
import { CadeadoSenhaIcon } from 'views/components/icons';

export const AccordionAlterarSenha = () => {
  const { showToast } = useToastSaurus();
  const { usuario } = useSessaoAtual();

  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();
  const { putAlterarSenha, carregando: carregandoPut } = usePutAlterarSenha();
  const [openAccordion, setOpenAccordion] = useState(false);
  const carregando = carregandoPut;

  const refAlterarSenha = useRef<DefaultFormRefs<AlterarSenhaFormModel>>(null);

  const saveChangesNovaSenha = useCallback(
    async (senhaModel: AlterarSenhaFormModel) => {
      let senhaNova = picker<AlterarSenhaModel>(
        senhaModel,
        new AlterarSenhaModel(),
      );

      senhaNova.id = usuario?.usuarioId || '';
      try {
        let ret = await putAlterarSenha(senhaNova);
        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Senha alterada com sucesso!');
        refAlterarSenha.current?.resetForm();
      } catch (e: any) {
        showToast('error', e.message);
        refAlterarSenha.current?.resetForm();
      }
    },
    [usuario?.usuarioId, putAlterarSenha, showToast],
  );

  const handleSubmit = useCallback(
    async (model: AlterarSenhaFormModel) => {
      saveChangesNovaSenha(model);
    },
    [saveChangesNovaSenha],
  );

  const buttonAlterarSenha = useMemo(() => {
    return (
      <div className={classesModal.acoes}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              disabled={carregando}
              onClick={() => refAlterarSenha.current?.submitForm()}
              variant="contained"
              color="primary"
              fullWidth
            >
              <AtualizarIcon tipo="BUTTON_PRIMARY" />
              Alterar Senha
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }, [carregando, classesModal.acoes]);

  const form = useMemo(() => {
    return (
      <div
        className={classNames(classesModal.contentForms)}
        style={{ height: '100%' }}
      >
        <FormAlterarSenha
          ref={refAlterarSenha}
          onSubmit={handleSubmit}
          showLoading={false}
          loading={carregando}
        />
      </div>
    );
  }, [carregando, classesModal.contentForms, handleSubmit]);

  return (
    <AccordionSaurus
      labelPrimary={'Alterar Senha'}
      tituloChildren={
        <CadeadoSenhaIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
        <div className={classesModal.content}>
          {form}
          {buttonAlterarSenha}
        </div>
      </div>
    </AccordionSaurus>
  );
};
