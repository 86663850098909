import { guidEmpty } from "utils/guid-empty";
import { PromocaoRegraDetalheItemModel } from "./promocao-regra-detalhes-item-model";

export class PromocaoRegraDetalheModel {
    constructor(
        public id: string = guidEmpty(),
        public regraId: string = guidEmpty(),
        public limiteOcorrencia: number = 0,
        public quantidade: number = 0,
        public valor: number = 0,
        public ativo: boolean = true,
        public itens: Array<PromocaoRegraDetalheItemModel> = [],
        public validado: boolean = true,
        public adicionado: boolean = true,
        public alterado: boolean = true
    ) { }
}
