import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        width: '36px',
        height: '36px',
        marginBottom: -6,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: '36px',
            height: '36px',
            marginBottom: -6,
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px',
            marginBottom: -6,

        },
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    },
    svgContainer: {
        borderRadius: 8,
        background: theme.palette.grey[100],
        boxShadow: theme.shadows[5],
        maxWidth: 300,
        maxHeight: 300,
        padding: theme.spacing(2),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fontOu: {
        margin: theme.spacing(2, 0),
        fontSize: '20px',
        fontWeight: 600
    },
    title: {
        '@media (max-height: 580px)': {
            fontSize: '35px',
        },
        '@media (max-height: 540px)': {
            fontSize: '30px',
        }
    },
    noMarginSvg: {
        marginRight: 0 + '!important'
    }
}));