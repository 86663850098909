import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostComissaoApuracaoBlackList() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postComissaoApuracaoBlackList = useCallback(
        (data: {
            empresaId: string;
            comissaoId: string;
            apuracaoId: string;
            blackList: string[];
        }) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${data.empresaId}/comissao/${data.comissaoId}/apuracao/${data.apuracaoId}/itens`,
                method: 'PATCH',
                data: {
                    itensApuracaoId: data.blackList,
                    situacao: 1
                },
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            })
        }, [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postComissaoApuracaoBlackList,
    };
}