import { CardConvenioFaturaProps } from './card-convenio-fatura-preco-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-convenio-fatura-preco-styles';
import classNames from 'classnames';
import { Grid, Typography } from 'views/design-system';

export const CardConvenioFaturaPreco = ({
  label,
  value,
}: CardConvenioFaturaProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const classes = useStyles()

  return (
    <DefaultCard
      isSelected={false}
      notClickable
      className={classes.defaultCard}
    >
      <div className={classNames(defaultCardClasses.cardContent, classes.card)}>
        <Grid container>
          <Grid item xs={8} sm={8} className={classes.item}>
            <Typography color='textSecondary' variant='caption'>
              {label}
            </Typography>
            <Typography color='textPrimary' className={classes.negrito}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </DefaultCard>
  );
};
