import { useCallback, useEffect, useState } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { CarrinhoListData } from './carrinho-list-data';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import {
  GestaoStorageKeys,
  useGestaoStorage,
  useToastSaurus
} from 'services/app';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';

interface CarrinhoListProps {
  inativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  reativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  alterarQtdeProduto(
    model: MovSimplesProdutoModel,
    incremento: number,
    fator: 'sub' | 'add'
  ): Promise<void>;
  setProductEdit: (produtoId: string) => void;
}

export const CarrinhoList = ({
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  setProductEdit
}: CarrinhoListProps) => {
  const {
    getMov,
    removerPedidoDoCarrinho,
    carregando: carregandoRemocaoPedido
  } = useMovAtual();
  const mov = getMov();
  const { getRegistro } = useGestaoStorage();

  const getMostrarItensCancelados = useCallback(() => {
    const mostrarItensCancelados = getRegistro(
      GestaoStorageKeys.MostrarItensCancelados,
      false
    ) as boolean | undefined;

    if (mostrarItensCancelados === true) {
      return true;
    }
    return false;
  }, [getRegistro]);

  const [mostrarItensCancelados, setMostrarItensCancelados] = useState(getMostrarItensCancelados());
  const [listStatus, setListStatus] = useState(mov?.produtos ?? []);
  const { addHandler, removeHandler } = useEventTools();
  const { showToast } = useToastSaurus();

  const movProdAlterada = useCallback(
    (mov: MovSimplesProdutoModel) => {
      setListStatus((previous) => {
        if (previous.length !== getMov()?.produtos.length)
          return getMov()?.produtos || [];

        const prods = previous.filter((x) => x.nSeq === mov.nSeq);
        if (prods.length > 0) {
          const index = previous.indexOf(prods[0]);
          previous[index] = mov;
          return [...previous];
        }
        return previous;
      });
    },
    [getMov]
  );

  const movAlterada = useCallback(
    (mov: MovSimplesModel) => {
      setListStatus((previous) => {
        if (previous.length !== mov.produtos.length)
          return previous;

        if (
          //SE QCOM OU VFINAL FOI MODIFICADO
          (previous.reduce((acc, curr) => acc + curr.qCom, 0) !== mov.produtos.reduce((acc, curr) => acc + curr.qCom, 0)
            || previous.reduce((acc, curr) => acc + curr.vFinal, 0) !== mov.produtos.reduce((acc, curr) => acc + curr.vFinal, 0)) &&
          //E TEM DESCONTO OU ACRESCIMO
          (
            mov.produtos.reduce((acc, curr) => acc + curr.vDesc, 0) !== previous.reduce((acc, curr) => acc + curr.vDesc, 0) ||
            mov.produtos.reduce((acc, curr) => acc + curr.vOutro, 0) !== previous.reduce((acc, curr) => acc + curr.vOutro, 0)
          )
        )
          return mov?.produtos || []


        return previous;
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterada);
    addHandler(AppEventEnum.MovAtualAlterada, movAlterada);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterada);
      removeHandler(AppEventEnum.MovAtualAlterada, movAlterada);
    };
  }, [addHandler, movAlterada, movProdAlterada, removeHandler]);

  const removerPedido = useCallback(
    async (idPedido: string) => {
      try {
        await removerPedidoDoCarrinho(idPedido);
      } catch (err: any) {
        showToast(
          'error',
          `Erro ao tentar remover o pedido da venda atual, por favor tente novamente. Detalhes: ${err.message}`
        );
      }
    },
    [removerPedidoDoCarrinho, showToast]
  );


  const onAtualizarMostrarItensCarrinho = useCallback((show: boolean) => {
    setMostrarItensCancelados(show);
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.AtualizarMostrarItensCarrinho, onAtualizarMostrarItensCarrinho);
    return () => {
      removeHandler(AppEventEnum.AtualizarMostrarItensCarrinho, onAtualizarMostrarItensCarrinho);
    };
  }, [addHandler, onAtualizarMostrarItensCarrinho, removeHandler]);

  return (
    <>
      {carregandoRemocaoPedido && <CircularLoading tipo="FULLSIZED" />}
      <CarrinhoListData
        inativarProduto={inativarProduto}
        reativarProduto={reativarProduto}
        alterarQtdeProduto={alterarQtdeProduto}
        mostrarItensCancelados={mostrarItensCancelados}
        list={listStatus}
        removerPedidoDoCarrinho={removerPedido}
        setProductEdit={setProductEdit}
      />
    </>
  );
};
