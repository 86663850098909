import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormDadosEntradaValidation } from './form-dados-destinatario-validation';
import { FormDadosDestinatarioProps } from './form-dados-destinatario-props';
import { DestFormModel } from 'model/app/forms/entrada/dest-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { UFMock } from 'data/mocks';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { useStyles } from './form-dados-destinatario-styles';

export const FormDadosDestinatario = forwardRef<
    DefaultFormRefs<DestFormModel>,
    FormDadosDestinatarioProps
>(({ ...props }, ref) => {
    const utilClasses = makeUtilClasses();
    const { formDadosEntradaValidationYup } = useFormDadosEntradaValidation();
    const [model, setModel] = useState<DestFormModel>(new DestFormModel())
    const modelFormRef = useRef<DestFormModel>(new DestFormModel())
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { showToast } = useToastSaurus()
    const classes = useStyles()

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
    } = useForm<DestFormModel>({
        resolver: yupResolver(formDadosEntradaValidationYup),
        defaultValues: {
            ...model
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: DestFormModel) => {
        props.onSubmit(values, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: DestFormModel) => {
            reset({ ...model })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    const ufMockSiglas = UFMock.map(x => new KeyValueModel(x.Value, x.Value))

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {carregandoCEP ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="documento"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'CNPJ_CPF'}
                                        readOnly
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="CPF/CNPJ"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.documento && errors.documento.message)}
                                        helperText={
                                            touchedFields.documento || errors.documento
                                                ? errors.documento?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xNome"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        readOnly
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Razão Social"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xNome && errors.xNome.message)}
                                        helperText={
                                            touchedFields.xNome || errors.xNome
                                                ? errors.xNome?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="ie"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        readOnly
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Inscrição Estadual"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.ie && errors.ie.message)}
                                        helperText={
                                            touchedFields.ie || errors.ie
                                                ? errors.ie?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="im"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        readOnly
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Inscrição Municipal"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.im && errors.im.message)}
                                        helperText={
                                            touchedFields.im || errors.im
                                                ? errors.im?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'EMAIL'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="E-mail"
                                        allowSubmit
                                        placeholder='Ex: usuario@saurus.com.br'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.email && errors.email.message)}
                                        helperText={
                                            touchedFields.email || errors.email
                                                ? errors.email?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="fone"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TELEFONE'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Telefone"
                                        allowSubmit
                                        placeholder='Ex: (11) 99999-9999'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.fone && errors.fone.message)}
                                        helperText={
                                            touchedFields.fone || errors.fone
                                                ? errors.fone?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box pb={1}>
                                <Typography variant="h6">
                                   Endereço
                                </Typography>
                                <Divider className={classes.divider}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="cep"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CEP"
                                        disabled={props.loading}
                                        fullWidth
                                        searchable
                                        autoComplete={'off'}
                                        label="CEP"
                                        placeholder="Ex: 01309-030"
                                        onSearch={async (value: string) => {
                                            try {
                                                let res = await consultaCEP(value);

                                                setValue('cep', res.CEP);
                                                setValue('xBairro', res.Bairro);
                                                setValue('xLgr', res.Logradouro);
                                                setValue('uf', res.UF);
                                                setValue('cMun', res.CMun ? Number(res.CMun) : 0);
                                                setValue('xMun', res.Municipio)
                                            } catch (e: any) {
                                                showToast('error', e.message);
                                            }
                                        }}
                                        error={Boolean(errors.cep && errors.cep.message)}
                                        helperText={
                                            errors.cep
                                                ? errors.cep?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xLgr"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Endereço"
                                        allowSubmit
                                        placeholder='Ex: Rua Fernando de Albuquerque'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xLgr && errors.xLgr.message)}
                                        helperText={
                                            touchedFields.xLgr || errors.xLgr
                                                ? errors.xLgr?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                name="nro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número"
                                        allowSubmit
                                        placeholder='Ex: 1.111'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.nro && errors.nro.message)}
                                        helperText={
                                            touchedFields.nro || errors.nro
                                                ? errors.nro?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Controller
                                name="xCpl"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Complemento"
                                        allowSubmit
                                        placeholder='Ex: Casa 5'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xCpl && errors.xCpl.message)}
                                        helperText={
                                            touchedFields.xCpl || errors.xCpl
                                                ? errors.xCpl?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xBairro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Bairro"
                                        allowSubmit
                                        placeholder='Ex: Consolação'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xBairro && errors.xBairro.message)}
                                        helperText={
                                            touchedFields.xBairro || errors.xBairro
                                                ? errors.xBairro?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Controller
                                name="xMun"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Município"
                                        allowSubmit
                                        placeholder='Ex: São Paulo'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xMun && errors.xMun.message)}
                                        helperText={
                                            touchedFields.xMun || errors.xMun
                                                ? errors.xMun?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        
                        <Grid item xs={3}>
                            <Controller
                                name="uf"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={ufMockSiglas}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="UF"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.uf && errors.uf.message)}
                                        helperText={
                                            touchedFields.uf || errors.uf
                                                ? errors.uf?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
