import classNames from "classnames";
import { BoxProps } from "./box-props"
import { useStyles } from "./box-styles";
import { useFlexs } from "../styles/flex/flex";
import { forwardRef } from "react";
import { useThemeQueries } from "views/theme";

export const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
    const styles = useStyles();
    const flex = useFlexs(props);
    const { theme } = useThemeQueries();

    return <div className={
        classNames(
            styles.root,
            flex.getFlex(),
            flex.getFlex() && flex.getAlignItems(), flex.getFlex() && flex.getFlexDirection(), flex.getFlex() && flex.getFlexWrap(), flex.getFlex() && flex.getJustifyContent(),
            props.className
        )} style={{
            gap: props.gap ? theme.spacing(props.gap) : undefined,

            paddingTop: props.pt !== undefined ? theme.spacing(props.pt) : props.py !== undefined ? theme.spacing(props.py) : props.p !== undefined ? theme.spacing(props.p) : undefined,
            paddingRight: props.pr !== undefined ? theme.spacing(props.pr) : props.px !== undefined ? theme.spacing(props.px) : props.p !== undefined ? theme.spacing(props.p) : undefined,
            paddingBottom: props.pb !== undefined ? theme.spacing(props.pb) : props.py !== undefined ? theme.spacing(props.py) : props.p !== undefined ? theme.spacing(props.p) : undefined,
            paddingLeft: props.pl !== undefined ? theme.spacing(props.pl) : props.px !== undefined ? theme.spacing(props.px) : props.p !== undefined ? theme.spacing(props.p) : undefined,

            marginTop: props.mt !== undefined ? theme.spacing(props.mt) : props.my !== undefined ? theme.spacing(props.my) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            marginRight: props.mr !== undefined ? theme.spacing(props.mr) : props.mx !== undefined ? theme.spacing(props.mx) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            marginBottom: props.mb !== undefined ? theme.spacing(props.mb) : props.my !== undefined ? theme.spacing(props.my) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            marginLeft: props.ml !== undefined ? theme.spacing(props.ml) : props.mx !== undefined ? theme.spacing(props.mx) : props.m !== undefined ? theme.spacing(props.m) : undefined,
            ...props.style
        }} id={props.id} onClick={props.onClick} ref={ref}>
        {props.children}
    </div >
});