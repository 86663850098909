import { guidEmpty } from "utils/guid-empty";
import { ConvenioFaturaLancamentoModel } from "./convenio-fatura-lancamento-model";
import { ConvenioSubFaturaModel } from "./convenio-sub-fatura-model";

export class ConvenioFaturaModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public pessoaNome: string = '',
        public pessoaCpfCnpj: string = '',
        public contaPessoaId: string = guidEmpty(),
        public anoMesCompetencia: number = 0,
        public fatura: ConvenioSubFaturaModel | undefined = undefined,
        public lancamentos: ConvenioFaturaLancamentoModel[] = [],
    ) { }
}