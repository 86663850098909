import { Grid, Typography } from "views/design-system";
import { PrivatePageHeaderProps } from "./header-private-page-props"
import { useStyles } from "./header-private-page-styles"
import { useThemeQueries } from "views/theme"
import classNames from "classnames";

export const PrivatePageHeader = (props: PrivatePageHeaderProps) => {
    const classes = useStyles();
    const { isMobile } = useThemeQueries()
    return (
        <>
            <Grid container className={classNames(classes.mainHeaderContainer, props.className ? props.className : '')} id={props.id}>
                <Grid item xs={12} >
                    <Grid container className={classes.topContainer} flexDirection="row" justifyContent="space-between" spacing={1} alignItems="center">
                        <Grid item className={classes.flexContent}>
                            <Grid container spacing={1} alignItems="center">
                                {
                                    props.leftArea &&
                                    (<Grid item className={classes.flexFixed}>
                                        {props.leftArea}
                                    </Grid>)
                                }
                                {
                                    (isMobile ? !props.middleArea : true) && <Grid item className={classes.flexContent}>
                                        <Typography variant="h4" className={classes.title}>
                                            {props.title}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    props.middleArea &&
                                    (<Grid item className={classes.flexContent}>
                                        {props.middleArea}
                                    </Grid>)
                                }
                            </Grid>
                        </Grid>
                        {
                            props.rightArea &&
                            (<Grid item className={classes.flexFixed}>
                                {props.rightArea}
                            </Grid>)
                        }
                    </Grid>
                    {
                        props.bottomArea &&
                        (<Grid className={classes.bottomContainer} item xs={12}>
                            {props.bottomArea}
                        </Grid>)
                    }
                </Grid>
            </Grid>
        </>
    )
}
