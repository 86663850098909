import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    formMovimentacao: {
        padding: '16px', justifyContent: 'space-between', height: '100%'
    },
    inputValorCenter: {
        '& input': {
            fontWeight: 600,
            fontSize: 40,
            padding: '12px 14px',
            textAlign: 'center'
        }
    },
    fieldContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    paperCard:{
        width: '100%', 
        padding: '36px 8px', 
        margin: '24px 0px' 
    },
    paperInfo:{
        padding: '8px', 
        backgroundColor: theme.palette.grey[200]
    },
    textInfo:{
        textAlign: 'center', 
        fontWeight: 600 
    },
    spanStyled:{
        color: theme.palette.primary.main
    },
    inputValor:{
         margin: '24px 0'
    }
}))