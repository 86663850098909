import {
  Chip,
  LinearProgress,
} from '@material-ui/core';
import { toDecimalString } from 'utils/to-decimal';
import { DefaultCard } from 'views/components/cards/components';
import { EnumTpSituacaoApuracao } from 'data/api/gestao/comissao/patch-campanha-apuracao-descondierar-comissionado';
import { useThemeQueries } from 'views/theme';
import {
  EnumTpStatusProcessamento,
  IComissionado
} from 'views/components/modals/comissao/comissao-modal/stores/types/IComissionado';
import { Box, Divider, Grid, Typography } from 'views/design-system';

const enumTpSituacaoApuracaoToLabel = (situacao: EnumTpSituacaoApuracao) => {
  switch (situacao) {
    case EnumTpSituacaoApuracao.Pendente:
      return 'Pendente';
    case EnumTpSituacaoApuracao.Sucesso:
      return 'Confirmado';
    default:
      return 'Ignorado';
  }
};

export const ApuracaoCard = (props: {
  comissionado: Omit<IComissionado, 'vendas'>;
  onClick?: (comissionado: Omit<IComissionado, 'vendas'>) => void;
}) => {
  const { theme } = useThemeQueries();
  return (
      <DefaultCard
        disabled={
          props.comissionado.statusProcessamento ===
          EnumTpStatusProcessamento.Processando
        }
        onClick={async () => {
          if (
            props.onClick &&
            props.comissionado.statusProcessamento ===
            EnumTpStatusProcessamento.Concluido
          ) {
            props.onClick(props.comissionado);
          }
        }}
      >
        <Grid container p={1}>
          {props.comissionado.statusProcessamento ===
            EnumTpStatusProcessamento.Processando && (
              <Grid item xs={12}>
                <LinearProgress color="primary" />
              </Grid>
            )}
          <Grid item flex justifyContent='space-between' xs={12} >
            <Box>
              <Typography
                color="textPrimary"
                variant="caption"
              >
                Competência
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {props.comissionado.dtComp}
              </Typography>

            </Box>
            <Chip
              label={enumTpSituacaoApuracaoToLabel(
                props.comissionado.situacao
              )}
              style={{
                backgroundColor:
                  props.comissionado.situacao ===
                    EnumTpSituacaoApuracao.Pendente
                    ? theme.palette.warning.main
                    : theme.palette.success.main,
                color: theme.palette.getContrastText(
                  props.comissionado.situacao ===
                    EnumTpSituacaoApuracao.Pendente
                    ? theme.palette.warning.main
                    : theme.palette.success.main
                )
              }}
            />
          </Grid>
          <Grid item xs={12} py={1}>
            <Divider variant='fullWidth' />
          </Grid>
          <Grid item xs={12} pb={1}>
            <Typography color="textPrimary" variant="caption">
              Nome
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {props.comissionado.nome}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textPrimary" variant="caption">
              Base de cálculo
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              R${toDecimalString(props.comissionado.baseCalculo)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textPrimary" variant="caption">
              Qtd Itens
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {props.comissionado.qtdItens}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textPrimary" variant="caption">
              Valor Comissão
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              R${toDecimalString(props.comissionado.valorComissao)}
            </Typography>
          </Grid>
        </Grid>
      </DefaultCard>
  );
};
