import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { EnumMenu } from "model";
import { useThemeQueries } from 'views/theme';
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { GestaoStorageKeys, useCadastros, useChatSuporteSaurus, useGestaoStorage } from "../../../../../../../../services/app";
import { useSessaoAtual } from 'services/app';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { useConfirm } from "material-ui-confirm";
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { EnumTour } from "model/enums/enum-tour";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import { VariaveisAmbiente } from "config";


export const useAcoesMenu = () => {
  const history = useHistory();
  const { isMobile } = useThemeQueries();
  const { fecharMenu, alterarModo } = useMenuPrincipal();
  const { getEmpresaSelecionada, usuario, plano, getPermissaoBoolean } = useSessaoAtual();
  const { abrirChat } = useChatSuporteSaurus();
  const confirm = useConfirm()
  const { getRegistro } = useGestaoStorage()
  const { callTour } = useTourSaurus()
  const { abrirSolicitarPermissao } = useCadastros()

  const token = getRegistro(GestaoStorageKeys.Token, false)
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const selectAction = useCallback(
    async (codigoModulo: number, path: string) => {
      switch (codigoModulo) {
        case EnumMenu.SUPORTE_CHAT:
          abrirChat(usuario?.email ?? '', getEmpresaSelecionada()?.Documento ?? '', usuario?.saudacao ?? '');
          break;
        case EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO:
          setTimeout(() => { callTour(EnumTour.CARDAPIO) }, 600)
          confirm({
            title: isFarmaceutico ? 'Minha Vitrine' : 'Meu Cardápio',
            description: `Ao confirmar, iremos te redirecionar ${isFarmaceutico ? 'para sua Vitrine' : 'ao seu Cardápio'} com a empresa atual selecionada, deseja continuar?`,
            confirmationText: 'Continuar',
            cancellationText: 'Voltar',
            dialogProps: {
              id: "tour-Cardapio"
            }
          })
            .then(() => {
              const urlAmbiente = VariaveisAmbiente.environment === 'prod' ? '' : '-staging'
              window.open(`https://cardapio${urlAmbiente}.touchone.com.br/${getEmpresaSelecionada()?.Id ?? ''}/login/${token}`, '_blank')
            })
          break;
        case EnumMenu.FARMA_SNGPC:
          window.open(`https://sngpc-staging.touchone.com.br/`, '_blank')
          break;

        case EnumMenu.OPERACIONAL_FORAVENDA_INICIAR_VENDA:
          const handlePush = () => {
            alterarModo(EnumMenuPrincipalModo.PDV);
            history.push(path);
          }
          if (!getPermissaoBoolean(EnumCodigosPermissoes.REALIZAR_VENDAS)) {
            abrirSolicitarPermissao(async () => handlePush(), EnumCodigosPermissoes.REALIZAR_VENDAS, 'realizar vendas')
            return
          }
          handlePush()
          break;
        default:
          history.push(path);
          break;
      }
      if (isMobile) {
        fecharMenu();
      }
    },
    [isMobile, abrirChat, usuario?.email, usuario?.saudacao, getEmpresaSelecionada, confirm, isFarmaceutico, getPermissaoBoolean, history, callTour, token, alterarModo, abrirSolicitarPermissao, fecharMenu]
  );
  return { selectAction: selectAction };
};
