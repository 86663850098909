import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    keyboardContainer: {
        flex: 1,
        height: "100%",
    },
    setarValorPadraoContainer: {
        display: "flex",
        height: theme.spacing(8),
        textWrap: "nowrap",
        background: theme.palette.grey[50],
        borderBottom:"1px solid " + theme.palette.grey[200],
    },
    setarValorPadraoItem: {
        flexBasis: "20%",
    },
}));