import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center'
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    containerInfo: {
        flex: 1,
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        overflow: 'auto',
        alignItems: 'center'
    },
    content: {
        maxWidth: '650px',
        display: "flex",
        height: '100%',
        flex: 1,
        flexDirection: "column",
        width: "100%",
    },
    contentPC: {
        maxWidth: '650px',
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: '100%',
    },
    header: {
        flex: "0 1 auto"
    },
    textTitle: {
        fontWeight: 700,
        lineHeight: "42px",
        fontSize: "2rem",
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
    },
    green: {
        color: theme.palette.success.dark,
    },
    greenBackground: {
        backgroundColor: theme.palette.success.dark,
        '& hover': {
            backgroundColor: theme.palette.success.main,
        }
    },
    red: {
        color: theme.palette.error.dark,
    },
    novaVenda: {
        fontSize: 20,
        fontWeight: 600,
        height: "56px!important",
        width: '100%'
    },
    containerInfoGeral: {
        height: "100%",
        flex: 1
    },
    containerInfoPreviewPC: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        "box-shadow": "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 8px rgba(96, 97, 112, 0.16)",
        borderRadius: "8px",
    },
    containerInfoPreview: {
        padding: theme.spacing(2),
    },
    infoNomeEQuantidade: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '23px',
        maxWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    infoItemValor: {
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: '32px',
        maxWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    valorNF: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1), 
        }
    },
    valorNFLabel: {
        fontSize: '14px',
        marginTop: 12,
    },
    valorNFValor: {
        fontSize: '40px',
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            paddingBottom: 0,
        }
    },
    valorTroco: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    valorTrocoLabel: {
        marginTop: 10,
        fontSize: '14px',
    },
    valorTrocoValor: {
        fontSize: '30px',
    },
    containerItemPreview: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    containerPrice: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        fontSize: '0.750rem',
        color: '#777',
        lineHeight: '14px'
    },
    labelValue: {
        fontSize: '1rem',
        color: '#696969',
        lineHeight: '14px',
        fontWeight: 600
    },
    labelPrice1: {
        fontSize: '2.5rem',
        lineHeight: '3.75rem',
        fontWeight: 600
    },
    labelPrice2: {
        fontSize: '2.5rem',
        lineHeight: '3.75rem',
        fontWeight: 600
    },
    labelQtde: {
        fontSize: '1.875rem',
        lineHeight: '2.813rem',
        fontWeight: 600
    },
    labelR$: {
        fontSize: '0.875rem',
        lineHeight: '3rem',
        fontWeight: 600,
        marginRight: '2px'
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    buttonContainer: {
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: 650,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1),
        }
    },
    buttons: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "& button": {
            height: 40
        }
    },
    containerImageSuccess: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    svg: {
        width: '105px',
        [theme.breakpoints.down('sm')]: {
            width: '95px'
        }
    },
    renderCompartilhamento: {
        marginTop: 8
    },
}));