export enum EnumPDVConfigCod {
    SincronizacaoCadastros = 15,
    ModeloVenda = 50,
    TicketConsumacao = 18,
    NotaManual = 102,
    ModeloTrabalho = 101,
    ModeloEquipamento = 51,
    PortaComunicacao = 52,
    QuantidadeColunas = 57,
    ImpressaoComprovante = 59,
    PortaComunicacao2 = 62,
    DispararSetorFinalizacaoVenda = 500,
    TaxaServicoSemPedido = 600,
}