import { DefaultCard } from '../components';
import { CardEdicaoGerenciamentoSessaoProps } from './card-edicao-gerenciamento-sesso-props';
import { isEmpty } from 'lodash';
import { toDateString } from 'utils/to-date';
import { useCallback } from 'react';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { toDecimalString } from 'utils/to-decimal';
import { Grid, Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';

export const CardEdicaoGerenciamentoSessao = ({
    sessao,
    sessaoValor
}: CardEdicaoGerenciamentoSessaoProps) => {

    const {isMobile} = useThemeQueries()
    const retornarDescricaoStatus = useCallback(() => {
        if (isEmpty(sessao.dFechamento)) {
            return 'Sessão Aberta';
        } else if (isEmpty(sessao.dConferencia)) {
            return 'Sessão Fechada';
        } else {
            return 'Sessão Conferida';
        }
    }, [sessao.dConferencia, sessao.dFechamento]);

    const valorDiferenca = useCallback(() => {
        let valorCaixa = 0;
        sessaoValor
            .filter((x) => x.tpLancamento === EnumTpLancamentoSessaoValor.FECHAMENTO)
            .forEach((item) => {
                valorCaixa += item.valor;
            });

        if (valorCaixa === 0) {
            return sessao.valorVendido;
        }

        return Math.abs(valorCaixa - sessao.valorVendido || 0);
    }, [sessao.valorVendido, sessaoValor]);

    return (
        <DefaultCard>
            <Grid container spacing={1} alignItems='center' justifyContent='space-between' p={1}>
                <Grid item xs={6} md={3} justifyContent='center'>
                    <Typography
                        variant="body1"
                        weight={600}
                    >
                        {retornarDescricaoStatus()}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} flex flexDirection='column'>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                        align='right'
                    >
                        Data da Conferência
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        align='right'

                    >
                        {!isEmpty(sessao.dConferencia) ? toDateString(sessao.dConferencia) : '-'}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3} flex flexDirection='column' justifyContent='center'>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                        align={isMobile ? 'left' :'right'}
                    >
                        Responsável
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        align={isMobile ? 'left' :'right'}                    >
                        {sessao.operadorNome}
                    </Typography>

                </Grid>
                <Grid item xs={6} md={3} flex flexDirection='column' justifyContent='flex-end'>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                        align='right'
                    >
                        Diferença
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        align='right'
                    >
                        R$ {toDecimalString(valorDiferenca(), 2)}
                    </Typography>
                </Grid>
            </Grid>

        </DefaultCard>
    );
};
