import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { CategoriaModal } from "views/components/modals/categoria/categoria-modal";

export const CadastroCategoriaModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: ''
    });

    const categoriaRoute = `/categorias/`;
    const novoCadastroRoute = 'adicionar';
    consoleDev('CadastroCategoriaModal')

    useEffect(() => {
        if (location.pathname.indexOf(categoriaRoute) === 0) {
            const posrota = location.pathname.replace(categoriaRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: categoriaRoute,
                    search: prev.search
                }));
                return;
            }
        }
    }, [location.pathname, categoriaRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl, categorias }: any) => {
        let url = callbackUrl.length === 0 ? categoriaRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;
        
        if (!openned) {
            push({
                pathname: categoriaRoute,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(categoriaRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        });
    }, [categoriaRoute, modalState.aberto, modalState.search, modalState.ultimaUrl, push])

    useEffect(() => {
        addHandler(AppEventEnum.CategoriaModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.CategoriaModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <CategoriaModal
            openned={modalState.aberto}
            key="mdlPessoa"
            id={modalState.id}
        />
    )
}