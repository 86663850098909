import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { VoltarIcon } from 'views/components/icons';
import { EnumUploadCarga } from 'model/enums/enum-upload-carga';
import { Box, Typography } from '@material-ui/core';
import { LoadingButton } from 'views/components';
import { useThemeQueries } from 'views/theme';

export interface LocationProps {
  from: {
    page?: number;
  }
}

interface UploadCargaDetalheHeaderProps {
  handleVoltar: () => void;
  status: EnumUploadCarga;
}

export const UploadCargaDetalheHeader = ({ handleVoltar, status }: UploadCargaDetalheHeaderProps) => {

  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={handleVoltar}
      ></ButtonPrivateHeader>
    );
  }, [handleVoltar]);

  return (
    <>
      <PrivatePageHeader title="Detalhes da Conversão" leftArea={leftArea()} rightArea={
        (status === EnumUploadCarga.CONFIRMACAO && !isMobile) ? (
          <Box display='flex' alignItems='center' gridGap={8} width='100%'>
            <LoadingButton tipo='AZUL' size='NORMAL' />
            <Typography
              style={{ whiteSpace: 'nowrap', fontSize: 14 }}
            >
              Sua carga está sendo processada...
            </Typography>
          </Box>
        ) : undefined
      } bottomArea={
        (status === EnumUploadCarga.CONFIRMACAO && isMobile) ? (
          <Box display='flex' justifyContent='center' alignItems='center' gridGap={8} width='100%' pb={1}>
            <LoadingButton tipo='AZUL' size='NORMAL' />
            <Typography
              style={{ whiteSpace: 'nowrap', fontSize: 14 }}
            >
              Sua carga está sendo processada...
            </Typography>
          </Box>
        ) : undefined
      } />
    </>
  );
};
