import useEventListener from '@use-it/event-listener';
import { EnumKey } from "model/enums/enum-keycode";

const defaultForwardKeys = [
    EnumKey.ARROW_UP,
    EnumKey.ARROW_RIGHT,
    EnumKey.ADD
]

const defaultBackwardKeys = [
    EnumKey.ARROW_DOWN,
    EnumKey.ARROW_LEFT,
    EnumKey.SUBTRACT
]

const defaultSubmitKeys = [
    EnumKey.ENTER,
]

const defaultCancelKeys = [
    EnumKey.ESC,
]

interface Props{
    onForward?: () => any;
    onBackward?: () => any;
    onEnter?: () => any;
    onCancel?: () => any;
    overrideKeys?: {
        forwardKeys? : EnumKey[];
        backwardKeys?: EnumKey[];
        submitKeys?: EnumKey[];
        cancelKeys?: EnumKey[];
    }
}

export const useKeyControls = (
    {
        onBackward,
        onCancel,
        onEnter,
        onForward,
        overrideKeys,
    }: Props
) => {

    const forwardKeys = overrideKeys?.forwardKeys || defaultForwardKeys;
    const backwardKeys = overrideKeys?.backwardKeys || defaultBackwardKeys;
    const submitKeys = overrideKeys?.submitKeys || defaultSubmitKeys;
    const cancelKeys = overrideKeys?.cancelKeys || defaultCancelKeys;

    useEventListener('keydown', (event: any) => {
        const key = event.key;

        if ([...forwardKeys, ...backwardKeys, ...submitKeys, ...cancelKeys].includes(key)) {

            if (submitKeys.includes(key) && onEnter) {
                event.preventDefault();
                event.stopPropagation();
                onEnter();
                return
            }

            event.preventDefault();

            if (backwardKeys.includes(key) && onBackward) {
                event.preventDefault();
                event.stopPropagation();
                onBackward();
                return
            }

            if (forwardKeys.includes(key) && onForward) {
                event.preventDefault();
                event.stopPropagation();
                onForward();
                return
            }

            if (cancelKeys.includes(key) && onCancel) {
                event.preventDefault();
                event.stopPropagation();
                onCancel();
                return;
            }
        }
    })
}