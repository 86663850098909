import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Grid } from 'views/design-system';
import { useFormPromocaoNomeValidation } from './form-promocao-nome-validations';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';

import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export class PromocaoNomeFormModel {
  constructor(
    public nome: string = ''
  ) { }
}

export interface FormPromocaoNomeProps
  extends DefaultFormProps<PromocaoNomeFormModel> { }

export const FormPromocaoNome = forwardRef<
  DefaultFormRefs<PromocaoNomeFormModel>,
  FormPromocaoNomeProps
>(({ loading, ...props }: FormPromocaoNomeProps, ref) => {
  const utilClasses = makeUtilClasses();
  const [focused, setFocused] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<PromocaoNomeFormModel>(
    new PromocaoNomeFormModel()
  );

  const { FormYupValidationNomePromocao } = useFormPromocaoNomeValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<PromocaoNomeFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormYupValidationNomePromocao),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (values: PromocaoNomeFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new PromocaoNomeFormModel());
      reset();
    },
    fillForm: (model: PromocaoNomeFormModel) => {
      setInitialValues(model);
      reset({ ...model });
    }
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid pb={2}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      {...field}
                      allowSubmit
                      maxTexto={30}
                      autoFocus={focused}
                      disabled={loading}
                      onFocus={() => setFocused(true)}
                      onBlur={() => {
                        field.onBlur();
                        setTimeout(() => setFocused(false), 100);
                      }}
                      fullWidth
                      variant="outlined"
                      label={'Nome da Campanha da Promoção'}
                      tipo={'TEXTO'}
                      placeholder="Ex: Promoção de Natal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(errors.nome && errors.nome.message)}
                      helperText={
                        touchedFields.nome || errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </Grid>
        </form>
      </div >
    </>
  );
});
