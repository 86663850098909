import { useStyles } from './styles';
import { usePostComissaoComissionados } from 'data/api/gestao/comissao/post-comissionados';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import {
  Comissionado,
  useComissionadosStore
} from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { TipoValorComissao } from '../comissionado-valor/components/keyboard/components/keyboard-fragment-props';
import { ComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { EnumTpCondicaoApuracao } from 'model/enums/enum-tp-condicao-apuracao';
import { guidEmpty } from 'utils/guid-empty';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { newGuid } from 'utils/new-guid';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { PreviewVendedores } from './components/preview-vendedores';
import { ComissionadoValor } from '../comissionado-valor/comissionado-valor';
import { CircularLoading } from 'views/components/utils';
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';

interface ComissionadoVendedorProps {
  comissaoId: string;
  getComissionados: () => Promise<void>;
  carregando: boolean;
  handleRemoverComissionado: (
    comissionados: Comissionado[]
  ) => Promise<boolean>;
}

enum EnumPassoComissionadoVendedores {
  Lista,
  Valor
}

export const ComissionadoVendedor = ({
  carregando: carregandoComissionado,
  comissaoId,
  getComissionados,
  handleRemoverComissionado,
  ...props
}: ComissionadoVendedorProps) => {
  const {
    setVendedoresAtual,
    state: { comissionadoVendedores }
  } = useComissionadosStore((state) => state);
  const { postComissaoComissionados, carregando: carregandoPost } =
    usePostComissaoComissionados();
  const { getEmpresaAtual } = useEmpresaAtual();

  const carregando = [carregandoComissionado, carregandoPost].includes(true);

  const [passo, setPasso] = useState<EnumPassoComissionadoVendedores>(
    EnumPassoComissionadoVendedores.Lista
  );

  // HOOKS
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const { fecharComissaoComissionados } = useCadastros();
  const { abrirPessoaSelecionarModal, fecharPessoaSelecionarModal } =
    useFiltrosModais();
  const { showToast } = useToastSaurus();

  const definirPasso = (passo: EnumPassoComissionadoVendedores) => {
    setPasso(passo);
  };

  const handleVoltarValor = useCallback(() => {
    definirPasso(EnumPassoComissionadoVendedores.Lista);

    setVendedoresAtual([]);
  }, [setVendedoresAtual]);

  const handleConfirmarValor = useCallback(
    async (valor: number, tipoValor: TipoValorComissao) => {
      try {
        const vendedores =
          useComissionadosStore.getState().state.vendedoresAtual;
        if (!vendedores) return;
        const comissionados: ComissaoModel[] = vendedores.map((vendedor) => ({
          ...new ComissaoModel(),
          ComissaoCampanhaId: comissaoId,
          comissionadoId: vendedor.pessoaId,
          condicao: tipoValor as unknown as EnumTpCondicaoApuracao,
          valor: valor,
          id: guidEmpty(),
          vendedorId: vendedor.pessoaId
        }));

        const res = await postComissaoComissionados(
          getEmpresaAtual()?.id ?? '',
          comissaoId,
          comissionados
        );
        if (res.erro) throw res.erro;

        await getComissionados();

        fecharComissaoComissionados();
        showToast('success', 'Vendedores adicionados!');
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      comissaoId,
      fecharComissaoComissionados,
      getComissionados,
      getEmpresaAtual,
      postComissaoComissionados,
      showToast
    ]
  );

  const handleAdicionarVendedores = useCallback(
    (pessoas: PessoaModel[]) => {
      setVendedoresAtual(
        pessoas.map((pessoa) => ({
          condicao: 0,
          documento: pessoa.cpfcnpj,
          id: guidEmpty(),
          pessoaId: pessoa.id,
          internalId: newGuid(),
          nome: pessoa.nome,
          tipo: EnumTpPessoaComissionamento.Vendedor,
          valor: 0
        }))
      );

      definirPasso(EnumPassoComissionadoVendedores.Valor);
      fecharPessoaSelecionarModal();
    },
    [fecharPessoaSelecionarModal, setVendedoresAtual]
  );

  const handleVoltarSelecaoPessoa = useCallback(() => {
    fecharPessoaSelecionarModal();
  }, [fecharPessoaSelecionarModal]);

  const abrirSelecionarVendedores = useCallback(() => {
    const comissionados =
      useComissionadosStore.getState().state.comissionadoVendedores;
    abrirPessoaSelecionarModal({
      adicionar: handleAdicionarVendedores,
      voltar: handleVoltarSelecaoPessoa,
      idsBloqueados: comissionados.map((x) => x.pessoaId),
      typeSelect: 'select',
      label: 'Selecione os Vendedores'
    });
  }, [
    abrirPessoaSelecionarModal,
    handleAdicionarVendedores,
    handleVoltarSelecaoPessoa
  ]);

  useEffect(() => {
    if (comissionadoVendedores.length === 0) {
      abrirSelecionarVendedores();
    }
  }, [abrirSelecionarVendedores, comissionadoVendedores.length]);

  return (
    <div className={modalClasses.root}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <ModalHeader
        title={passo ? 'Novos Vendedores' : 'Vendedores'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={fecharComissaoComissionados}
          />
        }
      />
      <div className={classes.content}>
        {passo === EnumPassoComissionadoVendedores.Lista && (
          <PreviewVendedores
            handleRemove={handleRemoverComissionado}
            setAddVendedores={abrirSelecionarVendedores}
            setDel={() => {}}
          />
        )}
        {passo === EnumPassoComissionadoVendedores.Valor && (
          <ComissionadoValor
            handleConfirmar={handleConfirmarValor}
            handleVoltar={handleVoltarValor}
          />
        )}
      </div>
    </div>
  );
};
