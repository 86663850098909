import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Box, Button, Grid, Typography } from 'views/design-system';
import { PublicPageHeader } from 'views/components/headers';
import { ContinuarIcon, VoltarIcon } from 'views/components/icons';
import { GestaoStorageKeys, useGestaoStorage } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { isEmpty } from 'lodash';
import { makeUtilClasses } from 'views/theme';
import { validaGuid } from 'utils/valida-guid';
import { DialogScannChaveAtivacao } from 'views/components/dialog/dialog-sccan-chave-ativacao/dialog-scann-chave-ativacao';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';

const NovoContratoChavePage = () => {
  const history = useHistory();
  const [codigo, setCodigo] = useState<string>('');
  const [dialogScanner, setDialogScanner] = useState<boolean>(false);
  const utilClasses = makeUtilClasses();
  const { setRegistro, exists, delRegistro } = useGestaoStorage();
  const [hasErro, setHasErro] = useState<boolean>(false);
  const [msgErro, setMsgErro] = useState<string>('');

  useEffect(() => {
    if (exists(GestaoStorageKeys.ChaveAtivacao)) {
      delRegistro(GestaoStorageKeys.ChaveAtivacao);
    }
  }, [delRegistro, exists]);

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    setHasErro(false);
    try {
      const chave = atob(codigo);
      const [id] = chave.split('|');

      if (!chave.includes('|')) {
        setHasErro(true);
        setMsgErro('Chave de Ativação inválida');
        return;
      }

      if (!validaGuid(id)) {
        setHasErro(true);
        setMsgErro('Chave de Ativação inválida');
        return;
      }
      history.push(`/novo-contrato?chaveAtivacao=${codigo}`);
      setRegistro(GestaoStorageKeys.ChaveAtivacao, codigo);
    } catch (err: any) {
      setHasErro(true);
      setMsgErro('Chave de Ativação inválida');
    }
  };

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <div className={utilClasses.contentCenter}>
        <Grid container className={utilClasses.rootContainer}>
          <Grid item xs={12}>
            <Grid container alignItems="center" flexDirection="column">
              <Grid item xs={12}>
                <Typography align="center" variant="h3" color="primary">
                  Chave de Ativação
                </Typography>
                <Box mt={1} mb={5}>
                  <Typography variant="body1" align="center">
                    Digite ou escaneie a chave de ativação para liberar a
                    criação de um novo contrato.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={8} style={{ width: '100%' }}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldSaurus
                        fullWidth
                        tipo="TEXTO"
                        onChange={(ev) => setCodigo(ev.target.value)}
                        value={codigo}
                        allowSubmit
                        error={hasErro}
                        helperText={hasErro && !isEmpty(msgErro) && msgErro}
                        showCamBarcode
                        handleShowBarCam={setDialogScanner}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        fullWidth={true}
                        onClick={() => {
                          if(VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS){
                            history.push('/novo-contrato-redirecionar')
                            return
                          }
                          history.push('/login')
                        }}
                        size="large"
                      >
                        <VoltarIcon tipo="BUTTON" />
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item xs={8} style={{ width: '100%' }}>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={isEmpty(codigo)}
                          fullWidth={true}
                          size="large"
                        >
                          <ContinuarIcon tipo="BUTTON_PRIMARY" />
                          Confirmar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {dialogScanner && (
        <DialogScannChaveAtivacao
          openned={dialogScanner}
          setCodigo={setCodigo}
          closeModal={() => setDialogScanner(false)}
        />
      )}
    </>
  );
};

export default NovoContratoChavePage;
