import { ProdutoSelecionarBuscaProps } from './produto-selecionar-busca-props';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Box, Button, Grid } from 'views/design-system';
import { Paginacao } from 'views/components/paginacao';
import {
  FiltroIcon,
  InformacaoIcon,
  OkIcon,
  VoltarIcon
} from 'views/components/icons';
import {
  EnumFiltroStatusProduto,
  ProdutoListSearchProps
} from 'views/components/form/receita/list-products/produto-list/produto-list-search-props';
import { FormPesquisaHeader } from 'views/components/form/form-pesquisa-header/form-pesquisa-header';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import classNames from 'classnames';
import { CardNaoEncontrado } from 'views/components/cards';
import { useGetProdutos } from 'data/api/gestao/produto/produto/get-produtos';
import { isEmpty } from 'lodash';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import CardProdutoSelecionar from 'views/components/cards/card-produto-selecionar/card-produto-selecionar';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { CircularLoading } from 'views/components/utils';
import { useModalStyles } from '../../utils/modal-styles';
import { useStyles } from './produto-selecionar-busca-styles';
import { ModalHeader } from '../../components';
import { TecladoIcon } from 'views/components/icons/teclado-icon';
import { formatDecimalInteger } from 'utils/to-decimal';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { MockStatus } from 'views/pages/private/cadastros/produto/components/produto-list/produto-list';

export const ProdutoSelecionarBusca = (props: ProdutoSelecionarBuscaProps) => {
  const ultimoTermoPesquisado = useRef(undefined as string | undefined);
  const termoRef = useRef<HTMLInputElement>(null);

  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const { abrirProdutoFiltroModal } = useFiltrosModais();
  const { showToast } = useToastSaurus();
  const { getProdutos, carregando } = useGetProdutos();
  const [produtosSelecionados, setProdutosSelecionados] = useState<
    ProdutoResumidoModel[]
  >([]);
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<ProdutoResumidoModel>()
  });

  const [filtros, setFiltros] = useState<ProdutoListSearchProps>({
    status: EnumFiltroStatusProduto.ATIVO,
    codigo: '',
    descricao: '',
    tipo: -1,
    termo: '',
    categoria: '',
    marca: ''
  });

  const modalClasses = useModalStyles();
  const classes = useStyles();

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.termo) ? '&Generico=' + filtros.termo : '') +
        (!isEmpty(filtros.descricao) ? `&Descricao=${filtros.descricao}` : '') +
        (!isEmpty(filtros.codigo) ? `&Codigo=${filtros.codigo}` : '') +
        (!isEmpty(filtros.categoria) ? `&categoria=${filtros.categoria}` : '') +
        (!isEmpty(filtros.marca) ? `&marca=${filtros.marca}` : '') +
        (filtros.tipo !== -1 ? `&Tipo=${filtros.tipo}` : '') +
        (filtros.status !== EnumFiltroStatusProduto.TODOS
          ? `&ativo=${MockStatus[filtros.status]}`
          : '');
      try {
        const res = await getProdutos(
          query,
          getEmpresaSelecionada()?.Id || '',
          newPage
        );
        if (res.erro) throw res.erro;
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        ultimoTermoPesquisado.current = filtros.termo;
        setQueryStatus({
          page: res.resultado?.data?.pageIndex,
          list: res.resultado?.data?.list,
          totalResults: res.resultado?.data?.totalResults,
          totalPages: res.resultado?.data?.totalPages
        });
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      filtros.termo,
      filtros.descricao,
      filtros.codigo,
      filtros.categoria,
      filtros.marca,
      filtros.tipo,
      filtros.status,
      getProdutos,
      getEmpresaSelecionada,
      showToast
    ]
  );

  const handleFilter = (filtro: ProdutoListSearchProps) => {
    setFiltros((prev) => ({
      ...prev,
      codigo: filtro.codigo,
      descricao: filtro.descricao,
      tipo: filtro.tipo,
      status: filtro.status,
      categoria: filtro.categoria,
      marca: filtro.marca
    }));

    return;
  };

  const cardSelecionado = useCallback(async (produto: ProdutoResumidoModel) => {
    setProdutosSelecionados((prev) => {
      if (
        prev.findIndex((x) => x.produtoGradeId === produto.produtoGradeId) > -1
      ) {
        return prev.filter((x) => x.produtoGradeId !== produto.produtoGradeId);
      } else {
        return [...prev, produto];
      }
    });
    return;
  }, []);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const selectAll = useCallback(() => {
    setProdutosSelecionados((prev) => {
      const todosSelecionados =
        (queryStatus?.list ?? []).filter(
          (x) =>
            prev.findIndex((y) => y.produtoGradeId === x.produtoGradeId) === -1
        ).length === 0;

      let res = prev.filter(
        (x) =>
          (queryStatus.list ?? []).findIndex(
            (y) => y.produtoGradeId === x.produtoGradeId
          ) === -1
      );

      if (!todosSelecionados) res.push(...queryStatus.list);
      return res;
    });
  }, [queryStatus.list]);

  useEffect(() => {
    termoRef.current?.focus();
  }, []);

  useEffect(() => {
    if (filtros.termo.length > -1) {
      search(1);
    }
  }, [filtros.termo, search]);

  const renderSearchTextfield = useMemo(
    () => (
      <FormPesquisaHeader
        text={filtros.termo}
        forceMobile
        inputRef={termoRef}
        onSubmit={(model) => {
          setFiltros((prev) => {
            if (prev.termo === model.generico) return prev;
            return {
              ...prev,
              termo: model.generico
            };
          });
        }}
        placeholder={`Buscar produtos`}
      />
    ),
    [filtros.termo]
  );

  const renderProdutos = useMemo(() => {
    return queryStatus.list.map((produto) => {
      return (
        <CardProdutoSelecionar
          selected={
            (props.idsBloqueados ?? []).findIndex(
              (item) =>
                item === produto.produtoId || item === produto.produtoGradeId
            ) > -1 ||
            produtosSelecionados.findIndex(
              (item) => item.produtoGradeId === produto.produtoGradeId
            ) > -1
              ? true
              : false
          }
          isFarma={isFarmaceutico}
          selectedDisabled={
            (props.idsBloqueados ?? []).findIndex(
              (item) =>
                item === produto.produtoId || item === produto.produtoGradeId
            ) > -1
          }
          onClick={() => cardSelecionado(produto)}
          model={produto}
          key={produto.id}
          select
        />
      );
    });
  }, [
    cardSelecionado,
    isFarmaceutico,
    produtosSelecionados,
    props.idsBloqueados,
    queryStatus.list
  ]);

  const renderTopContent = useMemo(() => {
    const todosSelecionados =
      (queryStatus?.list ?? []).filter(
        (x) =>
          produtosSelecionados.findIndex(
            (y) => y.produtoGradeId === x.produtoGradeId
          ) === -1
      ).length === 0;
    return (
      <Grid flex justifyContent="space-between" flexDirection="row">
        <Paginacao
          className={classes.paginacao}
          pageChanged={pageChanged}
          totalPages={queryStatus.totalPages}
          totalRegisters={queryStatus.totalResults}
          currentPage={queryStatus.page}
        />
        <Grid onClick={() => selectAll()} className={classes.botaoSelecionar}>
          Selecionar Todos
          <CheckboxDefault color="primary" checked={todosSelecionados} />
        </Grid>
      </Grid>
    );
  }, [
    classes.botaoSelecionar,
    classes.paginacao,
    pageChanged,
    produtosSelecionados,
    queryStatus?.list,
    queryStatus.page,
    queryStatus.totalPages,
    queryStatus.totalResults,
    selectAll
  ]);

  const renderButtons = useMemo(() => {
    const qtdSelecionados = produtosSelecionados.filter(
      (x) =>
        (props.idsBloqueados ?? []).findIndex(
          (item) => item === x.produtoId || item === x.produtoGradeId
        ) === -1
    ).length;
    return (
      <Box className={modalClasses.acoes}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Button
              disabled={qtdSelecionados === 0}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                if (props.adicionar)
                  props.adicionar(
                    produtosSelecionados.filter(
                      (x) =>
                        (props.idsBloqueados ?? []).findIndex(
                          (item) =>
                            item === x.produtoId || item === x.produtoGradeId
                        ) === -1
                    )
                  );
              }}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Confirmar Selecionados
              {(qtdSelecionados || 0) > 0 && (
                <Badge
                  className={classes.badgeQtde}
                  badgeContent={
                    (qtdSelecionados || 0) > 99
                      ? '99+'
                      : formatDecimalInteger(qtdSelecionados || 0, 3)
                  }
                  color="error"
                ></Badge>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }, [classes.badgeQtde, modalClasses.acoes, produtosSelecionados, props]);

  return (
    <Box className={classNames(modalClasses.root, classes.root)}>
      {carregando && (
        <Box className={classes.loadingContainer}>
          <CircularLoading tipo="FULLSIZED" />
        </Box>
      )}
      <ModalHeader
        title={renderSearchTextfield}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              if (props.voltar) props.voltar();
            }}
          />
        }
        rightArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<FiltroIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              abrirProdutoFiltroModal(filtros, handleFilter);
            }}
          />
        }
      />
      <Box className={modalClasses.content}>
        <Grid className={classNames(modalClasses.contentForms)}>
          {renderTopContent}
          <Grid>
            {!carregando && (
              <>
                {renderProdutos.length === 0 &&
                ultimoTermoPesquisado.current === undefined ? (
                  <CardNaoEncontrado
                    iconSize="small"
                    mensagem={`Digite alguma informação do produto para busca.`}
                    icon={<TecladoIcon tipo="GERAL" />}
                  />
                ) : renderProdutos.length === 0 &&
                  filtros.termo.length === 0 ? (
                  <CardNaoEncontrado
                    iconSize="small"
                    mensagem={`Nenhum produto encontrado.`}
                    icon={<InformacaoIcon tipo="GERAL" />}
                  />
                ) : renderProdutos.length === 0 && filtros.termo.length > 0 ? (
                  <CardNaoEncontrado
                    iconSize="small"
                    mensagem={`Nenhum produto encontrado com o filtro '${filtros.termo}'.`}
                    icon={<FiltroIcon tipo="GERAL" />}
                  />
                ) : (
                  renderProdutos
                )}
              </>
            )}
          </Grid>
        </Grid>
        {renderButtons}
      </Box>
    </Box>
  );
};
