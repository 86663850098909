import { useCallback } from "react"
import { useHistory } from "react-router"
import { EnumMenu } from "model"
import { useToastSaurus } from "./toast-saurus"
import { useCadastros } from "./cadastros"
import { usePDV } from "./pdv"
import { useMenuPrincipal } from "./menu-principal"
import { EnumMenuPrincipalModo } from "model/enums/enum-menu-principal-modo"
import { useMovAnteriorStorage } from "./mov-anterior-storage"
import { useMovRota } from "./mov-rota"
import { consoleDev } from "utils/console-dev"
import { useSincronizacaoGeral } from "./sincronizar-dados"
import { EnumStatusSincronizacaoGeral } from "model/enums/enum-status-sincronizacao-geral"

export const useOpenMovRota = () => {

    const history = useHistory()
    const { showToast } = useToastSaurus()
    const { get: getMovAnt } = useMovAnteriorStorage()
    const { getPDV } = usePDV()
    const { abrirCadastroPontosVenda, abrirConvenioAutenticar } = useCadastros()
    const { alterarModo, fecharMenu } = useMenuPrincipal()
    const { redirectPagamentoAvancado, redirectFinalizarVenda, redirectCancelamento, redirectLanding, redirectDelivery, validarCondicaoInicial } = useMovRota();
    const { iniciarSincronizacaoGeral } = useSincronizacaoGeral()

    const openMovRota = useCallback(async (url: string) => {
        try {
            await validarCondicaoInicial();
            history.push(url)
        } catch (e: any) {
            consoleDev('openMovRota', e.message)
            try {
                await redirectLanding(false, `?redirect=${url}`);
            } catch { }
        }
    }, [history, redirectLanding, validarCondicaoInicial])

    const actionMenuPDV = useCallback(async (codigoModulo: EnumMenu, path: string) => {
        try {
            switch (codigoModulo) {
                case EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_VENDAS:
                    const res = await iniciarSincronizacaoGeral()
                    if (res === EnumStatusSincronizacaoGeral.Erro)
                        showToast('error', 'Ocorreu algum problema ao sincronizar, tente novamente mais tarde.')
                    else if (res === EnumStatusSincronizacaoGeral.Sucesso)
                        showToast('success', 'Sincronização realizada com sucesso!')
                    else
                        showToast('info', 'Não há nenhuma pendência para Sincronização.')
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_PAGAMENTO:
                    await redirectPagamentoAvancado();
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA:
                    await redirectFinalizarVenda();
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR:
                    await redirectCancelamento();
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV:
                    abrirCadastroPontosVenda(getPDV()?.id ?? '', history.location.pathname, false);
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA:
                    const ultimaVenda = getMovAnt();
                    if (!ultimaVenda) {
                        showToast('info', 'Nenhuma venda foi localizada. Realize vendas para habilitar este módulo.')
                        break;
                    }
                    openMovRota(path);
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA:
                    alterarModo(EnumMenuPrincipalModo.Retaguarda);
                    openMovRota(path);
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY:
                    await redirectDelivery();
                    break;
                case EnumMenu.OPERACIONAL_DENTROVENDA_CONSULTAR_CONVENIO:
                    abrirConvenioAutenticar('consulta')
                    break;
                default:
                    openMovRota(path)
                    break;
            }
        } catch (e: any) {
            showToast('error', e.message);
        }
        fecharMenu();
    }, [fecharMenu, iniciarSincronizacaoGeral, showToast, redirectPagamentoAvancado, redirectFinalizarVenda, redirectCancelamento, abrirCadastroPontosVenda, getPDV, history.location.pathname, getMovAnt, openMovRota, alterarModo, redirectDelivery, abrirConvenioAutenticar])

    return {
        actionMenuPDV
    }
}
