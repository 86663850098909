import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: theme.palette.primary.main,
    },
    containerSaloes: {
        padding: 0,
        backgroundColor: '#FAFAFA',
        paddingTop: theme.spacing(2),
        height: '100%'
    },
    contentForms: {
        margin: theme.spacing(1, 2)
    },
    titleTop: {
        boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
        backgroundColor: '#FAFAFA',
    },
    dividerCard: {
        backgroundColor: theme.palette.grey[300],
        margin: theme.spacing(2, 0),
    }
}))