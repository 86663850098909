import { Grid, Typography } from 'views/design-system';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useState } from 'react';
import { useStyles } from './card-produto-subitem-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimalString } from 'utils/to-decimal';
import { AddESub } from './components/add-e-sub/button-add-e-sub';
import { MovSimplesModificadoresProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-modificadores-produto-model';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { DefaultCard } from '../components';
import { isEmpty } from 'lodash';

type CardSubitemProps = {
  produto: MovSimplesProdutoModel;
  handleClickAddOrSub: (
    tpProduto: EnumTpProduto,
    valorProdutoPai: number,
    produto: MovSimplesProdutoModel,
    qtd: number,
    modificador?: MovSimplesModificadoresProdutoModel,
    prodGradeId?: string,
    fator?: 'add' | 'sub'
  ) => Promise<void>;
  verificar?: boolean;
  showValue?: boolean;
  edit?: boolean;
  qtdPai: number;
  qtdModificador?: number;
  valorProdutoPai: number;
  tpProdutoPai: EnumTpProduto;
};

export const CardSubitem = ({
  produto,
  handleClickAddOrSub,
  verificar,
  showValue,
  edit,
  qtdPai,
  qtdModificador,
  valorProdutoPai = 0,
  tpProdutoPai
}: CardSubitemProps) => {
  // STATES E REFS
  const [qtd, setQtd] = useState<number>(produto.qCom);

  // AUX
  const classes = useStyles();

  const img = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  if (edit && verificar && produto.qCom < 0) {
    return null;
  }

  return (
    <DefaultCard >
      {/* {produto.subItens && produto.subItens.length > 0 && (
        <Typography
          variant="caption"
          className={classes.textInfoPersonalizavel}
        >
          Personalizável
        </Typography>
      )} */}

      <Grid className={classes.cardContainer}>
        <Grid style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          {img(produto.imgUrl)}

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 4,
              justifyContent: 'center'
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: '600', fontSize: '14px' }}
            >
              {produto.xProd}
            </Typography>
            {!isEmpty(produto.infAdic) && <Typography
              variant="body1"
              style={{ fontSize: '12px' }}
            >
              {produto.infAdic}
            </Typography>}
            <Typography
              variant="body1"
              style={{ fontWeight: 400, fontSize: '12px', height: '14.4px' }}
            >
              <span className={verificar && showValue ? '' : classes.invisible}>
                + R$ ${toDecimalString(
                  (produto.qComModificador > 0
                    ? produto.qComModificador
                    : qtd) * (produto.vUnCom ?? 0),
                  2
                )}
              </span>
              <span className={produto.infoSubItem!.qPadrao >= qtd || verificar ? classes.invisible : ''}>
                + R$ {toDecimalString(
                  (produto.qComModificador > 0
                    ? produto.qComModificador
                    : qtd - produto.infoSubItem!.qPadrao) *
                  (produto.vUnCom ?? 0),
                  2
                )}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
          <AddESub
            qtd={qtd}
            setQtd={setQtd}
            model={produto}
            alterarQtdeProduto={handleClickAddOrSub}
            verificar={verificar}
            qtdModificador={qtdModificador}
            valorProdutoPai={valorProdutoPai}
            tpProdutoPai={tpProdutoPai}
          />
        </Grid>
      </Grid>
    </DefaultCard>
  );
};
