export enum EnumFiltroStatusProduto{
    TODOS = -1,
    DESATIVADO = 1,
    ATIVO = 2
}

export interface ProdutoListSearchProps {
    termo: string;
    status: EnumFiltroStatusProduto;
    descricao: string;
    codigo: string;
    tipo: number;
    categoria: string;
    marca: string;
}