import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Grid } from "views/design-system";
import { useStyles } from "./environment-config-styles";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { getDevice, getEnvironment, getGatewayVersion, getIsDev, getLabel, getPerformanceDebugMode, setDevice, setEnvironment, setGatewayVersion, setIsDev, setLabel, setPerformanceDebugMode } from "config/environment-variables";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";

interface EnvironmentConfigProps {
    children: ReactNode;
}
export const EnvironmentConfig = (props: EnvironmentConfigProps) => {
    const classes = useStyles();
    const { addHandler, removeHandler } = useEventTools();

    const isDev = getIsDev();
    const label = getLabel();
    const environment = getEnvironment();
    const performanceDebugMode = getPerformanceDebugMode()
    const gatewayVersion = getGatewayVersion()
    const device = getDevice();
    const [painelAberto, setPainelAberto] = useState(false);

    const environmentConfig = useCallback((e: MouseEvent) => {
        if (e.altKey && e.shiftKey && e.ctrlKey) {
            setPainelAberto(true);
        }
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.EnvironmentConfig, environmentConfig)
        return () => {
            removeHandler(AppEventEnum.EnvironmentConfig, environmentConfig)
        }
    }, [addHandler, environmentConfig, removeHandler]);

    useEffect(() => {

        const script1 = document.createElement('script');
        if (device === EnumDeviceType.NAVIGATOR) {
            script1.src = `${process.env.PUBLIC_URL}/js/web-device.js`;
        }
        else {
            script1.src = `${process.env.PUBLIC_URL}/js/cordova-device.js`;
        }
        script1.async = true;
        document.body.appendChild(script1);


        const script2 = document.createElement('script');
        if (device !== EnumDeviceType.NAVIGATOR) {
            script2.src = `${process.env.PUBLIC_URL}/cordova.js`;
            script2.async = true;
        }
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };

    }, [device]);

    useEffect(() => {
        if (painelAberto) {
            const labelElement = document.getElementById('label') as HTMLInputElement;
            if (labelElement !== null) {
                labelElement.value = label;
            }

            const gatewayVersionElement = document.getElementById('gatewayVersion') as HTMLInputElement;
            if (gatewayVersionElement !== null) {
                gatewayVersionElement.value = gatewayVersion;
            }
        }


    }, [gatewayVersion, label, painelAberto]);

    const alterarAmbiente = useCallback((e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();

        const form = e.target as any;

        setIsDev(form.isDev.value === 'true');
        setLabel(form.label.value);
        setEnvironment(form.environment.value);
        setPerformanceDebugMode(form.performanceDebugMode.value === 'true')
        setGatewayVersion(form.gatewayVersion.value);
        setDevice(Number(form.device.value) as EnumDeviceType);

        alert('Ambiente alterado com sucesso! Pagina irá recarregar');

        (window as any).location = (window as any).location
    }, []);

    return (
        <>
            {painelAberto && (
                <Grid className={classes.container} >
                    <form method="post" className={classes.form} onSubmit={alterarAmbiente}>
                        <Grid>
                            isDev: <select name="isDev" id="isDev">
                                <option value="true" selected={isDev}>true</option>
                                <option value="false" selected={!isDev}>false</option>
                            </select>
                        </Grid>
                        <Grid>
                            label: <input type='text' id="label" name='label' />
                        </Grid>
                        <Grid>
                            environment: <select name="environment" id="environment">
                                <option value="staging" selected={environment === "staging"}>staging</option>
                                <option value="prod" selected={environment === "prod"}>prod</option>
                            </select>
                        </Grid>
                        <Grid>
                            performanceDebugMode:  <select name="performanceDebugMode" id="performanceDebugMode">
                                <option value="true" selected={performanceDebugMode}>true</option>
                                <option value="false" selected={!performanceDebugMode}>false</option>
                            </select>
                        </Grid>
                        <Grid>
                            gatewayVersion: <input type='text' id="gatewayVersion" name='gatewayVersion' />
                        </Grid>
                        <Grid>
                            device: <select name="device" id="device">
                                <option value="2" selected={device === EnumDeviceType.NAVIGATOR}>NAVIGATOR</option>
                                <option value="3" selected={device === EnumDeviceType.CORDOVA}>CORDOVA</option>
                                <option value="4" selected={device === EnumDeviceType.CORDOVA_POS}>CORDOVA_POS</option>
                            </select>
                        </Grid>
                        <button type='submit'>Alterar</button>
                        <button type='button' onClick={() => {
                            try {
                                (window as any)?.retornaAmbienteJs();
                            } catch (e) {
                                alert(e);
                            }
                        }}>TesteAmbiente</button>
                        <button type='button' onClick={() => { setPainelAberto(false); }}>Fechar</button>
                    </form>
                </Grid>
            )}
            {props.children}
        </>

    );
}