import { useThemeQueries } from 'views/theme';
import { VendaHeaderMobile } from './components/venda-header-mobile';
import { VendaHeaderDesktop } from './components/venda-header-desktop';
import { memo } from 'react';

const VendaHeader = () => {
  const { isMobile } = useThemeQueries();
  return (
    isMobile ?
      <VendaHeaderMobile /> :
      <VendaHeaderDesktop />
  )
};

export default memo(VendaHeader)