import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useRef, useState } from "react"
import { AvancarIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useToastSaurus } from "services/app"
import { useStyles } from "./info-pedido-compra-styles"
import classNames from "classnames"
import { DefaultFormRefs } from "views/components/form/utils"
import { FormInfoPedidoCompra } from "views/components/form/entrada/dados-info-pedido-compra/form-info-pedido-compra"
import { InfoPedidoCompraFormModel } from "model/app/forms/entrada/info-pedido-compra-form-model"
import { isEqual } from "lodash"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"
import { Button, Grid } from "views/design-system"
import { Container } from "@material-ui/core"

const NovoProdutoPage = () => {
    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const formRef = useRef<DefaultFormRefs<InfoPedidoCompraFormModel>>(null)
    const rotaApos = useRef<'proximo' | 'anterior'>('proximo')

    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const [modelForm, setModelForm] = useState<InfoPedidoCompraFormModel>(new InfoPedidoCompraFormModel())

    const { getVendaCompleta, saveChangesVendaCompleta, carregando } = useEntrada(mov)

    const recarregarForm = useCallback((model: InfoPedidoCompraFormModel) => {
        formRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(modelForm)
    }, [modelForm, recarregarForm])

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        let movAtual = new VendaCompletaModel()
        if (location.state?.mov && !att) {
            movAtual = location.state.mov
        }
        const venda = await getVendaCompleta(movId)

        movAtual = venda as VendaCompletaModel

        const valueForm: InfoPedidoCompraFormModel = {
            xCont: movAtual.infMov.xCont,
            xNEmp: movAtual.infMov.xNEmp,
            xPed: movAtual.infMov.xPed,
        }

        setMov(movAtual)
        return valueForm
    }, [getVendaCompleta, location.state?.mov, movId])

    const preencherTela = useCallback(async () => {
        try {
            const info = await getInfoVenda()
            setModelForm(info)
        } catch (error: any) {
            showToast('error', error.message)
        }
    }, [getInfoVenda, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.state, mov, movId]
    );

    useEffect(() => {
        preencherTela()
    }, [getInfoVenda, preencherTela])

    const saveChanges = async (model: InfoPedidoCompraFormModel) => {
        const movAtual = structuredClone(mov)

        movAtual.infMov = {
            ...movAtual.infMov,
            ...model

        }
        await saveChangesVendaCompleta(movAtual)
    }

    const proximaTela = (att: boolean = false) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/pagamento`,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto
        }
    })

    const voltarTela = (att: boolean = false) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/doc-referenciados`,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto
        }
    })

    const handleSubmit = async (model: InfoPedidoCompraFormModel, beforeModel: InfoPedidoCompraFormModel) => {
        try {
            const equal = isEqual(model, beforeModel)
            if (equal) {
                showToast('info', 'Nenhuma informação foi alterada')
                if (rotaApos.current === 'proximo') {
                    proximaTela()
                } else {
                    voltarTela()
                }
                return
            }

            await saveChanges(model)
            showToast('success', 'Informações atualizadas com sucesso!')

            await getInfoVenda(true)
            if (rotaApos.current === 'proximo') {
                proximaTela(true)
            } else {
                voltarTela(true)
            }
        } catch (error: any) {
            showToast('error', error.message)
        }
    }

    const submit = (rota: 'proximo' | 'anterior') => {
        rotaApos.current = rota
        formRef.current?.submitForm()
    }

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Informações de Pedido de Compra"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <FormInfoPedidoCompra
                            loading={carregando}
                            onSubmit={handleSubmit}
                            showLoading={false}
                            ref={formRef}
                        />
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => submit('anterior')}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => submit('proximo')}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default NovoProdutoPage