import { isEmpty } from "lodash"
import { EnumContratoConfig } from "model/enums/enum-contrato-config"
import { useCallback, useEffect } from "react"
import { useContratoAtual } from "services/app/hooks/contrato-atual"
import { useMovAtual } from "services/app/hooks/mov-atual"
import { useMovRota } from "services/app/hooks/mov-rota"
import { consoleDev } from "utils/console-dev"
import { useShowAviso } from "services/app/hooks/show-aviso"
import { CircularLoading } from "views/components"
import { EnumMovModelo } from "model"
import { useEventTools } from "services/app/hooks/events/event-tools"
import { AppEventEnum } from "model/enums/enum-app-event"

const MovAtendimentoDeliveryPage = () => {
    const { showAviso } = useShowAviso()
    const { getMov, alterarTpMod } = useMovAtual()
    const { getConfigByCod } = useContratoAtual()
    const { redirectLancadorPadrao, avancarFluxoMov } = useMovRota();
    const { callEvent } = useEventTools();
    
    const handleDelivery = useCallback(async () => {
        const mov = getMov();

        if (mov?.mod !== EnumMovModelo.DELIVERY) {
            const produtoEntrega = getConfigByCod(EnumContratoConfig.ProdutoEntrega)
            if (typeof mov?.infAdicional !== "undefined" && typeof mov?.infAdicional !== "string") {
                showAviso('info', 'Para iniciar o modo delivery, você precisará cancelar esta venda e iniciar uma nova, pois a sua venda em aberto está desatualizada.', 5000, true)
                return
            }

            if (isEmpty(produtoEntrega)) {
                showAviso('info', 'Você precisa selecionar um produto do tipo entrega ou criar um novo.', 5000, true)
                try {
                    await redirectLancadorPadrao();
                } catch (e: any) {
                    consoleDev('handleDelivery', e.message)
                }
                return
            }
            await alterarTpMod(EnumMovModelo.DELIVERY);
        }
        await avancarFluxoMov();
        callEvent(AppEventEnum.MenuPDVFavorito, true);
    }, [alterarTpMod, avancarFluxoMov, callEvent, getConfigByCod, getMov, redirectLancadorPadrao, showAviso])

    useEffect(() => {
        handleDelivery()
    }, [handleDelivery])

    return <CircularLoading tipo="FULLSIZED" />
}

export default MovAtendimentoDeliveryPage