import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  cardContainer: {
    margin: theme.spacing(0, 1, 1, 0),
    minWidth: '80px',
    height: '80px',
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "transparent",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    minHeight: '80px',
    position: "relative",
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: '32px',
      height: '32px',
    },
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
  },
  celulaGrid: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    "& p": {
      textAlign: "center",
      lineHeight: 1,
    }
  },
  textPayment: {
    fontWeight: 600,
    fontSize: 12,
    maxWidth: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': '2',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',

  },
  textPaymentMain: {
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  textPaymentDisabled: {
    color: theme.palette.action.disabledBackground
  },
  iconCadeado: {
    position: 'absolute',
    right: 0,
    top: 2,
    '& svg': {
      padding: 0,
      margin: 0
    }
  },
}));

