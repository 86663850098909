import { Grid, Typography, Button } from "views/design-system";

import { SucessoIcon } from "views/components/icons";
import { SuccessfullyProps } from "./successfully-changed-default-props";
import { useStyles } from "./successfully-changed-styles";


const SuccessfullyChanged = ({text, onClick }: SuccessfullyProps) => {
    const classes = useStyles();
    return (
        <Grid className={classes.root}>
          <Grid className={classes.iconArea}>
            <SucessoIcon tipo="GERAL" style={{ width: "130px" }} />
          </Grid>

          <Typography
            variant="body1"
            color="secondary"
            className={classes.message}
          >
            {text}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onClick}
          >
            Continuar
          </Button>
        </Grid>
    )
};

export { SuccessfullyChanged };