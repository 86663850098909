
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetConvenioByPessoa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConvenioByPessoa = useCallback(
        (pessoaId: string, empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/pessoa/${pessoaId}/conta-pessoa`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getConvenioByPessoa,
    };
}
