import { Box, Button } from '@material-ui/core';
import { useStyles } from './promocao-pesquisa-styles';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { useCallback, useState } from 'react';
import { RadioList } from 'views/components/controles/radio-list/radio-list';
import { DefaultModal, ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import classNames from 'classnames';
import { BorrachaIcon } from 'views/components/icons/borracha-icon';
import { CardFiltro } from 'views/components/cards/components/card-filtro/card-filtro';
import { useHistory } from 'react-router-dom';
import { PromocaoListSearchProps } from '../promocao-list/promocao-list-search-props';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { PromocaoStatusMock } from 'data/mocks/promocao-mock';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';

interface ITelaState {
  status: boolean;
}

interface PromocaoPesquisaModalProps {
  openned: boolean;
  filtros: PromocaoListSearchProps;
}

export const PromocaoPesquisaModal = ({
  openned,
  filtros
}: PromocaoPesquisaModalProps) => {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const { fecharPromocoesFiltroModal } = useFiltrosModais();
  const history = useHistory();

  const [telas, setTelas] = useState<ITelaState>({
    status: false
  });
  const [localProps, setLocalProps] =
    useState<PromocaoListSearchProps>(filtros);

  const voltarBtn = useCallback(() => {
    if (telas.status) {
      setTelas((prev) => ({
        ...prev,
        status: false
      }));
      return;
    }
    fecharPromocoesFiltroModal(false);
  }, [fecharPromocoesFiltroModal, telas.status]);

  const onSubmit = useCallback(
    (localProps: PromocaoListSearchProps) => {
      const searchs: Array<string | null> = [
        localProps.termo.length > 0 ? `termo=${localProps.termo}` : null,
        localProps.ativo !== undefined ? `ativo=${localProps.ativo}` : null
      ];

      let query = '';

      searchs
        .filter((x) => x)
        .forEach((x, i) => (i === 0 ? (query += `?${x}`) : (query += `&${x}`)));

      history.push({
        pathname: '/promocoes',
        search: query
      });
      fecharPromocoesFiltroModal(true);
    },
    [fecharPromocoesFiltroModal, history]
  );

  return (
    <DefaultModal
      minWidth="400px"
      open={openned}
      variant={'temporary'}
      anchor="right"
    >
      <Box className={modalClasses.root}>
        <ModalHeader
          title={'Filtrar'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={voltarBtn}
            />
          }
        />
        <>
          <Box className={classNames(classes.container)}>
            <CardFiltro<PromocaoListSearchProps, ITelaState>
              icon={<PromocaoIcon />}
              nome="Situação"
              propName="ativo"
              setState={setLocalProps}
              setTela={setTelas}
              telaName="status"
              value={
                PromocaoStatusMock.find(
                  (item) => item.Key === localProps.ativo
                )?.Value || ''
              }
              valorPadrao={true}
              valorAtual={localProps.ativo}
            />
            <Box className={classes.buttonContainer}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  onSubmit({
                    termo: '',
                    ativo: 'true'
                  });
                }}
              >
                <BorrachaIcon tipo="BUTTON" />
                Limpar Filtros
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => onSubmit(localProps)}
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Buscar
              </Button>
            </Box>
            {telas.status && (
              <RadioList
                handleSubmit={async (ativo) => {
                  setLocalProps((prev) => ({
                    ...prev,
                    ativo: ativo
                  }));
                  setTelas((prev) => ({
                    ...prev,
                    status: false
                  }));
                }}
                selecionado={localProps.ativo}
                mock={PromocaoStatusMock}
              />
            )}
          </Box>
        </>
      </Box>
    </DefaultModal>
  );
};
