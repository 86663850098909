import classNames from "classnames"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { useStyles } from "./edicao-convenio-styles"
import { useCallback, useEffect, useRef, useState } from "react"
import { DefaultFormRefs } from "views/components/form/utils"
import { GerenciarInfoConvenioFormModel } from "model/app/forms/convenio/gerenciar-info-convenio-form-model"
import { FormGerenciarInfoConvenio } from "views/components/form/convenio/form-gerenciar-info-convenio/form-gerenciar-info-convenio"
import { usePutConvenio } from "data/api/gestao/conta-pessoa/put-conta-pessoa"
import { CircularLoading } from "views/components/utils"
import { ConvenioPutModel } from "model/api/gestao/convenio/convenio-put-model"
import { useEmpresaAtual } from "services/app/hooks/empresa-atual"
import { useCadastros, useToastSaurus } from "services/app"
import { isEqual } from "lodash"
import { EdicaoConvenioProps } from "./edicao-convenio-props"
import CardCartaoConvenio from "views/components/cards/card-cartao-convenio/card-cartao-convenio"
import { InformacaoIcon, NovoIcon, SalvarIcon } from "views/components/icons"
import { CardNaoEncontrado } from "views/components/cards"
import { picker } from "utils/picker"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { Box, Button, Grid } from "views/design-system"

export const EdicaoConvenio = ({ convenio, getConvenioByIdWrapper, ...props }: EdicaoConvenioProps) => {
    const classesModal = useDefaultCadastroStyles()
    const classes = useStyles()
    const { putConvenio, carregando } = usePutConvenio()
    const { getEmpresaAtual } = useEmpresaAtual()
    const { showToast } = useToastSaurus()
    const { cartoes } = convenio
    const { abrirCadastroCartaoConvenio, abrirCadastroConvenio, fecharCadastroConvenio } = useCadastros()
    const [accordionTitular, setAccordionTitular] = useState<boolean>(true)

    const cartaoTitular = cartoes.find(cartao => cartao.pessoaId === convenio.pessoaId)

    const refEditForm = useRef<DefaultFormRefs<GerenciarInfoConvenioFormModel>>(null);

    const preencherForm = useCallback(() => {
        const form: GerenciarInfoConvenioFormModel = {
            ...new GerenciarInfoConvenioFormModel(),
            diaFechamento: convenio.diaFechamento,
            diasParaPagamento: convenio.diasParaPagamento
        }

        refEditForm.current?.fillForm(form)
    }, [convenio.diaFechamento, convenio.diasParaPagamento])

    useEffect(() => {
        preencherForm()
    }, [preencherForm])

    const atualizarConvenio = async (model: ConvenioPutModel) => {
        const res = await putConvenio(model, getEmpresaAtual()?.id ?? '')
        if (res.erro) throw res.erro

        showToast('success', 'Convênio atualizado com sucesso!')
    }

    const handleSubmit = async (model: GerenciarInfoConvenioFormModel, beforeModel: GerenciarInfoConvenioFormModel) => {
        try {
            const equal = isEqual(model, beforeModel)
            if (equal) {
                showToast('info', 'Nenhuma informação alterada.')
                return
            }
            const convenioPut = picker<ConvenioPutModel>(model, convenio)

            await atualizarConvenio(convenioPut)
            await getConvenioByIdWrapper()
        } catch (err: any) {
            showToast('error', 'Ocorreu um erro ao atualizar o convênio. Detalhe: ' + err.message)
        }
    }

    const onClickAdd = () => {
        abrirCadastroCartaoConvenio('', convenio.id)
    }

    const onClickEdit = (id: string) => {
        abrirCadastroCartaoConvenio(id, convenio.id)
    }

    const onClickNovoConvenio = () => {
        fecharCadastroConvenio()
        setTimeout(() => {
            abrirCadastroConvenio({ id: '' }, '', true)
        }, 50)
    }

    const cartoesAdicionais = convenio.cartoes.filter(cartao => cartao.pessoaId !== convenio.pessoaId)

    return (
        <div className={classNames(classesModal.content, classes.content)}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <div className={classes.form}>
                <FormGerenciarInfoConvenio
                    ref={refEditForm}
                    onSubmit={handleSubmit}
                    loading={carregando}
                    showLoading={false}
                />
            </div>
            <div className={classNames(classesModal.contentForms)}>
                {cartaoTitular && (
                    <Box>
                        <AccordionSaurus
                            labelPrimary="Cartão do Titular"
                            expanded={accordionTitular}
                            onChange={() => setAccordionTitular(prev => !prev)}
                            noPaperRoot
                            noPaddingContent
                            showDivider
                        >
                            <Box style={{ width: "100%" }}>
                                <CardCartaoConvenio model={cartaoTitular} onClick={onClickEdit} onCheck={() => { }} selected={false} />
                            </Box>
                        </AccordionSaurus>
                    </Box>
                )}
                <Box mt={2}>
                    <AccordionSaurus
                        labelPrimary="Cartões Adicionais"
                        noPaperRoot
                        noPaddingContent
                        showDivider
                    >
                        <Box style={{ width: "100%" }}>
                            {cartoesAdicionais.length === 0 && !carregando && (
                                <CardNaoEncontrado
                                    iconSize="small"
                                    mensagem="Nenhum Cartão encontrado."
                                    icon={<InformacaoIcon tipo="GERAL" />}
                                />
                            )}
                            {cartoesAdicionais.map(cartao => {
                                return (
                                    <CardCartaoConvenio model={cartao} onClick={onClickEdit} onCheck={() => { }} selected={false} />
                                )
                            })}
                            <Box mt={2}>
                                <Button onClick={onClickAdd} fullWidth variant="outlined" color="primary">
                                    <NovoIcon tipo="BUTTON" />
                                    Novo Cartão
                                </Button>
                            </Box>
                        </Box>
                    </AccordionSaurus>
                </Box>
            </div >
            <div className={classesModal.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            disabled={carregando}
                            onClick={onClickNovoConvenio}
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            <NovoIcon tipo="BUTTON" />
                            Novo
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            disabled={carregando}
                            onClick={() => {
                                refEditForm.current?.submitForm();
                            }}
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            <SalvarIcon tipo="BUTTON_PRIMARY" />
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}