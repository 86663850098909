import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    container: {
        overflowX: 'auto',
        "&::-webkit-scrollbar": {
            display: "block",
            height: 7,
        },
        width: 0,
        flex: 1,
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.24)',
    },
    content: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2),
    },
    btnAnoMes: {

    },
    barra: {
        padding: theme.spacing(0, .5),
    },
    naoExiste: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    loading: {
        width: 50,
        height: 50,
        '& .loader': {
            width: 80,
            height: 80,
        }
    }
}))