import { Button } from 'views/design-system';
import { FormStep } from 'model/app';
import { useCallback, useMemo, useRef } from 'react';
import { useFormStepper } from 'views/components/form-stepper';
import { AvancarIcon, OkIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { PromocaoNomeStep, RefPropPromocaoNomeStep } from '../components/promocao-nome-step/promocao-nome-step';
import { PromocaoDataStep, RefPropPromocaoDataStep } from '../components/promocao-data-step/promocao-data-step';

export const enum EnumPromocaoDadosSteps {
  Nome = 0,
  Periodo = 1
}

interface usePromocaoDadosStepsProps {
  steps: EnumPromocaoDadosSteps[];
  fimStepSalvar: boolean;
  confirmarCadastro: () => void;
  cancelarCadastro: () => void;
}

export const usePromocaoDadosSteps = ({
  confirmarCadastro,
  cancelarCadastro,
  steps,
  fimStepSalvar
}: usePromocaoDadosStepsProps) => {

  const refCadastroNomePromocao = useRef<RefPropPromocaoNomeStep>(null);
  const refCadastroDataPromocao = useRef<RefPropPromocaoDataStep>(null);
  const maxSteps = steps.length;

  const { currentStep, nextStep, prevStep, resetSteps } = useFormStepper(maxSteps);

  const voltarButton = useCallback(
    (step: EnumPromocaoDadosSteps) => {

      if (step === EnumPromocaoDadosSteps.Nome)
        return undefined;

      return (
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          size="large"
          fullWidth
          onClick={async () => {
            prevStep();
          }}
        >
          <VoltarIcon tipo="BUTTON" />
          Voltar
        </Button>
      );
    },
    [prevStep]
  );

  const avancarStep = useCallback(async (step: EnumPromocaoDadosSteps) => {
    try {
      switch (step) {
        case EnumPromocaoDadosSteps.Nome:
          refCadastroNomePromocao.current?.submit();
          break;
        case EnumPromocaoDadosSteps.Periodo:
          await refCadastroDataPromocao.current?.submit();
          break;
      }
    } catch (error: any) { }
  }, []);

  const avancarButton = useCallback(
    (step: EnumPromocaoDadosSteps) => {
      const index = steps.indexOf(step);

      return (
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={() => {
            avancarStep(step);
          }}
        >
          <>
            {step === EnumPromocaoDadosSteps.Periodo && (index === steps.length - 1) && fimStepSalvar ? (
              <>
                <OkIcon tipo="BUTTON_PRIMARY" />
                Concluir
              </>
            ) :
              step === EnumPromocaoDadosSteps.Periodo && (index === steps.length - 1) && !fimStepSalvar ? (
                <>
                  <SalvarIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </>
              ) :
                (
                  <>
                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                    Próximo
                  </>
                )}
          </>
        </Button>
      );
    },
    [avancarStep, fimStepSalvar, steps]
  );

  const getFormArray = useMemo(() => {
    const ret: FormStep[] = [];
    for (const step of steps) {
      switch (step) {
        case EnumPromocaoDadosSteps.Nome:
          ret.push(
            new FormStep(
              'Nome',
              ``,
              <ComandasIcon tipo="GERAL" />,
              'Nome',
              <ComandasIcon tipo="GERAL" />,
              (
                <PromocaoNomeStep
                  ref={refCadastroNomePromocao}
                  submit={() => {
                    nextStep();
                  }}
                />
              ),
              voltarButton(step),
              avancarButton(step)
            )
          );
          break;
        case EnumPromocaoDadosSteps.Periodo:
          ret.push(
            new FormStep(
              'Periodo',
              ``,
              <PessoaIcon tipo="GERAL" />,
              'Periodo',
              <PessoaIcon tipo="GERAL" />,
              (
                <PromocaoDataStep
                  ref={refCadastroDataPromocao}
                  submit={() => {
                    confirmarCadastro();
                  }}
                />
              ),
              steps.indexOf(step) > 0 ? voltarButton(step) : undefined,
              avancarButton(step)
            )
          );
          break;
      }
    }

    return ret;
  }, [steps, voltarButton, avancarButton, nextStep, confirmarCadastro]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep,
      resetSteps
    },
    formArray: getFormArray,
    carregando: false
  };
};
