import { Grid } from 'views/design-system';
import { useCallback, useEffect } from 'react';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { NovoPagamentoList } from './components/novo-pagamento-list/novo-pagamento-list';
import { useToastSaurus } from 'services/app';
import { CarrinhoTotais } from '../mov-carrinho/components/cart-wrapper/components/carrinho-totais/carrinho-totais';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

export const MovPagamentoNovoPage = () => {
  const classes = useDefaultCadastroStyles();
  const { showToast } = useToastSaurus();
  const { avancarFluxoMov, redirectCarrinho } = useMovRota();

  const validarEntradaTela = useCallback(async () => {
    try {
      // await validarCondicaoPagamentoAvancado();
    } catch (e: any) {
      try {
        await avancarFluxoMov();
      } catch (err: any) {
      }
      showToast('error', e.message);
    }
  }, [avancarFluxoMov, showToast]);

  useEffect(() => {
    (async () => {
      await validarEntradaTela();
    })();
  }, [validarEntradaTela]);

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Forma de Pagamento',
      showDefinicoes: true,
      voltar: {
        async onClick() {
          try {
            await redirectCarrinho();
          } catch (e: any) {
            showToast('error', e.message);
          }
        },
      }
    })
  }, [redirectCarrinho, setHeaderProps, showToast])

  return (
    <Grid className={classes.root}>
      <CarrinhoTotais telaDePagamento={true} />
      <Grid className={classes.list} flex flexDirection='column'>
        <NovoPagamentoList />
      </Grid>
    </Grid>
  );
};
