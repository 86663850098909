import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        background: '#E4F5FF',
        padding: 12,
        display: 'block'
    },
    title: {
        alignItems: "center",
        display: 'flex',
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    subtitle: {
        color: theme.palette.secondary.main,
        padding: '8px 0'
    }
}));
