import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
        flex: '1 1 100%',
        flexDirection: 'column',
        display: 'flex',
        overflowY: 'auto',
    },
    stepContent: {
        // marginLeft: theme.spacing(2),
        // marginRight: theme.spacing(2),
    }
}));
