import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        flexGrow: 1
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        background: '#FFF'
    },
    title: {
        fontSize: '22px',
        lineHeight: '30px'
    },
    inputArea: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 260,
        maxWidth: 395,
        borderBottom: '2px solid#D8D8D8',
    },
    inputText: {
        fontSize: '38px',
        lineHeight: '50px',
        fontWeight: 600
    },
    placeholder: {
        fontSize: '16px',
        lineHeight: '50px',
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    buscaCadastrados: {
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '42px',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    contentForms: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: '1',
        position: 'relative'
    },
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(1),
        background: '#f5f5f5',
    },
    step: {
        height: '100%',
        display: 'block'
    },
    stepForm: {
        height: '100%',
        alignItems: 'initial'
    },
    stepper: {
        padding: 0,
        paddingTop: theme.spacing(1),
        height: 80,
        '& .stepperSaurus': {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            }
        },
        '& .stepperConnectorAlternativeSaurus': {
            top: 15,
        },
        '& .stepLabelSaurus': {
            '& div': {
                width: 32,
                height: 32
            },
            '& svg': {
                width: 16,
                height: 16
            }
        },
        '& .stepLabelContainerSaurus': {
            "& span": {
                fontSize: 11,
                marginTop: theme.spacing(1),
            }
        }
    },
    convenio: {
        padding: theme.spacing(1, 0),
        borderBottom: `2px solid`,
        borderBottomColor: theme.palette.primary.main,
        '& > p': {
            fontWeight: 500,
        }
    },
    palavraDestaque: {
        fontWeight: 700,
        color: theme.palette.primary.main,
    }
}));