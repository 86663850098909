import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    titulo: {
        "& svg": {
            marginBottom:-6,
            width: 40,
            height: 40,
            marginRight: 8,
            fill: theme.palette.primary.main
        }
    },

}));