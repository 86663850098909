import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flex: 1,
    },
    headerPartUm: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        padding: theme.spacing(1),
        background: theme.palette.grey[50],
        justifyContent: 'space-evenly',
        paddingRight: 16,
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            paddingRight: 48,
        }
    },
    headerTelaPagamento: {
        [theme.breakpoints.down('xs')]: {
            paddingRight: 16,
        }
    },
    headerPartDois: {
        flex: 1,
        display: 'flex',
        maxWidth: '30%',
        minWidth: 100,
        background: theme.palette.common.white,
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    headerItemContainerEditavel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerItemContainer: {
        flexWrap: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        padding: '4px 16px',
    },
    badgeQtde: {
        position: 'absolute',
        left: -2,
        top: -6,
        "& p": {
            fontSize: 10,
        }
    },
    headerItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        "& svg": {
            [theme.breakpoints.down('xs')]: {
                width: 16,
                height: 16,
            },
            width: 18,
            height: 18,
            fill: theme.palette.text.primary
        }
    },
    labelText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 10,
        },
        fontSize: 12,
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
    },
    labelValor: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
        fontSize: 18,
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    labelValorDestaque: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    containerBotaoExpandir: {
        position: 'absolute',
        right: 8,
    },
    botaoRedondo: {
        background: theme.palette.grey[200],
        width: 32,
        height: 32,
        margin: 0,
        borderRadius: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        "& svg": {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: 0,
            padding: 0,
            fill: theme.palette.text.primary
        }
    },
    containerIconAcao: {
        background: theme.palette.grey[200],
        width: 32,
        marginLeft: -8,
        marginRight: 8,
        height: 32,
        margin: 0,
        borderRadius: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        "& svg": {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: 0,
            padding: 0,
            fill: theme.palette.text.primary
        }
    },
    informacoes: {
        order: 1,
        [theme.breakpoints.down('xs')]: {
            order: 3,
        }
    },
    total: {
        order: 2,
        [theme.breakpoints.down('xs')]: {
            order: 1,
        }
    },
    desconto: {
        order: 3,
        [theme.breakpoints.down('xs')]: {
            order: 2,
        }
    },
    servico: {
        order: 4,
        [theme.breakpoints.down('xs')]: {
            order: 4,
        }
    }

}));
