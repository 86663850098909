import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.background.default,
        flex: 1,
        overflow: 'hidden'
    },
    relative: {
        position: 'relative',
        height: '100%',
        overflowY: 'auto',
    },
}))