import { EditarIcon } from "views/components/icons";
import { useDefaultCardStyles } from "../components";
import { CardPerfilProps } from "./card-perfil-props";
import { DefaultCard } from "../components/default-card"
import { Typography } from "views/design-system";
import { Tooltip } from "@material-ui/core";

export const CardPerfil = ({ model, onClick, onCheck, selected }: CardPerfilProps) => {

  const defaultCardClasses = useDefaultCardStyles();

  return (
    <>
      <DefaultCard isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}>

        <div className={defaultCardClasses.cardContent}>
          <div className={defaultCardClasses.celulaGridFull}>
            <Typography color="textPrimary" variant="body1">
              <strong>{model.nome}</strong>
            </Typography>
            <Typography color="textPrimary" variant="caption" style={{ marginTop: -5 }}>
              {model.descricao}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar perfil">
          <div className={defaultCardClasses.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

