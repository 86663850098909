import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export enum EnumTpSituacaoApuracao {
    Pendente = 0,
    Sucesso = 2,
    Descinvulado = 1,
}


export function useDeleteComissaoApuracaoDesconsiderarComissionado() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchComissaoApuracaoDesconsiderarComissionado = useCallback(
        (data: {
            empresaId: string;
            comissaoId: string;
            apuracaoId: string;
            situacao: EnumTpSituacaoApuracao;
        }) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${data.empresaId}/comissao/${data.comissaoId}/apuracao/${data.apuracaoId}`,
                method: 'PATCH',
                headers: { "Content-Type": "application/json" },
                data: {
                    situacao: data.situacao
                },
                enviarTokenUsuario: true,
            })
        }, [invocarApi],
    );
    return {
        ...outrasPropriedades,
        patchComissaoApuracaoDesconsiderarComissionado,
    };
}