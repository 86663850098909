import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormGerenciarInfoConvenioValidation = () => {
    const FormGerenciarInfoConvenioValidation = useMemo(() => {
        return (
            Yup.object().shape({
            })
        )
    }, [])
    return {
        FormGerenciarInfoConvenioValidation,
    }
}

