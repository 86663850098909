import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VendaProdutoModel } from 'model/api/gestao/venda/venda-produto-model';

export function usePostPromocaoProcessamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postPromocaoProcessamento = useCallback(
    (produtos: VendaProdutoModel[]) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/promocao/processamento`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produtos),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postPromocaoProcessamento,
  };
}
