import { Controller, useForm } from "react-hook-form";
import { makeUtilClasses } from "views/theme";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { UsuarioModel } from "model/api/gestao/usuarios/usuario-model";
import { Box, Grid } from "views/design-system";
interface Props {
    model: UsuarioModel;
}

export const FormCodigoApi = ({
    model,
}: Props) => {

    //AUX
    const utilClasses = makeUtilClasses();

    const {
        control,
        formState: { errors, touchedFields },
    } = useForm<UsuarioModel>({
        criteriaMode: 'all',
        mode: 'onChange',
    });

    return (
        <>
            <Box my={2}>
                <div className={utilClasses.formContainer}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <Controller
                                name="userKey"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label="Código"
                                        tipo="TEXTO"
                                        mostrarCopiar
                                        fullWidth
                                        readOnly
                                        error={Boolean(
                                            errors.userKey && errors.userKey.message,
                                        )}
                                        helperText={
                                            touchedFields.userKey || errors.userKey
                                                ? errors.userKey?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={model.userKey}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </>
    )
}