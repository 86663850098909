import { useCallback, useEffect, useRef } from "react";
import { useDevice } from "./device"
import { EnumTemNFC } from "model/enums/enum-tem-nfc";
import { useEventTools } from "./events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";

export const useVerificarDispositivo = () => {
    const temNFC = useRef<boolean>(false)
    const temUSB = useRef<boolean>(false)

    const { dispositivoTemNFC, dispositivoTemUSB } = useDevice();
    const { addHandler, callEvent, removeHandler } = useEventTools();

    const dispararVerificacao = useCallback(async () => {
        if ((VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS)) {
            try {
                const res = await dispositivoTemNFC();
                if (res === EnumTemNFC.TEM_NFC) {
                    temNFC.current = true
                }
            } catch (e: any) {
                temNFC.current = false
            }

            try {
                const res = await dispositivoTemUSB();
                if (res) {
                    temUSB.current = true
                }
            } catch (e: any) {
                temUSB.current = false
            }
        }
    }, [dispositivoTemNFC, dispositivoTemUSB])

    const enviarEstadoNFC = useCallback(() => {
        callEvent(AppEventEnum.EnviarNFC, {
            temNFC: temNFC.current
        })
    }, [callEvent])

    const enviarEstadoUSB = useCallback(() => {
        callEvent(AppEventEnum.EnviarUSB, {
            temUSB: temUSB.current
        })
    }, [callEvent])

    useEffect(() => {
        addHandler(AppEventEnum.VerificarNFC, enviarEstadoNFC)
        addHandler(AppEventEnum.VerificarUSB, enviarEstadoUSB)
        return () => {
            removeHandler(AppEventEnum.VerificarNFC, enviarEstadoNFC)
            removeHandler(AppEventEnum.VerificarUSB, enviarEstadoUSB)
        }
    }, [addHandler, callEvent, enviarEstadoNFC, enviarEstadoUSB, removeHandler])

    return {
        dispararVerificacao
    }
}