import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormMesaValidation = (openMesa: boolean, openComanda: boolean) => {
  const FormMesaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
             
        mesaFinal: openMesa  ?  Yup.string().required('Numero de mesas é obrigatório').typeError('Numero de mesas é obrigatório') : Yup.string().notRequired().nullable(),
        comandaFinal: openComanda ? Yup.string().required('Numero de comandas é obrigatório').typeError('Numero de comandas é obrigatório'): Yup.string().notRequired().nullable(),
      })
    )
  }, [openComanda, openMesa]);

  return {
    FormMesaValidationYup,
  }
}