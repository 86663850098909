import { EnumModeloAutenticacao } from 'model/enums/enum-modelo-autenticacao';
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormGerenciarCartaoConvenioValidation = (modeloAutenticacao: EnumModeloAutenticacao) => {
    const FormGerenciarCartaoConvenioValidation = useMemo(() => {
        return (
            Yup.object().shape({
                nomeCartao: Yup.string().required('Selecione uma pessoa'),
                numeroCartao: Yup.string().when((campo, schema) => {
                    if (modeloAutenticacao !== EnumModeloAutenticacao.DocumentoCliente) {
                        return schema.required('Número do Cartão é obrigatório')
                    }
                    return schema
                }),
                limite: Yup.string().notRequired(),
                senha: Yup.string().when((campo, schema) => {
                    if (modeloAutenticacao === EnumModeloAutenticacao.NumeroCartaoSenha) {
                        return schema.required('A senha é obrigatória')
                    }
                    return schema
                }),
                dataValidade:
                    Yup
                        .string()
                        .required('Data de Validade é obrigatório')
                        .test({
                            message: 'A data deve ser maior que o dia atual',
                            name: 'data-validade',
                            test: (value) => {
                                const valorData = new Date(value ?? '')
                                const dataAtual = new Date()
                                return valorData.getTime() > dataAtual.getTime()
                            }
                        }),
            })
        )
    }, [modeloAutenticacao])
    return {
        FormGerenciarCartaoConvenioValidation,
    }
}

