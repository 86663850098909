import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  containerPrecos: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    boxShadow: '0px -1px 8px 0px rgba(96, 97, 112, 0.16)',
  },
  content: {
    flex: 1,
    height: 0,
    overflowY: 'auto'
  },
  listAdjust: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    height: "100%",
    overflowY: 'auto'
  }
}));
