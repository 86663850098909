import { useCallback, useMemo } from 'react';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils';
import { MobileStepper } from '@material-ui/core';
import { EnumStepCampanha, useCampanhaSteps } from './hooks/use-campanha-steps';
import classNames from 'classnames';
import { Box, Grid } from 'views/design-system';
import { useStyles } from '../comissao-steps-styles';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useComissaoStore } from '../../stores/comissao-store';
import { useCadastros, useToastSaurus } from 'services/app';
import { usePostComissao } from 'data/api/gestao/comissao/post-comissao';
import { usePutComissao } from 'data/api/gestao/comissao/put-comissao';
import { CampanhaComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { useHistory } from 'react-router-dom';
import { EnumAbaEdicaoComissao } from '../../comissao-edicao/comissao-edicao';

interface ComisssaoCampanhaProps {
  id?: string;
  cadastroNovaComissao?: boolean;
  selecionarAba?: (aba: EnumAbaEdicaoComissao) => void;
}

export const ComissaoCampanha = ({
  id,
  cadastroNovaComissao,
  selecionarAba
}: ComisssaoCampanhaProps) => {
  // HOOKS
  const classes = useStyles();
  const stepperClasses = useStepperStyles();
  const modalClasses = useModalStyles();
  const { fecharCadastroComissao, abrirCadastroComissao } = useCadastros();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { resetStoreComissao } = useComissaoStore((store) => store);
  const { postComissao, carregando: carregandoPostComissao } =
    usePostComissao();
  const { putComissao, carregando: carregandoPutComissao } = usePutComissao();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const pathName = history.location.pathname;

  const steps = cadastroNovaComissao
    ? [EnumStepCampanha.Nome, EnumStepCampanha.Periodo]
    : [EnumStepCampanha.Periodo];

  const cancelarCadastro = useCallback(async () => {
    resetStoreComissao();
    fecharCadastroComissao();
    return true;
  }, [fecharCadastroComissao, resetStoreComissao]);

  const confirmarCadastro = useCallback(async () => {
    try {
      const comissao = useComissaoStore.getState().state.comissao;
      if (cadastroNovaComissao) {
        const res = await postComissao(getEmpresaAtual()?.id ?? '', comissao);
        if (res.erro) throw res.erro;

        const resultado = res.resultado?.data as CampanhaComissaoModel;
        resetStoreComissao();

        abrirCadastroComissao(resultado?.id ?? '', pathName, true, true);
      } else {
        selecionarAba && selecionarAba(EnumAbaEdicaoComissao.Produtos);
        const res = await putComissao(getEmpresaAtual()?.id ?? '', comissao);
        if (res.erro) throw res.erro;
      }

      showToast(
        'success',
        `Comissão ${
          cadastroNovaComissao ? 'adicionado' : 'editado'
        } com sucesso!`
      );
      return true;
    } catch (e: Error | any) {
      showToast('error', e.message);
      return false;
    }
  }, [
    abrirCadastroComissao,
    cadastroNovaComissao,
    getEmpresaAtual,
    pathName,
    postComissao,
    putComissao,
    resetStoreComissao,
    selecionarAba,
    showToast
  ]);

  const {
    carregando: carregandoStepper,
    formArray,
    formStepper
  } = useCampanhaSteps({
    confirmarCadastro,
    cancelarCadastro,
    id,
    steps,
    cadastroNovaComissao
  });

  const carregando =
    carregandoStepper || carregandoPutComissao || carregandoPostComissao;

  const getButtons = useMemo(() => {
    return (
      <div className={stepperClasses.stepButtons}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box>
          <Grid spacing={1} container>
            {formArray[formStepper.currentStep]?.previousButton && (
              <Grid item xs={6} sm={6}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}
            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={
                  !formArray[formStepper.currentStep]?.previousButton ? 12 : 6
                }
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [
    stepperClasses.stepButtons,
    carregando,
    formArray,
    formStepper.currentStep
  ]);

  const renderStepsCampanha = () => {
    return (
      <div className={classes.content}>
        <div className={classNames(classes.contentForms)}>
          <Box className={classNames(classes.containerStepsForm, classes.box)}>
            <div className={classNames(stepperClasses.stepFix)}>
              <div
                className={classNames(
                  stepperClasses.step,
                  stepperClasses.stepContent,
                  classes.step
                )}
              >
                <div
                  className={classNames(
                    stepperClasses.stepForm,
                    classes.stepForm
                  )}
                >
                  {formArray[formStepper.currentStep].form}
                </div>
              </div>
            </div>
          </Box>
        </div>
        <div className={classes.acoes}>
          {steps?.length > 1 && (
            <div className={(stepperClasses.stepper, classes.stepper)}>
              <MobileStepper
                classes={{
                  progress: classes.mobileBarProgress,
                  root: classes.customStepContainer
                }}
                variant="progress"
                steps={4}
                position="static"
                activeStep={formStepper.currentStep + 1}
                nextButton={() => {
                  <></>;
                }}
                backButton={() => {
                  <></>;
                }}
              />
            </div>
          )}
          {getButtons}
        </div>
      </div>
    );
  };

  return (
    <Box className={modalClasses.root}>
      <Box className={classes.container}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        {!carregando && renderStepsCampanha()}
      </Box>
    </Box>
  );
};
