import { DefaultModal } from "views/components/modals/components/default-modal";
import { ProdutoSelecionarModalProps } from "./produto-selecionar-modal-props";
import { ProdutoSelecionarBusca } from "./components/produto-selecionar-busca";

export const ProdutoSelecionarModal = (props: ProdutoSelecionarModalProps) => {

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && (
                <ProdutoSelecionarBusca {...props} />
            )}
        </DefaultModal >
    );
};
