import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const FecharIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
        onClick={props.onClick}
      >
        <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
      </DefaultIcon>
    </>
  );
};
