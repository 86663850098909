import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogBarCodeCam } from "views/components/dialog/dialog-barcode-cam/dialog-barcode-cam";

export const CadastroBarCodeCamDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
        salao: ''
    });

    const modalAlterado = useCallback(({ openned, salao }: any) => {
        setModalState({
            aberto: openned,
            salao: salao
        });
    }, [])

    consoleDev('CadastroBarCodeCamDialog')

    useEffect(() => {
        addHandler(AppEventEnum.DialogBarCodeCam, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogBarCodeCam, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DialogBarCodeCam zIndex="1000000" openned={modalState.aberto} salao={modalState.salao} key="dialogBarCodeCam" />
    )
}