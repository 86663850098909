import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    body: {
        fontWeight: 600
    },
    title: {
        fontWeight: 500,
        color: 'rgba(128, 128, 128, 1)'
    },
    container: {
        padding: theme.spacing(2, 1),
        width: '100%',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.24)',
    },
    btnEditar: {
        '& svg': {
            margin: 0,
            height: 20,
            width: 20,
        },
        height: 44,
        width: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0
    },
    containerBtnEditar: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))