import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPromocao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPromocao = useCallback(
    (queryBusca: string, page?: number) => {
      let queryPage = "";
      if (page) {
        queryPage = "page=" + page.toString();
      }
      queryPage = queryPage + "&pageSize=10";

      let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
        }${queryPage}${queryPage.length > 0 ? "" : ""}${queryBusca}`;

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/promocao${queryFinal}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPromocao,
  };
}
