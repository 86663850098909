import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { makeUtilClasses, useThemeQueries } from 'views';

import {
    DefaultFormProps,
    DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { PessoaContatosModel } from 'model/api/gestao/pessoa';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { isEmpty } from 'lodash';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { useFormPessoaContatoValidation } from './form-pessoa-contat-validation';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormPessoaContatoRefs extends DefaultFormRefs<PessoaContatosModel> {
    getFields: () => Promise<PessoaContatosModel | false>;
}

export const FormPessoaContato = forwardRef<
    FormPessoaContatoRefs,
    DefaultFormProps<PessoaContatosModel>
>(
    (
        props: DefaultFormProps<PessoaContatosModel>,
        ref
    ) => {
        const utilClasses = makeUtilClasses();
        const { FormPessoaContatoValidationYup } = useFormPessoaContatoValidation();

        const { isMobile } = useThemeQueries();

        //REFS
        const refInputCnpjCpf = useRef<HTMLInputElement>(null);

        const {
            handleSubmit,
            control,
            reset,
            getValues,
            trigger,
            formState: { errors }
        } = useForm<PessoaContatosModel>({
            resolver: yupResolver(FormPessoaContatoValidationYup),
            defaultValues: new PessoaContatosModel(),
            criteriaMode: 'all',
            mode: 'onBlur'
        });

        const onSubmit = (values: PessoaContatosModel) => {
            props.onSubmit(values);
        };

        useImperativeHandle(ref, () => ({
            submitForm: async () => {
                await handleSubmit(onSubmit)();
            },
            resetForm: () => {
                reset();
                if (!isMobile) refInputCnpjCpf.current?.focus();
            },
            fillForm: (model: PessoaContatosModel) => {

                reset({ ...model });
                setTimeout(() => {
                    if (!isMobile) refInputCnpjCpf.current?.focus();
                }, 500);
            },
            getFields: async () => {
                await trigger();
                if(!isEmpty(errors)){
                    return false
                }

                return new PessoaContatosModel(
                    getValues('id'),
                    getValues('pessoaId'),
                    getValues('tipo'),
                    getValues('valor'),
                    getValues('responsavel')
                )
            }
        }));

        return (
            <>
                <div className={utilClasses.formContainer}>
                    {props.loading && props.showLoading ? (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo={'NORMAL'} />
                        </div>
                    ) : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={
                            props.loading && props.showLoading ? utilClasses.controlLoading : ''
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="valor"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={getValues('tipo') === EnumTipoPessoaContato.EMAIL ? 'EMAIL' : "TELEFONE"}
                                            disabled={props.loading}
                                            fullWidth
                                            autoComplete={'off'}
                                            label={getValues('tipo') === EnumTipoPessoaContato.EMAIL ? 'E-mail' : "Telefone"}
                                            placeholder=""
                                            error={Boolean(errors.valor && errors.valor.message)}
                                            helperText={
                                                errors.valor
                                                    ? errors.valor?.message
                                                    : undefined
                                            }
                                            {...field}
                                            value={getValues('valor')}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="responsavel"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'TEXTO'}
                                            disabled={props.loading}
                                            fullWidth
                                            label={'Responsável'}
                                            placeholder=""
                                            error={Boolean(errors.responsavel && errors.responsavel.message)}
                                            helperText={
                                                errors.responsavel
                                                    ? errors.responsavel?.message
                                                    : undefined
                                            }
                                            {...field}
                                            value={getValues('responsavel')}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </>
        );
    }
);