import { Button, Typography } from 'views/design-system';
import { useHistory } from 'react-router-dom';
import { EmpresaIcon } from 'views/components/icons';
import { useStyles } from './card-adicionar-empresa-styles';
import { DefaultCard } from 'views/components/cards/components';

export const CardAdicionarEmpresa = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push('/adicionar-empresa');
  };

  return (
    <DefaultCard hasTagStatus={false} className={classes.card}>
      <Typography variant="h6" className={classes.title}>
        Quer gerenciar mais empresas?
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Clique abaixo para adicionar novas empresas em sua conta de um só lugar!
      </Typography>
      <Button onClick={onClick} variant="contained" color="primary" fullWidth>
        <EmpresaIcon tipo="BUTTON_PRIMARY" />
        Adicionar
      </Button>
    </DefaultCard>
  );
};
