import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    alert: {
        display: "flex",
        flexDirection: "column",
        marginTop: "5px",
        padding: "15px",
        borderRadius: "8px",
        width: "340px",
    },
    textAlert: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.text.secondary
    },
    button: {
        height: '3rem',
    }
}));
