import { useStyles } from './form-text-edit-header-styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useThemeQueries } from 'views/theme';
import { Grid, Box, Typography } from 'views/design-system';
import { EditarIcon, FecharIcon, SalvarIcon } from 'views/components/icons';
import { HeaderTextField } from '../header-text-field/header-text-field';

interface FormTextEditHeaderProps {
  onSubmit: (valor: string) => Promise<boolean>;
  valor: string;
  placeholder?: string;
  forceMobile?: boolean;
  maxTexto?: number;
}

export const FormTextEditHeader = ({
  onSubmit,
  valor,
  maxTexto,
  placeholder = 'Digite aqui...',
  forceMobile
}: FormTextEditHeaderProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const inputRef = useRef<HTMLInputElement>(null);
  const [editando, setEditando] = useState(false);

  const submit = useCallback(async () => {
    if (await onSubmit(inputRef.current?.value ?? '')) {
      setEditando(false);
    }
  }, [onSubmit]);

  const cancelar = useCallback(() => {
    if (inputRef.current)
      inputRef.current.value = valor;
    setEditando(false);
  }, [valor]);

  useEffect(() => {
    if (editando && inputRef.current) {
      inputRef.current.value = valor;
      inputRef.current.focus();
    }
  }, [editando, valor]);

  const form = useMemo(() =>
    <Box className={classNames(classes.container, !(forceMobile || isMobile) && classes.containerDesktop)}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          submit();
        }}
      >
        <HeaderTextField
          placeholder={placeholder}
          forceMobile
          maxTexto={maxTexto}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              cancelar();
            }
          }}
          endAdornmentButton={
            <Grid className={classes.containerAcoes}>
              <Grid onClick={() => submit()} >
                <SalvarIcon tipo="GERAL" />
              </Grid>
              <Grid onClick={() => cancelar()} >
                <FecharIcon tipo="GERAL" />
              </Grid>
            </Grid>
          }
          inputRef={inputRef ?? inputRef} />
      </form>
    </Box>
    , [cancelar, classes.container, classes.containerAcoes, classes.containerDesktop, forceMobile, isMobile, maxTexto, placeholder, submit])

  const titulo = useMemo(() =>
    <Grid className={classes.containerTitulo} onClick={() => { setEditando(true); }}>
      <Grid>
        <Typography variant="h4" className={classes.title}>
          {valor}
        </Typography>
      </Grid >
      {valor.length > 0 && (
        <Grid >
          <EditarIcon tipo="GERAL" />
        </Grid>
      )}
    </Grid >
    , [classes.containerTitulo, classes.title, valor]);

  return (
    <>
      {editando ? form : titulo}
    </>
  );
};
