import { CardDepositoProps } from './card-deposito-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { Tooltip, Typography } from 'views/design-system';

export const CardDeposito = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardDepositoProps) => {
  const defaultCardClasses = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <div className={defaultCardClasses.cardContent}>
          <div
            className={defaultCardClasses.celulaGridFull}
          >
            <Typography color="textPrimary" variant="caption">
              Nome do Depósito
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.nome !== null ? model.nome : 'Sem nome'}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Categoria">
          <div className={defaultCardClasses.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
