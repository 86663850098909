import { useGetFinalizadoras } from "data/api/gestao/finalizadora";
import { usePostSincronizarValores } from "data/api/gestao/pontos-venda/post-sincronizar-valores";
import { useGetSessaoAtiva } from "data/api/gestao/sessao/get-sessao-ativa";
import { TouchoneDBPrimary } from "database/touchone-database";
import { FinalizadoraModel } from "model/api/gestao/finalizadora/finalizadora-model";
import { SessaoSincronizar, SessaoValorModel } from "model/api/gestao/sessao/sessao-valor-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor";
import { useCallback } from "react";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useMovRota } from "services/app/hooks/mov-rota";
import { usePDV } from "services/app/hooks/pdv";
import { useSessaoPDV } from "services/app/hooks/sessao-pdv";
import { useSincronizacaoGeral } from "services/app/hooks/sincronizar-dados";

export const useAbrirCaixa = () => {

    // AUX
    const { showToast } = useToastSaurus();

    // HOOKS
    const { setSessao, setValor, converterDados } = useSessaoPDV();
    const { callEvent } = useEventTools();
    const { getPDV } = usePDV();
    const { getEmpresaSelecionada, getPessoa } = useSessaoAtual();
    const { postSincronizarValores } = usePostSincronizarValores()
    const { redirectLanding } = useMovRota();
    const { iniciarSincronizacaoGeral } = useSincronizacaoGeral();

    // CHAMADAS API
    const { getSessaoAtiva, carregando: carregandoSessaoAtiva } =
        useGetSessaoAtiva();
    const { getFinalizadoras, carregando: carregandoGet } = useGetFinalizadoras();

    const carregando = [carregandoSessaoAtiva, carregandoGet].includes(true)

    const switchRedirecionamento = useCallback(async () => {
        try {
            redirectLanding();
        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [redirectLanding, showToast]);

    const getSessaoAtivaOperador = async (redirect: boolean = true) => {
        const existemSessoes = await TouchoneDBPrimary.sessao.toArray();

        if (existemSessoes.length > 0) {
            return false;
        }

        const response = await getSessaoAtiva(
            getEmpresaSelecionada()!.Id,
            getPDV()!.id,
            getPessoa().pessoa?.id ?? ''
        );

        if (response?.resultado?.data?.length > 0) {
            await setSessao(response?.resultado?.data);
            callEvent(AppEventEnum.SessaoPDV, 1);
            if (redirect) {
                switchRedirecionamento();
            }
            return true
        }
        return false
    };

    const sincronizarValores = useCallback(
        async (caixaId: string, valores: SessaoSincronizar[]) => {
            try {
                const respostaPost = await postSincronizarValores(
                    getEmpresaSelecionada()!.Id,
                    caixaId,
                    valores,
                );

                if (respostaPost.erro) {
                    throw respostaPost.erro;
                }

            } catch (e: any) {

            }
        },
        [getEmpresaSelecionada, postSincronizarValores],
    );

    const handleAbrirCaixa = useCallback(async (valor: string, redirect: boolean = true) => {
        try {
            const sessao = await setSessao(undefined);
            if (!sessao) return;

            const res = await TouchoneDBPrimary.finalizadoras.toArray();

            let pagamento = res.find((pag) => pag.tpMod === 1) as FinalizadoraModel;

            if (!pagamento) {
                const query = '&pageSize=50';
                try {
                    const res = await getFinalizadoras(query);
                    if (res.erro) throw res.erro;

                    const pagamentos = res.resultado?.data?.list ?? [];

                    pagamento = pagamentos(
                        (pag: FinalizadoraModel) => pag.tpMod === 1
                    ) as FinalizadoraModel;

                    if (!pagamento) {
                        throw new Error(
                            'O modelo de pagamento Dinheiro não foi encontrado'
                        );
                    }
                } catch (e: any) {
                    throw new Error('O modelo de pagamento Dinheiro não foi encontrado');
                }
            }

            const sessaoValor = await setValor(
                Number(
                    valor.length > 0
                        ? valor.replace(',', '.')
                        : '0'
                ),
                EnumTpLancamentoSessaoValor.ABERTURA,
                {
                    pagamentoId: pagamento.id,
                    descricao: pagamento.descricao,
                    tpMod: pagamento.tpMod,
                    tpTransacao: pagamento.tpTransacao,

                },
                'Abertura de Caixa',
                undefined,

            );

            iniciarSincronizacaoGeral();

            if (sessaoValor) {
                let valores: SessaoValorModel[] = [sessaoValor]
                const valoresConvertidos = converterDados(valores);
                callEvent(AppEventEnum.SessaoPDV, 1);
                await sincronizarValores(sessaoValor.caixaId, valoresConvertidos)
                if (redirect) {
                    switchRedirecionamento()
                }
            }
        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [callEvent, converterDados, getFinalizadoras, iniciarSincronizacaoGeral, setSessao, setValor, showToast, sincronizarValores, switchRedirecionamento]);
    return {
        handleAbrirCaixa,
        getSessaoAtivaOperador,
        switchRedirecionamento,
        carregando,
    }
}