import { TouchoneDBPrimary } from "database/touchone-database"
import { isEmpty } from "lodash";
import { Med } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model"
import { EnumTarja } from "model/enums/enum-tarja";
import { useCallback } from "react";

const tarjasControladas = [
    EnumTarja.TarjaPreta,
    EnumTarja.TarjaVermelha
]

export const useControleMedicamento = () => {
    const retornarInfoMedicamento = useCallback(async (codigoAnvisa: string | null, produtoId: string | null): Promise<Med | null> => {
        try {
            if (!codigoAnvisa) {
                const retProd = await TouchoneDBPrimary.produtos.where('produtoId').equals(produtoId || '').first();
                if (!retProd || !retProd.codigoAnvisa) {
                    throw new Error('Medicamento não encontrado!');
                }
                codigoAnvisa = retProd.codigoAnvisa;
            }

            const medicamento = await TouchoneDBPrimary.medicamentos.where('codigoAnvisa').equals(codigoAnvisa).first();

            if (!isEmpty(medicamento)) {
                return {
                    cProdANVISA: medicamento.codigoAnvisa,
                    medicamento: {
                        ...medicamento
                    },
                    nSeqReceitaMedica: 0,
                    vendaControlada: tarjasControladas.includes(medicamento.tarja),
                    vPMC: medicamento.precoMaximoConsumidor,
                    xMotivoIsencao: null,
                }
            } else {
                return {
                    cProdANVISA: codigoAnvisa,
                    nSeqReceitaMedica: 0,
                    vendaControlada: false,
                    vPMC: 0,
                    xMotivoIsencao: null,
                    medicamento: null,
                }
            }
        } catch {
            return null
        }
    }, [])

    return {
        retornarInfoMedicamento,
    }
}