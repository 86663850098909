import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormInfoComplementaresValidation } from './form-pagamentos-validation';
import { FormInfoComplementaresProps } from './form-pagamentos-props';
import { PagamentosFormModel } from 'model/app/forms/entrada/pagamentos-form-model';
import { Box, Button, Grid } from 'views/design-system';

export const FormPagamentos = forwardRef<
    DefaultFormRefs<PagamentosFormModel>,
    FormInfoComplementaresProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formInfoComplementaresValidationYup } = useFormInfoComplementaresValidation();
    const [model, setModel] = useState<PagamentosFormModel>(new PagamentosFormModel())
    const modelFormRef = useRef<PagamentosFormModel>(new PagamentosFormModel())

    const {
        handleSubmit,
        control,
        formState,
        reset,
    } = useForm<PagamentosFormModel>({
        resolver: yupResolver(formInfoComplementaresValidationYup),
        defaultValues: {
            ...model
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const onSubmit = (values: PagamentosFormModel) => {
        props.onSubmit(values, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: PagamentosFormModel) => {
            reset({ ...model })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));
    return (
        <Box mt={1}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="vProds"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor dos Produtos"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vProds && errors.vProds.message)}
                                        helperText={
                                            touchedFields.vProds || errors.vProds
                                                ? errors.vProds?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vDesc"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="(-) Valor de Desconto"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vDesc && errors.vDesc.message)}
                                        helperText={
                                            touchedFields.vDesc || errors.vDesc
                                                ? errors.vDesc?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vOutros"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Outros Valores"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vOutros && errors.vOutros.message)}
                                        helperText={
                                            touchedFields.vOutros || errors.vOutros
                                                ? errors.vOutros?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vImpostos"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor dos Impostos"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vImpostos && errors.vImpostos.message)}
                                        helperText={
                                            touchedFields.vImpostos || errors.vImpostos
                                                ? errors.vImpostos?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vSeg"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor Seguro"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vSeg && errors.vSeg.message)}
                                        helperText={
                                            touchedFields.vSeg || errors.vSeg
                                                ? errors.vSeg?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vFrete"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor Frete"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vFrete && errors.vFrete.message)}
                                        helperText={
                                            touchedFields.vFrete || errors.vFrete
                                                ? errors.vFrete?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vTotal"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor Total"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vTotal && errors.vTotal.message)}
                                        helperText={
                                            touchedFields.vTotal || errors.vTotal
                                                ? errors.vTotal?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vPago"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor Pago"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vPago && errors.vPago.message)}
                                        helperText={
                                            touchedFields.vPago || errors.vPago
                                                ? errors.vPago?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vTroco"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Troco Total"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vTroco && errors.vTroco.message)}
                                        helperText={
                                            touchedFields.vTroco || errors.vTroco
                                                ? errors.vTroco?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vAReceber"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        readOnly
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor a Receber"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vAReceber && errors.vAReceber.message)}
                                        helperText={
                                            touchedFields.vAReceber || errors.vAReceber
                                                ? errors.vAReceber?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
