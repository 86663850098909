import { guidEmpty } from 'utils/guid-empty';
export class DadosImpressao {
  constructor(
    public id: string = guidEmpty(),
    public equipamentoId: string = '',
    public impressao: string = '',
    public tipoImpressao: string = '',
    public externalCode: string = '',
    public externalId: string = '',
    public quantidadeVias: number = 0
  ) { }
}

export class DadosImpressaoAPI {
  constructor(
    public referencia: string = '',
    public impressao: string = '',
    public base64: boolean = false,
    public externalCode: string = '',
    public externalId: string = '',
    public quantidadeVias: number = 0,
    public equipamentoId: string = '',
  ) { }
}

export class Equipamento {
  constructor(
    public id: string = '',
    public descricao: string = '',
    public caminho: string = '',
    public modelo: string = '',
    public titulo: string = '',
    public codigo: string = '',
    public colunas: number = 0,
    public usuario: string = '',
    public senha: string = '',
    public quantidadeVias: number = 0
  ) { }
}
export class ImpressaoLocalModel {
  constructor(
    public dadosImpressao: DadosImpressao = new DadosImpressao(),
    public equipamento: Equipamento = new Equipamento()
  ) { }
}
