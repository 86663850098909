import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '100%',

  },
  dialogTitle: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: 0,

  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 4,
    margin: 0,
    flex: "none",
    marginRight: theme.spacing(1) * -1,
    width: 48,
    height: 48,
    '& svg': {
      padding: 0,
      margin: 0
    }
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  subTitle: {
    fontWeight: 500
  },
  titleCentralizado: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  dialogContent: {
    height: '100%',
  },
  container: {
    '@media print': {
      display: 'none'
    }
  }
}));
