import { Grid, Typography } from '@material-ui/core';
import useStyles from './card-produto-importacao-xml-styles';

import { CardParcelamentoImportacaoXMLProps } from './card-produto-importacao-xml-props';

import { DefaultCard } from '../components/default-card';
import { toDecimalString } from 'utils/to-decimal';
import { memo } from 'react';

export const CardParcelamentoImportacaoXML = ({
  onClick,
  model,
  parcela,
  selected
}: CardParcelamentoImportacaoXMLProps) => {
  const cardProdutoImporcataoXMLClasses = useStyles();

  const handleOnClick = async () => {
    if (onClick) {
      onClick();
    }
  };

  const valorParcelado = toDecimalString(model.vPag / parcela);

  return (
    <DefaultCard
      className={cardProdutoImporcataoXMLClasses.content}
      isSelected={selected}
      onClick={handleOnClick}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          className={cardProdutoImporcataoXMLClasses.textDisplay}
        >
          <Typography color="textPrimary" variant="body1">
            {parcela} X de
          </Typography>
          <Typography color="textPrimary" variant="h5">
            <strong>R$ {valorParcelado}</strong>
          </Typography>
        </Grid>
      </Grid>
    </DefaultCard>
  );
};

export default memo(CardParcelamentoImportacaoXML, (prev, next) => prev.selected === next.selected)
