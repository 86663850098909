import { useCallback, useMemo, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { TipoValorComissao } from './components/keyboard-fragment-props';
import KeyBoardFragment from './components/keyboard-fragment';

interface KeyboardValorProps {
    value: string;
    index: number;
    setValue: (value: string) => void;
    tipo: TipoValorComissao
}

const KeyboardValor = ({ value, index, setValue, tipo }: KeyboardValorProps) => {
    //  STATES E REFS
    const [valor, setValor] = useState<string>(value ?? '');

    // AUX
    const { showToast } = useToastSaurus();

    const textChanged = useCallback(
        async (text: string, formattedText: string) => {
            try {
                setValor(formattedText);
                setValue(formattedText)
                return true;
            } catch (e: any) {
                showToast('error', 'Erro ao inserir o valor. Detalhe: ' + e.message);
                return false;
            }
        },
        [setValue, showToast],
    );

    const submit = useCallback(async () => {
        return true;
    }, []);

    const fragmento = useMemo(() => {
        return (
            <KeyBoardFragment
                valor={valor}
                index={index}
                submit={submit}
                textChanged={textChanged}
                tipo={tipo}
            />
        );
    }, [valor, index, submit, textChanged, tipo]);

    return (
        <>
            {fragmento}
        </>
    );
};

export default KeyboardValor;
