import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    },
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '& svg': {
        fill: '#000',
        height: 22,
        width: 22
      }
    }
  },
  labelTexto: {
    whiteSpace: 'nowrap',
  },
  valorTexto: {
    background: theme.palette.primary.dark,
    color: `${theme.palette.primary.contrastText} !important`,
    borderRadius: 4,
    padding: '4px 8px',
    fontSize: '13px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 250,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150
    },
    '& svg': {
      fill: theme.palette.secondary.main,
      height: 22
    },
  },
  icon: {
    fill: theme.palette.secondary.main + '!important'
  },
  removeIcon: {
    fill: theme.palette.primary.main + '!important',
    '&:hover': {
      fill: theme.palette.error.main + '!important'
    }
  }
}));
