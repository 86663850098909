import { Tooltip, Typography } from 'views/design-system';
import React, { useCallback, useMemo } from 'react';
import {
  CartaoIcon,
  DinheiroIcon,
  QrCodeIcon,
} from 'views/components/icons';

import { TpModMock } from 'data/mocks/tp-mod-mock';
import { useStyles } from './card-carrinho-pagamento-styles';
import { useThemeQueries } from 'views';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { isPlanoPagIntegrado } from 'utils/plano-utils';
import classNames from 'classnames';
import { CimaIcon } from 'views/components/icons/cima-icon';
import { DefaultCard } from '../components';
import { CardCarrinhoPagamentoProps } from './card-carrinho-pagamento-props';

export const CardCarrinhoPagamento = ({
  model,
  onClick,
  className
}: CardCarrinhoPagamentoProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { plano } = useSessaoAtual()
  const { showToast } = useToastSaurus()

  const isPagIntegrado = isPlanoPagIntegrado(plano?.plano)

  const noModelPagIntegrado = !isPagIntegrado && model.methods[0]?.tpTransacao === EnumPagTpTransacao.S2_PAY

  const tpMod = TpModMock.filter((x) => x.Key === model.methods[0]?.tpMod)[0]?.Value || '';

  const handleClick = useCallback(async () => {
    if (noModelPagIntegrado) {
      showToast('error', 'Seu plano não tem Pagamento Integrado.')
      return
    }
    await onClick(model)
  }, [model, noModelPagIntegrado, onClick, showToast])

  const colorDisableIcon = noModelPagIntegrado ? theme.palette.action.disabledBackground : theme.palette.text.primary

  const cardIcon = useMemo(() => {
    switch (model.type) {
      case EnumPagTpMod.CARTAO_CREDITO:
      case EnumPagTpMod.CARTAO_DEBITO:
        return <CartaoIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.DINHEIRO:
        return <DinheiroIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return <QrCodeIcon tipo="GERAL" fill={colorDisableIcon} />;
      case EnumPagTpMod.OUTRO:
        return <CimaIcon tipo="GERAL" fill={colorDisableIcon} />;
    }
  }, [model.type, colorDisableIcon]);

  return (
    <DefaultCard hasTagStatus={false} onClick={handleClick} className={classNames(classes.cardContainer, className)}>
      <div className={classes.cardContent}>
        <div className={classes.iconGrid}>
          <Tooltip arrow title={tpMod}>
            <div className={classes.cardIcon}>{cardIcon}</div>
          </Tooltip>
        </div>
        <div className={classes.celulaGrid}>
          <Typography
            className={
              classNames(
                classes.textPayment,
                noModelPagIntegrado
                  ? classes.textPaymentDisabled
                  : classes.textPaymentMain
              )
            }
            variant="caption"
          >
            {model.name}
          </Typography>
        </div>
      </div>
    </DefaultCard>
  );
};

export default React.memo(CardCarrinhoPagamento);
