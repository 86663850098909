import { Box, Grid, Paper, Typography, IconButton, Button } from "@material-ui/core"
import { useState, useCallback, useMemo } from "react"
import { toDecimalString } from "utils/to-decimal"
import { ExportarExcelIcon } from "views/components/icons/export-excel-icon"
import { Paginacao } from "views/components/paginacao"
import { useThemeQueries } from "views/theme"
import { useComissaoApuracaoService } from "../../useComissaoApuracaoService"
import { ApuracaoComissaoVendaCard } from "../cards/apuracao-venda-card"
import { useStyles } from "./apuracao-list-styles"
import { EnumTpSituacaoApuracao } from "data/api/gestao/comissao/patch-campanha-apuracao-descondierar-comissionado"
import { EnumTpModeloApuracao } from "model/enums/enum-tp-modelo-apuracao"
import { EnumTpComissionado } from "model/enums/enum-tp-comissionado"
import { useComissaoStore } from "views/components/modals/comissao/comissao-modal/stores/comissao-store"
import { useApuracaoStore } from "../../stores/apuracao-store"

const modeloApuracaoToLabel = (modelo: EnumTpModeloApuracao) => {
    switch (modelo) {
        case EnumTpModeloApuracao.Lucro:
            return 'Lucro'
        case EnumTpModeloApuracao.ValorBruto:
            return 'Venda Bruta'
        case EnumTpModeloApuracao.ValorVendido:
            return 'Valor Vendido'
    }
}

const tpComissionadoToLabel = (tipo: EnumTpComissionado) => {
    switch (tipo) {
        case EnumTpComissionado.Gerente:
            return 'Gerente'
        case EnumTpComissionado.GerenteGeral:
            return 'Gerente Geral'
        case EnumTpComissionado.Vendedor:
            return 'Vendedor'
    }
}


export const ApuracaoVendaComissao = () => {
    const [paginationData, setPaginationData] = useState({
        page: 1,
        totalPages: 1,
        totalResults: 0
    })

    const classes = useStyles()

    const selectedComissionado = useApuracaoStore(state => state.stateComissaoApuracao.selectedComissionado)
    const comissao = useComissaoStore(state => state.state.comissao)
    const comissaoApuracaoAcaoApurar = useApuracaoStore(state => state.stateComissaoApuracao.ui.acaoApurarComissionado)


    const {
        setComissaoApuracaoApurarComissionado,
        resetBlackListVendas,
    } = useApuracaoStore()

    const { toogleBlackListVendas, sendBlackListVendas, exportCampanhaApuracaoVendasExcel, updateSituacaoApuracao } = useComissaoApuracaoService()

    const pageChanged = useCallback(async (newPage: number) => {
        if (!selectedComissionado) {
            return
        }
        if (newPage <= selectedComissionado.vendas.length / 10 || newPage > 0) {
            setPaginationData(prev => {
                return {
                    ...prev,
                    page: newPage
                }
            })
        }
    }, [selectedComissionado])

    const heightScroll = useMemo(() => {
        return window.innerHeight - 290 - (
            (selectedComissionado?.situacao === EnumTpSituacaoApuracao.Pendente ? 60 : 0)
        )
    }, [selectedComissionado?.situacao])

    const { theme } = useThemeQueries()

    return <>
        {
            selectedComissionado && <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.fixedHeightSmall}>
                        <Paper>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="caption">
                                        Competência
                                    </Typography>
                                    <Typography variant="body1">
                                        {
                                            selectedComissionado.dtComp
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">
                                        Modelo Apuração
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            modeloApuracaoToLabel(comissao.modeloApuracao)
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" variant="caption">
                                        Tipo comissionado
                                    </Typography>
                                    <Typography color="primary" variant="body1">
                                        {
                                            tpComissionadoToLabel(selectedComissionado.tipo)
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} className={classes.fixedHeightSmall}>
                        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                            {
                                selectedComissionado.situacao === EnumTpSituacaoApuracao.Pendente && (
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                if (comissaoApuracaoAcaoApurar === 'adicionando') {
                                                    setComissaoApuracaoApurarComissionado('deletando')
                                                } else {
                                                    setComissaoApuracaoApurarComissionado('adicionando')
                                                    resetBlackListVendas()
                                                }
                                            }}
                                        >
                                            {
                                                comissaoApuracaoAcaoApurar === 'adicionando' ? (
                                                    'Selecionar Produtos'
                                                ) : (
                                                    'Desfazer'
                                                )
                                            }
                                        </Button>
                                    </Grid>
                                )
                            }

                            <Grid item xs={
                                selectedComissionado.situacao === EnumTpSituacaoApuracao.Pendente ? undefined : 12
                            }>
                                <Paginacao
                                    pageChanged={pageChanged}
                                    totalPages={
                                        Math.ceil(selectedComissionado.vendas.length / 10)
                                    }
                                    currentPage={paginationData.page}
                                    totalRegisters={selectedComissionado.vendas.length}
                                    action={<>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                exportCampanhaApuracaoVendasExcel()
                                            }}
                                        >
                                            <ExportarExcelIcon tipo="BUTTON_PRIMARY" />
                                        </IconButton>
                                    </>}

                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} style={{
                        height: heightScroll,
                        overflowY: 'auto',
                    }}>
                        <Grid container spacing={1}>
                            {
                                selectedComissionado.vendas
                                    .slice(
                                        (paginationData.page - 1) * 10,
                                        paginationData.page * 10
                                    )
                                    .map((venda) => {
                                        return <Grid item xs={12} key={venda.id}>
                                            <ApuracaoComissaoVendaCard venda={venda} onClick={() => {
                                                if (comissaoApuracaoAcaoApurar === 'deletando') {
                                                    toogleBlackListVendas(venda.id)
                                                }
                                            }} />
                                        </Grid>
                                    })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        height: '70px',
                    }}>
                        <Paper style={{
                            height: '100%'
                        }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="caption">
                                        Base de Calculo
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            `R$${toDecimalString(selectedComissionado.baseCalculo)}`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">
                                        Apuração
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            selectedComissionado.valorCondicao
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" variant="caption">
                                        Valor Comissão
                                    </Typography>
                                    <Typography color="primary" variant="body1">
                                        {
                                            `R$${toDecimalString(selectedComissionado.valorComissao)}`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>



                    </Grid>
                    {
                        selectedComissionado.situacao === EnumTpSituacaoApuracao.Pendente && (
                            <Grid item xs={12} style={{
                                height: '40px',
                            }}>

                                <Button style={{
                                    color: comissaoApuracaoAcaoApurar === 'deletando' ? theme.palette.error.contrastText : theme.palette.primary.contrastText,
                                    background: comissaoApuracaoAcaoApurar === 'deletando' ? theme.palette.error.main : theme.palette.primary.main
                                }} fullWidth variant="contained" onClick={() => {
                                    if (comissaoApuracaoAcaoApurar === 'deletando') {
                                        sendBlackListVendas()
                                        setComissaoApuracaoApurarComissionado('adicionando')
                                    } else {
                                        updateSituacaoApuracao({
                                            situacao: EnumTpSituacaoApuracao.Sucesso,
                                        })
                                    }
                                }}>
                                    {
                                        comissaoApuracaoAcaoApurar === 'deletando' ? (
                                            'Deletar Produtos'
                                        ) : (
                                            'Salvar'
                                        )
                                    }
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </Box>
        }
    </>
}