import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardinativo: {
        opacity: 0.6,
        padding: theme.spacing(1),
    },
    card: {
        padding: theme.spacing(1),
    },
    containerInternoCard: {
        position: 'relative',
    },
    contentInformacoes: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
    },
    btnInativar: {
        marginRight: -8,
        marginTop: -8,
        height: '32px',
        width: '32px'
    },
    btnRetornar: {
        marginRight: -8,
        marginTop: -8,
        height: '32px',
        width: '32px'
    },
    containerInfo: {
        display: 'flex',
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    tags: {
        flexWrap: 'wrap',
        gap: 4,
        marginTop: 4,
        marginRight: theme.spacing(4),
    },
    tagProdutos: {
        cursor:'default',
        background: theme.palette.text.hint,
        padding: '2px 8px',
        borderRadius: '20px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& span": {
            fontSize: 10,
        },
        "& svg": {
            width: 14,
            marginRight: 4,
            height: 14,
            fill: theme.palette.text.primary,
        }
    },
    tagCodigos: {
        background: "#def3bd",
        color: theme.palette.text.primary
    },
    tagTarja: {
        background: "#f9e8d2",
        color: theme.palette.text.primary,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagTipoMedicamento: {
        background: "#f7e0f3",
        color: theme.palette.text.primary
    },
    tagComposicao: {
        background: "#e2dbf7",
        color: theme.palette.text.primary,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagLaboratorio: {
        background: "#efefef",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagLaboratorioDestaque: {
        background: "#fdf6d0",
        "& span": {
            fontWeight: 600,
        }
    },
    tagClasse: {
        background: "#e5f3ee",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagDesconto: {
        background: "#e0ebff",
        color: theme.palette.text.primary
    },
    textDescricao: {
        "& h6": {
            fontWeight: 600,
            paddingRight: theme.spacing(3),
            color: theme.palette.primary.main,
        }
    },
    textCategoria: {
        marginTop: theme.spacing(1) * -1,
        "& span": {
            color: theme.palette.grey[700],
        }
    },
    containerValores: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: -5,
    },
    textVProd: {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: 'normal',
        marginTop: -4,
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    textVUnitario: {
        fontWeight: 'bold',
        marginTop: -4,
    },
    textVUnitarioDe: {
        textDecoration: 'line-through',
    },
    textVUnitarioPequeno: {
        fontSize: 14,
        marginTop: -4,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        }
    },
    textVTotal: {
        fontSize: '10px',
        fontWeight: 400
    },
    textVProdDescAcres: {
        fontSize: '12px',
        paddingTop: '2px'
    },
    divider: {
        backgroundColor: theme.palette.grey[300],
        height: '1px',
        marginTop: '4px',
        marginBottom: '8px'
    },
    pAdicional: {
        fontWeight: 600,
        fontSize: '16px',
        marginTop: '8px'
    },
    validarTexto: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'justify',
    },
    containerTexto: {
        padding: '4px 0px'
    },
    texto: {
        textDecoration: 'underline',
        display: 'flex'
    },
    containerInativar: {
        position: 'absolute',
        top: 4,
        right: 4
    },
    textCod: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '6px'
    },
    containerValorUnidade: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'fle'
    }
}));
