import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        overflowX: 'hidden',
        background: theme.palette.grey[100],
        zIndex: 100,
        top: 0,
        height: '100%',
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    displayNone: {
        display: 'none',
    }
}))