import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textFieldSaurus: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit'
    }
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  numeric: {
    '-wap-input-format': 'N'
  },
  textFieldSaurusCor: {
    padding: 0,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit'
    },
    '& fieldset': {
      display: 'none'
    },
    '& input': {
      padding: theme.spacing(1),
      height: '40px'
    }
  },
  iconCadeado: {
    '& svg': {
      margin: 0
    }
  },
  textField: {
    backgroundColor: theme.palette.common.white,
  },
  campoObrigatorio: {
    color: theme.palette.error.main,
    fontWeight: 550
  }
}));
