import { CardImpressoraProps } from './card-marca-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { Grid, Tooltip, Typography } from 'views/design-system';

export const CardMarca = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardImpressoraProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id ?? '');
        }}
      >
        <Grid container className={classes.cardContent} px={1} spacing={1}>
          <Grid item
            className={classes.celulaGrid}
          >
            <Typography variant="caption">
              Nome
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              weight={700}
            >
              {model.nome}
            </Typography>
          </Grid>
          {model?.cnpjFab && model.cnpjFab.length > 0 && (
            <Grid item
              className={classes.celulaGrid}
            >
              <Typography variant="caption">
                CNPJ
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {formatarCPFCNPJ(model?.cnpjFab ?? '')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Tooltip arrow title="Editar Marca" placement='left'>
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
