import { useCallback } from "react"
import { IRangeData } from "views/components/controles/inputs/calendario/calendario"
import { useEmpresaAtual } from "services/app/hooks/empresa-atual"
import { useGetComissaoById } from "data/api/gestao/comissao/get-comissao-by-id"
import { useToastSaurus } from "services/app"
import { CampanhaComissaoModel } from "model/api/gestao/comissao/comissao-model"
import { usePostComissaoApuracao } from "data/api/gestao/comissao/post-campanha-apuracao"
import { useGetComissaoApuracaoList } from "data/api/gestao/comissao/get-campanha-comissao-apuracao-list"
import { useGetComissaoApuracaoById } from "data/api/gestao/comissao/get-campanha-apuracao-by-id"
import { usePostComissaoApuracaoBlackList } from "data/api/gestao/comissao/post-campanha-apuracao-black-list"
import { toDecimalString } from "utils/to-decimal"
import { EnumTpSituacaoApuracao, useDeleteComissaoApuracaoDesconsiderarComissionado } from "data/api/gestao/comissao/patch-campanha-apuracao-descondierar-comissionado"
import { useGetCampanhaApuracaoListExcel } from "data/api/gestao/comissao/get-campanha-apuracao-list-excel"
import { useGetCampanhaApuracaoVendasExcel } from "data/api/gestao/comissao/get-campanha-apuracao-vendas-excel"
import { EnumTpComissionado } from "model/enums/enum-tp-comissionado"
import { useComissaoStore } from "../comissao/comissao-modal/stores/comissao-store"
import { EnumTpStatusProcessamento } from "../comissao/comissao-modal/stores/types/IComissionado"
import { useApuracaoStore } from "./stores/apuracao-store"


// convert Junho/2024 para 2024-06-01
function convertDataFormatadaDataRange(dataFormatada: string): {
    dataInicial: Date;
    dataFinal: Date;
} {
    const meses = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    const [mes, ano] = dataFormatada.split('/')
    const mesIndex = meses.findIndex((m) => m === mes)

    return {
        dataInicial: new Date(parseInt(ano), mesIndex, 1),
        dataFinal: new Date(parseInt(ano), mesIndex + 1, 0)
    }
}

export function gerarDatasMesAMes(dataInicial: Date): string[] {
    const meses = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    let datas: string[] = [];

    for (let i = 0; i < 10; i++) {
        // Cria uma nova data para evitar mutação da data original
        let data = new Date(dataInicial.getFullYear(), dataInicial.getMonth() - i, 1);
        // Formata a data
        let dataFormatada = `${meses[data.getMonth()]}/${data.getFullYear()}`;
        // Adiciona ao array
        datas.push(dataFormatada);
    }

    // Inverte o array para começar da data mais antiga para a mais recente
    return datas.reverse();
}



interface ApuracaoSimples {
    id: string;
    empresaId: string;
    comissaoCampanhaId: string;
    dataApuracaoInicial: string;
    dataApuracaoFinal: string;
    competencia: string;
    comissionadoId: string;
    comissionadoCpfCnpj: string;
    comissionadoNome: string;
    condicao: number;
    valorCondicao: number;
    modeloApuracao: number;
    valorFinalBC: number;
    valorComissao: number;
    qtdeItens: number;
    situacao: number;
    comissionadoTipo: EnumTpComissionado;
    statusProcessamento: number;
}

interface ItemVendido {
    id: string;
    systemInsertUserId: string;
    systemUpdateUserId: string;
    systemDisabledUserId: string;
    systemInsertDate: string;
    systemUpdateDate: string;
    systemDisabledDate: string;
    systemDisabled: boolean;
    empresaId: string;
    movId: string;
    movProdId: string;
    comissaoCampanhaId: string;
    produtoVariacaoId: string;
    produtoId: string;
    caixaId: string;
    caixaNro: number;
    nnf: number;
    serie: number;
    mod: number;
    tpAmb: number;
    dhEmi: string;
    dataHoraLancamento: string;
    cProd: string;
    cEan: string;
    vUnCom: number;
    qCom: number;
    vProd: number;
    vDescItem: number;
    vDescPag: number;
    vDescPromocao: number;
    vAcrescimoItem: number;
    vAcrescimoPag: number;
    vItem: number;
    vImpostos: number;
    vCustoUnitario: number;
    vCusto: number;
    vLucro: number;
    valorVendaTotal: number;
    clienteId: string;
    clientePadrao: boolean;
    clienteCpfCnpj: string;
    clienteNome: string;
    operadorId: string;
    operadorNome: string;
    representanteId: string;
    representanteNome: string;
    representanteCpfCnpj: string;
    tabelaPrecoId: string;
    tabelaPrecoNome: string;
    vendedorId: string;
    vendedorNome: string;
    cancelamentoOperadorId: string;
    cancelamentoOperadorNome: string;
    dhCancelamento: string;
    situacao: number;
    valorComissao: number;
    xProd: string
}

interface ApuracaoCompleta {
    id: string;
    systemInsertUserId: string;
    systemUpdateUserId: string;
    systemDisabledUserId: string;
    systemInsertDate: string;
    systemUpdateDate: string;
    systemDisabledDate: string;
    systemDisabled: boolean;
    empresaId: string;
    comissaoCampanhaId: string;
    dataApuracaoInicial: string;
    dataApuracaoFinal: string;
    competencia: number;
    comissionadoTipo: number;
    comissionadoId: string;
    comissionadoCpfCnpj: string;
    comissionadoNome: string;
    condicao: number;
    valorCondicao: number;
    modeloApuracao: number;
    valorBC: number;
    valorDescontoBC: number;
    valorFinalBC: number;
    valorComissao: number;
    apuradorId: string;
    apuradorNome: string;
    financeiroId: string;
    urlArquivoBase: string;
    situacao: number;
    qtdeItens: number;
    itensApuracao: ItemVendido[];
    statusProcessamento: number;
}

export const useComissaoApuracaoService = () => {
    const { getEmpresaAtual } = useEmpresaAtual();
    const { showToast } = useToastSaurus()

    // API
    const { postComissaoApuracao } = usePostComissaoApuracao()
    const { getComissaoById } = useGetComissaoById();
    const { postComissaoApuracaoBlackList } = usePostComissaoApuracaoBlackList()
    const { getComissaoApuracaoBy } = useGetComissaoApuracaoById()
    const { getCampanhaApuracaoListExcel } = useGetCampanhaApuracaoListExcel()
    const { getCampanhaApuracaoVendasExcel } = useGetCampanhaApuracaoVendasExcel()
    const { patchComissaoApuracaoDesconsiderarComissionado } = useDeleteComissaoApuracaoDesconsiderarComissionado()
    const { getComissaoApuracaoList } = useGetComissaoApuracaoList()

    const {
        setComissaoApuracaoUICarregando,
        setComissaoApuracaoAcao,
        setComissionados,
        setSelectedComissionado,
        setBlackListVendas,
        setComissaoApuracaoApurar,
        setComissaoApuracaoUIDialogTitle,
        setApuracoesPagination
    } = useApuracaoStore()

    const { setComissao, state: { comissao } } = useComissaoStore()

    const comissionados = useApuracaoStore((state) => state.stateComissaoApuracao.comissionados)
    const selectedComissionado = useApuracaoStore((state) => state.stateComissaoApuracao.selectedComissionado)
    const blackListVendas = useApuracaoStore((state) => state.stateComissaoApuracao.blackListVendas)
    const paginacao = useApuracaoStore((state) => state.stateComissaoApuracao.ui.apuracoesPagination)
    const acaoApurar = useApuracaoStore((state) => state.stateComissaoApuracao.ui.acaoApurar)


    const getApuracaoListByComissaoId = useCallback(async (comissaoId: string, page: number, countRetry: number, dataFormatada?: string, retryQuandoTemProcessando?: boolean,  ) => {
        try {
            setComissaoApuracaoUICarregando(true)
            let filtroToQuery: string[] = []
            if (dataFormatada) {
                filtroToQuery = [dataFormatada]
            } else {
                filtroToQuery = gerarDatasMesAMes(new Date())
            }

            const dataRange = convertDataFormatadaDataRange(filtroToQuery[filtroToQuery.length - 1])
            const response = await getComissaoApuracaoList({
                empresaId: getEmpresaAtual()?.id ?? '',
                comissaoId,
                page: page,
                dataInicial: dataRange.dataInicial,
                dataFinal: dataRange.dataFinal
            })

            if (response.erro) {
                throw new Error(response.erro)
            }

            const apuracoes = response.resultado?.data?.list as ApuracaoSimples[]

            setComissionados(apuracoes.map((apuracao) => {
                let competenciaString = apuracao.competencia.toString()

                competenciaString = competenciaString.substring(0, 4) + '-' + competenciaString.substring(4, 6)
                return {
                    baseCalculo: apuracao.valorFinalBC,
                    dtComp: competenciaString,
                    id: apuracao.id,
                    nome: apuracao.comissionadoNome,
                    qtdItens: apuracao.qtdeItens,
                    valorComissao: apuracao.valorComissao,
                    tipo: apuracao.comissionadoTipo,
                    valorCondicao: `${apuracao.condicao === 0 ? 'R$' : '%'} ${apuracao.valorCondicao === 0 ? '0,00' : toDecimalString(apuracao.valorCondicao)}`,
                    situacao: apuracao.situacao,
                    statusProcessamento: apuracao.statusProcessamento
                }
            }))
            setApuracoesPagination(
                {
                    page: page,
                    totalRegisters: response.resultado?.data?.totalResults ?? 0,
                    totalPages: response.resultado?.data?.totalPages ?? 0
                }
            )

            // return true if has processando 
            const someProcessando = apuracoes.some((apuracao) => apuracao.statusProcessamento === EnumTpStatusProcessamento.Processando)
            if (someProcessando && retryQuandoTemProcessando && acaoApurar === 'comissionado' && countRetry < 6) { // Verifica se a ação de apurar ainda está ativa
                setTimeout(()=> getApuracaoListByComissaoId(comissao.id, 1, countRetry++, dataFormatada, true), 5000)
            } 

        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
            return false
        }
    }, [acaoApurar, comissao.id, getComissaoApuracaoList, getEmpresaAtual, setApuracoesPagination, setComissaoApuracaoUICarregando, setComissionados, showToast])

    const gerarApuracao = useCallback(async (data: {
        dataRange: IRangeData,
        comissaoId: string
    }) => {
        setComissaoApuracaoUICarregando(true)
        try {
            const response = await postComissaoApuracao({
                comissaoId: data.comissaoId,
                empresaId: getEmpresaAtual()?.id ?? '',
                dataInicial: data.dataRange.inicio,
                dataFinal: data.dataRange.fim
            })

            if (response.erro) {
                throw new Error(response.erro)
            }

            await getApuracaoListByComissaoId(data.comissaoId, 1, 1, undefined, true)

            setComissaoApuracaoAcao('apurar')
        } catch (error: any) {
            showToast('info', error.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
        }

    }, [getApuracaoListByComissaoId, getEmpresaAtual, postComissaoApuracao, setComissaoApuracaoAcao, setComissaoApuracaoUICarregando, showToast])

    const getComissao = useCallback(async (comissaoId: string) => {
        try {
            setComissaoApuracaoUICarregando(true);
            const resGetComissao = await getComissaoById(
                getEmpresaAtual()?.id ?? '',
                comissaoId
            );

            if (resGetComissao.erro) {
                throw new Error(resGetComissao.erro);
            }

            const comissao = resGetComissao.resultado
                ?.data as CampanhaComissaoModel;


            setComissao(comissao);

        } catch (e: any) {
            showToast('error', e.message);
        } finally {
            setComissaoApuracaoUICarregando(false);
        }
    },
        [setComissaoApuracaoUICarregando, getComissaoById, getEmpresaAtual, setComissao, showToast]
    );


    const selectComissionadoApuracao = useCallback(async (apuracaoId: string) => {
        try {
            const comissionado = comissionados.find((comissionado) => comissionado.id === apuracaoId)
            if (comissionado) {
                setComissaoApuracaoUICarregando(true)
                const response = await getComissaoApuracaoBy({
                    apuracaoId: apuracaoId,
                    comissaoId: comissao.id,
                    empresaId: getEmpresaAtual()?.id ?? ''
                })
                if (response.erro) {
                    throw new Error(response.erro)
                }

                const apuracao = response.resultado?.data as ApuracaoCompleta
                setSelectedComissionado({
                    ...comissionado,
                    vendas: apuracao.itensApuracao.filter((item) => {
                        return item.situacao === 0 // pega apenas ativas
                    }).map((item) => {
                        return {
                            dtLancamento: item.dataHoraLancamento,
                            id: item.id,
                            produto: item.xProd,
                            valorComissao: item.valorComissao,
                            valorVenda: item.valorVendaTotal,
                            venda: item.nnf.toString(),
                            custo: item.vCusto
                        }
                    })
                })
                setComissaoApuracaoApurar('vendas')
                setComissaoApuracaoUIDialogTitle(comissionado.nome)
            }
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
        }
    }, [comissao.id, comissionados, getComissaoApuracaoBy, getEmpresaAtual, setComissaoApuracaoApurar, setComissaoApuracaoUICarregando, setComissaoApuracaoUIDialogTitle, setSelectedComissionado, showToast])

    const toogleBlackListVendas = useCallback((vendaId: string) => {
        if (selectedComissionado) {
            const venda = selectedComissionado.vendas.find((venda) => venda.id === vendaId)
            const findInBlackList = blackListVendas.find((id) => id === vendaId)
            if (venda && !findInBlackList) {
                setBlackListVendas([...blackListVendas, venda.id])
            } else {
                setBlackListVendas(blackListVendas.filter((id) => id !== vendaId))
            }
        }
    }, [blackListVendas, selectedComissionado, setBlackListVendas])


    const sendBlackListVendas = useCallback(async () => {
        try {
            setComissaoApuracaoUICarregando(true)
            const response = await postComissaoApuracaoBlackList({
                empresaId: getEmpresaAtual()?.id ?? '',
                comissaoId: comissao.id,
                apuracaoId: selectedComissionado?.id ?? '',
                blackList: blackListVendas
            })

            if (response.erro) {
                throw new Error(response.erro)
            }

            showToast('success', 'Vendas adicionadas a blacklist com sucesso')
            setBlackListVendas([])
            setComissaoApuracaoApurar('comissionado')
            getApuracaoListByComissaoId(comissao.id, 1, 1)
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
        }
    }, [blackListVendas, comissao.id, getApuracaoListByComissaoId, getEmpresaAtual, postComissaoApuracaoBlackList, selectedComissionado?.id, setBlackListVendas, setComissaoApuracaoApurar, setComissaoApuracaoUICarregando, showToast])

    const updateSituacaoApuracao = useCallback(async (data: {
        situacao: EnumTpSituacaoApuracao
    }) => {
        try {
            setComissaoApuracaoUICarregando(true)
            await patchComissaoApuracaoDesconsiderarComissionado({
                apuracaoId: selectedComissionado?.id ?? '',
                empresaId: getEmpresaAtual()?.id ?? '',
                comissaoId: comissao.id,
                situacao: data.situacao
            })
            await getApuracaoListByComissaoId(comissao.id, 1, 1)
            setComissaoApuracaoApurar('comissionado')
            showToast('success', 'Comissionado desvinculado com sucesso')
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
        }
    }, [setComissaoApuracaoUICarregando, patchComissaoApuracaoDesconsiderarComissionado, selectedComissionado?.id, getEmpresaAtual, comissao.id, getApuracaoListByComissaoId, setComissaoApuracaoApurar, showToast])

    const exportCampanhaApuracaoListExcel = useCallback(async () => {
        try {
            setComissaoApuracaoUICarregando(true)

            const response = await getCampanhaApuracaoListExcel({
                comissaoId: comissao.id,
                empresaId: getEmpresaAtual()?.id ?? '',
                page: paginacao.page
            })

            if (response.erro) {
                throw new Error(response.erro)
            }

            const blob = new Blob([response.resultado?.data], { type: 'application/vnd.ms-excel' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a')

            anchor.href = url
            anchor.download = `Apuracoes-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
            anchor.click()
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
        }
    }, [comissao.id, getCampanhaApuracaoListExcel, getEmpresaAtual, paginacao.page, setComissaoApuracaoUICarregando, showToast])


    const exportCampanhaApuracaoVendasExcel = useCallback(async () => {
        try {
            setComissaoApuracaoUICarregando(true)

            const response = await getCampanhaApuracaoVendasExcel({
                comissaoId: comissao.id,
                empresaId: getEmpresaAtual()?.id ?? '',
                apuracaoId: selectedComissionado?.id ?? '',
                page: paginacao.page
            })

            if (response.erro) {
                throw new Error(response.erro)
            }

            const blob = new Blob([response.resultado?.data], { type: 'application/vnd.ms-excel' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a')

            anchor.href = url
            anchor.download = `Vendas-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
            anchor.click()
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setComissaoApuracaoUICarregando(false)
        }
    }, [comissao.id, getCampanhaApuracaoVendasExcel, getEmpresaAtual, paginacao.page, selectedComissionado?.id, setComissaoApuracaoUICarregando, showToast])


    return {
        gerarApuracao,
        getComissao,
        getApuracaoListByComissaoId,
        selectComissionadoApuracao,
        toogleBlackListVendas,
        sendBlackListVendas,
        updateSituacaoApuracao,
        exportCampanhaApuracaoListExcel,
        exportCampanhaApuracaoVendasExcel
    }
}