import { useStyles } from './card-doc-referenciados-styles'

import { CardDetalhesVendaProdutoProps, InfoProdInterface } from './card-detalhes-da-venda.props';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { Box, Grid, Typography } from 'views/design-system';
import { BarcodeIcon, CategoriaIcon } from 'views/components/icons';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { TipoMedicamentoMock } from 'data/mocks/tipo-medicamento-mock';
import classNames from 'classnames';
import { LaboratorioIcon } from 'views/components/icons/laboratorio-icon';
import { PromocaoDePorIcon } from 'views/components/icons/promocao-depor-icon';
import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { PromocaoAtacadoIcon } from 'views/components/icons/promocao-atacado-icon';
import { PromocaoLeveXPagueY } from 'views/components/icons/promocao-levex-paguey-icon';
import { PromocaoProgressivoIcon } from 'views/components/icons/promocao-progressivo-icon';
import { PromocaoTipoRegraMock } from 'data/mocks/promocao-mock';
import { toCurrency, toDecimal, toDecimalString } from 'utils/to-decimal';
import { useDescontoAcrescimo } from 'services/app/hooks/desconto-acrescimo';
import { TarjaMedicamentoMock } from 'data/mocks/tarja-medicamento-mock';

export const CardDetalhesVendaProduto = ({
  produto,
  index
}: CardDetalhesVendaProdutoProps) => {

  const classes = useStyles()
  const { retornaAcrescimoNoItem, retornaDescontoNoItem, retornaDescontoDePromocaoNoItem } = useDescontoAcrescimo()

  const valorProduto = produto.vUnComOrig ?? 0;
  const valorPMC = produto.med?.vPMC ?? 0;
  const valorPromocao = toDecimal((produto.vFinal / produto.qCom) ?? 0, 2);
  const acrescimosNoItem = retornaAcrescimoNoItem(produto)
  const descontosDePromo = retornaDescontoDePromocaoNoItem(produto)
  const valorDe = valorProduto > valorPMC ? valorProduto : valorPMC;
  const valorPorSemPromos = toDecimal((produto.vFinal + produto.vDesc - produto.vOutro) / produto.qCom, 2);
  const valorPorLiquido = valorPromocao;
  const descontoNoItem = retornaDescontoNoItem(produto)
  const valorUnComDeProdutosComAdicionais = produto.prodSubItem
    .map(x => x.vUnCom)
    .reduce((acc, current) => acc + current, 0) + produto.vFinal;
  const possuiDesconto = () => {
    if (valorDe > 0 && valorDe !== valorPorLiquido) {
      return true
    } return false

  }


  const InfoProduto = ({ produto, qtdPai }: InfoProdInterface) => {
    if (produto.infoSubItem?.qPadrao === 0 && produto.qCom < 1) {
      return null;
    }

    return (
      <>
        {produto.idAdicional && produto.indFin ?
          <>
            <div style={{ marginLeft: '10px' }}>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {produto.qCom <= 1 ? produto.qCom : (produto.qCom / qtdPai)}x {produto.xProd} <strong style={{ textAlign: 'end', fontWeight: 500 }}>{toCurrency(produto.vProd)}</strong>
              </p>
              {produto.prodSubItem.length > 0 &&
                produto.prodSubItem.map((p, index) => (
                  <InfoProduto
                    key={index}
                    produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
                    qtdPai={qtdPai}
                  />
                ))}
            </div>
          </>
          :
          <div style={{ marginLeft: '10px', }}>
            {produto.qCom}x {produto.xProd}
            {produto.prodSubItem.length > 0 &&
              produto.prodSubItem.filter(prod => !prod.idAdicional).map((p, index) => (
                <InfoProduto
                  key={index}
                  produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
                  qtdPai={qtdPai}
                />
              ))}
          </div>
        }
      </>
    );
  };

  return (
    <>
   
      <Grid item xs={12} flexDirection='column'
        key={index}
        className={
          index % 2 === 0
            ? classes.containerInfoPreviewBlue
            : classes.containerInfoPreview
        }
      >
        <Grid container xs={12} >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.textVTotal}
            >
              Item {index + 1}
            </Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ lineHeight: '16px', fontWeight: 600 }}
            >
              {produto.xProd}
            </Typography>
            <Grid className={classes.tags} xs={12} flex>
              {produto.cEan && (
                <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                  <BarcodeIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {produto.cEan}
                  </Typography>
                </Grid>
              )}
              {(produto.cProd !== produto.cEan) && (
                <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                  <BarcodeIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {produto.cProd}
                  </Typography>
                </Grid>
              )}
              {produto.med?.medicamento?.tarja !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagTarja)}>
                  <MedicamentoIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {TarjaMedicamentoMock.find((tarja) => tarja.Key === produto.med?.medicamento?.tarja)?.Value ?? ''}
                  </Typography>
                </Grid>
              )}
              {produto.med?.medicamento?.tipoMedicamento !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagTipoMedicamento)}>
                  <MedicamentoIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {TipoMedicamentoMock.find((tipo) => tipo.Key === produto.med?.medicamento?.tipoMedicamento)?.Value ?? ''}
                  </Typography>
                </Grid>
              )}
              {produto.med?.medicamento?.laboratorioNome !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagLaboratorio, produto.med?.medicamento.laboratorioNome.toUpperCase().indexOf("CIMED") > -1 ? classes.tagLaboratorioDestaque : undefined)}>
                  <LaboratorioIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {produto.med?.medicamento?.laboratorioNome}
                  </Typography>
                </Grid>
              )}
              {produto.med?.medicamento?.classeTerapeutica !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagClasse)}>
                  <CategoriaIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {produto.med?.medicamento?.classeTerapeutica}
                  </Typography>
                </Grid>
              )}
              {produto.med?.medicamento?.composicao !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagComposicao)}>
                  <MedicamentoIcon tipo="GERAL" />
                  <Typography variant="caption">
                    {produto.med?.medicamento?.composicao}
                  </Typography>
                </Grid>
              )}
              {produto.vOutroEmbutido > 0 && (
                <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)}>
                  <PromocaoDePorIcon tipo="GERAL" />
                  <Typography variant="caption">
                    <i>Acréscimo: </i>
                    <strong>+ R${' '}{toDecimalString(produto.vOutroEmbutido, 2)}</strong>
                  </Typography>
                </Grid>
              )}
              {((valorDe - valorPorSemPromos) * produto.qCom) > 0 && (
                <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)}>
                  <PromocaoDePorIcon tipo="GERAL" />
                  <Typography variant="caption">
                    <i>'De/Por'</i>
                    <strong>- R${' '}{toDecimalString(((valorDe - valorPorSemPromos) * produto.qCom), 2)}</strong>
                  </Typography>
                </Grid>
              )}
              {descontoNoItem > 0 && (
                <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)}>
                  <PromocaoDePorIcon tipo="GERAL" />
                  <Typography variant="caption">
                    <i>'Desconto no item'</i>
                    <strong>- R${' '}{toDecimalString((descontoNoItem), 2)}</strong>
                  </Typography>
                </Grid>
              )}
              {produto.descontos.filter(x => (x.promocao?.tipoPromocaoRegra ?? EnumPromocaoTipoRegra.DePor) !== EnumPromocaoTipoRegra.DePor).map((d) => {
                let icon = <PromocaoIcon tipo="GERAL" />
                switch (d.promocao?.tipoPromocaoRegra) {
                  case EnumPromocaoTipoRegra.DePor:
                    icon = <PromocaoDePorIcon tipo="GERAL" />;
                    break;
                  case EnumPromocaoTipoRegra.AtacadoValor:
                    icon = <PromocaoAtacadoIcon tipo="GERAL" />;
                    break;
                  case EnumPromocaoTipoRegra.AtacadoPerc:
                    icon = <PromocaoAtacadoIcon tipo="GERAL" />;
                    break;
                  case EnumPromocaoTipoRegra.LeveMaisPagueMenosQtde:
                    icon = <PromocaoLeveXPagueY tipo="GERAL" />;
                    break;
                  case EnumPromocaoTipoRegra.ProgressivoPerc:
                    icon = <PromocaoProgressivoIcon tipo="GERAL" />;
                    break;
                }
                return (
                  <Grid className={classNames(classes.tagProdutos, classes.tagDesconto)
                  } >
                    {icon}
                    <Typography variant="caption">
                      <i>{PromocaoTipoRegraMock.find(x => x.Key === d.promocao?.tipoPromocaoRegra)?.Value ?? ''}{' (' + d.promocao?.promocaoNome + ')'}: </i>
                      <strong>- R${' '}{toDecimalString(d.promocao?.valorDesconto, 2)}</strong>
                    </Typography>
                  </Grid>
                )
              })
              }
            </Grid>
          </Grid>
          <Grid item xs={7}
            alignItems='flex-end'
            justifyContent='center'
          >
            <Typography variant="subtitle1" className={classes.textVTotal}>
              Valor Unitário
            </Typography>
            <Grid container spacing={1}>
              {possuiDesconto() && (
                <Grid item >
                  <Typography variant="body1" color='textSecondary' className={classNames(classes.textVUnitario, classes.textVUnitarioPequeno, classes.textVUnitarioDe)}>
                    De R$ {toDecimalString(valorDe, 2)} {valorDe === valorPMC ? '*' : ''}
                  </Typography>

                </Grid>
              )}
              <Grid item>
                <Typography variant="body1" color="primary"
                  className={classNames(classes.textVUnitario, classes.textVUnitarioPequeno)}
                >
                  {possuiDesconto() ? 'Por' : ''} R$ {toDecimalString((produto.tpProduto === EnumTpProduto.ProdutoComSubItem)
                    ? (valorUnComDeProdutosComAdicionais + acrescimosNoItem) - descontosDePromo
                    : (produto.vUnComOrig + acrescimosNoItem) - descontosDePromo, 2)}
                </Typography>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.containerValorUnidade}
            flex
            flexDirection='column'
            alignItems='flex-end'
            justifyContent='center'
          >
            <Typography variant="subtitle1" className={classes.textVTotal}>
              Qtd
            </Typography>
            <Typography variant="body1" color="primary"
              className={classNames(classes.textVUnitario, classes.textVUnitarioPequeno)}
            >
              {Number.isInteger(produto.qCom) ? produto.qCom :toDecimalString(produto.qCom, 3) } {produto.uCom}
            </Typography>
          </Grid>
          <Grid item xs={3}
            flex
            flexDirection='column'
            alignItems='flex-end'
            justifyContent='center'
          >
            <Typography variant="subtitle1" className={classes.textVTotal}>
              Valor Total
            </Typography>
            <Typography variant="body1" color="primary"
              className={classNames(classes.textVUnitario, classes.textVUnitarioPequeno)}
            >
              R$ {toDecimalString((produto.tpProduto === EnumTpProduto.ProdutoComSubItem)
                ? (valorUnComDeProdutosComAdicionais)
                : (produto.vFinal), 2)}
            </Typography>
          </Grid>
        </Grid>
        {produto.adicionais.length > 0 && (
          <>
            <Box className={classes.subItensContainer}>
              <Typography
                color='primary'
                variant='subtitle1'
                className={classes.label}
              >
                Adicionais:
              </Typography>
            </Box>
            {produto.adicionais
              .filter((p) => p.qCom > 0)
              .map((p, index) => (
                <Box className={classes.subItensContainer}>
                  <InfoProduto key={index} produto={p} qtdPai={produto.qCom} />
                </Box>
              ))}
          </>
        )}
        {(produto.prodSubItem.length > 0 && produto.tpProduto === EnumTpProduto.Combo) && (
          <>
            <Box className={classes.subItensContainer}>
              <Typography
                color='primary'
                variant='subtitle1'
                className={classes.label}
              >
                Itens:
              </Typography>
            </Box>
            {produto.prodSubItem
              .filter((p) => p.qCom > 0)
              .map((p, index) => (
                <Box className={classes.subItensContainer}>
                  <InfoProduto key={index} produto={p} qtdPai={produto.qCom} />
                </Box>
              ))}
          </>
        )}
      </Grid>
    </>
  );
};
