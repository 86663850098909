
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { CartaoConvenioPostModel } from "model/api/gestao/convenio/cartao-convenio-post-model";

export function usePutCartaoConvenio() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putCartaoConvenio = useCallback(
		(cartaoConvenio: CartaoConvenioPostModel, empresaId: string) =>
			invocarApi({
				url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/${cartaoConvenio.contaPessoaId}/cartao/${cartaoConvenio.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				data: JSON.stringify(cartaoConvenio),
				enviarTokenUsuario: true,
			}),
		[invocarApi],
	);
	return {
		...outrasPropriedades,
		putCartaoConvenio,
	};
}
