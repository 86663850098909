import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexBasis: '100%',
    },
    buttonContainerHeader: {
        display: 'flex',
        height: '79px',
        borderRadius: '16px',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1)
    },
    button: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #C5C5C5',
        padding: '16px',
        flexWrap: 'wrap',
        textAlign: 'center',
        cursor: 'pointer'
    },
    buttomLeft: {
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
    },
    buttomRight: {
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
    },
    label: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px'
    },
    buttonSelected: {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px'
    },
    btnConfirmar: {
        padding: theme.spacing(1),
    }
}));
