import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

interface ParamsProps {
    pessoaId: string
    empresaId: string
}

export function useGetFaturaConvenioCompetencias() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturaConvenioCompetencias = useCallback(
        ({ pessoaId, empresaId }: ParamsProps) => {

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/pessoa/${pessoaId}/fatura/competencias`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            })
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturaConvenioCompetencias,
    };
}
