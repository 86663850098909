import { useStyles } from './accordion-perfil-styles';
import { PerfilEditPermissaoFormModel } from 'model/app/forms/perfil/perfil-edit-permissao-form-model';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import AccordionPerfilControle from '../accordion-perfil-controle/accordion-perfil-controle';
import { useCallback, useState } from 'react';
import { EnumTpControle } from 'model/enums/enum-tpcontrole';

interface AccordionPropsPerfil {
  permissoes: Array<PerfilEditPermissaoFormModel>;
  onPermissaoChanced(cod: number, novoValor: any, titulo: string): void;
  titulo: string;
  chave: string;
}

function AccordionPerfil(props: AccordionPropsPerfil) {
  const [todosOsAcessos, setTodosOsAcessos] = useState<boolean>(props.permissoes.some(x => x.valor === 'true' || Number(x.valor) > 0))
  const handlePermissaoChanged = (cod: number, novoValor: string) => {
    props.onPermissaoChanced(cod, novoValor, props.chave);
  };

  const [openned, setOpenned] = useState(false);
  const classes = useStyles();

  const alterarTodosOsAcessos = useCallback((value: boolean) => {
    props.permissoes.forEach((permissao) => {
      switch (permissao.tipo) {
        case EnumTpControle.SimNao:
          props.onPermissaoChanced(parseInt(permissao.codigo), value ? permissao.padrao : 'false', props.chave)
          return
        case EnumTpControle.Numero:
          props.onPermissaoChanced(parseInt(permissao.codigo), '0', props.chave)
          return
        default:
          return
      }
    })
    setTodosOsAcessos(value)

  }, [props])


  return (
    <div className={classes.root}>
      <AccordionSaurus
        labelPrimary={props.titulo}
        showDivider={true}
        onChange={() => {
          setOpenned(!openned);
        }}
        expanded={openned}
        noPaperRoot
        noPaddingContent
        funcaoDoSwitch={(value) => { alterarTodosOsAcessos(value) }}
        checkedSwitch={todosOsAcessos}
      >
        <AccordionPerfilControle
          onPermissaoChanced={handlePermissaoChanged}
          list={props.permissoes}
          habilitaTodasAsPermissoes={todosOsAcessos}
        />
      </AccordionSaurus>
    </div>
  );
}

export default AccordionPerfil;
