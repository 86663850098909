import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: theme.spacing(2),
        position: 'absolute',
        height: '100%',
        width: '100%',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        background: theme.palette.background.default,
        zIndex: 9999998,
    },
    item: {
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
    },
    button: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flexGrow: 1
    },
    text: {
        padding: theme.spacing(2)
    }
}));