import { isEmpty } from "lodash"
import { EmitCompletoModel } from "model/api/gestao/historico-vendas/historico-vendas-completo"
import { useMemo } from "react"
import { formatarCPFCNPJ } from "utils/cpfcnpj-format"
import { formatarCEP } from "utils/formatar-cep"
import { phoneMask } from "utils/phone-mask"
import { useThemeQueries } from "views/theme"
import { CardDadosEmpresaDetalheVendaProps } from "./card-dados-empresa-detalhe-venda-props"
import { Card } from "@material-ui/core"
import { Divider, Grid, Typography } from "views/design-system"

export const CardDadosEmpresaDetalheVenda = (props: CardDadosEmpresaDetalheVendaProps) => {

    const { theme } = useThemeQueries();

    const retornaEndereco = useMemo(() => {
        const { xBairro, cep, xMun, nro, xCpl, xLgr, uf } = props.model.infMov.emit ?? new
            EmitCompletoModel();
        if (isEmpty(props.model.infMov.emit)) {
            return ''
        }

        return `${xLgr ?? ''}${!isEmpty(nro) ? `, ${nro}` : ''}${!isEmpty(xBairro) ? (' - ' + xBairro) : ''}${xMun ? `, ${xMun}` : ''}${uf ? ` - ${uf}` : ''}${cep ? `, ${formatarCEP(cep ?? '')}` : ''}${xCpl ? `, ${xCpl}` : ''}`
    }, [props.model.infMov.emit])

    return (
        <Card style={{ boxShadow: theme.shadows[1] }}>
            <Grid container spacing={2} p={2}>
                <Grid item xs={12} >
                    <Typography weight={600} color="textPrimary" variant="h6">
                        Dados da Empresa
                    </Typography>
                    <Divider variant="fullWidth" style={{ background: theme.palette.grey[400] }} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Nome
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.emit.xNome) ? props.model.infMov.emit.xNome : 'Sem Vendedor'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                CPF/CNPJ
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.emit.doc) ? formatarCPFCNPJ(props.model.infMov.emit.doc) : "Não Informado"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Telefone
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.emit.fone) && props.model.infMov.emit.fone.length > 5 ? phoneMask(props.model.infMov.emit.fone) : 'Sem Telefone'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Endereço
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(retornaEndereco) ? retornaEndereco : 'Sem Endereço'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}