import { guidEmpty } from "utils/guid-empty";

export class CartaoSaldoConvenioModel {
    constructor(
        public id: string = guidEmpty(),
        public contaPessoaId: string = guidEmpty(),
        public contaPessoaCartaoId: string = guidEmpty(),
        public saldo: number = 0,
        public dataUltimoDebito: string = '',
        public valorUltimoDebito: number = 0,
        public dataUltimoCredito: string = '',
        public valorUltimoCredito: number = 0
    ) { }
}