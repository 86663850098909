import { useMemo } from "react"
import { CardItemMenuProps } from "./card-item-menu-props"
import { useStyles } from "./card-item-menu-styles"
import { useThemeQueries } from "views/theme"
import { IconsHelper } from "views/components/icons-helper"
import { useLocation } from "react-router-dom"
import { EnumMenu } from "model"
import { useOpenMovRota } from "services/app/hooks/open-mov-rota"
import { Box, Button, Tooltip, Typography } from "views/design-system"

export const CardItemMenu = (props: CardItemMenuProps) => {

    const { isMobile } = useThemeQueries()
    const { model } = useMemo(() => props, [props])
    const location = useLocation()
    const classes = useStyles({
        isMobile,
        location: location.pathname === model.rota
    })
    const { actionMenuPDV } = useOpenMovRota()

    const idDinamico = useMemo
        (() => {
            switch (model.codigo) {
                case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                    return 'tour-venda-avulso'
                case EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO:
                    return 'tour-catalogo'
                case EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA:
                    return 'tour-retaguarda'
                default:
                    return model.nome
            }
        }, [model.codigo, model.nome])

    const nomeCurto = useMemo(() => {
        switch (model.codigo) {
            case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                return 'Teclado'
            case EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA:
                return 'Finalizar'
            case EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE:
                return 'Cliente'
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA:
                return 'Mesas'
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS:
                return 'Pedidos'
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA:
                return 'Comandas'
            case EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV:
                return 'Configurações'
            case EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR:
                return 'Cancelar Venda'
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_BALCAO:
                return 'Balcão'
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY:
                return 'Acom. Delivery'
            case EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY:
                return 'Atendimento Delivery'
            case EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO:
                return 'Catálogo'
            case EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA:
                return 'Cadastros'
            case EnumMenu.OPERACIONAL_DENTROVENDA_HISTORICO_VENDA:
                return 'Histórico'
            case EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR:
                return 'Leitor'
            case EnumMenu.OPERACIONAL_DENTROVENDA_DASHBOARD:
                return 'Dashboard'
            case EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA:
                return 'Última Venda'
            case EnumMenu.OPERACIONAL_DENTROVENDA_PAGAMENTO:
                return 'Recebimento'
            case EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA:
                return 'Entrada'
            case EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS:
                return 'Sincronizar'
            case EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO:
                return 'Carrinho'
            case EnumMenu.OPERACIONAL_DENTROVENDA_GERENCIAR_SESSOES:
                return 'Sessões'
            case EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR:
                return 'Vendedor'
            default:
                return model.nome
        }
    }, [model.codigo, model.nome])

    const component = useMemo(() => {
        return (
            <Box
                id={props.renderTour ? idDinamico : undefined}
                className={classes.container}>
                <Button
                    className={classes.btnContent}
                    variant="text"
                    color="primary"
                    classes={{
                        label: classes.btnLabel,
                        text: classes.btnText,
                        root: classes.btnRoot
                    }}
                    onClick={() => {
                        actionMenuPDV(model.codigo, model.rota)
                    }}
                >
                    <>
                        <Tooltip arrow title={model.nome} placement="right">
                            <IconsHelper
                                codigoModulo={model.codigo}
                                class={classes.icon}
                                tipo="GERAL"
                            />
                        </Tooltip>
                        <Typography
                            color="textPrimary"
                            className={classes.textPrimary}
                        >
                            {nomeCurto}
                        </Typography>
                    </>
                </Button>
            </Box>
        )
    }, [actionMenuPDV, classes.btnContent, classes.btnLabel, classes.btnRoot, classes.btnText, classes.container, classes.icon, classes.textPrimary, idDinamico, model.codigo, model.nome, model.rota, nomeCurto, props.renderTour])

    return component
}