import CardFaturaLancamento from "views/components/cards/card-fatura-lancamento/card-fatura-lancamento";
import { LancamentosListProps } from "./lancamentos-list-props";
import { isEmpty } from "lodash";
import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { Button } from "views/design-system";

export const LancamentosList = ({ model, limitar, verMaisHistorico, carregando, ...props }: LancamentosListProps) => {
    return (
        <div>
            {(model ?? []).filter((lancamento, index) => limitar ? index < 8 : lancamento).map(lancamento => {
                return (
                    <CardFaturaLancamento
                        model={lancamento}
                    />
                )
            })}
            {!carregando && isEmpty(model) && (
                <CardNaoEncontrado mensagem="Nenhum lançamento encontrado." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {(model ?? []).length > 8 && limitar && (
                <div>
                    <Button color="primary" variant="text" fullWidth onClick={verMaisHistorico}>
                        VER MAIS
                    </Button>
                </div>
            )}
        </div>
    )
}