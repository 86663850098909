import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views';
import { CardTransferirItensProps } from './card-transferir-itens-props';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { DefaultCard } from '../components';
import { useStyles } from './card-transferir-itens-styles'
import { Tooltip } from 'views/design-system';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import classNames from 'classnames';

export const CardTransferirItens = ({
  model,
  onClick,
  selecionado,
}: CardTransferirItensProps) => {

  const classesCardDefault = useDefaultCardStyles();
  const { isMobile } = useThemeQueries();
  const classes = useStyles()

  return (
    <>
      <DefaultCard
        isSelected={selecionado}
        onClick={async () => {
          onClick(model);
        }}
      >
        <div style={{
          width: '100%',
          padding: 8
        }}>
          <div className={classesCardDefault.cardContent}>
            <div
              className={classesCardDefault.celulaGridFull}
              style={{ minWidth: 100, maxWidth: isMobile ? '160px' : '300px', margin: 0 }}
            >
              <Typography color="textPrimary" variant="caption">
                Nome
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.descricao}</strong>
              </Typography>
            </div>
            <div className={classesCardDefault.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Qtd
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <b>{model.quantidade} {model.unidadeComercial}</b>
              </Typography>
            </div>
          </div>
          <Box ml={1}>
            {model?.adicionais?.filter(x => x.indFin).length > 0 && (
              <>
                <Typography variant="body1" style={{ fontSize: 12 }}>Adicionais</Typography>
                <Divider className={classes.divider} />
              </>
            )}
            {model?.adicionais?.length > 0 && model.adicionais.filter(x => x.indFin).map((p, index) => (
              <Grid key={index}>
                <Typography>{p.quantidade}X {p.descricao}</Typography>
              </Grid>
            ))}
          </Box>
        </div>
        <Tooltip arrow title="Selecionar">
          <div className={classes.containerCardRightButton}>
            <div className={classNames(classes.cardRightButton, classesCardDefault.cardRightButtonWithoutNone)}>
              <CheckboxDefault checked={selecionado} />
            </div>
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
