import { forwardRef, ReactElement, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormProdutoCodigoaPreCadastroValidation } from './form-produto-codigo-pre-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';

import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProdutoCodigoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-codigo-form-model';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ABIcon } from 'views/components/icons/ab-icon';
import { TipoField } from 'views/components/controles/inputs/text-field-saurus/text-field-saurus-types';
import { NumberIcon } from 'views/components/icons/number-icon';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { validateEan } from 'utils/validateEan';
import { isEmpty } from 'lodash';
import { validaCaracter } from 'utils/valida-caracter';
import { Button, Grid } from 'views/design-system';

export enum EnumFormCodigo {
  EAN,
  ANVISA_EAN,
  AMBOS
}

export interface FormProdutoCodigoPreCadastroProps
  extends DefaultFormProps<ProdutoCodigoPreCadastroFormModel> {
  setCarregandoExterno(carregando: boolean): void;
  tipoForm: EnumFormCodigo;
  label?: string
  placeholder?: string
  obrigatorio?: boolean;
}

export const FormProdutoCodigoPreCadastro = forwardRef<
  DefaultFormRefs<ProdutoCodigoPreCadastroFormModel>,
  FormProdutoCodigoPreCadastroProps
>(({ loading,
  setCarregandoExterno,
  label = 'Código',
  placeholder = '',
  obrigatorio,
  tipoForm = EnumFormCodigo.EAN,
  ...props }: FormProdutoCodigoPreCadastroProps, ref,) => {

  const utilClasses = makeUtilClasses();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(true)
  const [type, setType] = useState<TipoField>("NUMERO")
  const [buttonEanAB, setButtonEanAB] = useState<ReactElement | null>(null)
  const [initialValues, setInitialValues] =
    useState<ProdutoCodigoPreCadastroFormModel>(
      new ProdutoCodigoPreCadastroFormModel(),
    );
  const { addHandler, removeHandler } = useEventTools()

  const { FormProdutoCodigoaPreCadastroYupValidation } =
    useFormProdutoCodigoaPreCadastroValidation({
      obrigatorio: obrigatorio || false,
      tipoForm
    });
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    setError
  } = useForm<ProdutoCodigoPreCadastroFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormProdutoCodigoaPreCadastroYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const valorBarCode = useCallback((valor: string) => {
    setValue('codigo', valor)
  }, [setValue])


  useEffect(() => {
    if (tipoForm === EnumFormCodigo.ANVISA_EAN) {
      if (validateEan(initialValues.codigo) && isEmpty(initialValues.codigoEAN)) {
        setValue('codigo', '')
        setValue('codigoEAN', initialValues.codigo)
      }
    }
  }, [initialValues.codigo, initialValues.codigoEAN, setValue, tipoForm])

  useEffect(() => {
    addHandler(AppEventEnum.SetValueCam, valorBarCode)
    setButtonEanAB(
      <>

        {type === "NUMERO" ? (
          <NumberIcon tipo='INPUT' style={{ cursor: 'pointer' }} onClick={() => {
            setType("TEXTO")
            refInputCodigo.current?.focus()
            setTimeout(() => setFocused(true), 101)
          }} />
        ) : (
          <ABIcon tipo='INPUT' style={{ cursor: 'pointer' }} onClick={() => {
            setType("NUMERO")
            refInputCodigo.current?.focus()
            setTimeout(() => setFocused(true), 101)
          }} />
        )}
      </>
    )

    return () => removeHandler(AppEventEnum.SetValueCam, valorBarCode)
  }, [type, focused, setValue, addHandler, valorBarCode, removeHandler])

  const onSubmit = (values: ProdutoCodigoPreCadastroFormModel) => {

    const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/
    if (regexCaracEspeciais.test(values.codigo)) {
      setError('codigo', { type: "error", message: 'Não pode conter caracteres especiais.' })
      return
    }
    const model = picker<ProdutoCodigoPreCadastroFormModel>(
      values,
      new ProdutoCodigoPreCadastroFormModel(),
    );

    model.codigo = validaCaracter(model.codigo, true);

    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ProdutoCodigoPreCadastroFormModel());
      if (!isMobile) refInputCodigo.current?.focus();
      reset();
    },
    fillForm: (model: ProdutoCodigoPreCadastroFormModel) => {
      if (isNaN(Number(model.codigo))) {
        setType('TEXTO')
      }
      setInitialValues(model);
      reset({ ...model })
      if (!isMobile) refInputCodigo.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="codigo"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    inputRef={refInputCodigo}
                    allowSubmit
                    autoFocus={focused}
                    disabled={loading}
                    showCamBarcode
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      field.onBlur()
                      setTimeout(() => setFocused(false), 100)
                    }}
                    fullWidth
                    variant="outlined"
                    label={label}
                    tipo={type}
                    endAdornmentButton={tipoForm === EnumFormCodigo.EAN ? buttonEanAB : undefined}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={placeholder}
                    error={Boolean(errors.codigo && errors.codigo.message)}
                    helperText={
                      touchedFields.codigo || errors.codigo
                        ? errors.codigo?.message
                        : undefined
                    }
                    ref={field.ref}
                    value={field.value}
                    onChange={(e) => {
                      if (type === 'TEXTO') {
                        e.target.value = validaCaracter(e.target.value, true);
                      }
                      field.onChange(e)
                    }}
                    name={field.name}
                  />
                )}
              />
            </Grid>
            {tipoForm === EnumFormCodigo.ANVISA_EAN && <Grid item xs={12}>
              <Controller
                name="codigoEAN"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    allowSubmit
                    autoFocus={focused}
                    disabled={loading}
                    showCamBarcode
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      field.onBlur()
                      setTimeout(() => setFocused(false), 100)
                    }}
                    fullWidth
                    variant="outlined"
                    label='Código EAN'
                    limite={60}
                    tipo={type}
                    endAdornmentButton={undefined}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={placeholder}
                    error={Boolean(errors.codigoEAN && errors.codigoEAN.message)}
                    helperText={
                      touchedFields.codigoEAN || errors.codigoEAN
                        ? errors.codigoEAN?.message
                        : undefined
                    }
                    ref={field.ref}
                    value={field.value}
                    onChange={(e) => {
                      if (type === 'TEXTO') {
                        e.target.value = validaCaracter(e.target.value, true);
                      }
                      field.onChange(e)
                    }}
                    name={field.name}
                  />
                )}
              />
            </Grid>}
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
},
);
