import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 260,
        maxWidth: 395,
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '40px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing(1),

        "& ::placeholder": {
            fontSize: '1rem',
            fontWeight: "normal",
            lineHeight: '40px',
            textAlign: 'center',
            color: theme.palette.text.primary,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            minWidth: '220px',
        }
    },
    title:
    {
        fontSize: '18px',
        marginBottom: theme.spacing(1) * -1,
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
    },
    contentInput: {
        display: 'flex',
        justifyContent: 'center'
    },
    root: {
        '& input': {
            textAlign: 'center'
        }
    }
}))