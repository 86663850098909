import { Typography } from 'views/design-system';
import { CardInfoPlanoProps } from './card-info-plano-props';
import { useStyles } from './card-info-plano-styles';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { useSessaoAtual } from 'services/app';
import { toDateString } from 'utils/to-date';
import { useThemeQueries } from 'views/theme';

export const CardInfoPlano = ({ data }: CardInfoPlanoProps) => {
  const classes = useStyles();
  const { plano } = useSessaoAtual();
  const { theme } = useThemeQueries()

  const expiracaoPlano = toDateString(plano?.contratado.dExpiracao);

  return (
    <div className={classes.cardContainer}>
      <Typography variant="caption" className={classes.textTitle}>
        {data.nome}
      </Typography>

      {data.valor === '1' ? (
        <OkIcon tipo="BUTTON" />
      ) : data.valor === '0' ? <CancelarIcon tipo="BUTTON" fill={theme.palette.error.main} /> : data.valor === '-1' ? (
        <div style={{ display: 'flex' }}>
          <Typography variant="body2" className={classes.textTitle}>
            {data.quant}/
          </Typography>
          <Typography className={classes.textInfinite}>ꝏ</Typography>
        </div>
      ) : data.valor !== '0' ? (
        <div style={{ display: 'flex' }}>
          {data.codigo === EnumPlanoOpcoes.DiasExpiracao ? (
            <Typography variant="body2" className={classes.textTotal}>
              {expiracaoPlano}
            </Typography>
          ) : (
            <>
              <Typography variant="body2" className={classes.textTitle}>
                {data.quant}/
              </Typography>
              <Typography variant="body2" className={classes.textTotal}>
                {data.valor}
              </Typography>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
