import { useMemo, ReactNode, useState, useEffect } from 'react';
import { Typography, Divider, Grid } from 'views/design-system';
import { useStyles } from '../../menu-principal-styles';
import { usePaiStyles } from './list-modulo-pai-styles';
import { ListModuloPaiProps } from './list-modulo-pai-props';
import { ListModuloFilho } from '../list-modulo-filho/list-modulo-filho';
import { EnumMenu } from 'model';
import { isPlanoComControleDeAcesso } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';

export const ListModuloPai = (props: ListModuloPaiProps) => {
  const { ...listProps } = useMemo(() => {
    return {
      ...props
    };
  }, [props]);

  const [listaModulos, setListaModulos] = useState(null as ReactNode);
  const { plano } = useSessaoAtual();
  const planoComControleDeAcesso = isPlanoComControleDeAcesso(plano?.plano);

  useEffect(() => {
    setListaModulos(
      listProps.modulos
        .filter((x) => x.codigoPai === props.codigoModulo)
        .map((item) => (
          <ListModuloFilho
            modeloAtual={props.modeloAtual}
            key={item.codigo}
            codigoModulo={item.codigo}
            dataValidade={item.dataValidade}
            nomeModulo={
              item.codigo === EnumMenu.ADMINISTRATIVO_ACESSOS_PERMISSOES
                ? planoComControleDeAcesso
                  ? item.nome
                  : 'Usuários'
                : item.nome
            }
            descricaoModulo={item.descricao}
            isFavorite={false}
            rota={item.rota}
          />
        ))
    );
  }, [listProps.modulos, props.modeloAtual, props.codigoModulo, planoComControleDeAcesso]);

  const classes = useStyles();
  const paiClasses = usePaiStyles();
  return (
    <>
      <Divider className={classes.divider} />

      {listProps.nomeModulo.length > 0 && (
        <Typography
          className={
            props.modeloAtual === 'Completo'
              ? paiClasses.title
              : paiClasses.titlehidden
          }
        >
          {listProps.nomeModulo}
        </Typography>
      )}
      {listProps.descricao.length > 0 && (
        <Typography>
          {listProps.descricao}
        </Typography>
      )}
      <Grid py={1} className={paiClasses.list}>{listaModulos}</Grid>
    </>
  );
};
