import { Box, Button, Grid, Typography } from "views/design-system";
import { CircularLoading } from "views/components/utils";
import { useStyles } from "../../dialog-adicionar-produto-balanca-styles";
import { useDevice } from "services/app/hooks/device";
import { CancelarIcon } from "views/components/icons";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useThemeQueries } from "views/theme";
import { TecladoIcon } from "views/components/icons/teclado-icon";
import { BalancaIcon } from "views/components/icons/balanca-icon";

export interface PesandoProdutoProps {
    submitPeso: (qCom: string) => Promise<void>;
    closeBalanca: () => void;
}

export const PesandoProduto = ({
    submitPeso,
    closeBalanca
}: PesandoProdutoProps) => {

    const { getBalanca, carregando } = useDevice();
    const classes = useStyles();
    const { theme } = useThemeQueries();

    const [erro, setErro] = useState<string>('')

    const capturarPeso = useCallback(async () => {
        setErro('')
        try {
            const peso = await getBalanca()
            await submitPeso(peso)
        } catch (e: any) {
            if (!isNaN(Number(e.message)) && Number(e.message) === 0) {
                closeBalanca();
                return
            }
            setErro(e.message)
        }
    }, [closeBalanca, getBalanca, submitPeso])

    useEffect(() => {
        capturarPeso()
    }, [capturarPeso])
    return (
        <Grid className={classes.cardContainer}>
            <Grid className={classes.cardContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box my={4} flex flexDirection='column' alignItems='center'>
                            {isEmpty(erro) || carregando ? (
                                <>
                                    <CircularLoading tipo='NORMAL' class={classes.loadingPesando} />
                                    <Typography>Coloque o produto na balança para capturar o peso.</Typography>
                                </>
                            ) : (
                                <>
                                    <CancelarIcon tipo="GERAL" style={{ fill: theme.palette.error.main, width: 80, marginBottom: 8 }} />
                                    <Typography variant="body1">{erro || 'Falha ao pesar produto. Tente novamente ou insira manualmente.'}</Typography>
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Button
                            disabled={carregando}
                            variant="outlined"
                            fullWidth
                            color="primary"
                            onClick={() => {
                                closeBalanca();
                            }}
                        >
                            <TecladoIcon tipo="BUTTON" />
                            Informar Manualmente
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Button
                            disabled={carregando}
                            variant="contained"
                            fullWidth
                            color="primary"
                            type="submit"
                            onClick={() => capturarPeso()}
                        >
                            <BalancaIcon tipo="BUTTON_PRIMARY" />
                            Pesar Novamente
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};