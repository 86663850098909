import { useEffect, useState, useCallback } from "react";
import { useStyles } from "./imposto-list-styles";
import { ImpostoListData } from "./imposto-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useToastSaurus, useCadastros, useSessaoAtual } from "services/app";
import { isEmpty } from "lodash";
import { ProdutoFiscalModel } from "model/api/gestao/produto/produto-fiscal/produto-fiscal-model";
import { useHistory } from "react-router";
import { useGetProdutosFiscais } from '../../../../../../../data/api/gestao/produto-fiscal/get-produtos-fiscais';
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { Grid } from "views/design-system";


export const ImpostoList = () => {
    const classes = useStyles();
    const { getProdutosFiscais, carregando } = useGetProdutosFiscais();
    const { showToast } = useToastSaurus();
    const history = useHistory();
    const { abrirCadastroProdutoFiscal } = useCadastros();
    const { getEmpresaSelecionada } = useSessaoAtual()
    const { addHandler, removeHandler } = useEventTools()

    const [modalEditAberto, setModalEditAberto] = useState(false);
    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<ProdutoFiscalModel>(),
    });

    const [selectedList, setSelectedList] = useState<Array<string>>([]);

    const urlParams = new URLSearchParams(history.location.search)
    const filtros = {
        status: isEmpty(urlParams.get('status')) ? 1 : Number(urlParams.get('status')),
        generico: urlParams.get('generico') || ''
    }

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<ProdutoFiscalModel>
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {

            const query =
                "" +
                (!isEmpty(filtros.generico) ? "&Descricao=" + filtros.generico : "") +
                (filtros.status > -1 ? "&ativo=" + Boolean(filtros.status) : "");

            try {
                const res = await getProdutosFiscais(query, getEmpresaSelecionada()?.Id ?? '', newPage);
                if (res.erro) throw res.erro;

                //se o index for maior que as paginas ele busca a ultima
                if (
                    res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
                    res.resultado?.data?.totalResults > 0
                ) {
                    search(res.resultado?.data?.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data?.pageIndex,
                    res.resultado?.data?.totalPages,
                    res.resultado?.data?.totalResults,
                    res.resultado?.data?.list
                );
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [filtros.generico, filtros.status, getProdutosFiscais, getEmpresaSelecionada, fillResult, showToast]
    );

    const modalEdit = useCallback(({ openned }: any) => {
        setModalEditAberto(openned)
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ProdutoFiscalModal, modalEdit)

        return () => removeHandler(AppEventEnum.ProdutoFiscalModal, modalEdit)
    }, [addHandler, modalEdit, removeHandler])

    useEffect(() => {
        if (!modalEditAberto) {
            search(queryStatus.page);
        }
    }, [modalEditAberto, queryStatus.page, search]);

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [search, queryStatus.totalPages]
    );

    const onCardSelected = (id: string) => {
        abrirCadastroProdutoFiscal(id, history.location.pathname, true);
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryStatus.totalPages}
                        totalRegisters={queryStatus.totalResults}
                        currentPage={queryStatus.page}
                    />
                    <Grid item xs={12} className={classes.listContainer}>
                        <ImpostoListData
                            carregando={carregando}
                            list={queryStatus.list}
                            selectedList={selectedList}
                            onCardSelected={onCardSelected}
                            onCardChecked={onCardChecked}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
