import { ConvenioFaturaModel } from "model/api/gestao/convenio";
import { CardNaoEncontrado } from "views/components/cards";
import { CardConvenioFatura } from "views/components/cards/card-convenio-fatura";
import { InformacaoIcon } from "views/components/icons";

export interface ConvenioFaturaGridProps {
    list: Array<ConvenioFaturaModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const ConvenioFaturaListData = ((props: ConvenioFaturaGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {(!props.list || props.list.length === 0) && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhuma fatura encontrada." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list && props.list.length > 0 && props.list.map((fatura, index) => {
                return (
                    <CardConvenioFatura
                        selected={
                            props.selectedList.filter((item) => item === fatura.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={fatura}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
