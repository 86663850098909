import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useTheme } from '@material-ui/core';
import { SalvarIcon, VoltarIcon } from 'views/components/icons';
import { DefaultFormRefs } from 'views/components/form/utils';
import { UsuariosConvidarModel } from 'model/api/gestao/perfil/usuarios-convidar-model';
import { FormUsuariosConvidar } from 'views/components/form/perfil/form-usuarios-convidar/form-usuarios-convidar';
import { PerfilUsuariosConvidarFormModel } from 'model/app/forms/perfil/perfil-usuarios-convidar-form-model';
import { useCallback, useEffect, useRef } from 'react';
import { usePostUsuariosConvidar } from 'data/api/gestao/perfil/post-usuarios-convidar';
import { useCadastros, useToastSaurus } from 'services/app';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views';
import { useSessaoAtual } from 'services/app';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { Button, Grid } from 'views/design-system';

export interface DialogUsuariosConvidarProps {
  aberto: boolean;
}

export const DialogUsuariosConvidar = (props: DialogUsuariosConvidarProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { postUsuariosConvidar, carregando } = usePostUsuariosConvidar();
  const { fecharCadastroUsuario } = useCadastros();
  const classes = useModalStyles();
  const {callTour} = useTourSaurus();
  const usuariosConvidarFormRef =
    useRef<DefaultFormRefs<PerfilUsuariosConvidarFormModel>>(null);
  const redirect = useRef<boolean>(true);
  const theme = useTheme();

  const postConviteUsuario = useCallback(
    async (model: UsuariosConvidarModel) => {
      try {
        const ret = await postUsuariosConvidar(model);
        if (ret.erro) {
          throw ret.erro;
        }
        showToast('success', 'Convite enviado com sucesso!');

        if (!redirect.current) {
          usuariosConvidarFormRef.current?.resetForm();
        }
      } catch (e: any) {
        showToast('error', e.message);
        usuariosConvidarFormRef.current?.fillForm(model);
      }
    },
    [postUsuariosConvidar, showToast],
  );

  const handleSubmit = useCallback(
    async (modelP: PerfilUsuariosConvidarFormModel) => {
      const conviteToCreate = picker<UsuariosConvidarModel>(
        modelP,
        new UsuariosConvidarModel(),
      );
      conviteToCreate.empresaId = getEmpresaSelecionada()?.Id || '';
      return postConviteUsuario(conviteToCreate);
    },
    [getEmpresaSelecionada, postConviteUsuario],
  );

  useEffect(()=>{
     setTimeout(()=>{callTour(EnumTour.FORMUSUARIOS)},500)
  },[callTour])

  return (
    <>
      {props.aberto && (
        <div id="tour-CadUsuariosForm" className={classes.root}>
          {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
          <div className={classes.content}>
            <div
              className={classNames(
                classes.contentForms,
                carregando ? classes.contentFormsLoading : undefined,
              )}
              style={{ height: '100%' }}
            >
              <FormUsuariosConvidar
                ref={usuariosConvidarFormRef}
                loading={carregando}
                showLoading={false}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
          <div className={classes.acoes}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={carregando}
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => fecharCadastroUsuario()}
                >
                  <VoltarIcon
                    tipo="BUTTON_PRIMARY"
                    fill={theme.palette.primary.main}
                  />
                  Voltar
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => usuariosConvidarFormRef.current?.submitForm()}
                >
                  <SalvarIcon tipo="BUTTON_PRIMARY" />
                  Convidar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
