import { useCallback } from 'react';
import { IdentificacaoCliente } from './components/identificacao-fragment/identificacao-fragment';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useToastSaurus } from 'services/app';

export const MovClienteIdentificacaoPage = () => {
  const { avancarFluxoMov } = useMovRota();
  const { showToast } = useToastSaurus();
  const redirecionarVenda = useCallback(async () => {
    try {
      await avancarFluxoMov();
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [avancarFluxoMov, showToast]);

  return (
    <>
      <IdentificacaoCliente
        redirecionarVenda={redirecionarVenda}
      />
    </>
  );
};
