export function convertTimeStringToNumber(timeString: string): number | null {
  const [hours, minutes] = timeString.split(':');

  if (Number.isNaN(parseInt(hours)) || Number.isNaN(parseInt(minutes))) {
    return null;
  }
  return parseInt(hours + '' + minutes);
}

export function convertNumberToTimeString(timeNumber: number | null): string {
  if (timeNumber === null) {
    return '0N:aN';
  }

  const timeString = timeNumber.toString().padStart(4, '0');
  const hours = timeString.slice(0, 2);
  const minutes = timeString.slice(2, 4);
  return `${hours}:${minutes}`;
}
