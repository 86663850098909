import * as Yup from "yup";
import { useMemo } from "react";

export const useFormCampanhaNomeValidation = () => {
  const FormYupValidationNomeCampanha = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Informe o nome da comissão.'),
      modeloApuracao: Yup.number().required('Modelo de apuração deve ser informado').typeError('Modelo de apuração deve ser informado.'),
    });
  }, []);

  return {
    FormYupValidationNomeCampanha,
  };
};
