import { create } from 'zustand';
 
interface IStateStore {
  termosAceitos: boolean;
}
 
interface ITermosStore {
  state: IStateStore;
  setTermosAceitos: (data: boolean) => void;
}
 
export const useTermosDeUsoStore = create<ITermosStore>((set) => ({
  state: {
    termosAceitos: false
  },
  setTermosAceitos(data: boolean) {
    set(() => ({
      state: {
        termosAceitos: data
      }
    }));
  }
}));