import { useEffect, useState, useCallback, useMemo } from 'react';
import { useStyles } from './produto-list-styles';
import { ProdutoListData } from './produto-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetProdutos } from 'data/api/gestao/produto/produto/get-produtos';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { produtoPageNome } from '../../produto-page';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { EnumFiltroStatusProduto } from 'views/components/form/receita/list-products/produto-list/produto-list-search-props';
import { ProdutoListSearchProps } from './produto-list-search-props';
import { Grid } from 'views/design-system';

export const MockStatus = {
  [EnumFiltroStatusProduto.ATIVO]: 'true',
  [EnumFiltroStatusProduto.DESATIVADO]: 'false',
  [EnumFiltroStatusProduto.TODOS]: '',
}

export interface ProdutoListProps {
  tipo: EnumTipoProduto
}

export const ProdutoList = (props: ProdutoListProps) => {
  const classes = useStyles();
  const { abrirCadastroProduto } = useCadastros();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getProdutos, carregando } = useGetProdutos();
  const { addHandler, removeHandler } = useEventTools()

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const location = useLocation();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<ProdutoResumidoModel>(),
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const filtros: ProdutoListSearchProps = useMemo(() => {
    const urlParams = new URLSearchParams(history.location.search)
    return {
      status: isEmpty(urlParams.get('status')) ? EnumFiltroStatusProduto.ATIVO : Number(urlParams.get('status')),
      codigo: urlParams.get('codigo') || '',
      descricao: urlParams.get('descricao') || '',
      tipo: urlParams.get('tipo') ? Number(urlParams.get('tipo')) : -1,
      termo: urlParams.get('termo') || '',
      categoria: urlParams.get('categoria') || '',
      marca: urlParams.get('marca') || '',
    }
  }, [history.location.search])

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ProdutoResumidoModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.termo)
          ? '&Generico=' + filtros.termo
          : '') +
        ('&Tipo=' + (filtros.tipo > -1 ? filtros.tipo : props.tipo) + (filtros.tipo === -1 && props.tipo === EnumTipoProduto.Produto ? `&Tipo=${EnumTipoProduto.Medicamento}` : '')) + (!isEmpty(filtros.descricao) ? `&Descricao=${filtros.descricao}` : '') +
        (!isEmpty(filtros.codigo) ? `&Codigo=${filtros.codigo}` : '') + (filtros.status !== EnumFiltroStatusProduto.TODOS ? `&ativo=${MockStatus[filtros.status]}` : '') + (!isEmpty(filtros.categoria) ? `&categoria=${filtros.categoria}` : '') + (!isEmpty(filtros.marca) ? `&marca=${filtros.marca}` : '');
      try {
        const res = await getProdutos(
          query,
          getEmpresaSelecionada()?.Id || '',
          newPage,
        );
        if (res.erro) throw res.erro;
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.termo, filtros.tipo, filtros.descricao, filtros.codigo, filtros.status, filtros.categoria, filtros.marca, props.tipo, getProdutos, getEmpresaSelecionada, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    if (!modalEditAberto && !location.pathname.includes('adicionar')) {
      search(queryStatus.page);
    }
    addHandler(AppEventEnum.ProdutoModal, modalEdit)

    return () => removeHandler(AppEventEnum.ProdutoModal, modalEdit)
  }, [addHandler, location.pathname, modalEdit, modalEditAberto, queryStatus.page, removeHandler, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    try {
      abrirCadastroProduto(id, props.tipo, history.location.pathname, true);
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <ProdutoListData
              title={produtoPageNome(props.tipo, false)}
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
