import { GerenciarCartaoConvenioProps } from "./gerenciar-cartao-convenio-props"
import { ModalHeader } from "views/components/modals/components";
import { CircularLoading } from "views/components/utils";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { AvancarIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import classNames from "classnames";
import { FormGerenciarCartaoConvenio } from "views/components/form/convenio/form-gerenciar-cartao-convenio/form-gerenciar-cartao-convenio";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { GerenciarCartaoConvenioFormModel } from "model/app/forms/convenio/gerenciar-cartao-convenio-form-model";
import { usePutCartaoConvenio } from "data/api/gestao/conta-pessoa/put-cartao-conta-pessoa";
import { usePostCartaoConvenio } from "data/api/gestao/conta-pessoa/post-cartao-conta-pessoa";
import { useGetCartaoConvenioById } from "data/api/gestao/conta-pessoa/get-cartao-conta-pessoa-by-id";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { CartaoConvenioModel } from "model/api/gestao/convenio/cartao-convenio-model";
import { picker } from "utils/picker";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";
import { toDecimal } from "utils/to-decimal";
import { isArray, isEqual } from "lodash";
import { usePutCartaoConvenioStatus } from "data/api/gestao/conta-pessoa/put-cartao-conta-pessoa-status";
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";
import { SwitchSaurus } from "views/components/controles";
import { useStyles } from "./gerenciar-cartao-convenio-styles";
import { CartaoConvenioPostModel } from "model/api/gestao/convenio/cartao-convenio-post-model";
import { toDateString } from "utils/to-date";
import { Box, Button, Grid, Typography } from "views/design-system";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";

export const GerenciarCartaoConvenio = ({ ...props }: GerenciarCartaoConvenioProps) => {
    const { showToast } = useToastSaurus();
    const { getPermissaoBoolean } = useSessaoAtual()
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const modalClasses = useModalStyles()
    const classes = useStyles()
    const { fecharCadastroCartaoConvenio, abrirConvenioResumoFaturas } = useCadastros()
    const { getEmpresaAtual, getConfigByCod } = useEmpresaAtual()
    const [cartaoFormState, setCartaoForm] =
        useState<GerenciarCartaoConvenioFormModel>(new GerenciarCartaoConvenioFormModel());
    const [cartaoState, setCartaoState] = useState<CartaoConvenioModel>(new CartaoConvenioModel())
    const limiteCartao = getConfigByCod(EnumEmpresaConfig.LimiteCreditoCartao)
    const senhaPadrao = getConfigByCod(EnumEmpresaConfig.SenhaPadrao)

    const { putCartaoConvenio, carregando: carregandoPut } = usePutCartaoConvenio()
    const { putCartaoConvenioStatus, carregando: carregandoPutStatus } = usePutCartaoConvenioStatus()
    const { postCartaoConvenio, carregando: carregandoPost } = usePostCartaoConvenio()
    const { getCartaoConvenioById, carregando: carregandoGet } = useGetCartaoConvenioById()

    const refEditForm = useRef<DefaultFormRefs<GerenciarCartaoConvenioFormModel>>(null);
    const attRef = useRef<boolean>(false)
    const refCartaoConvenioModel = useRef<CartaoConvenioModel>(
        new CartaoConvenioModel(),
    );

    const recarregarForm = useCallback((model: GerenciarCartaoConvenioFormModel) => {
        refEditForm.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(cartaoFormState);
    }, [cartaoFormState, recarregarForm]);

    const getCartaoConvenioByIdWrapper = useCallback(async () => {
        const res = await getCartaoConvenioById(props.id, props.convenioId, getEmpresaAtual()?.id ?? '');
        if (res.erro) {
            throw res.erro;
        }
        const ret = res.resultado?.data;
        let cartao: CartaoConvenioModel = ret

        if (isArray(ret)) {
            cartao = ret[0] as CartaoConvenioModel
        }

        refCartaoConvenioModel.current = cartao;
        setCartaoState(cartao)
        const cartaoConvenio = picker<GerenciarCartaoConvenioFormModel>(
            cartao,
            new GerenciarCartaoConvenioFormModel(),
        );
        cartaoConvenio.dataValidade = toDateString(new Date(cartaoConvenio.dataValidade), 'yyyy-MM-DD') ?? ''

        return cartaoConvenio
    }, [getCartaoConvenioById, props.id, props.convenioId, getEmpresaAtual]);

    const preencherTela = useCallback(async () => {
        try {
            setPreenchendoTela(true);
            if (props.id) {
                const cartaoForm = await getCartaoConvenioByIdWrapper()
                setCartaoForm(cartaoForm);
            } else {
                const dataValidade = new Date()
                dataValidade.setFullYear(dataValidade.getFullYear() + 5)
                const cartaoForm: GerenciarCartaoConvenioFormModel = {
                    ...new GerenciarCartaoConvenioFormModel(),
                    limite: limiteCartao ? toDecimal(limiteCartao) : 0,
                    senha: senhaPadrao ?? '',
                    dataValidade: toDateString(dataValidade, 'yyyy-MM-DD') ?? ''
                }
                setCartaoForm(cartaoForm)
            }
        } catch (e: any) {
            showToast('error', e.message);
        } finally {
            setPreenchendoTela(false)
        }
    }, [getCartaoConvenioByIdWrapper, limiteCartao, props.id, senhaPadrao, showToast])

    useEffect(() => {
        preencherTela()
    }, [preencherTela])

    const onCloseClick = () => {
        fecharCadastroCartaoConvenio(attRef.current)
    }

    const atualizarCartao = async (model: GerenciarCartaoConvenioFormModel, beforeModel: GerenciarCartaoConvenioFormModel) => {
        const equal = isEqual(model, beforeModel)
        if (equal) {
            showToast('info', 'Nenhuma informação alterada.')
            return
        }
        const cartaoPost = picker<CartaoConvenioPostModel>(refCartaoConvenioModel.current, new CartaoConvenioPostModel())
        const cartao = picker<CartaoConvenioPostModel>(model, cartaoPost)
        const res = await putCartaoConvenio(cartao, getEmpresaAtual()?.id ?? '')
        if (res.erro) throw res.erro

        fecharCadastroCartaoConvenio(true)
        showToast('success', 'Cartão atualizado com sucesso!')
    }

    const adicionarCartao = async (model: GerenciarCartaoConvenioFormModel) => {
        const cartao = picker<CartaoConvenioPostModel>(model, new CartaoConvenioPostModel())
        cartao.contaPessoaId = props.convenioId
        const res = await postCartaoConvenio(cartao, getEmpresaAtual()?.id ?? '')
        if (res.erro) throw res.erro
        fecharCadastroCartaoConvenio(true)

        showToast('success', 'Cartão adicionado com sucesso!')
    }

    const alterarStatus = useCallback(async () => {
        try {
            const novoStatus = cartaoState.status === EnumConvenioStatus.Ativo ? EnumConvenioStatus.Inativo : EnumConvenioStatus.Ativo
            const res = await putCartaoConvenioStatus(props.id, props.convenioId, novoStatus, getEmpresaAtual()?.id ?? '')
            if (res.erro) throw res.erro
            attRef.current = true

            showToast('success', 'Cartão ' + (novoStatus === EnumConvenioStatus.Ativo ? 'Ativado' : 'Inativado'))
            preencherTela()
        } catch (err: any) {
            showToast('error', 'Ocorreu um problema ao alterar o status. Detalhe: ' + err.message)
        }
    },[cartaoState.status, getEmpresaAtual, preencherTela, props.convenioId, props.id, putCartaoConvenioStatus, showToast])

    const visualizarSaldo = useCallback(() => {
        abrirConvenioResumoFaturas({
            pessoaId: cartaoState.pessoaId,
        })
    },[abrirConvenioResumoFaturas, cartaoState.pessoaId])

    const handleSubmit = async (model: GerenciarCartaoConvenioFormModel, beforeModel: GerenciarCartaoConvenioFormModel) => {
        try {
            if (props.id) {
                await atualizarCartao(model, beforeModel)
                return
            }
            await adicionarCartao(model)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }

    const carregando = [carregandoGet, carregandoPost, carregandoPut, carregandoPutStatus].includes(true)
    const permissaoEditarCartao = useMemo(() =>  getPermissaoBoolean(EnumCodigosPermissoes.CONVENIOCARTAO) , [getPermissaoBoolean])

   const headerMenuOptions = useCallback(() => {
        if (permissaoEditarCartao) {
            return (
                [new MenuOptionsModel(
                    (
                        <Box className={classes.menuItem}>
                            <Typography>Ativo</Typography>
                            <SwitchSaurus
                                size="small"
                                variant="DEFAULT"
                                checked={cartaoState.status === EnumConvenioStatus.Ativo}
                            />
                        </Box>
                    ),
                    <></>,
                    alterarStatus,
                ),
                new MenuOptionsModel(
                    (
                        <Box className={classes.menuItem}>
                            <Typography>Visualizar Saldo</Typography>
                            <AvancarIcon tipo="BUTTON" />
                        </Box>
                    ),
                    <></>,
                    visualizarSaldo,
                )]
            )
        } else {
            return ([
                new MenuOptionsModel(
                    (
                        <Box className={classes.menuItem}>
                            <Typography>Visualizar Saldo</Typography>
                            <AvancarIcon tipo="BUTTON" />
                        </Box>
                    ),
                    <></>,
                    visualizarSaldo,
                )
            ])
        }
    }, [alterarStatus, cartaoState.status, classes.menuItem, permissaoEditarCartao, visualizarSaldo])

    return (
        <div className={modalClasses.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <ModalHeader
                title={
                    props.id !== '' ? 'Edição de Cartão' : 'Cadastro de Cartão'
                }
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
                rightArea={
                    props.id && (
                        <MenuOptions
                            options={headerMenuOptions()}
                        />
                    )
                }
            />
            <div className={classNames(modalClasses.content, classes.content)}>
                <div
                    className={classNames(
                        modalClasses.contentForms,
                        preenchendoTela ? modalClasses.contentFormsLoading : undefined,
                    )}
                >
                    <FormGerenciarCartaoConvenio
                        ref={refEditForm}
                        onSubmit={handleSubmit}
                        showLoading={false}
                        loading={carregando}
                        tipo={props.tipo}
                    />
                </div>
                <div className={modalClasses.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                disabled={carregando}
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={onCloseClick}
                            >
                                <VoltarIcon tipo="BUTTON" />
                                Voltar
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refEditForm.current?.submitForm()
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarIcon tipo="BUTTON_PRIMARY" />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}