import { FlexProps } from './flex-props';
import { useStyles } from './flex-styles';

export const useFlexs = (props: FlexProps) => {
    const styles = useStyles();
    const getFlex = () => {
        return props.flex ? styles.flexContainer : undefined;
    }

    const getAlignItems = () => {
        return props.alignItems === 'baseline' ? styles.alignItemsBaseline :
            props.alignItems === 'center' ? styles.alignItemsCenter :
                props.alignItems === 'flex-end' ? styles.alignItemsFlexEnd :
                    props.alignItems === 'flex-start' ? styles.alignItemsFlexStart :
                        props.alignItems === 'stretch' ? styles.alignItemsStretch : undefined;
    }

    const getFlexDirection = () => {
        return props.flexDirection === 'column' ? styles.flexDirectionColumn :
            props.flexDirection === 'column-reverse' ? styles.flexDirectionColumnReverse :
                props.flexDirection === 'row' ? styles.flexDirectionRow :
                    props.flexDirection === 'row-reverse' ? styles.flexDirectionRowReverse : undefined;
    }

    const getFlexWrap = () => {
        return props.flexWrap === 'nowrap' ? styles.flexWrapNowrap :
            props.flexWrap === 'wrap' ? styles.flexWrapWrap :
                props.flexWrap === 'wrap-reverse' ? styles.flexWrapWrapReverse : undefined;
    }

    const getJustifyContent = () => {
        return props.justifyContent === 'center' ? styles.justifyContentCenter :
            props.justifyContent === 'flex-end' ? styles.justifyContentFlexEnd :
                props.justifyContent === 'flex-start' ? styles.justifyContentFlexStart :
                    props.justifyContent === 'space-around' ? styles.justifyContentSpaceAround :
                        props.justifyContent === 'space-between' ? styles.justifyContentSpaceBetween :
                            props.justifyContent === 'space-evenly' ? styles.justifyContentSpaceEvenly : undefined;
    }

    return {
        getFlex,
        getAlignItems,
        getFlexDirection,
        getFlexWrap,
        getJustifyContent
    };

}
