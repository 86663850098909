
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetCartaoConvenioById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getCartaoConvenioById = useCallback(
        (id: string, convenioId: string, empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/${convenioId}/cartao/${id}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getCartaoConvenioById,
    };
}
