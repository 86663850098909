import { isEmpty } from "lodash";
import { CancelarIcon, SATIcon, SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useStyles } from "./escaneando-comanda-styles";
import { useThemeQueries } from "views/theme";
import React, { useCallback, useEffect, useState } from "react";
import { useDevice } from "services/app/hooks/device";
import { useToastSaurus } from "services/app";
import scanAnim from 'assets/img/nfc-animation.gif'
import { EnumAcaoLeituraRfid } from "model/enums/enum-acao-leitura-rfid";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { ConfirmadoIcon } from "views/components/icons/confirmado-icon";
import { useNFC } from "services/app/hooks/nfc";
import { Box, Button, Grid, Typography } from "views/design-system";

interface EscaneandoComandaProps {
    closeEscaneando: () => void;
    confirmarComanda: (rfId: string, codComanda?: string) => Promise<void | boolean>;
    labelVoltar?: string;
    round?: boolean;
    erroExterno?: string;
    formCodigo?: boolean;
}

export const EscaneandoComanda = ({ closeEscaneando,
    confirmarComanda,
    labelVoltar,
    round,
    erroExterno,
    formCodigo }: EscaneandoComandaProps) => {

    const classes = useStyles();
    const { theme } = useThemeQueries();
    const { getComandaRfid, carregando } = useDevice();
    const { temNFC, verificarNFC } = useNFC();

    const [erro, setErro] = useState<string>('')

    const [codComanda, setCodComanda] = useState<string>('')
    const [rfid, setRfid] = useState<string>('')

    const { showToast } = useToastSaurus();

    const escanearComanda = useCallback(async () => {
        if (!temNFC) {
            setErro('Seu dispositivo não possuí NFC para ler as comandas.')
            return
        }
        setErro('')
        try {
            const rfId = await getComandaRfid(EnumAcaoLeituraRfid.ATIVAR)
            showToast('success', `Leitura realizada com sucesso.`)
            if (formCodigo) {
                setRfid(rfId)
                return
            }

            const res = await confirmarComanda(rfId)
            if (res) {
                setCodComanda('')
                setRfid('')
                escanearComanda();
            }

        } catch (e: any) {
            setErro(e)
        }
    }, [confirmarComanda, formCodigo, getComandaRfid, showToast, temNFC])

    useEffect(() => {
        verificarNFC();
        escanearComanda();
    }, [escanearComanda, verificarNFC])

    useEffect(() => {
        if (erroExterno && erroExterno.length > 0)
            setErro(erroExterno)
    }, [erroExterno])

    const cancelarScan = useCallback(async () => {
        if (!temNFC) {
            closeEscaneando()
            return
        }
        try {
            await getComandaRfid(EnumAcaoLeituraRfid.DESATIVAR)
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            closeEscaneando();
        }
    }, [closeEscaneando, getComandaRfid, showToast, temNFC])

    const comandaFoiDetectada = isEmpty(erro) && !carregando && rfid !== '';

    const clickFinalizar = useCallback(async () => {
        try {
            if (isEmpty(codComanda)) {
                showToast('error', 'Informe o código da comanda!')
                return
            }
            const sucesso = await confirmarComanda(rfid, codComanda)

            if (sucesso) {
                setCodComanda('')
                setRfid('')
                escanearComanda();
            }
        } catch (e: any) {
            setErro(e)
        }
    }, [codComanda, confirmarComanda, escanearComanda, rfid, showToast])

    return (
        <Grid container spacing={2} className={classes.container}>
            {formCodigo && <Grid item>
                <Box>
                    <TextFieldSaurus
                        label='Cód. Comanda'
                        onChange={(e: any) => setCodComanda(e.target.value)}
                        value={codComanda}
                    />
                </Box>
            </Grid>}
            <Grid item xs={12} className={classes.grow}>
                <Box flex flexDirection='column' alignItems='center' gap={8}>
                    {/* isEmpty(erro) || carregando */}
                    {(isEmpty(erro) || carregando) && !comandaFoiDetectada ? (
                        <>
                            <Typography align='center' variant='h6'>Aproxime o Cartão.</Typography>
                            <img src={scanAnim} alt='Escaneando' className={classes.scanAnim} />
                        </>
                    ) : comandaFoiDetectada ? (
                        <>
                            <ConfirmadoIcon tipo='GERAL' viewBox='0 0 138 138' style={{ width: 130, height: 138, fill: theme.palette.primary.main }} />
                            <Typography align='center'>Comanda detectada: {rfid}</Typography>

                        </>
                    ) : (
                        <>
                            <CancelarIcon tipo="GERAL" style={{ fill: theme.palette.error.main, width: 100 }} />
                            <Typography align='center'>{erro || 'Falha ao escanear comanda.'}</Typography>
                        </>
                    )}
                </Box>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            color="primary"
                            onClick={() => {
                                cancelarScan();
                            }}
                            className={round ? 'round' : ''}
                        >
                            <VoltarIcon tipo="BUTTON" />
                            {labelVoltar || 'Informar Manualmente'}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        {comandaFoiDetectada ? <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            type="submit"
                            className={round ? 'round' : ''}
                            onClick={clickFinalizar}
                        >
                            <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                            Finalizar
                        </Button> : <Button
                            variant="contained"
                            fullWidth
                            disabled={carregando || !temNFC}
                            color="primary"
                            type="submit"
                            className={round ? 'round' : ''}
                            onClick={() => escanearComanda()}
                        >
                            <SATIcon tipo="BUTTON_PRIMARY" />
                            Tentar Novamente
                        </Button>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}