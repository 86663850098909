import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    root: {
        cursor: 'default',
    },
   
    button: {
        height: '30px',
        width: '30px',
        '& svg': {
            marginRight: '0px',
        }
    }
}))