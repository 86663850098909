import { CardProdutoProps } from './card-produto-props';
import { useCallback } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from '../../../../utils/to-decimal';
import { useThemeQueries } from '../../..';
import { isEmpty } from 'lodash';
import { useStyles } from './card-produto-styles';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { TpProdutoFarmaciaMock } from 'data/mocks/tp-produto-mock';
import { useSessaoAtual } from 'services/app';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import classNames from 'classnames';
import { Box, Grid, Tooltip, Typography } from 'views/design-system';
import React from 'react';

const CardProduto = ({
  model,
  onClick,
  onCheck,
  selected,
  isButtonEdit,
}: CardProdutoProps) => {
  const classes = useDefaultCardStyles();
  const cardClasses = useStyles()
  const { theme } = useThemeQueries();
  const { plano } = useSessaoAtual();

  const planoFarmacia = isPlanoFarmaceutico(plano?.plano)

  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [model.ativo, theme.palette.error.main, theme.palette.success.main]);

  const img = (imagemUrl: string) => {
    if (imagemUrl && imagemUrl.length > 0) {
      try {
        const url = atob(imagemUrl);
        return <img src={url}
          className={classNames(classes.cardImg, cardClasses.prodImg)}
          alt={model.nome}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = SemImagem;
          }}
        />;
      } catch (err) {
        return (
          <img
            src={imagemUrl}
            className={classNames(classes.cardImg, cardClasses.prodImg)}
            alt={model.nome}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      }
    }

    return (
      <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
    );
  };

  const isTelaProduto = model.tipo === EnumTipoProduto.Produto || model.tipo === EnumTipoProduto.Medicamento
  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.produtoId);
        }}
      >
        <Tooltip
          arrow
          title={model.ativo ? 'Habilitado' : 'Desabilitado'}
          placement="right"
        >
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <Box className={cardClasses.imgContainer}>
          {img(model.imagemUrl)}
        </Box>
        <Grid container spacing={1} className={cardClasses.cardContent}>
          <Grid item xs={isTelaProduto ? 6 : 10}>
            <Box>
              <div className={cardClasses.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  Nome
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  {model.nome}
                </Typography>
              </div>
            </Box>
          </Grid>
          {isTelaProduto &&
            <>
              <Grid item xs={4}>
                <Box>
                  <div className={cardClasses.celulaGrid}>
                    <Typography color="textPrimary" variant="caption">
                      {model.tipo === EnumTipoProduto.Medicamento ? 'Laboratório' : 'Marca'}
                    </Typography>
                    <Typography
                      className={'celula-grid-value'}
                      color="textPrimary"
                      variant="body1"
                    >
                      {isEmpty(model.marcaDescricao)
                        ? (model.tipo === EnumTipoProduto.Medicamento ? 'Sem Laboratório' : 'Sem Marca')
                        : model.marcaDescricao}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <div className={cardClasses.celulaGrid}>
                    <Typography color="textPrimary" variant="caption">
                      Uni. Medida
                    </Typography>
                    <Typography
                      className={'celula-grid-value'}
                      color="textPrimary"
                      variant="body1"
                    >
                      {model.medida}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            </>
          }
          {(isTelaProduto && planoFarmacia) && (
            <Grid item xs={3}>
              <div className={cardClasses.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  Tipo
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  {TpProdutoFarmaciaMock.find(x => x.Key === model.tipo)?.Value || ''}
                </Typography>
              </div>
            </Grid>
          )}
          {isTelaProduto && <Grid item xs={planoFarmacia ? 3 : 6}>
            <div className={cardClasses.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Categoria
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.categoriaDescricao || 'Sem Categoria'}
              </Typography>
            </div>
          </Grid>}
          {isTelaProduto && (
            <Grid item xs={!isEmpty(model.ncm) ? 3 : 4}>
              <Box>
                <div className={cardClasses.celulaGrid}>
                  <Typography color="textPrimary" variant="caption">
                    Qtd de Estoque
                  </Typography>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                  >
                    {model.qtdEstoque}
                  </Typography>
                </div>
              </Box>
            </Grid>
          )}
          {!isEmpty(model.ncm) && (
            <Grid xs={1} >
              <Box>
                <div className={classNames(cardClasses.celulaGrid, cardClasses.ncm)}>
                  <Typography color="textPrimary" variant="caption">
                    NCM
                  </Typography>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                  >
                    {model.ncm}
                  </Typography>
                </div>
              </Box>
            </Grid>
          )}
          <Grid item xs={2}  >
            <Box>
              <div className={cardClasses.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  Valor de Venda
                </Typography>
                <Typography
                  className={classNames('celula-grid-value', cardClasses.bold)}
                  color="textPrimary"
                  variant="body1"
                >
                  <Tooltip
                    arrow
                    title={
                      'R$ ' +
                      toDecimalString(model.vPreco) +
                      ' a cada ' +
                      model.medidaDesc
                    }
                    placement="right"
                  >
                    <>
                      R$ {toDecimalString(model.vPreco)} {model.medida}
                    </>
                  </Tooltip>
                </Typography>
              </div>
            </Box>
          </Grid>

        </Grid>

        {!isButtonEdit && (
          <Tooltip arrow title="Editar Produto">
            <div className={classes.cardRightButton}>
              <EditarIcon tipo="BUTTON" />
            </div>
          </Tooltip>
        )}
      </DefaultCard>
    </>
  );
};

export default React.memo(CardProduto);