import { Box, Button } from "@material-ui/core"
import { CategoriaIcon, EditarIcon, FiltroIcon, LojaIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { ProdutoListSearchProps } from "../produto-list/produto-list-search-props";
import { useStyles } from './produto-pesquisa-modal-styles'
import { CaixaIcon } from "views/components/icons/caixa-icon";
import { FormPesquisaGenerico } from "views/components/form/pesquisa-generica/form-pesquisa-generico";
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { CodigoBarrasIcon } from "views/components/icons/codigo-barras-icon";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { KeyValueModel } from "model";
import { useSessaoAtual } from "services/app";
import { isPlanoFarmaceutico } from "utils/plano-utils";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { produtoPageUrl } from "../../produto-page";
import { FormPesquisaCategoria } from 'views/components/form/pesquisa-categoria/form-pesquisa-categoria';
import { FormPesquisaFabricante } from 'views/components/form/pesquisa-fabricante/form-pesquisa-fabricante';
import { EnumFiltroStatusProduto } from "views/components/form/receita/list-products/produto-list/produto-list-search-props";

export enum EnumFiltroTipoProduto {
    Produto = 0,
    Medicamento = 6
}

const MockFiltroTipo = [
    new KeyValueModel(-1, 'Todos'),
    new KeyValueModel(EnumFiltroTipoProduto.Produto, 'Produto'),
    new KeyValueModel(EnumFiltroTipoProduto.Medicamento, 'Medicamento'),
]

const MockFiltroProdutoStatus = [
    new KeyValueModel(EnumFiltroStatusProduto.TODOS, 'Todos'),
    new KeyValueModel(EnumFiltroStatusProduto.ATIVO, 'Ativo'),
    new KeyValueModel(EnumFiltroStatusProduto.DESATIVADO, 'Desativado')

]

interface ITelaState {
    tipoProduto: boolean;
    codigo: boolean;
    descricao: boolean;
    tipo: boolean;
    status: boolean;
    categoria: boolean;
    marca: boolean;
}

const PesquisaInicial: ProdutoListSearchProps = {
    status: EnumFiltroStatusProduto.ATIVO,
    termo: '',
    descricao: '',
    codigo: '',
    tipo: -1,
    categoria: '',
    marca: ''
}
interface ProdutoPesquisaModalProps {
    openned: boolean;
    filtros: ProdutoListSearchProps;
    callback?: (filtos: ProdutoListSearchProps) => {}
}
export const ProdutoPesquisaModal = ({
    openned,
    filtros,
    callback
}: ProdutoPesquisaModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { plano } = useSessaoAtual();
    const { fecharProdutoFiltroModal } = useFiltrosModais();
    const history = useHistory();

    const planoFarmacia = isPlanoFarmaceutico(plano?.plano)

    const [telas, setTelas] = useState<ITelaState>({
        tipoProduto: false,
        codigo: false,
        descricao: false,
        tipo: false,
        status: false,
        categoria: false,
        marca: false
    })
    const [localProps, setLocalProps] = useState<ProdutoListSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (Object.values(telas).some(x => x)) {
            setTelas(prev => ({
                ...prev,
                generico: false,
                codigo: false,
                descricao: false,
                tipo: false,
                status: false,
                categoria: false,
                marca: false
            }))
            return
        }
        fecharProdutoFiltroModal(false)
    }, [fecharProdutoFiltroModal, telas])

    const nomePlaceholder = () => {
        if (telas.codigo) {
            return { nome: 'codigo', label: 'Código do Produto', placeholder: 'Digite o código do produto...' }
        }
        if (telas.descricao) {
            return { nome: 'descricao', label: 'Descrição do Produto', placeholder: 'Digite a descrição do produto...' }
        }
        if (telas.categoria) {
            return { nome: 'categoria', label: 'Categoria do Produto', placeholder: 'Digite a categoria do produto...' }
        }
        if (telas.categoria) {
            return { nome: 'categoria', label: 'Categoria do Produto', placeholder: 'Digite a categoria do produto...' }
        }
        if (telas.marca) {
            return { nome: 'marca', label: 'Marca do Produto', placeholder: 'Digite o marca do produto...' }
        }
        return { nome: '', label: '', placeholder: '' }
    }

    const mostrarFiltroTipo = history.location.pathname.indexOf('/produtos') > -1 && planoFarmacia

    const onSubmit = useCallback((localProps: ProdutoListSearchProps) => {
        const searchs: Array<string | null> = [
            localProps.status ? `status=${localProps.status}` : null,
            localProps.codigo.length > 0 ? `codigo=${localProps.codigo}` : null,
            localProps.descricao.length > 0 ? `descricao=${localProps.descricao}` : null,
            localProps.tipo > -1 ? `tipo=${localProps.tipo}` : null,
            localProps.termo.length > 0 ? `termo=${localProps.termo}` : null,
            localProps.categoria?.length > 0 ? `categoria=${localProps.categoria}` : null,
            localProps.marca?.length > 0 ? `marca=${localProps.marca}` : null,
        ]

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

            if(callback) {
                callback({
                    codigo: localProps.codigo,
                    descricao: localProps.descricao,
                    status: localProps.status,
                    termo: localProps.termo,
                    tipo: localProps.tipo,
                    categoria: localProps.categoria,
                    marca: localProps.marca
                  });
            } else {
                history.push({
                    pathname: produtoPageUrl(),
                    search: query
                })
            }
        fecharProdutoFiltroModal(true)
    }, [callback, fecharProdutoFiltroModal, history])

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    <Box className={classNames(classes.container)}>
                        <CardFiltro<ProdutoListSearchProps, ITelaState>
                            icon={<EditarIcon />}
                            nome="Descrição do Produto"
                            propName="descricao"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="descricao"
                            value={localProps.descricao}
                            valorPadrao={PesquisaInicial.descricao}
                        />
                        <CardFiltro<ProdutoListSearchProps, ITelaState>
                            icon={<CodigoBarrasIcon />}
                            nome="Código do Produto"
                            propName="codigo"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="codigo"
                            value={localProps.codigo}
                            valorPadrao={PesquisaInicial.codigo}
                        />
                        {(mostrarFiltroTipo || callback) && <CardFiltro<ProdutoListSearchProps, ITelaState>
                            icon={<CaixaIcon />}
                            nome="Tipo de Produto"
                            propName="tipo"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="tipo"
                            value={MockFiltroTipo.find(x => x.Key === localProps.tipo)?.Value}
                            valorPadrao={PesquisaInicial.tipo}
                            valorAtual={localProps.tipo}
                        />}
                        <CardFiltro<ProdutoListSearchProps, ITelaState>
                            icon={<FiltroIcon />}
                            nome="Situação"
                            propName="status"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="status"
                            valorAtual={localProps.status}
                            value={MockFiltroProdutoStatus.find(x => x.Key === localProps.status)?.Value}
                            valorPadrao={PesquisaInicial.status}
                        />
                        <CardFiltro<ProdutoListSearchProps, ITelaState>
                            icon={<CategoriaIcon />}
                            nome="Categoria do Produto"
                            propName="categoria"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="categoria"
                            value={localProps.categoria}
                            valorPadrao={PesquisaInicial.categoria}
                        />
                        <CardFiltro<ProdutoListSearchProps, ITelaState>
                            icon={<LojaIcon />}
                            nome="Marca/Laboratório"
                            propName="marca"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="marca"
                            value={localProps.marca}
                            valorPadrao={PesquisaInicial.marca}
                        />
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => onSubmit({
                                status: EnumFiltroStatusProduto.ATIVO,
                                termo: '',
                                descricao: '',
                                codigo: '',
                                tipo: -1,
                                categoria: '',
                                marca: ''
                            })}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>
                        {(telas.descricao || telas.codigo) && (
                            <FormPesquisaGenerico
                                onSubmit={(model) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        [nomePlaceholder().nome]: model.generico
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        codigo: false,
                                        descricao: false,
                                        categoria: false,
                                        marca: false
                                    }))
                                }}
                                text={(localProps as any)?.[nomePlaceholder().nome] ?? ''}
                                label={nomePlaceholder().label}
                                placeholder={nomePlaceholder().placeholder}
                                showBarcodeCam={telas.codigo}
                            />
                        )}
                        {telas.tipo && (
                            <RadioList
                                handleSubmit={async (tipo) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        tipo
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        tipo: false
                                    }))
                                }}
                                mock={MockFiltroTipo}
                                selecionado={localProps.tipo}
                            />
                        )}
                        {telas.status && (
                            <RadioList
                                handleSubmit={async (status) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        status: status
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        status: false
                                    }))
                                }}
                                selecionado={localProps.status}
                                mock={MockFiltroProdutoStatus}
                            />
                        )}
                        {telas.categoria && (
                            <FormPesquisaCategoria
                                onSubmit={value => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        categoria: value.categoria
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        categoria: false
                                    }))
                                }}
                                text={localProps.categoria}
                            />
                        )}
                        {telas.marca && (
                            <FormPesquisaFabricante
                                onSubmit={value => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        marca: value.marca
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        marca: false
                                    }))
                                }}
                                text={localProps.marca}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal >
    )
}