import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  drawerResumoPerfilContainer: {
    transition: "0.2s ease-in-out margin",
    alignItems: "center",
    marginTop: 0,
    display: "flex",
    "& > *": {
      margin: "10px",
    },
    "& .fotoPerfilContainer": {
      position: 'relative',
      "& img": {
        borderRadius: "100%",
        transition: "0.2s ease-in-out width,0.2s ease-in-out height",
        objectFit: 'cover',
        width: 80,
        height: 80,
      },
      '@media (hover: hover)': {
        "&:hover .editarPerfil": {
          opacity: 1,
        }
      }
    },
    "& .editarPerfil": {
      position: 'absolute',
      top: 0,
      left: 0,
      background: 'rgba(255,255,255,0.8)',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      fontSize: '9px',
      transition: '0.2s ease-in-out opacity',
      opacity: 0,
      cursor: 'pointer',
      "& svg": {
        margin: 0,
        marginBottom: 5,
        width: 24,
        height: 24,
      }
    },
  },
  drawerPerfilCompletoNotMobile: {
    marginTop: -30,
  },
  usuarioPlano: {
    background: "rgba(255,255,255,0.7)",
    borderRadius: '100%',
    width: 32,
    height: 32,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    bottom: 0,
    "& svg": {
      width: '70%',
      height: '70%',
    }
  },
  drawerPerfilMini: {
    "& .fotoPerfilContainer": {
      "& img": {
        width: 58,
        height: 58,
      },

    },
  },
  selectEmpresa: {
    maxWidth: 230,
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '& svg': {
      fill: theme.palette.primary.main
    },
    '& ::before': {
      content: 'none'
    },
    '& ::after': {
      content: 'none'
    },
    '& :focus': {
      background: 'rgba(0,0,0,0)'
    }
  },
  labelUsuario: {
    fontSize: "10px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  containerNomeEPlano: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  usuarioNome: {
    fontWeight: 550,
    fontSize: "22px",
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  usuarioPerfil: {
    fontSize: "15px",
    fontWeight: 400,
    color: theme.palette.secondary.main,
    "& svg": {
      width: '22px',
      height: '22px',
      marginBottom: -5,
      marginRight: 5,
      fill: theme.palette.text.primary
    }
  },
}));
