
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { OkIcon, } from 'views/components/icons';
import { LoadingButton } from 'views/components/utils';
import { useThemeQueries } from 'views/theme';

import { Box, Button, Grid, Typography } from 'views/design-system';
import { Paper } from '@material-ui/core';
import { EnumTpMovimentacaoEstoque } from 'model/enums/enum-tipo-movimentacao-estoque';
import { useStyles } from '../components/form-movimentacao-produto-estoque-styles';

type Props = {
  valorEstoque: number;
  closeModal: () => void;
  carregando: boolean;
  handleSubmit: (value: number) => Promise<void>;
  depositoId: string;
  depositoNome: string;
  tipoDeOperacao: EnumTpMovimentacaoEstoque;
  descricao: string;
};
export const FormMovimentacaoProdutoEstoque = ({
  valorEstoque,
  closeModal,
  carregando,
  handleSubmit,
  depositoId,
  depositoNome,
  tipoDeOperacao,
  descricao
}: Props) => {
  const classes = useStyles()
  const [value, setValue] = useState<string>('');
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    !isMobile && inputRef.current?.focus();
  }, [isMobile, value]);

  const disabled = useCallback(() => {
    if (isEmpty(value) || carregando) {
      return true;
    }
    return false;
  }, [carregando, value]);

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        if (disabled()) return;
        if (tipoDeOperacao === EnumTpMovimentacaoEstoque.CorrigirSaldo) {
          await handleSubmit(Number(value));
        } else if (tipoDeOperacao === EnumTpMovimentacaoEstoque.Entrada) {
          await handleSubmit(valorEstoque + Number(value));
        } else if (tipoDeOperacao === EnumTpMovimentacaoEstoque.Saida) {
          await handleSubmit(valorEstoque - Number(value));
        }
        closeModal();
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível alterar o estoque do produto. Detalhe: ${e.message}`
        );
      }
    },
    [closeModal, disabled, handleSubmit, showToast, tipoDeOperacao, valorEstoque, value]
  );
  const totalAmovimentar = useCallback(() => {
    if (tipoDeOperacao === EnumTpMovimentacaoEstoque.CorrigirSaldo) {
      return Number(value) - valorEstoque;
    } else if (tipoDeOperacao === EnumTpMovimentacaoEstoque.Entrada) {
      return Number(value) + valorEstoque;
    } else if (tipoDeOperacao === EnumTpMovimentacaoEstoque.Saida) {
      return valorEstoque - Number(value);
    }
    return 0
  },[tipoDeOperacao, valorEstoque, value])

  const textoMovimentacao = useMemo(() => {
    if (tipoDeOperacao !== EnumTpMovimentacaoEstoque.CorrigirSaldo) {
      return 'Novo Saldo: ';
    } else {
      return 'Total a Movimentar: '
    }
  },[tipoDeOperacao])

  return (
    <form onSubmit={onSubmit} className={classes.formMovimentacao}>
      <Box className={classes.fieldContainer}>
        <Paper className={classes.paperCard}>
          <Grid xs={12} container spacing={1} justifyContent='center'>
            {!isEmpty(depositoId) && (
              <Grid item xs={9}>
                <Typography variant="h6" align="center" color='primary' >
                  <strong>Depósito: {depositoNome}</strong>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} flex justifyContent='center'>
              <Typography variant="body1" >
                {descricao}
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              className={classes.inputValor}
            >
              <TextFieldSaurus
                fullWidth
                tipo="NUMERO"
                className={classes.inputValorCenter}
                onChange={(e) => setValue(e.target.value)}
                variant="outlined"
                allowSubmit
                inputRef={inputRef}
                placeholder={valorEstoque?.toString()}
                value={value}
              />
            </Grid>
            <Grid xs={9} container spacing={1} justifyContent='space-around'  >
              <Grid xs={6} item>
                <Paper className={classes.paperInfo}>
                  <Typography
                    variant="body2"
                    className={classes.textInfo}
                  >
                    Saldo Atual:{' '}
                    <span className={classes.spanStyled}>
                      {valorEstoque}
                    </span>
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={6} item>
                <Paper className={classes.paperInfo}>
                  <Typography
                    variant='body2'
                    className={classes.textInfo}
                  >
                    {textoMovimentacao}
                    <span className={classes.spanStyled}>
                      {(isEmpty(value))
                        ? 0
                        : totalAmovimentar()}
                    </span>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              size="large"
              disabled={disabled()}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Confirmar Saldo
              {carregando && <LoadingButton tipo="AZUL" />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
