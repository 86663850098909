import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnDay: {
        fontSize: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        background: 'rgba(0, 0, 0, 0)',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        zIndex: 99,
        outline: 0,
        borderRadius: theme.shape.borderRadius,
        transition: '0.4s all',
        "&.selected-day": {
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
        }
    },
    calendar: {
        height: "390px",
        width: "324px",
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
        border: `2px solid ${theme.palette.primary.main}`,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: "#ffffff",
        padding: "10px",
        userSelect: "none",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
        padding: "15px",
        height: "50px",
        width: "100%",
        color: "#262626",
    },
    calendarContainer: {
        width: "100%",
        "& tr": {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            margin: "3px"
        },
        "& td": {
            display: "flex",
            height: "40px",
            width: "40px",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            zIndex: 99
        }
    },
    weekends: {
        "& th": {
            fontSize: "1em",
            color: "gray",
            fontWeight: "400",
            width: "40px",
            margin: "10px 0"
        }
    },
    valueDIsplayContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "324px",
        "& > div": {
            borderTop: 'none',
            padding: theme.spacing(1),
            width: '100%',
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            border: `2px solid ${theme.palette.primary.main}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            transition: '0.2s all',
            height: '50px',
            "&:hover": {
                cursor: "pointer",
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
            }
        },
        "& > div:nth-child(1)": {
            borderRadius: `0px 0px 0px ${theme.shape.borderRadius}px`,
        },
        "& > div:nth-child(2)": {
            borderRadius: `0px 0px ${theme.shape.borderRadius}px 0px`,
            borderLeft: 'none'
        },
    }
}));
