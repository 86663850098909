import { useState, useCallback, useMemo } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { FinalizadoraHeader } from './components/finalizadora-header/finalizadora-header';
import { FinalizadoraList } from './components/finalizadora-list/finalizadora-list';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { FinalizadoraListSearchProps } from './components/finalizadora-list/finalizadora-list-search-props';
import { useCadastros } from 'services/app';
import { Box } from 'views/design-system';

export const FinalizadoraPage = () => {
  const classes = useDefaultCadastroStyles();
  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<FinalizadoraListSearchProps>({
    termo: '',
    status: 1,
  });
  const { abrirCadastroFinalizadora } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroFinalizadora('', '', true);
  }, [abrirCadastroFinalizadora]);

  const component = useMemo(() => <Box className={classes.root}>
    <Box className={classes.header}>
      <FinalizadoraHeader
        openPesquisa={openPesquisa}
        setSearchProps={setSearchProps}
        setOpenPesquisa={setOpenPesquisa}
      />
    </Box>
    <Box className={classes.list}>
      <FinalizadoraList searchProps={searchProps} />
    </Box>
    <ButtonFab
      tooltip="Adicionar Forma de Pagamento"
      icon={<NovoIcon tipo="BUTTON_FAB" />}
      onClick={onClickAdd}
    />
  </Box>, [classes.header, classes.list, classes.root, onClickAdd, openPesquisa, searchProps]);

  return component;
};

export default FinalizadoraPage;
