import { DefaultCard, useDefaultCardStyles } from "views/components/cards/components"
import { CardPagamentosProps } from "./card-pagamentos-props";
import { toDecimalString } from "utils/to-decimal";
import { useCallback } from "react";
import { CancelarIcon, OkIcon } from "views/components/icons";
import { useStyles } from "./card-pagamentos-styles";
import { useThemeQueries } from "views/theme";
import { TpModMock } from "data/mocks";
import { EnumHistoricoVendaStatus } from "model/enums/enum-historico-venda-status";
import { Grid, Typography } from "views/design-system";

export const CardPagamentos = ({
    pagamento,
}: CardPagamentosProps) => {

    const classes = useDefaultCardStyles();
    const classesPagamentos = useStyles();
    const { theme } = useThemeQueries();

    const retornarIconeStatus = useCallback(() => {
        switch (pagamento.status) {
            case EnumHistoricoVendaStatus.Abortado:
            case EnumHistoricoVendaStatus.Pendente:
            case EnumHistoricoVendaStatus.Cancelado:
            case EnumHistoricoVendaStatus.EmDigitacao:
            case EnumHistoricoVendaStatus.Rejeitado:
                return <CancelarIcon tipo="BUTTON" fill={theme.palette.text.primary} />
            case EnumHistoricoVendaStatus.Finalizado:
                return <OkIcon tipo="BUTTON" fill={theme.palette.text.primary} />
        }
    }, [pagamento.status, theme.palette.text.primary])

    const labelText = TpModMock.filter((x) => x?.Key === pagamento?.modPagamento)[0]?.Value;

    return (
        <DefaultCard
            className={classesPagamentos.containerRoot}
        >
            <div className={classes.cardContent}>
                <div className={classes.celulaGrid}>
                    <Grid container spacing={2}>
                        <Grid item className={classesPagamentos.containerIcon}>
                            {retornarIconeStatus()}
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Forma de Pagamento
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                {labelText}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.celulaGrid}>
                    <Typography
                        variant='caption'
                        color="textPrimary"
                    >
                        Valor Total
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {`R$ ${toDecimalString(pagamento.vVenda)}`}
                    </Typography>
                </div>
            </div>
        </DefaultCard>
    )
}