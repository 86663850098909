import { useGetFaturaConvenioCompetencias } from "data/api/gestao/conta-pessoa"
import { AnoMesListProps } from "./ano-mes-list-props"
import { useStyles } from "./ano-mes-list-styles"
import { useCallback, useEffect, useRef, useState } from "react"
import { useToastSaurus } from "services/app"
import { KeyValueModel } from "model"
import { guidEmpty } from "utils/guid-empty"
import { CircularLoading } from "views/components/utils"
import { Box, Button, Typography } from "views/design-system"
import { isEmpty } from "lodash"

export const AnoMesList = ({ onClick, anoMesAtual, pessoaId, empresaId, setCarregandoInterno }: AnoMesListProps) => {

    const classes = useStyles()
    const scrollContainer = useRef<HTMLDivElement>(null);
    const { getFaturaConvenioCompetencias, carregando } = useGetFaturaConvenioCompetencias()
    const [listaAnoMes, setListaAnoMes] = useState<KeyValueModel[]>([])
    const { showToast } = useToastSaurus()

    const getCompetenciasWrapper = useCallback(async () => {
        if (!pessoaId || pessoaId === guidEmpty()) return
        try {
            const list: KeyValueModel[] = []
            const res = await getFaturaConvenioCompetencias({
                pessoaId,
                empresaId
            })
            if (res.erro) throw res.erro
            const resultado = res.resultado?.data as number[]

            resultado.forEach((anoMes) => {
                const dataAFormatar = new Date()
                const anoMesString = anoMes.toString()
                const getMes = anoMesString.slice(4)
                const getAno = anoMesString.slice(0, anoMesString.length - 2)
                dataAFormatar.setMonth(Number(getMes) - 1)
                dataAFormatar.setFullYear(Number(getAno))
                const mes = dataAFormatar.toLocaleString('pt-BR', { month: 'long' });
                const novoAnoMes = new KeyValueModel(anoMes, mes.charAt(0).toUpperCase() + mes.slice(1) + '/' + getAno)
                list.push(novoAnoMes)
            })
            if (list.length > 0 && !list.some(x => x?.Key === anoMesAtual)) {
                const firstAnoMes = list?.[0].Key
                firstAnoMes !== anoMesAtual && onClick(firstAnoMes)
            }
            setListaAnoMes(list)
        } catch (err: any) {
            showToast('error', err.message)
        } finally {
            setCarregandoInterno(false)
        }
    }, [pessoaId, getFaturaConvenioCompetencias, empresaId, anoMesAtual, onClick, showToast, setCarregandoInterno])

    useEffect(() => {
        getCompetenciasWrapper()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft = scrollContainer.current.scrollWidth;
        }
    }, [])

    return (
        <div className={classes.container} ref={scrollContainer}>
            <div className={classes.content}>
                {!carregando && listaAnoMes && listaAnoMes.map((value) => {
                    const isSelected = value.Key === anoMesAtual
                    const [mes, ano] = value.Value.split('/')
                    return (
                        <Box pr={2}>
                            <Button onClick={() => onClick(value.Key)} className={classes.btnAnoMes} variant={isSelected ? "contained" : "outlined"} color="primary" size="small">
                                <span>{mes}</span>
                                <span className={classes.barra}>/</span>
                                <span>{ano}</span>
                            </Button>
                        </Box>
                    )
                })}
                {(carregando || isEmpty(listaAnoMes)) && (
                    <div className={classes.naoExiste}>
                        {carregando && <CircularLoading tipo="NORMAL" class={classes.loading} />}
                        {!carregando && isEmpty(listaAnoMes) && (
                            <Typography>
                                Não existe competência para este convênio.
                            </Typography>
                        )}
                    </div>
                )}
            </div>
            <div />
        </div>
    )
}