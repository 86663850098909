import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import {
  CampanhaNomeFormModel,
  FormCampanhaNome
} from './components/form-nome-campanha';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useComissaoStore } from 'views/components/modals/comissao/comissao-modal/stores/comissao-store';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';

interface FormNomeCampanhaProps {
  nextStep: () => void;
}

export interface RefPropFormNomeCampanha {
  submit: () => void;
}

export const FormNomeCampanha = forwardRef<
  RefPropFormNomeCampanha,
  FormNomeCampanhaProps
>(({ nextStep }: FormNomeCampanhaProps, ref) => {
  const refCadastroNomeCampanhaRef =
    useRef<DefaultFormRefs<CampanhaNomeFormModel>>(null);

  const comissao = useComissaoStore((state) => state.state.comissao);
  const setComissaoStep1 = useComissaoStore((state) => state.setComissaoStep1);
  const { getEmpresaAtual } = useEmpresaAtual();

  const recarregarForm = useCallback((model: CampanhaNomeFormModel) => {
    refCadastroNomeCampanhaRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    if (comissao.nome.length > 0) {
      recarregarForm({
        nome: comissao.nome,
        modeloApuracao: comissao.modeloApuracao
      });
    }
  }, [comissao.modeloApuracao, comissao.nome, recarregarForm]);

  function submit(model: CampanhaNomeFormModel) {
    if (model.modeloApuracao !== null) {
      setComissaoStep1({
        nome: model.nome,
        modeloApuracao: model.modeloApuracao,
        empresaId: getEmpresaAtual()?.id ?? ''
      });
    }

    nextStep();
  }

  useImperativeHandle(ref, () => ({
    submit: () => {
      refCadastroNomeCampanhaRef.current?.submitForm();
    }
  }));

  return (
    <FormCampanhaNome
      showLoading={false}
      loading={false}
      onSubmit={submit}
      ref={refCadastroNomeCampanhaRef}
    />
  );
});
