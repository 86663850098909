import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
        marginTop: '60px',
    },
    paperTermos: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2, 1),
        margin: theme.spacing(1),
    },
    switchTermos: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    switchTermosText: {
        marginRight: theme.spacing(2)
    },
}));
