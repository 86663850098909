import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useRef, useState } from "react"
import { AvancarIcon, InformacaoIcon, NovoIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useCadastros, useToastSaurus } from "services/app"
import { useStyles } from "./doc-referenciados-styles"
import classNames from "classnames"
import { CardDocReferenciado } from "views/components/cards/card-doc-referenciado"
import { useEventTools } from "services/app/hooks/events/event-tools"
import { AppEventEnum } from "model/enums/enum-app-event"
import { isEmpty } from "lodash"
import { CardNaoEncontrado } from "views/components/cards"
import { newGuid } from "utils/new-guid"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"
import { Box, Button, Grid } from "views/design-system"
import { Container } from "@material-ui/core"

const DocReferenciadosPage = () => {

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const { abrirDocReferenciadoCadastro } = useCadastros()
    const { addHandler, removeHandler } = useEventTools()
    const firstEnterPageRef = useRef<boolean>(true)

    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const { getVendaCompleta, carregando } = useEntrada(mov)
    const [modalState, setModalState] = useState<{ open: boolean, att: boolean }>({
        open: false,
        att: false
    })

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        try {
            let movAtual = new VendaCompletaModel()
            if (location.state?.mov && !att) {
                movAtual = location.state.mov
            } else {
                movAtual = await getVendaCompleta(movId)
            }
            setMov({
                ...movAtual,
                infMov: {
                    ...movAtual.infMov,
                    nFref: movAtual.infMov.nFref.map(x => ({
                        ...x,
                        id: newGuid()
                    }))
                }
            })
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getVendaCompleta, location.state?.mov, movId, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                search: location.search,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.search, location.state, mov, movId]
    );

    const modalDoc = ({ openned, att }: any) => {
        setModalState({ open: openned, att: att })
    }

    useEffect(() => {
        if ((!modalState.open && modalState.att) || firstEnterPageRef.current) {
            getInfoVenda(modalState.att)
            firstEnterPageRef.current = false
        }

        addHandler(AppEventEnum.DocReferenciadoModal, modalDoc)

        return () => removeHandler(AppEventEnum.DocReferenciadoModal, modalDoc)
    }, [addHandler, getInfoVenda, modalState.att, modalState.open, removeHandler])

    const handleClickEdit = (id: string) => {
        abrirDocReferenciadoCadastro(structuredClone(mov), id)
    }

    const handleClickNovo = () => {
        abrirDocReferenciadoCadastro(structuredClone(mov), '')
    }

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Documentos Referenciados"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <Box >
                            {isEmpty(mov.infMov.nFref) && (
                                <Box mb={2}>
                                    <CardNaoEncontrado
                                        mensagem={
                                            "Nenhum documento referenciado encontrado"
                                        }
                                        icon={<InformacaoIcon tipo="GERAL" />}
                                    />
                                </Box>
                            )}
                            {mov.infMov.nFref && mov.infMov.nFref.map((x, index) => {
                                const key = `doc-${index}-referenciado`
                                return (
                                    <CardDocReferenciado
                                        key={key}
                                        model={x}
                                        onClick={handleClickEdit}
                                    />
                                )
                            })}
                            <Box mt={2}>
                                <Button fullWidth size="large" onClick={handleClickNovo} variant="outlined" color="primary">
                                    <NovoIcon tipo="BUTTON" />
                                    Adicionar Documento
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/info-complementares`,
                                        search: location.search,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/info-pedido-compra`,
                                        search: location.search,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid >
    )
}

export default DocReferenciadosPage