import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeIpiProps } from "./form-pe-ipi-props"
import { Controller } from "react-hook-form"
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { CstIpiMock } from "data/mocks/cst-ipi-mock"
import { useState } from "react"
import { ProdutoEntradaFormModel } from "model/app/forms/entrada/produto-entrada-form-model"
import { Grid } from "views/design-system"

export const FormPeIpi = ({ control, formState, setValue, getValues, ...props }: FormPeIpiProps) => {

    const { errors } = formState
    const [, setAtt] = useState<boolean>()
    const todosCampos = ["ipi.vBC", "ipi.pIPI", "ipi.qUnid", "ipi.vUnid", "ipi.vIPI", "ipi.clEnq", "ipi.cnpjProd", "ipi.cSelo", "ipi.qSelo", "ipi.cEnq"]
    const produtoPadrao = new ProdutoEntradaFormModel()
    const camposHabilitados = (value?: number) => ({
        0: todosCampos,
        1: ["ipi.clEnq", "ipi.cnpjProd", "ipi.cSelo", "ipi.qSelo", "ipi.cEnq"],
        2: ["ipi.clEnq", "ipi.cnpjProd", "ipi.cSelo", "ipi.qSelo", "ipi.cEnq"],
        3: [],
        4: [],
        5: [],
        49: [],
        50: [],
        51: [],
        52: [],
        53: [],
        54: [],
        55: [],
        99: todosCampos,
    })[value || getValues('ipi.cst')] || []

    const manterHabilitado = (name: string) => camposHabilitados().filter(x => x === name).length === 0

    const resetaCampos = (value: number) => {
        const camposString = camposHabilitados(value).map(y => y.split('.')[1])
        const objIcms = Object.entries(produtoPadrao.ipi).filter(x => !camposString.includes(x[0]))
        const naoRemover = ['cst']
        objIcms.forEach(x => {
            if (!naoRemover.includes(x[0])) {
                if (x?.[1] !== undefined) {
                    setValue((`ipi.${x[0]}`) as any, x[1])
                }
            }
        })
    }

    return (
        <AccordionSaurus
            labelPrimary="IPI"
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.cst"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                disabled={props.loading}
                                conteudo={CstIpiMock}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="CST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.cst && errors.ipi.cst.message)}
                                helperText={
                                    errors.ipi?.cst
                                        ? errors.ipi?.cst?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = CstIpiMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("ipi.cst", value)
                                    resetaCampos(value)
                                    setAtt(prev => !prev)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.vBC"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.vBC && errors.ipi.vBC.message)}
                                helperText={
                                    errors.ipi?.vBC
                                        ? errors.ipi?.vBC?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.pIPI"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota IPI"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.pIPI && errors.ipi.pIPI.message)}
                                helperText={
                                    errors.ipi?.pIPI
                                        ? errors.ipi?.pIPI?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.qUnid"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Quantidade de B.C."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.qUnid && errors.ipi.qUnid.message)}
                                helperText={
                                    errors.ipi?.qUnid
                                        ? errors.ipi?.qUnid?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.vUnid"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor Unitário"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.vUnid && errors.ipi.vUnid.message)}
                                helperText={
                                    errors.ipi?.vUnid
                                        ? errors.ipi?.vUnid?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.vIPI"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor IPI"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.vIPI && errors.ipi.vIPI.message)}
                                helperText={
                                    errors.ipi?.vIPI
                                        ? errors.ipi?.vIPI?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="ipi.clEnq"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="TEXTO"
                                maxTexto={5}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Classe de Enquadramento"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.clEnq && errors.ipi.clEnq.message)}
                                helperText={
                                    errors.ipi?.clEnq
                                        ? errors.ipi?.clEnq?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="ipi.cnpjProd"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="CNPJ_CPF"
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="CNPJ do Produto"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.cnpjProd && errors.ipi.cnpjProd.message)}
                                helperText={
                                    errors.ipi?.cnpjProd
                                        ? errors.ipi?.cnpjProd?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.cSelo"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="TEXTO"
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Código Selo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.cSelo && errors.ipi.cSelo.message)}
                                helperText={
                                    errors.ipi?.cSelo
                                        ? errors.ipi?.cSelo?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.qSelo"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="NUMERO"
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Quantidade Selo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.qSelo && errors.ipi.qSelo.message)}
                                helperText={
                                    errors.ipi?.qSelo
                                        ? errors.ipi?.qSelo?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ipi.cEnq"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="NUMERO"
                                maxTexto={3}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Enquadramento"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ipi?.cEnq && errors.ipi.cEnq.message)}
                                helperText={
                                    errors.ipi?.cEnq
                                        ? errors.ipi?.cEnq?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}