import { Paper, useTheme } from '@material-ui/core';
import { CardDashboardChartCategoriasProps } from '../card-dashboard-chart-props';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { Box, Divider, Grid, Typography } from 'views/design-system';

export const CardDashboardChartCategorias = ({
  model,
  carregando
}: CardDashboardChartCategoriasProps) => {
  const utilClasses = useStyles();
  const theme = useTheme();

  let modUnicos = new Map(model.map((obj) => [obj.categoria, obj]));

  const modsUnicosList = Array.from(modUnicos.values());

  let categoriasSerelizados = modsUnicosList.map((item) => {
    return {
      ...item,
      vVenda: 0,
      qCom: 0,
    };
  });

  categoriasSerelizados = categoriasSerelizados.map((item) => {
    model.map((i) => {
      if (i.categoria === item.categoria) {
        let novaQuantidade = (item.vVenda += i.vVenda);
        let novaQTDE = (item.qCom += i.qCom);
        return {
          ...item,
          vVenda: novaQuantidade,
          qCom: novaQTDE,
        };
      }
      return i;
    });
    return item;
  });

  const totalVendas = model.reduce((a, b) => a + b.vVenda, 0);

  return (
    <>
      <Paper className={utilClasses.cardContainer}>
        <Grid container >
          <Grid item xs={12} p={1}>
            <Typography
              color="primary"
              weight={500}
            >
              Top 10 Categorias
            </Typography>
            <Divider variant='fullWidth'/>
          </Grid>
         
          <Grid item xs={12} flex flexDirection='column' my={2}>
            {categoriasSerelizados.length === 0 ? (
              <Typography variant="body1" align="center">
                Não há dados para exibir
              </Typography>
            ) : (
              <>
                {categoriasSerelizados
                  .sort((a, b) => b.vVenda - a.vVenda)
                  .filter((item, index) => index < 10)
                  .map((item, index) => {
                    let dinheiro = item.vVenda;
                    let vendas = item.qCom;

                    const porcentagem = (
                      (dinheiro * 100) /
                      totalVendas
                    ).toFixed(0);

                    const options = {
                      chart: {
                        sparkline: {
                          enabled: true,
                          offsetY: -20,
                        },
                      },
                      plotOptions: {
                        radialBar: {
                          hollow: {
                            size: '65%',
                          },
                          dataLabels: {
                            name: {
                              show: true,
                              offsetY: 5,
                              fontSize: '14px',
                              fontWeight: 700,
                              color: '#666',
                              formatter: function (val: any) {
                                return `R$ ${val}`;
                              },
                            },
                            value: {
                              show: false,
                            },
                          },
                        },
                      },
                      labels: [String(toDecimalString(dinheiro, 2))],
                      colors: [
                        '#' +
                        ((Math.random() * 0xffffff) << 0)
                          .toString(16)
                          .padStart(6, '0'),
                      ],
                    };

                    return (
                      <Paper
                        key={index}
                        className={classNames('card')}
                        style={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <Box px={2} flex justifyContent='space-between' alignItems='center'>
                        
                            <Typography
                              style={{ fontWeight: 600, fontSize: '1.2rem' }}
                            >
                              {index + 1}°{' '}
                              {item.categoria !== null
                                ? item.categoria
                                : 'Sem categoria'}
                            </Typography>
                            <Typography variant="body2">
                              {vendas < 2
                                ? `${vendas} Produto vendido`
                                : `${vendas} Produtos vendidos`}
                            </Typography>
                          <div>
                            {VariaveisAmbiente.paymentDevice === undefined ? (
                              <Box py={1}>
                                <Typography
                                align='right'
                                weight={600}
                                >
                                  R$ {String(toDecimalString(dinheiro, 2))}
                                </Typography>
                                <Typography align='right' weight={500}
                                >
                                  {toDecimalString(porcentagem,2)}%
                                </Typography>
                              </Box>
                            ) : (
                              <Chart
                                options={options}
                                series={[Number(porcentagem)]}
                                type="radialBar"
                                height={130}
                                width={130}
                              />
                            )}
                          </div>
                        </Box>
                        {VariaveisAmbiente.paymentDevice === undefined ? (
                          <div
                            style={{
                              width: `${porcentagem}%`,
                              backgroundColor: `#${(
                                (Math.random() * 0xffffff) <<
                                0
                              )
                                .toString(16)
                                .padStart(6, '0')}`,
                              height: 5,
                              zIndex: '-1000',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Paper>
                    );
                  })}
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
