
import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { CardHistoricoMovimentosEstoque } from "../card-historico-movimentacoes-de-estoque";
import { MovimentacaoEstoqueModel } from "model/api/gestao/movimentacao-estoque/movimentacao-estoque-model";

export interface MovimentacoesEstoqueProps {
    list: Array<MovimentacaoEstoqueModel>;
    carregando: boolean;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}


export const MovimentacoesEstoqueListData = ((props: MovimentacoesEstoqueProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhuma Movimentação de estoque localizada." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            
            {props.list.length > 0 && props.list.map((movimentacao, index) => {
                return (
                    <CardHistoricoMovimentosEstoque
                        selected={false}
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={movimentacao}
                        key={index}
                    />
                );
            })}
        </>
    );
}
);
