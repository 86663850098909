import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeIiProps } from "./form-pe-ii-props"
import { Controller } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { useState } from "react"
import { SwitchSaurus } from "views/components/controles"
import { IiModel } from "model/api/gestao/venda/venda-completa-model"
import { isEqual } from "lodash"
import { Box, Grid } from "views/design-system"

export const FormPeIi = ({ control, formState, setValue, getValues, ...props }: FormPeIiProps) => {

    const iiPadrao = new IiModel()
    const ii = getValues('ii')

    const iiEqual = isEqual(ii, iiPadrao)

    const { errors } = formState
    const [disabled, setDisabled] = useState<boolean>(iiEqual)
    const [openned, setOpenned] = useState<boolean>(false)

    const handleDisabled = () => {
        setDisabled(prev => {
            if (!prev === true) {
                setValue('ii', new IiModel())
                return !prev
            }
            return !prev
        })
    }

    return (
        <AccordionSaurus
            labelPrimary="II"
            expanded={openned}
            betweenTitles
            labelSecondary={(
                <Box flex justifyContent="flex-end" mr={1}>
                    <SwitchSaurus
                        variant="LIGHT"
                        checked={!disabled}
                        onClick={() => {
                            if (openned) {
                                setOpenned(prev => !prev)
                            }
                            handleDisabled()
                        }}
                    />
                </Box>
            )}
            onChange={() => setOpenned(prev => !prev)}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="ii.vBC"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ii?.vBC && errors.ii.vBC.message)}
                                helperText={
                                    errors.ii?.vBC
                                        ? errors.ii?.vBC?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ii.vDespAdu"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor Despesas Aduaneiras"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ii?.vDespAdu && errors.ii.vDespAdu.message)}
                                helperText={
                                    errors.ii?.vDespAdu
                                        ? errors.ii?.vDespAdu?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ii.vII"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor do II"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ii?.vII && errors.ii.vII.message)}
                                helperText={
                                    errors.ii?.vII
                                        ? errors.ii?.vII?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="ii.vIOF"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor do IOF"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.ii?.vIOF && errors.ii.vIOF.message)}
                                helperText={
                                    errors.ii?.vIOF
                                        ? errors.ii?.vIOF?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}