import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    display: 'flex',
    flexDirection:'column',
    position: "relative",
    width: "100%",
    overflow: "hidden",
    height: '100%',
    paddingTop: theme.spacing(2),
  },
  listContainer: {
    flexGrow: 1,
    width:'100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  
  info:{
    marginLeft: theme.spacing(1)
  },
  paperInfo:{
    padding: theme.spacing(2, 3),
  },
  paginacao:{
    padding: theme.spacing(0, 1)
  }
}));
