import {
    EmitModel,
    PagsModel,
    ProdsModel,
} from './../../../model/api/gestao/venda/venda-completa-model';
import { TabelaVenda } from 'database/interfaces/interface-tabela-vendas';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { VendaModel } from 'model/api/gestao/venda/venda-model';
import { useCallback } from 'react';
import { newGuid } from 'utils/new-guid';
import { useGestaoToken } from '../providers/gestao-token';
import { useToastSaurus } from './toast-saurus';
import { EnumIdeStatus } from 'model/enums/enum-ide-status';
import { useGenerateIdeId } from './generateIde_id';
import { EnumMovModelo } from 'model';
import { TouchoneBacupkup } from 'database/touchone-backup';
import { useSessaoAtual } from '../providers';
import { EnumCRT } from 'model/enums/enum-crt';

export function useVenda() {
    // HOOKS
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { getEmpresaAtual } = useGestaoToken();
    const { ObterChave } = useGenerateIdeId();

    // AUX
    const { showToast } = useToastSaurus();

    const getVendas = useCallback(async () => {
        try {
            let todasAsVendas: TabelaVenda[] = [];

            const vendasAtuais = await TouchoneDBPrimary.vendas
                .filter((venda) => venda.empresaId === getEmpresaSelecionada()!.Id && !venda.dataSincronizacao)
                .toArray();

            const vendasAntigas = await TouchoneBacupkup.vendas
                .filter((venda) => venda.empresaId === getEmpresaSelecionada()!.Id && !venda.dataSincronizacao)
                .toArray();

            todasAsVendas = [...vendasAtuais, ...vendasAntigas];

            if (todasAsVendas.length === 0) {
                return [];
            }

            return todasAsVendas;
        } catch (e: any) {
            return undefined;
        }
    }, [getEmpresaSelecionada]);

    const getVenda = useCallback(
        async (id: string) => {
            try {
                const venda = await TouchoneDBPrimary.vendas.get({
                    id: id,
                    empresaId: getEmpresaSelecionada()?.Id,
                });
                return venda;
            } catch (e: any) {
                showToast('error', `Erro ao buscar venda.`);
                return undefined;
            }
        },
        [getEmpresaSelecionada, showToast],
    );

    const setVenda = useCallback(async (venda: VendaModel, dataSincronizacao: string = '') => {
        const vendaToSave = {
            ...venda,
            id: newGuid(),
            dataSincronizacao,
            cliente: venda.cliente,
            status: EnumIdeStatus.FINALIZADO,
            vNF: venda.vNF,
        };

        await TouchoneDBPrimary.vendas.add(vendaToSave);

        return vendaToSave;
    }, []);

    const atualizarVendaSinc = useCallback(
        async (vendaId: string) => {
            try {
                const venda = await TouchoneDBPrimary.vendas.get({ id: vendaId });

                if (!venda) {
                    const vendaAntiga = await TouchoneBacupkup.vendas.get({ id: vendaId });

                    if (!vendaAntiga) {
                        return true;
                    }

                    await TouchoneBacupkup.vendas.delete(vendaAntiga.idIndexed!);
                    return true;
                }

                await TouchoneDBPrimary.vendas.put({ ...venda, dataSincronizacao: new Date() });

                return true;
            } catch (err: any) {
                showToast('error', err.message);
                return true;
            }
        },
        [showToast],
    );

    const cancelarVenda = useCallback(
        async (id: string) => {
            try {
                const venda = await getVenda(id);

                if (!venda) {
                    throw new Error('Não foi possível identificar a venda.');
                }

                await TouchoneDBPrimary.vendas.put({
                    ...venda,
                    status: EnumIdeStatus.CANCELADO,
                });

                return true;
            } catch (e: any) {
                showToast('error', e.message);
                return false;
            }
        },
        [getVenda, showToast],
    );

    const converterParaModeloDeImpressao = useCallback(
        async (venda: TabelaVenda) => {
            let vendaAtual: VendaCompletaModel = new VendaCompletaModel();
            const empresaAtual = getEmpresaAtual();

            vendaAtual.infMov.mod = venda.mod;
            vendaAtual.infMov.serie = venda.serie;
            vendaAtual.infMov.nnf = venda.nnf;
            vendaAtual.status = venda.status;
            vendaAtual.infMov.dhEmi = String(venda.dEmi);
            vendaAtual.infMov.infCpl = '';
            vendaAtual.infMov.idNFe = venda.mod === EnumMovModelo.NFCE ? ObterChave(empresaAtual?.uf ?? '', new Date(venda.dEmi), empresaAtual!.cpfcnpj, venda.mod, venda.serie, venda.nnf, venda.tpEmis, 11914993).chave : ''
            vendaAtual.infMov.vAcresc = venda.vAcresc;
            vendaAtual.infMov.vDesc = venda.vDesc;

            // EMIT
            vendaAtual.infMov.emit = {
                ...new EmitModel(),
                id: empresaAtual?.id ?? '',
                xFant: empresaAtual?.nomeFantasia ?? '',
                xLgr: empresaAtual?.logradouro ?? '',
                nro: empresaAtual?.numero ?? '',
                xCpl: empresaAtual?.complemento ?? '',
                xBairro: empresaAtual?.bairro ?? '',
                xMun: empresaAtual?.municipio ?? '',
                cMun: empresaAtual?.cMunicipio ? Number(empresaAtual?.cMunicipio) : 0,
                doc: empresaAtual?.cpfcnpj ?? '',
                ie: empresaAtual?.ierg ?? '',
                fone: empresaAtual?.fone ?? '',
                uf: empresaAtual?.uf ?? '',
                empresaId: empresaAtual?.id ?? '',
                xNome: empresaAtual?.razaoSocial ?? '',
                cep: empresaAtual?.cep ?? '',
                cnae: empresaAtual?.cnae ?? '',
                cPais: 0,
                crt: EnumCRT.DEFAULT,
                ideId: venda.mod === EnumMovModelo.NFCE || venda.mod === EnumMovModelo.NFE ? ObterChave(empresaAtual?.uf ?? '', new Date(venda.dEmi), empresaAtual!.cpfcnpj, venda.mod, venda.serie, venda.nnf, venda.tpEmis, 11914993).chave : null,
            };

            // DEST
            vendaAtual.infMov.dest = {
                ...vendaAtual.infMov.dest,
                documento: venda.cliente?.cpfcnpj ?? venda.documento,
                email: '',
                fone: '',
                id: venda.pessoaId,
                xNome: venda.cliente?.nome ?? 'CONSUMIDOR',
            };

            // PRODUTOS
            vendaAtual.infMov.prod = venda.produtos.map((produto, index) => {
                return {
                    id: produto.id,
                    vendedorId: produto.vendedorId,
                    produtoGradeId: produto.produtoGradeId,
                    nItem: index + 1,
                    cProd: produto.cProd,
                    cEan: produto.cean,
                    xProd: produto.xProd,
                    qCom: produto.qCom,
                    uCom: produto.uCom,
                    vUnCom: produto.vUnCom,
                    vProd: produto.vProd,
                    vDesc: produto.vDesc,
                    infAdProd: produto.infAdic,
                    vOutro: produto.vOutro,
                    vFrete: produto.vFrete,
                    vSeg: produto.vSeg,
                    cean: produto.cean,
                    cancelado: produto.cancelado,
                    indFin: produto.indFin
                } as unknown as ProdsModel;
            });

            const pagamentos = await TouchoneDBPrimary.finalizadoras.toArray();
            // PAGAMENTOS
            vendaAtual.infMov.pag = venda.pags.map((pagamento) => {
                const pag = pagamentos.find((pag) => pag.id === pagamento.pagamentoId);

                return {
                    id: pagamento.id,
                    cancelado: pagamento.cancelado,
                    descricao: pag?.descricao ?? 'Não identificado',
                    vTroco: pagamento.vTroco,
                    vPag: pagamento.vPag,
                    tPag: pag?.tpMod ?? 1,
                    viaConsumidor: pagamento.viaConsumidor,
                    viaEstabelecimento: pagamento.viaEstabelecimento,
                    qtdeParcela: pagamento.qtdeParcela,
                    pagamentoId: pagamento.pagamentoId,
                    tefId: pagamento.tefId
                } as unknown as PagsModel;
            });

            return vendaAtual;
        },
        [ObterChave, getEmpresaAtual],
    );

    return {
        getVenda,
        getVendas,
        setVenda,
        cancelarVenda,
        atualizarVendaSinc,
        converterParaModeloDeImpressao,
    };
}
