import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "views/design-system";
import { useModalStyles } from "../../utils/modal-styles";
import classNames from "classnames";
import { ModalHeader } from "../../components";
import { useStyles } from "./promocao-edicao-styles";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { InformacaoIcon, VoltarIcon } from "views/components/icons";
import { useCadastros, useToastSaurus } from "services/app";
import { CircularLoading } from "views/components/utils";
import { PromocaoDados } from "../components/promocao-dados/promocao-dados";
import { useGetPromocaoById } from "data/api/gestao/promocao/get-promocao-by-id";
import { PromocaoModel } from "model/api/gestao/promocao/promocao-model";
import { usePromocaoDadosStore } from "../components/promocao-dados/hooks/use-promocao-dados-store";
import { usePutPromocao } from "data/api/gestao/promocao/put-promocao";
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from "views/components/tabs/tabs-saurus";
import { useGetPromocaoRegras } from "data/api/gestao/promocao/get-promocao-regras";
import { PromocaoRegraListModel } from "model/api/gestao/promocao/promocao-regras-list-model";
import { PromocaoIcon } from "views/components/icons/promocao-icon";
import { PromocaoRegraList } from "./components/promocao-regras-list/promocao-regra-list";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import { SwitchSaurus } from "views/components/controles";
import { useMenuOptionsStyles } from "views/components/menu-options/menu-options-styles";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useThemeQueries } from "views/theme";
import { isEmpty } from "lodash";
import { FormTextEditHeader } from "views/components/form/form-text-edit-header/form-text-edit-header";

enum EnumAbaEdicaoPromocao {
    Campanha,
    Regras
}

export const PromocaoEdicao = (props: { id: string }) => {

    const menuClasses = useMenuOptionsStyles({});
    const { theme } = useThemeQueries();
    const modalClasses = useModalStyles();
    const classes = useStyles();
    const { fecharCadastroPromocao } = useCadastros()
    const { addHandler, removeHandler } = useEventTools();

    const [carregandoManual, setCarregandoManual] = useState(true);
    const { showToast } = useToastSaurus();
    const [regras, setRegras] = useState<PromocaoRegraListModel[]>([])

    const [abaSelecionada, setAbaSelecionada] = useState<EnumAbaEdicaoPromocao>(
        EnumAbaEdicaoPromocao.Regras
    );

    const abas = [
        new TabSaurusLabel('Campanha', EnumAbaEdicaoPromocao.Campanha, <InformacaoIcon tipo="BUTTON" fill={abaSelecionada === EnumAbaEdicaoPromocao.Campanha ? theme.palette.primary.main : theme.palette.grey[400]} style={{ margin: '0px 8px' }} />),
        new TabSaurusLabel('Regras', EnumAbaEdicaoPromocao.Regras, <PromocaoIcon tipo="BUTTON" fill={abaSelecionada === EnumAbaEdicaoPromocao.Regras ? theme.palette.primary.main : theme.palette.grey[400]} style={{ margin: '0px 8px' }} />)
    ]

    const { getPromocaoRegras, carregando: carregandoPromocaoRegras } = useGetPromocaoRegras();
    const { getPromocaoById, carregando: carregandoGetById } = useGetPromocaoById();
    const { putPromocao, carregando: carregandoPutPromocao } = usePutPromocao();
    const {
        resetStorePromocao,
        setPromocao,
        state: { promocao }
    } = usePromocaoDadosStore((store) => store);

    const fecharTela = useCallback(() => {
        resetStorePromocao();
        fecharCadastroPromocao();
    }, [fecharCadastroPromocao, resetStorePromocao]);

    const cancelarCadastro = useCallback(async () => {
        fecharTela();
        return true;
    }, [fecharTela]);


    const changeStatus = useCallback(async () => {
        try {
            const promocao = usePromocaoDadosStore.getState().state.promocao

            const newModel = { ...promocao };
            newModel.ativo =
                newModel.ativo = !newModel.ativo;
            const res = await putPromocao(newModel);
            if (res.erro) throw res.erro;

            showToast("success", "Campanha atualizada com sucesso!", );
            setPromocao(newModel);
        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [showToast, setPromocao, putPromocao]);

    const menuOptions = useCallback((): any[] => {
        const promocao = usePromocaoDadosStore.getState().state.promocao

        return [
            new MenuOptionsModel(
                (
                    <Box className={menuClasses.menuItem}>
                        <Typography variant="body1">Ativa</Typography>
                        <SwitchSaurus
                            size="small"
                            value={promocao.ativo}
                            variant="DEFAULT"
                            checked={promocao.ativo}
                        />
                    </Box>
                ),
                <></>,
                () => changeStatus()
            ),

        ];
    }, [changeStatus, menuClasses.menuItem]);

    const confirmarCadastro = useCallback(async () => {
        try {
            const promocao = usePromocaoDadosStore.getState().state.promocao
            const res = await putPromocao(promocao);
            if (res.erro) throw res.erro;

            showToast("success", "Campanha atualizada com sucesso!", );
            setAbaSelecionada(EnumAbaEdicaoPromocao.Regras);
            return true;
        } catch (e: Error | any) {
            showToast('error', e.message);
            return false;
        }
    }, [showToast, putPromocao]);

    const preencherBuscarPromocaoDados = useCallback(async () => {
        try {
            const res = await getPromocaoById(props.id);
            if (res.erro) throw res.erro;

            const data = res.resultado?.data as PromocaoModel;
            setPromocao(data);
            setCarregandoManual(false);
        } catch (e: Error | any) {
            showToast('error', e.message);
        }
    }, [props.id, getPromocaoById, showToast, setPromocao]);

    const preencherRegrasPromocao = useCallback(async () => {
        try {
            const res = await getPromocaoRegras(props.id);
            if (res.erro) throw res.erro;

            const data = res.resultado?.data as PromocaoRegraListModel[];
            setRegras(data);
            setCarregandoManual(false);
        } catch (e: Error | any) {
            showToast('error', e.message);
        }
    }, [props.id, showToast, setRegras, getPromocaoRegras]);

    const regraAlterada = useCallback(() => {
        setAbaSelecionada(EnumAbaEdicaoPromocao.Regras);
        preencherRegrasPromocao();
    }, [preencherRegrasPromocao]);

    useEffect(() => {
        addHandler(AppEventEnum.PromocaoRegraModal, regraAlterada);
        return () => {
            removeHandler(AppEventEnum.PromocaoRegraModal, regraAlterada);
        };
    }, [addHandler, regraAlterada, removeHandler]);


    //VAI FAZER O CARREGAMENTO DA PROMOCAO EXISTENTE
    useEffect(() => {
        preencherBuscarPromocaoDados();
        preencherRegrasPromocao();
    }, [preencherBuscarPromocaoDados, preencherRegrasPromocao])

    const alterarNomePromocao = useCallback(async (valor: string) => {
        try {
            if (isEmpty(valor || ''))
                throw new Error('O nome da Campanha está inválido.');

            const promocao = usePromocaoDadosStore.getState().state.promocao

            const newModel = { ...promocao };
            newModel.nome = valor || '';
            const res = await putPromocao(newModel);
            if (res.erro) throw res.erro;

            showToast("success", "Campanha atualizada com sucesso!", );
            setPromocao(newModel);
            return true;
        } catch (e: any) {
            showToast('error', e.message);
            return false;
        }
    }, [putPromocao, setPromocao, showToast]);
    const carregando = carregandoManual || carregandoGetById || carregandoPutPromocao || carregandoPromocaoRegras;

    const titleArea = useMemo(() => {
        return <FormTextEditHeader
            valor={promocao.nome}
            forceMobile
            maxTexto={30}
            onSubmit={alterarNomePromocao}
            placeholder={`Nome da Campanha`}
        />
    }
        , [alterarNomePromocao, promocao.nome]);

    return (
        <>
            {carregando && (
                <Box className={classes.loadingContainer}>
                    <CircularLoading tipo="FULLSIZED" />
                </Box>
            )}
            <Box className={classNames(modalClasses.root, classes.root)}>
                <ModalHeader
                    title={titleArea}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={() => {
                                fecharTela();
                            }}
                        />
                    }
                    rightArea={
                        <MenuOptions
                            options={menuOptions()}
                            disable={carregando}
                            labels={['Opções da Campanha']}
                        />
                    }
                />
                <Box className={classNames(modalClasses.content, classes.relative)}>
                    <TabsSaurus
                        tabsLabel={abas}
                        selectedTabIndex={abaSelecionada}
                        onChange={(index) => {
                            setAbaSelecionada(index);
                        }}
                        tabsContent={[
                            //CAMPANHA
                            new TabSaurusContent(
                                EnumAbaEdicaoPromocao.Campanha,
                                (
                                    <PromocaoDados
                                        cadastroDeNovaPromocao={false}
                                        cancelarCadastro={cancelarCadastro}
                                        confirmarCadastro={confirmarCadastro}
                                    ></PromocaoDados>
                                )
                            ),
                            //REGRAS
                            new TabSaurusContent(
                                EnumAbaEdicaoPromocao.Regras,
                                (
                                    <PromocaoRegraList promocaoId={props.id} atualizarModelos={() => preencherRegrasPromocao()} regras={regras} carregando={carregando} />
                                )
                            )
                        ]}
                    />
                </Box>
            </Box>
        </>
    );
};
