import { useCallback, useState } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, ProcurarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { FormPesquisaHeader } from "views/components/form/form-pesquisa-header/form-pesquisa-header";


export const ConvenioHeader = () => {

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const location = useLocation();
  const history = useHistory()
  const { abrirConvenioFiltroModal } = useFiltrosModais();

  const urlParams = new URLSearchParams(location.search)

  const filtros = {
    status: isEmpty(urlParams.get('status')) ? -1 : Number(urlParams.get('status')),
    termo: urlParams.get('termo') || '',
  }

  const [openTextfield, setOpenTextfield] = useState<boolean>(true);

  const leftArea = useCallback(
    () => (
      <>
        {isMobile && !location.pathname.includes('/adicionar') && !location.pathname.includes('/pesquisar') ?
          <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
          null}
      </>
    ),
    [isMobile, location.pathname, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      <Box display='flex' gridGap={8}>
        {!openTextfield && <ButtonPrivateHeader
          icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
          tooltip={'Abrir Pesquisa'}
          onClick={() => setOpenTextfield(true)}
        ></ButtonPrivateHeader>}
        <ButtonPrivateHeader
          icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
          tooltip={'Abrir Filtros'}
          onClick={() => {
            abrirConvenioFiltroModal({
              status: filtros.status,
            })
          }}
        ></ButtonPrivateHeader>
      </Box>
    ),
    [abrirConvenioFiltroModal, filtros.status, openTextfield]
  );

  const searchTextfield = (
    <FormPesquisaHeader
      text={filtros.termo}
      closeSearch={() => setOpenTextfield(false)}
      onSubmit={(model) => {
        const searchs: Array<string | null> = [
          model.generico.length > 0 ? `termo=${model.generico}` : null,
          filtros.status > -1 ? `status=${filtros.status}` : null,
        ]

        let query = ''

        searchs
          .filter(x => x)
          .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        history.push({
          pathname: '/convenios',
          search: query
        })
      }}
      placeholder="Buscar pelo nome ou numero do cartão..."
    />
  )

  return (
    <>
      <PrivatePageHeader
        title='Convênios'
        leftArea={leftArea()}
        middleArea={(openTextfield) && searchTextfield}
        rightArea={(location.pathname === '/convenios' || location.pathname === '/convenios/') && rightArea()}
      />
    </>
  );
};
