import { forwardRef, useImperativeHandle } from 'react';
import { Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { ManifestoModel } from 'model/api/gestao/manifesto/manifesto-model';

const retornarNumeroSerieNotaFiscal = (chave: string = '', cnpj: string = '') => {
    if ((!chave || chave.length < 40 || !cnpj || cnpj.length === 0) || !chave.includes('NFe')) return ''; 

    let numero, serie;
    chave = chave.split(cnpj)[1]

    chave = chave.substring(2)

    serie = chave.substring(0, 3)
    numero = chave.substring(3, 12)
    let removerZeros = true;
    numero = Array.from(numero).filter(x => {
        if (x !== '0') {
            removerZeros = false;
            return true
        }
        if (removerZeros && x === '0') {
            return false
        }
        return true
    }).join('')

    return `${numero}/${serie}`;
}

export const FormSituacaoManifesto = forwardRef<
    DefaultFormRefs<ManifestoModel>,
    DefaultFormProps<ManifestoModel>
>((props: DefaultFormProps<ManifestoModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const {
        handleSubmit,
        control,
        reset,
        getValues
    } = useForm<ManifestoModel>({
        defaultValues: { ...props.model },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: ManifestoModel) => {
        props.onSubmit(values, props.model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset({ ...props.model });
        },
        fillForm: (model: ManifestoModel) => {
            reset({ ...model });
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Controller
                                name='nome'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Emitente'
                                        variant='outlined'
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='chave'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Número'
                                        variant='outlined'
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                        value={retornarNumeroSerieNotaFiscal(getValues('chave'), getValues('cnpj'))}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='documento'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='CNPJ'
                                        tipo='CNPJ_CPF'
                                        variant='outlined'
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='chave'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Chave de Acesso'
                                        variant='outlined'
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='vnf'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Valor'
                                        variant='outlined'
                                        tipo='DECIMAL'
                                        showStartAdornment
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name='dhDocumento'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Data Manifesto'
                                        variant='outlined'
                                        tipo='DATA_HORA'
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                        value={getValues('dhDocumento') ? getValues('dhDocumento').split('.')[0] : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='integrado'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Situação da Nota Fiscal'
                                        variant='outlined'
                                        tipo='TEXTO'
                                        autoComplete='new-password'
                                        readOnly
                                        fullWidth
                                        {...field}
                                        value={field.value ? 'Importada' : 'Não Importada'}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});