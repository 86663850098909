import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogProdutoMotivoCancelamento } from "views/components/dialog/dialog-produto-motivo-cancelamento/dialog-produto-motivo-cancelamento";

export const ProdutoMotivoCancelamentoDialogRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
        callback: () => { }
    });

    consoleDev('ProdutoMotivoCancelamentoDialog')

    const modalAlterado = useCallback(({ aberto, callback }: any) => {
        setModalState({
            aberto,
            callback,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ProdutoMotivoCancelamentoDialog, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ProdutoMotivoCancelamentoDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogProdutoMotivoCancelamento aberto={modalState.aberto} callback={modalState.callback} />
            )}
        </>
    )
}