import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 250,
        '& svg': {
            fill: theme.palette.secondary.main
        }
    },
}))