import { makeStyles } from '@material-ui/core';

export const useMenuOptionsStyles = makeStyles((theme) => ({
  divider: {
    borderTop: 'thin solid ' + theme.palette.secondary.main
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(2),
    '& svg': {
      fill: theme.palette.secondary.main,
      marginRight: 0
    },
    '& p': {
      fontSize: '14px'
    },
    '& .MuiSwitch-root': {
      marginRight: '0 !important'
    }
  },
  iconError: {
    '& svg': {
      fill: theme.palette.error.main
    }
  },
  menuIcon: {
    '& svg': {
      width: 36,
      height: 36
    }
  },
  menuIconSmall: {
    '& svg': {
      width: 26,
      height: 26
    }
  }
}));
