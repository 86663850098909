import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { toDateString } from 'utils/to-date';

export function usePostComissaoApuracao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postComissaoApuracao = useCallback(
        (data: {
            empresaId: string;
            comissaoId: string;
            dataInicial: Date | undefined;
            dataFinal: Date | undefined;
        }) => {
            const dataInicial = data.dataInicial ? toDateString(data.dataInicial, 'yyyy-MM-DD') : undefined;
            const dataFinal = data.dataFinal ? toDateString(data.dataFinal, 'yyyy-MM-DD') : undefined;
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${data.empresaId}/comissao/${data.comissaoId}/apuracao`,
                method: 'POST',
                params: {
                    ...(data.dataInicial ? {
                        dataInicio: dataInicial,
                    } : null),
                    ...(data.dataFinal ? {
                        dataFim: dataFinal,
                    } : null)
                },
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            })
        }, [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postComissaoApuracao,
    };
}