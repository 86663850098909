
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";

export function usePutConvenioStatus() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putConvenioStatus = useCallback(
        (convenioId: string, status: EnumConvenioStatus, empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/${convenioId}/status/${status}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putConvenioStatus,
    };
}
