
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { FaturaAnoMesCompetenciaPostModel } from "model/api/gestao/convenio/fatura-ano-mes-pagamento-post-model";

interface ParamProps {
    empresaId: string
    faturaId: string
    anoMesCompetencia: string
    pagamento: FaturaAnoMesCompetenciaPostModel
}

export function usePostFaturaAnoMesPagamento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postFaturaAnoMesPagamento = useCallback(
        ({ anoMesCompetencia, faturaId, empresaId, pagamento }: ParamProps) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/fatura/${faturaId}/competencia/${anoMesCompetencia}/pagamento`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(pagamento),
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postFaturaAnoMesPagamento,
    };
}
