import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogConvenioCartaoQrCode } from 'views/components/dialog/dialog-convenio-cartao-qrcode/dialog-convenio-cartao-qrcode';

class DialogConvenioCartaoQrCodeRenderProps {
  constructor(
    public aberto: boolean = false,
    public model: {
      numeroCartao: string
      senha: string
      nomeCartao: string
    } = {
        numeroCartao: '',
        senha: '',
        nomeCartao: ''
      }
  ) { }
}

export const DialogConvenioQrCodeRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogConvenioCartaoQrCodeRenderProps>(
    new DialogConvenioCartaoQrCodeRenderProps()
  );

  consoleDev('DialogConvenioCartaoQrCode');

  const modalAlterado = useCallback(
    ({ aberto, model }: any) => {
      setModalState({
        aberto,
        model,
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogConvenioCartaoQrCode, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogConvenioCartaoQrCode, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogConvenioCartaoQrCode
          aberto={modalState.aberto}
          model={modalState.model}
        />
      )}
    </>
  );
};
