import { ModificadorEditProdutosProps } from "./modificador-produtos-props"
import { FormModificadorProduto } from "views/components/form/produto/produto-modificador/modificador-produto/form-modificador-produto"
import { useDeleteProdutoModificador } from "data/api/gestao/produto/produto-modificador/delete-produto-modificador"
import { usePutProdutoModificador } from "data/api/gestao/produto/produto-modificador/put-produto-modificador"
import { usePostProdutoModificador } from "data/api/gestao/produto/produto-modificador/post-produto-modificador"
import { useCallback, useState } from "react"
import { useToastSaurus } from "services/app"
import { ProdutoModificadorFormModel } from "model/app/forms/produto/produto-modificador/produto-modificador-form-model"
import { isEmpty, isEqual } from "lodash"
import { ProdutoModificadorModel } from "model/api/gestao/produto/produto-modificador/produto-modificador-model"
import { picker } from "utils/picker"
import { useEmpresaAtual } from "services/app/hooks/empresa-atual"
import CardModificadorProduto from "views/components/cards/card-modificador-produto/card-modificador-produto"
import { toDecimal } from "utils/to-decimal"
import { CardNaoEncontrado } from "views/components/cards"
import { InformacaoIcon, NovoIcon } from "views/components/icons"
import { useStyles } from './modificador-produtos-styles'
import { Box, Button, Grid } from "views/design-system"

export const ModificadorEditProdutos = ({ modificadorId, atualizarArray, ...props }: ModificadorEditProdutosProps) => {

    const { deleteProdutoModificador, carregando: carregandoDelete } = useDeleteProdutoModificador()
    const { putProdutoModificador, carregando: carregandoPut } = usePutProdutoModificador()
    const { postProdutoModificador, carregando: carregandoPost } = usePostProdutoModificador()
    const classes = useStyles()

    const [openNovoForm, setOpenNovoForm] = useState<boolean>(false)

    const { showToast } = useToastSaurus()
    const { getEmpresaAtual } = useEmpresaAtual()

    const carregando = [carregandoDelete, carregandoPost, carregandoPut].includes(true)

    const closeNovoModificador = () => {
        setOpenNovoForm(false)
    }

    const saveNewProdutoModificador = useCallback(async (produto: ProdutoModificadorFormModel) => {
        const prodModificador = picker<ProdutoModificadorModel>(produto, new ProdutoModificadorModel())
        prodModificador.modificadorId = modificadorId
        prodModificador.ordem = (props.produtos?.length ?? 0) + 1

        const res = await postProdutoModificador(modificadorId, prodModificador)
        if (res.erro) throw res.erro

    }, [modificadorId, postProdutoModificador, props.produtos?.length])

    const saveChangesProdModificador = useCallback(async (produto: ProdutoModificadorFormModel) => {
        const prodModificador = picker<ProdutoModificadorModel>(produto, new ProdutoModificadorFormModel())
        prodModificador.modificadorId = modificadorId
        prodModificador.valor = toDecimal(prodModificador.valor)

        const res = await putProdutoModificador(modificadorId, prodModificador)
        if (res.erro) throw res.erro
        showToast("success", "Produto atualizado com sucesso!");
    }, [modificadorId, putProdutoModificador, showToast])

    const handleSubmitNovo = useCallback(
        async (model: ProdutoModificadorFormModel, beforeModel: ProdutoModificadorFormModel) => {
            try {
                const produtoSubItemEqual = isEqual(model, beforeModel);
                if (produtoSubItemEqual) {
                    showToast("info", "Nenhuma informação foi alterada");
                    return false
                }
                await saveNewProdutoModificador(model);

                showToast("success", "Produto adicionado com sucesso!");
                await atualizarArray();
                closeNovoModificador();
                return true
            } catch (e: any) {
                showToast("error", e.message);
                return false
            }
        },
        [atualizarArray, saveNewProdutoModificador, showToast]
    );

    const handleSubmit = useCallback(async (model: ProdutoModificadorFormModel, beforeModel: ProdutoModificadorFormModel) => {
        try {
            const produtoSubItemEqual = isEqual(model, beforeModel);

            if (produtoSubItemEqual) {
                showToast("info", "Nenhuma informação foi alterada");
                return false
            }

            await saveChangesProdModificador(model)

            await atualizarArray();
            return true
        } catch (err: any) {
            showToast("error", err.message);
            return false
        }
    }, [atualizarArray, saveChangesProdModificador, showToast])

    const handleDelete = useCallback(async (id: string) => {
        try {
            const res = await deleteProdutoModificador(modificadorId, id)

            if (res.erro) throw res.erro
            showToast('success', "Produto deletado!")
            await atualizarArray();
            return true
        } catch (err: any) {
            showToast('error', err.message)
            return false
        }
    }, [atualizarArray, deleteProdutoModificador, modificadorId, showToast])

    const trocarOrdem = useCallback(async (model: ProdutoModificadorModel, ordem: 'cima' | 'baixo') => {
        try {

            const produto = props.produtos.find(x => x.ordem === model.ordem)
            const produtos = props.produtos.sort((a, b) => a.ordem - b.ordem)
            if (!produto) {
                return;
            }

            let produtosArr: ProdutoModificadorModel[] = []
            if (ordem === 'cima') {

                const produtoAnterior = produtos[produtos.indexOf(produto) - 1]
                const anteriorDoAnterior = produtos[produtos.indexOf(produtoAnterior) - 1]

                if (isEmpty(anteriorDoAnterior)) {
                    produto.ordem = 1

                    produtoAnterior.ordem = produto.ordem + 1
                } else {
                    produto.ordem = anteriorDoAnterior.ordem + 1
                    produtoAnterior.ordem = produto.ordem + 1
                }
                produtosArr.push(produtoAnterior)
            } else {
                const produtoAnterior = produtos[produtos.indexOf(produto) - 1]
                const produtoPosterior = produtos[produtos.indexOf(produto) + 1]
                if (isEmpty(produtoAnterior)) {
                    produtoPosterior.ordem = 1
                    produto.ordem = produtoPosterior.ordem + 1
                } else {
                    produtoPosterior.ordem = produtoAnterior.ordem + 1
                    produto.ordem = produtoPosterior.ordem + 1
                }
                produtosArr.push(produtoPosterior)
            }
            produtosArr.push(produto)

            for await (let produto of produtosArr) {
                const res = await putProdutoModificador(modificadorId, produto)
                if (res.erro) throw res.erro
            }
            showToast('success', 'Ordem alterada com sucesso!')
            await atualizarArray();
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [atualizarArray, modificadorId, props.produtos, putProdutoModificador, showToast])

    return (
        <Box
            p={2}
        >
            {props.produtos?.length <= 0 && (
                <CardNaoEncontrado
                    mensagem="Nenhum produto encontrado."
                    icon={<InformacaoIcon tipo="GERAL" />}
                    removeMarginCard
                />
            )}
            {props.produtos?.length > 0 && (
                <Grid container spacing={1}>
                    {
                        props.produtos
                            .sort((a, b) => a.ordem - b.ordem)
                            .map((prod, index, arr) => {
                                return (
                                    <Grid item xs={12} className={classes.card}>
                                        <CardModificadorProduto
                                            key={prod.id}
                                            models={arr}
                                            carregando={carregando}
                                            empresaId={getEmpresaAtual()?.id ?? ''}
                                            handleDelete={handleDelete}
                                            handleSubmit={handleSubmit}
                                            model={prod}
                                            trocarOrdem={trocarOrdem}
                                            selected={false}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            })
                    }
                </Grid>
            )}
            <Box mt={3}>
                {!openNovoForm ? (
                    <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => setOpenNovoForm(true)}
                    >
                        <NovoIcon tipo="BUTTON" />
                        Adicionar novo produto
                    </Button>
                ) : (
                    <FormModificadorProduto
                        empresaId={getEmpresaAtual()?.id ?? ''}
                        loading={carregando}
                        onSubmit={handleSubmitNovo}
                        showLoading={true}
                        closeForm={closeNovoModificador}
                    />
                )}
            </Box>
        </Box>
    )
}