

import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useThemeQueries } from 'views/theme';
import { TermosDeUso } from './components/termos-de-uso';
import { ModalProps } from '../utils';
export interface ModalExibirTermosDeUsoProps extends ModalProps {
    finalizarCadastro: () => void
}

export const TermosDeUsoModal = ({ ...props
}: ModalExibirTermosDeUsoProps) => {

    const { isMobile } = useThemeQueries()
    return (
        <DialogSaurus
            aberto={props.openned || false}
            fullHeight
            fullScreen={isMobile ? true : false}
            isButtonTitleClose
            tamanho={'md'}
        >
            <TermosDeUso {...props} />
        </DialogSaurus>
    );
};
