import { useCallback, useMemo } from 'react';
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { DepositoHeader } from "./components/deposito-header/deposito-header";
import { DepositoList } from "./components/deposito-list/deposito-list";
import { ButtonFab } from "views/components/controles";
import { DepositoAddIcon } from "views/components/icons";
import { useCadastros } from 'services/app';
import { Box } from 'views/design-system';

export const DepositoPage = () => {

  const classes = useDefaultCadastroStyles();
  const { abrirCadastroDepositos } = useCadastros();
  const onClickAdd = useCallback(() => {
    abrirCadastroDepositos('', "", true);
  }, [abrirCadastroDepositos]);

  const component = useMemo(() => <Box className={classes.root}>
    <Box className={classes.header}>
      <DepositoHeader />
    </Box>
    <Box >
      <DepositoList />
    </Box>
    <ButtonFab tooltip="Adicionar Depósito" icon={<DepositoAddIcon tipo="BUTTON_FAB" />} onClick={onClickAdd} />
  </Box>, [classes.header, classes.root, onClickAdd]);

  return component;
};

export default DepositoPage;
