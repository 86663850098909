import { useStyles } from "./digitar-autenticacao-styles"
import { FormConvenioAutenticar } from "views/components/form/convenio-autenticar/form-convenio-autenticar/form-convenio-autenticar"
import { Button, Divider, Grid, Typography } from "views/design-system"
import { DigitarAutenticacaoProps } from "./digitar-autenticacao-props"
import { OkIcon, VoltarIcon } from "views/components/icons"
import { useMemo } from "react"
import { EnumModeloAutenticacao } from "model/enums/enum-modelo-autenticacao"

export const DigitarAutenticacao = ({
    carregando,
    handleSubmit,
    refForm,
    onClickSair,
    modeloAutenticacao
}: DigitarAutenticacaoProps) => {
    const classes = useStyles()

    const descricao = useMemo(() => {
        const list = {
            [EnumModeloAutenticacao.DocumentoCliente]: () => 'Digite o documento do cartão para autenticar.',
            [EnumModeloAutenticacao.NumeroCartaoSenha]: () => 'Digite seu número do cartão e senha para autenticar.',
            [EnumModeloAutenticacao.NumeroCartao]: () => 'Digite seu número do cartão para autenticar.',
        }
        return list[modeloAutenticacao ?? EnumModeloAutenticacao.NumeroCartaoSenha]()
    }, [modeloAutenticacao])

    return (
        <Grid className={classes.cardContainer}>
            <Typography className={classes.descricao}>
                {descricao}
            </Typography>
            <Grid className={classes.cardContent} spacing={2}>
                <FormConvenioAutenticar
                    loading={carregando}
                    onSubmit={handleSubmit}
                    showLoading={false}
                    ref={refForm}
                />
                <Grid container className={classes.acoes} spacing={2}>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            disabled={carregando}
                            onClick={onClickSair}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            <VoltarIcon tipo="BUTTON" />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => refForm.current?.submitForm()}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}