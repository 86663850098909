import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  vendedorContent: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  vendedorText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
    '& b': {
      color: theme.palette.text.primary
    }
  },
  vendedorDivider: {
    height: 2,
    backgroundColor: theme.palette.grey[300]
  },
  listContainer: {
    overflowY: 'auto',
    '& .card': {
      margin: theme.spacing(1)
    }
  },
}));
