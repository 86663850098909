import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden",
        flexGrow: 1
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 'auto'
    },
}));
