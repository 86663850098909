import { guidEmpty } from 'utils/guid-empty';


export class PromocaoModel {
    constructor(
        public id: string = guidEmpty(),
        public nome: string = '',
        public dataInicial: string = '',
        public dataFinal: string = '',
        public horarioInicial: number = 0,
        public horarioFinal: number = 0,
        public diasSemana: number = 0,
        public ativo: boolean = true
    ) { }
}
