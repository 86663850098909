import CardPromocao from 'views/components/cards/card-promocao/card-promocao';
import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { PromocaoModel } from 'model/api/gestao/promocao/promocao-model';

export interface PromocaoGridProps {
  list: Array<PromocaoModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const PromocaoListData = (props: PromocaoGridProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma campanha encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((promocao, index) => {
          return (
            <CardPromocao
              selected={
                props.selectedList.filter((item) => item === promocao.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={promocao}
              key={index}
            />
          );
        })}
    </>
  );
};
