import { useEffect, useState, useCallback, useMemo } from 'react';
import { useStyles } from '../../../entrada/components/entrada-list/entrada-list-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toDateString } from 'utils/to-date';
import { descMonth, getCurrentMonth } from 'utils/get-date';
import { ManifestoModel } from 'model/api/gestao/manifesto/manifesto-model';
import { useGetManifesto } from 'data/api/gestao/empresa-documento/get-manifesto';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { ManifestosListData } from './manifestos-list-data';
import { EnumStatusManifesto } from 'model/enums/enum-status-manifesto';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Grid } from 'views/design-system';

const TodosStatus = [
    EnumStatusManifesto.EventosDeConfirmacaoDeOperacao,
    EnumStatusManifesto.EventosDeCienciaDaOperacao,
    EnumStatusManifesto.EventosDeDesconhecimentoDaOperacao,
    EnumStatusManifesto.EventosDeOperacaoNaoRealizada,
    EnumStatusManifesto.ResumoEvento,
    EnumStatusManifesto.ResumoNFe,
]

export const ManifestoList = () => {
    const classes = useStyles();
    const { getManifesto, carregando } = useGetManifesto()
    const { showToast } = useToastSaurus();
    const { addHandler, removeHandler } = useEventTools();
    const [manifestos, setManifestos] = useState<ManifestoModel[]>([])
    const { abrirModalSituacaoManifesto } = useCadastros();
    const history = useHistory();
    const urlParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search])
    const filtros = useMemo(() => (
        {
            dInicio: urlParams.get('dInicial') || toDateString(descMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD')!,
            dFinal: urlParams.get('dFinal') || toDateString(new Date(), 'yyyy-MM-DD')!,
            statusManifesto: !isEmpty(urlParams.getAll('status')) ? urlParams.getAll('status').map(tp => Number(tp)) : [-1],
            integrado: urlParams.get('integrado') || 'false'
        }
    ), [urlParams])
    const { getEmpresaAtual } = useEmpresaAtual()


    const search = useCallback(
        async () => {
            const query =
                (isEmpty(filtros.dInicio)
                    ? ''
                    : `&dataInicio=${filtros.dInicio}T00:00:00`) +
                (isEmpty(filtros.dFinal)
                    ? ''
                    : `&dataFinal=${filtros.dFinal}T23:59:59`) +
                (filtros.statusManifesto.length > 0
                    ? filtros.statusManifesto.find(t => t === -1) ?
                        TodosStatus.reduce((acc, current) => acc + `&statusManifesto=${current}`, '') :
                        filtros.statusManifesto.reduce((acc, current) => acc + `&statusManifesto=${current}`, '')
                    : '') +
                `${filtros.integrado !== '-1' ? `&integrado=${filtros.integrado}` : ''}`
            try {
                const cnpj = getEmpresaAtual()?.cpfcnpj || '';
                const res = await getManifesto(cnpj, query)

                if (res.erro) throw res.erro;

                const data = res.resultado?.data

                setManifestos(data)


            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [filtros.statusManifesto, filtros.dInicio, filtros.dFinal, filtros.integrado, getEmpresaAtual, getManifesto, showToast],
    );

    const attManifesto = useCallback(({openned}) => {
        if(!openned){
            search();
        }
    }, [search])

    useEffect(() => {
        search();

        addHandler(AppEventEnum.ConfirmarSituacaoManifesto, attManifesto)
        return () => {
            removeHandler(AppEventEnum.ConfirmarSituacaoManifesto, attManifesto)
        }
    }, [addHandler, attManifesto, removeHandler, search]);

    const onCardClick = (model: ManifestoModel) => {
        abrirModalSituacaoManifesto(model)
    };

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container>
                    <Grid item xs={12} className={classes.listContainer}>
                        <ManifestosListData
                            carregando={carregando}
                            list={manifestos}
                            onCardClick={onCardClick}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};