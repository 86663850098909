import { isEmpty } from 'lodash';
import { EnumDeviceType } from "model/enums/enum-device-type";
import { VariaveisAmbiente } from "./enviroment";

export const getIsDev = () => {
    const isDevStorage = localStorage.getItem('environments-isDev');
    if (!isDevStorage) {
        const isDevDefault = (process.env.REACT_APP_ISDEV || 'false');
        localStorage.setItem('environments-isDev', isDevDefault.toString());
        return isDevDefault === 'true';
    }
    return isDevStorage === 'true';
}
export const setIsDev = (isDev: boolean) => {
    localStorage.setItem('environments-isDev', isDev.toString());
    VariaveisAmbiente.isDev = isDev;
};

export const getChaveAtivacaoRevenda = () => {
    const chaveAtivacaoRevendaStorage = localStorage.getItem('environments-chaveAtivacaoRevenda');
    if (!chaveAtivacaoRevendaStorage) {
        const chaveAtivacaoRevendaDefault = (process.env.REACT_APP_ATIVACAO || "");
        localStorage.setItem('environments-chaveAtivacaoRevenda', chaveAtivacaoRevendaDefault);
        return isEmpty(chaveAtivacaoRevendaDefault) ? undefined : chaveAtivacaoRevendaDefault;
    }
    return chaveAtivacaoRevendaStorage;
}

export const setChaveAtivacaoRevenda = (chaveAtivacaoRevenda: string) => {
    localStorage.setItem('environments-chaveAtivacaoRevenda', chaveAtivacaoRevenda);
    VariaveisAmbiente.chaveAtivacaoRevenda = chaveAtivacaoRevenda;
}

export const getLabel = () => {
    const labelStorage = localStorage.getItem('environments-label');
    if (!labelStorage) {
        const labelDefault = (process.env.REACT_APP_LABEL || "touchone");
        localStorage.setItem('environments-label', labelDefault);
        return labelDefault
    }
    return labelStorage;

}
export const setLabel = (label: string) => {
    localStorage.setItem('environments-label', label);
    VariaveisAmbiente.label = label;
}

export const getEnvironment = (): `prod` | `staging` => {
    const environmentStorage = sessionStorage.getItem('environments-environment');
    if (!environmentStorage) {
        const environment = (process.env.REACT_APP_ENV || "prod") as `prod` | `staging`;
        sessionStorage.setItem('environments-environment', environment);
        return environment
    }
    return environmentStorage as `prod` | `staging`;

}
export const setEnvironment = (environment: `prod` | `staging`) => {
    sessionStorage.setItem('environments-environment', environment);
    VariaveisAmbiente.environment = environment as `prod` | `staging`;
}

export const getPerformanceDebugMode = () => {
    const performanceDebugModeStorage = localStorage.getItem('environments-performanceDebugMode');
    if (!performanceDebugModeStorage) {
        localStorage.setItem('environments-performanceDebugMode', false.toString());
        return false;
    }
    return performanceDebugModeStorage === 'true';
}
export const setPerformanceDebugMode = (performanceDebugMode: boolean) => {
    localStorage.setItem('environments-performanceDebugMode', performanceDebugMode.toString());
    VariaveisAmbiente.performanceDebugMode = performanceDebugMode;
}

export const getGatewayVersion = () => {
    const gatewayVersionStorage = sessionStorage.getItem('environments-gatewayVersion');
    if (!gatewayVersionStorage) {
        const gatewayVersionDefault = "v5";
        sessionStorage.setItem('environments-gatewayVersion', gatewayVersionDefault);
        return gatewayVersionDefault
    }
    return gatewayVersionStorage;
}
export const setGatewayVersion = (gatewayVersion: string) => {
    sessionStorage.setItem('environments-gatewayVersion', gatewayVersion);
    VariaveisAmbiente.gatewayVersion = gatewayVersion;
}

export const getDevice = () => {
    const deviceStorage = sessionStorage.getItem('environments-device');
    if (!deviceStorage) {
        const deviceDefault = Number(process.env.REACT_APP_DEVICE) || EnumDeviceType.NAVIGATOR;

        sessionStorage.setItem('environments-device', deviceDefault.toString());
        return deviceDefault
    }
    return Number(deviceStorage) as EnumDeviceType;

}
export const setDevice = (device: EnumDeviceType) => {
    sessionStorage.setItem('environments-device', device.toString());
    VariaveisAmbiente.paymentDevice = device;
}

export function getFrontUrl(): string {
    const isDev = getIsDev();
    const env = getEnvironment();


    switch (process.env.REACT_APP_LABEL?.toLowerCase()) {
        case 'safra':
            return 'https://gestaofacil.digital';
        case 'pdvon':
            return 'https://app.pdvon.com.br';
        case 'farma':
            return 'https://farma.touchone.com.br';
        default:
            if (env === 'staging') {
                if (isDev) {
                    return 'https://app-dev.touchone.com.br';
                } else {
                    return 'https://app-staging.touchone.com.br';
                }
            }
            return 'https://app.touchone.com.br'
    }
}