import { useDeleteComissaoComissionados } from "data/api/gestao/comissao/delete-comissionados";
import { useGetComissionadosById } from "data/api/gestao/comissao/get-comissionados-by-id";
import { usePostPessoasByIds } from "data/api/gestao/pessoa/post-pessoas-by-ids";
import { ComissaoModel } from "model/api/gestao/comissao/comissao-model";
import { PessoaModel } from "model/api/gestao/pessoa";
import { EnumTpPessoaComissionamento } from "model/enums/enum-tp-pessoa-comissionamento";
import { useCallback, useMemo } from "react";
import { useToastSaurus } from "services/app";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { guidEmpty } from "utils/guid-empty";
import { newGuid } from "utils/new-guid";
import { GerentesMap } from "views/components/modals/comissao/comissao-modal/components/comissao-steps-types";
import { Comissionado, useComissionadosStore } from "views/components/modals/comissao/comissao-modal/stores/comissionados-store";

export interface ComissionadosHookProps {
    comissaoId: string
}

export const useComissionados = ({ comissaoId }: ComissionadosHookProps) => {

    const { showToast } = useToastSaurus()
    const { getEmpresaAtual } = useEmpresaAtual()
    const { getComissaoComissionadosById, carregando: carregandoGetComissionados } = useGetComissionadosById()
    const { carregando: carregandoComissaoPessoasByIds, postPessoasByIds } =
        usePostPessoasByIds();
    const { deleteComissaoComissionados, carregando: carregandoDelComissionados } = useDeleteComissaoComissionados()

    const carregando = useMemo(() =>
        [carregandoComissaoPessoasByIds, carregandoGetComissionados, carregandoDelComissionados].includes(true)
        , [carregandoComissaoPessoasByIds, carregandoDelComissionados, carregandoGetComissionados])

    const {
        setStatePessoasComissionados
    } = useComissionadosStore((store) => store);

    const filtrarGerentesGerais = (
        pessoas: PessoaModel[],
        gerentesGerais: ComissaoModel[]
    ): Comissionado[] => {
        const comissionadoIds = gerentesGerais
            .filter(
                (comissao) =>
                    comissao.comissionadoId &&
                    !comissao.vendedorId &&
                    !comissao.operadorId
            )
            .map((c) => c.comissionadoId);

        return pessoas?.reduce((result: Comissionado[], pessoa) => {
            if (comissionadoIds.includes(pessoa.id)) {
                const gerenteGeralComissionado = gerentesGerais?.find(
                    (gg) => gg.comissionadoId === pessoa.id
                );

                const id = gerentesGerais?.find(
                    (gg) => gg.comissionadoId === pessoa.id
                );

                if (gerenteGeralComissionado) {
                    result.push({
                        documento: pessoa.cpfcnpj,
                        nome: pessoa.nome,
                        id: id?.id ?? guidEmpty(),
                        condicao: gerenteGeralComissionado.condicao,
                        pessoaId: pessoa.id,
                        valor: gerenteGeralComissionado.valor,
                        tipo: EnumTpPessoaComissionamento.GerenteGeral,
                        internalId: newGuid()
                    });
                }
            }
            return result ?? [];
        }, []);
    };

    const filtrarVendedores = (
        pessoas: PessoaModel[],
        comissoes: ComissaoModel[]
    ): Comissionado[] => {
        return pessoas?.reduce((result: Comissionado[], pessoa) => {
            const vendedorComissionado = comissoes?.find(
                (comissao) =>
                    comissao.comissionadoId === pessoa.id &&
                    comissao.comissionadoId === comissao.vendedorId
            );

            const id = comissoes?.find((gg) => gg.comissionadoId === pessoa.id);

            if (vendedorComissionado) {
                result.push({
                    documento: pessoa.cpfcnpj,
                    nome: pessoa.nome,
                    id: id?.id ?? guidEmpty(),
                    condicao: vendedorComissionado.condicao,
                    pessoaId: pessoa.id,
                    valor: vendedorComissionado.valor,
                    tipo: EnumTpPessoaComissionamento.Vendedor,
                    internalId: newGuid()
                } as Comissionado);
            }

            return result ?? [];
        }, []);
    };

    const filtrarGerentes = (
        pessoas: PessoaModel[],
        comissoes: ComissaoModel[]
    ): Comissionado[] => {
        const gerenteVendedor = comissoes.filter(
            (c) =>
                c.comissionadoId && c.vendedorId && c.comissionadoId !== c.vendedorId
        );

        const gerentes = Object.values(
            gerenteVendedor.reduce((acc: any, comissionado: ComissaoModel) => {
                if (!acc[comissionado.comissionadoId]) {
                    acc[comissionado.comissionadoId] = {
                        comissionadoId: comissionado.comissionadoId,
                        comissaoComissaoId: comissionado.ComissaoCampanhaId,
                        vendedores: [],
                        id: newGuid()
                    };
                }
                acc[comissionado.comissionadoId].vendedores.push({
                    id: comissionado.id,
                    operadorId: comissionado.operadorId,
                    vendedorId: comissionado.vendedorId,
                    condicao: comissionado.condicao,
                    valor: comissionado.valor
                });
                return acc;
            }, {})
        ) as GerentesMap[];

        const gerentePessoas = gerentes.map((g) => {
            let gerentePessoa = pessoas?.find((p) => p.id === g.comissionadoId);

            const vendedores = g.vendedores.map((v) => {
                let vendedorPessoa = pessoas?.find((p) => p.id === v.vendedorId);

                return {
                    tipo: EnumTpPessoaComissionamento.Vendedor,
                    id: v.id,
                    condicao: v.condicao,
                    valor: v.valor,
                    documento: vendedorPessoa?.cpfcnpj ?? '',
                    internalId: newGuid(),
                    nome: vendedorPessoa?.nome ?? '',
                    pessoaId: vendedorPessoa?.id ?? ''
                };
            }) as Comissionado[];

            return {
                condicao: vendedores[0].condicao,
                documento: gerentePessoa?.cpfcnpj ?? '',
                id: newGuid(),
                internalId: newGuid(),
                nome: gerentePessoa?.nome ?? '',
                pessoaId: gerentePessoa?.id ?? '',
                tipo: EnumTpPessoaComissionamento.Gerente,
                valor: vendedores[0].valor,
                vendedores: vendedores
            };
        }) as Comissionado[];

        return gerentePessoas;
    };

    const extractIds = useCallback((items: ComissaoModel[]): string[] => {
        const ids: string[] = [];

        items.forEach((item) => {
            if (item.vendedorId && item.vendedorId !== guidEmpty()) {
                if (item.comissionadoId === item.vendedorId) {
                    ids.push(item.comissionadoId);
                } else {
                    ids.push(item.comissionadoId, item.vendedorId);
                }
            } else {
                ids.push(item.comissionadoId);
            }
        });

        return ids;
    }, []);

    const getComissionados = useCallback(async () => {
        try {
            const resGetComissaoComissionados = await getComissaoComissionadosById(
                getEmpresaAtual()?.id ?? '',
                comissaoId
            );

            if (resGetComissaoComissionados.erro) {
                throw new Error(resGetComissaoComissionados.erro);
            }

            const comissionados = (resGetComissaoComissionados.resultado?.data
                ?.list ?? []) as ComissaoModel[];
            const comissionadosIds = extractIds(comissionados);

            const resPostPessos = await postPessoasByIds(comissionadosIds);

            if (resPostPessos.erro) {
                throw new Error(resPostPessos.erro);
            }

            const pessoasComissionados =
                resPostPessos.resultado?.data ?? ([] as PessoaModel[]);

            const gerentesGerais = filtrarGerentesGerais(
                pessoasComissionados,
                comissionados
            );

            const vendedores = filtrarVendedores(
                pessoasComissionados,
                comissionados
            );

            const gerentes = filtrarGerentes(pessoasComissionados, comissionados);

            setStatePessoasComissionados(
                gerentesGerais ?? [],
                gerentes ?? [],
                vendedores ?? []
            );

        } catch (err: any) {
            showToast('error', err.message)
            throw err
        }
    }, [comissaoId, extractIds, getComissaoComissionadosById, getEmpresaAtual, postPessoasByIds, setStatePessoasComissionados, showToast])

    const handleRemoverComissionado = useCallback(async (comissionados: Comissionado[], reload: boolean = true) => {
        try {
            const comissionadosId: string[] = []
            comissionados.forEach(com => {
                if (com.vendedores && com.vendedores.length > 0) {
                    com.vendedores.forEach(ven => {
                        comissionadosId.push(ven.id)
                    })
                } else {
                    comissionadosId.push(com.id)
                }
            })
            const res = await deleteComissaoComissionados(getEmpresaAtual()?.id ?? '', comissaoId, comissionadosId)
            if (res.erro) throw res.erro
            if (reload) {
                await getComissionados()
            }

            showToast('success', `Comissionado${comissionados.length > 1 ? 's' : ''} removidos!`)
            return true
        } catch (err: any) {
            showToast('error', err.message)
            return false
        }
    }, [comissaoId, deleteComissaoComissionados, getComissionados, getEmpresaAtual, showToast])

    return {
        carregando,
        handleRemoverComissionado,
        getComissionados
    }
}