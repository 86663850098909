import { newGuid } from "utils/new-guid";

export class MenuModel {
  constructor(
    public nome: string = "",
    public descricao: string = "",
    public codigo: number = 0,
    public permissoes: Array<number> = [],
    public rota: string = "",
    public posicao: number,
    public codigoPai: number = 0,
    public dataValidade: Date | undefined = undefined,
    public favorito: boolean = false,
    public id: string = newGuid(),
  ) {
  }
}
