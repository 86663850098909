import classNames from 'classnames'
import { BackgroundFooterRight, BackgroundHeaderLeft, BackgroundHeaderRight } from './background/background-blue'
import { BackgroundWhiteBottom, BackgroundWhiteRight, BackgroundWhiteTop } from './background/background-white'
import { PublicPageHeaderProps } from './header-public-page-props'
import { useStyles } from './header-public-page-styles'
import { EnvironmentInfo } from 'config/components/environment-info'

export const PublicPageHeader = (props: PublicPageHeaderProps) => {
    const classes = useStyles({
        containedPage: props.containedPage
    });

    return (
        <>
            <div className={
                classNames(
                    classes.publicPageHeaderContainer,
                    props.variant === 'white' ? classes.publicPageHeaderContainerWhite : classes.publicPageHeaderContainerBlue
                )

            } style={props.styles}>
                <div className={classes.publicPageHeaderTopContainer}>
                    {
                        props.variant === 'white' ?
                            <><BackgroundWhiteTop /> </> :
                            <><BackgroundHeaderLeft /><BackgroundHeaderRight /></>
                    }
                </div>
                <div className={classes.publicPageHeaderRightContainer}>
                    {
                        props.variant === 'white' ?
                            <> <BackgroundWhiteRight /> </> : null
                    }
                </div>
                <div className={classes.publicPageHeaderBottomContainer}>
                    {
                        props.variant === 'white' ?
                            <> <BackgroundWhiteBottom /> </> :
                            <>
                                <div />
                                <BackgroundFooterRight />
                            </>
                    }
                </div>
                {!props.hideInfo && (<div className={classes.publicPageHeaderVersionControl}>
                    <EnvironmentInfo />
                </div>
                )}
            </div >
        </>
    )
}
