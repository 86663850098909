import { useRef, useState } from 'react';
import { useStyles } from './form-login-styles';
import { useFormLoginValidation } from './form-login-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps
} from '../../utils/form-default-props';
import { LoginFormModel } from 'model/app';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from 'views/design-system';

interface FormLoginProps extends DefaultFormProps<LoginFormModel> {
  marginTop?: string;
  semAutocomplete?: boolean;
}

export const FormLogin = React.forwardRef<
  DefaultFormRefs<LoginFormModel>,
  FormLoginProps
>((props: FormLoginProps, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const txtSenha = useRef<HTMLInputElement>();
  const { FormLoginYupValidation } = useFormLoginValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const [model, setModel] = useState({
    modeloForm: new LoginFormModel('', '')
  });

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset
  } = useForm<LoginFormModel>({
    resolver: yupResolver(FormLoginYupValidation),
    defaultValues: { ...model.modeloForm },
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched'
  });

  const onSubmit = (form: LoginFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: LoginFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) {
        if (model.usuario.length > 0) txtSenha?.current?.focus();
        else txtEmail?.current?.focus();
      }
    }
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
          style={{
            marginTop: props.marginTop ? props.marginTop : undefined
          }}
        >
          <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="usuario"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtEmail}
                    allowSubmit={false}
                    autoComplete={
                      props.semAutocomplete ? 'new-password' : undefined
                    }
                    label="E-mail / Apelido / Telefone"
                    placeholder="usuario@empresa.com.br"
                    error={Boolean(errors.usuario && errors.usuario.message)}
                    helperText={
                      errors.usuario ? errors.usuario?.message : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={5}
                mb={Boolean(touchedFields.senha && errors.senha) ? 0 : 1.5}
              >
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit={true}
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      inputRef={txtSenha}
                      autoComplete={
                        props.semAutocomplete ? 'new-password' : undefined
                      }
                      label="Senha"
                      placeholder="Digite sua Senha"
                      error={Boolean(errors.senha && errors.senha.message)}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
