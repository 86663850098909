import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
    },
    flexContainer: {
        display: 'flex',
    },
    flexDirectionRow: {
        flexDirection: 'row',
    },
    flexDirectionColumn: {
        flexDirection: 'column',
    },
    flexDirectionRowReverse: {
        flexDirection: 'row-reverse',
    },
    flexDirectionColumnReverse: {
        flexDirection: 'column-reverse',
    },
    flexWrapNowrap: {
        flexWrap: 'nowrap',
    },
    flexWrapWrap: {
        flexWrap: 'wrap',
    },
    flexWrapWrapReverse: {
        flexWrap: 'wrap-reverse',
    },
    alignItemsFlexStart: {
        alignItems: 'flex-start',
    },
    alignItemsFlexEnd: {
        alignItems: 'flex-end',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
    alignItemsBaseline: {
        alignItems: 'baseline',
    },
    alignItemsStretch: {
        alignItems: 'stretch',
    },
    justifyContentFlexStart: {
        justifyContent: 'flex-start',
    },
    justifyContentFlexEnd: {
        justifyContent: 'flex-end',
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    justifyContentSpaceBetween: {
        justifyContent: 'space-between',
    },
    justifyContentSpaceAround: {
        justifyContent: 'space-around',
    },
    justifyContentSpaceEvenly: {
        justifyContent: 'space-evenly',
    },
}));
