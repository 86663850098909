import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-refazer-mesa-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef } from 'react';
import { MesasGerarModel } from 'model/api/gestao/mesa/mesa-gerar-model';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { usePostGerarMesa } from 'data/api/gestao/mesas/post-gerar-mesa';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormMesaGerar } from 'views/components/form/mesa/form-mesa-gerar/form-mesa-gerar';
import { useConfirm } from 'material-ui-confirm';
import { Button, Grid } from 'views/design-system';
interface DialogRefazerMesaProps {
  close: () => void;
  carregando?: boolean;
  aberto: boolean;
  salaoId: string;
  temMesas: boolean;
}

export const DialogRefazerMesa = ({
  close,
  carregando,
  aberto,
  salaoId,
  temMesas,
}: DialogRefazerMesaProps) => {
  const refInputXProd = useRef<HTMLInputElement>(null);
  const classesComponent = useStyles();

  const { getEmpresaSelecionada } = useSessaoAtual();
  const { postGerarMesa, carregando: carregandoPost } = usePostGerarMesa();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();
  const confirm = useConfirm();

  const loading = carregandoPost || carregando;

  useEffect(() => {
    if (aberto && !isMobile) {
      setTimeout(() => {
        refInputXProd.current?.focus();
      }, 50);
    }
  }, [aberto, isMobile]);

  const cadMesasFormRef = useRef<DefaultFormRefs<MesasGerarModel>>(null);
  const redirect = useRef<boolean>(true);

  const saveGerarMesa = useCallback(
    async (model: MesasGerarModel) => {
      try {
        const ret = await postGerarMesa(
          model,
          getEmpresaSelecionada()?.Id || '',
          salaoId,
        );
        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Mesas geradas com sucesso!');

        if (!redirect.current) {
          close();
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadMesasFormRef.current?.fillForm(model);
      }
    },
    [postGerarMesa, getEmpresaSelecionada, salaoId, showToast, close],
  );

  const handleSubmit = useCallback(
    async (model: MesasGerarModel) => {
      if (temMesas) {
        confirm({
          title: 'Atenção',
          description:
            'Ao configurar ou refazer as mesas com a opção de subistituir, você perderá informações de pedidos atrelados à mesa caso tenha, você deseja continuar com o processo?',
          cancellationText: 'Cancelar',
          confirmationText: 'Confirmar',
          confirmationButtonProps: {
            variant: 'contained',
            color: 'primary',
          },
        }).then(() => saveGerarMesa(model));
      } else {
        saveGerarMesa(model);
      }
    },
    [confirm, saveGerarMesa, temMesas],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadMesasFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadMesasFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  return (
    <DialogSaurus aberto={aberto || false} titulo="Refazer Mesas" tamanho="xs">
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Grid className={classesComponent.cardContent}>
          <FormMesaGerar
            ref={cadMesasFormRef}
            showLoading={false}
            loading={loading ?? false}
            onSubmit={handleSubmit}
          />
          <Grid container className={classesComponent.acoes} spacing={2}>
            <Grid item xs={5}>
              <Button
                disabled={loading}
                onClick={onCloseClick}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                disabled={loading}
                onClick={() => submitForm(false)}
                variant="contained"
                color="primary"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
