import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { PessoaSelecionarModal } from "views/components/modals/pessoa-selecionar-modal/pessoa-selecionar-modal";
import { PessoaSelecionarModalProps } from "views/components/modals/pessoa-selecionar-modal/pessoa-selecionar-modal-props";

export const PessoaSelecionarModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<PessoaSelecionarModalProps>();

    const modalAlterado = useCallback((props: PessoaSelecionarModalProps) => {
        setModalState(props);
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PessoaSelecionarModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PessoaSelecionarModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <PessoaSelecionarModal
            adicionar={modalState?.adicionar}
            idsBloqueados={modalState?.idsBloqueados ?? []}
            openned={modalState?.openned || false}
            voltar={modalState?.voltar}
            typeSelect={modalState?.typeSelect}
            label={modalState?.label}
            pessoasSelecionadas={modalState?.pessoasSelecionadas}
        />
    )
}