import { Grid, Typography, Button, Box } from "views/design-system";
import { useConfirm } from "material-ui-confirm";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeUtilClasses, useThemeQueries } from "views";
import { PublicPageHeader } from "views/components/headers";
import { CancelarIcon } from "views/components/icons";
import { MedalhaFillIcon } from "views/components/icons/medalha-fill-icon";
import { CarouselPlanosSaurus } from "../components";

import { useStyles } from "./selecionar-plano-page-styles";

const SelecionarPlanoPage = () => {
  const classes = useStyles();
  const utilClasses = makeUtilClasses();
  const confirm = useConfirm();
  const history = useHistory();
  const [isPlanoAvulso, setIsPlanoAvulso] = useState<boolean>(false)
  const { theme } = useThemeQueries();

  const rejeitarPlano = useCallback(() => {
    if (isPlanoAvulso) {
      return history.push('/logout')
    }
    confirm({
      title: "Deseja fazer logout?",
      description:
        "Infelizmente ao não escolher um plano, você não poderá acessar o sistema. Deseja realmente sair?",
      cancellationText: "Recusar e Realizar Logoff",
      cancellationButtonProps: { color: "secondary" },
      confirmationButtonProps: { variant: "contained" },
      confirmationText: "Escolher um plano",
    }).catch(() => {
      history.push('/logout');
    });
  }, [confirm, history, isPlanoAvulso]);

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <div className={utilClasses.contentCenter}>
        <Grid className={classes.root}>
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            {isPlanoAvulso ? (
              <Typography
                variant="h4"
                color="primary"
                style={{ display: "inline", fontWeight: 700 }}
              >
                Plano Avulso
              </Typography>
            ) : (
              <>
                <MedalhaFillIcon
                  fill={theme.palette.primary.main}
                  tipo="MODAL_HEADER"
                />
                <Typography
                  variant="h4"
                  color="primary"
                  style={{ display: "inline", fontWeight: 700 }}
                >
                  Escolha um Plano
                </Typography>
              </>
            )}
          </Grid>

          <div
            className={classes.alert}
            style={{
              maxWidth: isPlanoAvulso ? 600 : 340
            }}
          >
            <Grid>
              <Typography
                className={classes.textAlert}
                color="secondary"
                align="center"
              >
                {isPlanoAvulso
                  ? "Identificamos que atualmente você está utilizando um plano personalizado e não é possivel fazer alteração nesta situação. Por favor entre em contato com seu representante para personalizar os módulos."
                  : "Escolha o plano que mais se encaixa com o seu negócio."
                }

              </Typography>
            </Grid>
          </div>

          <CarouselPlanosSaurus setIsPlanoAvulso={setIsPlanoAvulso} pathToRedirect="/" />

          <Box mt={3}>
            <Button
              onClick={rejeitarPlano}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <CancelarIcon tipo="BUTTON_PRIMARY" />
              {isPlanoAvulso
                ? "Fazer Logout"
                : "Cancelar e Fazer Logout"
              }

            </Button>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default SelecionarPlanoPage;
