import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPromocaoExiste() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPromocaoExiste = useCallback(
    () => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/promocao/existe`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPromocaoExiste,
  };
}
