import { forwardRef, useImperativeHandle, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormGerenciarCartaoConvenioValidation } from './form-gerenciar-cartao-convenio-validation';
import { GerenciarCartaoConvenioFormModel } from 'model/app/forms/convenio/gerenciar-cartao-convenio-form-model';
import { AutocompletePessoas, EnumNomeCnpj } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { stringNumeros } from 'utils/string-numeros';
import { QrCodeIcon } from 'views/components/icons';
import { useCadastros } from 'services/app';
import { Box, Button, Divider, Grid } from 'views/design-system';
import { EnumModeloAutenticacao } from 'model/enums/enum-modelo-autenticacao';
import { FormGerenciarCartaoConvenioProps } from './form-gerenciar-cartao-convenio-props';
import { EnumTpAutenticacaoConvenio } from 'model/enums/enum-tp-autenticacao-convenio';

export const FormGerenciarCartaoConvenio = forwardRef<
    DefaultFormRefs<GerenciarCartaoConvenioFormModel>,
    FormGerenciarCartaoConvenioProps
>(({ tipo, ...props }: FormGerenciarCartaoConvenioProps, ref) => {
    const utilClasses = makeUtilClasses();
    const refInputCnpjCpf = useRef<HTMLInputElement>(null);
    const { isMobile } = useThemeQueries();
    const { getConfigByCod } = useEmpresaAtual()
    const modeloAutenticacao = getConfigByCod(EnumEmpresaConfig.ModeloAutenticacao) as EnumModeloAutenticacao
    const naoAparecerSenha = modeloAutenticacao !== EnumModeloAutenticacao.NumeroCartaoSenha
    const { FormGerenciarCartaoConvenioValidation } = useFormGerenciarCartaoConvenioValidation(modeloAutenticacao);
    const [modelForm, setModelForm] = useState<GerenciarCartaoConvenioFormModel>(new GerenciarCartaoConvenioFormModel())
    const { abrirConvenioCartaoQrCode } = useCadastros()

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
    } = useForm<GerenciarCartaoConvenioFormModel>({
        resolver: yupResolver(FormGerenciarCartaoConvenioValidation),
        criteriaMode: 'all',
        mode: 'onChange',
        defaultValues: modelForm
    });

    const onSubmit = (values: GerenciarCartaoConvenioFormModel) => {
        values.numeroCartao = stringNumeros(values.numeroCartao)
        values.senha = naoAparecerSenha ? '' : values.senha
        props.onSubmit(values, modelForm);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputCnpjCpf.current?.focus();
        },
        fillForm: (model: GerenciarCartaoConvenioFormModel) => {
            if (!isMobile) refInputCnpjCpf.current?.focus();
            setModelForm(model)
            reset(model)
        },
    }));

    const gerarQrCode = () => {
        abrirConvenioCartaoQrCode({
            ...modelForm
        })
    }

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Box my={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="nomeCartao"
                                    control={control}
                                    render={({ field }) => (
                                        <AutocompletePessoas
                                            disabled={Boolean(modelForm.id || modelForm.bloquearPessoa) || props.loading}
                                            allowSubmit
                                            nomeCnpj={EnumNomeCnpj.Todos}
                                            label="Pessoa"
                                            error={Boolean(
                                                errors.nomeCartao && errors.nomeCartao.message,
                                            )}
                                            helperText={
                                                touchedFields.nomeCartao || errors.nomeCartao
                                                    ? errors.nomeCartao?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={value => {
                                                if (!value.isString) {
                                                    const pessoa: PessoaModel = value.value as PessoaModel
                                                    setValue('nomeCartao', pessoa.nome)
                                                    setValue('numeroCartao', pessoa.cpfcnpj)
                                                    setValue('pessoaId', pessoa.id)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {modeloAutenticacao !== EnumModeloAutenticacao.DocumentoCliente && (
                                <Grid item xs={naoAparecerSenha ? 12 : 7} sm={12}>
                                    <Controller
                                        name="numeroCartao"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={'CNPJ_CPF_GERAL'}
                                                disabled={props.loading || tipo === EnumTpAutenticacaoConvenio.Dependente}
                                                allowSubmit
                                                fullWidth
                                                variant="outlined"
                                                label="Número do Cartão"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(
                                                    errors.numeroCartao && errors.numeroCartao.message,
                                                )}
                                                helperText={
                                                    touchedFields.numeroCartao || errors.numeroCartao
                                                        ? errors.numeroCartao?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={naoAparecerSenha ? 6 : 5} sm={naoAparecerSenha ? 6 : 4}>
                                <Controller
                                    name="limite"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'DECIMAL'}
                                            disabled={props.loading || tipo === EnumTpAutenticacaoConvenio.Dependente}
                                            fullWidth
                                            variant="outlined"
                                            label="Limite (opcional)"
                                            manterMascara
                                            limite={10}
                                            allowSubmit
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder=""
                                            error={Boolean(
                                                errors.limite && errors.limite.message,
                                            )}
                                            helperText={
                                                touchedFields.limite || errors.limite
                                                    ? errors.limite?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            {!naoAparecerSenha && (
                                <Grid item xs={7} sm={4}>
                                    <Controller
                                        name="senha"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={'PASSWORD'}
                                                disabled={props.loading}
                                                fullWidth
                                                variant="outlined"
                                                label={`Senha`}
                                                allowSubmit
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder=""
                                                error={Boolean(
                                                    errors.senha && errors.senha.message,
                                                )}
                                                helperText={
                                                    touchedFields.senha || errors.senha
                                                        ? errors.senha?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={naoAparecerSenha ? 6 : 5} sm={naoAparecerSenha ? 6 : 4}>
                                <Controller
                                    name="dataValidade"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'DATA'}
                                            disabled={props.loading || tipo === EnumTpAutenticacaoConvenio.Dependente}
                                            fullWidth
                                            variant="outlined"
                                            label="Data de Validade"
                                            allowSubmit
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder=""
                                            error={Boolean(
                                                errors.dataValidade && errors.dataValidade.message,
                                            )}
                                            helperText={
                                                touchedFields.dataValidade || errors.dataValidade
                                                    ? errors.dataValidade?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            {modeloAutenticacao !== EnumModeloAutenticacao.DocumentoCliente && modelForm.id && (
                                <Grid item xs={12}>
                                    <Box mt={3}>
                                        <Button fullWidth variant="outlined" color="primary" onClick={gerarQrCode}>
                                            <QrCodeIcon tipo="BUTTON" /> Gerar QR Code do Cartão
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
