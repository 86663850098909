import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDateString } from 'utils/to-date';
import { useStyles } from './card-manifesto-styles';
import { ManifestoModel } from 'model/api/gestao/manifesto/manifesto-model';
import { toCurrency } from 'utils/to-decimal';
import classNames from 'classnames';
import { AcaoManifestoMock } from 'data/mocks/acao-manifesto-mock';
import { useThemeQueries } from 'views/theme';
import { EnumStatusManifesto } from 'model/enums/enum-status-manifesto';
import { Grid, Tooltip, Typography } from 'views/design-system';

export interface CardEntradaProps {
    model: ManifestoModel,
    onClick: (model: ManifestoModel) => any
    selected: boolean;
}

export const CardManifesto = ({
    model,
    onClick,
    selected,
}: CardEntradaProps) => {
    const classes = useDefaultCardStyles();
    const myClasses = useStyles()
    const { theme } = useThemeQueries()

    const horaFormatada = useCallback((val) => {
        const data = new Date(val);
        const hora = data.getHours();
        const minutos = data.getMinutes();
        return `${('00' + hora).substr(-2)}:${('00' + minutos).substr(-2)}`;
    }, []);

    const retornarNumeroSerieNotaFiscal = () => {
        let chave = model.chave;
        if ((!chave || chave.length < 40) || !chave.includes('NFe')) return null; // Verifica se a chave é válida

        let numero, serie;
        chave = chave.split(model.cnpj)[1]
        if (!chave) {
            return null
        }
        chave = chave.substring(2)

        serie = chave.substring(0, 3)
        numero = chave.substring(3, 12)
        let removerZeros = true;
        numero = Array.from(numero).filter(x => {
            if (x !== '0') {
                removerZeros = false;
                return true
            }
            if (removerZeros && x === '0') {
                return false
            }
            return true
        }).join('')

        return `${numero}/${serie}`;
    }

    const retornarCorStatus = useCallback((): string => {
        switch (model.statusManifesto) {
            case EnumStatusManifesto.EventosDeCienciaDaOperacao:
                return theme.palette.success.main;
            case EnumStatusManifesto.EventosDeConfirmacaoDeOperacao:
                return theme.palette.success.dark;
            case EnumStatusManifesto.EventosDeDesconhecimentoDaOperacao:
                return theme.palette.error.main;
            case EnumStatusManifesto.EventosDeOperacaoNaoRealizada:
                return theme.palette.error.dark;
            default:
                return theme.palette.warning.main;
        }
    }, [model.statusManifesto, theme.palette.success.main, theme.palette.success.dark, theme.palette.error.main, theme.palette.error.dark, theme.palette.warning.main]);

    const descricao = AcaoManifestoMock.find(x => x.Key === model.statusManifesto)?.Value || 'Sem Manifestação'

    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={async () => {
                    onClick(model);
                }}
            >
                <Tooltip arrow title={descricao ?? 'Não encontrado'} placement="right">
                    <div
                        className={classes.tagStatus}
                        style={{ background: retornarCorStatus() }}
                    ></div>
                </Tooltip>
                <Grid container spacing={1} className={classNames(classes.cardContent, myClasses.card)}>
                    <Grid item xs={7} lg={2}>
                        <Typography variant="caption" className={myClasses.cardTitle}>
                            Data do Manifesto
                        </Typography>
                        <Typography
                            variant="body1"
                            className={'celula-grid-value'}
                        >
                            {toDateString(new Date(model.dhDocumento + 'Z' as string))} {horaFormatada(model.dhDocumento + 'Z')}
                        </Typography>
                    </Grid>
                    {retornarNumeroSerieNotaFiscal() && <Grid item xs={3} lg={2}>
                        <Typography variant="caption" className={myClasses.cardTitle}>
                            Número
                        </Typography>
                        <Typography
                            variant="body1"
                            className={'celula-grid-value'}
                        >
                            {retornarNumeroSerieNotaFiscal()}
                        </Typography>
                    </Grid>}
                    <Grid item xs={12} lg={3}>
                        <Typography variant="caption" className={myClasses.cardTitle}>
                            Emitente
                        </Typography>
                        <Tooltip title={model.nome}>
                            <Typography
                                variant="body1"
                                className={'celula-grid-value'}
                            >
                                {model.nome || '-'}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={7} lg={2}>
                        <Typography variant="caption" className={myClasses.cardTitle}>
                            Situação da NF
                        </Typography>
                        <Typography
                            variant="body1"
                            className={'celula-grid-value'}
                        >
                            {model.integrado ? 'Importada' : 'Não Importada'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} lg={2}>
                        <Typography variant="caption" className={myClasses.cardTitle}>
                            Valor
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classNames('celula-grid-value', myClasses.bold)}
                        >
                            {toCurrency(model.vnf || 0)}
                        </Typography>
                    </Grid>
                </Grid>
                <Tooltip arrow title="Editar PDV">
                    <div className={classes.cardRightButton}>
                        <EditarIcon tipo="BUTTON" />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};