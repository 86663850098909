import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonLabel: {
        height: '100%'
    },
    content: {
        display: "flex",
        minHeight: 150,
        maxWidth: 140,
        minWidth: 140,
        margin: theme.spacing(1),
        background: theme.palette.common.white,
        boxShadow: '0px 1.8px 7px 0px #00000024',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            minHeight: 100,
            maxWidth: 130,
            minWidth: 130,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 100,
            maxWidth: 150,
            minWidth: 150,
        }
    },
    contentBox: {
        flex: 1,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'inherit',
        textAlign: 'inherit',
    },
    contentIcon: {
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 65,
        },
        [theme.breakpoints.down('xs')]: {
            height: 50,
        },
    },
    icon: {
        width: 32,
        height: 32,
        [theme.breakpoints.down('sm')]: {
            width: 26,
            height: 26,
        },
        [theme.breakpoints.down('xs')]: {
            width: 22,
            height: 22,
        },
    },
    contentTexto: {
        flex: 1,
        display: "flex",
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'inherit',
        [theme.breakpoints.down('xs')]: {
        },
    },
    red: {
        border: '1px solid red',
        borderColor: theme.palette.error.dark,
        '& p': {
            color: theme.palette.error.dark,
        }
    },
    green: {
        border: '1px solid red',
        borderColor: theme.palette.success.dark,
        '& p': {
            color: theme.palette.success.dark,
        }
    },
    destaque: {
        border: '1px solid red',
        borderColor: theme.palette.primary.dark,
        '& p': {
            color: theme.palette.primary.dark,
        }
    },
    moduloAtivo: {
        border: '1px solid red',
        borderColor: theme.palette.info.main,
        '& p': {
            color: theme.palette.info.main,
        }
    },
    text: {
        fontWeight: "bold",
        width: "100%",
        lineHeight: '16px',
        fontSize: 15,

        [theme.breakpoints.down('sm')]: {
            lineHeight: '14px',
            fontSize: 12,
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight: '12px',
            fontSize: 11,
        },
    },

    descricao: {
        marginTop: 8,
        lineHeight: '12px',
        fontSize: 10,
        fontWeight: "normal",
        [theme.breakpoints.down('sm')]: {
            marginTop: 4,
            lineHeight: '11px',
            fontSize: 9,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 4,
            lineHeight: '10px',
            fontSize: 9,
        },
    },
    btnModo: {
        '& svg': {
            width: 25,
            height: 25,
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                width: 22,
                height: 22,
            },
            [theme.breakpoints.down('xs')]: {
                width: 15,
                height: 15,
            },
        },
        width: 50,
        height: 50,
        padding: 0,
        position: 'absolute',
        top: 5,
        left: 5,
        borderRadius: '100%',

        [theme.breakpoints.down('sm')]: {
            top: -3,
            left: -3,
        },
        [theme.breakpoints.down('xs')]: {
            top: -4,
            left: -4,
        },
    },
    btnFavorito: {
        '& svg': {
            width: 30,
            height: 30,
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                width: 28,
                height: 28,
            },
            [theme.breakpoints.down('xs')]: {
                width: 20,
                height: 20,
            },
        },
        width: 50,
        height: 50,
        padding: 0,
        position: 'absolute',
        top: 5,
        right: 5,
        borderRadius: '100%',
        [theme.breakpoints.down('sm')]: {
            top: -3,
            right: -3,
        },
        [theme.breakpoints.down('xs')]: {
            top: -4,
            right: -4,
        },
    },

}));

