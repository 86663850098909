import { DefaultFormRefs } from "views/components/form/utils";
import { useRef, useCallback, useEffect, useState } from "react";
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { useToastSaurus, useCadastros, useSessaoAtual, } from 'services/app';
import { SalvarEditarIcon, SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { usePostMarca } from "data/api/gestao/marca/post-marca";
import { MarcaModel } from "model/api/gestao/marca/marca-model";
import { nomeMarcaAmbiente } from "utils/nome-marca-ambiente";
import { FormMarcaCadastro } from "views/components/form/marca/form-marca-cadastro/form-marca-cadastro";
import { Button, Grid } from "views/design-system";

export const MarcaCadastro = () => {
  const { postMarca, carregando: carregandoPost } = usePostMarca();
  const { fecharCadastroMarca, abrirCadastroMarca } = useCadastros();
  const cadMarcaFormRef = useRef<DefaultFormRefs<MarcaModel>>(null);
  const [formInSubmit, setFormInSubmit] = useState(false);
  const redirect = useRef<boolean>(true);
  const classes = useModalStyles();
  const { plano, usuario } = useSessaoAtual()
  const carregando = carregandoPost || formInSubmit;
  useEffect(() => {
    cadMarcaFormRef.current?.fillForm(new MarcaModel());
  }, []);

  const history = useHistory();
  const pathName = history.location.pathname;

  const { showToast } = useToastSaurus();

  const saveNewMarca = useCallback(
    async (model: MarcaModel) => {
      try {
        model.contratoId = usuario?.empresa?.[0].ContratoId || ''
        const ret = await postMarca(model);
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          abrirCadastroMarca(ret.resultado?.data.id, pathName, true);
        } else {
          cadMarcaFormRef.current?.resetForm();
        }
        if (!redirect.current) {
          showToast("success", `${nomeMarcaAmbiente(plano?.plano, true)} adicionado com sucesso!`, );
        }
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [usuario?.empresa, postMarca, abrirCadastroMarca, pathName, showToast, plano?.plano]
  );
  const handleSubmit = useCallback(
    async (model: MarcaModel) => {

      return saveNewMarca(model);
    },
    [saveNewMarca]
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadMarcaFormRef.current?.submitForm();
  }, []);
  const onCloseClick = useCallback(() => {
    fecharCadastroMarca();
  }, [fecharCadastroMarca]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Cadastro de " + nomeMarcaAmbiente(plano?.plano, true)}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div className={classNames(classes.contentForms, carregando ? classes.contentFormsLoading : undefined)}>
          <FormMarcaCadastro
            ref={cadMarcaFormRef}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            setCarregandoExterno={setFormInSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
