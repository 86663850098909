import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { useStyles } from './card-naoencontrado-styles';
import { Grid, Typography } from 'views/design-system';

export interface CardNaoEncontratoProps {
  mensagem: ReactNode | string;
  icon: ReactNode;
  removeMarginCard?: boolean;
  iconSize?: 'normal' | 'small';
}

export const CardNaoEncontrado = (props: CardNaoEncontratoProps) => {
  const classes = useDefaultCardStyles();
  const classesNaoEncontrado = useStyles();

  const isString = typeof props.mensagem === 'string';

  return (
    <>
      <DefaultCard hasTagStatus={false}>
        <div className={classNames(classes.cardContent)}>
          <div
            className={classNames(
              classes.celulaGridFull,
              props.removeMarginCard ? '' : classesNaoEncontrado.marginCard,
              classesNaoEncontrado.cardNaoEncontrado,
              props.iconSize === 'small'
                ? classesNaoEncontrado.cardNaoEncontradoSmall
                : undefined,
              'cardCelula'
            )}
          >
            <Grid container alignItems="center" justifyContent="center">
              {props.icon}
              {isString ? (
                <Typography
                  className={classNames(
                    'celula-grid-value',
                    classesNaoEncontrado.textCenter
                  )}
                  color="textPrimary"
                  variant="h4"
                >
                  {props.mensagem}
                </Typography>
              ) : (
                props.mensagem
              )}
            </Grid>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
