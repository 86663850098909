import classNames from "classnames"
import { LoadingOverlayProps } from "./loading-overlay-props"
import { useStyles } from "./loading-overlay-styles";

export const LoadingOverlay = (props: LoadingOverlayProps) => {
    const loadingOverlayStyles = useStyles();

    return (
        <div className={classNames(loadingOverlayStyles.overlay, props.variant === 'dark' ? loadingOverlayStyles.overlayDark : loadingOverlayStyles.overlayLight)}>
            <div className={classNames(loadingOverlayStyles.ripple, props.variant === 'dark' ? loadingOverlayStyles.rippleDark : loadingOverlayStyles.rippleLight)}></div>
        </div>
    );
};