import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        background: theme.palette.common.white,
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    divider: {
        width: "100%",
        background: theme.palette.primary.main,
        height: "2px!important",
    },
    containerInfoGeralPC: {
        height: "auto",
        flex: 1,
        minHeight: '100%',
        background: '#FFF',
        boxShadow: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 8px rgba(96, 97, 112, 0.16)",
        padding: theme.spacing(2),
        borderRadius: "8px",
    },
    containerInfo: {
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    dividersub: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderBottom: `1px solid #DCDCDC`,
    },
    header: {
        flex: "0 1 auto"
    },
    containerInfoWidth: {
        maxWidth: '650px',
        flex: 1,
        margin: theme.spacing(2),
    },
    cardInfoPreview: {
        background: '#FFFFFF',
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 8px rgba(96, 97, 112, 0.16)",
        borderRadius: "8px",

    },
    containerInfoPreview: {
        display: 'flex',
        padding: theme.spacing(1),
        flexDirection: 'column',
        borderRadius: "8px",
        backgroundColor: theme.palette.grey[100],
        marginBottom: theme.spacing(1),
    },
    containerInfoPreviewBlue: {
        backgroundColor: 'rgba(31, 156, 228, 0.04)',
        padding: theme.spacing(1),
        borderRadius: "8px",
        marginBottom: theme.spacing(1),

    },
    containerPrice: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        lineHeight: '2rem',
        fontWeight: 500,
    },
    labelTotais: {
        lineHeight: '2rem',
        fontWeight: 700,
    },
  
    labelPagamento: {
        fontSize: '1.125rem',
        lineHeight: '0.8rem',
        fontWeight: 600
    },
    tags: {
        flexWrap: 'wrap',
        gap: 4,
        margin:2
    },
    tagProdutos: {
        cursor:'default',
        background: theme.palette.text.hint,
        padding: '2px 8px',
        borderRadius: '20px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& span": {
            fontSize: 10,
        },
        "& svg": {
            width: 14,
            marginRight: 4,
            height: 14,
            fill: theme.palette.text.primary,
        }
    },
    tagCodigos: {
        background: "#def3bd",
        color: theme.palette.text.primary
    },
    tagTarja: {
        background: "#f9e8d2",
        color: theme.palette.text.primary,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagTipoMedicamento: {
        background: "#f7e0f3",
        color: theme.palette.text.primary
    },
    tagComposicao: {
        background: "#e2dbf7",
        color: theme.palette.text.primary,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagLaboratorio: {
        background: "#efefef",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagLaboratorioDestaque: {
        background: "#fdf6d0",
        "& span": {
            fontWeight: 600,
        }
    },
    tagClasse: {
        background: "#e5f3ee",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagDesconto: {
        background: "#e0ebff",
        color: theme.palette.text.primary
    },
    containerTexto: {
        padding: '4px 0px'
    },
    texto: {
        textDecoration: 'underline',
        display: 'flex'
    },
    containerInativar: {
        position: 'absolute',
        top: 4,
        right: 4
    },
    textCod: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '6px'
    },
    containerValorUnidade: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    textVUnitario: {
        fontWeight: 'bold',
        marginTop: -4,
    },
    textVUnitarioPequeno: {
        fontSize: 14,
        marginTop: -4,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        }
    },
    textVUnitarioDe: {
        textDecoration: 'line-through',
    },
    textVTotal: {
        fontSize: '10px',
        fontWeight: 600,
        marginRight: '8px'
    },

    subItensContainer:{
        padding:'0px 8px'
    },
    gridTopicos:{
        margin: theme.spacing(1, 0),
        '&:first-child':{
            marginTop: 0
        },
        '&:last-child':{
            marginBottom: 0
        }
    }

}));
