import { useCallback, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { FiltroIcon, MenuIcon, ProcurarIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { useThemeQueries } from 'views';
import { useHistory, useLocation } from 'react-router-dom';
import { FormPesquisaHeader } from 'views/components/form/form-pesquisa-header/form-pesquisa-header';
import { isEmpty } from 'lodash';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { Box } from 'views/design-system';

export const ComissaoHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory();
  const location = useLocation();
  const { abrirComissoesFiltroModal } = useFiltrosModais();

  const urlParams = new URLSearchParams(location.search);

  const filtros = {
    status: isEmpty(urlParams.get('status'))
      ? 0
      : Number(urlParams.get('status')),
    termo: urlParams.get('termo') || ''
  };

  const [openTextfield, setOpenTextfield] = useState<boolean>(true);

  const leftArea = useCallback(
    () => (
      <>
        {isMobile &&
        !location.pathname.includes('/adicionar') &&
        !location.pathname.includes('/pesquisar') ? (
          <ButtonPrivateHeader
            icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
            tooltip="Menu"
            onClick={abrirMenu}
          ></ButtonPrivateHeader>
        ) : null}
      </>
    ),
    [isMobile, location.pathname, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      <Box flex gap={8}>
        {!openTextfield && (
          <ButtonPrivateHeader
            icon={<ProcurarIcon tipo="PRIVATE_HEADER" />}
            tooltip={'Abrir Pesquisa'}
            onClick={() => setOpenTextfield(true)}
          ></ButtonPrivateHeader>
        )}
        <ButtonPrivateHeader
          icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
          tooltip={'Abrir Filtros'}
          onClick={() => {
            abrirComissoesFiltroModal({
              status: filtros.status,
              termo: filtros.termo
            });
          }}
        ></ButtonPrivateHeader>
      </Box>
    ),
    [abrirComissoesFiltroModal, filtros.status, filtros.termo, openTextfield]
  );

  const searchTextfield = (
    <FormPesquisaHeader
      text={filtros.termo}
      closeSearch={() => setOpenTextfield(false)}
      onSubmit={(model) => {
        const searchs: Array<string | null> = [
          model.generico.length > 0 ? `termo=${model.generico}` : null,
          filtros.status > -1 ? `status=${filtros.status}` : null
        ];

        let query = '';

        searchs
          .filter((x) => x)
          .forEach((x, i) =>
            i === 0 ? (query += `?${x}`) : (query += `&${x}`)
          );

        history.push({
          pathname: '/comissoes',
          search: query
        });
      }}
      placeholder="Buscar comissão"
    />
  );

  return (
    <>
      <PrivatePageHeader
        title="Campanha de Comissão"
        leftArea={leftArea()}
        middleArea={openTextfield && searchTextfield}
        rightArea={
          (location.pathname === '/comissoes' ||
            location.pathname === '/comissoes/') &&
          rightArea()
        }
      />
    </>
  );
};
