import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fafafa",
    },
    container: {
        minWidth: 260,
        maxWidth: 395,
    },
    title:
    {
        fontSize: '18px',
        marginBottom: theme.spacing(1) * -1,
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
    },
    text: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '28px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing(2),
    },
    radio: {
        paddingLeft: theme.spacing(1),
        height: 32,
        paddingRight: theme.spacing(1),
        "& svg": {
            width: 32,
            height: 32
        }
    },
}))