import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContent: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    icon: {
        width: 56,
        height: 56,
        margin: 0,
    },
    btn: {
        minHeight: 160
    },
    btnTitle: {
        fontWeight: 600
    },
    divider: {
        marginTop: theme.spacing(2)
    }
}));
