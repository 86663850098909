import { useMemo, useCallback } from "react";
import classNames from "classnames";
import { IconsHelper } from "views/components/icons-helper";
import { useStyles } from "./list-modulo-filho-styles";
import { useAcoesMenu } from "./acoes-menu/acoes-menu";
import { ListModuloFilhoProps } from "./list-modulo-filho-props";
import { Grid, Tooltip, Typography } from "views/design-system";
import React from "react";
import { useSessaoAtual } from "services/app";
import { NewPropagandaIcon } from "views/components/icons/new-propaganda-icon";
import { OfertaIcon } from "views/components/icons/oferta-icon";
import { isPlanoAtivo } from "utils/plano-utils";
import { useHistory } from "react-router-dom";
import { handleClickFeedbackTatil } from "utils/feedback-tatil-fn";
import { LoadingOverlay } from "views/design-system/loading-overlay/loading-overlay";

export const ListModuloFilho = (props: ListModuloFilhoProps) => {
  const { ...listItemProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();
  const { selectAction } = useAcoesMenu();
  const { plano } = useSessaoAtual();
  const history = useHistory();

  const onClickItem = useCallback(() => {
    if (!isPlanoAtivo(props.codigoModulo, plano?.plano)) {
      const redirectTo = '/alterar-plano';
      history.push(redirectTo)
    } else {
      selectAction(listItemProps.codigoModulo, listItemProps.rota);
    }
  }, [props.codigoModulo, plano?.plano, history, selectAction, listItemProps.codigoModulo, listItemProps.rota]);

  const isNew = useCallback((dtLimiteIcone: Date) => {
    if (isPlanoAtivo(props.codigoModulo, plano?.plano)) {
      if (new Date() <= dtLimiteIcone) {
        return true
      }
    }
    return false;
  }, [plano?.plano, props.codigoModulo]);

  const component = React.useMemo(() => {
    return (<>
      <Grid flex alignItems="center" flexDirection="row" id={props.id} className={props.isFavorite ? classes.favoriteRoot : classes.root} onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (loading) return;
        await handleClickFeedbackTatil();
        setLoading(true);

        //OBS: setTimeout para garantir que vai dar tempo de fazer o render do botão antes de iniciar o processo do click e travar o DOM
        setTimeout(async () => {
          await onClickItem();
          setLoading(false);
        }, 20);

      }}>
        {loading && (
          <LoadingOverlay variant={'light'} />
        )}
        <Tooltip arrow title={listItemProps.nomeModulo} placement="right">
          <Grid className={classes.iconContainer}>
            <IconsHelper
              codigoModulo={listItemProps.codigoModulo} class={props.isFavorite ? classes.favoriteIcon : classes.icon} tipo="GERAL"
            />
          </Grid>
        </Tooltip>
        <div className={classes.containerNomeDescricao}>
          {props.modeloAtual === "Completo" && (
            <Typography className={classNames(classes.text, props.isFavorite && classes.favoriteText)} variant="body1" color="textPrimary" noWrap>
              {listItemProps.nomeModulo}
            </Typography>
          )}

          {props.descricaoModulo?.length! > 0 && (
            <>
              <Typography className={classNames(classes.containerDecricaoModulo, classes.descricaoText)} variant="body1" color="textPrimary" noWrap>
                {listItemProps.descricaoModulo}
              </Typography>
            </>
          )}
        </div>

        {isPlanoAtivo(props.codigoModulo, plano?.plano) ? (
          <>
            {(props.dataValidade && isNew(new Date(props.dataValidade))) && (
              <NewPropagandaIcon
                style={{
                  width: '45px',
                  height: '45px',
                  marginRight: '8px',
                  marginLeft: '4px',
                  marginBottom: '0'
                }} />
            )}
          </>
        ) :
          <OfertaIcon
            style={{
              width: '55px',
              height: '55px',
              marginRight: '8px',
              marginLeft: '4px',
              marginBottom: '0'
            }} />}
      </Grid >
    </>)
  },
    [props.id, props.isFavorite, props.modeloAtual, props.descricaoModulo?.length, props.codigoModulo, props.dataValidade, classes.favoriteRoot, classes.root, classes.iconContainer, classes.favoriteIcon, classes.icon, classes.containerNomeDescricao, classes.text, classes.favoriteText, classes.containerDecricaoModulo, classes.descricaoText, loading, listItemProps.nomeModulo, listItemProps.codigoModulo, listItemProps.descricaoModulo, plano?.plano, isNew, onClickItem],
  );

  return component;
};
