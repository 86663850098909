export const MovRotasMock = {
    baseRota: '/venda-simples',
    catalogoRota: '/venda-simples/catalogo',
    lancamentoAvulsoRota: '/venda-simples/avulso',
    produtoLeitorRota: '/venda-simples/leitor',
    pagamentoHistoricoRota: "/venda-simples/pagamentos",
    novoPagamentoRota: "/venda-simples/novo-pagamento",
    enviarPedidoRota: "/venda-simples/enviar-pedido",
    finalizarVendaRota: "/venda-simples/finalizar-venda",
    finalizarVendaRejeicaoRota: "/venda-simples/finalizar-venda/rejeicao",
    finalizarVendaDetalheRota: "/venda-simples/finalizar-venda/detalhes-venda",
    finalizarVendaComprovanteRota: "/venda-simples/finalizar-venda/comprovante",
    identificarClienteRota: "/venda-simples/identificar-cliente",
    carrinhoRota: '/venda-simples/carrinho',
    identificarVendedorRota: '/venda-simples/selecao-vendedor',
    cancelarRota: '/venda-simples/cancelar',
    landingRota: '/venda-simples/landing',
    historicoVendas: '/venda-simples/historico',
    atendimentoDeliveryRota: '/venda-simples/atendimento-delivery',
    pedidosDeliveryRota: '/venda-simples/pedidos/delivery',
    identificarClienteDelivery: '/venda-simples/identificar-cliente-delivery',
    entregaFreteDelivery: '/venda-simples/entrega-frete',

    pedidoMesaVincularRota: '/venda-simples/pedido-vincular-mesa',
    pedidoComandaVincularRota: '/venda-simples/pedido-vincular-comanda',
    pedidoIdentificadorVincularRota: '/venda-simples/pedido-vincular-identificador',
    pedidosRota: '/venda-simples/pedidos',
    pedidosMesas: '/venda-simples/pedidos/mesas',
    pedidosBalcao: '/venda-simples/pedidos/balcao',
    pedidosComandas: '/venda-simples/pedidos/comandas',
    pedidosEntrada: '/venda-simples/gerar-mesa-comanda',
    pedidoAlterarVendedor: '/venda-simples/alterar-vendedor',

    pagamentoProcessarRota: '/venda-simples/processando-pagamento',
    finalizacaoParcialRota: "/venda-simples/finalizacao-parcial",
    visualizarPedidoRota: "/venda-simples/visualizar-pedido",
    mesaEComandaRota: "/venda-simples/mesa-e-comanda",
    transferirItensRota: "/venda-simples/transferir-itens",
    fechamentoComandaRota: "/venda-simples/fechamento-comanda",
    fecharComandaRota: "/venda-simples/fechar-comanda",
    importarPedidosRota: "/venda-simples/importar-pedidos",
    cancelarImportacaoRota: "/venda-simples/cancelar-importacao",
    finalizarPedidosRota: "/venda-simples/finalizar-pedidos",
    selecionarPDVRota: "/venda-simples/selecionar-pdv",
    abrirCaixaRota: "/venda-simples/abrir-caixa",
}

//MOCK PARA FINS DE VALIDAÇÃO:
export const MovRotasComCarrinho = [
    MovRotasMock.carrinhoRota,
    MovRotasMock.catalogoRota,
    MovRotasMock.produtoLeitorRota,
    MovRotasMock.lancamentoAvulsoRota,
]