import { useCallback, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { MenuIcon, ProcurarIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { useThemeQueries } from 'views';
import { useHistory } from 'react-router-dom';
import { FormPesquisaHeader } from 'views/components/form/form-pesquisa-header/form-pesquisa-header';
import { useSessaoAtual } from 'services/app';
import { nomeMarcaAmbiente } from 'utils/nome-marca-ambiente';
import { isPlanoFarmaceutico } from 'utils/plano-utils';

export const MarcaHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory();
  const { plano } = useSessaoAtual();

  const [openTextfield, setOpenTextfield] = useState<boolean>(true);

  const urlParams = new URLSearchParams(history.location.search);
  const filtros = {
    nome: urlParams.get('descricao')
  };

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<ProcurarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Pesquisar"
        onClick={() => setOpenTextfield(true)}
      ></ButtonPrivateHeader>
    ),
    []
  );

  const searchTextfield = (
    <FormPesquisaHeader
      text={filtros.nome || ''}
      closeSearch={() => setOpenTextfield(false)}
      onSubmit={(model) => {
        const searchs: Array<string | null> = [
          model.generico.length > 0 ? `nome=${model.generico}` : null
        ];

        let query = '';

        searchs
          .filter((x) => x)
          .forEach((x, i) =>
            i === 0 ? (query += `?${x}`) : (query += `&${x}`)
          );

        history.push({
          pathname: '/marcas',
          search: query
        });
      }}
      placeholder={
        'Buscar ' +
        (isPlanoFarmaceutico(plano?.plano) ? 'laboratório/marca' : 'marca') +
        ' pelo nome...'
      }
    />
  );

  return (
    <>
      <PrivatePageHeader
        title={nomeMarcaAmbiente(plano?.plano, true, true)}
        leftArea={leftArea()}
        middleArea={openTextfield && searchTextfield}
        rightArea={!openTextfield && rightArea()}
      />
    </>
  );
};
