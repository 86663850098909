import { KeyValueModel } from "model/api";
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";

export const StatusConvenioMock: KeyValueModel[] = [
    new KeyValueModel(EnumConvenioStatus.Ativo, "Ativado"),
    new KeyValueModel(EnumConvenioStatus.Bloqueado, "Bloqueado"),
    new KeyValueModel(EnumConvenioStatus.Inativo, "Inativo"),
];

export const StatusConvenioMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    ...StatusConvenioMock
];
