import { guidEmpty } from "utils/guid-empty";
import { CartaoConvenioModel } from "./cartao-convenio-model";
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";

export class ConvenioModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public diaFechamento: number = 0,
        public diasParaPagamento: number = 0,
        public contratoId: string = '',
        public status: EnumConvenioStatus = EnumConvenioStatus.Ativo,
        public diaFechamentoUltimaAlteracao: string = '',
        public cartoes: CartaoConvenioModel[] = []
    ) { }
}