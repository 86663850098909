import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Tooltip } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { toDecimal, toDecimalString } from '../../../../../../utils/to-decimal';
import { useConfirm } from 'material-ui-confirm';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormMedicamentoPrecoValidation } from './form-medicamento-preco-validation';
import { ProdutoMedicamentoPrecoFormModel } from 'model/app/forms/produto/medicamento/medicamento-preco-form-model';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { isEmpty } from 'lodash';
import { InformacaoIcon } from 'views/components/icons';
import { useStyles } from '../../form-produto-edit/produto-edit-principal/form-produto-principal-edit-styles'
import { Button, Grid, Typography } from 'views/design-system';
export interface FormMedicamentoPrecoProps
    extends DefaultFormProps<ProdutoMedicamentoPrecoFormModel> {
    valoresEncontrados: boolean;
    sugeriuPrecos: boolean;
}

export const FormMedicamentoPreco = forwardRef<
    DefaultFormRefs<ProdutoMedicamentoPrecoFormModel>,
    FormMedicamentoPrecoProps
>(({ loading, valoresEncontrados = false, sugeriuPrecos = false, ...props }: FormMedicamentoPrecoProps, ref) => {
    const utilClasses = makeUtilClasses();
    const { getEmpresaAtual } = useEmpresaAtual();
    const refInputValorCompra = useRef<HTMLInputElement>(null);
    const refInputValorVenda = useRef<HTMLInputElement>(null);
    const refDatas = useRef({ dtInicial: '', dtFinal: '' })
    const [initialValues, setInitialValues] =
        useState<ProdutoMedicamentoPrecoFormModel>(
            new ProdutoMedicamentoPrecoFormModel(),
        );
    const { isMobile } = useThemeQueries();
    const classes = useStyles();
    const confirm = useConfirm();
    const { FormMedicamentoPrecoYupValidation } =
        useFormMedicamentoPrecoValidation();

    const showConfirm = useCallback(
        (campo: string) => {
            return confirm({
                title: `Campo "${campo}" sem valor!`,
                description: `Deseja manter o campo "${campo}" sem valor?`,
                cancellationText: 'Não',
                confirmationText: 'Sim',
            });
        },
        [confirm],
    );
    const calcularPLucro = useCallback((vPreco: number, vCompra: number) => {
        const pLucro = ((vPreco - vCompra) / vCompra) * 100;
        return toDecimalString(isNaN(pLucro) ? 0 : pLucro, 2);
    }, []);

    const calcularValorFinal = useCallback((vCompra: number, pLucro: number) => {
        return toDecimalString((vCompra * pLucro) / 100 + vCompra);
    }, []);

    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
        setValue,
        reset,
    } = useForm<ProdutoMedicamentoPrecoFormModel>({
        defaultValues: { ...initialValues },
        resolver: yupResolver(FormMedicamentoPrecoYupValidation),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = async (
        values: ProdutoMedicamentoPrecoFormModel,
    ) => {
        const model = picker<ProdutoMedicamentoPrecoFormModel>(
            values,
            new ProdutoMedicamentoPrecoFormModel(),
        );

        let confirmVPreco = true;

        if (toDecimal(model.vPreco) === 0) {
            await showConfirm('Valor Venda').catch(() => {
                confirmVPreco = false;
                if (!isMobile) refInputValorVenda.current?.focus();
                return;
            });
            if (!confirmVPreco) {
                return;
            }
        }
        if (!isEmpty(getEmpresaAtual()?.uf)) {
            model.uf = getEmpresaAtual()!.uf;
        }
        props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            setInitialValues(new ProdutoMedicamentoPrecoFormModel());
            if (!isMobile) refInputValorCompra.current?.focus();
            reset();
        },
        fillForm: (model: ProdutoMedicamentoPrecoFormModel) => {
            if (Boolean(model.dataFinal) &&
                Boolean(model.dataInicial) &&
                model.dataFinal.includes('T') &&
                model.dataInicial.includes('T')
            ) {
                model.dataFinal = model.dataFinal.split('T')[0]
                model.dataInicial = model.dataInicial.split('T')[0]
                refDatas.current = { dtFinal: model.dataFinal.split('T')[0], dtInicial: model.dataInicial.split('T')[0] }
            }
            model = {
                ...model,
            }
            if (!sugeriuPrecos) {
                if (!toDecimal(model.vCompra) && model.precoFabricacao) {
                    model.vCompra = toDecimalString(model.precoFabricacao);
                }
                if (!toDecimal(model.vPreco) && model.precoMaximoConsumidor) {
                    model.vPreco = toDecimalString(model.precoMaximoConsumidor);
                }

                model.pLucro = calcularPLucro(toDecimal(model.vPreco), toDecimal(model.vCompra))
            }
            setInitialValues(model);
            reset({ ...model });
            if (!isMobile) refInputValorCompra.current?.focus();
        },
    }));

    const calcularValores = useCallback(
        (event: any) => {
            setValue(event.target.name, event.target.value);
            const vCompra = toDecimal(
                event.target.name === 'vCompra'
                    ? event.target.value
                    : getValues('vCompra'),
            );
            const vPreco = toDecimal(
                event.target.name === 'vPreco'
                    ? event.target.value
                    : getValues('vPreco'),
            );
            const pLucro = toDecimal(
                event.target.name === 'pLucro'
                    ? event.target.value
                    : getValues('pLucro'),
            );

            if (event.target.name === 'pLucro') {
                setValue('vPreco', calcularValorFinal(vCompra, pLucro));
            } else {
                setValue('pLucro', calcularPLucro(vPreco, vCompra));
            }
        },
        [setValue, getValues, calcularValorFinal, calcularPLucro],
    );

    return (
        <>
            <div className={utilClasses.formContainer}>
                {loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="vCompra"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        inputRef={refInputValorCompra}
                                        tipo="DECIMAL"
                                        manterMascara
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        showStartAdornment
                                        label="Valor de Compra"
                                        endAdornmentButton={
                                            <Tooltip
                                                title={
                                                    <Typography
                                                        className={classes.tooltipText}
                                                        align="center"
                                                    >
                                                        Preço Fabricação:
                                                        {getValues('precoFabricacao') ? (
                                                            ' R$ ' + toDecimalString(
                                                                getValues('precoFabricacao')
                                                            )
                                                        ) : ' Não encontrado'}
                                                    </Typography>
                                                }
                                            >
                                                <div>
                                                    <InformacaoIcon
                                                        tipo="GERAL"
                                                        class={classes.infoIcon}
                                                    />
                                                </div>
                                            </Tooltip>
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ maxLength: 12 }}
                                        placeholder="(Opcional)"
                                        error={Boolean(errors.vCompra && errors.vCompra.message)}
                                        helperText={
                                            errors.vCompra
                                                ? errors.vCompra?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => calcularValores(event)}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="pLucro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        manterMascara
                                        disabled={loading}
                                        tipo="DECIMAL"
                                        fullWidth
                                        variant="outlined"
                                        label="Margem de Lucro (%)"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Percentual"
                                        error={Boolean(errors.pLucro && errors.pLucro.message)}
                                        helperText={
                                            errors.pLucro
                                                ? errors.pLucro?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => calcularValores(event)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="vPreco"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        inputRef={refInputValorVenda}
                                        tipo="DECIMAL"
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        manterMascara
                                        showStartAdornment
                                        endAdornmentButton={
                                            <Tooltip
                                                title={
                                                    <Typography
                                                        className={classes.tooltipText}
                                                        align="center"
                                                    >
                                                        Preço Máximo Consumidor:
                                                        {getValues('precoMaximoConsumidor') ? (
                                                            ' R$ ' + toDecimalString(
                                                                getValues('precoMaximoConsumidor')
                                                            )
                                                        ) : ' Não encontrado'}
                                                    </Typography>
                                                }
                                            >
                                                <div>
                                                    <InformacaoIcon
                                                        tipo="GERAL"
                                                        class={classes.infoIcon}
                                                    />
                                                </div>
                                            </Tooltip>
                                        }
                                        variant="outlined"
                                        label="Valor de Venda"
                                        inputProps={{ maxLength: 12 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="(Opcional)"
                                        error={Boolean(errors.vPreco && errors.vPreco.message)}
                                        helperText={
                                            errors.vPreco
                                                ? errors.vPreco?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => calcularValores(event)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});  