import { useTheme } from '@material-ui/core';
// import { TpBalcaoSalaoMock } from "data/mocks/tp-balcao-salao-mock";
import { TpStatusSalaoMock } from 'data/mocks/tp-status-salao-mock';
// import { EnumStatusSalao } from "model/enums/enum-status-salao";
// import { useCallback } from "react";
// import { EditarIcon } from "views/components/icons";
import { DefaultCard, useDefaultCardStyles } from '../components';
import { CardSetoresProps } from './card-setor-props';
import { useThemeQueries } from 'views';
import { EditarIcon } from 'views/components/icons';
import { Tooltip, Typography } from 'views/design-system';

export const CardSetores = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardSetoresProps) => {
  const classes = useDefaultCardStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries();

  const descricao = TpStatusSalaoMock.filter(
    (x) => x.Key === model?.descricao,
  )[0]?.Value;

  // const retornarCorStatus = useCallback((): string => {
  //   switch (model.descricao) {
  //     case EnumStatusSalao.ATIVO:
  //       return theme.palette.success.main;
  //     case EnumStatusSalao.INATIVO:
  //       return theme.palette.error.main;
  //     default:
  //       return theme.palette.primary.main;
  //   }
  // }, [
  //   theme.palette.success.main,
  //   theme.palette.error.main,
  //   theme.palette.primary.main,
  //   model.descricao,
  // ]);

  // const tpBalcao = TpBalcaoSalaoMock.filter(
  //   (x) => x.Key === model?.descricao,
  // );

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model?.id ?? '');
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: theme.palette.success.main }}
          ></div>
        </Tooltip>
        <div className={classes.cardContent}>
          <div
            className={
              isMobile
                ? classes.celulaGridFullMobile
                : classes.celulaGridFullDescriptionFull
            }
          >
            <Typography color="textPrimary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              style={{ textOverflow: 'ellipsis' }}
            >
              {model.descricao}
            </Typography>
          </div>
          {/* <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Balcão
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {tpBalcao[0]?.Value}
              </Typography>
            </div> */}
        </div>
        <Tooltip arrow title="Editar Setor">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
