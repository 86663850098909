import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 250,
        '& svg': {
            fill: theme.palette.secondary.main
        }
    },
    content: {
        background: '#f5f5f5'
    },
    sombraBaixo: {
        padding: theme.spacing(1)
    },
    status: {
    },
    boxStatus: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(.5, 0),
        fontWeight: 600,
        fontSize: 14,
        width: 130,
        textAlign: 'center',
    },
    value: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 1,
        textAlign: 'right',
    },
    title: {
        fontWeight: 500,
        fontSize: 18,
        color: "#949494",
    },
    titleRight: {
        textAlign: 'right'
    },
    contentStatus: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    acoes: {
        boxShadow: '1.82px 1.82px 7.29px 1.82px rgba(0, 0, 0, 0.08) inset',
    },
}))