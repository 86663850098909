import { useMovAtual } from './mov-atual';
import { useHistory } from 'react-router-dom';
import { useMovInsercaoStorage } from './mov-insercao-storage';
import { useCallback } from 'react';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { useCadastros } from './cadastros';
import { useEmpresaAtual } from './empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { consoleDev } from 'utils/console-dev';
import { useMovValidators } from './mov-validators';
import { MovRotasComCarrinho, MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { EnumMovModelo } from 'model';
import { isEmpty } from 'lodash';
import { useToastSaurus } from './toast-saurus';
import { usePedidoLocal } from './pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useDesktopMode } from './desktop-mode';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { PessoaModel } from 'model/api/gestao/pessoa';

export function useMovRota() {
  const {
    getMov,
    verificaProdutosControlados,
    naoInformarDadosDaReceita,
    setSalaoId,
    setMesaId,
    setComandaId,
    setIdentificadorPager
  } = useMovAtual();
  const { push, location } = useHistory();
  const { getInsercaoFavoritaRota } = useMovInsercaoStorage();
  const { getConfigByCod: getConfigEmp } = useEmpresaAtual();
  const {
    abrirDialogControlado,
    fecharDialogControlado,
    abrirReceita,
    fecharReceita
  } = useCadastros();
  const { showToast } = useToastSaurus();
  const {
    dadosClientePendente,
    dadosFaturamentoPendente,
    dadosIniciaisPendente,
    dadosPedidoComandaPendente,
    dadosPedidoIdentificadorPendente,
    dadosPedidoMesaPendente,
    dadosProdutosPendente,
    dadosPagamentoPendente,
    dadosProdutoEntregaPendente
  } = useMovValidators();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { getDesktopMode } = useDesktopMode();


  //VALIDADORES COM THROW
  const validarCondicaoInicialInterno = useCallback(
    async (movAtual: MovSimplesModel | undefined) => {
      if (await dadosIniciaisPendente(movAtual))
        throw new Error('Não foi possível identificar uma venda em Andamento.');
    },
    [dadosIniciaisPendente]
  );

  const validarCondicaoSelecionarClienteInterno = useCallback(
    async (movAtual: MovSimplesModel | undefined) => {
      await validarCondicaoInicialInterno(movAtual);
    },
    [validarCondicaoInicialInterno]
  );

  const validarCondicaoInicial = useCallback(async () => {
    const movAtual = getMov();
    if (await dadosIniciaisPendente(movAtual))
      throw new Error('Não foi possível identificar uma venda em Andamento.');
    return movAtual as MovSimplesModel;
  }, [dadosIniciaisPendente, getMov]);

  const validarInicioDoPedido = useCallback(async () => {
    const movAtual = getMov();
    if (
      movAtual?.mod !== EnumMovModelo.PEDIDO &&
      isEmpty(movAtual?.informacoesGeraisPedido.identificador)
    ) {
      await setSalaoId('');
      await setMesaId('');
      await setComandaId('');
      await setIdentificadorPager('');
      return false;
    }
    return true;
  }, [getMov, setComandaId, setIdentificadorPager, setMesaId, setSalaoId]);

  //REDIRECIONADORES
  const redirectLanding = useCallback(
    async (verificarCadastros: boolean = false, query: string = '') => {
      if (verificarCadastros)
        push(MovRotasMock.landingRota + '?verificarCadastros=true');
      else push(MovRotasMock.landingRota + query);
    },
    [push]
  );

  const redirectCatalogo = useCallback(() => {
    push(MovRotasMock.catalogoRota);
  }, [push]);

  const validarRedirectParaCarrinho = useCallback(() => {
    if (getDesktopMode()) {
      if (MovRotasComCarrinho.some(rota => location.pathname.includes(rota))) {
        return false;
      }
    }
    return true
  }, [getDesktopMode, location.pathname])

  const redirectLancadorPadrao = useCallback(async () => {
    const insercaoFavoritaRota = getInsercaoFavoritaRota();
    const movAtual = getMov();
    await validarCondicaoInicialInterno(movAtual);
    push(insercaoFavoritaRota ?? MovRotasMock.carrinhoRota);
  }, [getInsercaoFavoritaRota, getMov, push, validarCondicaoInicialInterno]);

  const redirectPagamentoHistorico = useCallback(async () => {
    const movAtual = getMov();
    await validarCondicaoInicialInterno(movAtual);

    push(MovRotasMock.pagamentoHistoricoRota);
  }, [getMov, push, validarCondicaoInicialInterno]);

  const redirectPagamentoAvancado = useCallback(async () => {
    const movAtual = getMov();
    await validarCondicaoInicialInterno(movAtual);

    push(MovRotasMock.novoPagamentoRota);
  }, [getMov, push, validarCondicaoInicialInterno]);

  const redirectSelecionarCliente = useCallback(
    async (state: any = undefined) => {
      const movAtual = getMov();
      await validarCondicaoSelecionarClienteInterno(movAtual);

      if (movAtual?.mod === EnumMovModelo.DELIVERY) {
        push({ pathname: MovRotasMock.identificarClienteDelivery });
      } else {
        push({ pathname: MovRotasMock.identificarClienteRota, state });
      }

    },
    [getMov, push, validarCondicaoSelecionarClienteInterno]
  );

  const redirectFinalizarVenda = useCallback(async () => {
    const movAtual = getMov();
    await validarCondicaoInicialInterno(movAtual);

    //SE TIVER TUDO CERTO COM A VENDA, E FOR UM PEDIDO, VAI PARA ENVIAR-PEDIDO    
    if (movAtual?.mod === EnumMovModelo.PEDIDO || movAtual?.mod === EnumMovModelo.DELIVERY) {
      push(MovRotasMock.enviarPedidoRota);
      return
    }
    if (movAtual && (movAtual?.vPago < movAtual?.vNF || isEmpty(movAtual.pags))) {
      push(MovRotasMock.novoPagamentoRota);
      return
    }

    push(MovRotasMock.finalizarVendaRota);

  }, [getMov, push, validarCondicaoInicialInterno]);

  const redirectCancelamento = useCallback(
    async (id: string = '', state: any = undefined, query: string = '') => {
      if (id.length > 0) {
        push({
          pathname: MovRotasMock.cancelarRota + '/' + id,
          state: state,
          search: query
        });
      } else {
        const movAtual = getMov();
        await validarCondicaoInicialInterno(movAtual);
        push({ pathname: MovRotasMock.cancelarRota, state: state });
      }
    },
    [getMov, push, validarCondicaoInicialInterno]
  );

  const redirectDelivery = useCallback(async () => {
    const movAtual = getMov();
    await validarCondicaoInicialInterno(movAtual);
    push(MovRotasMock.atendimentoDeliveryRota);
  }, [getMov, push, validarCondicaoInicialInterno]);

  const redirectCarrinho = useCallback(async () => {
    const movAtual = getMov();
    await validarCondicaoInicialInterno(movAtual);
    push(MovRotasMock.carrinhoRota);
  }, [getMov, push, validarCondicaoInicialInterno]);

  const redirectProcessarPagamento = useCallback(
    async (state: any = undefined, validarMov: boolean = true) => {
      const movAtual = getMov();
      if (validarMov) {
        await validarCondicaoInicialInterno(movAtual);
      }
      push({ pathname: MovRotasMock.pagamentoProcessarRota, state: state });
    },
    [getMov, push, validarCondicaoInicialInterno]
  );

  const redirectPedidoVincularMesa = useCallback(async () => {
    push(MovRotasMock.pedidoMesaVincularRota);
  }, [push]);

  const redirectPedidoVincularComanda = useCallback(async () => {
    push(MovRotasMock.pedidoComandaVincularRota);
  }, [push]);

  const redirectPedidoVincularIdentificador = useCallback(async () => {
    push(MovRotasMock.pedidoIdentificadorVincularRota);
  }, [push]);

  const redirectVendaFinalizadaDetalhe = useCallback(async () => {
    push(MovRotasMock.finalizarVendaDetalheRota);
  }, [push]);

  const redirectVendaFinalizadaComprovante = useCallback(async () => {
    push(MovRotasMock.finalizarVendaComprovanteRota);
  }, [push]);

  const redirectVendaFrete = useCallback(async () => {
    push(MovRotasMock.entregaFreteDelivery);
  }, [push]);

  const redirectVisualizarPedidoComanda = useCallback(async (comandaId: string) => {
    push({
      pathname: MovRotasMock.visualizarPedidoRota + '/' + comandaId,
      search: `?modo=comanda`
    })
  }, [push])

  //Estes são para navegar entre as páginas da visualização do pedido
  const redirectVisualizarPedido = useCallback((
    pedido: PedidoModel,
    search?: string,
  ) => {
    if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA) {
      push({
        pathname: `${MovRotasMock.visualizarPedidoRota}/${pedido.comandaId}`,
        search: search || location.search,
        state: {
          pedido: pedido
        }
      });
      return
    }
    if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA) {
      push({
        pathname: `${MovRotasMock.mesaEComandaRota}/${pedido.mesaId}`,
        search: search || location.search,
        state: {
          pedido: pedido,
        }
      })
      return
    }

    push({
      pathname: `${MovRotasMock.visualizarPedidoRota}/${pedido.id}`,
      search: search || location.search,
      state: {
        pedido: pedido
      }
    });
  }, [getConfiguracoesMesaEComanda, location.search, push])

  const redirectTransferirItensPedido = useCallback(({
    pedido,
    alterarVendedor,
    transferirPedido,
    vendedor,
  }: {
    pedido: PedidoModel,
    transferirPedido: boolean,
    alterarVendedor: boolean,
    vendedor?: PessoaModel,
  }) => {
    push({
      pathname: MovRotasMock.transferirItensRota,
      search: location.search,
      state: {
        pedido,
        transferirPedido,
        alterarVendedor,
        vendedor,
      },
    });
  }, [location.search, push])

  const redirectFluxoVenda = useCallback(() => {
    showToast('info', 'Você foi redirecionado para o fluxo de venda');
    redirectLanding();
  }, [redirectLanding, showToast]);

  //FLUXOS
  const avancarFluxoMedicamento = useCallback(async () => {
    await new Promise(async (resolve, reject) => {
      try {
        const medicamentos = await verificaProdutosControlados();
        const qtd = medicamentos.reduce(
          (acc, current) => acc + current.qCom,
          0
        );
        //SE NAO TEM MEDICAMENTOS PENDENTES
        if (qtd <= 0) {
          resolve(undefined);
          return;
        }

        //ABRE O DIALOG DE MEDICAMENTOS
        abrirDialogControlado(
          qtd,
          async () => {
            fecharDialogControlado();
            await naoInformarDadosDaReceita();
            resolve(undefined);
          },
          async () => {
            fecharDialogControlado();
            abrirReceita(async () => {
              fecharReceita();
              resolve(undefined);
            });
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  }, [
    abrirDialogControlado,
    abrirReceita,
    fecharDialogControlado,
    fecharReceita,
    naoInformarDadosDaReceita,
    verificaProdutosControlados
  ]);

  const avancarFluxoPedido = useCallback(
    async (movAtual: MovSimplesModel) => {
      if (movAtual.mod === EnumMovModelo.DELIVERY) {
        const clientePendente = await dadosClientePendente(movAtual);
        if (dadosProdutoEntregaPendente(movAtual)) {
          if (clientePendente) {
            await redirectSelecionarCliente()
            return false
          } else {
            await redirectVendaFrete();
            return false;
          }
        }
      }

      if (movAtual.mod === EnumMovModelo.PEDIDO) {
        const mesaOuComanda = getConfiguracoesMesaEComanda();

        if (await dadosPedidoComandaPendente(movAtual)) {
          await redirectPedidoVincularComanda();
          return false;
        }

        if (
          (await dadosPedidoMesaPendente(movAtual)) &&
          mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          await redirectPedidoVincularMesa();
          return false;
        }

        if (await dadosPedidoIdentificadorPendente(movAtual)) {
          await redirectPedidoVincularIdentificador();
          return false;
        }

        if (!validarInicioDoPedido()) {
          redirectFluxoVenda();
          return false;
        }
      }

      if (await dadosProdutosPendente(movAtual)) {
        redirectCatalogo();
        return false;
      }

      return true;
    },
    [dadosClientePendente, dadosPedidoComandaPendente, dadosPedidoIdentificadorPendente, dadosPedidoMesaPendente, dadosProdutoEntregaPendente, dadosProdutosPendente, getConfiguracoesMesaEComanda, redirectCatalogo, redirectFluxoVenda, redirectPedidoVincularComanda, redirectPedidoVincularIdentificador, redirectPedidoVincularMesa, redirectSelecionarCliente, redirectVendaFrete, validarInicioDoPedido]
  );

  const avancarFluxoMov = useCallback(async () => {
    consoleDev('avancarFluxoMov', location.pathname);
    const movAtual = getMov();

    const fluxoSelecaoMesaComandaIdentificador = getConfigEmp(
      EnumEmpresaConfig.SelecaoInfoPedido
    );
    const fluxoSelecaoCliente = getConfigEmp(
      EnumEmpresaConfig.SelecaoClienteVenda
    );

    if (movAtual === undefined) {
      await redirectLanding();
    }

    //SE OBRIGATORIO SELECIONAR CLIENTE NO INICIO DA VENDA
    const dadoClientePendente = await dadosClientePendente(movAtual);

    if (fluxoSelecaoCliente === '1' && dadoClientePendente) {
      await redirectSelecionarCliente();
      return;
    }

    if (
      (fluxoSelecaoMesaComandaIdentificador === '0' && movAtual?.mod === EnumMovModelo.PEDIDO) || movAtual?.mod === EnumMovModelo.DELIVERY
    ) {
      if (!await avancarFluxoPedido(movAtual!)) return;
    }

    if (await dadosProdutosPendente(movAtual!)) {
      await redirectLancadorPadrao();
      return;
    }

    //CASO NAO TENHA SIDO FATURADA, MANDO PARA O CARRINHO POIS FOI LÁ QUE DECIDI COLOCAR O FLUXO DE FATURAMENTO
    if (await dadosFaturamentoPendente(movAtual!) && validarRedirectParaCarrinho()) {
      await redirectCarrinho();
      return;
    }

    //RECOLOCADO POR DAVI 10/10/2024 POIS O FLUXO DE VENDA (SIMPLES) DEVERIA SER IR PARA O CARRINHO AO CLICAR NO FINALIZAR VENDA LÁ
    //NO CATALOGO OU NOS OUTROS MODOS DE INSERCAO
    //NAO TIRAR, AJUSTAR SE CAUSAR ALGUM PROBLEMA EM ALGUM LUGAR

    //CASO PAGAMENTO NÃO TENHA SIDO INICIADO, VAI PARA O CARRINHO
    if ((await dadosPagamentoPendente(movAtual!)) && movAtual!.vPago === 0 && validarRedirectParaCarrinho()) {
      await redirectCarrinho();
      return;
    }

    await avancarFluxoMedicamento();

    //CASO PAGAMENTO NÃO ESTEJA COMPLETO MAS TENHA SIDO INICIADO
    if ((await dadosPagamentoPendente(movAtual!))) {
      await redirectPagamentoAvancado();
      return;
    }

    const rotasFinalizadoras = [
      MovRotasMock.catalogoRota,
      MovRotasMock.produtoLeitorRota
    ];

    if (
      (rotasFinalizadoras.some((x) => location.pathname.includes(x)) ||
        (await dadosFaturamentoPendente(movAtual!))) && validarRedirectParaCarrinho()
    ) {
      await redirectCarrinho();
      return;
    }

    //SE OBRIGATORIO SELECIONAR CLIENTE NO FIM DA VENDA
    //SE ESTIVESSE CONFIGURADO PARA NAO EXIGIR O CLIENTE, O CLIENTE IDENTIFICADO FICARAIA = TRUE
    //E TBM JA RETORNARIA AQUI
    if (fluxoSelecaoCliente !== '1' && (await dadosClientePendente(movAtual))) {
      await redirectSelecionarCliente();
      return;
    }

    if (
      fluxoSelecaoMesaComandaIdentificador !== '0' &&
      (movAtual?.mod === EnumMovModelo.PEDIDO ||
        movAtual?.mod === EnumMovModelo.DELIVERY)
    ) {
      if (!avancarFluxoPedido(movAtual!)) return;
    }

    //SE TODAS CONDIÇÕES ESTIVEREM OK, FINALIZA A VENDA
    await redirectFinalizarVenda();
  }, [
    getMov,
    location.pathname,
    getConfigEmp,
    dadosClientePendente,
    dadosProdutosPendente,
    dadosFaturamentoPendente,
    dadosPagamentoPendente,
    avancarFluxoMedicamento,
    redirectFinalizarVenda,
    redirectLanding,
    redirectSelecionarCliente,
    avancarFluxoPedido,
    redirectLancadorPadrao,
    redirectCarrinho,
    redirectPagamentoAvancado,
    validarRedirectParaCarrinho
  ]);

  return {
    redirectLancadorPadrao,
    redirectCarrinho,
    redirectCatalogo,
    redirectSelecionarCliente,
    redirectDelivery,
    redirectPagamentoAvancado,
    redirectProcessarPagamento,
    redirectPagamentoHistorico,
    redirectFinalizarVenda,
    redirectVendaFinalizadaDetalhe,
    redirectVendaFinalizadaComprovante,
    redirectCancelamento,
    redirectLanding,
    redirectPedidoVincularMesa,
    redirectPedidoVincularComanda,
    redirectVendaFrete,
    redirectVisualizarPedidoComanda,
    redirectVisualizarPedido,
    redirectTransferirItensPedido,

    avancarFluxoMov,
    validarCondicaoInicial,
    validarInicioDoPedido
  };
}
