import { useCallback, useEffect, useRef, useState } from 'react';
import { LixoIcon, OkIcon, VoltarIcon } from 'views/components/icons';
import { isEqual } from 'utils/is-equal';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros, } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { PagamentoEditConteudoProps } from './pagamento-edit-conteudo-props';
import { DupCobrModel, PagsModel, VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { useStyles } from './pagamento-edit-conteudo-styles';
import { FormPagamentoEdit } from 'views/components/form/entrada/dados-pagamento-edit/form-pagamento-edit';
import { CardDupPagamento } from 'views/components/cards/card-dup-pagamento/card-dup-pagamento';
import { useConfirm } from 'material-ui-confirm';
import { useEntrada } from 'views/pages/private/entrada/hooks/entrada';
import { roundTo } from 'utils/round-to';
import { Button, Grid } from 'views/design-system';

export interface DupCobrPagamentoModel extends DupCobrModel {
  index: number
}

export const PagamentoEditConteudo = ({ id: pagId, mov }: PagamentoEditConteudoProps) => {
  const { showToast } = useToastSaurus();
  const modalClasses = useModalStyles();
  const classes = useStyles()
  const [dupModel, setDupModel] = useState<DupCobrPagamentoModel | null>(null)
  const confirm = useConfirm()
  const movModel = structuredClone(mov) as VendaCompletaModel
  const dups = movModel.infMov.cobr?.dup
  const dupsPorPagId = movModel.infMov.cobr?.dup.filter(dup => dup.pagId === pagId)
  const { saveChangesVendaCompleta, carregando } = useEntrada(mov)

  const { fecharPagamentoEditModal } = useCadastros();

  const formRef = useRef<DefaultFormRefs<DupCobrModel>>(null);

  useEffect(() => {
    if (movModel) {
      if (movModel.infMov.cobr) {
        const dupPagId = movModel.infMov.cobr.dup.filter(x => x.pagId === pagId);
        if (dupPagId.length === 1 && !dupModel) {
          const dupMov = movModel.infMov.cobr.dup.find(x => x.pagId === pagId)
          const dupForm = picker<DupCobrModel>(dupMov, new DupCobrModel())
          setDupModel({ ...dupForm, index: 0 })
          formRef.current?.fillForm(dupForm);
        }
      }
    }
  }, [dupModel, movModel, pagId]);

  const saveChanges = useCallback(
    async (modelForm: DupCobrModel) => {
      const movModel = structuredClone(mov) as VendaCompletaModel
      const dup = modelForm
      const dupMov = movModel.infMov.cobr.dup.find(x => x.nDup === dup.nDup)
      const index = movModel.infMov.cobr.dup.indexOf(dupMov!)
      movModel.infMov.cobr.dup[index] = dup

      const dupsPag = movModel.infMov.cobr.dup.filter(item => item.pagId === pagId)
      const valorPag = dupsPag.reduce<number>((prev, curr) => curr.vDup + prev, 0)

      const indexPag = movModel.infMov.pag.indexOf(movModel.infMov.pag.find(x => x.id === pagId) || new PagsModel())

      movModel.infMov.pag[indexPag].vPag = roundTo(valorPag);

      await saveChangesVendaCompleta(movModel);

      showToast('success', 'Parcela atualizada com sucesso!');
    },
    [mov, pagId, saveChangesVendaCompleta, showToast],
  );

  const handleSubmit = async (
    model: DupCobrModel,
    beforeModel: DupCobrModel,
  ) => {
    try {
      const pagEqual = isEqual(model, beforeModel);

      if (pagEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
      }

      await saveChanges(model);

      fecharPagamentoEditModal(true);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }

  const onCloseClick = useCallback(() => {
    if (dupsPorPagId && dupsPorPagId.length > 1 && dupModel) {
      setDupModel(null)
      return
    }
    fecharPagamentoEditModal();
  }, [dupModel, dupsPorPagId, fecharPagamentoEditModal]);

  const handleRemove = async () => {
    try {
      const movAtual = structuredClone(mov) as VendaCompletaModel
      if (!movAtual.infMov.pag) return;

      let pags = movAtual.infMov.pag;
      let dups = movAtual.infMov.cobr.dup.filter(x => {
        if (dupModel) {
          return x.nDup !== dupModel.nDup
        }
        return x.pagId !== pagId;
      })
      const parcelas = dups.filter(x => x.pagId === pagId);
      if (!parcelas || parcelas.length === 0) {
        pags = movAtual.infMov.pag.filter(x => x.id !== pagId)
      } else {

        dups = dups.map(dup => {
          if (dup.pagId === pagId) {
            const pag = pags.find(x => x.id === pagId);
            if (pag) {
              const vDup = roundTo(pag.vPag / parcelas.length);
              return { ...dup, vDup }
            }
          }
          return dup
        })

        const restante = mov.infMov.total.vnf - dups.reduce<number>((prev, curr) => prev + curr.vDup, 0)
        if (restante > 0 && restante < 1) {
          dups[dups.length - 1].vDup =
            roundTo(dups[dups.length - 1].vDup + restante);
        }

      }

      movAtual.infMov.cobr.dup = dups
      movAtual.infMov.pag = pags
      movAtual.infMov.UFSaidaPais =
        movAtual.infMov.UFSaidaPais &&
          movAtual.infMov.UFSaidaPais > 0
          ? movAtual.infMov.UFSaidaPais
          : null

      await saveChangesVendaCompleta(movAtual)

      showToast('success', 'Pagamento removido com sucesso')
      fecharPagamentoEditModal(true)
    } catch (error: any) {
      showToast('error', error.message)
    }
  }

  const handleEditarParcela = (model: DupCobrPagamentoModel) => {
    setDupModel(model)
  }

  const handleRemoveConfirm = () => {
    confirm({
      title: 'Remover Pagamento',
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      description: 'Para remover este pagamento, clique em confirmar.'
    }).then(() => handleRemove())
  }

  return (
    <div className={modalClasses.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title="Edição do Parcelamento"
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={modalClasses.content}>
        <div
          className={classNames(
            modalClasses.contentForms,
            carregando ? modalClasses.contentFormsLoading : undefined,
          )}
        >
          {!dupModel ? (
            <>
              {dupsPorPagId?.map((dup, index) => {
                return (
                  <CardDupPagamento
                    model={dup}
                    index={index}
                    onClick={handleEditarParcela}
                  />
                )
              })}
            </>
          ) : (
            <FormPagamentoEdit
              ref={formRef}
              onSubmit={handleSubmit}
              showLoading={false}
              loading={carregando}
              model={dupModel}
              numParcelas={dupsPorPagId.length}
            />
          )}
        </div>
        <div className={modalClasses.acoes}>
          <Grid container spacing={2}>
            {((dups && dups.length === 1) || dupModel) && (
              <Grid item xs={6} md={dups.length === 1 ? 6 : 12}>
                <Button
                  disabled={carregando}
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classes.btnRemover}
                  fullWidth
                  onClick={handleRemoveConfirm}
                >
                  <LixoIcon tipo="BUTTON" />
                  Excluir
                </Button>
              </Grid>
            )}
            {((dups && dups.length === 1) || dupModel) && (
              <Grid item xs={6} md={dups.length > 1 && dupModel ? 12 : 6}>
                <Button
                  disabled={carregando}
                  onClick={() => formRef.current?.submitForm()}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Confirmar
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div >
  );
};
