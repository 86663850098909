import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxHeight: '100%',
        overflow: 'hidden',
        background: theme.palette.background.default,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: theme.spacing(3, 2),
        gap: theme.spacing(2),
    },
    relative: {
        position: 'relative',
        height: '100%',
        overflowY: 'auto',

    },
    loadingContainer: {
        position: 'absolute',
        width: 'calc(100% - 2px)',
        height: '100%',
        zIndex: 999999
    },
}));