import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import ConvenioGerenciar from "./components/convenio-gerenciar/convenio-gerenciar";
import { PessoaModel } from "model/api/gestao/pessoa";

interface ConvenioModalProps extends ModalProps {
  openned: boolean
  id: string
  pessoa?: PessoaModel
}

export const ConvenioModal = (props: ConvenioModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && <ConvenioGerenciar {...props} />}
    </DefaultModal>
  );
};
