import { Tooltip, Typography } from '@material-ui/core';
import { CardPagamentoEntradaProps } from './card-pagamento-entrada-props';
import React, { useCallback } from 'react';
import {
  BoletoIcon,
  CartaoIcon,
  ChequeIcon,
  CreditoLojaIcon,
  DepositoBancarioIcon,
  DinheiroIcon,
  DuplicataMercantilIcon,
  QrCodeIcon,
  CartaoFidelidadeIcon,
  SemDinheiroIcon,
  TransferenciaBancariaIcon,
  ValeAlimentacaoIcon,
  ValeCombustivelIcon,
  ValePresenteIcon,
} from 'views/components/icons';
import { EditarIcon } from '../../icons/editar-icon';
import { TpModMock } from 'data/mocks/tp-mod-mock';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { EnumPagTpMod } from '../../../../model/enums/enum-pag-tpmod';
import { POSIcon } from 'views/components/icons/pos-icon';
import { toDecimalString } from 'utils/to-decimal';

export const CardPagamentoEntrada = ({
  model,
  onClick,
}: CardPagamentoEntradaProps) => {
  const classes = useDefaultCardStyles();
  const tpMod = TpModMock.filter((x) => x.Key === model.tPag)[0].Value;

  const cardIcon = useCallback(() => {
    switch (model.tPag) {
      case EnumPagTpMod.BOLETO_BANCARIO:
        return <BoletoIcon tipo="GERAL" />;
      case EnumPagTpMod.CARTAO_CREDITO:
      case EnumPagTpMod.CARTAO_DEBITO:
        return <CartaoIcon tipo="GERAL" />;
      case EnumPagTpMod.CHEQUE:
        return <ChequeIcon tipo="GERAL" />;
      case EnumPagTpMod.CREDITO_LOJA:
        return <CreditoLojaIcon tipo="GERAL" />;
      case EnumPagTpMod.DEPOSITO_BANCARIO:
        return <DepositoBancarioIcon tipo="GERAL" />;
      case EnumPagTpMod.DINHEIRO:
        return <DinheiroIcon tipo="GERAL" />;
      case EnumPagTpMod.DUPLICATA_MERCANTIL:
        return <DuplicataMercantilIcon tipo="GERAL" />;
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return <QrCodeIcon tipo="GERAL" />;
      case EnumPagTpMod.PROGRAMA_FIDELIDADE:
        return <CartaoFidelidadeIcon tipo="GERAL" />;
      case EnumPagTpMod.SEM_PAGAMENTO:
        return <SemDinheiroIcon tipo="GERAL" />;
      case EnumPagTpMod.TRANSFERENCIA_BANCARIA:
        return <TransferenciaBancariaIcon tipo="GERAL" />;
      case EnumPagTpMod.VALE_ALIMENTACAO:
      case EnumPagTpMod.VALE_REFEICAO:
        return <ValeAlimentacaoIcon tipo="GERAL" />;
      case EnumPagTpMod.VALE_COMBUSTIVEL:
        return <ValeCombustivelIcon tipo="GERAL" />;
      case EnumPagTpMod.VALE_PRESENTE:
        return <ValePresenteIcon tipo="GERAL" />;

      default:
        return <POSIcon tipo="GERAL" />;
    }
  }, [model.tPag]);

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <div className={classes.cardContent}>
          <Tooltip arrow title={tpMod}>
            <div className={classes.cardIcon}>{cardIcon()}</div>
          </Tooltip>
          <div className={classes.celulaGridFull}>
            <Typography color="textPrimary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.descricao}</strong>
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Valor Total
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>R$ {toDecimalString(model.vPag)}</strong>
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Pagamento">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardPagamentoEntrada);
