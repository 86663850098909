import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormComandaValidation } from './form-comandas-cadastro-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComandasGerarFormModel } from 'model/api/gestao/comanda/comanda-gerar-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpSubstituidoMock } from 'data/mocks/tp-substituir-mock';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { useStyles } from './form-comandas-cadastro-styles';
import { Button, Grid } from 'views/design-system';

export const FormComandasCadastro = forwardRef<
  DefaultFormRefs<ComandasGerarFormModel>,
  DefaultFormProps<ComandasGerarFormModel>
>((props: DefaultFormProps<ComandasGerarFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormComandaValidationYup } = useFormComandaValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<ComandasGerarFormModel>({
    defaultValues: { ...props.model },
    resolver: yupResolver(FormComandaValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ComandasGerarFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: (model: ComandasGerarFormModel) => { 
      reset({...model})
    },
  }));

  const classes = useStyles()
  const {callTour} = useTourSaurus()

  useEffect(()=>{
   setTimeout(()=>{callTour(EnumTour.COMANDASFORM)},500)
  },[callTour])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>

          <Grid item xs={12}>
              <Controller
                name="prefixo"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Prefixo"
                    autoComplete='new-password'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(
                      errors.prefixo && errors.prefixo.message,
                    )}
                    helperText={
                      touchedFields.prefixo || errors.prefixo
                        ? errors.prefixo?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            
            <Grid id="tour-CadComandas1" item xs={12}>
              <Grid item xs={12}>
                <Controller
                  name="comandaInicial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="NUMERO"
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Comanda Inicial"
                      autoComplete='new-password'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={'EX: 1'}
                      error={Boolean(
                        errors.comandaInicial && errors.comandaInicial.message,
                      )}
                      helperText={
                        touchedFields.comandaInicial || errors.comandaInicial
                          ? errors.comandaInicial?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid className={classes.comandaFinal} item xs={12}>
                <Controller
                  name="comandaFinal"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="NUMERO"
                      disabled={props.loading}
                      fullWidth
                      autoComplete='new-password'
                      variant="outlined"
                      label="Comanda Final"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={'EX: 10'}
                      error={Boolean(
                        errors.comandaFinal && errors.comandaFinal.message,
                      )}
                      helperText={
                        touchedFields.comandaFinal || errors.comandaFinal
                          ? errors.comandaFinal?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                name="temRFID"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Comanda com RFID"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={TpTemRFIDMock}
                    error={Boolean(errors.temRFID && errors.temRFID.message)}
                    helperText={
                      touchedFields.temRFID || errors.temRFID
                        ? errors.temRFID?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = TpTemRFIDMock.filter(
                        (item) => item.Key === event.target.value,
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('temRFID', item === 1 ? true : false);
                      }
                    }}
                    value={getValues('temRFID')}
                  />
                )}
              />
            </Grid>
            {getValues('temRFID') && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="prefixoRfId"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        disabled={props.loading}
                        autoComplete='new-password'
                        fullWidth
                        variant="outlined"
                        label="Prefixo RFID"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={'EX: 1234'}
                        error={Boolean(
                          errors.prefixoRfId && errors.prefixoRfId.message,
                        )}
                        helperText={
                          touchedFields.prefixoRfId || errors.prefixoRfId
                            ? errors.comandaFinal?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="inicialRfId"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        disabled={props.loading}
                        fullWidth
                        autoComplete='new-password'
                        variant="outlined"
                        label="RFID Inicial"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={'Ex: 100500'}
                        error={Boolean(
                          errors.inicialRfId && errors.inicialRfId.message,
                        )}
                        helperText={
                          touchedFields.inicialRfId || errors.inicialRfId
                            ? errors.inicialRfId?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="finalRfId"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        disabled={props.loading}
                        fullWidth
                        autoComplete='new-password'
                        variant="outlined"
                        label="RFID Final"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={'EX: 100510'}
                        error={Boolean(
                          errors.finalRfId && errors.finalRfId.message,
                        )}
                        helperText={
                          touchedFields.finalRfId || errors.finalRfId
                            ? errors.finalRfId?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </>
            )} */}
            <Grid item xs={12}>
              <Controller
                name="substituir"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Substituir Comandas"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={TpSubstituidoMock}
                    error={Boolean(
                      errors.substituir && errors.substituir.message,
                    )}
                    helperText={
                      touchedFields.substituir || errors.substituir
                        ? errors.substituir?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = TpSubstituidoMock.filter(
                        (item) => item.Key === event.target.value,
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('substituir', item === 1 ? true : false);
                      }
                    }}
                    value={getValues('substituir')}
                    id="tour-CadComandas2"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
