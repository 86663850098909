import { EnumAbaEdicaoComissao } from '../../comissao-edicao/comissao-edicao';
import { useStyles } from '../comissao-steps-styles';
import { RevisaoProdutos } from './components/revisao';

interface ComissaoProdutosProps {
  getProdutos: () => Promise<void>
  comissaoId: string
  isTabProduto: boolean
  selecionarAba: (aba: EnumAbaEdicaoComissao) => void
  veioDoCadastro: boolean
}

export const ComissaoProdutos = ({ ...props }: ComissaoProdutosProps) => {
  // HOOKS
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <RevisaoProdutos {...props} />
    </div>
  )
}

