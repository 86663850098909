import { DefaultFormRefs } from 'views/components/form/utils';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import { convertNumberToTimeString, convertTimeStringToNumber } from 'utils/corverter-horario';
import { FormPromocaoData, PromocaoDataFormModel } from './components/form-promocao-data';
import { usePromocaoDadosStore } from '../../hooks/use-promocao-dados-store';

interface PromocaoDataStepProps {
  submit: () => void;
}

export interface RefPropPromocaoDataStep {
  submit: () => Promise<void>;
}

export const PromocaoDataStep = forwardRef<RefPropPromocaoDataStep, PromocaoDataStepProps>((props: PromocaoDataStepProps, ref) => {
  const refCadastroDataPromocaoRef =
    useRef<DefaultFormRefs<PromocaoDataFormModel>>(null);

  const promocao = usePromocaoDadosStore((state) => state.state.promocao);
  const setPromocaoStep2 = usePromocaoDadosStore((state) => state.setPromocaoStep2);

  const recarregarForm = useCallback((model: PromocaoDataFormModel) => {
    refCadastroDataPromocaoRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm({
      dataFinal: promocao.dataFinal,
      dataInicial: promocao.dataInicial,
      diasSemana: promocao.diasSemana,
      horarioFinal: promocao.horarioFinal === null ? '' : convertNumberToTimeString(promocao.horarioFinal),
      horarioInicial: promocao.horarioInicial === null ? '' : convertNumberToTimeString(promocao.horarioInicial)
    });
  }, [promocao.dataFinal, promocao.dataInicial, promocao.diasSemana, promocao.horarioFinal, promocao.horarioInicial, recarregarForm]);

  function submit(model: PromocaoDataFormModel) {
    setPromocaoStep2({
      dataInicial: model.dataInicial,
      dataFinal: model.dataFinal,
      diasSemana: model.diasSemana ?? 127,
      horarioInicial: convertTimeStringToNumber(model.horarioInicial ?? "") ?? 0,
      horarioFinal: convertTimeStringToNumber(model.horarioFinal ?? "") ?? 2359
    });
    props.submit();
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await refCadastroDataPromocaoRef.current?.submitForm();
    }
  }));

  return (
    <FormPromocaoData
      loading={false}
      ref={refCadastroDataPromocaoRef}
      onSubmit={submit}
      showLoading={false}
    />
  );
});
