import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPoliticaPendente() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPoliticaPendente = useCallback(
    (referencia: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Termo/${referencia}/pendentes?tipoSolucao=touchone`,
        method: 'GET',
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPoliticaPendente
  };
}
