import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
    },
    item: {
        margin: 0,
        boxSizing: 'border-box',
    },
    spacing1: {
        '& > .grid-item': {
            padding: (theme.spacing(1) / 2),
        },
        width: `calc(100% + ${theme.spacing(1)}px)`,
        margin: (theme.spacing(1) / 2 * -1)
    },
    spacing2: {
        '& > .grid-item': {
            padding: (theme.spacing(2) / 2),
        },
        width: `calc(100% + ${theme.spacing(2)}px)`,
        margin: (theme.spacing(2) / 2 * -1)
    },
    spacing3: {
        '& > .grid-item': {
            padding: (theme.spacing(3) / 2),
        },
        width: `calc(100% + ${theme.spacing(3)}px)`,
        margin: (theme.spacing(3) / 2 * -1)
    },
    spacing4: {
        '& > .grid-item': {
            padding: (theme.spacing(4) / 2),
        },
        width: `calc(100% + ${theme.spacing(4)}px)`,
        margin: (theme.spacing(4) / 2 * -1)
    },
    spacing5: {
        '& > .grid-item': {
            padding: (theme.spacing(5) / 2),
        },
        width: `calc(100% + ${theme.spacing(5)}px)`,
        margin: (theme.spacing(5) / 2 * -1)
    },
    spacing6: {
        '& > .grid-item': {
            padding: (theme.spacing(6) / 2),
        },
        width: `calc(100% + ${theme.spacing(6)}px)`,
        margin: (theme.spacing(6) / 2 * -1)
    },
    spacing7: {
        '& > .grid-item': {
            padding: (theme.spacing(7) / 2),
        },
        width: `calc(100% + ${theme.spacing(7)}px)`,
        margin: (theme.spacing(7) / 2 * -1)
    },
    spacing8: {
        '& > .grid-item': {
            padding: (theme.spacing(8) / 2),
        },
        width: `calc(100% + ${theme.spacing(8)}px)`,
        margin: (theme.spacing(8) / 2 * -1)
    },
    tamanhoXsAuto: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: 'none',
            flexBasis: 'auto'
        },
    },
    tamanhoXsTrue: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 1,
            maxWidth: '100%',
            flexBasis: 0
        },
    },
    tamanhoXs1: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "8.333333%",
            flexBasis: "8.333333%",
        },
    },
    tamanhoXs2: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "16.666667%",
            flexBasis: "16.666667%",
        },
    },
    tamanhoXs3: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "25%",
            flexBasis: "25%",
        },
    },
    tamanhoXs4: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "33.333333%",
            flexBasis: "33.333333%",
        },
    },
    tamanhoXs5: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "41.666667%",
            flexBasis: "41.666667%",
        },
    },
    tamanhoXs6: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "50%",
            flexBasis: "50%",
        },
    },
    tamanhoXs7: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "58.333333%",
            flexBasis: "58.333333%",
        },
    },
    tamanhoXs8: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "66.666667%",
            flexBasis: "66.666667%",
        },
    },
    tamanhoXs9: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "75%",
            flexBasis: "75%",
        },
    },
    tamanhoXs10: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "83.333333%",
            flexBasis: "83.333333%",
        },
    },
    tamanhoXs11: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "91.666667%",
            flexBasis: "91.666667%",
        },
    },
    tamanhoXs12: {
        [theme.breakpoints.up('xs')]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    tamanhoSmTrue: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 1,
            maxWidth: '100%',
            flexBasis: 0
        },
    },
    tamanhoSmAuto: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: 'none',
            flexBasis: 'auto'
        },
    },
    tamanhoSm1: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "8.333333%",
            flexBasis: "8.333333%",
        },
    },
    tamanhoSm2: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "16.666667%",
            flexBasis: "16.666667%",
        },
    },
    tamanhoSm3: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "25%",
            flexBasis: "25%",
        },
    },
    tamanhoSm4: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "33.333333%",
            flexBasis: "33.333333%",
        },
    },
    tamanhoSm5: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "41.666667%",
            flexBasis: "41.666667%",
        },
    },
    tamanhoSm6: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "50%",
            flexBasis: "50%",
        },
    },
    tamanhoSm7: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "58.333333%",
            flexBasis: "58.333333%",
        },
    },
    tamanhoSm8: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "66.666667%",
            flexBasis: "66.666667%",
        },
    },
    tamanhoSm9: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "75%",
            flexBasis: "75%",
        },
    },
    tamanhoSm10: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "83.333333%",
            flexBasis: "83.333333%",
        },
    },
    tamanhoSm11: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "91.666667%",
            flexBasis: "91.666667%",
        },
    },
    tamanhoSm12: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    tamanhoMdTrue: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            maxWidth: '100%',
            flexBasis: 0
        },
    },
    tamanhoMdAuto: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: 'none',
            flexBasis: 'auto'
        },
    },
    tamanhoMd1: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "8.333333%",
            flexBasis: "8.333333%",
        },
    },
    tamanhoMd2: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "16.666667%",
            flexBasis: "16.666667%",
        },
    },
    tamanhoMd3: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "25%",
            flexBasis: "25%",
        },
    },
    tamanhoMd4: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "33.333333%",
            flexBasis: "33.333333%",
        },
    },
    tamanhoMd5: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "41.666667%",
            flexBasis: "41.666667%",
        },
    },
    tamanhoMd6: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "50%",
            flexBasis: "50%",
        },
    },
    tamanhoMd7: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "58.333333%",
            flexBasis: "58.333333%",
        },
    },
    tamanhoMd8: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "66.666667%",
            flexBasis: "66.666667%",
        },
    },
    tamanhoMd9: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "75%",
            flexBasis: "75%",
        },
    },
    tamanhoMd10: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "83.333333%",
            flexBasis: "83.333333%",
        },
    },
    tamanhoMd11: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "91.666667%",
            flexBasis: "91.666667%",
        },
    },
    tamanhoMd12: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    tamanhoLgTrue: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 1,
            maxWidth: '100%',
            flexBasis: 0
        },
    },
    tamanhoLgAuto: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: 'none',
            flexBasis: 'auto'
        },
    },
    tamanhoLg1: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "8.333333%",
            flexBasis: "8.333333%",
        },
    },
    tamanhoLg2: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "16.666667%",
            flexBasis: "16.666667%",
        },
    },
    tamanhoLg3: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "25%",
            flexBasis: "25%",
        },
    },
    tamanhoLg4: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "33.333333%",
            flexBasis: "33.333333%",
        },
    },
    tamanhoLg5: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "41.666667%",
            flexBasis: "41.666667%",
        },
    },
    tamanhoLg6: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "50%",
            flexBasis: "50%",
        },
    },
    tamanhoLg7: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "58.333333%",
            flexBasis: "58.333333%",
        },
    },
    tamanhoLg8: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "66.666667%",
            flexBasis: "66.666667%",
        },
    },
    tamanhoLg9: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "75%",
            flexBasis: "75%",
        },
    },
    tamanhoLg10: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "83.333333%",
            flexBasis: "83.333333%",
        },
    },
    tamanhoLg11: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "91.666667%",
            flexBasis: "91.666667%",
        },
    },
    tamanhoLg12: {
        [theme.breakpoints.up('lg')]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    tamanhoXlTrue: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 1,
            maxWidth: '100%',
            flexBasis: 0
        },
    },
    tamanhoXlAuto: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: 'none',
            flexBasis: 'auto'
        },
    },
    tamanhoXl1: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "8.333333%",
            flexBasis: "8.333333%",
        },
    },
    tamanhoXl2: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "16.666667%",
            flexBasis: "16.666667%",
        },
    },
    tamanhoXl3: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "25%",
            flexBasis: "25%",
        },
    },
    tamanhoXl4: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "33.333333%",
            flexBasis: "33.333333%",
        },
    },
    tamanhoXl5: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "41.666667%",
            flexBasis: "41.666667%",
        },
    },
    tamanhoXl6: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "50%",
            flexBasis: "50%",
        },
    },
    tamanhoXl7: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "58.333333%",
            flexBasis: "58.333333%",
        },
    },
    tamanhoXl8: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "66.666667%",
            flexBasis: "66.666667%",
        },
    },
    tamanhoXl9: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "75%",
            flexBasis: "75%",
        },
    },
    tamanhoXl10: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "83.333333%",
            flexBasis: "83.333333%",
        },
    },
    tamanhoXl11: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "91.666667%",
            flexBasis: "91.666667%",
        },
    },
    tamanhoXl12: {
        [theme.breakpoints.up('xl')]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
}));
