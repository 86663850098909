import { ConfiguracoesHeader } from './components/configuracoes-header/configuracoes-header';
import { Grid } from 'views/design-system';
import { AccordionDadosConta } from './components/accordion-dados-conta/accordion-dados-conta';
import { AccordionAlterarSenha } from './components/accordion-alterar-senha/accordion-alterar-senha';
import { AccordionDadosEmpresa } from './components/accordion-dados-empresa/accordion-dados-empresa';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useStyles } from './configuracoes-page-styles';
import classNames from 'classnames';
import { useThemeQueries } from '../../../theme/util-styles';
import { AccordionDadosPlano } from './components/accordion-dados-plano/accordion-dados-plano';
import { AccordionConfiguracoesNfce } from './components/accordion-configuracoes-nfce/accordion-configuraces-nfce';
import { isPlanoFiscal } from 'utils/plano-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularLoading } from 'views';
import { AccordionSAT } from './components/accordion-sat/accordion-sat';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { AccordionUploadCarga } from './components/accordion-upload-carga/accordion-upload-carga';
import { CardEmpresas } from './components/card-empresas/card-empresas';
import { AccordionLimparDados } from './components/accordion-limpar-dados/accordion-limpar-dados';
import { AccordionConfiguracoesEmpresaa } from './components/accordion-configuracoes-empresa/accordion-configuracoes-empresa';
import { HorariosFuncionamento } from './components/horarios-funcionamento/horarios-funcionamento';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useSessaoAtual } from 'services/app';
import { AccordionConfiguracaoCardapio } from './components/accordion-configuracao-cardapio/accordion-configuracao-cardapio';
import { AccordionWebhook } from './components/accordion-webhook/accordion-webhook';
import { AccordionConfiguracoesContrato } from './components/accordion-configuracoes-contrato/accordion-configuracoes-contrato';
import { AccordionChaveApi } from './components/accordion-chave-api/accordion-chave-api';
import { AccordionRecarregarDados } from './components/accordion-recarregar-dados/accordion-recarregar-dados';

export const ConfiguracoesPage = () => {
  const cadastroStyles = useDefaultCadastroStyles();
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { plano, usuario, getEmpresaSelecionada } = useSessaoAtual();
  const [carregando, setCarregando] = useState(true);
  const [uf, setUF] = useState<string>();
  const [, setAtualiza] = useState<boolean>(false)
  const isFiscal = isPlanoFiscal(plano?.plano);
  const { addHandler, removeHandler } = useEventTools();
  const loading = carregando;

  useEffect(() => {
    setTimeout(() => {
      setCarregando(false);
    }, 510);
  }, []);

  const empresaAtt = useCallback(() => {
    setAtualiza(prev => !prev)
  }, [])


  useEffect(() => {
    addHandler(AppEventEnum.EmpresaAtualAlterado, empresaAtt)

    return () => removeHandler(AppEventEnum.EmpresaAtualAlterado, empresaAtt)
  }, [addHandler, empresaAtt, removeHandler])
  const fragment = useMemo(() => {
    return (
      <Grid
        className={cadastroStyles.defaultContainer}
        container
        alignItems="flex-start"
        justifyContent="center"
        style={{ padding: theme.spacing(1) }}
      >
        <Grid
          container
          spacing={1}
          style={{ maxWidth: 1200 }}
          className={classes.containerGrid}
        >
          <Grid item xs={12}>
            <AccordionDadosConta />
          </Grid>
          {usuario!.empresa.length > 1 && (
            <Grid item xs={12}>
              <CardEmpresas />
            </Grid>
          )}
          <Grid item xs={12}>
            <AccordionDadosPlano />
          </Grid>
          <Grid item xs={12}>
            <AccordionAlterarSenha />
          </Grid>
          {usuario!.empresa.length === 1 && (
            <Grid item xs={12}>
              <AccordionDadosEmpresa setUF={setUF} />
            </Grid>
          )}
          {usuario?.empresa.length === 1 && (
            <Grid item xs={12}>
              <HorariosFuncionamento />
            </Grid>
          )}
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && usuario!.empresa.length === 1 && isFiscal && (
            <Grid item xs={12}>
              <AccordionConfiguracoesNfce />
            </Grid>
          )}
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && usuario!.empresa.length === 1 && isFiscal && uf === 'SP' && (
            <Grid item xs={12}>
              <AccordionSAT />
            </Grid>
          )}
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && usuario?.empresa.length === 1 && (
            <Grid item xs={12}>
              <AccordionConfiguracoesEmpresaa id={getEmpresaSelecionada()?.Id || ''} />
            </Grid>
          )}
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && (
            <Grid item xs={12}>
              <AccordionConfiguracoesContrato id='' />
            </Grid>
          )}
          <Grid item xs={12}>
            <AccordionConfiguracaoCardapio />
          </Grid>
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && (
            <Grid item xs={12}>
              <AccordionRecarregarDados />
            </Grid>
          )}
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && (
            <Grid item xs={12}>
              <AccordionUploadCarga />
            </Grid>
          )}
          {VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS && (
            <>
              <Grid item xs={12}>
                <AccordionChaveApi />
              </Grid>
              <Grid item xs={12}>
                <AccordionWebhook />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <AccordionLimparDados />
          </Grid>
        </Grid>
      </Grid>
    );
  }, [cadastroStyles.defaultContainer, classes.containerGrid, getEmpresaSelecionada, isFiscal, theme, uf, usuario]);

  return (
    <Grid className={classNames(cadastroStyles.root, classes.backgroundMobile)}>
      <Grid className={cadastroStyles.header}>
        <ConfiguracoesHeader />
      </Grid>
      {loading ? <CircularLoading tipo="FULLSIZED" /> : fragment}
    </Grid>
  );
};

export default ConfiguracoesPage;
