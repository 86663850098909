import { useEffect, useState, useCallback } from 'react';
import { useStyles } from './finalizadora-list-styles';
import { FinalizadoraListData } from './finalizadora-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus, useCadastros, useSessaoAtual } from 'services/app';
import { FinalizadoraListSearchProps } from './finalizadora-list-search-props';
import { isEmpty } from 'lodash';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { useGetFinalizadoras, usePutFinalizadora } from 'data/api/gestao/finalizadora';
import { useHistory } from 'react-router';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import { usePutFinalizadoraOrdem } from 'data/api/gestao/finalizadora/put-finalizadora-ordem';
import { FinalizadoraOrdemModel } from 'model/api/gestao/finalizadora/finalizadora-ordem-model';
import { Grid } from 'views/design-system';

export interface FinalizadoraListProps {
  searchProps: FinalizadoraListSearchProps;
}

export const FinalizadoraList = (props: FinalizadoraListProps) => {
  const classes = useStyles();
  const { getFinalizadoras, carregando: carregandoGet } = useGetFinalizadoras();
  const { putFinalizadora, carregando: carregandoPut } = usePutFinalizadora();
  const { putFinalizadoraOrdem, carregando: carregandoPutOrdem } = usePutFinalizadoraOrdem();

  const carregando = carregandoGet || carregandoPut || carregandoPutOrdem

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { abrirCadastroFinalizadora } =
    useCadastros();
  const { addHandler, removeHandler } = useEventTools()

  const [modalEditAberto, setModalEditAberto] = useState(false);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FinalizadoraModel>(),
  });
  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FinalizadoraModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );
  consoleDev('finalizadora')
  const search = useCallback(
    async (newPage: number) => {
      const query =
        (!isEmpty(props.searchProps.termo)
          ? '&Descricao=' + props.searchProps.termo
          : '') +
        (!isEmpty(getEmpresaSelecionada()?.Id) ? '&EmpresaId=' + getEmpresaSelecionada()?.Id : ''
        ) + ('&pageSize=50')
      try {
        const res = await getFinalizadoras(query, newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [props.searchProps.termo, getEmpresaSelecionada, getFinalizadoras, fillResult, showToast],
  );

  const changeStatusFinalizadora = useCallback(async (model: FinalizadoraModel) => {
    try {
      const data = { ...model, ativo: !model.ativo }
      const res = await putFinalizadora(data)

      if (res.erro) throw res.erro

      const message = `Forma de Pagamento ${data.descricao} ${data.ativo ? 'ativada' : 'desativada'} com sucesso.`
      showToast('success', message)

      await search(queryStatus.page);

      try {
        const prod = await TouchoneDBPrimary.finalizadoras.get({ id: model.id })
        if (prod) {
          await TouchoneDBPrimary.finalizadoras.update(prod?.idIndexed!, {
            ...model,
            idIndexed: prod?.idIndexed!
          })
        }
      } catch (e: any) {

      }

    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [putFinalizadora, queryStatus.page, search, showToast])

  const saveOrdemFinalizadoras = async (list: FinalizadoraOrdemModel[]) => {
    try{
      const res = await putFinalizadoraOrdem(list)

      if(res.erro) throw res.erro

      await search(queryStatus.page)

      showToast('success', 'Ordenação salva com sucesso.')
    }catch(e: any){
      showToast('error', e.message)
    }
  }

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    addHandler(AppEventEnum.FinalizadoraModal, modalEdit)

    return () => removeHandler(AppEventEnum.FinalizadoraModal, modalEdit)
  }, [addHandler, modalEdit, removeHandler])

  useEffect(() => {
    if (!modalEditAberto) {
      search(queryStatus.page);
    }
  }, [modalEditAberto, queryStatus.page, search]);
  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    abrirCadastroFinalizadora(id, history.location.pathname, true);
  };


  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container spacing={2}>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
            isRegistro={false}
          />
          <DndProvider backend={TouchBackend} options={{
            enableMouseEvents: true,
          }}>
            <FinalizadoraListData
              carregando={carregando}
              list={queryStatus.list.sort((a, b) => a.ordem - b.ordem)}
              selectedList={[]}
              onCardSelected={onCardSelected}
              onChangeStatus={changeStatusFinalizadora}
              submitOrdenacao={saveOrdemFinalizadoras}
            />
          </DndProvider>
        </Grid>
      </div>
    </>
  );
};
