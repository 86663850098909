import { FormStep } from 'model/app';
import { useCallback, useMemo, useRef } from 'react';
import { useFormStepper } from 'views/components/form-stepper';
import { AvancarIcon, NovoIcon, SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import {
  CadastroDataCampanha,
  RefPropFormDataCampanha
} from '../components/data-campanha';
import {
  FormNomeCampanha,
  RefPropFormNomeCampanha
} from '../components/nome-campanha';
import { useCadastros } from 'services/app';
import { useHistory } from 'react-router-dom';
import { useComissaoStore } from '../../../stores/comissao-store';
import { useComissionadosStore } from '../../../stores/comissionados-store';
import { useComissaoProdutosStore } from '../../../stores/produtos-store';
import { Button } from 'views/design-system';

export const enum EnumStepCampanha {
  Nome = 1,
  Periodo = 2
}

interface useCampanhaStepsProps {
  steps: EnumStepCampanha[];
  id?: string;
  cadastroNovaComissao?: boolean
  confirmarCadastro: () => Promise<boolean>
  cancelarCadastro: () => Promise<boolean>
}

export const useCampanhaSteps = ({
  id,
  steps,
  cadastroNovaComissao,
  confirmarCadastro,
  cancelarCadastro
}: useCampanhaStepsProps) => {
  const refCadastroNomeCampanha = useRef<RefPropFormNomeCampanha>(null);
  const refCadastroDataCampanha = useRef<RefPropFormDataCampanha>(null);
  const {
    resetStoreComissao,
  } = useComissaoStore((store) => store);
  const { resetStoreComissionados } = useComissionadosStore((store) => store);
  const { resetStoreProdutos } = useComissaoProdutosStore((store) => store);
  const { abrirCadastroComissao, fecharCadastroComissao } = useCadastros();

  const resetStore = useCallback(() => {
    resetStoreComissao();
    resetStoreComissionados();
    resetStoreProdutos();
  }, [resetStoreComissao, resetStoreComissionados, resetStoreProdutos]);

  const { location } = useHistory();

  const { currentStep, nextStep, prevStep } = useFormStepper(2);

  const voltarButton = useCallback(
    () => {
      return (
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          size="large"
          fullWidth
          onClick={async () => {
            prevStep();
          }}
        >
          <VoltarIcon tipo="BUTTON" />
          Voltar
        </Button>
      );
    },
    [prevStep]
  );

  const avancarStep = useCallback(async (last: boolean = false) => {
    try {
      if (last) {
        await refCadastroDataCampanha.current?.submit();
        return;
      }
      refCadastroNomeCampanha.current?.submit();
    } catch (error: any) { }
  }, []);

  const avancarButton = useCallback(
    (last: boolean = false) => {
      return (
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={() => {
            avancarStep(last);
          }}
        >
          {steps.length === 1 ? (
            <>
              <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
              Salvar
            </>
          ) : (
            <>
              <AvancarIcon tipo="BUTTON_PRIMARY" />
              Próximo
            </>
          )}
        </Button>
      );
    },
    [avancarStep, steps.length]
  );



  const AdicionarButton = useCallback(
    (last: boolean = false) => {
      return (
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          size="large"
          fullWidth
          onClick={() => {
            fecharCadastroComissao();
            setTimeout(() => {
              resetStore();
              abrirCadastroComissao('', location.pathname, true);
            }, 150)
          }}
        >
          <NovoIcon tipo="BUTTON" />
          Nova
        </Button>
      );
    },
    [abrirCadastroComissao, fecharCadastroComissao, location.pathname, resetStore]
  );

  const getFormArray = useMemo(() => {

    const ret: FormStep[] = [];
    for (const step of steps) {
      switch (step) {
        case EnumStepCampanha.Nome:
          ret.push(
            new FormStep(
              'Nome',
              ``,
              <ComandasIcon tipo="GERAL" />,
              'Nome',
              <ComandasIcon tipo="GERAL" />,
              (
                <FormNomeCampanha
                  ref={refCadastroNomeCampanha}
                  nextStep={nextStep}
                />
              ),
              voltarButton(),
              avancarButton()
            )
          );
          break;
        case EnumStepCampanha.Periodo:
          ret.push(
            new FormStep(
              'Periodo',
              ``,
              <PessoaIcon tipo="GERAL" />,
              'Periodo',
              <PessoaIcon tipo="GERAL" />,
              (
                <CadastroDataCampanha
                  ref={refCadastroDataCampanha}
                  submit={async () => await confirmarCadastro()}
                  cadastroNovaComissao={cadastroNovaComissao}
                />
              ),
              voltarButton(),
              avancarButton()
            )
          );
          break;
      }
    }
    if (ret?.length > 0) {
      ret[0].previousButton = id && id?.length > 0 ? AdicionarButton() : undefined;
      ret[ret?.length - 1].nextButton = avancarButton(true);
    }

    return ret;
  }, [steps, nextStep, voltarButton, avancarButton, confirmarCadastro, cadastroNovaComissao, id, AdicionarButton]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep
    },
    formArray: getFormArray,
    carregando: false
  };
};
