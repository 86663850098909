import { useCallback, useEffect, useRef, useState } from "react"
import { TextFieldSaurus } from "../text-field-saurus"
import { Calendario, CalendarioMaskType, IRangeData } from "../calendario/calendario";
import { toDateString } from "utils/to-date";
import { CalendarioIcon, RelogioIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { IconButton } from "@material-ui/core";
import { CloseIcon } from "views/components/icons/close-icon";
import { HorarioOptions, HorarioOptionsMaskedType, IRangeHorario } from "./horario-options";



interface IProps {
    label?: string
    placeholder?: string
    name?: string
    value: IRangeData | Date | undefined | IRangeHorario | string
    onChange: (value: IRangeData | Date | string | IRangeHorario, maskedValue?: CalendarioMaskType | HorarioOptionsMaskedType) => void
    type?: 'date' | 'date-range' | 'time' | 'time-range'
    manterMascara?: boolean
}


export const InputDataHora = (props: IProps) => {
    const [open, setOpen] = useState(false);
    const [top, setTop] = useState(0);
    const [inputValue, setInputValue] = useState<string | undefined>(undefined)
    const { isMobile } = useThemeQueries();

    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current) {
            const rect = (ref.current as any).getBoundingClientRect();
            setTop(rect.top);
        }
    }, [ref]);

    const getInputValue = useCallback(() => {
        let value: string | undefined = undefined
        if (props.type === 'date') {
            value = toDateString(props.value as Date) ?? undefined
        } else if (props.type === 'date-range') {
            const rangeData = props.value as IRangeData
            value = `Data Início: ${rangeData?.inicio ? toDateString(rangeData?.inicio) : ''} - Data Fim: ${rangeData?.fim ? toDateString(rangeData?.fim) : ''}`
        } else if (props.type === 'time-range') {
            const rangeData = props.value as IRangeHorario
            value = `Tempo Início: ${rangeData?.inicio ?? ''} - Tempo Fim: ${rangeData?.fim ?? ''}`
        } else if (props.type === 'time') {
            value = props.value as string
        }
        setInputValue(value)
    }, [props.type, props.value])
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setOpen(false);
            }
        }
        const handleTabKey = (event: KeyboardEvent) => {
            if (event.key === 'Tab') {
                setOpen(false);
            } else if (event.key === 'Enter' && props.type === 'date') {
                //força onBlur
                if (event.target instanceof HTMLInputElement) {
                    event.target.blur();
                }
            }
        }
        if (open) {
            document.addEventListener('click', handleClickOutside);
            document.addEventListener('keydown', handleTabKey);
        } else {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleTabKey);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleTabKey);
        }
    }, [getInputValue, inputValue, open, props]);

    const isTopHalf = top < window.innerHeight / 2;

    useEffect(() => {
        getInputValue()
    }, [getInputValue, props.type, props.value])


    return (
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }} ref={ref}>
            <div
                onClick={(e) => {
                    e.stopPropagation()
                }}
                style={{
                    margin: '10px 0',
                    display: open ? 'flex' : 'none',
                    position: isMobile ? 'fixed' : 'absolute',
                    zIndex: 1000,
                    top: isMobile ? -10 : isTopHalf ? '100%' : undefined,
                    bottom: isMobile ? -10 : isTopHalf ? undefined : '100%',
                    left: isMobile ? 0 : undefined,
                    right: isMobile ? 0 : undefined,
                    height: isMobile ? '100vh' : undefined,
                    width: isMobile ? '100vw' : undefined,
                    backgroundColor: isMobile ? 'rgba(0,0,0,0.5)' : undefined,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >

                <div style={{
                    position: 'relative'
                }}>
                    {
                        isMobile && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: '10px',
                                zIndex: 1001
                            }}>
                                <IconButton
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    <CloseIcon tipo="BUTTON_PRIMARY" fill="black" />
                                </IconButton>
                            </div>
                        )
                    }
                    {
                        (props.type === 'date-range' || props.type === 'date') && (
                            <Calendario manterMascara={props.manterMascara} type={props.type as 'date' | 'date-range'} onChange={(value, maskedValue) => {
                                if (props.type === 'date' || props.type === undefined) {
                                    props.onChange(value as Date, maskedValue)
                                } else if (props.type === 'date-range') {
                                    props.onChange(value as IRangeData, maskedValue)
                                }
                            }} value={props.value as Date | IRangeData} />
                        )
                    }
                    {
                        (props.type === 'time' || props.type === 'time-range') && (
                            <HorarioOptions value={props.value as IRangeHorario | string} manterMascara={props.manterMascara} onChange={(e, maskedValue) => {
                                props.onChange(e, maskedValue)
                            }} onConfirm={() => {
                                setOpen(false)
                            }} type={props.type} />
                        )
                    }
                </div>
            </div>
            <TextFieldSaurus
                readOnly={isMobile}
                InputProps={{
                    endAdornment: props.type === 'date' || props.type === 'date-range' ? <div onClick={() => {
                        setOpen(true);
                    }} ><CalendarioIcon tipo="INPUT" /></div> : <div onClick={() => {
                        setOpen(true);
                    }}><RelogioIcon tipo="INPUT" /> </div>,
                    value: inputValue,
                    name: props.name,
                    readOnly: isMobile,
                    onChange: (e) => {
                        if (props.type === 'date-range') return
                        let value = e.target.value;
                        // Remove any character that is not a digit
                        const numbers = value.replace(/\D/g, '');

                        // Format the string as "DD/MM/YYYY"
                        let dateValue = '';
                        if (numbers.length <= 2) {
                            // If there are 2 or fewer digits, just show them (assumed to be the day)
                            dateValue = numbers;
                        } else if (numbers.length <= 4) {
                            // If there are between 3 and 4 digits, split them into "DD/MM"
                            dateValue = `${numbers.slice(0, 2)}/${numbers.slice(2)}`;
                        } else {
                            // If there are more than 4 digits, split into "DD/MM/YYYY"
                            dateValue = `${numbers.slice(0, 2)}/${numbers.slice(2, 4)}/${numbers.slice(4, 8)}`;
                        }

                        // Update your state or input value here
                        // For example, using a state setter if you have one:
                        setInputValue(dateValue); // Assuming you have a state setter function setInputValue
                    },
                    onBlur: () => {
                        //input value is DD/MM/YYYY format
                        //convert it to Date object
                        // also verify if is a valid date
                        let dateParts = inputValue?.split("/");
                        if (dateParts && dateParts.length === 3) {
                            let date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
                            props.onChange(date);
                        } else {
                            getInputValue()
                        }
                    }
                }}

                placeholder={props.placeholder}
                label={props.label}
                onFocus={() => {
                    setOpen(true);
                }}
            />
        </div>
    );
};

