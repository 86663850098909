import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  ImagemInput,
  TextFieldSaurus,
} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useFormProdutoResumoEditValidation } from './form-produto-resumo-edit-validation';

import SemImagem from 'assets/img/sem-imagem.jpg';
import { ProdutoResumoEditFormModel } from 'model/app/forms/produto/produto-edit/produto-resumo-edit-form-model';
import { useStyles } from './form-produto-resumo-edit-styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { Box, Button, Grid, Typography } from 'views/design-system';

interface tpProdutosConfigProps {
  servico: string | undefined;
  generico: string | undefined;
  entrega: string | undefined;
}

export interface FormProdutoResumoEditProps
  extends DefaultFormProps<ProdutoResumoEditFormModel> {
  setCarregandoExterno(carregando: boolean): void;
  tpProdutosConfig: tpProdutosConfigProps;
  contratoId: string;
  empresaId: string;
  id: string;
  trocarTipoProduto(produtoId: string, tpProdutoAtual: number): void;
  tipo: EnumTipoProduto;
  ativo: boolean;
}
export const FormProdutoResumoEdit = forwardRef<
  DefaultFormRefs<ProdutoResumoEditFormModel>,
  FormProdutoResumoEditProps
>(
  (
    { setCarregandoExterno, loading, ...props }: FormProdutoResumoEditProps,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);

    const [modelForm, setModelForm] = useState<ProdutoResumoEditFormModel>(
      new ProdutoResumoEditFormModel(),
    );
    const { FormProdutoResumoEditYupValidation } =
      useFormProdutoResumoEditValidation();

    const { isMobile } = useThemeQueries();
    const classes = useStyles();
    const { plano } = useSessaoAtual()
    const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

    const {
      handleSubmit,
      control,
      formState: { errors },
      getValues,
      setValue,
      reset,
      setError,
      clearErrors
    } = useForm<ProdutoResumoEditFormModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormProdutoResumoEditYupValidation),
      criteriaMode: 'all',
      mode: 'onSubmit',
    });

    const validateCarc = useCallback((value) => {
      const descricao: string = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/
      if (regexCaracEspeciais.test(descricao)) {
        return true
      }
      return false
    }, [])

    const onSubmit = async (values: ProdutoResumoEditFormModel) => {
      const isValidInfAdc = validateCarc(values.infAdic);
      const isValidName = validateCarc(values.nome);
      let error = false

      if (!isFarmaceutico) {
        if (isValidInfAdc) {
          setError('infAdic', { type: "error", message: 'Não pode conter caracteres especiais.' })
          error = true
        } else {
          clearErrors('infAdic')
        }

        if (isValidName) {
          setError('nome', { type: "error", message: 'Não pode conter caracteres especiais.' })
          error = true
        } else {
          clearErrors('nome')
        }
      }
      if (error) return

      const model = picker<ProdutoResumoEditFormModel>(
        values,
        new ProdutoResumoEditFormModel(),
      );

      if (!isEmpty(errors)) {
        return
      }

      props.onSubmit(model, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: ProdutoResumoEditFormModel) => {
        setModelForm(model);
        reset({ ...model });
        //senão o focus n funfa
        setTimeout(() => {
          if (!isMobile) refInputNome.current?.focus();
        }, 500);
      },
    }));

    return (
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid className={classes.root}>
              <Grid className={classes.resumoContainer} xs={12} md={9}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          inputRef={refInputNome}
                          fullWidth
                          autoComplete='off'
                          disabled={loading}
                          label={`Nome Comercial`}
                          variant="outlined"
                          placeholder="Ex: Agua Mineral 600ml"
                          error={Boolean(errors.nome && errors.nome.message)}
                          helperText={
                            errors.nome
                              ? errors.nome?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="infAdic"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          fullWidth
                          allowSubmit
                          disabled={loading}
                          placeholder='Ex: "Consumir em até 7 dias após aberto". (Limite 400 caracteres)'
                          multiline
                          maxRows={6}
                          minRows={3}
                          maxTexto={400}
                          label={`Informações Adicionais`}
                          variant="outlined"
                          error={Boolean(errors.infAdic && errors.infAdic.message)}
                          helperText={
                            errors.infAdic
                              ? errors.infAdic?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container flexDirection='column' xs={12} md={3}>
                <Grid item className={classes.imagemContainer}>
                  <Controller
                    name="imagemUrl"
                    control={control}
                    render={({ field }) => (
                      <ImagemInput
                        loadErrorImage={SemImagem}
                        imageRadius="5%"
                        width={isMobile ? '170px' : '130px'}
                        height={isMobile ? '170px' : '130px'}
                        accept="image/*"
                        error={Boolean(
                          errors.imagemUrl && errors.imagemUrl.message,
                        )}
                        helperText={
                          errors.imagemUrl
                            ? errors.imagemUrl?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('imagemUrl')}
                        onChange={({ base64 }: any) => {
                          setValue('imagemUrl', base64);
                        }}
                        onBlur={() => {
                          setValue('imagemUrl', '');
                        }}
                        semImagemRepresentante
                        mode='contain'
                      />
                    )}
                  />
                </Grid>
                <Grid item className={classes.tagsContainer}>
                  {props.tpProdutosConfig.generico === props.id && (
                    <Tooltip
                      arrow
                      title="Este produto está sendo usado como Produto Genérico."
                    >
                      <Typography variant='caption' className={classNames(classes.tipoStatus, classes.bgPrimaryDark)}>Genérico</Typography>
                    </Tooltip>
                  )}
                  {props.tpProdutosConfig.servico === props.id && (
                    <Tooltip
                      arrow
                      title="Este produto está sendo usado como Produto de Serviço."
                    >
                      <Typography variant='caption' className={classNames(classes.tipoStatus, classes.bgInfo)}>Serviço</Typography>
                    </Tooltip>
                  )}
                  {props.tpProdutosConfig.entrega === props.id && (
                    <Tooltip
                      arrow
                      title="Este produto está sendo usado como Produto de Entrega."
                    >
                      <Typography variant='caption' className={classNames(classes.tipoStatus, classes.bgInfo)}>Entrega</Typography>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    );
  },
);
