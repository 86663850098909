import { ProdutoCompletoPreCadastroFormModel } from '../../../../../../../model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model';
import { isPlanoFiscal } from 'utils/plano-utils';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormStepper } from 'views/components/form-stepper';
import { DefaultFormRefs } from 'views/components/form/utils';
import { ProdutoValorVendaCompraPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-valor-compra-venda-form-model';
import { picker } from 'utils/picker';
import { Button, Typography } from 'views/design-system';
import {
  AvancarIcon,
  EditarIcon,
  EmpresaIcon,
  ImpostoAddIcon,
  OkIcon,
  VoltarIcon,
} from 'views/components/icons';
import { FormStep } from 'model/app';
import { EnumAtivarRevisarNFCE } from 'model/enums/enum-aba-ativar-revisar-nfce';
import { EmpresaEditFormModel } from 'model/app/forms/master/empresa-edit-form-model';
import { FormEmpresaEdit, FormEmpresaEditRef } from 'views/components/form/master/empresa-edit/form-empresa-edit';
import { isEqual } from 'lodash';
import { useGetEmpresaId, usePutEmpresa } from 'data/api/gestao/empresa';
import { EmpresaModel } from 'model/api';
import { toDateString } from 'utils/to-date';
import { EnumRegime } from 'model/enums/enum-regime';
import { FormConfigNfce } from 'views/components/form/master/configuracoes-nfce/form-configuracoes-nfce';
import { ConfigNfceFormModel } from 'model/app/forms/master/configuracoes-nfce-form-model';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useGetConfigEmissor } from 'data/api/gestao/empresa/get-config-emissor';
import { PatchConfigEmissorProps, usePatchConfigEmissor } from 'data/api/gestao/empresa/patch-config-emissor';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useHistory } from 'react-router-dom';
import { NfceIcon } from 'views/components/icons/nfce-icon';

export const useAtivarRevisarNFCE = () => {
  // STATES E REFS
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const [empresaFormState, setEmpresaFormState] =
    useState<EmpresaEditFormModel | null>(new EmpresaEditFormModel());
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [sat, setSat] = useState(false);
  const [emissorFormState, setEmissorFormState] = useState<ConfigNfceFormModel>(
    new ConfigNfceFormModel(),
  );
  const refEmpresaModel = useRef<EmpresaModel>(new EmpresaModel());
  const emissorModel = useRef<ConfigNfceFormModel>(new ConfigNfceFormModel());
  const refEmpresaForm = useRef<FormEmpresaEditRef>(null);
  const configNfceFormRef = useRef<DefaultFormRefs<ConfigNfceFormModel>>(null);
  const refPreCadastroValorCompraVenda =
    useRef<DefaultFormRefs<ProdutoValorVendaCompraPreCadastroFormModel>>(null);
  const refPreCadastroProdutoCompleto =
    useRef<DefaultFormRefs<ProdutoCompletoPreCadastroFormModel>>(null);
  const refValorCompraVendaModelForm =
    useRef<ProdutoValorVendaCompraPreCadastroFormModel>(
      new ProdutoValorVendaCompraPreCadastroFormModel(),
    );

  // PROVIDERS
  const { showToast } = useToastSaurus();
  const { currentStep, nextStep, prevStep } = useFormStepper(7);
  const { plano, getEmpresaSelecionada, refreshEmpresa, refreshEmpresaFiscal } = useSessaoAtual();

  // CHAMADAS API
  const { getEmpresaId, carregando: carregandoGetEmpresa } = useGetEmpresaId();
  const { putEmpresa, carregando: carregandoPutEmpresa } = usePutEmpresa();
  const { getConfigEmissor, carregando: carregandoGetConfigEmissor } =
    useGetConfigEmissor();
  const { patchConfigEmissor, carregando: carregandoPatchConfigEmissor } =
    usePatchConfigEmissor();

  // AUX
  const history = useHistory();
  const { isMobile } = useThemeQueries();
  const isPOS = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS;

  const loading: boolean =
    carregandoFromForms ||
    carregandoGetEmpresa ||
    carregandoPutEmpresa ||
    preenchendoTela ||
    carregandoGetConfigEmissor ||
    carregandoPatchConfigEmissor;

  // SESSÃO DADOS DA EMPRESA
  const getEmpresaByIdWrapper = useCallback(async () => {
    const empresaId = getEmpresaSelecionada()?.Id || '';
    const res = await getEmpresaId(empresaId);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as EmpresaModel;
    refEmpresaModel.current = ret;
    const empresa = picker<EmpresaEditFormModel>(
      ret,
      new EmpresaEditFormModel(),
    );
    return empresa;
  }, [getEmpresaId, getEmpresaSelecionada]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        try {
          if (currentStep === 0) {
            setPreenchendoTela(true);
            const empresa = await getEmpresaByIdWrapper();
            setEmpresaFormState(empresa);
            setPreenchendoTela(false);
          }
        } catch (e: any) {
          showToast('error', e.message);
          setPreenchendoTela(false);
        } finally {
          setPreenchendoTela(false);
        }
      })();
      return () => {
        //funcao de limpeza
        setEmpresaFormState(new EmpresaEditFormModel());
      };
    }, 300);
  }, [currentStep, getEmpresaByIdWrapper, showToast]);

  const recarregarForm = useCallback((model: EmpresaEditFormModel | null) => {
    refEmpresaForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(empresaFormState);
  }, [empresaFormState, recarregarForm]);

  const saveChangesEmpresa = useCallback(
    async (empresaModel: EmpresaEditFormModel) => {
      const empresa = picker<EmpresaModel>(
        empresaModel,
        refEmpresaModel.current,
      );
      empresa.dNascimento = toDateString(
        empresaModel.dNascimento,
        'yyyy-MM-DDTHH:mm:ss',
      );

      try {
        const ret = await putEmpresa(empresa);
        if (ret.erro) {
          throw ret.erro;
        }

        refEmpresaModel.current = empresa;
        setEmpresaFormState(empresa);
      } catch (e: any) {
        showToast('error', e.message);
        refEmpresaForm.current?.resetForm();
      }
    },
    [putEmpresa, showToast],
  );

  const handleSubmitEmpresa = useCallback(
    async (model: EmpresaEditFormModel, beforeModel: EmpresaEditFormModel) => {
      let empresaEqual = isEqual(model, beforeModel);
      if (
        model.regime === EnumRegime.MEI ||
        model.regime === EnumRegime.NAOSEAPLICA
      ) {
        showToast(
          'error',
          'Para Vinculação fiscal o Regime não pode ser "MEI e Não se Aplica".',
        );
        return;
      }

      if (!empresaEqual) {
        saveChangesEmpresa(model);
        nextStep();
      }

      if (empresaEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
        nextStep();
      }
    },
    [nextStep, saveChangesEmpresa, showToast],
  );

  const getFormDadosEmpresa = useCallback((): JSX.Element => {
    return (
      <FormEmpresaEdit
        setCarregandoExterno={setCarregandoFromForms}
        showLoading={loading}
        onSubmit={handleSubmitEmpresa}
        loading={loading}
        ref={refEmpresaForm}
        paraFiscal
      />
    );
  }, [handleSubmitEmpresa, loading]);

  // SESSÃO NFCE
  const getConfigNfce = useCallback(async () => {
    const res = await getConfigEmissor(getEmpresaSelecionada()?.Documento ?? '');
    if (res.erro) {
      throw res.erro;
    }

    let formModel = picker<ConfigNfceFormModel>(res.resultado?.data, new ConfigNfceFormModel());
    return formModel;
  }, [getConfigEmissor, getEmpresaSelecionada]);

  const preencherTelaNFCE = useCallback(async () => {
    try {
      const configNfce = await getConfigNfce();
      setEmissorFormState(configNfce);
      emissorModel.current = configNfce;
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getConfigNfce, showToast]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        try {
          if (currentStep === 2) {
            setPreenchendoTela(true);
            preencherTelaNFCE();
            setPreenchendoTela(false);
          }
        } catch (e: any) {
          showToast('error', e.message);
          setPreenchendoTela(false);
        }
      })();

      return () => {
        //funcao de limpeza
        setEmissorFormState(new ConfigNfceFormModel());
      };
    }, 300);
  }, [currentStep, getConfigNfce, preencherTelaNFCE, showToast]);

  const recarregarFormNFCE = useCallback((model: ConfigNfceFormModel) => {
    configNfceFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarFormNFCE(emissorFormState);
  }, [recarregarFormNFCE, emissorFormState]);

  const saveChangesConfigNfce = useCallback(
    async (model: ConfigNfceFormModel) => {
      const index = model.pfxBase64.indexOf('https://saurus.blob.core');
      if (index === 0) {
        return;
      } else {
        const env = picker<PatchConfigEmissorProps>(model, new PatchConfigEmissorProps());

        const ret = await patchConfigEmissor(
          env,
          getEmpresaSelecionada()?.Documento || '',
        );

        if (ret.erro) {
          throw ret.erro;
        }
        setEmissorFormState(model);
        showToast('success', 'NFC-e atualizado com sucesso!');
        preencherTelaNFCE();
      }
    },
    [getEmpresaSelecionada, patchConfigEmissor, preencherTelaNFCE, showToast],
  );

  const handleSubmitNFCE = useCallback(
    async (model: ConfigNfceFormModel, beforeModel: ConfigNfceFormModel) => {
      const configToEqual = isEqual(model, beforeModel);
      try {
        if (!configToEqual && beforeModel.senha !== '') {
          await saveChangesConfigNfce(model);
          nextStep();
        } else if (!configToEqual) {
          await saveChangesConfigNfce(model);
          await refreshEmpresa();
          await refreshEmpresaFiscal();
          nextStep();
        } else {
          showToast('info', 'Nenhuma informação atualizada.');
          nextStep();
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [nextStep, refreshEmpresa, refreshEmpresaFiscal, saveChangesConfigNfce, showToast],
  );

  const getFormNFCE = useCallback((): JSX.Element => {
    return (
      <FormConfigNfce
        ref={configNfceFormRef}
        onSubmit={handleSubmitNFCE}
        showLoading={false}
        loading={preenchendoTela || isPOS}
      />
    );
  }, [isPOS, handleSubmitNFCE, preenchendoTela]);
  // FIM SESSÃO NFCE

  // SESSÃO CONCLUSÃO
  const getConclusao = useCallback((): JSX.Element => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            Cadastre seus impostos para poder realizar a vinculação fiscal de
            seus produtos, boas vendas!
          </Typography>
        </div>
      </>
    );
  }, []);

  // FIM SESSÃO CONCLUSÃO

  // SESSÃO NÃO FISCAL
  const getNAOFISCAL = useCallback((): JSX.Element => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            Para Continuar com a configuração e emissão de NFC-e, migre para o
            plano Ouro!
          </Typography>
        </div>
      </>
    );
  }, []);

  // FIM SESSÃO NÃO FISCAL

  const isFiscal = isPlanoFiscal(plano?.plano);
  const retTelaIndex = useCallback(
    (index: number): EnumAtivarRevisarNFCE | undefined => {
      if (isFiscal) {
        switch (index) {
          case 0:
            return EnumAtivarRevisarNFCE.DADOS_DA_EMPRESA;
          case 1:
            return EnumAtivarRevisarNFCE.NFCE;
          case 2:
            return EnumAtivarRevisarNFCE.CONCLUSAO;
        }
      } else {
        switch (index) {
          case 0:
            return EnumAtivarRevisarNFCE.NAOFISCAL;
        }
      }
      return undefined;
    },
    [isFiscal],
  );

  useEffect(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumAtivarRevisarNFCE.DADOS_DA_EMPRESA:
        refEmpresaForm.current?.fillForm(refEmpresaModel.current);
        break;
      case EnumAtivarRevisarNFCE.NFCE:
        refPreCadastroValorCompraVenda.current?.fillForm(
          refValorCompraVendaModelForm.current,
        );
        break;
      case EnumAtivarRevisarNFCE.CONCLUSAO:
        break;
      case EnumAtivarRevisarNFCE.NAOFISCAL:
        break;
    }
  }, [currentStep, empresaFormState, nextStep, retTelaIndex]);

  const avancarStep = useCallback(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumAtivarRevisarNFCE.DADOS_DA_EMPRESA:
        refEmpresaForm.current?.submitForm();
        break;
      case EnumAtivarRevisarNFCE.NFCE:
        configNfceFormRef.current?.submitForm();
        break;
      case EnumAtivarRevisarNFCE.CONCLUSAO:
        refPreCadastroProdutoCompleto.current?.submitForm();
        break;
    }
  }, [currentStep, retTelaIndex]);

  const voltarStep = useCallback(() => {
    if (currentStep - 1 === 0) {
      setEmpresaFormState(refEmpresaModel.current);
    }

    if (currentStep - 1 === 1) {
      if (refEmpresaModel.current.uf !== 'SP') {
        setEmpresaFormState(refEmpresaModel.current);
        prevStep();
      }
      setEmpresaFormState(refEmpresaModel.current);
    }

    if (currentStep - 1 === 2) {
      setSat(false);
      setEmissorFormState(emissorModel.current);
    }
    prevStep();
  }, [currentStep, prevStep]);

  const voltarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        fullWidth={true}
        disabled={loading}
        onClick={() => {
          voltarStep();
          setSat(false);
        }}
      >
        <VoltarIcon tipo="BUTTON" />
        Passo Anterior
      </Button>
    );
  }, [loading, voltarStep]);

  const avancarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        fullWidth={true}
        disabled={loading || sat}
        onClick={avancarStep}
      >
        <AvancarIcon tipo="BUTTON_PRIMARY" />
        Próximo Passo
      </Button>
    );
  }, [loading, avancarStep, sat]);

  const cadastroImpostosButton = useMemo(() => {
    return (
      <Button
        disabled={loading}
        onClick={() => {
          history.push('/impostos');
        }}
        variant="contained"
        color="primary"
        fullWidth
      >
        <ImpostoAddIcon tipo="BUTTON_PRIMARY" />
        Cadastro de impostos
      </Button>
    );
  }, [history, loading]);

  const mudarPlanoButton = useMemo(() => {
    return (
      <Button
        disabled={loading}
        onClick={() => {
          history.push('/alterar-plano');
        }}
        variant="contained"
        color="primary"
        fullWidth
      >
        <EditarIcon tipo="BUTTON_PRIMARY" />
        Alterar plano
      </Button>
    );
  }, [history, loading]);

  const getFormArray = useMemo(() => {
    const ret = [];

    let i = 0;
    while (retTelaIndex(i)) {
      const tela = retTelaIndex(i);

      switch (tela) {
        case EnumAtivarRevisarNFCE.DADOS_DA_EMPRESA:
          ret.push(
            new FormStep(
              !isMobile ? 'Dados da Empresa' : 'Empresa',
              'Preencha ou verifique os dados informados sobre a empresa.',
              <EmpresaIcon tipo="GERAL" />,
              !isMobile ? 'Dados da Empresa' : 'Empresa',
              <EmpresaIcon tipo="GERAL" />,
              getFormDadosEmpresa(),
              voltarButton,
              avancarButton,
            ),
          );
          break;

        case EnumAtivarRevisarNFCE.NFCE:
          ret.push(
            new FormStep(
              'NFC-e',
              'Preencha ou verifique os dados informados sobre a NFC-e.',
              <NfceIcon tipo="GERAL" />,
              'NFC-e',
              <NfceIcon tipo="GERAL" />,
              getFormNFCE(),
              voltarButton,
              avancarButton,
            ),
          );
          break;
        case EnumAtivarRevisarNFCE.CONCLUSAO:
          ret.push(
            new FormStep(
              'Conclusão',
              'Parabéns, agora a sua empresa está apta a emitir nota fiscal. Seu próximo passo é configurar os impostos!',
              <OkIcon tipo="GERAL" />,
              'Conclusão',
              <OkIcon tipo="GERAL" />,
              getConclusao(),
              voltarButton,
              cadastroImpostosButton,
            ),
          );
          break;
        case EnumAtivarRevisarNFCE.NAOFISCAL:
          ret.push(
            new FormStep(
              'Plano não fiscal',
              'Seu plano atual não possui configuração fiscal.',
              <OkIcon tipo="GERAL" />,
              'Plano',
              <OkIcon tipo="GERAL" />,
              getNAOFISCAL(),
              voltarButton,
              mudarPlanoButton,
            ),
          );
          break;
      }
      i++;
    }

    if (ret.length > 0) {
      ret[ret.length - 1].previousButton = voltarButton;
      ret[ret.length - 1].nextButton = isFiscal
        ? cadastroImpostosButton
        : mudarPlanoButton;
      ret[0].previousButton = undefined;
    }

    return ret;
  }, [retTelaIndex, isMobile, getFormDadosEmpresa, voltarButton, avancarButton, getFormNFCE, getConclusao, cadastroImpostosButton, getNAOFISCAL, mudarPlanoButton, isFiscal]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep,
    },
    formArray: getFormArray,
    carregando: loading,
  };
};
