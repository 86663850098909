import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { RelatoriosAvancadosFiltroModal } from 'views/components/modals/relatorios-avancados-filtro/relatorios-avancados-filtro-modal';
import { RelatoriosAvancadosListSearchProps } from 'views/pages/private/relatorios/relatorios-avancados/components/relatorios-avancados-list/relatorios-avancados-list-search-props';

export const RelatoriosAvancadosFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            termo: '',
            status: 1,
            codigo: ''
        } as RelatoriosAvancadosListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.RelatoriosAvancadosFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.RelatoriosAvancadosFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if(!modalState.openned){
        return null
    }

    return (
        <RelatoriosAvancadosFiltroModal openned={modalState.openned} filtros={modalState.filtros} key="mdlRelatoriosAvancadosFiltro" />
    )
}