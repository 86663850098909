import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '156px',
    background: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '2px 2px 4px 0px #00000014',
    borderRadius: '8px',
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  label: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '12px',
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  border: {
    border: 'solid 1px',
    borderColor: theme.palette.primary.main
  },
  radio: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  description: {
    fontWeight: 500,
    fontSize: '9px',
    lineHeight: '12px',
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));
