import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
    },
    loadingContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 999999
    },
    paginacao: {
        padding: 0,
        margin: 0,
    },
    botaoSelecionar: {
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing(1),
        minHeight: '30px',
        boxShadow: theme.shadows[1],
        borderRadius: 5,
        padding: 3,
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0),
        background: theme.palette.common.white,
        "& strong": {
            paddingLeft: theme.spacing(1)
        },
        "& svg": {
            fill: theme.palette.primary.main,
            width: 24,
            height: 24,
        }
    },
    cardCheckBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: -8,
        marginLeft: 8,
    },
    badgeQtde: {
        position: 'absolute',
        marginRight: -260,
        marginTop: -10,
        "& span": {
            fontSize: 10,
        }
    },
}));