import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.default,
        flex: 1,
        overflow: 'hidden'
    },
    content: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.default
    },
    title: {
        fontSize: '22px',
        lineHeight: '30px'
    },
    inputArea: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 260,
        maxWidth: 395,
        borderBottom: '2px solid#D8D8D8'
    },
    inputText: {
        fontSize: '38px',
        lineHeight: '50px',
        fontWeight: 600
    },
    placeholder: {
        fontSize: '16px',
        lineHeight: '50px'
    },
    listContainer: {
        '& .card': {
            margin: theme.spacing(1)
        }
    },
    contentForms: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        flex: 1,
    },
    acoes: {
        flex: '0 0 auto',
        paddingTop: 0,
    },
    step: {
        height: '100%',
        display: 'block'
    },
    stepForm: {
        height: '100%',
        alignItems: 'initial'
    },
    stepper: {
        '& .MuiStepper-root': {
            padding: 0
        }
    },
    mobileBarProgress: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    customStepContainer: {
        background: 'transparent',
        padding: '0px',
        paddingBottom: theme.spacing(2),
        margin: 0,
        marginLeft: theme.spacing(2) * -1,
        marginRight: theme.spacing(2) * -1,
    },
    containerStepsForm: {
        padding: 8
    }
}));
