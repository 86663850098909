import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { ProdutosIcon, VoltarIcon } from 'views/components/icons';
import { ModalHeader } from 'views/components/modals/components';
import { useComissaoStore } from '../stores/comissao-store';
import { useComissionadosStore } from '../stores/comissionados-store';
import { useComissaoProdutosStore } from '../stores/produtos-store';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { SwitchSaurus } from 'views/components/controles';
import { IComissao } from '../components/comissao-steps-types';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useMenuOptionsStyles } from 'views/components/menu-options/menu-options-styles';
import { usePutComissao } from 'data/api/gestao/comissao/put-comissao';
import { useGetComissaoById } from 'data/api/gestao/comissao/get-comissao-by-id';
import { useGetComissaoProdutosById } from 'data/api/gestao/comissao/get-comissao-produtos-by-id';
import { usePostProdutosByIds } from 'data/api/gestao/produto/produto/post-produtos-by-ids';
import { Box, Typography } from 'views/design-system';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useStyles } from './comissao-edicao-styles';
import { CampanhaComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { FormTextEditHeader } from 'views/components/form/form-text-edit-header/form-text-edit-header';
import { CircularLoading } from 'views/components/utils';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus
} from 'views/components/tabs/tabs-saurus';
import classNames from 'classnames';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { useThemeQueries } from 'views/theme';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { ComissaoCampanha } from '../components/comissao-campanha';
import { EnumStatusComissao } from 'model/enums/enum-status-comissao';
import { ComissaoComissionados } from '../components/comissao-comissionados';
import { ComissaoProdutos } from '../components/comissao-produtos';
import { useComissionados } from 'views/components/modals/comissao-comissionados/components/comissionados-edicao/hooks/comissionados';

export enum EnumAbaEdicaoComissao {
  Campanha,
  Comissionados,
  Produtos
}

export const ComissaoEdicao = ({ id, veioDoCadastro }: IComissao) => {
  // HOOKS
  const { fecharCadastroComissao, abrirComissaoApuracao } = useCadastros();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { showToast } = useToastSaurus();
  const menuClasses = useMenuOptionsStyles();
  const modalClasses = useModalStyles();
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { getComissionados, carregando: carregandoComissionados } =
    useComissionados({ comissaoId: id });

  const [abaSelecionada, setAbaSelecionada] = useState<EnumAbaEdicaoComissao>(
    veioDoCadastro ? EnumAbaEdicaoComissao.Produtos : EnumAbaEdicaoComissao.Campanha
  );
  const [preenchendoTela, setPreenchendoTela] = useState<boolean>(true)


  // CALL API
  const { carregando: loadingPutComissao, putComissao } = usePutComissao();
  const { carregando: loadingComissaoById, getComissaoById } =
    useGetComissaoById();
  const { carregando: loadingComissaoProdutosById, getComissaoProdutosById } =
    useGetComissaoProdutosById();
  const { carregando: loadingComissaoProdutosByIds, postProdutosByIds } =
    usePostProdutosByIds();

  // STORES
  const {
    state: { comissao },
    resetStoreComissao,
    setComissao,
    setStatus
  } = useComissaoStore((store) => store);

  const { resetStoreComissionados } = useComissionadosStore((store) => store);

  const { resetStoreProdutos, setProdutos } = useComissaoProdutosStore(
    (store) => store
  );

  const resetStore = useCallback(() => {
    resetStoreComissao();
    resetStoreComissionados();
    resetStoreProdutos();
  }, [resetStoreComissao, resetStoreComissionados, resetStoreProdutos]);

  const carregando = useMemo(
    () =>
      [
        loadingPutComissao,
        loadingComissaoById,
        loadingComissaoProdutosById,
        loadingComissaoProdutosByIds,
        carregandoComissionados,
        preenchendoTela
      ].includes(true),
    [carregandoComissionados, loadingComissaoById, loadingComissaoProdutosById, loadingComissaoProdutosByIds, loadingPutComissao, preenchendoTela]
  );

  const changeStatus = useCallback(async () => {
    try {
      const resComissao = await putComissao(getEmpresaAtual()?.id ?? '', {
        ...comissao,
        status: comissao.status === 1 ? 0 : 1
      });

      if (resComissao.erro) {
        throw new Error(resComissao.erro);
      }
      showToast('success', `Comissão ${comissao.status === 0 ? 'desativada' : 'ativada'}!`)
      setStatus();
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [comissao, getEmpresaAtual, putComissao, setStatus, showToast]);

  const menuOptions = useCallback((): MenuOptionsModel[] => {
    return [
      new MenuOptionsModel(
        (
          <Box className={menuClasses.menuItem}>
            <Typography variant="body1">
              {comissao.status === EnumStatusComissao.Ativo
                ? 'Ativa'
                : 'Desativada'}
            </Typography>
            <SwitchSaurus
              size="small"
              value={comissao.status === EnumStatusComissao.Ativo}
              variant="DEFAULT"
              checked={comissao.status === EnumStatusComissao.Ativo}
            />
          </Box>
        ),
        <></>,
        () => changeStatus()
      ),
      new MenuOptionsModel('Apurações', <></>, () => {
        abrirComissaoApuracao(id, '', false);
      })
    ];
  }, [
    menuClasses.menuItem,
    comissao.status,
    changeStatus,
    abrirComissaoApuracao,
    id
  ]);

  const produtosComissaoWrapper = (
    produtos: ProdutoResumidoModel[]
  ): Array<{
    nome: string;
    categoria: string;
    id: string;
    imgUrl: string;
    marca: string;
  }> => {
    return produtos.map((prod) => ({
      nome: prod.nome,
      categoria: prod.categoriaDescricao,
      id: prod.produtoGradeId,
      imgUrl: prod.imagemUrl,
      removido: false,
      sincronizado: true,
      marca: prod?.marcaDescricao ?? ''
    }));
  };

  const getProdutos = useCallback(async () => {
    const resGetComissaoProdutos = await getComissaoProdutosById(
      getEmpresaAtual()?.id ?? '',
      id
    );

    if (resGetComissaoProdutos.erro) {
      throw new Error(resGetComissaoProdutos.erro);
    }

    const produtosIds = (resGetComissaoProdutos.resultado?.data
      ?.produtoVariacaoId ?? []) as string[];

    const resPostProdutos = await postProdutosByIds(
      getEmpresaAtual()?.id ?? '',
      produtosIds
    );

    if (resPostProdutos.erro) {
      throw new Error(resPostProdutos.erro);
    }

    const produtosResponse = (resPostProdutos.resultado?.data ??
      []) as ProdutoResumidoModel[];

    const produtosComissaoComissao = produtosComissaoWrapper(produtosResponse);

    setProdutos(produtosComissaoComissao ?? []);
  }, [
    getComissaoProdutosById,
    getEmpresaAtual,
    id,
    postProdutosByIds,
    setProdutos
  ]);

  const getComissao = useCallback(async () => {
    const resGetComissao = await getComissaoById(
      getEmpresaAtual()?.id ?? '',
      id
    );

    if (resGetComissao.erro) {
      throw new Error(resGetComissao.erro);
    }

    const comissao = resGetComissao.resultado?.data as CampanhaComissaoModel;

    await getProdutos();
    await getComissionados();

    // Populando stores
    setComissao(comissao);
  }, [getComissaoById, getEmpresaAtual, id, getProdutos, getComissionados, setComissao]);

  const preencherTela = useCallback(async () => {
    try {
      setPreenchendoTela(true)
      await getComissao()
    } catch (err: any) {
      showToast('error', err.message)
    } finally {
      setPreenchendoTela(false)
    }
  }, [getComissao, showToast])

  useEffect(() => {
    preencherTela();
  }, [getComissao, preencherTela]);

  const alterarNomeComissao = useCallback(
    async (value: string) => {
      try {
        const newModel = { ...comissao };
        newModel.nome = value;
        const res = await putComissao(getEmpresaAtual()?.id ?? '', newModel);
        if (res.erro) throw res.erro;

        setComissao(newModel);
        showToast('success', 'Nome alterado com sucesso!');
        return true;
      } catch (err: any) {
        showToast('error', err.message);
        return false;
      }
    },
    [comissao, getEmpresaAtual, putComissao, setComissao, showToast]
  );

  const selecionarAba = (aba: EnumAbaEdicaoComissao) => {
    setAbaSelecionada(aba)
  }

  const abas = [
    new TabSaurusLabel(
      'Campanha',
      EnumAbaEdicaoComissao.Campanha,
      (
        <ComandasIcon
          tipo="BUTTON"
          fill={
            abaSelecionada === EnumAbaEdicaoComissao.Campanha
              ? theme.palette.primary.main
              : theme.palette.grey[400]
          }
          style={{ margin: '0px 8px' }}
        />
      )
    ),
    new TabSaurusLabel(
      'Produtos',
      EnumAbaEdicaoComissao.Produtos,
      (
        <ProdutosIcon
          tipo="BUTTON"
          fill={
            abaSelecionada === EnumAbaEdicaoComissao.Produtos
              ? theme.palette.primary.main
              : theme.palette.grey[400]
          }
          style={{ margin: '0px 8px' }}
        />
      )
    ),
    new TabSaurusLabel(
      'Comissionados',
      EnumAbaEdicaoComissao.Comissionados,
      (
        <PessoaIcon
          tipo="BUTTON"
          fill={
            abaSelecionada === EnumAbaEdicaoComissao.Comissionados
              ? theme.palette.primary.main
              : theme.palette.grey[400]
          }
          style={{ margin: '0px 8px' }}
        />
      )
    )
  ];

  const titleArea = useMemo(() => {
    return (
      <FormTextEditHeader
        valor={comissao.nome}
        forceMobile
        maxTexto={30}
        onSubmit={alterarNomeComissao}
        placeholder={`Nome da Campanha`}
      />
    );
  }, [alterarNomeComissao, comissao.nome]);

  return (
    <Box className={modalClasses.rootClass}>
      <ModalHeader
        title={titleArea}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              if (id && id.length > 0) {
                resetStore();
              }
              fecharCadastroComissao();
            }}
          />
        }
        rightArea={
          id ? (
            <MenuOptions
              options={menuOptions()}
              disable={carregando}
              labels={['Opções']}
            />
          ) : null
        }
      />
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      {!preenchendoTela && (
        <Box className={classes.container}>
          <Box className={classNames(modalClasses.content, classes.relative)}>
            <TabsSaurus
              tabsLabel={abas}
              selectedTabIndex={abaSelecionada}
              onChange={(index) => {
                selecionarAba(index);
              }}
              tabsContent={[
                //CAMPANHA
                new TabSaurusContent(
                  EnumAbaEdicaoComissao.Campanha,
                  <ComissaoCampanha cadastroNovaComissao={false} id={id} selecionarAba={selecionarAba} />
                ),
                //PRODUTOS
                new TabSaurusContent(
                  EnumAbaEdicaoComissao.Produtos,
                  (
                    <ComissaoProdutos
                      getProdutos={getProdutos}
                      comissaoId={id}
                      isTabProduto={
                        abaSelecionada === EnumAbaEdicaoComissao.Produtos
                      }
                      selecionarAba={selecionarAba}
                      veioDoCadastro={veioDoCadastro}
                    />
                  )
                ),
                //COMISSIONADOS
                new TabSaurusContent(
                  EnumAbaEdicaoComissao.Comissionados,
                  <ComissaoComissionados comissaoId={id} />
                )
              ]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
