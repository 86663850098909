import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const MaisIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={{ ...props.style }} fill={props.fill}>
                <path d="M 24 9 L 24 24 L 9 24 L 9 26 L 24 26 L 24 41 L 26 41 L 26 26 L 41 26 L 41 24 L 26 24 L 26 9 Z"></path>
            </DefaultIcon>
        </>
    );
};