import { Paper } from '@material-ui/core';
import { CardDashboardChartLineProps } from '../card-dashboard-chart-props';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import classNames from 'classnames';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { Box, Divider, Grid, Typography } from 'views/design-system';

export const CardDashboardChartLine = ({
  model,
  carregando
}: CardDashboardChartLineProps) => {
  const utilClasses = useStyles();

  const hora = Array.from(
    new Set<string>(
      model?.sort((a, b) => a.hora - b.hora).map((item) => `${item?.hora} h`),
    ),
  );
  const compararHora = Array.from(
    new Set<string>(
      model?.sort((a, b) => a.hora - b.hora).map((item) => `${item?.hora}`),
    ),
  );

  const options = useCallback(() => {
    const options = {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '20px',
        },
        y: {
          formatter: (val: any) => `R$ ${toDecimalString(val)}`
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (val: any) => `R$ ${val.toFixed(0)}`
        }
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
          },
        },
      ],
      chart: {
        width: '700px',
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: hora,
      },
    };
    return options;
  }, [hora]);

  const retornaSeries = useCallback(() => {
    const series: any[] = [
      {
        name: 'Custo',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + b.vCusto, 0).toFixed(2),
        ),
      },
      {
        name: 'Total vendido',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + b.vVenda, 0).toFixed(2),
        ),
      },
      {
        name: 'Lucro',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + (b.vVenda - b.vCusto), 0).toFixed(2),
        ),
      },
    ];
    return series;
  }, [compararHora, model]);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <Grid container >
          <Grid item xs={12} p={1} >
            <Typography
              color="primary"
              weight={500}
            >
              Receita por horário
            </Typography>
            <Divider variant='fullWidth' />
          </Grid>

          <Grid item xs={12} flex flexDirection='column' my={2} >
           
              {model.length === 0 ? (
                <Typography variant="body1" align='center'>
                  Não há dados para exibir
                </Typography>
              ) : (
                <>
                  {VariaveisAmbiente.paymentDevice === undefined ? (
                    <>
                      {compararHora.map((item, index) => {
                        return (
                          <Paper
                            key={index}
                            className={utilClasses.cardReceitaPorHorario}
                          >
                            <Box>
                              <Typography
                                variant="caption"
                                weight={700}
                              >
                                Hora
                              </Typography>
                              <Typography>{item}h</Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                weight={700}
                              >
                                Total Vendido
                              </Typography>
                              <Typography>
                                R$ {toDecimalString(
                                  model
                                    .filter((i) => String(i.hora) === item && i)
                                    .reduce((a, b) => a + b.vVenda, 0),
                                  2,
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Box>
                                <Typography
                                  variant="caption"
                                  weight={700}
                                >
                                  Lucro
                                </Typography>
                                <Typography style={{ color: 'green' }}>
                                  R$ {toDecimalString(
                                    model
                                      .filter(
                                        (i) => String(i.hora) === item && i,
                                      )
                                      .reduce(
                                        (a, b) => a + (b.vVenda - b.vCusto),
                                        0,
                                      ),
                                    2,
                                  )}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant='body2'
                                  color='error'
                                >
                                  -{' '}
                                  {toDecimalString(
                                    model
                                      .filter(
                                        (i) => String(i.hora) === item && i,
                                      )
                                      .reduce((a, b) => a + b.vCusto, 0),
                                    2,
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Paper>
                        );
                      })}
                    </>
                  ) : (
                    <Chart
                      options={options()}
                      series={retornaSeries()}
                      type="line"
                      className={utilClasses.chart}
                    />
                  )}
                </>
              )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
