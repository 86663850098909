import { Button, Grid, Typography } from 'views/design-system';
import { useGetSessaoFechamento } from 'data/api/gestao/sessao/get-sessao-fechamento';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { SessaoFechamentoModel } from 'model/api/gestao/sessao/sessao-fechamento-model';
import { SessaoValueFechamento } from 'model/api/gestao/sessao/sessao-valor-model';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastSaurus } from 'services/app';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { toDecimalString } from 'utils/to-decimal';
import { CircularLoading } from 'views/components';
import CardFinalizarSessaoValores from 'views/components/cards/card-finalizar-sessao-valores/card-finalizar-sessao-valores';
import { DialogFechamentoSessao } from 'views/components/dialog/dialog-fechamento-sessao/dialog-fechamento-sessao';
import { OkIcon } from 'views/components/icons';
import { useStyles } from './sessao-fechamento-caixa-page-styles';
import { useSessaoAtual } from 'services/app';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';

const SessaoFechamentoCaixaPage = () => {
  // STATES E REFS
  const [sessaoValues, setSessaoValues] = useState<SessaoValueFechamento[]>([]);
  const [dialogFechamento, setDialogFechamento] = useState<boolean>(false);

  // HOOKS
  const {
    setValores,
    verificarItensParaSincronizacao,
    verificarItensParaFechamento,
    getSessao
  } = useSessaoPDV();
  const { getEmpresaSelecionada } = useSessaoAtual();

  // CHAMADAS API
  const { getSessaoFechamento, carregando } = useGetSessaoFechamento();

  // AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { push: historyPush } = useHistory();

  const getFinalizadoras = useCallback(async () => {
    try {
      const sessaoAtual = await getSessao();
      const res = await getSessaoFechamento(
        getEmpresaSelecionada()?.Id ?? '',
        sessaoAtual?.caixaId ?? '',
        sessaoAtual?.id ?? ''
      );

      if (res.erro) {
        showToast(
          'error',
          'Não foi possível buscar os valores correspondes para as formas de pagamento, por favor preencha manualmente.'
        );
      }

      const fechamentoApi: SessaoFechamentoModel[] = res.resultado?.data || [];

      const getpaymentMethodsFromIndexed = await TouchoneDBPrimary.finalizadoras
        .filter((finalizadora) => finalizadora.ativo)
        .toArray();

      const formasDePagamento = getpaymentMethodsFromIndexed.sort(function (
        a,
        b
      ) {
        return ('' + a.descricao).localeCompare(b.descricao ?? '');
      }) as FinalizadoraModel[];

      setSessaoValues(
        formasDePagamento.map((pag) => {
          return {
            valor: pag.tpTransacao === 4 ? '0,00' : toDecimalString(
              (fechamentoApi.length > 0 ? fechamentoApi?.find(
                (pagamentoApi) => pagamentoApi?.pagamentoId === pag.id
              )?.valorTotal ?? '' : '')
            ),
            tpLancamento: EnumTpLancamentoSessaoValor.FECHAMENTO,
            pagamento: {
              descricao: pag.descricao,
              pagamentoId: pag.id,
              tpMod: pag.tpMod,
              tpTransacao: pag.tpTransacao
            },
            observacao: 'Fechamento',
            responsavel: undefined
          };
        })
      );
    } catch (e: any) {
      showToast('error', e.message);
      historyPush('/venda-simples/gerenciar-sessao');
    }
  }, [
    getEmpresaSelecionada,
    getSessao,
    getSessaoFechamento,
    historyPush,
    showToast
  ]);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Fechamento de Caixa',
      showDefinicoes: false,
      showMenuPdv: false,
      voltar: {
        onClick: () => historyPush(PdvRotasMock.gerenciarSessao)
      }
    })
  }, [historyPush, setHeaderProps])

  useEffect(() => {
    getFinalizadoras();
  }, [getFinalizadoras]);

  const setValue = useCallback((index: number, value: string) => {
    setSessaoValues(prev => {
      prev[index] = { ...prev[index], valor: value };

      return [...prev]
    });
  }, []);

  const inserirValores = useCallback(async () => {
    await setValores(sessaoValues);

    const valoresNaoSincronizados = await verificarItensParaFechamento();

    if (!valoresNaoSincronizados) {
      const itens = await verificarItensParaSincronizacao();
      if (!itens || itens.length === 0) {
        return historyPush('/venda-simples/gerenciar-sessao');
      }
    }

    return historyPush({
      pathname: '/venda-simples/gerenciar-sessao-sincronizar-fechamento',
      state: { itens: valoresNaoSincronizados }
    });
  }, [
    historyPush,
    sessaoValues,
    setValores,
    verificarItensParaFechamento,
    verificarItensParaSincronizacao
  ]);

  const handleConfirm = () => {
    const zerados = sessaoValues.filter(
      (sessaoValue) => sessaoValue.valor === ''
    );

    if (zerados.length > 0) {
      setDialogFechamento(true);
      return;
    }

    inserirValores();
  };

  const okIcon = useMemo(() => (
    <OkIcon tipo="BUTTON_PRIMARY" />
  ), [])

  const valorEmCaixa = useMemo(() => (
    <Typography className={classes.textValorEmCaixa}>
      Valor em Caixa:
    </Typography>
  ), [classes.textValorEmCaixa])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.container}>
        <Grid className={classes.list}>
          {sessaoValues.map((finalizadora, index) => (
            <>
              <CardFinalizarSessaoValores
                key={finalizadora.pagamento.pagamentoId}
                index={index}
                model={finalizadora}
                value={finalizadora.valor}
                setValue={setValue}
              />
            </>
          ))}
        </Grid>
        <Grid className={classes.containerButton}>
          <Grid className={classes.containerValorEmCaixa}>
            {valorEmCaixa}
            <Typography className={classes.textValorEmCaixa}>
              <strong>
                R${' '}
                {toDecimalString(
                  sessaoValues.reduce(
                    (acc, current) =>
                      acc +
                      Number(
                        current.valor ? current.valor.replace(',', '.') : 0
                      ),
                    0
                  ),
                  2
                )}
              </strong>
            </Typography>
          </Grid>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirm}
          >
            {okIcon}
            Confirmar
          </Button>
        </Grid>
      </Grid>

      {dialogFechamento && (
        <DialogFechamentoSessao
          openned={dialogFechamento}
          closeModal={() => setDialogFechamento(false)}
          valores={sessaoValues}
          inserirValores={inserirValores}
        />
      )}
    </>
  );
};

export { SessaoFechamentoCaixaPage };
