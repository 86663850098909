import { useCallback, useMemo } from "react";

import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { NcmHeader } from "./components/ncm-header/ncm-header";

import { NcmList } from "./components/ncm-list/ncm-list";
import { ButtonFab } from "views/components/controles";
import { NcmAddIcon } from '../../../../components/icons/ncm-add-icon';
import { useCadastros } from "services/app";
import { Box } from "views/design-system";

export const NcmPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirCadastroNcm } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroNcm("", "", true);
  }, [abrirCadastroNcm]);

  const component = useMemo(() => <Box className={classes.root}>
    <Box className={classes.header}>
      <NcmHeader />
    </Box>
  <Box className={classes.list}>
      <NcmList />
    </Box>
    <ButtonFab
      tooltip="Adicionar NCM"
      icon={<NcmAddIcon tipo="BUTTON_FAB" />}
      onClick={onClickAdd}
    />
  </Box>, [classes.header, classes.list, classes.root, onClickAdd]);

  return component;
};

export default NcmPage;
