import { cloneDeep } from 'lodash';
import { useCallback } from 'react';

export enum GestaoStorageKeys {
  Token,
  TermosDeUso,
  EmpresaAtual,
  Plano,
  PerfisDoUsuario,
  PessoaDoUsuario,
  CadastroPerfis,
  ConfiguracoesMenu,
  EmailRecuperacaoSenha,
  UsuariosConectados,
  SugestaoMelhorias,
  MovSimplesAnterior,
  MovSimplesAtual,
  ConsumidorPadrao,
  MovSimplesInsercaoFavorita,
  VendedorAtual,
  PDV,
  PDVInicializado,
  Impressora,
  UltimaSincronizacao,
  UltimoLogin,
  SalaoAtual,
  SincronizacaoGeralAtual,
  ConfiguracoesMesaEComanda,
  DocumentoContrato,
  PendenciaContrato,
  ChaveAtivacao,
  LandingPage,
  ModoCadastroMesa,
  PaletaWhitelabel,
  QtdPessoasPagamento,
  MenuPDVFavorito,
  ConfigContrato,
  MeuUsuario,
  PrimeiraImpressaoResumo,
  ResumoSessao,
  Whitelabel,
  MostrarItensCancelados,
  IsDelivery,
  DigitarCodigo,
  Tutoriais,
  UltimaImpressaoLocal,
  DesktopMode,
}

export function useGestaoStorage() {
  const getRegistro = useCallback(
    (key: GestaoStorageKeys, session: boolean = true) => {
      const valorCache = getCache(key);
      if (valorCache) return valorCache;

      const valorStorage = JSON.parse(
        (session
          ? sessionStorage.getItem(GestaoStorageKeys[key].toString())
          : localStorage.getItem(GestaoStorageKeys[key].toString())) ?? '{}'
      );


      salvarCache(key, valorStorage);
      return valorStorage;
    },
    []
  );

  const exists = useCallback(
    (key: GestaoStorageKeys, session: boolean = true) => {
      const valorCache = getCache(key);
      if (valorCache) return true;

      if (session)
        return Boolean(
          sessionStorage.getItem(GestaoStorageKeys[key].toString())
        );
      else
        return Boolean(localStorage.getItem(GestaoStorageKeys[key].toString()));
    },
    []
  );

  const setRegistro = useCallback(
    (key: GestaoStorageKeys, obj: any, session: boolean = true): void => {
      if (session) {
        sessionStorage.setItem(
          GestaoStorageKeys[key].toString(),
          JSON.stringify(obj)
        );
      } else {
        localStorage.setItem(
          GestaoStorageKeys[key].toString(),
          JSON.stringify(obj)
        );
      }

      salvarCache(key, obj);
    },
    []
  );

  const delRegistro = useCallback(
    (key: GestaoStorageKeys, session: boolean = true) => {
      if (session) {
        sessionStorage.removeItem(GestaoStorageKeys[key].toString());
      } else {
        localStorage.removeItem(GestaoStorageKeys[key].toString());
      }

      salvarCache(key, undefined);
    },
    []
  );

  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists
  };

  function getCache(key: GestaoStorageKeys) {
    const cache = (window as any)?.cache;
    if (cache) {
      const valor = cache[key.toString()]
      if (key === GestaoStorageKeys.ConfiguracoesMenu) {
        return valor;
      }
    }
    return undefined;
  }

  function salvarCache(key: GestaoStorageKeys, obj: any) {
    if ((window as any)?.cache === undefined)
      (window as any).cache = [];

    //ATENÇÂO DEIXEI O CLONE DEEP POIS ESTAMOS SE NAO FOR UMA NOVA REFERENCIA DE MEMORIA OS ESTADOS NÃO SERÃO MODIFICADOS POIS ESTAVAM COM OUTRA REFERENCIA DE MEMORIA
    (window as any).cache[key.toString()] = cloneDeep(obj);
  }
}
