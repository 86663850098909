export class GerenciarCartaoConvenioFormModel {
    constructor(
        public nomeCartao: string = "",
        public pessoaId: string = '',
        public numeroCartao: string = '',
        public limite: number = 0,
        public senha: string = '',
        public id: string = '',
        public bloquearPessoa: boolean = false,
        public dataValidade: string | Date = '',
    ) { }
}