import React from "react";
import { useMenuStorage } from "services/app/hooks/menu-storage";
import { MenuPrincipalModelo, MenuPrincipalModo } from "./menu-principal-types";
import { EnumMenuPrincipalStatus } from 'model/enums/enum-menu-principal-status';
import { EnumMenuPrincipalModo } from "model/enums/enum-menu-principal-modo";
import { useSessaoAtual } from "services/app/providers";
import { useCadastros } from "../cadastros";

export const useMenuPrincipal = () => {
    const { get, persist } = useMenuStorage();
    const { getPermissaoBoolean } = useSessaoAtual()
    const { abrirSolicitarPermissao } = useCadastros()

    const abrirMenu = React.useCallback(async (): Promise<void> => {
        var menuAtual = get();

        if (menuAtual.modo === EnumMenuPrincipalModo.PDV) {
            if (!getPermissaoBoolean(5004)) {
                abrirSolicitarPermissao(
                    async () => {
                        menuAtual.situacao = EnumMenuPrincipalStatus.Aberto;
                        persist(menuAtual);
                    },
                    5004,
                    'ir a central administrativa'
                );
                return;
            }
        }

        menuAtual.situacao = EnumMenuPrincipalStatus.Aberto;
        persist(menuAtual);

    }, [get, persist, abrirSolicitarPermissao, getPermissaoBoolean]);

    const fecharMenu = React.useCallback(async (): Promise<void> => {
        var menuAtual = get();
        menuAtual.situacao = EnumMenuPrincipalStatus.Fechado;

        persist(menuAtual);
    }, [get, persist]);

    const alterarModelo = React.useCallback(async (modelo: MenuPrincipalModelo): Promise<void> => {
        var menuAtual = get();
        menuAtual.modelo = modelo;

        persist(menuAtual);
    }, [get, persist]);

    const alterarModo = React.useCallback(async (modo: MenuPrincipalModo): Promise<void> => {
        var menuAtual = get();
        menuAtual.modo = modo;

        persist(menuAtual);
    }, [get, persist]);

    const isMenuAberto = React.useCallback(() => {
        const menuAtual = get();

        return menuAtual.situacao === EnumMenuPrincipalStatus.Aberto
    }, [get])

    return {
        abrirMenu,
        fecharMenu,
        alterarModelo,
        alterarModo,
        isMenuAberto
    }
};
