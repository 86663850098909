import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonKeyboardAddContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        cursor: "pointer",
        outline: "none",
        userSelect: "none",
    },
    addIcon: {
        height: 32,
        width: 32,
        margin: 0,
    }
}));