import { KeyValueModel } from "model/api";
import { EnumConvenioTpFaturaStatus } from "model/enums/enum-convenio-tp-fatura-status";

export const TpStatusFaturaConvenioMock: KeyValueModel[] = [
    new KeyValueModel(EnumConvenioTpFaturaStatus.Aberta, "Aberto"),
    new KeyValueModel(EnumConvenioTpFaturaStatus.NaoPaga, "Não Pago"),
    new KeyValueModel(EnumConvenioTpFaturaStatus.Paga, "Pago"),
    new KeyValueModel(EnumConvenioTpFaturaStatus.Fechada, "Fechado"),
];

export const TpStatusFaturaConvenioMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    ...TpStatusFaturaConvenioMock
];
