import { Box, Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-enviar-cupom-venda-por-email-styles';
import { FecharIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { useCallback, useRef, useState } from 'react';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { useValidationYupEmail } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AutocompleteMultiple } from 'views/components/controles/autocompletes/autocomplete-multiple/autocomplete-multiple';
import { isEmpty } from 'lodash';
interface DialogDialogeEnviarCupomVendaPorEmailProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  enviarEmail: () => void;
  email: string[];
  setEmail: (email: string[]) => void;
}

export const DialogEnviarCupomEmail = ({
  openned,
  closeModal,
  enviarEmail,
  loading,
  email,
  setEmail,
}: DialogDialogeEnviarCupomVendaPorEmailProps) => {
  const classes = useStyles();
  const showContratar = enviarEmail;
  const refInput = useRef<HTMLDivElement | null>(null);
  const { emailYup } = useValidationYupEmail()
  const [erro, setErro] = useState<string>('')
  const emailsString = email.join(';')
  let schema = Yup.object({
    email: emailYup(),
  });


  const onClickEmail = useCallback(async (ev: any) => {

    ev.preventDefault()

    schema.isValid({
      email: emailsString
    }).then((valid) => {
      if (!valid) {
        setErro('O email é inválido.')
        return
      }
      setErro('')
      enviarEmail()
    })

  }, [emailsString, enviarEmail, schema])

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Enviar por E-mail"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box style={{ flex: 1 }}>
              <Typography className={classes.label}>
                Informe o E-mail para envio do conteúdo:
              </Typography>
              <AutocompleteMultiple
                opcoes={email}
                inputRef={refInput}
                value={email}
                onChange={(event, newValue) => { setEmail(newValue) }}
                error={!isEmpty(erro)}
                helperText={!isEmpty(erro) && erro}
              />
            </Box>
            <form onSubmit={onClickEmail}>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                <Grid item xs={4}>
                  {closeModal && (
                    <Button
                      disabled={loading}
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                      rounded
                    >
                      <FecharIcon tipo="BUTTON" />
                      Fechar
                    </Button>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={!showContratar || loading}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    rounded
                  >
                    <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                    {'Enviar cupom'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
