import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    divider: {
        background: '#DFDFDF'
    },
    content: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: '0px 3px 3px 0px',
        marginBottom: theme.spacing(1),
        zIndex: 1
    },
    title: {
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '39px',
        textAlign: 'center',
        color: '#1F9CE4',
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerTextButton: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textButton: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '33px',
        textAlign: 'center',
    },
    visorButton: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '100%',
        padding: '0',
        "& svg": {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    },
    visorApagar: {
        "& svg": {
            padding: "10%",
            marginLeft: "-5%",
        }
    },
    visorLeitor: {
        "& svg": {
            padding: "20%",
        }
    },
    visorCodigo: {
        height: '150px',

        background: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    contentVisor: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    textoLeitorVisor: {

    },
    textVisor: {
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '40px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing(1),
        borderBottom: "1px solid #D0D0D0",
        minWidth: '220px',
    },
    textVisorAguardando: {
        fontSize: '1rem',
        fontWeight: "normal",
        lineHeight: '40px',
        textAlign: 'center',
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderBottom: "1px solid #D0D0D0",
        minWidth: '220px',
    },
    displayNone: {
        display: 'none'
    },
    displayNormal: {
        display: 'initial'
    },
    label: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
    },
    containerFooter: {
        display: 'flex',
    },
    containerItemFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: theme.spacing(1),

        "& .MuiBadge-badge": {
            "z-index": 0,
        }
    },
    containerPreviewInfo: {
        width: '100%',
        height: '60px',
        background: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        marginTop: theme.spacing(1),
        paddingLeft: '10px',
        paddingRight: '10px',
        zIndex: 1
    },
    textPreview: {
        color: '#fff',
        fontSize: '16px',
        lineHeight: '31px',
        fontWeight: 600
    },
    headerHighSide: {
        position: 'relative',
        zIndex: 3
    }
}));

