import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComissao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissao = useCallback(
        (empresaId: string, queryBusca: string, page?: number) => {
          let queryPage = "";
          if (page) {
            queryPage = "page=" + page.toString();
          }
          queryPage = queryPage + "&pageSize=10";
          
          let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
            }${queryPage}${queryPage.length > 0 ? "" : ""}${queryBusca}`;
    
          return invocarApi({
            url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/comissao${queryFinal}`,
            method: "GET",
            enviarTokenUsuario: true,
          });
        },
        [invocarApi]
      );

    return {
        ...outrasPropriedades,
        getComissao,
    };
}
