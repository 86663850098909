import { InputDataHora } from "views/components/controles/inputs/input-data-hora";
import { IRangeData } from "views/components/controles/inputs/calendario/calendario";
import { useEffect, useState } from "react";
import { useComissaoApuracaoService } from "../useComissaoApuracaoService";
import { useStyles } from "./criar-apuracao-comissao-styles";
import { useComissaoStore } from "../../comissao/comissao-modal/stores/comissao-store";
import { useApuracaoStore } from "../stores/apuracao-store";
import { OkIcon } from "views/components/icons";
import { Box, Button, Grid } from "views/design-system";

export const CriarComissaoApuracao = (props: {
    id: string | undefined;
}) => {
    const comissao = useComissaoStore(state => state.state.comissao)
    const { setComissaoApuracaoUIDialogTitle } = useApuracaoStore()
    const [rangeData, setRangeData] = useState<IRangeData>()

    useEffect(() => {
        //add one day 
        const dataInicial = new Date(comissao?.dataInicial)
        dataInicial.setDate(dataInicial.getDate() + 1)

        const dataFinal = new Date(comissao?.dataFinal)
        dataFinal.setDate(dataFinal.getDate() + 1)

        setRangeData({
            inicio: dataInicial,
            fim: dataFinal
        })
    }, [comissao])

    const classes = useStyles()


    const { gerarApuracao } = useComissaoApuracaoService()

    useEffect(() => {
        setComissaoApuracaoUIDialogTitle('Gerar Apuração')
    }, [setComissaoApuracaoUIDialogTitle])

    return <>
        <Box p={1} pt={2} className={classes.fullHeight}>
            <form onSubmit={(e) => {
                if (!rangeData?.inicio || !rangeData?.fim) {
                    return
                }
                e.preventDefault()
                if (props.id) {
                    gerarApuracao({
                        comissaoId: props.id,
                        dataRange: rangeData
                    })
                }
            }}>
                <Grid container flexDirection="column" justifyContent="space-between" p={1}>
                    <Grid item >
                        <InputDataHora label="Data início e fim" onChange={(value) => {
                            setRangeData(value as IRangeData)
                        }} value={rangeData} type="date-range" />
                    </Grid>
                    <Grid item>
                        <Button color="primary" fullWidth variant="contained" type="submit" disabled={
                            !rangeData?.inicio || !rangeData?.fim
                        }>
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Gerar Apurações
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>

    </>
}