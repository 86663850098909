import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: 'relative',
    minHeight: 180,
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: 48,
      height: 48,
    },
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
  },
  celulaGrid: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    "& p": {
      textAlign: "center",
      lineHeight: 1,
    }
  },
  text: {
    fontWeight: 600,
    fontSize: 12,
    maxWidth: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': '2',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),


  },
  info: {
    fontWeight: 'normal',
    fontSize: 10,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

  },
  badgeQtde: {
    position: 'absolute',
    left: 18,
    top: 15,
    "& span": {
      fontSize: 10,
    }
  },
  emBreve: {
    opacity: 0.5,
  },
  badge: {
    width: 100,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(5),
    background: theme.palette.info.main,
    color: theme.palette.primary.contrastText,
    opacity: 1,
    fontSize: 10,
    padding: '2px 4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
  },
}));

