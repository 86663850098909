import { ReceitaMedicaModel } from "model/api/gestao/venda/venda-completa-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { RastroMedicamentoModal } from "views/components/modals/rastro-medicamento/rastro-medicamento-modal";

export const CadastroRastroMedicamentoModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<{
        openned: boolean;
        medicamentos: any[];
        receitaMed: ReceitaMedicaModel;
        fluxoFinalizado: () => Promise<void>;
    }>({
        openned: false,
        medicamentos: [],
        receitaMed: new ReceitaMedicaModel(),
        fluxoFinalizado: () => Promise.resolve(),
    });

    const modalAlterado = useCallback(({ ...props }: any) => {
        setModalState({
            ...props
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.RastroMedicamentoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.RastroMedicamentoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <RastroMedicamentoModal fluxoFinalizado={modalState.fluxoFinalizado} medicamentos={modalState.medicamentos} receitaMed={modalState.receitaMed} openned={modalState.openned} key="mdlRastroMedicamento" id={''} />
    )
}