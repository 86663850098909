import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useToastSaurus } from 'services/app';
import { Grid } from 'views/design-system';
import { CircularLoading } from 'views';
import { useStyles } from './components/list-styles';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus,
} from 'views/components/tabs/tabs-saurus';
import { Paginacao } from 'views/components/paginacao';
import { HistoricoVendaListData } from './components/historico-venda-list-data';
import { HistoricoVendaModel } from 'model/api/gestao/historico-vendas/historico-vendas-model';
import { useGetHistoricoVenda } from 'data/api/gestao/historico-venda/get-historico-venda';
import { isEmpty } from 'lodash';
import { VendaOffListData } from './components/venda-off-list-data';
import { useVenda } from 'services/app/hooks/gerenciar-venda';
import { VendaOffModel } from 'model/api/gestao/venda/venda-off-model';
import { filterVendaOffLine } from 'utils/filtro-venda-offiline';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { ButtonFab } from 'views/components/controles';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { SincronizarVendasIcon } from 'views/components/icons/sincronizacao-vendas-icon';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpNf } from 'model/enums/enum-tp-nf';

export const HistoricoVendaTabs = () => {
  // AUX
  const { replace, location } = useHistory();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();

  // rotas
  const rotaSincronizadas = location.pathname.indexOf('/venda-simples/') > -1 ? '/venda-simples/historico/sincronizadas' : '/historico/sincronizadas';
  const rotaNaoSincronizadas = location.pathname.indexOf('/venda-simples/') > -1 ? '/venda-simples/historico/nao-sincronizadas' : '/historico/nao-sincronizadas';

  const retornaAba = useCallback((path: string) => {
    return path.indexOf(rotaNaoSincronizadas) > -1 ? 1 : 0;
  }, [rotaNaoSincronizadas]);

  // STATES
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<HistoricoVendaModel>(),
  });
  const [queryVendaOff, setQueryVendaOff] = useState<VendaOffModel[]>([]);

  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname),
  );
  const [carregandoInicial, setCarregandoInicial] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const { iniciarSincronizacaoGeral } = useSincronizacaoGeral();

  // chamadas API
  const { getHistoricoVenda, carregando: carregandoHistoricoSinc } =
    useGetHistoricoVenda();

  // HOOKS
  const { getVendas } = useVenda();
  const { addHandler, removeHandler } = useEventTools();

  const carregando = carregandoHistoricoSinc || carregandoInicial;

  const filtros = useMemo(() => {
    const urlParams = new URLSearchParams(history.location.search)
    return {
      status: isEmpty(urlParams.get('status')) ? -1 : Number(urlParams.get('status')),
      dInicial: urlParams.get('dInicial') || '',
      dFinal: urlParams.get('dFinal') || '',
      serie: urlParams.get('serie') || '',
      empresa: urlParams.get('empresa') || '',
      nnf: urlParams.get('nnf') || '',
      tpMov: !isEmpty(urlParams.getAll('tpMov')) ? urlParams.getAll('tpMov').map(tp => Number(tp)) : [-1],
      nnfInicial: urlParams.get('nnfInicial') || '',
      nnfFinal: urlParams.get('nnfFinal') || '',
    }
  }, [history.location.search])

  // Sincronizadas
  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<HistoricoVendaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const searchSincronizadas = useCallback(
    async (newPage: number) => {
      const query =
        `TpNFe=${EnumTpNf.SAIDA}` +
        (filtros.status === -1
          ? ''
          : '&Status=' + filtros.status) +
        (isEmpty(filtros.dInicial)
          ? ''
          : `&DInicial=${filtros.dInicial}T00:00:00`) +
        (isEmpty(filtros.dFinal)
          ? ''
          : `&DFinal=${filtros.dFinal}T23:59:59`) +
        (isEmpty(filtros.serie)
          ? ''
          : `&Serie=${filtros.serie}`) +
        (isEmpty(filtros.empresa)
          ? ''
          : `&Empresas=${filtros.empresa}`) +
        (isEmpty(filtros.nnfInicial)
          ? ''
          : `&nnfInicial=${filtros.nnfInicial}`) +
        (isEmpty(filtros.nnfFinal)
          ? ''
          : `&nnfFinal=${filtros.nnfFinal}`) +
        (isEmpty(filtros.nnf)
          ? ''
          : `&NNF=${filtros.nnf}`) +
        (filtros.tpMov.length > 0
          ? filtros.tpMov.find(t => t === -1) ? ''
            : filtros.tpMov.reduce((acc, current) => acc + `&mod=${current}`, '')
          : '') +
        '&TemFiltro=true';

      try {
        const res = await getHistoricoVenda(query, newPage);

        if (res.erro) throw res.erro;

        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          searchSincronizadas(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.status, filtros.dInicial, filtros.dFinal, filtros.serie, filtros.empresa, filtros.nnfInicial, filtros.nnfFinal, filtros.nnf, filtros.tpMov, getHistoricoVenda, fillResult, showToast],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        searchSincronizadas(newPage);
      }
    },
    [searchSincronizadas, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    return history.push({
      pathname: `/detalhes-venda/${id}`,
      search: history.location.search
    })
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  // VENDA OFF
  const handleVendaOFF = useCallback(
    async (newPage: number) => {
      try {
        const res = await getVendas();
        if (!res) {
          setQueryVendaOff([] as VendaOffModel[]);
          return;
        }

        const vendasFilter = filterVendaOffLine(
          res as VendaOffModel[],
          filtros.serie,
          filtros.status,
          filtros.dInicial,
          filtros.dFinal,
          filtros.nnf,
          filtros.tpMov
        );

        setQueryVendaOff(vendasFilter.reverse());
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.dFinal, filtros.dInicial, filtros.nnf, filtros.serie, filtros.status, filtros.tpMov, getVendas, showToast],
  );

  const onCardVendaOffSelected = (
    id: string,
  ) => {
    return history.push({
      pathname: `/detalhes-venda/${id}`,
      search: history.location.search.length > 0 ? history.location.search + '&offline=true' : '?offline=true'
    })
  };

  const onCardVendaOffChecked = (id: string) => { };

  const tabChange = (index: number) => {
    if (index === 0) {
      replace({
        pathname: rotaSincronizadas,
        search: location.search
      });
    } else {
      replace({
        pathname: rotaNaoSincronizadas,
        search: location.search
      });
    }
  };

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname, retornaAba]);

  useEffect(() => {
    if (abaSelecionada === 0) {
      searchSincronizadas(1);
    } else {
      handleVendaOFF(1);
    }
    setCarregandoInicial(false);
  }, [abaSelecionada, handleVendaOFF, searchSincronizadas]);

  useEffect(() => {
    addHandler(AppEventEnum.AtualizarVendaOffiline, handleVendaOFF)

    return () => removeHandler(AppEventEnum.AtualizarVendaOffiline, handleVendaOFF)
  }, [addHandler, handleVendaOFF, removeHandler])

  return (
    <>
      <TabsSaurus
        selectedTabIndex={abaSelecionada}
        onChange={tabChange}
        tabsLabel={[
          new TabSaurusLabel('Sincronizadas', 0),
          new TabSaurusLabel('Não sincronizadas', 1),
        ]}
        tabsContent={[
          new TabSaurusContent(
            0,
            (
              <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container>
                  <Paginacao
                    pageChanged={pageChanged}
                    totalPages={queryStatus.totalPages}
                    totalRegisters={queryStatus.totalResults}
                    currentPage={queryStatus.page}
                  />

                  <Grid item xs={12} className={classes.listContainer}>
                    <HistoricoVendaListData
                      carregando={carregando}
                      list={queryStatus.list}
                      selectedList={selectedList}
                      onCardSelected={onCardSelected}
                      onCardChecked={onCardChecked}
                    />
                  </Grid>
                </Grid>
              </div>
            ),
          ),
          new TabSaurusContent(
            1,
            (
              <>
                <div className={classes.defaultContainer}>
                  {carregando && <CircularLoading tipo="FULLSIZED" />}
                  <Grid container>
                    <Grid item xs={12} className={classes.listContainer}>
                      <VendaOffListData
                        carregando={carregando}
                        vendas={queryVendaOff}
                        onCardChecked={onCardVendaOffChecked}
                        onCardSelected={onCardVendaOffSelected}
                        selectedList={[]}
                      />
                    </Grid>
                  </Grid>
                  <ButtonFab
                    tooltip="Sincronizar Dados"
                    icon={<SincronizarVendasIcon tipo="BUTTON_FAB" />}
                    onClick={async () => await iniciarSincronizacaoGeral()}
                  />
                </div>
              </>
            ),
          ),
        ]}
      />
    </>
  );
};
