
import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const MenuHorizontalIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <circle cx="10.75" cy="24.75" r="4.75" />
                <circle cx="25" cy="24.75" r="4.75" />
                <circle cx="39.25" cy="24.75" r="4.75" />
            </DefaultIcon>
        </>
    );
};
