import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ProdutoFiscalCadastroFormModel } from '../../../../../model/app/forms/produto-fiscal/produto-fiscal-cadastro-form-model';
import { useFormProdutoFiscalCadastroValidation } from './form-produto-fiscal-cadastro-validation';
import { CstIcmsMock } from '../../../../../data/mocks/cst-icms-mock';
import { CstPisCofinsMock } from '../../../../../data/mocks/cst-pis-mock';
import { AutocompleteCFOPs } from '../../../controles/autocompletes/autocomplete-cfops/autocomplete-cfops';
import { KeyValueModel } from '../../../../../model/api/utils/key-value';
import { picker } from '../../../../../utils/picker';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

export const FormProdutoFiscalCadastro = forwardRef<
  DefaultFormRefs<ProdutoFiscalCadastroFormModel>,
  DefaultFormProps<ProdutoFiscalCadastroFormModel>
>((props: DefaultFormProps<ProdutoFiscalCadastroFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refDescricaoGrupo = useRef<HTMLInputElement>(null);
  const { FormProdutoFiscalCadastroValidationYup } =
    useFormProdutoFiscalCadastroValidation();
  const [initialValues, setInitialValues] =
    useState<ProdutoFiscalCadastroFormModel>(
      new ProdutoFiscalCadastroFormModel(),
    );
  const { isMobile } = useThemeQueries();

  //adicioando essa tipagem no form para não alterar a model ProdutoFiscalCadastroFormModel
  interface FormType {
    cfopDesc: string;
  }

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<ProdutoFiscalCadastroFormModel & FormType>({
    defaultValues: {
      ...initialValues,
      cfopDesc: '',
    },
    resolver: yupResolver(FormProdutoFiscalCadastroValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ProdutoFiscalCadastroFormModel) => {
    const model = picker<ProdutoFiscalCadastroFormModel>(
      values,
      new ProdutoFiscalCadastroFormModel(),
    );
    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ProdutoFiscalCadastroFormModel());
      reset();
      if (!isMobile) refDescricaoGrupo.current?.focus();
    },
    fillForm: (model: ProdutoFiscalCadastroFormModel) => {
      setInitialValues(model);
      reset({ ...model });
      if (!isMobile) refDescricaoGrupo.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      inputRef={refDescricaoGrupo}
                      fullWidth
                      disabled={props.loading}
                      label="Nome do Grupo"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cfop"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteCFOPs
                      allowSubmit={false}
                      placeholder="Ex: 5102"
                      label="CFOP"
                      error={Boolean(errors.cfop && errors.cfop.message)}
                      helperText={
                        touchedFields.cfop || errors.cfop
                          ? errors.cfop?.message
                          : undefined
                      }
                      {...field}
                      onChange={(retorno) => {
                        if (!retorno.isString) {
                          let cfop = picker<KeyValueModel>(
                            retorno.value,
                            new KeyValueModel(),
                          );
                          setValue('cfop', cfop.Key.toString());
                          setValue('cfopDesc', cfop.Value.toString());
                        }
                      }}
                      value={getValues('cfopDesc')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="icmsCst"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      fullWidth
                      variant="outlined"
                      label="CST/CSOSN"
                      disabled={props.loading}
                      conteudo={CstIcmsMock}
                      error={Boolean(errors.icmsCst && errors.icmsCst.message)}
                      helperText={
                        touchedFields.icmsCst || errors.icmsCst
                          ? errors.icmsCst?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        if (event) {
                          setValue(
                            'icmsCst',
                            CstIcmsMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0]?.Key,
                          );
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="pisCst"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      fullWidth
                      variant="outlined"
                      label="CST Pis"
                      disabled={props.loading}
                      conteudo={CstPisCofinsMock}
                      error={Boolean(errors.pisCst && errors.pisCst.message)}
                      helperText={
                        touchedFields.pisCst || errors.pisCst
                          ? errors.pisCst?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        if (event) {
                          setValue(
                            'pisCst',
                            CstPisCofinsMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0]?.Key,
                          );
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="cofinsCst"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      fullWidth
                      variant="outlined"
                      label="CST Cofins"
                      disabled={props.loading}
                      conteudo={CstPisCofinsMock}
                      error={Boolean(
                        errors.cofinsCst && errors.cofinsCst.message,
                      )}
                      helperText={
                        touchedFields.cofinsCst || errors.cofinsCst
                          ? errors.cofinsCst?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        if (event) {
                          const item = CstPisCofinsMock.find(
                            (item) => item.Key === event.target.value,
                          );
                          setValue('cofinsCst', item?.Key);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
