import { EnumModeloAutenticacao } from 'model/enums/enum-modelo-autenticacao';
import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormGerenciarCartaoConvenioValidation = (modeloAutenticacao: EnumModeloAutenticacao) => {
    const { documentoYup } = useValidationYupDocumento(modeloAutenticacao !== EnumModeloAutenticacao.DocumentoCliente)
    const FormGerenciarCartaoConvenioValidation = useMemo(() => {
        return (
            Yup.object().shape({
                numeroCartao: Yup.string().when((campo, schema) => {
                    if (modeloAutenticacao !== EnumModeloAutenticacao.DocumentoCliente) {
                        return schema.required('Número do Cartão é obrigatório')
                    }
                    return schema
                }),
                senha: Yup.string().when((campo, schema) => {
                    if (modeloAutenticacao === EnumModeloAutenticacao.NumeroCartaoSenha) {
                        return schema.required('A senha é obrigatória.')
                    }
                    return schema
                }),
                cpfcnpj: documentoYup()
            })
        )
    }, [documentoYup, modeloAutenticacao])

    return {
        FormGerenciarCartaoConvenioValidation,
    }
}

