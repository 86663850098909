import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fixedHeightSmall: {
        height: '70px',
    },
    fixedHeightMedium: {
        height: '110px',
    },
    listagem:{
        maxHeight: '370px',
        overflowY: 'auto',
    },
    container:{
        height:'100%'
    },
    paginacao:{
        height: theme.spacing(6)
    }
}))
