import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from 'views/design-system';
import { SalvarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { ConfigEmpresaModel } from 'model/api/gestao/config-empresa/config-empresa-model';
import { FormConfigEmpresaEdit } from 'views/components/form/master/config-empresa-edit/form-config-empresa-edit';
import { useGetConfigContrato } from 'data/api/gestao/contrato/get-contrato-configuracao';
import { PutConfigContratoProps, usePutConfigContrato } from 'data/api/gestao/contrato/put-contrato-configuracao';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { SistemaIcon } from 'views/components/icons/sistema-icon';

interface Props {
  id: string;
}

export const AccordionConfiguracoesContrato = ({ id }: Props) => {
  // STATES E REFS
  const configFormRef =
    useRef<DefaultFormRefs<ConfigEmpresaModel[]>>(null);
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [configFormState, setConfigFormState] = useState<ConfigEmpresaModel[]>(new Array<ConfigEmpresaModel>());

  // PROVIDERS & HOOKS
  const { refreshContrato } = useContratoAtual();
  const {getEmpresaSelecionada } = useSessaoAtual();

  // CHAMADAS API
  const { getConfigContrato, carregando: carregandoGetConfigContrato } =
    useGetConfigContrato();
  const { putConfigContrato, carregando: carregandoPutConfigContrato } =
    usePutConfigContrato();

  // AUX
  const { showToast } = useToastSaurus();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();

  const carregando =
    carregandoFromForms ||
    carregandoGetConfigContrato ||
    preenchendoTela ||
    carregandoPutConfigContrato;

  const getConfigByIdWrapper = useCallback(async () => {
    const res = await getConfigContrato(getEmpresaSelecionada()?.ContratoId || '');
    if (res.erro) {
      throw res.erro;
    }
    return (res.resultado?.data as ConfigEmpresaModel[])
  }, [getConfigContrato, getEmpresaSelecionada]);

  const preencherTela = useCallback(async () => {
    setPreenchendoTela(true);
    try {
      const config = await getConfigByIdWrapper();
      setConfigFormState(config);
    } catch (e: any) {
      showToast('error', e.message);
    }
    finally {
      setPreenchendoTela(false);
    }
  }, [getConfigByIdWrapper, showToast]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        await preencherTela();
      })();
      return () => {
        //funcao de limpeza
        setConfigFormState(new Array<ConfigEmpresaModel>());
      };
    }, 200);
  }, [getConfigByIdWrapper, preencherTela, showToast]);

  const recarregarForm = useCallback((model: ConfigEmpresaModel[]) => {
    configFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(configFormState);
  }, [recarregarForm, configFormState]);

  const saveChangesConfigEmpresa = useCallback(
    async (model: ConfigEmpresaModel[]) => {
      const ret = await putConfigContrato(
        model.map(config => {
          return new PutConfigContratoProps(null, config.cod, config.vConfig.toString())
        }), getEmpresaSelecionada()?.ContratoId || '');

      if (ret.erro) {
        throw ret.erro;
      }

      return true
    },
    [getEmpresaSelecionada, putConfigContrato],
  );

  const handleSubmit = useCallback(
    async (model: ConfigEmpresaModel[]) => {
      try {
        const pendente = model.filter(item => item.editado)
        if (pendente.length < 1) {
          return showToast('info', "Nenhuma alteração para salvar.")
        }

        await saveChangesConfigEmpresa(pendente)

        await refreshContrato();


        showToast('success', "Configurações atualizadas.")
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [refreshContrato, saveChangesConfigEmpresa, showToast],
  );

  const form = useMemo(() => {
    return (
      <div
        className={classNames(
          classesModal.contentForms,
          preenchendoTela ? classesModal.contentFormsLoading : '',
        )}
        style={{ height: '100%' }}
      >
        <FormConfigEmpresaEdit
          showLoading={false}
          ref={configFormRef}
          setCarregandoExterno={setCarregandoFromForms}
          loading={carregando}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }, [
    carregando,
    classesModal.contentForms,
    classesModal.contentFormsLoading,
    handleSubmit,
    preenchendoTela,
  ]);

  const buttonSalvar = useMemo(() => {
    return (
      <div className={classesModal.acoes}>
        <Grid item xs={12}>
          <Button
            disabled={carregando}
            onClick={() => configFormRef.current?.submitForm()}
            variant="contained"
            color="primary"
            fullWidth
          >
            <SalvarIcon tipo="BUTTON_PRIMARY" />
            Salvar
          </Button>
        </Grid>
      </div>
    );
  }, [carregando, classesModal.acoes]);

  return (
    <AccordionSaurus
      labelPrimary={'Parâmetros do Sistema'}
      tituloChildren={
        <SistemaIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          {form}
          {buttonSalvar}
        </div>
      </div>
    </AccordionSaurus>
  );
};
