import { makeStyles } from "@material-ui/core";

const height = window.innerHeight;

export const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexBasis: '100%'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    textField: {
        '& svg': {
            fill: theme.palette.grey[700]
        },
        '& input::placeholder': {
            color: theme.palette.grey[700]
        },
        color: theme.palette.grey[700]
    },
    list: {
        height: `calc(${height}px - 360px)`,
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: `calc(${height}px - 375px)`
        }
    },
    listIsGerente: {
        height: `calc(${height}px - 392px)`,
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: `calc(${height}px - 393px)`
        }
    },
    listRevisao: {
        height: '100%',
        display: 'flex',
    },
}))