import { useMemo } from 'react';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils';
import { MobileStepper } from '@material-ui/core';
import { Box, Grid } from 'views/design-system';
import classNames from 'classnames';
import { useStyles } from './promocao-dados-styles';
import { EnumPromocaoDadosSteps, usePromocaoDadosSteps } from './hooks/use-promocao-dados-steps';

interface PromocaoDadosProps {
  confirmarCadastro: () => Promise<boolean>;
  cancelarCadastro: () => Promise<boolean>;
  cadastroDeNovaPromocao: boolean;
}

export const PromocaoDados = ({ confirmarCadastro, cancelarCadastro, cadastroDeNovaPromocao }: PromocaoDadosProps) => {
  // HOOKS
  const classes = useStyles();
  const stepperClasses = useStepperStyles();
  const modalClasses = useModalStyles();

  let steps = cadastroDeNovaPromocao ? [
    EnumPromocaoDadosSteps.Nome,
    EnumPromocaoDadosSteps.Periodo,
  ] : [
    EnumPromocaoDadosSteps.Periodo,
  ]

  const { carregando: carregandoStepper, formArray, formStepper } = usePromocaoDadosSteps({
    confirmarCadastro: async () => {
      if (await confirmarCadastro())
        formStepper.resetSteps();
    },
    cancelarCadastro: cancelarCadastro,
    steps: steps,
    fimStepSalvar: cadastroDeNovaPromocao
  });

  const carregando = carregandoStepper

  const getButtons = useMemo(() => {

    if (formArray.length < formStepper.currentStep)
      return <></>

    return (
      <div className={stepperClasses.stepButtons}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box>
          <Grid spacing={1} container>
            {formArray[formStepper.currentStep]?.previousButton && (
              <Grid item xs={6} sm={6}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}
            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={!formArray[formStepper.currentStep]?.previousButton ? 12 : 6}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepperClasses.stepButtons, carregando, formArray, formStepper.currentStep]);

  const renderStepsCampanha = useMemo(() => {


    return (
      <div className={classes.content}>
        <div className={classNames(classes.contentForms)}>

          <Box flex style={{ height: "100%" }} className={
            classes.containerStepsForm
          }>
            <div className={classNames(stepperClasses.stepFix)}>
              <div
                className={classNames(
                  stepperClasses.step,
                  stepperClasses.stepContent,
                  classes.step
                )}
              >
                <div className={classNames(stepperClasses.stepForm, classes.stepForm)}>
                  {formArray[formStepper.currentStep].form}
                </div>
              </div>
            </div>
          </Box>
        </div>
        <div className={classNames(modalClasses.acoes, classes.acoes)}>
          {steps?.length > 1 && (
            <div className={(stepperClasses.stepper, classes.stepper)}>
              <MobileStepper
                classes={{
                  progress: classes.mobileBarProgress,
                  root: classes.customStepContainer
                }}
                variant='progress'
                steps={4}
                position='static'
                activeStep={formStepper.currentStep + 1}
                nextButton={() => { <></> }}
                backButton={() => { <></> }}
              />
            </div>
          )}
          {getButtons}
        </div>
      </div>
    )
  }, [classes.acoes, classes.containerStepsForm, classes.content, classes.contentForms, classes.customStepContainer, classes.mobileBarProgress, classes.step, classes.stepForm, classes.stepper, formArray, formStepper.currentStep, getButtons, modalClasses.acoes, stepperClasses.step, stepperClasses.stepContent, stepperClasses.stepFix, stepperClasses.stepForm, stepperClasses.stepper, steps?.length]);


  return (
    <Box className={modalClasses.root}>
      <Box className={classes.container}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        {!carregando && renderStepsCampanha}
      </Box>
    </Box>
  );
};
