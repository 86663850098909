import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    containerScroll: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        justifyContent: 'center',
        display: 'flex',
        padding: theme.spacing(1)
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    cardImg: {
        display: "flex",
        objectFit: "contain",
        zIndex: 1,
        position: 'relative',
        background: theme.palette.common.white,
        width: 120,
        height: 120,
        borderRadius: "5px",
        transition: "0.2s ease-in-out transform",
        [theme.breakpoints.down('sm')]: {
            width: 100,
            height: 100
        }
    },
    infoProd: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    textInfo: {
        fontSize: '1.5rem',
        width: '100%',
        fontWeight: 600,
        lineHeight: '32px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'
    },
    containerButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonChanged: {
        width: 48,
        height: 48,
        padding: '0 !important',
        borderRadius: '50%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        "& svg": {
            width: 48,
            height: 48,
        }
    },
    buttonMaisMenos: {
        fill: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        width: 48,
        height: 48,
    },
    textPerson: {
        fontSize: '1.5rem',
        padding: 0,
        lineHeight: '10px',
        fontWeight: 700,
        textAlignLast: 'center',
        width: '100px',
        height: '61px',
    },
    buttonQuantidade: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonAdicionar: {
        width: '100%',
        flex: 'none',
        padding: theme.spacing(3, 2),
        paddingBottom: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    divider: {
        background: theme.palette.grey[400],
    },
    infoContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        gap: theme.spacing(1),
        width: '100%',
    },
    infoTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& h5': {
            fontSize: '1.2rem',
            fontWeight: 600,
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(1),
        }
    },
    cardInfo: {
        background: 'rgba(31, 156, 228, 0.07)',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
    infoLine: {
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap'
    },
    valorUnitario: {
        paddingTop: theme.spacing(1),
    },
    tagProdutos: {
        cursor: 'default',
        background: theme.palette.text.hint,
        padding: '2px 8px',
        borderRadius: '20px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
        "& span": {
            fontSize: 10,
        },
        "& svg": {
            width: 14,
            marginRight: 4,
            height: 14,
            fill: theme.palette.text.primary,
        }
    },
    tagCodigos: {
        background: "#def3bd",
        color: theme.palette.text.primary
    },
    tagCategoria: {
        background: '#F3E8BD',
        color: theme.palette.text.primary
    },
    tagTarja: {
        background: "#f9e8d2",
        color: theme.palette.text.primary,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagClasse: {
        background: "#e5f3ee",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagTipoMedicamento: {
        background: "#f7e0f3",
        color: theme.palette.text.primary
    },
    tagComposicao: {
        background: "#e2dbf7",
        color: theme.palette.text.primary,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagLaboratorio: {
        background: "#efefef",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagLaboratorioDestaque: {
        background: "#fdf6d0",
        "& span": {
            fontWeight: 600,
        }
    },
    qtdContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
    },
    imageContainer: {
        width: 140,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 120
        }
    },
    prodTitle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}));