import { Box, Typography } from "views/design-system";
import { useStyles } from "../pessoa-cadastro-steps-styles";
import { EnumCadastroTipo } from "model";

interface PessoaCadastroCardProps{
    icon: React.ReactNode;
    title: string;
    onClick: (tipo: EnumCadastroTipo) => void;
    tipo: EnumCadastroTipo;
}

export const PessoaCadastroCard = ({
    icon,
    title,
    onClick,
    tipo,
}

: PessoaCadastroCardProps) => {
    const classes = useStyles({});

    return (
        <Box  className={classes.card} onClick={() => onClick(tipo)}>
            {icon}
            <Typography color='primary' align='center' className={classes.cardTitle} >
                {title}
            </Typography>
        </Box>
    )
}