import { forwardRef, useImperativeHandle, useState } from "react";
import { DefaultFormProps, DefaultFormRefs } from "../../utils";
import { Controller, useForm } from "react-hook-form";
import { CircularLoading } from "views/components/utils";
import { makeUtilClasses } from "views/theme";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { PerimetroAtendimentoPresencialMock } from "data/mocks/perimetro-atendimento-presencial-mock";
import { SimNaoBooleanMock } from "data/mocks/sim-nao-mock";
import { TpOperacaoCardapio } from "data/mocks/tp-operacao-cardapio";
import { TpHistoricoVendaMock } from "data/mocks/tp-historico-venda-mock";
import { TpAmbMock } from "data/mocks/tp-amb-mock";
import { useFormConfiguracoesCardapioValidation } from "./form-configuracoes-cardapio-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { TemaPutModel } from "model/api/gestao/cardapio/tema-put-model";
import { isEqual } from "lodash";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { Box, Button, Divider, Grid, Typography } from "views/design-system";

export interface Props extends DefaultFormProps<TemaPutModel> {
    endreco: string;
}

export const FormConfiguracoesCardapio = forwardRef<
    DefaultFormRefs<TemaPutModel>,
    Props
>(
    ({ loading, endreco, ...props }: Props,
        ref,
    ) => {
        const { plano } = useSessaoAtual();
        const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);
        //AUX
        const utilClasses = makeUtilClasses();
        const { showToast } = useToastSaurus();

        //STATES 
        const [model, setModel] = useState(new TemaPutModel());
        const [configpdv, setConfigpdv] = useState(false);

        //VALIDATION
        const { formConfiguracoesCardapioValidation } = useFormConfiguracoesCardapioValidation()

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
            getValues,
            setValue,
        } = useForm<TemaPutModel>({
            resolver: yupResolver(formConfiguracoesCardapioValidation),
            defaultValues: { ...model },
            criteriaMode: 'all',
            mode: 'onChange',
        });

        const onSubmit = (form: TemaPutModel) => {
            let temaEqual = isEqual(model, form);

            if (temaEqual) {
                return showToast('info', 'Nenhuma informação foi alterada.')
            }

            props.onSubmit(form, model);
        };

        useImperativeHandle(ref, () => ({
            submitForm: async () => {
                await handleSubmit(onSubmit)();
            },
            resetForm: () => {
                reset();
            },
            fillForm: (model: TemaPutModel) => {
                setModel({ ...model });
                setConfigpdv(model.disponibilizarPagamentoFinalizacao);
                reset({ ...model });
            },
        }));

        const isConfigPDV = model.serie !== 0;

        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        {loading && props.showLoading ? (
                            <div className={utilClasses.controlLoading}>
                                <CircularLoading tipo="NORMAL" />
                            </div>
                        ) : null}

                        <form
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        weight={500}
                                    >
                                        Configurações Gerais
                                    </Typography>
                                    <Divider variant="fullWidth" />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Controller
                                        name="endereco"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                readOnly={true}
                                                label='Endereço Atual'
                                                fullWidth
                                                {...field}
                                                value={endreco}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        name="distanciaMaxima"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={PerimetroAtendimentoPresencialMock}
                                                label='Perímetro Presencial'
                                                error={Boolean(errors.distanciaMaxima && errors.distanciaMaxima.message)}
                                                helperText={
                                                    errors.distanciaMaxima
                                                        ? errors.distanciaMaxima?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    const item = PerimetroAtendimentoPresencialMock.filter(
                                                        (item) => item.Key === event.target.value,
                                                    )[0]?.Key;
                                                    if (item !== undefined) {
                                                        setValue('distanciaMaxima', item);
                                                    }
                                                }}
                                                value={getValues('distanciaMaxima')}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} md={isFarmaceutico ? 4 : 6}>
                                    <Controller
                                        name="permiteEntrega"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={SimNaoBooleanMock}
                                                label='Permite Entrega'
                                                error={Boolean(errors.permiteEntrega && errors.permiteEntrega.message)}
                                                helperText={
                                                    errors.permiteEntrega
                                                        ? errors.permiteEntrega?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    const item = SimNaoBooleanMock.filter(
                                                        (item) => item.Key === event.target.value,
                                                    )[0]?.Key;
                                                    if (item !== undefined) {
                                                        setValue('permiteEntrega', item);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} md={isFarmaceutico ? 4 : 6}>
                                    <Controller
                                        name="permiteRetirada"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={SimNaoBooleanMock}
                                                label='Permite Retirada'
                                                error={Boolean(errors.permiteRetirada && errors.permiteRetirada.message)}
                                                helperText={
                                                    errors.permiteRetirada
                                                        ? errors.permiteRetirada?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    const item = SimNaoBooleanMock.filter(
                                                        (item) => item.Key === event.target.value,
                                                    )[0]?.Key;
                                                    if (item !== undefined) {
                                                        setValue('permiteRetirada', item);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {!isFarmaceutico &&
                                    <Grid item xs={12} md={isConfigPDV ? 6 : 12}>
                                        <Controller
                                            name="tipoUsoCardapio"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={TpOperacaoCardapio}
                                                    label='Tipo de Operação'
                                                    error={Boolean(errors.tipoUsoCardapio && errors.tipoUsoCardapio.message)}
                                                    helperText={
                                                        errors.tipoUsoCardapio
                                                            ? errors.tipoUsoCardapio?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                    onChange={(event) => {
                                                        const item = TpOperacaoCardapio.filter(
                                                            (item) => item.Key === event.target.value,
                                                        )[0]?.Key;
                                                        if (item !== undefined) {
                                                            setValue('tipoUsoCardapio', item);
                                                        }
                                                    }}
                                                    value={getValues('tipoUsoCardapio')}
                                                />
                                            )}
                                        />
                                    </Grid>}
                                <Grid item xs={12} md={isFarmaceutico ? 4 : 6}>
                                    <Controller
                                        name="disponibilizarPagamentoFinalizacao"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={SimNaoBooleanMock}
                                                label='Disponibilizar Pagamento na Finalização'
                                                error={Boolean(errors.disponibilizarPagamentoFinalizacao && errors.disponibilizarPagamentoFinalizacao.message)}
                                                helperText={
                                                    errors.disponibilizarPagamentoFinalizacao
                                                        ? errors.disponibilizarPagamentoFinalizacao?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    const item = SimNaoBooleanMock.filter(
                                                        (item) => item.Key === event.target.value,
                                                    )[0]?.Key;
                                                    if (item !== undefined) {
                                                        setValue('disponibilizarPagamentoFinalizacao', item);
                                                        setConfigpdv(item)
                                                    }
                                                }}

                                            />
                                        )}
                                    />
                                </Grid>
                                {configpdv &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                                weight={500}
                                            >
                                                Configurações para venda
                                            </Typography>
                                            <Divider variant="fullWidth" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="serie"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldSaurus
                                                        label="Série"
                                                        tipo="NUMERO"
                                                        fullWidth
                                                        error={Boolean(
                                                            errors.serie && errors.serie.message,
                                                        )}
                                                        helperText={
                                                            errors.serie
                                                                ? errors.serie?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        value={getValues('serie')}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="tipoVenda"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectSaurus
                                                        conteudo={TpHistoricoVendaMock}
                                                        label="Tipo de Venda"
                                                        error={Boolean(errors.tipoVenda && errors.tipoVenda.message)}
                                                        helperText={
                                                            errors.tipoVenda
                                                                ? errors.tipoVenda?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        onChange={(event) => {
                                                            const item = TpHistoricoVendaMock.filter(
                                                                (item) => item.Key === event.target.value,
                                                            )[0]?.Key;
                                                            if (item !== undefined) {
                                                                setValue('tipoVenda', item);
                                                            }
                                                        }}
                                                        value={getValues('tipoVenda')}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="ambiente"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectSaurus
                                                        conteudo={TpAmbMock}
                                                        label="Ambiente"
                                                        error={Boolean(errors.ambiente && errors.ambiente.message)}
                                                        helperText={
                                                            errors.ambiente
                                                                ? errors.ambiente?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        onChange={(event) => {
                                                            const item = TpAmbMock.filter(
                                                                (item) => item.Key === event.target.value,
                                                            )[0]?.Key;
                                                            if (item !== undefined) {
                                                                setValue('ambiente', item);
                                                            }
                                                        }}
                                                        value={getValues('ambiente')}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>}
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        )
    });