import { EnumCadastroTipo } from "model/enums";
import { EnumSexo } from "model/enums/enum-sexo";
import { EnumUploadCarga } from "model/enums/enum-upload-carga";
import { EnumUploadCargaStatus } from "model/enums/enum-upload-carga-status";
import { EnumUploadCargaTipo } from "model/enums/enum-upload-carga-tipo";
import { guidEmpty } from "utils/guid-empty";
import { PessoaEnderecoModel } from "../pessoa";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";

export class Resumo {
  constructor(
    public descricao: string = '',
    public codigo: EnumUploadCargaStatus = EnumUploadCargaStatus.Aguardando
  ) { }
}
export class UploadCargaProduto {
  constructor(
    public tipoCadastro: EnumUploadCargaTipo = EnumUploadCargaTipo.PRODUTO,
    public id: string = guidEmpty(),
    public descricao: string = '',
    public acao: EnumUploadCargaStatus = EnumUploadCargaStatus.Aguardando,
    public codigo: string = '',
    public categoria: string = '',
    public marca: string = '',
    public medida: string = '',
    public preco: number = 0,
    public quantidade: number = 0,
    public ncm: string = '',
    public ref: string = '',
    public descImposto: string = '',
    public subCategoria: string = '',
    public status: Resumo = new Resumo(),
    public alteracoes: number = 0,
    public rejeicoes: number = 0,
    public tpCadastro: EnumTipoProduto = EnumTipoProduto.Produto
  ) { }
}
export class UploadCargaPessoa {
  constructor(
    public id: string = guidEmpty(),
    public documento: string = '',
    public telefone: string = '',
    public razaoSocial: string = '',
    public cep: string = '',
    public ativo: boolean = true,
    public tipo: EnumCadastroTipo = EnumCadastroTipo.FUNCIONARIO,
    public acao: EnumUploadCargaStatus = EnumUploadCargaStatus.Aguardando,
    public tipoCadastro: EnumUploadCargaTipo = EnumUploadCargaTipo.PESSOA,
    public status: Resumo = new Resumo(),
    public alteracoes: number = 0,
    public rejeicoes: number = 0,
    public sexo: EnumSexo = EnumSexo.Masculino,
    public dtNasc: string = '',
    public email: string = '',
    public endereco: PessoaEnderecoModel = new PessoaEnderecoModel(),
  ) { }
}

export class UploadCargaStatus{
  constructor(
    public codigo: EnumUploadCarga = EnumUploadCarga.PROCESSADO,
    public descricao: string = ''
  ){}
}

export class UploadTipoArquivo{
  constructor(
    public codigo: EnumUploadCargaTipo = EnumUploadCargaTipo.PRODUTO,
    public descricao: string = ''
  ){}
}

export class UploadCargaModel {
  constructor(
    public id: string = guidEmpty(),
    public prioriedade: number = 0,
    public retorno: string = '',
    public origem: string | null = null,
    public status: UploadCargaStatus = new UploadCargaStatus(),
    public dataAnalise: string = '',
    public dataProcessamento: string = '',
    public dataFinalizacao: string = '',
    public dataCancelamento: string = '',
    public processamentoImediato: boolean = true,
    public tipoArquivo: UploadTipoArquivo = new UploadTipoArquivo(),
    public urlJson: string = ''
  ) { }
}

export class UploadCargaDetalhadoModel {
  constructor(
    public id: string = guidEmpty(),
    public tipo: EnumUploadCargaTipo = EnumUploadCargaTipo.PRODUTO,
    public data: string = '',
    public registros: number = 0,
    public status: EnumUploadCarga = EnumUploadCarga.CANCELADO,
    public pessoas: UploadCargaPessoa[] = [],
    public produtos: UploadCargaProduto[] = [],

  ) { }
}
