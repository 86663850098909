import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { CardMesaProps } from './card-mesa-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { useCallback } from 'react';
import { EnumMesas } from 'model/enums/enum-mesas';
import { MesaStatusMock } from 'data/mocks/mesas-mock';

export const CardMesa = ({ model, selected, onClick }: CardMesaProps) => {
  const classes = useDefaultCardStyles();
  const theme = useTheme();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status.codigo) {
      case EnumMesas.ATIVO:
        return theme.palette.success.main;
      case EnumMesas.INATIVO:
        return theme.palette.error.main;
      case EnumMesas.INVALIDO:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status,
  ]);

  const descricao = MesaStatusMock.filter(
    (x) => x.Key === model.status.codigo,
  )[0].Value;

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid}>
            <MesaIcon tipo="BUTTON" fill={theme.palette.primary.main} />
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Código Mesa
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.codigo}
            </Typography>
          </div>
          <div className={classes.celulaGridFull}></div>
        </div>
        <Tooltip arrow title="Editar Mesa">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
