import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100%',
        overflow: 'hidden',
        flexGrow: 1,
    },
    containerMain: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden'
    },
    containerBottom: {
        marginTop: 1,
        borderTop: '1px solid ' + theme.palette.grey[300],
        background: theme.palette.grey[100],
        width: '100%',
        flexGrow: 0,
    },
    containerFaturamento: {
        padding: theme.spacing(1),
        background: theme.palette.grey[100],
        width: '100%'
    },
    botaoFaturar: {
        backgroundColor: "#f19c07",
    },
}))