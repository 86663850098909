import { Redirect, Route } from 'react-router-dom';
import { ProtectedProps } from './protected-route-props';

export const ProtectedRoute = ({
  component: Component,
  roles,
  validatePermission,
  ...rest
}: ProtectedProps<any>) => {
  const isPermission: boolean = validatePermission(roles);

  return (
    <Route
      {...rest}
      render={(props) => {
        const params = {
          ...rest,
          ...props
        };
        return isPermission ? (
          <Component {...params} />
        ) : (
          <Redirect to="/not-allowed" />
        );
      }}
    />
  );
};
