import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        position: 'relative',
    },
    containerPDV: {
        overflow: "hidden",
    }
}))