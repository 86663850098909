import { useCallback } from 'react';
import { VariaveisAmbiente } from 'config';
import { useApiBase } from 'data/api/base';

export function useGetMovimentacoesProdutoEstoque() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMovimentacoesProdutoEstoque = useCallback(
        (queryBusca: string, empresaId: string, page?: Number) => {
            let queryPage = '';
            if (page) {
              queryPage = 'page=' + page.toString();
            }
      
            let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? '?' : ''
              }${queryPage}${queryPage.length > 0 ? '&' : ''}${queryBusca}`;
      
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Dashboard/mov-prod-estoque${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getMovimentacoesProdutoEstoque
    };
}