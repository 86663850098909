import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComissionadosById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissaoComissionadosById = useCallback(
        (empresaId: string, comissaoId: string) => {
          return invocarApi({
            url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/comissao/${comissaoId}/comissionados?pageSize=150`,
            method: "GET",
            enviarTokenUsuario: true,
          });
        },
        [invocarApi]
      );

    return {
        ...outrasPropriedades,
        getComissaoComissionadosById,
    };
}
