import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormGerenciarCartaoConvenioValidation } from './form-convenio-autenticar-validation';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { stringNumeros } from 'utils/string-numeros';
import { CartaoIcon } from 'views/components/icons';
import { Box, Button, Grid } from 'views/design-system';
import { EnumModeloAutenticacao } from 'model/enums/enum-modelo-autenticacao';
import { ConvenioCartaoAutenticarFormModel } from 'model/app/forms/convenio/convenio-cartao-autenticar-form-model';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';

export const FormConvenioAutenticar = forwardRef<
    DefaultFormRefs<ConvenioCartaoAutenticarFormModel>,
    DefaultFormProps<ConvenioCartaoAutenticarFormModel>
>((props: DefaultFormProps<ConvenioCartaoAutenticarFormModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const { getConfigByCod } = useEmpresaAtual()
    const modeloAutenticacao = getConfigByCod(EnumEmpresaConfig.ModeloAutenticacao) as EnumModeloAutenticacao
    const naoAparecerSenha = modeloAutenticacao !== EnumModeloAutenticacao.NumeroCartaoSenha
    const isModeloDocumentoCliente = modeloAutenticacao === EnumModeloAutenticacao.DocumentoCliente

    const { FormGerenciarCartaoConvenioValidation } = useFormGerenciarCartaoConvenioValidation(modeloAutenticacao);
    const [modelForm, setModelForm] = useState<ConvenioCartaoAutenticarFormModel>(new ConvenioCartaoAutenticarFormModel())

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
    } = useForm<ConvenioCartaoAutenticarFormModel>({
        resolver: yupResolver(FormGerenciarCartaoConvenioValidation),
        criteriaMode: 'all',
        mode: 'onChange',
        defaultValues: modelForm
    });


    const onSubmit = (values: ConvenioCartaoAutenticarFormModel) => {
        values.numeroCartao = stringNumeros(values?.numeroCartao ?? '')
        values.senha = naoAparecerSenha ? '' : values.senha
        props.onSubmit(values, modelForm);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: ConvenioCartaoAutenticarFormModel) => {
            setModelForm(model)
            reset(model)
        },
    }));

    const inputNumeroCartao = useRef<HTMLInputElement>(null)
    const inputDocumentoCliente = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if(isModeloDocumentoCliente){
            inputDocumentoCliente.current?.focus()
        }else{
            inputNumeroCartao.current?.focus()
        }
      }, [isModeloDocumentoCliente]);

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Box my={2}>
                        <Grid container spacing={2}>
                            {!isModeloDocumentoCliente && (
                                <>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="numeroCartao"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'CNPJ_CPF_GERAL'}
                                                    disabled={props.loading}
                                                    allowSubmit
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Número do Cartão"
                                                    showStartAdornment
                                                    startAdornmentButton={<CartaoIcon tipo="INPUT" />}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(
                                                        errors.numeroCartao && errors.numeroCartao.message,
                                                    )}
                                                    helperText={
                                                        touchedFields.numeroCartao || errors.numeroCartao
                                                            ? errors.numeroCartao?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                    inputRef={inputNumeroCartao}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {!naoAparecerSenha && (
                                        <Grid item xs={12}>
                                            <Controller
                                                name="senha"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldSaurus
                                                        tipo={'PASSWORD'}
                                                        showStartAdornment
                                                        disabled={props.loading}
                                                        allowSubmit
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Senha"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(
                                                            errors.senha && errors.senha.message,
                                                        )}
                                                        helperText={
                                                            touchedFields.senha || errors.senha
                                                                ? errors.senha?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                            {isModeloDocumentoCliente && (
                                <Grid item xs={12}>
                                    <Controller
                                        name="cpfcnpj"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={'CNPJ_CPF'}
                                                showStartAdornment
                                                disabled={props.loading}
                                                allowSubmit
                                                fullWidth
                                                variant="outlined"
                                                label="CPF/CNPJ"
                                                startAdornmentButton={<PessoaIcon tipo="INPUT" />}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(
                                                    errors.cpfcnpj && errors.cpfcnpj.message,
                                                )}
                                                helperText={
                                                    touchedFields.cpfcnpj || errors.cpfcnpj
                                                        ? errors.cpfcnpj?.message
                                                        : undefined
                                                }
                                                {...field}
                                                inputRef={inputDocumentoCliente}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
