import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { CardProdutoPedidoProps } from './card-produto-pedido-props';
import { useStyles } from './card-produto-pedido-styles';
import { toDecimalString } from 'utils/to-decimal';
import React from 'react';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useDefaultCardStyles } from '../components';
import { useThemeQueries } from 'views/theme';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { Button, Divider, Grid, Typography } from 'views/design-system';
import { Paper } from '@material-ui/core';

const CardProdutoPedido = ({
  model,
  inativarProduto,
  statusPedido,
}: CardProdutoPedidoProps) => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();
  const { theme, isMobile } = useThemeQueries();

  return (
    <Grid
      item
      xs={12}
      className={`${model.status.codigo === EnumStatusProdutoPedido.DESISTENCIA
        ? classes.cardinativo
        : ''
        }`}
    >
      <Paper
        className={`${classNames('card')} ${classes.containerInternoCard}`}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classesCard.celulaGrid} style={{ marginLeft: 0 }}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{
                  minWidth: isMobile ? '120px' : '250px',
                  maxWidth: isMobile ? '140px' : '250px',
                  color: theme.palette.primary.main,
                }}
              >
                <strong>{model.descricao} </strong>
              </Typography>
            </div>
            <div className={classesCard.celulaGridFull}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{ maxWidth: isMobile ? '100px' : '100%' }}
              >
                {model.quantidade % 1 > 0 ? toDecimalString(model.quantidade, 3) + ' ' + model.unidadeComercial : model.quantidade + ' ' + model.unidadeComercial}
              </Typography>
            </div>
            <div className={classesCard.celulaGrid} style={{ marginRight: '5px' }}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{ maxWidth: isMobile ? '130px' : '100%' }}
              >
                <strong>R$ {toDecimalString(((model.tpProduto.codigo === EnumTpProduto.Combo ? 0 : model.valorTotal) +
                  model.adicionais.reduce((acc, current) => acc + current.valorTotal, 0)), 2)}
                </strong>
              </Typography>
            </div>
            |
            <Button
              disabled={
                model.status.codigo === EnumStatusProdutoPedido.DESISTENCIA ||
                  statusPedido === EnumStatusPedido.FECHADO ||
                  statusPedido === EnumStatusPedido.IMPORTADO
                  ? true
                  : false
              }
              onClick={() => inativarProduto(model)}
              className={classes.btnInativar}
            >
              <CloseIcon />
            </Button>
          </div>
          {model?.adicionais?.length > 0 && (
            <>
              <Typography variant="body1" style={{ fontSize: 12 }}>{model.tpProduto.codigo !== EnumTpProduto.Combo ? 'Adicionais' : 'Composição'}</Typography>
              <Divider className={classes.divider} />
            </>
          )}
          {model?.adicionais?.length > 0 && model.adicionais.map((p, index) => (
            <Grid key={index}>
              <Typography>{p.quantidade}X {p.descricao}</Typography>
            </Grid>
          ))}
          {model.motivoCancelamento && (
            <>
              <Typography variant="caption" color="primary">
                Motivo do Cancelamento
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.motivoCancelamento}
              </Typography>
            </>
          )}
        </div>
      </Paper>
    </Grid>
  );
};
export default React.memo(CardProdutoPedido);
