import { Typography } from '@material-ui/core';
import { CardUploadCargaProps } from './card-upload-props';
import { DefaultCard } from '../components/default-card';
import { useStyles } from './card-upload-carga-styles';
import { useThemeQueries } from '../../..';
import { StatusUploadCargaMock } from 'data/mocks/status-upload-carga-mock';
import { useCallback } from 'react';
import { EnumUploadCarga } from 'model/enums/enum-upload-carga';
import { TpUploadCargaTipoMock } from 'data/mocks/tp-upload-carga-tipo-mock';
import { toDateString } from 'utils/to-date';

export const CardUploadCarga = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardUploadCargaProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const tipoCarga = TpUploadCargaTipoMock.filter((x) => x.Key === model.tipoArquivo.codigo)[0]
    .Value;

  const status = StatusUploadCargaMock.filter((x) => x.Key === model.status.codigo)[0]
    .Value;

  const retornarCorStatus = useCallback((): any => {
    switch (model.status.codigo) {
      case EnumUploadCarga.PENDENTE:
        return '#E4A501';
      case EnumUploadCarga.AGUARDANDO:
        return '#1F9CE4';
      case EnumUploadCarga.ANALISANDO:
        return '#7200CC';
      case EnumUploadCarga.PROCESSADO:
        return '#10C822';
      case EnumUploadCarga.CANCELADO:
        return '#F44336';
      case EnumUploadCarga.REJEITADO:
        return '#F44336';
      case EnumUploadCarga.CONFIRMACAO:
        return '#1F9CE4';
      default:
        return '#4A4A4A';
    }
  }, [model.status]);

  const retornarData = () => {
    let dt = ''
    switch (model.status.codigo) {
      case EnumUploadCarga.CONFIRMACAO:
        dt = model.dataProcessamento;
        break;
      case EnumUploadCarga.PROCESSADO:
        dt = model.dataFinalizacao;
        break;
      case EnumUploadCarga.CANCELADO:
        dt = model.dataCancelamento;
        break;
      default:
        dt = model.dataAnalise;
    }

    return dt || model.dataAnalise ? toDateString(dt ? dt : model.dataAnalise, 'DD/MM/yyyy HH:mm') : 'Sem Data'
  }

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model?.id);
        }}
      >
        <div className={classes.cardContent}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              paddingRight: '5px',
            }}
          >
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Data
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body2"
              >
                <strong>{retornarData()}</strong>
              </Typography>
            </div>
            <div className={classes.celulaGridFull}>
              <Typography color="textPrimary" variant="caption">
                Tipo
              </Typography>
              <Typography color="textPrimary" variant="body1">
                {tipoCarga}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Status
              </Typography>
              <div
                style={{
                  display: 'flex',
                  padding: theme.spacing(0.5),
                  borderRadius: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: retornarCorStatus(),
                  height: '15px',
                }}
              >
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body2"
                  style={{ color: '#fff', fontWeight: 600, fontSize: '10px' }}
                >
                  {status}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
