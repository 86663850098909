import { Grid, Typography } from "views/design-system";
import { useStyles } from "./modal-header-styles";
import { ModalHeaderProps } from "./modal-header-props"
import React from "react";

export const ModalHeader = (props: ModalHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.mainHeaderContainer}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between" flexDirection="row" alignItems="center">
            <Grid item className={classes.flexContent}>
              <Grid container spacing={1} alignItems="center">
                {props.leftArea !== undefined ? (
                  <Grid item className={classes.flexFixed}>{props.leftArea}</Grid>
                ) : null}
                {
                  (!props.middleArea) && (
                    <Grid item className={classes.flexContent}>
                      {React.isValidElement(props.title) ? props.title : (
                        <Typography variant="h4" className={classes.title} ref={props.titleRef}>
                          {props.title}
                        </Typography>
                      )}
                    </Grid>
                  )}
                {
                  props.middleArea &&
                  (<Grid item className={classes.flexContent}>
                    {props.middleArea}
                  </Grid>)
                }
              </Grid>
            </Grid>
            {props.rightArea !== undefined ? (
              <Grid item className={classes.flexFixed}>{props.rightArea}</Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
