import { CarrinhoList } from '../carrinho-list/carrinho-list';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import {
  useCadastros,
  useSessaoAtual,
  useToastSaurus
} from 'services/app';
import { useCarrinho } from 'services/app/hooks/carrinho';
import { memo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { Grid } from 'views/design-system';
import { useStyles } from './carrinho-itens-styles';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';

const CarrinhoItens = () => {
  // AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  // PROVIDERS
  const { getPermissaoBoolean } = useSessaoAtual();
  // HOOKS
  const { abrirAdicionarProdutoSubItem, abrirSolicitarPermissao } =
    useCadastros();
  const { alterarQtdProduto, inativarProduto, reativarProduto } = useCarrinho();
  const { getMov } = useMovAtual();
  const { redirectLanding } = useMovRota();

  const checkInactivatePermission = useCallback(
    async (model: MovSimplesProdutoModel, naoAlteraTaxa: boolean = false) => {
      if (!getPermissaoBoolean(EnumCodigosPermissoes.CANCELAMENTO_ITENS)) {
        abrirSolicitarPermissao(
          async () => {
            await inativarProduto(model, naoAlteraTaxa);
          },
          EnumCodigosPermissoes.CANCELAMENTO_ITENS,
          'inativar o produto'
        );
        return;
      }
      //caso tenha permissão segue o fluxo normalmente
      inativarProduto(model, naoAlteraTaxa);
    },
    [abrirSolicitarPermissao, getPermissaoBoolean, inativarProduto]
  );

  const editProductWithSubItem = useCallback(
    (produtoId: string) => {
      try {
        const mov = getMov();
        if (isEmpty(mov) || !mov) {
          showToast('error', 'Não existe uma venda em Andamento.');
          redirectLanding();
          return;
        }

        const produto = mov.produtos.find((p) => p.id === produtoId);
        if (isEmpty(produto)) return;

        abrirAdicionarProdutoSubItem(produto, true);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [abrirAdicionarProdutoSubItem, getMov, redirectLanding, showToast]
  );

  return (
    <Grid container className={classes.list}>
      <CarrinhoList
        inativarProduto={checkInactivatePermission}
        reativarProduto={reativarProduto}
        alterarQtdeProduto={alterarQtdProduto}
        setProductEdit={editProductWithSubItem}
      />
    </Grid>
  );
};

export default memo(CarrinhoItens);
