import { Tooltip, Typography } from '@material-ui/core';
import { CardTaxaServicoProps } from './card-taxa-servico-carrinho-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { memo } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { useStyles } from './card-taxa-servico-carrinho-styles'
import classNames from 'classnames';

export const CardTaxaServicoCarrinho = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardTaxaServicoProps) => {

  const classes = useDefaultCardStyles();
  const myClasses = useStyles()

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model);
        }}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Vendedor
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model?.vendedorNome || '-'}
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Valor
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              R$ {toDecimalString(model.vFinal)}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Taxa">
          <div className={classNames(classes.cardRightButton, myClasses.iconCor)}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default memo(CardTaxaServicoCarrinho);
