import { isEmpty } from 'lodash';
import { EnumMenuPrincipalStatus } from 'model/enums/enum-menu-principal-status';
import React from 'react';
import { GestaoStorageKeys } from 'services/app';
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { create } from 'zustand';

interface DeviceDiffProps<T> {
    desktop: T,
    mobile: T,
}

export type HeaderProp<T> = T | DeviceDiffProps<T>;

interface VoltarProps {
    onClick: () => any;
}

/*
    EXPLICANDO ESSA INTERFACE:
    ELA SERVE EXCLUSIVAMENTE PRA INFORMAR SE O MODO DE PESQUISA DO HEADER
    VAI ATIVAR DIGITANDO LETRAS OU NUMEROS. VOU USAR COMO EXEMPLO A TELA
    DE TECLADO AVULSO, ELA POSSUI UM TECLADO Q CAPTURA NUMEROS, PORTANTO,
    EU NÃO POSSO ABRIR O HEADER CASO DIGITE UM NUMERO, APENAS LETRAS,
    NO ENTANTO, UMA VEZ QUE O MODO DE PESQUISA DO HEADER ESTEJA ABERTO
    EU PRECISO PERMITIR QUE ELE DIGITE NUMEROS (ESSA FUNÇÃO CAI NA OUTRA
    PROP DE allowLetras e allowNumeros)
*/
export interface CapturarProps{
    capturarNumeros?: boolean;
    capturarLetras?: boolean;
}
interface PesquisaProps {
    capturarDigitacao?: boolean | CapturarProps;
    allowNumbers?: boolean;
    allowLetras?: boolean;

}

interface CustomButtonProps {
    icon: React.ReactElement;
    onClick: () => any;
    tooltip?: string;
}

interface IStateStore {
    showDefinicoes?: HeaderProp<boolean>;
    showMenuPdv?: HeaderProp<boolean>;
    pesquisa?: PesquisaProps;
    showFiltro?: HeaderProp<boolean>;
    openFiltro?: HeaderProp<boolean>;
    hide?: HeaderProp<boolean>;
    title?: HeaderProp<string>;
    id?: HeaderProp<string>;
    voltar?: HeaderProp<VoltarProps>;
    customButton?: HeaderProp<CustomButtonProps[]>;
    search?: string;
}

export interface IConvertedStateProps {
    showDefinicoes?: boolean;
    showMenuPdv?: boolean;
    pesquisa?: PesquisaProps;
    showFiltro?: boolean;
    openFiltro?: boolean;
    hide?: boolean;
    title?: string;
    id?: string;
    voltar?: VoltarProps;
    customButton?: CustomButtonProps[];
    search?: string;
}

interface IVendaHeaderStore {
    props: IStateStore;
    setHeaderProps: (props: IStateStore) => void;
    setSearch: (termo: string) => void;
    setHideHeader: (hide: boolean) => void;
    switchFiltro: () => void;
}

const isMenuAberto = () => {
    const str = window.localStorage.getItem(GestaoStorageKeys[GestaoStorageKeys.ConfiguracoesMenu].toString());
    if (!isEmpty(str)) {
        const menu = JSON.parse(str || '') as MenuPrincipalModel;
        if (menu.situacao === EnumMenuPrincipalStatus.Aberto) {
            return true;
        }
    }
    return false;
}

export const useVendaHeaderStore = create<IVendaHeaderStore>((set) => ({
    props: {
        id: '',
        title: '',
        showDefinicoes: false,
        showMenuPdv: false,
        pesquisa: undefined,
        showFiltro: false,
        openFiltro: false,
        hide: false,
        voltar: undefined,
        customButton: undefined,
        habilitarDigitacao: false,
        search: '',
    },
    setHeaderProps(
        {
            showDefinicoes = false,
            showMenuPdv = false,
            pesquisa = undefined,
            showFiltro = false,
            hide = false,
            title = '',
            voltar = undefined,
            customButton = undefined,
            search = '',
        }: IStateStore
    ) {
        hide = isMenuAberto();
        set((state) => ({
            props: {
                ...state.props,
                showDefinicoes,
                showMenuPdv,
                pesquisa,
                showFiltro,
                title,
                hide,
                voltar,
                customButton,
                search
            }
        }));
    },
    setSearch(termo: string) {
        set((state) => ({
            props: {
                ...state.props,
                search: termo
            }
        }))
    },
    switchFiltro() {
        set((state) => ({
            props: {
                ...state.props,
                openFiltro: !state.props.openFiltro,
            }
        }))
    },
    setHideHeader(hide: boolean) {
        set((state) => ({
            props: {
                ...state.props,
                hide
            }
        }))
    },
}));