import { makeStyles } from "@material-ui/core";

export const useDefaultCadastroStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
        flexGrow: 1,
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
        position: "relative",
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        height: '100%'
    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(2),
    },
    contentForms: {
        flex: "1 1 auto",
        padding: theme.spacing(2),
        height: "0px",
        overflowX: "hidden",
        overflowY: "auto",
        "& * > form": {
            visibility: "visible",
        },
        "&  .tabs-class": {
            visibility: "visible",
        },
    },
    contentFormsLoading: {
        "& * > form": {
            visibility: "hidden",
        },

        "&  .tabs-class": {
            visibility: "hidden",
        },
    },
    halfPage: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            width: '60%'
        }
    },
    cartPage: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            width: '40%'
        }
    },
    divisoria: {
        borderLeft: '4px solid ' + theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            borderLeft: 'none'
        }
    },
    pagesContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'stretch',
        overflowY: 'auto',
    },
    fullWidth: {
        width: '100% !important'
    }
}));
