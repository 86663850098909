import { Box, Button, Grid, Typography } from 'views/design-system';
import { useStyles } from './mov-cancelar-page-styles';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { CircularLoading, useThemeQueries } from 'views';
import { useConfirm } from 'material-ui-confirm';
import { CancelarVendaIcon } from 'views/components/icons/cancelar-venda-icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toDecimalString } from 'utils/to-decimal';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { CaracteresString } from 'utils/caracteres-string';
import { TpModMock } from 'data/mocks';
import { useGetVendaSimplificada } from 'data/api/gestao/venda-simplificada/get-venda-simplificada';
import { VendaSimplificadaModel } from 'model/api/gestao/venda-simplificada/venda-simplificada-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { OkIcon } from 'views/components/icons';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { toDateString } from 'utils/to-date';
import classNames from 'classnames';
import { useDeleteCancelarVenda } from 'data/api/gestao/venda/delete-cancelar-venda';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useMovAnteriorStorage } from 'services/app/hooks/mov-anterior-storage';
import { EnumIdeStatus } from 'model/enums/enum-ide-status';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

type Params = {
  id: string;
};

const MovCancelarPage = () => {
  const classes = useStyles();
  const { isMobile, theme } = useThemeQueries();
  const { getPermissaoBoolean } = useSessaoAtual()
  const { abrirSolicitarPermissao } = useCadastros()
  const {
    getMov,
    cancelarMov,
    carregando: carregandoMovSimples,
  } = useMovAtual();
  const { get: getMovAnterior, persist: persistMovAnterior } = useMovAnteriorStorage();
  const { deleteCancelarVenda, carregando: deleteCancelarVendaCarregando } =
    useDeleteCancelarVenda();
  const { getVendaSimplificada, carregando: getVendaSimplificadaCarregando } =
    useGetVendaSimplificada();
  const confirm = useConfirm();
  const { id } = useParams<Params>();
  const [motivoCancelamento, setMotivoCancelamento] = useState<string>('');
  const [venda, setVenda] = useState<VendaSimplificadaModel>(
    new VendaSimplificadaModel(),
  );
  const history = useHistory();
  const { showToast } = useToastSaurus();
  const mov = getMov();
  const { deletePedidoDelivery } = usePedidoDelivery();
  const pagamentos = venda?.pagamento.split(',')
  const location = useLocation<{ urlCalback?: string | null | undefined }>();
  const movAnterior = getMovAnterior();
  const { redirectLanding } = useMovRota();
  const carregando =
    deleteCancelarVendaCarregando ||
    carregandoMovSimples ||
    getVendaSimplificadaCarregando

  const input = useRef<HTMLInputElement>(null);

  //EFFECT INICIAL
  useEffect(() => {
    if (!id) {
      if (!mov) {
        redirectLanding().catch((e) => {
          showToast('error', e.message);
        });
        return;
      }
    }
  }, [history, id, mov, redirectLanding, showToast]);

  const handleClickFinalizar = async () => {
    try {
      await cancelarMov(
        'Motivo do Cancelamento: ' +
        (motivoCancelamento.length < 15
          ? 'Não informado pelo operador!'
          : motivoCancelamento),
        location.state?.urlCalback
      );
      deletePedidoDelivery();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const getVendaSimplificadaWrapper = useCallback(async () => {
    try {
      const ret = await getVendaSimplificada(id);
      if (ret.erro) throw ret.erro;
      const res = ret.resultado?.data as VendaSimplificadaModel;
      setVenda(res);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getVendaSimplificada, id, showToast]);

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Cancelamento de Venda',
      showMenuPdv: !id,
      voltar: id ? {
        onClick: () => {
          history.push(location.state?.urlCalback ?? MovRotasMock.landingRota);
        }
      } : undefined,
    })
    if (id) {
      getVendaSimplificadaWrapper();
    }
    setTimeout(() => input.current?.focus(), 100)
  }, [getVendaSimplificadaWrapper, history, id, location.state?.urlCalback, setHeaderProps]);

  const handleCancelarVenda = async () => {
    try {
      await deleteCancelarVenda(
        id,
        'Motivo do Cancelamento: ' +
        (motivoCancelamento.length < 15
          ? 'Não informado pelo operador!'
          : motivoCancelamento)
      );
      deletePedidoDelivery();
      showToast('success', 'Venda cancelada!');

      if (new URLSearchParams(history.location.search).get('vendaAnterior') && movAnterior) {
        persistMovAnterior({ ...movAnterior, status: EnumIdeStatus.CANCELADO });
      }

      if (location.state?.urlCalback) {
        return history.push(location.state?.urlCalback);
      }
      redirectLanding();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const handleClickCancel = () => {
    const motivo = motivoCancelamento !== undefined && motivoCancelamento.length > 15 ? (
      `Tem certeza que deseja cancelar a venda? Motivo: ${motivoCancelamento}`) :
      (`O motivo do cancelamento não foi informado pelo operador, então será informado o texto a seguir. Motivo: Não informado pelo operador!`)

    confirm({
      title: `Motivo de cancelamento`,
      description: motivo,
      cancellationText: 'Voltar',
      cancellationButtonProps: {
        color: 'default',
      },
      confirmationText: 'Cancelar Venda',
    }).then(async () => {
      if (!id) {
        await handleClickFinalizar();
      } else {
        handleCancelarVenda();
      }
    });
  };

  const nnf = mov?.nnf || venda?.nnf;
  const serie = mov?.serie || venda?.serie;

  const checarPermissao = useCallback(() => {
    const permitido = getPermissaoBoolean(530104)

    //caso não tenha permissão
    if (!permitido) {
      abrirSolicitarPermissao(
        async () => {
          if (!id) {
            if (mov?.produtos.length === 0 || mov?.produtos.length === undefined) {
              handleClickFinalizar()
            } else {
              handleClickCancel()
            }
          } else {
            handleClickCancel()
          }
        }, 530104, 'cancelar a venda')
      return
    }

    if (!id) {
      if (mov?.produtos.length === 0 || mov?.produtos.length === undefined) {
        handleClickFinalizar()
      } else {
        handleClickCancel()
      }
    } else {
      handleClickCancel()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abrirSolicitarPermissao, getPermissaoBoolean, id, mov?.produtos.length, motivoCancelamento])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.containerCancelamento}>
          <Grid className={classes.contentPC}>
            <Grid
              className={
                isMobile ? classes.infoContainer : classes.infoContainerPC
              }
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    maxWidth: '100%',
                    margin: '8px 0',
                    justifyContent: 'center',
                  }}
                >
                  <CancelarVendaIcon tipo="GERAL" style={{ width: '130px' }} />
                </Grid>
              </Grid>
              <Grid className={classes.infoContent}>
                <Grid container justifyContent="space-between">
                  <Typography className={classes.label}>
                    Número / Série
                  </Typography>
                  <Typography className={classes.label}>
                    Data de Emissão
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: 16 }}
                  justifyContent="space-between"
                >
                  <Typography className={classes.infoNomeEQuantidade}>
                    {nnf ? nnf + '/' + serie : 'Não Gerado'}
                  </Typography>
                  <Typography className={classes.infoNomeEQuantidade}>
                    {!id
                      ? toDateString(new Date(mov?.dEmi || new Date()))
                      : toDateString(new Date(String(venda.dhEmi)))}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.label}>Cliente</Typography>
                </Grid>
                <Grid container style={{ marginBottom: 16 }}>
                  <Typography className={classes.infoNomeEQuantidade}>
                    {!id ?
                      ((mov?.cliente?.cpfcnpj || '') !== "" ?
                        formatarCPFCNPJ(mov?.cliente?.cpfcnpj || '') + ' - ' : '') + CaracteresString(mov?.cliente?.nome || '', 25) :
                      venda?.destDoc ? formatarCPFCNPJ(venda?.destDoc || '') + ' - ' : '' + CaracteresString(venda?.destNome || 'Não Informado', 25)}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.label}>Pagamento</Typography>
                </Grid>
                <Grid container style={{ marginBottom: 16 }}>
                  <Typography className={classes.infoNomeEQuantidade}>
                    {!id
                      ? TpModMock.filter(
                        (item) => item.Key === mov?.pags[0]?.modPagamento,
                      )[0]?.Value || 'Não selecionado'
                      : pagamentos[0]}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography className={classes.label}>Itens</Typography>
                  <Typography className={classes.label}>Valor</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Typography className={classes.infoItemValor}>
                    {!id ? mov?.qCom : venda.totalQtCom}
                  </Typography>
                  <Typography className={classes.infoItemValor}>
                    R${' '}
                    {!id
                      ? toDecimalString(mov?.vNF)
                      : toDecimalString(venda.totalVNf)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={classes.infoContent}>
                <Box style={{ flex: 1 }}>
                  <Typography className={classes.infoNomeEQuantidade}>
                    Digite o motivo do cancelamento desta venda.
                  </Typography>

                  <TextFieldSaurus
                    fullWidth
                    size="small"
                    minRows={2}
                    multiline
                    maxRows={5}
                    allowSubmit={true}
                    value={motivoCancelamento}
                    inputRef={input}
                    placeholder="Motivo não informado pelo Operador"
                    onChange={(e) =>
                      setMotivoCancelamento(e.target.value.substring(0, 50))
                    }
                    adornmentColor={theme.palette.text.disabled}
                  />

                  <Grid container>
                    <Grid item xs={8}>
                      <Typography className={classes.label}>
                        Mínimo de 15 caracteres
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'end' }}>
                      <Typography className={classes.label}>
                        (
                        <span
                          className={
                            motivoCancelamento.length > 1 &&
                              motivoCancelamento.length < 15
                              ? classes.labelVermelho
                              : motivoCancelamento.length > 14
                                ? classes.labelVerde
                                : ''
                          }
                        >
                          {motivoCancelamento.length}
                        </span>
                        )
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid container>
                <Typography
                  className={classes.textSubtitle}
                  style={{
                    margin: '0 8px',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  Para confirmar o cancelamento, clique no botão abaixo.
                </Typography>
              </Grid>
            </Grid>


          </Grid>
        </Grid>
        <Grid className={classes.containerButton}>
          <Button
            variant="contained"
            fullWidth
            color="error"
            size="large"
            rounded
            style={{ margin: '8px 0' }}
            onClick={checarPermissao}

            className={classNames('round')}
          >
            <OkIcon tipo="BUTTON_PRIMARY" />
            Confirmar Cancelamento
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MovCancelarPage;
