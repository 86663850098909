import { Grid } from '@material-ui/core';
import { LeituraDeCaixaBody } from './components/leitura-de-caixa-body/leitura-de-caixa-body';
import { useStyles } from './sessao-leitura-caixa-page-styles';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';

const SessaoLeituraCaixaPage = () => {
  // AUX
  const classes = useStyles();
  const { push: historyPush } = useHistory();

  const setHeaderProps = useVendaHeaderStore(store => store.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Pré Conferência',
      voltar: {
        onClick: () => historyPush(PdvRotasMock.gerenciarSessao)
      }
    })
  }, [historyPush, setHeaderProps])

  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.content}>
          <Grid style={{flex: 1 }}>
            <LeituraDeCaixaBody />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { SessaoLeituraCaixaPage };
