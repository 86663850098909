import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    segmentoInfo: {
        display: "flex",
        flexDirection: "column",
        textAlign: 'left',
        width: '100%',
    },
    segmentoBotao: {
        display: "flex",
        alignItems: "start",
        height: "13rem",
        boxSizing: 'border-box',
        boxShadow: ' 0px 2.5px 8px 0px #28293D40',
        border: '.5px inset #28293D40',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '16rem',
            height: '17rem',
            padding: '16px',
            alignItens: 'center'
        },
    },
    segmentoBotaoSelecionado: {
        display: "flex",
        alignItems: "start",
        height: "13rem",
        boxSizing: 'border-box',
        boxShadow: '0px 2.5px 8px 0px #606170 ',
        border: `2px solid ${theme.palette.primary.main}`,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '16rem',
            height: '17rem',
            padding: '16px',
            alignItens: 'center'
        },


    },
    textoRamoSelecionado: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1rem 0',
    },
    descricaoDestaque: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    perfilBotao: {
        margin: 0,
        boxShadow: '15px #28293d'
    },
    checkBox: {
        position: 'absolute',
        top: '0',
        left: '1rem'
    },
    segmentosGrid: {
        display: 'flex',

    },

    questaoAtivaModuloInicial: {
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        margin: 'auto',
    },
    listaDeModulos: {
        height: '100%',
        minHeight: "250px",
        flexDirection: "column",
    },

    botaoQuestaoAtivaModulo: {
        margin: '0 15px'
    },
    botaoRamo: {
        display: 'flex',
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItens: 'left',
        }

    },
    iconeBotao: {
        padding: '1rem',
        [theme.breakpoints.down('sm')]: {
            padding: '0 1rem 0 0'
        }

    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '1rem'
    },
    perfilCard: {
        display: 'flex',
    },
    cardPerfilContainer: {
        marginTop: theme.spacing(2),
        boxShadow: ' 0px 2.5px 8px 0px #28293D40',
        border: '.5px inset #28293D40',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        boxSizing: 'border-box',
        padding: '15px',
        alignItems: 'center',
        width: '100%',
        height: '120px',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'left',

        }

    },
    cardPerfilContent: {
        boxSizing: 'border-box',
        padding: '16px 0',
        width: '100%'
    },
    cardPerfilHeader: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    accordionNfce: {
        boxShadow: ' 0px 2.5px 8px 0px #28293D40',
        border: '.5px inset #28293D40',
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    tituloSaudacao: {
        fontWeight: 700,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'start'
        },

    },
    cardPlano: {
        padding: '.5rem'
    },
    botaoMobile: {
        padding: '2px 0px',

    },
    conteudoDeEtapa: {
        justifyContent: 'center',
    },
    "& .MuiMobileStepper-progress": {
        color: 'red'
    },

    itemLista: {
        padding: theme.spacing(1),
        marginTop: '16px',
    },
    switchGrid: {
        display: "flex"
    },
    containerRamo: {
        display: 'flex',
        height: '100%',
    },
    contentRamo: {
        display: 'flex',
        maxHeight: 350
    },
    itemRamo: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    cardRamo: {
        width: "100%",
        padding: '15px',
        [theme.breakpoints.down('sm')]: {
            scrollSnapAlign: 'center',
            padding: '0px',
            margin: '0 16px',

        }
    },
    containerPlanos: {
        display: 'flex'
    },
    stepButtons: {
        alignItems: 'center'
    },
    slider: {
        display: 'flex',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        alignItems: 'center',
        maxHeight: 350,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    buttonRevisao: {
        padding: '0 8px'
    },
    divButtonRevisao: {
        display: 'flex',
        padding: 0,
        marginTop: theme.spacing(2),
    },
    buttonRevisaoLeft: {
        marginRight: '8px'
    },
    buttonRevisaoRight: {
        marginLeft: '8px'
    },
    cardPerfilTextAndIcon: {
        display: 'flex',
        marginBottom: '8px'
    },
    upgradesNecessariosContainer: {
        height: '100%'
    },
    upgradesNecessariosTitle: {
        marginTop: theme.spacing(2),
        padding: '8px'
    },
    upgradesNecessariosCard: {
        marginTop: theme.spacing(2),
        boxShadow: ' 0px 2.5px 8px 0px #28293D40',
        border: '.5px inset #28293D40',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        boxSizing: 'border-box',
        padding: '15px',
        alignItems: 'center',
        maxHeight: 72,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'left',
            height: '120px'
        }

    }



}));
