import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-opcoes-compartilhamento-styles';
import { VoltarIcon } from 'views/components/icons';
import { useCadastros } from 'services/app';
import { SMSIcon } from 'views/components/icons/sms-icon';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';

interface DialogOpcoesCompartilhamentoProps {
  openned: boolean;
  closeModal: () => void;
  id: string;
  mod: number;
  emails: string[]
}

export const DialogOpcoesCompartilhamento = ({
  openned,
  closeModal,
  id,
  mod,
  emails
}: DialogOpcoesCompartilhamentoProps) => {
  const { abrirEnviarEmailDialog, abrirEnviarSmsDialog } = useCadastros();
  const classes = useStyles();
  return (
    <>
      <DialogSaurus
        aberto={openned || false}
        titulo="Compartilhamento"
        tamanho="xs"
      >
        <Box className={classes.cardContainer}>
          <Box className={classes.cardContent}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Grid container spacing={2} style={{ marginBottom: 8 }}>
                <Grid
                  item
                  style={{ margin: '0 8px', padding: '4px 0' }}
                  xs={12}
                >
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Selecione abaixo a forma que deseja compartilhar:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => abrirEnviarEmailDialog({ movId: id, mod, emails })}
                  >
                    <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                    Enviar E-mail
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => abrirEnviarSmsDialog(id, mod)}
                  >
                    <SMSIcon tipo="BUTTON_PRIMARY" />
                    Enviar SMS
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {closeModal && (
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <VoltarIcon tipo="BUTTON" />
                      Voltar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogSaurus>
    </>
  );
};
