import { useCadastros, useToastSaurus } from 'services/app';
import { useStyles } from './styles';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { VoltarIcon } from 'views/components/icons';
import { Comissionado, useComissionadosStore } from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { ModalHeader } from 'views/components/modals/components';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useCallback, useEffect, useState } from 'react';
import { PreviewGerentesGerais } from './components/preview-gerentes-gerais';
import { ComissionadoValor } from '../comissionado-valor/comissionado-valor';
import { usePostComissaoComissionados } from 'data/api/gestao/comissao/post-comissionados';
import { CircularLoading } from 'views/components/utils';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { ComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { TipoValorComissao } from '../comissionado-valor/components/keyboard/components/keyboard-fragment-props';
import { EnumTpCondicaoApuracao } from 'model/enums/enum-tp-condicao-apuracao';
import { guidEmpty } from 'utils/guid-empty';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { newGuid } from 'utils/new-guid';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';

interface ComissionadoGerenteGeralProps {
  comissaoId: string
  getComissionados: () => Promise<void>
  carregando: boolean
  handleRemoverComissionado: (comissionados: Comissionado[]) => Promise<boolean>
}

enum EnumPassoComissionadoGerenteGeral {
  Lista,
  Valor,
}

export const ComissionadoGerenteGeral = ({ carregando: carregandoComissionado, comissaoId, getComissionados, handleRemoverComissionado, ...props }: ComissionadoGerenteGeralProps) => {
  const { setGerenteGeralAtual, state: { comissionadoGerenteGeral } } = useComissionadosStore(state => state);
  const { postComissaoComissionados, carregando: carregandoPost } = usePostComissaoComissionados()
  const { showToast } = useToastSaurus()

  const carregando = [carregandoComissionado, carregandoPost].includes(true)

  const [passo, setPasso] = useState<EnumPassoComissionadoGerenteGeral>(EnumPassoComissionadoGerenteGeral.Lista)

  const definirPasso = (passo: EnumPassoComissionadoGerenteGeral) => {
    setPasso(passo)
  }

  // HOOKS
  const classes = useStyles();
  const modalClasses = useModalStyles()
  const { fecharComissaoComissionados } = useCadastros()
  const { getEmpresaAtual } = useEmpresaAtual()
  const { abrirPessoaSelecionarModal, fecharPessoaSelecionarModal } = useFiltrosModais()

  const handleVoltarValor = useCallback(() => {
    definirPasso(EnumPassoComissionadoGerenteGeral.Lista)

    setGerenteGeralAtual(undefined)
  }, [setGerenteGeralAtual])

  const handleConfirmarValor = useCallback(async (valor: number, tipoValor: TipoValorComissao) => {
    try {
      const gerenteGeral = useComissionadosStore.getState().state.gerenteGeralAtual
      if (!gerenteGeral) return
      const comissionado: ComissaoModel = {
        ...new ComissaoModel(),
        ComissaoCampanhaId: comissaoId,
        comissionadoId: gerenteGeral.pessoaId,
        condicao: tipoValor as unknown as EnumTpCondicaoApuracao,
        valor: valor,
        id: guidEmpty(),
      }

      const res = await postComissaoComissionados(getEmpresaAtual()?.id ?? '', comissaoId, [comissionado])
      if (res.erro) throw res.erro

      await getComissionados()

      fecharComissaoComissionados()
      showToast('success', 'Gerente Geral adicionado!')
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [comissaoId, postComissaoComissionados, getEmpresaAtual, getComissionados, fecharComissaoComissionados, showToast])

  const handleAdicionarGerenteGeral = useCallback((pessoas: PessoaModel[]) => {
    const [pessoa] = pessoas
    setGerenteGeralAtual({
      condicao: 0,
      documento: pessoa.cpfcnpj,
      id: guidEmpty(),
      pessoaId: pessoa.id,
      internalId: newGuid(),
      nome: pessoa.nome,
      tipo: EnumTpPessoaComissionamento.GerenteGeral,
      valor: 0,
    })

    definirPasso(EnumPassoComissionadoGerenteGeral.Valor)
    fecharPessoaSelecionarModal()
  }, [fecharPessoaSelecionarModal, setGerenteGeralAtual])

  const handleVoltarSelecaoPessoa = useCallback(() => {
    fecharPessoaSelecionarModal()
  }, [fecharPessoaSelecionarModal])

  const abrirSelecionarGerenteGeral = useCallback(() => {
    const gerentesGerais = useComissionadosStore.getState().state.comissionadoGerenteGeral
    abrirPessoaSelecionarModal({
      adicionar: handleAdicionarGerenteGeral,
      voltar: handleVoltarSelecaoPessoa,
      idsBloqueados: gerentesGerais.map(x => x.pessoaId),
      typeSelect: 'radio',
      label: 'Selecione o Gerente Geral'
    })
  }, [abrirPessoaSelecionarModal, handleAdicionarGerenteGeral, handleVoltarSelecaoPessoa])

  useEffect(() => {
    if (comissionadoGerenteGeral.length === 0) {
      abrirSelecionarGerenteGeral()
    }
  }, [abrirSelecionarGerenteGeral, comissionadoGerenteGeral.length])

  return (
    <div className={modalClasses.root}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <ModalHeader
        title={passo === EnumPassoComissionadoGerenteGeral.Valor ? 'Novo Gerente Geral' : 'Gerentes Geral'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={fecharComissaoComissionados}
          />
        }
      />
      <div className={classes.content}>
        {passo === EnumPassoComissionadoGerenteGeral.Lista &&
          <PreviewGerentesGerais
            setAddGerenteGeral={abrirSelecionarGerenteGeral}
            setDel={() => { }}
            handleRemove={handleRemoverComissionado}
          />
        }
        {passo === EnumPassoComissionadoGerenteGeral.Valor && <ComissionadoValor handleVoltar={handleVoltarValor} handleConfirmar={handleConfirmarValor} />}
      </div>
    </div>
  );
};
