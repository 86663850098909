import { CardDocReferenciadoProps } from './card-doc-referenciado-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { DocReferenciadoNF } from './components/doc-referenciado-nf';
import { DocReferenciadoECF } from './components/doc-referenciado-ecf';
import { DocReferenciadoCTE } from './components/doc-referenciado-cte';
import { DocReferenciadoNFe } from './components/doc-referenciado-nfe';
import { DocReferenciadoNFP } from './components/doc-referenciado-nfp';
import { Box, Tooltip } from 'views/design-system';
import classNames from 'classnames';

export const CardDocReferenciado = ({
  model,
  onClick,
}: CardDocReferenciadoProps) => {
  const classes = useDefaultCardStyles();
  const valorReal = Object.entries(model).find(x => x[1])
  const getKey = Object.keys(model).filter(x => x === valorReal?.[0] || '')
  const switchComp = (name: string) => ({
    refNFe: <DocReferenciadoNFe model={model} />,
    refCTe: <DocReferenciadoCTE model={model} />,
    refECF: <DocReferenciadoECF model={model} />,
    refNF: <DocReferenciadoNF model={model} />,
    refNFP: <DocReferenciadoNFP model={model} />,
  })[name] || <DocReferenciadoNFe model={model} />

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <Box p={1} className={classNames(classes.celulaGridFull, classes.cardContent)} style={{ whiteSpace: 'break-spaces' }}>
          {switchComp(getKey[0])}
        </Box>
        <Tooltip arrow title="Editar Documento">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
