import { EnumStatusComissao } from 'model/enums/enum-status-comissao';
import { EnumTpCondicaoApuracao } from 'model/enums/enum-tp-condicao-apuracao';
import { EnumTpFechamento } from 'model/enums/enum-tp-fechamento';
import { EnumTpModeloApuracao } from 'model/enums/enum-tp-modelo-apuracao';
import { guidEmpty } from 'utils/guid-empty';

export class CampanhaComissaoModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = '',
        public dataInicial: string = '',
        public dataFinal: string = '',
        public nome: string = '',
        public horarioInicio: number | null = 0,
        public horarioFinal: number | null = 0,
        public diasSemana: number = 127,
        public valorMinimoCupom: number = 0,
        public tipoFechamento: EnumTpFechamento = EnumTpFechamento.Manualmente,
        public diaOuIntervaloFechamento: number = 0,
        public modeloApuracao: EnumTpModeloApuracao = EnumTpModeloApuracao.ValorVendido,
        public valorMaximoApuracao: number = 0,
        public valorMinimoApuracao: number = 0,
        public status: EnumStatusComissao = EnumStatusComissao.Ativo
    ) { }
}


export class ComissaoModel {
    constructor(
        public id: string = guidEmpty(),
        public ComissaoCampanhaId: string = guidEmpty(),
        public comissionadoId: string = guidEmpty(),
        public operadorId: string | null = null,
        public vendedorId: string | null = null,
        public condicao: EnumTpCondicaoApuracao = EnumTpCondicaoApuracao.ValorFixo,
        public valor: number = 0
    ) { }
}
