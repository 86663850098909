import { useTheme } from '@material-ui/core';
import { useStyles } from './comissionado-valor-styles';
import classNames from 'classnames';
import { TipoValorComissao } from './components/keyboard/components/keyboard-fragment-props';
import KeyboardValor from './components/keyboard';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useCallback, useState } from 'react';
import { Button, Grid } from 'views/design-system';
import { ComissionadoValorProps } from './comissionado-valor-props';

export const ComissionadoValor = ({
  handleVoltar,
  handleConfirmar
}: ComissionadoValorProps) => {
  const [tipoValor, setTipoValor] = useState<TipoValorComissao>(
    TipoValorComissao.Porcentagem
  );
  const [valor, setValor] = useState<number>(0);

  const classes = useStyles();
  const theme = useTheme();

  const handleValue = (value: string) => {
    setValor(Number(value.replace(',', '.')));
  };

  const handleClickType = (type: TipoValorComissao) => {
    setTipoValor(type);
  };

  const handleClickConfirmar = useCallback(() => {
    handleConfirmar(valor, tipoValor);
  }, [handleConfirmar, tipoValor, valor]);

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainerHeader}>
        <div
          className={classNames(
            classes.button,
            classes.buttomLeft,
            tipoValor === TipoValorComissao.Porcentagem &&
              classes.buttonSelected
          )}
          onClick={() => {
            handleClickType(TipoValorComissao.Porcentagem);
          }}
        >
          <p className={classes.label}>
            Porcentagem (
            <span style={{ color: theme.palette.primary.main }}>%</span>)
          </p>
        </div>
        <div
          className={classNames(
            classes.button,
            classes.buttomRight,
            tipoValor === TipoValorComissao.ValorFixoProduto &&
              classes.buttonSelected
          )}
          onClick={() => {
            handleClickType(TipoValorComissao.ValorFixoProduto);
          }}
        >
          <p className={classes.label}>
            Valor fixo produto (
            <span style={{ color: theme.palette.primary.main }}>R$</span>)
          </p>
        </div>
      </div>
      <KeyboardValor
        index={0}
        setValue={handleValue}
        value={String(valor)}
        tipo={tipoValor}
      />
      <div className={classes.btnConfirmar}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              type="submit"
              color="primary"
              onClick={handleVoltar}
            >
              <CancelarIcon tipo="BUTTON" />
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              color="primary"
              disabled={valor <= 0}
              onClick={handleClickConfirmar}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
