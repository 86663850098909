import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mov-fechamento-styles';
import { VoltarIcon } from 'views/components/icons';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { useCadastros } from 'services/app';
import { useState } from 'react';
import { CircularLoading } from 'views/components/utils';
import { Box, Button, Grid, Typography } from 'views/design-system';

interface DialogMovFechamentoProps {
  openned: boolean;
  handleFechamento: () => Promise<void>;
  handleImportar: () => Promise<void>;
}

export const DialogMovFechamento = ({
  openned,
  handleFechamento,
  handleImportar
}: DialogMovFechamentoProps) => {
  const { fecharMovFechamentoDialog } = useCadastros();
  const { getPDV } = usePDV();
  const classes = useStyles();
  const [carregando, setCarregando] = useState<boolean>(false)

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(
    getPDV()?.configuracoes.find((p) => p.cod === 101)
      ?.vConfig as EnumModeloDeTrabalho
  );

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Finalização de Pedido"
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Typography style={{ marginBottom: '16px' }}>
          Selecione abaixo a opção desejada:
        </Typography>
        <Box className={classes.cardContent}>
          <Grid container spacing={2} style={{ marginBottom: 8, textAlign: "center" }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={async () => {
                  try {
                    setCarregando(true)
                    await handleFechamento();
                    fecharMovFechamentoDialog();
                  } finally {
                    setCarregando(false)
                  }
                }}
              >
                <ImpressoraIcon tipo="BUTTON_PRIMARY" />
                Imprimir Fechamento de Conta
              </Button>
              <Typography variant='caption'>
                Este pedido ficará bloqueado para lançamentos.
              </Typography>

            </Grid>
            {
              !isSomentePedido ? (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      handleImportar()
                      fecharMovFechamentoDialog()
                    }}>
                    <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                    Concluir como Venda
                  </Button>
                  <Typography variant='caption'>
                    Importar os produtos deste pedido para finalizá-lo como venda.
                  </Typography>
                </Grid>

              ) : null
            }

            <Grid item xs={12}>
              {fecharMovFechamentoDialog && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"

                  onClick={() => {
                    fecharMovFechamentoDialog();
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
