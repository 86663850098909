export enum EnumSincronizacao {
    PRODUTOS = 0,
    CLIENTES = 1,
    FINALIZADORAS = 2,
    CATEGORIAS = 3,
    NCMS = 4,
    IMPOSTOS = 5,
    MESAS = 6,
    COMANDAS = 7,
    SALOES = 8,
    MODIFICADORES = 9,
    MEDICAMENTOS = 10,
    PROMOCOES_DEPOR = 11,
    CONFIGURACOES = 12,
}