import { Button, Grid, Typography } from "views/design-system"
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { InformacaoOperadorDialogRenderProps } from "views/components/render-modais/components/informacao-operador-dialog-render"
import { EnumCadastroStatus } from "model"
import { useCallback, useMemo } from "react"
import { OkIcon } from "views/components/icons"
import { useCadastros } from "services/app"
import { useStyles } from "./dialog-informacao-operador-styles"
import { useThemeQueries } from "views/theme"

export const DialogInformacaoOperador = ({ cancelar, cliente, confirmar, openned }: InformacaoOperadorDialogRenderProps) => {

    const { fecharInformacaoOperador } = useCadastros()
    const classes = useStyles()
    const { theme } = useThemeQueries()

    const clienteBloqueado = cliente.status === EnumCadastroStatus.BLOQUEADO

    const tituloDialog = clienteBloqueado
        ? 'Cliente Bloqueado'
        : 'Informações do Operador'

    const descricao = useMemo(() => {
        if (clienteBloqueado) {
            return (
                <Typography weight={500}>
                    Cliente <span className={classes.destaqueNome}>({cliente.nome})</span> foi bloqueado por um dos operadores, ele não poderá ser selecionado nesta venda.
                </Typography>
            )
        } else {
            return (
                <Typography weight={500}>
                    Foi adicionado uma informação de aviso sobre o cliente <span className={classes.destaqueNome}>({cliente.nome})</span>.
                </Typography>
            )
        }
    }, [classes.destaqueNome, cliente.nome, clienteBloqueado])

    const handleClick = useCallback(() => {
        clienteBloqueado ? cancelar() : confirmar()
        fecharInformacaoOperador()
    }, [cancelar, clienteBloqueado, confirmar, fecharInformacaoOperador])

    const bottomArea = useMemo(() => {
        return (
            <Grid container p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleClick}
                            fullWidth
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [handleClick])

    const titleMotivo = clienteBloqueado ? "Motivo do Bloqueio" : 'Informação'

    return (
        <DialogSaurus
            aberto={openned ?? false}
            titulo={tituloDialog}
            classesTitle={classes.titleDialog}
            colorTitulo={clienteBloqueado ? theme.palette.error.main : theme.palette.primary.main}
            bottomArea={bottomArea}
            tamanho="sm"
        >
            <Grid container mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} pb={4}>
                        <Typography weight={500}>
                            {descricao}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        flex
                        flexDirection="column"
                        xs={12}
                        alignItems="center"
                        className={clienteBloqueado ? classes.contentMotivoBloqueio : classes.contentMotivo}
                    >
                        <Typography color="primary" variant="h6" weight={700} align="center">
                            {titleMotivo}
                        </Typography>
                        <Typography weight={500} color="textPrimary" variant="body1">
                            {cliente.infOperador}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus >
    )
}