import { DefaultModal } from '../../components/default-modal/default-modal';
import { ModalProps } from '../../utils/modal-props';
import { ComissaoEdicao } from './comissao-edicao/comissao-edicao';
import { ComissaoCadastro } from './comissao-cadastro/comissao-cadastro';

export interface ComissaoModalProps extends ModalProps {
  veioDoCadastro: boolean
}

export const ComissaoModal = (props: ComissaoModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {!props.id && <ComissaoCadastro />}
      {props.id && <ComissaoEdicao id={props.id} veioDoCadastro={props.veioDoCadastro} />}
    </DefaultModal>
  );
};
