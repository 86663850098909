import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses, useThemeQueries } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { ConfigInicialMesasEcomandasModel } from 'model/api/gestao/config-inicial/config-inicial-mesas-e-comandas-model';
import { useStyles } from './form-mesas-e-comandas-style';
import { useFormMesaValidation } from './form-mesas-e-comandas-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccordionSaurusSecondary } from 'views/components/accordions/accordion-saurus-secondary/accordion-saurus-secondary';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { ComandasIconAlt } from 'views/components/icons/comanda-icon-alt';
import { Button, Grid, Typography } from 'views/design-system';

export const FormMesasEcomandas = forwardRef<

    DefaultFormRefs<ConfigInicialMesasEcomandasModel>,
    DefaultFormProps<ConfigInicialMesasEcomandasModel>
>((props: DefaultFormProps<ConfigInicialMesasEcomandasModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const { theme } = useThemeQueries()
    const [openAccordionMesas, setOpenAccordionMesas] = useState<boolean>(false)
    const [openAccordionComandas, setOpenAccordionComandas] = useState<boolean>(false)
    const { FormMesaValidationYup } = useFormMesaValidation(openAccordionMesas, openAccordionComandas);
    const [modelForm, setModelForm] = useState<ConfigInicialMesasEcomandasModel>(new ConfigInicialMesasEcomandasModel())

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        setValue,
        reset,
        getValues
    } = useForm<ConfigInicialMesasEcomandasModel>({
        defaultValues: { ...modelForm},
        resolver: yupResolver(FormMesaValidationYup),
        criteriaMode: 'all',
        mode: 'onChange'
    });
    const onSubmit = (values: ConfigInicialMesasEcomandasModel) => {
        if (openAccordionComandas) {
            values.comandaInicial = 1
        } else {
            values.comandaInicial = null
            values.comandaFinal = null
            values.prefixoComanda = null
        }
        if (openAccordionMesas) {
            values.mesaInicial = 1
        } else {
            values.mesaInicial = null
            values.mesaFinal = null
        }
        props.onSubmit(values);
    };

    const classes = useStyles()

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
           
        },
        fillForm: (values) => {
            if(!values) return
            reset(values)
            setModelForm(values)
            

        }
    }));

    const FormularioMesas = useCallback(() => {
        return (
            <Grid xs={12} container  >
                <Grid className={classes.descricaoForm} item xs={12} spacing={2}>
                    <Typography >Insira a quantidade de mesas do estabelecimento.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                    <Grid item xs={12} className={classes.inputLeft} >
                        <Controller
                            name="mesaFinal"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="NUMERO"
                                    disabled={props.loading}
                                    fullWidth
                                    autoComplete='off'
                                    variant="outlined"
                                    label="Quantidade de Mesas"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={'EX: 1'}
                                    error={Boolean(
                                        errors.mesaFinal && errors.mesaFinal.message
                                    )}
                                    helperText={
                                        touchedFields.mesaFinal || errors.mesaFinal
                                            ? errors.mesaFinal?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = parseInt(e.target.value)
                                        setValue('mesaFinal', valor);
                                    }}
                                    value={getValues('mesaFinal')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [classes.descricaoForm, classes.inputContainer, classes.inputLeft, control, errors.mesaFinal, getValues, props.loading, setValue, touchedFields.mesaFinal])

    const FormularioComandas = useCallback(() => {
        return (
            <Grid xs={12} container spacing={1} >
                <Grid className={classes.descricaoForm} item xs={12}>
                    <Typography>Insira a quantidade de comandas do estabelecimento.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                    <Grid item xs={6} className={classes.inputLeft}>
                        <Controller
                            name="prefixoComanda"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="TEXTO"
                                    disabled={props.loading}
                                    fullWidth
                                    variant="outlined"
                                    label="Prefixo Comanda"
                                    autoComplete='new-password'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={'EX: C'}
                                    error={Boolean(
                                        errors.prefixoComanda && errors.prefixoComanda.message,
                                    )}
                                    helperText={
                                        touchedFields.prefixoComanda || errors.prefixoComanda
                                            ? errors.prefixoComanda?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = (e.target.value)
                                        setValue('prefixoComanda', valor);
                                    }}
                                    value={getValues('prefixoComanda')}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inputLeft}>
                        <Controller
                            name="comandaFinal"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="NUMERO"
                                    disabled={props.loading}
                                    fullWidth
                                    variant="outlined"
                                    label="Quantidade de Comandas"
                                    autoComplete='new-password'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={'EX: 1'}
                                    error={Boolean(
                                        errors.comandaFinal && errors.comandaFinal.message,
                                    )}
                                    helperText={
                                        touchedFields.comandaFinal || errors.comandaFinal
                                            ? errors.comandaFinal?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = parseInt(e.target.value)
                                        setValue('comandaFinal', valor);
                                    }}
                                    value={getValues('comandaFinal')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [classes.descricaoForm, classes.inputContainer, classes.inputLeft, control, errors.comandaFinal, errors.prefixoComanda, getValues, props.loading, setValue, touchedFields.comandaFinal, touchedFields.prefixoComanda])

    const formularios = [
        {
            titulo: 'Mesas',
            estado: openAccordionMesas,
            mudancaDeEstado: setOpenAccordionMesas,
            formulario: <FormularioMesas />,
            icon: <MesaIcon tipo='BUTTON_PRIMARY' fill={theme.palette.primary.main} />
        },
        {
            titulo: 'Comandas',
            estado: openAccordionComandas,
            mudancaDeEstado: setOpenAccordionComandas,
            formulario: <FormularioComandas />,
            icon: <ComandasIconAlt tipo='BUTTON_PRIMARY' fill={theme.palette.primary.main} />
        }
    ]

    const accordionsForm = formularios.map((formulario) =>
        <Grid item xs={12}>
            <AccordionSaurusSecondary
                key={formulario.titulo}
                labelPrimary={formulario.titulo}
                titleBold
                labelIcon={formulario.icon}
                tipoExpand="bold"
                noIconRotation={true}
                noPaperRoot={false}
                heightDivider={2}
                showDivider={formulario.estado}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={formulario.estado}
                botaoChecked={formulario.estado}
                onChange={() =>
                    formulario.mudancaDeEstado(!formulario.estado)
                }
                className={classes.card}
            >
                {formulario.formulario}
            </AccordionSaurusSecondary>
        </Grid>
    )
    
    
    return (
        <>
            <div>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={2} className={classes.formularioUnico}>
                        {accordionsForm}
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </Grid>
                </form>
            </div>
        </>
    );
});
