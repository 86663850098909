import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import React, { useCallback } from 'react';
import { useStyles } from './card-fatura-lancamento-styles'
import classNames from 'classnames';
import { CardFaturaLancamentoProps } from './card-fatura-lancamento-props';
import { toDecimalString } from 'utils/to-decimal';
import { toDateString } from 'utils/to-date';
import { EnumTpFaturaLancamentoStatus } from 'model/enums/enum-tp-fatura-lancamento-status';
import { TpFaturaLancamentoStatusMock } from 'data/mocks/tp-fatura-lancamento-status-mock';
import { Grid, Typography } from 'views/design-system';
import tinycolor from 'tinycolor2';
import { MoedaIcon } from 'views/components/icons';
import { isEmpty } from 'lodash';

const CardFaturaLancamento = ({ model }: CardFaturaLancamentoProps) => {
  const classes = useDefaultCardStyles();
  const lancClasses = useStyles();

  const nome = model.contaPessoaCartaoNome
  const nomeNoCartao = model.pessoaNome

  const returnCorStatus = useCallback(() => {
    const status = {
      [EnumTpFaturaLancamentoStatus.Venda]: () => '#0F9FD6',
      [EnumTpFaturaLancamentoStatus.DevolucaoVenda]: () => '#132259',
      [EnumTpFaturaLancamentoStatus.EstornoVenda]: () => '#191A1E',
      [EnumTpFaturaLancamentoStatus.TrocaLimite]: () => '#DF4161',
      [EnumTpFaturaLancamentoStatus.Fatura]: () => '#2F08CC',
      [EnumTpFaturaLancamentoStatus.PagamentoFatura]: () => '#6A3451',
      [EnumTpFaturaLancamentoStatus.PagamentoAntecipado]: () => '#11A065',
      [EnumTpFaturaLancamentoStatus.Taxas]: () => '#4CB399',
      [EnumTpFaturaLancamentoStatus.Negociacao]: () => '#FA931F',
    }

    return status[model?.tipo]() || '#2196F3'
  }, [model?.tipo])

  const returnColorText = useCallback(() => {
    return tinycolor(returnCorStatus()).getBrightness() > 200 ? tinycolor(returnCorStatus()).darken(50).toString() : '#fff'
  }, [returnCorStatus])

  const descricaoStatus = TpFaturaLancamentoStatusMock.find(status => status.Key === model?.tipo)?.Value

  return (
    <>
      <DefaultCard
        notClickable
      >
        <div className={classNames(classes.cardContent, lancClasses.card)}>
          <Grid container>
            <Grid item xs='auto' className={classNames(lancClasses.item, lancClasses.verticalCenter, lancClasses.removeMargin)}>
              <Typography color='textSecondary' variant="caption">
                {toDateString(model.dataLancamento, 'DD/MM')}
              </Typography>
            </Grid>
            <Grid item xs className={lancClasses.item}>
              <Typography color='textPrimary' className={lancClasses.negrito}>
                {isEmpty(nome) ? nomeNoCartao : nome}
              </Typography>
              <div>
                <div className={lancClasses.cardStatus} style={{
                  backgroundColor: returnCorStatus()
                }}>
                  <Typography align='center' variant="caption" style={{
                    color: returnColorText()
                  }}>
                    {descricaoStatus}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} className={lancClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                {(model.valorDebito || model.valorCredito) ? "Valor" : 'Limite Atual'}
              </Typography>
              <Typography color='textPrimary' className={lancClasses.negrito}>
                R$ {toDecimalString((model.valorDebito ?? model.valorCredito) ?? model.limiteAtual)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classNames(classes.cardRightButton, lancClasses.iconRight)}>
          <MoedaIcon tipo="BUTTON" />
        </div>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardFaturaLancamento);
