import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { PromocaoModal } from "views/components/modals/promocao/promocao-modal";

export const CadastroPromocaoModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: '',
    });

    const promocaoRoute = `/promocoes/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroPromocaoModal')

    useEffect(() => {
        if (location.pathname.indexOf(promocaoRoute) === 0) {
            try {
                const rota = location.pathname.split('/');
                const id = rota[2];
                
                if (id.length > 0) {
                    setModalState(prev => ({
                        id: id === novoCadastroRoute ? '' : id,
                        aberto: true,
                        ultimaUrl: promocaoRoute,
                        search: prev.search
                    }));
                    return;
                }
            } catch { }
        }
    }, [location.pathname, promocaoRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? promocaoRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;
       
        if (!openned && !trocarUrl) {
            push({
                pathname: promocaoRoute,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(promocaoRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        });
    }, [modalState.aberto, modalState.search, modalState.ultimaUrl, promocaoRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.PromocaoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PromocaoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            {modalState.aberto && <PromocaoModal openned={modalState.aberto} key="mdlPromocao" id={modalState.id} />}
        </>
    )
}