
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { TermosDeUsoModal, ModalExibirTermosDeUsoProps } from "views/components/modals/termos-de-uso/termos-de-uso-modal";


export const ExibirTermosDeUso = () => {
    consoleDev('termosDeUso');
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<ModalExibirTermosDeUsoProps>({
        openned: false,
        finalizarCadastro: () => { },
        id: ''
    });

    const modalAlterado = useCallback(({ openned, finalizarCadastro }: any) => {
        setModalState(prev => ({
            ...prev,
            openned,
            finalizarCadastro
        }));
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogTermosDeUso, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogTermosDeUso, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.openned && (
                <TermosDeUsoModal {...modalState} />)}
        </>
    )
}