import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './produto-list-revisao-styles';
import { useComissaoProdutosStore } from 'views/components/modals/comissao/comissao-modal/stores/produtos-store';
import { Box, Button, Grid } from 'views/design-system';
import { ListProdutoDataRevisao } from './produto-list-data-revisao';
import { AvancarIcon, DesfazerIcon, NovoIcon } from 'views/components/icons';
import { RadioSelecionadoTodosIcon } from 'views/components/icons/radio-selecionado-todos-icon';
import { Paginacao } from 'views/components/paginacao';
import classNames from 'classnames';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { MenosIcon } from 'views/components/icons/menos-icon';
import { useDeleteComissaoProdutos } from 'data/api/gestao/comissao/delete-produtos';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { usePostComissionadosProdutos } from 'data/api/gestao/comissao/post-produtos';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { useToastSaurus } from 'services/app';
import { EnumAbaEdicaoComissao } from 'views/components/modals/comissao/comissao-modal/comissao-edicao/comissao-edicao';

interface ProdutoListRevisaoProps {
  getProdutos: () => Promise<void>;
  comissaoId: string;
  carregando?: boolean;
  isTabProduto: boolean;
  selecionarAba: (aba: EnumAbaEdicaoComissao) => void;
  veioDoCadastro: boolean;
}

interface SelecaoProdutoListaProps {
  nome: string;
  categoria: string;
  id: string;
  imgUrl: string;
  removido?: boolean;
  sincronizado?: boolean;
  marca: string;
}

export const ProdutoListRevisao = ({
  comissaoId,
  getProdutos,
  isTabProduto,
  selecionarAba,
  carregando: carregandoComissao,
  veioDoCadastro,
  ...props
}: ProdutoListRevisaoProps) => {
  const produtos = useComissaoProdutosStore((state) => state.state.produtos);
  const { deleteComissaoProdutos, carregando: loadingDeleteComissaoProdutos } =
    useDeleteComissaoProdutos();
  const { postComissaoProdutos, carregando: loadingComissaoProdutos } =
    usePostComissionadosProdutos();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { abrirProdutoSelecionarModal, fecharProdutoSelecionarModal } =
    useFiltrosModais();

  const carregando =
    loadingDeleteComissaoProdutos ||
    loadingComissaoProdutos ||
    carregandoComissao;

  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const modalClasses = useModalStyles();

  const [selectedList, setSelectedList] = useState<SelecaoProdutoListaProps[]>(
    []
  );
  const [selecionando, setSelecionando] = useState(false);
  const abriuSelecaoProdutos = useRef<boolean>(false);

  const onCardSelected = (id: string) => { };

  const onCardChecked = (produto: SelecaoProdutoListaProps) => {
    setSelectedList((prev) => {
      if (prev.some((prod) => produto.id === prod.id)) {
        return prev.filter((prod) => prod.id !== produto.id);
      }

      return [...prev, produto];
    });
  };

  const renderTopContent = useMemo(() => {
    const todosSelecionados =
      produtos.findIndex(
        (x) => selectedList.findIndex((y) => y.id === x.id) === -1
      ) === -1;
    return (
      <Grid flex justifyContent="space-between" flexDirection="row">
        {produtos.length > 0 && (
          <Grid
            onClick={() => {
              setSelectedList([]);
              setSelecionando(!selecionando);
            }}
            className={classes.botaoSelecionar}
          >
            {selecionando ? (
              <>
                <DesfazerIcon tipo="BUTTON_PRIMARY" />
                Desfazer
              </>
            ) : (
              <>
                <RadioSelecionadoTodosIcon tipo="BUTTON_PRIMARY" />
                Selecionar Produtos
              </>
            )}
          </Grid>
        )}
        <Paginacao
          className={classes.paginacao}
          pageChanged={() => { }}
          totalPages={1}
          totalRegisters={produtos.length}
          currentPage={1}
        />
        {selecionando && (
          <Grid
            onClick={() => {
              if (todosSelecionados) setSelectedList([]);
              else setSelectedList(produtos);
            }}
            className={classNames(classes.botaoSelecionar, classes.check)}
            style={{ marginLeft: 8, marginRight: 0 }}
          >
            Selecionar Todos
            <CheckboxDefault
              style={{ margin: -8 }}
              checked={todosSelecionados}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [
    classes.botaoSelecionar,
    classes.check,
    classes.paginacao,
    produtos,
    selecionando,
    selectedList
  ]);

  const deletarProdutosSelecionados = useCallback(async () => {
    try {
      const res = await deleteComissaoProdutos(
        getEmpresaAtual()?.id ?? '',
        comissaoId,
        selectedList.map((x) => x.id)
      );
      if (res.erro) throw res.erro;

      showToast('success', 'Produtos removidos');
      setSelecionando(false);
      getProdutos();
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [
    comissaoId,
    deleteComissaoProdutos,
    getEmpresaAtual,
    getProdutos,
    selectedList,
    showToast
  ]);

  const handleAdicionarProdutos = useCallback(
    async (produtos: ProdutoResumidoModel[]) => {
      try {
        fecharProdutoSelecionarModal();
        const res = await postComissaoProdutos(
          getEmpresaAtual()?.id ?? '',
          comissaoId,
          produtos.map((x) => x.produtoGradeId)
        );
        if (res.erro) throw res.erro;

        showToast('success', 'Produtos adicionados');
        getProdutos();

        veioDoCadastro && selecionarAba(EnumAbaEdicaoComissao.Comissionados);
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      comissaoId,
      fecharProdutoSelecionarModal,
      getEmpresaAtual,
      getProdutos,
      postComissaoProdutos,
      selecionarAba,
      showToast,
      veioDoCadastro
    ]
  );

  const abrirAdicionarProdutos = useCallback(() => {
    abrirProdutoSelecionarModal({
      idsBloqueados: produtos.map((x) => x.id),
      adicionar: handleAdicionarProdutos,
      voltar: () => fecharProdutoSelecionarModal()
    });
  }, [
    abrirProdutoSelecionarModal,
    fecharProdutoSelecionarModal,
    handleAdicionarProdutos,
    produtos
  ]);

  useEffect(() => {
    if (
      produtos.length === 0 &&
      isTabProduto &&
      !abriuSelecaoProdutos.current
    ) {
      abrirAdicionarProdutos();
      abriuSelecaoProdutos.current = true;
    }
    if (!isTabProduto) {
      abriuSelecaoProdutos.current = false;
    }
  }, [abrirAdicionarProdutos, produtos.length, isTabProduto]);

  const renderButtons = useMemo(() => {
    return (
      <Box className={classes.acoes}>
        <Grid container spacing={1}>
          {!selecionando ? (
            <>
              <Grid item xs={7}>
                <Button
                  disabled={Boolean(carregando)}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={abrirAdicionarProdutos}
                >
                  <NovoIcon tipo="BUTTON" />
                  Adicionar Produtos
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  disabled={Boolean(carregando)}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={() => selecionarAba(EnumAbaEdicaoComissao.Comissionados)}
                >
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Próximo
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="error"
                size="large"
                fullWidth
                onClick={deletarProdutosSelecionados}
              >
                <MenosIcon tipo="BUTTON_PRIMARY" />
                Deletar Produtos
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }, [
    abrirAdicionarProdutos,
    carregando,
    deletarProdutosSelecionados,
    classes.acoes,
    selecionando,
    selecionarAba,
  ]);

  return (
    <>
      <div className={modalClasses.root}>
        <div className={classNames(modalClasses.content, classes.content)}>
          <Grid container>
            <div style={{ width: '100%', padding: 8 }}>{renderTopContent}</div>
            <Grid item xs={12} className={classes.listContainer}>
              <ListProdutoDataRevisao
                carregando={false}
                list={produtos.filter((p) => !p.removido)}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
                select={selecionando}
              />
            </Grid>
          </Grid>
        </div>
        {renderButtons}
      </div>
    </>
  );
};
