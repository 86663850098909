import { FinalizarVendaButton } from '../../../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Box, Button, Grid } from 'views/design-system';
import { useStyles } from './carrinho-container-styles';
import { CarrinhoTotais } from './components/carrinho-totais/carrinho-totais';
import CarrinhoItens from './components/carrinho-itens/carrinho-itens';
import CarrinhoPagamento from './components/carrinho-pagamento/carrinho-pagamento';
import { useToastSaurus } from 'services/app';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { useFaturamento } from 'services/app/hooks/faturamento';
import { EnumMovModelo } from 'model';
interface CartWrapperProps {
  isPageCarrinho?: boolean;
}

export const CarrinhoContainer = ({
  isPageCarrinho = false,
}: CartWrapperProps) => {
  // STATES E REFS
  const [validateFlow, setValidateFlow] = useState<'fastPayment' | 'order' | 'empty' | 'faturamento' | undefined>(
    undefined
  );

  // HOOKS
  const { getMov } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools();
  const { showToast } = useToastSaurus();
  const classes = useStyles();
  const { faturarVenda } = useFaturamento();

  const valideteFlow = useCallback(async () => {
    try {
      const movAtual = getMov();
      if (movAtual!.vNF <= 0) {
        setValidateFlow('empty');
        return;
      }

      if (!movAtual!.isFaturada) {
        setValidateFlow('faturamento');
        return;
      }

      if (movAtual?.mod === EnumMovModelo.PEDIDO || movAtual?.mod === EnumMovModelo.DELIVERY) {
        setValidateFlow('order');
        return
      }

      setValidateFlow('fastPayment');
      return;
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getMov, showToast]);

  const movProdAlterado = useCallback(
    (any: any) => {
      valideteFlow();
    },
    [valideteFlow]
  );

  useEffect(() => {
    if (validateFlow === undefined)
      valideteFlow();
  }, [validateFlow, valideteFlow]);

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler, valideteFlow]);

  const faturar = useCallback(async () => {
    try {

      if (faturarVenda)
        await faturarVenda();

      setValidateFlow(undefined);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [faturarVenda, showToast])

  return (
    <Box className={classes.root}>
      <Box className={classes.containerMain}>
        <CarrinhoItens />
      </Box>
      <Grid className={classes.containerBottom}>
        {validateFlow !== 'empty' && (
          <Grid xs={12}>
            <CarrinhoTotais telaDePagamento={false} />
          </Grid>
        )}
        {validateFlow === 'faturamento' && (
          <Grid item className={classes.containerFaturamento} xs={12}>
            <Button fullWidth className={classes.botaoFaturar} variant="contained" color="primary" rounded onClick={faturar}>
              <PromocaoIcon tipo='BUTTON_PRIMARY' />
              Calcular Promoções
            </Button>
          </Grid>
        )}
        {validateFlow === 'fastPayment' && (
          <CarrinhoPagamento />
        )}
        {((validateFlow === 'order')) && (
          <Grid item xs={12}>
            <FinalizarVendaButton paddingLeft label={''} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
