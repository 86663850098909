import { useCallback, useEffect, useState, useRef } from 'react';
import { CancelarIcon } from 'views/components/icons';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCadastros, useToastSaurus } from 'services/app';
import { useGetFinalizadoraById } from 'data/api/gestao/finalizadora/get-finalizadora-by-id';
import { usePutFinalizadora } from 'data/api/gestao/finalizadora/put-finalizadora';
import { CredenciamentoSafra, FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';

import { isEmpty } from 'lodash';
import { stringNumeros } from 'utils/string-numeros';
import { useStyles } from '../../dialog-produto-facilitado/components/cadastro-produto-facilitado-styles'
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { FormCredenciarPix } from 'views/components/form/finalizadora/form-credenciar-pix/form-credenciar-pix';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useStyles as useCredenciarStyles } from './credenciar-pix-styles';
import { LinkIcon } from 'views/components/icons/link-icon';

interface CredenciarPixProps {
    finalizadoraId: string;
    finalizar: (model: FinalizadoraModel) => Promise<void>;
    cancelar: (message: string) => Promise<void>;
}

export const CredenciarPix = ({ cancelar, finalizadoraId, finalizar }: CredenciarPixProps) => {
    const reload = useRef<boolean>(false);
    const { showToast } = useToastSaurus();
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const { abrirDialogQrCode, fecharDialogCredenciarPix } = useCadastros();

    const classes = useStyles();
    const credenciarClasses = useCredenciarStyles();

    const { getFinalizadoraById, carregando: carregandoGet } =
        useGetFinalizadoraById();
    const { putFinalizadora, carregando: carregandoPut } = usePutFinalizadora();

    const carregando = carregandoPut || carregandoGet || preenchendoTela;
    const [finalizadoraFormState, setFinalizadoraForm] =
        useState<FinalizadoraModel>(new FinalizadoraModel());

    const refEditForm = useRef<DefaultFormRefs<CredenciamentoSafra>>(null);
    const refFinalizadoraModel = useRef<FinalizadoraModel>(
        new FinalizadoraModel(),
    );

    const recarregarForm = useCallback((model: FinalizadoraModel) => {
        const cred = isEmpty(model.credenciais) ? new CredenciamentoSafra() : JSON.parse(model.credenciais) as CredenciamentoSafra
        refEditForm.current?.fillForm(cred);
    }, []);

    useEffect(() => {
        recarregarForm(finalizadoraFormState);
    }, [finalizadoraFormState, recarregarForm]);

    const getFinalizadoraByIdWrapper = useCallback(async () => {
        const res = await getFinalizadoraById(finalizadoraId);
        if (res.erro) {
            throw res.erro;
        }
        const ret = res.resultado?.data as FinalizadoraModel;
        refFinalizadoraModel.current = ret;

        return ret;
    }, [getFinalizadoraById, finalizadoraId]);

    const preencherTela = useCallback(async () => {
        try {
            setPreenchendoTela(true);
            const finalizadora = await getFinalizadoraByIdWrapper();
            setFinalizadoraForm(finalizadora);
        } catch (e: any) {
            showToast('error', e.message);
        }
        finally {
            setPreenchendoTela(false);
        }
    }, [getFinalizadoraByIdWrapper, showToast])

    useEffect(() => {
        preencherTela()
        return () => {
            //funcao de limpeza
            setFinalizadoraForm(new FinalizadoraModel());
        };
    }, [preencherTela]);

    const handleClickLink = useCallback(() => {
        const link = `https://portal.safrapay.com.br/keys`;
    
        if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) {
          abrirDialogQrCode(link);
          return;
        }
        window.open(link, '_blank');
      }, [abrirDialogQrCode]);

    const saveChangesFinalizadora = useCallback(
        async (finalizadoraModel: FinalizadoraModel) => {
            try {

                const ret = await putFinalizadora(finalizadoraModel);

                if (ret.erro) {
                    throw ret.erro;
                }
                reload.current = true;

                const updatedFinalizadora = await getFinalizadoraByIdWrapper();

                const finalizadoras = await TouchoneDBPrimary.finalizadoras
                    .where({ id: updatedFinalizadora.id })
                    .toArray();


                await TouchoneDBPrimary.finalizadoras.update(finalizadoras[0].idIndexed!, {
                    credenciais: updatedFinalizadora.credenciais,
                    credenciado: updatedFinalizadora.credenciado,
                    dataCredenciamento: updatedFinalizadora.dataCredenciamento
                });

                if (updatedFinalizadora.credenciado) {
                    fecharDialogCredenciarPix()
                    finalizar(updatedFinalizadora)
                    return
                }
                setFinalizadoraForm(updatedFinalizadora)
                showToast('error', 'Não foi possível credenciar. Cheque suas credenciais.')
            } catch (e: any) {
                showToast('error', e.message);
                refEditForm.current?.resetForm();
            }
        },
        [fecharDialogCredenciarPix, finalizar, getFinalizadoraByIdWrapper, putFinalizadora, showToast],
    );

    const handleSubmit = useCallback(
        async (
            model: CredenciamentoSafra,
        ) => {
            model.cnpj = stringNumeros(model.cnpj)
            const finalizadoraModel = refFinalizadoraModel.current
            finalizadoraModel.credenciais = JSON.stringify(model)
            await saveChangesFinalizadora(finalizadoraModel);
        },
        [saveChangesFinalizadora],
    );
    const onCloseClick = useCallback(() => {
        cancelar('');
    }, [cancelar]);

    return (
        <Grid container spacing={2} className={classes.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid item>
                <FormCredenciarPix
                    loading={carregando}
                    onSubmit={handleSubmit}
                    showLoading={false}
                    ref={refEditForm}
                />
            </Grid>
            <Grid item className={credenciarClasses.infoBox}>
                <Typography>
                    Não está credenciado? Entre em contato com seu gerente Safrapay!
                </Typography>
                <Button color='primary' variant='outlined' onClick={handleClickLink} size='small'>
                    <LinkIcon tipo='BUTTON'/>
                    Portal SafraPay
                </Button>
            </Grid>
            <Grid item>
                <Box display='flex' justifyContent='flex-end' width='100%' gridGap={16} flexWrap='wrap'>
                    <Button variant='outlined'
                        color='primary'
                        onClick={onCloseClick}
                        className={classes.button}>
                        <CancelarIcon tipo='BUTTON' />
                        Cancelar
                    </Button>
                    <Button variant='contained'
                        color='primary'
                        onClick={() => refEditForm.current?.submitForm()}
                        className={classes.button}>
                        <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                        Credenciar
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
export default CredenciarPix;