import React from 'react';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';

import { TabelaPromocoesDePor } from 'database/interfaces/interface-tabela-promocoes-de-por';
import { EnumDiasSemana } from 'views/components/modals/promocao/components/promocao-dados/components/promocao-data-step/components/form-promocao-data';

export const usePromocoes = () => {

  const retornarPromocaoDePor = React.useCallback((produto: ProdutoResumidoModel) => {
    let promoAplicada: TabelaPromocoesDePor = {
      codigo: '',
      promocaoId: '',
      promocao: '',
      dataInicial: 0,
      dataFinal: 0,
      horaInicial: 0,
      horaFinal: 0,
      diasSemana: 0,
      ativo: true,
      valorPromocao: produto.vPreco,
      variacaoId: ''
    };

    const horaAtual = new Date().getHours() * 100 + new Date().getMinutes();
    const diaAtual = new Date().getDay() === 1 ? EnumDiasSemana.Segunda : new Date().getDay() === 2 ? EnumDiasSemana.Terca : new Date().getDay() === 3 ? EnumDiasSemana.Quarta : new Date().getDay() === 4 ? EnumDiasSemana.Quinta : new Date().getDay() === 5 ? EnumDiasSemana.Sexta : new Date().getDay() === 6 ? EnumDiasSemana.Sabado : EnumDiasSemana.Domingo;
    if (produto?.promocoes?.length > 0) {
      produto?.promocoes.forEach(promo => {
        if (horaAtual >= promo.horaInicial && horaAtual <= promo.horaFinal && ((promo.diasSemana & diaAtual) > 0) && promo.valorPromocao < promoAplicada.valorPromocao) {
          promoAplicada = promo;
        }
      });
    }
    return promoAplicada.promocaoId === '' ? undefined : promoAplicada;
  }, []);

  const processarPromocaoMov = React.useCallback(async (produto: ProdutoResumidoModel, mov: MovSimplesProdutoModel) => {
  }, []);

  return {
    retornarPromocaoDePor,
    processarPromocaoMov,
  }
};