import { useCallback, useMemo } from 'react';
import { Stepper } from 'views/components/stepper';
import { Box, Button, Grid, MobileStepper, Typography } from '@material-ui/core';
import { useConfigInicial } from './componentes/use-config-inicial';
import { useStyles } from './config-inicial-styles';
import { useThemeQueries } from 'views/theme';
import { EnumTelasConfigIniciais } from 'model/enums/enum-tela-configuracoes-iniciais';
import { CloseIcon } from 'views/components/icons/close-icon';
import { useConfirm } from 'material-ui-confirm';
import { usePostTutorial } from 'data/api/gestao/tutorial/post-tutorial';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useCadastros, useToastSaurus } from 'services/app';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { CircularLoading } from 'views/components/utils';
import { isEmpty } from 'lodash';

export const ConfigInicial = () => {

    const { formArray, formStepper, segmentoSelecionado, dadosTour } = useConfigInicial();
    const classes = useStyles();
    const { isMobile } = useThemeQueries()
    const formInicial = formArray.filter(step => step.id === EnumTelasConfigIniciais.Saudacao || step.id === EnumTelasConfigIniciais.Segmento || step.id === EnumTelasConfigIniciais.Config)
    const formVarejo = formArray.filter(step => step.id === EnumTelasConfigIniciais.Saudacao || step.id === EnumTelasConfigIniciais.Segmento || step.id === EnumTelasConfigIniciais.Cardapio || step.id === EnumTelasConfigIniciais.Perfil || step.id === EnumTelasConfigIniciais.NFCE || step.id === EnumTelasConfigIniciais.ResumoDasConfiguracoes)
    const formDefault = formArray.filter(step => step.id !== EnumTelasConfigIniciais.Config)
    const { showToast } = useToastSaurus()

    const validarLista = useCallback(() => {
        let modelo = []

        if ((formStepper.currentStep === 0 && formArray[0].previousButton === undefined) || (formStepper.currentStep === 1 && formArray[1].previousButton === undefined)) {
            modelo = formInicial
            return modelo
        }
        if (segmentoSelecionado === "Varejo Geral") {
            modelo = formVarejo;
            return modelo
        }
        return modelo = formDefault
    }, [formArray, formDefault, formInicial, formStepper.currentStep, formVarejo, segmentoSelecionado])
    const arrayDinamico = validarLista()
    const confirm = useConfirm()
    const { postTutorial, carregando } = usePostTutorial()
    const { fecharConfiguracoesIniciais } = useCadastros()
    const finalizaTutorialWizard = useCallback(async () => {
        try {
            const res = await postTutorial(dadosTour)
            if (res.erro) throw res.erro

        } catch (err: any) {
            showToast('error', err.message)
        }

    }, [dadosTour, postTutorial, showToast])

    const { callEvent } = useEventTools()

    const fecharWizard = useCallback(async () => {
        await finalizaTutorialWizard()
        callEvent(AppEventEnum.FinalizouWizard, { finalizado: true })
        fecharConfiguracoesIniciais()
    }, [callEvent, fecharConfiguracoesIniciais, finalizaTutorialWizard])


    const getButtons = useMemo(() => {
        return (
            <div>
                <Box mt={1}>
                    {arrayDinamico[formStepper.currentStep].habilitaModulo !== false &&
                        <Grid spacing={2} container >
                            {arrayDinamico[formStepper.currentStep].previousButton && (
                                <Grid item xs={6} sm={6}>
                                    {arrayDinamico[formStepper.currentStep].previousButton}
                                </Grid>
                            )}

                            {arrayDinamico[formStepper.currentStep].nextButton && (
                                <Grid
                                    item
                                    xs={arrayDinamico[formStepper.currentStep].id === EnumTelasConfigIniciais.Saudacao ? 12 : 6}
                                >
                                    {arrayDinamico[formStepper.currentStep].nextButton}
                                </Grid>
                            )}
                        </Grid>
                    }
                </Box>
            </div>
        );
    }, [arrayDinamico, formStepper.currentStep]);

    const validarTipoDeStepper = () => {
        if (arrayDinamico === formInicial) {
            return true
        }
        return false
    }
    return (

        <Grid className={classes.containerStepper} style={{ display: 'flex', flexDirection: 'column' }} >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid className={classes.closeButton} >
                <Button onClick={
                    () => {
                        confirm({
                            title: "Pular Configuração Inicial",
                            description:
                                <>
                                    <Typography>
                                        Tem certeza que deseja pular a configuração inicial?
                                    </Typography>
                                    <br />
                                    <Typography>
                                        Você perderá todas as configurações preenchidas até o momento.
                                    </Typography>
                                </>,
                            cancellationText: 'Não fechar',
                            confirmationText: 'Fechar',
                            confirmationButtonProps: {
                                style: {
                                    padding: 8
                                }
                            },
                            cancellationButtonProps: {
                                style: {
                                    padding: 8
                                }
                            }
                        }).then(() => {
                            fecharWizard()
                        }).catch(() => {
                        })
                    }

                }>
                    <CloseIcon tipo='BUTTON' />
                </Button>
            </Grid>
            {isMobile ?
                <Grid xs={11} className={classes.stepper} item>
                    <MobileStepper
                        classes={{
                            progress: classes.mobileBarProgress
                        }}
                        variant='progress'
                        steps={arrayDinamico.length}
                        position='static'
                        activeStep={formStepper.currentStep}
                        nextButton={() => { <></> }}
                        backButton={() => { <></> }}
                    />
                </Grid>
                :
                <Grid item className={validarTipoDeStepper() ? classes.stepperInicial : classes.stepperDefault}>
                    <Stepper
                        linear={true}
                        steps={arrayDinamico}
                        stepAtual={formStepper.currentStep}
                    />
                </Grid>
            }
            <Grid style={{ display: 'flex', flexDirection: 'column' }} xs={12}  >
                <Grid item alignItems='center' justifyContent={isMobile ? 'flex-start' : 'center'} className={classes.stepTitle}>
                    <Grid >
                        {arrayDinamico[formStepper.currentStep].formTitleIcon}
                    </Grid>
                    <Grid >
                        <Typography
                            align={isMobile ? 'left' : 'center'}
                            variant="h4"
                            color="primary"
                            style={{ fontWeight: 700 }}
                        >
                            {arrayDinamico[formStepper.currentStep].formTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item className={isEmpty(arrayDinamico[formStepper.currentStep].formDesc) ? classes.formDescEmpty : classes.formDesc} xs={12} md={8}>
                    <Typography align={isMobile ? 'left' : 'center'} variant="body1" style={{ fontWeight: 500 }}>
                        {arrayDinamico[formStepper.currentStep].formDesc}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.conteudoStep} alignContent='center' justifyContent='center'>
                    {arrayDinamico[formStepper.currentStep].form}
                </Grid>
                <Grid item md={8} className={classes.botoes} >
                    {getButtons}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default ConfigInicial;
