import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    pagesContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'stretch',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    containerButton: {
        display: 'flex',
        width: '100%',
    },
    containerIcon: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '5px',
        alignItems: 'center',
        "& svg": {
            width: theme.spacing(3.5),
            fill: theme.palette.primary.main
        }
    },
    containerIconAcao: {
        height: theme.spacing(4),
        borderLeft: '1px solid ' + theme.palette.grey[500],
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: theme.spacing(1),
        "& svg": {
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
            fill: theme.palette.primary.main
        }
    },
    textTitle: {
        fontSize: '11px',
        [theme.breakpoints.up("md")]: {
            fontSize: '12px'
        },
    },
    textValue: {
        fontWeight: 600,
        fontSize: '14px',
        [theme.breakpoints.up("md")]: {
            fontSize: '16px'
        },
    },
    logo: {
        padding: theme.spacing(2),
        height: 350,
        width: 350
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    }
}))