import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { ReceitaModal } from 'views/components/modals/receita/receita-modal';

export const CadastroReceitaModal = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<{
        aberto: boolean;
        fluxoFinalizado: () => Promise<void>;
    }>({
        aberto: false,
        fluxoFinalizado: () => Promise.resolve(),
    });

    const modalAlterado = useCallback(({ openned, fluxoFinalizado }: { openned: boolean, fluxoFinalizado: () => Promise<void> }) => {
        setModalState({
            aberto: openned,
            fluxoFinalizado: fluxoFinalizado,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.Receita, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.Receita, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <ReceitaModal openned={modalState.aberto} fluxoFinalizado={modalState.fluxoFinalizado} key="mdlReceita" id={''} />
    )
}