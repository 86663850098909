import { Paper } from '@material-ui/core';
import { CardDashboardChartBarProps } from '../card-dashboard-chart-props';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import { DashboardVendasModel } from 'model/api/gestao/dashboard/dashboard-vendas';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { Box, Divider, Grid, Typography } from 'views/design-system';

export const CardDashboardChartBar = ({
  model,
  carregando
}: CardDashboardChartBarProps) => {
  const utilClasses = useStyles();

  const semana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const seriesMP = useCallback(() => {
    let totalVendido = [];

    for (let i = 0; i < 7; i++) {
      let diaAtual = Array.from(
        new Set<DashboardVendasModel>(
          model?.filter(
            (item) => new Date(pegarDia(String(item.data))).getDay() === i,
          ),
        ),
      );

      totalVendido[i] = diaAtual;
    }

    return totalVendido.map((item) => item);
  }, [model]);

  const series = useCallback(() => {
    let totalVendido = [];
    let custo = [];
    let lucro = [];

    for (let i = 0; i < 7; i++) {
      let diaAtual = Array.from(
        new Set<DashboardVendasModel>(
          model?.filter(
            (item) => new Date(pegarDia(String(item.data))).getDay() === i,
          ),
        ),
      );

      if (diaAtual === undefined) {
        totalVendido[i] = 0;
        custo[i] = 0;
        lucro[i] = 0;
      } else {
        totalVendido[i] = diaAtual.reduce((a, b) => a + b.vVenda, 0).toFixed(2);
        custo[i] = diaAtual.reduce((a, b) => a + b.vCusto, 0).toFixed(2);
        lucro[i] = diaAtual.reduce((a, b) => a + (b.vVenda - b.vCusto), 0).toFixed(2);
      }
    }
    const series: any[] = [
      {
        name: 'Custo',
        data: custo,
      },
      {
        name: 'Total vendido',
        data: totalVendido,
      },
      {
        name: 'Lucro',
        data: lucro,
      },
    ];
    return series;
  }, [model]);

  const pegarDia = (item: String) => {
    let ano = item.slice(0, 4);
    let mes = item.slice(4, 6);
    let dia = item.slice(6, 8);
    return `${ano}/${mes}/${Number(dia)}`;
  };

  const options = useCallback(() => {
    const semana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const options = {
      labels: semana,
      tooltip: {
        style: {
          fontSize: '20px',
        },
        y: {
          formatter: (val: any) => `R$ ${toDecimalString(val)}`
        },
      },
      xaxis: {
        labels: {
          formatter: (val: any) => `R$ ${val.toFixed(0)}`
        }
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
            label: {
              fontSize: 16,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#46B2F0', '#1F9CE4', '#B2DDF6'],
      dataLabels: {
        enabled: false,
      },
    };
    return options;
  }, []);

  return (
    <>
      <Paper className={utilClasses.cardContainer}>
        <Grid container >
          <Grid item xs={12} p={1}>
            <Typography
              color="primary"
              weight={500}
            >
              Receita por dia da semana
            </Typography>
            <Divider variant='fullWidth' />
          </Grid>
          <Grid item xs={12} mt={2}>
            <>
              {model.length === 0 ? (
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                  Não há dados para exibir
                </Typography>
              ) : (
                <>
                  {VariaveisAmbiente.paymentDevice === undefined ? (
                    <>
                      {seriesMP().map((item, index) => (
                        <Paper
                          key={index}
                         className={utilClasses.cardReceitaPorHorario}
                        >
                          <Box>
                            <Typography
                              variant="caption"
                              weight={700}
                            >
                              Dia
                            </Typography>
                            <Typography>{semana[index]}</Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="caption"
                              weight={700}
                              align='right'
                            >
                              Total Vendido
                            </Typography>
                            <Typography align='right'>
                              R$ {toDecimalString(
                                item.reduce((a, b) => a + b.vVenda, 0),
                                2,
                              )}
                            </Typography>
                          </Box>
                          <Box flex flexDirection='column'>
                            <Typography
                              variant="caption"
                              weight={700}
                              align='right'
                            >
                              Lucro
                            </Typography>
                            <Typography 
                            align='right'
                            style={{ color: 'green' }}>
                              R$ {toDecimalString(
                                item.reduce(
                                  (a, b) => a + (b.vVenda - b.vCusto),
                                  0,
                                ),
                                2,
                              )}
                            </Typography>
                            <Typography
                              variant="caption"
                              align='right'
                              color='error'
                            >
                              -{' '}
                              {toDecimalString(
                                item.reduce((a, b) => a + b.vCusto, 0),
                                2,
                              )}
                            </Typography>
                          </Box>
                        </Paper>
                      ))}
                    </>
                  ) : (
                    <Chart
                      options={options()}
                      series={series()}
                      type="bar"
                      className={utilClasses.chart}
                    />
                  )}
                </>
              )}
            </>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
