import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const BalancaIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 16.8125 2 C 16.335938 2.089844 15.992188 2.511719 16 3 L 16 11 C 16 11.550781 16.449219 12 17 12 L 22 12 L 22 30 L 10 30 C 9.96875 30 9.9375 30 9.90625 30 C 9.613281 30.03125 9.351563 30.191406 9.1875 30.4375 L 2.1875 40.4375 C 2.070313 40.601563 2.003906 40.796875 2 41 L 2 45 C 2 45.550781 2.449219 46 3 46 L 4 46 L 4 47 L 6 47 L 6 46 L 44 46 L 44 47 L 46 47 L 46 46 L 47 46 C 47.550781 46 48 45.550781 48 45 L 48 41 C 47.996094 40.796875 47.929688 40.601563 47.8125 40.4375 L 40.8125 30.4375 C 40.628906 30.167969 40.324219 30.003906 40 30 L 28 30 L 28 12 L 33 12 C 33.550781 12 34 11.550781 34 11 L 34 3 C 34 2.449219 33.550781 2 33 2 L 17 2 C 16.96875 2 16.9375 2 16.90625 2 C 16.875 2 16.84375 2 16.8125 2 Z M 18 4 L 32 4 L 32 10 L 18 10 Z M 29 7 L 29 9 L 31 9 L 31 7 Z M 24 12 L 26 12 L 26 30 L 24 30 Z M 10.5 32 L 39.5 32 L 45.09375 40 L 4.90625 40 Z M 4 42 L 46 42 L 46 44 L 4 44 Z" />
            </DefaultIcon>
        </>
    );
};