import { Button, Grid, Tooltip } from "views/design-system";
import { useStyles } from './button-header-desktop-styles'
import React from "react";
import classNames from "classnames";

interface Props {
    onClick: () => void;
    icon: React.ReactNode;
    divisoriaLateral?: boolean;
    destaque?: boolean;
    tooltip?: string;
    id?: string;
}

const TooltipWrapper = ({ text, children }: { text?: string, children: React.ReactElement }) => {
    if (!text || text.length === 0) {
        return children;
    }

    return <Tooltip
        title={text}
    >
        {children}
    </Tooltip>
}

export const ButtonHeaderDesktop = ({
    icon,
    onClick,
    divisoriaLateral,
    destaque,
    tooltip,
    id
}: Props) => {

    const classes = useStyles();

    return (
        <Grid className={
            classNames(
                divisoriaLateral ? classes.dividerVerticalRightArea : '',
                destaque ? classes.destaqueButton : '',
                classes.buttonMenu
            )
        }>
            <TooltipWrapper text={tooltip}>
                <Button
                    className={classes.iconOptions}
                    onClick={onClick}
                    id={id}
                >
                    {icon}
                </Button>
            </TooltipWrapper>
        </Grid>
    )
}