import { useStyles } from '../../../comissao-steps-styles';
import classNames from 'classnames';
import { OkIcon, PessoasIcon } from 'views/components/icons';
import { PessoasMesaIcon } from 'views/components/icons/pessoas-mesa-icon';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { useComissionadosStore } from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { Box, Button, Grid } from 'views/design-system';
import { CardTipoPessoa } from 'views/components/cards/card-tipo-pessoa/card-tipo-pessoa';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useCadastros } from 'services/app';

interface StepsSelecaoPessoaProps {
  onClick: (type: EnumTpPessoaComissionamento) => void;
}

export const SelecaoTipoPessoa = ({ onClick }: StepsSelecaoPessoaProps) => {

  // STORES
  const {
    comissionadoGerenteGeral,
    comissionadoGerentes,
    comissionadoVendedores
  } = useComissionadosStore((state) => state.state);

  // HOOKS
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const { fecharCadastroComissao } = useCadastros()

  const handleClickTipoPessoa = (type: EnumTpPessoaComissionamento) => {
    onClick(type);
  };

  const buttonsTipoPessoa = [
    {
      icone: (
        <PessoaIcon
          tipo="BUTTON"
          style={{
            width: '96px',
            height: '52px',
            margin: 0
          }}
        />
      ),
      label: 'Gerente geral',
      type: EnumTpPessoaComissionamento.GerenteGeral
    },
    {
      icone: (
        <PessoasIcon
          tipo="BUTTON"
          style={{
            width: '96px',
            height: '52px',
            margin: 0
          }}
        />
      ),
      label: 'Gerente',
      type: EnumTpPessoaComissionamento.Gerente
    },
    {
      icone: (
        <PessoasMesaIcon
          tipo="BUTTON"
          style={{
            width: '96px',
            height: '52px',
            margin: 0
          }}
        />
      ),
      label: 'Vendedor',
      type: EnumTpPessoaComissionamento.Vendedor
    }
  ];

  return (
    <div className={classes.flex}>
      <div className={modalClasses.root}>
        <div className={classNames(classes.content)}>
          <div className={classNames(classes.contentForms)}>
            <Box className={classNames(classes.containerStepsForm)}>
              <div>
                <div
                  className={classNames(
                    classes.step
                  )}
                >
                  <div
                    className={classNames(
                      classes.stepForm
                    )}
                  >
                    <Grid container spacing={1} style={{ marginTop: 20 }}>
                      {buttonsTipoPessoa.map((modelo) => {
                        let quantidade = 0;

                        if (
                          modelo.type === EnumTpPessoaComissionamento.GerenteGeral
                        ) {
                          quantidade = comissionadoGerenteGeral?.length ?? 0;
                        }

                        if (modelo.type === EnumTpPessoaComissionamento.Gerente) {
                          quantidade = comissionadoGerentes.length ?? 0;
                        }

                        if (modelo.type === EnumTpPessoaComissionamento.Vendedor) {
                          quantidade = comissionadoVendedores?.length ?? 0;
                        }

                        return (
                          <Grid item xs={4}>
                            <CardTipoPessoa
                              key={modelo.label}
                              icone={modelo.icone}
                              label={modelo.label}
                              type={modelo.type}
                              quantity={quantidade}
                              onClick={handleClickTipoPessoa}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <Box className={classes.acoes}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={
                  comissionadoVendedores.length <= 0 &&
                  comissionadoGerenteGeral.length <= 0 &&
                  comissionadoGerentes.length <= 0
                }
                onClick={() => fecharCadastroComissao('')}
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
