import classNames from 'classnames';
import { useStyles } from './card-tipo-pessoa-styles';
import { Badge } from '@material-ui/core';

interface CardTipoPessoaProps {
  icone: JSX.Element;
  label: string;
  type: number;
  onClick: (type: number) => void;
  quantity: number | null;
}

export const CardTipoPessoa = ({
  icone,
  label,
  onClick,
  type,
  quantity
}: CardTipoPessoaProps) => {
  const classes = useStyles();

  const handleClickApuracao = () => {
    onClick(type);
  };

  return (
    <div
      className={classNames(classes.container)}
      onClick={handleClickApuracao}
    >
      <Badge
        badgeContent={quantity}
        color={'error'}
        style={{ marginLeft: '20px' }}
      ></Badge>
      {icone}
      <p className={classes.label}>{label}</p>
    </div>
  );
};
