import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useStyles } from './header-text-field-styles';
import classNames from 'classnames';
import { useThemeQueries } from 'views/theme';
import { TextFieldSaurusProps } from 'views/components/controles/inputs/text-field-saurus/text-field-saurus-props';

export type HeaderTextFieldProps = TextFieldSaurusProps & {
  forceMobile?: boolean;
}

export const HeaderTextField = (props: HeaderTextFieldProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  return <TextFieldSaurus
    className={classNames((props.forceMobile || isMobile) ? classes.textFieldMobile : classes.textfield)}
    variant="outlined"
    removeBackground
    fullWidth
    allowSubmit
    autoComplete="new-password"
    InputLabelProps={{
      shrink: true
    }}
    size="small"
    {...props}
  />

};
