import { HistoricoVendaHeader } from './components/historico-venda-header/historico-venda-header';
import { Grid } from 'views/design-system';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { HistoricoVendaTabs } from './components/historico-venda-tabs/historico-venda-tabs';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useEffect, useMemo } from 'react';

export const HistoricoVendaPage = () => {
  const classes = useDefaultCadastroStyles();
  const setHideHeader = useVendaHeaderStore(state => state.setHideHeader);

  useEffect(() => {
    setHideHeader(true);
  }, [setHideHeader])

  const component = useMemo(() => <>
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <HistoricoVendaHeader />
      </Grid>
      <Grid className={classes.list}>
        <HistoricoVendaTabs />
      </Grid>
    </Grid>
  </>, [classes.header, classes.list, classes.root]);
  return component;
};