import { Grid } from "views/design-system";
import { PromocaoRegraListModel } from "model/api/gestao/promocao/promocao-regras-list-model";
import CardPromocaoModelo from "views/components/cards/card-promocao-modelo/card-promocao-modelo";
import { PromocaoRegraModeloModel } from "model/api/gestao/promocao/promocao-regra-modelo-model";
import { useCallback } from "react";
import { useCadastros, useToastSaurus } from "services/app";
import { useStyles } from "./promocao-regra-list-styles";
import { PromocaoTipoRegraMock } from "data/mocks/promocao-mock";

export interface PromocaoRegraListProps {
    regras: PromocaoRegraListModel[];
    promocaoId: string;
    carregando: boolean;
    atualizarModelos(): void;
}
export const PromocaoRegraList = (props: PromocaoRegraListProps) => {
    const { showToast } = useToastSaurus();
    const classes = useStyles();
    const { abrirCadastroPromocaoRegra } = useCadastros();
    const onClickRegra = useCallback((model: PromocaoRegraModeloModel) => {
        try {

            var regras = props.regras.filter(x => x.tipoRegra === model.tipo);

            if (regras.length < 2) {
                abrirCadastroPromocaoRegra(regras.length > 0 ? regras[0].id : '', model.promocaoId, model.tipo, '', true);
                return;
            } else {
                throw Error('Existem mais de uma Regra para este modelo de Promoção. Acione o Suporte para corrigir');
            }
        } catch (e: Error | any) {
            showToast('error', e.message)
        }

    }, [props.regras, abrirCadastroPromocaoRegra, showToast]);


    return (
        <Grid m={2}>
            <Grid container spacing={2} className={classes.list} >
                {!props.carregando && (
                    PromocaoTipoRegraMock.map((tipoRegra) => {
                        var model = new PromocaoRegraModeloModel(props.promocaoId, tipoRegra.Key, tipoRegra.Value, tipoRegra.Adicionais?.info, tipoRegra.Adicionais?.status, props.regras.filter(x => x.tipoRegra === tipoRegra.Key).reduce((sum, current) => sum + current.totalDetalhes, 0));
                        return <CardPromocaoModelo model={model} onClick={onClickRegra}></CardPromocaoModelo>
                    })
                )}
            </Grid>
        </Grid>
    );
};


