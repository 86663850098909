import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useFormNovaSenhaValidation } from './form-alterar-senha-validation';
import { makeUtilClasses, useThemeQueries } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { AlterarSenhaFormModel } from 'model/app/forms/master/alterar-senha-form-model';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from 'views/design-system';

export const FormAlterarSenha = forwardRef<
  DefaultFormRefs<AlterarSenhaFormModel>,
  DefaultFormProps<AlterarSenhaFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<AlterarSenhaFormModel>,
    ref,
  ) => {
    const { FormNovaSenhaValidationYup } = useFormNovaSenhaValidation();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
    } = useForm<AlterarSenhaFormModel>({
      resolver: yupResolver(FormNovaSenhaValidationYup),
      criteriaMode: 'all',
      mode: 'onChange',
    });
    const refInputSenhaAtual = useRef<HTMLInputElement>(null);
    const utilClasses = makeUtilClasses();
    const { isMobile } = useThemeQueries();

    const submitAlterarSenha = useCallback(
      (data: AlterarSenhaFormModel) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        handleSubmit(submitAlterarSenha)();
      },
      resetForm: () => {
        if (!isMobile) {
          refInputSenhaAtual.current?.focus();
        }

        reset();
      },
      fillForm: () => {
        if (!isMobile) {
          refInputSenhaAtual.current?.focus();
        }
        reset();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarSenha)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="senhaAtual"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputSenhaAtual}
                        id="senhaAtual"
                        label="Senha Atual"
                        tipo="PASSWORD"
                        showPasswordVisibilityButton
                        fullWidth={true}
                        error={Boolean(
                          errors.senhaAtual && errors.senhaAtual.message,
                        )}
                        helperText={
                          touchedFields.senhaAtual || errors.senhaNova
                            ? errors.senhaAtual?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="senhaNova"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        tipo="PASSWORD"
                        showPasswordVisibilityButton
                        id="novaSenha"
                        label="Nova Senha"
                        fullWidth={true}
                        error={Boolean(
                          errors.senhaNova && errors.senhaNova.message,
                        )}
                        helperText={
                          touchedFields.senhaNova || errors.senhaNova
                            ? errors.senhaNova?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="senhaConfirmacao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="PASSWORD"
                        showPasswordVisibilityButton
                        disabled={loading}
                        id="novaSenhaConfirmacao"
                        label="Confirmar nova senha"
                        fullWidth={true}
                        helperText={
                          touchedFields.senhaConfirmacao ||
                          errors.senhaConfirmacao
                            ? errors.senhaConfirmacao?.message
                            : undefined
                        }
                        error={Boolean(
                          errors.senhaConfirmacao &&
                            errors.senhaConfirmacao.message,
                        )}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
