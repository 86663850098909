import { Typography } from '@material-ui/core';
import { useThemeQueries } from 'views';
import { CardComandaFechamentoProps } from './card-comanda-fechamento-props';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { toDecimalString } from 'utils/to-decimal';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { DefaultCard } from '../components';

export const CardComandaFechamento = ({
  model,
  onClick,
  selecionado,
}: CardComandaFechamentoProps) => {
  const classesCardDefault = useDefaultCardStyles();
  const { theme } = useThemeQueries();

  return (
    <>
      <DefaultCard
        isSelected={selecionado}
        onClick={async () => {
          onClick(model);
        }}
      >
        <div className={classesCardDefault.cardContent}>
          <div
            className={classesCardDefault.celulaGrid}
            style={{ marginLeft: 8 }}
          >
            <ComandasIcon
              style={{ height: '35px', margin: 0 }}
              fill={theme.palette.primary.main}
            />
          </div>

          <div
            className={classesCardDefault.celulaGrid}
            style={{ minWidth: 100 }}
          >
            <Typography color="textPrimary" variant="caption">
              Comanda
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.codigoComanda}</strong>
            </Typography>
          </div>

          <div className={classesCardDefault.celulaGrid}>
            <SacoDinheiroIcon
              fill={theme.palette.primary.main}
              style={{ height: '35px', margin: 0 }}
            />
          </div>

          <div className={classesCardDefault.celulaGridFull}>
            <Typography color="textPrimary" variant="caption">
              Valor
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <b>R$ {toDecimalString(model.valorTotalPedido, 2)}</b>
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
