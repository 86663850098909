import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    base: {
        color: 'inherit',
        border: '0',
        cursor: 'pointer',
        margin: '0',
        display: 'inline-flex',
        outline: '0',
        padding: '0',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        borderRadius: '0',
        verticalAlign: 'middle',
        MozAppearance: 'none',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        WebkitAppearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        overflow: 'hidden',
    },
    root: {
        color: theme.palette.text.primary,
        padding: '6px 16px',
        fontSize: '0.875rem',
        minWidth: 'none',
        boxSizing: 'border-box',
        fontFamily: 'Montserrat, sans-serif',
        lineHeight: '1.75',
        borderRadius: '8px',
        textTransform: 'none',
        fontWeight: 600,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            }
        },
        "&:active": {
            backgroundColor: theme.palette.grey[500],
        },
        "&:focus ": {
            backgroundColor: theme.palette.grey[400],
        },

    },


    containedPrimary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            }
        },
        "&:active": {
            backgroundColor: theme.palette.primary.light,
        },
        "&:focus ": {
            backgroundColor: theme.palette.primary.dark,
        },
        "&:disabled": {
            backgroundColor: theme.palette.grey[500],
        }
    },
    sizeLargeRounded: {
        borderRadius: theme.spacing(8),
    },
    sizeMediumRounded: {
        borderRadius: theme.spacing(7),
    },
    sizeSmallRounded: {
        borderRadius: theme.spacing(6),
    },
    containedSecondary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            }
        },
        "&:active": {
            backgroundColor: theme.palette.secondary.light,
        },
        "&:focus ": {
            backgroundColor: theme.palette.secondary.dark,
        },
        "&:disabled": {
            backgroundColor: theme.palette.grey[500],
        }
    },
    containedInherit: {
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.grey[300],
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
            }
        },
        "&:active": {
            backgroundColor: theme.palette.grey[500],
        },
        "&:focus ": {
            backgroundColor: theme.palette.grey[400],
        },
        "&:disabled": {
            backgroundColor: theme.palette.grey[500],
        }
    },
    containedError: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            }
        },
        "&:active": {
            backgroundColor: '#ff6b60',
        },
        "&:focus ": {
            backgroundColor: theme.palette.error.dark,
        },
        "&:disabled": {
            backgroundColor: theme.palette.grey[500],
        }
    },
    containedDefault: {
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
            }
        },
        "&:active": {
            backgroundColor: theme.palette.grey[500],
        },
        "&:focus ": {
            backgroundColor: theme.palette.grey[400],
        },
        "&:disabled": {
            backgroundColor: theme.palette.grey[500],
        }
    },

    outlinedPrimary: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        padding: "5px 15px",
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `${theme.palette.primary.main}10`,
                border: `1px solid${theme.palette.primary.main}`,
            }
        },
        "&:active": {
            backgroundColor: `${theme.palette.primary.main}20`,
            border: `1px solid${theme.palette.primary.main}`,
        },
        "&:focus": {
            backgroundColor: `${theme.palette.primary.main}10`,
            border: `1px solid${theme.palette.primary.main}`,
        },
        "&:disabled": {
            border: `1px solid${theme.palette.grey[500]}`,
        }
    },
    outlinedSecondary: {
        color: theme.palette.secondary.main,
        border: `1px solid${theme.palette.secondary.main}80`,
        padding: "5px 15px",
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `${theme.palette.secondary.main}10`,
                border: `1px solid${theme.palette.secondary.main}`,
            }
        },
        "&:active": {
            backgroundColor: `${theme.palette.secondary.main}20`,
            border: `1px solid${theme.palette.secondary.main}`,
        },
        "&:focus": {
            backgroundColor: `${theme.palette.secondary.main}10`,
            border: `1px solid${theme.palette.secondary.main}`,
        },
        "&:disabled": {
            border: `1px solid${theme.palette.grey[500]}`,
        }
    },
    outlinedError: {
        color: theme.palette.error.main,
        border: `1px solid${theme.palette.error.main}80`,
        padding: "5px 15px",
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `${theme.palette.error.main}10`,
                border: `1px solid${theme.palette.error.main}`,
            }
        },
        "&:active": {
            backgroundColor: `${theme.palette.error.main}20`,
            border: `1px solid${theme.palette.error.main}`,
        },
        "&:focus": {
            backgroundColor: `${theme.palette.error.main}10`,
            border: `1px solid${theme.palette.error.main}`,
        },
        "&:disabled": {
            border: `1px solid${theme.palette.grey[500]}`,
        }
    },
    outlinedInherit: {
        color: theme.palette.text.primary,
        border: `1px solid rgba(0,0,0,0.23)`,
        borderColor: "currentColor",
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.05)`,
            }
        },
        "&:active": {
            backgroundColor: `rgba(0,0,0,0.1)`,
        },
        "&:focus": {
            backgroundColor: `rgba(0,0,0,0.05)`,
        },
        "&:disabled": {
            border: `1px solid${theme.palette.grey[500]}`,
        }
    },
    outlinedDefault: {
        border: `1px solid rgba(0,0,0,0.23)`,
        padding: "5px 15px",
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.05)`,
            }
        },
        "&:active": {
            backgroundColor: `rgba(0,0,0,0.1)`,
        },
        "&:focus": {
            backgroundColor: `rgba(0,0,0,0.05)`,
        },
        "&:disabled": {
            border: `1px solid${theme.palette.grey[500]}`,
        }
    },

    textPrimary: {
        color: theme.palette.primary.main,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `${theme.palette.primary.main}10`,
            }
        },
        "&:active": {
            backgroundColor: `${theme.palette.primary.main}20`,
        },
        "&:focus": {
            backgroundColor: `${theme.palette.primary.main}10`,
        },
        "&:disabled": {
            backgroundColor: `${theme.palette.grey[500]}10`,
        }
    },
    textSecondary: {
        color: theme.palette.secondary.main,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `${theme.palette.secondary.main}10`,
            }
        },
        "&:active": {
            backgroundColor: `${theme.palette.secondary.main}20`,
        },
        "&:focus": {
            backgroundColor: `${theme.palette.secondary.main}10`,
        },
        "&:disabled": {
            backgroundColor: `${theme.palette.grey[500]}10`,
        }
    },
    textError: {
        color: theme.palette.error.main,
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `${theme.palette.error.main}10`,
            }
        },
        "&:active": {
            backgroundColor: `${theme.palette.error.main}20`,
        },
        "&:focus": {
            backgroundColor: `${theme.palette.error.main}10`,
        },
        "&:disabled": {
            backgroundColor: `${theme.palette.grey[500]}10`,
        }
    },
    textInherit: {
        color: 'inherit',
        borderColor: 'currentColor',
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.05)`,
            }
        },
        "&:active": {
            backgroundColor: `rgba(0,0,0,0.1)`,
        },
        "&:focus ": {
            backgroundColor: `rgba(0,0,0,0.05)`,
        },
        "&:disabled": {
            backgroundColor: `${theme.palette.grey[500]}10`,
        }
    },
    textDefault: {
        '@media (hover: hover)': {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.05)`,
            }
        },
        "&:active": {
            backgroundColor: `rgba(0,0,0,0.1)`,
        },
        "&:focus ": {
            backgroundColor: `rgba(0,0,0,0.05)`,
        },
        "&:disabled": {
            backgroundColor: `${theme.palette.grey[500]}10`,
        }
    },

    text: {
        padding: "6px 8px",
    },
    outlined: {
        padding: "5px 15px",
    },
    contained: {
        boxShadow: theme.shadows[2],
    },

    containedSizeLarge: {
        padding: '8px 22px',
        fontSize: '0.9375rem',
    },
    containedSizeSmall: {
        padding: '4px 10px',
        fontSize: '0.8125rem',
    },

    outlinedSizeLarge: {
        padding: '7px 21px',
        fontSize: '0.9375rem',
    },
    outlinedSizeSmall: {
        padding: '3px 9px',
        fontSize: '0.8125rem',
    },

    textSizeLarge: {
        padding: '8px 11px',
        fontSize: '0.9375rem',
    },
    textSizeSmall: {
        padding: '4px 5px',
        fontSize: '0.8125rem',
    },

    sizeLarge: {
        height: theme.spacing(8),
    },
    sizeMedium: {
        height: theme.spacing(7),
    },
    sizeSmall: {
        height: theme.spacing(6),
    },

    span: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    fullWidth: {
        width: "100%",
    }
}));
