
export class GeolocalizacaoFreteModel {
    constructor(
        public valorKM: number = 0,
        public distanciaMaximaMetros: number = 0,
        public origem: GeolocalizacaoEnderecoFreteModel = new GeolocalizacaoEnderecoFreteModel(),
        public destinatario: GeolocalizacaoEnderecoFreteModel = new GeolocalizacaoEnderecoFreteModel()
    ) { }
}

export class GeolocalizacaoEnderecoFreteModel {
    constructor(
        public logradouro: string = '',
        public numero: string = '',
        public bairro: string = '',
        public cidade: string = '',
        public pais: string = '',
        public estado: string = '',
        public cep: string = '',
        public codigoMunicipio: string = '',
        public codigoUF: string = ''
    ) { }
}
