import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '66px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center'
  },
  content: {
    flexDirection: 'column',
    display: 'flex'
  }
}));
