import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-fechamento-styles';
import { DinheiroIcon, VoltarIcon } from 'views/components/icons';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { useHistory } from 'react-router-dom';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useConfirm } from 'material-ui-confirm';
import { useCallback } from 'react';
import { useCadastros } from 'services/app';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';

interface DialogFechamentoProps {
  openned: boolean;
  pedidos: PedidoModelComanda[];
}

export const DialogFechamento = ({
  openned,
  pedidos
}: DialogFechamentoProps) => {
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const { fecharFechamentoPedidorDialog } = useCadastros();

  const naoFechados = pedidos.filter(
    (item) =>
      item.statusPedido.codigo !== EnumStatusPedido.FECHADO &&
      item.statusPedido.codigo !== EnumStatusPedido.IMPORTADO
  );

  const pedidoNaMesa = pedidos.filter((item) => !item.comandaId);

  const fecharTodasComandas = useCallback(() => {
    confirm({
      title: 'Fechar Comandas',
      description: 'Deseja fechar todas as comandas disponíveis?',
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(() => {
      history.push({
        pathname: MovRotasMock.fecharComandaRota,
        state: { pedidos: naoFechados, mesa: true }
      });

      fecharFechamentoPedidorDialog();
    });
  }, [confirm, fecharFechamentoPedidorDialog, history, naoFechados]);

  const fecharPedidoNaMesa = useCallback(() => {
    confirm({
      title: 'Fechar pedido da mesa',
      description: 'Deseja fechar o pedido feito na mesa?',
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(() => {
      history.push({
        pathname: MovRotasMock.fecharComandaRota,
        state: { pedidos: pedidoNaMesa, mesa: true }
      });

      fecharFechamentoPedidorDialog();
    });
  }, [confirm, fecharFechamentoPedidorDialog, history, pedidoNaMesa]);

  const importarPedidoDaMesa = useCallback(() => {
    confirm({
      confirmationText: 'Finalizar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
        className: 'round'
      },
      cancellationButtonProps: {
        className: 'round'
      },
      title: 'Finalizar Pedidos',
      description: 'Deseja finalizar o pedido da mesa?'
    }).then(() =>
      history.push({
        pathname: MovRotasMock.importarPedidosRota,
        state: {
          pedidos: pedidoNaMesa,
          mesa: true
        }
      })
    );
  }, [confirm, history, pedidoNaMesa]);

  return (
    <DialogSaurus aberto={openned || false} titulo="Fechamento" tamanho="xs">
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Grid
            container
            spacing={2}
            style={{ marginBottom: 8, textAlign: 'center' }}
          >
            {pedidoNaMesa.length > 0 &&
              pedidoNaMesa[0].statusPedido.codigo !==
                EnumStatusPedido.IMPORTADO && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      if (
                        pedidoNaMesa[0].statusPedido.codigo ===
                        EnumStatusPedido.FECHADO
                      ) {
                        importarPedidoDaMesa();
                      } else {
                        fecharPedidoNaMesa();
                      }
                      fecharFechamentoPedidorDialog();
                    }}
                  >
                    <MesaIcon tipo="BUTTON_PRIMARY" />
                    {pedidoNaMesa[0].statusPedido.codigo ===
                    EnumStatusPedido.FECHADO
                      ? 'Importar pedido da mesa'
                      : 'Fechar pedido da mesa'}
                  </Button>
                  <Typography variant="caption">
                    {pedidoNaMesa[0].statusPedido.codigo ===
                    EnumStatusPedido.FECHADO
                      ? 'finalizar pedido da mesa como venda.'
                      : 'Realizar o fechamento do pedido realizado na mesa.'}
                  </Typography>
                </Grid>
              )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: MovRotasMock.fechamentoComandaRota,
                    state: { pedidos }
                  });
                  fecharFechamentoPedidorDialog();
                }}
              >
                <ComandasIcon tipo="BUTTON_PRIMARY" />
                Selecionar comanda
              </Button>
              <Typography variant="caption">
                Selecione as comandas que deseja realizar o fechamento.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={naoFechados.length === 0 ? true : false}
                variant="contained"
                fullWidth
                color="primary"
                onClick={fecharTodasComandas}
              >
                <DinheiroIcon tipo="BUTTON_PRIMARY" />
                Fechar todos os pedidos
              </Button>
              <Typography variant="caption">
                Realize o fechamento de todos os pedidos atrelados à mesa.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {fecharFechamentoPedidorDialog && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    fecharFechamentoPedidorDialog();
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
