import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useCadastros } from "services/app";
import { Button, Divider, Grid } from "@material-ui/core";
import { useCallback, useRef } from "react";
import { OkIcon, VoltarIcon } from "views/components/icons";
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { DefaultFormRefs } from "views/components/form/utils";
import { AdicionarDescontoAcrescimoFormModel } from "model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model";
import { EnumDecontoOuAcrescimo } from "model/enums/enum-desconto-ou-acrescimo";
import { useCarrinho } from "services/app/hooks/carrinho";
import { FormAdicionarDescontoAcrescimo } from "views/components/form/adicionar-desconto-acrescimo/form-adicionar-desconto-acrescimo";

export interface DialogAdicionarAcrescDescProps {
    aberto: boolean;
    tipo: EnumDecontoOuAcrescimo;
    model?: MovSimplesProdutoModel;
    valorTotal?: number;
}

export const DialogAdicionarAcrescDesc = ({
    aberto,
    model,
    tipo,
    valorTotal,
}: DialogAdicionarAcrescDescProps) => {

    //AUX
    const { theme } = useThemeQueries();

    //PROVIDERS
    const { fecharDialogAdicionarAcrescDesc } = useCadastros();
    const { adicionarDescontoProduto, adicionarAcrescimoProduto } = useCarrinho();
    
    //REFS
    const refFormDescontoAcrescimo = useRef<DefaultFormRefs<AdicionarDescontoAcrescimoFormModel>>(null);

    const handleSubmmit = useCallback((value: AdicionarDescontoAcrescimoFormModel) => {
        if(!model){
            return
        }
        switch (tipo) {
            case EnumDecontoOuAcrescimo.AcrescimoItem:
                adicionarAcrescimoProduto(model, value, fecharDialogAdicionarAcrescDesc);
                return
            case EnumDecontoOuAcrescimo.DescontoItem:
                adicionarDescontoProduto(model, value, fecharDialogAdicionarAcrescDesc);
                return
        }
    },
        [adicionarAcrescimoProduto, adicionarDescontoProduto, fecharDialogAdicionarAcrescDesc, model, tipo],
    );

    const switchTitulo = useCallback(() => {
        switch (tipo) {
            case EnumDecontoOuAcrescimo.AcrescimoItem:
                return 'Acréscimo no Produto'
            case EnumDecontoOuAcrescimo.AcrescimoVenda:
                return 'Acréscimo na Venda'
            case EnumDecontoOuAcrescimo.DescontoItem:
                return 'Desconto no Produto'
            case EnumDecontoOuAcrescimo.DescontoVenda:
                return 'Desconto na Venda'
        }
    }, [tipo])

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                titulo={switchTitulo()}
                isButtonTitleClose
                handleClickClose={fecharDialogAdicionarAcrescDesc}
                tamanho='xs'
                colorTitulo={theme.palette.primary.main}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormAdicionarDescontoAcrescimo
                            ref={refFormDescontoAcrescimo}
                            loading={false}
                            showLoading={false}
                            onSubmit={handleSubmmit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={fecharDialogAdicionarAcrescDesc}
                        >
                            <VoltarIcon tipo="BUTTON" />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => refFormDescontoAcrescimo.current?.submitForm()}
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Aplicar
                        </Button>
                    </Grid>
                </Grid>
            </DialogSaurus>
        </>
    )
}