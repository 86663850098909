import { SaloesHeader } from "./components/saloes-header/saloes-header"
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles"
import { SaloesList } from "./components/saloes-list/saloes-list";
import { Grid } from "views/design-system";
import { useMemo } from "react";

const SaloesPage = () => {

    const classes = useDefaultCadastroStyles();
    const component = useMemo(() => <>
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <SaloesHeader />
            </Grid>
            <Grid className={classes.list}>
                <SaloesList />
            </Grid>
        </Grid>
    </>, [classes.header, classes.list, classes.root]);

    return component;
}

export default SaloesPage