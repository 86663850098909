import { Grid } from "views/design-system";
import { useHistory } from "react-router";
import { SuccessfullyChanged } from "views/components/successfully-changed/successfully-changed";
import { useStyles } from "./convite-concluido-page-styles";

const ConviteConcluidoPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleContinue = () => {
    history.push("/");
  };

  return (
    <Grid className={classes.root}>
      <SuccessfullyChanged
        text="Convite concluido com sucesso!"
        onClick={handleContinue}
      />
    </Grid>
  );
};

export default ConviteConcluidoPage;
