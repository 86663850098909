
export class GeolocalizacaoFreteResponseModel {
    constructor(
        public dentro: boolean = false,
        public distanciaMetros: number = 0,
        public distanciaFormatada: string = '',
        public duracaoFormatada: string = '',
        public duracaoMinutos: number = 0,
        public enderecoDestino: string = '',
        public enderecoOrigem: string = '',
        public valorFrete: number = 0,
    ) { }
}

