import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    minWidth: '60px',
    height: '80px',
    flex: 1,
    margin: 0,
    marginRight: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: 'transparent',
    border: "0px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "@media (hover: hover)": {
      "&:hover": {
        border: '1px solid ' + theme.palette.primary.main,
        color: '#fff'
      }
    }
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: 32,
      height: 32,
    },
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconGrid: {
  },
  celulaGrid: {
    display: 'flex',
    flex: 'none',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    "& p": {
      textAlign: "center",
      lineHeight: 1,
    }
  },
  textPayment: {
    marginTop: 4,
    fontWeight: 700,
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    }
  },
  textPaymentMain: {
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  textPaymentDisabled: {
    color: theme.palette.action.disabledBackground
  },
  iconCadeado: {
    position: 'absolute',
    right: 0,
    top: 2,
    '& svg': {
      padding: 0,
      margin: 0
    }
  },
}));

