import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormPromocaoNome, PromocaoNomeFormModel } from './components/form-promocao-nome';
import { usePromocaoDadosStore } from '../../hooks/use-promocao-dados-store';

interface PromocaoNomeStepProps {
  submit: () => void;
}

export interface RefPropPromocaoNomeStep {
  submit: () => void;
}

export const PromocaoNomeStep = forwardRef<
  RefPropPromocaoNomeStep,
  PromocaoNomeStepProps
>((props: PromocaoNomeStepProps, ref) => {
  const refCadastroNomePromocaoRef =
    useRef<DefaultFormRefs<PromocaoNomeFormModel>>(null);

  const promocao = usePromocaoDadosStore((state) => state.state.promocao);
  const setPromocaoStep1 = usePromocaoDadosStore((state) => state.setPromocaoStep1);

  const recarregarForm = useCallback((model: PromocaoNomeFormModel) => {
    refCadastroNomePromocaoRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    if (promocao.nome.length > 0) {
      recarregarForm({
        nome: promocao.nome,
      });
    }
  }, [promocao.nome, recarregarForm]);

  function submit(model: PromocaoNomeFormModel) {
    setPromocaoStep1({
      nome: model.nome,
    });
    props.submit();
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await refCadastroNomePromocaoRef.current?.submitForm();
    }
  }));

  return (
    <FormPromocaoNome
      showLoading={false}
      loading={false}
      onSubmit={submit}
      ref={refCadastroNomePromocaoRef}
    />
  );
});
