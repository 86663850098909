import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { guidEmpty } from 'utils/guid-empty';
import { PromocaoRegraDetalheModel } from './promocao-regra-detalhes-model';

export class PromocaoRegraModel {
    constructor(
        public id: string = guidEmpty(),
        public promocaoId: string = guidEmpty(),
        public tipoRegra: EnumPromocaoTipoRegra | undefined = undefined,
        public valorMinimoCupom: number = 0,
        public limiteOcorrencia: number = 0,
        public ativo: boolean = true,
        public detalhes: Array<PromocaoRegraDetalheModel> = []
    ) { }
}
