import { PessoaModel } from 'model/api/gestao/pessoa';
import { CircularLoading } from 'views/components';
import { CardNaoEncontrado } from 'views/components/cards';
import { CardSelecaoVendedor } from 'views/components/cards/card-selecao-vendedor';
import { InformacaoIcon } from 'views/components/icons';

export interface VendedorGridProps {
  list: Array<PessoaModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (model: PessoaModel) => any;
  onCardChecked: (id: string) => any;
}

export const VendedorListData = (props: VendedorGridProps) => {

  const onCardSelected = (model: PessoaModel) => {
    props.onCardSelected(model);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhum vendedor encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 && props.list.map((vendedor, index) => {
        return (
          <>
            <CardSelecaoVendedor
              selected={
                props.selectedList.filter((item) => item === vendedor.id)
                  .length > 0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={vendedor}
              key={index}
              index={index}
            />
          </>
        );
      })}
    </>
  );
};
