import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { CampanhaComissaoModel } from 'model/api/gestao/comissao/comissao-model';

export function usePutComissao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putComissao = useCallback(
        (empresaId: string, comissao: CampanhaComissaoModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/comissao/${comissao.id}`,
                method: 'PUT',
                data: JSON.stringify(comissao),
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putComissao,
    };
}