import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  botaoSelecionar: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    minHeight: '30px',
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    padding: 3,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: theme.palette.common.white,
    "& strong": {
      paddingLeft: theme.spacing(1)
    },
    "& svg": {
      fill: theme.palette.text.primary,
      width: 22,
      height: 22,
    }
  },
  check: {
    "& svg": {
      fill: theme.palette.primary.main,
      marginLeft: 8,
    }
  },
  paginacao: {
    padding: 0,
    margin: 0,
  },
  content: {
    height: 0,
    overflowY: 'auto',
    flex: '1 1 auto'
  },
  acoes: {
    flex: '0 0 auto',
    padding: theme.spacing(1)
  },
}));