import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { ComissaoListSearchProps } from 'views/pages/private/cadastros/comissao/components/comissao-list/comissao-list-search-props';
import { ComissaoPesquisaModal } from 'views/pages/private/cadastros/comissao/components/comissao-pesquisa-modal/comissao-pesquisa-modal';

export const ComissoesFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            status: -1,
            termo: '',
        } as ComissaoListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ComissaoFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ComissaoFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <ComissaoPesquisaModal
            filtros={modalState.filtros}
            openned={modalState.openned}
        />
    )
}