import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const PromocaoProgressivoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 12.8125 7 C 12.261719 7.050781 11.855469 7.542969 11.90625 8.09375 C 11.957031 8.644531 12.449219 9.050781 13 9 L 19.5625 9 L 2.28125 26.28125 C 1.882813 26.679688 1.882813 27.320313 2.28125 27.71875 C 2.679688 28.117188 3.320313 28.117188 3.71875 27.71875 L 21 10.4375 L 21 17 C 20.996094 17.359375 21.183594 17.695313 21.496094 17.878906 C 21.808594 18.058594 22.191406 18.058594 22.503906 17.878906 C 22.816406 17.695313 23.003906 17.359375 23 17 L 23 7 L 13 7 C 12.96875 7 12.9375 7 12.90625 7 C 12.875 7 12.84375 7 12.8125 7 Z M 34 11 L 34 50 L 48 50 L 48 11 Z M 36 13 L 46 13 L 46 48 L 36 48 Z M 18 24 L 18 50 L 32 50 L 32 24 Z M 20 26 L 30 26 L 30 48 L 20 48 Z M 2 35 L 2 50 L 16 50 L 16 35 Z M 4 37 L 14 37 L 14 48 L 4 48 Z"></path>
            </DefaultIcon>
        </>
    );
};
