import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeMedicamentoProps } from "./form-pe-medicamento-props"
import { Controller } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus"
import { SimNaoBooleanMock } from "data/mocks/sim-nao-mock"
import { Grid } from "views/design-system"

export const FormPeMedicamento = ({ control, formState, setValue, ...props }: FormPeMedicamentoProps) => {

    const { errors } = formState
    return (
        <AccordionSaurus
            labelPrimary="Medicamento"
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="cProdANVISA"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="NUMERO"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Código da Anvisa"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cProdANVISA && errors.cProdANVISA.message)}
                                helperText={
                                    errors.cProdANVISA
                                        ? errors.cProdANVISA?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="vPMC"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                casasDecimais={4}
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="PMC"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.vPMC && errors.vPMC.message)}
                                helperText={
                                    errors.vPMC
                                        ? errors.vPMC?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="xMotivoIsencao"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="TEXTO"
                                disabled={props.loading}
                                multiline
                                minRows={2}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Motivo da Isenção da ANVISA"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.xMotivoIsencao && errors.xMotivoIsencao.message)}
                                helperText={
                                    errors.xMotivoIsencao
                                        ? errors.xMotivoIsencao?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="vendaControlada"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={SimNaoBooleanMock}
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Controlado?"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.vendaControlada && errors.vendaControlada.message)}
                                helperText={
                                    errors.vendaControlada
                                        ? errors.vendaControlada?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}