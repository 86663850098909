import { makeStyles } from '@material-ui/core';

export const useButtonFabStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[8],
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1)
  }
}));
