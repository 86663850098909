import { useGetSetores } from 'data/api/gestao/setores';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { CircularLoading } from 'views/components';
import { ButtonFab } from 'views/components/controles';
import { SetorProcessosAddIcon } from 'views/components/icons';
import { useStyles } from './components/list-styles';
import { SetoresListData } from './components/setores-list-data';
import { useSessaoAtual } from 'services/app';
import { Grid } from 'views/design-system';

export const SetoresProcessosTabs = () => {
  // rotas
  // const rotaSetores = '/setor/setores';
  const rotaEquipamentos = '/setor/equipamentos';

  const retornaAba = (path: string) => {
    return path.indexOf(rotaEquipamentos) > -1 ? 1 : 0;
  };

  // AUX
  const { location } = useHistory();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();

  // STATES
  const [queryStatusSetores, setQueryStatusSetores] = useState({
    listSetores: Array<SetoresModel>(),
  });

  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname),
  );
  const [carregandoInicial, setCarregandoInicial] = useState(false);

  const [modalEditAberto, setModalEditAberto] = useState(false);

  // chamadas API
  const { getSetores, carregando: carregandoSetores } = useGetSetores();

  // providers
  const { abrirCadastroSetores } = useCadastros();
  const { getEmpresaSelecionada } = useSessaoAtual();

  const carregando = carregandoSetores || carregandoInicial;

  // Setores
  const fillResultSetores = useCallback(
    async (listSetores: Array<SetoresModel>) => {
      setQueryStatusSetores({
        listSetores: listSetores,
      });
    }, []);

  const handleSetores = useCallback(
    async (newPage: number) => {
      try {
        const res = await getSetores(getEmpresaSelecionada()?.Id ?? '');
        if (res.erro) throw res.erro;

        fillResultSetores(res.resultado?.data.list);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getSetores, getEmpresaSelecionada, fillResultSetores, showToast],
  );

  const onCardSetorSelected = (id: string) => {
    abrirCadastroSetores(id, location.pathname, true);
  };

  const onCardSetorChecked = (id: string) => { };

  const onClickAdd = useCallback(() => {
    abrirCadastroSetores('', '', true);
  }, [abrirCadastroSetores]);

  const handleEquipamentos = useCallback(
    async (newPage: number) => {
      try {
        // const res = await getComandas(query, getEmpresaSelecionada()!.Id);
        // if (res.erro) throw res.erro;
        // fillResultComandas(res.resultado?.data);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [showToast],
  );

  // const tabChange = (index: number) => {
  //   if (index === 0) {
  //     replace(rotaSetores);
  //   } else {
  //     replace(rotaEquipamentos);
  //   }
  // };

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname]);

  useEffect(() => {
    if (!modalEditAberto) {
      if (abaSelecionada === 0) {
        handleSetores(1);
      } else {
        handleEquipamentos(1);
      }
    }
    setCarregandoInicial(false);
  }, [abaSelecionada, handleEquipamentos, handleSetores, modalEditAberto]);


  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned);
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.SetoresModal, modalEdit);

    return () => removeHandler(AppEventEnum.SetoresModal, modalEdit);
  }, [addHandler, modalEdit, removeHandler]);

  useEffect(() => {
    addHandler(AppEventEnum.EquipamentoModal, modalEdit);

    return () => removeHandler(AppEventEnum.EquipamentoModal, modalEdit);
  }, [addHandler, modalEdit, removeHandler]);

  return (
    <>
      {/* <TabsSaurus
        selectedTabIndex={abaSelecionada}
        onChange={tabChange}
        tabsLabel={[
          new TabSaurusLabel('Setores', 0),
          // new TabSaurusLabel('Equipamentos', 1),
        ]}
        tabsContent={[
          new TabSaurusContent(
            0,
            (
            */}
      <>
        <div className={classes.defaultContainer}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid container>
            <Grid item xs={12} className={classes.listContainer}>
              <SetoresListData
                carregando={carregando}
                list={queryStatusSetores.listSetores}
                onCardChecked={onCardSetorChecked}
                onCardSelected={onCardSetorSelected}
                selectedList={[]}
              ></SetoresListData>
            </Grid>
          </Grid>
        </div>
        <ButtonFab
          tooltip="Adicionar Setor"
          icon={
            <SetorProcessosAddIcon fill={'#fff'} tipo="BUTTON_FAB" />
          }
          onClick={onClickAdd}
        />
      </>
      {/* ),
      ), */}
      {/* // new TabSaurusContent(
          //     1,
          //     (
          //         <>
          //             <div className={classes.defaultContainer}>
          //                 {carregando && <CircularLoading tipo="FULLSIZED" />}
          //                 <Grid container>
          //                     <Grid item xs={12} className={classes.listContainer}>

          //                     </Grid>
          //                 </Grid>
          //             </div>
          //         </>
          //     ), */}
      {/* // ), */}
      {/* ]}
     /> */}
    </>
  );
};
