import { Box, Grid, Typography, Button } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-controlado-styles';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useTheme } from '@material-ui/core';

interface DialogControladosProps {
  openned: boolean;
  qtd: number;
  handleClickNaoInformar: () => Promise<void>;
  handleClickInformar: () => Promise<void>;
}

export const DialogControlados = ({
  openned,
  qtd,
  handleClickNaoInformar,
  handleClickInformar
}: DialogControladosProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <DialogSaurus
      icone={
        <MedicamentoIcon
          tipo="BUTTON"
          fill={`${theme.palette.secondary.main}`}
        />
      }
      colorTitulo={`${theme.palette.secondary.main}`}
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Controlados"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box
              style={{ flex: 1, justifyContent: 'center', textAlign: 'center' }}
            >
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                Identificamos que essa venda
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                possui {qtd} {`${qtd === 1 ? 'medicamento' : 'medicamentos'}`}
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                {`${qtd === 1 ? 'controlado' : 'controlados'}`}, deseja informar
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                os dados da receita?
              </Typography>
            </Box>
            <Grid container spacing={2} style={{ marginTop: 24 }}>
              <Grid item xs={6}>
                <Button fullWidth variant="outlined" onClick={async () => await handleClickNaoInformar()}>
                  <CancelarIcon tipo="BUTTON" />
                  Não informar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth variant="contained" color="primary" onClick={async () => await handleClickInformar()}>
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Informar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
