import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import { CardCarrinhoPedidoProps } from './card-carrinho-pedido-props';
import { useStyles } from './card-carrinho-pedido-styles';
import { useThemeQueries } from 'views';
import { toDecimalString } from 'utils/to-decimal';
import React from 'react';
import { useDefaultCardStyles } from '../components';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { Button, Divider, Grid, Typography } from 'views/design-system';
import { Paper } from '@material-ui/core';

const CardCarrinhoPedido = ({
  model,
  inativarProduto,
  reativarProduto,
}: CardCarrinhoPedidoProps) => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();

  const { theme, isMobile } = useThemeQueries();
  return (
    <Grid item xs={12} className={model.ativo ? '' : classes.cardinativo}>
      <Paper
        className={`${classNames('card')} ${classes.containerInternoCard}`}
      >
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
          <div className={classesCard.celulaGrid} style={{margin: 0}}>
          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
            style={{
              minWidth: isMobile ? '100px' : '250px',
              maxWidth: isMobile ? '100px' : '250px',
              color: theme.palette.primary.main,
            }}
          >
            <strong>{model.xProd}</strong>
          </Typography>
        </div>
        <div className={classesCard.celulaGridFull}>
          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
            style={{ maxWidth: isMobile ? '120px' : '100%' }}
          >
            {toDecimalString(model.qCom, 3)} {model.uCom}
          </Typography>
        </div>
        <div className={classesCard.celulaGrid} style={{ marginRight: '5px' }}>
          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
            style={{ maxWidth: isMobile ? '120px' : '100%' }}
          >
            <strong>R$ {toDecimalString((![EnumTpProduto.Combo, EnumTpProduto.ProdutoComSubItem].includes(model.tpProduto) ? model.vFinal : 0) + model.adicionais.reduce((acc, current) => acc + current.vFinal, 0), 2)}</strong>
          </Typography>
        </div>
        |
        {model.ativo ? (
          <Button
            onClick={async () => await inativarProduto(model)}
            className={classes.btnInativar}
          >
            <CloseIcon />
          </Button>
        ) : (
          <Button
            onClick={async () => await reativarProduto(model)}
            className={classes.btnRetornar}
          >
            <ReplyIcon />
          </Button>
        )}
          </div>
          {model?.adicionais?.length > 0 && (
          <>
          <Typography variant="body1" style={{fontSize: 12}}>Adicionais</Typography>
          <Divider className={classes.divider} />
        </>
        )}
        {model?.adicionais?.length > 0 && model.adicionais.map((p, index) => (
          <Grid key={index}>
            <Typography style={{fontSize: 14}}>{p.qCom}X {p.xProd}</Typography>
          </Grid>
        ))}
        </div>
      </Paper>
    </Grid>
  );
};
export default React.memo(CardCarrinhoPedido);
