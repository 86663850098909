import { useCallback } from "react";
import { useApiBase } from 'data/api/base';
import { FormRelatoriosAvancadosPost } from 'views/components/form/relatorios-avancados/form-relatorios-avancados-edit/form-relatorios-avancados-edit';
import { VariaveisAmbiente } from 'config';
import { TipoRelatorio } from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';

export function usePostRelatorioAvancado() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postRelatorioAvancado = useCallback(
    (data: FormRelatoriosAvancadosPost, modelo: TipoRelatorio) =>
      invocarApi({
        url: `/api/v1/Relatorio/${data.relatorio}/processar/${modelo}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiRelatorios
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postRelatorioAvancado,
  };
}
