import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-enviar-cupom-venda-por-sms-styles';
import { VoltarIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useRef } from 'react';
import { SMSIcon } from 'views/components/icons/sms-icon';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { useTheme } from '@material-ui/core';

interface DialogDialogeEnviarCupomVendaPorSMSProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  enviarSMS: () => void;
  numero: string;
  setNumero: (numero: string) => void;
}

export const DialogEnviarCupomSMS = ({
  openned,
  closeModal,
  enviarSMS,
  loading,
  numero,
  setNumero
}: DialogDialogeEnviarCupomVendaPorSMSProps) => {
  const classes = useStyles();
  const refInput = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Enviar por SMS"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box flex flexDirection='column'>
            <Box flex flexDirection='column'>
              <Typography className={classes.label}>
                Informe o número para envio do conteúdo:
              </Typography>
              <TextFieldSaurus
                inputRef={refInput}
                manterMascara
                fullWidth
                size="medium"
                allowSubmit={true}
                value={numero}
                placeholder="(00) 00000-0000"
                onChange={(e) => setNumero(e.target.value)}
                adornmentColor={theme.palette.text.disabled}
                tipo="TELEFONE"
              />
            </Box>
            <Grid
              container
              spacing={2}
              mt={2}
              mb={1}
            >
              <Grid item xs={4}>
                {closeModal && (
                  <Button
                    disabled={loading}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      closeModal();
                    }}
                    className="round"
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Button
                  disabled={numero.length === 0 || !enviarSMS || loading}
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={enviarSMS}
                  className="round"
                >
                  <SMSIcon tipo="BUTTON_PRIMARY" fill={'#fff'} />
                  {'Enviar cupom'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
