import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComissaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissaoById = useCallback(
        (empresaId: string, comissaoId: string) => {
          return invocarApi({
            url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/comissao/${comissaoId}`,
            method: "GET",
            enviarTokenUsuario: true,
          });
        },
        [invocarApi]
      );

    return {
        ...outrasPropriedades,
        getComissaoById,
    };
}
