import { EnumMovModelo } from 'model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovSimples } from 'services/app/hooks/mov-simples';
import { consoleDev } from 'utils/console-dev';
import { DialogEnviarCupomEmail } from 'views/components/dialog/dialog-enviar-cupom-venda-por-email/dialog-enviar-cupom-venda-por-email';

export class EnviarEmailDialogProps {
  constructor(
    public openned: boolean = false,
    public movId: string = '',
    public mod: EnumMovModelo = EnumMovModelo.ORCAMENTO,
    public emails?: string[],
  ) { }
}

export const EnviarEmailDialog = () => {
  consoleDev('EnviarEmailDialog');

  const { addHandler, removeHandler } = useEventTools();
  const { enviarCupomPorEmail, enviarCupomVendaOffline } = useMovSimples();

  const [carregando, setCarregando] = useState<boolean>(false);
  const [email, setEmail] = useState<string[]>([]);
  const [modalState, setModalState] = useState<EnviarEmailDialogProps>(
    new EnviarEmailDialogProps()
  );

  const { fecharEnviarEmailDialog } = useCadastros();
  const { showToast } = useToastSaurus();

  const juntarEmails = () => {
    return email.join(';')
  }

  useEffect(() => {
    if (modalState.emails) {
      setEmail(modalState.emails)
    }
  }, [modalState.emails])

  const modalAlterado = useCallback(({ openned, movId, mod, emails }: any) => {
    setModalState({
      openned,
      movId,
      mod,
      emails,
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.EnviarEmailDialog, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.EnviarEmailDialog, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  const handleClickEnviarComprovantePorEmail = async () => {
    try {
      setCarregando(true);

      const emailAEnviar = juntarEmails()

      if (
        modalState.mod === EnumMovModelo.NFCE ||
        modalState.mod === EnumMovModelo.NFE
      ) {
        await enviarCupomPorEmail(modalState.movId, emailAEnviar);
      } else {
        await enviarCupomVendaOffline(modalState.movId, emailAEnviar);
      }
      showToast('success', `Cupom enviado para ${(email ?? []).length > 1 ? 'os E-Mails: ' : ' o E-mail: '}${email}`);
      setEmail([]);
      fecharEnviarEmailDialog();
    } catch (err: any) {
      showToast('error', err.message);
    } finally {
      setCarregando(false)
    }
  };

  return (
    <>
      {modalState.openned && (
        <DialogEnviarCupomEmail
          loading={carregando}
          openned={modalState.openned}
          closeModal={fecharEnviarEmailDialog}
          email={email}
          setEmail={setEmail}
          enviarEmail={async () => {
            await handleClickEnviarComprovantePorEmail();
          }}
        />
      )}
    </>
  );
};
