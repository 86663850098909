import classNames from 'classnames';
import { ProdutoListRevisao } from './components/produtos-list-revisao/produto-list-revisao';
import { useStyles } from './styles';
import { EnumAbaEdicaoComissao } from '../../../../comissao-edicao/comissao-edicao';

interface RevisaoProdutosProps {
  getProdutos: () => Promise<void>;
  comissaoId: string;
  isTabProduto: boolean;
  selecionarAba: (aba: EnumAbaEdicaoComissao) => void;
  veioDoCadastro: boolean;
}

export const RevisaoProdutos = ({ ...props }: RevisaoProdutosProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classNames(classes.listRevisao)}>
        <ProdutoListRevisao {...props} />
      </div>
    </div>
  );
};
