import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useEffect, useRef } from 'react';
import { CargaRecarregarDadosModel } from 'model/api/gestao/carga/carga-recarregar-dados-model';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormRecarregarDadosValidation } from './dialog-recarregar-dados-validation';
import { usePostCargaRecarregarDados } from 'data/api/gestao/carga/post-carga-recarregar-dados';
import { useThemeQueries } from 'views/theme';
import { Box, Button, Grid, Typography } from 'views/design-system';

interface DialogRecarregarDadosProps {
  openned: boolean;
  closeModal: () => void;
}

export const DialogRecarregarDados = ({
  openned,
  closeModal
}: DialogRecarregarDadosProps) => {

  // STATES & REFS
  const refInputSenha = useRef<HTMLInputElement>(null);

  // AUX
  const { showToast } = useToastSaurus()
  const { isMobile } = useThemeQueries()

  // HOOKS & REQS
  const { postCargaRecarregarDados, carregando } = usePostCargaRecarregarDados()
  const { FormRecarregarDadosYupValidation } = useFormRecarregarDadosValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset
  } = useForm<CargaRecarregarDadosModel>({
    resolver: yupResolver(FormRecarregarDadosYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  useEffect(() => {
    if (!isMobile) refInputSenha.current?.focus();
  }, [isMobile])

  const handleRecarregarDados = async (dados: CargaRecarregarDadosModel) => {
    try {
      const res = await postCargaRecarregarDados(dados)
      if (res.erro) throw res.erro

      showToast('success', 'Dados Recarregados com sucesso!')
      closeModal()
    } catch (error: any) {
      showToast('error', error.message)
      reset({ senhaTecnica: '' })
    }
  }

  return (
    <DialogSaurus
      aberto={openned}
      handleClickClose={closeModal}
      isButtonTitleClose
      tamanho="xs"
      titulo="Sincronização com PDVs"
    >
      <Box p={1} >
        <form onSubmit={handleSubmit(handleRecarregarDados)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Preencha a senha técnica e confirme para poder recarregar seus dados.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Controller
                  name="senhaTecnica"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      inputRef={refInputSenha}
                      tipo="PASSWORD"
                      disabled={carregando}
                      allowSubmit
                      label="Senha Técnica"
                      variant="outlined"
                      error={Boolean(
                        errors.senhaTecnica && errors.senhaTecnica.message,
                      )}
                      helperText={
                        touchedFields.senhaTecnica || errors.senhaTecnica
                          ? errors.senhaTecnica?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth disabled={carregando} type="submit" variant="contained" color="primary">
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={closeModal} fullWidth disabled={carregando} variant="outlined" color="primary">
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DialogSaurus >
  );
};