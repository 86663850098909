import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { ComissionadosEdicaoProps } from './comissionados-edicao-props';
import { ComissionadoGerente } from './components/comissionado-gerente';
import { ComissionadoGerenteGeral } from './components/comissionado-gerente-geral';
import { ComissionadoVendedor } from './components/comissionado-vendedor';
import { useComissionados } from './hooks/comissionados';

export const ComissionadosEdicao = ({
  tpComissionado,
  comissaoId,
  ...props
}: ComissionadosEdicaoProps) => {
  const { carregando, getComissionados, handleRemoverComissionado } =
    useComissionados({ comissaoId });

  const returnTipoPessoaComissionada = () => {
    const obj = {
      [EnumTpPessoaComissionamento.GerenteGeral]: (
        <ComissionadoGerenteGeral
          {...{
            carregando,
            comissaoId,
            getComissionados,
            handleRemoverComissionado
          }}
        />
      ),
      [EnumTpPessoaComissionamento.Gerente]: (
        <ComissionadoGerente
          {...{
            carregando,
            comissaoId,
            getComissionados,
            handleRemoverComissionado
          }}
        />
      ),
      [EnumTpPessoaComissionamento.Vendedor]: (
        <ComissionadoVendedor
          {...{
            carregando,
            comissaoId,
            getComissionados,
            handleRemoverComissionado
          }}
        />
      )
    };
    return obj[tpComissionado];
  };

  return returnTipoPessoaComissionada();
};
