import { Box, Typography } from "views/design-system";
import { useStyle } from "./card-filtro-styles"
import React from "react";
import { AvancarIcon, FecharIcon } from "views/components/icons";
import { isEmpty, isEqual } from "lodash";
import { useThemeQueries } from "views/theme";

interface CardFiltroProps<T, X> {
    nome: string;
    propName: string | string[];
    icon: React.ReactNode;
    setState: React.Dispatch<React.SetStateAction<T>>;
    setTela: React.Dispatch<React.SetStateAction<X>>;
    telaName: string;
    valorPadrao: any | any[];
    value?: string;
    valorAtual?: any | any[];
}

export const CardFiltro = <T, X>({
    icon,
    nome,
    value,
    propName,
    setState,
    setTela,
    telaName,
    valorPadrao,
    valorAtual
}: CardFiltroProps<T, X>) => {
    const classes = useStyle();
    const { theme } = useThemeQueries()
    return (
        <Box className={classes.item} onClick={() => setTela(prev => ({
            ...prev,
            [telaName]: true
        }))}>
            <Box>
                {icon}
                <Typography className={classes.labelTexto} >{nome}</Typography>
            </Box>
            <Box>
                {!isEmpty(value) && (
                    <Typography className={classes.valorTexto}>{value}</Typography>
                )}
                {!value || (isEqual(valorAtual, valorPadrao)) ? <AvancarIcon fill={theme.palette.primary.main} /> : <Box style={{ height: 25 }} onClick={(e) => {
                    e.stopPropagation()
                    setState(prev => {
                        if (typeof propName === 'string') {
                            return {
                                ...prev,
                                [propName]: valorPadrao
                            }
                        }
                        const obj = propName.reduce<T>((prev, curr, i) => {
                            return {
                                ...prev,
                                [curr]: valorPadrao[i]
                            }
                        }, prev)

                        return {
                            ...obj
                        }
                    })
                }}>
                    <FecharIcon fill={theme.palette.primary.main} />
                </Box>}
            </Box>
        </Box>
    )
}
