export enum EnumMovModelo {
    NOTAMANUAL = 1,
    ORCAMENTO = 10,
    NFE = 55,
    CTe = 57,
    MDFe = 58,
    SAT = 59,
    NFCE = 65,
    CTeOS = 67,
    SATPDV = 70,
    ENTRADA = 80,
    TROCA = 90,
    DELIVERY = 998,
    PEDIDO = 999
}