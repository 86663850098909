import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { RalatoriosAvancadosEdit } from './components/relatorios-avancados-edit/relatorios-avancados-edit';
import { RelatoriosAvancadosModel } from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';

interface RelatoriosAvancadosModalProps extends ModalProps{
  openned: boolean;
  relatorio: RelatoriosAvancadosModel;
}

export const RalatoriosAvancadosModal = (props: RelatoriosAvancadosModalProps) => { 
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && <RalatoriosAvancadosEdit relatorio={props.relatorio} />}
    </DefaultModal>
  );
};
