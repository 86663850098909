import {
  Comissionado,
  useComissionadosStore
} from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { useStyles } from './preview-gerentes-styles';
import { useConfirm } from 'material-ui-confirm';
import { AddIcon } from 'views/components/icons/add-icon';
import { useCallback, useState } from 'react';
import { Button, Grid } from 'views/design-system';
import CardPessoaPreview from 'views/components/cards/card-pessoa-preview/card-pessoa-preview';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { CardNaoEncontrado } from 'views/components/cards';
import {
  DesfazerIcon,
  InformacaoIcon,
  LixoIcon,
  NovoIcon,
  VoltarIcon
} from 'views/components/icons';
import { ButtonFab } from 'views/components/controles';
import { useThemeQueries } from 'views/theme';
import { Paginacao } from 'views/components/paginacao';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { RadioSelecionadoTodosIcon } from 'views/components/icons/radio-selecionado-todos-icon';
import classNames from 'classnames';

interface PreviewGerentesProps {
  setAddGerente: () => void;
  setAddVendedor: (value: boolean) => void;
  setAlterarGerente: (value: Comissionado) => void;
  handleRemove: (value: Comissionado[]) => Promise<boolean>;
  gerenteSelecionado: Comissionado | null;
  setGerenteSelecionado: React.Dispatch<
    React.SetStateAction<Comissionado | null>
  >;
}

export const PreviewGerentes = ({
  setAddGerente,
  setAddVendedor,
  setAlterarGerente,
  handleRemove,
  gerenteSelecionado,
  setGerenteSelecionado
}: PreviewGerentesProps) => {
  const [editGerente, setEditGerente] = useState(false);
  const [selectedList, setSelectedList] = useState<Comissionado[]>([]);
  const [isModeSelect, setIsModeSelect] = useState<boolean>(false);

  const confirm = useConfirm();

  const comissionadoGerentes = useComissionadosStore(
    (store) => store.state.comissionadoGerentes
  );

  const classes = useStyles();
  const { theme } = useThemeQueries();

  const handleRemoveComissionados = (isGerente?: boolean) => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: `Excluir ${isGerente ? 'gerentes' : 'vendedores'}`,
      description: (
        <div>
          Deseja excluir os {isGerente ? 'gerentes' : 'vendedores'}?
          {((gerenteSelecionado && gerenteSelecionado?.vendedores?.length) ??
            0) -
            selectedList.length ===
            0 && (
              <p>
                Ao remover todos os vendedores vinculados ao gerente (
                <strong style={{ wordBreak: 'break-word' }}>
                  {gerenteSelecionado?.nome ?? ''}
                </strong>
                ), ele será removido também.
              </p>
            )}
        </div>
      ),
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(async () => {
      const res = await handleRemove(selectedList);
      if (res) {
        setGerenteSelecionado(null);
        setEditGerente(false);
        setIsModeSelect(false);
        if (comissionadoGerentes.length - 1 <= 0) {
          setAddGerente();
        }
        if (gerenteSelecionado) {
          setGerenteSelecionado((prev) => {
            if (prev) {
              const vendedores =
                prev.vendedores?.filter(
                  (x) =>
                    !selectedList
                      .map((x) => x.internalId)
                      .includes(x.internalId)
                ) ?? [];

              if (vendedores.length <= 0) {
                setEditGerente(false);
                setGerenteSelecionado(null);
              }

              return {
                ...prev,
                vendedores
              };
            }
            return null;
          });
        }
      }
    });
  };

  const onCardSelected = useCallback((comissionado: Comissionado) => {
    setSelectedList((prev) => {
      if (prev.some((x) => x.id === comissionado.id)) {
        return prev.filter((x) => x.id !== comissionado.id);
      }

      return [...prev, comissionado];
    });
  }, []);

  const onCardSelecionaGerente = (comissionado: Comissionado) => {
    setEditGerente(true);
    setGerenteSelecionado(comissionado);
  };

  const renderTopContent = useCallback(
    (comissionados: Comissionado[], isGerente?: boolean) => {
      const todosSelecionados =
        comissionados.findIndex(
          (x) => selectedList.findIndex((y) => y.id === x.id) === -1
        ) === -1;
      return (
        <Grid flex justifyContent="space-between" flexDirection="row">
          {comissionados.length > 0 && (
            <Grid
              onClick={() => {
                setSelectedList([]);
                setIsModeSelect((prev) => !prev);
              }}
              className={classes.botaoSelecionar}
            >
              {isModeSelect ? (
                <>
                  <DesfazerIcon tipo="BUTTON_PRIMARY" />
                  Desfazer
                </>
              ) : (
                <>
                  <RadioSelecionadoTodosIcon tipo="BUTTON_PRIMARY" />
                  Selecionar {isGerente ? 'Gerentes' : 'Vendedores'}
                </>
              )}
            </Grid>
          )}
          <Paginacao
            className={classes.paginacao}
            pageChanged={() => { }}
            totalPages={1}
            totalRegisters={comissionados.length}
            currentPage={1}
          />
          {isModeSelect && (
            <Grid
              onClick={() => {
                if (todosSelecionados) setSelectedList([]);
                else setSelectedList(comissionados);
              }}
              className={classNames(classes.botaoSelecionar, classes.check)}
              style={{ marginLeft: 8, marginRight: 0 }}
            >
              Selecionar Todos
              <CheckboxDefault
                style={{ margin: -8 }}
                checked={todosSelecionados}
              />
            </Grid>
          )}
        </Grid>
      );
    },
    [
      classes.botaoSelecionar,
      classes.check,
      classes.paginacao,
      isModeSelect,
      selectedList
    ]
  );

  const renderListGerentes = () => {
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          {renderTopContent(comissionadoGerentes, true)}

          <div className={classes.list}>
            <div className={classes.listContainer}>
              {comissionadoGerentes.length === 0 && (
                <CardNaoEncontrado
                  mensagem="Gerentes não encontrados"
                  icon={<InformacaoIcon tipo="GERAL" />}
                />
              )}
              {comissionadoGerentes.length > 0 &&
                comissionadoGerentes.map((comissionado) => {
                  return (
                    <CardPessoaPreview
                      tipo={EnumTpPessoaComissionamento.Gerente}
                      onAltera={onCardSelecionaGerente}
                      onSelect={onCardSelected}
                      model={comissionado}
                      edit={!isModeSelect}
                      selecionar={isModeSelect}
                      selecionado={selectedList.some(
                        (x) => x.internalId === comissionado.internalId
                      )}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className={classes.acoes}>
          {isModeSelect ? (
            <Button
              variant="contained"
              fullWidth
              color="error"
              disabled={selectedList.length <= 0}
              onClick={() => {
                handleRemoveComissionados();
              }}
            >
              <LixoIcon tipo="BUTTON_PRIMARY" />
              Remover vendedores
            </Button>
          ) : (
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={() => {
                setAddGerente();
              }}
            >
              <AddIcon tipo="BUTTON" />
              Adicionar gerente
            </Button>
          )}
        </div>
      </div>
    );
  };

  const onCardCheckedAlterarGerente = (pessoa: Comissionado) => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: 'Alterar gerente',
      description: (
        <p>
          Deseja alterar o gerente (
          <strong style={{ wordBreak: 'break-word' }}>{pessoa.nome}</strong>) ?
        </p>
      ),
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(() => {
      if (gerenteSelecionado) {
        setAlterarGerente(pessoa);
      }
    });
  };

  const onCardAlteraVendedor = (comissionado: Comissionado) => { };

  const handleVoltar = () => {
    setEditGerente(false);
    setGerenteSelecionado(null);
  };

  const renderEditGerentes = () => {
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.header}>
            <div>
              <p className={classes.title}>Gerente</p>
            </div>
          </div>

          {gerenteSelecionado && (
            <div className={classes.listContainer}>
              <CardPessoaPreview
                tipo={EnumTpPessoaComissionamento.Gerente}
                onAltera={onCardCheckedAlterarGerente}
                onSelect={() => { }}
                model={gerenteSelecionado}
                alterar
              />
            </div>
          )}

          <p className={classes.title} style={{ margin: '16px 0 8px' }}>
            Vendedores
          </p>
          {renderTopContent(gerenteSelecionado?.vendedores ?? [])}
          {gerenteSelecionado && (
            <div className={classes.listVendedores}>
              <div className={classes.listContainer}>
                {(gerenteSelecionado?.vendedores ?? []).map((comissionado) => {
                  return (
                    <CardPessoaPreview
                      tipo={EnumTpPessoaComissionamento.Vendedor}
                      onAltera={onCardAlteraVendedor}
                      model={comissionado}
                      onSelect={onCardSelected}
                      selecionar={isModeSelect}
                      selecionado={selectedList.some(
                        (x) => x.id === comissionado.id
                      )}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.acoes}>
          {isModeSelect ? (
            <Button
              variant="contained"
              fullWidth
              color="error"
              disabled={selectedList.length <= 0}
              onClick={() => {
                handleRemoveComissionados();
              }}
            >
              <LixoIcon tipo="BUTTON_PRIMARY" />
              Remover vendedores
            </Button>
          ) : (
            <>
              <ButtonFab
                onClick={() => {
                  if (gerenteSelecionado) {
                    setAddVendedor(true);
                  }
                }}
                icon={<NovoIcon tipo="BUTTON_FAB" />}
                tooltip="Novo Vendedor"
                spacingBottom={theme.spacing(1.5)}
              />
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                size="large"
                style={{ marginTop: 16 }}
                onClick={handleVoltar}
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  return editGerente ? renderEditGerentes() : renderListGerentes();
};
