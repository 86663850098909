import { Grid } from "@material-ui/core";
import { TesteMaterialTsx } from "./teste-material"
import { TesteSystemTsx } from "./teste-system"

const TestePage = () => {
    return <>
        <Grid container>
            <Grid item xs={6}>
                <TesteMaterialTsx></TesteMaterialTsx>
            </Grid>
            <Grid item xs={6}>
                <TesteSystemTsx></TesteSystemTsx>
            </Grid>
        </Grid>
    </>
}

export default TestePage;
