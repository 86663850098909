import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {

    return {
        divider: {
            backgroundColor: theme.palette.primary.main,
            height: 2,
        },
        negrito: {
            fontWeight: 600
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
            whiteSpace: 'nowrap'
        },
        container: {
            minHeight: 70
        }
    }
})