import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        width: '40px',
        height: '40px',
        marginBottom: -2,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    },
    termosDeUso: {
        width: '100%',
        color: theme.palette.text.primary,
        fontSize: '1rem',
        textAlign: 'justify',
        overflowY: 'auto',
        maxHeight: '60%',
        '& table':{
            width:'100%'
        },
        '& td':{
            padding:theme.spacing(1, 2),
        }
    },
    localizacaoGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    localizacaoGridSvg: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(10),
        "& svg": {
            fill: theme.palette.primary.main,
            width: '250px',
        }
    },
    contentCenter: {
        display:'flex',
        justifyContent: 'center',
        maxHeight:'100%',
    
    },
    titleSpace:{
        maxHeight: '20%'
    },
    buttonSpace:{
        maxHeight: '20%'
    },
    overflowHidden: {
        overflow: 'hidden',
    }
}))