import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import CardCarrinhoGrupoProdutos from 'views/components/cards/card-carrinho-grupo-produtos/card-carrinho-grupo-produtos';
import CardCarrinho from 'views/components/cards/card-carrinho/card-carrinho';
import { useStyles } from './carrinho-list-data-styles';
import classNames from 'classnames';
import { useAgruparProdutos } from 'services/app/hooks/agrupar-produtos';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { Divider, Grid, Typography } from 'views/design-system';
import { CarrinhoVazio } from '../carrinho-vazio/carrinho-vazio';

export interface CarrinhoGridProps {
  list: Array<MovSimplesProdutoModel>;
  mostrarItensCancelados: boolean;
  inativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  reativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  alterarQtdeProduto(
    model: MovSimplesProdutoModel,
    incremento: number,
    fator: 'sub' | 'add'
  ): Promise<void>;
  removerPedidoDoCarrinho(idPedido: string): Promise<void>;
  setProductEdit: (produtoId: string) => void;
}
export interface CarrinhoListPedido {
  pedidoId: string;
  comandaId: string;
  mesaId: string;
  codigoPedido: string;
  identificador: string;
  produtos: MovSimplesProdutoModel[];
}
export const CarrinhoListData = ({
  list,
  mostrarItensCancelados,
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  removerPedidoDoCarrinho,
  setProductEdit
}: CarrinhoGridProps) => {
  // STATES E REFS
  const [visibleItems, setVisibleItems] = useState(15);
  const divRef = useRef<HTMLDivElement>(null);

  // HOOKS
  const { agruparProdutosPorAdicionais, agruparProdutosPorPedido } =
    useAgruparProdutos(list ?? []);
  const { getConfigByCod } = useContratoAtual();
  const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico);

  // AUX
  const classes = useStyles();

  const pedidos = agruparProdutosPorPedido();
  const produtosComAdicionais = agruparProdutosPorAdicionais();
  const produtosCarrinho = list.filter((produto) => {
    if (!(prodTaxaServicoId === produto.produtoId)) {
      if (!mostrarItensCancelados) {
        return produto.ativo;
      } else {
        return produto;
      }
    }
    return false;
  });

  const handleScroll = useCallback(() => {
    if (divRef.current && divRef.current.scrollTop + divRef.current.clientHeight + 10 >= divRef.current.scrollHeight) {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
    }
  }, [])

  useEffect(() => {
    const div = divRef.current;
    const idTimeaout = setTimeout(() => {
      div?.addEventListener('scroll', handleScroll);
    }, 1350);


    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
      clearTimeout(idTimeaout);
    };
  }, [handleScroll]);


  return (
    <>
      {produtosCarrinho.length === 0 ? (
        <CarrinhoVazio />
      ) : (
        <Grid
          item
          xs={12}
          className={classNames(
            classes.listContainer
          )}
          ref={divRef}
        >

          {/* Listagem de produtos por pedidos */}
          {pedidos.length > 0 ? (
            pedidos.map((item) => {
              return (
                <CardCarrinhoGrupoProdutos
                  inativarProduto={inativarProduto}
                  reativarProduto={reativarProduto}
                  model={item}
                  key={item.pedidoId}
                  mostrarItensCancelados={mostrarItensCancelados}
                  removerPedidoDoCarrinho={removerPedidoDoCarrinho}
                />
              );
            })
          ) : (
            <></>
          )}

          {/* Listagem de produtos de venda sem pedido atrelado */}
          {produtosComAdicionais.length > 0 ? (
            produtosComAdicionais
              .sort((a, b) => b.nSeq - a.nSeq)
              .slice(0, visibleItems)
              .map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      display: !mostrarItensCancelados && !item.ativo ? 'none' : 'block'
                    }}
                  >
                    {(index === 0 ||
                      produtosComAdicionais[index - 1].vendedorId !==
                      item.vendedorId) && (
                        <div className={classes.vendedorContent}>
                          <Typography className={classes.vendedorText}>
                            Vendedor: <b>{item.vendedorNome}</b>
                          </Typography>
                          <Divider className={classes.vendedorDivider} />
                        </div>
                      )}
                    <CardCarrinho
                      inativarProduto={inativarProduto}
                      reativarProduto={reativarProduto}
                      alterarQtdeProduto={alterarQtdeProduto}
                      key={item.id}
                      model={item}
                      setProductEdit={setProductEdit}
                    />
                  </div>
                );
              })
          ) : (
            <></>
          )}
        </Grid>
      )}
    </>
  );
};