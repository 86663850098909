import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '112px',
    background: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '2px 2px 4px 0px #00000014',
    borderRadius: '8px',
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  label: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  }
}));
