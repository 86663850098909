
import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const MenuCirculadoFilledIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M11.6667 7.5C9.365 7.5 7.5 9.365 7.5 11.6667C7.5 13.9683 9.365 15.8333 11.6667 15.8333C13.9683 15.8333 15.8333 13.9683 15.8333 11.6667C15.8333 9.365 13.9683 7.5 11.6667 7.5ZM25 7.5C22.6983 7.5 20.8333 9.365 20.8333 11.6667C20.8333 13.9683 22.6983 15.8333 25 15.8333C27.3017 15.8333 29.1667 13.9683 29.1667 11.6667C29.1667 9.365 27.3017 7.5 25 7.5ZM38.3333 7.5C36.0317 7.5 34.1667 9.365 34.1667 11.6667C34.1667 13.9683 36.0317 15.8333 38.3333 15.8333C40.635 15.8333 42.5 13.9683 42.5 11.6667C42.5 9.365 40.635 7.5 38.3333 7.5ZM11.6667 20.8333C9.365 20.8333 7.5 22.6983 7.5 25C7.5 27.3017 9.365 29.1667 11.6667 29.1667C13.9683 29.1667 15.8333 27.3017 15.8333 25C15.8333 22.6983 13.9683 20.8333 11.6667 20.8333ZM25 20.8333C22.6983 20.8333 20.8333 22.6983 20.8333 25C20.8333 27.3017 22.6983 29.1667 25 29.1667C27.3017 29.1667 29.1667 27.3017 29.1667 25C29.1667 22.6983 27.3017 20.8333 25 20.8333ZM38.3333 20.8333C36.0317 20.8333 34.1667 22.6983 34.1667 25C34.1667 27.3017 36.0317 29.1667 38.3333 29.1667C40.635 29.1667 42.5 27.3017 42.5 25C42.5 22.6983 40.635 20.8333 38.3333 20.8333ZM11.6667 34.1667C9.365 34.1667 7.5 36.0317 7.5 38.3333C7.5 40.635 9.365 42.5 11.6667 42.5C13.9683 42.5 15.8333 40.635 15.8333 38.3333C15.8333 36.0317 13.9683 34.1667 11.6667 34.1667ZM25 34.1667C22.6983 34.1667 20.8333 36.0317 20.8333 38.3333C20.8333 40.635 22.6983 42.5 25 42.5C27.3017 42.5 29.1667 40.635 29.1667 38.3333C29.1667 36.0317 27.3017 34.1667 25 34.1667ZM38.3333 34.1667C36.0317 34.1667 34.1667 36.0317 34.1667 38.3333C34.1667 40.635 36.0317 42.5 38.3333 42.5C40.635 42.5 42.5 40.635 42.5 38.3333C42.5 36.0317 40.635 34.1667 38.3333 34.1667Z" />
            </DefaultIcon>
        </>
    );
};