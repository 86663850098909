import { Box, Button } from "@material-ui/core"
import { CalendarioIcon, EditarIcon, FiltroIcon, OkIcon, TipoMovimentacaoIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { useStyles } from './historico-venda-filtro-modal-styles'
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { HistoricoVendaListSearchProps } from "views/pages/private/historico-vendas/components/historico-venda-tabs/components/historico-venda-list-search-props";
import { StatusHistoricoVendaMockTodos } from "data/mocks/status-historico-venda-mock";
import { FormPersonalizado } from "views/components/form/form-personalizado/form-personalizado";
import { toDateString } from "utils/to-date";
import { isEmpty, isEqual } from "lodash";
import { FormPesquisaGenerico } from "views/components/form/pesquisa-generica/form-pesquisa-generico";
import { useSessaoAtual } from "services/app";
import { KeyValueModel } from "model";
import { guidEmpty } from "utils/guid-empty";
import { UltimaVendaIcon } from "views/components/icons/ultima-venda-icon";
import { MovModeloTodosMock } from "data/mocks/mov-modelo-mock";

interface DatasForm {
    dInicial: string;
    dFinal: string;
}

interface EmpresasFiltroForm {
    empresa: string;
}

interface NNFIntervaloForm {
    nnfInicial: string;
    nnfFinal: string;
}

interface ITelaState {
    status: boolean;
    serie: boolean;
    datas: boolean;
    empresa: boolean;
    nnf: boolean;
    tpMov: boolean;
    nnfIntervalo: boolean

}

interface HistoricoVendaFiltroModalProps {
    openned: boolean;
    filtros: HistoricoVendaListSearchProps;
}
export const HistoricoVendaFiltroModal = ({
    openned,
    filtros
}: HistoricoVendaFiltroModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { fecharHistoricoVendaFiltroModal } = useFiltrosModais();
    const history = useHistory();
    const { usuario } = useSessaoAtual();

    const EmpresaListMock = [
        ...usuario!.empresa.map((item) => {
            return new KeyValueModel(item.Id, item.Descricao);
        }),
        new KeyValueModel(guidEmpty(), 'Todas')
    ]

    const [telas, setTelas] = useState<ITelaState>({
        status: false,
        datas: false,
        empresa: false,
        serie: false,
        nnf: false,
        tpMov: false,
        nnfIntervalo: false
    })
    const [localProps, setLocalProps] = useState<HistoricoVendaListSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (Object.values(telas).some(x => x)) {
            setTelas(() => ({
                status: false,
                datas: false,
                empresa: false,
                serie: false,
                nnf: false,
                tpMov: false,
                nnfIntervalo: false,
            }))
            return
        }
        fecharHistoricoVendaFiltroModal(false)
    }, [fecharHistoricoVendaFiltroModal, telas])

    const onSubmit = useCallback((localProps: HistoricoVendaListSearchProps) => {
        const searchs: Array<string | null> = [
            `status=${localProps.status}`,
            localProps.serie ? `serie=${localProps.serie}` : null,
            localProps.dInicial.length > 0 ? `dInicial=${localProps.dInicial}` : null,
            localProps.dFinal.length > 0 ? `dFinal=${localProps.dFinal}` : null,
            localProps.empresa !== guidEmpty() && localProps.empresa.length > 0 ? `empresa=${localProps.empresa}` : null,
            localProps.nnf ? `nnf=${localProps.nnf}` : null,
            localProps.nnfInicial.length > 0 ? `nnfInicial=${localProps.nnfInicial}` : null,
            localProps.nnfFinal.length > 0 ? `nnfFinal=${localProps.nnfFinal}` : null,
        ]
        localProps.tpMov.forEach((tp) => {
            searchs.push(`tpMov=${tp}`)
        })

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        const urlNaoSincronizados = history.location.pathname.indexOf('/nao-sincronizadas') > 1 ? '/nao-sincronizadas' : ''

        history.push({
            pathname: history.location.pathname.indexOf('/venda-simples/') > -1 ? `/venda-simples/historico${urlNaoSincronizados}` : `/historico${urlNaoSincronizados}`,
            search: query
        })
        fecharHistoricoVendaFiltroModal(true)
    }, [fecharHistoricoVendaFiltroModal, history])

    const retornarStringData = () => {
        if (isEmpty(localProps.dInicial) && !isEmpty(localProps.dFinal)) {
            return toDateString(localProps.dFinal, 'DD/MM')
        }
        if (!isEmpty(localProps.dInicial) && isEmpty(localProps.dFinal)) {
            return toDateString(localProps.dInicial, 'DD/MM')
        }
        if (!isEmpty(localProps.dInicial) && !isEmpty(localProps.dFinal)) {
            return `${toDateString(localProps.dInicial, 'DD/MM')} - ${toDateString(localProps.dFinal, 'DD/MM')}`
        }
        return ''
    }

    const labels = localProps.tpMov.map(t => MovModeloTodosMock.find(item => item.Key === t)?.Value || '').join(', ');

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    {/*______________CARDS______________*/}
                    <Box className={classNames(classes.container)}>
                        <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<CalendarioIcon />}
                            nome="Data"
                            propName={['dInicial', 'dFinal']}
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="datas"
                            value={retornarStringData()}
                            valorPadrao={['', '']}
                            valorAtual={[localProps.dInicial, localProps.dFinal]}
                        />
                        <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<FiltroIcon />}
                            nome="Situação da Venda"
                            propName="status"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="status"
                            value={StatusHistoricoVendaMockTodos.find(x => x.Key === localProps.status)?.Value || ''}
                            valorPadrao={-1}
                            valorAtual={localProps.status}
                        />
                        <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<EditarIcon />}
                            nome="Número de Série"
                            propName="serie"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="serie"
                            value={localProps.serie}
                            valorPadrao={''}
                            valorAtual={localProps.serie}
                        />
                        <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<UltimaVendaIcon />}
                            nome="Número da Venda"
                            propName="nnf"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="nnf"
                            value={localProps.nnf}
                            valorPadrao={''}
                            valorAtual={localProps.nnf}
                        />
                        {/* <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<EmpresaIcon />}
                            nome="Empresa"
                            propName="empresa"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="empresa"
                            value={EmpresaListMock.find(x => x.Key === localProps.empresa)?.Value || ''}
                            valorPadrao={guidEmpty()}
                            valorAtual={localProps.empresa}
                        /> */}
                        <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<TipoMovimentacaoIcon />}
                            nome="Movimentação"
                            propName="tpMov"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="tpMov"
                            value={labels}
                            valorPadrao={[-1]}
                            valorAtual={localProps.tpMov}
                        />
                        <CardFiltro<HistoricoVendaListSearchProps, ITelaState>
                            icon={<UltimaVendaIcon />}
                            nome="Número Venda com Intervalo"
                            propName={['nnfInicial', 'nnfFinal']}
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="nnfIntervalo"
                            value={
                                [localProps.nnfInicial, localProps.nnfFinal].some(x => x)
                                    ? `${localProps.nnfInicial} - ${localProps.nnfFinal}`
                                    : ''
                            }
                            valorPadrao={['', '']}
                            valorAtual={[localProps.nnfInicial, localProps.nnfFinal]}
                        />

                        {/*______________BOTÕES______________*/}
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => onSubmit({
                                dFinal: '',
                                dInicial: '',
                                empresa: '',
                                status: -1,
                                serie: '',
                                nnf: '',
                                tpMov: [-1],
                                nnfFinal: '',
                                nnfInicial: '',
                            })}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>

                        {/*______________TELAS______________*/}
                        {telas.status && (
                            <RadioList
                                handleSubmit={async (status) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        status: status
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        status: false
                                    }))
                                }}
                                selecionado={localProps.status}
                                mock={StatusHistoricoVendaMockTodos}
                            />
                        )}
                        {telas.datas && (
                            <FormPersonalizado<DatasForm>
                                campos={[
                                    {
                                        propName: 'dInicial',
                                        grid: {
                                            md: 6,
                                            xs: 6
                                        },
                                        label: 'Data Inicial',
                                        tipoTextfield: 'DATA',
                                        tipo: 'TEXTFIELD',
                                    },
                                    {
                                        propName: 'dFinal',
                                        grid: {
                                            md: 6,
                                            xs: 6
                                        },
                                        label: 'Data Final',
                                        tipoTextfield: 'DATA',
                                        tipo: 'TEXTFIELD',
                                    },
                                ]}
                                model={{
                                    dInicial: localProps.dInicial,
                                    dFinal: localProps.dFinal
                                }}
                                onSubmit={model => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        dInicial: model.dInicial,
                                        dFinal: model.dFinal
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        datas: false
                                    }))
                                }}
                            />
                        )}
                        {telas.serie && (
                            <FormPesquisaGenerico
                                onSubmit={(model) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        serie: model.generico
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        serie: false
                                    }))
                                }}
                                text={localProps.serie}
                                label={'Número de Série'}
                                placeholder='Ex: 103'
                            />
                        )}
                        {telas.empresa && (
                            <FormPersonalizado<EmpresasFiltroForm>
                                campos={[
                                    {
                                        propName: 'empresa',
                                        label: 'Empresa',
                                        tipo: 'SELECT',
                                        conteudo: EmpresaListMock,
                                    },
                                ]}
                                model={{
                                    empresa: localProps.empresa,
                                }}
                                onSubmit={model => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        empresa: model.empresa
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        empresa: false
                                    }))
                                }}
                            />
                        )}
                        {telas.nnf && (
                            <FormPesquisaGenerico
                                onSubmit={(model) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        nnf: model.generico
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        nnf: false
                                    }))
                                }}
                                text={localProps.nnf}
                                label={'Número da Venda'}
                                placeholder='Ex: 10'
                            />
                        )}
                        {telas.tpMov && (
                            <RadioList
                                handleSubmit={async (tp) => { }}
                                handleSubmitMultiCheck={(select: any) => {
                                    if (select) {
                                        const arrOriginal = MovModeloTodosMock.filter(x => x.Key !== -1).map(x => x.Key);
                                        if (
                                            select.includes(-1) ||
                                            isEqual(arrOriginal, select)
                                        ) {
                                            select = [-1];
                                        }
                                        setLocalProps(prev => ({
                                            ...prev,
                                            tpMov: select
                                        }))
                                    }
                                    setTelas(prev => ({
                                        ...prev,
                                        tpMov: false
                                    }))
                                }}
                                selecionado={localProps.tpMov}
                                mock={MovModeloTodosMock}
                                multiCheck
                            />
                        )}
                        {telas.nnfIntervalo && (
                            <FormPersonalizado<NNFIntervaloForm>
                                campos={[
                                    {
                                        propName: 'nnfInicial',
                                        label: 'Número da Venda Inicial',
                                        tipo: 'TEXTFIELD',
                                        required: true,
                                        grid: { xs: 6, md: 6 },
                                        tipoTextfield: 'NUMERO',
                                    },
                                    {
                                        propName: 'nnfFinal',
                                        label: 'Número da Venda Final',
                                        tipo: 'TEXTFIELD',
                                        grid: { xs: 6, md: 6 },
                                        required: true,
                                        tipoTextfield: 'NUMERO',
                                    },
                                ]}
                                model={{
                                    nnfInicial: localProps.nnfInicial,
                                    nnfFinal: localProps.nnfFinal,
                                }}
                                onSubmit={model => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        nnfInicial: model.nnfInicial.toString(),
                                        nnfFinal: model.nnfFinal.toString(),
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        nnfIntervalo: false
                                    }))
                                }}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal >
    )
}