import { EnumMovModelo, KeyValueModel } from "model";

export const TpHistoricoVendaMock: KeyValueModel[] = [
    new KeyValueModel(EnumMovModelo.ENTRADA, "Entrada"),
    new KeyValueModel(EnumMovModelo.NFCE, "NFC-e"),
    new KeyValueModel(EnumMovModelo.NFE, "NF-e"),
    new KeyValueModel(EnumMovModelo.ORCAMENTO, "Simples"),
    new KeyValueModel(EnumMovModelo.NOTAMANUAL, "Manual"),
    new KeyValueModel(EnumMovModelo.MDFe, "MDF-e"),
    new KeyValueModel(EnumMovModelo.CTe, "CT-e"),
    new KeyValueModel(EnumMovModelo.CTeOS, "CT-e OS"),
    new KeyValueModel(EnumMovModelo.SAT, "SAT"),
    new KeyValueModel(EnumMovModelo.TROCA, "Troca"),
];