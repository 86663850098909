import { guidEmpty } from "utils/guid-empty";
import { CartaoSaldoConvenioModel } from "./cartao-saldo-convenio-model";
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";
import { EnumTpAutenticacaoConvenio } from "model/enums/enum-tp-autenticacao-convenio";

export class CartaoConvenioModel {
    constructor(
        public id: string | null = null,
        public contaPessoaId: string = guidEmpty(),
        public nomeCartao: string = '',
        public numeroCartao: string = '',
        public senha: string = '',
        public limite: number = 0,
        public pessoaId: string = '',
        public status: EnumConvenioStatus = EnumConvenioStatus.Ativo,
        public saldo: CartaoSaldoConvenioModel = new CartaoSaldoConvenioModel(),
        public dataValidade: string | Date = '',
        public tipo: EnumTpAutenticacaoConvenio = EnumTpAutenticacaoConvenio.Titular,
    ) { }
}
