import { Box, Grid, Typography } from 'views/design-system';
import { NotAllowedHeader } from './components/not-allowed-header.tsx/sem-permissao-header';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useStyles } from './sem-permissao-styles';
import classNames from 'classnames';
import { TristeIcon } from 'views/components/icons/triste-icon';

const NotAllowedPage = () => {
  const classes = useDefaultCadastroStyles();
  const pageClasses = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <NotAllowedHeader />
      </Grid>
      <Grid className={classNames(classes.content, pageClasses.content)}>
        <Box className={pageClasses.svgContainer}>
          <Typography align="center" variant="h6">
            Você não possui acesso
          </Typography>
          <Typography align="center" variant="body2">
            Atenção: Entre em contato com o seu administrador e peça a liberação
            caso precise acessar este modulo.
          </Typography>
          <TristeIcon style={{ marginTop: 32 }} tipo="GERAL" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotAllowedPage;
