import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-convenio-cartao-qrcode-styles';
import { VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCadastros } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { ConvenioCartaoAutenticarModel } from 'model/api/gestao/convenio';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { QRCodeSVG } from 'qrcode.react';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { useReactToPrint } from 'react-to-print'

interface DialogConvenioAutenticarProps {
  aberto: boolean;
  model: {
    numeroCartao: string
    senha: string
    nomeCartao: string
  }
}

export const DialogConvenioCartaoQrCode = ({
  aberto,
  model
}: DialogConvenioAutenticarProps) => {
  // STATES E REFS
  const refForm = useRef<DefaultFormRefs<ConvenioCartaoAutenticarModel>>(null)
  const value = btoa(`${model.numeroCartao}|${model.senha}`)

  // HOOKS & AUX
  const { fecharConvenioCartaoQrCode } = useCadastros()
  const classesComponent = useStyles();
  const { theme } = useThemeQueries();
  const componentToPrintRef = useRef<any>(null)
  const [carregando, setCarregando] = useState<boolean>(false)
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
    onBeforePrint: () => setCarregando(true),
    onAfterPrint: () => setCarregando(false)
  })

  const onCloseClick = useCallback(() => {
    fecharConvenioCartaoQrCode();
  }, [fecharConvenioCartaoQrCode]);

  useEffect(() => {
    refForm.current?.fillForm(new ConvenioCartaoAutenticarModel())
  }, [])

  const content = useMemo(() => (
    <Grid container className={classesComponent.cardContent} spacing={2}>
      <Grid item xs={12}>
        <div className={classesComponent.contentQrCode} ref={componentToPrintRef}>
          <Box flex justifyContent="center" mb={2}>
            <div>
              <Typography className={classesComponent.title} variant="h5" align='center' color="primary">
                {model.nomeCartao}
              </Typography>
              <Typography align="center">
                Número: {formatarCPFCNPJ(model.numeroCartao)}
              </Typography>
            </div>
          </Box>
          <div className={classesComponent.ajustarTela}>
            <QRCodeSVG value={value} className={classesComponent.qrCode} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider variant='fullWidth' className={classesComponent.divider} />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handlePrint}
          variant="contained"
          color="primary"
          fullWidth
        >
          <ImpressoraIcon tipo="BUTTON_PRIMARY" />
          Imprimir QR Code
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onCloseClick}
          variant="outlined"
          color="primary"
          fullWidth
        >
          <VoltarIcon tipo="BUTTON" />
          Voltar
        </Button>
      </Grid>
    </Grid>
  ), [classesComponent.ajustarTela, classesComponent.cardContent, classesComponent.contentQrCode, classesComponent.divider, classesComponent.qrCode, classesComponent.title, handlePrint, model.nomeCartao, model.numeroCartao, onCloseClick, value])

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="QR Code do Cartão"
      tamanho="xs"
      centralizarTitulo
      colorTitulo={theme.palette.primary.main}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      {content}
    </DialogSaurus>
  );
};
