import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        position: 'relative',
        flexShrink: 0,
        verticalAlign: 'middle'
    },
    topRight: {
        top: 0,
        right: 0,
        transform: 'scale(1) translate(50%, -50%)',
        transformOrigin: '100% 0%'
    },
    badge: {
        height: '20px',
        display: 'flex',
        padding: '0 6px',
        zIndex: 1,
        position: 'absolute',
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        minWidth: '20px',
        boxSizing: 'border-box',
        transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
        lineHeight: 1,
        alignContent: 'center',
        borderRadius: '10px',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    default: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.grey[50],
    }

}));
