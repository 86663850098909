

export enum EnumTipoFieldEmpresa {
    Numero,
    Texto,
    SimNao,
    Valor,
    Invisivel,
    Bitwise
}
