import { Button, Grid, Typography } from '@material-ui/core';
import { useGetSessaoImpressao } from 'data/api/gestao/sessao/get-impressao';
import { EnumSincronizacaoGeral } from 'model/enums/enum-sincronizacao-geral';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GestaoStorageKeys,
  useCadastros,
  useGestaoStorage,
  useToastSaurus
} from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useVenda } from 'services/app/hooks/gerenciar-venda';
import { ImpressaoSaurus } from 'services/app/hooks/impressao-saurus';
import { usePDV } from 'services/app/hooks/pdv';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { CircularLoading, LoadingButton } from 'views/components';
import { CancelarIcon, ConfiguracaoIcon } from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { RetornarIcon } from 'views/components/icons/retornar-icon';
import { ConteudoLeitura } from '../conteudo-leitura/conteudo-leitura';
import { useStyles } from './leitura-de-caixa-body-styles';
import classNames from 'classnames';
import { ModuloResumoSessaoProps } from 'views/components/dialog/dialog-config-resumo-sessao/dialog-config-resumo-sessao';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';

export const LeituraDeCaixaBody = () => {
  // STATES E REFS
  const [impressao, setImpressao] = useState('');
  const [webPdvImpressao, setWebPdvImpressao] = useState('');
  const [conteudotela, setConteudoTela] = useState('');
  const [error, setError] = useState<boolean>(false);

  // HOOKS
  const {
    getSessao,
    imprimirResumo,
    verificarItensParaSincronizacao,
    agruparValores,
    carregandoImpressaoLocal
  } = useSessaoPDV();
  const { getPDV, getImpressoraPdv } = usePDV();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const {
    getStatusSincronizacaoAtual,
    setStatusSincronizacaoAtual,
    iniciarSincronizacaoGeral
  } = useSincronizacaoGeral();
  const { getVendas } = useVenda();
  const { abrirConfigResumoSessao } = useCadastros();
  // CHAMADAS API
  const { getSessaoImpressao, carregando } = useGetSessaoImpressao();

  // AUX
  const classes = useStyles();
  const { addHandler, removeHandler } = useEventTools();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { getRegistro, setRegistro } = useGestaoStorage();
  const getResumo = useCallback(async () => {
    try {
      const sincEmAndamento = getStatusSincronizacaoAtual();

      if (
        sincEmAndamento &&
        sincEmAndamento.status === EnumSincronizacaoGeralStatus.EmAndamento
      ) {
        showToast(
          'info',
          'Já existe uma sincronização em andamento, aguarde até a finalização da sincronização atual.'
        );
        setError(true);
        return;
      }

      const vendas = await getVendas();

      if (vendas && vendas.length > 0) {
        if (!navigator.onLine) {
          showToast(
            'info',
            'Sem acesso a internet, não foi possível realizar a sincronização.'
          );
          setError(true);
          return;
        }

        await iniciarSincronizacaoGeral(() =>
          history.push(PdvRotasMock.gerenciarSessaoLeituraCaixa)
        );
        return;
      }

      const itens = await verificarItensParaSincronizacao();

      if (itens && itens.length > 0) {
        const agrupado = agruparValores(itens, 20);

        setStatusSincronizacaoAtual({
          ativo: false,
          sincronizandoAtual: 0,
          status: EnumSincronizacaoGeralStatus.EmAndamento,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0
        });

        return history.push({
          pathname: PdvRotasMock.gerenciarSessaoSincronizar,
          state: { itens: agrupado }
        });
      }

      const sessaoAtual = await getSessao();

      if (!sessaoAtual) {
        throw new Error('Nenhuma sessão foi identificada');
      }

      const qtdColuna = getPDV()?.configuracoes.find((p) => p.cod === 57);

      const primeiroAcesso = getRegistro(
        GestaoStorageKeys.PrimeiraImpressaoResumo,
        false
      );
      if (primeiroAcesso === true) {
        setRegistro(GestaoStorageKeys.PrimeiraImpressaoResumo, true, false);
        abrirConfigResumoSessao(sessaoAtual.id);
      }
      const query = getRegistro(
        GestaoStorageKeys.ResumoSessao,
        false
      ) as ModuloResumoSessaoProps[];
      const filtros = query.map((filtro) => {
        return `&${filtro.query}=${filtro.selecionado}`.toString();
      });
      const queryTratada = filtros.join('');

      const res = await getSessaoImpressao(
        getEmpresaSelecionada()!.Id,
        sessaoAtual.caixaId,
        sessaoAtual.id,
        qtdColuna?.vConfig ?? 62,
        queryTratada
      );

      if (res.erro) {
        throw new Error(res.erro);
      }

      if (getImpressoraPdv()) {
        setWebPdvImpressao(res?.resultado?.data?.texto ?? '');
      }

      const resConteudo = await getSessaoImpressao(
        getEmpresaSelecionada()!.Id,
        sessaoAtual.caixaId,
        sessaoAtual.id,
        40,
        queryTratada
      );

      if (resConteudo.erro) {
        throw new Error(
          'Não foi possível realizar a leitura, tente novamente mais tarde.'
        );
      }

      const renderizarNaTela = new ImpressaoSaurus('HTML_SIMPLES');

      const resumoTraduzido2 = renderizarNaTela.Traduz(
        resConteudo?.resultado?.data?.texto ?? ''
      );

      setConteudoTela(
        '<style>\nh1{font-size:26px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh2{font-size:22px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh3{font-size:20px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh4{font-size:16px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh5{font-size: 12px;font-weight: normal;white-space: pre-wrap;margin: 0;display: flex;flex-direction: column;justify-content: center;align-items: flex-end;text-align: end;}\n</style>\n' +
        resumoTraduzido2 ?? ''
      );
      setImpressao(res?.resultado?.data?.texto ?? '');
      setError(false);
    } catch (e: any) {
      setError(true);
    }
  }, [abrirConfigResumoSessao, agruparValores, getEmpresaSelecionada, getImpressoraPdv, getPDV, getRegistro, getSessao, getSessaoImpressao, getStatusSincronizacaoAtual, getVendas, history, iniciarSincronizacaoGeral, setRegistro, setStatusSincronizacaoAtual, showToast, verificarItensParaSincronizacao]);

  useEffect(() => {
    const primeiraImpressao = getRegistro(
      GestaoStorageKeys.PrimeiraImpressaoResumo,
      false
    );
    if (primeiraImpressao !== false) {
      setRegistro(GestaoStorageKeys.PrimeiraImpressaoResumo, true, false);
    }
    const gtResumo = async () => {
      await getResumo();
    };
    gtResumo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chamarResumo = useCallback(
    ({ aberto }: any) => {
      if (!aberto) {
        const resumo = async () => {
          await getResumo();
        };
        resumo();
      }
    },
    [getResumo]
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo);

    return () => {
      removeHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo);
    };
  }, [addHandler, chamarResumo, removeHandler]);

  return (
    <>
      <div
        style={{
          position: 'relative',
          minHeight: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid className={classes.list} style={{ overflowY: 'auto', flex: 1 }}>
          <Grid className={classes.containerResumo}>
            {error ? (
              <Grid className={classes.resumo}>
                <Grid
                  style={{
                    height: '100%',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Grid className={classes.imageError}>
                    <CancelarIcon tipo="GERAL" fill="#D22" />
                  </Grid>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      fontWeight: 500
                    }}
                  >
                    Dados não sincronizados, por favor tente novamente mais
                    tarde.
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.resumo}>
                {conteudotela && <ConteudoLeitura text={conteudotela} />}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className={classes.containerButton} container>
          <Grid style={{ flex: 1 }} item>
            <Button
              className={classNames(classes.button, 'round')}
              disabled={carregandoImpressaoLocal || carregando}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                error
                  ? getResumo()
                  : imprimirResumo(
                    webPdvImpressao ? webPdvImpressao : impressao,
                    getPDV()?.numCaixa.toString() ?? '',
                    webPdvImpressao ? false : true
                  );
              }}
            >
              {carregandoImpressaoLocal ? (
                <LoadingButton tipo="AZUL" />
              ) : error ? (
                <RetornarIcon tipo="BUTTON_PRIMARY" />
              ) : (
                <ImpressoraIcon tipo="BUTTON_PRIMARY" />
              )}

              {carregandoImpressaoLocal ? '' : error ? 'Tentar novamente' : 'Imprimir'}
            </Button>
          </Grid>
          <Button
            className={classNames('round', classes.button, classes.buttonRedondo)}
            variant='contained'
            color='primary'
            onClick={async () => {
              const sessaoAtual = await getSessao();
              abrirConfigResumoSessao(sessaoAtual!.id);
            }}
          >
            <ConfiguracaoIcon tipo="BUTTON_PRIMARY" />
          </Button>
        </Grid>
      </div>
    </>
  );
};
