import { useCallback, useState } from 'react';
export const useFormStepper = (nSteps: number) => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = useCallback(() => {
    if (currentStep < nSteps) setCurrentStep((n) => n + 1);
  }, [nSteps, currentStep]);

  const prevStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((n) => n - 1);
      return true;
    } else {
      return false;
    }
  }, [currentStep]);

  const resetSteps = useCallback(() => {
    setCurrentStep(0);
  }, []);

  const skipStep = useCallback(() => {
    setCurrentStep((n) => n + 1);
  }, []);

  const getStep = useCallback((step: number) => {
    setCurrentStep(step);
  }, []);

  return {
    nextStep,
    prevStep,
    resetSteps,
    currentStep,
    skipStep,
    getStep
  };
};
