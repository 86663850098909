import { CardProdutoSubItemProps } from "./card-produto-subitem-props";
import { EditarIcon } from "../../icons/editar-icon";
import { DefaultCard } from "../components/default-card";
import { useDefaultCardStyles } from "../components/default-card/default-card-styles";
import { memo } from "react";
import {useStyles} from './card-produto-subitem-styles'
import { Grid, Typography } from "views/design-system";
import { Tooltip } from "@material-ui/core";

const CardProdutoSubItem = ({
  model,
  selected,
  abrirEdicao,
}: CardProdutoSubItemProps) => {
  const classes = useDefaultCardStyles();
  const cardClasses = useStyles();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          abrirEdicao({
            ...model,
            produtoNome: model.produtoSubGrade?.descricao ?? '',
            modificadorNome: model.modificador?.descricao ?? '',
            modificadorId: model.modificadorId ?? '',
            produtoSubId: model.produtoSubId,
            produtoId: model.produtoId
          })
        }}
        className={cardClasses.card}
      >
        <Grid container className={classes.cardContent} justifyContent="space-between">
          <Grid item xs={6} md={8}>
            <Typography color="textSecondary">
              Produto
            </Typography>
            <Typography color="textPrimary" variant="body1" className={cardClasses.weight}>
              {model.produtoSubGrade?.descricao ? model.produtoSubGrade?.descricao : ''}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="textSecondary">
              Quantidade Mínima
            </Typography>
            <Typography color="textPrimary" variant="body1" className={cardClasses.weight}>
              {model.qMin}
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography color="textSecondary">
              Modificador
            </Typography>
            <Typography color="textPrimary" variant="body1" className={cardClasses.weight}>
              {model.modificador?.descricao ? model.modificador?.descricao : '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="textSecondary">
              Quantidade Máxima
            </Typography>
            <Typography color="textPrimary" variant="body1" className={cardClasses.weight}>
              {model.qMax}
            </Typography>
          </Grid>
        </Grid>
        <Tooltip arrow title="Editar Insumo">
          <div style={{
            display: 'flex',
            alignItems: "center"
          }}>
            <EditarIcon tipo="BUTTON" style={{ width: 25, height: 25, marginRight: 0 }} />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default memo(CardProdutoSubItem)