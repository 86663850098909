import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutos() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutos = useCallback(
    (queryBusca: string, empresaId: string, page?: number, pageSize?: number) => {
      let queryPage = '';
      if (page) {
        queryPage = 'page=' + page.toString();
      }
      if (pageSize) {
        queryPage += `${queryPage.length > 0 ? '&' : ''}pageSize=${pageSize}`;
      }

      let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? '?' : ''
        }${queryPage}${queryPage.length > 0 ? '&' : ''}${queryBusca}`;

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Empresa/${empresaId}/produto${queryFinal}`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutos
  };
}
