import { forwardRef, useImperativeHandle } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormMesaNovaValidation } from './form-mesa-nova-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MesasNovaModel } from 'model/api/gestao/mesa/mesa-nova-model';
import { Button, Grid } from 'views/design-system';
// import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
// import { SimNaoBooleanMock } from 'data/mocks/sim-nao-mock';
// import { useHistory } from 'react-router';
// import { useTourSaurus } from 'services/app/hooks/tour-saurus';
// import { EnumTour } from 'model/enums/enum-tour';

export const FormMesaNova = forwardRef<
  DefaultFormRefs<MesasNovaModel>,
  DefaultFormProps<MesasNovaModel>
>((props: DefaultFormProps<MesasNovaModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormMesaNovaValidationYup } = useFormMesaNovaValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    // getValues
  } = useForm<MesasNovaModel>({
    defaultValues: { ...props.model },
    resolver: yupResolver(FormMesaNovaValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: MesasNovaModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: (model: MesasNovaModel) => {
      reset({ ...model });
    },
  }));



  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="codigo"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Codigo da Mesa"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={'EX: 1'}
                    error={Boolean(errors.codigo && errors.codigo.message)}
                    helperText={
                      touchedFields.codigo || errors.codigo
                        ? errors.codigo?.message
                        : undefined
                    }
                    {...field}
                    onChange={(e: any) => {
                      if(e.target.value === '' || e.target.value === null){
                        setValue('codigo', '')
                      }
                      var numberPattern = /\d+/g;
                      const value = e.target.value.match(numberPattern)
         
                       if(value) {
                         setValue('codigo', value.join(''))
                       } 
                     }}
                     id="tour-CadMesasForm"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                name="pedidoComQrCode"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Permitir Pedido por QRcode?"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={SimNaoBooleanMock}
                    error={Boolean(errors.pedidoComQrCode && errors.pedidoComQrCode.message)}
                    helperText={
                      touchedFields.pedidoComQrCode || errors.pedidoComQrCode
                        ? errors.pedidoComQrCode?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = SimNaoBooleanMock.filter(
                        (item) => item.Key === event.target.value,
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('pedidoComQrCode', item);
                      }
                    }}
                    value={getValues('pedidoComQrCode')}
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
