import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 12,
        lineHeight: '30px',
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    container: (props: any) => ({
        padding: theme.spacing(1),
        paddingBottom: 0,
        borderTop: props.tiraBorderTop ? undefined : '1px solid #e0e0e0',
        color: '#A3A3A3',
        fontWeight: 600,
    }),
    label: {
        color: theme.palette.primary.dark,
        fontSize: 10,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    content: {
        display: 'flex',
        flexDirection: 'column'
    },
    value: {
        fontSize: 16,
        marginTop: 0,
        marginBottom: 5,
        color: theme.palette.primary.main,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& svg': {
            marginBottom: -2,
            width: 18,
            height: 18,
        }
    },
    meio: {
        textAlign: 'center'
    },
    final: {
        textAlign: 'end'
    },
    carregando: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 33,
        alignItems: 'center'
    },
    containerButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 45,
        background: '#F0F0F0',
        marginRight: -8,
        borderRadius: '35px',
        padding: '5px'
    },
    button: {
        width: 130,
        fontSize: 12,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRadius: '32px',
        background: '#fff',
        color: '#6B6B6B'
    },
}));
