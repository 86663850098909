import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app/hooks';
import { useFormStepper } from '../../../../../form-stepper/form-step';
import { FormStep } from '../../../../../../../model/app/form-stepper/form-step';
import { ProdutoCategoriaPreCadastroFormModel } from '../../../../../../../model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-categoria-form-model';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import {
  OkIcon,
  VoltarIcon,
  AvancarIcon,
  BarcodeIcon,
  ProdutoIcon,
  DinheiroIcon,
  SalvarEditarIcon,
  ImpostoIcon,
  NovoIcon
} from 'views/components/icons';
import { ProdutoNovoModel } from '../../../../../../../model/api/gestao/produto/produto/produto-novo-model';
import { picker } from 'utils/picker';
import { usePostProduto } from 'data/api/gestao/produto/produto/post-produto';
import { toDecimal } from '../../../../../../../utils/to-decimal';
import { ProdutoCodigoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-codigo-form-model';
import {
  EnumFormCodigo,
  FormProdutoCodigoPreCadastro
} from '../../../../../form/produto/produto-pre-cadastro/form-produto-codigo/form-produto-codigo-pre-cadastro';
import { ProdutoImpostoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-imposto-form-model';
import { ProdutoImagemPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-imagem-form-model';
import { imagemForUpload } from '../../../../../../../utils/imagem-for-upload';
import { EnumRetornoApiBase } from '../../../../../../../data/api/base/api-base-response';
import { usePostImagemBase64 } from '../../../../../../../data/api/imagem/post-imagem';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { useGetNcms } from 'data/api/gestao/ncm/get-ncms';
import { NcmMasterSummaryModel } from '../../../../../../../model/api/ncm-master/ncm-master-model';
import { guidEmpty } from '../../../../../../../utils/guid-empty';
import { useGetNcmsMaster } from 'data/api/ncm-master/get-ncms-master';
import { usePostNcm } from 'data/api/gestao/ncm/post-ncm';
import { newGuid } from 'utils/new-guid';
import { useCadastros, useSessaoAtual } from 'services/app';
import { useHistory } from 'react-router';
import { EnumTelaProduto } from 'model/enums/enum-aba-tela-produto';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { FormProdutoNcmPreCadastro } from 'views/components/form/produto/produto-pre-cadastro/form-produto-imposto/form-produto-imposto-pre-cadastro';
import { isPlanoFiscal } from 'utils/plano-utils';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useGetMedicamentos } from 'data/api/gestao/medicamentos/get-medicamentos';
import { MedicamentoModel } from 'model/api/gestao/medicamento/medicamento-model';
import { ProdutoMedicamentoPrecoFormModel } from 'model/app/forms/produto/medicamento/medicamento-preco-form-model';
import { FormMedicamentoPreco } from 'views/components/form/produto/medicamento-pre-cadastro/form-medicamento-preco/form-medicamento-preco';
import { FormMedicamentoProduto } from 'views/components/form/produto/medicamento-pre-cadastro/form-medicamento-produto/form-medicamento-produto';
import { usePostMedicamento } from 'data/api/gestao/medicamentos/post-medicamentos';
import { FormMedicamentoCompleto } from 'views/components/form/produto/medicamento-pre-cadastro/form-medicamento-completo/form-medicamento-completo';
import { MedicamentoCompletoFormModel } from 'model/app/forms/produto/medicamento/medicamento-completo-form-model';
import { isEmpty } from 'lodash';
import { useGetProdutos } from 'data/api/gestao/produto/produto/get-produtos';
import { useConfirm } from 'material-ui-confirm';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { MedicamentoPrecoModel } from 'model/api/gestao/medicamento/medicamento-preco-model';
import { useGetMedicamentoPrecoByMedId } from 'data/api/gestao/medicamento-preco/get-medicamento-by-med-id';
import { useGetMarcas } from 'data/api/gestao/marca/get-marcas';
import { usePostMarca } from 'data/api/gestao/marca/post-marca';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { validarCNPJ } from 'utils/cpfcnpj-validate';
import { useGetProdutoExisteCodigo } from 'data/api/gestao/produto/produto-codigo/get-produto-existe-codigo';
import { validaEan } from 'utils/valida-ean';
import { Button, Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';

export const useMedicamentoCadastro = (
  contratoId: string,
  empresaId: string,
  voltarTela: () => void
) => {
  const { isMobile } = useThemeQueries()
  const { showToast } = useToastSaurus();
  const { showAviso } = useShowAviso();
  const { abrirCadastroProduto } = useCadastros();
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const { currentStep, nextStep, prevStep, resetSteps } = useFormStepper(7);
  const { postProduto, carregando: carregandoProduto } = usePostProduto();
  const confirm = useConfirm();
  const { postImagemBase64, carregando: carregandoPostImagem } =
    usePostImagemBase64();
  const { getMedicamentos, carregando: carregandoGetMedicamentos } =
    useGetMedicamentos();
  const { getProdutos, carregando: carregandoProdutos } = useGetProdutos();
  const { getProdutoExisteCodigo, carregando: carregandoExiste } =
    useGetProdutoExisteCodigo();
  const { getMarcas, carregando: carregandoMarcas } = useGetMarcas();
  const { postMarca, carregando: carregandoPostMarcas } = usePostMarca();
  const { getMedicamentoPrecoByMedId, carregando: carregandoGetPreco } =
    useGetMedicamentoPrecoByMedId();
  const { postMedicamento, carregando: carregandoPostMedicamento } =
    usePostMedicamento();
  const { getNcms, carregando: carregandoNcms } = useGetNcms();
  const { getNcmsMaster, carregando: carregandoNcmsMaster } =
    useGetNcmsMaster();
  const { plano } = useSessaoAtual();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { postNcm, carregando: carregandoPostNcm } = usePostNcm();
  const loading: boolean =
    carregandoFromForms ||
    carregandoProduto ||
    carregandoPostImagem ||
    carregandoGetMedicamentos ||
    carregandoNcms ||
    carregandoNcmsMaster ||
    carregandoPostNcm ||
    carregandoPostMedicamento ||
    carregandoExiste ||
    carregandoGetMedicamentos ||
    carregandoGetPreco ||
    carregandoMarcas ||
    carregandoPostMarcas ||
    carregandoProdutos;

  const refPreCadastroCodigo =
    useRef<DefaultFormRefs<ProdutoCodigoPreCadastroFormModel>>(null);
  const refPreCadastroMedicamento =
    useRef<DefaultFormRefs<MedicamentoModel>>(null);
  const refMedicamentoPreco =
    useRef<DefaultFormRefs<ProdutoMedicamentoPrecoFormModel>>(null);
  const refPreCadastroImposto =
    useRef<DefaultFormRefs<ProdutoImpostoPreCadastroFormModel>>(null);
  const refPreCadastroProdutoCompleto =
    useRef<DefaultFormRefs<MedicamentoCompletoFormModel>>(null);

  //-----------------------------------------refs para os forms no  vai e volta do stepper
  const refCategoriaModelForm = useRef<ProdutoCategoriaPreCadastroFormModel>(
    new ProdutoCategoriaPreCadastroFormModel()
  );
  const refCodigoModelForm = useRef<ProdutoCodigoPreCadastroFormModel>(
    new ProdutoCodigoPreCadastroFormModel()
  );
  const refMedicamentoForm = useRef<MedicamentoModel>(new MedicamentoModel());
  const refNcmModelForm = useRef<ProdutoImpostoPreCadastroFormModel>(
    new ProdutoImpostoPreCadastroFormModel()
  );
  const refImagemModelForm = useRef<ProdutoImagemPreCadastroFormModel>(
    new ProdutoImagemPreCadastroFormModel()
  );

  const refMedicamentoModelForm = useRef<MedicamentoModel>(
    new MedicamentoModel()
  );
  const refMedicamentoPrecoModelForm = useRef<ProdutoMedicamentoPrecoFormModel>(
    new ProdutoMedicamentoPrecoFormModel()
  );

  const medicamentoEncontrado = useRef<boolean>(false);
  const precoEncontrado = useRef<boolean>(false);
  const buscouProduto = useRef<boolean>(false);
  const cadastrouMedicamento = useRef<boolean>(false);
  const sugeriuPrecos = useRef<boolean>(false);
  const idLaboratorio = useRef<string>('');

  const redirectToEdit = useRef(false);
  const { location } = useHistory();
  const newPathName = location.pathname.replace('/adicionar', '');

  const limparValoresRefs = useCallback(() => {
    // refCategoriaModelForm.current = new ProdutoCategoriaPreCadastroFormModel();
    refCodigoModelForm.current = new ProdutoCodigoPreCadastroFormModel();
    refMedicamentoForm.current = new MedicamentoModel();
    refMedicamentoPrecoModelForm.current =
      new ProdutoMedicamentoPrecoFormModel();
    // refNcmModelForm.current = new ProdutoImpostoPreCadastroFormModel();
    refImagemModelForm.current = new ProdutoImagemPreCadastroFormModel();
    resetSteps();
  }, [resetSteps]);

  const getProdutoCompleto = useCallback(() => {
    let medicamentoCompleto = new MedicamentoCompletoFormModel();

    //categoria
    medicamentoCompleto = picker<MedicamentoCompletoFormModel>(
      refCategoriaModelForm.current,
      medicamentoCompleto
    );
    //codigo
    medicamentoCompleto = picker<MedicamentoCompletoFormModel>(
      refCodigoModelForm.current,
      medicamentoCompleto
    );
    //medida e nome
    medicamentoCompleto = picker<MedicamentoCompletoFormModel>(
      refMedicamentoForm.current,
      medicamentoCompleto
    );
    medicamentoCompleto.infAdic = refMedicamentoForm.current.descritivo;
    medicamentoCompleto.ncmId = null;
    //valores venda e compra
    medicamentoCompleto = picker<MedicamentoCompletoFormModel>(
      refMedicamentoPrecoModelForm.current,
      medicamentoCompleto
    );
    //ncm
    medicamentoCompleto = picker<MedicamentoCompletoFormModel>(
      refNcmModelForm.current,
      medicamentoCompleto
    );
    return medicamentoCompleto;
  }, []);

  const sendImage = useCallback(
    async (modelo: MedicamentoCompletoFormModel) => {
      try {
        let imagem = modelo.imagemUrl;
        let imgUpload = imagemForUpload(modelo.imagemUrl);
        if (imgUpload.length > 0) {
          let path = 'medicamentos';
          const retImagem = await postImagemBase64(
            imgUpload,
            `${path}/${contratoId}/`,
            newGuid()
          );
          if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
            throw new Error('Erro ao enviar a Imagem selecionada.');
          }
          if (retImagem.resultado?.data.data.status === 2) {
            throw new Error(
              'Erro ao enviar a Imagem selecionada.Detalhe: ' +
              retImagem.resultado?.data?.data?.retorno
            );
          }
          imagem =
            retImagem.resultado?.data?.data?.url_blob +
            '?timestamp=' +
            new Date().getTime();
        }

        modelo.imagemUrl = btoa(imagem);
        return modelo;
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [contratoId, postImagemBase64, showToast]
  );
  //para ver se o ncm retornado quando acha pelo ean esta cadastrado na nossa base, se não busca na api de ncms e cadastra
  const searchNcm = useCallback(
    async (modelo: MedicamentoCompletoFormModel) => {
      if (modelo.ncmId === null && modelo.codigoNcm.length > 0) {
        setCarregandoFromForms(true);
        try {
          //primeiro vou buscar na minha base
          const retNcmGestao = await getNcms(
            `Codigo=${modelo.codigoNcm}&TemFiltro=true`
          );
          if (retNcmGestao.erro) throw retNcmGestao.erro;
          if (retNcmGestao.resultado?.data.list.length > 0) {
            const ncm = retNcmGestao.resultado?.data?.list[0] as NcmModel;
            modelo.codigoNcm = ncm.codigo;
            modelo.ncmId = ncm.id;
          }
        } catch (e: any) { }
        //se o id do ncm não estiver preenchido significa que não existe na nossa api esse ncm cadastrado
        if (modelo.ncmId === null) {
          //entao busco na api de ncms master
          const retNcmGestao = await getNcmsMaster(modelo.codigoNcm);
          if (retNcmGestao.erro) throw retNcmGestao.erro;
          if (retNcmGestao.resultado?.data.list.length > 0) {
            const ncm = retNcmGestao.resultado?.data
              ?.list[0] as NcmMasterSummaryModel;
            const ncmModel = new NcmModel(
              guidEmpty(),
              contratoId,
              ncm.id,
              ncm.codigo,
              ncm.descricao,
              ncm.cest
            );
            //cadastro em nossa base
            const ret = await postNcm(ncmModel);
            if (ret.erro) {
              throw ret.erro;
            }
            const novoNcm = ret.resultado?.data as NcmModel;
            modelo.codigoNcm = novoNcm.codigo;
            modelo.ncmId = novoNcm.id;
          }
        }
      }
      setCarregandoFromForms(false);
    },
    [contratoId, getNcms, getNcmsMaster, postNcm]
  );
  const finalizarCadastro = useCallback(
    async (produtoCompleto: MedicamentoCompletoFormModel) => {
      try {
        if (!medicamentoEncontrado.current && !cadastrouMedicamento.current) {
          const res = await postMedicamento({
            ...refMedicamentoForm.current,
            qtdEmbalagem: refMedicamentoPrecoModelForm.current.qtdEmbalagem,
            laboratorioId: '' //o backend precisa reconhecer esse campo como marcaId para enviar algo
          });
          if (res.erro) {
            throw res.erro;
          }
          refMedicamentoModelForm.current = {
            ...refMedicamentoModelForm.current,
            ...res.resultado?.data
          };
          showToast(
            'success',
            'Medicamento cadastrado e enviado para revisão.'
          );
          cadastrouMedicamento.current = true;
        }
        // if (!precoEncontrado.current && !cadastrouPreço.current) {
        //     //LEMBRA DE PASSAR O ID
        //     let precoModel = picker<MedicamentoPrecoModel>(refMedicamentoForm.current, new MedicamentoPrecoModel())
        //     precoModel = picker<MedicamentoPrecoModel>(refMedicamentoPrecoModelForm.current, { ...precoModel })
        //     precoModel.medicamentoId = refMedicamentoModelForm.current.id;
        //     const resPreco = await postMedicamentoPreco(precoModel);

        //     if (resPreco.erro) throw resPreco.erro;

        //     cadastrouPreço.current = true
        // }
        //vou verificar se o ncm existe parta pegar o id dele, ou cadastrar um novo
        await searchNcm(produtoCompleto);
        //vamos upar a imagem na api de upar imagens
        await sendImage(produtoCompleto);

        let produtoFinal = picker<ProdutoNovoModel>(
          produtoCompleto,
          new ProdutoNovoModel()
        );

        produtoFinal.medidaEntradaId = produtoCompleto.medidaId;
        produtoFinal.medidaSaidaId = produtoCompleto.medidaId;
        produtoFinal.vCompra = toDecimal(produtoCompleto.vCompra);
        produtoFinal.vPreco = toDecimal(produtoCompleto.vPreco);
        produtoFinal.empresaId = empresaId;
        produtoFinal.tipo = EnumTipoProduto.Medicamento;
        produtoFinal.codigoAnvisa =
          refMedicamentoModelForm.current.codigoAnvisa;
        produtoFinal.codigo = refMedicamentoModelForm.current.codigoEan;

        //valido se não é o mesmo ID da api de medicamentos, para não dar erro na hora do POST
        produtoFinal.marcaId =
          refMedicamentoModelForm.current.laboratorioId ===
            idLaboratorio.current
            ? ''
            : refMedicamentoModelForm.current.laboratorioId;

        if (produtoFinal.categoriaId === guidEmpty()) {
          produtoFinal.categoriaId = null;
        }

        const ret = await postProduto(produtoFinal, produtoFinal.empresaId);
        if (ret.erro) throw ret.erro;

        if (!redirectToEdit.current) {
          limparValoresRefs();
          showToast('success', 'Cadastro realizado com Sucesso!');
        } else {
          abrirCadastroProduto(
            ret.resultado?.data.id,
            ret.resultado?.data.tipo,
            newPathName,
            true
          );
        }
      } catch (e: Error | any) {
        showAviso(
          'error',
          `Erro ao cadastrar o medicamento. Tente novamente em alguns instantes. Detalhe:/n ` +
          e.message,
          undefined,
          true,
          true
        );
      }
    },
    [
      searchNcm,
      sendImage,
      empresaId,
      postProduto,
      abrirCadastroProduto,
      newPathName,
      limparValoresRefs,
      postMedicamento,
      showToast,
      showAviso
    ]
  );

  const getFormPreCadastroCodigo = useCallback((): JSX.Element => {
    const buscarMedicamento = async (
      codigo: string,
      modelo: ProdutoCodigoPreCadastroFormModel
    ) => {
      refCodigoModelForm.current = { ...refCodigoModelForm.current, codigo };
      if (codigo.length > 0) {
        let list = [] as MedicamentoModel[];
        setCarregandoFromForms(true);
        //SETO ESSE BUSCOU PRODUTO PARA QUE A PESSOA INFORME O CÓDIGO DA ANVISA CASO NÃO IDENTIFIQUE
        buscouProduto.current = true;
        let res = await getMedicamentos(`codigoAnvisa=${codigo}`);
        list = res.resultado?.data.list;
        if (!list || list.length < 1) {
          res = await getMedicamentos(`codigoEAN=${codigo}`);

          list = res.resultado?.data.list;
        }
        if (list && list.length > 0) {
          const ret = picker<MedicamentoModel>(list[0], new MedicamentoModel());

          medicamentoEncontrado.current = true;
          idLaboratorio.current = ret.laboratorioId;
          if (ret.laboratorioId && ret.laboratorioId !== guidEmpty()) {
            try {
              const retLab = await getMarcas(`Nome=${ret.laboratorioNome}`);

              if (retLab.erro) throw retLab.erro;

              if (retLab.resultado?.data.list.length > 0) {
                ret.laboratorioId = retLab.resultado?.data.list[0].id || '';
              } else {
                const cnpj = validarCNPJ(ret.laboratorioDocumento)
                  ? ret.laboratorioDocumento
                  : '';
                const retPost = await postMarca(
                  new MarcaModel(
                    guidEmpty(),
                    contratoId,
                    ret.laboratorioNome,
                    cnpj
                  )
                );
                if (retPost.erro) throw retPost.erro;

                ret.laboratorioId = retPost.resultado?.data.id || '';
              }
            } catch {
              ret.laboratorioId = '';
            }
          }

          let ncm = { ...refNcmModelForm.current };
          ncm.codigoNcm = ret.ncm;
          //removi medicamento setar aqui coloca dnv lembrar pra preencher
          let nomeMedida = { ...refMedicamentoForm.current };
          nomeMedida.nome = ret.nome;
          let imagem = { ...refImagemModelForm.current };
          imagem.imagemUrl = `https://saurusean.blob.core.windows.net/eanimagens/${ret.codigoEan}.jpg`;

          refNcmModelForm.current = ncm;
          refMedicamentoForm.current = nomeMedida;
          refImagemModelForm.current = imagem;

          const retPreco = await getMedicamentoPrecoByMedId(ret.id);

          if (
            retPreco.erro ||
            !retPreco.resultado?.data ||
            retPreco.resultado.data.precos.length === 0
          ) {
            precoEncontrado.current = false;
            refMedicamentoPrecoModelForm.current =
              new ProdutoMedicamentoPrecoFormModel();
          } else {
            precoEncontrado.current = true;
            const preco = retPreco.resultado.data
              .precos[0] as MedicamentoPrecoModel;

            refMedicamentoPrecoModelForm.current = {
              ...refMedicamentoPrecoModelForm.current,
              dataFinal: preco.dataFinal,
              dataInicial: preco.dataInicial,
              precoFabricacao: preco.precoFabricacao,
              precoMaximoConsumidor: preco.precoMaximoConsumidor
            };
          }

          refMedicamentoModelForm.current = { ...ret };
          modelo.codigo = ret.codigoAnvisa;
          modelo.codigoEAN = ret.codigoEan;
          return;
        }
        refMedicamentoModelForm.current = new MedicamentoModel();
        refMedicamentoPrecoModelForm.current =
          new ProdutoMedicamentoPrecoFormModel();
        medicamentoEncontrado.current = false;
        precoEncontrado.current = false;
        throw new Error();
      }
    };

    const handleSubmitFormPreCadastroCodigo = async (
      modelo: ProdutoCodigoPreCadastroFormModel
    ) => {
      try {
        if (!buscouProduto.current) {
          await buscarMedicamento(modelo.codigo, modelo);
        }
        setCarregandoFromForms(false);
      } catch (e: any) {
        setCarregandoFromForms(false);
        const codModel = new ProdutoCodigoPreCadastroFormModel()
        if (validaEan(modelo.codigo)) {
          codModel.codigoEAN = modelo.codigo
        } else {
          codModel.codigo = modelo.codigo;
        }
        refPreCadastroCodigo.current?.fillForm(codModel);
        return;
      }

      //CHECANDO SE O EAN JÁ EXISTE

      try {
        const codEAN = !isEmpty(modelo.codigoEAN)
          ? modelo.codigoEAN
          : refMedicamentoModelForm.current.codigoEan;

        let res: { statusCode: number, codigo: string }[] = [];

        if (!isEmpty(codEAN)) {
          const retEan = await getProdutoExisteCodigo(codEAN || '');
          res.push({
            statusCode: retEan.statusCode,
            codigo: codEAN || ''
          });
        }

        if (res.some(x => x.statusCode === 202) || res.length === 0) {
          if (!isEmpty(modelo.codigo)) {
            const retAnvisa = await getProdutoExisteCodigo(modelo.codigo);
            res.push({
              statusCode: retAnvisa.statusCode,
              codigo: modelo.codigo
            })
          }
        }
        let repetido = res.find(x => x.statusCode === 403)
        if (repetido) {
          try {
            const resProd = await getProdutos(
              `Codigo=${repetido.codigo}`,
              getEmpresaAtual()?.id || ''
            );

            if (resProd.erro) throw resProd.erro;

            const ret = resProd.resultado?.data.list;

            if (ret.length < 1) {
              throw new Error();
            }

            const prod = ret[0];

            await confirm({
              content: `Encontramos um produto com mesmo código EAN em seus cadastros. (${prod.nome}).
                        Deseja ir a tela do produto já cadastrado?`,
              confirmationText: 'Ir ao Produto',
              cancellationText: 'Cancelar Cadastro'
            })
              .then(() => {
                abrirCadastroProduto(
                  prod.produtoId,
                  prod.tipo,
                  newPathName,
                  true
                );
              })
              .catch(() => {
                voltarTela();
                return;
              });
          } catch {
            showAviso(
              'error',
              `O código "${codEAN}" já está cadastrado nesta Empresa.`,
              undefined,
              true,
              true
            );
          }
        } else {
          buscouProduto.current = false;
          refCodigoModelForm.current = modelo;
          nextStep();
        }
      } catch (e: any) { }
    };
    return (
      <FormProdutoCodigoPreCadastro
        obrigatorio
        showLoading={false}
        ref={refPreCadastroCodigo}
        loading={loading}
        setCarregandoExterno={setCarregandoFromForms}
        onSubmit={handleSubmitFormPreCadastroCodigo}
        label={
          buscouProduto.current
            ? 'Código ANVISA'
            : 'Código ANVISA ou EAN do Produto'
        }
        tipoForm={
          buscouProduto.current
            ? EnumFormCodigo.ANVISA_EAN
            : EnumFormCodigo.AMBOS
        }
      />
    );
  }, [
    abrirCadastroProduto,
    confirm,
    contratoId,
    getEmpresaAtual,
    getMarcas,
    getMedicamentoPrecoByMedId,
    getMedicamentos,
    getProdutoExisteCodigo,
    getProdutos,
    loading,
    newPathName,
    nextStep,
    postMarca,
    showAviso,
    voltarTela
  ]);
  const getFormPreCadastroNomeMedida = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroNomeMedida = async (
      modelo: MedicamentoModel
    ) => {
      refMedicamentoForm.current = modelo;
      refMedicamentoModelForm.current = modelo;
      nextStep();
    };
    return (
      <FormMedicamentoProduto
        valoresEncontrados={medicamentoEncontrado.current}
        showLoading={false}
        ref={refPreCadastroMedicamento}
        loading={loading}
        setCarregandoExterno={setCarregandoFromForms}
        onSubmit={handleSubmitFormPreCadastroNomeMedida}
        model={refMedicamentoForm.current}
      />
    );
  }, [loading, nextStep]);

  const getFormMedicamentoPreco = useCallback((): JSX.Element => {
    const handleSubmitFormMedicamentoPreco = async (
      modelo: ProdutoMedicamentoPrecoFormModel
    ) => {
      refMedicamentoPrecoModelForm.current = modelo;
      sugeriuPrecos.current = true;
      nextStep();
    };
    return (
      <FormMedicamentoPreco
        showLoading={false}
        ref={refMedicamentoPreco}
        loading={loading}
        onSubmit={handleSubmitFormMedicamentoPreco}
        valoresEncontrados={precoEncontrado.current}
        sugeriuPrecos={sugeriuPrecos.current}
      />
    );
  }, [loading, nextStep]);

  const getFormPreCadastroNcm = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroNcm = async (
      modelo: ProdutoImpostoPreCadastroFormModel
    ) => {
      refNcmModelForm.current = modelo;
      nextStep();
    };
    return (
      <FormProdutoNcmPreCadastro
        showLoading={false}
        ref={refPreCadastroImposto}
        loading={loading}
        onSubmit={handleSubmitFormPreCadastroNcm}
      />
    );
  }, [loading, nextStep]);

  const getFormProdutoCompleto = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroProdutoCompleto = async (
      modelo: MedicamentoCompletoFormModel
    ) => {
      await finalizarCadastro(modelo);
    };
    return (
      <FormMedicamentoCompleto
        showLoading={false}
        ref={refPreCadastroProdutoCompleto}
        loading={loading}
        contratoId={contratoId}
        empresaId={empresaId}
        setCarregandoExterno={setCarregandoFromForms}
        onSubmit={handleSubmitFormPreCadastroProdutoCompleto}
        desabilitarCampos={medicamentoEncontrado.current}
        desabilitarPrecos={precoEncontrado.current}
      />
    );
  }, [contratoId, empresaId, finalizarCadastro, loading]);

  const retTelaIndex = useCallback(
    (index: number): EnumTelaProduto | undefined => {
      const isFiscal = isPlanoFiscal(plano?.plano);
      const empresaFiscal = getEmpresaAtual()?.isFiscal;
      if (isFiscal && empresaFiscal) {
        switch (index) {
          case 0:
            return EnumTelaProduto.Codigo;
          case 1:
            return EnumTelaProduto.Produto;
          case 2:
            return EnumTelaProduto.Preco;
          case 3:
            return EnumTelaProduto.Tributacao;
          case 4:
            return EnumTelaProduto.Revisao;
        }
      } else {
        switch (index) {
          //case 0: return EnumTelaProduto.Categoria;
          case 0:
            return EnumTelaProduto.Codigo;
          case 1:
            return EnumTelaProduto.Produto;
          case 2:
            return EnumTelaProduto.Preco;
          case 3:
            return EnumTelaProduto.Revisao;
        }
      }

      return undefined;
    },
    [getEmpresaAtual, plano?.plano]
  );

  useEffect(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelaProduto.Codigo:
        refPreCadastroCodigo.current?.fillForm(refCodigoModelForm.current);
        break;
      case EnumTelaProduto.Produto:
        refPreCadastroMedicamento.current?.fillForm({
          ...refMedicamentoModelForm.current,
          codigoAnvisa: refCodigoModelForm.current.codigo,
          codigoEan: refMedicamentoModelForm.current.codigoEan
            ? refMedicamentoModelForm.current.codigoEan
            : refCodigoModelForm.current.codigoEAN || ''
        });
        break;
      case EnumTelaProduto.Preco:
        refMedicamentoPreco.current?.fillForm(
          refMedicamentoPrecoModelForm.current
        );
        break;
      case EnumTelaProduto.Revisao:
        refPreCadastroProdutoCompleto.current?.fillForm(getProdutoCompleto());
        break;
      case EnumTelaProduto.Tributacao:
        refPreCadastroImposto.current?.fillForm(refNcmModelForm.current);
    }
  }, [currentStep, finalizarCadastro, getProdutoCompleto, retTelaIndex]);

  const avancarStep = useCallback(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelaProduto.Codigo:
        refPreCadastroCodigo.current?.submitForm();
        break;
      case EnumTelaProduto.Produto:
        refPreCadastroMedicamento.current?.submitForm();
        break;
      case EnumTelaProduto.Preco:
        refMedicamentoPreco.current?.submitForm();
        break;
      case EnumTelaProduto.Revisao:
        refPreCadastroProdutoCompleto.current?.submitForm();
        break;
      case EnumTelaProduto.Tributacao:
        refPreCadastroImposto.current?.submitForm();
    }
  }, [currentStep, retTelaIndex]);

  const voltarStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const voltarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        size="large"
        fullWidth={true}
        disabled={loading}
        onClick={voltarStep}
      >
        <VoltarIcon tipo="BUTTON" />
        Voltar
      </Button>
    );
  }, [voltarStep, loading]);

  const avancarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        fullWidth={true}
        disabled={loading}
        onClick={avancarStep}
      >
        <AvancarIcon tipo="BUTTON_PRIMARY" />
        Avançar
      </Button>
    );
  }, [loading, avancarStep]);
  const cadastrarNovoButton = useMemo(() => {
    return (
      <Button
        disabled={loading}
        onClick={() => {
          redirectToEdit.current = false;
          avancarStep();
        }}
        variant="outlined"
        color="primary"
        size="large"
        fullWidth
      >
        <NovoIcon tipo="BUTTON" />
        <Typography variant={isMobile ? 'body2' : 'button'} weight={600}>
          Salvar e Adicionar Outro
        </Typography>
      </Button>
    );
  }, [loading, isMobile, avancarStep]);
  const cadastrarEditarButton = useMemo(() => {
    return (
      <Button
        disabled={loading}
        onClick={() => {
          redirectToEdit.current = true;
          avancarStep();
        }}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
      >
        <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
        <Typography variant={isMobile ? 'body2' : 'button'} weight={600}>
          Salvar
        </Typography>
      </Button>
    );
  }, [loading, isMobile, avancarStep]);

  const getFormArray = useMemo(() => {
    const ret = [];

    let i = 0;
    while (retTelaIndex(i)) {
      const tela = retTelaIndex(i);

      switch (tela) {
        case EnumTelaProduto.Codigo:
          ret.push(
            new FormStep(
              'Código',
              `Insira o código do produto. Pode ser o código de barras ou um código de referência.`,
              <BarcodeIcon tipo="GERAL" />,
              'Código',
              <BarcodeIcon tipo="GERAL" />,
              getFormPreCadastroCodigo(),
              undefined,
              avancarButton
            )
          );
          break;
        case EnumTelaProduto.Preco:
          ret.push(
            new FormStep(
              'Preço',
              `Informe os preços do medicamento!`,
              <DinheiroIcon tipo="GERAL" />,
              'Preço',
              <DinheiroIcon tipo="GERAL" />,
              getFormMedicamentoPreco(),
              voltarButton,
              avancarButton
            )
          );
          break;
        case EnumTelaProduto.Produto:
          ret.push(
            new FormStep(
              `Medicamento`,
              `Informe os dados do Medicamento.`,
              <ProdutoIcon tipo="GERAL" />,
              `Medicamento`,
              <ProdutoIcon tipo="GERAL" />,
              getFormPreCadastroNomeMedida(),
              voltarButton,
              avancarButton
            )
          );
          break;
        case EnumTelaProduto.Revisao:
          ret.push(
            new FormStep(
              'Revisão',
              `Revise as informações do Medicamento.`,
              <OkIcon tipo="GERAL" />,
              'Revisão',
              <OkIcon tipo="GERAL" />,
              getFormProdutoCompleto(),
              cadastrarNovoButton,
              cadastrarEditarButton
            )
          );
          break;
        case EnumTelaProduto.Tributacao:
          ret.push(
            new FormStep(
              'Tributação',
              `Informe agora as informações tributárias do Medicamento.`,
              <ImpostoIcon tipo="GERAL" />,
              'Tributação',
              <ImpostoIcon tipo="GERAL" />,
              getFormPreCadastroNcm(),
              voltarButton,
              avancarButton
            )
          );
          break;
      }
      i++;
    }

    // if (ret.length > 0) {
    //   ret[ret.length - 1].previousButton = voltarButton;
    //   ret[ret.length - 1].nextButton = cadastrarEditarButton;
    //   ret[0].previousButton = undefined;
    // }

    return ret;
  }, [
    retTelaIndex,
    getFormPreCadastroCodigo,
    voltarButton,
    avancarButton,
    getFormMedicamentoPreco,
    getFormPreCadastroNomeMedida,
    getFormProdutoCompleto,
    cadastrarNovoButton,
    cadastrarEditarButton,
    getFormPreCadastroNcm
  ]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep
    },
    formArray: getFormArray,
    carregando: loading
  };
};
