import { PromocaoModel } from 'model/api/gestao/promocao/promocao-model';
import { guidEmpty } from 'utils/guid-empty';
import { toDateString } from 'utils/to-date';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IStateStore {
  step: number;
  promocao: PromocaoModel;
}

interface ISetPromocaoStep1 {
  nome: string;
}

interface ISetPromocaoStep2 {
  dataInicial: string;
  dataFinal: string;
  horarioInicial: number;
  horarioFinal: number;
  diasSemana: number;
}

interface IPromocaoStore {
  state: IStateStore;
  setPromocaoStep1: (data: ISetPromocaoStep1) => void;
  setPromocaoStep2: (data: ISetPromocaoStep2) => void;
  resetStorePromocao: () => void;
  setPromocao: (data: PromocaoModel) => void;
}

export const usePromocaoDadosStore = create<IPromocaoStore>()(
  persist(
    (set) => ({

      state: {
        step: 0,
        promocao: new PromocaoModel()
      },

      setPromocaoStep1: (data: ISetPromocaoStep1) => {
        set((state) => ({
          state: {
            ...state.state,
            promocao: {
              ...state.state.promocao,
              ...data
            }
          }
        }));
      },

      setPromocaoStep2: (data: ISetPromocaoStep2) => {
        set((state) => ({
          state: {
            ...state.state,
            promocao: {
              ...state.state.promocao,
              dataInicial: data.dataInicial,
              dataFinal: data.dataFinal,
              horarioInicial: data.horarioInicial,
              horarioFinal: data.horarioFinal,
              diasSemana: data.diasSemana
            }
          }
        }));
      },

      resetStorePromocao() {
        set(() => ({
          state: {
            step: 0,
            promocao: new PromocaoModel(
              guidEmpty(),
              '',
              toDateString(new Date(), 'yyyy-MM-DD'),
              toDateString(new Date(), 'yyyy-MM-DD'),
              0,
              2359,
              127,
              true
            )
          }
        }));
      },
      setPromocao(data: PromocaoModel) {
        set(() => ({
          state: {
            step: 0,
            promocao: {
              ...data,
              dataInicial: data.dataInicial.length > 0 ? data.dataInicial.split('T')?.[0] ?? '' : '',
              dataFinal: data.dataFinal.length > 0 ? data.dataFinal.split('T')?.[0] ?? '' : ''
            }
          }
        }));
      }
    }),
    {

      name: 'promocao-cadastro-store',
    }
  )
);
