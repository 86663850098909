import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    overflowY: 'auto',
    flexGrow: 2,
    paddingBottom: theme.spacing(2)
  },
  acoes: {
    paddingBottom: theme.spacing(3)
  },
  content: {
    width: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  backgroundWhite: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4, 2)
  },
  indicadorProdutos: {
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.background.default,
      width: '100%',
      textAlign: 'center',
    },
    fontSize: '16px',
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    '& p': {
      fontWeight: 500
    }
  },
  form: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  loading: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    zIndex: 20
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '& p': {
      whiteSpace: 'line-break',
    }
  },
  checkboxCard: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  noPadding: {
    padding: 0,
  }
}));
