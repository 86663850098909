import { useEffect, useMemo, useRef } from 'react';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { PublicPageHeader } from 'views/components/headers';
import { CircularLoading, useThemeQueries } from 'views';
import { useStyles } from './apelido-page-styles';
import { AvancarIcon } from '../../../components/icons/avancar-icon';
import { LogoutIcon, UserIcon } from 'views/components/icons';
import { FormApelido } from 'views/components/form/master/apelido/form-apelido';
import { ApelidoFormModel } from 'model/app/forms/master/apelido-form';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useGetMeuUsuario, usePutMeuUsuario } from 'data/api/gestao/meu-usuario';
import { MeuUsuarioModel, MeuUsuarioPutModel } from 'model/api/gestao/master/meu-usuario';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { picker } from 'utils/picker';
import { useHistory } from 'react-router-dom';

const ApelidoPage = () => {
  // STATES E REFS
  const apelidoRefs = useRef<DefaultFormRefs<ApelidoFormModel>>(null);
  const meuUsuario = useRef<MeuUsuarioModel>(new MeuUsuarioModel());

  // CALL API
  const { getMeuUsuario, carregando: carregandoMeuUsuario } =
    useGetMeuUsuario();
  const { putMeuUsuario, carregando: carregandoPutMeuUsuario } =
    usePutMeuUsuario();

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const { setUserApelido } = useSessaoAtual()
  const loading = carregandoMeuUsuario || carregandoPutMeuUsuario;
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const usuario = await getMeuUsuario();

        if (usuario.erro) {
          throw usuario.erro;
        }

        const ret = usuario.resultado?.data as MeuUsuarioModel;

        if (ret.apelido && ret.apelido.length >= 3) {
          setUserApelido(ret.apelido)
          history.push('/')
          return
        }

        meuUsuario.current = ret as MeuUsuarioModel;
      } catch (e: any) {
        showToast('error', e?.message);
      }
    })();
  }, [getMeuUsuario, history, setUserApelido, showToast]);

  const buttons = useMemo(() => {
    return (
      <div className={classes.Buttons}>
        <Box mt={3}>
          <Grid spacing={2} container>
            <Grid item xs={4}>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                fullWidth={true}
                disabled={false}
                onClick={() => history.push('/logout')}
              >
                <LogoutIcon tipo="BUTTON" />
                Sair
              </Button>
            </Grid>

            <Grid item xs={8}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={false}
                onClick={() => apelidoRefs.current?.submitForm()}
              >
                <AvancarIcon tipo="BUTTON_PRIMARY" />
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }, [classes.Buttons, history]);

  const handleSubmitFormApelido = async (modelo: ApelidoFormModel) => {
    try {
      const usuarioPicker = picker<MeuUsuarioPutModel>(modelo, meuUsuario.current);

      if (usuarioPicker.nome.length <= 0) {
        usuarioPicker.nome = usuarioPicker.apelido
      }

      const usuarioPut = await putMeuUsuario(usuarioPicker);

      if (usuarioPut.erro) {
        throw usuarioPut.erro
      }
      setUserApelido(modelo.apelido)
      history.push('/');
    } catch (e: Error | any) {
      showToast("error", e?.message);
    }
  };

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      {loading ? <CircularLoading tipo="FULLSIZED" /> : null}
      <Grid className={classes.root} container justifyContent="center">
        <Grid item xs={11} md={10} lg={6}>
          <div className={classes.stepperOuterContainer}>
            <div className={classes.Content}>
            </div>
            <div className={classes.divForm}>
              <div className={classes.Fix}>
                <div className={classes.content}>
                  <div>
                    <Typography
                      align="center"
                      className={classes.Title}
                      variant="h3"
                      color="primary"
                    >
                      <UserIcon tipo="GERAL" />
                      Apelido
                    </Typography>
                    <Box mt={3}>
                      <Typography align="center" variant="body1">
                        Escolha um apelido, o mesmo deverá ser único, pense bem
                        na sua opção.
                      </Typography>
                    </Box>
                  </div>
                  <div className={classes.Form}>
                    <FormApelido
                      showLoading={false}
                      ref={apelidoRefs}
                      loading={false}
                      onSubmit={handleSubmitFormApelido}
                    />
                  </div>
                  {!isMobile && buttons}
                </div>
              </div>
              {isMobile && buttons}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ApelidoPage;
