import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const ApagarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={{ padding: "20%", ...props.style }}
        fill={props.fill}
      >
        <path d="M 12.542969 10 L -0.316406 25 L 12.542969 40 L 46 40 C 48.199219 40 50 38.199219 50 36 L 50 14 C 50 11.800781 48.199219 10 46 10 Z M 13.457031 12 L 46 12 C 47.117188 12 48 12.882813 48 14 L 48 36 C 48 37.117188 47.117188 38 46 38 L 13.460938 38 L 2.316406 25 Z M 22.707031 18.292969 L 21.292969 19.707031 L 26.585938 25 L 21.292969 30.292969 L 22.707031 31.707031 L 28 26.414063 L 33.292969 31.707031 L 34.707031 30.292969 L 29.414063 25 L 34.707031 19.707031 L 33.292969 18.292969 L 28 23.585938 Z"></path>
      </DefaultIcon >
    </>
  );
};
