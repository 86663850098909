import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const PDFIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M7 2V48H43V14.5938L42.7188 14.2812L30.7188 2.28125L30.4062 2H7ZM9 4H29V16H41V46H9V4ZM31 5.4375L39.5625 14H31V5.4375ZM15 22V24H35V22H15ZM15 28V30H31V28H15ZM15 34V36H35V34H15Z" />
      </DefaultIcon>
    </>
  );
};
