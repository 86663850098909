import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        alignItems: 'stretch',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
    },
    resumoContainer: {
        flex: '1 1 100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        aligmItems: 'stretch',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    imagemContainer: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 'auto',
            marginLeft: theme.spacing(0),
            marginBottom: theme.spacing(2),
        },
    },
    tipoStatus: {
        padding: `6px 2px`,
        color: '#FFF',
        fontSize: '.6rem',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(.5),
        "&:last-child": {
            marginRight: 0
        },
        [theme.breakpoints.down('sm')]:{
            padding: theme.spacing(1)
        }
    },
    bgInfo: {
        background: theme.palette.info.main
    },
    bgSuccess: {
        background: theme.palette.success.main
    },
    bgError: {
        background: theme.palette.error.dark
    },
    bgPrimaryDark: {
        background: theme.palette.primary.dark
    },
    tagsContainer:{
        flexDirection:'column',
        textAlign:'center',
        margin: theme.spacing( 1, 0, 0, 2) ,
        height:'30px',
        [theme.breakpoints.down('sm')]:{
            margin:theme.spacing( 0, 1, 2, 1)
        }
    }
}));
