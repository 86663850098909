import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden',
        flex: 1,
    },
    header: {
        flex: '0 1 auto',
    },
    content: {
        background: "#fff",
        height: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    inputArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 260,
        maxWidth: 395,
        borderBottom: '2px solid#D8D8D8',
    },
    container: {
        flex: '1 1 100%',
        display: 'flex',
        overflowX: 'hidden',
        overflow: 'auto',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column',
    },
    containerTitle: {
        margin: '24px 0 0 0'
    },
    title: {
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '30px',
        textAign: 'center',
        color: theme.palette.secondary.main,
    },
    subtitle: {
        fontFamily: 'Montserrat',
        fontSize: '18px',
        lineHeight: '24px',
        textAlign: 'center',
        margin: '0 35px',
    },
    valor: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        height: '56px',
        borderBottom: '1px solid #666',
    },
    inputText: {
        fontSize: '38px',
        lineHeight: '50px',
        fontWeight: 600
    },
    placeholder: {
        fontSize: '16px',
        lineHeight: '50px',
    },
    field: {
        outline: 0,
        border: '0',
        fontFamily: 'Montserrat',
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        padding: '0px 0',
        boxSizing: 'border-box',
        boxShadow: 'none',

        '& .PrivateNotchedOutline-root-84': {
            border: '0',
            outline: 0,
            boxShadow: 'none',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },

        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '0',
            outline: 0,
        },

        '& fieldset': {
            outline: 0,
            border: '0'
        }
    },
    button: {
        width: '300px',
        height: '50px',
    },
}));
