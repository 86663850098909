import { PagsModel } from 'model/api/gestao/venda/venda-completa-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DadosDoPagamentoModal } from 'views/components/modals/pagamentos/pagamento-modal';

export const CadastroDadosDoPagamentoModal = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState({
    id: '',
    aberto: false,
    pagCb: {
      valor: 0,
      callback: (pag: PagsModel, credenciais?: string, credenciado?: boolean) => new Promise<boolean>(() => true)
    },
    isPagFatura: false
  });

  const modalAlterado = useCallback(({ id, openned, pagCb, isPagFatura }: any) => {
    setModalState({
      id: id,
      aberto: openned,
      pagCb: pagCb,
      isPagFatura
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.DadosPagamento, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DadosPagamento, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <DadosDoPagamentoModal
      openned={modalState.aberto}
      key="dadosPAgamento"
      id={modalState.id}
      pagCb={modalState.pagCb}
      isPagFatura={modalState.isPagFatura}
    />
  );
};
