import { Box, Button, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { OkIcon } from 'views/components/icons';
import { useCadastros, useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormInformacaoAdicionalValidation } from './dialog-informacao-adicional-validations';
import { isEmpty } from 'lodash';
import { DialogInformacaoAdicionalProps } from './dialog-informacao-adicional-props';
import { useThemeQueries } from 'views/theme';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCallback } from 'react';
import { useStyles } from './dialog-informacao-adicional-styles';
import { useMovRota } from 'services/app/hooks/mov-rota';

class InfAdicionalForm {
  constructor(
    public infAdicional: string = ''
  ) { }
}

export const DialogInformacaoAdicional = ({
  ...props
}: DialogInformacaoAdicionalProps) => {

  const { theme } = useThemeQueries()
  const { fecharDialogInformacaoAdicional } = useCadastros()
  const { getMov, editarInfAdicional } = useMovAtual()
  const { showToast } = useToastSaurus()
  const classes = useStyles()
  const { redirectCarrinho } = useMovRota();
  const { formInformacaoAdicionalYupValidation } = useFormInformacaoAdicionalValidation()

  const {
    handleSubmit,
    control,
  } = useForm<InfAdicionalForm>({
    defaultValues: {
      infAdicional: getMov()?.infAdicional ?? ''
    },
    resolver: yupResolver(formInformacaoAdicionalYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });


  const submit = useCallback(async (value: InfAdicionalForm) => {
    try {

      await editarInfAdicional(value.infAdicional ?? '')
      await redirectCarrinho();

      if (value.infAdicional.length > 0) {
        showToast('success', 'Informação Adicional atualizada!')
      }
      fecharDialogInformacaoAdicional()
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [editarInfAdicional, fecharDialogInformacaoAdicional, redirectCarrinho, showToast])

  return (
    <DialogSaurus
      aberto={props.aberto}
      titulo="Informação Adicional"
      tamanho="xs"
      isButtonTitleClose={props.permiteFechar}
      colorTitulo={theme.palette.primary.main}
      handleClickClose={fecharDialogInformacaoAdicional}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Typography>
          <Box mb={2}>
            Digite uma informação da venda que seja relevante para o cliente ou para a produção.
          </Box>
        </Typography>
        <div>
          <Controller
            name='infAdicional'
            control={control}
            render={({ field, fieldState }) => (
              <TextFieldSaurus
                {...field}
                placeholder={'Exemplo: Próximo à Padaria do Zé'}
                error={!isEmpty(fieldState.error)}
                multiline
                minRows={2}
                allowSubmit
                helperText={fieldState.error
                  ? fieldState.error.message
                  : undefined}
              />
            )}
          />
        </div>

        <div className={classes.containerButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            rounded
            type="submit"
          >
            <OkIcon tipo='BUTTON_PRIMARY' />
            Confirmar
          </Button>
        </div>
      </form>
    </DialogSaurus>
  )
};
