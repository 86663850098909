import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses, useThemeQueries } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormEditProcessosValidation } from './form-edit-processos-validation';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ProcessosFormModel } from 'model/api/gestao/processos/processos-model';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useGetEquipamentos } from 'data/api/gestao/equipamento';
import { useStyles } from './form-edit-processos-styles'
import { KeyValueModel } from 'model';
import { FecharIcon } from 'views/components/icons';
import { picker } from 'utils/picker';
import { StatusProcessoTipoSelect } from 'data/mocks/status-processo-tipo-mock';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { Card } from '@material-ui/core';

interface Props extends DefaultFormProps<ProcessosFormModel> {
  onlyImpressor?: boolean
}

export const FormEditProcessos = forwardRef<
  DefaultFormRefs<ProcessosFormModel>,
  Props
>(({ onlyImpressor = false, ...props }: Props, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormEditProcessosValidationYup } = useFormEditProcessosValidation();
  const refInputProcesso = useRef<HTMLInputElement>(null);
  const { theme } = useThemeQueries()
  const { getEquipamentos, carregando: carregandoGet } = useGetEquipamentos()
  const { fecharCadastroSetores, abrirCadastroEquipamentos } = useCadastros()
  const [equipamentosSelecionados, setEquipamentosSelecionados] = useState<Array<string>>(props.model?.equipamentosId ?? [])
  const { getEmpresaSelecionada } = useSessaoAtual()
  const { showToast } = useToastSaurus()
  const [model, setModel] = useState<ProcessosFormModel>(
    props.model ?? new ProcessosFormModel(),
  );

  const classes = useStyles()
  const [equipamentosMock, setEquipamentosMock] = useState<Array<KeyValueModel>>([])

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<ProcessosFormModel>({
    defaultValues: { ...model },
    resolver: yupResolver(FormEditProcessosValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const mock: Array<KeyValueModel> =
    equipamentosMock
      ?.filter(x => !equipamentosSelecionados.includes(x.Key))

  const onSubmit = (values: ProcessosFormModel) => {
    const processo = picker<ProcessosFormModel>(values, props.model)
    processo.equipamentosId = equipamentosSelecionados
    props.onSubmit(processo, props.model);
  };

  const getEquipamentosWrapper = useCallback(async () => {
    const res = await getEquipamentos(getEmpresaSelecionada()?.Id ?? '')

    if (res.erro) throw res.erro

    if (!res.resultado) {
      return []
    }

    return res.resultado.data?.list as Array<ImpressoraModel>
  }, [getEmpresaSelecionada, getEquipamentos])

  useEffect(() => {
    (async () => {
      try {
        const equipamentosArray = await getEquipamentosWrapper()
        setEquipamentosMock(equipamentosArray.map(x => new KeyValueModel(x.id, x.descricao)))
      } catch (err: any) {
        showToast('error', err.message)
      }
    })()
  }, [getEquipamentosWrapper, showToast])

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...props.model });

      refInputProcesso.current?.focus();
    },
    fillForm: (model: ProcessosFormModel) => {
      reset({ ...model });
      setModel({ ...model });
      setTimeout(() => {
        refInputProcesso.current?.focus();
      }, 500);
    },
  }));

  const removeEquipamento = useCallback((value: string) => {
    const modulo =
      equipamentosSelecionados
        .filter(item => item !== value)
    setEquipamentosSelecionados(modulo)
  }, [equipamentosSelecionados])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={!onlyImpressor ? 8 : 12}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Descrição do Processo"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={'EX: Finalizado'}
                    error={Boolean(
                      errors.descricao && errors.descricao.message,
                    )}
                    inputProps={{ maxLength: 30 }}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('descricao')}
                  />
                )}
              />
            </Grid>
            {
              !onlyImpressor && (
                <>
                  <Grid item xs={4}>
                    <Controller
                      name="processoProducao"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          autoComplete='new-password'
                          tipo="NUMERO"
                          disabled={props.loading}
                          fullWidth
                          variant="outlined"
                          label="Ordem"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={'EX: 01'}
                          error={Boolean(
                            errors.processoProducao &&
                            errors.processoProducao.message,
                          )}
                          helperText={
                            touchedFields.processoProducao || errors.processoProducao
                              ? errors.processoProducao?.message
                              : undefined
                          }
                          {...field}
                          value={getValues('processoProducao')}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          label="Tipo"
                          variant="outlined"
                          fullWidth
                          disabled={props.loading}
                          conteudo={StatusProcessoTipoSelect}
                          error={Boolean(errors.status && errors.status.message)}
                          helperText={
                            touchedFields.status || errors.status
                              ? errors.status?.message
                              : undefined
                          }
                          {...field}
                          onChange={(event) => {
                            const item = StatusProcessoTipoSelect.filter(
                              (item) => item.Key === event.target.value
                            )[0]?.Key;

                            setValue('status', item);
                          }}
                          value={getValues('status')}
                          defaultValue={getValues('status')}
                        />
                      )}
                    />
                  </Grid>
                </>
              )
            }
            <Grid item xs={12}>
              <Box >
                {carregandoGet && <CircularLoading tipo="FULLSIZED" />}
                <SelectSaurus
                  fullWidth
                  disabled={mock.length <= 0}
                  label="Adicionar Equipamentos"
                  variant="outlined"
                  conteudo={mock}
                  value=""
                  helperText={mock.length <= 0 ?
                    <Typography variant='caption'>
                      Você não tem nenhum equipamento para selecionar. Para cadastrar um, <Link onClick={() => {
                        fecharCadastroSetores()
                        abrirCadastroEquipamentos('', '', true)
                      }} to='/impressoras'>clique aqui</Link>
                    </Typography> : ''}
                  onChange={(e) => {
                    const item = mock.filter(i => i.Key === e.target.value)
                    setEquipamentosSelecionados(prev => [...prev, item[0].Key])
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ padding: '0 8px 16px' }}>
                <Box pb={1}>
                  <Typography variant="caption" color="textSecondary">
                    Equipamentos Selecionados
                  </Typography>
                </Box>
                <Grid container spacing={2} style={{ minHeight: 52, borderRadius: 8, border: `1px solid ${theme.palette.action.hover}` }}>
                  {equipamentosSelecionados.map(equipamento => {
                    return (
                      <Grid item>
                        <Card className={classes.card}>
                          <div className={classes.cardContent}>
                            <Typography className={classes.moduloNome} variant="body2">
                              {equipamentosMock?.filter(item => item.Key === equipamento)[0]?.Value}
                            </Typography>
                            <FecharIcon tipo="BUTTON" style={{ width: 16, cursor: 'pointer' }} onClick={() => removeEquipamento(equipamento)} />
                          </div>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid >
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form >
      </div >
    </>
  );
});
