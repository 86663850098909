import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormDocReferenciadoValidation } from './form-doc-referenciado-validation';
import { FormDocReferenciadoProps } from './form-doc-referenciado-props';
import { DocReferenciadoFormModel } from 'model/app/forms/entrada/doc-referenciado-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TipoDocRefMock } from 'data/mocks/tipo-doc-ref-mock';
import { EnumTipoDocRef, RefEcfModel, RefNfModel, RefNfpModel } from 'model/api/gestao/venda/venda-completa-model';
import { FormRefCTe } from './components/form-ref-cte/form-ref-cte';
import { FormRefECF } from './components/form-ref-ecf/form-ref-ecf';
import { FormRefNFP } from './components/form-ref-nfp/form-ref-nfp';
import { FormRefNFe } from './components/form-ref-nfe/form-ref-nfe';
import { FormRefNF } from './components/form-ref-nf/form-ref-nf';
import { picker } from 'utils/picker';
import { stringNumeros } from 'utils/string-numeros';
import { Box, Button, Grid } from 'views/design-system';

export const FormDocReferenciado = forwardRef<
    DefaultFormRefs<DocReferenciadoFormModel>,
    FormDocReferenciadoProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formDocReferenciadoValidationYup } = useFormDocReferenciadoValidation();
    const [model, setModel] = useState<DocReferenciadoFormModel>(new DocReferenciadoFormModel())
    const modelFormRef = useRef<DocReferenciadoFormModel>(new DocReferenciadoFormModel())
    const [, setAtt] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        formState,
        reset,
        setValue,
        getValues
    } = useForm<DocReferenciadoFormModel>({
        resolver: yupResolver(formDocReferenciadoValidationYup),
        criteriaMode: 'firstError',
        defaultValues: {
            ...model
        },
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const onSubmit = (values: DocReferenciadoFormModel) => {
        const doc: DocReferenciadoFormModel = {
            refCTe: values.refCTe,
            refECF: values.refECF ? picker(values.refECF, new RefEcfModel()) : null,
            refNF: values.refNF ? picker(values.refNF, new RefNfModel()) : null,
            refNFe: values.refNFe,
            refNFP: values.refNFP ? picker(values.refNFP, new RefNfpModel()) : null,
            tipo: values.tipo
        }
        if (doc.refNF) doc.refNF.cnpj = stringNumeros(doc.refNF.cnpj)
        props.onSubmit(doc, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: DocReferenciadoFormModel) => {
            reset({ ...model })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    const propsComp = {
        control: control,
        formState: formState,
        setValue: setValue,
        loading: props.loading
    }

    const switchForm = {
        [EnumTipoDocRef.NfeNfcSat]: <FormRefNFe {...propsComp} />,
        [EnumTipoDocRef.ConhecimentoTE]: <FormRefCTe {...propsComp} />,
        [EnumTipoDocRef.ECF]: <FormRefECF {...propsComp} />,
        [EnumTipoDocRef.NfmTalonario]: <FormRefNF {...propsComp} />,
        [EnumTipoDocRef.NfPR]: <FormRefNFP {...propsComp} />,
    }

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box mb={2}>
                                        <Controller
                                            name="tipo"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={TipoDocRefMock}
                                                    disabled={props.loading}
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Tipo de Documento"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.tipo && errors.tipo.message)}
                                                    helperText={
                                                        touchedFields.tipo || errors.tipo
                                                            ? errors.tipo?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                    onChange={(event) => {
                                                        const item = TipoDocRefMock.filter(x => x.Key === event.target.value)
                                                        reset({ ...new DocReferenciadoFormModel() })
                                                        setValue('tipo', item[0].Key)
                                                        setAtt(prev => !prev)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {switchForm[getValues('tipo')]}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box >
    );
});
