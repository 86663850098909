import { useEffect, useState, useCallback } from 'react';
import { useStyles } from './marca-list-styles';
import { MarcaListData } from './marca-list-data';

import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { useHistory } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { useGetMarcas } from 'data/api/gestao/marca/get-marcas';
import { isEmpty } from 'lodash';
import { Grid } from 'views/design-system';

export const MarcaList = () => {
    const classes = useStyles();
    const { abrirCadastroMarca } = useCadastros();
    const { getMarcas, carregando } = useGetMarcas()
    const { addHandler, removeHandler } = useEventTools()

    const { showToast } = useToastSaurus();
    const history = useHistory();
    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<MarcaModel>(),
    });
    const [modalEditAberto, setModalEditAberto] = useState(false);

    const [selectedList, setSelectedList] = useState<Array<string>>([]);

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<MarcaModel>,
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
            });
        },
        [],
    );

    const urlParams = new URLSearchParams(history.location.search)
    const filtros = {
        nome: urlParams.get('nome') || '',
        cnpjfab: urlParams.get('cnpjfab') || '',
    }

    const search = useCallback(
        async (newPage: number) => {
            try {
                const query =
                    "" +
                    (!isEmpty(filtros.nome) ? "&nome=" + filtros.nome : "") +
                    (!isEmpty(filtros.nome) ? "&cnpjfab=" + filtros.cnpjfab : "");

                const res = await getMarcas(query, newPage);
                if (res.erro) throw res.erro;

                //se o index for maior que as paginas ele busca a ultima
                if (
                    res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
                    res.resultado?.data?.totalResults > 0
                ) {
                    search(res.resultado?.data?.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data.pageIndex,
                    res.resultado?.data.totalPages,
                    res.resultado?.data.totalResults,
                    res.resultado?.data.list,
                );
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [filtros.nome, filtros.cnpjfab, getMarcas, fillResult, showToast],
    );

    const modalEdit = useCallback(({ aberto }: any) => {
        setModalEditAberto(aberto)
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.MarcaModal, modalEdit)

        return () => removeHandler(AppEventEnum.MarcaModal, modalEdit)
    }, [addHandler, modalEdit, removeHandler])

    useEffect(() => {
        if (!modalEditAberto) {
            search(queryStatus.page);
        }
    }, [modalEditAberto, queryStatus.page, search]);

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [search, queryStatus.totalPages],
    );

    const onCardSelected = (id: string) => {
        abrirCadastroMarca(id, history.location.pathname, true);
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryStatus.totalPages}
                        totalRegisters={queryStatus.totalResults}
                        currentPage={queryStatus.page}
                    />
                    <Grid item xs={12} className={classes.listContainer}>
                        <MarcaListData
                            carregando={carregando}
                            list={queryStatus.list}
                            selectedList={selectedList}
                            onCardSelected={onCardSelected}
                            onCardChecked={onCardChecked}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
