import { Box, Button } from "@material-ui/core"
import { useStyles } from './pessoa-pesquisa-styles'
import { PessoaIcon } from "views/components/icons/pessoa-icon";
import { OkIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { PessoaListSearchProps } from "../pessoa-list/pessoa-list-search-props";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { StatusCadastroMockFiltro, TpCadastroFarmaciaMockTodos } from "data/mocks";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { EnumCadastroStatus } from "model";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";

interface ITelaState {
    tipoPessoa: boolean;
    generico: boolean;
    status: boolean;
}

interface PessoaPesquisaModalProps {
    openned: boolean;
    filtros: PessoaListSearchProps;
    esconder?: {
        tpCadastro?: boolean
        status?: boolean
    }
    callback?: (filtos: PessoaListSearchProps) => {}
}

export const PessoaPesquisaModal = ({
    openned,
    filtros,
    esconder,
    callback
}: PessoaPesquisaModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { fecharPessoasFiltroModal } = useFiltrosModais();
    const history = useHistory();

    const [telas, setTelas] = useState<ITelaState>({
        tipoPessoa: false,
        generico: false,
        status: false,

    })
    const [localProps, setLocalProps] = useState<PessoaListSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (telas.tipoPessoa) {
            setTelas(prev => ({
                ...prev,
                tipoPessoa: false
            }))
            return
        }
        if (telas.generico) {
            setTelas(prev => ({
                ...prev,
                generico: false
            }))
            return
        }
        if (telas.status) {
            setTelas(prev => ({
                ...prev,
                status: false
            }))
            return
        }
        fecharPessoasFiltroModal(false)
    }, [fecharPessoasFiltroModal, telas.generico, telas.status, telas.tipoPessoa])

    const onSubmit = useCallback((localProps: PessoaListSearchProps) => {
        const searchs: Array<string | null> = [
            localProps.termo.length > 0 ? `termo=${localProps.termo}` : null,
        ]

        localProps.tpCadastro.forEach((tp) => {
            searchs.push(`tpCadastro=${tp}`)
        })
        localProps.status.forEach((tp) => {
            searchs.push(`status=${tp}`)
        })

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        if (callback) {
            callback({
                status: localProps.status,
                tpCadastro: localProps.tpCadastro,
                termo: localProps.termo
            });
        } else {
            if (history.location.pathname.includes('pessoas')) {
                history.push({
                    pathname: '/pessoas',
                    search: query
                })
            }
        }

        fecharPessoasFiltroModal(true, localProps)
    }, [callback, fecharPessoasFiltroModal, history])


    const labelsTpCadastro = localProps.tpCadastro.map(t => TpCadastroFarmaciaMockTodos.find(item => item.Key === t)?.Value || '').join(', ');
    const labelsStatus = localProps.status.map(t => StatusCadastroMockFiltro.find(item => item.Key === t)?.Value || '').join(', ');

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    <Box className={classNames(classes.container)}>
                        {!esconder?.tpCadastro && (
                            <CardFiltro<PessoaListSearchProps, ITelaState>
                                icon={<PessoaIcon />}
                                nome="Tipo de Pessoa"
                                propName="tpCadastro"
                                setState={setLocalProps}
                                setTela={setTelas}
                                telaName="tipoPessoa"
                                value={labelsTpCadastro}
                                valorPadrao={[-1]}
                                valorAtual={localProps.tpCadastro}
                            />
                        )}
                        {!esconder?.status && (
                            <CardFiltro<PessoaListSearchProps, ITelaState>
                                icon={<PessoaIcon />}
                                nome="Situação"
                                propName="status"
                                setState={setLocalProps}
                                setTela={setTelas}
                                telaName="status"
                                value={labelsStatus}
                                valorPadrao={[-1]}
                                valorAtual={localProps.status}
                            />
                        )}
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => {
                                onSubmit({
                                    termo: '',
                                    status: [EnumCadastroStatus.LIBERADO, EnumCadastroStatus.BLOQUEADO],
                                    tpCadastro: [-1]
                                });
                            }}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>
                        {telas.tipoPessoa && (
                            <RadioList
                                handleSubmit={async (tp) => { }}
                                handleSubmitMultiCheck={(select: any) => {
                                    if (select) {
                                        const arrOriginal = TpCadastroFarmaciaMockTodos.filter(x => x.Key !== -1).map(x => x.Key);
                                        if (
                                            select.includes(-1) ||
                                            isEqual(arrOriginal, select)
                                        ) {
                                            select = [-1];
                                        }
                                        setLocalProps(prev => ({
                                            ...prev,
                                            tpCadastro: select
                                        }))
                                    }
                                    setTelas(prev => ({
                                        ...prev,
                                        tipoPessoa: false
                                    }))
                                }}
                                selecionado={localProps.tpCadastro}
                                mock={TpCadastroFarmaciaMockTodos}
                                multiCheck
                            />
                        )}
                        {telas.status && (
                            <RadioList
                                handleSubmit={async (tp) => { }}
                                handleSubmitMultiCheck={async (select: any) => {
                                    const arrOriginal = StatusCadastroMockFiltro.filter(x => x.Key !== -1).map(x => x.Key);
                                    if (select) {
                                        if (
                                            select.includes(-1) ||
                                            isEqual(arrOriginal, select)
                                        ) {
                                            select = [-1];
                                        }
                                        setLocalProps(prev => ({
                                            ...prev,
                                            status: select
                                        }))
                                    }
                                    setTelas(prev => ({
                                        ...prev,
                                        status: false
                                    }))
                                }}
                                selecionado={localProps.status}
                                multiCheck
                                mock={StatusCadastroMockFiltro}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal>

    )
}