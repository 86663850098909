import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';

import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles';
import { AvancarIcon, VoltarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useStyles } from '../opcoes-entrada-styles';
import { DadosNfeIcon } from 'views/components/icons/dados-nfe-icon';
import { DadosEntradaProdutosIcon } from 'views/components/icons/dados-entrada-produtos-icon';
import { DadosEntradaPagamentosIcon } from 'views/components/icons/dados-entrada-pagamentos-icon';
import { DadosEntradaResumoItensIcon } from 'views/components/icons/dados-entrada-resumo-itens-icon';
import { SimpleFormStep } from 'model/app';
import { Stepper } from 'views/components/stepper';
import FormEntradaCadastroNFE from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-nfe';
import FormEntradaCadastroResumoItens from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-resumo-itens';
import { AddOutlineIcon } from 'views/components/icons/add-outline-icon';
import FormEntradaCadastroPagamentos from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-pagamentos';
import { CardProdutoImportacaoXML } from 'views/components/cards/card-produto-importacao-xml/card-produto-importacao-xml';
import { CardPagamentosImportacaoXML } from 'views/components/cards/card-pagamentos-importacao-xml/card-produto-importacao-xml';
import {
  PagsModel,
} from 'model/api/gestao/venda/venda-completa-model';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { InformacaoDetalhadaIcon } from 'views/components/icons/informacao-detalhada-icon';
import {
  OpcoesEntradaImportacaoXMLStepperTemplateProps,
  Steppers,
  tipoEdit
} from './opcoes-entrada-importacao-xml-template-interfaces';
import { VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { useThemeQueries } from 'views/theme';
import { newGuid } from 'utils/new-guid';
import { useSalvarXML } from '../../../hooks/salvar-xml';
import OpcoesEntradaConfirmarStatus from './opcoes-entrada-confirmar-status';

export default function OpcoesEntradaImportacaoXMLStepperTemplate({
  activeStep,
  carregando: carregandoExterno,
  setActiveStep,
  setPaymentEditing,
  setEditing
}: Readonly<OpcoesEntradaImportacaoXMLStepperTemplateProps>): React.ReactElement {
  const cadClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { saveChangesXML, carregando: carregandoSalvar } = useSalvarXML();

  const carregando = carregandoExterno || carregandoSalvar;

  const steps = [
    new SimpleFormStep(
      'Dados da NF-e',
      isMobile ? 'Dados' : 'Dados da NF-e',
      <DadosNfeIcon viewBox="0 0 25 25" />
    ),
    new SimpleFormStep(
      'Produtos',
      'Produtos',
      <DadosEntradaProdutosIcon viewBox="0 0 24 24" />
    ),
    new SimpleFormStep(
      'Pagamentos',
      'Pagamentos',
      <DadosEntradaPagamentosIcon viewBox="0 0 24 22" />
    ),
    new SimpleFormStep(
      'Resumo',
      'Resumo',
      <DadosEntradaResumoItensIcon viewBox="0 0 25 25" />
    )
  ];

  const { control, getValues } = useFormContext<VendaCompletaXMLModel>();


  const { fields: produtos } = useFieldArray({
    control: control,
    name: 'infMov.prod',
    keyName: '_id'
  });

  const { fields: pagamentos, append: appendPagamento } = useFieldArray({
    control: control,
    name: 'infMov.pag',
    keyName: '_id'
  });

  const valorRestante = useMemo(() => {
    return getValues('infMov.total.vnf') - pagamentos.reduce((a, b) => a + b.vPag, 0)
  }, [getValues, pagamentos])

  const handleShowResume = useCallback(() => {
    setEditing({
      tela: tipoEdit.Resume,
    });
  }, [setEditing]);

  const handleShowItens = useCallback(() => {
    setEditing({
      tela: tipoEdit.Itens,
      step: 0
    });
  }, [setEditing]);

  const handleNext = useCallback(async (e) => {
    if (activeStep !== Steppers.payments && activeStep !== Steppers.resume && activeStep !== Steppers.products) {
      await saveChangesXML(getValues())
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    e.preventDefault()
  }, [activeStep, getValues, saveChangesXML, setActiveStep]);

  const backStep = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [setActiveStep]);

  const handleBack = async () => {
    if (activeStep !== Steppers.payments && activeStep !== Steppers.resume && activeStep !== Steppers.products) {
      await saveChangesXML(getValues())
    }
    if (activeStep === Steppers.nfe) {
      handleShowResume();
    } else {
      backStep();
    }
  };

  const handleAddPayments = () => {
    const newpag = new PagsModel(newGuid());

    newpag.card.detPagId = newpag.id;

    appendPagamento(newpag);
    setPaymentEditing({ index: pagamentos.length, isCreating: true, remainingValue: valorRestante });
    setEditing({
      tela: tipoEdit.Pagamento
    });
  };

  const handleViewPayments = (index: number) => {
    setPaymentEditing({ index, isCreating: false, remainingValue: 0 });
    setEditing({
      tela: tipoEdit.Pagamento
    });
  };

  const leftArea = () => (
    <ButtonPrivateHeader
      icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
      tooltip="Menu"
      onClick={handleShowResume}
    ></ButtonPrivateHeader>
  );

  return (
    <>
      <Grid className={cadClasses.header}>
        <PrivatePageHeader
          title={steps[activeStep].stepLabel}
          leftArea={leftArea()}
        />
      </Grid>

      <Container maxWidth="md" className={classes.container}>
        <Stepper steps={steps} stepAtual={activeStep} />
        <Grid container spacing={2} className={classes.backgroundWhite}>

          {activeStep === Steppers.nfe ? (
            <Grid container spacing={2}>
              <FormEntradaCadastroNFE />
            </Grid>
          ) : null}

          {activeStep === Steppers.products ? (
            <>
              {produtos?.map((produto, i) => (
                <CardProdutoImportacaoXML
                  key={produto.id}
                  model={produto}
                  selected={false}
                  onClick={() => {
                    setEditing({
                      tela: tipoEdit.Itens,
                      step: i
                    });
                  }}
                />
              ))}
            </>
          ) : null}

          {activeStep === Steppers.payments ? (
            <>
              <Grid container spacing={2}>
                <FormEntradaCadastroPagamentos />
              </Grid>

              <Box className={classes.content}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box pb={1}>
                      <Typography variant="h6">Pagamentos</Typography>
                      <Divider />
                    </Box>
                  </Grid>
                  <Box className={classes.content}>
                    {pagamentos.map((pagamento, pagamentoIndex) => (
                      <CardPagamentosImportacaoXML
                        key={pagamento.id}
                        model={pagamento}
                        selected={false}
                        onClick={() => handleViewPayments(pagamentoIndex)}
                        index={pagamentoIndex}
                      />
                    ))}
                  </Box>
                </Grid>
              </Box>

              <Grid item xs={12}>
                <Button
                  disabled={carregando || valorRestante <= 0}
                  onClick={handleAddPayments}
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <AddOutlineIcon tipo="BUTTON" viewBox="0 0 25 25" />
                  Adicionar Pagamento
                </Button>
              </Grid>
            </>
          ) : null}

          {activeStep === Steppers.resume ? (
            <Grid container spacing={2}>
              <FormEntradaCadastroResumoItens />
              <Grid />
              <Grid item xs={12}>
                <OpcoesEntradaConfirmarStatus removePadding />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={carregando}
                  onClick={handleShowItens}
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <InformacaoDetalhadaIcon tipo="BUTTON" viewBox="0 0 25 25" />
                  Revisar Produtos
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Box pt={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                disabled={carregando}
                onClick={handleBack}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                type='button'
              >
                <VoltarIcon tipo="BUTTON" />
                {activeStep === Steppers.nfe ? 'Voltar' : 'Anterior'}
              </Button>
            </Grid>

            <Grid item xs={6}>
              {activeStep === Steppers.resume ? (
                <Button
                  disabled={carregando}
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <ConfirmarIcon tipo="BUTTON_PRIMARY" />
                  Concluir
                </Button>
              ) : (
                <Button
                  disabled={carregando}
                  onClick={handleNext}
                  type='button'
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Avançar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
