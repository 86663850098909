import { Grid } from "views/design-system";
import { useDefaultCadastroStyles } from "../../../cadastros/components/default-cadastro-styles";
import { GerarComandaCadastro } from "./components/gerar-comanda-cadastro/gerar-comanda-cadastro";
import { usePedidoLocal } from "services/app/hooks/pedido-local";
import { EnumTipoTrabalho } from "model/enums/enum-tipo-trabalho";
import { useEffect } from "react";
import { useMovRota } from "services/app/hooks/mov-rota";
import { useToastSaurus } from "services/app";
import { useVendaHeaderStore } from "views/components/headers/venda-header/stores/venda-header-store";

export const MovGerarComandaPage = () => {
  const classes = useDefaultCadastroStyles();

  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { redirectLanding } = useMovRota();
  const { showToast } = useToastSaurus();
  useEffect(() => {
    if (getConfiguracoesMesaEComanda()?.tipoTrabalho !== EnumTipoTrabalho.COMANDA) {
      redirectLanding().catch((e) => showToast('error', e.message));
    }
  })

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Registrar Entrada',
      showMenuPdv: true,
    })
  }, [setHeaderProps])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.list}>
        <GerarComandaCadastro tipoTrabalho={getConfiguracoesMesaEComanda()?.tipoTrabalho || EnumTipoTrabalho.COMANDA} />
      </Grid>
    </Grid>
  );
};

export default MovGerarComandaPage;
