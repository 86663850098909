import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';

export const useDigitarCodigo = () => {
  const { getRegistro, setRegistro } = useGestaoStorage();

  const get = (): boolean => {
    const digitarCodigo = getRegistro(
      GestaoStorageKeys.DigitarCodigo,
      false
    ) as boolean;

    if (!digitarCodigo) {
      return false;
    }
    return digitarCodigo;
  };

  const set = (digitarCodigo: boolean) => {
    setRegistro(GestaoStorageKeys.DigitarCodigo, digitarCodigo, false);
  };

  return {
    get,
    set
  };
};
