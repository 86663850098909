import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-vincular-comanda-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutPedidoDadosMesa } from 'data/api/gestao/pedido-dados/put-pedido-dados-mesas';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { BarcodeScanIcon } from 'views/components/icons/barcode-scan-icon';
import { EnumComandas } from 'model/enums/enum-comandas';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePostPedidoDados } from 'data/api/gestao/pedido-dados/post-pedido-dados';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { guidEmpty } from 'utils/guid-empty';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { useConfirm } from 'material-ui-confirm';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { ScannerIcon } from 'views/components/icons/scanner-icon';
import { CodigoBarrasIcon } from 'views/components/icons/codigo-barras-icon';
import { DialogScannRFID } from '../dialog-scann-rfid/dialog-scann-rfid';
import { isEmpty } from 'lodash';
import { useNFC } from 'services/app/hooks/nfc';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { useMovRota } from 'services/app/hooks/mov-rota';

interface DialogVincularComandaProps {
  aberto: boolean;
  mesaId: string;
}

export const DialogVincularComanda = ({
  aberto,
  mesaId
}: DialogVincularComandaProps) => {
  // STATES E REFS
  const [codComanda, setCodComanda] = useState('');
  const [erro, setErro] = useState<string>('')
  const [carregandoNFC, setCarregandoNFC] = useState<boolean>(true)

  // CHAMADAS API
  const { getPedidoSalao, carregando: carregandoGetPedidos } =
    useGetPedidoSalao();
  const { putPedidoDadosMesa, carregando: carregandoPutPedidoDados } =
    usePutPedidoDadosMesa();
  const { postPedidoDados, carregando: carregandoPostPedidos } =
    usePostPedidoDados();
  // PROVIDERS
  const { temNFC, verificarNFC } = useNFC();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getMov, setPedidoExistente } = useMovAtual();
  const { pedidoDadosWrapper, getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { fecharVincularComandaDialog, abrirDialogBarCodeCamComanda } =
    useCadastros();
  const { getConfigByCod } = useEmpresaAtual()

  const tipoTrabalhoComanda = useMemo(() => getConfiguracoesMesaEComanda()?.tipoTrabalhoComanda, [getConfiguracoesMesaEComanda]);

  const [tpScan, setTpScan] = useState<EnumTipoTrabalhoComanda>(tipoTrabalhoComanda ?? EnumTipoTrabalhoComanda.HIBRIDO)

  useEffect(() => setTpScan(tipoTrabalhoComanda ?? EnumTipoTrabalhoComanda.HIBRIDO), [tipoTrabalhoComanda])

  const retornarCampo = useCallback(() => {
    switch (tpScan) {
      case EnumTipoTrabalhoComanda.APENAS_CODIGO:
        return 'codigoComanda';
      default:
        return 'rfId'
    }
  }, [tpScan])

  // AUX
  const classesComponent = useStyles();
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const carregando =
    carregandoGetPedidos || carregandoPutPedidoDados || carregandoPostPedidos;
  const { callEvent } = useEventTools();
  const confirm = useConfirm();
  const { redirectLancadorPadrao } = useMovRota();

  const postPedidoWrapper = useCallback(
    async (comandaId: string) => {
      const mov = getMov();

      let movAtual = {
        ...mov,
        informacoesGeraisPedido: {
          comandaId: comandaId,
          mesaId: mesaId
        }
      } as MovSimplesModel;

      const pedidos = pedidoDadosWrapper(movAtual);

      const res = await postPedidoDados(getEmpresaSelecionada()?.Id!, pedidos);

      if (res.erro) {
        throw new Error('Não foi possível criar um pedido, tente novamente.');
      }

      return res.resultado?.data as PedidoModel;
    },
    [getEmpresaSelecionada, getMov, mesaId, pedidoDadosWrapper, postPedidoDados]
  );

  const handleConfirme = useCallback(
    async (codigo: string) => {
      try {

        const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
        const prefixo = buscaPrefixo?.toUpperCase();

        if (codigo.toUpperCase().startsWith(prefixo ?? '')) {
          codigo = codigo.slice(prefixo?.length);
        }

        if (tpScan === EnumTipoTrabalhoComanda.APENAS_CODIGO) {
          if (codigo.length > 3) {
            codigo = codigo.slice(codigo.length - 3, codigo.length);

            if (codigo[0] === '0' && codigo[1] === '0') {
              codigo = codigo.slice(codigo.length - 1, codigo.length);
            } else if (codigo[0] === '0') {
              codigo = codigo.slice(codigo.length - 2, codigo.length);
            }
          } else {
            if (codigo[0] === '0' && codigo[1] === '0') {
              codigo = codigo.slice(codigo.length - 1, codigo.length);
            } else if (codigo[0] === '0') {
              codigo = codigo.slice(codigo.length - 2, codigo.length);
            }
          }
        }

        const comanda = await TouchoneDBPrimary.comandas.get({
          [retornarCampo()]: codigo
        });

        if (!comanda) {
          throw new Error(`Comanda com o ${retornarCampo()} ${codigo} não encontrada.`);
        }

        if (comanda && comanda?.status?.codigo !== EnumComandas.ATIVO) {
          throw new Error(
            `Comanda com o ${retornarCampo()} ${codigo} está com status de inativo.`
          );
        }

        let query = `comandaId=${comanda.id}`;

        const responsePedidos = await getPedidoSalao(
          getEmpresaSelecionada()!.Id,
          query
        );

        if (responsePedidos.erro) {
          throw new Error(responsePedidos.erro);
        }

        const pedidos =
          (responsePedidos?.resultado?.data?.list as PedidoModel[]) ?? [];

        if (pedidos.length === 0) {
          const pedidoAtual = await postPedidoWrapper(comanda.id!);

          const mesaEncontrada = await TouchoneDBPrimary.mesas.get({
            id: mesaId
          });

          const mesa = mesaEncontrada as MesasModel;

          await setPedidoExistente(
            pedidoAtual.id,
            pedidoAtual.comandaId === guidEmpty() ? '' : pedidoAtual.comandaId,
            pedidoAtual.mesaId === guidEmpty() ? mesaId : pedidoAtual.mesaId,
            pedidoAtual.salaoId === guidEmpty()
              ? mesa.salaoId
              : pedidoAtual.salaoId,
            mesa ? mesa.codigo : '',
            pedidoAtual.codigoComanda ? codComanda : '',
            pedidoAtual.codigoPedido || '',
            pedidoAtual.identificador || ''
          );
          fecharVincularComandaDialog(false);
          await redirectLancadorPadrao();
          return;
        }

        const respostaPut = await putPedidoDadosMesa(
          getEmpresaSelecionada()?.Id ?? '',
          pedidos[0].id,
          mesaId
        );

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        showToast('success', `A comanda ${codigo} foi vinculada com sucesso`);
        fecharVincularComandaDialog(false);
        callEvent(AppEventEnum.ComandaVinculada, true);
      } catch (e: any) {
        showToast('error', e.message);
        setErro(e.message);
      }
    },
    [getConfigByCod, tpScan, retornarCampo, getPedidoSalao, getEmpresaSelecionada, putPedidoDadosMesa, mesaId, showToast, fecharVincularComandaDialog, callEvent, postPedidoWrapper, setPedidoExistente, codComanda, redirectLancadorPadrao]
  );

  const handleSubmit = useCallback(
    async (e?: any) => {
      if (e) e.preventDefault();
      if (codComanda.length < 1) return;

      confirm(
        {
          title: 'Vincular Comanda',
          description: `Realmente deseja alterar a comanda ${codComanda} para esta mesa ?`,
          confirmationText: 'Confirmar',
          cancellationText: 'Voltar',
        }).then(async () => {
          await handleConfirme(codComanda);
        })

    },
    [codComanda, confirm, handleConfirme]
  );

  const triggerEscolher = useCallback(() => {
    confirm({
      title: 'Escanear Comanda',
      description: 'Como deseja escanear a comanda?',
      confirmationText: <>
        <ScannerIcon tipo='BUTTON_PRIMARY' />
        Comanda RFID
      </>,
      cancellationText: <>
        <CodigoBarrasIcon tipo='BUTTON_PRIMARY' />
        Código de Barras
      </>,
      cancellationButtonProps: {
        variant: 'contained',
        color: 'primary'
      }
    }).then(() => setTpScan(EnumTipoTrabalhoComanda.APENAS_RFID))
      .catch(() => setTpScan(EnumTipoTrabalhoComanda.APENAS_CODIGO))
  }, [confirm])

  useEffect(() => {
    verificarNFC();
    setTimeout(() => {
      setCarregandoNFC(false)
    }, 400)
  }, [verificarNFC])

  const codigoComponent = useMemo(() => {
    return <DialogSaurus
      aberto={aberto || false}
      titulo="Vincular Comanda"
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Typography
          style={{
            textAlign: 'center',
            marginBottom: theme.spacing(2),
            fontWeight: '600',
            fontSize: '20px',
            color: theme.palette.grey[400]
          }}
        >
          Digite o código da comanda que deseja vincular na mesa
        </Typography>
        <Grid className={classesComponent.cardContent}>
          <form onSubmit={handleSubmit}>
            <TextFieldSaurus
              tipo="TEXTO"
              disabled={carregando}
              fullWidth
              allowSubmit
              variant="outlined"
              label="Código Comanda"
              InputLabelProps={{
                shrink: true
              }}
              placeholder={'EX: 1'}
              value={codComanda}
              onChange={(e) => setCodComanda(e.target.value)}
              endAdornmentButton={
                <BarcodeScanIcon
                  tipo="INPUT"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    abrirDialogBarCodeCamComanda(mesaId);
                    fecharVincularComandaDialog(false);
                  }}
                />
              }
            />
            <Grid container className={classesComponent.acoes} spacing={2}>
              <Grid item xs={5}>
                <Button
                  disabled={carregando}
                  onClick={() => fecharVincularComandaDialog(false)}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className="round"
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  disabled={carregando}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="round"
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  }, [aberto, abrirDialogBarCodeCamComanda, carregando, classesComponent.acoes, classesComponent.cardContainer, classesComponent.cardContent, codComanda, fecharVincularComandaDialog, handleSubmit, mesaId, theme])

  const confirmRFID = useCallback(async (rfid: string) => {
    setCodComanda(rfid)
    await confirm(
      {
        title: 'Vincular Comanda',
        description: `Realmente deseja alterar a comanda ${rfid} para esta mesa ?`,
        confirmationText: 'Confirmar',
        cancellationText: 'Voltar',
      }).then(async () => {
        await handleConfirme(rfid);
      })
  }, [confirm, handleConfirme])

  const switchPage = useCallback(() => {
    switch (tpScan) {
      case EnumTipoTrabalhoComanda.APENAS_CODIGO:
        return codigoComponent
      case EnumTipoTrabalhoComanda.APENAS_RFID:
        if (temNFC) {
          return <DialogScannRFID closeDialog={() => fecharVincularComandaDialog(false)}
            openned
            confirmarComandaRFID={confirmRFID}
            erro={!isEmpty(erro) ? erro : undefined}
            loading={carregando}
          />

        }
        showToast('error', 'Você não possuí NFC para ler o RFID de suas comandas');
        fecharVincularComandaDialog(false);
        return <></>
      case EnumTipoTrabalhoComanda.HIBRIDO:
        if (temNFC) {
          triggerEscolher();
        }
        return codigoComponent
    }
  }, [carregando, codigoComponent, confirmRFID, erro, fecharVincularComandaDialog, showToast, temNFC, tpScan, triggerEscolher])

  return (
    <>
      {carregandoNFC ?
        <Box>
          <CircularLoading tipo='FULLSIZED' />
        </Box>
        : switchPage()}
    </>
  );
};
