import { RelatoriosAvancadosModel } from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { RalatoriosAvancadosModal } from 'views/components/modals/relatorios-avancados/relatorios-avancados-modal';

export const EditRelatoriosAvancadosModal = () => {
    const { push } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        relatorio: new RelatoriosAvancadosModel(),
        aberto: false,
        ultimaUrl: '',
        search: '',
    });

    const relatoriosAvancadosRoute = `/relatorios-avancados/`;
    const novoRelatorioAvancadoRoute = 'adicionar';
    consoleDev('EditRelatoriosAvancadosModal')

    const modalAlterado = useCallback(({ relatorio, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? relatoriosAvancadosRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;

        if (!openned) {
            push({
                pathname: relatoriosAvancadosRoute,
                search: prevSearch
            })
        }

        if (trocarUrl) {
            const rota = relatoriosAvancadosRoute + (relatorio?.codigo === 0 ? novoRelatorioAvancadoRoute : relatorio?.codigo);
            push(rota);
        }

        setModalState({
            relatorio,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search,
        });
    }, [relatoriosAvancadosRoute, modalState.aberto, modalState.search, modalState.ultimaUrl, push])

    useEffect(() => {
        addHandler(AppEventEnum.RelatoriosAvancadosModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.RelatoriosAvancadosModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <RalatoriosAvancadosModal
            openned={modalState.aberto}
            key="mdlRelatoriosAvancados"
            relatorio={modalState.relatorio}
            id={modalState.relatorio.codigo?.toString() ?? ''}
        />
    )
}