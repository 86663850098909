import { Box, Button } from "@material-ui/core"
import { useStyles } from './convenio-pesquisa-styles'
import { PessoaIcon } from "views/components/icons/pessoa-icon";
import { OkIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { ConvenioListSearchProps } from "../convenio-list/convenio-list-search-props";
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";
import { StatusConvenioMockTodos } from "data/mocks/status-convenio-mock";

interface ITelaState {
    generico: boolean;
    status: boolean;
}

interface ConvenioPesquisaModalProps {
    openned: boolean;
    filtros: ConvenioListSearchProps;
}

export const ConvenioPesquisaModal = ({
    openned,
    filtros
}: ConvenioPesquisaModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { fecharConvenioFiltroModal } = useFiltrosModais();
    const history = useHistory();

    const [telas, setTelas] = useState<ITelaState>({
        generico: false,
        status: false,

    })
    const [localProps, setLocalProps] = useState<ConvenioListSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (telas.generico) {
            setTelas(prev => ({
                ...prev,
                generico: false
            }))
            return
        }
        if (telas.status) {
            setTelas(prev => ({
                ...prev,
                status: false
            }))
            return
        }
        fecharConvenioFiltroModal(false)
    }, [fecharConvenioFiltroModal, telas.generico, telas.status])

    const onSubmit = useCallback((localProps: ConvenioListSearchProps) => {
        const searchs: Array<string | null> = [
            localProps.status > -1 ? `status=${localProps.status}` : null,
        ]

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)


        history.push({
            pathname: '/convenios',
            search: query
        })
        fecharConvenioFiltroModal(true)
    }, [fecharConvenioFiltroModal, history])

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    <Box className={classNames(classes.container)}>
                        <CardFiltro<ConvenioListSearchProps, ITelaState>
                            icon={<PessoaIcon />}
                            nome="Situação"
                            propName="status"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="status"
                            value={StatusConvenioMockTodos.find(item => item.Key === localProps.status)?.Value || ''}
                            valorPadrao={EnumConvenioStatus.Ativo}
                            valorAtual={localProps.status}
                        />
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => {
                                onSubmit({
                                    status: EnumConvenioStatus.Ativo,
                                });
                            }}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>
                        {telas.status && (
                            <RadioList
                                handleSubmit={async (status) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        status: status
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        status: false
                                    }))
                                }}
                                selecionado={localProps.status}
                                mock={StatusConvenioMockTodos}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal>

    )
}