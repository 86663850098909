import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogConvenioAutenticar } from 'views/components/dialog/dialog-convenio-autenticar/dialog-convenio-autenticar';

class DialogConvenioAutenticarRenderProps {
  constructor(
    public aberto: boolean = false,
    public modo: 'consulta' | 'venda' = 'consulta',
    public dadosPagamento?: {
      pagamento: MovSimplesPagamentoModel;
      credenciais: any;
    }
  ) { }
}

export const DialogConvenioAutenticarRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogConvenioAutenticarRenderProps>(
    new DialogConvenioAutenticarRenderProps()
  );

  consoleDev('DialogConvenioAutenticar');

  const modalAlterado = useCallback(
    ({ aberto, modo, dadosPagamento }: any) => {
      setModalState({
        aberto,
        modo,
        dadosPagamento
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogConvenioAutenticar, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogConvenioAutenticar, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogConvenioAutenticar
          aberto={modalState.aberto}
          modo={modalState.modo}
          dadosPagamento={modalState.dadosPagamento}
        />
      )}
    </>
  );
};
