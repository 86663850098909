import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { CircularLoading } from 'views/components/utils';
import { guidEmpty } from 'utils/guid-empty';
import { HorarioModel } from 'model/api/gestao/horarios-funcionamento/horario-model';
import { SwitchSaurus } from 'views/components/controles';
import { FecharIcon} from 'views/components/icons';
import { useStyles } from './form-estabelecimento-horarios-config-inicial-styles';

export const FormEstabelecimentoHorariosConfigInicial = forwardRef<
  DefaultFormRefs<HorarioModel>,
  DefaultFormProps<HorarioModel>
>((props: DefaultFormProps<HorarioModel>, ref) => {

  const utilClasses = makeUtilClasses();
  const { isMobile, theme } = useThemeQueries()
  const classes = useStyles()
  const [modelForm, setModelForm] = useState<HorarioModel>(new HorarioModel())
  const {
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<HorarioModel>({
    defaultValues:{...modelForm}
  }
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "empresaHorarioDias"
  });


  const diasSemana = useCallback((dia: number) => {
    switch (dia) {
      case 0:
        return "Domingo"
      case 1:
        return "Segunda-feira"
      case 2:
        return "Terça-feira"
      case 3:
        return "Quarta-feira"
      case 4:
        return "Quinta-feira"
      case 5:
        return "Sexta-feira"
      case 6:
        return "Sábado"
    }
  }, [])

  const fieldsSerializados = fields.map((fields, index) => {
    return {
      ...fields,
      indexOriginal: index,
    };
  });

  const onSubmit = (values: HorarioModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
     
    },
    fillForm: (values: HorarioModel) => {
      setModelForm(values)
      reset(values);
    },
  }));

  const diasNumber = [0, 1, 2, 3, 4, 5, 6]

  const botao = (grupo: number) => {
    return (
      <SwitchSaurus
        checked={
          fields
            .filter(obj =>
              obj.diaSemana === grupo).length > 0
        }
        onClick={() => {
          if (fieldsSerializados.filter(obj => obj.diaSemana === grupo).length > 0) {
            const items = fieldsSerializados.filter(obj => obj.diaSemana === grupo)
            let indexesRemover: number[] = []
            for (let obj of items) {
              indexesRemover.push(obj.indexOriginal)
            }
            remove(indexesRemover)
            return
          }

          const diaAnterior = grupo === 0 ? 6 : grupo - 1
          const horariosAnteriores = getValues('empresaHorarioDias').filter(horario => horario.diaSemana === diaAnterior)

          if (horariosAnteriores.length > 0) {
            horariosAnteriores.forEach(horario => {
              append({
                id: guidEmpty(),
                diaSemana: grupo,
                empresaHorarioId: getValues('id'),
                inicio: horario.inicio,
                fim: horario.fim,
              })
            })
          } else {
            append({
              id: guidEmpty(),
              diaSemana: grupo,
              empresaHorarioId: getValues('id'),
              inicio: '',
              fim: '',
            })
          }
        }}
        variant='LIGHT'
      />
    )
  }

  return (
    <>
      {props.loading && props.showLoading ? (
        <CircularLoading tipo="FULLSIZED" />
      ) : null}
      <div className={utilClasses.formContainer} >

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={1}>

              {diasNumber
                .sort((a, b) => a - b)
                .map(grupo => {

                  return (
                    <Grid item xs={12}>
                      <Grid container xs={12}  className={classes.containerCard}>
                        <Grid item xs={12} md={3} >
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            <Typography style={{
                              fontWeight: 600
                            }}>
                              {diasSemana(grupo)}
                            </Typography>
                            {isMobile &&
                              <Grid item>
                                {botao(grupo)}
                              </Grid>
                            }
                          </div>

                        </Grid>


                        {fieldsSerializados
                          .filter((fil) => {
                            return fil.diaSemana === grupo
                          }).length > 0 && (
                            <Grid item xs={12} md={8}>
                              <Grid container spacing={2}>
                                {fieldsSerializados
                                  .filter((fil) => {

                                    return fil.diaSemana === grupo
                                  })
                                  .map((item, idx, count) => {
                                    return (
                                      <>
                                        <Grid item xs={12} md={10} >
                                          <Grid style={{
                                            display: 'flex',

                                          }}>
                                            <Controller
                                              render={({ field }) =>
                                                <TextFieldSaurus
                                                  allowSubmit
                                                  tipo="HORA"
                                                  label="Abre às"
                                                  {...field}
                                                  style={{
                                                    marginRight: 8,
                                                    backgroundColor: '#FFF'
                                                  }}
                                                />}
                                              defaultValue={item.inicio}
                                              name={`empresaHorarioDias.${item.indexOriginal}.inicio`}
                                              control={control}
                                            />

                                            <Controller
                                              render={({ field }) =>
                                                <TextFieldSaurus
                                                  allowSubmit
                                                  label='Fecha às'
                                                  tipo="HORA"
                                                  style={{
                                                    marginRight: 8,
                                                    backgroundColor: '#FFF'
                                                  }}
                                                  {...field}
                                                />}
                                              defaultValue={item.inicio}
                                              
                                              name={`empresaHorarioDias.${item.indexOriginal}.fim`}
                                              control={control}
                                            />
                                            {
                                              count.length !== 1 && (
                                                <Tooltip title="Remover" placement='top'>
                                                  <Button color="secondary" onClick={() => {
                                                    remove(item.indexOriginal)
                                                  }}>
                                                    <FecharIcon tipo="BUTTON" fill={theme.palette.error.main} style={{
                                                      margin: '0px',
                                                      width: 15,
                                                      height: 15
                                                    }} />
                                                  </Button>
                                                </Tooltip>
                                              )
                                            }
                                          </Grid>
                                        </Grid>
                                        {
                                          (count.length - 1) === idx && (
                                            <Grid md={2} item style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              padding: '8px'
                                            }}>
                                              <Button className={classes.botaoAdicionar} color="primary" onClick={() => {
                                                append({
                                                  diaSemana: grupo,
                                                  empresaHorarioId: getValues('id'),
                                                  id: guidEmpty(),
                                                  inicio: '',
                                                  fim: ''
                                                })
                                              }}>
                                                <Typography variant='caption'>Novo Horário</Typography>
                                              </Button>
                                            </Grid>
                                          )
                                        }
                                      </>
                                    )
                                  })}
                              </Grid>
                            </Grid>
                          )}

                        {!isMobile &&
                          <Grid md={1}>
                            {botao(grupo)}
                          </Grid>
                        }

                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
