import { forwardRef, useImperativeHandle, useState } from 'react';
import { useFormCampanhaNomeValidation } from './form-nome-campanha-validations';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';

import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { ValorLucroIcon } from 'views/components/icons/valor-lucro-icon';
import { Button, Divider, Grid } from 'views/design-system';
import { ValoresApuracao } from '../../valores-apuracao/valores-apuracao';
import { EnumTpModeloApuracao } from 'model/enums/enum-tp-modelo-apuracao';

export class CampanhaNomeFormModel {
  constructor(
    public nome: string = '',
    public modeloApuracao: EnumTpModeloApuracao | null = EnumTpModeloApuracao.ValorVendido
  ) { }
}

export interface FormCampanhaNomeProps
  extends DefaultFormProps<CampanhaNomeFormModel> { }

export const FormCampanhaNome = forwardRef<
  DefaultFormRefs<CampanhaNomeFormModel>,
  FormCampanhaNomeProps
>(({ loading, ...props }: FormCampanhaNomeProps, ref) => {

  const handleSubmitButton = (e: any) => {
    e?.preventDefault();
    const form = document.getElementById('formName');
    if (form instanceof HTMLFormElement) {
      form?.requestSubmit();
    }
  }

  const utilClasses = makeUtilClasses();
  const [modeloApuracao, setModeloApuracao] = useState<EnumTpModeloApuracao | null>(
    EnumTpModeloApuracao.ValorVendido
  );
  const [initialValues, setInitialValues] = useState<CampanhaNomeFormModel>(
    new CampanhaNomeFormModel()
  );

  const { FormYupValidationNomeCampanha } = useFormCampanhaNomeValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    clearErrors
  } = useForm<CampanhaNomeFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormYupValidationNomeCampanha),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (values: CampanhaNomeFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new CampanhaNomeFormModel());
      reset();
    },
    fillForm: (model: CampanhaNomeFormModel) => {
      setInitialValues(model);
      reset({ ...model });
      setModeloApuracao(model.modeloApuracao);
    }
  }));

  const onClickCardModeloApuracao = (type: number, e: any) => {
    setValue('modeloApuracao', type);
    setModeloApuracao(type);
    clearErrors('modeloApuracao')
    handleSubmitButton(e)
  };

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          id="formName"
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label={'Nome'}
                    tipo={'TEXTO'}
                    placeholder="Digite o nome da campanha de comissão"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(errors.nome && errors.nome.message)}
                    helperText={
                      touchedFields.nome || errors.nome
                        ? errors.nome?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Divider
            style={{
              marginTop: '24px',
              marginBottom: '24px'
            }}
          />

          <ValoresApuracao
            modeloApuracao={modeloApuracao}
            onClick={onClickCardModeloApuracao}
          />

          {errors.modeloApuracao && errors.modeloApuracao.message && (
            <p style={{ margin: 8, color: 'red', fontSize: '12px' }}>{errors.modeloApuracao && errors.modeloApuracao.message}</p>
          )}
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
