import Dexie, { Table } from 'dexie';
import { TabelaCategorias } from './interfaces/interface-tabela-categorias';
import { TabelaClientes } from './interfaces/interface-tabela-clientes';
import { TabelaComandas } from './interfaces/interface-tabela-comandas';
import { TabelaFinalizadoras } from './interfaces/interface-tabela-finalizadoras';
import { TabelaImpostos } from './interfaces/interface-tabela-impostos';
import { TabelaMesas } from './interfaces/interface-tabela-mesas';
import { TabelaNCM } from './interfaces/interface-tabela-ncm';
import { TabelaNumeracao } from './interfaces/interface-tabela-numeracao';
import { TabelaProdutos } from './interfaces/interface-tabela-produtos';
import { TabelaSaloes } from './interfaces/interface-tabela-saloes';
import { TabelaSessao } from './interfaces/interface-tabela-sessao';
import { TabelaSessaoValores } from './interfaces/interface-tabela-sessao-valores';
import { TabelaVenda } from './interfaces/interface-tabela-vendas';
import { TabelaModificadores } from './interfaces/interface-tabela-modificadores';
import { TabelaMedicamentos } from './interfaces/interface-tabela-medicamentos';
import { TabelaPromocoesDePor } from './interfaces/interface-tabela-promocoes-de-por';

export class TouchoneDatabase extends Dexie {
    produtos!: Table<TabelaProdutos>;
    clientes!: Table<TabelaClientes>;
    finalizadoras!: Table<TabelaFinalizadoras>;
    categorias!: Table<TabelaCategorias>;
    ncms!: Table<TabelaNCM>;
    impostos!: Table<TabelaImpostos>;
    mesas!: Table<TabelaMesas>;
    saloes!: Table<TabelaSaloes>;
    comandas!: Table<TabelaComandas>;
    sessao!: Table<TabelaSessao>;
    sessaoValores!: Table<TabelaSessaoValores>;
    numeracao!: Table<TabelaNumeracao>;
    vendas!: Table<TabelaVenda>;
    modificadores!: Table<TabelaModificadores>;
    medicamentos!: Table<TabelaMedicamentos>;
    promocoes!: Table<TabelaPromocoesDePor>;

    constructor(dbName: string) {
        super(dbName);
        this.version(101).stores({
            produtos: `++idIndexed, ativo, balanca, categoriaId, codigo, empresaId, grupoImpostoId, imagemUrl, medida, medidaDesc, ncm, ncmId, nome, produtoGradeId, produtoId, vPreco, favorito, codigos, setorId, subItens, prodSubItem,cobraTaxaServico, numCodigos, tipo, codigoAnvisa, medicamento`,
            clientes: `++idIndexed, id, contratoId, regimeTributarioId, tabelaPrecoId, representanteId, tpCadastro, cpfcnpj, ierg, im, indIEDest, nome, fantasia, infOperador, infInterno, status, contatos, dtNasc, documentos`,
            finalizadoras: `++idIndexed, id, empresaId, descricao, resumido, tpTransacao, tpMod, tpPagamento, vDesc, vAcresc, pDesc, pAcresc, vMinParc, qMaxParc, vencimento, qDias, ativo, cnpjCred, credenciais, credenciado, dataCredenciamento, ordem`,
            categorias: `++idIndexed, id, contratoId, categoriaPaiId, nome, descricao, cor, ativo, breadCrumbs, temProdutos, foto, tipoExibicao`,
            ncms: `++idIndexed, id, contratoId, ncmId, codigo, cest, descricao, pTribManual, ativo, pTribMunicipal, pTribFederal, pTribEstadual`,
            impostos: `++idIndexed, id, contratoId, empresaId, regimeTributarioId, descricao, cfop, cBenef, icmsCst, icmsModBc, icmsPRedBc, icmsPIcms, icmsModBcSt, icmsPMvaVUnidSt, icmsPredBcSt, icmsPIcmsSt, icmsMotDesIcms, icmsPBcOp, icmsUfSt, icmsPCredSn, icmsPredSn, icmsPFcp, icmsPFcpSt, pisCst, pisPercVUnidPis, cofinsCst, cofinsPercVUnidCofins, ecfAliq, ativo`,
            mesas: `++idIndexed, id, salaoId, codigo, status`,
            saloes: `++idIndexed, id, descricao, balcao, status`,
            comandas: `++idIndexed, id, codigoComanda, rfId, status`,
            numeracao: `++idIndexed, mod, serie, numero, empresaId, tpAmb, data`,
            vendas: `++idIndexed, id, empresaId, operadorId, sessaoId, status, tpAmb, dEmi, serie, nnf, mod, pessoaId, documento, regimeTributarioId, produtos, pags, cliente, dataSincronizacao, vNF, tpEmis, telefone, mesaCodigo, codigoComanda, vDesc, vAcresc, receitasMedicas, documentoNaNota, cobr`,
            sessao: `++idIndexed, id, dataAbertura, dataFechamento, operadorId, aberta, empresaId, caixaId`,
            sessaoValores: `++idIndexed, id, sessaoId, pagamento, valor, responsavel, empresaId, tpLancamento, observacao, operador, dataInsercao, portador, dataSincronizacao, caixaId`,
            modificadores: `++idIndexed, id, contratoId, empresaId, nome, descricao, tpCalculo, produtos`,
            medicamentos: `++idIndexed, id, codigoEan, codigoAnvisa, nome, qtdEmbalagem, composicao, tipoMedicamento, tarja, status, precos`
        });
        this.version(102).stores({
            promocoes: `++idIndexed,promocaoId,promocao,dataInicial,dataFinal,horaInicial,horaFinal,diasSemana,valorPromocao,variacaoId,codigo`,
        });
    }

    limparDados = async () => {
        await this.produtos.clear();
        await this.promocoes.clear();
        await this.clientes.clear();
        await this.ncms.clear();
        await this.finalizadoras.clear();
        await this.impostos.clear();
        await this.categorias.clear();
        await this.mesas.clear();
        await this.saloes.clear();
        await this.comandas.clear();
        await this.modificadores.clear();
        await this.medicamentos.clear();
    }

    limparDadosSessao = async () => {
        await this.sessao.clear();
        await this.sessaoValores.clear();
    }
}

export const TouchoneDBPrimary = new TouchoneDatabase('touchonev1.17');
export const TouchoneDBSinc = new TouchoneDatabase('touchone-tempv1.17');