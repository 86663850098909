import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { validarEmail } from 'utils/valida-email';
import * as Yup from 'yup';

export const useFormGerarComandaValidation = () => {

    const FormGerarComandaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                comandaId: Yup.string().required('Número da comanda é obrigatório.').typeError('Número da comanda é obrigatório'),
                cliente: Yup.object({
                    nomeFantasia: Yup.string().required('Nome Completo é obrigatório.'),
                    email: Yup.string().notRequired().test({
                        message: 'Email Inválido',
                        test: (value) => {
                            if (!isEmpty(value) && validarEmail(value || '')) {
                                return false
                            }
                            return true
                        }
                    }),
                    cpfCnpj: Yup.string().required('Documento é obrigatório.'),
                }),
                enderecoEntrega: Yup.object({
                    logradouro: Yup.string().notRequired(),
                    cep: Yup.string().notRequired(),
                    numero: Yup.string().notRequired(),
                    codigoMunicipio: Yup.string().notRequired(),
                    uf: Yup.string().notRequired(),
                    bairro: Yup.string().notRequired()
                })
            })
        )
    }, [])

    return {
        FormGerarComandaValidationYup,
    }
}

