import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupEmail = () => {
    const emailYup = useCallback(() => {
        return (
            Yup.string().test({
                test: (value) => {
                    if (!value) return false;
                    const emails = value.split(';').map(email => email.trim());
                    return emails.every(email => Yup.string().email().isValidSync(email))
                },
                message: 'Email inválido'
            })
                .required('O e-mail é obrigatório.')
            //Busquei essa solução pelo gpt para validar cada email da lista mas não permitir emails no formato incorreto
        )
    }, [])
    return {
        emailYup
    }
}
