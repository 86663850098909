import { Button, Grid } from "views/design-system";
import { useConfirm } from "material-ui-confirm";
import { EnumPagTpTransacao } from "model";
import { useCallback, useEffect } from "react";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { PagamentosListData } from "views/components/dialog/dialog-historico-pagamento/pagamentos-list-data/pagamentos-list-data";
import { AtualizarIcon, OkIcon } from "views/components/icons";
import { useStyles } from './mov-historico-pagamento-styles'
import { useToastSaurus } from "services/app";
import { useMovRota } from "services/app/hooks/mov-rota";
import { useVendaHeaderStore } from "views/components/headers/venda-header/stores/venda-header-store";
import { useHistory } from "react-router-dom";

const MovHistoricoPagamentoPage = () => {
    const classes = useStyles()

    const { getMov, refazerPagamento } = useMovAtual();
    const confirm = useConfirm();
    const { showToast } = useToastSaurus();
    const { avancarFluxoMov, redirectPagamentoAvancado } = useMovRota();
    const { goBack } = useHistory();
    const confirmar = useCallback(() => {
        confirm({
            title: "Refazer Pagamentos",
            description: "Isso irá resetar todos os pagamentos não integrados, deseja continuar?",
            cancellationText: "Cancelar",
            confirmationText: "Refazer",
            confirmationButtonProps: {
                variant: "contained",
                color: 'primary',
                className: 'round'
            },
            cancellationButtonProps: {
                className: 'round'
            }
        }).then(() => {
            try {
                refazerPagamento()
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        })
    }, [confirm, refazerPagamento, showToast])

    const disabledRefazer = useCallback(() => {
        if (getMov()!.pags.length < 1) return true

        if (getMov()!.pags.filter(i => i.tpTransacao === EnumPagTpTransacao.NORMAL).length < 1
        ) return true

        return false
    }, [getMov])

    const movAtual = getMov();

    const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
    useEffect(() => {
        setHeaderProps({
            title: 'Pagamentos Realizados',
            voltar: {
                async onClick() {
                    try {
                        try {
                            goBack();
                        } catch {
                            await redirectPagamentoAvancado();
                        }
                    } catch (e: any) {
                        showToast('error', e.message);
                    }
                },
            }
        })
    }, [goBack, redirectPagamentoAvancado, setHeaderProps, showToast])

    return (
        <Grid className={classes.container}>
            <Grid className={classes.content}>
                <Grid container>
                    <Grid item xs={12}>
                        <PagamentosListData list={movAtual?.pags} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{ padding: 8 }} xs={5}>
                    <Button size="large" rounded variant="outlined" color="primary" disabled={disabledRefazer()} onClick={confirmar} className="round" fullWidth>
                        <AtualizarIcon tipo="BUTTON" />
                        Refazer
                    </Button>
                </Grid>
                <Grid item xs={7} style={{ padding: 8 }}>
                    <Button size="large" rounded variant="contained" color="primary" onClick={async () => {
                        try {
                            await avancarFluxoMov();
                        } catch (e: any) {
                            showToast('error', e.message);
                        }
                    }} className="round" fullWidth>
                        <OkIcon tipo="BUTTON_PRIMARY" />
                        Continuar
                    </Button>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MovHistoricoPagamentoPage;