import { Box } from 'views/design-system';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { useCadastros } from 'services/app';
import { VoltarIcon } from 'views/components/icons';
import { useStyles } from './comissao-cadastro-styles';
import { ComissaoCampanha } from '../components/comissao-campanha';
import classNames from 'classnames';
import { useComissaoStore } from '../stores/comissao-store';

export const ComissaoCadastro = () => {
  const modalClasses = useModalStyles();
  const classes = useStyles();
  const { fecharCadastroComissao } = useCadastros();
  const { resetStoreComissao } = useComissaoStore();

  return (
    <Box className={classNames(modalClasses.root, classes.root)}>
      <ModalHeader
        title="Cadastro de comissão"
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              resetStoreComissao();
              fecharCadastroComissao();
            }}
          />
        }
      />
      <Box className={classNames(modalClasses.content, classes.relative)}>
        <ComissaoCampanha
          {...{
            cadastroNovaComissao: true
          }}
        />
      </Box>
    </Box>
  );
};
