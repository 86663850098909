export const padEnd = (value: string, requiredLength: number, character: string = ' ') => {
    try {
        if (value.length >= requiredLength) return value;

        const loops = requiredLength - value.length;
        let returnValue = value;

        for (let i = 0; i < loops; i++) {
            returnValue = `${returnValue}${character}`;
        }

        return returnValue;
    } catch {
        return value;
    }
}

export const padStart = (value: string, requiredLength: number, character: string = ' ') => {
    try {
        if (value.length >= requiredLength) return value;

        const loops = requiredLength - value.length;
        let returnValue = value;

        for (let i = 0; i < loops; i++) {
            returnValue = `${character}${returnValue}`;
        }

        return returnValue;
    } catch {
        return value;
    }
}