import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    
    textArea: {
        '& div': {
            '& input': {
                width:'100%!important'
            }
        }
    },
    deleteIconx:{
       '& svg':{
        color: theme.palette.error.dark
       }
    }
}))