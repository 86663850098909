import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { Tooltip } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { PontosVendaEditFormModel } from 'model/app/forms/pontos-venda/pontos-venda-edit-form-model';
import { StatusIndstatusMock } from 'data/mocks/status-indstatus-mock';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { KeyValueModel } from 'model';
import { useStyles } from './form-pontos-venda-edit-styles';
import { useSessaoAtual } from 'services/app';
import {
  isPlanoComEstoque,
  isPlanoConsumacao,
  isPlanoControleMesasComandas,
  isPlanoFarmaceutico,
  isPlanoFiscal,
  isPlanoIntegradoSaurusPDV
} from 'utils/plano-utils';
import { TpCaixaMock } from 'data/mocks/tp-caixa-mock';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormPontosVendaValidation } from '../form-pontos-venda-cadastro/form-pontos-venda-cadastro-validation';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { useGetObterVersao } from 'data/api/gestao/pontos-venda/get-obter-versao';
import { useGetDeposito } from 'data/api/gestao/deposito';
import { DepositoModel } from 'model/api/gestao/deposito/deposito-model';
import { isEmpty } from 'lodash';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { guidEmpty } from 'utils/guid-empty';
import { useGetEquipamentos } from 'data/api/gestao/equipamento';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { InformacaoIcon } from 'views/components/icons';
import { TooltipPdvConfigMock } from 'data/mocks/tooltip-pdv-config-mock';
import { EnumTour } from 'model/enums/enum-tour';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { CircularLoading } from 'views/components/utils';
import { usePDV } from 'services/app/hooks/pdv';
import { Box, Button, Grid, Typography } from 'views/design-system';
// import { EnumTour } from 'model/enums/enum-tour';
// import { useHistory } from 'react-router';
// import { AppEventEnum } from 'model/enums/enum-app-event';
// import { useEventTools } from 'services/app/hooks/events/event-tools';

interface Props extends DefaultFormProps<PontosVendaEditFormModel> {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pdvId: string
}

interface VersaoPDV {
  versao: string;
  dAtualizacao: string;
}

export const FormPontosVendaEdit = forwardRef<
  DefaultFormRefs<PontosVendaEditFormModel>,
  Props
>(({ loading, setLoading, ...props }: Props, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputDescricao = useRef<HTMLInputElement>(null);
  const accordionsRef = useRef<any>({})
  const { isMobile } = useThemeQueries();
  const { FormPontosVendaValidationYup } = useFormPontosVendaValidation();
  const [DepositosMock, setDepositosMock] = useState<KeyValueModel[]>([]);
  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const [impressorasMock, setImpressorasMock] = useState<KeyValueModel[]>([]);
  const [modelForm, setModelForm] = useState<PontosVendaEditFormModel>(
    new PontosVendaEditFormModel()
  );
  const [accordions, setAccordions] = useState<boolean[]>([false, false])
  const planoComEstoque = isPlanoComEstoque(plano?.plano);
  const isFiscal = isPlanoFiscal(plano?.plano);
  const isIntegracaoPDV = isPlanoIntegradoSaurusPDV(plano?.plano);
  const isMesaComanda = isPlanoControleMesasComandas(plano?.plano);
  const isConsumacao = isPlanoConsumacao(plano?.plano);
  const { invalidarConfiguracoes } = usePDV()
  const [versao, setVersao] = useState<String[]>([]);
  const [finalizouTour1, setFinalizouTour1] = useState<boolean>(false)
  const [finalizouTour2, setFinalizouTour2] = useState<boolean>(false)
  const [finalizouTour3, setFinalizouTour3] = useState<boolean>(false)
  const { addHandler, removeHandler } = useEventTools()
  const classes = useStyles();
  const iniciarTour = useRef<boolean>(true);
  const { getDeposito } = useGetDeposito();
  const { getObterVersao } =
    useGetObterVersao();
  const { getEquipamentos } =
    useGetEquipamentos();
  const { callTour } = useTourSaurus()
  const carregando = loading;
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
    setValue
  } = useForm<PontosVendaEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormPontosVendaValidationYup),
    criteriaMode: 'firstError',
    mode: 'onChange'
  });

  const { fields } = useFieldArray({
    control,
    name: 'configuracoes'
  });

  const fieldsSerializados = fields.map((fields, index) => {
    return {
      ...fields,
      indexOriginal: index
    };
  });

  const depositosWrapper = useCallback(async () => {
    const res = await getDeposito('PageSize=100', 1);

    if (res.erro) throw res.erro;

    const ret = res.resultado?.data.list as DepositoModel[];

    const fazerDepositoMock = ret.map((item) => {
      const key = new KeyValueModel(item.id, item.nome);

      return key;
    });

    setDepositosMock(fazerDepositoMock);
  }, [getDeposito]);

  const handleDownload = useCallback(() => {
    const fileUrl =
      'https://suporte.saurus.net.br/arquivos/sistemas/impressor_local.exe';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'impressor_local.exe';
    link.click();
  }, []);

  const impressorasWrapper = useCallback(async () => {
    const res = await getEquipamentos(getEmpresaSelecionada()?.Id ?? '');

    if (res.erro) return;

    const impressoras = (res?.resultado?.data?.list ?? []) as Array<ImpressoraModel>;

    const mock = impressoras.map((item) => {
      const key = new KeyValueModel(item.id, item.descricao);
      return key;
    });

    mock.push(new KeyValueModel('', 'Sem Impressora'));
    setImpressorasMock(mock);
  }, [getEmpresaSelecionada, getEquipamentos]);

  const [, setCaixa] = useState<number>();

  const isVendaSimples =
    (getValues('configuracoes').filter(
      (item) => item.cod === EnumPDVConfigCod.ModeloVenda
    )[0]?.vConfig ?? '') === 'Venda Simples';

  const onSubmit = (values: PontosVendaEditFormModel) => {
    const pontosVenda = picker<PontosVendaEditFormModel>(
      values,
      new PontosVendaEditFormModel()
    );

    if (isVendaSimples) {
      const notaManual =
        values.configuracoes.filter(
          (item) => item.cod === EnumPDVConfigCod.NotaManual
        )[0].vConfig !== '0';

      if (notaManual) {
        values.configuracoes.filter(
          (item) => item.cod === EnumPDVConfigCod.NotaManual
        )[0].vConfig = '0';
      }
    }
    pontosVenda.configuracoes = values.configuracoes;

    invalidarConfiguracoes();

    props.onSubmit(pontosVenda, modelForm);
  };

  const getObterVersaoWrapper = useCallback(async () => {
    const ret = await getObterVersao(
      getEmpresaSelecionada()!.Id || '',
      modelForm.id || ''
    );

    if (ret.erro) return setVersao([]);

    const res = ret.resultado?.data as VersaoPDV;
    return setVersao(
      !isEmpty(res) && !isEmpty(res.versao) ? res.versao.split('|') : []
    );
  }, [getEmpresaSelecionada, getObterVersao, modelForm.id]);

  useEffect(() => {
    if (modelForm.id !== guidEmpty() && !isEmpty(modelForm.id)) {
      (async () => {
        setLoading(true)
        await getObterVersaoWrapper();
        await depositosWrapper();
        await impressorasWrapper();
        setLoading(false)
      })()
    }
  }, [
    depositosWrapper,
    getObterVersaoWrapper,
    impressorasWrapper,
    modelForm.id,
    setLoading
  ]);

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInputDescricao.current?.focus();
    },
    fillForm: (model: PontosVendaEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInputDescricao.current?.focus();
      }, 500);
    }
  }));

  let codArray: number[] = [];

  // Variável apenas para encurtar um pouco as linhas
  const codRepetidos = [
    EnumPDVConfigCod.TicketConsumacao,
    EnumPDVConfigCod.ModeloVenda,
    EnumPDVConfigCod.DispararSetorFinalizacaoVenda,
    EnumPDVConfigCod.ModeloTrabalho,
    EnumPDVConfigCod.NotaManual,
    EnumPDVConfigCod.ImpressaoComprovante,
    EnumPDVConfigCod.PortaComunicacao,
    EnumPDVConfigCod.ModeloEquipamento,
    EnumPDVConfigCod.QuantidadeColunas,
    EnumPDVConfigCod.TaxaServicoSemPedido,
  ];

  switch (getValues('tpCaixa')) {
    case EnumPDVTpCaixa.WEBPDV:
      codArray = [
        EnumPDVConfigCod.ModeloVenda,
        EnumPDVConfigCod.DispararSetorFinalizacaoVenda,
        EnumPDVConfigCod.ModeloTrabalho,
        EnumPDVConfigCod.NotaManual,
        EnumPDVConfigCod.ImpressaoComprovante,
        EnumPDVConfigCod.PortaComunicacao,
        EnumPDVConfigCod.TaxaServicoSemPedido,
        EnumPDVConfigCod.SincronizacaoCadastros,
        EnumPDVConfigCod.TicketConsumacao,
      ];
      break;
    case EnumPDVTpCaixa.SAURUSPDV:
      codArray = [];
      break;
    case EnumPDVTpCaixa.ANDROID:
      codArray = codRepetidos;
      break;
    default:
      codArray = codRepetidos;
  }

  if (!isFiscal) {
    //TIRO A SELECAO DE EQUIPAMENTO
    codArray = codArray.filter(
      (x) =>
        x !== EnumPDVConfigCod.ModeloVenda
    );
  }

  if (!isConsumacao) {
    codArray = codArray.filter((x) => x !== EnumPDVConfigCod.TicketConsumacao);
  }

  const unique = Array.from(
    new Set<string>(
      fieldsSerializados
        .filter((x) => {
          if (codArray.length > 0) {
            return codArray.includes(x.cod);
          }
          return true;
        })
        .map((item) => item.grupoConfig)
    )
  );

  const filtrosSelect = (x: KeyValueModel) => {
    if (!isFiscal) {
      if ((x.Key as string).toLowerCase() === 'nfc-e') {
        return false;
      }
    }
    if (!isMesaComanda) {
      if ((x.Value as string).toLowerCase() === 'caixa e lançador de pedidos') {
        return false;
      }
      if ((x.Value as string).toLowerCase() === 'apenas lançador de pedidos') {
        return false;
      }
      if ((x.Value as string).toLowerCase() === 'pedido rápido') {
        return false;
      }
    }
    return true;
  };

  const acessouFormulario = useCallback((value: any) => {
    setFinalizouTour1(value.finalizado)
  }, [])
  const acessouFormulario2 = useCallback((value: any) => {
    setFinalizouTour2(value.finalizado)
  }, [])
  const acessouFormulario3 = useCallback((value: any) => {
    setFinalizouTour3(value.finalizado)
  }, [])



  useEffect(() => {
    if (!carregando && iniciarTour.current) {
      if (finalizouTour3) {
        setAccordions(prev => {
          const newArr = [...prev]
          newArr[1] = !newArr[1]
          return newArr
        })
        setTimeout(() => { callTour(EnumTour.CONFIGIMPRESSOR) }, 1000)
        return
      }
      if (finalizouTour2) {
        setAccordions(prev => {
          const newArr = [...prev]
          newArr[0] = !newArr[0]
          return newArr
        })
        setTimeout(() => { callTour(EnumTour.EDICAOPDVIMPRESSOR) }, 1000)
        return
      }
      if (finalizouTour1) {
        setAccordions(prev => {
          const newArr = [...prev]
          newArr[0] = !newArr[0]
          return newArr
        })
        setTimeout(() => { callTour(EnumTour.CONFIGPDV) }, 1000)
      }

      if (!finalizouTour1) {
        setTimeout(() => { callTour(EnumTour.EDICAOPDVCONFIG) }, 1000)
      }
    }
  }, [callTour, carregando, finalizouTour1, finalizouTour2, finalizouTour3])

  useEffect(() => {
    addHandler(AppEventEnum.TourFinish, acessouFormulario)
    return () => {
      removeHandler(AppEventEnum.TourFinish, acessouFormulario)
    }
  }, [acessouFormulario, addHandler, removeHandler])

  useEffect(() => {
    addHandler(AppEventEnum.TourFinish2, acessouFormulario2)
    return () => {
      removeHandler(AppEventEnum.TourFinish2, acessouFormulario2)
    }
  }, [acessouFormulario2, addHandler, removeHandler])

  useEffect(() => {
    addHandler(AppEventEnum.TourFinish3, acessouFormulario3)
    return () => {
      removeHandler(AppEventEnum.TourFinish3, acessouFormulario3)
    }
  }, [acessouFormulario3, addHandler, removeHandler])

  const idConfigInicial = useCallback((index: number) => {
    switch (index) {
      case 0:
        return "accordionConfiguracoesPDV";
      case 1:
        return "accordionImpressoras";
    }
  }, [])

  const renderConfigs = () => {
    return (
      <>
        {carregando && <CircularLoading tipo='FULLSIZED' />}
        {unique
          .filter((x) => x)
          .sort(function (a, b) {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((grupo, index) => {
            accordionsRef.current[index] = React.createRef()
            return (
              <div className={classes.root} key={index} ref={accordionsRef.current[index]}>
                <AccordionSaurus
                  labelPrimary={grupo.substr(3)}
                  showDivider={true}
                  noPaperRoot
                  noPaddingContent
                  expanded={accordions[index]}
                  onChange={() => {
                    setAccordions(prev => {
                      const newArr = [...prev]
                      newArr[index] = !newArr[index]
                      return newArr
                    })
                  }}
                  id={idConfigInicial(index)}
                  key={index}
                >
                  <Grid id={`content${index}`} container spacing={2}>
                    {fieldsSerializados
                      .filter((x) => {
                        /*
                          VALIDANDO MODELO DE TRABALHO PARA EXIBIR OU NÃO
                          A CONFIGURAÇÃO DE EXIBIR MESAS E COMANDAS NO INICIO OU
                          FIM DA VENDA.
                        */
                        const indexModeloTrabalho =
                          fieldsSerializados.find(
                            (config) =>
                              config.cod === EnumPDVConfigCod.ModeloTrabalho
                          )?.indexOriginal || 0;

                        if (
                          x.cod ===
                          EnumPDVConfigCod.TaxaServicoSemPedido &&
                          (isFarmaceutico || !([EnumModeloDeTrabalho.APENAS_CAIXA, EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS].includes(getValues(
                            `configuracoes.${indexModeloTrabalho}.vConfig`
                          ) as EnumModeloDeTrabalho)))
                        ) {
                          return false;
                        }

                        if (
                          x.cod === EnumPDVConfigCod.ModeloTrabalho &&
                          getValues('tpCaixa') === EnumPDVTpCaixa.SAURUSPDV
                        ) {
                          return false;
                        }

                        if (codArray.length > 0) {
                          return (
                            x.grupoConfig === grupo && codArray.includes(x.cod)
                          );
                        }
                        return x.grupoConfig === grupo;
                      })
                      .sort(function (a, b) {
                        if (a.ordem < b.ordem) {
                          return -10;
                        }
                        if (a.ordem > b.ordem) {
                          return 10;
                        }
                        if (a.descConfig < b.descConfig) {
                          return -1;
                        }
                        if (a.descConfig > b.descConfig) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((config, index, arr) => {
                        if (config.tpControle === 1) {
                          let items = (config.itemDrop || '').split('|');
                          if (
                            (config.itemDrop || '').length === 0 &&
                            config.vMax !== config.vMin
                          ) {
                            for (let i = config.vMin; i <= config.vMax; i++) {
                              items.push(i.toString());
                            }
                          }

                          const mock: Array<KeyValueModel> = items.map(
                            (valor, iSplit) => {
                              //VALUE DO DROP É INTEIRO
                              if (config.vMax !== config.vMin) {
                                return new KeyValueModel(
                                  (iSplit + config.vMin).toString(),
                                  valor
                                );
                              } else {
                                return new KeyValueModel(valor, valor);
                              }
                            }
                          );

                          return (
                            <>
                              {/* TEMPORARIAMENTE DESATIVANDO A NOTA MANUAL */}
                              {config.cod ===
                                EnumPDVConfigCod.NotaManual ? null : isVendaSimples &&
                                  config.cod ===
                                  EnumPDVConfigCod.NotaManual ? null : !isFiscal &&
                                    config.cod ===
                                    EnumPDVConfigCod.NotaManual ? null : (
                                <Grid key={`gridConfig${index}`} item xs={12}>
                                  <Controller
                                    name={`configuracoes.${config.indexOriginal}.vConfig`}
                                    control={control}
                                    render={({ field }) => (
                                      <Box className={classes.divField}>
                                        <SelectSaurus
                                          fullWidth
                                          variant="outlined"
                                          {...field}
                                          label={config.descConfig}
                                          disabled={loading}
                                          key={index}
                                          conteudo={mock.filter(filtrosSelect)}
                                          value={getValues(`configuracoes.${config.indexOriginal}.vConfig`)}
                                          onChange={(item) => {
                                            item.stopPropagation()
                                            const value = mock.filter(
                                              (opt) =>
                                                opt.Key === item.target.value
                                            )[0];
                                            setValue(
                                              `configuracoes.${config.indexOriginal}.vConfig`,
                                              value.Key
                                            );
                                            setValue(
                                              `configuracoes.${config.indexOriginal}.editado`,
                                              true
                                            );
                                          }}
                                        />
                                        <Tooltip
                                          title={
                                            <Typography className={classes.tooltipText} align='center'>
                                              {TooltipPdvConfigMock.find(item => item.Key === config.cod)?.Value || ''}
                                            </Typography>
                                          }
                                        >
                                          <div>
                                            <InformacaoIcon tipo='GERAL' class={classes.infoIcon} />
                                          </div>
                                        </Tooltip>
                                      </Box>
                                    )}
                                  />
                                </Grid>
                              )}
                            </>
                          );
                        } else if (config.tpControle === 0) {
                          return (
                            <Grid key={`gridConfig${index}`} item xs={12}>
                              <Controller
                                name={`configuracoes.${config.indexOriginal}.vConfig`}
                                control={control}
                                render={({ field }) => (
                                  <>
                                    {getValues('tpCaixa') ===
                                      EnumPDVTpCaixa.WEBPDV &&
                                      config.cod ===
                                      EnumPDVConfigCod.PortaComunicacao ? (
                                      <Box className={classes.divField}>
                                        <SelectSaurus
                                          key={index}
                                          disabled={loading}
                                          label={'Impressora'}
                                          fullWidth={true}
                                          variant="outlined"
                                          conteudo={impressorasMock}
                                          {...field}
                                          onChange={(event) => {
                                            const item = impressorasMock.filter(
                                              (e) => e.Key === event.target.value
                                            )[0].Key;
                                            setValue(
                                              `configuracoes.${config.indexOriginal}.vConfig`,
                                              item
                                            );
                                            setValue(
                                              `configuracoes.${config.indexOriginal}.editado`,
                                              true
                                            );
                                          }}
                                          value={getValues(`configuracoes.${config.indexOriginal}.vConfig`)}
                                        />
                                        <Tooltip
                                          title={
                                            <Typography className={classes.tooltipText} align='center'>Selecione qual impressora você irá utilizar para impressão dos seus pedidos.

                                              Para configurar uma impressora acesse o Painel Administrativo, selecione a Retaguarda e dentro da sessão Administrativo clique em “Impressoras”.</Typography>
                                          }
                                        >
                                          <div>
                                            <InformacaoIcon tipo='GERAL' class={classes.infoIcon} />
                                          </div>
                                        </Tooltip>
                                      </Box>

                                    ) : (
                                      <Box className={classes.divField}>
                                        <TextFieldSaurus
                                          inputRef={refInputDescricao}
                                          tipo={
                                            config.tpTxt.toUpperCase() ===
                                              'NUMERO' ||
                                              config.tpTxt.toUpperCase() === '0'
                                              ? 'NUMERO'
                                              : 'TEXTO'
                                          }
                                          {...field}
                                          disabled={loading}
                                          key={index}
                                          autoComplete="off"
                                          fullWidth
                                          variant="outlined"
                                          value={getValues(`configuracoes.${config.indexOriginal}.vConfig`)}
                                          label={config.descConfig}
                                          onChange={(item) => {
                                            setValue(
                                              `configuracoes.${config.indexOriginal}.vConfig`,
                                              item.target.value
                                            );
                                            setValue(
                                              `configuracoes.${config.indexOriginal}.editado`,
                                              true
                                            );
                                          }}
                                        />
                                      </Box>
                                    )}
                                  </>
                                )}
                              />
                            </Grid>
                          );
                        } else {
                          return <></>;
                        }
                      })}

                    {grupo.substr(3) === 'Impressão' &&
                      getValues('tpCaixa') === EnumPDVTpCaixa.WEBPDV && (
                        <Grid item xs={12}>
                          <Box className={classes.divField}>

                            <Button
                              variant="contained"
                              fullWidth
                              color="primary"
                              onClick={handleDownload}
                            >
                              <ImpressoraIcon tipo="BUTTON_PRIMARY" />
                              <Typography variant="button">
                                Download Impressor
                              </Typography>
                            </Button>
                            <Tooltip
                              title={
                                <Typography className={classes.tooltipText} align='center'>Faça o download do componente impressor para que seja possível reimprimir os seus cupons fiscais e controlar suas impressoras.</Typography>
                              }
                            >
                              <div>
                                <InformacaoIcon tipo='GERAL' class={classes.infoIcon} />
                              </div>
                            </Tooltip>
                          </Box>
                        </Grid>
                      )}
                  </Grid>
                </AccordionSaurus>
              </div>
            );
          })}

      </>
    );
  };

  const planoIntegracaoPDV = (item: KeyValueModel) => {
    if (!isIntegracaoPDV) {
      if (item.Key === EnumPDVTpCaixa.SAURUSPDV) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading || carregando ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="numCaixa"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete="new-password"
                      inputRef={refInputDescricao}
                      fullWidth
                      disabled={loading}
                      label="Número do Caixa"
                      variant="outlined"
                      error={Boolean(
                        errors.numCaixa && errors.numCaixa.message
                      )}
                      helperText={
                        errors.numCaixa ? errors.numCaixa?.message : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete="new-password"
                      inputRef={refInputDescricao}
                      fullWidth
                      disabled={loading}
                      label="Descrição"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message
                      )}
                      helperText={
                        errors.descricao ? errors.descricao?.message : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="chave"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete="new-password"
                      inputRef={refInputDescricao}
                      fullWidth
                      disabled={
                        loading ||
                        getValues('tpCaixa') === EnumPDVTpCaixa.WEBPDV
                      }
                      label="Chave Única"
                      variant="outlined"
                      error={Boolean(errors.chave && errors.chave.message)}
                      helperText={
                        errors.chave ? errors.chave?.message : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldSaurus
                  autoComplete="new-password"
                  disabled={loading}
                  label={'Versão do PDV'}
                  readOnly
                  fullWidth={true}
                  variant="outlined"
                  value={
                    versao.length !== 0 ?
                      `${versao?.[0]}, ${versao?.[1]}, ${versao?.[2]}` : 'A versão não foi gerada.'
                  }
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  name="indStatus"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete="new-password"
                      disabled={loading}
                      label={'Situação'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                      value={
                        StatusIndstatusMock.filter(
                          (item) => getValues('indStatus') === item.Key
                        )[0]?.Value
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  name="tpCaixa"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      disabled={loading}
                      label={'Tipo PDV'}
                      fullWidth={true}
                      variant="outlined"
                      conteudo={TpCaixaMock.filter(planoIntegracaoPDV)}
                      error={Boolean(errors.tpCaixa && errors.tpCaixa.message)}
                      helperText={
                        errors.tpCaixa ? errors.tpCaixa?.message : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpCaixaMock.filter(
                          (e) => e.Key === event.target.value
                        )[0].Key;
                        if (item !== undefined) {
                          setCaixa(item);
                          setValue('tpCaixa', item);
                        }
                      }}
                      value={getValues('tpCaixa')}
                    />
                  )}
                />
              </Grid>
              {planoComEstoque ? (
                <Grid item xs={12} md={12}>
                  <Controller
                    name="depositoId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        label={'Depósito'}
                        fullWidth={true}
                        variant="outlined"
                        conteudo={DepositosMock}
                        error={Boolean(
                          errors.depositoId && errors.depositoId.message
                        )}
                        helperText={
                          errors.depositoId
                            ? errors.depositoId?.message
                            : undefined
                        }
                        {...field}
                        onChange={(event) => {
                          const item = DepositosMock.filter(
                            (e) => e.Key === event.target.value
                          )[0].Key;
                          if (item !== undefined) {
                            setValue('depositoId', item);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Box>{renderConfigs()}</Box>
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
