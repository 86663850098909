import { useCallback, useEffect, useState } from 'react';
import { Grid } from 'views/design-system';
import { useStyles } from './selecionar-mesa-list-styles';
import { SelecionarMesaListData } from './selecionar-mesa-list-data';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { FormSelecionarSalao } from 'views/components/form/selecionar-salao/form-selecionar-salao/form-selecionar-salao';
import { guidEmpty } from 'utils/guid-empty';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useMovValidators } from 'services/app/hooks/mov-validators';
interface SelecionarMesaListProps {
  codigoMesa: string;
}

export const SelecionarMesaList = ({ codigoMesa }: SelecionarMesaListProps) => {
  //PROVIDERS
  const { setMesaId, setSalaoId } = useMovAtual();
  const { showToast } = useToastSaurus();
  const { permiteEditarPedidoMesa } = useMovValidators();

  const {
    getSaloesCadastrados,
    getSalaoAtual,
    setSalaoAtual,
    getMesasCadastradas,
    getConfiguracoesMesaEComanda,
    carregando: carregandoHook,
  } = usePedidoLocal();
  const { avancarFluxoMov } = useMovRota();

  const salaoFavorito = getSalaoAtual();

  const [saloes, setSaloes] = useState<SaloesModel[]>([]);
  const [salaoAtualId, setSalaoAtualId] = useState<string>(() => {
    if (salaoFavorito) {
      return salaoFavorito.id!;
    }
    return guidEmpty();
  });
  const [mesas, setMesas] = useState<MesasModel[]>([]);

  //AUX
  const { push: historyPush } = useHistory();
  const classes = useStyles();
  const carregando = carregandoHook;

  useEffect(() => {
    const buscarSaloes = async () => {
      try {
        const res = await getSaloesCadastrados();
        setSaloes(res);

        if (res.length === 1) {
          setSalaoAtual(res[0].id!);
          setSalaoAtualId(res[0].id!);
        }
      } catch (err: any) {
        showToast('error', err.message);
      }
    };

    buscarSaloes();
  }, [getSaloesCadastrados, setSalaoAtual, showToast]);

  useEffect(() => {
    const buscarMesas = async () => {
      try {
        if (salaoAtualId === '') return;
        const responseMesa = await getMesasCadastradas(salaoAtualId);

        const mesasFiltered = codigoMesa
          ? responseMesa.filter((mesa) => mesa?.codigo?.includes(codigoMesa))
          : responseMesa;

        setMesas(mesasFiltered ?? []);
      } catch (err: any) {
        showToast('error', err.message);
      }
    };

    buscarMesas();
  }, [codigoMesa, getMesasCadastradas, salaoAtualId, showToast]);

  const onCardClicked = useCallback(
    async (model: MesasModel) => {
      try {
        await setMesaId(model.id, model.codigo);
        await setSalaoId(model.salaoId);
        await avancarFluxoMov();
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [avancarFluxoMov, setMesaId, setSalaoId, showToast],
  );

  const onClickBalcao = useCallback(
    async (balcao: SaloesModel) => {
      try {
        await setMesaId('');
        await setSalaoId(balcao.id!);
        await avancarFluxoMov();
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [avancarFluxoMov, setMesaId, setSalaoId, showToast],
  );

  useEffect(() => {
    (async () => {
      permiteEditarPedidoMesa().then(async (res) => {
        if (!res) {
          try {
            await avancarFluxoMov();
          } catch (e: any) {
            showToast('error', e.message)
          }
        }
      }).catch((e) => showToast('error', e.message));
    })()
  }, [avancarFluxoMov, getConfiguracoesMesaEComanda, getSaloesCadastrados, historyPush, permiteEditarPedidoMesa, showToast])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}

      <Grid className={classes.root}>
        <Grid container flexDirection="column">
          {saloes.length > 1 && (
            <Grid item style={{ padding: '0px 8px', background: '#fff' }}>
              <FormSelecionarSalao
                setSalaoAtual={setSalaoAtual}
                setSalaoAtualId={setSalaoAtualId}
                saloes={saloes}
                model={getSalaoAtual()}
              />
            </Grid>
          )}
          <Grid item xs={12} style={{ padding: '8px' }}>
            <SelecionarMesaListData
              salao={saloes.find((salao) => salao.id === salaoAtualId)}
              carregando={carregando}
              list={mesas}
              selectedList={[]}
              onCardClicked={onCardClicked}
              onClickBalcao={onClickBalcao}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
