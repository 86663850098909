import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPoliticaValidar() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPoliticaValidar = useCallback(
        () =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Termo?tipoSolucao=touchone`,
                method: 'GET',
                enviarTokenUsuario: false
            })
        ,
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getPoliticaValidar,
    };

}
