import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({


    botaoTipoRegraHeader: {
        width: 'auto',
        flex: 'none',
        backgroundColor: theme.palette.primary.dark,
        "& .buttonSpan": {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    tipoRegraHeader: {
        whiteSpace: 'nowrap',
        color: theme.palette.primary.contrastText,
        fontSize: 10,
    },
    dadosIndex: {
        whiteSpace: 'nowrap',
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
        fontSize: 14,
    },
    formContent: {
    },
    contentValoresProduto: {
        flex: 'none',
        borderBottom: '1px solid ' + theme.palette.grey[300],
        "& div": {
            paddingLeft: 8,
            paddingRight: 8,
        },
        "& span": {
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 12,
            textAlign: 'right',
            [theme.breakpoints.down('xs')]: {
                fontSize: 11,
            }
        },
        "& h6": {
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: -8,
            fontWeight: 600,
            fontSize: 18,
            textAlign: 'right',
            [theme.breakpoints.down('xs')]: {
                fontSize: 15,
            }
        }
    },
    contentCamposPromocao: {
        flex: 'none',
        borderBottom: '1px solid ' + theme.palette.grey[300],
        padding: 4,
    },
    campoContainer: {
        position: 'relative',
        textAlign: 'right',
        padding: 4,
        "& svg": {
            width: 24,
            height: 24,
            position: 'absolute',
            left: 16,
            top: '50%',
            marginTop: -12,
            fill: theme.palette.text.hint
        }
    },
    campo: {
        padding: '4px 8px',
        width: '100%',
        height: '100%',
        borderRadius: 4,
        background: theme.palette.common.white,
        border: '2px solid ' + theme.palette.grey[300],
        [theme.breakpoints.down('xs')]: {
            padding: 4,
            paddingLeft: 0,
        },
        "& span": {
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 14,
            textAlign: 'right',
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            }
        },
        "& h6": {
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: -8,
            fontWeight: 600,
            fontSize: 18,
            textAlign: 'right',
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
            }
        }
    },
    campoErro: {
        border: '2px solid ' + theme.palette.error.main,
    },
    campoSelecionado: {
        border: '2px solid ' + theme.palette.primary.main,
    },
    campoSelecionadoErro: {
        outline: '1px solid ' + theme.palette.error.main,
    },
    acoes: {
        paddingTop: 0,
    },
    stepper: {
        '& .MuiStepper-root': {
            padding: 0
        }
    },
    mobileBarProgress: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    customStepContainer: {
        background: 'transparent',
        padding: '0px',
        paddingBottom: theme.spacing(2),
        margin: 0,
        marginLeft: theme.spacing(2) * -1,
        marginRight: theme.spacing(2) * -1,
    },
}));