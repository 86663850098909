import { CardRelatoriosAvancadosProps } from './card-relatorios-avancados-props';
import { useCallback } from 'react';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../../theme/util-styles';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { Tooltip, Typography } from 'views/design-system';

export const CardRelatoriosAvancados = ({
  model,
  onClick,
  selected
}: CardRelatoriosAvancadosProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch(model.ativo) {
      case 1:
        return theme.palette.success.main
      case 2:
        return theme.palette.error.main;
      default:
          return theme.palette.grey[300];
    }
  }, [model.ativo, theme.palette.success.main, theme.palette.error.main, theme.palette.grey]);

  const descricao = model.ativo
    ? 'Relatório Habilitado'
    : 'Relatório Desabilitado';

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={defaultCardClasses.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={defaultCardClasses.cardContent} style={{ justifyContent: 'flex-start' }}>
          <div
            className={defaultCardClasses.celulaGrid}
            style={{
              width: 130 
            }}
          >
            <Typography color="textPrimary" variant="caption">
              Código
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.codigo === 0 || model.codigo === null || model.codigo === undefined
                  ? 'Sem código'
                  : model.codigo
              }
            </Typography>
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
            style={{
              flexWrap: 'wrap'
            }}
          >
            <Typography color="textPrimary" variant="caption">
              Descrição relatório
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.nome !== null ? model.nome : 'Sem descrição'}</strong>
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Tirar relatório">
          <div className={defaultCardClasses.cardRightButton}>
            <ImpressoraIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
