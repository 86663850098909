import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: theme.spacing(2),
        minHeight: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridGap: theme.spacing(2),
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        }
    },
    cardContainer: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        transition: "0.3s ease-in-out border",
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(8)
        }
    },
    cardContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    submitButton: {
        boxShadow: theme.shadows[8],
        position: "fixed",
        width: '95%',
        right: theme.spacing(1),
        left: theme.spacing(1),
        bottom: theme.spacing(12),
        zIndex: 2
    },
    pedido: {
        backgroundColor: 'rgb(187 251 194)',
        padding: theme.spacing(0.5, 1),
        color: theme.palette.success.main,
        fontWeight: 600,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(1),
    }
}));