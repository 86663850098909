import { toDateStringApi } from 'utils/to-date';
import { toDate } from 'utils/to-date';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import React, { useState, useCallback, useRef } from 'react';
import { useGetFinalizadoras } from 'data/api/gestao/finalizadora';
import { useGetNcms } from 'data/api/gestao/ncm';
import { useGetPessoas } from 'data/api/gestao/pessoa';
import { useGetProdutosFiscais } from 'data/api/gestao/produto-fiscal/get-produtos-fiscais';
import { useGetProdutoCategorias } from 'data/api/gestao/produto/produto-categoria';
import { useGetProdutosCatalogo } from 'data/api/gestao/produto/produto/get-produtos-catalogo';
import { EnumSincronizacao } from 'model/enums/enum-sincronizacao';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { useToastSaurus } from './toast-saurus';
import { useHistory } from 'react-router-dom';
import { RetornoApiModel } from 'model/api/gestao/retorno-api-model';
import { TouchoneDBPrimary, TouchoneDBSinc } from 'database/touchone-database';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useGetMesas } from 'data/api/gestao/mesas/get-mesas';
import { useGetSaloes } from 'data/api/gestao/saloes/get-saloes';
import { useGetComandas } from 'data/api/gestao/comandas/get-comandas';
import { useCadastroPadrao } from './cadastro-padrao';
import { TabelaProdutos } from 'database/interfaces/interface-tabela-produtos';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { useContratoAtual } from './contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useGetModificadoresParaCatalogo } from 'data/api/gestao/modificador/get-modificadores-catalogo';
import { UFMock } from 'data/mocks';
import { useEmpresaAtual } from './empresa-atual';
import { useSessaoAtual } from '../providers';
import { useGetPromocaoCargaDePor } from 'data/api/gestao/promocao/get-promocao-carga-de-por';
import { PromocaoCargaDePorModel } from 'model/api/gestao/promocao/promocao-carga-de-por-model';
import { toDateString } from 'utils/to-date';
import { TabelaPromocoesDePor } from 'database/interfaces/interface-tabela-promocoes-de-por';
import { useGetPromocaoExiste } from 'data/api/gestao/promocao/get-promocao-existe';
import { useMovRota } from './mov-rota';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { useGetMedicamentoCarga } from 'data/api/gestao/medicamento-preco/get-medicamento-carga';
import { toDecimal } from 'utils/to-decimal';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';

export interface SincronizacaoDados {
  possuiPromocao: boolean | undefined;
  dataPromocao: string | undefined;
  dataSucesso: string | undefined;
  dataUltima: string | undefined;
  statusUltima: IStatusType[];
}

export interface IStatusType {
  tipo: EnumSincronizacao;
  sucesso: boolean;
  paginaAtual: number;
  qtdSincronizado: number;
  qtdASincronizar: number;
}
interface ISincAtual {
  sincAtual: EnumSincronizacao;
  pageAtual?: number | undefined;
  pageSize?: number | undefined;
  totalPage?: number | undefined;
  totalResults?: number | undefined;
}

export function useSincronizacaoCadastros() {
  // STATES E REFS
  const statusRef = useRef<IStatusType[]>([]);
  const [sincAtual, setSincAtual] = useState<ISincAtual | undefined>();

  const medicamentosCodAnvisa = useRef<TabelaProdutos[]>([]);

  // PROVIDERS
  const { getConsumidor } = useCadastroPadrao();
  const { showToast } = useToastSaurus();
  const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();
  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();
  const { plano } = useSessaoAtual();
  const { getEmpresaAtual, refreshEmpresa } = useEmpresaAtual();

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);
  const { redirectLanding } = useMovRota();
  // CHAMADAS API
  const { getProdutosCatalogo, carregando: carregandoGetProdutos } =
    useGetProdutosCatalogo();
  const { getPessoas, carregando: carregandoPessoas } = useGetPessoas();
  const { getProdutoCategorias, carregando: carregandoCategorias } =
    useGetProdutoCategorias();
  const { getProdutosFiscais, carregando: carregandoImpostos } =
    useGetProdutosFiscais();
  const { getFinalizadoras, carregando: carregandoFinalizadoras } =
    useGetFinalizadoras();
  const { getNcms, carregando: carregandoNcms } = useGetNcms();
  const { getMesas, carregando: carregandoMesas } = useGetMesas();
  const { getSaloes, carregando: carregandoSaloes } = useGetSaloes();
  const { getComandas, carregando: carregandoComandas } = useGetComandas();
  const { getModificadoresParaCatalagos, carregando: carregandoModificadores } = useGetModificadoresParaCatalogo();
  const { getMedicamentoCarga, carregando: carregandoMedicamentoCarga } = useGetMedicamentoCarga();
  const { getPromocaoCargaDePor, carregando: carregandoPromocaoDePor } = useGetPromocaoCargaDePor();
  const { getPromocaoExiste, carregando: carregandoPromocaoExiste } = useGetPromocaoExiste();

  // OUTROS
  const carregando =
    carregandoGetProdutos ||
    carregandoPessoas ||
    carregandoCategorias ||
    carregandoImpostos ||
    carregandoFinalizadoras ||
    carregandoNcms ||
    carregandoMesas ||
    carregandoSaloes ||
    carregandoModificadores ||
    carregandoPromocaoDePor ||
    carregandoPromocaoExiste ||
    carregandoMedicamentoCarga ||
    carregandoComandas;

  const idProdutoTaxa = getContratoConfigByCod(EnumContratoConfig.ProdutoServico);
  const history = useHistory();
  const { callEvent } = useEventTools();

  const setUltimaSincronizacao = React.useCallback(
    (sinc: SincronizacaoDados | undefined) => {
      if (sinc === undefined)
        delRegistro(GestaoStorageKeys.UltimaSincronizacao, false);
      else setRegistro(GestaoStorageKeys.UltimaSincronizacao, sinc, false);
    },
    [delRegistro, setRegistro]
  );

  const getUltimaSincronizacao = React.useCallback(():
    | SincronizacaoDados
    | undefined => {
    const statusUltimaSinc = getRegistro(
      GestaoStorageKeys.UltimaSincronizacao,
      false
    ) as SincronizacaoDados;

    if (Object.entries(statusUltimaSinc).length === 0) {
      return undefined;
    }
    return statusUltimaSinc;
  }, [getRegistro]);

  const persistIndexedDBSecundario = async (tipo: Number, data: any[]) => {
    switch (tipo) {
      case EnumSincronizacao.PRODUTOS:
        await TouchoneDBSinc.produtos.bulkAdd(data);
        break;
      case EnumSincronizacao.CLIENTES:
        await TouchoneDBSinc.clientes.bulkAdd(data);
        break;
      case EnumSincronizacao.FINALIZADORAS:
        await TouchoneDBSinc.finalizadoras.bulkAdd(data);
        break;
      case EnumSincronizacao.IMPOSTOS:
        await TouchoneDBSinc.impostos.bulkAdd(data);
        break;
      case EnumSincronizacao.NCMS:
        await TouchoneDBSinc.ncms.bulkAdd(data);
        break;
      case EnumSincronizacao.CATEGORIAS:
        await TouchoneDBSinc.categorias.bulkAdd(data);
        break;
      case EnumSincronizacao.MESAS:
        await TouchoneDBSinc.mesas.bulkAdd(data);
        break;
      case EnumSincronizacao.COMANDAS:
        await TouchoneDBSinc.comandas.bulkAdd(data);
        break;
      case EnumSincronizacao.SALOES:
        await TouchoneDBSinc.saloes.bulkAdd(data);
        break;
      case EnumSincronizacao.MODIFICADORES:
        await TouchoneDBSinc.modificadores.bulkAdd(data);
        break;
      case EnumSincronizacao.MEDICAMENTOS:
        await TouchoneDBSinc.medicamentos.bulkAdd(data);
        break;
      default:
        return;
    }
  };

  const removerErroDeFalhaNaSincronizacao = useCallback(
    (tipo: EnumSincronizacao, paginaAtual: number) => {
      const atual = getUltimaSincronizacao();
      if (atual === undefined) {
        return;
      }

      const alterandoOErroDoStorage = atual.statusUltima!.map((erro) => {
        if (erro.tipo === tipo && erro.paginaAtual === paginaAtual) {
          return { ...erro, sucesso: true };
        }
        return erro;
      });

      atual.statusUltima = alterandoOErroDoStorage;
      setUltimaSincronizacao(atual);
    },
    [getUltimaSincronizacao, setUltimaSincronizacao]
  );

  const temErroDeFalhaNaSincronizacao = useCallback(
    (tipo: EnumSincronizacao, paginaAtual: number, status: boolean) => {
      const atual = getUltimaSincronizacao();
      if (atual === undefined) {
        return false;
      }
      const temErroDoStorage = atual.statusUltima?.find(
        (erro) =>
          erro.sucesso === status &&
          erro.tipo === tipo &&
          erro.paginaAtual === paginaAtual
      );
      return temErroDoStorage;
    },
    [getUltimaSincronizacao]
  );

  const sincronizarTabela = useCallback(
    async (
      funcaoGet: (...args: any[]) => Promise<RetornoApiModel>,
      tipo: EnumSincronizacao,
      EmpresaId?: string,
      precisaDoConsumidorPadrao: boolean = false,
      paginaAtual?: number,
      postProcessing?: Function,
      pageSize: number = 100
    ) => {
      let totalPage = 0;
      let pageAtual = paginaAtual ? paginaAtual : 1;
      let data: any[] = [];

      let query = '&pageSize=' + pageSize;

      if (precisaDoConsumidorPadrao) {
        const consumidor = await getConsumidor();
        if (consumidor === undefined) {
          throw new Error(
            'Consumidor padrão não identificado para recuperar os produtos para catalogo'
          );
        }
        query +=
          `&TabelaPrecoId=${consumidor.tabelaPrecoId}` +
          `&RegimeTributarioId=${consumidor.regimeTributarioId}`;
      }
      setSincAtual({ pageSize: pageSize, pageAtual, sincAtual: tipo });

      let result: RetornoApiModel = {
        resultado: undefined,
        erro: undefined,
        tipoRetorno: 0,
        statusCode: 0,
        isTimeout: false
      };

      if (EmpresaId) {
        result = await funcaoGet(query, EmpresaId, pageAtual);
      } else {
        result = await funcaoGet(query, pageAtual);
      }

      const temErro = temErroDeFalhaNaSincronizacao(tipo, pageAtual, false);

      if (temErro && result.erro) {
        // se tem erro no get e ja registramos esse erro antes, segue o fluxo
      } else if (result.erro) {
        statusRef.current = [
          ...statusRef.current,
          {
            tipo: tipo,
            sucesso: false,
            paginaAtual: pageAtual,
            qtdASincronizar: result.resultado?.data?.totalResults ?? 0,
            qtdSincronizado: result.resultado?.data?.list.length ?? 0
          }
        ];
        return false;
      } else if (paginaAtual) {
        removerErroDeFalhaNaSincronizacao(tipo, paginaAtual);
      } else {
        statusRef.current = [
          ...statusRef.current,
          {
            tipo: tipo,
            sucesso: true,
            paginaAtual: pageAtual,
            qtdASincronizar: result.resultado?.data?.totalResults ?? 0,
            qtdSincronizado: result.resultado?.data?.list.length ?? 0
          }
        ];
      }

      // Aqui eu preencho os dados da primeira chamada
      data = [...result.resultado?.data?.list];
      // total de paginas no endpoint pra buscar todos os registros
      totalPage = result.resultado?.data?.totalPages ?? 1;
      // incremento mais um para partir da segunda chamada
      pageAtual += 1;

      for (; pageAtual <= totalPage;) {
        let result: RetornoApiModel = {
          resultado: undefined,
          erro: undefined,
          tipoRetorno: 0,
          statusCode: 0,
          isTimeout: false
        };

        if (EmpresaId) {
          result = await funcaoGet(query, EmpresaId, pageAtual);
        } else {
          result = await funcaoGet(query, pageAtual);
        }

        const temErro = temErroDeFalhaNaSincronizacao(tipo, pageAtual, false);

        if (temErro && result.erro) {
          // se tem erro no get e já registramos esse erro, segue.
        } else if (result.erro) {
          statusRef.current = [
            ...statusRef.current,
            {
              tipo: tipo,
              sucesso: false,
              paginaAtual: pageAtual,
              qtdASincronizar: result.resultado?.data?.totalResults ?? 0,
              qtdSincronizado: result.resultado?.data?.list.length ?? 0
            }
          ];
        } else if (temErro) {
          removerErroDeFalhaNaSincronizacao(tipo, pageAtual);
        } else {
          statusRef.current = [
            ...statusRef.current,
            {
              tipo: tipo,
              sucesso: true,
              paginaAtual: pageAtual,
              qtdASincronizar: result.resultado?.data?.totalResults ?? 0,
              qtdSincronizado: result.resultado?.data?.list.length ?? 0
            }
          ];
        }

        totalPage = result.resultado?.data?.totalPages ?? 1;

        setSincAtual({
          pageAtual,
          pageSize: pageSize,
          sincAtual: tipo,
          totalPage,
          totalResults: result.resultado?.data?.totalResults
        });

        if (result.resultado?.data?.list) {
          data = [...data, ...result.resultado?.data?.list];
        }
        pageAtual += 1;
      }

      await persistIndexedDBSecundario(tipo, tipo === EnumSincronizacao.PRODUTOS ? (data as TabelaProdutos[]).map((p) => {
        return {
          ...p,
          numCodigos: p.codigos?.length ?? 0
        }
      }) : data);

      if (postProcessing) {
        postProcessing();
      }

      setSincAtual(undefined);

      return true;
    },
    [getConsumidor, removerErroDeFalhaNaSincronizacao, temErroDeFalhaNaSincronizacao]
  );

  const sincronizarMesasEComandas = useCallback(
    async (
      funcaoGet: (...args: any[]) => Promise<RetornoApiModel>,
      tipo: EnumSincronizacao,
      EmpresaId?: string,
      paginaAtual?: number
    ) => {
      let pageAtual = paginaAtual ? paginaAtual : 1;
      let data: any[] = [];

      setSincAtual({ pageAtual, sincAtual: tipo });

      let result: RetornoApiModel = {
        resultado: undefined,
        erro: undefined,
        tipoRetorno: 0,
        statusCode: 0,
        isTimeout: false,
      };

      let query = 'pageSize=0';

      result = await funcaoGet(query, EmpresaId);

      const temErro = temErroDeFalhaNaSincronizacao(tipo, pageAtual, false);

      if (temErro && result.erro) {
        // se tem erro no get e ja registramos esse erro antes, segue o fluxo
      } else if (result.erro) {
        statusRef.current = [
          ...statusRef.current,
          {
            tipo: tipo,
            sucesso: false,
            paginaAtual: 1,
            qtdASincronizar: result.resultado?.data?.list.length ?? 0,
            qtdSincronizado: result.resultado?.data?.list.length ?? 0
          }
        ];
        return false;
      } else if (paginaAtual) {
        removerErroDeFalhaNaSincronizacao(tipo, paginaAtual);
      } else {
        statusRef.current = [
          ...statusRef.current,
          {
            tipo: tipo,
            sucesso: true,
            paginaAtual: pageAtual,
            qtdASincronizar: result.resultado?.data?.list.length ?? 0,
            qtdSincronizado: result.resultado?.data?.list.length ?? 0
          }
        ];
      }

      // Aqui eu preencho os dados da primeira chamada
      data = [...result.resultado?.data.list];
      // total de paginas no endpoint pra buscar todos os registros

      await persistIndexedDBSecundario(tipo, data);
      setSincAtual(undefined);

      return true;
    },
    [removerErroDeFalhaNaSincronizacao, temErroDeFalhaNaSincronizacao]
  );

  const sincronizarMedicamentos = useCallback(async (produtosComCodAnvisa: TabelaProdutos[], forcar: boolean) => {
    let medicamentos = await TouchoneDBPrimary.medicamentos.toArray();
    if (forcar)
      medicamentos = [];

    let codigos = Array.from(new Set(produtosComCodAnvisa.map(p => p.codigoAnvisa).filter(c => c !== null)));
    codigos = codigos.filter((item, index) => codigos.indexOf(item) === index);

    let cods = codigos.filter(x => forcar ? true : medicamentos.findIndex(y => y.codigoAnvisa === x) === -1).map(c => {
      const prods = produtosComCodAnvisa.filter(p => p.codigoAnvisa === c) ?? [];
      return {
        codAnvisa: c,
        prods
      }
    });

    const pageSize = 1000;
    const pages = toDecimal(Math.floor(cods.length / pageSize), 0) + (cods.length % pageSize > 0 ? 1 : 0);
    const uf = UFMock.find(uf => uf.Value === getEmpresaAtual()?.uf)?.Key ?? 35;

    for (let i = 0; i < pages; i++) {

      setSincAtual({
        totalResults: cods.length,
        pageSize: pageSize,
        sincAtual: EnumSincronizacao.MEDICAMENTOS,
        pageAtual: i + 1,
        totalPage: pages
      });

      const codsPage = cods.map(x => x.codAnvisa).filter((x): x is string => x !== null);
      const codsEnv = codsPage.slice(i * pageSize, (i + 1) * pageSize);
      const res = await getMedicamentoCarga(codsEnv || [], uf);

      if (res.erro) {
        continue
      }

      if (res.resultado?.data.length > 0) {
        medicamentos = [...medicamentos, ...res.resultado?.data];
      }

    }

    const prodsSet = [];
    for (let j = 0; j < medicamentos.length; j++) {
      const prods = await TouchoneDBSinc.produtos.where({ codigoAnvisa: medicamentos[j].codigoAnvisa }).toArray();
      for (let k = 0; k < prods.length; k++) {
        prods[k].medicamento = medicamentos[j];
      }
      prodsSet.push(...prods);
    }
    await TouchoneDBSinc.produtos.bulkPut(prodsSet);

    await persistIndexedDBSecundario(EnumSincronizacao.MEDICAMENTOS, medicamentos);

  }, [getEmpresaAtual, getMedicamentoCarga])

  const transformarPromocaoCargaDePorModel = (promocaoCarga: PromocaoCargaDePorModel): TabelaPromocoesDePor[] => {
    const resultado: TabelaPromocoesDePor[] = [];

    for (const detalhe of promocaoCarga.promocoes) {
      for (const item of detalhe.itens) {
        resultado.push({
          promocaoId: detalhe.promocaoId,
          promocao: detalhe.promocao,
          dataInicial: parseInt(toDateString(toDate(detalhe.dataInicial), "yyyyMMDD") ?? '0'),
          dataFinal: parseInt(toDateString(toDate(detalhe.dataFinal), "yyyyMMDD") ?? '0'),
          horaInicial: detalhe.horaInicial,
          horaFinal: detalhe.horaFinal,
          diasSemana: detalhe.diasSemana,
          ativo: item.ativo,
          valorPromocao: item.valorPromocao,
          variacaoId: item.variacaoId,
          codigo: item.codigo,
        });
      }
    }

    return resultado;
  }

  const sincronizarPromocoesDePor = useCallback(async (sincronizacao: SincronizacaoDados, forcar: boolean) => {

    const maxDate = toDate(sincronizacao?.dataPromocao) ?? new Date(2000, 1, 1, 1, 1, 1, 1);

    setSincAtual({ sincAtual: EnumSincronizacao.PROMOCOES_DEPOR });

    let todasPromos: TabelaPromocoesDePor[] = []
    if (!forcar)
      todasPromos = await TouchoneDBPrimary.promocoes.toArray();

    const resExiste = await getPromocaoExiste();
    if (resExiste.erro) {
      return;
    }
    sincronizacao.possuiPromocao = resExiste.resultado?.data;

    sincronizacao.dataPromocao = (resExiste.resultado?.data as PromocaoCargaDePorModel).dataServidor;

    const res = await getPromocaoCargaDePor(maxDate);
    if (res.erro) {
      return;
    }
    const promosRes = res.resultado?.data as PromocaoCargaDePorModel;
    let novasPromos = transformarPromocaoCargaDePorModel(promosRes);
    const anoMesDia = parseInt(toDateString(new Date(), 'yyyyMMDD') ?? '0');
    sincronizacao.dataPromocao = promosRes.dataServidor;

    //ADICIONO TODAS AS PROMOCOES ANTERIORES QUE NAO ESTAO NA NOVA CARGA
    //ESTOU TIRANDO AS PROMOCOES QUE NAO ESTAO NA NOVA CARGA ANALISANDO O RESPONSE POIS A NOVASPROMOS SO VAI TRAZER SE O ARRAY DE ITENS[] > 0
    novasPromos = [...novasPromos, ...todasPromos.filter(x => promosRes.promocoes.findIndex(y => y.promocaoId === x.promocaoId) === -1)]
    if (novasPromos.length > 0) {
      const promosBanco = novasPromos.filter(x => x.dataFinal >= anoMesDia)
      for (let i = 0; i < promosBanco.length; i++) {
        const promo = promosBanco[i];
        const prod = await TouchoneDBSinc.produtos.where({ produtoGradeId: promo.variacaoId }).first();
        if (prod) {
          let promosProd = prod.promocoes ?? []
          promosProd = [...promosProd, promo];
          //SO DEIXO NO PRODUTO AS PROMOCOES ATIVAS E AS QUE TEM PROMOCAOID NO BANCO
          promosProd = promosProd.filter(x => x.ativo);

          await TouchoneDBSinc.produtos.update(prod.idIndexed!, {
            promocoes: promosProd
          });
        }
      }
      TouchoneDBSinc.promocoes.bulkAdd(promosBanco.filter(x => x.ativo));
    }

  }, [getPromocaoCargaDePor, getPromocaoExiste]);

  const sincronizarConfiguracoes = useCallback(async () => {
    setSincAtual({ sincAtual: EnumSincronizacao.CONFIGURACOES });

    try {
      await refreshEmpresa();
    } catch (e) {
    }
  }, [refreshEmpresa]);

  const sincronizarCadastros = useCallback(
    async (empresaId: string, forcar: boolean) => {

      //PEGA A ULTIMA SINCRONIZACAO
      let ultima = getUltimaSincronizacao();
      if (!ultima)
        //SE NAO EXISTIR, CRIA UMA NOVA
        ultima = {
          possuiPromocao: false,
          dataSucesso: undefined,
          dataPromocao: undefined,
          dataUltima: toDateStringApi(new Date()),
          statusUltima: []
        };

      //SE FOR FORÇAR A SINCRONIZACAO, LIMPA A DATA DA PROMOCAO PARA PEGAR TODAS
      if (forcar) {
        ultima.dataPromocao = undefined;
      }

      ultima.dataUltima = toDateStringApi(new Date());
      ultima.statusUltima = [];

      setUltimaSincronizacao(ultima);
      TouchoneDBSinc.limparDados();

      await sincronizarConfiguracoes();

      await sincronizarTabela(getPessoas, EnumSincronizacao.CLIENTES);

      await sincronizarTabela(getNcms, EnumSincronizacao.NCMS);
      await sincronizarTabela(getModificadoresParaCatalagos, EnumSincronizacao.MODIFICADORES);
      await sincronizarTabela(
        getProdutosCatalogo,
        EnumSincronizacao.PRODUTOS,
        empresaId,
        true,
        undefined,
        async () => {
          const prods = (await TouchoneDBSinc.produtos.toArray()).filter(produtos => produtos.codigoAnvisa);
          medicamentosCodAnvisa.current = prods;
        }, 500
      );
      await sincronizarTabela(
        getProdutoCategorias,
        EnumSincronizacao.CATEGORIAS,
        undefined,
        undefined,
        undefined,
        async () => {
          //ATUALIZO AS CATEGORIAS PARA SABER SE TEM PRODUTOS
          const prods = (await TouchoneDBSinc.produtos.toArray()).filter(
            (prod) => prod.produtoId !== idProdutoTaxa
          );
          var cats = await TouchoneDBSinc.categorias.toArray();
          cats.forEach((c) => {
            var produtos = prods.findIndex(
              (x) => x.categoriaId === c.id && x.ativo && (x.tipo === EnumTipoProduto.Produto || x.tipo === EnumTipoProduto.Combo || x.tipo === EnumTipoProduto.Medicamento)
            );

            TouchoneDBSinc.categorias.update(c.idIndexed!, {
              temProdutos: produtos > -1
            });
          });
        }
      );
      await sincronizarTabela(
        getProdutosFiscais,
        EnumSincronizacao.IMPOSTOS,
        empresaId
      );

      await sincronizarPromocoesDePor(ultima, forcar);

      if (medicamentosCodAnvisa.current.length > 0 && isFarmaceutico) {
        await sincronizarMedicamentos(medicamentosCodAnvisa.current, forcar);
      }
      await sincronizarTabela(
        getFinalizadoras,
        EnumSincronizacao.FINALIZADORAS
      );
      await sincronizarMesasEComandas(
        getMesas,
        EnumSincronizacao.MESAS,
        empresaId
      );
      await sincronizarMesasEComandas(
        getSaloes,
        EnumSincronizacao.SALOES,
        empresaId
      );
      await sincronizarMesasEComandas(
        getComandas,
        EnumSincronizacao.COMANDAS,
        empresaId
      );

      ultima.dataUltima = toDateStringApi(new Date())
      ultima.statusUltima = statusRef.current;

      setUltimaSincronizacao(ultima);
    },
    [getComandas, getFinalizadoras, getMesas, getModificadoresParaCatalagos, getNcms, getPessoas, getProdutoCategorias, getProdutosCatalogo, getProdutosFiscais, getSaloes, getUltimaSincronizacao, idProdutoTaxa, isFarmaceutico, setUltimaSincronizacao, sincronizarConfiguracoes, sincronizarMedicamentos, sincronizarMesasEComandas, sincronizarPromocoesDePor, sincronizarTabela]
  );

  const limparCadastros = useCallback(async () => {
    // Limpando os dados da tabela
    TouchoneDBPrimary.limparDados();
    TouchoneDBSinc.limparDados();
    setUltimaSincronizacao(undefined);
  }, [setUltimaSincronizacao]);

  const confirmarSincronizacao = useCallback(async () => {
    try {
      const ultimaSinc = getUltimaSincronizacao();
      if (!ultimaSinc) {
        return;
      }

      await TouchoneDBPrimary.limparDados();
      // setando os dados presentes no banco de dados temporario para o banco principal
      await TouchoneDBPrimary.clientes.bulkAdd(
        await TouchoneDBSinc.clientes.toArray()
      );
      await TouchoneDBPrimary.produtos.bulkAdd(
        await TouchoneDBSinc.produtos.toArray()
      );
      await TouchoneDBPrimary.ncms.bulkAdd(
        await TouchoneDBSinc.ncms.toArray()
      );
      await TouchoneDBPrimary.finalizadoras.bulkAdd(
        await TouchoneDBSinc.finalizadoras.toArray()
      );
      await TouchoneDBPrimary.impostos.bulkAdd(
        await TouchoneDBSinc.impostos.toArray()
      );
      await TouchoneDBPrimary.categorias.bulkAdd(
        await TouchoneDBSinc.categorias.toArray()
      );
      await TouchoneDBPrimary.mesas.bulkAdd(
        await TouchoneDBSinc.mesas.toArray()
      );
      await TouchoneDBPrimary.saloes.bulkAdd(
        await TouchoneDBSinc.saloes.toArray()
      );
      await TouchoneDBPrimary.comandas.bulkAdd(
        await TouchoneDBSinc.comandas.toArray()
      );
      await TouchoneDBPrimary.modificadores.bulkAdd(
        await TouchoneDBSinc.modificadores.toArray()
      );
      await TouchoneDBPrimary.medicamentos.bulkAdd(
        await TouchoneDBSinc.medicamentos.toArray()
      );
      await TouchoneDBPrimary.promocoes.bulkAdd(
        await TouchoneDBSinc.promocoes.toArray()
      );

      ultimaSinc.dataSucesso = toDateStringApi(new Date())
      ultimaSinc.dataUltima = toDateStringApi(new Date())
      setUltimaSincronizacao(ultimaSinc);

      TouchoneDBSinc.limparDados();
    } catch (e: any) {
      limparCadastros();
      showToast(
        'error',
        'Ocorreu um erro inesperado, a operação foi abortada, tente novamente mais tarde.'
      );
      return history.push(RetaguardaRotasMock.dashboardRota.path);
    }
  }, [
    getUltimaSincronizacao,
    history,
    limparCadastros,
    setUltimaSincronizacao,
    showToast
  ]);

  const iniciarSincronizacao = useCallback(
    async (empresaId: string, forcar: boolean) => {
      try {
        callEvent(AppEventEnum.Sincronizacao, 0);
        await sincronizarCadastros(empresaId, forcar);


        //SE EXISTE ERRO
        if (getUltimaSincronizacao()?.statusUltima.find((sinc) => sinc.sucesso === false)
        ) {
          callEvent(AppEventEnum.Sincronizacao, 1);
          return history.push(PdvRotasMock.sincronizarResumoRota);
        }


        await confirmarSincronizacao();
        callEvent(AppEventEnum.Sincronizacao, 1);

        delRegistro(GestaoStorageKeys.PDVInicializado, false);
        delRegistro(GestaoStorageKeys.PDV, false);
        delRegistro(GestaoStorageKeys.Impressora, false);
        delRegistro(GestaoStorageKeys.ConfiguracoesMesaEComanda, false);

        redirectLanding(false);
        return;

      } catch (e: any) {
        TouchoneDBSinc.limparDados();
        showToast(
          'error',
          `Ocorreu um erro ao sincronizar os dados. Detalhes: ${e.message}`
        );
      }
    },
    [callEvent, confirmarSincronizacao, delRegistro, getUltimaSincronizacao, history, redirectLanding, showToast, sincronizarCadastros]
  );

  return {
    carregando,
    sincAtual,
    getUltimaSincronizacao,
    iniciarSincronizacao,
    limparCadastros
  };
}
