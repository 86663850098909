import { KeyValueModel } from "model";
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov";

export const StatusSituacaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpStatusMov.EmDigitacao, 'Em Digitação'),
    new KeyValueModel(EnumTpStatusMov.Finalizado, 'Finalizada'),
    new KeyValueModel(EnumTpStatusMov.Cancelado, 'Cancelada'),
]

export const StatusVendaMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, 'Todos'),
    new KeyValueModel(EnumTpStatusMov.EmDigitacao, 'Em Digitação'),
    new KeyValueModel(EnumTpStatusMov.Finalizado, 'Finalizada'),
    new KeyValueModel(EnumTpStatusMov.Cancelado, 'Cancelada'),
    new KeyValueModel(EnumTpStatusMov.Pendente, 'Pendente'),
    new KeyValueModel(EnumTpStatusMov.Rejeitado, 'Rejeitado'),
    new KeyValueModel(EnumTpStatusMov.Abortado, 'Abortado'),
]