import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const MenosSimplesIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={{ ...props.style }} fill={props.fill}>
                <path d="M 9 24 L 9 26 L 41 26 L 41 24 Z"></path>
            </DefaultIcon>
        </>
    );
};