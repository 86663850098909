import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, Grid, Typography } from 'views/design-system';
import { useFormPagerIdentificadorValidation } from './form-pager-identificacao-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../utils';
import { useStyles } from './form-pager-identificador-styles';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { useToastSaurus } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { FormIdentificadorModel } from 'model/api/gestao/pedido/pedido-dados-model';

interface FormPagerIdentificadorProps extends DefaultFormProps<FormIdentificadorModel> {
    salaoIdref: React.MutableRefObject<string>
}
export const FormPagerIdentificador = forwardRef<
    DefaultFormRefs<FormIdentificadorModel>,
    FormPagerIdentificadorProps
>((props: FormPagerIdentificadorProps, ref) => {
    const {
        getSaloesCadastrados,
    } = usePedidoLocal();
    const { showToast } = useToastSaurus()
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const [saloes, setSaloes] = useState<SaloesModel[]>([]);
    const carregando = props.loading
    const { FormPagerIdentificadorValidationYup } = useFormPagerIdentificadorValidation();
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
        getFieldState,
    } = useForm<FormIdentificadorModel>({
        defaultValues: { ...props.model },
        resolver: yupResolver(FormPagerIdentificadorValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
    });
    const buscarSaloes = useCallback(async () => {
        try {
            const res = await getSaloesCadastrados();
            const ret = res.filter(x => x.status.codigo === EnumStatusSalao.ATIVO)
            setSaloes(ret);
            return ret

        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getSaloesCadastrados, showToast]);
    const inputIdentificador = useRef<HTMLInputElement>(null);

    const onSubmit = (values: FormIdentificadorModel) => {
        props.onSubmit(values);

    };
    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: async (model: FormIdentificadorModel) => {
            const ret = await buscarSaloes();
            if (ret && ret.length > 0) {
                props.salaoIdref.current = ret[0].id || ''
                model.salaoId = ret[0].id || '';
            }
            reset({ ...model })
            if (inputIdentificador.current) {
                inputIdentificador.current.focus();
            }
        },
    }));
    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={carregando ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2} justifyContent='center' className={classes.container}>
                        <Grid item xs={12} mb={2} style={{ paddingBottom: 0 }}>
                            <Typography color='primary' variant='body1'>Informe abaixo a identificação do cliente para chamar quando o pedido estiver pronto.</Typography>
                        </Grid>
                        {saloes.length > 1 && saloes.filter(salao => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA).length > 0 ?
                            <Grid item xs={12} >
                                <Controller
                                    name="salaoId"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            label="Selecione o Salão"
                                            conteudo={saloes.map((valor: any) => {
                                                return new KeyValueModel(valor.id.toString(), valor.descricao);
                                            })}

                                            fullWidth={true}
                                            variant="outlined"
                                            {...field}
                                            onChange={(event) => {
                                                let salaoSelecionado = event.target.value
                                                props.salaoIdref.current = salaoSelecionado
                                                setValue('salaoId', salaoSelecionado)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            :
                            null}
                        <Grid item xs={12} >
                            <Controller
                                name="identificador"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={carregando}
                                        fullWidth
                                        inputRef={inputIdentificador}
                                        variant="outlined"
                                        label="Identificador do Pedido"
                                        placeholder='Exemplo: João / Pager 123'
                                        error={Boolean(
                                            getFieldState("identificador").error,
                                        )}
                                        allowSubmit
                                        helperText={
                                            errors.identificador
                                                ? errors.identificador?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>


                    </Grid >
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form >
            </div>
        </>
    )
});