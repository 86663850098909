import React, { useEffect, useState } from 'react';
import {
  ChangeColorsProps,
  useWhitelabelTheme
} from '../hooks/whitelabel-theme';
import { Theme, ThemeProvider } from '@material-ui/core';
import { useSessaoAtual } from './sessao-atual';
import { GestaoStorageKeys, useGestaoStorage } from '../hooks';
import { WhitelabelModel } from 'model/api/gestao/whitelabel/whitelabel-model';
import { ConfirmProvider } from 'material-ui-confirm';
import { isEmpty } from 'lodash';
import { consoleDev } from 'utils/console-dev';

interface WhiteLabelProviderProps {
  children: React.ReactNode;
}

export const WhiteLabelProvider = ({ children }: WhiteLabelProviderProps) => {
  const { getTemaAtual, mudarTema, defaultTheme } = useWhitelabelTheme();
  const { usuario } = useSessaoAtual();
  const { getRegistro } = useGestaoStorage();

  const [tema, setTema] = useState<Theme>(defaultTheme);

  //Se for Whitelabel do Safra não deve trocar o whitelabel
  const changeTheme =
    getRegistro(GestaoStorageKeys.Whitelabel, false) &&
    getRegistro(GestaoStorageKeys.Whitelabel, false).contratoId ===
    usuario?.empresa[0].ContratoId;

  consoleDev('WhiteLabelProvider');

  useEffect(() => {
    const whitelabel = getRegistro(
      GestaoStorageKeys.Whitelabel,
      false
    ) as WhitelabelModel;
    if (changeTheme) {
      const colors = new ChangeColorsProps();
      colors.mudarCor('primary', whitelabel.corPrincipal);
      if (whitelabel.corSecundaria) {
        colors.mudarCor('secondary', whitelabel.corSecundaria);
      }
      if (whitelabel.urlIcone) {
        let link = document.querySelector("link[rel~='icon']") as any;
        if (!link) {
          link = document.createElement('link');
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = whitelabel.urlIcone;
      }
      document.title = !isEmpty(whitelabel.nome) ? whitelabel.nome : 'PDV NET';
      mudarTema(colors);

      const temaAtual = getTemaAtual();
      setTema(temaAtual);
    }

    return () => {
      let link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        document.getElementsByTagName('head')[0].appendChild(link);
      }

      link.href = '';
      document.title = 'Ponto de Venda';
    };
  }, [changeTheme, getRegistro, getTemaAtual, mudarTema, usuario]);

  if (!changeTheme) {
    return <>{children}</>;
  }

  return (
    <ThemeProvider theme={tema}>
      <ConfirmProvider
        defaultOptions={{
          title: 'Confirma a Operação?',
          cancellationButtonProps: {
            variant: 'text',
            color: 'secondary',
            size: 'large'
          },
          confirmationButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'large'
          },
          confirmationText: 'Sim',
          cancellationText: 'Não'
        }}
      >
        {children}
      </ConfirmProvider>
    </ThemeProvider>
  );
};
