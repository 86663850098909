import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { Button, Grid } from 'views/design-system';
import { CircularLoading } from "views/components";
import { PrivatePageHeader } from "views/components/headers";
import { CardPerformanceRelatorio } from "./components/card-performance-relatorio/card-performance-relatorio";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RelatorioRender, useRelatorioPerformance } from "services/app/providers/relatorio-performance-provider/relatorio-performance-provider";
import { DialogPerformanceRelatorio } from "./components/dialog-performance-relatorio/dialog-performance-relatorio";
import { ButtonPrivateHeader } from "views/components/controles";
import { FecharIcon, FiltroIcon, MenuIcon, ProcurarIcon, RelogioIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useStyles } from "./performance-debug-page-styles";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";


export const PerformanceDebugPage = () => {

    const classes = useDefaultCadastroStyles();
    const { btnPesquisa } = useStyles();
    const { theme, isMobile } = useThemeQueries();
    const { abrirMenu } = useMenuPrincipal();

    const { gerarRelatorio, limparRenderizacoes, carregando } = useRelatorioPerformance();

    const [list, setList] = useState<RelatorioRender[]>([])
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [dialogComponent, setDialogComponent] = useState<string>('');
    const [filtros, setFiltros] = useState<boolean>(false)
    const [termo, setTermo] = useState<string>('');

    const [filtro, setFiltro] = useState<'tempo' | 'vezes' | 'log' | string | undefined>()

    const bottomArea = useMemo(() => filtros ? (
        <Grid container spacing={2} style={{ padding: 15, background: theme.palette.background.default, borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Grid item xs={6} md={2}>
                <Button variant="contained" fullWidth color='primary' size="small" onClick={() => setFiltro('tempo')}>
                    <RelogioIcon tipo='BUTTON_PRIMARY' />
                    Filtrar por Tempo
                </Button>
            </Grid>
            <Grid item xs={6} md={2}>
                <Button variant="contained" fullWidth color='primary' size="small" onClick={() => setFiltro('log')}>
                    <RelogioIcon tipo='BUTTON_PRIMARY' />
                    Log
                </Button>
            </Grid>
            <Grid item xs={6} md={2}>
                <Button variant="contained" fullWidth color='primary' size="small" onClick={() => setFiltro('vezes')}>
                    <FecharIcon tipo='BUTTON_PRIMARY' />
                    Filtrar por Vezes
                </Button>
            </Grid>
            <Grid item xs={12} md={3}>
                <TextFieldSaurus
                    value={termo}
                    onChange={(e) => setTermo(e.target.value || '')}
                    tipo='TEXTO'
                    size="small"
                    placeholder='Nome Componente'
                    endAdornmentButton={
                        <Button
                            color="primary"
                            onClick={() => setFiltro(termo)}
                            className={btnPesquisa}
                            fullWidth={true}
                        >
                            <ProcurarIcon tipo="BUTTON" />
                        </Button>
                    }
                />
            </Grid>
            <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='outlined' color='primary' fullWidth={isMobile} onClick={() => {
                    limparRenderizacoes();
                    setList([])
                }}>
                    Limpar Renderizações
                </Button>
            </Grid>
        </Grid>
    ) : null, [btnPesquisa, filtros, isMobile, limparRenderizacoes, termo, theme.palette.background.default, theme.palette.divider])

    useEffect(() => {
        const renders = gerarRelatorio(filtro);
        setList(renders)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtro])

    const onCardClick = useCallback((name: string) => {
        setDialogComponent(name)
        setOpenDialog(true)
    }, [])

    const leftArea = useCallback(() => (
        isMobile ?
            <ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={abrirMenu}
            /> :
            null
    ),
        [isMobile, abrirMenu]
    );

    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <PrivatePageHeader title="Relatório de Renderizações"
                    rightArea={(
                        <ButtonPrivateHeader
                            icon={<FiltroIcon tipo='PRIVATE_HEADER' />}
                            onClick={() => setFiltros(prev => !prev)}
                            tooltip="Filtros"
                        ></ButtonPrivateHeader>
                    )}
                    bottomArea={bottomArea}
                    leftArea={leftArea()}
                ></PrivatePageHeader>
            </Grid>
            <Grid className={classes.list} style={{ padding: '0 15px' }}>
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                {list.map(render => (
                    <Grid item xs={12}>
                        <CardPerformanceRelatorio model={render} onClick={onCardClick} />
                    </Grid>
                ))}
            </Grid>
            <DialogPerformanceRelatorio
                openned={openDialog}
                closeModal={() => setOpenDialog(false)}
                name={dialogComponent}
            />
        </Grid>

    );
};