import { DefaultCard, useDefaultCardStyles } from "views/components/cards/components";
import { Box, Grid, Tooltip, Typography } from "views/design-system";
import { toDateString } from "utils/to-date";
import { useStyles } from "../produto-estoque-styles";
import { MovimentacaoEstoqueModel } from "model/api/gestao/movimentacao-estoque/movimentacao-estoque-model";
import { toDecimalString } from "utils/to-decimal";
import { statusMovimentacaoEstoqueMock } from "data/mocks/status-movimentacao-estoque-mock";
import { EnumStatusMovimentacaoEstoque } from "model/enums/enum-status-movimentacoes-estoque";
import { useThemeQueries } from "views/theme";

interface CardHistoricoMovimentosEstoqueProps {
    model: MovimentacaoEstoqueModel
    onClick: (id: string) => void
    onCheck: (id: string) => void
    selected: boolean
}
export const CardHistoricoMovimentosEstoque = ({
    model,
    onClick,
    onCheck,
    selected,
}: CardHistoricoMovimentosEstoqueProps) => {
    const classes = useDefaultCardStyles();
    const myClasses = useStyles()
    const {theme} = useThemeQueries()
    const retornarCorStatus = (status: EnumStatusMovimentacaoEstoque) => ({
        [EnumStatusMovimentacaoEstoque.RESERVAR]: theme.palette.warning.main,
        [EnumStatusMovimentacaoEstoque.APROVAR]: theme.palette.success.main,
        [EnumStatusMovimentacaoEstoque.DESRESERVAR]: theme.palette.error.main,
        [EnumStatusMovimentacaoEstoque.ESTORNAR]: theme.palette.error.main
    })[status] || theme.palette.primary.main;
    const statusAtual = statusMovimentacaoEstoqueMock.find(status => status.Key === model.status)
    return (
        <>
            <DefaultCard
                hasTagStatus={false}
                className={myClasses.cardMovimentacoesContent}
            >
                <Tooltip arrow title={statusAtual?.Value ?? ''} placement="right">
                    <div
                        className={classes.tagStatus}
                        style={{background: retornarCorStatus(model.status)}}
                    ></div>
                </Tooltip>
                <Box className={classes.cardContent}>
                    <Grid container className={myClasses.cardGrid}>
                        {/* Esse card esta ocultado pois a api não retorna algum dado que se enquandre em Historico
                        <Grid item xs={9}>
                            <Typography variant="caption">Histórico</Typography>
                            <Typography className={myClasses.cardInfo} variant="body1">{ }</Typography>
                        </Grid> */}
                        <Grid item xs={5}>
                            <Typography variant="caption">Nome</Typography>
                            <Typography className={myClasses.cardInfo} variant="body1">{model.pessoaNome}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="caption">Quantidade</Typography>
                            <Typography className={myClasses.cardInfo} variant="body1"><strong>{model.qComOriginal}</strong></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption">Data</Typography>
                            <Typography className={myClasses.cardInfo} variant="body1">{toDateString(model.dhEmi, 'DD/MM/yyyy HH:mm')}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="caption">Valor</Typography>
                            <Typography className={myClasses.cardInfo} variant="body1"><strong>R$ {toDecimalString(model.vProd, 2)}</strong></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DefaultCard>
        </>
    )
}