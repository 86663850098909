import { EnumIndDesperdicio } from "model/enums/enum-ind-desperdicio";
import { EnumStatusProdutoPedido } from "model/enums/enum-status-produto-pedido";
import { EnumTpProduto } from "model/enums/enum-tp-produto";
import { guidEmpty } from "utils/guid-empty";
import { VendaAcrescimoModel, VendaDescontoModel } from "../venda/venda-desconto-model";

export class PedidoProdutoStatus {
    constructor(
        public codigo: EnumStatusProdutoPedido = EnumStatusProdutoPedido.DISPONIVEL,
        public descricao: string = 'Disponivel',
    ) { }
}

export class PedidoProdutoIndDesperdicio {
    constructor(
        public codigo: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica,
        public descricao: string = 'Não se aplica'
    ) { }
}

export class PedidoProdutoTpProduto {
    constructor(
        public codigo: EnumTpProduto = EnumTpProduto.Produto,
        public descricao: string = 'Produto'
    ) { }
}

export interface RastroPedido {
    numeroLote: string | null,
    quantidadeLote: number | null,
    dataFabricacao: Date | string | null,
    dataValidade: Date | string | null,
    codigoAgregacao: number | null,
}

export class PedidoProdutoModel {
    constructor(
        public id: string = guidEmpty(),
        public setorEmpresaId: string = guidEmpty(),
        public plu: string = '',
        public descricao: string = '',
        public observacao: string = '',
        public pesoVariavel: boolean = true,
        public codigoBarra: string = '',
        public status: PedidoProdutoStatus = new PedidoProdutoStatus(),
        public nItem: number = 0,
        public posicaoMesa: string = '',
        public codigoReferencia: string = '',
        public quantidade: number = 0,
        public valorTotal: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalDescontoEmbutido: number = 0,
        public valorUnitario: number = 0,
        public valorTotalAdicional: number = 0,
        public valorTotalAdicionalEmbutido: number = 0,
        public valorTotalSubItens: number = 0,
        public valorTotalFrete: number = 0,
        public valorTotalServico: number = 0,
        public subItens: PedidoProdutoModel[] = [],
        public vendedor: string = '',
        public vendedorId: string = guidEmpty(),
        public produtoGradeId: string | null = null,
        public tabelaPrecoId: string | null = null,
        public ncm: string = '',
        public unidadeComercial: string = '',
        public temImposto: boolean = false,
        public grupoImpostoId: string | null = null,
        public ncmId: string | null = null,
        public pedidoId: string = '',
        public comandaId: string = '',
        public mesaId: string = '',
        public setorId: string | null = null,
        public produtoPai: string | null = null,
        public adicionalId: string | null = null,
        public groupId: string | null = null,
        public adicionais: Array<PedidoProdutoModel> = [],
        public cliente: string = '',
        public indDesperdicio: PedidoProdutoIndDesperdicio = new PedidoProdutoIndDesperdicio(),
        public motivoCancelamento: string = '',
        public tpProduto: PedidoProdutoTpProduto = new PedidoProdutoTpProduto(),
        public salaoId: string | null = null,
        public taxaServico: number = 0,
        public indFin: boolean = true,
        public systemInsertDate: string | Date = '',
        public systemUpdateDate: string | Date = '',
        public nivel: number = 0,
        public produtoIdReferencia: string | null = null,
        public quantidadeMax: number = 0,
        public mesaCodigo: string | null = null,
        public codigoComanda: string | null = null,
        public identificador: string | null = null,
        public codigoAnvisa: string | null = null,
        public precoMaximoConsumidor: number = 0,
        public numeroSequenciaReceitaMedica: number | null = null,
        public vendaControlada: boolean = false,
        public rastros: RastroPedido[] | null = null,
        public descontos: VendaDescontoModel[] = [],
        public acrescimos: VendaAcrescimoModel[] = [],
        public codigoPedido: string = '',
        public dataHoraLancamento: string = '',
    ) { }
}
export class PedidoProdutoModelVenda {
    constructor(
        public id: string = guidEmpty(),
        public setorId: string | null = null,
        public vendedor: string = '',
        public vendedorId: string = guidEmpty(),
        public cliente: string = '',
        public plu: string = '',
        public descricao: string = '',
        public observacao: string = '',
        public pesoVariavel: boolean = true,
        public codigoBarra: string = '',
        public status: PedidoProdutoStatus = new PedidoProdutoStatus(),
        public nItem: number = 0,
        public posicaoMesa: string = '',
        public codigoReferencia: string = '',
        public quantidade: number = 0,
        public valorTotal: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalDescontoEmbutido: number = 0,
        public valorUnitario: number = 0,
        public valorTotalAdicional: number = 0,
        public valorTotalAdicionalEmbutido: number = 0,
        public valorTotalSubItens: number = 0,
        public valorTotalFrete: number = 0,
        public produtoGradeId: string | null = null,
        public tabelaPrecoId: string | null = null,
        public ncm: string = '',
        public temImposto: boolean = false,
        public grupoImpostoId: string | null = null,
        public ncmId: string | null = null,
        public pedidoId: string = '',
        public comandaId: string = '',
        public mesaId: string = '',
        public subItens: PedidoProdutoModel[] = [],
        public taxaServico: number = 0,
        public systemInsertDate: string | Date = '',
        public salaoId: string | null = null,
        public indFin: boolean = true,
        public produtoPai: string | null = null,
        public adicionalId: string | null = null,
        public groupId: string | null = null,
        public tpProduto: PedidoProdutoTpProduto = new PedidoProdutoTpProduto(),
        public adicionais: Array<PedidoProdutoModel> = [],
        public indDesperdicio: PedidoProdutoIndDesperdicio = new PedidoProdutoIndDesperdicio(),
        public nivel: number = 0,
        public produtoIdReferencia: string | null = null,
        public quantidadeMax: number = 0,
        public mesaCodigo: string | null = null,
        public motivoCancelamento: string = '',
        public codigoComanda: string | null = null,
        public codigoAnvisa: string | null = null,
        public identificador: string | null = null,
        public precoMaximoConsumidor: number = 0,
        public numeroSequenciaReceitaMedica: number | null = null,
        public vendaControlada: boolean = false,
        public rastros: RastroPedido[] | null = null,
        public descontos: VendaDescontoModel[] = [],
        public acrescimos: VendaAcrescimoModel[] = [],
        public codigoPedido: string = '',
        public dataHoraLancamento: string = '',
    ) { }
}
export class PedidoProdutoModelPost {
    constructor(
        public id: string = guidEmpty(),
        public setorEmpresaId: string = guidEmpty(),
        public plu: string = '',
        public descricao: string = '',
        public observacao: string = '',
        public pesoVariavel: boolean = true,
        public codigoBarra: string = '',
        public status: EnumStatusProdutoPedido = EnumStatusProdutoPedido.DISPONIVEL,
        public nItem: number = 0,
        public posicaoMesa: string = '',
        public codigoReferencia: string = '',
        public quantidade: number = 0,
        public valorTotal: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalDescontoEmbutido: number = 0,
        public valorUnitario: number = 0,
        public valorTotalAdicional: number = 0,
        public valorTotalAdicionalEmbutido: number = 0,
        public valorTotalServico: number = 0,
        public valorTotalFrete: number = 0,
        public taxaServico: number = 0,
        public vendedor: string = '',
        public vendedorId: string = guidEmpty(),
        public produtoGradeId: string | null = null,
        public tabelaPrecoId: string | null = null,
        public unidadeComercial: string = '',
        public ncm: string = '',
        public temImposto: boolean = false,
        public grupoImpostoId: string | null = null,
        public ncmId: string | null = null,
        public setorId: string | null = null,
        public salaoId: string | null = null,
        public indFin: boolean = true,
        public produtoPai: string | null = null,
        public subItens: Array<PedidoProdutoModel> = [],
        public adicionalId: string | null = null,
        public groupId: string | null = null,
        public tpProduto: EnumTpProduto = EnumTpProduto.Produto,
        public indDesperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica,
        public produtoIdReferencia: string | null = null,
        public quantidadeMax: number = 0,
        public precoMaximoConsumidor: number = 0,
        public numeroSequenciaReceitaMedica: number | null = null,
        public vendaControlada: boolean = false,
        public rastros: RastroPedido[] | null = null,
        public descontos: VendaDescontoModel[] = [],
        public acrescimos: VendaAcrescimoModel[] = [],
    ) { }
}

export class PedidoProdutoFechamentoParcialGroupIDModelPost {
    constructor(
        public produtoId: string = guidEmpty(),
        public quantidade: number = 0,
        public groupId: string | null = guidEmpty()
    ) { }
}

export class PedidoProdutoFechamentoParcialModelPost {
    constructor(
        public produtoId: string = guidEmpty(),
        public quantidade: number = 0
    ) { }
}
