import { Box, Typography } from "@material-ui/core"
import { DefaultCard, useDefaultCardStyles } from "../components"
import { CardSelecaoProdutosCodigosProps } from "./card-selecao-produtos-codigos-props"
import { toDecimalString } from "utils/to-decimal"
import { useStyles } from "./card-selecao-produtos-codigos-styles"

export const CardSelaecaoProdutosCodigos = ({
    model,
    produto,
    onClick,
}: CardSelecaoProdutosCodigosProps) => {

    const classes = useDefaultCardStyles();
    const styles = useStyles();

    const valorTotalProduto = () => {
        if (model.precoFixo === null && model.percTabela === null) {
            return produto.vPreco;
        }

        if (model.percTabela) {
            return produto.vPreco + ((produto.vPreco * model.percTabela!) / 100)
        }

        if (model.precoFixo === null)
            return 0;

        return model.precoFixo;
    }

    //TODO: VERIFICAR ISSO AQUI PFV (PROMOCAO)
    
    return (
        <DefaultCard
            className={styles.border}
            onClick={async () => {
                onClick(model);
            }}>
            <div className={classes.cardContent}>
                <div className={classes.celulaGrid}>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                    >
                        Código
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        <Box fontWeight={500}>
                            {model.codigo}
                        </Box>
                    </Typography>
                </div>
                <div className={classes.celulaGrid} style={{
                    marginLeft: '0px'

                }}>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                    >
                        Qtd
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {`${model.fator === null ? 1 : model.fator} UN`}
                    </Typography>
                </div>
                <div className={classes.celulaGrid} style={{
                    marginLeft: '0px'
                }}>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                    >
                        Desconto
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {`${model.percTabela === null ? 0 : model.percTabela}%`}
                    </Typography>
                </div>
                <div className={classes.celulaGrid} style={{
                    minWidth: '80px',
                    maxWidth: '80px',
                    marginLeft: '0px'
                }}>
                    <Typography
                        variant="caption"
                        color="textPrimary"
                    >
                        Preço Final
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {`R$ ${toDecimalString(valorTotalProduto(), 2)}`}
                    </Typography>
                </div>
            </div>
        </DefaultCard>
    )
}