export enum EnumTpFaturaLancamentoStatus {
    Venda = 1,
    DevolucaoVenda = 2,
    EstornoVenda = 3,
    TrocaLimite = 4,
    Fatura = 5,
    PagamentoFatura = 6,
    PagamentoAntecipado = 7,
    Taxas = 8,
    Negociacao = 9
}