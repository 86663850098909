import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { ButtonPrivateHeader } from "views/components/controles";
import { MenuCirculadoIcon } from "views/components/icons/menu-circulado";
import { ButtonPrivateHeaderProps } from "./menu-button-header-props";

export const MenuButtonHeader = (props: ButtonPrivateHeaderProps) => {
    const { abrirMenu } = useMenuPrincipal();

    return <ButtonPrivateHeader
        id={props.id}
        icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
        tooltip="Ir para Menu Administrativo"
        onClick={() => abrirMenu()}
    />
}