import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    containerPreviewInfo: {
        width: '100%',
        height: '60px',
        background: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        marginTop: '80px'
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        margin: '2px 0',
        height: '2px'
    },
    infoPedido: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '8px',
    },
    textoDestaque: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    containerFooter: {
        display: 'flex',
    },
}));