import { forwardRef, useImperativeHandle } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormModificadorValidation } from './form-modificador-cadastro-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ModificadorCadastroFormModel } from 'model/app/forms/modificador/modificador-cadastro-form-model';
import { picker } from 'utils/picker';
import { TpCalculoModificadorMock } from 'data/mocks/tp-calculo-modificador-mock';
import { Box, Button, Grid } from 'views/design-system';

export const FormModificadorCadastro = forwardRef<
  DefaultFormRefs<ModificadorCadastroFormModel>,
  DefaultFormProps<ModificadorCadastroFormModel>
>((props: DefaultFormProps<ModificadorCadastroFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormModificadorValidationYup } = useFormModificadorValidation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<ModificadorCadastroFormModel>({
    defaultValues: new ModificadorCadastroFormModel(),
    resolver: yupResolver(FormModificadorValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ModificadorCadastroFormModel) => {
    const modelP = picker<ModificadorCadastroFormModel>(values, new ModificadorCadastroFormModel());

    props.onSubmit(modelP);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({
        ...new ModificadorCadastroFormModel()
      });
    },
    fillForm: () => { },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'TEXTO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Nome"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: Tamanho do Açaí"
                      error={Boolean(
                        errors.nome && errors.nome.message,
                      )}
                      helperText={
                        errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name="tpCalculo"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Tipo de Cálculo"
                      variant="outlined"
                      fullWidth
                      disabled={props.loading}
                      conteudo={TpCalculoModificadorMock}
                      error={Boolean(errors.tpCalculo && errors.tpCalculo.message)}
                      helperText={
                        errors.tpCalculo
                          ? errors.tpCalculo?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpCalculoModificadorMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;

                        setValue('tpCalculo', item);
                      }}
                      value={getValues('tpCalculo')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'TEXTO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Descrição"
                      placeholder='Digite uma instrução para facilitar o entendimento deste modificador'
                      multiline
                      rows={2}
                      limite={150}
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
