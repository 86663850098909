import { EnumTpAutenticacaoConvenio } from "model/enums/enum-tp-autenticacao-convenio";
import { DefaultModal } from "../components"
import { ModalProps } from "../utils";
import { GerenciarCartaoConvenio } from "./components/gerenciar-cartao-convenio/gerenciar-cartao-convenio";

interface CartaoConvenioModalProps extends ModalProps {
    openned: boolean;
    convenioId: string;
    id: string;
    tipo?: EnumTpAutenticacaoConvenio
}

export const CartaoConvenioModal = (props: CartaoConvenioModalProps) => {

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && <GerenciarCartaoConvenio {...props} />}
        </DefaultModal>
    )
}