import classNames from "classnames"
import { useStyles } from "./divider-styles";
import { DividerProps } from "./divider-props";
import { forwardRef } from "react";

export const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => {
    const styles = useStyles();

    return <hr className={classNames(
        props.className,
        styles.root
    )} style={props.style} ref={ref} id={props.id}
        onMouseDown={props.onMouseDown}
        onMouseUp={props.onMouseUp}
        onTouchMove={props.onTouchMove}
        onTouchStart={props.onTouchStart}
        onTouchEnd={props.onTouchEnd}
        onClick={props.onClick} />
});