import { Grid } from "@material-ui/core";
import { GerarPagerIdentificador } from "./components/gerar-pager-identificador/gerar-pager-identificador";
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { useVendaHeaderStore } from "views/components/headers/venda-header/stores/venda-header-store";
import { useEffect } from "react";

export const MovGerarPagerIdentificadorPage = () => {
  const classes = useDefaultCadastroStyles();

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Identificação do Pedido',
      showMenuPdv: true,
    })
  }, [setHeaderProps])

  return (
    <Grid className={classes.root} justifyContent="center">
      <GerarPagerIdentificador />
    </Grid>
  );
};

export default MovGerarPagerIdentificadorPage;
