import { EnumCadastroStatus } from "model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { PessoaListSearchProps } from "views/pages/private/cadastros/pessoa/components/pessoa-list/pessoa-list-search-props";
import { PessoaPesquisaModal } from "views/pages/private/cadastros/pessoa/components/pessoa-pesquisa-modal/pessoa-pesquisa-modal";

export const PessoaFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            status: [EnumCadastroStatus.BLOQUEADO, EnumCadastroStatus.LIBERADO],
            termo: '',
            tpCadastro: [-1]
        } as PessoaListSearchProps,
        esconder: {
            tpCadastro: false,
            status: false
        },
        callback: undefined
    });

    const modalAlterado = useCallback(({ openned, filtros, esconder = null, callback }: any) => {
        setModalState({
            openned,
            filtros,
            esconder,
            callback
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PessoasFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PessoasFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <PessoaPesquisaModal
            filtros={modalState.filtros}
            openned={modalState.openned}
            callback={modalState.callback}
            esconder={modalState.esconder}
        />
    )
}