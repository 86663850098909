import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { CartaoConvenioModal } from "views/components/modals/cartao-convenio/cartao-convenio-modal";

export const CartaoConvenioModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        convenioId: '',
        id: '',
        tipo: undefined
    });

    const modalAlterado = useCallback(({ openned, convenioId, id, tipo }: any) => {
        setModalState({
            openned,
            convenioId,
            id,
            tipo
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.CartaoConvenioModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.CartaoConvenioModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            <CartaoConvenioModal openned={modalState.openned} convenioId={modalState.convenioId} id={modalState.id} key="mdlCartaoConvenioModal" />
        </>
    )
}