import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { GeolocalizacaoFreteModel } from 'model/api/gestao/geolocalizacao/geolocalizacao-frete-model';
import { VariaveisAmbiente } from 'config';

export function usePostGeolocalizacaoFrete() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postGeolocalizacaoFrete = useCallback(
        (value: GeolocalizacaoFreteModel) =>
            invocarApi({
                url: `/api/v2/geolocalizacao/comparar-preco`,
                method: 'POST',
                data: value,
                enviarTokenUsuario: true,
                baseURL: VariaveisAmbiente.apiUrlLocalizacao
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postGeolocalizacaoFrete,
    };
}
