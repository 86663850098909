import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ConvenioModal } from "views/components/modals/convenio/convenio-modal";

export const CadastroConvenioModalRender = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        pessoa: undefined,
        aberto: false,
        ultimaUrl: '',
        search: '',
    });

    const convenioRoute = `/convenios/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroConvenioModal')

    useEffect(() => {
        if (location.pathname.indexOf(convenioRoute) === 0) {
            const posrota = location.pathname.replace(convenioRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, ''),
                    pessoa: undefined,
                    aberto: true,
                    ultimaUrl: convenioRoute,
                    search: prev.search
                }));
                return;
            }
        }
    }, [location.pathname, convenioRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl, pessoa }: any) => {
        let url = callbackUrl.length === 0 ? convenioRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;
        
        if (!openned && !trocarUrl) {
            push({
                pathname: convenioRoute,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(convenioRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            pessoa,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        });
    }, [modalState.aberto, modalState.search, modalState.ultimaUrl, convenioRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.ConvenioModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ConvenioModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <ConvenioModal openned={modalState.aberto} key="mdlConvenio" {...modalState} />
    )
}