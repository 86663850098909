import { KeyValueModel } from "model/api";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";

export const TpProdutoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoProduto.Produto, "Produto"),
    new KeyValueModel(EnumTipoProduto.Combo, "Combo"),
    new KeyValueModel(EnumTipoProduto.Insumo, "Insumo"),
];

export const TpProdutoFarmaciaMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoProduto.Produto, "Produto"),
    new KeyValueModel(EnumTipoProduto.Combo, "Combo"),
    new KeyValueModel(EnumTipoProduto.Insumo, "Insumo"),
    new KeyValueModel(EnumTipoProduto.Medicamento, "Medicamento")
];

export const TpProdutoCadastroIsFarmacia: KeyValueModel[] = [
    new KeyValueModel(EnumTipoProduto.Medicamento, "Medicamento"),
    new KeyValueModel(EnumTipoProduto.Produto, "Produto"),
];
//Verificar qual motivo existem dois mocks iguais com nomes diferentes
export const TpProdutoCadastroFarmacia: KeyValueModel[] = [
    new KeyValueModel(EnumTipoProduto.Medicamento, "Medicamento"),
    new KeyValueModel(EnumTipoProduto.Produto, "Produto")
];

export const TpProdutoCapsLockMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoProduto.Produto, "PRODUTO"),
    new KeyValueModel(EnumTipoProduto.Combo, "COMBO"),
    new KeyValueModel(EnumTipoProduto.Insumo, "INSUMO"),
    new KeyValueModel(EnumTipoProduto.Medicamento, "MEDICAMENTO"),
    // new KeyValueModel(EnumTipoProduto.Servico, "Servico")
];
