import { KeyValueModel } from "model/api";
import { EnumTpFaturaLancamentoStatus } from "model/enums/enum-tp-fatura-lancamento-status";

export const TpFaturaLancamentoStatusMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpFaturaLancamentoStatus.Venda, "Venda"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.DevolucaoVenda, "Devolução"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.EstornoVenda, "Estorno"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.Fatura, "Fatura"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.Negociacao, "Negociação"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.PagamentoAntecipado, "Antecipado"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.PagamentoFatura, "Pagamento"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.Taxas, "Taxas"),
    new KeyValueModel(EnumTpFaturaLancamentoStatus.TrocaLimite, "Limite"),
];
