import { Button, Container, Grid } from "@material-ui/core"
import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useRef, useState } from "react"
import { LixoIcon, OkIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { ProdsModel, VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useToastSaurus } from "services/app"
import { useStyles } from "./novo-produto-styles"
import classNames from "classnames"
import { DefaultFormRefs } from "views/components/form/utils"
import { FormProdutoEntrada } from "views/components/form/entrada/produto-entrada/form-produto-entrada"
import { ProdutoEntradaFormModel } from "model/app/forms/entrada/produto-entrada-form-model"
import { picker } from "utils/picker"
import { isEmpty, isEqual } from "lodash"
import { useConfirm } from "material-ui-confirm"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"

const NovoProdutoEntradaPage = () => {

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId, prodId } = useParams<{ movId: string, prodId: string }>()
    const { showToast } = useToastSaurus()
    const formRef = useRef<DefaultFormRefs<ProdutoEntradaFormModel>>(null)
    const confirm = useConfirm()
    const [preencherTudo, setPreencherTudo] = useState<boolean>(false)

    const produtoId = prodId !== "adicionar" ? prodId : ''

    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())

    const { getVendaCompleta, saveChangesVendaCompleta, carregando: carregandoEntrada } = useEntrada(mov)

    const carregando = [carregandoEntrada, preencherTudo].includes(true)

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        try {
            let movAtual = new VendaCompletaModel()
            if (location.state?.mov && !att) {
                movAtual = location.state.mov
            } else {
                movAtual = await getVendaCompleta(movId)
            }

            setMov(movAtual)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getVendaCompleta, location.state?.mov, movId, showToast])

    const handleVoltar = useCallback((att: boolean = false) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/produtos`,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto
        }
    }), [history, location.state, mov, movId])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => handleVoltar()}></ButtonPrivateHeader >
        ), [handleVoltar]);

    useEffect(() => {
        getInfoVenda()
    }, [getInfoVenda])

    useEffect(() => {
        const prod = mov.infMov.prod.filter(x => x.id === prodId)
        if (prod.length > 0) {
            const prodForm = picker<ProdutoEntradaFormModel>(prod[0], new ProdutoEntradaFormModel())
            prodForm.rastro = isEmpty(prodForm.rastro) ? [] : prod[0].rastro
            formRef.current?.fillForm(prodForm)
        } else {
            formRef.current?.fillForm(new ProdutoEntradaFormModel())
        }
    }, [mov.infMov.prod, prodId])

    const saveChanges = async (modelForm: ProdutoEntradaFormModel, resetarPagamento?: boolean) => {
        const movAtual = structuredClone(mov)
        let prodModel = new ProdsModel()

        if (produtoId) {
            const prodMov = movAtual.infMov.prod.find((x: any) => x.id === produtoId)
            prodModel = picker<ProdsModel>(modelForm, prodMov)
            if (movAtual.depositoId) {
                prodModel.depositoId = movAtual.depositoId
            }
            prodModel.acrescimos = []
            prodModel.descontos = []
            prodModel.rastro = modelForm.rastro
            prodModel.indFin = true
            const index = movAtual.infMov.prod.indexOf(prodMov!)
            movAtual.infMov.prod[index] = prodModel
        } else {
            prodModel = picker<ProdsModel>(modelForm, new ProdsModel())
            if (movAtual.depositoId) {
                prodModel.depositoId = movAtual.depositoId
            }
            prodModel.acrescimos = []
            prodModel.descontos = []
            prodModel.rastro = modelForm.rastro
            movAtual.infMov.prod.push(prodModel)
        }
        if (resetarPagamento) {
            movAtual.infMov.pag = []
            if (movAtual.infMov.cobr)
                movAtual.infMov.cobr.dup = []
        }

        await saveChangesVendaCompleta(movAtual)
        showToast('success', `Produto ${produtoId ? 'alterado' : 'adicionado'} com sucesso!`)
        handleVoltar(true)
    }

    const handleSubmit = async (model: ProdutoEntradaFormModel, beforeModel: ProdutoEntradaFormModel) => {
        try {
            setPreencherTudo(true)
            let resetarPagamento = false
            const modelEqual = isEqual(model, beforeModel)
            if (modelEqual) {
                showToast('info', 'Nenhuma informação alterada')
                return
            }

            if (produtoId) {
                if (model.vProd !== beforeModel.vProd && mov.infMov.pag.length > 0) {
                    try {
                        await confirm({
                            title: 'Confirmação de Edição de Produto',
                            description: 'Há pagamentos já realizados. Se você alterar o preço deste produto, todos os pagamentos serão deletados. Deseja confirmar?',
                            confirmationText: 'Confirmar',
                            cancellationText: 'Cancelar'
                        })
                        resetarPagamento = true
                    } catch {
                        resetarPagamento = false
                    }
                }
            } else {
                if (mov.infMov.pag.length > 0) {
                    try {
                        await confirm({
                            title: 'Confirmação de Cadastro de Produto',
                            description: 'Há pagamentos já realizados. Se você cadastrar este produto, todos os pagamentos serão deletados. Deseja confirmar?',
                            confirmationText: 'Confirmar',
                            cancellationText: 'Cancelar'
                        })
                        resetarPagamento = true
                    } catch {
                        resetarPagamento = false
                    }
                }
            }

            await saveChanges(model, resetarPagamento)

        } catch (error: any) {
            showToast('error', error.message)
        }
        finally {
            setPreencherTudo(false)
        }
    }

    const handleRemoveProduto = async () => {
        try {
            const movAtual = structuredClone(mov) as VendaCompletaModel
            if (!movAtual.infMov.prod) return;

            const prods = movAtual.infMov.prod.filter(x => prodId !== x.id)
            movAtual.infMov.prod = prods

            if (mov.infMov.pag.length > 0) {
                movAtual.infMov.pag = []
                movAtual.infMov.cobr.dup = []
            }

            await saveChangesVendaCompleta(movAtual)

            showToast('info', 'Produto removido com sucesso')
            handleVoltar(true)
        } catch (error: any) {
            showToast('error', error.message)
        }
    }

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title={produtoId ? "Editar Produto" : 'Novo Produto'}
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <FormProdutoEntrada
                            loading={carregando}
                            onSubmit={handleSubmit}
                            showLoading={false}
                            ref={formRef}
                        />
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            {produtoId && <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => confirm({
                                        title: 'Confirmação de Remoção de Produto',
                                        description: `Deseja remover este produto? Esta ação não poderá ser desfeita futuramente. ${mov.infMov.pag.length > 0 ? 'Também há pagamentos já realizados, se você remover este produto, todos os pagamentos serão deletados.' : ''} Deseja confirmar?`,
                                        cancellationText: 'Cancelar',
                                        confirmationText: 'Confirmar'
                                    }).then(() => handleRemoveProduto())}
                                    variant="outlined"
                                    className={classes.btnRemover}
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <LixoIcon tipo="BUTTON" />
                                    Remover
                                </Button>
                            </Grid>}
                            <Grid item xs={produtoId ? 6 : 12}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => formRef.current?.submitForm()}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                    {produtoId ? (
                                        <>
                                            Salvar
                                        </>
                                    ) : (
                                        <>
                                            Adicionar
                                        </>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default NovoProdutoEntradaPage