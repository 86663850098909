import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1)
  },
  disabled: {
    opacity: 0.5
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      fontWeight: 500
    },
    [theme.breakpoints.down('xs')]: {
      '& p, span': {
        fontSize: '13px'
      }
    }
  },
  iconRight: {
    display: 'flex !important',
    border: 'none',
    "& svg": {
      width: 24,
      height: 24,
    }
  }
}));
