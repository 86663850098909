import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-finalizar-pedidos-styles';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useThemeQueries } from 'views/theme';
import { useGestaoToken, useToastSaurus } from 'services/app';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { CancelarIcon } from 'views/components/icons';
import { useMovAnteriorStorage } from 'services/app/hooks/mov-anterior-storage';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { useVenda } from 'services/app/hooks/gerenciar-venda';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { useSessaoAtual } from 'services/app';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

const MovFinalizarPedidoPage = () => {
  const { get } = useMovAnteriorStorage();
  const { getEmpresaAtual } = useGestaoToken();
  const movAnterior = useRef<MovSimplesModel | undefined>(get());
  const [pedidoAtual, setPedidoAtual] = useState<number>(0);
  const [erro, setErro] = useState<boolean>(false);
  const { redirectLanding, redirectVendaFinalizadaComprovante } = useMovRota();
  const { putPedidoStatus } = usePutPedidoStatus();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getVendas } = useVenda();
  const {
    getStatusSincronizacaoAtual,
    iniciarSincronizacaoGeral
  } = useSincronizacaoGeral();

  const { isMobile } = useThemeQueries();
  const classes = useStyles();
  const { showToast } = useToastSaurus();

  const finalizarPedido = useCallback(
    async (id: string) => {
      const respostaPutStatus = await putPedidoStatus(
        getEmpresaSelecionada()!.Id,
        id,
        EnumStatusPedido.FINALIZADOOUENTREGUE,
      );

      if (respostaPutStatus.erro) {
        throw respostaPutStatus.erro;
      }
    },
    [getEmpresaSelecionada, putPedidoStatus],
  );

  const finalizandoPedidos = useCallback(async () => {
    try {
      if (!movAnterior.current) {
        await redirectLanding();
        return;
      }

      if (
        pedidoAtual < movAnterior.current.informacoesGeraisPedido.pedidos.length
      ) {
        await finalizarPedido(
          movAnterior.current.informacoesGeraisPedido.pedidos[pedidoAtual].id,
        );
      }

      const vConfig = getEmpresaAtual()?.configuracoes.filter(
        (item) => item.cod === 924,
      )[0].vConfig;

      if (pedidoAtual ===
        movAnterior.current.informacoesGeraisPedido.pedidos.length - 1 && vConfig === '-1') {
        const sincEmAndamento = getStatusSincronizacaoAtual();

        if (
          sincEmAndamento &&
          sincEmAndamento.status ===
          EnumSincronizacaoGeralStatus.EmAndamento
        ) {
          await redirectLanding();
          return;
        }

        const vendas = await getVendas();

        if (vendas && vendas.length > 0) {
          if (!navigator.onLine) {
            showToast(
              'info',
              'Sem acesso a internet, não foi possível realizar a sincronização.'
            );
            await redirectLanding();
            return;
          }
          await iniciarSincronizacaoGeral();
          await redirectLanding();
          return;
        }
        await redirectLanding();
      }

      if (
        pedidoAtual ===
        movAnterior.current.informacoesGeraisPedido.pedidos.length - 1
      ) {
        await redirectVendaFinalizadaComprovante();
        return;
      }

      setPedidoAtual((prev) => prev + 1);
    } catch {
      setErro(true);
    }
  }, [finalizarPedido, getEmpresaAtual, getStatusSincronizacaoAtual, getVendas, iniciarSincronizacaoGeral, pedidoAtual, redirectLanding, redirectVendaFinalizadaComprovante, showToast]);

  useEffect(() => {
    const finalizar = async () => {
      await finalizandoPedidos();
    };

    finalizar();
  }, [finalizandoPedidos]);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Finalizar Pedidos',
    })
  }, [setHeaderProps])

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.content}>
          <Grid
            className={
              isMobile ? classes.infoContainer : classes.infoContainerPC
            }
          >
            <Typography className={classes.textTitle}>
              {!erro ? 'Finalizando Pedidos' : 'Ocorreu um erro'}
            </Typography>
            {!erro ? (
              <Typography
                className={classes.infoTotal}
                style={{ textAlign: 'center' }}
              >
                {pedidoAtual + 1}/
                {movAnterior.current?.informacoesGeraisPedido.pedidos.length ??
                  0}
              </Typography>
            ) : (
              <></>
            )}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 32,
              }}
            >
              {!erro ? (
                <LoadingFinalizacao />
              ) : (
                <Grid className={classes.imageError}>
                  <CancelarIcon tipo="GERAL" fill="#D22" />
                </Grid>
              )}
            </Grid>
            <Grid
              className={!isMobile ? classes.infoCardContent : classes.cardInfo}
            >
              <Grid
                container
                justifyContent="center"
                style={{ flexDirection: 'column', display: 'flex' }}
              >
                <Grid
                  item
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {!erro ? (
                    <Typography className={classes.infoTotal}>
                      Aguarde...
                    </Typography>
                  ) : (
                    <Typography className={classes.infoNomeEQuantidade}>
                      Ocorreu um erro ao finalizar
                    </Typography>
                  )}
                </Grid>
                {erro && (
                  <Grid
                    item
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      variant="contained"
                      onClick={async () => {
                        setErro(false);
                        await finalizandoPedidos();
                      }}
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      Tentar Novamente
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MovFinalizarPedidoPage;
