import { memo, useCallback, useEffect, useState } from 'react';
import { useStyles } from './carrinho-pagamento-styles';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumPagTipo, EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCadastros, useToastSaurus } from 'services/app';
import { usePayment } from 'services/app/hooks/payment';
import { CircularLoading } from 'views/components';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { guidEmpty } from 'utils/guid-empty';
import { CardCarrinhoPagamento } from 'views/components/cards/card-carrinho-pagamento/card-carrinho-pagamento';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { Grid } from 'views/design-system';

const CarrinhoPagamento = () => {
  const [active, setActive] = useState(false);
  const [payments, setPayments] = useState<
    Array<{
      name: string;
      type: EnumPagTpMod;
      methods: FinalizadoraModel[];
    }>
  >([]);

  // HOOKS
  const { startPayment, loading } = usePayment();
  const { getMov, carregando } = useMovAtual();
  const { showToast } = useToastSaurus();
  const { abrirPagamentosDialog } = useCadastros();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod } = useContratoAtual()
  const { redirectPagamentoAvancado } = useMovRota();

  // AUX
  const classes = useStyles();

  const groupByTpMod = useCallback((methods: FinalizadoraModel[]) => {

    const tpModArray = [
      EnumPagTpMod.DINHEIRO,
      EnumPagTpMod.CARTAO_CREDITO,
      EnumPagTpMod.CARTAO_DEBITO,
      EnumPagTpMod.PAGAMENTO_INSTANTANEO
    ];

    const groupPayments = [
      {
        name: 'Dinheiro',
        type: EnumPagTpMod.DINHEIRO,
        methods: [] as FinalizadoraModel[],
        ordem: 1
      },
      {
        name: 'Crédito',
        type: EnumPagTpMod.CARTAO_CREDITO,
        methods: [] as FinalizadoraModel[],
        ordem: 2
      },
      {
        name: 'Débito',
        type: EnumPagTpMod.CARTAO_DEBITO,
        methods: [] as FinalizadoraModel[],
        ordem: 3
      },
      {
        name: 'PIX',
        type: EnumPagTpMod.PAGAMENTO_INSTANTANEO,
        methods: [] as FinalizadoraModel[],
        ordem: 4
      }
    ];

    return methods
      .filter((p) => tpModArray.includes(p.tpMod)).filter((item) => item.ativo === true)
      .reduce((group, payment) => {
        const groupIndex = group.find((g) => g.type === payment.tpMod);

        if (payment.tpMod === groupIndex?.type) {
          return group.map((x) => {
            if (x.type === groupIndex.type) {
              return {
                ...x,
                methods: [...x.methods, payment]
              };
            }

            return x;
          });
        }
        return group;
      }, groupPayments);
  }, []);

  const getPaymentMethods = useCallback(async () => {
    const result =
      (await TouchoneDBPrimary.finalizadoras.toArray()) as FinalizadoraModel[];
    const group = groupByTpMod(result);
    setPayments(group.map(g => ({
      ...g,
      methods: g.methods.sort()
    })).filter(groupPayment => groupPayment.methods.length > 0).map(g => ({
      ...g,
      ordem: g.methods[0]?.ordem ?? 999
    })).sort((a, b) => a.ordem - b.ordem));
  }, [groupByTpMod]);

  const onClick = useCallback(
    async (type: EnumPagTpMod, name: string, methods: FinalizadoraModel[]) => {
      if (methods.length === 0) {
        showToast(
          'info',
          'Você não possui nenhuma forma de pagamento com o tipo selecionado ou a forma não é permitida no ambiente atual, por favor verifique seus cadastros.'
        );
        return;
      }

      if (methods.length > 1) {
        abrirPagamentosDialog(methods);
        return;
      }
      await startPayment(methods[0]);
    },
    [abrirPagamentosDialog, showToast, startPayment]
  );

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const movProdAlterado = useCallback(
    (any: any) => {
      const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico)
      const produtos = getMov()?.produtos.filter((p) => p.ativo && !(p.produtoId === prodTaxaServicoId))

      if ((produtos?.length ?? 0) > 0) {
        setActive(true);
      } else {
        setActive(false);
      }
    },
    [getConfigByCod, getMov]
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler]);

  useEffect(() => {
    const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico)
    const produtos = getMov()?.produtos.filter((p) => p.ativo && !(p.produtoId === prodTaxaServicoId))
    if ((produtos?.length ?? 0) > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [getConfigByCod, getMov]);

  const outros = new FinalizadoraModel(guidEmpty(), guidEmpty(), 'Expandir', 'Expandir', EnumPagTpTransacao.NORMAL, EnumPagTpMod.OUTRO, EnumPagTipo.AMBOS);

  const exibirPags = !(loading || carregando || !active);
  return (
    <>
      <div className={classes.container}>
        <Grid className={classes.paymentArea} pl={1} style={{ gridTemplateColumns: payments.length === 0 ? 0 : ('repeat(' + payments.length + 1 + ', 1fr);') }}>
          {!exibirPags ?
            <Grid flex className={classes.containerLoading} alignItems='center' justifyContent='center'>
              <CircularLoading tipo="NORMAL" />
            </Grid> :
            (<>
              {payments.map((pay) => (
                <CardCarrinhoPagamento
                  model={pay}
                  key={pay.type}
                  className={classes.botoesPagamento}
                  onClick={async () => await onClick(pay.type, pay.name, pay.methods)}
                  selected={false}
                />
              ))}
              {payments.length > 0 && (
                <CardCarrinhoPagamento
                  model={{
                    methods: [outros],
                    name: 'Avançado',
                    type: EnumPagTpMod.OUTRO
                  }}
                  key={EnumPagTpMod.OUTRO}
                  onClick={async () => {
                    try {
                      await redirectPagamentoAvancado();
                    } catch (e: any) {
                      showToast('error', e.message)
                    }
                  }
                  }
                  selected={false}
                />
              )}
            </>)}
        </Grid>
      </div>
    </>
  );
};

export default memo(CarrinhoPagamento);
