
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ConvenioPutModel } from "model/api/gestao/convenio/convenio-put-model";
import { VariaveisAmbiente } from "config";

export function usePutConvenio() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putConvenio = useCallback(
		(convenio: ConvenioPutModel, empresaId: string) =>
			invocarApi({
				url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa/${convenio.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				data: JSON.stringify(convenio),
				enviarTokenUsuario: true,
			}),
		[invocarApi],
	);
	return {
		...outrasPropriedades,
		putConvenio,
	};
}
