import { CardProdutoProps } from './card-principio-ativo-props';
import { memo } from 'react';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import useOnPress from 'utils/useOnPress';
import { ProcurarIcon } from 'views/components/icons';
import { Grid, Typography } from 'views/design-system';
import { PrincipioAtivoIcon } from 'views/components/icons/principio-ativo-icon';
import { useStyles } from './card-principio-ativo-styles';

const CardPrincipioAtivo = ({ model, onClick }: CardProdutoProps) => {
  const defaultClasses = useDefaultCardStyles();
  const classes = useStyles();

  const handlePress = () => { };

  const handleClick = async () => {
    onClick(model);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 700
  };

  const EventPressAndClick = useOnPress(
    handlePress,
    handleClick,
    defaultOptions
  );

  return (
    <>
      <div {...EventPressAndClick}>
        <DefaultCard>
          <Grid className={defaultClasses.cardContent} gap={1}>
            <Grid className={classes.icone} flex justifyContent='center' alignItems='center'>
              <PrincipioAtivoIcon tipo="GERAL" />
            </Grid>
            <Grid className={classes.texto}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {model}
              </Typography>
            </Grid>
            <Grid className={classes.iconeAcao} flex justifyContent='center' alignItems='center'>
              <ProcurarIcon tipo="GERAL" />
            </Grid>
          </Grid>
        </DefaultCard>
      </div>
    </>
  );
};

export default memo(CardPrincipioAtivo, (prev, next) => {
  if (prev !== next) return false;
  return true;
});
