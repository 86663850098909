import { EnumStatusMedicamento } from "model/enums/enum-status-medicamento";
import { EnumTarja } from 'model/enums/enum-tarja';
import { EnumTipoMedicamento } from "model/enums/enum-tipo-medicamento";
import { guidEmpty } from "utils/guid-empty";

export class MedicamentoPostModel {
    constructor(
        public id: string = guidEmpty(),
        public laboratorioId: string = guidEmpty(),
        public codigoEan: string = '',
        public codigoAnvisa: string = '',
        public codigoGGRem: string = '',
        public codigoCAS: string = '',
        public codigoDCB: string = '',
        public codigoATC: string = '',
        public codigoTissTuss: string = '',
        public codigoPort34498: string = '',
        public nome: string = '',
        public descritivo: string = '',
        public composicao: string = '',
        public qtdEmbalagem: number = 1,
        public dataLancamento: string = '',
        public dataEncerramento: string = '',
        public ncmId: string = '',
        public ncm: string = '',
        public cest: string = '',
        public tarja: EnumTarja = EnumTarja.VendaLivre,
        public tipoMedicamento: EnumTipoMedicamento = EnumTipoMedicamento.Generico,
        public classeTerapeutica: string = '',
        public produtoReferencia: string = '',
        public participaCAP: boolean = true,
        public participaConfaz87: boolean = true,
    ) { }
}

export class MedicamentoModel {
    constructor(
        public id: string = guidEmpty(),
        public laboratorioId: string = guidEmpty(),
        public laboratorioNome: string = '',
        public laboratorioDocumento: string = '',
        public codigoEan: string = '',
        public codigoAnvisa: string = '',
        public codigoGGRem: string = '',
        public codigoCAS: string = '',
        public codigoDCB: string = '',
        public codigoATC: string = '',
        public codigoTissTuss: string = '',
        public codigoPort34498: string = '',
        public nome: string = '',
        public descritivo: string = '',
        public composicao: string = '',
        public qtdEmbalagem: number = 1,
        public dataLancamento: string = '',
        public dataEncerramento: string = '',
        public ncmId: string = '',
        public ncm: string = '',
        public cest: string = '',
        public tarja: EnumTarja = EnumTarja.VendaLivre,
        public tipoMedicamento: EnumTipoMedicamento = EnumTipoMedicamento.Generico,
        public classeTerapeutica: string = '',
        public produtoReferencia: string = '',
        public participaCAP: boolean = true,
        public participaConfaz87: boolean = true,
        public status: EnumStatusMedicamento = EnumStatusMedicamento.Revisao,
    ) { }
}

export interface MedicamentoPreco {
    id: string;
    laboratorioNome: string;
    codigoEan: string;
    codigoAnvisa: string;
    nome: string;
    descritivo: string;
    composicao: string;
    ncmId: string;
    ncm: string;
    cest: string;
    tipoMedicamento: number;
    tarja: number;
    status: number;
    produtoReferencia: string;
    classeTerapeutica: string;
    aliquotaICMS: number;
    precoFabricacao: number;
    precoMaximoConsumidor: number;
}
  
  