import { isEmpty } from "lodash";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumUploadCargaTipo } from "model/enums/enum-upload-carga-tipo";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { UploadCargaModal } from "views/components/modals/upload-carga-modal/upload-carga-modal";

export const CadastroUploadCargaModal = () => {
    const { push } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        model: undefined,
        aberto: false,
        ultimaUrl: '',
        tipo: EnumUploadCargaTipo.PESSOA
    });

    const uploadCargaRoute = `/upload-carga/`;
    const novoCadastroRoute = 'adicionar';
    consoleDev('CadastroUploadCargaModal')

    const modalAlterado = useCallback(({ model, openned, callbackUrl, trocarUrl, tipo }: any) => {
        let url = callbackUrl.length === 0
            ? !isEmpty(modalState.ultimaUrl)
                ? modalState.ultimaUrl
                : uploadCargaRoute
            : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }

        if (!openned) {
            push(url)
        }
        if (trocarUrl) {
            push(uploadCargaRoute + (model && model?.id.length > 0 ? model.id : novoCadastroRoute));
        }
        
        setModalState({
            model: model,
            aberto: openned,
            ultimaUrl: url,
            tipo: tipo

        });
    }, [modalState.aberto, modalState.ultimaUrl, uploadCargaRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.UploadCargaModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.UploadCargaModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <UploadCargaModal tipo={modalState.tipo} openned={modalState.aberto} key="mdlPessoa" model={modalState.model} />
    )
}