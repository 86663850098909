import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useThemeQueries } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';
import CardCodigo from 'views/components/cards/card-codigo/card-codigo';
import { FormCodigoEdit } from './components/form-codigo-edit';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { usePostProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/post-produto-codigo';
import { usePutProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/put-produto-codigo';
import { useGetProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/get-produto-codigo';
import { isEqual } from 'utils/is-equal';
import { useToastSaurus } from 'services/app';
import { guidEmpty } from 'utils/guid-empty';
import { isEmpty } from 'lodash';
import { useDeleteProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/delete-produto-codigo';
import { GenericForm } from 'views/components/form/components/generic-form';
import { FormComponentCodigoModel } from 'views/components/modals/produto/components/produto-edit/produto-edit';
import { NovoIcon } from 'views/components/icons';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useStyles } from './form-produto-codigos-edit-styles'
import { Button, Grid } from 'views/design-system';

export interface FormProdutoCodigoRefs {
  goBack: () => void;
  adicionarNovo: (model: ProdutoCodigoModel, titulo?: string) => void;
  isOpenned: () => boolean;
}
export interface FormProdutoCodigoProps {
  model: ProdutoCodigoModel[]
  produtoId: string
  variacaoId: string;
  preencherTela: () => Promise<void>;
  hideList: boolean;
}

export const FormProdutoCodigosEdit = forwardRef<
  FormProdutoCodigoRefs,
  FormProdutoCodigoProps
>(({ model,
  produtoId,
  variacaoId,
  preencherTela,
  hideList,
}: FormProdutoCodigoProps, ref) => {
  const { showToastPersonalizado, showToast } = useToastSaurus()
  const [codigos, setCodigos] = useState<ProdutoCodigoModel[]>([])
  const { theme } = useThemeQueries()
  const { callEvent } = useEventTools();
  const classes = useStyles();

  const [openAccordion, setOpenAccordion] = useState<boolean>(true)
  const [formComponent, setFormComponent] = useState<FormComponentCodigoModel | null>(null)

  const { postProdutoCodigo, carregando: carregandoPost } = usePostProdutoCodigo()
  const { putProdutoCodigo, carregando: carregandoPut } = usePutProdutoCodigo()
  const { getProdutoCodigo, carregando: carregandoGet } = useGetProdutoCodigo()
  const { deleteProdutoCodigo, carregando: carregandoDel } = useDeleteProdutoCodigo()

  const carregando = carregandoPost || carregandoPut || carregandoDel

  const recarregarTela = useCallback(() => {
    preencherTela()
  }, [preencherTela])

  const getProdutoCodigoWrapper = useCallback(async () => {
    const res = await getProdutoCodigo(produtoId, variacaoId);
    if (res.erro) throw res.erro;
    setCodigos(res.resultado?.data || []);
    if (res.resultado?.data.length <= 2) {
      recarregarTela();
    }
  }, [getProdutoCodigo, produtoId, recarregarTela, variacaoId])

  const deleteCodigo = useCallback(async (id: string) => {
    try {
      const res = await deleteProdutoCodigo(
        produtoId,
        variacaoId,
        id
      )

      if (res.erro) throw res.erro

      await getProdutoCodigoWrapper();

      showToast('success', 'Código deletado!');
      setFormComponent(null)
      return true
    } catch (e: any) {
      showToast('error', e.message)
      return false
    }
  }, [deleteProdutoCodigo, getProdutoCodigoWrapper, produtoId, showToast, variacaoId])

  const saveNewCodigo = useCallback(async (model: ProdutoCodigoModel) => {
    model.produtoId = produtoId
    model.produtoGradeId = variacaoId

    if (model.percTabela === 0) {
      model.percTabela = null
    }

    if (model.precoFixo === 0) {
      model.precoFixo = null
    }
    const res = await postProdutoCodigo(produtoId, variacaoId, model)

    if (res.erro) throw res.erro

    await getProdutoCodigoWrapper()

    showToastPersonalizado('success', 'Novo Código Adicionado!')
  }, [
    getProdutoCodigoWrapper,
    postProdutoCodigo,
    produtoId,
    showToastPersonalizado,
    variacaoId
  ])

  const saveChangesCodigo = useCallback(async (model: ProdutoCodigoModel) => {

    if (model.percTabela === 0) {
      model.percTabela = null
    }

    if (model.precoFixo === 0) {
      model.precoFixo = null
    }

    const res = await putProdutoCodigo(produtoId, variacaoId, model)

    if (res.erro) throw res.erro

    await getProdutoCodigoWrapper()

    showToastPersonalizado('success', 'Código Atualizado!')
  }, [
    getProdutoCodigoWrapper,
    produtoId,
    putProdutoCodigo,
    showToastPersonalizado,
    variacaoId
  ])

  const handleSubmit = useCallback(async (model: ProdutoCodigoModel, beforeModel?: ProdutoCodigoModel) => {
    try {
      const codEqual = isEqual(model, beforeModel)

      if (codEqual) {
        showToastPersonalizado('info', 'Nenhuma alteração ocorrida!')
        return false
      }

      if (model.id === guidEmpty()) {
        await saveNewCodigo(model)
        setFormComponent(null)
        return true;
      }

      await saveChangesCodigo(model)

      setFormComponent(null)
      return true
    }

    catch (e: any) {
      showToastPersonalizado('error', e.message)
      return false
    }

  }, [saveChangesCodigo, saveNewCodigo, showToastPersonalizado])

  const optArray = isEmpty(codigos) ? model : codigos

  useImperativeHandle(ref, () => ({
    adicionarNovo: (model, titulo = 'Adicionar Código') => {
      callEvent(AppEventEnum.AttTituloEdicaoProduto, {
        titulo
      })
      setFormComponent({
        title: 'Adicionar Código',
        model
      })
    },
    goBack: () => {
      callEvent(AppEventEnum.AttTituloEdicaoProduto, {
        titulo: ''
      })
      setFormComponent(null)
    },
    isOpenned: () => Boolean(formComponent)
  }))

  return (
    <>
      {!hideList && <AccordionSaurus
        expanded={openAccordion}
        labelPrimary="Códigos"
        colorExpand={theme.palette.primary.main}
        tipoExpand="bold"
        noPaperRoot
        sombreado
        onChange={() => setOpenAccordion(prev => !prev)}
        className={classes.accordion}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          {(carregandoGet) && (
            <CircularLoading tipo='FULLSIZED' />
          )}
          <Grid container spacing={2}>
            {optArray.map((item, index, arr) => {
              item.fator = (arr.some((x, i) => i !== index && x.fator && x.fator !== 1) && !item.fator) ? 1 : item.fator;
              return (
                <Grid item xs={12} >
                  <CardCodigo
                    key={item.id}
                    model={item}
                    onClick={(model) => {
                      callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                        titulo: 'Editar Código'
                      })
                      setFormComponent({
                        model,
                        title: 'Editar Código'
                      })
                    }}
                  />
                </Grid>
              )
            })}
            <Grid item xs={12}>
              <Button fullWidth variant='outlined' color='primary' onClick={() => {
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                  titulo: 'Adicionar Código'
                })
                setFormComponent({
                  title: 'Adicionar Código',
                  model: new ProdutoCodigoModel()
                });
              }}>
                <NovoIcon tipo='BUTTON' />
                Adicionar Código
              </Button>
            </Grid>
          </Grid>
        </div>
      </AccordionSaurus>}
      {formComponent && <GenericForm<ProdutoCodigoModel>
        buttonLabel='Confirmar'
        model={formComponent.model}
        deleteItem={{
          buttonLabel: formComponent.model.id === guidEmpty() ? 'Cancelar' : 'Excluir',
          handleDelete: formComponent.model.id === guidEmpty() ? async () => setFormComponent(null) : async (id: string) => { deleteCodigo(id) },
          id: formComponent.model.id,
          secondaryColor: formComponent.model.id === guidEmpty()
        }}
      >
        <FormCodigoEdit
          loading={carregando}
          showLoading={false}
          edit
          carregando={carregando}
          onSubmit={handleSubmit}
          model={formComponent.model}
        />
      </GenericForm>}
    </>
  );
})
