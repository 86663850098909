import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Divider, Grid, Typography } from 'views/design-system';
import { useFormPromocaoDataValidation } from './form-promocao-data-validations';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardDiaSemana } from 'views/components/cards/card-dia-semana/card-dia-semana';

export class PromocaoDataFormModel {
  constructor(
    public dataInicial: string = '',
    public dataFinal: string = '',
    public horarioInicial: string | null = null,
    public horarioFinal: string | null = null,
    public diasSemana: number | null = null
  ) { }
}

export enum EnumDiasSemana {
  Domingo = 1,
  Segunda = 2,
  Terca = 4,
  Quarta = 8,
  Quinta = 16,
  Sexta = 32,
  Sabado = 64
}

const buttonsDiaSemana = [
  {
    label: 'Segunda-feira',
    type: EnumDiasSemana.Segunda
  },
  {
    label: 'Terça-feira',
    type: EnumDiasSemana.Terca
  },
  {
    label: 'Quarta-feira',
    type: EnumDiasSemana.Quarta
  },
  {
    label: 'Quinta-feira',
    type: EnumDiasSemana.Quinta
  },
  {
    label: 'Sexta-feira',
    type: EnumDiasSemana.Sexta
  },
  {
    label: 'Sábado',
    type: EnumDiasSemana.Sabado
  },
  {
    label: 'Domingo',
    type: EnumDiasSemana.Domingo
  }
];

export interface FormPromocaoDataProps
  extends DefaultFormProps<PromocaoDataFormModel> { }

export const FormPromocaoData = forwardRef<
  DefaultFormRefs<PromocaoDataFormModel>,
  FormPromocaoDataProps
>(({ loading, ...props }: FormPromocaoDataProps, ref) => {
  const utilClasses = makeUtilClasses();
  const [diasSemana, setDiasSemana] = useState<number | null>(127);
  const [initialValues, setInitialValues] = useState<PromocaoDataFormModel>(
    new PromocaoDataFormModel()
  );
  const { FormYupValidationDataPromocao } = useFormPromocaoDataValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    clearErrors
  } = useForm<PromocaoDataFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormYupValidationDataPromocao),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (values: PromocaoDataFormModel) => {
    props.onSubmit({ ...values });
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await await handleSubmit(onSubmit)();
    },
    resetForm: async () => {
      setInitialValues(new PromocaoDataFormModel());
      reset({
        ...new PromocaoDataFormModel()
      });
    },
    fillForm: (model: PromocaoDataFormModel) => {
      setInitialValues(model);
      reset({ ...model });
      setDiasSemana(model.diasSemana === 0 ? 127 : model.diasSemana);
    }
  }));

  const onClickCardDiaSemana = (type: number) => {
    setDiasSemana((prev) => {

      if (!prev) {
        clearErrors('diasSemana');
        setValue('diasSemana', type);
        return type;
      }

      if (Boolean(type & prev)) {
        const novoValor = prev - type;
        setValue('diasSemana', novoValor === 0 ? null : novoValor);
        return novoValor === 0 ? 0 : novoValor;
      }

      const novoValor = prev + type;
      setValue('diasSemana', novoValor);
      return novoValor;
    });
  };

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid pb={2}>
            <Grid container spacing={2} mt={1} mb={2}>
              <Grid item xs={6}>
                <Controller
                  name="dataInicial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      {...field}
                      allowSubmit
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label={'Data Inicial'}
                      tipo={'DATA'}
                      placeholder="Data de Início"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(
                        errors.dataInicial && errors.dataInicial.message
                      )}
                      helperText={
                        touchedFields.dataInicial || errors.dataInicial
                          ? errors.dataInicial?.message
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dataFinal"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      {...field}
                      allowSubmit
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label={'Data Final'}
                      tipo={'DATA'}
                      placeholder="Data de Fim"
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(
                        errors.dataFinal && errors.dataFinal.message
                      )}
                      helperText={
                        touchedFields.dataFinal || errors.dataFinal
                          ? errors.dataFinal?.message
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle2" color='primary'          >
              Horário de Atividade (Opcional)
            </Typography>
            <Divider />

            <Grid container spacing={2} mt={1} mb={2}>
              <Grid item xs={6}>
                <Controller
                  name="horarioInicial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      {...field}
                      allowSubmit
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label={'Início'}
                      tipo={'HORA'}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(
                        errors.horarioInicial && errors.horarioInicial.message
                      )}
                      helperText={
                        touchedFields.horarioInicial || errors.horarioInicial
                          ? errors.horarioInicial?.message
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="horarioFinal"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      {...field}
                      allowSubmit
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label={'Fim'}
                      tipo={'HORA'}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(
                        errors.horarioFinal && errors.horarioFinal.message
                      )}
                      helperText={
                        touchedFields.horarioFinal || errors.horarioFinal
                          ? errors.horarioFinal?.message
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle2" color='primary'          >
              Dias da Semana
            </Typography>
            <Divider />

            <Grid container spacing={1} mt={1}>
              {buttonsDiaSemana.map((modelo, index) => (
                <Grid item xs={index < 4 ? 3 : 4}>
                  <CardDiaSemana
                    key={modelo.label}
                    label={modelo.label}
                    type={modelo.type}
                    onClick={onClickCardDiaSemana}
                    isCheck={Boolean(modelo.type & (diasSemana ?? 0))}
                  />
                </Grid>
              ))}
            </Grid>
            {errors.diasSemana && errors.diasSemana.message && (
              <Grid item xs={12} flex alignItems='center' justifyContent='center' mt={1}>
                <Typography align='center' color='error' variant='caption'>
                  {errors.diasSemana && errors.diasSemana.message}
                </Typography>
              </Grid>
            )}
            <Button style={{ display: 'none' }} type="submit"></Button>
          </Grid>
        </form>
      </div>
    </>
  );
});
