
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { ConvenioPostModel } from "model/api/gestao/convenio/convenio-post-model";

export function usePostConvenio() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postConvenio = useCallback(
    (convenio: ConvenioPostModel, empresaId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(convenio),
        enviarTokenUsuario: true,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    postConvenio,
  };
}
