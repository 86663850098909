import { FormPeRastroProps } from "./form-pe-rastro-props";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { FormPeRastroCampos } from "./components/form-pe-rastro-campos";
import { RastroModel } from "model/api/gestao/venda/venda-completa-model";
import { useCallback } from "react";
import { toDate } from "utils/to-date";
import { useStyles } from "./form-pe-rastro-styles";
import { AvisoIcon, NovoIcon } from "views/components/icons";
import { Box, Button, Grid } from "views/design-system";

export const FormPeRastro = ({
    control,
    formState,
    getValues,
    setValue,
    fields,
    remove,
    append,
    getFieldState,
    loading,
}: FormPeRastroProps) => {

    const classes = useStyles()

    const dataFormatada = useCallback((val: string | Date) => {
        const data = toDate(val) ?? new Date(val);
        const dia = data?.getDate();
        const mes = data?.getMonth() + 1;
        const ano = data?.getFullYear();

        const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
            }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
        return dataFormated;
    }, []);

    return (
        <AccordionSaurus
            labelPrimary="Lotes"
            labelSecondary={formState.errors.rastro?.message && (<><AvisoIcon tipo="BUTTON_PRIMARY" /> {(formState.errors.rastro.message ?? '')}</>)}
            labelSecondaryClasses={classes.rastroErroAccordion}
        >
            <Grid container spacing={2}>
                {fields.map((item, index) => {
                    return (
                        <FormPeRastroCampos
                            {...{
                                item,
                                index,
                                control,
                                formState,
                                loading,
                                remove,
                                getValues,
                                getFieldState
                            }}
                            item={item}
                            index={index}
                            control={control}
                            formState={formState}
                            loading={loading}
                            remove={remove}
                            key={item.id}
                            getValues={getValues}
                        />
                    )
                })}
                <Grid item xs={12}>
                    <Box mt={2}>
                        <Button color="primary" variant="outlined" fullWidth onClick={() => append({
                            ...new RastroModel(),
                            dFab: dataFormatada(new Date()),
                            dVal: dataFormatada(new Date())
                        })}>
                            <NovoIcon tipo="BUTTON" /> Novo Lote
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
} 