import { EnumPagTpTransacao, EnumPagTpMod } from "model";
import { EnumIndStatusMovPag } from "model/enums/enum-indstatus-movpag";
import { guidEmpty } from "utils/guid-empty";

export class MovSimplesPagamentoModel {
    constructor(
        // public id: string = guidEmpty(),
        public tid: string = guidEmpty(),
        public tpTransacao: EnumPagTpTransacao = EnumPagTpTransacao.NORMAL,
        public modPagamento: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
        public nParcelas: number = 1,
        public dhTransacao: Date = new Date(),
        public cAut: string = '',
        public codNsu: string = '',
        public bandeira: string = '',
        public numCartao: string = '',
        public nomeCartao: string = '',
        public adquirente: string = '',
        public viaCliente: string = '',
        public viaLojista: string = '',
        public descontoAplicado: number = 0,
        public acrescimoAplicado: number = 0,
        public vPag: number = 0,
        public envioAPI: string = '',
        public retornoAPI: string = '',
        public status: EnumIndStatusMovPag = EnumIndStatusMovPag.EmAberto,
        public pagamentoId: string = guidEmpty(),
        public pagamentoNome: string = '',
        public vTroco: number = 0,
        public cnpj: string = '',
        public cancelado: boolean = true,
        public tefId: string = guidEmpty(),
    ) { }
}
