import { CardModificadorProdutoProps } from "./card-modificador-produto-props";
import { EditarIcon } from "../../icons/editar-icon";
import { DefaultCard } from "../components/default-card";
import { useDefaultCardStyles } from "../components/default-card/default-card-styles";
import { memo, useCallback, useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { FormModificadorProduto } from "views/components/form/produto/produto-modificador/modificador-produto/form-modificador-produto";
import { toDecimalString } from "utils/to-decimal";
import { ContinuarIcon } from "views/components/icons";
import { useStyles } from './card-modificador-produto-styles'
import classNames from "classnames";
import { Box, Button, Tooltip, Typography } from "views/design-system";

const CardModificadorProduto = ({
  model,
  selected,
  ...props
}: CardModificadorProdutoProps) => {
  const classes = useDefaultCardStyles();
  const cardClasses = useStyles()
  const confirm = useConfirm()
  const [atualizar, setAtualizar] = useState<boolean>(false)

  const handleDelete = useCallback(async () => {
    const res = await props.handleDelete(model.id ?? '')
    if (res) {
      setAtualizar(false)
    }
  }, [model.id, props])

  return (
    <>
      {!atualizar && (
        <Box className={cardClasses.botoesOrdem}>
          <div className={classNames(props.index + 1 === 1 ? cardClasses.someDiv : '')}>
            <Tooltip arrow title="Subir produto" >
              <div className={cardClasses.iconeCima} onClick={() => props.trocarOrdem(model, 'cima')}>
                <ContinuarIcon tipo="BUTTON" />
              </div>
            </Tooltip>
          </div>
          <div className={classNames((props.index + 1) === props.models.length ? cardClasses.someDiv : '')}>
            <Tooltip arrow title="Descer produto" >
              <div className={cardClasses.iconeBaixo} onClick={() => props.trocarOrdem(model, 'baixo')}>
                <ContinuarIcon tipo="BUTTON" />
              </div>
            </Tooltip>
          </div>
        </Box>
      )}
      {!atualizar ? (
        <DefaultCard
          isSelected={selected}
          onClick={async () => {
            setAtualizar(true)
          }}
        >
          <div className={classes.cardContent}>
            <div className={classNames(classes.celulaGrid, cardClasses.espacamentoOrdem)}>
              <Typography color="primary" variant="caption">
                Ordem
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{props.index + 1}</strong>
              </Typography>
            </div>
            <Tooltip title={model.produtoGrade}>
              <Box className={classes.celulaGridFull} style={{ width: 100 }}>
                <Typography color="primary" variant="caption">
                  Nome
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  <strong>{model.produtoGrade}</strong>
                </Typography>
              </Box>
            </Tooltip>
            {model.valor > 0 && (
              <div className={classes.celulaGrid} style={{ textAlign: 'center' }}>
                <Typography color="primary" variant="caption">
                  Valor
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  <strong>R$ {toDecimalString(model.valor)}</strong>
                </Typography>
              </div>
            )}

          </div>
          <Tooltip arrow title="Editar Produto">
            <div style={{
              display: 'flex',
              alignItems: "center"
            }}>
              <Button
                variant='contained'
                color="primary"
                size="large"
                style={{
                  width: 40,
                  height: 40,
                  padding: 0,
                  marginRight: 5,
                  marginLeft: 5
                }}
              >
                <EditarIcon tipo="BUTTON_PRIMARY" style={{ width: 25, height: 25, marginRight: 0 }} />
              </Button>
            </div>
          </Tooltip>
        </DefaultCard>
      ) : (
        <>
          <FormModificadorProduto
            isFormEdit
            empresaId={props.empresaId}
            loading={props.carregando}
            onSubmit={props.handleSubmit}
            showLoading={true}
            handleDelete={() => confirm({
              cancellationText: "Voltar",
              confirmationText: "Deletar",
              title: "Deletar Produto",
              description: <Typography variant="body1" color="textPrimary">Deseja realmente deletar o produto <b>"{model.produtoGrade}"</b>?</Typography>,
              confirmationButtonProps: {
                variant: 'contained',
                color: 'primary'
              },
              dialogProps: {
                maxWidth: 'xs'
              }
            })
              .then(async () => {
                await handleDelete()
              })}
            closeForm={() => setAtualizar(false)}
            model={{
              ...model,
              produtoNome: model.produtoGrade,
              valor: toDecimalString(model.valor)
            }}

          />
        </>
      )}
    </>
  );
};

export default memo(CardModificadorProduto)