import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    content: {
        background: '#f5f5f5'
    },
    form: {
        padding: theme.spacing(0, 2),
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.18)",
    }
}))