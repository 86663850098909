import { Container } from "@material-ui/core"
import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useRef, useState } from "react"
import { AvancarIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { DestModel, VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useToastSaurus } from "services/app"
import { useStyles } from "./dados-destinatario-styles"
import classNames from "classnames"
import { DefaultFormRefs } from "views/components/form/utils"
import { FormDadosDestinatario } from "views/components/form/entrada/dados-destinatario/form-dados-destinatario"
import { picker } from "utils/picker"
import { DestFormModel } from "model/app/forms/entrada/dest-form-model"
import { isEqual } from "lodash"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"
import { Button, Grid } from "views/design-system"

const DadosDestinatarioPage = () => {

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const formRef = useRef<DefaultFormRefs<DestFormModel>>(null)

    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const [modelForm, setModelForm] = useState<DestFormModel>(new DestFormModel())
    const [preenchendoTela, setPreenchendoTela] = useState<boolean>(true)
    const rotaApos = useRef<'proximo' | 'anterior'>('proximo')
    const { getVendaCompleta, saveChangesVendaCompleta, carregando: carregandoEntrada } = useEntrada(mov)

    const carregando = [carregandoEntrada, preenchendoTela].includes(true)

    const recarregarForm = useCallback((model: DestFormModel) => {
        formRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(modelForm)
    }, [modelForm, recarregarForm])

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        let movAtual = new VendaCompletaModel()
        if (location.state?.mov && !att) {
            movAtual = location.state.mov
        } else {
            movAtual = await getVendaCompleta(movId)
        }
        const dest = { ...movAtual.infMov.dest }
        const destForm = picker<DestFormModel>(dest, new DestFormModel())
        setMov(movAtual)

        return destForm;
    }, [getVendaCompleta, location.state?.mov, movId])

    const preencherTela = useCallback(async (att: boolean = false) => {
        try {
            setPreenchendoTela(true);
            const dest = await getInfoVenda(att);
            setModelForm(dest);
        } catch (e: any) {
            showToast('error', e.message);
        }
        finally {
            setPreenchendoTela(false);
        }
    }, [getInfoVenda, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                search: location.search,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.search, location.state, mov, movId]
    );

    useEffect(() => {
        preencherTela(true)
    }, [getInfoVenda, preencherTela])

    useEffect(() => {
        if (mov) {
            const formValue = picker<DestFormModel>(mov.infMov.dest, new DestFormModel())
            formRef.current?.fillForm(formValue)
        }
    }, [mov])

    const proximaTela = (att?: boolean) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/emitente`,
        search: location.search,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto

        }
    })

    const voltarTela = (att?: boolean) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/entrada`,
        search: location.search,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto

        }
    })

    const handleSubmit = async (model: DestFormModel, beforeModel: DestFormModel) => {
        try {
            const equal = isEqual(model, beforeModel)
            const dest = { ...mov.infMov.dest }

            if (equal) {
                showToast('info', 'Nenhuma informação alterada')
                if (rotaApos.current === 'proximo') {
                    proximaTela()
                } else {
                    voltarTela()
                }
                return
            }

            const destForm = picker<DestModel>(model, dest)

            const movAtual = {
                ...mov,
                infMov: {
                    ...mov.infMov,
                    dest: destForm,
                },
            }

            await saveChangesVendaCompleta(movAtual)

            showToast('success', 'Dados do Destinatário alterado com sucesso!')

            if (rotaApos.current === 'proximo') {
                proximaTela(true)
            } else {
                voltarTela(true)
            }
        } catch (error: any) {
            showToast('error', error.message)
        }
    }

    const submit = (rota: 'proximo' | 'anterior') => {
        rotaApos.current = rota
        formRef.current?.submitForm()
    }

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Dados do Destinatário"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <FormDadosDestinatario
                            loading={carregando}
                            onSubmit={handleSubmit}
                            showLoading={false}
                            ref={formRef}
                        />
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => submit('anterior')}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => submit('proximo')}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default DadosDestinatarioPage