import { Grid, Typography } from 'views/design-system';
import { useCallback } from 'react';
import { Styles } from './card-historico-mobile-styles';
import { useThemeQueries } from 'views';
import { EnumHistoricoVendaStatus } from 'model/enums/enum-historico-venda-status';
import { StatusHistoricoVendaMock } from 'data/mocks/status-historico-venda-mock';
import { TpHistoricoVendaMock } from 'data/mocks/tp-historico-venda-mock';
import { toDecimalString } from 'utils/to-decimal';
import { CardHistoricoVendaOffProps } from './card-historico-venda-off-props';
import { toDateString } from 'utils/to-date';

export const CardHistoricoMobileOff = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardHistoricoVendaOffProps) => {
  const classes = Styles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumHistoricoVendaStatus.Pendente:
        return theme.palette.warning.main;
      case EnumHistoricoVendaStatus.Finalizado:
        return theme.palette.success.main;
      case EnumHistoricoVendaStatus.Cancelado:
      case EnumHistoricoVendaStatus.Abortado:
      case EnumHistoricoVendaStatus.Rejeitado:
        return theme.palette.error.main;
      case EnumHistoricoVendaStatus.EmDigitacao:
        return theme.palette.warning.dark;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.primary.main,
    model.status,
    theme.palette.error.main,
    theme.palette.warning.dark,
  ]);

  const mod =
    TpHistoricoVendaMock.filter((x) => x.Key === model.mod)[0]?.Value ?? 10;

  const status =
    StatusHistoricoVendaMock.filter((x) => x.Key === model.status)[0]?.Value ??
    10;

  return (
    <div
      onClick={() =>
        onClick(model.id ?? '')
      }
    >
      <div className={classes.cardContainer}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className={classes.celulaGridNome}>
              <Typography color="textPrimary" variant="caption">
                Dados do cliente
              </Typography>

              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{ maxWidth: 240 }}
              >
                <strong>{model.cliente?.nome ?? 'Consumidor'}</strong>
              </Typography>
            </div>

            <div>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  className={classes.celulaGridStatus}
                  style={{
                    marginRight: theme.spacing(0.5),
                    background: theme.palette.primary.main,
                  }}
                >
                  <Typography
                    className={'celula-grid-value'}
                    variant="body1"
                    style={{ color: '#fff', fontSize: '12px' }}
                  >
                    {mod}
                  </Typography>
                </div>

                <div
                  className={classes.celulaGridStatus}
                  style={{
                    background: retornarCorStatus(),
                    display: 'nowrap',
                  }}
                >
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                    style={{
                      color: '#fff',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: '12px',
                    }}
                  >
                    {status}
                  </Typography>
                </div>
              </Grid>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Itens:
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.produtos.filter((prod) => !prod.cancelado).length ?? 0}
              </Typography>
            </div>

            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Data:
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {toDateString(model.dEmi, 'DD/MM/yyyy HH:mm')}
              </Typography>
            </div>

            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Número:
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.nnf}/{model.serie}
              </Typography>
            </div>

            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Valor total:
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{toDecimalString(model.vNF, 2)}</strong>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
