import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DepositoModal } from "views/components/modals/deposito/deposito-modal";

export const CadastroDepositoModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: '',
    });

    const depositoRoute = '/depositos/'
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroDepositoModal')

    useEffect(() => {
        if (location.pathname.indexOf(depositoRoute) === 0) {
            const posrota = location.pathname.replace(depositoRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: depositoRoute,
                    search: prev.search
                }));
                return;
            }
        }
    }, [location.pathname, depositoRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? depositoRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search
        
        if (!openned) {
            push(depositoRoute + prevSearch)
        }
        if (trocarUrl) {
            push(depositoRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search,
        });
    }, [modalState.aberto, modalState.search, modalState.ultimaUrl, push])

    useEffect(() => {
        addHandler(AppEventEnum.DepositoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DepositoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DepositoModal openned={modalState.aberto} key="mdlPessoa" id={modalState.id} />
    )
}