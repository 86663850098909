import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.default,
        flex: 1,
        overflow: 'hidden'
    },
    root: {
        maxHeight: '100%',
        overflow: 'hidden',
        background: theme.palette.background.default,
    },
    relative: {
        position: 'relative',
        height: '100%',
        overflowY: 'auto',
        paddingTop: theme.spacing(1),
    },
}))