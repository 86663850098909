import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    filterContent:{
        padding: '16px'
      },
      formulario:{
        width: '100%', 
        display: 'flex' 
      }
}));