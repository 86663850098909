import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerPessoa: {
    display: 'flex',
    flex: 1,
    padding: 8,
    margin: theme.spacing(2, 2, 3, 2),
  },
  labelPessoa: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '18px',
    marginRight: 8,
    color: '#fff'
  },
  labelInfoPessoa: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
    color: '#fff'
  },
  containerIconPessoa: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerIconEditar: {
    background: '#fff',
    marginLeft: 8,
    width: 48,
    height: 48,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    padding: theme.spacing(2, 0, 2, 2)
  },
  sessaoHeader: {
    flex: 1,
    marginBottom: 16
  },
  sessaoText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 16,
    fontWeight: 700
  },
  sessaoTextItem: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    fontSize: 14,
    fontWeight: 600,
  },
  labelRadio: {
    margin: 0
  },
  gridCheck: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 90,
    heigth: 60,
    "& .MuiSwitch-root": {
      margin: 0,
    },
  },
  contentOpcao: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    height: 70,
    borderRadius: 0,
    padding: theme.spacing(2, 1, 2, 2),
  },
  divider: {
    height: '1px',
    backgroundColor: theme.palette.grey[300],
  },
  tipoDeVenda: {
    padding: theme.spacing(.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: 12,
    lineHeight: '24px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    margin: theme.spacing(0, 2)
  },
  switchSelect: {
    margin: 0
  }
}));
