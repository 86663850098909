import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import React from 'react';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { EnumMenu } from 'model';
import { ModulosPDVMock } from 'data/mocks/menu-pdv-mock';
export function useMovInsercaoStorage() {
  const { getRegistro, setRegistro } = useGestaoStorage();

  const setInsercaoFavorita = React.useCallback(
    async (rota: EnumMenu) => {
      setRegistro(GestaoStorageKeys.MovSimplesInsercaoFavorita, rota, false);
    },
    [setRegistro]
  );

  const getInsercaoFavorita = React.useCallback((): EnumMenu => {
    const tpInsercao = getRegistro(
      GestaoStorageKeys.MovSimplesInsercaoFavorita,
      false
    );
    if (tpInsercao > 0) {
      return tpInsercao
    };

    const arrayValidation = [EnumDeviceType.CORDOVA_POS]

    if (arrayValidation.includes(VariaveisAmbiente.paymentDevice)) {
      setInsercaoFavorita(EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO)
      return EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO;
    }

    setInsercaoFavorita(EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO)
    return EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO;

  }, [getRegistro, setInsercaoFavorita]);

  const getInsercaoFavoritaRota = React.useCallback((): string => {
    const favorito = getInsercaoFavorita();
    const favMod = ModulosPDVMock.find(x => x.codigo === favorito);

    if (favMod) {
      return favMod.rota;
    }
    return '';
  }, [getInsercaoFavorita]);

  return {
    getInsercaoFavorita,
    getInsercaoFavoritaRota,
    setInsercaoFavorita
  };
}