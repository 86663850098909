import { EnumTpEmis } from 'model/enums/enum-tp-emis';
import { EnumIndIEDest, EnumMovModelo, EnumPagTpMod } from 'model/enums';
import { guidEmpty } from 'utils/guid-empty';
import { EnumLevelStatus } from 'model/enums/enum-level-status';
import { EnumTpAmb } from 'model/enums/enum-tpamb';
import { EnumTpImp } from 'model/enums/enum-tp-imp';
import { EnumIdDest } from 'model/enums/enum-id-dest';
import { EnumIndIntermed } from 'model/enums/enum-ind-intermed';
import { EnumFinNFe } from 'model/enums/enum-fin-nfe';
import { EnumIndFinal } from 'model/enums/enum-ind-final';
import { EnumIndPres } from 'model/enums/enum-ind-pres';
import { EnumTpNf } from 'model/enums/enum-tp-nf';
import { EnumModFrete } from 'model/enums/enum-mod-frete';
import { EnumTpStatusMov } from 'model/enums/enum-tp-status-mov';
import { EnumCRT } from 'model/enums/enum-crt';
import { EnumIndIcms } from 'model/enums/enum-ind-icms';
import { EnumTpAuditoria } from 'model/enums/enum-tp-auditoria';
import { EnumSexo } from 'model/enums/enum-sexo';
import { EnumTipoReceita } from 'model/enums/enum-tipo-receita';
import { EnumOrigemEmissao } from 'model/enums/enum-origem-emissao';
import { AcrescimoItemModel, DescontoItemModel } from './venda-desconto-model';

export class VendaCompletaModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public operadorId: string = guidEmpty(),
        public sessaoId: string | null = null,
        public terminalId: string | null = null,
        public terminalChave: string | null = null,
        public terminalNumero: number | null = null,
        public depositoId: string | null = null,
        public dhCompetencia?: string | Date,
        public origem: string = '',
        public indVenda: boolean = false,
        public indFinanceiro: boolean = false,
        public indEstoque: boolean = true,
        public documentoNaNota: boolean = false,
        public status: EnumTpStatusMov = EnumTpStatusMov.EmDigitacao,
        public destinatarioId: string = guidEmpty(),
        public regimeTributarioId: string = guidEmpty(),
        public motivoCancelamento: string = '',
        public sessaoCancelamentoId?: string,
        public dhCancelamento?: string | Date,
        public forcarContingencia: boolean = false,
        public indOperacao: number = 0,
        public origemEmissao: EnumOrigemEmissao = EnumOrigemEmissao.TERCEIROS,
        public dhRecebimento: string | Date = '',
        public infMov: InfMovModel = new InfMovModel(),
        public movStatus: MovStatus[] = [],
        public autorizacao: AutorizacaoModel = new AutorizacaoModel(),
        public cancelamento: CancelamentoModel = new CancelamentoModel(),
        public inutilizacao: InutilizacaoModel = new InutilizacaoModel(),
        public denegacao: DenegacaoModel = new DenegacaoModel(),
        public audits: AuditModel[] = [],
        public idNFe: string | null = null
    ) { }
}

export class InfMovModel {
    constructor(
        public versao: string = '',
        public id: string = guidEmpty(),
        public idNFe: string = guidEmpty(),
        public cuf?: number,
        public cnf: string = '',
        public natOp: string = '',
        public mod: EnumMovModelo = EnumMovModelo.ORCAMENTO,
        public serie: number = 0,
        public nnf: number = 0,
        public dhEmi: string | Date = '',
        public dhSaiEnt: string | Date = '',
        public tpNF: EnumTpNf = EnumTpNf.ENTRADA,
        public idDest: EnumIdDest = EnumIdDest.INTERNA,
        public cMunFG: number = 0,
        public tpImp: EnumTpImp = EnumTpImp.SEM_GERACAO_DANFE,
        public tpEmis: EnumTpEmis = EnumTpEmis.NORMAL,
        public cdv: number = 0,
        public tpAmb: EnumTpAmb = EnumTpAmb.Producao,
        public finNFe: EnumFinNFe = EnumFinNFe.NORMAL,
        public indFinal: EnumIndFinal = EnumIndFinal.NORMAL,
        public indPres: EnumIndPres = EnumIndPres.NAO_SE_APLICA,
        public indIntermed: EnumIndIntermed = EnumIndIntermed.OP_SEM_INTERMEDIADOR,
        public infIntermediador: InfIntermediadorModel = new InfIntermediadorModel(),
        public procEmi: number = 0,
        public cobr: CobrModel = new CobrModel(),
        public verProc: string = '',
        public dhCont: string = '',
        public xJust: string = '',
        public nFref: NFrefModel[] = [],
        public emit: EmitModel = new EmitModel(),
        public dest: DestModel = new DestModel(),
        public prod: ProdsModel[] = [],
        public total: TotalModel = new TotalModel(),
        public transp: TranspModel = new TranspModel(),
        public pag: PagsModel[] = [],
        public infAdFisco: string = '',
        public infCpl: string = '',
        public UFSaidaPais: number | null = null,
        public xLocExporta: string = '',
        public xLocDespacho: string = '',
        public xNEmp: string = '',
        public xPed: string = '',
        public xCont: string = '',
        public infRespTec: InfRespTecModel = new InfRespTecModel(),
        public receitasMedicas: ReceitaMedicaModel[] = [],
        public vDesc: number = 0,
        public vAcresc: number = 0
    ) { }
}


export class InfIntermediadorModel {
    constructor(
        public cnpj: string | null = null,
        public idCadIntTran: string | null = null,
    ) { }
}

export class DestModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public documento: string = '',
        public xNome: string = '',
        public indIEDest: EnumIndIEDest = EnumIndIEDest.CONTRIBUINTE_ICMS,
        public ie: string = '',
        public isuf: string = '',
        public im: string = '',
        public email: string = '',
        public xLgr: string = '',
        public nro: string = '',
        public xCpl: string = '',
        public xBairro: string = '',
        public cMun: number = 0,
        public xMun: string = '',
        public uf: string = '',
        public cep: string = '',
        public cPais: number = 0,
        public xPais: string = '',
        public fone: string = '0',
    ) { }
}

export class CardModel {
    constructor(
        public id: string = guidEmpty(),
        public detPagId: string = guidEmpty(),
        public tpIntegra: number = 2,
        public cnpj: string = '',
        public tBand: number = 1,
        public cAut: string = ''
    ) { }
}

export class PagsModel {
    constructor(
        public id: string = guidEmpty(),
        public vTroco: number = 0,
        public status: number = 100,
        public tPag: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
        public tpTransacao: number = 0,
        public tefId: string = guidEmpty(),
        public vPag: number = 0,
        public qtdeParcela: number = 0,
        public descricao: string = '',
        public cancelado: boolean = false,
        public viaConsumidor: string = '',
        public viaEstabelecimento: string = '',
        public card: CardModel = new CardModel(),
        public pagamentoId: string = guidEmpty(),
    ) { }
}
export class IcmsModel {
    constructor(
        public id: string = guidEmpty(),
        public prodId: string = guidEmpty(),
        public vBcUfDest: number = 0,
        public vBcFcpUfDest: number = 0,
        public pFcpUfDest: number = 0,
        public pIcmsUfDest: number = 0,
        public pIcmsInter: number = 0,
        public pIcmsInterPart: number = 0,
        public orig: number = 0,
        public cst: number = 0,
        public csosn: number = 0,
        public vFcpUfDest: number = 0,
        public vIcmsUfDest: number = 0,
        public vIcmsUfRemet: number = 0,
        public modBc: number = 0,
        public pRedBc: number = 0,
        public vBc: number = 0,
        public pIcms: number = 0,
        public vIcmsOp: number = 0,
        public pDif: number = 0,
        public vIcmsDif: number = 0,
        public tipoIcms: EnumIndIcms = EnumIndIcms.Normal,
        public vIcms: number = 0,
        public pFcp: number = 0,
        public vFcp: number = 0,
        public vBcFcp: number = 0,
        public modBcSt: number = 0,
        public pMvaSt: number = 0,
        public pRedBcSt: number = 0,
        public vBcSt: number = 0,
        public pIcmsSt: number = 0,
        public vIcmsSt: number = 0,
        public vBcFcpSt: number = 0,
        public pFcpSt: number = 0,
        public vFcpSt: number = 0,
        public ufSt: string = '',
        public pBcOp: number = 0,
        public vBcStRet: number = 0,
        public pSt: number = 0,
        public vIcmsDeson: number = 0,
        public motDesIcms: number | null = null,
        public vIcmsSubstituto: number = 0,
        public vIcmsStRet: number = 0,
        public vBcFcpStRet: number = 0,
        public pFcpStRet: number = 0,
        public vFcpStRet: number = 0,
        public pCredSn: number = 0,
        public vCredIcmsSn: number = 0,
        public vBcStDest: number = 0,
        public vIcmsStDest: number = 0,
        public pRedBcEfet: number = 0,
        public vbcEfet: number = 0,
        public pIcmsEfet: number = 0,
        public vIcmsEfet: number = 0,
    ) { }
}

export class PisModel {
    constructor(
        public id: string = guidEmpty(),
        public prodId: string = guidEmpty(),
        public cst: number = 0,
        public vBc: number = 0,
        public pPis: number = 0,
        public qBcProd: number = 0,
        public vAliqProd: number = 0,
        public vPis: number = 0,
    ) { }
}

export class CofinsModel {
    constructor(
        public id: string = guidEmpty(),
        public prodId: string = guidEmpty(),
        public cst: number = 0,
        public vBc: number = 0,
        public pCofins: number = 0,
        public qBcProd: number = 0,
        public vAliqProd: number = 0,
        public vCofins: number = 0,
    ) { }
}

export class IpiModel {
    constructor(
        public clEnq: string = '',
        public cnpjProd: string = '',
        public cSelo: string = '',
        public qSelo: number = 0,
        public cEnq: number = 0,
        public cst: number = 0,
        public vBC: number = 0,
        public pIPI: number = 0,
        public qUnid: number = 0,
        public vUnid: number = 0,
        public vIPI: number = 0,
    ) { }
}

export class IiModel {
    constructor(
        public vBC: number = 0,
        public vDespAdu: number = 0,
        public vII: number = 0,
        public vIOF: number = 0,
    ) { }
}

export class RastroModel {
    constructor(
        public nLote: string = '',
        public qLote: number = 0,
        public dFab: string = '',
        public dVal: string = '',
        public cAgreg: number = 0,
    ) { }
}

export class ProdsModel {
    constructor(
        public id: string = guidEmpty(),
        public vendedorId: string = guidEmpty(),
        public produtoGradeId: string = guidEmpty(),
        public produtoId: string = guidEmpty(),
        public ideId: string = guidEmpty(),
        public categoriaId: string = guidEmpty(),
        public categoriaNome: string = '',
        public nItem: number = 0,
        public cProd: string = '',
        public depositoId: string = '',
        public cEan: string = '',
        public xProd: string = '',
        public ncm: string = '',
        public nve: string = '',
        public cest: string = '',
        public indEscala: number = 78,
        public cnpjFab: string = '',
        public cBenef: string = '',
        public extIpi: string = '',
        public cfop: number = 0,
        public uCom: string = '',
        public qCom: number = 0,
        public vUnCom: number = 0,
        public vProd: number = 0,
        public cEanTrib: string = '',
        public uTrib: string = '',
        public qTrib: number = 0,
        public vUnTrib: number = 0,
        public vFrete: number = 0,
        public vSeg: number = 0,
        public vDesc: number = 0,
        public vDescEmbutido: number = 0,
        public vOutro: number = 0,
        public vOutroEmbutido: number = 0,
        public indTot: number = 0,
        public xPed: string = '',
        public nItemPed: string = '',
        public nFci: string = '',
        public nrecopi: string = '',
        public infAdProd: string = '',
        public vTotTrib: number = 0,
        public cancelado: boolean = false,
        public vCusto: number = 0,
        public pTribFed: number = 0,
        public pTribEst: number = 0,
        public pTribMun: number = 0,
        public rastro: RastroModel[] = [],
        public icms: IcmsModel = new IcmsModel(),
        public pis: PisModel = new PisModel(),
        public cofins: CofinsModel = new CofinsModel(),
        public ipi: IpiModel = new IpiModel(),
        public ii: IiModel = new IiModel(),
        public taxaServico: number = 0,
        public valorServico: number = 0,
        public indFin: boolean = true,
        public indEstoque: boolean = true,
        public cProdANVISA: string = '',
        public vPMC: number = 0,
        public nSeqReceitaMedica: number = 0,
        public vendaControlada: boolean = false,
        public pDevol: number = 0,
        public vIPIDevol: number = 0,
        public acrescimos: AcrescimoItemModel[] = [],
        public descontos: DescontoItemModel[] = [],
        public marca: string = '',
        public motivoCancelamento: string = '',
    ) { }
}

export class InfRespTecModel {
    constructor(
        public cnpj: string = '',
        public xContato: string = '',
        public email: string = '',
        public fone: string = '0',
        public idCSRT: number = 0,
        public hashCSRT: string = '',
    ) { }
}

export class EmitModel {
    constructor(
        public id: string = guidEmpty(),
        public ideId: string | null = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public doc: string = '',
        public xNome: string = '',
        public xFant: string = '',
        public xLgr: string = '',
        public nro: string = '',
        public xCpl: string = '',
        public xBairro: string = '',
        public cMun: number = 0,
        public xMun: string = '',
        public uf: string = '',
        public cep: string = '',
        public cPais: number = 0,
        public xPais: string = '',
        public fone: string = '',
        public email: string = '',
        public ie: string = '',
        public iest: string = '',
        public im: string = '',
        public cnae: string = '',
        public crt: EnumCRT = EnumCRT.REGIME_NORMAL,
    ) { }
}

export class ReboqueTranspModel {
    constructor(
        public id: string = guidEmpty(),
        public placa: string = '',
        public uf: string = '',
        public rntc: string = '',
    ) { }
}

export class VolTranspModel {
    constructor(
        public qVol: number = 0,
        public esp: string = '',
        public marca: string = '',
        public nVol: string = '',
        public pesoL: number = 0,
        public pesoB: number = 0,
    ) { }
}

export class TranspModel {
    constructor(
        public id: string = guidEmpty(),
        public ideId: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public modFrete: EnumModFrete = EnumModFrete.SEM_TRANSPORTE,
        public doc: string = '',
        public xNome: string = '',
        public ie: string = '',
        public xEnder: string = '',
        public xMun: string = '',
        public uf: number | null = 35,
        public placa: string = '',
        public ufVeiculo: number | null = 35,
        public rntc: string = '',
        public reboque: ReboqueTranspModel[] = [],
        public vol: VolTranspModel[] = [],
    ) { }
}

export class MovStatus {
    constructor(
        public contratoId: string = guidEmpty(),
        public ideId: string = guidEmpty(),
        public id: string = guidEmpty(),
        public nSeq: number = 0,
        public descricao: string = '',
        public level: EnumLevelStatus = EnumLevelStatus.AutorizacaoArquivo,
    ) { }
}

export enum EnumTipoDocRef {
    NfeNfcSat = "refNFe",
    ConhecimentoTE = "refCTe",
    ECF = "refECF",
    NfmTalonario = "refNF",
    NfPR = "refNFP"
}

export class RefNfModel {
    constructor(
        public cUF: number = 0,
        public aamm: string = '',
        public cnpj: string = '',
        public mod: number = 0,
        public serie: number = 0,
        public nNF: number = 0,
    ) { }
}

export class RefNfpModel {
    constructor(
        public cUF: number = 0,
        public aamm: string = '',
        public cnpj: string = '',
        public cpf: string = '',
        public ie: string = '',
        public mod: number = 0,
        public serie: number = 0,
        public nNF: number = 0,
    ) { }
}

export class RefEcfModel {
    constructor(
        public mod: string = '',
        public nECF: number = 0,
        public nCOO: number = 0,
    ) { }
}

export class NFrefModel {
    constructor(
        public id: string = '',
        public refNFe: string | null = null,
        public refNF: RefNfModel | null = null,
        public refNFP: RefNfpModel | null = null,
        public refCTe: string | null = null,
        public refECF: RefEcfModel | null = null,
    ) { }
}
export class PrescritorModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string | null = null,
        public nome: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public uf: string = '',
        // public especialidade: string = '',
        public tipoDocumento: number = 1,
        public documento: string = '',
        public orgaoExpedidor: number = 1,
        public ufOrgaoExpedidor: string = '',
        public cpfcnpj: string = '',
    ) { }
}

export class CompradorModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string | null = null,
        public nome: string = '',
        public sexo: EnumSexo = EnumSexo.NaoInformado,
        public telefone: string = '',
        public dataNascimento: string | Date = '',
        public tipoDocumento: number = 1,
        public documento: string = '',
        public orgaoExpedidor: number = 1,
        public ufOrgaoExpedidor: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public uf: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public cpfcnpj: string = '',
    ) { }
}

export class PacienteModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string | null = null,
        public nome: string = '',
        public sexo: EnumSexo = EnumSexo.NaoInformado,
        public telefone: string = '',
        public dataNascimento: string | Date = '',
        public tipoDocumento: number = 1,
        public documento: string = '',
        public orgaoExpedidor: number = 1,
        public ufOrgaoExpedidor: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public uf: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public cpfcnpj: string = '',
    ) { }
}

export class ReceitaMedicaModel {
    constructor(
        public id: string = '',
        public ideId: string = '',
        public nSeq: number = 0,
        public receitaTipo: EnumTipoReceita = EnumTipoReceita.NotificacaoReceitaA,
        public dataHoraLancamento: string | Date = '',
        public dataReceita: string | Date = '',
        public numeroReceita: string = '',
        public uf: string = '',
        public cid: string = '',
        public prescritor: PrescritorModel = new PrescritorModel(),
        public comprador: CompradorModel = new CompradorModel(),
        public paciente: PacienteModel = new PacienteModel(),
        public compradorPacienteMesmaPessoa: boolean = false,
        public usoProlongado: boolean = false,
    ) { }
}

export class TotalModel {
    constructor(
        public id: string = guidEmpty(),
        public qItens: number = 0,
        public qCom: number = 0,
        public vProd: number = 0,
        public vFrete: number = 0,
        public vSeg: number = 0,
        public vDesc: number = 0,
        public vOutro: number = 0,
        public vnf: number = 0,
        public vBC: number = 0,
        public vICMS: number = 0,
        public vICMSDeson: number = 0,
        public vFCPUFDest: number = 0,
        public vICMSUFDest: number = 0,
        public vICMSUFRemet: number = 0,
        public vFCP: number = 0,
        public vBCST: number = 0,
        public vST: number = 0,
        public vFCPST: number = 0,
        public vFCPSTRet: number = 0,
        public vII: number = 0,
        public vIPI: number = 0,
        public vIPIDevol: number = 0,
        public vPIS: number = 0,
        public vCOFINS: number = 0,
        public vTotTrib: number = 0,
    ) { }
}

export class AuditModel {
    constructor(
        public id: string = guidEmpty(),
        public usuarioId: string = guidEmpty(),
        public usuarioNome: string = '',
        public tpAuditoria: EnumTpAuditoria = EnumTpAuditoria.AlteracaoDeStatus,
        public descricao: string = '',
        public registroId: string = '',
        public origemId: string = guidEmpty(),
        public data: string | Date = '',
    ) { }
}


export class AutorizacaoModel {
    constructor(
        public transmissaoId: string = guidEmpty(),
        public digVal: string = '',
        public msgSefaz: string = '',
        public codSefaz: number = 0,
        public nProt: string = '',
        public urlArquivo: string = '',
        public dhRegistro: string = '',
        public urlWs: string = '',
        public urlQRCode: string = '',
        public urlConsultaChave: string = '',
        public id: string = '',
        public empresaId: string = '',
        public operadorId: string = '',
        public sessaoId: string = '',
        public terminalId: string = '',
        public dhCompetencia: string = '',
        public origem: string = '',
        public indVenda: boolean = false,
        public indFinanceiro: boolean = false,
        public indEstoque: boolean = true,
        public status: number = 0,
        public destinatarioId: string = '',
        public regimeTributarioId: string = '',
        public motivoCancelamento: string = '',
        public sessaoCancelamentoId: string = '',
        public dhCancelamento: string = '',
        public forcarContingencia: boolean = false,
        public indOperacao: number = 0,
        public origemEmissao: EnumOrigemEmissao = EnumOrigemEmissao.TERCEIROS,
        public dhRecebimento: string = '',
        public infMov: InfMovModel = new InfMovModel(),
        public movStatus: MovStatus[] = []
    ) { }
}

export class CancelamentoModel {
    constructor(
        public transmissaoId: string = guidEmpty(),
        public digVal: string = '',
        public msgSefaz: string = '',
        public codSefaz: number = 0,
        public nProt: string = '',
        public urlArquivo: string = '',
        public dhRegistro: string = '',
        public urlWs: string = '',
        public urlQRCode: string = '',
        public urlConsultaChave: string = ''
    ) { }
}

export class InutilizacaoModel {
    constructor(
        public transmissaoId: string = guidEmpty(),
        public digVal: string = '',
        public msgSefaz: string = '',
        public codSefaz: number = 0,
        public nProt: string = '',
        public urlArquivo: string = '',
        public dhRegistro: string = '',
        public urlWs: string = '',
        public urlQRCode: string = '',
        public urlConsultaChave: string = ''
    ) { }
}

export class DenegacaoModel {
    constructor(
        public transmissaoId: string = guidEmpty(),
        public digVal: string = '',
        public msgSefaz: string = '',
        public codSefaz: number = 0,
        public nProt: string = '',
        public urlArquivo: string = '',
        public dhRegistro: string = '',
        public urlWs: string = '',
        public urlQRCode: string = '',
        public urlConsultaChave: string = ''
    ) { }
}

export class CobrModel {
    constructor(
        public fat: FatCobrModel = new FatCobrModel(),
        public dup: DupCobrModel[] = []
    ) { }
}

export class FatCobrModel {
    constructor(
        public nFat: string = '',
        public vOrig: number = 0,
        public vDesc: number = 0,
        public vLiq: number = 0,
    ) { }
}

export class DupCobrModel {
    constructor(
        public nDup: string = '',
        public dVenc: string | Date = '',
        public vDup: number = 0,
        public pagId: string = guidEmpty(),
    ) { }
}


