import { isEmpty } from 'lodash';
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { PromocaoCadastro } from './promocao-cadastro/promocao-cadastro';
import { PromocaoEdicao } from './promocao-edicao/promocao-edicao';

export const PromocaoModal = (props: ModalProps) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && isEmpty(props.id) && (<PromocaoCadastro />)}
            {props.openned && !isEmpty(props.id) && (<PromocaoEdicao id={props.id} />)}
        </DefaultModal>
    );
};
