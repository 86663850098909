import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        padding: theme.spacing(2),
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            padding: 0
        }
    },
    cardContainer: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        transition: "0.3s ease-in-out border",
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        maxWidth: 663,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            background: theme.palette.background.paper,
            boxShadow: 'none',
            borderRadius: 0,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2),
        }
    },
    cardContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        gap: theme.spacing(2),
        '& div': {
            width: '50%',
        }
    },
    submitButton1: {
        marginBottom: theme.spacing(1),
        zIndex: 2,
        paddingRight: '1rem',
    },
    submitButton2: {
        marginBottom: theme.spacing(1),
        zIndex: 2,
        paddingRight: 0
    },
}));