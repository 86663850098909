import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Controller, useForm } from "react-hook-form"
import { IRangeData } from "views/components/controles/inputs/calendario/calendario"
import { InputDataHora } from "views/components/controles/inputs/input-data-hora"
import { FiltroIcon } from "views/components/icons"
import { DepositoListModel } from "views/components/modals/produto/components/produto-edit/components/produto-estoque/produto-estoque"
import { Button, Grid } from "views/design-system"
import { useThemeQueries } from "views/theme"
import { useStyles } from "./form-movimentacoes-estoque-filtros-style"
import { forwardRef, useImperativeHandle, useMemo } from "react"
import { DefaultFormProps, DefaultFormRefs } from "../utils"
import { isEmpty } from "lodash"

export class FormMovimentacoesEstoqueModel {
    constructor(
        public depositos: DepositoListModel = new DepositoListModel('', '', ''),
        public periodo: IRangeData = { inicio: undefined, fim: undefined }
    ) { }
}

interface FormMovimentacoesEstoqueProps extends DefaultFormProps<FormMovimentacoesEstoqueModel> {
    depositoList: DepositoListModel[];
}

export const FormMovimentacoesEstoqueFiltros =
    forwardRef<DefaultFormRefs<FormMovimentacoesEstoqueModel>, FormMovimentacoesEstoqueProps
    >((props, ref) => {
        const { control, setValue, handleSubmit, reset } = useForm<FormMovimentacoesEstoqueModel>()
        const { theme } = useThemeQueries()
        const classes = useStyles()

        const onSubmit = (model: FormMovimentacoesEstoqueModel) => {
            props.onSubmit(model);
        }

        useImperativeHandle(ref, () => ({
            fillForm(inputModel) {
                reset({ ...inputModel })
            },
            resetForm() {
            },
            async submitForm() {
                handleSubmit(onSubmit)()
            },
        }))
        const depositos = useMemo(() =>
             [...props.depositoList, new DepositoListModel('', '', 'Todos os depósitos')], 
        [props.depositoList])
        const depositoPadrao = useMemo(() =>
            depositos.find(deposito => isEmpty(deposito.id))
            , [depositos])

        return (

            <form className={classes.formulario} onSubmit={handleSubmit(onSubmit)}>
                <Grid container alignItems="center" spacing={1} className={classes.filterContent}>
                    <Grid item xs={12}>
                        <Controller control={control} name={'depositos'} render={({ field }) =>
                            <Autocomplete
                                options={depositos}
                                getOptionLabel={(option) => option.nome}
                                defaultValue={depositoPadrao}
                                {...field}
                                onChange={(event, newValue) => {
                                    let deposito = newValue
                                    if (deposito) {
                                        setValue('depositos', deposito)
                                    }
                                }}
                                value={field.value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Depositos"
                                    />
                                )}
                            />

                        }
                        />
                    </Grid>
                    <Grid item xs >
                        <Controller control={control} name='periodo' render={({ field }) =>
                            <InputDataHora
                                label="Data início e fim"
                                {...field}
                                onChange={(value) => {
                                    const periodo = value as IRangeData
                                    setValue('periodo', periodo)
                                }}
                                value={field.value}
                                type="date-range"
                            />
                        }
                        />
                    </Grid>
                    <Grid alignItems="center" xs='auto'>
                        <Button type="submit" variant="contained" color="primary"><FiltroIcon tipo="BUTTON_FAB" fill={theme.palette.common.white} /></Button>
                    </Grid>
                </Grid>
            </form>
        )
    })