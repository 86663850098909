import { Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useStyles } from './dialog-alteracao-cadastros-styles';
import { toDateString } from 'utils/to-date';

interface DialogAlteracaoCadastrosProps {
  openned: boolean;
  ultimaAlteracaoAPI: Date | undefined,
  ultimaAlteracaoLocal: Date | undefined,
  handleClick: () => void;
  closeModal: () => void;
}

export const DialogAlteracaoCadastros = ({
  openned,
  closeModal,
  handleClick,
  ultimaAlteracaoAPI,
  ultimaAlteracaoLocal
}: DialogAlteracaoCadastrosProps) => {
  const classes = useStyles();

  return (
    <DialogSaurus
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Sincronização Pendente"
      tamanho="xs"
    >
      <Grid mt={2} mb={2}>
        <Typography variant="subtitle2" align='center' className={classes.title}>
          Identificamos alterações nos cadastros/configurações do sistema às <b>{toDateString(ultimaAlteracaoAPI, "DD/MM HH:mm")}</b>. Deseja iniciar a sincronização agora?
        </Typography>
        <Typography variant="subtitle2" align='center' style={{ marginTop: 16 }} className={classes.title}>
          Obs: Data da última sincronização: <b>{toDateString(ultimaAlteracaoLocal, "DD/MM HH:mm")}</b>
        </Typography>
        <Grid container spacing={2} mt={2} flexDirection='row'>
          <Grid item xs={6} md={6}>
            <Button
              variant="outlined"
              fullWidth
              rounded
              color="primary"
              onClick={closeModal}
            >
              <CancelarIcon tipo="BUTTON" />
              Não
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              variant="contained"
              fullWidth
              rounded
              color="primary"
              onClick={handleClick}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Sim
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus >
  );
};
