import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useFormDepositoValidation } from './form-deposito-edit-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DepositoModel } from 'model/api/gestao/deposito/deposito-model';
import { Box, Button, Grid } from 'views/design-system';

export const FormDepositoEdit = forwardRef<
  DefaultFormRefs<DepositoModel>,
  DefaultFormProps<DepositoModel>
>(({ loading, ...props }: DefaultFormProps<DepositoModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<DepositoModel>(
    new DepositoModel(),
  );
  const { FormDepositoYupValidation } = useFormDepositoValidation();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<DepositoModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormDepositoYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: DepositoModel) => {
    const finalizadora = picker<DepositoModel>(
      values,
      new DepositoModel(),
    );
    props.onSubmit(finalizadora, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) {
        refInputNome.current?.focus();
      }
    },
    fillForm: (model: DepositoModel) => {
      setModelForm(model);
      reset({
        ...model,
      });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) {
          refInputNome.current?.focus();
        }
      }, 500);
    },
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      inputRef={refInputNome}
                      disabled={loading}
                      label="Descrição"
                      variant="outlined"
                      error={Boolean(
                        errors.nome && errors.nome.message,
                      )}
                      helperText={
                        touchedFields.nome || errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="nfReferenciadaNaVenda"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      disabled={loading}
                      label="Chave de Acesso - Nota Fiscal Referênciada"
                      placeholder='(Opcional - Informada automaticamente na venda)'
                      variant="outlined"
                      error={Boolean(
                        errors.nfReferenciadaNaVenda && errors.nfReferenciadaNaVenda.message,
                      )}
                      helperText={
                        touchedFields.nfReferenciadaNaVenda || errors.nfReferenciadaNaVenda
                          ? errors.nfReferenciadaNaVenda?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
