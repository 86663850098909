import { guidEmpty } from 'utils/guid-empty';

export enum TipoCampoRelatorioEnum {
  Texto,
  Numero,
  Data
}

export enum ConectorEnum {
  And,
  Or
}

export enum EnumCondicoes {
  Igual,
  Diferente,
  Maior,
  MaiorIgual,
  Menor,
  MenorIgual,
  Contenha,
  NaoContenha
}

export class FiltrosRelatoriosAvancadosModel {
  constructor(
    public nome: string = '',
    public descricao: string = '',
    public campo: string = '',
    public sequencia: number = 0,
    public obrigatorio: boolean = false,
    public tipo: TipoCampoRelatorioEnum = TipoCampoRelatorioEnum.Texto,
    public condicao: EnumCondicoes[] = [],
    public espaco: number = 12,
    public valor: string = '',
    public comparacao: EnumCondicoes = EnumCondicoes.Contenha
    // public Conector: ConectorEnum | null = null,
  ) {}
}

export class OrdenadoresRelatoriosAvancadosModel {
  constructor(public nome: string = '', public conteudo: string = '') {}
}

export class RelatoriosAvancadosModel {
  constructor(
    public id: string = guidEmpty(),
    public ativo: number = 1,
    public nome: string = '',
    public codigo: number = 0,
    public filtros: FiltrosRelatoriosAvancadosModel[] = [],
    public ordenadores: OrdenadoresRelatoriosAvancadosModel[] = [],
  ) {}
}


export enum TipoRelatorio {
  PDF = 'pdf',
  EXCEL = 'excel',
  JSON = 'json'
}