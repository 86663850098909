import { Grid, Typography, Button } from 'views/design-system';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { OkIcon } from 'views/components/icons';
import { ComandasFechamentoListData } from './components/comanda-fechamento-list/comandas-fechamento-list-data';
import { useStyles } from './mov-selecionar-comanda-fechamento-styles';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

interface LocationProps {
  pedidos: PedidoModelComanda[];
  mesaId: string;
}

export const MovSelecionarComandaFechamentoPage = () => {
  const [comandasSelecionada, setComandasSelecionada] = useState<
    PedidoModelComanda[]
  >([]);

  const location = useLocation<LocationProps>();
  const history = useHistory();
  const classes = useStyles();
  const { goBack } = useHistory();

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Fechamento de Comanda',
      voltar: {
        onClick() {
          goBack();
        },
      }
    })
  }, [goBack, setHeaderProps])

  const onCardComandaSelected = useCallback((comanda: PedidoModelComanda) => {
    setComandasSelecionada((prev: PedidoModelComanda[]) => {
      const temEssaComandaSelecionada = prev.find(
        (c) => c.comandaId === comanda.comandaId,
      );

      if (temEssaComandaSelecionada) {
        return prev.filter(
          (coman) => coman.comandaId !== temEssaComandaSelecionada.comandaId,
        );
      }

      return [...prev, comanda];
    });
  }, []);

  const pedidosAutorizadosParaFechamento = [
    EnumStatusPedido.AGUARDANDO_CONFIRMACAO,
    EnumStatusPedido.CONFIRMADO,
    EnumStatusPedido.EM_PREPARO,
    EnumStatusPedido.FINALIZADOOUENTREGUE,
  ];

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.containerInfo}>
          <Grid
            style={{
              display: 'flex',
              background: '#fff',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography style={{ fontWeight: '600' }}>
              Escolha a comanda que deseja realizar o fechamento.
            </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.list}>
          <ComandasFechamentoListData
            comandas={
              location?.state?.pedidos.filter(
                (pedido) =>
                  pedido.valorTotalPedido > 0 &&
                  pedidosAutorizadosParaFechamento.includes(
                    pedido.statusPedido.codigo,
                  ),
              ) ?? []
            }
            carregando={false}
            onCardSelected={onCardComandaSelected}
            comandaSeleciona={comandasSelecionada}
          />
        </Grid>
        <Grid className={classes.containerAcao}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={comandasSelecionada.length === 0 ? true : false}
                onClick={() =>
                  history.push({
                    pathname: MovRotasMock.fecharComandaRota,
                    state: { pedidos: comandasSelecionada },
                  })
                }
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
