import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

interface ParamsProps {
    anoMes: number
    empresaId: string
    queryBusca?: string
    page?: number
}

export function useGetFaturaAnoMes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturaAnoMes = useCallback(
        ({ anoMes, empresaId, page, queryBusca }: ParamsProps) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            let queryFinal = `${queryPage?.length > 0 || (queryBusca ?? '')?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca ?? ''}`;

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/fatura/${anoMes}${queryFinal}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            })
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturaAnoMes,
    };
}
