import { EnumTpPessoaComissionamento } from "model/enums/enum-tp-pessoa-comissionamento";
import { DefaultModal } from "../components";
import { ModalProps } from "../utils";
import { ComissionadosEdicao } from "./components/comissionados-edicao/comissionados-edicao";

export interface ComissaoComissionadosModalProps extends ModalProps {
    id: string;
    openned?: boolean;
    comissaoId: string
    tpComissionado: EnumTpPessoaComissionamento
}

export const ComissaoComissionadosModal = ({ openned, ...props }: ComissaoComissionadosModalProps) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {openned && <ComissionadosEdicao {...props} />}
        </DefaultModal>
    );
}