import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

interface UseGetNovaEntradaXMLProps {
  id: string;
  empresaId: string;
}

export function useGetNovaEntradaXML() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getNovaEntradaXML = useCallback(
    ({ id, empresaId }: UseGetNovaEntradaXMLProps) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Entrada/${id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getNovaEntradaXML
  };
}
