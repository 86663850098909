import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const MoedaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M25 2C12.3093 2 2 12.3093 2 25C2 37.6907 12.3093 48 25 48C37.6907 48 48 37.6907 48 25C48 12.3093 37.6907 2 25 2ZM25 4C36.6098 4 46 13.3902 46 25C46 36.6098 36.6098 46 25 46C13.3902 46 4 36.6098 4 25C4 13.3902 13.3902 4 25 4ZM24 10V12.8008C18.4 13.1008 17.4492 17.2992 17.4492 19.1992C17.4492 28.5992 31.1504 23.5992 31.1504 30.6992C31.1504 31.5992 30.9508 35.5996 25.0508 35.5996C24.8508 35.5996 18.7492 35.6008 18.9492 29.3008H16.8496C17.0496 36.0008 21.3 36.8992 24 37.1992V40H26V37.1992C32.7 36.6992 33.1504 31.6992 33.1504 30.6992C33.1504 21.6992 19.4492 26.3004 19.4492 19.4004C19.4492 17.1004 20.3508 14.4004 25.0508 14.4004C29.8508 14.4004 30.4508 18.2004 30.5508 19.9004H32.6504C32.8504 17.1004 30.8508 13.0008 26.0508 12.8008V10H24Z" />
      </DefaultIcon>
    </>
  );
};
