import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import React from 'react';
import { useStyles } from './card-cartao-convenio-styles'
import classNames from 'classnames';
import { CardCartaoConvenioProps } from './card-cartao-convenio-props';
import { EnumConvenioStatus } from 'model/enums/enum-convenio-status';
import { toDecimalString } from 'utils/to-decimal';
import { StatusConvenioMock } from 'data/mocks/status-convenio-mock';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { Grid, Tooltip, Typography } from 'views/design-system';

const CardCartaoConvenio = ({ model, onClick, onCheck, selected }: CardCartaoConvenioProps) => {
  const classes = useDefaultCardStyles();
  const pessoaClasses = useStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumConvenioStatus.Ativo:
        return theme.palette.success.main;
      case EnumConvenioStatus.Bloqueado:
        return theme.palette.warning.main;
      case EnumConvenioStatus.Inativo:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status,
  ]);

  const descricao = StatusConvenioMock.filter((x) => x.Key === model.status)?.[0]
    ?.Value;

  const nome = model.nomeCartao

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model?.id ?? '');
        }}
        hasTagStatus={false}
      >
        <Tooltip arrow title={descricao ?? 'Não encontrado'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, pessoaClasses.card)}>
          <Grid container>
            <Grid item xs={12} className={pessoaClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                Nome Comissão
              </Typography>
              <Typography color='textPrimary' className={pessoaClasses.negrito}>
                {nome}
              </Typography>
            </Grid>
            <Grid item xs={'auto'} className={pessoaClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                Número
              </Typography>
              <Typography color='textPrimary' className={pessoaClasses.negrito}>
                {model.numeroCartao.length === 11 || model.numeroCartao.length === 14 ? formatarCPFCNPJ(model.numeroCartao) : model.numeroCartao}
              </Typography>
            </Grid>
            <Grid item xs={'auto'} className={pessoaClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                Limite
              </Typography>
              <Typography color='textPrimary' className={pessoaClasses.negrito}>
                R$ {toDecimalString(model.limite)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Editar Pessoa">
          <div className={classNames(classes.cardRightButton, pessoaClasses.iconRight)}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardCartaoConvenio);
