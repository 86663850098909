import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ConvenioIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 5 7 C 2.2504839 7 0 9.2504839 0 12 L 0 38 C 0 40.749516 2.2504839 43 5 43 L 45 43 C 47.749516 43 50 40.749516 50 38 L 50 12 C 50 9.2504839 47.749516 7 45 7 L 5 7 z M 5 9 L 45 9 C 46.668484 9 48 10.331516 48 12 L 48 38 C 48 39.668484 46.668484 41 45 41 L 5 41 C 3.3315161 41 2 39.668484 2 38 L 2 12 C 2 10.331516 3.3315161 9 5 9 z M 8 20 C 6.895 20 6 20.895 6 22 L 8 22 L 8 20 z M 9 20 L 9 24 L 12 24 L 12 20 L 9 20 z M 13 20 L 13 22 L 15 22 C 15 20.895 14.105 20 13 20 z M 6 23 L 6 25 C 6 26.105 6.895 27 8 27 L 8 23 L 6 23 z M 13 23 L 13 27 C 14.105 27 15 26.105 15 25 L 15 23 L 13 23 z M 9 25 L 9 27 L 12 27 L 12 25 L 9 25 z M 7 34 A 1 1 0 0 0 7 36 A 1 1 0 0 0 7 34 z M 10 34 A 1 1 0 0 0 10 36 A 1 1 0 0 0 10 34 z M 13 34 A 1 1 0 0 0 13 36 A 1 1 0 0 0 13 34 z M 17 34 A 1 1 0 0 0 17 36 A 1 1 0 0 0 17 34 z M 20 34 A 1 1 0 0 0 20 36 A 1 1 0 0 0 20 34 z M 23 34 A 1 1 0 0 0 23 36 A 1 1 0 0 0 23 34 z M 27 34 A 1 1 0 0 0 27 36 A 1 1 0 0 0 27 34 z M 30 34 A 1 1 0 0 0 30 36 A 1 1 0 0 0 30 34 z M 33 34 A 1 1 0 0 0 33 36 A 1 1 0 0 0 33 34 z M 37 34 A 1 1 0 0 0 37 36 A 1 1 0 0 0 37 34 z M 40 34 A 1 1 0 0 0 40 36 A 1 1 0 0 0 40 34 z M 43 34 A 1 1 0 0 0 43 36 A 1 1 0 0 0 43 34 z"></path>
      </DefaultIcon>
    </>
  );
};
