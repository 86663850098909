import { RetiradaDocumentoModel } from "model/api/gestao/retirada-documento/retirada-documento-model";
import { CardDocumentoFiscal } from "views/components/cards/card-documento-fiscal";
import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { Typography } from "views/design-system";
import { useStyles } from './documento-fiscal-list-styles'
import { useCadastros } from "services/app";
import { useCallback } from "react";


export interface DocumentoFiscalGridProps {
    list: Array<RetiradaDocumentoModel>;
    carregando: (boolean);
    selectedList: Array<string>;
    onCardSelected: (id: string, link: string) => any;
    onCardChecked: (id: string) => any;
}

export const DocumentoFiscalListData = ((props: DocumentoFiscalGridProps) => {

    const classes = useStyles();

    const { abrirCadastroRetirada } = useCadastros();

    const onClickAdd = useCallback(() => {
        abrirCadastroRetirada('', '', true);
    }, [abrirCadastroRetirada]);

    const onCardSelected = (id: string, link: string) => {
        props.onCardSelected(id, link);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem={
                        <Typography variant='h6' className={classes.textVazio} align='center'>
                            Nenhum documento foi encontrado no período especificado. <span className={classes.buttonGerar} onClick={onClickAdd}>Clique aqui</span> para gerar um novo documento.
                        </Typography>
                    }
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.list.length > 0 && props.list.map((documento, index) => {
                return (
                    <CardDocumentoFiscal
                        selected={
                            props.selectedList.filter((item) => item === documento.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={documento}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
