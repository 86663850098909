import { forwardRef, useImperativeHandle, useState } from 'react';
import { useFormCampanhaDataValidation } from './form-data-campanha-validations';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid, Typography } from 'views/design-system';
import { CardDiaSemana } from 'views/components/cards/card-dia-semana/card-dia-semana';
import { ValoresApuracao } from '../../valores-apuracao/valores-apuracao';
import { EnumTpModeloApuracao } from 'model/enums/enum-tp-modelo-apuracao';

export class CampanhaDataFormModel {
  constructor(
    public dataInicial: string = '',
    public dataFinal: string = '',
    public horarioInicio: string = '00:00',
    public horarioFinal: string = '23:59',
    public diasSemana: number | null = 127,
    public modeloApuracao: EnumTpModeloApuracao | null = EnumTpModeloApuracao.ValorVendido
  ) { }
}

export enum DiasSemana {
  Domingo = 1,
  Segunda = 2,
  Terca = 4,
  Quarta = 8,
  Quinta = 16,
  Sexta = 32,
  Sabado = 64
}

const buttonsDiaSemana = [
  {
    label: 'Segunda-feira',
    type: DiasSemana.Segunda
  },
  {
    label: 'Terça-feira',
    type: DiasSemana.Terca
  },
  {
    label: 'Quarta-feira',
    type: DiasSemana.Quarta
  },
  {
    label: 'Quinta-feira',
    type: DiasSemana.Quinta
  },
  {
    label: 'Sexta-feira',
    type: DiasSemana.Sexta
  },
  {
    label: 'Sábado',
    type: DiasSemana.Sabado
  },
  {
    label: 'Domingo',
    type: DiasSemana.Domingo
  }
];

export interface FormCampanhaDataProps
  extends DefaultFormProps<CampanhaDataFormModel> {
  cadastroNovaComissao?: boolean
}

export const FormCampanhaData = forwardRef<
  DefaultFormRefs<CampanhaDataFormModel>,
  FormCampanhaDataProps
>(({ loading, cadastroNovaComissao, ...props }: FormCampanhaDataProps, ref) => {
  const utilClasses = makeUtilClasses();
  const [diasSemana, setDiasSemana] = useState<number | null>(127);
  const [initialValues, setInitialValues] = useState<CampanhaDataFormModel>(
    new CampanhaDataFormModel()
  );
  const [modeloApuracao, setModeloApuracao] = useState<EnumTpModeloApuracao | null>(
    EnumTpModeloApuracao.ValorVendido
  );

  const { FormYupValidationDataCampanha } = useFormCampanhaDataValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    clearErrors
  } = useForm<CampanhaDataFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormYupValidationDataCampanha),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (values: CampanhaDataFormModel) => {
    props.onSubmit({ ...values });
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new CampanhaDataFormModel());
      reset({
        ...new CampanhaDataFormModel()
      });
    },
    fillForm: (model: CampanhaDataFormModel) => {
      setInitialValues(model);
      reset({ ...model });
      setDiasSemana(model.diasSemana === 0 ? 127 : model.diasSemana);
      setModeloApuracao(model.modeloApuracao)
    }
  }));

  const onClickCardDiaSemana = (type: number) => {
    setDiasSemana((prev) => {
      if (!prev) {
        clearErrors('diasSemana');
        return type;
      }

      if (Boolean(type & prev)) {
        const novoValor = prev - type;
        setValue('diasSemana', novoValor === 0 ? null : novoValor);
        return novoValor === 0 ? null : novoValor;
      }

      const novoValor = prev + type;
      setValue('diasSemana', novoValor);
      return novoValor;
    });
  };

  const onClickCardModeloApuracao = (type: number, e: any) => {
    setValue('modeloApuracao', type);
    setModeloApuracao(type);
    clearErrors('modeloApuracao')
  };

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="primary"
                style={{
                  marginTop: 16,
                  marginBottom: '8px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 600
                }}
              >
                Dias de Atividade
              </Typography>

              <Divider
                style={{
                  marginBottom: '8px'
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="dataInicial"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label={'Data inicio comissão'}
                    tipo={'DATA'}
                    placeholder="Data inicio"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.dataInicial && errors.dataInicial.message
                    )}
                    helperText={
                      touchedFields.dataInicial || errors.dataInicial
                        ? errors.dataInicial?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="dataFinal"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label={'Data fim comissão'}
                    tipo={'DATA'}
                    placeholder="Data inicio"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.dataFinal && errors.dataFinal.message
                    )}
                    helperText={
                      touchedFields.dataFinal || errors.dataFinal
                        ? errors.dataFinal?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="primary"
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 600
                }}
              >
                Hora de Atividade
              </Typography>

              <Divider
                style={{
                  marginBottom: '8px'
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="horarioInicio"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label={'Hora inicio comissão'}
                    tipo={'HORA'}
                    placeholder="Hora inicio"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.horarioInicio && errors.horarioInicio.message
                    )}
                    helperText={
                      touchedFields.horarioInicio || errors.horarioInicio
                        ? errors.horarioInicio?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="horarioFinal"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    {...field}
                    allowSubmit
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label={'Hora fim comissão'}
                    tipo={'HORA'}
                    placeholder="Hora final"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(
                      errors.horarioFinal && errors.horarioFinal.message
                    )}
                    helperText={
                      touchedFields.horarioFinal || errors.horarioFinal
                        ? errors.horarioFinal?.message
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography
            color="primary"
            style={{
              marginTop: '24px',
              marginBottom: '8px',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 600
            }}
          >
            Dias semana ativa
          </Typography>

          <Divider
            style={{
              marginBottom: '8px'
            }}
          />

          <Grid container spacing={1}>
            {buttonsDiaSemana.map((modelo) => (
              <Grid item xs={3}>
                <CardDiaSemana
                  key={modelo.label}
                  label={modelo.label}
                  type={modelo.type}
                  onClick={onClickCardDiaSemana}
                  isCheck={Boolean(modelo.type & (diasSemana ?? 0))}
                />
              </Grid>
            ))}
          </Grid>
          {!cadastroNovaComissao && (
            <>
              <Divider
                style={{
                  marginTop: '24px',
                  marginBottom: '24px'
                }}
              />

              <ValoresApuracao modeloApuracao={modeloApuracao} onClick={onClickCardModeloApuracao} />
            </>
          )}
          {errors.diasSemana && errors.diasSemana.message && (
            <p style={{ margin: 2, color: 'red', fontSize: '12px' }}>
              {errors.diasSemana && errors.diasSemana.message}
            </p>
          )}
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
