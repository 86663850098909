import { toDateStringApi } from 'utils/to-date';
import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPromocaoCargaDePor() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPromocaoCargaDePor = useCallback(
    (dataUltimaConsulta: Date) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/promocao/carga-de-por?dataReferencia=${toDateStringApi(dataUltimaConsulta)}`,
        method: 'GET',
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPromocaoCargaDePor,
  };
}
