import { sleep } from "utils/sleep";
import { Box, Button, Tooltip, Typography, Grid, Divider } from "views/design-system";

export const TesteSystemTsx = () => {
    return (
        <>
            <Grid>Grid</Grid>
            <Grid container>Grid Container</Grid>
            <Divider></Divider>
            <Divider variant="fullWidth"></Divider>
            <Divider variant="middle"></Divider>
            <Divider variant="inset"></Divider>
            <Grid container>
                <Grid item xs={6} sm={3} md={'auto'}>1</Grid>
                <Grid item xs={6} sm={3} md={'auto'}>2</Grid>
                <Grid item xs={6} sm={3} md={'auto'}>3</Grid>
                <Grid item xs={6} sm={3} md={'auto'}>4</Grid>
            </Grid>
            <Grid item>Grid Item</Grid>
            <Grid container spacing={5}>Grid Item 5</Grid>
            <Grid item xs={12} container spacing={5}>
                <Grid item xs={6} sm={3}>1</Grid>
                <Grid item xs={6} sm={3}>2</Grid>
                <Grid item xs={6} sm={3}>3</Grid>
                <Grid item xs={6} sm={3}>4</Grid>
            </Grid>
            <Typography >semNada</Typography>
            <Typography variant='body1'>body1</Typography>
            <Typography variant='body2'>body2</Typography>
            <Typography variant='button'>button</Typography>
            <Typography variant='caption'>caption</Typography>
            <Typography variant='h1'>h1</Typography>
            <Typography variant='h2'>h2</Typography>
            <Typography variant='h3'>h3</Typography>
            <Typography variant='h4'>h4</Typography>
            <Typography variant='h5'>h5</Typography>
            <Typography variant='h6'>h6</Typography>
            <Typography variant='inherit'>inherit</Typography>
            <Typography variant='overline'>overline</Typography>
            <Typography variant='srOnly'>srOnly</Typography>
            <Typography variant='subtitle1'>subtitle1</Typography>
            <Typography variant='subtitle2'>subtitle2</Typography>

            <Typography color='primary' variant='body1'>body1</Typography>
            <Typography color='primary' variant='body2'>body2</Typography>
            <Typography color='primary' variant='button'>button</Typography>
            <Typography color='primary' variant='caption'>caption</Typography>
            <Typography color='primary' variant='h1'>h1</Typography>
            <Typography color='primary' variant='h2'>h2</Typography>
            <Typography color='primary' variant='h3'>h3</Typography>
            <Typography color='primary' variant='h4'>h4</Typography>
            <Typography color='primary' variant='h5'>h5</Typography>
            <Typography color='primary' variant='h6'>h6</Typography>
            <Typography color='primary' variant='inherit'>inherit</Typography>
            <Typography color='primary' variant='overline'>overline</Typography>
            <Typography color='primary' variant='srOnly'>srOnly</Typography>
            <Typography color='primary' variant='subtitle1'>subtitle1</Typography>
            <Typography color='primary' variant='subtitle2'>subtitle2</Typography>

            <Typography color='secondary' variant='body1'>body1</Typography>
            <Typography color='secondary' variant='body2'>body2</Typography>
            <Typography color='secondary' variant='button'>button</Typography>
            <Typography color='secondary' variant='caption'>caption</Typography>
            <Typography color='secondary' variant='h1'>h1</Typography>
            <Typography color='secondary' variant='h2'>h2</Typography>
            <Typography color='secondary' variant='h3'>h3</Typography>
            <Typography color='secondary' variant='h4'>h4</Typography>
            <Typography color='secondary' variant='h5'>h5</Typography>
            <Typography color='secondary' variant='h6'>h6</Typography>
            <Typography color='secondary' variant='inherit'>inherit</Typography>
            <Typography color='secondary' variant='overline'>overline</Typography>
            <Typography color='secondary' variant='srOnly'>srOnly</Typography>
            <Typography color='secondary' variant='subtitle1'>subtitle1</Typography>
            <Typography color='secondary' variant='subtitle2'>subtitle2</Typography>

            <Typography color='error' variant='body1'>body1</Typography>
            <Typography color='error' variant='body2'>body2</Typography>
            <Typography color='error' variant='button'>button</Typography>
            <Typography color='error' variant='caption'>caption</Typography>
            <Typography color='error' variant='h1'>h1</Typography>
            <Typography color='error' variant='h2'>h2</Typography>
            <Typography color='error' variant='h3'>h3</Typography>
            <Typography color='error' variant='h4'>h4</Typography>
            <Typography color='error' variant='h5'>h5</Typography>
            <Typography color='error' variant='h6'>h6</Typography>
            <Typography color='error' variant='inherit'>inherit</Typography>
            <Typography color='error' variant='overline'>overline</Typography>
            <Typography color='error' variant='srOnly'>srOnly</Typography>
            <Typography color='error' variant='subtitle1'>subtitle1</Typography>
            <Typography color='error' variant='subtitle2'>subtitle2</Typography>

            <Typography color='textPrimary' variant='body1'>body1</Typography>
            <Typography color='textPrimary' variant='body2'>body2</Typography>
            <Typography color='textPrimary' variant='button'>button</Typography>
            <Typography color='textPrimary' variant='caption'>caption</Typography>
            <Typography color='textPrimary' variant='h1'>h1</Typography>
            <Typography color='textPrimary' variant='h2'>h2</Typography>
            <Typography color='textPrimary' variant='h3'>h3</Typography>
            <Typography color='textPrimary' variant='h4'>h4</Typography>
            <Typography color='textPrimary' variant='h5'>h5</Typography>
            <Typography color='textPrimary' variant='h6'>h6</Typography>
            <Typography color='textPrimary' variant='inherit'>inherit</Typography>
            <Typography color='textPrimary' variant='overline'>overline</Typography>
            <Typography color='textPrimary' variant='srOnly'>srOnly</Typography>
            <Typography color='textPrimary' variant='subtitle1'>subtitle1</Typography>
            <Typography color='textPrimary' variant='subtitle2'>subtitle2</Typography>

            <Typography color='textSecondary' variant='body1'>body1</Typography>
            <Typography color='textSecondary' variant='body2'>body2</Typography>
            <Typography color='textSecondary' variant='button'>button</Typography>
            <Typography color='textSecondary' variant='caption'>caption</Typography>
            <Typography color='textSecondary' variant='h1'>h1</Typography>
            <Typography color='textSecondary' variant='h2'>h2</Typography>
            <Typography color='textSecondary' variant='h3'>h3</Typography>
            <Typography color='textSecondary' variant='h4'>h4</Typography>
            <Typography color='textSecondary' variant='h5'>h5</Typography>
            <Typography color='textSecondary' variant='h6'>h6</Typography>
            <Typography color='textSecondary' variant='inherit'>inherit</Typography>
            <Typography color='textSecondary' variant='overline'>overline</Typography>
            <Typography color='textSecondary' variant='srOnly'>srOnly</Typography>
            <Typography color='textSecondary' variant='subtitle1'>subtitle1</Typography>
            <Typography color='textSecondary' variant='subtitle2'>subtitle2</Typography>


            <Typography color='inherit' variant='body1'>body1</Typography>
            <Typography color='inherit' variant='body2'>body2</Typography>
            <Typography color='inherit' variant='button'>button</Typography>
            <Typography color='inherit' variant='caption'>caption</Typography>
            <Typography color='inherit' variant='h1'>h1</Typography>
            <Typography color='inherit' variant='h2'>h2</Typography>
            <Typography color='inherit' variant='h3'>h3</Typography>
            <Typography color='inherit' variant='h4'>h4</Typography>
            <Typography color='inherit' variant='h5'>h5</Typography>
            <Typography color='inherit' variant='h6'>h6</Typography>
            <Typography color='inherit' variant='inherit'>inherit</Typography>
            <Typography color='inherit' variant='overline'>overline</Typography>
            <Typography color='inherit' variant='srOnly'>srOnly</Typography>
            <Typography color='inherit' variant='subtitle1'>subtitle1</Typography>
            <Typography color='inherit' variant='subtitle2'>subtitle2</Typography>

            <Typography color='initial' variant='body1'>body1</Typography>
            <Typography color='initial' variant='body2'>body2</Typography>
            <Typography color='initial' variant='button'>button</Typography>
            <Typography color='initial' variant='caption'>caption</Typography>
            <Typography color='initial' variant='h1'>h1</Typography>
            <Typography color='initial' variant='h2'>h2</Typography>
            <Typography color='initial' variant='h3'>h3</Typography>
            <Typography color='initial' variant='h4'>h4</Typography>
            <Typography color='initial' variant='h5'>h5</Typography>
            <Typography color='initial' variant='h6'>h6</Typography>
            <Typography color='initial' variant='inherit'>inherit</Typography>
            <Typography color='initial' variant='overline'>overline</Typography>
            <Typography color='initial' variant='srOnly'>srOnly</Typography>
            <Typography color='initial' variant='subtitle1'>subtitle1</Typography>
            <Typography color='initial' variant='subtitle2'>subtitle2</Typography>

            <Tooltip title='Teste Tooltip'>
                <div id="teste1234">TESTE AQUI</div>
            </Tooltip>
            <Tooltip arrow title='Teste Tooltip' >
                <div id="teste1234">TESTE AQUI2</div>
            </Tooltip>
            <Box>BOX</Box>
            <Box px={1}>BOX PX1</Box>
            <Box flex>BOX FLEX</Box>
            <Box alignItems="center">BOX CENTER</Box>
            <br />
            <Button variant="contained" color="primary">Botão 1</Button>
            <Button variant="outlined" color="primary">Botão 2</Button>
            <Button variant="text" color="primary">Botão 3</Button>
            <br />

            <Button variant="contained" color="secondary">Botão 1</Button>
            <Button variant="outlined" color="secondary">Botão 2</Button>
            <Button variant="text" color="secondary">Botão 3</Button>
            <br />

            <Button variant="contained" color="inherit">Botão 1</Button>
            <Button variant="outlined" color="inherit">Botão 2</Button>
            <Button variant="text" color="inherit">Botão 3</Button>
            <br />

            <Button variant="contained" color="default">Botão 1</Button>
            <Button variant="outlined" color="default">Botão 2</Button>
            <Button variant="text" color="default">Botão 3</Button>
            <br />


            <Button fullWidth variant="contained" color="primary">Botão Full</Button>
            <br />

            <Button size="large" variant="contained" onClick={async () => { await sleep(5000); }} color="primary">Botão Grande1</Button>
            <Button size="medium" variant="contained" color="primary">Botão Medio</Button>
            <Button size="small" variant="contained" color="primary">Botão Pequeno</Button>
            <br />
            <Button size="large" variant="outlined" onClick={async () => { await sleep(5000); }} color="primary">Botão Grande</Button>
            <Button size="medium" variant="outlined" color="primary">Botão Medio</Button>
            <Button size="small" variant="outlined" color="primary">Botão Pequeno</Button>
            <br />
            <Button size="large" variant="text" onClick={async () => { await sleep(5000); }} color="primary">Botão Grande</Button>
            <Button size="medium" variant="text" color="primary">Botão Medio</Button>
            <Button size="small" variant="text" color="primary">Botão Pequeno</Button>
            <br />
        </>
    );
}