import { guidEmpty } from "utils/guid-empty"

export class VendaDescontoModel {
    constructor(
        public promocao: DescontoPromocaoModel | undefined = undefined,
        public pagamento: DescontoPagamentoModel | undefined = undefined,
        public item: DescontoItemModel | undefined = undefined,
    ) { }
}

export class VendaAcrescimoModel {
    constructor(
        public pagamento: AcrescimoPagamentoModel | undefined = undefined,
        public item: AcrescimoItemModel | undefined = undefined,
    ) { }
}

export class DescontoPromocaoModel {
    constructor(
        public promocaoId: string = guidEmpty(),
        public promocaoNome: string = '',
        public tipoPromocaoRegra: number = 0,
        public percentualDesconto: number = 0,
        public valorDesconto: number = 0,
        public externalId: string = guidEmpty(),
    ) { }
}

export class AcrescimoPagamentoModel {
    constructor(
        public movPagId: string = guidEmpty(),
        public finalizadoraId: string = guidEmpty(),
        public finalizadoraNome: string = '',
        public percentualAcrescimo: number = 0,
        public valorAcrescimo: number = 0,
        public externalId: string = '',
        public usuarioId: string = guidEmpty(),
        public usuarioNome: string = '',
        public embutido: boolean = true,
    ) { }
}
export class DescontoPagamentoModel {
    constructor(
        public movPagId: string = guidEmpty(),
        public finalizadoraId: string = guidEmpty(),
        public finalizadoraNome: string = '',
        public percentualDesconto: number = 0,
        public valorDesconto: number = 0,
        public externalId: string = '',
        public usuarioId: string = guidEmpty(),
        public usuarioNome: string = '',
    ) { }
}

export class DescontoItemModel {
    constructor(
        public percentualDesconto: number = 0,
        public valorDesconto: number = 0,
        public usuarioId: string = guidEmpty(),
        public usuarioNome: string = '',
    ) { }
}
export class AcrescimoItemModel {
    constructor(
        public percentualAcrescimo: number = 0,
        public valorAcrescimo: number = 0,
        public usuarioId: string = guidEmpty(),
        public usuarioNome: string = '',
        public embutido: boolean = true,
    ) { }
}