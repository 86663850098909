import { Grid, Button } from 'views/design-system';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AtualizarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { SincronizacaoSucessoErroTabs } from './components/Sucesso-erro-tabs/sincronizacao-sucesso-erro-tabs';
import { useThemeQueries } from 'views/theme';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

export const SincronizacaoSucessoErroPage = () => {
  const classes = useDefaultCadastroStyles();
  const confirm = useConfirm();
  const { push } = useHistory();
  const { theme } = useThemeQueries();

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Sincronização',
      showMenuPdv: {
        mobile: true,
        desktop: false,
      },
    })
  }, [setHeaderProps])

  const handleClickRessincronizar = useCallback(() => {
    confirm({
      title: `Nova Sincronização.`,
      description: `Deseja iniciar uma nova sincronização? Obs: Este processo pode demorar alguns instantes.`,
      cancellationText: 'Voltar',
      cancellationButtonProps: {
        color: 'default',
        className: 'round'
      },
      confirmationButtonProps: {
        className: 'round'
      },
      confirmationText: 'Confirmar',
    }).then(() => {
      push(PdvRotasMock.sincronizarDadosRota);
    });
  }, [confirm, push]);

  const buttons = useMemo(() => {
    return (
      <Grid style={{ margin: theme.spacing(1) }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleClickRessincronizar}
          style={{ height: '52px' }}
          rounded
        >
          <AtualizarIcon tipo="BUTTON_PRIMARY" />
          Sincronizar Novamente
        </Button>
      </Grid>
    );
  }, [handleClickRessincronizar, theme]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.list}>
        <SincronizacaoSucessoErroTabs />
      </Grid>
      {buttons}
    </Grid>
  );
};

export default SincronizacaoSucessoErroPage;
