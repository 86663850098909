import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { MarcaModal } from "views/components/modals/marca/marca-modal";

export const CadastroMarcaModalRender = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: ''
    });

    const marcaRoute = `/marcas/`;
    const novoCadastroRoute = 'adicionar';
    consoleDev('CadastroMarcaModal')
    useEffect(() => {
        if (location.pathname.indexOf(marcaRoute) === 0) {
            const posrota = location.pathname.replace(marcaRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: marcaRoute,
                    search: prev.search
                }));
                return;
            }
        }
    }, [location.pathname, marcaRoute]);

    const modalAlterado = useCallback(({ id, aberto, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? marcaRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;
        
        if (!aberto) {
            push({
                pathname: marcaRoute,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(marcaRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
        setModalState({
            id: id,
            aberto: aberto,
            ultimaUrl: url,
            search: window.location.search
        });
    }, [marcaRoute, modalState.aberto, modalState.search, modalState.ultimaUrl, push])

    useEffect(() => {
        addHandler(AppEventEnum.MarcaModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.MarcaModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <MarcaModal
            openned={modalState.aberto}
            key="mdlPessoa"
            id={modalState.id}
        />
    )
}