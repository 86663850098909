import * as Yup from "yup";
import { useMemo } from "react";

export const useFormCampanhaDataValidation = () => {
  const FormYupValidationDataCampanha = useMemo(() => {
    return Yup.object().shape({
      horarioFinal: Yup.string().required('Informe o horário final.'),
      horarioInicio: Yup.string().required('Informe o horário inicial.'),
      dataFinal: Yup.string()
        .required('Informe a data final.')
        .test('is-greater', 'A data final deve ser maior ou igual à data inicial.', function(value) {
          const { dataInicial } = this.parent;
          if (!dataInicial || !value) return true;
          return new Date(value) >= new Date(dataInicial);
        }),
      dataInicial: Yup.string().required('Informe a data inicial.'),
      diasSemana: Yup.number().required('Selecione os dias da semana.').typeError('Selecione os dias da semana.')
    });
  }, []);

  return {
    FormYupValidationDataCampanha,
  };
};
