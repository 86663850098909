import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  rootAccordionNoPaper: {
    boxShadow: 'unset',
    paddingLeft: '0px',
    paddingRight: '0px',
    background: 'transparent'
  },
  rootAccordion: {},
  title: {
    '& svg': {
      width: 24
    }
  },
  accordionContent: {
    padding: theme.spacing(1, 2)
  },
  accordionContentNoPadding: {
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
  },
  accordionSumaryContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  accordionSumaryContentNoPadding: {},
  divider: {
    width: '100%',
    height: '1px',
    marginTop: '26px',
    marginBottom: '10px',
    backgroundColor: theme.palette.primary.main
  },
  iconWithoutRotation: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      // transform: "rotate(0deg)",
    }
  },
  sombreado: {
    boxShadow: '0px 3.992727518081665px 5.989091396331787px 0px #00000033',
    borderRadius: theme.shape.borderRadius
  },
}));
