import { useCallback } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { ComissaoHeader } from './components/comissao-header/comissao-header';
import { ComissaoList } from './components/comissao-list/comissao-list';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';

import { useHistory } from 'react-router-dom';
import { useCadastros } from 'services/app';
import { useConfirm } from 'material-ui-confirm';
import { useComissaoStore } from 'views/components/modals/comissao/comissao-modal/stores/comissao-store';
import { useComissionadosStore } from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { useComissaoProdutosStore } from 'views/components/modals/comissao/comissao-modal/stores/produtos-store';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { guidEmpty } from 'utils/guid-empty';

export const ComissaoPage = () => {
  const classes = useDefaultCadastroStyles();
  const { location } = useHistory();
  const { abrirCadastroComissao } = useCadastros();
  const confirm = useConfirm();
  const { getEmpresaAtual } = useEmpresaAtual();

  const {
    state: { comissao },
    resetStoreComissao,
  } = useComissaoStore((store) => store);
  const { resetStoreComissionados } = useComissionadosStore((store) => store);
  const { resetStoreProdutos } = useComissaoProdutosStore((store) => store);

  const resetStore = useCallback(() => {
    resetStoreComissao();
    resetStoreComissionados();
    resetStoreProdutos();
  }, [resetStoreComissao, resetStoreComissionados, resetStoreProdutos]);

  const onClickAdd = useCallback(() => {
    if (comissao.nome.length > 0 && comissao.empresaId === getEmpresaAtual()?.id && comissao.id === guidEmpty()) {
      confirm({
        title: `Cadastro em andamento`,
        description: `Observamos que você já iniciou o cadastro de uma comissao de comissão. Gostaria de continuar com o processo de cadastro?`,
        cancellationText: 'Novo cadastro',
        cancellationButtonProps: {
          color: 'default'
        },
        confirmationText: 'Continuar cadastro'
      })
        .then(() => {
          abrirCadastroComissao('', location.pathname, true);
        })
        .catch(() => {
          resetStore();
          abrirCadastroComissao('', location.pathname, true);
        });
    } else {
      resetStore();
      abrirCadastroComissao('', location.pathname, true);
    }
  }, [abrirCadastroComissao, comissao.empresaId, comissao.id, comissao.nome.length, confirm, getEmpresaAtual, location.pathname, resetStore]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <ComissaoHeader />
        </div>
        <div className={classes.list}>
          <ComissaoList />
        </div>
        <ButtonFab
          id="tour-cadComissao"
          tooltip="Adicionar campanha de comissão"
          icon={<NovoIcon tipo="BUTTON_FAB" />}
          onClick={onClickAdd}
        />
      </div>
    </>
  );
};
