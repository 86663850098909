import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetConvenio() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConvenio = useCallback(
        (empresaId: string, queryBusca: string, page?: number) => {

            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/conta-pessoa${queryFinal}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
            })
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getConvenio,
    };
}
