import { makeStyles, Theme } from "@material-ui/core";

interface Props {
    containedPage?: boolean
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
    "@keyframes fadein": {
        "0%": {
            opacity: 0
        },
        "100%": {
            opacity: 1
        }
    },
    publicPageHeaderContainer: {
        position: props => props.containedPage ? 'absolute' : 'fixed',
        top: 0,
        right: 0,
        width: props => props.containedPage ? '100%' : '100vh',
        height: props => props.containedPage ? '100%' : '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& >div': {
            zIndex: props => props.containedPage ? 1 : -1,
        },
        '& :last-child': {
            zIndex: 1,
        }
    },
    publicPageHeaderContainerWhite: {
        "& svg": {
            width: props => props.containedPage ? '22%' : '22vw',
            [theme.breakpoints.up('sm')]: {
                width: props => props.containedPage ? '17%' : '17vw',
            },
            [theme.breakpoints.up('md')]: {
                width: props => props.containedPage ? '12%' : '12vw',
            },
            [theme.breakpoints.up('lg')]: {
                width: props => props.containedPage ? '9%' : '9vw',
            }
        }
    },
    publicPageHeaderContainerBlue: {
        "& svg": {
            width: props => props.containedPage ? '27%' : '27vw',
            [theme.breakpoints.up('sm')]: {
                width: props => props.containedPage ? '20%' : '20vw',
            },
            [theme.breakpoints.up('md')]: {
                width: props => props.containedPage ? '15%' : '15vw',
            },
            [theme.breakpoints.up('lg')]: {
                width: props => props.containedPage ? '12%' : '12vw',
            }
        }
    },
    publicPageHeaderTopContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        "& svg:nth-child(2)": {
            marginTop: '20px',
        },
    },
    publicPageHeaderRightContainer: {
        position: 'fixed',
        right: 0,
        top: '50%',
        transform: `translateY(-50%)`,

    },
    publicPageHeaderBottomContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',

    },
    publicPageHeaderVersionControl: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        opacity: 0.6,
    }
}));

