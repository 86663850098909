
import { Button, Tooltip } from 'views/design-system';
import { useStyles } from './button-private-header-styles';

interface ButtonIconProps {
  icon: JSX.Element;
  tooltip: string;
  onClick: () => any;
  disabled?: boolean;
  buttonCancel?: boolean;
  id?: string;
}

export const ButtonPrivateHeader = (props: ButtonIconProps) => {
  const classes = useStyles();

  return (
    <>
      <Tooltip arrow title={props.tooltip} placement="right">
        <Button
          className={
            props.disabled || false ? classes.buttonCancel : classes.button
          }
          id={props.id}
          onClick={props.onClick}
          disabled={props.disabled}
          type='button'
        >
          {props.icon}
        </Button>
      </Tooltip>
    </>
  );
};
