import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumConvenioStatus } from "model/enums/enum-convenio-status";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { ConvenioListSearchProps } from "views/pages/private/cadastros/convenio/components/convenio-list/convenio-list-search-props";
import { ConvenioPesquisaModal } from "views/pages/private/cadastros/convenio/components/convenio-pesquisa-modal/convenio-pesquisa-modal";

export const ConvenioFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            status: EnumConvenioStatus.Ativo
        } as ConvenioListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ConvenioFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ConvenioFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <ConvenioPesquisaModal
            filtros={modalState.filtros}
            openned={modalState.openned}
        />
    )
}