import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Box, Grid, Typography, Button } from 'views/design-system';
import { PublicPageHeader } from 'views/components/headers';
import { useGestaoStorage } from 'services/app';
import { makeUtilClasses } from 'views/theme';
import { VariaveisAmbiente } from 'config';
import { isEmpty } from 'lodash';
import { useStyles } from './novo-contrato-redirect-styles'
import { QRCodeSVG } from 'qrcode.react';
import { AvancarIcon, LoginIcon, UserIcon } from 'views/components/icons';
import { EnumDeviceType } from 'model/enums/enum-device-type';

const NovoContratoRedirectPage = () => {
    const history = useHistory();
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { exists, delRegistro } = useGestaoStorage();
    const location = useLocation();
    const search = location.search;
    const chaveAtivacao = VariaveisAmbiente.chaveAtivacaoRevenda || new URLSearchParams(search).get('chaveAtivacao');

    useEffect(() => {
        if (VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS) {
            history.push('/novo-contrato')
        }
    }, [delRegistro, exists, history]);

    const valorQRCode = useMemo(() => {
        let url = VariaveisAmbiente.frontUrl;
        url = url + '/novo-contrato';
        if (!isEmpty(chaveAtivacao)) {
            url = `${url}?chaveAtivacao=${chaveAtivacao}`;
        }
        return url;
    }, [chaveAtivacao])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer} style={{ padding: 0 }}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" flexDirection="column" justifyContent='space-between'>
                            <Grid item xs={12}>
                                <Typography align="center" variant="h3" color="primary" className={classes.title}>
                                    <UserIcon tipo="GERAL" class={classes.imgTitulo} />
                                    Criar Conta
                                </Typography>
                                <Box mt={1} mb={5}>
                                    <Typography variant="body1" align="center">
                                        Escaneie o QRCode com seu celular para uma melhor experiência
                                        e praticidade na criação e configuração da sua conta.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid flex alignItems='center' justifyContent='center' item xs={12} md={8} lg={8} style={{ width: '100%' }}>
                                <Box className={classes.svgContainer}>
                                    <QRCodeSVG value={valorQRCode} height='100%' width='100%' />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.fontOu}>OU</Typography>
                            </Grid>

                            <Grid item container gap={2} xs={12} sm={6} md={4}>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => {
                                            history.push('/novo-contrato')
                                        }}
                                        color='primary'
                                        variant='outlined'
                                        fullWidth
                                    >
                                        <AvancarIcon tipo='BUTTON' />
                                        Continuar por aqui
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => history.push('/login')}
                                        color='primary'
                                        variant='contained'
                                        fullWidth
                                    >
                                        <LoginIcon tipo='BUTTON_PRIMARY' />
                                        Ir para o Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default NovoContratoRedirectPage;