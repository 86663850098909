import { TabelaSessaoValores } from 'database/interfaces/interface-tabela-sessao-valores';
import { TouchoneBacupkup } from 'database/touchone-backup';
import { TouchoneDBPrimary } from 'database/touchone-database';
import {
  PagamentoSessaoModel,
  PortadorSessaoModel,
  SessaoFechamento,
  SessaoSincronizar,
  SessaoValorModel,
  SessaoValueFechamento
} from 'model/api/gestao/sessao/sessao-valor-model';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import React from 'react';
import { newGuid } from 'utils/new-guid';
import { useDevice } from './device';
import { ImpressaoSaurus } from './impressao-saurus';
import { usePDV } from './pdv';
import { useToastSaurus } from './toast-saurus';
import { useSessaoAtual } from '../providers';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { useImpressaoLocal } from './impressao-local';
import { dataAtual } from 'utils/to-date';

export function useSessaoPDV() {
  // HOOKS
  const { getPDV, getImpressoraPdv } = usePDV();
  const { printHtml, printNative, printType } = useDevice();
  const { getEmpresaSelecionada, getPessoa } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { enviarImpressaoLocal, carregandoImpressaoLocal } = useImpressaoLocal();
  // AUX
  const getSessao = React.useCallback(async () => {
    try {
      const sessaoAberta = await TouchoneDBPrimary.sessao
        .filter(
          (sessao) =>
            sessao.aberta === true &&
            sessao.empresaId === getEmpresaSelecionada()!.Id &&
            sessao.caixaId === getPDV()!.id &&
            sessao.operadorId === getPessoa().pessoa?.id
        )
        .toArray();

      if (sessaoAberta.length > 0) {
        return sessaoAberta[0];
      }

      return undefined;
    } catch (e: any) {
      return undefined;
    }
  }, [getEmpresaSelecionada, getPDV, getPessoa]);

  const setSessao = React.useCallback(
    async (id: string | undefined) => {
      try {
        const sessao = await getSessao();

        if (sessao) {
          return sessao;
        }

        const empresaId = getEmpresaSelecionada()!.Id
        const operadorId = getPessoa().pessoa?.id ?? ''


        const novaSessao = {
          id: id ?? newGuid(),
          dataAbertura: dataAtual(),
          aberta: true,
          empresaId: empresaId,
          dataFechamento: '',
          operadorId: operadorId,
          caixaId: getPDV()!.id
        };
        await TouchoneDBPrimary.sessao.add(novaSessao);
        return novaSessao;
      } catch (e: any) {
        showToast('error', `Erro ao abrir sessão`);
        return undefined;
      }
    },
    [getEmpresaSelecionada, getPDV, getPessoa, getSessao, showToast]
  );

  const fecharSessao = React.useCallback(
    async (sessaoId: string) => {
      try {
        const sessao = await TouchoneDBPrimary.sessao.get({ id: sessaoId });
        if (!sessao) {
          return true;
        }

        await TouchoneDBPrimary.sessao.update(sessao.idIndexed!, {
          aberta: false,
          dataFechamento: new Date().toString()
        });

        return true;
      } catch (err: any) {
        showToast('error', `Erro ao finalizar a sessão Localmente`);
        return false;
      }
    },
    [showToast]
  );

  const setValor = React.useCallback(
    async (
      valor: number,
      tpLancamento: number,
      pagamento: PagamentoSessaoModel | undefined,
      observacao: string = '',
      portador: PortadorSessaoModel | undefined
    ) => {
      try {
        const sessao = await getSessao();

        if (!sessao) {
          throw new Error('Nenhuma sessão foi encontrada.');
        }

        let pagamentoDefault: PagamentoSessaoModel = new PagamentoSessaoModel();

        if (!pagamento) {
          const pag = await TouchoneDBPrimary.finalizadoras
            .filter((pag) => pag.tpMod === 1)
            .toArray();
          if (pag.length === 0) {
            throw new Error(
              'O modelo de pagamento dinheiro não foi encontrado'
            );
          }

          pagamentoDefault = {
            pagamentoId: pag[0]!.id as string,
            descricao: pag[0]!.descricao as string,
            tpMod: pag[0]!.tpMod,
            tpTransacao: pag[0]!.tpTransacao
          };
        }

        const sessaoValor = {
          id: newGuid(),
          sessaoId: sessao.id,
          empresaId: getEmpresaSelecionada()!.Id,
          observacao,
          tpLancamento,
          valor,
          dataSincronizacao: '',
          caixaId: getPDV()!.id,
          pagamento: pagamento ? pagamento : pagamentoDefault,
          responsavel: {
            responsavelId: getPessoa().pessoa?.id ?? '',
            nome: getPessoa().pessoa?.nome ?? '',
            documento: getPessoa().pessoa?.cpfcnpj ?? ''
          },
          portador: portador
            ? portador
            : {
              portadorId: getPessoa().pessoa?.id ?? '',
              nome: getPessoa().pessoa?.nome ?? '',
              documento: getPessoa().pessoa?.cpfcnpj ?? ''
            },
          operador: {
            operadorId: getPessoa().pessoa?.id ?? '',
            nome: getPessoa().pessoa?.nome ?? '',
            documento: getPessoa().pessoa?.cpfcnpj ?? ''
          },
          dataInsercao: dataAtual()
        };

        await TouchoneDBPrimary.sessaoValores.add(sessaoValor);

        return sessaoValor;
      } catch (e: any) {
        showToast('error', `Erro ao inserir o valor. Detalhes: ${e.message}`);
        return undefined;
      }
    },
    [getEmpresaSelecionada, getPDV, getPessoa, getSessao, showToast]
  );

  const setValores = React.useCallback(
    async (sessaoValores: SessaoValueFechamento[]) => {
      try {
        const sessao = await getSessao();

        if (!sessao) {
          throw new Error('Nenhuma sessão foi encontrada.');
        }

        const valoresSerealizados = sessaoValores.map((v) => {
          return {
            ...v,
            valor: Number(v.valor.replace(',', '.')),
            responsavel:
              v.responsavel === undefined
                ? {
                  responsavelId: getPessoa().pessoa?.id ?? '',
                  nome: getPessoa().pessoa?.nome ?? '',
                  documento: getPessoa().pessoa?.cpfcnpj ?? ''
                }
                : v.responsavel,
            operador: {
              operadorId: getPessoa().pessoa?.id ?? '',
              nome: getPessoa().pessoa?.nome ?? '',
              documento: getPessoa().pessoa?.cpfcnpj ?? ''
            },
            portador: {
              portadorId: getPessoa().pessoa?.id ?? '',
              nome: getPessoa().pessoa?.nome ?? '',
              documento: getPessoa().pessoa?.cpfcnpj ?? ''
            },
            dataInsercao: new Date().toString(),
            id: newGuid(),
            empresaId: getEmpresaSelecionada()!.Id,
            dataSincronizacao: '',
            caixaId: getPDV()!.id,
            sessaoId: sessao.id
          };
        });

        await TouchoneDBPrimary.sessaoValores.bulkAdd(valoresSerealizados);
      } catch (e: any) {
        showToast('error', `Erro ao inserir o valores. Detalhes: ${e.message}`);
      }
    },
    [getEmpresaSelecionada, getPDV, getPessoa, getSessao, showToast]
  );

  const verificarItensParaSincronizacao = React.useCallback(async () => {
    try {
      let valoresNaoSincronizados: TabelaSessaoValores[] = [];

      const valoresNaoSincronizadosAtuais =
        await TouchoneDBPrimary.sessaoValores
          .filter(
            (valor) =>
              (valor.caixaId === getPDV()?.id ?? '') &&
              valor.tpLancamento !== EnumTpLancamentoSessaoValor.FECHAMENTO &&
              !valor.dataSincronizacao &&
              valor.operador.operadorId === getPessoa().pessoa?.id
          )
          .toArray();

      const valoresNaoSincronizadosAntigos =
        await TouchoneBacupkup.sessaoValores
          .filter(
            (valor) =>
              (valor.caixaId === getPDV()?.id ?? '') &&
              valor.tpLancamento !== EnumTpLancamentoSessaoValor.FECHAMENTO &&
              !valor.dataSincronizacao &&
              valor.operador.operadorId === getPessoa().pessoa?.id
          )
          .toArray();

      valoresNaoSincronizados = [
        ...valoresNaoSincronizadosAtuais,
        ...valoresNaoSincronizadosAntigos
      ];

      return valoresNaoSincronizados;
    } catch (e: any) {
      showToast(
        'error',
        `Erro ao realizar verificação. Detalhes: ${e.message}`
      );
      return undefined;
    }
  }, [getPDV, getPessoa, showToast]);

  const converterDados = React.useCallback(
    (valoresParaConversao: SessaoValorModel[]) => {
      const conversao = valoresParaConversao.map((valor) => {
        return {
          id: valor.id,
          sessaoId: valor.sessaoId,
          pagamentoId: valor.pagamento.pagamentoId,
          valor: valor.valor,
          tpLancamento: valor.tpLancamento,
          dLancamento: valor.dataInsercao,
          observacao: valor.observacao,
          operadorId: valor.operador.operadorId,
          operadorNome: valor.operador.nome,
          responsavelId: valor.responsavel.responsavelId,
          responsavelNome: valor.responsavel.nome,
          portadorId: valor.portador.portadorId,
          portadorNome: valor.portador.nome,
          caixaId: valor.caixaId
        };
      }) as SessaoSincronizar[];
      return conversao;
    },
    []
  );

  const agruparValores = React.useCallback(
    (valoresParaAgrupar: SessaoValorModel[], quantidade: number) => {
      let result: SessaoValorModel[][] = [[]];
      let group = 0;

      for (let i = 0; i < valoresParaAgrupar.length; i++) {
        if (result[group] === undefined) {
          result[group] = [];
        }
        result[group].push(valoresParaAgrupar[i]);
        if ((i + 1) % quantidade === 0) {
          group += 1;
        }
      }
      return result;
    },
    []
  );

  const verificarValoresDBBackup = React.useCallback(
    async (attValores: TabelaSessaoValores[]) => {
      let atualizarDados: TabelaSessaoValores[] = attValores;
      for (let i = 0; i < attValores.length; i++) {
        const valorNoDbAntigo = await TouchoneBacupkup.sessaoValores.get({
          id: attValores[i].id
        });

        if (!valorNoDbAntigo) {
          continue;
        }

        atualizarDados = atualizarDados.filter(
          (item) => item.id !== valorNoDbAntigo.id
        );
        await TouchoneBacupkup.sessaoValores.delete(valorNoDbAntigo.id);
      }

      await TouchoneDBPrimary.sessaoValores.bulkPut(atualizarDados);
    },
    []
  );

  const atualizarValores = React.useCallback(
    async (valores: SessaoValorModel[]) => {
      try {
        const attValores = valores.map((valor) => {
          return {
            ...valor,
            dataSincronizacao: new Date().toString()
          };
        }) as TabelaSessaoValores[];
        await verificarValoresDBBackup(attValores);
      } catch (e: any) {
        showToast(
          'error',
          `Erro ao atualizar dados localmente. Detalhes: ${e.message}`
        );
        return undefined;
      }
    },
    [showToast, verificarValoresDBBackup]
  );

  const atualizarValoresFechamento = React.useCallback(
    async (sessaoId: string) => {
      try {
        const valores = await TouchoneDBPrimary.sessaoValores
          .filter(
            (valor) =>
              valor.tpLancamento === EnumTpLancamentoSessaoValor.FECHAMENTO &&
              !valor.dataSincronizacao &&
              valor.sessaoId === sessaoId
          )
          .toArray();

        const attValores = valores.map((valor) => {
          return {
            ...valor,
            dataSincronizacao: new Date().toString()
          };
        }) as TabelaSessaoValores[];

        await TouchoneDBPrimary.sessaoValores.bulkPut(attValores);
      } catch (e: any) {
        showToast(
          'error',
          `Erro ao atualizar dados localmente. Detalhes: ${e.message}`
        );
        return undefined;
      }
    },
    [showToast]
  );

  const imprimirResumo = React.useCallback(
    async (resumo: string, codigoReferencia: string, converter: boolean = true, impressorLocal: boolean = true) => {
      try {
        const imprimir = new ImpressaoSaurus('HTML');
        const impressora = getImpressoraPdv()
        if (resumo) {
          const pdvEmUso = getPDV();
          const qtdColuna = pdvEmUso?.configuracoes.find((p) => p.cod === 57);

          if (printType() === 'HTML') {
            const resumoTraduzido = imprimir.Traduz(resumo);
            if (!resumoTraduzido) {
              throw new Error(`Erro ao realizar impressão do resumo.`);
            }

            if (pdvEmUso?.tpCaixa === EnumPDVTpCaixa.WEBPDV && impressora && impressorLocal) {
              const externalIdGerado = newGuid();
              await enviarImpressaoLocal(externalIdGerado, codigoReferencia, converter ? resumoTraduzido : resumo, 'Resumo', null);
              return;
            }

            return await printHtml(
              resumoTraduzido as string,
              Number(qtdColuna!.vConfig || '0')
            );
          } else if (printType() === 'NATIVE') {
            const caminho = pdvEmUso?.configuracoes.find((p) => p.cod === 52);
            const modelo = pdvEmUso?.configuracoes.find((p) => p.cod === 51);

            return await printNative(
              resumo,
              Number(qtdColuna!.vConfig || '0'),
              caminho!.vConfig,
              modelo!.vConfig,
              1
            );
          }
        } else {
          throw new Error(`Erro ao realizar impressão do resumo.`);
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [enviarImpressaoLocal, getImpressoraPdv, getPDV, printHtml, printNative, printType, showToast]
  );

  const verificarItensParaFechamento = React.useCallback(async () => {
    try {
      const itensNaoSincronizados = await verificarItensParaSincronizacao();
      if (itensNaoSincronizados && itensNaoSincronizados.length > 0) {
        return undefined;
      }
      const valoresNaoSincronizados = await TouchoneDBPrimary.sessaoValores
        .filter(
          (valor) =>
            (valor.caixaId === getPDV()?.id ?? '') &&
            valor.tpLancamento === EnumTpLancamentoSessaoValor.FECHAMENTO &&
            !valor.dataSincronizacao &&
            valor.operador.operadorId === getPessoa().pessoa?.id
        )
        .toArray();

      const sessoesNaoSincronizadas = await TouchoneDBPrimary.sessao
        .filter(
          (sessao) =>
            (sessao.caixaId === getPDV()?.id ?? '') &&
            !sessao.dataFechamento &&
            sessao.operadorId === getPessoa().pessoa?.id
        )
        .toArray();

      let agrupados: SessaoFechamento[] = [];

      for (let i = 0; i < sessoesNaoSincronizadas.length; i++) {
        let value = {
          sessaoId: sessoesNaoSincronizadas[i].id,
          payload: {
            dataAcao: dataAtual(),
            responsavelId: '',
            responsavelNome: '',
            valores: []
          }
        } as SessaoFechamento;

        for (let j = 0; j < valoresNaoSincronizados.length; j++) {
          if (
            valoresNaoSincronizados[j].sessaoId ===
            sessoesNaoSincronizadas[i].id
          ) {
            if (!value.payload.responsavelId) {
              value.payload.responsavelId =
                valoresNaoSincronizados[j].responsavel.responsavelId;
              value.payload.responsavelNome =
                valoresNaoSincronizados[j].responsavel.nome;
            }

            value.payload.valores.push({
              pagamentoId: valoresNaoSincronizados[j].pagamento.pagamentoId,
              valor: valoresNaoSincronizados[j].valor
            });

            const jaAdicionado = agrupados.find(
              (grupo) => grupo.sessaoId === sessoesNaoSincronizadas[i].id
            );

            if (!jaAdicionado) {
              agrupados.push(value);
            }
          }
        }
      }
      return agrupados;
    } catch (e: any) {
      showToast('error', `Erro realizar verificação. Detalhes: ${e.message}`);
      return undefined;
    }
  }, [getPDV, getPessoa, showToast, verificarItensParaSincronizacao]);

  return {
    // Sessão Caixa
    getSessao,
    setSessao,
    fecharSessao,

    // Sessão Valores
    setValor,
    setValores,
    atualizarValores,
    atualizarValoresFechamento,

    // Verificação
    verificarItensParaSincronizacao,
    converterDados,
    agruparValores,
    verificarItensParaFechamento,

    // Impressão
    imprimirResumo,
    carregandoImpressaoLocal
  };
}
