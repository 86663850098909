import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useDeleteComissaoComissionados() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteComissaoComissionados = useCallback(
        (empresaId: string, comissaoId: string, comissionadosId: string[]) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/comissao/${comissaoId}/comissionados`,
                method: 'DELETE',
                data: JSON.stringify(comissionadosId),
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        deleteComissaoComissionados,
    };
}