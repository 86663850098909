import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(theme => (
    {
        cardContainer: {
            transition: "0.3s ease-in-out border",
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: "5px",
            display: "flex",
            minHeight: "60px",
            position: "relative",
            marginTop: "5px",
        },
        chart: {
            width: '97%',
            marginLeft: '5px'
        },
        chartDonut: {
            width: '500px',
            [theme.breakpoints.down("sm")]: {
                width: '370px !important',
            },
            marginLeft: '5px'
        },
        progressBar: {
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-50%',
                width: '100%',
                height: '100%',
                background: `#${((Math.random() * 0xffffff) << 0)
                    .toString(16)
                    .padStart(6, '0')}`,
                pointerEvents: ' none',
                zIndex: 1,
            }
        },
        cardReceitaPorHorario: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'right',
        }
    }
))