import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginBottom: theme.spacing(2),
    },
    acoes: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    descricao: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        fontWeight: 500,
        fontSize: '16px',
        color: theme.palette.grey[400],
    },
    divider: {
    }
}))