import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useStyles } from './form-taxa-entrega-styles';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { CircularLoading } from 'views/components/utils';
import { isEmpty, isEqual } from 'lodash';
import { useFormTaxaEntregaValidation } from './form-taxa-entrega-validation';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { GeolocalizacaoFreteResponseModel } from 'model/api/gestao/geolocalizacao/geolocalizacao-frete-response-model';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { TaxaEntregaFormModel } from 'model/app/forms/taxa-entrega/taxa-entrega-form-model';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

interface FormTaxaEntregaProps extends DefaultFormProps<TaxaEntregaFormModel> {
    vCalculado: GeolocalizacaoFreteResponseModel,
    pedidoState?: PedidoModel
}

export const FormTaxaEntrega = forwardRef<
    DefaultFormRefs<TaxaEntregaFormModel>,
    FormTaxaEntregaProps
>(({ vCalculado, pedidoState, ...props }: FormTaxaEntregaProps, ref) => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { theme } = useThemeQueries();
    const { getConfigByCod: getConfigEmp } = useEmpresaAtual()
    const { getConfigByCod: getConfigContrato } = useContratoAtual()
    const { formTaxaEntregaValidationYup } = useFormTaxaEntregaValidation();
    const [, setAtualizar] = useState<boolean>(false)
    const valorBandeira = getConfigEmp(EnumEmpresaConfig.ValorBandeira)
    const produtoEntrega = getConfigContrato(EnumContratoConfig.ProdutoEntrega)
    const prodEntregaPedido = pedidoState?.produtos.find(x => x.produtoIdReferencia === produtoEntrega)

    const carregando = [props.loading].includes(true)

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
    } = useForm<TaxaEntregaFormModel>({
        defaultValues: {
            valor: 0,
            tipoEntrega: 'propria'
        },
        resolver: yupResolver(formTaxaEntregaValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const submit = async (value: TaxaEntregaFormModel) => {
        props.onSubmit(value)
    }

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            handleSubmit(submit)();
        },
        resetForm: () => {
            reset({ ...new TaxaEntregaFormModel() });
        },
        fillForm: (model: TaxaEntregaFormModel) => {
            reset({ ...model })
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}
                <Grid container spacing={2} justifyContent='center' className={classes.container}>
                    <form onSubmit={handleSubmit(submit)}>
                        <Box
                            width="100%"
                            border={`dashed 1px ${theme.palette.text.secondary}`}
                            p={1}
                            mb={2}
                            borderRadius={"8px"}
                        >
                            <FormControlLabel
                                label="Entrega Própria"
                                checked={getValues('tipoEntrega') === 'propria'}
                                onClick={() => {
                                    setValue('tipoEntrega', 'propria')
                                    setAtualizar(prev => !prev)
                                }}
                                control={<SaurusRadio />}
                            />
                            <div className={classes.containerValores}>
                                <div>
                                    <Typography variant="body2" className={classes.title}>
                                        Valor de Bandeira
                                    </Typography>
                                    <Typography className={classes.text}>
                                        {`R$ ${toDecimalString(valorBandeira)}`}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="body2" className={classes.title}>
                                        Valor por KM
                                    </Typography>
                                    <Typography className={classes.text}>
                                        {`R$ ${toDecimalString(vCalculado.valorFrete || 0)}`}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="body2" className={classes.title}>
                                        Valor Frete
                                    </Typography>
                                    <Typography className={classes.text}>
                                        {`R$ ${toDecimalString((vCalculado.valorFrete || 0) + toDecimal(valorBandeira || 0))}`}
                                    </Typography>
                                </div>
                            </div>
                            <FormControlLabel
                                label="Entrega Terceirizada"
                                checked={getValues('tipoEntrega') === 'terceirizada'}
                                onClick={() => {
                                    setValue('tipoEntrega', 'terceirizada')
                                    setAtualizar(prev => !prev)
                                }}
                                control={<SaurusRadio />}
                            />
                        </Box>
                        <div>
                            <Controller
                                name='valor'
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextFieldSaurus
                                        {...field}
                                        disabled={carregando}
                                        tipo="DECIMAL"
                                        manterMascara
                                        label="Valor da Taxa (R$)"
                                        error={!isEmpty(fieldState.error)}
                                        helperText={fieldState.error
                                            ? fieldState.error.message
                                            : undefined}
                                    />
                                )}
                            />
                            {pedidoState && !isEqual(vCalculado.valorFrete, prodEntregaPedido?.valorTotal || 0) && (
                                <Typography align='right'>
                                    <Box fontWeight={500}>
                                        Frete Atual: R$ {toDecimalString(prodEntregaPedido?.valorTotalFrete)}
                                    </Box>
                                </Typography>
                            )}
                        </div>
                        <Button type="submit" style={{ display: 'none' }} />
                    </form>
                </Grid>
            </div>
        </>
    );
});
