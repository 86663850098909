import { KeyValueModel } from "model/api";
import { EnumPDVConfigCod } from "model/enums/enum-pdv-config";

export const TooltipPdvConfigMock: KeyValueModel[] = [
   
    new KeyValueModel(EnumPDVConfigCod.SincronizacaoCadastros, "Ao entrar no caixa ou finalizar uma venda o PDV poderá avalizar se é necessária uma atualização dos dados."),
    new KeyValueModel(EnumPDVConfigCod.ModeloTrabalho, "Apenas Caixa: Além de fazer o papel de caixa, permite incluir produtos somente após a importação a ser fechada; Lançador com Fechamento de Venda: Além de fazer o papel de caixa, permite fazer o lançamento de produtos; Lançador sem Fechamento de Venda: Permite apenas fazer o lançamento de produtos."),
    new KeyValueModel(EnumPDVConfigCod.ModeloVenda, "Venda Simples - controle manual.    ECF - para transmissão manual dos cupons.    SAT CF-e - para transmissão automática dos cupons.     NFC-e - controle fiscal."),
    new KeyValueModel(EnumPDVConfigCod.DispararSetorFinalizacaoVenda, "Defina o momento em que o pedido será enviado para o preparo.    Sim - após o pagamento.    Não - após o lançamento."),
    new KeyValueModel(EnumPDVConfigCod.ImpressaoComprovante, "Escolha se os comprovantes serão impressos de forma automática ou manual após o pagamento"),
    new KeyValueModel(EnumPDVConfigCod.TicketConsumacao, "Escolha se deseja ou não utilizar o ticket de consumação. Ao habilitar, será gerado ticket em todas as vendas."),

];
