import { Container } from "@material-ui/core"
import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { AvancarIcon, NovoIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { CobrModel, DupCobrModel, FatCobrModel, PagsModel, VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useCadastros, useToastSaurus } from "services/app"
import { useStyles } from "./dados-pagamento-styles"
import classNames from "classnames"
import { FormPagamentos } from "views/components/form/entrada/dados-pagamentos/form-pagamentos"
import { DefaultFormRefs } from "views/components/form/utils"
import { PagamentosFormModel } from "model/app/forms/entrada/pagamentos-form-model"
import CardPagamentoEntrada from "views/components/cards/card-pagamento-entrada/card-pagamento-entrada"
import { useEventTools } from "services/app/hooks/events/event-tools"
import { AppEventEnum } from "model/enums/enum-app-event"
import { toDecimal } from "utils/to-decimal"
import { findLastIndex } from "lodash"
import { roundTo } from "utils/round-to"
import { newGuid } from "utils/new-guid"
import { DinheiroMaosIcon } from "views/components/icons/dinheiro-maos-icon"
import { useThemeQueries } from "views/theme"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"
import { Box, Button, Grid, Typography } from "views/design-system"


const DadosPagamentoPage = () => {

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const formRef = useRef<DefaultFormRefs<PagamentosFormModel>>(null)
    const { abrirDadosPagamento, abrirPagamentoEditModal } = useCadastros();
    const [att, setAtt] = useState<boolean>(false)
    const { addHandler, removeHandler } = useEventTools()
    const firstEnterPageRef = useRef<boolean>(true)
    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const [valueInput, setValueInput] = useState<number>(0)
    const [error] = useState<string>('')
    const { theme } = useThemeQueries()

    const { getVendaCompleta, saveChangesVendaCompleta, carregando } = useEntrada(mov)

    const valorRestante = useMemo(() => mov.infMov.total.vnf - mov.infMov.pag.reduce((a, b) => a + b.vPag, 0), [mov.infMov.pag, mov.infMov.total.vnf])

    useEffect(() => {
        const totais = mov.infMov.total
        const vImpostos =
            totais.vCOFINS +
            totais.vFCP +
            totais.vFCPST +
            totais.vFCPSTRet +
            totais.vFCPUFDest +
            totais.vICMS +
            totais.vICMSDeson +
            totais.vICMSUFDest +
            totais.vICMSUFRemet +
            totais.vII +
            totais.vIPI +
            totais.vST +
            totais.vIPIDevol +
            totais.vPIS;

        const vPago = mov.infMov.pag.reduce((a, b) => a + (b.vPag - b.vTroco), 0)
        const valores: PagamentosFormModel = {
            vDesc: totais.vDesc,
            vFrete: totais.vFrete,
            vImpostos: vImpostos,
            vOutros: totais.vOutro,
            vPago,
            vProds: totais.vProd,
            vSeg: totais.vSeg,
            vTotal: totais.vnf,
            vTroco: mov.infMov.pag.reduce((a, b) => a + b.vTroco, 0),
            vAReceber: totais.vnf - vPago
        }

        const vRestante = mov.infMov.total.vnf - mov.infMov.pag.reduce((a, b) => a + b.vPag, 0)
        setValueInput(vRestante)

        if (formRef.current) {
            formRef.current.fillForm(valores)
        }
    }, [mov])

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        try {
            let movAtual = new VendaCompletaModel()
            if (location.state?.mov && !att) {
                movAtual = location.state.mov
            } else {
                movAtual = await getVendaCompleta(movId)
            }

            setAtt(false);
            setMov(movAtual)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getVendaCompleta, location.state?.mov, movId, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                search: location.search,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.search, location.state, mov, movId]
    );

    const atualizar = ({ att = false }: { att: boolean }) => {
        setAtt(att)
    }

    useEffect(() => {
        if (att || firstEnterPageRef.current) {
            getInfoVenda(att)
            firstEnterPageRef.current = false
        }

        addHandler(AppEventEnum.PagamentoEditModal, atualizar)
        return () => removeHandler(AppEventEnum.PagamentoEditModal, atualizar)
    }, [addHandler, att, getInfoVenda, removeHandler])



    const handleCobrDup = useCallback((mov: VendaCompletaModel) => {
        let cobr = mov.infMov.cobr
        if (!cobr) {
            cobr = new CobrModel()
        }
        cobr.fat.nFat = String(mov.infMov.nnf)
        cobr.fat.vOrig = mov.infMov.total.vnf
        cobr.fat.vDesc = mov.infMov.total.vDesc
        cobr.fat.vLiq = mov.infMov.total.vProd

        const ultPagamento = mov.infMov.pag[findLastIndex(mov.infMov.pag)]
        const dupPorUltPag = cobr.dup.filter(dup => dup.pagId === ultPagamento.id)
        let dup = cobr.dup
        const dupSort = dup.sort((a, b) => Number(a.nDup) - Number(b.nDup))
        const dupLast = dupSort?.[dupSort.length - 1]
        if (dupPorUltPag.length === 0) {
            const nParcelas = ultPagamento.qtdeParcela <= 0 ? 1 : ultPagamento.qtdeParcela
            for (let sequencia = 0; sequencia < nParcelas; sequencia++) {
                let newDup = new DupCobrModel()
                const vPag = ultPagamento.vPag / nParcelas
                const data = new Date()
                const dVenc = new Date(data.setMonth(data.getMonth() + sequencia))

                newDup.vDup = roundTo(vPag, 2)
                newDup.dVenc = dVenc
                newDup.pagId = ultPagamento.id
                newDup.nDup = dupLast ? String(Number(dupLast.nDup) + (sequencia + 1)) : `${sequencia + 1}`
                dup.push(newDup)
            }
            cobr.dup = dup
        }
        mov.infMov.cobr = cobr
    }, [])

    const handlePagamento = async (pag: PagsModel) => {
        try {
            const model = structuredClone(mov) as VendaCompletaModel
            const guid = newGuid()
            pag.id = guid
            pag.card.detPagId = guid
            model.infMov.pag.push(pag)
            if (!model.infMov.cobr) {
                model.infMov.cobr = new CobrModel()
            }
            handleCobrDup(model)
            await saveChangesVendaCompleta(model)

            await getInfoVenda(true)
            return true
        } catch (error: any) {
            showToast('error', error.message)
            return false
        }
    }

    const handleEdit = (pagId: string) => {
        const clonedMov = structuredClone(mov) as VendaCompletaModel
        if (!clonedMov.infMov.cobr) {
            const fat = new FatCobrModel(
                clonedMov.infMov.nnf.toString(),
                clonedMov.infMov.total.vnf,
                clonedMov.infMov.total.vnf
            )
            const cobr = new CobrModel(
                fat,
                clonedMov.infMov.pag.map((pag, i) => {
                    return new DupCobrModel(`${i + 1}`, '', pag.vPag, pag.id);
                })
            )

            clonedMov.infMov.cobr = cobr;
        }
        abrirPagamentoEditModal(pagId, clonedMov)
    }

    const handleFaturarNovamente = async () => {
        try {
            const movAtual = structuredClone(mov) as VendaCompletaModel

            movAtual.infMov.pag = []
            if (movAtual.infMov.cobr) {
                movAtual.infMov.cobr.dup = []
            }
            await saveChangesVendaCompleta(movAtual)

            showToast('success', 'Pagamento resetado!')
            await getInfoVenda(true)
        } catch (error: any) {
            showToast('error', error.message)
        }
    }

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Pagamentos"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Grid container className={classes.container}>
                    <Grid item
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <FormPagamentos
                            loading={carregando}
                            onSubmit={() => { }}
                            showLoading={false}
                            ref={formRef}
                        />


                        <Grid item xs={12} className={classes.buttonAddPayment}>
                            <Button
                                color="primary"
                                disabled={error.length > 0 || valorRestante <= 0}
                                fullWidth
                                variant="outlined"
                                onClick={() => abrirDadosPagamento({
                                    id: '',
                                    callbackUrl: '',
                                    trocarUrl: false,
                                    pagCb: {
                                        valor: toDecimal(valueInput),
                                        callback: handlePagamento
                                    }
                                })}
                            >
                                <NovoIcon tipo="BUTTON" />
                                <Typography className={classes.buttonTitle} variant='button'>Adicionar Pagamento</Typography>
                            </Button>
                        </Grid>

                        <Box mt={3}>
                            {mov.infMov.pag.map(pag => {
                                return (
                                    <CardPagamentoEntrada model={pag} onClick={handleEdit} />
                                )
                            })}
                        </Box>
                    </Grid>
                </Grid>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    disabled={carregando || mov.infMov.pag.length <= 0}
                                    onClick={handleFaturarNovamente}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <DinheiroMaosIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                                    <Typography className={classes.buttonTitle} variant='button'>Faturar Novamente</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/info-pedido-compra`,
                                        search: location.search,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/situacao-entrada`,
                                        search: location.search,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default DadosPagamentoPage