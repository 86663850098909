//import { guidEmpty } from "../../../../utils/guid-empty";

import { PerfilPermissaoOpcoesModel } from "model/api";
import { EnumGrupoPermissao } from "model/enums/enum-grupo-permissao";
import { EnumTpControle } from "model/enums/enum-tpcontrole";

export class PerfilEditPermissaoFormModel {
  constructor(
    public id: string = '',
    public codigo: string = '',
    public descricao: string = '',
    public titulo: string = '',
    public tipo: EnumTpControle = EnumTpControle.SimNao,
    public valor: string = '',
    public padrao: string = '',
    public opcoes: PerfilPermissaoOpcoesModel | null = null,
    public alterado: boolean = false,
    public grupoPermisao: EnumGrupoPermissao | null = null
  ) { }
}
