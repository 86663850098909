import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentMotivoBloqueio: {
        background: theme.palette.error.main + '25',
        borderRadius: theme.shape.borderRadius
    },
    contentMotivo: {
        background: theme.palette.primary.main + '25',
        borderRadius: theme.shape.borderRadius
    },
    titleDialog: {
        fontSize: theme.typography.h5.fontSize
    },
    destaqueNome: {
        color: theme.palette.primary.main,
        fontWeight: 600
    }
}))