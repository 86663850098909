import classNames from 'classnames';
import { usePostSetor } from 'data/api/gestao/setores/post-setores';
import { NewSetorModel } from 'model/api/gestao/setores/setores-model';
import { SetorCadastroFormModel } from 'model/app/forms/setor/setor-cadastro-form-model';
import { useCallback, useEffect, useRef } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { FormSetorCadastro } from 'views/components/form/setor/form-setor-cadastro/form-setor-cadastro';
import { DefaultFormRefs } from 'views/components/form/utils';
import {
  SalvarEditarIcon,
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { CircularLoading } from 'views/components/utils';
import { ModalHeader } from '../../components';
import { useModalStyles } from '../../utils/modal-styles';
import { useSessaoAtual } from 'services/app';
import { Button, Grid } from 'views/design-system';

export const SetoresCadastro = () => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { postSetor, carregando } = usePostSetor();
  const { fecharCadastroSetores, abrirCadastroSetores } = useCadastros();

  const classes = useModalStyles();
  const { showToast } = useToastSaurus();

  const cadSaloesFormRef =
    useRef<DefaultFormRefs<SetorCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);

  const saveNewSalao = useCallback(
    async (model: NewSetorModel) => {
      try {
        const ret = await postSetor(model, getEmpresaSelecionada()?.Id ?? '');

        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          setTimeout(() => {
            abrirCadastroSetores(ret.resultado?.data.id, '', true);
          }, 1);
        }
        showToast('success', 'Setor adicionado com sucesso!');

        if (!redirect.current) {
          cadSaloesFormRef.current?.resetForm();
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadSaloesFormRef.current?.fillForm({
          ...model,
        });
      }
    },
    [postSetor, getEmpresaSelecionada, showToast, abrirCadastroSetores],
  );

  const handleSubmit = useCallback(
    async (modelP: SetorCadastroFormModel) => {
      return saveNewSalao({
        ...modelP,
        descricao: modelP.descricao,
        uriImage: modelP.uriImage,
      });
    },
    [saveNewSalao],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadSaloesFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadSaloesFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroSetores();
  }, [fecharCadastroSetores]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Novo Setor'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormSetorCadastro
            ref={cadSaloesFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
