import { PessoaSelecionarBuscaProps } from './pessoa-selecionar-busca-props';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Box, Button, Divider, Grid } from 'views/design-system';
import { Paginacao } from 'views/components/paginacao';
import {
  FiltroIcon,
  InformacaoIcon,
  OkIcon,
  VoltarIcon
} from 'views/components/icons';
import { FormPesquisaHeader } from 'views/components/form/form-pesquisa-header/form-pesquisa-header';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import classNames from 'classnames';
import { CardNaoEncontrado } from 'views/components/cards';
import { isEmpty } from 'lodash';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components/utils';
import { useModalStyles } from '../../utils/modal-styles';
import { useStyles } from './pessoa-selecionar-busca-styles';
import { ModalHeader } from '../../components';
import { TecladoIcon } from 'views/components/icons/teclado-icon';
import { formatDecimalInteger } from 'utils/to-decimal';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { PessoaListSearchProps } from 'views/pages/private/cadastros/pessoa/components/pessoa-list/pessoa-list-search-props';
import { useGetPessoas } from 'data/api/gestao/pessoa';
import { PessoaModel } from 'model/api/gestao/pessoa';
import CardPessoaSelecionar from 'views/components/cards/card-pessoa-selecionar/card-pessoa-selecionar';
import { Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import { EnumCadastroStatus } from 'model';

export const PessoaSelecionarBusca = (props: PessoaSelecionarBuscaProps) => {
  const ultimoTermoPesquisado = useRef(undefined as string | undefined);
  const termoRef = useRef<HTMLInputElement>(null);
  const { abrirPessoasFiltroModal } = useFiltrosModais();
  const { showToast } = useToastSaurus();
  const { getPessoas, carregando } = useGetPessoas();
  const [pessoasSelecionadas, setPessoasSelecionadas] = useState<PessoaModel[]>(
    []
  );
  const { theme } = useThemeQueries();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaModel>()
  });

  const [filtros, setFiltros] = useState<PessoaListSearchProps>({
    termo: '',
    status: [EnumCadastroStatus.LIBERADO, EnumCadastroStatus.LIBERADO],
    tpCadastro: []
  });

  const modalClasses = useModalStyles();
  const classes = useStyles();

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.termo) ? '&Generico=' + filtros.termo : '') +
        (filtros.status.length > 0
          ? filtros.status.find(t => t === -1) ? '' : filtros.status.reduce((acc, current) => acc + `&status=${current}`, '')
          : '');
      try {
        const res = await getPessoas(query, newPage);
        if (res.erro) throw res.erro;
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        ultimoTermoPesquisado.current = filtros.termo;
        setQueryStatus({
          page: res.resultado?.data?.pageIndex,
          list: res.resultado?.data?.list,
          totalResults: res.resultado?.data?.totalResults,
          totalPages: res.resultado?.data?.totalPages
        });
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.termo, filtros.status, getPessoas, showToast]
  );

  const handleFilter = (filtro: PessoaListSearchProps) => {
    setFiltros((prev) => ({
      ...prev,
      ...filtro
    }));

    return;
  };

  const cardSelecionado = useCallback(
    async (pessoa: PessoaModel) => {
      if (props.typeSelect === 'radio') {
        setPessoasSelecionadas([pessoa]);
        props.adicionar && props.adicionar([pessoa]);
        return;
      }
      setPessoasSelecionadas((prev) => {
        if (prev.findIndex((x) => x.id === pessoa.id) > -1) {
          return prev.filter((x) => x.id !== pessoa.id);
        } else {
          return [...prev, pessoa];
        }
      });
    },
    [props]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const selectAll = useCallback(() => {
    setPessoasSelecionadas((prev) => {
      const todosSelecionados =
        (queryStatus?.list ?? []).filter(
          (x) => prev.findIndex((y) => y.id === x.id) === -1
        ).length === 0;

      let res = prev.filter(
        (x) => (queryStatus.list ?? []).findIndex((y) => y.id === x.id) === -1
      );

      if (!todosSelecionados) res.push(...queryStatus.list);
      return res;
    });
  }, [queryStatus.list]);

  useEffect(() => {
    termoRef.current?.focus();
  }, []);

  useEffect(() => {
    if (filtros.termo.length > -1) {
      search(1);
    }
  }, [filtros.termo, search]);

  const renderSearchTextfield = useMemo(
    () => (
      <FormPesquisaHeader
        text={filtros.termo}
        forceMobile
        inputRef={termoRef}
        onSubmit={(model) => {
          setFiltros((prev) => {
            if (prev.termo === model.generico) return prev;
            return {
              ...prev,
              termo: model.generico
            };
          });
        }}
        placeholder={`Buscar pessoa`}
      />
    ),
    [filtros.termo]
  );

  const renderProdutos = useMemo(() => {
    return queryStatus.list.map((pessoa) => {
      return (
        <CardPessoaSelecionar
          selected={
            (props.idsBloqueados ?? []).findIndex(
              (item) => item === pessoa.id
            ) > -1 ||
              pessoasSelecionadas.findIndex((item) => item.id === pessoa.id) > -1
              ? true
              : false
          }
          selectedDisabled={
            (props.idsBloqueados ?? []).findIndex(
              (item) => item === pessoa.id
            ) > -1
          }
          onClick={() => cardSelecionado(pessoa)}
          onCheck={() => { }}
          model={pessoa}
          key={pessoa.id}
          select={props.typeSelect === 'select'}
        />
      );
    });
  }, [
    cardSelecionado,
    pessoasSelecionadas,
    props.idsBloqueados,
    props.typeSelect,
    queryStatus.list
  ]);

  const renderTopContent = useMemo(() => {
    const todosSelecionados =
      (queryStatus?.list ?? []).filter(
        (x) => pessoasSelecionadas.findIndex((y) => y.id === x.id) === -1
      ).length === 0;
    return (
      <Grid>
        <Box mb={2}>
          {typeof props.label === 'string' ? (
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {props.label}
            </Typography>
          ) : (
            props.label
          )}
          <Divider
            style={{ backgroundColor: '#D9D9D9', marginTop: theme.spacing(1) }}
          />
        </Box>
        <Grid flex justifyContent="space-between" flexDirection="row">
          <Paginacao
            className={classes.paginacao}
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          {props.typeSelect === 'select' && (
            <Grid
              onClick={() => selectAll()}
              className={classes.botaoSelecionar}
            >
              Selecionar Todos
              <CheckboxDefault color="primary" checked={todosSelecionados} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }, [
    classes.botaoSelecionar,
    classes.paginacao,
    pageChanged,
    pessoasSelecionadas,
    props.label,
    props.typeSelect,
    queryStatus?.list,
    queryStatus.page,
    queryStatus.totalPages,
    queryStatus.totalResults,
    selectAll,
    theme
  ]);

  const renderButtons = useMemo(() => {
    const qtdSelecionados = pessoasSelecionadas.filter(
      (x) =>
        (props.idsBloqueados ?? []).findIndex((item) => item === x.id) === -1
    ).length;
    return (
      <Box className={modalClasses.acoes}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Button
              disabled={qtdSelecionados === 0}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                if (props.adicionar)
                  props.adicionar(
                    pessoasSelecionadas.filter(
                      (x) =>
                        (props.idsBloqueados ?? []).findIndex(
                          (item) => item === x.id
                        ) === -1
                    )
                  );
              }}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Confirmar Selecionados
              {(qtdSelecionados || 0) > 0 && (
                <Badge
                  className={classes.badgeQtde}
                  badgeContent={
                    (qtdSelecionados || 0) > 99
                      ? '99+'
                      : formatDecimalInteger(qtdSelecionados || 0, 3)
                  }
                  color="error"
                ></Badge>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }, [classes.badgeQtde, modalClasses.acoes, pessoasSelecionadas, props]);

  return (
    <Box className={classNames(modalClasses.root, classes.root)}>
      {carregando && (
        <Box className={classes.loadingContainer}>
          <CircularLoading tipo="FULLSIZED" />
        </Box>
      )}
      <ModalHeader
        title={renderSearchTextfield}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              if (props.voltar) props.voltar();
            }}
          />
        }
        rightArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<FiltroIcon tipo="MODAL_HEADER" />}
            onClick={() => {
              abrirPessoasFiltroModal(filtros, handleFilter);
            }}
          />
        }
      />
      <Box className={modalClasses.content}>
        <Grid className={classNames(modalClasses.contentForms)}>
          {renderTopContent}
          <Grid>
            {!carregando && (
              <>
                {renderProdutos.length === 0 &&
                  ultimoTermoPesquisado.current === undefined ? (
                  <CardNaoEncontrado
                    iconSize="small"
                    mensagem={`Digite alguma informação do produto para busca.`}
                    icon={<TecladoIcon tipo="GERAL" />}
                  />
                ) : renderProdutos.length === 0 &&
                  filtros.termo.length === 0 ? (
                  <CardNaoEncontrado
                    iconSize="small"
                    mensagem={`Nenhuma pessoa encontrada.`}
                    icon={<InformacaoIcon tipo="GERAL" />}
                  />
                ) : renderProdutos.length === 0 && filtros.termo.length > 0 ? (
                  <CardNaoEncontrado
                    iconSize="small"
                    mensagem={`Nenhuma pessoa encontrada com o filtro '${filtros.termo}'.`}
                    icon={<FiltroIcon tipo="GERAL" />}
                  />
                ) : (
                  renderProdutos
                )}
              </>
            )}
          </Grid>
        </Grid>
        {props.typeSelect === 'select' && renderButtons}
      </Box>
    </Box>
  );
};
