import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Produto {
  nome: string;
  categoria: string;
  id: string;
  imgUrl: string;
  removido?: boolean;
  sincronizado?: boolean;
  marca: string
}

interface IStateStore {
  stepProdutos: number;
  produtos: Produto[];
  produtosDesvincular: Produto[];
}

interface IComissaoProdutosStore {
  state: IStateStore;
  setProdutos: (data: Produto[]) => void;
  setProdutosDesvincular: (data: Produto[]) => void;
  removerProdutosDesvincular: (data: Produto[]) => void;
  resetStoreProdutos: () => void;
}

export const useComissaoProdutosStore = create<IComissaoProdutosStore>()(
  persist(
    (set) => ({
      state: {
        stepProdutos: 0,
        produtos: [],
        produtosDesvincular: []
      },
      setProdutos(data: Produto[]) {
        set((state) => ({
          state: {
            ...state.state,
            produtos: [...data]
          }
        }));
      },
      setProdutosDesvincular(data: Produto[]) {
        set((state) => ({
          state: {
            ...state.state,
            produtosDesvincular: [...data]
          }
        }));
      },
      removerProdutosDesvincular(data: Produto[]) {
        set((state) => ({
          state: {
            ...state.state,
            produtosDesvincular: state.state.produtosDesvincular.filter(
              (item) => !data.some((produto) => produto.id === item.id)
            ),
            produtos: state.state.produtos.map((item) => {
              const desvinculado = state.state.produtosDesvincular.find(
                (produto) => produto.id === item.id
              );
              if (desvinculado) {
                return {
                  ...item,
                  removido: true
                };
              }
              return item;
            })
          }
        }));
      },
      resetStoreProdutos() {
        set(() => ({
          state: {
            stepProdutos: 0,
            produtos: [],
            produtosDesvincular: []
          }
        }));
      }
    }),
    {
      name: 'comissoes-produtos-store'
    }
  )
);
